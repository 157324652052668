<template>
  <div class="d-flex align-items-center" style="height: 50px">
    <el-tooltip effect="dark" content="Edit Zoho Link" placement="bottom">
    <i v-if="user.type === 6" data-placement="right" class="el-icon-s-tools" style="font-size: 22px;color: #333333"
       @click="dialogVisible=true"></i>
    </el-tooltip>
    <el-dialog
        class="edit-zoho-dialog"
        title="Edit Zoho Link"
        :visible.sync="dialogVisible"
        @close="closeModal"
        width="30%">
      <el-form ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules">
        <el-form-item label="Old Link" prop="oldZohoLink">
          <el-input v-model="ruleForm.oldZohoLink"></el-input>
        </el-form-item>
        <el-form-item label="New Link" prop="newZohoLink">
          <el-input v-model="ruleForm.newZohoLink"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <v-btn class="white-button me-2" dark @click="dialogVisible = false">Cancel</v-btn>
    <v-btn dark :loading="loading" @click="submitForm('ruleForm')">Save</v-btn>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";

export default {
  name: "EditZohoLinkDialog.vue",
  props: ['type', 'id', 'index'],
  data() {
    const validateOld = (rule, value, callback) => {
      if (value !== ''){
        if (this.ruleForm.newZohoLink !== '') {
          this.$refs.ruleForm.validateField('newZohoLink');
        }
        callback();
      } else {
        callback();
      }
    };
    const validateNew = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input new link'));
      } else if (value === this.ruleForm.oldZohoLink) {
        callback(new Error('New link and old link should not be the same'));
      } else {
        callback();
      }
    };
    return {
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      dialogVisible: false,
      loading: false,
      ruleForm: {
        oldZohoLink:'',
        newZohoLink: ''
      },
      rules: {
        oldZohoLink: [
          { validator: validateOld, trigger: 'blur' }
        ],
        newZohoLink: [
          { validator: validateNew, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeModal() {
      this.$refs.ruleForm.resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const param = {
            oldZohoLink: this.ruleForm.oldZohoLink.trim(),
            newZohoLink: this.ruleForm.newZohoLink.trim(),
            tableID: this.id,
            updateType: this.type
          }
          utilities.updateZohoLink(param).then(() => {
            this.loading = false
            this.$emit('updateSuccess', this.index, this.ruleForm.newZohoLink)
            this.dialogVisible = false
            this.$notify({
              dangerouslyUseHTMLString: true,
              message: '<div class="alert-success">' +
                  '            <b>Success:</b> Zoho Link Updated' +
                  '          </div>',
            });
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
.edit-zoho-dialog ::v-deep .el-dialog__body {
  padding: 10px 20px;
}

</style>