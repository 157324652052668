<template>
  <div class="add-client-address-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>{{isClient ? 'Client Address' : 'Claimant Address'}}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <form>
            <div class="modal-body div-responsive">
              <div class="box-body">
                <div class="box-row">
                  <div class="box-col box-col-left">Address 1</div>
                  <div class="box-col box-col-right">
                    <input id="address-line1" autocomplete="address-line1"  name="address-line1" class="padding-left-10" maxlength="100" type="text" v-model="addressInfo.address1" placeholder="Address 1" tabindex="1"/>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Address 2</div>
                  <div class="box-col box-col-right">
                    <input id="address-line2" autocomplete="address-line2"  name="address-line2" class="padding-left-10" maxlength="100" type="text" v-model="addressInfo.address2" placeholder="Address 2" tabindex="2" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">City</div>
                  <div class="box-col box-col-right">
                    <input id="address-level2" autocomplete="address-level2"  name="address-level2" class="padding-left-10" type="text" v-model="addressInfo.city" placeholder="City" tabindex="3"/>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">State</div>
                  <div class="box-col box-col-right select-state">
                    <!-- <div v-if="!clickState" @click="handleState">
                      <input type="text" class="padding-left-10" disabled v-model="addressInfo.state" placeholder="Click to choose the State" tabindex="4"/>
                    </div> -->
                    <select name="state" class="textbox" v-model="addressInfo.state" tabindex="4">
                      <option hidden value="">Please choose the State</option>
                      <option v-for="(state, index) in states" :value="state.value" :key="index">{{ state.text }}</option>
                    </select>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Postcode</div>
                  <div class="box-col box-col-right">
                    <input id="postcode" autocomplete="postal-code"  name="postcode" class="padding-left-10" type="number" v-model="addressInfo.postcode" placeholder="Postcode" tabindex="5"/>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Country</div>
                  <div class="box-col box-col-right">
                    <input id="country" autocomplete="country"  name="country" class="padding-left-10" type="text" disabled v-model="addressInfo.country" placeholder="Country" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <button style="min-width: 120px;
    min-height: 39px;
    border-radius: 5px;
    text-transform: capitalize;
    background-color: #525252; color: white" @click="clickSave" dark>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import CitySuggestion from '@/components/CitySuggestion'

  export default {
    name: 'AddClientAddressModal',
    props: ['typrProps', 'clientProps'],
    components: {
      CitySuggestion,
    },
    data() {
      return {
        loadModal: false,
        hasError: false,
        clickState: false,
        singleCase: this.$parent.singleCase,
        listCities: [],
        cities: [],
        citySuggestion: CitySuggestion,
        states: utilities.getStates(),
        addressInfo: utilities.initAddress(),
        isConsentForm: false,
        loaded: false,
      }
    },

    computed: {
      validateNewEmail() {
        if (this.singleCase.claimantEmail == '' || !utilities.validateEmail(this.singleCase.claimantEmail)) {
          return false
        }
        return true
      },
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      async clickSave() {
        if (this.typrProps == 'fromBooking') {
          this.saveToBookingAddress()
        } else if (this.typrProps == 'fromclient') {
          this.saveToClientAddress()
        } else {
          this.saveToCaseAddress()
        }
        // this.handleModal()
      },

      fromConsentForm() {
        this.isConsentForm = true
      },

      setClientAddressDetails() {
        this.addressInfo.address1 = this.clientProps.addressLine1
        this.addressInfo.address2 = this.clientProps.addressLine2
        this.addressInfo.city = this.clientProps.city
        this.addressInfo.state = this.clientProps.state
        this.addressInfo.postcode = this.clientProps.postcode
      },

      async saveToClientAddress() {
        if (this.clientProps.clientId !== undefined && this.clientProps.clientId != '' && this.clientProps.clientId !== 'new') {
          var params = {
            ClientName: this.clientProps.clientName,
            BranchName: this.clientProps.branchName,
            PrepaymentRequired: this.clientProps.prepaymentRequired,
            PaymentDeferralOption: this.clientProps.paymentDeferralOption,
            RedHealthMayContactClaimants: this.clientProps.redHealthMayContactClaimants,
            BillingCategoryID: this.clientProps.BillingCategoryID,
            AddressLine1: this.addressInfo.address1,
            AddressLine2: this.addressInfo.address2,
            City: this.addressInfo.city,
            State: this.addressInfo.state,
            Postcode: this.addressInfo.postcode,
          }
          if (this.clientProps.clientId !== undefined && this.clientProps.clientId != '') params.ClientID = this.clientProps.clientId
          if (params.AddressLine1 !== '' || params.AddressLine2 !== '') {
            var geoLoc = await utilities.getGeoLocation(params.AddressLine1 + ',' + params.AddressLine2 + ',' + params.City + ',' + params.State + ',' + params.Postcode)
            params.Latitude = geoLoc.lat
            params.Longitude = geoLoc.lng
            if (this.$parent.client.clientId !== undefined && this.$parent.client.clientId !== '') {
              var check = await utilities.putClient(params)
              if (check != false) {
                this.$parent.client.addressLine1 = this.addressInfo.address1
                this.$parent.client.addressLine2 = this.addressInfo.address2
                this.$parent.client.city = this.addressInfo.city
                this.$parent.client.state = this.addressInfo.state
                this.$parent.client.postcode = this.addressInfo.postcode
                this.successMessage = 'Client updated.'
                utilities.showAlert('.alert-success')
              }
            }
            this.handleModal()
          } else {
            {
              var check = await utilities.putClient(params)
              if (check != false) {
                this.$parent.client.addressLine1 = this.addressInfo.address1
                this.$parent.client.addressLine2 = this.addressInfo.address2
                this.$parent.client.city = this.addressInfo.city
                this.$parent.client.state = this.addressInfo.state
                this.$parent.client.postcode = this.addressInfo.postcode
                this.$parent.successMessage = 'Client updated.'
                utilities.showAlert('.alert-success')
              }
            }
            this.handleModal()
          }
        } else {
          this.$parent.client.addressLine1 = this.addressInfo.address1
          this.$parent.client.addressLine2 = this.addressInfo.address2
          this.$parent.client.city = this.addressInfo.city
          this.$parent.client.state = this.addressInfo.state
          this.$parent.client.postcode = this.addressInfo.postcode
          this.handleModal()
        }
        this.$parent.addressKey += 1
      },

      setCaseAddressDetails() {
        this.addressInfo.address1 = this.singleCase.claimantAddressLine1
        this.addressInfo.address2 = this.singleCase.claimantAddressLine2
        this.addressInfo.city = this.singleCase.claimantCity
        this.addressInfo.state = this.singleCase.claimantState
        this.addressInfo.postcode = this.singleCase.claimantPostcode
      },

      saveToCaseAddress() {
        this.singleCase.claimantAddressLine1 = this.addressInfo.address1
        this.singleCase.claimantAddressLine2 = this.addressInfo.address2
        this.singleCase.claimantCity = this.addressInfo.city
        this.singleCase.claimantState = this.addressInfo.state
        this.singleCase.claimantPostcode = this.addressInfo.postcode
        this.singleCase.claimantFullAddress =
          utilities.convertNullToEmpty(this.singleCase.claimantAddressLine1) +
          ' ' +
          utilities.convertNullToEmpty(this.singleCase.claimantAddressLine2) +
          ' ' +
          utilities.convertNullToEmpty(this.singleCase.claimantCity) +
          ' ' +
          utilities.convertNullToEmpty(this.singleCase.claimantState) +
          ' ' +
          utilities.convertNullToEmpty(this.singleCase.claimantPostcode) +
          ' Australia'

        this.$parent.singleCase = this.singleCase
        this.$parent.isEditAddress = true
        if (this.addressInfo.address1 == '' && this.addressInfo.address2 == '' && this.addressInfo.city == '' && this.addressInfo.state == '' && this.addressInfo.postcode == '') {
          this.$parent.isEditAddress = false
        }
        this.$parent.addressKey += 1
        if (this.isConsentForm) {
          this.$parent.tapUpdate()
        }
        this.handleModal()
      },

      setBookingAddressDetails() {
        this.addressInfo.address1 = this.booking.claimantAddressLine1
        this.addressInfo.address2 = this.booking.claimantAddressLine2
        this.addressInfo.city = this.booking.claimantCity
        this.addressInfo.state = this.booking.claimantState
        this.addressInfo.postcode = this.booking.claimantPostcode
      },

      saveToBookingAddress() {
        this.booking.claimantAddressLine1 = this.addressInfo.address1
        this.booking.claimantAddressLine2 = this.addressInfo.address2
        this.booking.claimantCity = this.addressInfo.city
        this.booking.claimantState = this.addressInfo.state
        this.booking.claimantPostcode = this.addressInfo.postcode
        this.booking.claimantFullAddress =
          utilities.convertNullToEmpty(this.booking.claimantAddressLine1) +
          ' ' +
          utilities.convertNullToEmpty(this.booking.claimantAddressLine2) +
          ' ' +
          utilities.convertNullToEmpty(this.booking.claimantCity) +
          ' ' +
          utilities.convertNullToEmpty(this.booking.claimantState) +
          ' ' +
          utilities.convertNullToEmpty(this.booking.claimantPostcode) +
          ' Australia'

        this.$parent.booking = this.booking
        this.$parent.addressKey += 1
        this.$parent.isEditAddress = true
        if (this.addressInfo.address1 == '' && this.addressInfo.address2 == '' && this.addressInfo.city == '' && this.addressInfo.postcode == '') {
          this.$parent.isEditAddress = false
        }
        this.handleModal()
      },

      handleState() {
        this.clickState = true
      },
    },

    beforeMount() {
    },

    mounted() {
      setTimeout(() => {
        $('.vue-suggestion input.city-suggestion').each((index, element) => {
          $(element).focusout(() => {
            this.$refs['citySuggestion' + index][0].searchText = ''
          })
        })
      }, 500)
    },

    updated() {
      if (!this.loaded) {
        if (this.typrProps == 'fromclient') {
          this.setClientAddressDetails()
        }
        this.loaded = true
      }
    },
  }
</script>
