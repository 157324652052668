/* eslint-disable */
<template>
  <div class="specialist-booking-detail">
    <SpecialistNavigation indexProps="2"/>
    <div class="top-panel">
      <Header/>
      <div v-if="booking != undefined" class="header-title-section">
        <div class="header-title"><h1>Booking ID {{ booking.id }}</h1></div>
        <div class="header-sub-title" v-if="booking.bookingDateTime">Date Time:
          {{ formatDate(booking.bookingDateTime, "hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss") }} -
          {{ formatDate(booking.bookingEndTime, "hh:mm A", "HH:mm:ss") }} on
          {{ formatDate(booking.bookingDateTime, "DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss") }}.
        </div>
      </div>
    </div>
    <div class="panel">
      <SpecialistBookingMenu indexProps="8"/>
      <div class="content">
        <div v-if="booking != undefined">
          <div class="box-booking-details">
            <span
            ><div class="left-label">Claimant:</div>
              {{ booking.claimantFullName }}
            </span>
            <span
            ><div class="left-label">D.O.B.:</div>
              {{ formatDate(booking.dateOfBirth, "DD/MM/YYYY", "YYYY-MM-DD") }}</span
            >
            <span
            ><div class="left-label">Client:</div>
              {{ booking.clientName }}</span
            >
          </div>
          <div class="box">
            <div class="box-heading">
              <span> Booking Detail </span>
              <div class="right-element d-flex align-items-center h-100"
                   v-if="((booking.bookingStatus == 9 && booking.trackingStatus.indexOf('With Specialist') > -1 )|| (booking.bookingStatus == 5 && booking.trackingStatus.indexOf('Awaiting Dictation') > -1)) && !booking.specialistFinaliseBookingDate">
                <div v-if="booking.originaleBookingId" class="mr-5">
                  <router-link :to="{ name: 'specialist-booking-detail', query: { id: booking.originaleBookingId } }">
                    <v-btn dark>{{ booking.originaleBookingId }}</v-btn>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Booking Type</div>
                </div>
                <div class="box-col box-col-right">
                  {{ booking.bookingTypeName }}
                </div>
              </div>
              <div v-if="booking.bookingTypeId == 4" class="box-row">
                <div class="box-col box-col-left">Video Link URL</div>
                <div class="box-col box-col-right">
                  <a v-if="booking.videoLinkUrl != ''" :href="booking.videoLinkUrl" target="_blank"
                     class="text-decoration">Join Video Link</a>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Type of Claim</div>
                </div>
                <div class="box-col box-col-right">
                  {{ booking.claimTypeName }}
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Booking Location</div>
                </div>
                <div class="box-col box-col-right">
                  <input :disabled="true" type="text" :value="bookingLocation.fullLocation"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Claimant Attendance Status</div>
                </div>
                <div class="box-col box-col-right">
                  {{ status }}
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Claimant Contact Details</div>
                </div>
                <div class="box-col box-col-right">
                  <input :disabled="true" type="text" placeholder="" v-model="contactDetails"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Claimant Address</div>
                </div>
                <div class="box-col box-col-right">
                  <input :disabled="true" type="text" v-model="fullAddress"/>
                </div>
              </div>
              <div v-if="!booking.overPeriodofTime" class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Date of Accident/Event</div>
                </div>
                <div class="box-col box-col-right box-col-center">
                  {{ booking.accidentDate }}
                </div>
              </div>
              <div v-else class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Over a period of time</div>
                </div>
                <div class="box-col box-col-right">
                  <input :disabled="true" type="checkbox" id="checkbox" v-model="booking.overPeriodofTime"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Affected Body Areas</div>
                </div>
                <div class="box-col box-col-right col-center-content">
                  <vue-tags-input
                      v-model="bodyAreaTag"
                      :disabled="true"
                      placeholder=""
                      :tags="bodyAreaTags"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left left-label">Other Body Areas</div>
                <div class="box-col box-col-right box-col-center">
                  <vue-tags-input
                      :disabled="true"
                      v-model="otherBodyAreaTag"
                      :tags="otherBodyAreaTags"
                      placeholder=""/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  <div class="left-label">Conditions</div>
                </div>
                <div class="box-col box-col-right col-center-content">
                  <vue-tags-input
                      v-model="conditionTag"
                      :disabled="true"
                      placeholder=""
                      :tags="conditionTags"/>
                </div>
              </div>
            </div>
          </div>

          <el-dialog
              class="no-padding-dialog"
              :title="currentNotes ? 'View Note' : 'Add Note'"
              :visible.sync="noteDialogVisible"
              @close="closeModal"
              width="50%">
            <div>
              <div v-if="currentNotes">
                <span class="text-bold">{{currentNotes.Note}}</span>
                <div v-if="currentNotes.children">
                  <div v-for="item in currentNotes.children" :key="item.SpecialistRequestNoteID">
                    Reply: {{item.Note}}
                  </div>
                </div>
              </div>
              <div v-else>
                <textarea rows="4" type="text" @keydown.enter.prevent="submitForm" v-model="requestNotes" class="textbox" maxlength="1024" placeholder="Enter a note" />
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
    <v-btn class="white-button me-2" dark @click="closeModal">Cancel</v-btn>
    <v-btn dark @click="submitForm">Save</v-btn>
  </span>
          </el-dialog>
          <div class="box">
            <div class="box-heading tab">
              <div class="tab-heading" @click="openTab(0)">Files</div>
              <div class="tab-heading" @click="openTab(1)">Notes</div>
              <div class="right-element" v-if="!hideButton">
                <v-btn v-if="checkIfVisible" class="mr-2" dark @click="finaliseBooking()">Submit Report</v-btn>
                <v-btn class="add-icon" dark @click="openTimeModal()" v-if="tab === 'notes'">Add New</v-btn>
                <v-btn class="text-white add-icon" dark @click="openFilesBox" v-else>Add New</v-btn>
              </div>
            </div>
            <div class="box-body tab-content hidden-element">
              <div>
                <div>
                  <table class="table">
                    <thead>
                    <tr>
                      <th style="width:15%" class="long-text">
                        File Type
                        <img v-if="listParam.SortFlag === 1" class="sort-icon"
                             :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)"/>
                        <img v-else-if="listParam.SortFlag === 2" class="sort-icon"
                             :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                             @click="sortParam(1)"/>
                      </th>
                      <th style="width:30%" class="long-text">
                        File Name
                        <img v-if="listParam.SortFlag === 3" class="sort-icon"
                             :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(4)"/>
                        <img v-else-if="listParam.SortFlag === 4" class="sort-icon"
                             :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                             @click="sortParam(3)"/>
                      </th>
                      <th style="width:15%" class="long-text">
                        Uploaded By
                        <img v-if="listParam.SortFlag === 5" class="sort-icon"
                             :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)"/>
                        <img v-else-if="listParam.SortFlag === 6" class="sort-icon"
                             :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                             @click="sortParam(5)"/>
                      </th>
                      <th style="width:20%" class="long-text">
                        Date Uploaded
                        <img v-if="listParam.SortFlag === 7" class="sort-icon"
                             :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(8)"/>
                        <img v-else-if="listParam.SortFlag === 8" class="sort-icon"
                             :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                             @click="sortParam(7)"/>
                      </th>
                      <th style="width:10%" class="icon-col">
                        Status
                        <img v-if="listParam.SortFlag === 9" class="sort-icon"
                             :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(10)"/>
                        <img v-else-if="listParam.SortFlag === 10" class="sort-icon"
                             :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                             @click="sortParam(9)"/>
                      </th>
                      <th style="width: 10%" class="icon-col">
                        <div class="icon-group">&nbsp;</div>
                      </th>
                    </tr>
                    </thead>
                  </table>
                </div>
                <FileStatusModal ref="fileTypeModal"/>
                <div class="content-scroll-y" style="height: auto; max-height: 460px">
                  <table class="table remove-min-height">
                    <tbody :key="fileKey">
                    <tr
                        v-for="(file, index) in booking.bookingFiles"
                        :key="index"
                        class="py-0"
                    >
                      <th style="width: 15%" class="long-text">
                        <!-- <select v-model="file.fileTypeId" v-if="file.uploadedBy === user.id && file.fileStatus == 0" @change="updateFileType(file.bookingFileId)">
                          <option value="" hidden>Select File Type</option>
                          <option v-for="fileType in fileTypes" :value="fileType.id">{{ fileType.name }}</option>
                        </select> -->
                        <span>{{ file.FileTypeName }}</span>
                      </th>
                      <th style="width: 30%" class="long-text">
                        <div class="is-icon cursor-pointer" @click="getFileNew(file)">{{ file.FileName }}</div>
                      </th>
                      <th style="width: 15%" class="long-text">
                        {{ decodeURIComponent(file.UserName) }}
                      </th>
                      <th style="width:20%" class="long-text">
                        {{ formatDate(file.DateUploaded, 'DD/MM/YYYY HH:mm', "YYYY-MM-DD HH:mm:ss.ssssss") }}
                      </th>
                      <th style="width: 10%" class="long-text">
                        <select v-model="file.FileStatus" v-if="file.UploadedBy === user.id && file.FileStatus == 0"
                                @change="finalizeFile(file.BookingFileID, index)">
                          <option v-for="status in fileStatus" :value="status.id">
                            {{ status.name }}
                          </option>
                          <!-- <option value="2">Complete</option> -->
                        </select>
                        <span style="padding-left: 12px" v-else>{{
                            file.FileStatus == 2 ? 'Complete' : getFileStatusNameById(file.FileStatus)
                          }}</span>
                      </th>
                      <th style="width: 10%; vertical-align: middle" class="icon-col">
                        <div v-if="file.UserRoleID == user.type && file.FileStatus == 0" class="icon-group"
                             style="display: flex; justify-content: center">
                          <img src="@/assets/images/delete_icon.svg"
                               @click="deleteFile(file.BookingFileID, index, file.UploadedBy)"/>
                        </div>
                      </th>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="box-col" v-if="!hideButton">
                <div class="file-upload-area border-gray-300 mt-5 mb-5" @dragover="dragover" @dragleave="dragleave"
                     @drop="drop">
                  <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandle][]"
                      id="assetsFieldHandle"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      @change="onChange"
                      ref="file"
                      accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV"/>
                  <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                    <div>Drop files in this area or <span style="text-decoration: underline"
                                                          class="is-icon">click here</span> to upload files.
                    </div>
                  </label>
                  <div class="block cursor-pointer d-flex justify-content-center">Once you have uploaded your file or files please click the Submit Report button</div>
                </div>
              </div>
            </div>
            <div class="box-body tab-content hidden-element">
              <div class="box-row d-flex flex-column" v-for="(note, index) in notesList" :key="index" :style="{'text-decoration': note.Deleted ? 'line-through' : 'none'}" >
                <div class="d-flex align-content-center w-100 ">
                  <div class="box-col box-col-left is-icon" >
                    {{ decodeURIComponent(note.UserName) }}
                  </div>
                  <div class="box-col box-col-left">{{ formatDate(note.NoteDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss") }}</div>
                  <div style="max-width: 60%" class="box-col box-col-right long-text" data-toggle="tooltip" data-placement="right" :title="note.Note">
                    {{ decodeURIComponent(note.Note) }}
                  </div>
                  <div style="width: 200px" class="d-flex align-content-center justify-content-between">
                    <div>
                      <i v-if="note.children" class="el-icon-s-comment" style="font-size: 26px; margin-right: 40px; margin-top: 20px; cursor: pointer" @click="openTimeModal(note)"/>
                    </div>
                    <div v-if="note.UserID == user.id" class="mr-1" style="font-size: 20px" @click="deleteNote(note.SpecialistRequestNoteID, index)">
                      <img src="@/assets/images/delete_icon.svg" />
                    </div>
                  </div>
                </div>
                <div class="pl-4 d-flex align-center w-100 font-weight-bold" v-for="item in note.children" :key="item.SpecialistRequestNoteID">
                  <div class="box-col box-col-left is-icon" >
                    {{ decodeURIComponent(item.UserName) }}
                  </div>
                  <div class="box-col box-col-left">{{ formatDate(item.NoteDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss") }}</div>
                  <div style="max-width: 60%" class="box-col box-col-right long-text" data-toggle="tooltip" data-placement="right" :title="item.Note">
                    {{ decodeURIComponent(item.Note) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-warning confirmation hidden-element">
            <a class="close" title="close" @click="tapButtonConfirmation(false)">×</a>
            <b>Warning:</b> Are you sure? Your changes will take effect immediately.
            <div class="icon-group center-element">
              <v-btn class="alert-button" @click="tapButtonConfirmation(true)">Yes</v-btn>
              <v-btn class="alert-button" @click="tapButtonConfirmation(false)">No</v-btn>
            </div>
          </div>

          <div class="box">
            <div class="box-heading col-space-between">
              <div class="box-row">Additional Billing Time requests</div>
              <div class="col-space-between" v-if="!hideButton">
                <v-btn class="add-icon" dark @click="openServiceTimeModal">Add New</v-btn>
                <AddSpecialistServiceTimeModal ref="addSpecialistServiceTimeModal" :bookingProps="booking"/>
              </div>
            </div>
            <div class="box-body">
              <div>
                <table class="table">
                  <tbody>
                  <tr v-for="(bookingHour, index) in booking.bookingHours" v-if="bookingHour.status < 3">
                    <td style="width: 20%" class="long-text">
                      <div class="left-label">Additional Hours:</div>
                      {{ bookingHour.numberOfHours }}
                    </td>
                    <td style="width: 40%" class="long-text">
                      {{ bookingHour.service }}
                    </td>
                    <td style="width: 20%" class="long-text">
                      {{ additionalTimeStatus(bookingHour.status) }}
                    </td>
                    <td style="width: 20%" class="long-text">
                      <el-tooltip :content="bookingHour.reason">
                        <div style="max-width: 100%" class="text-overflow-hide">
                          {{ bookingHour.reason }}
                        </div>
                      </el-tooltip>
                    </td>
                  </tr>
                  </tbody>
                  <div v-if="booking.bookingHours.length == 0" style="margin: 15px;">
                    No additional time requested
                  </div>
                </table>
              </div>
            </div>
          </div>

          <div class="alert alert-warning" v-if="booking.callClientAfterAppointment"><b>Please note:</b> Client has
            requested a call after this appointment.
          </div>
        </div>
        <div v-else>This booking not found.</div>
      </div>
    </div>
    <Chat/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import SpecialistNavigation from '@/components/SpecialistNavigation'
import SpecialistBookingMenu from '@/components/SpecialistBookingMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import AddSpecialistServiceTimeModal from '@/components/AddSpecialistServiceTimeModal'
import FileStatusModal from '@/components/FileStatusModal'
import moment from "moment";

export default {
  name: 'SpecialistBookingDetail',
  components: {
    FileStatusModal,
    SpecialistNavigation,
    SpecialistBookingMenu,
    Header,
    Chat,
    AddSpecialistServiceTimeModal,
  },
  data() {
    return {
      listParam: {
        SortFlag: 7,
      },
      notesList: [],
      locations: [],
      fileStatusName: '',
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      booking: utilities.initBooking(),
      fileStatus: utilities.getFileStatus(),
      curStatus: 3,
      status: '',
      filelist: [],
      test: -1,
      placeholderText: '',
      fullAddress: '',
      contactDetails: '',
      confirmServiceProvided: false,
      deleteId: -1,
      deleteIndex: -1,
      bookingLocation: {},
      fileTypes: [],
      fileTypeId: 0,
      bodyAreaTag: '',
      bodyAreaTags: [],
      bodyAreasAutocomplete: [],
      otherBodyAreaTags: [],
      otherBodyAreaTag: '',
      noteDialogVisible: false,
      requestNotes: '',
      conditionTag: '',
      conditionTags: [],
      conditionsAutocomplete: [],
      currentNotes: null,
      uploadedFiles: [],
      fileKey: 1,
      users: [],
      tab: 'notes',
      hideButton: false,
    }
  },

  computed: {
    checkIfVisible() {
      if(this.booking.bookingFiles.length === 0) {
        return false
      } else {
        let filter = this.booking.bookingFiles.filter(item => item.UploadedBy === this.user.id)
        return filter.length > 0
      }
    }
  },
  methods: {

    getCaseOwnerName() {
      if (this.booking == undefined || this.booking.bookingId == '') {
        return ''
      }

      if (this.booking.clientContacts.length > 0) {
        return this.booking.clientContacts[0].clientContactFullName
      }
    },
    openFileTypeModal() {
      this.uploadFile()
    },
    sortParam(param = 7) {
      this.listParam.SortFlag = param
      switch (param) {
        case 1:
          this.booking.bookingFiles.sort((a, b) => {
            return (b.FileTypeName >= a.FileTypeName) ? 1 : -1
          })
          break;
        case 2:
          this.booking.bookingFiles.sort((a, b) => {
            return (a.FileTypeName >= b.FileTypeName) ? 1 : -1
          })
          break;
        case 3:
          this.booking.bookingFiles.sort((a, b) => {
            return (b.FileName >= a.FileName) ? 1 : -1
          })
          break;
        case 4:
          this.booking.bookingFiles.sort((a, b) => {
            return (a.FileName >= b.FileName) ? 1 : -1
          })
          break;
        case 5:
          this.booking.bookingFiles.sort((a, b) => {
            return (b.UserName >= a.UserName) ? 1 : -1
          })
          break;
        case 6:
          this.booking.bookingFiles.sort((a, b) => {
            return (a.UserName >= b.UserName) ? 1 : -1
          })
          break;
        case 7:
          this.booking.bookingFiles.sort((a, b) => {
            if (moment(a.DateUploaded).isAfter(moment(b.DateUploaded))) {
              return -1
            }
            return 1
          })
          break;
        case 8:
          this.booking.bookingFiles.sort((a, b) => {
            if (moment(a.DateUploaded).isBefore(moment(b.DateUploaded))) {
              return -1
            }
            return 1
          })
          break;
        case 9:
          this.booking.bookingFiles.sort((a, b) => {
            return b.FileStatus - a.FileStatus
          })
          break;
        case 10:
          this.booking.bookingFiles.sort((a, b) => {
            return a.FileStatus - b.FileStatus
          })
          break;
      }
    },
    additionalTimeStatus(status) {
      if (status == 1) {
        return 'Approved'
      } else if (status == 2) {
        return 'Rejected'
      } else {
        return 'Awaiting Approval'
      }
    },

    setBookingNoteAndAction() {
    },

    getFileStatusNameById(id) {
      if (id == 2) {
        return 'Complete'
      } else if (id == 3) {
        return 'For Review'
      }
      return this.fileStatus.find((x) => x.id == id).name
    },

    openTab(index) {
      utilities.openTab(index)
      this.tab = index === 1 ? 'notes' : 'files'
      // Ticket #4619 - Hide note section from specialist
      // if (index == 0) this.tab = 'notes'
      // else this.tab = 'files'
    },

    clearFile() {
      $("#assetsFieldHandle").val(null)
    },

    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },

    openAddNoteModal(e, note) {
      e.preventDefault()
      e.stopPropagation()
      if (note != undefined && note.id != '') {
        this.$refs.addNoteModal.editCurrentNote(note)
      } else {
        this.$refs.addNoteModal.addNewNote()
      }
      this.$refs.addNoteModal.handleModal()
    },

    addServiceTime() {
      this.booking.bookingHours.push(utilities.initBookingHour())
    },

    setBookingData() {
      this.status = this.booking.claimantConfirmed != '' ? 'Confirmed' : 'Not Confirmed'

      if (this.booking.bodyAreas.length > 0) {
        this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(this.booking.bodyAreas, "", "removeCross")
      }

      if (this.booking.bodyAreaOther.length > 0) {
        var items = this.booking.bodyAreaOther.split(',');
        this.otherBodyAreaTags = utilities.getAutoCompleteOtherBodyAreaTags(items)
      }

      if (this.booking.personConditions.length > 0) {
        this.conditionTags = utilities.getAutoCompleteTags(this.booking.personConditions, "", "removeCross")
      }

      // this.fullAddress = this.booking.claimantAddressLine1 + ", " + (this.booking.claimantAddressLine2.length != '' ? this.booking.claimantAddressLine2.length + ", " : "") + this.booking.claimantCity + " " + this.booking.claimantState + " " + this.booking.claimantCountry + " " + this.booking.claimantPostcode
      var claimant = {
        addressLine1: this.booking.claimantAddressLine1,
        addressLine2: this.booking.claimantAddressLine2,
        city: this.booking.claimantCity,
        state: this.booking.claimantState,
        postcode: this.booking.claimantPostcode,
      }
      this.fullAddress = utilities.getFullAddress(claimant)
      let mobile = this.booking.claimantMobile != '' ? 'M: ' + this.booking.claimantMobile : ''
      let phone1 = this.booking.claimantPhone1 != '' ? ' P1: ' + this.booking.claimantPhone1 : ''
      let phone2 = this.booking.claimantPhone2 != '' ? ' P2: ' + this.booking.claimantPhone2 : ''
      let email = this.booking.claimantEmail != '' ? ' E: ' + this.booking.claimantEmail : ''
      this.contactDetails = mobile + phone1 + phone2 + email
    },

    openTimeModal(note) {
      console.log(note)
      if(note) {
        this.currentNotes = note
      } else {
        this.currentNotes = null
      }
      this.noteDialogVisible = true
      console.log(this.currentNotes)
      this.$forceUpdate()
    },
    closeModal() {
      this.requestNotes = ''
      this.noteDialogVisible = false
      this.currentNotes = null
    },
    async getSpecialistNotes() {
      let bookingId = this.$route.query != undefined && this.$route.query.id != undefined ? this.$route.query.id : ''
      const result = await utilities.getSpecialistNote({PageNum:1, PageSize: 10, BookingID: bookingId})
      let arr = result.filter(item => !item.RequestID)
      result.forEach(item => {
        if(item.RequestID) {
          let obj = arr.find(a => a.SpecialistRequestNoteID === item.RequestID)
          if(obj) {
            if(!obj.children) {
              obj.children = [item]
            } else {
              obj.children.push(item)
            }
          }
        }
      })
      this.notesList = arr
    },
    async submitForm() {
      const param = {
        BookingID: this.booking.id,
        NoteDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        Note: this.requestNotes,
        Category: 'Request'
      }
      this.$store.dispatch('loading/setLoadComponent', true)
      const result = await utilities.postSpecialistNote(param)
      if(result) {
        this.closeModal()
        await this.getSpecialistNotes()
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    setTestAndOpenModal(e, test) {
      e.preventDefault()
      e.stopPropagation()
      this.test = test
      this.$refs.addSpecialistBookingTestModal.handleModal()
    },

    openServiceTimeModal(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs.addSpecialistServiceTimeModal.handleModal()
    },

    openTimeModalForEdit(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs.addSpecialistBookingTestEditModal.handleModal()
    },

    formatDate(date, newFormat = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(date, newFormat, currentFormat)
    },

    getUploadedByName(id) {
      var user = this.users.find((x) => x != undefined && x.id === id)
      return user === undefined ? '' : user.name
    },

    callReposrtUploadEmailAPI(fileName) {
      var params = {
        BookingID: this.booking.id,
        BookingTypeName: this.booking.bookingTypeName,
        CurrentDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        SpecialistFullName: this.booking.specialistFullName,
        ClientFullName: '',
        IsClient: 0,
        FileName: fileName,
        FileStatusName: this.fileStatusName,
        FileTypeName: "Report",
        IsSpecialist: 1
      }
      utilities.postReportUploadEmail(params)
    },

    getFileNew(file) {
      const params = {
        fn: file.FileName,
        id: this.booking.id,
        VersionId: file.VersionId,
        filetype: "bf",
      }
      utilities.getDownloadFileUrlNew(params).then(res => {
        let a = document.createElement('a');
        let fileName = file.fileName
        a.target = '_blank'
        a.download = fileName;
        a.href = res
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(res);
        document.body.removeChild(a);
      })
    },

    async uploadFile() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.filelist = [...this.$refs.file.files]
      var allFileNames = ''
      if (this.filelist.length > 0) {
        await Promise.all(this.filelist.map(async (x) => {
          if (this.booking.id != '') {
            try {
              var fileName = x.name
              if (allFileNames.length == 0) {
                allFileNames = fileName
              } else {
                allFileNames = allFileNames + ', ' + fileName
              }
              const result = await utilities.postPreSign({
                ft: x.type ? x.type : 'application/octet-stream',
                fn: 'Specialist File – ' + fileName,
                id: this.booking.id,
                FileStatus: this.curStatus,
                FileTypeID: this.fileTypes.find((x) => x.name == 'Report').id,
              })
              let postData = new FormData()
              for (let key in result.fields) {
                postData.append(key, result.fields[key]);
              }
              postData.append('file', x)
              const r = await utilities.postImage(result.url, postData)
              if (r) {
                await utilities.postFileRecord([{
                      BookingID: this.booking.id,
                      DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
                      FileName: 'Specialist File – ' + x.name,
                      FileStatus: this.curStatus,
                      FileTypeID: this.fileTypes.find((x) => x.name == 'Report').id,
                      MimeType: x.type ? x.type : 'application/octet-stream',
                      UploadedBy: this.user.id,
                      UploadedByName: this.user.name
                    }]
                )
              }
              if (this.filelist.length > 0) {
                this.booking.bookingFiles = await utilities.getBookingFileNew({
                  bookingID: this.booking.id,
                  PageNum: 1,
                  PageSize: 999
                })
                this.sortParam(this.listParam.SortFlag)
                this.fileKey += 1
              }
            } catch (error) {
              utilities.showErrorMessage(error.response.data?.message || error.response.data || error)
            }
          }
        }))
      }

      this.$store.dispatch('loading/setLoadComponent', false)
    },
    async onChange() {
      this.openFileTypeModal()
    },

    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains('bg-green-300')) {
      //   event.currentTarget.classList.remove('bg-gray-100')
      //   event.currentTarget.classList.add('bg-green-300')
      // }
      event.currentTarget.classList.add('dark-border-hover')
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100')
      // event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },

    async tapButtonConfirmation(states) {
      utilities.hideAlert('.confirmation')
      if (states) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          id: this.deleteId,
          IsMainEdit: 0,
        }
        params.BookingDateTime = this.booking.bookingDateTime
        params.SpecialistName = this.booking.specialistFullName
        params.CaseOwnerName = this.booking.clientContacts[0].clientContactFirstName
        params.ClientName = this.booking.clientName + ' - ' + this.booking.branchName
        params.BookingTypeName = this.booking.bookingTypeName
        params.LocationName = this.getLocationName(this.booking.locationId)
        params.IsAdmin = true
        params.BookingID = this.booking.id
        params.NoteDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
        var result = await utilities.deleteBookingNoteById(params)
        if (result) {
          var temp = _.cloneDeep(this.booking.bookingNotes)
          this.booking.bookingNotes.splice(this.deleteIndex, 1)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      }
    },
    getLocationName(id) {
      if (id) {
        var value = this.locations.find((x) => x.id == id)
        if (value) {
          return value.shortLocation
        }
      }
    },

    async deleteNote(id, index) {
      this.$confirm({
        content: 'Are you sure you want to delete this request ?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          const param = {
            BookingID: this.booking.id,
            SpecialistRequestNoteID: id,
          }
          await utilities.deleteRequestNoteById(param)
          this.$store.dispatch('loading/setLoadComponent', false)
          this.notesList.splice(index, 1)
        }
      })
    },
    async finaliseBooking() {
      this.$msgbox({
        title: 'Warning',
        message: 'Have you uploaded your dictation or amended draft and any additional required files? By confirming, the QA team will be notified, and they will progress your report.',
        confirmButtonText: 'Yes, I have finished this stage of the report',
        cancelButtonText: 'No',
        showCancelButton: true,
        showClose: true
      }).then(async () => {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          BookingID: this.booking.id,
        }

        var callback = async (response) => {
          if (response) {
            const noteObj = {
              Note: `Booking Update: I have uploaded my report, and it is ready for the QA team to action`,
              NoteDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
              UserID: this.user.id,
              Username: this.user.name,
              BookingID: this.booking.id,
              BookingDateTime: this.booking.bookingDateTime,
              SpecialistName: this.booking.specialistFullName,
              CaseOwnerName: this.getCaseOwnerName(),
              ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
              BookingTypeName: this.booking.bookingTypeName,
              InternalOnly: 1,
              IsMainEdit: 0,
              IsAdmin: true
            }
            await utilities.postBookingNote(noteObj)
            this.$notify({
              dangerouslyUseHTMLString: true,
              message: '<div class="alert-success">' +
                  '            <b>Success:</b> The QA Team will now progress your report !' +
                  '          </div>',
            });
            this.$router.push('/specialist-progress-bookings')
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }

        await utilities.finalizeBooking(params, callback)
      }).catch(() => {

      });
    },

    async deleteFile(fileId, index) {
      var file = this.booking.bookingFiles[index]
      this.$confirm({
        content: 'Are you sure you want to delete this file?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          var params = {
            userId: this.user.id,
            userName: this.user.name,
            id: fileId,
          }
          var params2 = {
            BookingID: this.booking.id,
            FileName: file.FileName,
            BookingDateTime: (this.booking.bookingDateTime !== "" && this.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
            SpecialistName: this.booking.specialistFullName,
            CaseOwnerName: this.getCaseOwnerName(),
            ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
            BookingTypeName: this.booking.bookingTypeName,
            LocationName: this.getLocationName(this.booking.locationId),
            CurrentDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
            UserID: this.user.id,
            Username: this.user.name,
            IsDeleted: 1
          }
          var result = await utilities.deleteBookingFileById(params, params2)
          if (result) {
            this.booking.bookingFiles.splice(index, 1)
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    async updateFileType(bookingFileId) {
      this.$store.dispatch('loading/setLoadComponent', true)
      var params = {
        BookingFileID: bookingFileId,
        FileTypeID: this.booking.bookingFiles.find((x) => x.bookingFileId === bookingFileId).fileTypeId,
        UserID: this.user.id,
        Username: this.user.name,
      }
      await utilities.putBookingFileDetails(params)
      this.sortParam(this.listParam.SortFlag)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    async finalizeFile(bookingFileId, index) {
      var file = this.booking.bookingFiles[index]
      this.$store.dispatch('loading/setLoadComponent', true)
      var params = {
        BookingFileID: bookingFileId,
        FileStatus: file.fileStatus,
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        FileStatusName: this.fileStatus.find((x) => x.id == file.fileStatus).name,
        FileName: file.fileName,
        BookingDateTime: this.booking.bookingDateTime,
        SpecialistName: this.booking.specialistFullName,
        CaseOwnerName: this.booking.clientContacts[0].clientContactFirstName,
        ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
        BookingTypeName: this.booking.bookingTypeName,
        LocationName: this.bookingLocation.fullLocation,
        CurrentDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        IsSpecialist: true,
      }
      await utilities.putBookingFileDetails(params)
      this.sortParam(this.listParam.SortFlag)
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async loadData(bookingId) {
      this.getSpecialistNotes()
      const apiArr = [utilities.getBookingById(bookingId), utilities.getLocations(), utilities.getBookingFileNew({
        bookingID: bookingId,
        PageNum: 1,
        PageSize: 999
      }), utilities.getFileTypes()]
      const result = await Promise.all(apiArr)

      this.booking = result[0]
      this.locations = result[1]
      this.booking.bookingFiles = result[2]

      if (this.booking != undefined) {
        if (this.booking.specialistId != this.user.specialistId) {
          this.$router.push({path: '/login'})
          return
        }


        this.hideButton = false
        if (this.booking.specialistConfirmedHours || this.booking.bookingStatus >= 10) {
          this.hideButton = true
        }
        this.sortParam(this.listParam.SortFlag)
        var bookingLocation = await utilities.getLocationByLocationId(this.booking.locationId)
        if (bookingLocation != undefined) this.bookingLocation = bookingLocation
        this.setBookingData()
      }
      //this.fileTypes = await utilities.getFileTypes()
      var types = result[3]
      types.map((x) => {
        if (x.name != 'Travel Itinerary') {
          this.fileTypes.push(x)
        }
      })
    },
  },
  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    $('body>.tooltip').remove()
    var bookingId = this.$route.query != undefined && this.$route.query.id != undefined ? this.$route.query.id : ''
    await this.loadData(bookingId)
    this.openTab(0)
    utilities.initTooltip()
    this.$store.dispatch('loading/setLoadComponent', false)
  },
  mounted() {
  },
}
</script>
