/* eslint-disable */
<template>
  <div class="bookings">
    <Navigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title">
          <h1 v-if="this.$route.query.caseId == undefined || this.$route.query.caseId == '' ">Bookings</h1>
          <h1 v-else>Bookings for Case  {{caseReference}}</h1>
        </div>
        <div class="header-sub-title">{{ title }}</div>
      </div>
    </div>
    <div class="panel">
      <ClientBookingMenu indexProps="0" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
<!--            <div>-->
<!--              <el-checkbox v-model="showDefault" size="large" @change="changeFilter">Show appointments created in the past 7days</el-checkbox>-->
<!--            </div>-->
            <div class="icon-group right-float-element">
              <el-input style="max-width: 115px" class="mr-1" @input="search()" clearable placeholder="Booking ID" v-model="listParam.BookingID"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Claimant Name" v-model="listParam.ClaimantName"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Specialist Name" v-model="listParam.SpecialistName"/>
              <el-select @change="selectPage(1)" style="max-width: 160px" placeholder="Booking Status"  class="mr-1" v-model="listParam.Status" filterable  clearable>
                <el-option v-for="item in bookingStatuses" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
              </el-select>
              <el-date-picker
                  style="width: 260px"
                  v-model="dateValue"
                  type="daterange"
                  start-placeholder="Booking Date Start"
                  popper-class="date-range-picker-popper"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  @change="changeDates"
                  end-placeholder="Booking Date End"
                  clearable
              />
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'id', order: 'descending'}" stripe @sort-change="sortParam" :data="bookings" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    width="100"
                    prop="id"
                    label="ID"
                    sortable="custom"
                    show-overflow-tooltip
                    fixed="left"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    sortable="custom"
                    show-overflow-tooltip
                    label="Claimant"
                    prop="claimantFullName"
                >
                </el-table-column>
                <el-table-column
                    v-if="user.type >= 2"
                    width="150"
                    show-overflow-tooltip
                    label="Client Contact"
                    prop="contactFullName"
                >
                </el-table-column>
                <el-table-column
                    width="140"
                    show-overflow-tooltip
                    prop="dateCreated"
                    sortable="custom"
                    label="Create Date"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.dateCreated, "DD/MM/YYYY")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="160"
                    show-overflow-tooltip
                    prop="booingDate"
                    sortable="custom"
                    label="Booking Date"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="120"
                    show-overflow-tooltip
                    prop="booingDate"
                    label="Booking Time"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="190"
                    show-overflow-tooltip
                    prop="booingDate"
                    label="Booking Claimant Time"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.claimantBookingDateTime, "HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="specialistFullName"
                    label="Specialist"
                    sortable="custom"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="claimTypeName"
                    label="Type of Claim"
                    sortable="custom"
                >
                </el-table-column>
                <el-table-column
                    width="250"
                    show-overflow-tooltip
                    sortable="custom"
                    prop="bookingTypeName"
                    label="Booking Type"
                >
                </el-table-column>
                <el-table-column
                    width="160"
                    sortable="custom"
                    show-overflow-tooltip
                    prop="bookingStatusName"
                    label="Booking Status"
                >
                </el-table-column>
                <el-table-column
                    width="200"
                    prop="custom"
                    label="Paperwork Status"
                    show-overflow-tooltip
                >
                  <template slot="header">
                    <div class="d-flex align-items-center">
                      <span>Paperwork Status</span>
                      <img v-if="listParam.SortFlag !== 22 && listParam.SortFlag !== 24 && listParam.SortFlag !== 26 && listParam.SortFlag !== 28" id="22" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4.svg"
                      />
                      <img v-if="listParam.SortFlag === 22" id="24" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-1.svg"
                      />
                      <img v-if="listParam.SortFlag === 24" id="26" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-2.svg"
                      />
                      <img v-if="listParam.SortFlag === 26" id="28" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-3.svg"
                      />
                      <img v-if="listParam.SortFlag === 28" id="0" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-4.svg"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="d-flex align-items-center">
                      <el-tooltip  effect="dark" content="LOI Received" placement="bottom">
                        <img
                            class="is-icon"
                            src="@/assets/images/lol-received.svg"
                            :class="isDate(scope.row.lolReceived) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip  effect="dark" content="Brief Received" placement="bottom">
                        <img
                            class="ml-4 is-icon"
                            src="@/assets/images/brief-received.svg"
                            :class="isDate(scope.row.briefReceived) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip  effect="dark" content="Additional Paperwork Received" placement="bottom">
                        <img
                            class="ml-4 is-icon"
                            src="@/assets/images/additional-paperwork-received.svg"
                            title="Additional Paperwork Received"
                            :class="isDate(scope.row.additionalPaperworkReceived) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip  effect="dark" content="Paperwork Processed" placement="bottom">
                        <img
                            class="ml-4 is-icon"
                            src="@/assets/images/paperwork-processed.svg"
                            :class="isDate(scope.row.paperworkProcessed) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";
import Navigation from '@/components/Navigation'
import ClientBookingMenu from '@/components/ClientBookingMenu'
import Header from "@/components/Header";
import { debounce } from 'lodash';

export default {
  name: "AdminBookings",
  props: ["searchOptionProps"],
  components: {
    Navigation,
    ClientBookingMenu,
    Header,
  },
  data() {
    return {
      showDefault: true,
      dateValue: '',
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),

      filterOption: {
        startDate: "",
        endDate: "",
      },

      caseReference: '',
      totalCount: 0,
      byClient: false,
      byContact: false,
      byClaimant: false,
      byTypeOfClaim: false,
      bySpecialist: false,
      byDate: false,
      byBookingStatus: false,
      byPaperworkStatus: false,

      claimTypes: [],
      bookingStatuses: [],
      paperworkStatuses: [],
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
        FromDate: '',
        ToDate: '',
        ClientName: '',
        SpecialistName: '',
        ContactName: '',
        ClaimantName: '',
        Status: null,
        BookingID: null,
      },
      bookingMenuIndex: 0,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      title: "All bookings, listed in booking date order.",
    };
  },
  computed: {},
  methods: {
    customSort(sort) {
      this.listParam.SortFlag = Number(sort.target.id)
      this.selectPage(1)
    },
    RowClick(row) {
      this.openBooking(row?.id)
    },
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    changeFilter(e) {
      this.listParam = {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
        FromDate: '',
        ToDate: '',
        ClientName: '',
        SpecialistName: '',
        ContactName: '',
        ClaimantName: '',
        Status: null,
        BookingID: null,
      }
      // if(e) {
      //   this.listParam.CreateFromDate = moment().subtract(7, 'd').format('YYYY-MM-DD')
      //   this.listParam.CreateToDate = moment().format('YYYY-MM-DD')
      //
      // } else {
      //   this.listParam.CreateFromDate = ''
      //   this.listParam.CreateToDate = ''
      // }
      this.selectPage(1)
    },
    getFullPhoneEmail(phone, email) {
      let fullPhoneEmail = phone + (phone !== '' && email !== '' ? " / " : "") + email
      return fullPhoneEmail
    },
    isDate(date) {
      return moment(date, "YYYY-MM-DD").isValid()
    },

    sortParam( v ) {
      if(v.prop === 'id' && v.order === "ascending") {
        this.listParam.SortFlag = 19
      } else if (v.prop === 'id' && v.order === "descending") {
        this.listParam.SortFlag = 20
      } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.listParam.SortFlag = 6
      } else if(v.prop === 'dateCreated' && v.order === "ascending") {
        this.listParam.SortFlag = 15
      } else if (v.prop === 'dateCreated' && v.order === "descending") {
        this.listParam.SortFlag = 16
      } else if(v.prop === 'booingDate' && v.order === "ascending") {
        this.listParam.SortFlag = 19
      } else if (v.prop === 'booingDate' && v.order === "descending") {
        this.listParam.SortFlag = 20
      } else if(v.prop === 'clientName' && v.order === "ascending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'clientName' && v.order === "descending") {
        this.listParam.SortFlag = 2
      } else if(v.prop === 'contactFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'contactFullName' && v.order === "descending") {
        this.listParam.SortFlag = 4
      } else if(v.prop === 'specialistFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 9
      } else if (v.prop === 'specialistFullName' && v.order === "descending") {
        this.listParam.SortFlag = 10
      } else if(v.prop === 'claimTypeName' && v.order === "ascending") {
        this.listParam.SortFlag = 7
      } else if (v.prop === 'claimTypeName' && v.order === "descending") {
        this.listParam.SortFlag = 8
      } else if(v.prop === 'bookingStatusName' && v.order === "ascending") {
        this.listParam.SortFlag = 17
      } else if (v.prop === 'bookingStatusName' && v.order === "descending") {
        this.listParam.SortFlag = 18
      } else if(v.prop === 'paperwork' && v.order === "ascending") {
        this.listParam.SortFlag = 13
      } else if (v.prop === 'paperwork' && v.order === "descending") {
        this.listParam.SortFlag = 14
      } else if (v.prop === 'bookingTypeName' && v.order === "ascending") {
        this.listParam.SortFlag = 29
      }else if (v.prop === 'bookingTypeName' && v.order === "descending") {
        this.listParam.SortFlag = 30
      }
      this.selectPage(1)
    },

    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },

    changeDates(v) {
      if(v) {
        this.listParam.FromDate = v[0]
        this.listParam.ToDate = v[1]
      } else {
        this.listParam.FromDate = null
        this.listParam.ToDate = null
      }
      this.selectPage(1)
    },
    filter() {
      if (this.filterOption.startDate == "" && this.filterOption.endDate == "") {
        this.title = "All bookings, listed in due date order."
        return false
      }
      this.title = "Results of a search for bookings between " + moment(this.filterOption.startDate).format("DD/MM/YYYY") + " and " + moment(this.filterOption.endDate).format("DD/MM/YYYY")
      var bookings = this.allBookings.filter((x) => moment(x.bookingDateTime) >= this.filterOption.startDate && moment(x.bookingDateTime) <= this.filterOption.endDate)
      this.paging(bookings)
    },
    formatDate(date, dateToFormat, newFormat)  {
      return utilities.formatDate(date, dateToFormat, newFormat);
    },
    openSearchBooking() {
      this.$refs.navigation.$refs.globalSearch.openSearchBooking();
    },
    openBooking(id) {
      this.$router.push({ name: "edit-booking", query: { id: id } });
    },
    paging(bookings) {
      utilities.disposeTooltip();
      if (bookings.length == 0 || bookings.length <= this.limit) {
        this.bookings = bookings;
        $(".pagination").hide();
      } else {
        this.pagination = utilities.loadPagination(bookings, this.limit);
        this.bookings = this.pagination.list[this.pagination.current];
        $(".pagination").show();
      }
      utilities.initTooltip();
      utilities.hoverTooltip();
    },

    async searchBooking(searchOption) {
      var params = {
        Firstname: searchOption.claimantFirstname,
        Surname: searchOption.claimantSurname,
        UserName: searchOption.userName,
        ClientName: searchOption.clientName,
        CaseRef: searchOption.bookingReference,
        BookingID: searchOption.bookingId,
        FromDate: searchOption.fromDate ? moment(searchOption.fromDate).format('YYYY-MM-DD') : '',
        ToDate: searchOption.toDate ? moment(searchOption.toDate).format('YYYY-MM-DD'): '',
        BA: searchOption.bodyAreas,
        PC: searchOption.conditions,
        Status: searchOption.status,
        UserID: this.user.id,
        tmp:1,
        SpecialistID: searchOption.specialistId
      };
      this.title =
          "Results of a search for " +
          Object.values(searchOption)
              .filter((x) => x != undefined && x != "")
              .map((x) => "'" + x + "'")
              .join(" and ") +
          ".";

      this.bookings = [];
      var bookings = await utilities.getSearchBookings(params);
      if (bookings) {
        bookings.sort((a, b) => {
          return a.dueDate == ""
              ? 1
              : moment(a.dueDate).isBefore(moment(b.dueDate))
                  ? 1
                  : -1;
        });
        this.allBookings = _.cloneDeep(bookings);
        this.paging(bookings);
      }

    },
    async loadBookings() {
      this.$store.dispatch("loading/setLoadComponent", true);
      if (this.$route.query.caseId !== undefined && this.$route.query.caseId !== "") {
        this.listParam.CaseID = this.$route.query.caseId
        let singleCase = await utilities.getCaseByCaseId(this.$route.query.caseId)
        this.caseReference = singleCase.caseReference
      } else if (this.$route.query.clientId !== undefined && this.$route.query.clientId !== "") {
        this.listParam.ClientID = this.$route.query.clientId
      }
      let {bookings, total} = await utilities.getAllBookingsNew(this.listParam);
      this.bookings = _.cloneDeep(bookings);
      this.totalCount = total
      console.log(this.listParam.SortFlag)
      this.$store.dispatch("loading/setLoadComponent", false);
    },
    loadData() {
      this.claimTypes =  utilities.getClaimTypes();
      this.bookingStatuses = utilities.getBookingStatuses();
      this.paperworkStatuses = utilities.getPaperworkStatuses();
    },
  },
  async beforeMount() {
    $("body>.tooltip").remove();
    this.$store.dispatch("loading/setLoadComponent", true);
    this.loadData();
    if (this.searchOptionProps != undefined) {
      this.title =
          "Results of a search for " +
          Object.values(this.searchOptionProps)
              .filter((x) => x != undefined && x != "")
              .map((x) => "'" + x + "'")
              .join(" and ") +
          ".";
      await this.searchBooking(this.searchOptionProps);
    } else {
      await this.loadBookings();
    }
    this.$store.dispatch("loading/setLoadComponent", false);
  },
  mounted() {
    $(".pagination").hide();
  },
};
</script>
