/* eslint-disable */
<template>
  <div class="specialist-schedule-pdf">
    <div :key="dataKey" class="pdf-panel p-1 m-1">
      <div class="pdf-content p-2 m-1" v-for="(bookings, index) in Object.values(bookingsByDate)">
        <div class="pdf-header">
          <div class="row">
            <div class="col-4">
              <strong>{{ formatDate(bookings[0].bookingDetails.dateTime) }}</strong> <br />
              {{ specialist.fullName }} <br />
              {{ bookings[0].location.name }} <br />
              {{ bookings[0].location.address1 }} <br v-if="bookings[0].location.address1 !== ''" />
              {{ bookings[0].location.address2 }} <br v-if="bookings[0].location.address2 !== ''" />
              {{ bookings[0].location.cityName }}
              {{ bookings[0].location.state }}
              {{ bookings[0].location.postcode }}
            </div>
            <div class="col-4">
              <span class="increased-line-height"><strong>Red Health: </strong>Dictation@redhealth.com.au<br /></span>
              PO BOX 10362 Adelaide Street,<br />
              Brisbane QLD 4000<br />
              <span class="increased-line-height"><strong>Phone:</strong> 07 3221 5154<br /></span>
            </div>
            <div class="col-4">
              <img class="img-fluid" src="@/assets/images/red_health_logo.svg" />
            </div>
          </div>
        </div>
        <div class="content-scroll-x mt-4">
          <div v-for="(booking, index) in bookings" class="avoid-breaking-page">
            <div>
              <table class="table">
                <thead>
                  <tr class="border-bottom">
                    <th colspan="3" class="border-right">
                      {{ formatTime(booking.bookingDetails.dateTime) }} - #{{ booking.bookingDetails.id }} -
                      {{ getBookingTypeName(booking.bookingDetails.typeId) }}
                    </th>
                    <th colspan="5">Office use only</th>
                  </tr>
                  <tr>
                    <th width="12.5%">Claimant</th>
                    <th width="12.5%">Referred By</th>
                    <th width="12.5%">Contact</th>
                    <th width="12.5%">Tests</th>
                    <th width="12.5%">Finalised</th>
                    <th width="12.5%">Report Sent</th>
                    <th width="12.5%">Client Invoiced</th>
                    <th width="12.5%">Spec. Invoiced</th>
                  </tr>
                </thead>
                <tbody>
                <tr class="border-bottom">
                  <td width="12.5%">{{ booking.casesDetails.claimantFullName }}<br /><strong>Ph: </strong>{{ booking.casesDetails.claimantPhone1 }}</td>
                  <td width="12.5%">{{ booking.clientDetails.clientName }}</td>
                  <td width="12.5%">{{ booking.clientContactDetails.fullName }}</td>
                  <td width="12.5%">{{ booking.bookingDetails.bookingTests.map((x) => x.testName).join(', ') }}</td>
                  <td width="12.5%">{{ booking.bookingDetails.status >= 11 && booking.bookingDetails.status <= 15 ? 'Yes' : 'No' }}</td>
                  <td width="12.5%">-</td>
                  <td width="12.5%">-</td>
                  <td width="12.5%">-</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="index < Object.values(bookingsByDate).length - 1" class="html2pdf__page-break" />
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'SpecialistSchedulePdf',
    components: {},
    data() {
      return {
        specialist: utilities.initSpecialist(),
        bookings: [],
        bookingsByDate: {},

        bookingTypes: [],
        dataKey: 0,
      }
    },
    computed: {},
    methods: {
      formatDate(dateToFormat) {
        return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
      },
      formatTime(timeToFormat) {
        return moment(timeToFormat).format('hh:mm A')
      },
      getBookingTypeName(typeId) {
        return this.bookingTypes.find((x) => x.id === typeId).name
      },

      async loadData(date = "") {
        this.bookingsByDate = {}
        if(this.specialist.id != "") {
          this.specialist = await utilities.getSpecialistById(this.specialist.id)
          var key = ""
          var startDate = ""
          var endDate = ""
          if(date == "") {
            startDate = moment().format('YYYY-MM-DD')
            endDate = moment().add(3, 'M').format('YYYY-MM-DD')
          } else {
            startDate = date
            endDate = date
          }

          var bookingsParams = {
            specialistId: this.specialist.id,
            startDate: startDate,
            endDate: endDate,
          }
          this.bookings = await utilities.getSpecialistBookings(bookingsParams)
          this.bookings = this.bookings.filter(x => Number(x.bookingDetails.status) >= 2 && Number(x.bookingDetails.status) <= 15 && Number(x.bookingDetails.status) != 6)
          this.bookings.sort((a, b) => {
              return moment(new Date(a.bookingDetails.dateTime)).isAfter(moment(new Date(b.bookingDetails.dateTime))) ? 1 : -1
          }).map(x => {
            if(x.bookingDetails.locationId != '') {
              key = x.bookingDetails.locationId + "-" + moment(x.bookingDetails.dateTime).format('YYYY-MM-DD')
              if(this.bookingsByDate[key] == undefined) this.bookingsByDate[key] = []
              this.bookingsByDate[key].push(x)
            }
          })

          this.dataKey += 1
        }
      },
    },
    async beforeMount() {
      this.bookingTypes = await utilities.getBookingTypes()
    },
    mounted() {},
  }
</script>
