/* eslint-disable */
<template>
  <div class="upcoming-bookings">
    <Navigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Upcoming Bookings</h1></div>
        <div class="header-sub-title">Manage bookings and upload documentation for an upcoming appointment.</div>
      </div>
    </div>
    <div class="panel">
      <ClientBookingMenu indexProps="1" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input style="max-width: 115px" class="mr-1" @input="search()" clearable placeholder="Booking ID" v-model="listParam.BookingID"/>
            <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Claimant Name" v-model="listParam.ClaimantName"/>
            <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Specialist Name" v-model="listParam.SpecialistName"/>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-2 pl-0">
              <div class="border-image ml-0">
                <div class="border-image-number">{{totalCount}}</div>
                <div class="border-image-text">Upcoming</div>
              </div>
            </div>
            <div class="col">&nbsp;</div>
            <div class="col">&nbsp;</div>
          </div>
          <div class="table-responsive">
            <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'booingDate', order: 'ascending'}" stripe @sort-change="sortParam" :data="upcomingBookings" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
              <el-table-column
                  width="100"
                  prop="id"
                  label="ID"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="120"
                  prop="claimantFullName"
                  label="Claimant"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="150"
                  prop="booingDate"
                  label="Appointment"
                  sortable="custom"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  show-overflow-tooltip
                  prop="specialistFullName"
                  label="Specialist"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="locationName"
                  width="250"
                  label="Location"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  width="250"
                  prop="bookingTypeName"
                  label="Booking Type"
              >
              </el-table-column>

              <el-table-column
                  v-if="user.type >= 2"
                  width="150"
                  show-overflow-tooltip
                  prop="contactFullName"
                  label="Client Contact"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  width="100"
                  show-overflow-tooltip
                  prop="bookingStatusName"
                  label="Status"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  width="150"
                  show-overflow-tooltip
                  prop="paperworkDue"
                  label="Paperwork Due"
                  sortable="custom"
              >
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.paperworkDue, 'DD/MM/YYYY') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  show-overflow-tooltip
                  prop="status"
                  label="Claimant Confirmed?"
              >
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.claimantConfirmed, 'DD/MM/YYYY') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="totalCount > listParam.PageSize" class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ listParam.PageNum }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import ClientBookingMenu from '@/components/ClientBookingMenu'
import Header from '@/components/Header'
import { debounce } from 'lodash';
import Chat from '@/components/Chat'

export default {
  name: 'UpcomingBookings',
  components: {
    Navigation,
    ClientBookingMenu,
    Header,
    Chat,
  },
  data() {
    return {
      limit: 12,
      pagination: utilities.initPagination(),
      byDate: false,
      byPaperwork: false,
      byPaperworkDue: false,
      maxDate: '',
      upcomingBookings: [],
      allUpcomingBookings: [],
      totalCount: 0,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 15,
        FromDate: window.moment().format('YYYY-MM-DD'),
        SpecialistName: '',
        ClaimantName: '',
        BookingID: null,
        Status: '3,4,7,9,10',
      },
      paperworkStatuses: utilities.getPaperworkStatuses(),
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      searchBookingId: '',
      searchClaimantName: '',
      searchSpecialist: '',
    }
  },

  computed: {},

  methods: {
    sortParam( v ) {
      if(v.prop === 'id' && v.order === "ascending") {
        this.listParam.SortFlag = 15
      } else if (v.prop === 'id' && v.order === "descending") {
        this.listParam.SortFlag = 16
      } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.listParam.SortFlag = 6
      } else if(v.prop === 'dateCreated' && v.order === "ascending") {
        this.listParam.SortFlag = 15
      } else if (v.prop === 'dateCreated' && v.order === "descending") {
        this.listParam.SortFlag = 16
      } else if(v.prop === 'booingDate' && v.order === "ascending") {
        this.listParam.SortFlag = 19
      } else if (v.prop === 'booingDate' && v.order === "descending") {
        this.listParam.SortFlag = 20
      } else if(v.prop === 'clientName' && v.order === "ascending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'clientName' && v.order === "descending") {
        this.listParam.SortFlag = 2
      } else if(v.prop === 'contactFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'contactFullName' && v.order === "descending") {
        this.listParam.SortFlag = 4
      } else if(v.prop === 'specialistFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 9
      } else if (v.prop === 'specialistFullName' && v.order === "descending") {
        this.listParam.SortFlag = 10
      } else if(v.prop === 'claimTypeName' && v.order === "ascending") {
        this.listParam.SortFlag = 7
      } else if (v.prop === 'claimTypeName' && v.order === "descending") {
        this.listParam.SortFlag = 8
      } else if(v.prop === 'bookingStatusName' && v.order === "ascending") {
        this.listParam.SortFlag = 17
      } else if (v.prop === 'bookingStatusName' && v.order === "descending") {
        this.listParam.SortFlag = 18
      } else if(v.prop === 'paperworkDue' && v.order === "ascending") {
        this.listParam.SortFlag = 31
      } else if (v.prop === 'paperworkDue' && v.order === "descending") {
        this.listParam.SortFlag = 32
      } else if (v.prop === 'bookingTypeName' && v.order === "ascending") {
        this.listParam.SortFlag = 29
      }else if (v.prop === 'bookingTypeName' && v.order === "descending") {
        this.listParam.SortFlag = 30
      } else if (v.prop === 'locationName' && v.order === "ascending") {
        this.listParam.SortFlag = 33
      } else if (v.prop === 'locationName' && v.order === "descending") {
        this.listParam.SortFlag = 34
      }
      this.selectPage(1)
    },
    RowClick(row) {
      this.editBooking(row?.id)
    },
    getBookingStatus(id) {
      return utilities.getBookingStatus(id)
    },
    search: debounce(function () {
      this.selectPage(1)
    }, 800),

    setOriginalData() {
      this.upcomingBookings = _.cloneDeep(this.allUpcomingBookings)
      this.setUpcomingPagination()
    },

    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date.length > 0 && date != '0000-00-00') {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ""
    },

    sortByDateTime(date) {
      // this.upcomingBookings.sort((a, b) => {
      //   if(this.byDate) {  // ASC
      //     return a.bookingDetails.dateTime > b.bookingDetails.dateTime ? 1 : -1
      //   } else { // DESC
      //     return a.bookingDetails.dateTime < b.bookingDetails.dateTime ? 1 : -1
      //   }
      // })
      // this.byDate = !this.byDate

      this.allUpcomingBookings.sort((a, b) => {
        if(this.byDate) {  // ASC
          return a.bookingDetails.dateTime > b.bookingDetails.dateTime ? 1 : -1
        } else { // DESC
          return a.bookingDetails.dateTime < b.bookingDetails.dateTime ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings;
      this.setUpcomingPagination()
      this.byDate = !this.byDate
    },

    sortByPaperwork(status) {
      this.allUpcomingBookings.sort((a, b) => {
        if(!this.byPaperwork) {  // ASC
          return Number(a.bookingDetails.paperworkStatus) > Number(b.bookingDetails.paperworkStatus) ? 1 : -1
        } else { // DESC
          return Number(a.paperworkStatus) < Number(b.paperworkStatus) ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings
      this.setUpcomingPagination()
      this.byPaperwork = !this.byPaperwork
    },

    sortByPaperworkDue(status) {
      // this.upcomingBookings.sort((a, b) => {
      //   if(this.byDate) {  // ASC
      //     return a.bookingDetails.paperworkStatus > b.bookingDetails.paperworkStatus ? 1 : -1
      //   } else { // DESC
      //     return a.paperworkStatus < b.paperworkStatus ? 1 : -1
      //   }
      // })
      // this.byDate = !this.byDate
      var date1 = null
      var date2 = null
      this.allUpcomingBookings.sort((a, b) => {
        if(a.bookingDetails.paperworkDue != '') {
          date1 = moment(a.bookingDetails.paperworkDue)
        } else {
          date1 = moment(this.maxDate)
        }
        if(b.bookingDetails.paperworkDue != '') {
          date2 = moment(b.bookingDetails.paperworkDue)
        } else {
          date2 = moment(this.maxDate)
        }

        if (this.byPaperworkDue) {
          // ASC
          return date1.isAfter(date2) ? 1 : -1
        } else {
          // DESC
          return date1.isBefore(date2) ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings
      this.setUpcomingPagination()
      this.byPaperworkDue = !this.byPaperworkDue
    },

    getPaperworkStatusById(id) {
      if (id) {
        return this.paperworkStatuses.find((x) => x.id == id).name
      }
      return ""
    },

    editBooking(bookingId) {
      this.$router.push({path:'/edit-booking', query:{id:bookingId}}).catch((err) => {})
    },

    setUpcomingPagination() {
      if(this.upcomingBookings.length > this.limit) {
        this.pagination = utilities.loadPagination(this.upcomingBookings, this.limit)
        this.upcomingBookings = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      } else {
        $(".pagination").hide()
      }
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      let {bookings, total} = await utilities.getAllBookingsNew(this.listParam);
      this.upcomingBookings = bookings
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },

  async beforeMount() {
    await this.loadData()
    this.$store.dispatch("loading/setLoadComponent", false)
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
}
</script>
