<template>
  <div class="manage-contact-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title mt-5">
              <h1>{{ clientName }} Contacts</h1>
            </div>
            <v-btn v-if="contacts.length == 0" class="add-icon mr-4 mt-5" dark @click="pushContact">Add New</v-btn>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div v-if="contacts.length === 0">No contacts added yet. Press "Add New"</div>
            <div class="accordion" id="contacts-accordion">
              <div class="card" v-for="(contact, index) in contacts" :key="index">
                <div class="card-header" :id="'contact' + index">
                  <h2 class="mb-0 d-flex flex-row justify-content-end w-100">
                    <button class="btn btn-link w-75 text-left" type="button" data-toggle="collapse" :data-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
                      {{ getContactDetailsFormatted(contact) }}
                    </button>
                    <button class="btn btn-link w-25 text-right" type="button" data-toggle="collapse" :data-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
                      <img src="@/assets/images/down_arrow_icon.svg" />
                    </button>
                  </h2>
                </div>
                <form autocomplete="off">
                  <div :id="'collapse' + index" class="collapse" :class="index == selectedContact ? 'show' : ''" aria-labelledby="contact" data-parent="#contacts-accordion">
                    <div class="card-body">
                      <div class="row" v-if="!contact.isNew && !contact.primaryContact && ($parent.user.type == 3 || $parent.user.type == 6)">
                        <div class="col-12 col-md-4">Active</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="checkbox" class="float-left row-item" v-model="contact.active" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">First Name</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" maxlength="20" v-model="contact.firstName" :class="hasError && !validateContactFirstName(index) ? 'is-invalid' : ''" placeholder="Enter First Name" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Middle Name</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" v-model="contact.middleName" placeholder="Enter Middle Name" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Last Name</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" v-model="contact.surname" maxlength="50" placeholder="Enter Last Name" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Salutation</div>
                        <div class="col-12 col-md-8">
                          <el-select :popper-append-to-body="false" class="w-50" v-model="contact.Salutation" filterable clearable placeholder="Select Salutation">
                            <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Land Line</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" v-model="contact.phoneNumber1" :class="hasError && !validatePhone1(index) ? 'is-invalid' : ''" placeholder="Enter Land Line" />
                          <div v-if="hasError && !validatePhone1(index)" class="text-danger">Land Line is invalid.</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Mobile</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" maxlength="12" v-model="contact.mobile" :class="hasError && !validateMobile(index) ? 'is-invalid' : ''" placeholder="Enter Mobile" />
                          <div v-if="hasError && !validateMobile(index)" class="text-danger">{{ errorMobileMessage }}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Work Phone</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" maxlength="12" v-model="contact.phoneNumber2" :class="hasError && !validatePhone2(index) ? 'is-invalid' : ''" placeholder="Enter Work Phone" />
                          <div v-if="hasError && !validatePhone2(index)" class="text-danger">Work Phone is invalid.</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Email Address</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" maxlength="100" v-model="contact.emailAddress" :class="hasError && !validateContactEmail(index) ? 'is-invalid' : ''" placeholder="Enter Email Address" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Notification Email Address</div>
                        <div class="col-12 col-md-8">
                          <input autocomplete="new-password" type="text" maxlength="100" v-model="contact.NotificationEmailAddress" :class="hasError && !validateNotificationEmail(index) ? 'is-invalid' : ''" placeholder="Enter Email Address" />
                          <div v-if="hasError && !validateNotificationEmail" class="text-danger">{{ errorEmailMessage }}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">Role</div>
                        <div class="col-12 col-md-8">
                          <select v-model="contact.userRoleId">
                            <option value="1">Client</option>
                            <option value="2">Branch Admin</option>
                          </select>
                          <!-- <input
                    type="text"
                    v-model="contact.role"
                    placeholder="Enter First Name"
                  /> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-4">User Login?</div>
                        <div class="col-12 col-md-8">
                          <input type="checkbox" class="float-left row-item" v-model="contact.canLogin" />
                          <!-- <v-btn v-if="contactcontact.canLogin && !contact.isNew" class="ml-10 col-center-content" style="background-color:#C4C4C4;color:#6686B6F" @click="reSendInvitation(index)"> Resend Invite</v-btn> -->
                        </div>
                      </div>
                      <div class="row" v-if="contacts.length > 1 && contact.active && (($parent.user.type == 3 || $parent.user.type == 6))">
                        <div class="col-12 col-md-4">Primary Contact?</div>
                        <div class="col-12 col-md-8">
                          <input type="checkbox" class="float-left row-item" :disabled="contact.primaryContact && index == 0" v-model="contact.primaryContact" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <br/>
            <div class="alert alert-success alert-success-resend hidden-element">
              <a class="close" title="close">×</a>
              <b>Success:</b> {{ successMessage }}
            </div>
            <div class="alert alert-danger hidden-element">
              <a class="close" title="close">×</a>
              <b>Error:</b> {{ errorMessage }}
            </div>

            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                  <v-btn dark @click="submitContacts">Save</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminManageContactModal',
    props: ['passedContactsProps'],
    data() {
      return {
        errorEmailMessage: '',
        salutationList: utilities.initSalutation(),
        loaded: false,
        contacts: this.passedContactsProps,
        numberOfContacts: 0,
        loadModal: false,
        selectedContact: 0,
        pushedFirst: false,
        hasError: false,
        allContacts: [],
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        errorMobileMessage: '',
      }
    },
    computed: {
      clientName() {
        return this.$parent.client.name
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      async reSendInvitation(index) {
        if (!this.validatePhone(index)) {
          this.hasError = true
          return
        }

        var item = this.contacts[index]
        var mobile = utilities.updateMobileCode(utilities.validatePhonenumber(item.mobile))
        if(mobile.charAt(0) == '0') {
          mobile = mobile.substring(1)
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          Name: item.fullName,
          EmailAddress: item.emailAddress,
          Mobile: mobile,
          UserRoleID: item.type,
        }
        var result = await utilities.postResendInvite(params)
        if (result) {
          this.successMessage = result
          utilities.showAlert(".manage-contact-modal .alert-success-resend")
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      setContacts(contacts){
        if (contacts.length > 0) {
          var sorted = []
          var temp = []
          sorted.push(contacts.find((x) => x.primaryContact))
          for (var i = 0; i < contacts.length; i++) {
            var item = contacts[i]
            if (!item.primaryContact) {
              temp.push(item)
            }
          }
          temp.sort((a, b) => {
            return a.fullName > b.fullName ? 1 : -1
          })
          this.contacts = sorted.concat(temp)
          this.allContacts = _.cloneDeep(this.contacts)
        }
      },
      getContactDetailsFormatted(contact) {
        if (contact) {
          var text =   contact.userRoleId == 2 ? '  - Branch Admin' : ''
          return contact.fullName + ' M: ' + contact.mobile + ' E: ' + contact.emailAddress + text
        }
        return ""
      },
      pushContact() {
        var isPrimary = this.contacts == undefined || this.contacts.length == 0 ? true : false
        this.contacts.push({
          canLogin: false,
          emailAddress: '',
          Salutation: '',
          NotificationEmailAddress: '',
          firstName: '',
          fullName: '',
          middleName: '',
          mobile: '',
          phoneNumber1: '',
          phoneNumber2: '',
          primaryContact: isPrimary,
          surname: '',
          isNew: true,
          userRoleId: 1,
          active: true
        })
        this.selectedContact = this.contacts.length - 1
        $('.card .collapse')
          .eq(this.selectedContact)
          .collapse('show')
      },

      validateContactFirstName(index) {
        if (this.contacts[index].firstName.trim() == '') {
          return false
        }
        return true
      },
      validateContactEmail(index) {
        if (this.contacts[index].emailAddress.trim() == '') {
          return false
        }
        return true
      },
      validateNotificationEmail(index) {
        if (this.contacts[index].NotificationEmailAddress.trim() === '') {
          return true
        } else if(this.contacts[index].NotificationEmailAddress.trim() !== '' && !utilities.validateEmail(this.contacts[index].NotificationEmailAddress)) {
          this.errorEmailMessage = "Email is invalid."
          return false
        }
        return true
      },
      validateMobile(index) {
        if(this.contacts[index].canLogin == 1 && this.contacts[index].mobile == '') {
          this.errorMobileMessage = "Mobile is required."
          return false
        } else if(this.contacts[index].mobile != '' && !utilities.validatePhoneAU(this.contacts[index].mobile)) {
          this.errorMobileMessage = "Please enter a valid mobile number in International format without spaces - i.e. +61400123456"
          return false
        }
        return true
      },
      validatePhone1(index) {
        if(this.contacts[index].phoneNumber1 != '' && !utilities.validateLandPhone(this.contacts[index].phoneNumber1)) {
          return false
        }
        return true
      },
      validatePhone2(index) {
        if(this.contacts[index].phoneNumber2 != '' && !utilities.validateLandPhone(this.contacts[index].phoneNumber2)) {
          return false
        }
        return true
      },
      validateContact() {
        for (var i = 0; i < this.contacts.length; i++) {
          //this.contacts != undefined && this.contacts.length > 0
          if (!this.validateContactFirstName(i) || !this.validateContactEmail(i) || !this.validateMobile(i) || !this.validatePhone1(i) || !this.validateNotificationEmail(i) || !this.validatePhone2(i)) {
            return false
          }
        }
        return true
      },
      async submitContacts(e) {
        e.preventDefault()
        e.stopPropagation()

        this.hasError = false
        if (!this.validateContact()) {
          utilities.showAlert('.manage-contact-modal .alert-danger')
          this.hasError = true
          return
        }

        if (this.contacts != undefined && this.contacts.length > 0) {
          var localContacts = []
          this.contacts.map((x, index) => {
            if (x.isNew || JSON.stringify(x) !== JSON.stringify(this.allContacts[index])) {
                var originalItem = []
                if (this.allContacts.length > 0) {
                  originalItem = this.allContacts[index]
                }
                x.fullName = x.firstName + ' ' + (x.middleName == '' ? '' : x.middleName + ' ') + x.surname
                var number = utilities.updateMobileCode(x.mobile)

                var localContact = {
                  Mobile: utilities.validatePhonenumber(number),
                  Surname: x.surname,
                  CanLogin: utilities.convertBoolToNum(x.canLogin),
                  FirstName: x.firstName,
                  MiddleName: x.middleName,
                  EmailAddress: x.emailAddress,
                  NotificationEmailAddress: x.NotificationEmailAddress,
                  Salutation: x.Salutation,
                  PhoneNumber1: utilities.validatePhonenumber(x.phoneNumber1),
                  PhoneNumber2: utilities.validatePhonenumber(x.phoneNumber2),
                  IsPrimaryContact: utilities.convertBoolToNum(x.primaryContact),
                  UserRoleID: x.userRoleId,
                  UserID: this.$parent.user.id,
                  Username: this.$parent.user.name,
                  Active: utilities.convertBoolToNum(x.active),
                }
                if (originalItem) {
                  if (x.canLogin && !originalItem.canLogin) {
                    localContact.Reactivate = 1
                  } else if (x.canLogin && originalItem.canLogin) {
                    localContact.Reactivate = 0
                  } else if (!x.canLogin && originalItem.canLogin) {
                    localContact.Deactivate = 1
                  }

                  if (x.emailAddress != originalItem.emailAddress) {
                    localContact.OldEmailAddress = originalItem.emailAddress
                  }
                }


                if (x.clientContactId !== undefined && x.clientContactId !== '') localContact.ClientContactID = x.clientContactId
                if (x.contactUserId !== undefined && x.contactUserId !== '') localContact.ContactUserID = x.contactUserId
                if (this.$parent.pageMode == 'edit') localContact.ClientID = this.$parent.client.id
                localContacts.push(localContact)
              }
              x.isNew = false
          })
          // clientToPut.ContactObject = localContacts
          var all = []
          var flag = 0
          /*
          //NOT USING CONTACT EDIT FOR THIS MODEL
          if (this.$parent.pageMode == 'edit') {
            this.$store.dispatch('loading/setLoadComponent', true)
            await Promise.all(localContacts.map(async (element) => {
              if (element.ClientContactID === undefined) {
                // This is post
                if (element.CanLogin == 1) {
                  // Separate API
                  all.push(utilities.postContactCanLogin(element))
                } else {
                  all.push(utilities.postContact(element))
                }
              } else {
                // This is put
                if (element.CanLogin == 1) {
                  all.push(utilities.putContactCanLogin(element))
                } else {
                  all.push(utilities.putContact(element))
                }
              }
              await Promise.all(all).then((response) => {
                response.map((x) => {
                  if (x == true) flag += 1
                })
              }).then(() => {
                var factorial = 1
                for (var i = 2; i <= all.length; i++) factorial = factorial + i
                this.$store.dispatch('loading/setLoadComponent', false)
                if (flag == factorial) {
                  this.allContacts =  _.cloneDeep(this.contacts)
                  this.successMessage = 'Contacts updated.'
                  utilities.showAlert('.manage-contact-modal .alert-success')
                  this.$parent.loadClient()
                }
              })
            }))
            setTimeout(() => {
              this.$store.dispatch('loading/setLoadComponent', false)
              this.handleModal()
            }, 2000)
          } else {
            this.handleModal()
          }
          */
          this.handleModal()
        } else {
          this.handleModal()
        }
      },
    },
    mounted() {},
    updated() {
      if (!this.loaded) {
        this.loaded = true
      }
      if (this.contacts === undefined && !this.pushedFirst) {
        this.pushContact()
      }
      if (this.contacts[0] != undefined && this.contacts[0].clientContactId === undefined) {
        this.pushedFirst = true
        $('.collapse').addClass('show')
      }
    },
  }
</script>
