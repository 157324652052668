/* eslint-disable */
<template>
  <div class="registration p-0">
    <div class="red-vertical"></div>
    <div class="login-container">
      <div class="login-left">
        <div class="logo">
          <router-link :to="{ path: '/', }"><img src="@/assets/images/red_health_logo.svg" /></router-link>
        </div>
        <v-form v-if="this.account.userName != ''" ref="form" class="login-form">
          <v-subheader>Email address</v-subheader>
          <v-text-field v-model="account.userName" disabled solo></v-text-field>

          <v-subheader>Password</v-subheader>
          <v-text-field
            v-model="account.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.validatePassword.required]"
            placeholder="Enter your Password"
            @click:append="() => (showPassword = !showPassword)"
            solo
            required></v-text-field>

          <v-subheader>Re-Password</v-subheader>
          <v-text-field
            v-model="account.rePassword"
            :type="showRePassword ? 'text' : 'password'"
            :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.validateRePassword.required, rules.validateRePassword.match]"
            placeholder="Re-Enter your new Password"
            @click:append="() => (showRePassword = !showRePassword)"
            solo
            required></v-text-field>
          <br /><br />

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="icon-group right-float-element">
            <router-link :to="{ name: 'login' }">
              <v-btn class="white-button" dark>Cancel</v-btn>
            </router-link>
            <v-btn class="red-button" dark @click="signUp">Sign Up</v-btn>
          </div>
        </v-form>
        <div v-else class="login-form" style="width: 80%">The link is expired. <router-link :to="{ name: 'login' }" class="text-decoration">Click here</router-link> to return.</div>
      </div>
      <div class="login-right">
        <div class="banner">
          <img src="@/assets/images/banner.svg" />
        </div>
        <Chat />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import auth from '@/assets/js/cognitoAuth'
  import utilities from '@/assets/js/utilities'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'

  export default {
    name: 'Registration',
    components: {
      Footer,
      Chat,
    },
    data() {
      return {
        successMessage: "Your registration with Red Health Online is complete. Please check the email.",
        errorMessage: "Please try after some time.",

        showPassword: false,
        showRePassword: false,

        account: {
          userName: '',
          password: '',
          rePassword: '',
          email: '',
        },

        rules: {
          validatePassword: {
            required: (value) => !!value || 'Password is required.',
          },
          validateRePassword: {
            required: (value) => !!value || 'Re-password is required.',
            match: (value) => !(value != this.account.password) || 'Re-password is incorrect.',
          },
        },

        users: [],
      }
    },
    methods: {
      signUp() {
        if (!this.$refs.form.validate()) {
          return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        var authenticationData = {
          Username: this.account.userName,
          Password: this.account.password,
          Email: this.account.userName,
          Phone: '+01234567890',
        }

        var successCallback = () => {
          this.$store.dispatch('loading/setLoadComponent', false)
          utilities.showAlert('.alert-success')
          // setTimeout(() => {
          //   this.$router.push({ name: "login" }).catch((err) => {})
          // }, 2000)
        }
        var failureCallback = () => {
          this.$store.dispatch('loading/setLoadComponent', false)
          utilities.showAlert('.alert-danger')
        }
        auth.signUp(authenticationData, successCallback, failureCallback)
      },

      async loadData(email) {
        const {users} = await utilities.getUsersNew({PageNum: 1, PageSize: 10, EmailAddress: email})
        this.users = users
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var email = this.$route.query != undefined && this.$route.query.email != undefined ? this.$route.query.email : ''
      await this.loadData(email)
      if(this.users.length > 0) {
        this.account.userName = email
      }
      console.log(this.account)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $('.registration form').on('keypress', (e) => {
        if (e.keyCode == 13) {
          this.signUp()
        }
      })
    },
  }
</script>
