export const URL_API =  process.env.VUE_APP_URL_API
export const URL_API_1 = process.env.VUE_APP_URL_API_1
export const URL_API_2 = process.env.VUE_APP_URL_API_2
export const URL_API_3 = process.env.VUE_APP_URL_API_3
export const URL_API_4 = process.env.VUE_APP_URL_API_4
export const URL_API_5 = process.env.VUE_APP_URL_API_5
export const URL_API_6 = process.env.VUE_APP_URL_API_6
export const URL_API_7 = process.env.VUE_APP_URL_API_7
export const URL_API_8 = process.env.VUE_APP_URL_API_8
export const URL_API_9 = process.env.VUE_APP_URL_API_9
export const URL_API_10 = process.env.VUE_APP_URL_API_10
export const SP_ZOHO_URL = process.env.VUE_APP_SP_ZOHO_URL
export const BK_ZOHO_URL = process.env.VUE_APP_BK_ZOHO_URL
export const CLIENT_ZOHO_URL = process.env.VUE_APP_CLIENT_ZOHO_URL
export const CASE_ZOHO_URL = process.env.VUE_APP_CASE_ZOHO_URL
export const GET_FILE_URL = process.env.VUE_APP_GET_FILE_URL
export const GET_FILE_URL_NEW = process.env.VUE_APP_GET_FILE_URL_NEW
export const S3_URL = process.env.VUE_APP_S3_FILE_URL
