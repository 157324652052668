<template>
  <div class="add-client-contact-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Claimant Contact Details</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <form autocomplete="off">
            <div class="modal-body div-responsive">
              <div class="box-body" >
                <div class="box-row">
                  <div class="box-col box-col-left">Mobile</div>
                  <div class="box-col box-col-right">
                    <input type="number" v-model="contacts.mobile" placeholder="Mobile" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-center box-col-left">Email Address</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="contacts.email" placeholder="Email Address" />
                    <div class="text-danger" v-if="hasError && !validateEmail">
                      Please enter a valid email address.
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-center box-col-left">Land line</div>
                  <div class="box-col box-col-right">
                    <input type="number" v-model="contacts.landline" placeholder="Land line" />
                    <div class="text-danger" v-if="hasError && !validatelandline">
                      Please enter a valid phone number.
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-center box-col-left">Work Number aa</div>
                  <div class="box-col box-col-right">
                    <input type="number" v-model="contacts.workNumber" placeholder="Work Number" />
                    <div class="text-danger" v-if="hasError && !validateworkNumber">
                      Please enter a valid phone number.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn
                        class="white-button"
                        @click="handleModal($event)"
                        dark>Cancel</v-btn>
                    <v-btn
                        @click="clickSave"
                        dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AdminManageContactDetailsModal",
  props: ["typrProps"],
  data() {
    return {
      loadModal: false,
      hasError: false,
      singleCase: utilities.initCase(),
      contacts: utilities.initContactDetails(),
      booking: utilities.initBooking(),
    }
  },

  computed: {
    validateNewEmail() {
      if(this.contacts.email != "" && !utilities.validateEmail(this.contacts.email)) {
        return false
      }
      return true
    },
    validateMobile() {
      if (!utilities.validatePhoneAU(this.contacts.mobile)) {
        return false
      }
      return true
    },
    validatelandline() {
      if (!utilities.validateLandPhone(this.contacts.landline.trim())) {
        return false
      }
      return true
    },
    validateworkNumber() {
      if (!utilities.validateLandPhone(this.contacts.workNumber)) {
        return false
      }
      return true
    },
  },

  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    async clickSave() {
      if (!this.validateNewEmail || !this.validateMobile || !this.validatelandline ||  !this.validateworkNumber) {
        this.hasError = true
        return
      }
      if (this.typrProps == "fromcases") {
        this.saveToCaseContact()

      } else if (this.typrProps == "fromBooking") {
        this.saveToBookingContact()
      }
      this.handleModal()
    },

    setCaseContactDetails() {
      this.contacts.landline = this.singleCase.claimantPhone1
      this.contacts.mobile = this.singleCase.claimantMobile
      this.contacts.workNumber = this.singleCase.claimantPhone2
      this.contacts.email = this.singleCase.claimantEmail
    },

    setBookingContactDetails() {
      this.contacts.landline = this.booking.claimantPhone1
      this.contacts.mobile = this.booking.claimantMobile
      this.contacts.workNumber = this.booking.claimantPhone2
      this.contacts.email = this.booking.claimantEmail
    },

    saveToCaseContact() {
      this.singleCase.claimantPhone1 = this.contacts.landline
      this.singleCase.claimantMobile = this.contacts.mobile
      this.singleCase.claimantPhone2 = this.contacts.workNumber
      this.singleCase.claimantEmail = this.contacts.email
      this.$parent.singleCase = this.singleCase
      this.$parent.contactKey += 1
    },

    saveToBookingContact() {
      this.booking.claimantPhone1 = this.contacts.landline
      this.booking.claimantMobile = this.contacts.mobile
      this.booking.claimantPhone2 = this.contacts.workNumber
      this.booking.claimantEmail = this.contacts.email
      this.$parent.booking = this.booking
      this.$parent.contactKey += 1
    },
  },
  beforeMount() {},
  mounted() {},
  updated() {},
}
</script>
