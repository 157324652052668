<template>
  <transition name="el-fade-in-linear">
  <div v-show="visible" class="alert alert-warning confirmation-dialog">
    <a class="close" style="cursor: pointer" title="close" @click="cancel">×</a>
    <b>Warning:</b> {{content}}
    <div class="icon-group">
      <v-btn :disabled="loading" class="btn btn-default" @click="confirm">Yes</v-btn>
      <v-btn :disabled="loading" class="btn btn-default" @click="cancel">No</v-btn>
    </div>
  </div>
  </transition>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    content: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'Warning'
    },
  }
}
</script>

<style scoped>
.confirmation-dialog {
  background-color: #fff3cd;
  border: 2px solid rgb(255, 128, 0);
  min-width: 350px;
}
.btn-default {
  background-color: transparent !important;
  border: 2px solid #ff8000;
  width: 80px;
  height: 30px;
  min-width: 120px;
  min-height: 39px;
  border-radius: 5px;
  margin: 0 10px;
  text-transform: capitalize;
  font-weight: 500;
  box-shadow: none;
  color: #333;
}
.icon-group {
  display: flex;
  justify-content: flex-end;
}
.close {
  margin: -5px -5px 0 10px;
}
</style>