<template>
  <div class="add-client-contact-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Claimant Contact Details</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <form autocomplete="off">
            <div class="modal-body div-responsive">
              <div class="box-body">
                <div class="box-row">
                  <div class="box-col box-col-left">Mobile</div>
                  <div class="box-col box-col-right">
                    <input type="tel" class="textbox w-100 mobile" maxlength="12" v-model="contacts.mobile" placeholder="Mobile" />
                    <div class="text-danger" v-if="hasError && !validateMobile">
                      Please enter a valid mobile number in International format without spaces - i.e. +61400123456
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-center box-col-left">Email Address</div>
                  <div class="box-col box-col-right">
                    <input autocomplete="off" class="textbox w-100" type="text" v-model="contacts.email" placeholder="Email Address" />
                    <div class="text-danger" v-if="hasError && !validateNewEmail">
                      Please enter a valid email address with no spaces.
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-center box-col-left">Land line</div>
                  <div class="box-col box-col-right">
                    <input class="textbox w-100" type="tel" v-model="contacts.landline" placeholder="Land line" />
                    <div class="text-danger" v-if="hasError && !validatelandline">
                      Please enter a valid phone number with no spaces.
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-center box-col-left">Work Number</div>
                  <div class="box-col box-col-right">
                    <input class="textbox w-100" type="tel" v-model="contacts.workNumber" placeholder="Work Number" />
                    <div class="text-danger" v-if="hasError && !validateworkNumber">
                      Please enter a valid phone number with no spaces.
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="clickSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddClientContactModal',
    props: ['typrProps'],
    data() {
      return {
        loadModal: false,
        hasError: false,
        singleCase: utilities.initCase(),
        contacts: utilities.initContactDetails(),
        booking: utilities.initBooking(),
        // loaded: false,
      }
    },

    computed: {
      validateNewEmail() {
        if (this.contacts.email == undefined || this.contacts.email == '') {
          return true
        }

        if (!utilities.validateEmail(this.contacts.email)) {
          return false
        }
        return true
      },
      validateMobile() {
        if (!utilities.validatePhoneAU(this.contacts.mobile)) {
          return false
        }
        return true
      },
      validatelandline() {
        if (!utilities.validateLandPhone(this.contacts.landline.trim())) {
          return false
        }
        return true
      },
      validateworkNumber() {
        if (!utilities.validateLandPhone(this.contacts.workNumber)) {
          return false
        }
        return true
      },
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          this.hasError = false
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      async clickSave() {
        this.hasError = false
        if (!this.validateNewEmail || !this.validateMobile || !this.validatelandline ||  !this.validateworkNumber) {
          this.hasError = true
          return
        }
        if (this.typrProps == 'fromcases') {
          this.saveToCaseContact()
        } else if (this.typrProps == 'fromBooking') {
          this.saveToBookingContact()
        } else if (this.typrProps == 'consentForm') {
          this.setToConsentFrom()
        }
        this.handleModal()
      },

      setToConsentFrom() {
        this.$parent.singleCase.claimantPhone1 = utilities.validatePhonenumber(this.contacts.landline)
        this.$parent.singleCase.claimantPhone2 = utilities.validatePhonenumber(this.contacts.workNumber)
        this.$parent.singleCase.claimantMobile = this.contacts.mobile
        this.$parent.singleCase.claimantEmail = this.contacts.email
        this.$parent.isEditContact = true
        if (this.contacts.landline == '' && this.contacts.workNumber == '' && this.contacts.mobile == '' && this.contacts.email == '') {
          this.$parent.isEditContact = false
        }
        this.$parent.contactKey += 1
        this.$parent.tapUpdate()
      },

      setCaseContactDetails() {
        this.singleCase = this.$parent.singleCase
        this.contacts.landline = this.singleCase.claimantPhone1
        this.contacts.mobile = this.singleCase.claimantMobile
        this.contacts.workNumber = this.singleCase.claimantPhone2
        this.contacts.email = this.singleCase.claimantEmail
      },

      setBookingContactDetails() {
        this.contacts.landline = this.booking.claimantPhone1
        this.contacts.mobile = this.booking.claimantMobile
        this.contacts.workNumber = this.booking.claimantPhone2
        this.contacts.email = this.booking.claimantEmail
      },

      saveToCaseContact() {

        if (this.$parent.singleCase.id != '') {

          this.$parent.singleCase.claimantPhone1 = utilities.validatePhonenumber(this.contacts.landline)
          this.$parent.singleCase.claimantPhone2 = utilities.validatePhonenumber(this.contacts.workNumber)
          this.$parent.singleCase.claimantMobile = this.contacts.mobile
          this.$parent.singleCase.claimantEmail = this.contacts.email
          this.$parent.isEditContact = true
          if (this.contacts.landline == '' && this.contacts.workNumber == '' && this.contacts.mobile == '' && this.contacts.email == '') {
            this.$parent.isEditContact = false
          }
          this.$parent.contactKey += 1
          // If cancel, don't save
        } else {
          this.$parent.singleCase.claimantPhone1 = utilities.validatePhonenumber(this.contacts.landline)
          this.$parent.singleCase.claimantPhone2 = utilities.validatePhonenumber(this.contacts.workNumber)
          this.$parent.singleCase.claimantMobile = this.contacts.mobile
          this.$parent.singleCase.claimantEmail = this.contacts.email
          this.$parent.isEditContact = true
          if (this.contacts.landline == '' && this.contacts.workNumber == '' && this.contacts.mobile == '' && this.contacts.email == '') {
            this.$parent.isEditContact = false
          }
          this.$parent.contactKey += 1
        }
      },

      saveToBookingContact() {
        this.booking.claimantPhone1 = utilities.validatePhonenumber(this.contacts.landline)
        this.booking.claimantPhone2 = utilities.validatePhonenumber(this.contacts.workNumber)
        this.booking.claimantMobile = this.contacts.mobile
        this.booking.claimantEmail = this.contacts.email
        this.$parent.booking = this.booking
        this.$parent.isEditContact = true
        if (this.contacts.landline == '' && this.contacts.workNumber == '' && this.contacts.mobile == '' && this.contacts.email == '') {
          this.$parent.isEditContact = false
        }
        this.$parent.contactKey += 1
      },
    },

    beforeMount() {
      // if(this.$parent.booking != undefined) this.booking = this.$parent.booking
    },
    mounted() {},
    updated() {
      // if (!this.loaded) {
      //   this.contacts.landline = this.booking.claimantPhone1
      //   this.contacts.mobile = this.booking.claimantMobile
      //   this.contacts.workNumber = this.booking.claimantPhone2
      //   this.contacts.email = this.booking.claimantEmail
      //   this.loaded = true
      // }
    },
  }
</script>
