/* eslint-disable */
<template>
  <div class="case-menu sub-menu">
    <ul>
      <li><router-link to="/cases">Cases</router-link></li>
      <!-- <li><router-link to="/search-specialist">Add Booking/case</router-link></li>
      <li><router-link to="/search-cases">Search Cases</router-link></li> -->
    </ul>
  </div>
</template>

<script>

export default {
  name: 'CaseMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
