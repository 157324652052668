<template>
  <div class="add-name-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Claimant Name</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <form autocomplete="off">
            <div class="modal-body div-responsive">
              <div class="box-body">
                <div class="box-row">
                  <div class="box-col box-col-left">First Name</div>
                  <div class="box-col box-col-right">
                    <input id="firstname" name="firstname" autocomplete="given-name" type="text" :class="hasError && !validateClaimantSurname ? 'is-invalid' : ''" v-model="nameObject.firstName" placeholder="First Name"/>
                    <div class="text-danger" v-if="hasError && !validateClaimantFirstname">
                      Please enter the correct claimant's name (do not contain ' " or `)
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Middle Name/s</div>
                  <div class="box-col box-col-right">
                    <input id="additional-name" name="additional-name" autocomplete="additional-name"  type="text" v-model="nameObject.middleName" placeholder="Middle Name/s"/>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Surname</div>
                  <div class="box-col box-col-right">
                    <input id="family-name" name="family-name" autocomplete="family-name"  type="text" :class="hasError && !validateClaimantSurname ? 'is-invalid' : ''" v-model="nameObject.surname" placeholder="Surname"/>
                    <div class="text-danger" v-if="hasError && !validateClaimantSurname">
                      Please enter the correct claimant's name (do not contain ' " or `)
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="clickSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddNameModal',
    props: ['typrProps', 'clientProps'],
    data() {
      return {
        nameObject: {
          firstName: "",
          middleName: "",
          surname: "",
        },
        loadModal: false,
        hasError: false,
        loaded: false,
        isNew: false,
        isEdit: false,
      }
    },

    computed: {
      validateNewEmail() {
        if (this.singleCase.claimantEmail == '' || !utilities.validateEmail(this.singleCase.claimantEmail)) {
          return false
        }
        return true
      },
      validateClaimantSurname() {
        if (this.nameObject.surname === '' || !utilities.validateNameText(this.nameObject.surname)) {
          return false
        }
        return true
      },
      validateClaimantFirstname() {
        if (this.nameObject.firstName === '' || !utilities.validateNameText(this.nameObject.firstName)) {
          return false
        }
        return true
      },
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      addName() {
        this.isEdit = false
      },

      editFullName(firstName, middleName, surname) {
        this.isEdit = true
        this.nameObject.firstName = firstName
        this.nameObject.middleName = middleName
        this.nameObject.surname = surname
      },

      saveFullName() {},

      async clickSave() {
        this.hasError = false
        if(!this.validateClaimantSurname || !this.validateClaimantFirstname) {
          this.hasError = true
          return
        }
        this.$parent.setName(this.nameObject)
        this.handleModal()
      },
    },
    beforeMount() {},
    mounted() {},
  }
</script>
