/* eslint-disable */
<template>
  <div class="settings">
    <AdminNavigation ref="navigation" indexProps="9"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Booking File Log</h1></div>
        <div class="header-sub-title">Get the booking file download logs</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="1-1"/>
      <div class="content">
        <div>
          <div class="tool-bar row">
            <div class="col">
              <div class="icon-group right-float-element">
                <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="File Name"
                          v-model="listParam.Fn"/>
                <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Operation Type"
                          v-model="listParam.Op"/>
                <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Username"
                          v-model="listParam.Un"/>
              </div>
            </div>
          </div>
          <div class="content-scroll-x">
            <div>
              <table class="table">
                <thead>
                <tr>
                  <th style="width:20%" class="long-text">ID</th>
                  <th style="width:30%" class="long-text">FileName</th>
                  <th style="width:20%; vertical-align:middle" class="icon-col">Log Date<img v-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(0)" />
                    <img v-else-if="listParam.SortFlag === 0" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(1)" />
                    </th>
                  <th style="width:15%; vertical-align:middle" class="icon-col">Operation Type</th>
                  <th style="width:15%; vertical-align:middle" class="icon-col">Username</th>
                </tr>
                </thead>
              </table>
            </div>
            <!-- <div :class="specialists.length > 5 ? 'content-scroll-y' : ''"> -->
            <div class="content-scroll-y">
              <table class="table">
                <tbody>
                <tr v-for="(log, index) in logs" :key="index" >
                  <td style="width:20%" class="long-text">
                    {{ log.BookingFileLogID }}
                  </td>
                  <td style="width:30%" class="long-text">
                    {{ log.FileName }}
                  </td>
                  <td style="width:20%;vertical-align:middle" class="icon-col">
                    {{ getTime(log.LogDateUTC) }}
                  </td>
                  <td style="width:15%" class="long-text">
                    {{ log.OperationType }}
                  </td>
                  <td style="width:15%" class="long-text">
                    {{ log.Username }}
                  </td>
                </tr>
                <tr v-if="logs.length === 0">
                  <td colspan="9">No results to display.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminSettingsMenu from '@/components/AdminSettingsMenu'
import { debounce } from 'lodash';
import Header from '@/components/Header'

export default {
  name: 'AdminSettings',
  components: {
    AdminNavigation,
    AdminSettingsMenu,
    Header,
  },
  data() {
    return {
      hasError: false,
      logs: [],
      totalCount: 0,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        Fn: '',
        Op: '',
        Un: '',
        SortFlag: 1,
      },
      successMessage: '',
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    getTime(item) {
      return moment(item).format('DD/MM/YYYY HH:mm:ss')
    },
    sortParam(v) {
      this.listParam.SortFlag = v
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },
    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      let param = {}
      for (let i in this.listParam) {
        if (this.listParam[i] !== undefined && this.listParam[i] !== null && this.listParam[i] !== "") param[i] = this.listParam[i]
      }
      const {data, count} = await utilities.getBookingFileLogs(param)
      this.logs = data
      this.totalCount = count
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    addNewPersonCondition() {
      this.$refs.addPersonConditionModal.isEdit = false
      this.$refs.addPersonConditionModal.handleModal()
    },

    editCondition(index) {
      var item = this.personConditions[index]
      this.$refs.addPersonConditionModal.isEdit = true
      this.$refs.addPersonConditionModal.editItem(item)
      this.$refs.addPersonConditionModal.handleModal()

    }
  },
  async beforeMount() {
    await this.loadData()
  },
  mounted() {
  },
}
</script>
