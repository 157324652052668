/* eslint-disable */
<template>
  <div class="manage-specialist">
    <AdminNavigation ref="navigation" indexProps="3" />
    <div class="top-panel">
      <Header />
      <div v-if="specialist != undefined" class="header-title-section">
        <div class="header-title"><h1>Edit Specialist Fees</h1></div>
        <div class="header-sub-title">Specialists each have their own fees per billing category, and potentially per client + billing category. Where client-specific fees do not exist, the standard fee determined by the client’s billing category will apply.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <!-- <SpecialistMenu indexProps="0" /> -->
      <div class="content">
        <div v-if="rateCard.specialist != undefined">
          <div class="box remove-min-height">
            <div class="box-heading">
              <span>Rate Card</span>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Specialist</div>
                <div class="box-col box-col-right select-title">
                  <!-- <select class="textbox" disabled>
                  <option hidden value="">Please choose ...</option>
                </select> -->
                  {{ specialist.fullName }}
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Billing Category</div>
                <div class="box-col box-col-right">
                  <div v-if="!clickBillingCategory" @click="clickBillingCategory = true">
                    <input type="text" disabled placeholder="Click to choose the billing category" />
                  </div>
                  <div v-else>
                    <select class="textbox" style="display: inline-block; margin-right: 30px" v-model="selectedCategory" @change="selectBillingCategory">
                      <option hidden value="">Please choose ...</option>
                      <option v-for="(billingCategory, index) in billingCategories" :key="index" :value="billingCategory.id">
                        {{ billingCategory.name }}
                      </option>
                    </select>
                    <!-- <img
                      src="@/assets/images/delete_icon.svg"
                      @click="deleteRatecardForClient()"
                    /> -->
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Client</div>
                <div class="box-col box-col-right">
                  <div v-if="!clickClient && rateCard.specialist.id == ''" @click="clickClient = true">
                    <input type="text" disabled placeholder="Click to choose the client" />
                  </div>
                  <div v-else>
                    <select class="textbox" style="display: inline-block; margin-right: 30px" v-model="selectedClient" @change="changeClient()">
                      <option value="0">No Client Selected</option>
                      <option v-for="(client, index) in clients" :key="index" :value="client.id">
                        {{ client.name }} - {{client.branchName}}
                      </option>
                    </select>
                    <img src="@/assets/images/delete_icon.svg" class="is-icon" @click="confirmDelete()" v-if="showDelete" />

                    <div class="alert alert-warning confirmation hidden-element" style="line-height: 2.5;">
                      <a class="close" title="close" @click="deleteRatecardForClient(false)">×</a>
                      <b>Warning:</b> Are you sure? Your changes will take effect immediately.
                      <div class="icon-group center-element">
                        <v-btn class="alert-button" @click="deleteRatecardForClient(true)">Yes</v-btn>
                        <v-btn class="alert-button" @click="deleteRatecardForClient(false)">No</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="box">
            <div class="box-body">
              <div class="content-scroll-x">
                <div>
                  <div>
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="width:33.3%" class="long-text">Booking Type</th>
                          <th style="width:33.3%" class="long-text">Code</th>
                          <th style="width:33.3%" class="long-text">Rate</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-if="showBookingType">
                    <table class="table">
                      <tbody>
                        <tr v-for="bookingType in bookingTypes" v-if="selectedCategory != ''">
                          <td style="width:33.3%" class="long-text">{{bookingType.name}}</td>
                          <td style="width:33.3%" class="long-text"><input type="text" placeholder="Code" v-model="bookingType.specialistFeecode"  /></td>
                          <td style="width:33.3%" class="long-text"><input type="text" placeholder="Please Enter Rate" v-model="bookingType.price"  /></td>
                        </tr>
                        <tr v-if="selectedCategory != ''">
                          <td style="width:33.3%" class="long-text">Cancellation Fee</td>
                          <td style="width:33.3%" class="long-text"><input type="text" placeholder="Code" value="CancellationFees" disabled /></td>
                          <td style="width:33.3%" class="long-text"><input type="text" placeholder="Please Enter Rate" v-model="cancellationFee.cancellationFee" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
<!--
          <div class="box remove-min-height" v-for="bookingType in bookingTypes" v-if="rateCard.category != ''">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col">
                  {{ bookingType.name }}
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Code</div>
                <div class="box-col box-col-right">
                  <input type="text" placeholder="Code" v-model="currentRates[bookingType.id].specialistFeecode" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Rate</div>
                <div class="box-col box-col-right">
                  <input type="text" placeholder="Please Enter Rate" v-model="currentRates[bookingType.id].fee" />
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="box remove-min-height" v-if="rateCard.category != ''">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col">
                  Cancellation Fees
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Code</div>
                <div class="box-col box-col-right">
                  <input type="text" placeholder="Code" value="CancellationFees" disabled />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Rate</div>
                <div class="box-col box-col-right">
                  <input type="text" placeholder="Please Enter Rate" v-model="currentRates['cancellation'].cancellationFee" />
                </div>
              </div>
            </div>
          </div> -->

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="right-element"><v-btn dark @click="submit">Update</v-btn></div>
        </div>
        <div v-else>
          This Specialist could not be found.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import CitySuggestion from '@/components/CitySuggestion'

  export default {
    name: 'AdminManageRateCard',
    components: {
      AdminNavigation,
      SpecialistMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: '',
        clickTitle: false,
        clickClientAlignment: false,
        clickState: false,
        clickLocation: false,
        clickClient: false,
        clickBillingCategory: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),

        showBookingType: false,
        specialistRate: [],
        billingCategories: [],
        originalBookingTypes: [],
        showDelete: false,

        rateCard: {
          client: utilities.initClient(),
          specialist: utilities.initSpecialist(),
          category: '',
          rates: {},
          clients: {},
        },

        selectedCategory: -1,
        selectedClient: -1,
        currentRates: {},
        cancellationFee: {},
        originalCancellationFee: {},

        specialist: utilities.initSpecialist(),
        // selectedClientId: -1,
        clients: [],
        // codes: this.resetBookingTypeObjects(),
        // rates: this.resetBookingTypeObjects(),
        // ids: this.resetBookingTypeObjects()

        bookingTypes: [],
        allRates: {},
        loaded: false,
      }
    },
    computed: {},
    methods: {
      handleClientClick() {
        this.clickClient = true
      },

      validatePrice() {
        if (true) {

        }
      },

      async submit(e) {
        e.preventDefault()
        e.stopPropagation()
        this.$store.dispatch('loading/setLoadComponent', true)
        var callAPI = false
        await Promise.all(this.bookingTypes.map(async (x) => {
          var original = this.originalBookingTypes.find((y) => y.id == x.id)
          //check item is edited or not
          if (JSON.stringify(original) != JSON.stringify(x)) {
            var params = {
              BillingCategoryID: this.selectedCategory,
              BookingTypeID: x.id,
              SpecialistID: this.specialist.id,
              Fee: x.price || 0.00,
              SpecialistFeeCode: x.specialistFeecode,
              UserID: this.user.id,
              Username: this.user.name,
            }
            if (this.selectedClient != -1) {
              params.ClientID = this.selectedClient
            }

            if (original == undefined || original.price == '') {
              await utilities.postSpecialistFee(params)
            } else {
              params.BillingCategorySpecialistFeeID = x.billingCategorySpecialistFeeId
              await utilities.putSpecialistFee(params)
            }
            callAPI = true
          }
        }))

        //Add or update cancelationfee
        if (this.cancellationFee.cancellationFee != undefined && (this.originalCancellationFee.cancellationFee == undefined ||  JSON.stringify(this.originalCancellationFee) != JSON.stringify(this.cancellationFee))) {
          var params = {
            BillingCategoryID: this.selectedCategory,
            CancellationFee: this.cancellationFee.cancellationFee,
            SpecialistID: this.specialist.id,
            UserID: this.user.id,
            Username: this.user.name,
          }

          if (this.selectedClient != -1) {
            params.ClientID = this.selectedClient
          }

          if (this.originalCancellationFee.cancellationFee == undefined) {
            await utilities.postSpecialistCancellationFee(params)
          } else {
            params.BillingCategorySpecialistCancellationFeeID = this.cancellationFee.billingCategorySpecialistCancellationFeeId
            await utilities.putSpecialistCancellationFee(params)
          }
          callAPI = true
        }

        if (callAPI) {
          this.getSpecialistFee()
          this.successMessage = 'Specialist Fees Updated'
          utilities.showAlert('.alert-success')
        }
        this.originalBookingTypes = _.cloneDeep(this.bookingTypes)

        // var params = {
        //   BillingCategoryID: this.selectedCategory,
        //   CancellationFee: 100,
        //   SpecialistID: this.specialist.id,
        //   UserID: this.user.id,
        //   Username: this.user.name,
        //   ClientID: '',
        // }
        // console.log('cancel');
        // console.log(params);
        // utilities.postSpecialistCancellationFee(params)
        //this.originalBookingTypes = _.cloneDeep(this.bookingTypes)
        /*
        var all = []

        //
        // console.log('rates');
        // console.log(this.rateCard.rates);
        // console.log('clients');
        // console.log(this.clients);

        if (this.allRates.rates != this.rateCard.rates && this.selectedClient != 0) {
          console.log('IF ******');
        } else {
          console.log('Else ******');
        }

        console.log(JSON.stringify(this.rateCard.rates));
        // Go through 0 first
        Object.keys(this.rateCard.rates).map(async (key, index) => {
          Object.keys(this.rateCard.rates[key]).map(async (ykey, index2) => {
            if (this.rateCard.rates[key][ykey].fee != undefined || this.rateCard.rates[key][ykey].specialistFeecode != undefined) {
              console.log('ssss s ' + key + ' --- > ' + ykey);
              var params = {
                BillingCategoryID: key,
                BookingTypeID: ykey,
                SpecialistID: this.rateCard.specialist.id,
                Fee: this.rateCard.rates[key][ykey].fee,
                SpecialistFeeCode: this.rateCard.rates[key][ykey].specialistFeecode,
                UserID: this.user.id,
                Username: this.user.name,
              }
              if (this.rateCard.rates[key][ykey].billingCategorySpecialistFeeId == undefined || this.rateCard.rates[key][ykey].billingCategorySpecialistFeeId == '') {
                // post
                console.log('if' + JSON.stringify(params));
                all.push(utilities.postSpecialistFee(params))
              } else {
                // put
                params.BillingCategorySpecialistFeeID = this.rateCard.rates[key][ykey].billingCategorySpecialistFeeId
                console.log('else' + JSON.stringify(params));
                all.push(utilities.putSpecialistFee(params))
              }
            } else if (ykey == 'cancellation') {
              var params = {
                BillingCategoryID: key,
                CancellationFee: this.rateCard.rates[key][ykey].cancellationFee !== undefined ? this.rateCard.rates[key][ykey].cancellationFee : 0,
                SpecialistID: this.rateCard.specialist.id,
                UserID: this.user.id,
                Username: this.user.name,
              }
              if (this.rateCard.rates[key][ykey].billingCategorySpecialistCancellationFeeId !== undefined && this.rateCard.rates[key][ykey].billingCategorySpecialistCancellationFeeId !== '') {
                params.BillingCategorySpecialistCancellationFeeID = this.rateCard.rates[key][ykey].billingCategorySpecialistCancellationFeeId
                // PUT
                all.push(utilities.putSpecialistCancellationFee(params))
              } else {
                // POST
                all.push(utilities.postSpecialistCancellationFee(params))
              }
            }
          })
        })

        if (this.selectedClient != 0) {
          // Then thru each client
          console.log('client');
          console.log(this.rateCard.clients);
          Object.keys(this.rateCard.clients).map(async (key, index) => {
            Object.keys(this.rateCard.clients[key]).map(async (ykey, index) => {
              Object.keys(this.rateCard.clients[key][ykey]).map(async (zkey, index2) => {
                if (this.rateCard.clients[key][ykey][zkey].fee != undefined || this.rateCard.clients[key][ykey][zkey].specialistFeecode != undefined) {
                  var params = {
                    BillingCategoryID: ykey,
                    BookingTypeID: zkey,
                    SpecialistID: this.rateCard.specialist.id,
                    Fee: this.rateCard.clients[key][ykey][zkey].fee,
                    SpecialistFeeCode: this.rateCard.clients[key][ykey][zkey].specialistFeecode,
                    ClientID: key,
                    UserID: this.user.id,
                    Username: this.user.name,
                  }
                  if (this.rateCard.clients[key][ykey][zkey].billingCategorySpecialistFeeId == undefined || this.rateCard.clients[key][ykey][zkey].billingCategorySpecialistFeeId == '') {
                    // post
                    all.push(utilities.postSpecialistFee(params))
                  } else {
                    // put
                    params.BillingCategorySpecialistFeeID = this.rateCard.clients[key][ykey][zkey].billingCategorySpecialistFeeId
                    all.push(utilities.putSpecialistFee(params))
                  }
                } else if (zkey == 'cancellation') {
                  if (this.rateCard.clients[key][ykey][zkey].cancellationFee != undefined && this.rateCard.clients[key][ykey][zkey].cancellationFee != '') {
                    var params = {
                      BillingCategoryID: ykey,
                      CancellationFee: this.rateCard.clients[key][ykey]['cancellation'].cancellationFee !== undefined ? this.rateCard.clients[key][ykey]['cancellation'].cancellationFee : 0,
                      SpecialistID: this.rateCard.specialist.id,
                      UserID: this.user.id,
                      Username: this.user.name,
                      ClientID: key,
                    }
                    if (
                      this.rateCard.clients[key][ykey]['cancellation'].billingCategorySpecialistCancellationFeeId !== undefined &&
                      this.rateCard.clients[key][ykey]['cancellation'].billingCategorySpecialistCancellationFeeId !== ''
                    ) {
                      params.BillingCategorySpecialistCancellationFeeID = this.rateCard.rates[key][ykey]['cancellation'].billingCategorySpecialistCancellationFeeId
                      // PUT
                      all.push(utilities.putSpecialistCancellationFee(params))
                    } else {
                      // POST
                      all.push(utilities.postSpecialistCancellationFee(params))
                    }
                  }
                }
              })
            })
          })
        }
        await Promise.all(all).then((res) => {
          this.loadData()
          this.successMessage = 'Specialist Fees Updated'
          utilities.showAlert('.alert-success')
          this.$store.dispatch('setOriginalObject', this.rateCard.rates)
          this.$store.dispatch('setNewObject', this.rateCard.rates)
        })
        */
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async handleClientChange() {},

      confirmDelete() {
        utilities.showAlert('.confirmation', false)
      },

      async loadData() {
        this.clients = await utilities.getClients()
        this.billingCategories = await utilities.getBillingCategories()
        this.bookingTypes = await utilities.getBookingTypes()
        /*
        if (this.rateCard.specialist != undefined) {
          this.clients = await utilities.getClients()
          this.billingCategories = await utilities.getBillingCategories()
          this.bookingTypes = await utilities.getBookingTypes()
          this.clients.map((x) => {
            this.rateCard.clients[x.id] = {}
            this.billingCategories.map((y) => {
              this.rateCard.clients[x.id][y.id] = {}
              this.bookingTypes.map((z) => {
                this.rateCard.clients[x.id][y.id][z.id] = {}
              })
              this.rateCard.clients[x.id][y.id]['cancellation'] = {}
            })
          })
          this.billingCategories.map((x) => {
            this.rateCard.rates[x.id] = {}
            this.bookingTypes.map((y) => {
              this.rateCard.rates[x.id][y.id] = {}
            })
            this.rateCard.rates[x.id]['cancellation'] = {}
          })
          console.log('loadData');
          console.log(this.billingCategories);
          console.log(this.rateCard.rates);

          var rates = await utilities.getSpecialistFee(this.specialist.id)
          rates.map((x) => {
            if (this.rateCard.rates[x.billingCategoryId] == undefined) this.rateCard.rates[x.billingCategoryId] = {}
            if (x.clientId == 0) this.rateCard.rates[x.billingCategoryId][x.bookingTypeId] = x
            else this.rateCard.clients[x.clientId][x.billingCategoryId][x.bookingTypeId] = x
          })
          var cancellations = await utilities.getSpecialistCancellationFees(this.specialist.id)
          cancellations.map((x) => {
            if (x.clientId == 0) this.rateCard.rates[x.billingCategoryId]['cancellation'] = x
            else this.rateCard.clients[x.clientId][x.billingCategoryId]['cancellation'] = x
          })
          this.allRates = _.cloneDeep(this.rateCard)
          console.log('allRates');
          console.log(this.allRates);
        }
        */
      },
      async loadSpecialist() {
        this.specialist.id = this.$route.query.id != undefined ? this.$route.query.id : ''
        if (this.specialist.id != '') {
          this.specialist = await utilities.getSpecialistById(this.specialist.id)
        }
      },

      setBookingFee() {
        var temp = _.cloneDeep(this.bookingTypes)
        this.showDelete = false
        temp.map((x) => {
          var item = this.specialistRate.find((s) => s.bookingTypeId == x.id)
          if (item != undefined) {
            x.price = item.fee
            x.billingCategorySpecialistFeeId = item.billingCategorySpecialistFeeId
            x.billingCategoryId = item.billingCategoryId
            x.specialistFeecode = item.specialistFeecode != 'undefined' ? item.specialistFeecode : ''

            if (!this.showDelete && this.selectedClient != -1) {
              this.showDelete = true
            }
          } else {
            x.price = ''
          }
        })
        this.bookingTypes = temp
        this.originalBookingTypes = _.cloneDeep(this.bookingTypes)
      },

      async getCancelationFee() {
        if (this.specialist.id != '') {
          this.cancellationFee = await utilities.getSpecialistCancellationFees(this.specialist.id, this.selectedCategory, this.selectedClient)
          this.originalCancellationFee = _.cloneDeep(this.cancellationFee)
        }
      },

      async getSpecialistFee() {
        this.specialistRate = await utilities.getSpecialistFee(this.specialist.id, this.selectedCategory, this.selectedClient)
        await this.getCancelationFee()
        this.setBookingFee()
      },

      async changeClient() {
        if (this.selectedClient == 0) {
          this.selectedClient = -1
          this.cancellationFee = {}
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        if (this.specialist.id != '') {
          this.getSpecialistFee()
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async selectBillingCategory() {
        this.$store.dispatch('loading/setLoadComponent', true)
        if (this.specialist.id != '') {
          this.showBookingType = true
          this.getSpecialistFee()
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async deleteRatecardForClient(state) {
        utilities.hideAlert('.confirmation')
        if (state) {
          var callAPI = false
          await Promise.all(this.bookingTypes.map(async (x) => {
            if (x.price != '') {
              x.price = ''
              x.specialistFeecode = ''
              await utilities.deleteRatecardForClient(x.billingCategorySpecialistFeeId, this.user.id, this.user.name)
              callAPI = true
            }
          }))

          if (this.cancellationFee.cancellationFee != '') {
            this.cancellationFee = []
            await utilities.deleteCancellationFees(this.cancellationFee.billingCategorySpecialistCancellationFeeId, this.user.id, this.user.name)
          }

          if (callAPI) {
            this.successMessage = 'Rate cards deleted for ' + this.clients.find((x) => x.id === this.selectedClient).name
            utilities.showAlert('.alert-success')
            this.showDelete = false
          }

          /*
          var all = []
          Object.keys(this.rateCard.clients[this.rateCard.client.id]).map(async (key, index) => {
            Object.keys(this.rateCard.clients[this.rateCard.client.id][key]).map(async (ykey, index) => {
              if (
                this.rateCard.clients[this.rateCard.client.id][key][ykey].billingCategorySpecialistFeeId != undefined &&
                this.rateCard.clients[this.rateCard.client.id][key][ykey].billingCategorySpecialistFeeId != ''
              )
                all.push(utilities.deleteRatecardForClient(this.rateCard.clients[this.rateCard.client.id][key][ykey].billingCategorySpecialistFeeId, this.user.id, this.user.name))
            })
            if (
              this.rateCard.clients[this.rateCard.client.id][key]['cancellation'].billingCategorySpecialistCancellationFeeId != undefined &&
              this.rateCard.clients[this.rateCard.client.id][key]['cancellation'].billingCategorySpecialistCancellationFeeId != ''
            ) {
              all.push(utilities.deleteCancellationFees(this.rateCard.clients[this.rateCard.client.id][key]['cancellation'].billingCategorySpecialistCancellationFeeId, this.user.id, this.user.name))
            }
          })
          Promise.all(all).then((res) => {
            this.rateCard.clients[this.rateCard.client.id] = {}
            this.billingCategories.map((y) => {
              this.rateCard.clients[this.rateCard.client.id][y.id] = {}
              this.bookingTypes.map((z) => {
                this.rateCard.clients[this.rateCard.client.id][y.id][z.id] = {}
              })
              this.rateCard.clients[this.rateCard.client.id][y.id] = {}
            })
            this.currentRates = this.rateCard.clients[this.rateCard.client.id][this.rateCard.category]
            this.successMessage = 'Rate cards deleted for ' + this.clients.find((x) => x.id === this.rateCard.client.id).name
            utilities.showAlert('.alert-success')

            var originalObject = store.getters['getOriginalObject']
            var newObject = store.getters['getNewObject']

            this.$store.dispatch('setOriginalObject', this.rateCard.rates)
            this.$store.dispatch('setNewObject', this.rateCard).rates
          })
          */
        }
      },
    },

    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      if (this.user.type != 4 && this.user.type != 6) {
        this.$router.go(-1)
      } else {
        await this.loadSpecialist()
        await this.loadData()
      }
      this.rateCard.client.id = 0
      this.loaded = true
      this.$store.dispatch('setOriginalObject', this.rateCard.rates)
      this.$store.dispatch('setNewObject', this.rateCard.rates)
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    mounted() {
      setTimeout(() => {
        autosize($('textarea'))
      })
    },
    watch: {
      rateCard: {
        handler(value) {
          if (this.loaded) this.$store.dispatch('setNewObject', value.rates)
        },
        deep: true,
      },
    },
  }
</script>
