<template>
  <div class="timeline-content">
    <div v-if="timeline.length > 0" class="timeline-line"></div>
    <ul class="timeline">
      <li v-for="item in timeline" :key="item.logDate" class="timeline-item">
        <div class="timeline-badge"  data-placement="left" data-toggle="tooltip" :title="item.userName">{{ getInitials(item.userName) }}</div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">{{ item.datetime }}</h4>
          </div>
          <div class="timeline-body">
            <div class="timeline-body-cover">&nbsp;</div>
            <span v-html="item.body"></span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: 'Timeline',
  props:['timeline'],
	data() {
		return {
      initials: '',
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      timelineData: []
    }
  },
  methods: {
    getInitials(item) {
      try {
        if(item?.userName?.length < 3) return item.toUpperCase()
      var str = ''
      item?.split(' ').map(x => {
        str += x.substr(0,1)
      })
      return str.toUpperCase()
      }
      catch (e) { return "" }
    },
  },
  updated() {
    if(this.timeline.id != '') {
      this.timelineData = this.timeline
    }
  },
  beforeMount() {},
  mounted() {
    utilities.initTooltip()
  },
}
</script>
