/* eslint-disable */
<template :key="updatedKey">
  <div class="admin-home-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-bookings-awaiting-dictation', params: {} }">Bookings Awaiting Dictation ({{ awaitingDictationNumber }})</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-awaiting-paperwork', params: {} }">Bookings Awaiting Paperwork ({{ awaitingPaperworkNumber }})</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-tentative', params: {} }">Tentative Bookings ({{ tentativebookingsNumber }})</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-waitlisted', params: {} }">Waitlisted Bookings ({{ waitlistedbookingsNumber }})</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-cancelled', params: {} }">Cancelled Bookings ({{ cancelledBookingsNumber }})</router-link></li>
      <li><router-link :to="{ name: 'admin-updated-cases', params: {} }">Recently Updated Cases</router-link></li>
      <li><router-link :to="{ name: 'admin-availabilities', params: {} }">Upcoming Specialist Availability</router-link></li>
      <li v-if="user.type == 4 || user.type == 6"><router-link :to="{ name: 'admin-purchase-orders', params: {} }">Finance Approvals ({{ financeApprovalsNumber }})</router-link></li>
      <li><router-link :to="{ name: 'admin-client-requests', params: {} }">Client Account Requests ({{ clientRequestNumber }})</router-link></li>
    </ul>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"

export default {
  name: 'AdminHomeMenu',
  props: ["indexProps", "dataProps"],
  data() {
    return {
      awaitingDictationNumber: 0,
      awaitingPaperworkNumber: 0,
      tentativebookingsNumber: 0,
      waitlistedbookingsNumber: 0,
      cancelledBookingsNumber: 0,
      financeApprovalsNumber: 0,
      clientRequestNumber: 0,
      updatedKey: 0,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {
  },
  async beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")

     if(this.dataProps != undefined){
        // var awaitingPaperwork = await utilities.getBookingAwaitingPaperwork()
        // var allBookings = await utilities.getAllBookings()
        // var bookings = await utilities.getBookingsAwaitingApproval()
        // var travels = await utilities.getTravelsAwaitingApproval()

        if(this.dataProps.countBookingsAwaitingDictation) this.awaitingDictationNumber = this.dataProps.countBookingsAwaitingDictation
        if(this.dataProps.countBookingsAwaitingPaperwork) this.awaitingPaperworkNumber = this.dataProps.countBookingsAwaitingPaperwork
        if(this.dataProps.countBookingsTentative) this.tentativebookingsNumber = this.dataProps.countBookingsTentative
        if(this.dataProps.countBookingsWaitlisted) this.waitlistedbookingsNumber = this.dataProps.countBookingsWaitlisted
        if(this.dataProps.countBookingsCancelled) this.cancelledBookingsNumber = this.dataProps.countBookingsCancelled
        if(this.dataProps.countAwaitingFinancialApprovals && this.dataProps.countTravelAwaitingApprovals) this.financeApprovalsNumber = this.dataProps.countAwaitingFinancialApprovals + this.dataProps.countTravelAwaitingApprovals
        if(this.dataProps.countClientRequests) this.clientRequestNumber = this.dataProps.countClientRequests
        this.updatedKey += 1
      }
  },
}
</script>
