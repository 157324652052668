<template>
  <div class="search-specialist-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Specialist Search</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Qualified to Practice as:</label>
                    <vue-tags-input
                      v-model="qualificationTag"
                      :tags="searchOption.qualificationTags"
                      :avoid-adding-duplicates="true"
                      :autocomplete-items="filteredQualifications"
                      placeholder="Add Qualification"
                      @tags-changed="(newTags) => (searchOption.qualificationTags = newTags)"
                    />
                  </div>
                  <div class="col">
                    <label>Expert In:</label>
                    <vue-tags-input
                      v-model="personConditionTag"
                      :tags="searchOption.personConditionTags"
                      :autocomplete-items="filteredPersonConditions"
                      placeholder="Add Condition"
                      @tags-changed="(newTags) => (searchOption.personConditionTags = newTags)"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>Name</label>
                    <input type="text" class="textbox specialist-name" v-model="searchOption.name" placeholder="Please enter specialist name" />
                  </div>
                  <div class="col">
                    <label>Region / Location</label>
                    <treeselect ref="treeSelect"
                      :multiple="false"
                      :searchable="false"
                      :closeOnSelect="false"
                      :options="regionLocations"
                      :limit="7"
                      :max-height="200"
                      :defaultExpandLevel="0"
                      placeholder="Please choose ..."
                      noOptionsText="No regions"
                      noChildrenText="No cities"
                      v-model="searchOption.regionId"
                      @select="changeRegion" />
                  </div>
<!--                  <div class="col">-->
<!--                    <label>OR Within selected Km radius</label>-->
<!--                    <select-->
<!--                      :disabled="searchOption.regionId == null || searchOption.regionId.includes('r') ? true : false"-->
<!--                      class="textbox"-->
<!--                      :class="searchOption.regionId == null || searchOption.regionId.includes('r') ? 'disabled-element' : ''"-->
<!--                      style="height: 46px;"-->
<!--                      v-model="searchOption.radius">-->
<!--                      <option hidden value="">Please choose ...</option>-->
<!--                      <option v-for="(item, index) in radius" :value="item">{{ item }}</option>-->
<!--                    </select>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="search">Search</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminSearchSpecialistModal',
    data() {
      return {
        loadModal: false,

        searchOption: this.initSearchOption(),

        qualificationTag: '',
        qualifications: [],

        personConditionTag: '',
        personConditions: [],

        regions: [],
        locations: [],
        regionLocations: [],
        radius: [10, 25, 50, 75, 100],
      }
    },
    computed: {
      filteredQualifications() {
        return this.qualifications.filter((i) => {
          return i.text.toLowerCase().indexOf(this.qualificationTag.toLowerCase()) !== -1
        })
      },
      filteredPersonConditions() {
        return this.personConditions.filter((i) => {
          return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
        })
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
          setTimeout(() => {
            $('.search-specialist-modal form').on('keypress', (e) => {
              if (e.keyCode == 13) {
                this.search()
              }
            })
            $('.search-specialist-modal .specialist-name').focus()
          }, 500)
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      initSearchOption() {
        return {
          name: '',
          qualificationTags: [],
          personConditionTags: [],
          regionId: null,
          regionName: '',
          radius: 0,
        }
      },
      changeRegion(item, e) {
        if (item.id.includes('s')) {  // Disable select state
          this.$refs.treeSelect.clear()
          var index = this.regionLocations.findIndex(x => x.id == item.id)
          if(index > -1) {
            var mouseEvent = document.createEvent("MouseEvents")
            mouseEvent.initMouseEvent("mousedown", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
            $(".vue-treeselect__option[data-id*='s'] .vue-treeselect__option-arrow-container")[index].dispatchEvent(mouseEvent)
          }
        } else {
          this.$refs.treeSelect.closeMenu()
          if (!item.id.includes('c')) {
            this.searchOption.radius = 0
          }
          this.searchOption.regionName = item.label
        }
      },

      async search() {
        if (this.searchOption.name == '' && this.searchOption.qualificationTags.length == 0 && this.searchOption.personConditionTags.length == 0 && this.searchOption.regionId == null) {
          return false
        }

        this.$store.dispatch("loading/setLoadComponent", true)
        if (this.$route.fullPath.includes('/admin-specialists')) {
          await this.$parent.$parent.$parent.searchSpecialist(this.searchOption)
          this.$parent.$parent.$parent.selectedSpecialists = []
        } else {
          await utilities.delay(1000)
          this.$router.push({ name: 'admin-specialists', params: { searchOptionProps: this.searchOption } }).catch((err) => {})
        }
        this.handleModal()
        this.$store.dispatch("loading/setLoadComponent", false)
      },
    },
    beforeMount() {},
    mounted() {},
  }
</script>
