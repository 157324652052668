/* eslint-disable */
<template>
  <div class="specialists">
    <Navigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Export Specialists</h1></div>
        <div class="header-sub-title">Export details of all specialists currently available through Red Health Online to a PDF, including their summary profiles.</div>
      </div>
    </div>
    <div class="panel">
      <SpecialistMenu indexProps="1" />
      <div class="content">
        <div class="top-detail row row-block">
          <div class="col">
              <v-btn class="export" dark>Export</v-btn>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import SpecialistMenu from '@/components/SpecialistMenu'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Chat from '@/components/Chat'

export default {
  name: 'Specialists',
  components: {
    Navigation,
    SpecialistMenu,
    Header,
    Chat,
  },
  data() {
    return {
      pagination: utilities.initPagination(),
    }
  },
  methods: {
  },
  beforeMount() {
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
}
</script>
