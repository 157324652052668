<template>
  <div class="cancel_booking-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive display-sub-title">
            <div class="modal-title">
              <h1>Cancel Booking</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
            <div class="">
              Please advise the reason for cancellation of this booking:
            </div>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Reason</div>
                <div class="box-col box-col-right">
                  <select class="textbox" @change="onChangeReason($event)" :class="hasError && !validateBooking ? 'is-invalid' : ''">
                    <option hidden value="0">Please choose</option>
                    <option v-for="(reason, index) in cancelationReasons" :value="reason.value">{{ reason.text }}</option>
                  </select>
                  <div v-if="hasError && !validateBooking" class="text-danger">cancellation reason is required.</div>
                </div>
              </div>
              <div v-if="showDetail" class="box-row">
                <div class="box-col box-col-left">Details</div>
                <div class="box-col box-col-right">
                  <textarea v-model="reason" rows="3" maxlength="255"/>
                </div>
              </div>
            </div>

            <div class="" v-if="showMsg">
              Please Note, a cancellation fee MAY apply. To avoid, please reschedule your booking instead
            </div>
            <div class="row">
              <div class="col" style="padding-top: 20px;">
                <div class="icon-group right-float-element">
                  <v-btn
                    class="white-button"
                    @click="handleModal($event)"
                    dark>Keep Booking
                  </v-btn>
                  <v-btn v-if="showMsg"
                    dark
                    @click="tapReschedule">Reschedule
                  </v-btn>
                  <v-btn
                    @click="clickSave"
                    dark>Cancel Booking
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "CancelBookingModal",
  data() {
    return {
      loadModal: false,
      hasError: false,
      clickState: false,
      enableSave: false,
      showDetail: false,
      showMsg: false,
      reason: "",
      reasonId: "",
      cancelationReasons: utilities.getCancellationReasons(),
      user: _.cloneDeep(this.$store.getters['user/getUser']),
    }
  },

  computed: {
    validateBooking() {
      if (this.reason == "") {
        return false
      }
      return true
    },
  },

  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    onChangeReason(event) {
      this.showDetail = false
      if (event.target.value == 'Other') {
        this.showDetail = true
        this.reason = ""
      } else {
        this.reason = event.target.value
      }
      this.enableSave = true
    },

    showRescheduleMsg() {
      this.showMsg = true
    },

    async clickSave() {
      if (!this.validateBooking) {
        this.hasError = true
        return
      }

      var params = {
        BookingID: this.$parent.booking.id,
        BookingStatus: 6,
        BookingStatusName: 'Cancelled',
        BookingTypeID: this.$parent.booking.bookingTypeId,
        BookingTypeName: this.$parent.booking.bookingTypeName,
        CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        CaseID: this.$parent.booking.caseId,
        CancellationReason: this.reason,
        UserID: this.$parent.user.id,
        Username: this.$parent.user.name,
        BookingDateTime: (this.$parent.booking.bookingDateTime !== "" && this.$parent.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.$parent.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
        BookingEndTime: (this.$parent.booking.bookingEndTime !== "" && this.$parent.booking.bookingEndTime.indexOf('0000-00-00') < 0) ? window.moment(this.$parent.booking.bookingEndTime, 'HH:mm:ss').format('HH:mm:ss') : null,
        AvailabilityID: 0,
        ZohoLink: this.$parent.booking.zohoLink.split('/')[this.$parent.booking.zohoLink.split('/').length - 1]
      }

      const noteObj = {
        Note: `Booking Cancelled.  Reason for Cancellation: ${this.reason}`,
        NoteDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        UserID: this.$parent.user.id,
        Username: this.$parent.user.name,
        BookingID: this.$parent.booking.id,
        InternalOnly: 1,
        IsMainEdit: 0,
        BookingDateTime: this.$parent.booking.bookingDateTime,
        SpecialistName: this.$parent.booking.specialistFullName,
        CaseOwnerName: this.$parent.getCaseOwnerName,
        ClientName: this.$parent.booking.clientName + ' - ' + this.$parent.booking.branchName,
        BookingTypeName: this.$parent.booking.bookingTypeName,
        LocationName: this.$parent.getLocationName(this.$parent.booking.locationId),
        IsAdmin: true,
        IsCanceled: 1
      }

      this.$store.dispatch("loading/setLoadComponent", true)
      var callback = async () => {
        await utilities.postBookingNote(noteObj)
        await this.$parent.bookingCancel()
        this.handleModal()
        this.$store.dispatch("loading/setLoadComponent", false)
      }
      await utilities.putBooking(params, callback)
    },

    tapReschedule() {
      this.handleModal()
      this.$router.push({path:'/search-specialist', query:{id:this.$parent.booking.caseId, bookingId: this.$parent.booking.id}}).catch((err) => {})
    },

    handleState() {
      this.clickState = true
    },
  },

  async beforeMount() {
  },

  mounted() {
  },

  updated() {
  },
}
</script>
