<template>
  <div class="add-note-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1 v-if="!isBilling">{{ isEdit ? 'Edit Note' : 'Add Note' }}</h1>
              <h1 v-else>{{ isEdit ? 'Edit Billing Note' : 'Add Billing Note' }}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form style="font-size: 14px;">
              <div v-if="fromProps === 'admin' && !isBilling" class="form-group box-center">
                <label class="pr-5 m-0">Internal only?</label>
                <input type="radio" :value="0" v-model="internalOnly" v-if="fromProps === 'admin'" /><p v-if="fromProps === 'admin'" class="pl-1">No</p>
                <input style="margin-left: 12px;" type="radio" :value="1" v-model="internalOnly" v-if="fromProps === 'admin'" /><p v-if="fromProps === 'admin'" class="pl-1">Yes</p>
              </div>
              <div class="form-group">
                <textarea rows="4" type="text" @keydown.enter.prevent="callSave" v-model="note" class="textbox" maxlength="1024" placeholder="Enter a note" />
                <div v-if="hasError && !validateNote" class="text-danger">{{ errorMessage }}</div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" id="savebutton" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import store from "@/store";

  export default {
    name: 'AddNoteModal',
    props: ['bookingProps', 'fromProps', 'isBilling'],
    data() {
      return {
        hasError: false,
        loadModal: false,
        note: '',
        errorMessage: '',
        isNoteFromClient: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        isEdit: false,
        editNote: '',
        clientNote: '',
        isNewNote: false,
        isEditClient: false,
        isNewBooking: false,
        isEditClientNote: false,
        internalOnly: 0,
      }
    },
    computed: {
      validateNote() {
        if(!this.note) {
          this.errorMessage = 'Please input text'
          return false
        }
        if(utilities.validateInput(this.note)) {
          return true
        } else {
          this.errorMessage = 'Please do not start with \'=\' or  \'-\'  or  \'+\' or  \'@\' or  \'%\' and do not contains \'--\' or \'||\' or \'@@\''
          return false
        }
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          this.note = ''
          $('html').removeClass('modal-mask-open')
        }
      },
      addNoteToBooking() {
        this.internalOnly = 0
        this.isNewNote = true
      },
      addNewNote() {
        this.isEdit = false
        this.editNote = ''
        this.note = ''
        this.internalOnly = 1
      },
      editCurrentNote(note, isNewBooking = false) {
        this.isEdit = true
        this.isNewBooking = isNewBooking
        if (this.isNewBooking) {
          this.note = note
        } else {
          this.editNote = note
          this.note = this.editNote.note
        }
      },

      editClientNote(note, isEditClientNote) {
        this.isEdit = true
        this.isNewNote = false
        this.isEditClientNote = isEditClientNote
        this.clientNote = note
        this.note = this.clientNote.note
      },

      callPostBookingNoteAPI() {
        var params = {
          Note: this.note.trim(),
          NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name,
          BookingID: this.bookingProps.id,
          IsMainEdit: this.$parent.hasChange ? 1 : 0,
        }
        if(!this.isBilling) params.InternalOnly = this.internalOnly
        if (params.IsMainEdit == 0) {
          params.BookingDateTime = this.$parent.booking.bookingDateTime
          params.SpecialistName = this.$parent.booking.specialistFullName
          params.CaseOwnerName = typeof(this.$parent.getCaseOwnerName) === 'string' ? this.$parent.getCaseOwnerName :  this.$parent.getCaseOwnerName()
          params.ClientName = this.$parent.booking.clientName + ' - ' + this.$parent.booking.branchName
          params.BookingTypeName = this.$parent.booking.bookingTypeName
          params.LocationName = this.$parent.getLocationName(this.$parent.booking.locationId)
          params.IsAdmin = true
        }
        var callback = (response) => {
          try {
            var allNote = this.isBilling ? this.bookingProps.billingNotes : this.bookingProps.bookingNotes
            var newNote = {}
            newNote = {
              note: this.note.trim(),
              noteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
              id: response,
              userName: this.user.name,
              userId: this.user.id,
              deleted: false,
            }
            if(!this.isBilling) newNote.internalOnly = this.internalOnly
            allNote.push(newNote)
            //this.$parent.getOriginalBooking()
            if(this.isBilling) {
              this.$parent.booking.billingNotes = allNote
              this.$parent.setBillingNoteAndAction()
            } else {
              this.$parent.booking.bookingNotes = allNote
              this.$parent.setBookingNoteAndAction()
            }
            this.$parent.noteKey += 1
            this.handleModal()
          } catch (e) {}
        }
        $('#savebutton').prop('disabled', false)
        return this.isBilling ? utilities.postBillingNote(params, callback) : utilities.postBookingNote(params, callback)
      },

      callPutBookingNoteAPI() {
        var params = {
          BookingNoteID: this.editNote.id,
          Note: this.note.trim(),
          NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name,
          BookingID: this.bookingProps.id,
          IsMainEdit: this.$parent.hasChange ? 1 : 0,
        }
        if (params.IsMainEdit == 0) {
          params.BookingDateTime = this.$parent.booking.bookingDateTime
          params.SpecialistName = this.$parent.booking.specialistFullName
          params.CaseOwnerName = typeof(this.$parent.getCaseOwnerName) === 'string' ? this.$parent.getCaseOwnerName :  this.$parent.getCaseOwnerName()
          params.ClientName = this.$parent.booking.clientName + ' - ' + this.$parent.booking.branchName
          params.BookingTypeName = this.$parent.booking.bookingTypeName
          params.LocationName = this.$parent.getLocationName(this.$parent.booking.locationId)
          params.IsAdmin = true
        }
        if(!this.isBilling) params.InternalOnly = this.internalOnly

        var callback = (response) => {
          this.editNote.note = this.note.trim()
          this.editNote.internalOnly = this.internalOnly
          if (this.isEditClient) {
            this.isBilling ? this.$parent.setBillingNoteAndAction(params) : this.$parent.setBookingNoteAndAction(params)
          }
          this.$parent.noteKey += 1
            this.handleModal()
        }
        $('#savebutton').prop('disabled', false)
        return this.isBilling ? utilities.putBillingNote(params, callback) : utilities.putBookingNote(params, callback)
      },

      callPutClientNoteAPI() {
        var params = {
          ClientID: this.clientNote.clientId,
          ClientNoteID: this.clientNote.clientNoteId,
          Note: this.note.trim(),
          NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name,
          InternalOnly: this.internalOnly,
        }
        var callback = (response) => {
          this.$parent.getClientNotes()
          this.handleModal()
        }
        $('#savebutton').prop('disabled', false)
        return utilities.putClientNote(params, callback)
      },

      async saveNoteToBooking() {
        this.$parent.adminEditNoteToBooking &&  this.$parent.adminEditNoteToBooking(this.note.trim())
        if (!this.isBilling) {
          this.$parent.addNoteToBooking && await this.$parent.addNoteToBooking(this.note.trim())
          this.handleModal()
        } else {
          this.handleModal()
        }
        $('#savebutton').prop('disabled', false)
      },

      async callSave() {
        if( this.$store.getters["loading/getLoadComponent"]) {
          console.log(this.$store.getters["loading/getLoadComponent"])
          return
        }
        this.hasError = false
        if (!this.validateNote) {
          this.hasError = true
          return
        }
        $('#savebutton').prop('disabled', true)
        if (this.fromProps == undefined || this.fromProps == 'admin') {
          this.$store.dispatch('loading/setLoadComponent', true)
          if (this.isNewNote) {
            if(this.isBilling) {
              this.$parent.booking.billingNotes.push({
                note: this.note.trim(),
                noteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                userName: this.$parent.user.name,
              })
            } else {
              if(this.$parent.booking && !this.$parent.addNoteToBooking) {
                this.$parent.booking.bookingNotes.push({
                  note: this.note.trim(),
                  noteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                  userName: this.$parent.user.name,
                  internalOnly: this.internalOnly,
                })
              }
            }
            this.saveNoteToBooking(this.note)
          } else if (this.isEdit) {
            if (this.isNewBooking) {
              this.saveNoteToBooking(this.note)
            } else if (this.isEditClientNote) {
              await this.callPutClientNoteAPI()
            } else {
              await this.callPutBookingNoteAPI()
            }
          } else {
            await this.callPostBookingNoteAPI()
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        } else if (this.fromProps == 'supplementaryService') {
          this.saveNoteToBooking(this.note)
        } else {
          if (this.fromProps == 'create') {
            this.$parent.booking.bookingNotes.push({
              note: this.note.trim(),
              noteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
              userName: this.$parent.user.name,
            })
          }
          if (this.fromProps == 'travel') {
            if(this.$parent.travelItinerary.specialistTravelItineraryId != '') {
              this.$parent.travelItinerary.notes = this.note.trim()
              this.saveTravelNote()
            }
            else this.$parent.note = this.note.trim()
          }
          this.handleModal()
        }
      },
      async saveTravelNote() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          Notes: this.note.trim(),
          SpecialistTravelItineraryID: this.$parent.travelItinerary.specialistTravelItineraryId
        }
        await utilities.putTravelItinerary(params)
      },
    },
    mounted() {},
  }
</script>
