/* eslint-disable */
<template>
  <div class="manage-case">
    <AdminNavigation ref="navigation" indexProps="1" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>{{ singleCase.id == '' ? 'Create case' : 'Update case' }}</h1></div>
        <div class="header-sub-title">Cases help clients keep track of all the services required for a particular matter (claimant + event).</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <span>Case Details</span>
          </div>
          <div class="box-body">
            <div class="box-row" v-if="singleCase.id != ''">
              <div class="box-col box-col-left">Status</div>
              <div class="box-col box-col-right">
                <select v-model="singleCase.caseStatus" class="textbox w-50">
                  <option v-for="status in statusList" :value="status.value">{{status.text}}</option>
                </select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Client</div>
              <div class="box-col box-col-right">
                <div v-if="singleCase.id == undefined || singleCase.id == ''">
                  <div v-if="!clientFocus" @click="clientFocus = true" class="parent">
                    <input type="text" disabled class="child w-50 px-3" placeholder="Click to choose the client" :class="hasError && !validateClient ? 'is-invalid' : ''" />
                  </div>
                  <select v-model="singleCase.clientId" v-else class="w-50 px-3" @change="loadClient()" :class="hasError && !validateClient ? 'is-invalid' : ''">
                    <option value="" hidden>Please choose client...</option>
                    <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name +' - '+ client.branchName }}</option>
                  </select>
                  <div class="text-danger" v-if="hasError && !validateClient">
                    Please select a client.
                  </div>
                </div>
                <div v-else>
                  <input type="text" disabled class="w-50 pl-3" :value="client.name + ' - ' + client.branchName" />
                </div>
                <router-link :to="{ path: '/admin-manage-client' }"
                  ><img src="@/assets/images/add_black_icon.svg" alt="" class="right-element align-self-center is-icon pr-3" style="top:50%;margin-top:-12px;"
                /></router-link>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Contact</div>
              <div class="box-col box-col-right">
                <div v-if="singleCase.clientId == undefined && !contactFocus" @click="contactFocus = true">
                  <input type="text" disabled class="w-50 px-3" placeholder="Click to choose the contact" :class="hasError && !validateContact ? 'is-invalid' : ''" />
                </div>
                <select v-model="singleCase.caseOwner" class="w-50 px-3" v-else :class="hasError && !validateClient ? 'is-invalid' : ''">
                  <option value="">Please choose contact...</option>
                  <option v-for="(contact, index) in client.contacts" :key="index" :value="contact.contactUserId">{{ contact.fullName }}</option>
                </select>
                <div class="text-danger" v-if="hasError && !validateContact">
                  Please select a case owner.
                </div>
                <!-- <img src="@/assets/images/add_black_icon.svg" alt="" class="right-element align-self-center" style="top:50%;margin-top:-12px;" /> -->
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Reference Number</div>
              <div class="box-col box-col-right">
                <input type="text" placeholder="Enter Reference Number" class="px-3 w-50" v-model="singleCase.caseReference" :class="hasError && !validateCaseReference ? 'is-invalid' : ''" maxlength="25" />
                <div class="text-danger" v-if="hasError && !validateCaseReference">
                  Please enter case reference (do not contain ' " or `)
                </div>
              </div>
            </div>
            <!-- <div class="box-row"> //removed it from case
              <div class="box-col box-col-left">On behalf of</div>
              <div class="box-col box-col-right">
                <input type="text" class="textbox behalf" v-model="singleCase.onBehalfOf" placeholder="On behalf of" />
              </div>
            </div> -->
            <div class="box-row">
              <div class="box-col box-col-left">Claimant Name</div>
              <div class="box-col box-col-right" @click="openNameModal()">
                <span class="px-3">{{ singleCase.claimantFullName }}</span>
                <div class="text-danger" v-if="hasError && !validateClaimantName">
                  Please enter the correct claimant's name (do not contain ' " or `)
                </div>
              </div>

            </div>
            <AddNameModal ref="addNameModal" />

            <div class="box-row">
              <div class="box-col box-col-left">Claimant Salutation</div>
              <div class="box-col box-col-right" >
                <el-select class="w-50" v-model="singleCase.Salutation" filterable clearable placeholder="Select Salutation">
                  <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                </el-select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Claimant Preferred Name</div>
              <div class="box-col box-col-right" >
                <input class="px-3 w-50" v-model="singleCase.PreferredGivenName" maxlength="100" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Date of Birth</div>
              <div class="box-col box-col-right">
                <datepicker
                  format="dd/MM/yyyy"
                  :typeable="true"
                  class="date-of-birth"
                  input-class="form-control px-3"
                  placeholder="dd/mm/yyyy"
                  v-model="singleCase.dateOfBirth"
                  :input-class="hasError && !isValidDateOfBirth ? 'is-invalid' : ''"
                  :disabled-dates="disabledDates"
                  @input="changeDateOfBirth"></datepicker>
                <div class="text-danger" v-if="hasError && !isValidDateOfBirth">
                  Claimant's date of birth is invalid
                </div>
              </div>
            </div>
            <!-- <div class="box-row">
              <div class="box-col box-col-left">Commencement Date</div>
              <div class="box-col box-col-right">
                <datepicker
                  format="dd/MM/yyyy"
                  :typeable="true"
                  input-class="form-control px-3"
                  :input-class="hasError && !validateCommencementDate ? 'is-invalid' : ''"
                  placeholder="dd/mm/yyyy"
                  v-model="singleCase.commencementDate"
                  :disabled-dates="disabledDates"></datepicker>
                <div class="text-danger" v-if="hasError && !validateCommencementDate">
                  Please enter Commencement Date
                </div>
              </div>
            </div> -->
            <div class="box-row">
              <div class="box-col box-col-left">Claimant Contact Details<br /></div>
              <div class="box-col box-col-right">
                <div @click="openManageContactModal" v-if="contactString == ''" class="parent">
                  <input type="text" class="child px-3" disabled placeholder="Please click here to enter contact details" />
                </div>
                <span class="pl-4" @click="openManageContactModal" v-else v-html="contactString"></span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">
                Claimant Address
              </div>
              <div class="box-col box-col-right parent" @click="openManageAddressModal">
                <input class="child w-100 px-3" v-if="claimantAddress == ''" disabled placeholder="Please click here to update claimant's address" />
                <span class="px-3" v-else>{{ claimantAddress }}</span>
              </div>
            </div>
            <!-- <div class="box-row pb-0"> //removed it from case
              <div class="box-col box-col-left mx-0">
                <label class="pb-0 mb-0">Date of Accident/Event</label>
              </div>
              <div class="box-col box-col-left mx-0">
                <datepicker
                  format="dd/MM/yyyy"
                  :typeable="true"
                  class="accient-date"
                  placeholder="dd/mm/yyyy"
                  v-model="singleCase.accidentDate"
                  :disabled-dates="disabledDates"
                  input-class="w-100 px-3"
                  :input-class="hasError && !isValidAccidentDate ? 'is-invalid' : ''"
                  :disabled="overTime ? true : false"
                  @input="changeAccientDate" />
                <div class="text-danger" v-if="hasError && !isValidAccidentDate">
                  Date of Accident/Event is invalid
                </div>
              </div>
              <div class="box-col box-col-left mx-0 d-flex align-items-center">
                <input type="checkbox" v-model="overTime" :sync="true" class="d-inline-block mr-3" id="overTime" @change="changeOverTime" />
                <label for="overTime" style="margin-bottom: 0px;"> Over a period of time</label>
              </div>
              <div class="box-col box-col-left mx-0">
                &nbsp;
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Affected Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  class="px-3 pr-7"
                  v-model="bodyArea"
                  :tags="bodyAreas"
                  @focus="openDigram()"
                  :validation="validate"
                  placeholder="Please choose"
                  @before-deleting-tag="deleteBodyArea"
                />
                <div @click="openBodyDiagram">
                  <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3" style="top:50%;margin-top:-18px;"/>
                </div>
                <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Other Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  class="px-3"
                  v-model="otherBodyAreaTag"
                  :tags="otherBodyAreaTags"
                  placeholder="Add Other Body Areas"
                  @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
              </div>
            </div>
            <div class="box-row reset-line-height">
              <div class="box-col box-col-left">Conditions</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  ref="conditions"
                  class="px-3 conditions"
                  v-model="condition"
                  :tags="conditions"
                  :add-only-from-autocomplete="true"
                  :autocomplete-items="filteredConditions"
                  @tags-changed="updateConditions"
                  :validation="validate"
                  placeholder="Add Conditions"
                  @before-deleting-tag="deletePersonCondition"
                />
              </div>
            </div> -->
            <div class="box-row">
              <div class="box-col box-col-left">Type of Claim</div>
              <div class="box-col box-col-right">
                <div v-if="!claimFocus && (singleCase.claimTypeId == '' || singleCase.claimTypeId == undefined)" @click="claimFocus = true">
                  <input class="w-50 px-3" type="text" disabled placeholder="Click to choose the Type of Claim" :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" />
                </div>
                <select v-model="singleCase.claimTypeId" v-else :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" class="w-50 px-3 claim">
                  <option value="" hidden>Please choose type of claim...</option>
                  <option v-for="claimType in claimTypes" :key="claimType.value" :value="claimType.id">{{ claimType.name }}</option>
                </select>
                <div class="text-danger" v-if="hasError && !validateTypeOfClaim">
                  Please select Type of Claim
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div class="right-element"><v-btn dark @click="submit">{{ this.singleCase.id == '' ? 'Create' : 'Update' }}</v-btn></div>
       <br>
       <br>
        <div class="header-title ml-0 mt-10" style="padding-left=0px" v-if="isEdit">File Summary</div>
        <div class="" v-if="isEdit">
          <div class="table-responsive position-relative">
            <div class="move-files">
              <div class="move-files-content">
                <div class="move-files-button"><v-btn dark @click="moveFiles">Move To</v-btn></div>
                <div class="case-bookings ml-1">
                  <select v-model="moveToBookingId" class="textbox">
                    <option value='0'>Select</option>
                    <option v-for="booking in bookings" :value="booking.bookingDetails.id">{{ booking.bookingDetails.id }}</option>
                  </select>
                </div>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th class="hidden-element">Id</th>
                  <th style="width:5%"></th>
                  <th style="width:20%" class="long-text">File Type</th>
                  <th style="width:30%" class="long-text">File Name</th>
                  <th style="width:10%" class="long-text">Booking ID</th>
                  <th style="width:15%" class="long-text">Uploaded by</th>
                  <th style="width:10%" class="long-text">Status</th>
                  <th style="width:5%" class="long-text"></th>
                  <th style="width:5%" class="long-text"></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="table-responsive" style="max-height: 200px">
            <table class="table" :key="fileKey">
              <tbody>
                <tr v-for="(file, index) in allBookingFiles" v-if="allBookingFiles.length > 0">
                  <td style="width:5%"><input type="checkbox" class="form-checkbox" :value="index" @change="changeCheckbox($event)" /></td>
                  <td style="width:20%" class="long-text">{{file.fileTypeName}}</td>
                  <td style="width:30%" class="long-text">{{file.fileName}}</td>
                  <td style="width:10%" class="long-text">{{file.bookingId}}</td>
                  <td style="width:15%" class="long-text">{{file.uploadedByName}}</td>
                  <td style="width:10%" class="long-text">{{file.fileStatus == 2 ? 'Complete' : getFileStatusNameById(file.fileStatus)}}</td>
                  <td style="width:5%" @click="deleteFile(file.bookingFileId, index, file.uploadedBy)" class="long-text"><img src="@/assets/images/delete_icon.svg" /></td>
                  <td style="width:5%" class="long-text"><div @click="getFileUrl(file)"><img src="@/assets/images/eye-icon.svg" /></div></td>
                </tr>
                <tr v-if="allBookingFiles.length == 0">
                  <td>No Files</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="this.singleCase.id" class="box mt-20" style="margin-top: 100px;">
          <div class="box-heading col-space-between">
            <div>Bookings : {{bookings.length}}</div>
            <div>
              <router-link :to="{ name: 'admin-search-specialist', query: { id: this.singleCase.id }}" class="router-link"><v-btn class="add-icon submit-request" dark>Add New</v-btn></router-link>
            </div>
          </div>
          <div v-if="this.singleCase.id" class="box-body" style="max-height: 250px;overflow: hidden;overflow-y: auto;">
            <div v-if="bookings.length > 0" class="box-row d-flex align-items-center" v-for="(booking, index) in bookings" :key="index">
              <div class="list-detail-row is-icon">
                  <div class="box-col" style="width: 90%;">
                      ID: {{booking.bookingDetails.id}} {{booking.bookingDetails.bookingTypeName + ","}} {{formatDate(booking.bookingDetails.dateTime, "hh:mm A DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss")}} {{booking.specialistDetails.fullName}} {{booking.clientDetails.clientName}} {{booking.clientDetails.branchName}}
                  </div>
                  <div class="box-col" style="width: 15%;text-align: end;">
                      {{getBookingStatusById(booking.bookingDetails.status)}}
                  </div>
              </div>
              <el-button style="background: #525252; color: white; border-radius: 4px" typs="primary" size="small" @click="editBooking(booking)">View</el-button>
            </div>
            <div v-if="bookings.length == 0" class="box-row" style="padding: 10px;">
              No bookings
            </div>
          </div>
        </div>
      </div>
    </div>
    <BookingModal @closeModal="booking = null" :booking="booking" />
    <AdminCaseCreatedModal ref="adminCaseCreatedModal" :clientProps="false" />
    <AdminManageContactModal ref="adminManageContactModal" />
    <AdminManageClaimantAddressModal :passedCasesProps="this.singleCase" ref="adminManageAddressModal" />
    <AddClientContactModal ref="addClientContactModal" typrProps="fromcases" />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminCaseCreatedModal from '@/components/AdminCaseCreatedModal'
  import AdminManageContactModal from '@/components/AdminManageContactModal'
  import AdminManageClaimantAddressModal from '@/components/AdminManageClaimantAddressModal'
  import AddClientContactModal from '@/components/AddClientContactModal'
  import AddNameModal from '@/components/AddNameModal'
  import BookingModal from '@/components/case/BookingModal'
  // import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'

  export default {
    name: 'AdminManageCase',
    components: {
      AdminNavigation,
      BookingModal,
      Header,
      AdminCaseCreatedModal,
      AdminManageContactModal,
      AdminManageClaimantAddressModal,
      AddClientContactModal,
      AddNameModal,
      // AddBodyDiagramModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: '',
        salutationList: utilities.initSalutation(),
        // Validate Dates
        isValidDateOfBirth: true,
        isValidAccidentDate: true,

        clientFocus: false,
        contactFocus: false,
        claimFocus: false,

        overTime: false,

        focused: '',
        client: utilities.initClient(),
        singleCase: utilities.initCase(),
        bookingStatuses: utilities.getBookingStatuses(),
        statusList: utilities.getStatus(),
        booking: null,
        clients: [],
        claimTypes: [],
        bookings: [],
        fileStatus: utilities.getFileStatus(),
        allBookingFiles: [],
        selectedBookings: [],
        moveToBookingId: 0,
        isEdit: false,
        fileKey: 0,

        user: _.cloneDeep(this.$store.getters['user/getUser']),

        disabledDates: {
          from: moment().toDate(),
        },

        validate: [
          {
            classes: 'test',
            rule: (tag) => tag.length > 0,
          },
        ],

        loaded: false,
      }
    },
    watch: {
      singleCase: {
        handler(value) {
          if (this.loaded) this.$store.dispatch('setNewObject', value)
        },
        deep: true,
      },
    },
    computed: {
      validateClient() {
        return this.singleCase.clientId == '' ? false : true
      },
      validateCaseReference() {
        if (this.singleCase.caseReference == '' || !utilities.validateNameText(this.singleCase.caseReference)) {
          return false
        }
        return true
      },
      validateClaimantSurname() {
        return this.singleCase.claimantSurname == '' ? false : true
      },
      // validateCommencementDate() {
      //   return this.singleCase.commencementDate == '' ? false : true
      // },
      validateTypeOfClaim() {
        return this.singleCase.claimTypeId == '' ? false : true
      },
      validateContact() {
        return this.singleCase.caseOwner == '' ? false : true
      },
      validateClaimantName() {
        if (this.singleCase.claimantFirstName == '' || this.singleCase.claimantSurname == '') {
          return false
        }
        if(!utilities.validateNameText(this.singleCase.claimantFirstName) || !utilities.validateNameText(this.singleCase.claimantSurname)) {
          return false
        }
        return true
      },
      clientAddress() {
        var address = ''
        if (this.client.addressLine1 != '') address += this.client.addressLine1 + ', '
        if (this.client.addressLine2 != '') address += this.client.addressLine2 + ', '
        if (this.client.city != '') address += this.client.city + ', '
        if (this.client.state != '') address += this.client.state + ' '
        if (this.client.postcode != '') address += this.client.postcode
        return address
      },
      claimantAddress() {
        var address = ''
        if (this.singleCase.claimantAddressLine1 != '') address += this.singleCase.claimantAddressLine1 + ', '
        if (this.singleCase.claimantAddressLine2 != '') address += this.singleCase.claimantAddressLine2 + ', '
        if (this.singleCase.claimantCity != '') address += this.singleCase.claimantCity + ', '
        if (this.singleCase.claimantState != '') address += this.singleCase.claimantState + ' '
        if (this.singleCase.claimantPostcode != '') address += this.singleCase.claimantPostcode
        return address
      },
      filteredBodyAreas() {
        return this.bodyAreasAutocomplete.filter((i) => {
          return i.text.toLowerCase().indexOf(this.bodyArea.toLowerCase()) !== -1
        })
      },
      filteredConditions() {
        return this.conditionsAutocomplete.filter((i) => {
          return i.text.toLowerCase().indexOf(this.condition.toLowerCase()) !== -1
        })
      },
      contactString() {
        var phone = this.singleCase.claimantPhone1 == '' ? '' : '<strong>Phone: </strong>' + this.singleCase.claimantPhone1
        var mobile = this.singleCase.claimantMobile == '' ? '' : '<strong ' + (phone == '' ? '' : 'class="ml-3"') + '>Mobile: </strong>' + this.singleCase.claimantMobile
        var work = this.singleCase.claimantPhone2 == '' ? '' : '<strong ' + (phone == '' && mobile == '' ? '' : 'class="ml-3"') + '>Work: </strong>' + this.singleCase.claimantPhone2
        var email = this.singleCase.claimantEmail == '' ? '' : '<strong ' + (phone == '' && mobile == '' && work == '' ? '' : 'class="ml-3"') + '>Email: </strong>' + this.singleCase.claimantEmail
        return phone + mobile + work + email

        // <strong>Phone: </strong>12345<strong class="ml-3">Mobile: </strong>0453 123 123<strong class="ml-3">Work: </strong>0343 456 456<strong class="ml-3">Email: </strong>jsmith@theworld.com.au
      },
    },
    methods: {
      // updateConditions(newTags) { //removed it from case
      //   if(this.$refs.conditions.newTag.length == 0) newTags.pop()
      //   this.conditions = newTags
      // },
      validateDateOfBirth() {
        if(this.singleCase.dateOfBirth == '' || !moment($(".date-of-birth input").val(), "DD/MM/YYYY").isValid()) {
          return false
        }
        return true
      },
      // validateAccidentDate() { //removed it form case
      //   if ($(".accient-date input").val().length > 0) {
      //     if((this.singleCase.accidentDate == '' || !moment($(".accient-date input").val(), "DD/MM/YYYY").isValid()) && !this.overTime) {
      //       return false
      //     }
      //   }
      //   return true
      // },
      validateAllDates() {
        if(!this.validateDateOfBirth()) return false
        else return true
      },

      changeCheckbox(e) {
        var index = Number($(e.target).val())
        if(index > -1) {
          var booking = this.allBookingFiles[index]
          if (e.target.checked) {
            // Add to list
            this.selectedBookings.push(booking)
          } else {
            // Remove from list
            this.selectedBookings = this.selectedBookings.filter(x => x.bookingFileId != booking.bookingFileId)
          }
          // console.log(this.selectedBookings.map(x => { return { bookingFileId: x.bookingFileId, bookingId: x.bookingId } }))

          var checkedNumber = $('.form-checkbox:checked').length
          if (checkedNumber > 0) {
            $('.move-files').show()
          } else {
            $('.move-files').hide()
          }
        }
      },

      changeDateOfBirth() {
        setTimeout(() => {
          this.isValidDateOfBirth = this.validateDateOfBirth()
        }, 200)
      },

      // changeAccientDate() { //removed it from case
      //   setTimeout(() => {
      //     this.isValidAccidentDate = this.validateAccidentDate()
      //   }, 200)
      // },
      // changeOverTime() { //removed it from case
      //   if(this.overTime) this.singleCase.accidentDate = ''
      //   this.isValidAccidentDate = this.validateAccidentDate()
      // },

      editBooking(item) {
        this.booking = item
      },

      // openBodyDiagram() { //removed it from case
      //   this.$refs.addBodyDiagramModal.handleModal()
      //   this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreasAutocomplete
      //   this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreas)
      //   this.$refs.addBodyDiagramModal.isFromAdmin = true
      //   this.$refs.addBodyDiagramModal.handleClick()
      // },

      getFileStatusNameById(id) {
        if(id == 2) {
          return 'Complete'
        } else if (id == 3) {
          return 'For Review'
        }
        if (id == 0 || id == 1) {
          return this.fileStatus.find((x) => x.id == id).name
        }
        return ''
      },

      async moveFiles() {
        if(this.selectedBookings.length > 0 && this.moveToBookingId != 0) {
          this.$store.dispatch('loading/setLoadComponent', true)
          var result = await this.postMoveBookingFiles()
          if (result) {
            this.allBookingFiles.filter(x => this.selectedBookings.map(y => { return y.bookingFileId }).includes(x.bookingFileId)).map(x => {
              x.bookingId = this.moveToBookingId
            })
            this.selectedBookings = []
            await this.getAllBookingFiles()
            $('.form-checkbox').prop("checked", false)
            $('.move-files').hide()
            utilities.showSuccessMessage("The files have been moved to Booking ID " + this.moveToBookingId)
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },

      async postMoveBookingFiles() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          FilesToMove: [],
          DestinationBookingID: this.moveToBookingId,
        }

        var allBookingFiles = this.allBookingFiles.filter(x => this.selectedBookings.map(y => { return y.bookingFileId }).includes(x.bookingFileId))
        allBookingFiles.map(x => {
          if(x.bookingId != this.moveToBookingId) {
            params.FilesToMove.push({
              FileName: x.fileName,
              BID: x.bookingId,
            })
          }
        })

        if(params.FilesToMove.length == 0) return false

        return await utilities.postMoveBookingFiles(params)
      },

      async deleteFile(fileId, index, uploadedBy) {
        var file = this.allBookingFiles[index]
        this.$confirm({
          content: 'Are you sure you want to delete this file?',
          onConfirm: async () => {
            this.$store.dispatch('loading/setLoadComponent', true)
            var booking = this.bookings.find((x) => x.bookingDetails.id == file.bookingId)
            var params = {
              userId: this.user.id,
              userName: this.user.name,
              id: fileId,
            }
            var params2 = {
              BookingID: file.bookingId,
              FileName: file.fileName,
              BookingDateTime:  moment(booking.bookingDetails.dateTime).format('YYYY-MM-DD HH:mm:ss'),
              SpecialistName: booking.specialistDetails.fullName,
              CaseOwnerName: booking.clientContactDetails.fullName,
              ClientName: booking.clientDetails.clientName + ' - ' + booking.clientDetails.branchName,
              BookingTypeName: booking.bookingDetails.bookingTypeName,
              LocationName: booking.location.fullLocation,
              CurrentDateTime:moment().format('YYYY-MM-DD HH:mm:ss'),
              UserID: this.user.id,
              Username: this.user.name,
            }
            var result = await utilities.deleteBookingFileById(params, params2)
            if (result) {
              this.allBookingFiles.splice(index, 1)
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        })
      },

      getFileUrl(file) {
        if(!file.fileName) return
        const params = {
          uploadtype: "bf",
          fn: file.fileName,
          id: file.bookingId
        }
        utilities.getFileUrlNew(params).then(res => {
          let a = document.createElement('a');
          let fileName = file.fileName
          a.target = '_blank'
          a.download = fileName;
          a.href = res
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(res);
          document.body.removeChild(a);
        })
      },

      async setImageUrl() {
        var temp = this.allBookingFiles
          await Promise.all(temp.map(async(x) =>{
          if (x.fileName != '') {
            var params = {
              uploadtype: "bf",
              fn: x.fileName,
              id: x.bookingId
            }
            x.fileUrl = await utilities.getFileUrlNew(params)
          }
        }))
        this.allBookingFiles = temp
        setTimeout(() =>{
          this.fileKey += 1
        }, 500)
      },

      async loadData() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.singleCase.id = this.$route.query.id != undefined ? this.$route.query.id : ''
        if (this.singleCase.id != '') {
          this.singleCase = await utilities.getCaseByCaseId(this.singleCase.id)
          if (this.singleCase.caseOwner == '') {
            // This client's primary contact becomes caseowner
          }
          this.client = await utilities.getClientById(this.singleCase.clientId)

          // this.booking.bodyAreas.map((x) => { //removed it from case
          //   this.bodyAreas.push({ id: x.caseBodyAreaId, text: x.bodyArea, caseId: this.$route.query.id })
          // })
          // this.booking.personCondition.map((x) => {
          //   this.conditions.push({ id: x.id, text: x.name, caseId: this.$route.query.id })
          // })
          // this.overTime = this.singleCase.overPeriodofTime
        } else {
          this.clients = await utilities.getClients()
          this.clients = this.clients.sort((a,b) => {
            return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
          })
        }

        if (this.$route.query.clientId != undefined) {
          this.clientFocus = true
          this.singleCase.clientId =  this.$route.query.clientId != undefined ? this.$route.query.clientId : ''
          this.loadClient()
        }
        // this.bodyAreasAutocomplete = await utilities.getBodyArea() //Removed it from case
        // this.bodyAreasAutocomplete = this.bodyAreasAutocomplete.map((x) => {
        //   return { id: x.id, text: x.bodyArea }
        // })
        // this.conditionsAutocomplete = await utilities.getPersonConditions()
        // this.conditionsAutocomplete = this.conditionsAutocomplete.map((x) => {
        //   return { id: x.id, text: x.name }
        // })
        // if (this.singleCase.bodyAreaOther != '' && this.singleCase.bodyAreaOther.length > 0) {
        //   var items = this.singleCase.bodyAreaOther.split(',');
        //   this.otherBodyAreaTags = utilities.getAutoCompleteOtherBodyAreaTags(items)
        // }
        if (this.singleCase.id) {
          this.isEdit = true
          this.getAllBookingFiles()
          // this.isEdit = true
          // this.bookings = await utilities.getBookingsByCaseId(this.singleCase.id, this.singleCase.clientId)
          // this.bookings.forEach((booking, i) => {
          //   if (booking.bookingDetails.bookingFiles.length > 0) {
          //     booking.bookingDetails.bookingFiles.forEach((file, i) => {
          //       file.bookingId = booking.bookingDetails.id
          //       this.allBookingFiles.push(file)
          //     });
          //   }
          // });
          // this.setImageUrl()
        }
        this.claimTypes = await utilities.getClaimTypes()
        this.$store.dispatch('setOriginalObject', this.singleCase)
        this.$store.dispatch('setNewObject', this.singleCase)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async getAllBookingFiles() {
        this.allBookingFiles = []
        this.bookings = await utilities.getBookingsByCaseId(this.singleCase.id, this.singleCase.clientId)
        this.bookings.forEach((booking, i) => {
          if (booking.bookingDetails.bookingFiles.length > 0) {
            booking.bookingDetails.bookingFiles.forEach((file, i) => {
              file.bookingId = booking.bookingDetails.id
              this.allBookingFiles.push(file)
            });
          }
        });
      },

      openManageAddressModal(e) {
        e.preventDefault()
        e.stopPropagation()
        this.$refs.adminManageAddressModal.loaded = false
        this.$refs.adminManageAddressModal.handleModal()
      },

      setBodyArea(bodyAreas) {
        this.bodyAreas = []
        this.bodyAreas = bodyAreas
      },

      async loadClient() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.client = await utilities.getClientById(this.singleCase.clientId)
        this.client.contacts.sort((a, b) => {
          return a.fullName > b.fullName ? 1 : -1
        })
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      getFormattedDate(dateToFormat) {
        var date = new moment(dateToFormat, 'YYYY-MM-DD')
        return date.format('DD/MM/YYYY')
      },

      getTravelStatusById(id) {
        if (this.travelStatuses.length > 0 && this.travel.specialist != undefined) {
          var status = this.travelStatuses.find((x) => x.id === id)
          return status.name
        }
      },
      openSearchModal(e) {
        e.preventDefault()
        e.stopPropagation()
      },
      openAdminCaseCreatedModal() {
        this.$refs.adminCaseCreatedModal.caseReference = this.singleCase.caseReference
        this.$refs.adminCaseCreatedModal.clientName = this.client != undefined ? this.client.name : '',
        this.$refs.adminCaseCreatedModal.caseId = this.singleCase.id
        this.$refs.adminCaseCreatedModal.clientId = this.singleCase.clientId
        this.$refs.adminCaseCreatedModal.handleModal()
      },

      async submit(e) {
        e.preventDefault()
        e.stopPropagation()

        if (!this.validateClient || !this.validateCaseReference || !this.validateClaimantName
          || !this.validateAllDates()
          || !this.validateTypeOfClaim || !this.validateContact) {
          this.hasError = true
          return
        } else {
          this.hasError = false
        }

        this.$store.dispatch('loading/setLoadComponent', true)

        // var bodyAreasLocal = []
        // var conditionsLocal = []
        // this.bodyAreas.map((x) => {
        //   if (x.caseId === undefined) bodyAreasLocal.push({ BodyAreaID: x.id })
        // })
        // this.conditions.map((x) => {
        //   if (x.caseId === undefined) conditionsLocal.push({ PersonConditionID: x.id })
        // })
        //
        // var otherBodyArea = ""
        // this.otherBodyAreaTags.map((x) => {
        //   if (otherBodyArea == "") {
        //     otherBodyArea = x.text
        //   } else {
        //     otherBodyArea = otherBodyArea + "," + x.text
        //   }
        // })

        var localCase = {
          ClientID: this.singleCase.clientId,
          ClaimTypeID: this.singleCase.claimTypeId,
          ClaimTypeName: this.claimTypes.find((x) => x.id == this.singleCase.claimTypeId).name,
          CaseOwner: this.singleCase.caseOwner, //UserID of the ClientContact selected
          ClaimantFirstName: this.singleCase.claimantFirstName, //not mandatory
          ClaimantGivenNames: this.singleCase.claimantGivenNames, //not mandatory
          PreferredGivenName: this.singleCase.PreferredGivenName, //not mandatory
          Salutation: this.singleCase.Salutation,
          ClaimantSurname: this.singleCase.claimantSurname,
          ClaimantFullName: utilities.getClaimantFullName(this.singleCase),
          DateofBirth: moment(this.singleCase.dateOfBirth).format("YYYY-MM-DD"),
          CaseReference: this.singleCase.caseReference,
          UserID: this.user.id,
          Username: this.user.name,
          CaseStatus: this.singleCase.caseStatus,
        }

        localCase.ClaimantPhone1 = this.singleCase.claimantPhone1
        localCase.ClaimantPhone2 = this.singleCase.claimantPhone2
        localCase.ClaimantMobile = this.singleCase.claimantMobile
        localCase.ClaimantEmail = this.singleCase.claimantEmail
        localCase.ClaimantAddressLine1 = this.singleCase.claimantAddressLine1
        localCase.ClaimantAddressLine2 = this.singleCase.claimantAddressLine2
        localCase.ClaimantCity = this.singleCase.claimantCity
        localCase.ClaimantState = this.singleCase.claimantState
        localCase.ClaimantPostcode = this.singleCase.claimantPostcode
        localCase.ClaimantCountry = 'AU'

        if(this.client.zohoLink != '') {
          var zohoId = this.client.zohoLink.split("/")
          zohoId = zohoId[zohoId.length - 1]
          localCase.ClientZohoLink = zohoId
        }
        if(this.singleCase.zohoCaseId != '') {
          localCase.ZohoCaseID = this.singleCase.zohoCaseId
        }

        var contact = this.client.contacts.find((x) => x.userId == this.singleCase.caseOwner)
        if (contact != undefined) {
            localCase.CCFirstName = contact.firstName
            localCase.CCSurname = contact.surname
            localCase.CCPhone = contact.phoneNumber1
            localCase.CCEmail = contact.NotificationEmailAddress || contact.emailAddress
            localCase.CCZohoID = contact.zohoClientContactId
            localCase.BranchName = this.client.branchName
        }
        // if (bodyAreasLocal.length > 0) localCase.BodyAreaObject = bodyAreasLocal
        // if (conditionsLocal.length > 0) localCase.ConditionObject = conditionsLocal

        if (this.singleCase.id == '' || this.singleCase.id == undefined) {


          var callback = (response) => {
            this.singleCase.id = response
            //utilities.clearChangingObject()
            if(location.search.includes('clientId')) window.history.replaceState({}, document.title, location.pathname + "?id=" + this.singleCase.id)
            // Trigger modal
            this.openAdminCaseCreatedModal()
            setTimeout(() =>{
              utilities.clearChangingObject()
            }, 500)

          }

          await utilities.postCase(localCase, callback)

          // if (this.singleCase.id !== undefined) {
          //
          // }
          this.$store.dispatch('loading/setLoadComponent', false)
        } else {
          var callback = (response) => {
            if (this.singleCase.id !== undefined && response) {
              utilities.clearChangingObject()
              this.successMessage = 'case Updated Successfully.'
              utilities.showAlert('.alert-success')
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
          localCase.CaseID = this.singleCase.id
          localCase.ZohoClaimantID = this.singleCase.zohoClaimantId,
          await utilities.putCase(localCase, callback)
        }
      },
      formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
        if (date) {
          return utilities.formatDate(date, format, currentFormat)
        }
        return ""
      },
      getBookingStatusById(id) {
        if (id) {
          var status = this.bookingStatuses.find((x) => x.id == id)
          if (status) {
            return status.name
          }
        }
        return ""
      },

      openManageContactModal(e) {
        e.preventDefault()
        e.stopPropagation()
        this.$refs.addClientContactModal.singleCase = this.singleCase
        this.$refs.addClientContactModal.booking = this.booking
        this.$refs.addClientContactModal.setCaseContactDetails()
        this.$refs.addClientContactModal.handleModal()
      },

      /*  removed it from case*/
      // openDigram() {
      //   this.openBodyDiagram()
      // },
      //
      // async deleteBodyAreaItems(items) {
      //   items.map((x) => {
      //     this.deleteBodyArea(x)
      //   })
      //
      // },

      // async deleteBodyArea(e) {
      //   // Filter from list
      //   if (e.tag.caseId !== undefined) {
      //     // Delete
      //     this.singleCase.bodyAreas = this.singleCase.bodyAreas.filter((x) => x.caseBodyAreaId !== e.tag.id)
      //     // Call delete API
      //     this.$store.dispatch('loading/setLoadComponent', true)
      //     var params = {
      //       CaseID: this.singleCase.id,
      //       BodyAreaObject: [{ CaseBodyAreaID: e.tag.id }],
      //       ClientID: this.singleCase.clientId,
      //       ClaimTypeID: this.singleCase.claimTypeId,
      //       ClaimTypeName: this.claimTypes.find((x) => x.id == this.singleCase.claimTypeId).name,
      //       CaseOwner: this.singleCase.caseOwner,
      //       CaseReference: this.singleCase.caseReference,
      //       UserID: this.user.id,
      //       Username: this.user.name,
      //     }
      //     await utilities.deleteFromCase(params)
      //     this.$store.dispatch('loading/setLoadComponent', false)
      //   }
      //   this.bodyAreas.splice(e.index, 1);
      //   e.deleteTag()
      // },

      // async deletePersonCondition(e) {
      //   // Filter from list
      //   if (e.tag.caseId !== undefined) {
      //     // Delete
      //     this.singleCase.personCondition = this.singleCase.personCondition.filter((x) => x.caseBodyAreaId !== e.tag.id)
      //     // Call delete API
      //     this.$store.dispatch('loading/setLoadComponent', true)
      //     var params = {
      //       CaseID: this.singleCase.id,
      //       ConditionObject: [{ CasePersonConditionID: e.tag.id }],
      //       ClientID: this.singleCase.clientId,
      //       ClaimTypeID: this.singleCase.claimTypeId,
      //       ClaimTypeName: this.claimTypes.find((x) => x.id == this.singleCase.claimTypeId).name,
      //       CaseOwner: this.singleCase.caseOwner,
      //       CaseReference: this.singleCase.caseReference,
      //       UserID: this.user.id,
      //       Username: this.user.name,
      //     }
      //     await utilities.deleteFromCase(params)
      //     this.$store.dispatch('loading/setLoadComponent', false)
      //   }
      //   e.deleteTag()
      // },

      openNameModal() {
        if (this.singleCase.id != '') {
          this.$refs.addNameModal.editFullName(this.singleCase.claimantFirstName, this.singleCase.claimantGivenNames, this.singleCase.claimantSurname)
        }
        this.$refs.addNameModal.handleModal()
      },

      setName(nameObject) {
        this.singleCase.claimantFirstName = nameObject.firstName
        this.singleCase.claimantGivenNames = nameObject.middleName
        this.singleCase.claimantSurname = nameObject.surname
        this.singleCase.claimantFullName = utilities.getClaimantFullName(this.singleCase)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadData()
      this.loaded = true
      this.isValidDateOfBirth = this.validateDateOfBirth()
      //this.isValidAccidentDate = this.validateAccidentDate()
      this.$store.dispatch('loading/setLoadComponent', false)

      if(this.bookings.length > 0) this.moveToBookingId = this.bookings[0].bookingDetails.id

      $('.behalf').keydown((e) => {
          var code = e.keyCode || e.which;
          if (code === 9) {
            this.openNameModal()
          }
      });

      $('.date-of-birth').keydown((e) => {
          var code = e.keyCode || e.which;
          if (code === 9) {
            this.openManageContactModal(e)
          }
      });

      $('.conditions').keydown((e) => {
          var code = e.keyCode || e.which;
          if (code === 9) {
            this.claimFocus = true
          }
      });
      $(document).ready(function() {
          $(".claim").click(function() {
             $("#sel").simulate('mousedown');
          });
      });
    },
    mounted() {},
  }
</script>
