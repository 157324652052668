<template>
  <div class="add-service-time-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Service Time</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Additional Hours</div>
                <div class="box-col box-col-right">
                  <vue-timepicker tabindex="14" :v-model="serviceTime.numberOfHours"
                    input-class="form-control"
                    placeholder="HH:MM"
                    :minute-interval="15"
                    @change="handleChangeHours"></vue-timepicker>
                  <div v-if="validateHours" class="text-danger">Additional hours is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Service</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="serviceTime.service">
                    <option hidden value="">Select ...</option>
                    <option value="Preparation Time">Preparation Time</option>
                    <option value="Reading Time">Reading Time</option>
                  </select>
                  <div v-if="validateService" class="text-danger">Service is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Reason</div>
                <div class="box-col box-col-right">
                  <textarea v-model="note" class="textbox" placeholder="Please enter reason" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                  <v-btn @click="clickSave" dark>Save</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminAddServiceTimeModal',
    props: ['bookingProps', 'roleProps', 'fromProps'],
    data() {
      return {
        loadModal: false,
        note: '',
        serviceTime: utilities.initBookingHour(),
        validateHours: false,
        validateService: false,
        bookingId: this.bookingProps.id,
        isPreApproved: false,
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      setPreApproved() {
        this.isPreApproved = true
      },

      handleChangeHours(e) {
        if (!/[0-9]{2}:[0-9]{2}/.test(this.serviceTime.numberOfHours)) {
          this.serviceTime.numberOfHours = Number(e.data.H) + Number(e.data.m) / 60
        }
      },

      async clickSave() {
        this.validateHours = false
        this.validateService = false

        if (this.serviceTime.numberOfHours == '' && this.serviceTime.service == '') {
          this.validateHours = true
          this.validateService = true
        } else if (this.serviceTime.numberOfHours == '') {
          this.validateHours = true
        } else if (this.serviceTime.service == '') {
          this.validateService = true
        }
        if (this.validateHours == false && this.validateService == false && (this.fromProps == undefined || this.fromProps == '')) {
          var result = await this.postBookingHour()
          var booking = await utilities.getBookingById(this.bookingProps.id)
          this.$parent.booking.bookingHours = booking.bookingHours
          this.$parent.additionalTime ++
          this.handleModal()
          this.$store.dispatch('loading/setLoadComponent', false)
        } else if (this.validateHours == false && this.validateService == false && this.fromProps == 'create' && this.roleProps == 'adminAsSpecialist') {
          this.$parent.booking.bookingHours.push({
            id: '',
            isNew: true,
            numberOfHours: this.serviceTime.numberOfHours,
            service: this.serviceTime.service,
            status: 0,
          })
          this.handleModal()
          this.$store.dispatch('loading/setLoadComponent', false)
        } else if (this.validateHours == false && this.validateService == false && this.fromProps == 'create') {
          this.$parent.preapprovedHours.push({ numberOfHours: this.serviceTime.numberOfHours, service: this.serviceTime.service })
          this.handleModal()
        }
      },
      async postBookingHour() {
        var userId = this.$parent.user.id
        var userName = this.$parent.user.name
        if (this.roleProps == 'adminAsSpecialist') {
          userId = this.bookingProps.specialistId
          userName = this.bookingProps.specialistFirstName + ' ' + this.bookingProps.specialistSurname
        }
        var serviceTimes = []
        var time = {}
        this.serviceTime.status = 0  // Pending
        var preApprovedHour = 0
        var approvedHour = 0
        var availabledHour = 0
        var hourLeft = 0
        // Get hour of same service and pre-approved status
        var params = {
          BookingID: this.bookingProps.id,
          Service: this.serviceTime.service,
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var bookingHours = await utilities.getBookingHours(params)
        bookingHours.filter(x => x.service == this.serviceTime.service && x.bookingHoursStatus == 3).map(x => {
          preApprovedHour += x.numberHours
        })
        bookingHours.filter(x => x.service == this.serviceTime.service && x.bookingHoursStatus == 1).map(x => {
          approvedHour += x.numberHours
        })
        availabledHour = preApprovedHour - approvedHour
        if(availabledHour > 0) {
          if(this.serviceTime.numberOfHours > availabledHour) {
            // Add origin hour to approved
            time = _.cloneDeep(this.serviceTime)
            time.numberOfHours = availabledHour
            time.status =  1
            serviceTimes.push(_.cloneDeep(time))

            // Add hour left to pending
            hourLeft = (this.serviceTime.numberOfHours * 60 - availabledHour * 60) / 60
            time = _.cloneDeep(this.serviceTime)
            time.numberOfHours = hourLeft
            time.status = 0
            serviceTimes.push(_.cloneDeep(time))
          } else {
            // Add origin hour to approved
            time = _.cloneDeep(this.serviceTime)
            time.status =  1
            serviceTimes.push(_.cloneDeep(time))
          }
        } else {
          time = _.cloneDeep(this.serviceTime)
          time.status =  0
          serviceTimes.push(_.cloneDeep(time))
          //If the amount exceeds the amount of pre-approved time, create booking action for time
          await this.callBookingActionAPI(serviceTimes[0])
        }

        await Promise.all(serviceTimes.map(async (x) => {
          params = {
            BookingID: this.bookingProps.id,
            Service: x.service,
            NumberHours: x.numberOfHours,
            BookingHoursStatus: x.status,
            RequestedBy: userId,
            DateRequested: moment().format('YYYY-MM-DD'),
            UserID: userId,
            Username: userName,
            Reason: this.note
          }

          var callback = async (response) => {
            return response
          }
          await utilities.postBookingHour(params, callback)
          this.$store.dispatch('loading/setLoadComponent', false)
        }))
      },

      async callBookingActionAPI(item) {
        var param = {
          BookingID: this.bookingProps.id,
          DateRequested: moment().format('YYYY-MM-DD HH:mm:ss'),
          RequestedBy: this.$parent.user.id,
          BookingActionRequestStatus: 1,
          RequestedAction: "An additional " + item.numberOfHours + " hours has been requested by Specialist for " + item.service,
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name,
          IsSpecialist: 0,
          Reason: this.note
        }
        var callback = (response) => {}
        await utilities.postBookingAction(param, callback)
      },

      async callputBooking() {
        var ServiceTime = []
        var userId = this.$parent.user.id
        var userName = this.$parent.user.name
        var userTypes = {
          3: 'Admin',
          4: 'Admin',
          5: 'Specialist',
        }
        // if (this.roleProps == 'adminAsSpecialist') {
        //   userId = this.bookingProps.specialistId
        //   userName = this.bookingProps.specialistFirstName + ' ' + this.bookingProps.specialistSurname
        // }
        var roleName = userTypes[this.$parent.user.type]
        var time = {}
        var status = 0
        if (this.isPreApproved) {
          status = 3
        }

        time = {
          Service: this.serviceTime.service,
          NumberHours: this.serviceTime.numberOfHours,
          BookingHoursStatus: status,
          RequestedBy: userId,
        }
        ServiceTime.push(time)

        var params = {
          BookingID: this.bookingProps.id,
          AvailabilityID: this.bookingProps.specialistAvailabilityId,
          SpecialistID: this.bookingProps.specialistId,
          BookingTypeID: this.bookingProps.bookingTypeId,
          BookingTypeName: this.bookingProps.bookingTypeName,
          CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
          Private: this.bookingProps.private,
          OTFCERequired: this.bookingProps.otfceRequired,
          BookingStatus: this.bookingProps.bookingStatus,
          WillAcceptEarlier: this.bookingProps.willAcceptEarlier,
          RedHealthMayContactClaimant: this.bookingProps.redHealthMayContactClaimant,
          ServiceTimeObject: ServiceTime,
          UserID: userId,
          Username: userName,
        }

        var callback = async (response) => {}
        return utilities.putBooking(params, callback)
      },
    },
    mounted() {},
    updated() {},
  }
</script>
