/* eslint-disable */
<template>
  <div class="availability-bookings-reschedule">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Reschedule {{ availabilityBooking1.specialist.id != '' ? ' - ' + availabilityBooking1.specialist.fullName : '' }}</h1></div>
        <div class="header-sub-title">Choose another Specialist to move appointments between calendars.</div>
      </div>
    </div>
    <div class="panel">
      <AdminAvailabilityMenu indexProps="0" />
      <div class="content">
        <div class="row">
          <div class="col">&nbsp;</div>
          <div class="col">
            <div class="box-row">
              <div class="box-col box-col-left"><label>Specialist</label></div>
              <div class="box-col box-col-right">
                <el-select popper-class="select-popper" class="select-dropdown select box-col-right" value-key @change="selectedSpecialist" :value="availabilityBooking2.specialist.id" filterable placeholder="Please choose ...">
                  <el-option v-for="item in listSpecialists" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
                </el-select>
                <div v-if="availabilityBooking2.specialist.id === ''" class="text-danger">Please select a specialist.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left"><label>Date</label></div>
              <div class="box-col box-col-right">
                <el-date-picker
                    :picker-options="pickerOptions"
                    popper-class="date-picker-popper"
                    format="dd/MM/yyyy"
                    v-model="calendarDate2"
                    type="date"
                    @change="changeDate"
                    :clearable="false"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-heading">
            <div class="w-50 mw-100 long-text">
              <span class="mr-1">{{ formatDate(calendarDate1, "dddd Do MMMM, YYYY") }}</span>
              <span v-if="locationList1.trim().length > 0">|</span>
              <span v-if="locationList1.trim().length > 0" class="ml-1 location-list-1" data-placement="top" data-toggle="tooltip" :title="locationList1"><strong>Location: </strong>{{ locationList1 }}</span>
            </div>
            <div class="pl-5 w-50 mw-100 long-text">
              <span class="mr-1">{{ formatDate(calendarDate2, "dddd Do MMMM, YYYY") }}</span>
              <span v-if="locationList2.trim().length > 0">|</span>
              <span v-if="locationList2.trim().length > 0" class="ml-1 location-list-2" data-placement="top" data-toggle="tooltip" :title="locationList2"><strong>Location: </strong>{{ locationList2 }}</span>
            </div>
          </div>
          <div class="box-body">
            <v-row class="fill-height">
                <v-col>
                  <v-sheet height="600">
                    <v-calendar
                      id="calendar-1"
                      ref="calendar1"
                      color="primary"
                      type="day"
                      v-model="availabilityBooking1.calendarDate"
                      :interval-height="anHourHeight"
                      :events="availabilityBooking1.bookings"
                      :event-ripple="false"
                      :weekday-format="setWeekday"
                      :day-format="setDay"
                      event-overlap-mode="column"
                      @mousedown:event="startDrag($event, 'calendar-1')"
                      @mousedown:time="startTime($event, 'calendar-1')"
                      @mousemove:time="moveTime($event, 'calendar-1')"
                      @mouseup:event="endDrag($event, 'calendar-1')">
                      <template v-slot:event="{ event }">
                        <div @mouseup="itemMouseUp($event)" v-if="!event.bookingId" :id="event.item.AvailabilityID" class="fill-height border calendar-event v-event-draggable" :data="event.id">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="d-flex align-items-center ml-2">
                                <el-tooltip  v-if="event.item.Options.VideoOnly" content="Only available for video appointment">
                                  <div class="video-icon"></div>
                                </el-tooltip>
                                <el-tooltip  v-if="event.item.Options.VideoLinkAvailable" content="Available for video and face to face appointment">
                                  <div  class="user-video-icon"></div>
                                </el-tooltip>
                                <el-tooltip  v-if="!event.item.Options.VideoLinkAvailable && !event.item.Options.VideoOnly" content="Only available for face to face appointment">
                                  <div class="face-icon"></div>
                                </el-tooltip>
                                <el-tooltip v-if="event.item.Options.ShowWorkCover" content="Show to everyone INCLUDING WorkCover QLD">
                                  <div class="show-worker-icon"></div>
                                </el-tooltip>
                                <el-tooltip v-if="event.item.Options.ShowSpecificClients">
                                  <template slot="content">
                                    <div>Show only to these clients:</div>
                                    <div
                                        v-for="(item, index) in event.item.SpecificClients"
                                        :key="index"
                                    >
                                      <div>{{decodeURIComponent(item.ClientName)}}</div>
                                    </div>
                                  </template>
                                  <div class="specific-client-icon"></div>
                                </el-tooltip>
                                <el-tooltip v-if="event.item.Options.ByRequestOnly" content="Request only">
                                  <div v-if="event.item.Options.ByRequestOnly" class="request-icon"></div>
                                </el-tooltip>
                              </div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.item.Location.LocationName)}}</div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="calendar-event-booking-tooltip w-100 v-event-draggable" style="z-index: 100" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.bookingDetail.detail">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center w-100">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div style="overflow: hidden; text-overflow: ellipsis; margin-right: 20px" class="text-white ml-2">{{decodeURIComponent(event.name)}}</div>
                              <div class="event-icon right-element" @click="toBookingPage(event)"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet height="600">
                    <v-calendar v-if="availabilityBooking2.specialist.id != ''"
                      id="calendar-2"
                      ref="calendar2"
                      color="primary"
                      type="day"
                      v-model="availabilityBooking2.calendarDate"
                      :interval-height="anHourHeight"
                      :events="availabilityBooking2.bookings"
                      :event-ripple="false"
                      :weekday-format="setWeekday"
                      :day-format="setDay"
                      event-overlap-mode="column"
                      @mousedown:event="startDrag($event, 'calendar-2')"
                      @mousedown:time="startTime($event, 'calendar-2')"
                      @mousemove:time="moveTime($event, 'calendar-2')"
                      @mouseup:event="endDrag($event, 'calendar-2')">
                      <template v-slot:event="{ event }">
                        <div v-if="!event.bookingId" :id="event.item.AvailabilityID" class="fill-height border calendar-event v-event-draggable" :data="event.id">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="d-flex align-items-center ml-2">
                                <el-tooltip  v-if="event.item.Options.VideoOnly" content="Only available for video appointment">
                                  <div class="video-icon"></div>
                                </el-tooltip>
                                <el-tooltip  v-if="event.item.Options.VideoLinkAvailable" content="Available for video and face to face appointment">
                                  <div  class="user-video-icon"></div>
                                </el-tooltip>
                                <el-tooltip  v-if="!event.item.Options.VideoLinkAvailable && !event.item.Options.VideoOnly" content="Only available for face to face appointment">
                                  <div class="face-icon"></div>
                                </el-tooltip>
                                <el-tooltip v-if="event.item.Options.ShowWorkCover" content="Show to everyone INCLUDING WorkCover QLD">
                                <div class="show-worker-icon"></div>
                                </el-tooltip>
                                <el-tooltip v-if="event.item.Options.ShowSpecificClients">
                                  <template slot="content">
                                    <div>Show only to these clients:</div>
                                    <div
                                        v-for="(item, index) in event.item.SpecificClients"
                                        :key="index"
                                    >
                                      <div>{{decodeURIComponent(item.ClientName)}}</div>
                                    </div>
                                  </template>
                                <div class="specific-client-icon"></div>
                                </el-tooltip>
                                <el-tooltip v-if="event.item.Options.ByRequestOnly" content="Request only">
                                  <div v-if="event.item.Options.ByRequestOnly" class="request-icon"></div>
                                </el-tooltip>
                              </div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.item.Location.LocationName)}}</div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="calendar-event-booking-tooltip w-100 v-event-draggable" style="z-index: 100" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.bookingDetail.detail">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center w-100">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div style="overflow: hidden; text-overflow: ellipsis; margin-right: 20px" class="text-white w-75 ml-2">{{decodeURIComponent(event.name)}}</div>
                              <div class="event-icon right-element" @click="toBookingPage(event)"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </v-col>
            </v-row>
          </div>
        </div>
        <div class="box-col d-flex mt-5">
          <div class="availability-item">
            <div class="calender-indicator calender-available"></div>
            <div class="indicator-text">Available</div>
          </div>
          <div class="availability-item">
            <div class="calender-indicator calender-bookied"></div>
            <div class="indicator-text">Booked</div>
          </div>
          <div class="availability-item">
            <div class="calender-indicator calender-onhold"></div>
            <div class="indicator-text">On hold</div>
          </div>
          <div class="availability-item">
            <div class="calender-indicator calender-unconfirmed"></div>
            <div class="indicator-text">Unconfirmed</div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <br />

        <!-- <div class="icon-group">
          <div class="w-100"><v-btn dark class="white-button right-float-element" @click="$router.go(-1)">Close</v-btn></div>
        </div> -->
      </div>
    </div>
    <el-dialog  title="Warning" :visible.sync="dialogVisible"
               width="50%">
      <div v-if="dragAvailability && currentItem">
        <div style="word-break: break-word">{{`The new appointment slot can be either a VideoLink assessment, or an In Person assessment at ${ decodeURIComponent(getFullLocation(dragAvailability.item.Location))}. Please select which type of appointment you would like:`}}</div>
        <el-select style="margin-top: 20px; width: 400px" placeholder="Please select a type" v-model="currentItem.newBooking.BookingTypeID" @change="onChangeSelect">
          <el-option :value="2" label="Independent Medical Examination">Independent Medical Examination</el-option>
          <el-option :value="4" label="Independent Medical Examination By Video">Independent Medical Examination By Video</el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false; cancelDrag()">Cancel</el-button>
    <el-button type="primary" @click="confirmChange">OK</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu"
import Header from "@/components/Header"

export default {
  name: "AdminAvailabilityBookingsReschedule",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    Header,
  },
  data() {
    return {
      currentItem: null,
      dialogVisible: false,
      hasError: false,
      successMessage: "",
      errorMessage: "",
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      clickCalendar: "calendar-1",

      calendarDate1: (this.$route.query != undefined && this.$route.query.date != undefined) ? this.$route.query.date : "",
      availabilityBooking1: {
        specialist: utilities.initSpecialist(),
        specialistAvailabilityId: 0,
        calendarDate: "",
        availabilities: [],
        bookings: [],
      },
      bookingEvents1: [],
      availabilityEvents1: [],
      locationList1: "",
      deepEle: null,

      calendarDate2: moment().format("YYYY-MM-DD"),
      availabilityBooking2: {
        specialist: utilities.initSpecialist(),
        specialistAvailabilityId: 0,
        calendarDate: "",
        availabilities: [],
        bookings: [],
      },
      bookingEvents2: [],
      availabilityEvents2: [],
      locationList2: "",

      specialists: [],
      listSpecialists: [],
      originalSpecialists: [],
      disabledDates: {},
      selectedAvailability1: {
        id: 0,
        locationId: 0,
      },
      originalBookings1: [],
      selectedAvailability2: {
        id: 0,
        locationId: 0,
      },
      originalBookings2: [],
      locations: [],
      dragAvailability: null,
      originalEvent: null,  // event
      dragEvent: null,  // event
      dragOriginal: null, // event
      dragTime: null, // timestamp
      createEvent: null,
      createStart: null,
      extendOriginal: null, // time
      clickEvent: null,  // event

      minimumAppointmentLength1: utilities.APPOINTMENT_LENGTH,
      minimumAppointmentLength2: utilities.APPOINTMENT_LENGTH,
      anHourHeight: utilities.AN_HOUR_HEIGHT,
    }
  },
  computed: {
    pickerOptions() {
      const listDate =  this.disabledDates
      return {
        disabledDate(time) {
          if(time.getTime() >= Number(window.moment().startOf('day').format('x'))) {
            let momentDay = window.moment(time).format('YYYY-MM-DD')
            return !listDate.includes(momentDay)
          } else {
            return true
          }
        },
      }
    }
  },
  methods: {
    itemMouseUp(e) {
      console.log(e)
    },
    onChangeSelect(e) {
      if(e === 2) {
        this.currentItem.newBooking.type = 'Independent Medical Examination'
      } else {
        this.currentItem.newBooking.type = 'Independent Medical Examination By Video'
      }
    },
    showClient(e) {
      let content = '<div style="padding: 10px"><b>Show only to these clients:</b>'
      e.forEach(item => {
        content += `<div>${decodeURIComponent(item.ClientName)}</div>`
      })
      content += '</div>'
      this.$notify({
        message: content,
        dangerouslyUseHTMLString: true,
        duration: 10000
      });
    },
    toBookingPage(booking) {
      this.openBooking(booking.bookingId)
    },
    onMouseEnter(e) {
      if(this.dragEvent) {
        this.dropEle = e
        console.log(e)
      }
    },
    setWeekday(d) {
      return this.formatDate(d.date, 'dddd')
    },
    setDay(d) {
      return this.formatDate(d.date, 'DD/MM/YYYY')
    },
    formatDate(dateToFormat, newFormat, currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    async openAvailabilityBookings(availability) {
      var specialistId = availability.elementId == "calendar-1" ? this.availabilityBooking1.specialist.id : this.availabilityBooking2.specialist.id
      var bookingParams = {
        id: availability.specialistId,
        endDate: availability.endDate,
        fillOrder: availability.fillOrder,
        locationId: availability.locationId,
        bookingDate: availability.availabilityDate,
        bookingTime: availability.selectedTime,
        availabilityId: availability.id,
        locationName: availability.fullLocation,
        typeId: ''
      }
      bookingParams.mergedTimes = availability.mergedTimes.find((x) => x.startTime == availability.selectedTime)
      this.$store.dispatch("booking/setBookingObject", bookingParams)
      this.$router.push({ name: 'admin-search-cases-details' }).catch((err) => {})
      // this.$store.dispatch("loading/setLoadComponent", true)
      // var specialistId = availability.elementId == "calendar-1" ? this.availabilityBooking1.specialist.id : this.availabilityBooking2.specialist.id
      // var data = {
      //   specialistId: specialistId,
      //   availability: availability,
      //   calendarDate: availability.elementId == "calendar-1" ? this.calendarDate1 : this.calendarDate2,
      // }
      // await this.$refs.availabilityBookingsModal.loadBooking(data)
      // this.$refs.availabilityBookingsModal.handleModal()
      // this.$store.dispatch("loading/setLoadComponent", false)
    },
    async changeCalendar2() {
      if(this.availabilityBooking2.specialist.id != '') {
        this.$store.dispatch("loading/setLoadComponent", true)
        this.bookingEvents2 = []
        this.availabilityEvents2 = []
        this.availabilityBooking2.calendarDate = this.calendarDate2
        await this.loadAvailabilityBooking2()
        this.updateCalendarEvent()
        $(".v-calendar-daily__scroll-area").animate({ scrollTop: ($(".v-calendar-daily__scroll-area").height() / 3 * 4) }, 'slow')
        this.$store.dispatch("loading/setLoadComponent", false)
      }
    },
    // changeSpecialist() {
    //   if(this.availabilityBooking1.specialist.id == this.availabilityBooking2.specialist.id && this.calendarDate1 == this.calendarDate2) {
    //     var today = moment().format("YYYY-MM-DD")
    //     this.calendarDate2 = this.calendarDate1 != today ? today : moment().add(1, 'd').format("YYYY-MM-DD")
    //   }
    //   this.disabledDates = this.getDisabledDates()
    //   this.changeCalendar2()
    // },
    selectedSpecialist(item) {
      if(item) {
        this.availabilityBooking2.specialist.id = item
        if(this.availabilityBooking1.specialist.id === this.availabilityBooking2.specialist.id && this.calendarDate1 === this.calendarDate2) {
          var today = moment().format("YYYY-MM-DD")
          this.calendarDate2 = this.calendarDate1 != today ? today : moment().add(1, 'd').format("YYYY-MM-DD")
        }
        this.changeCalendar2()
      }
    },
    filterSpecialist(text) {
      this.listSpecialists = this.originalSpecialists.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
    },
    changeDate() {
      setTimeout(() => {
        this.calendarDate2 = this.formatDate(this.calendarDate2, "YYYY-MM-DD")
        this.changeCalendar2()
      }, 100)
    },
    startDrag(e, elementId) {
      if (e.event && e.event.bookingId) {
        this.originalBookings = _.cloneDeep(e.event)
        this.dragEvent = e.event
        this.originalEvent = _.cloneDeep(this.dragEvent)
        this.originalEvent.elementId = elementId
        this.dragOriginal = _.cloneDeep(this.dragEvent)
        this.dragOriginal.elementId = elementId
      }
    },
    startTime(eventDate) {
      this.$nextTick(() => {
        var mouse = utilities.convertEventToTimestamp(eventDate)
        if (this.dragEvent && this.dragTime === null) { // Drag booking
          var start = utilities.convertDateToTimestamp(this.dragEvent.start)
          this.dragTime = mouse - start
        }
      })
    },
    moveTime(eventDate, element) {
      var mouse = utilities.convertEventToTimestamp(eventDate)
      if (this.dragEvent && this.dragTime !== null) {
        var start = utilities.convertDateToTimestamp(this.dragEvent.start)
        var end = utilities.convertDateToTimestamp(this.dragEvent.end)
        var duration = end - start
        var newStartTime = mouse - this.dragTime
        var newStart = utilities.roundTime(newStartTime)
        var newEnd = newStart + duration
        this.deepEle = _.cloneDeep(this.dragEvent)
        this.dragEvent.start = utilities.convertTimestampToDate(newStart, "YYYY-MM-DD HH:mm")
        this.dragEvent.end = utilities.convertTimestampToDate(newEnd, "YYYY-MM-DD HH:mm")
        this.dragEvent.elementId = element
        this.deepEle.elementId = element
        this.deepEle.start = utilities.convertTimestampToDate(newStart, "YYYY-MM-DD HH:mm")
        this.deepEle.end = utilities.convertTimestampToDate(newEnd, "YYYY-MM-DD HH:mm")
        this.$forceUpdate()
        if(element !== this.originalEvent.elementId && element === 'calendar-2') {
          const index = this.availabilityBooking2.bookings.findIndex(item => item?.bookingDetail?.id === this.deepEle.bookingDetail.id)
          if(index > -1) {
            this.$set(this.availabilityBooking2.bookings[index], 'start', this.deepEle.start)
            this.$set(this.availabilityBooking2.bookings[index], 'end', this.deepEle.end)
          } else {
            this.availabilityBooking2.bookings.push(this.deepEle)
          }
        } else if(element !== this.originalEvent.elementId && element === 'calendar-1') {
          const index = this.availabilityBooking1.bookings.findIndex(item => item?.bookingDetail?.id === this.deepEle.bookingDetail.id)
          if(index > -1) {
            this.$set(this.availabilityBooking1.bookings[index], 'start', this.deepEle.start)
            this.$set(this.availabilityBooking1.bookings[index], 'end', this.deepEle.end)
          } else {
            this.availabilityBooking1.bookings.push(this.deepEle)
          }
        } else if (element === this.originalEvent.elementId && element === 'calendar-1') {
          const index = this.availabilityBooking2.bookings.findIndex(item => item?.bookingDetail?.id === this.deepEle.bookingDetail.id)
          if(index > -1) {
            this.$set(this.availabilityBooking2.bookings[index], 'start', this.deepEle.start)
            this.$set(this.availabilityBooking2.bookings[index], 'end', this.deepEle.end)
          }
        } else if (element === this.originalEvent.elementId && element === 'calendar-2') {
          const index = this.availabilityBooking1.bookings.findIndex(item => item?.bookingDetail?.id === this.deepEle.bookingDetail.id)
          if(index > -1) {
            this.$set(this.availabilityBooking1.bookings[index], 'start', this.deepEle.start)
            this.$set(this.availabilityBooking1.bookings[index], 'end', this.deepEle.end)
          }
        }
      }
    },
    createBooking(item) {
      if(item.booking || item.bookingId) return
      this.openAvailabilityBookings(item.item)
    },
    endDrag(e, element) {
      var updateEvent = null
      this.updateCalendarEvent()
      if(this.dragEvent) {
        this.dragEvent.elementId = element
        if(this.dragEvent.start === this.dragOriginal.start && this.dragEvent.end === this.dragOriginal.end) {
          this.createBooking(this.dragEvent)
        }
        if((this.dragEvent.start !== this.dragOriginal.start || this.dragEvent.end !== this.dragOriginal.end) && (this.dragEvent.bookingDetail.availabilityId !== this.dropEle) && this.checkAvailable(this.dragEvent, e)) {
          updateEvent = _.cloneDeep(this.dragEvent)
        } else {   // outside the available times
          this.cancelDrag()
          return
        }
      }
      if(updateEvent != null) this.updateAvailabilityBooking(updateEvent, e)
    },
    cancelDrag(e) {
      if(this.dragEvent) {
        if(this.originalEvent.elementId === 'calendar-1' && this.dragEvent.elementId === 'calendar-2') {
          const index = this.availabilityBooking2.bookings.findIndex(item => item?.bookingDetail?.id === this.deepEle.bookingDetail.id)
          if(index > -1) {
            this.availabilityBooking2.bookings.splice(index, 1).push(this.deepEle)
          }
        } else if (this.originalEvent.elementId === 'calendar-2' && this.dragEvent.elementId === 'calendar-1') {
          const index = this.availabilityBooking1.bookings.findIndex(item => item?.bookingDetail?.id === this.deepEle.bookingDetail.id)
          if(index > -1) {
            this.availabilityBooking1.bookings.splice(index, 1).push(this.deepEle)
          }
        }
        this.dragEvent.start = this.originalEvent.start
        this.dragEvent.end = this.originalEvent.end
        this.dragEvent.elementId = this.originalEvent.elementId
        this.deepEle = null
      }

      this.dragEvent = null
      this.dragOriginal = null
      this.dragTime = null
      this.createEvent = null
      this.createStart = null
      this.clickEvent = null
      this.originalEvent = null
      this.dragAvailability = null
    },
    hideAvailability() {
      if(this.availabilityEvents1.length > 0 && this.availabilityBooking1.bookings.length > this.bookingEvents1.length) {
        this.availabilityEvents1.map(x => {
          this.availabilityBooking1.bookings.splice(-1, 1)
        })
      }
      if(this.availabilityEvents2.length > 0 && this.availabilityBooking2.bookings.length > this.bookingEvents2.length) {
        this.availabilityEvents2.map(x => {
          this.availabilityBooking2.bookings.splice(-1, 1)
        })
      }
    },
    // showAvailability() {
    //   if(this.availabilityEvents1.length > 0) {
    //     this.availabilityBooking1.bookings = []
    //     this.availabilityEvents1.map(x => {
    //       this.availabilityBooking1.bookings.push(_.cloneDeep(x))
    //     })
    //   }
    //   if(this.availabilityEvents2.length > 0) {
    //     this.availabilityBooking2.bookings = []
    //     this.availabilityEvents2.map(x => {
    //       this.availabilityBooking2.bookings.push(_.cloneDeep(x))
    //     })
    //   }
    // },
    updateCalendarEvent() {
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip({template: '<div class="tooltip calendar-event"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'})
        // Hover header
        $(".v-calendar-daily__head").unbind("hover")
        $(".v-calendar-daily__head").hover(() => {
          this.cancelDrag()
        })
      }, 500)
    },
    validateDragEvent(start, end, elementId) {
      var result = false
      var availabilityBooking = elementId == 'calendar-1' ? this.availabilityBooking1 : this.availabilityBooking2
      var calendarDate = elementId == 'calendar-1' ? this.calendarDate1 : this.calendarDate2
      availabilityBooking.availabilities.filter(x => x.availabilityDate == calendarDate).map(a => {
        var mergedTimes = a.mergedTimes.filter(x => x.status == 'A')
        var index = mergedTimes.findIndex(x => moment(calendarDate + " " + start, "YYYY-MM-DD hh:mm A").isSame(moment(calendarDate + " " + x.startTime, "YYYY-MM-DD hh:mm A")))
        if(index > -1) {
          if(elementId == 'calendar-1') {
            this.selectedAvailability1.id = a.id
            this.selectedAvailability1.locationId = a.locationId
            //console.log("Selected availability 1:")
            //console.log(this.selectedAvailability1)
          } else {
            this.selectedAvailability2.id = a.id
            this.selectedAvailability2.locationId = a.locationId
            //console.log("Selected availability 2:")
            //console.log(this.selectedAvailability2)
          }
          result = true
        }
      })
      return result
    },
    openBooking(id) {
      var routeData = this.$router.resolve({ name: 'admin-manage-booking', query: { id: id } })
      window.open(routeData.href, '_blank')
    },
    async putBooking(booking, specialist, oldSpecialist) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingStatus: 3,
        BookingStatusName: 'Booked',
        BookingID: booking.id,
        SpecialistID: specialist.id,
        AvailabilityID: this.dragAvailability.item.AvailabilityID,
        LocationID: this.dragAvailability.item.Location.LocationID,
        LocationName: decodeURIComponent(this.getLocationName(this.dragAvailability.item.Location.LocationID)),
        BookingTypeID: booking.BookingTypeID,
        BookingTypeName: booking.type,
        CurrentDateTime: utilities.timeToBrisbane(window.moment().format('YYYY-MM-DD HH:mm:ss')),
        BookingDateTime: utilities.formatDate(this.dragAvailability.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"),
        BookingEndTime: utilities.formatDate(this.dragAvailability.end, "HH:mm:ss", "YYYY-MM-DD HH:mm"),
        IsAdmin: 1,
        IsReschedule: true,
        Specialty: specialist.qualificationList,
        ZohoLink: booking.zohoLink.split('/')[booking.zohoLink.split('/').length - 1],
        ZohoBookingDateTime: window.moment(this.dragAvailability.start, "YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm").format(),
        SpecialistZohoLink: specialist.zohoLink,
      }
      if (utilities.formatDate(this.originalEvent.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD") !== utilities.formatDate(this.dragAvailability.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD")) {
        params.ClaimantConfirmed = '0000-00-00'
      }

      const noteObj = {
        Note: `Booking Rescheduled from ${oldSpecialist.fullName} ${utilities.formatDate(this.originalEvent.start, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm")} to ${specialist.fullName} ${moment(params.BookingDateTime).format('DD/MM/YYYY HH:mm')}`,
        NoteDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: booking.id,
        BookingDateTime: params.BookingDateTime,
        SpecialistName: specialist.fullName,
        ClientName: booking.clientName + ' - ' + booking.branchName,
        BookingTypeName: booking.type,
        InternalOnly: 1,
        IsMainEdit: 0,
        IsAdmin: true
      }

      var callback = async () => {
        utilities.postBookingNote(noteObj)
        this.rescheduleEmail(booking, this.dragAvailability)
      }

      return await utilities.putBooking(params, callback)
    },
    getFullLocation(location) {
      return utilities.addComma(location.LocationName) + utilities.addComma(location.AddressLine1) + utilities.addComma(location.AddressLine2) + utilities.addComma(location.City.CityName) + utilities.addComma(location.State.StateName) + location.Country.CountryName
    },
    rescheduleEmail(booking, specialist, originalSpecialist) {
      const params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: booking.id,
        BookingDateTime: utilities.formatDate(this.dragAvailability.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"),
      	OldBookingDateTime: utilities.formatDate(this.originalEvent.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"),
      	SpecialistID: specialist.id,
        OldSpecialistID: originalSpecialist?.id || specialist.id,
        SpecialistName: specialist.fullName,
        OldSpecialistName: originalSpecialist?.fullName || specialist?.fullName,
      	OldLocationName: this.originalEvent.bookingDetail.fullLocation,
      	LocationName: decodeURIComponent(this.getLocationName(this.dragAvailability.item.Location.LocationID)),
      	BookingTypeName: booking.type,
        ClientName: booking.clientName,
        BranchName: booking.branchName,
        IsAdmin: 1,
      }

      return utilities.postRescheduleEmail(params)
    },

    checkAvailable(booking) {
      let result = false
      if(booking.elementId === "calendar-1") {
        this.availabilityBooking1.bookings.forEach(item => {
          if(window.moment(booking.start).isBetween(window.moment(item.start), window.moment(item.end)) && item.item && booking.bookingDetail.availabilityId !== item.item.AvailabilityID && item.item.BookingStatus === 'A' && item.item.AvailabilityStatus !=='1') {
            result = item
          } else if ( window.moment(booking.start).isSame(window.moment(item.start)) && item.item && booking.bookingDetail.availabilityId !== item.item.AvailabilityID && item.item.BookingStatus === 'A' && item.item.AvailabilityStatus !=='1') {
            result = item
          }
        })
      } else {
        this.availabilityBooking2.bookings.forEach(item => {
          if(window.moment(booking.start).isBetween(window.moment(item.start), window.moment(item.end)) && item.item && booking.bookingDetail.availabilityId !== item.item.AvailabilityID && item.item.BookingStatus === 'A' && item.item.AvailabilityStatus !=='1') {
            result = item
          } else if ( window.moment(booking.start).isSame(window.moment(item.start)) && item.item && booking.bookingDetail.availabilityId !== item.item.AvailabilityID && item.item.BookingStatus === 'A' && item.item.AvailabilityStatus !=='1') {
            result = item
          }
        })
      }
      this.dragAvailability = result
      return result
    },

    async updateAvailabilityBooking(booking) {
      let oldSpecialist = booking.bookingDetail.specialistDetails
      let specialist = booking.bookingDetail.specialistDetails
      if(booking.elementId === 'calendar-1' && this.originalEvent.elementId === 'calendar-2') {
        oldSpecialist = this.availabilityBooking2.specialist
        specialist = this.availabilityBooking1.specialist
      } else if (booking.elementId === 'calendar-2' && this.originalEvent.elementId === 'calendar-1') {
        oldSpecialist = this.availabilityBooking1.specialist
        specialist = this.availabilityBooking2.specialist
      } else if (booking.elementId === 'calendar-1' && this.originalEvent.elementId === 'calendar-1') {
        oldSpecialist = this.availabilityBooking1.specialist
        specialist = this.availabilityBooking1.specialist
      } else if (booking.elementId === 'calendar-2' && this.originalEvent.elementId === 'calendar-2') {
        oldSpecialist = this.availabilityBooking2.specialist
        specialist = this.availabilityBooking2.specialist
      }
      let newBooking = booking.bookingDetail
      if(this.dragAvailability && this.dragAvailability.item) {
        if(newBooking.BookingTypeID === 2 && this.dragAvailability.item.Options.VideoOnly === 1) {
          this.$msgbox({
            title: 'Warning',
            message: 'By rescheduling this booking to the selected appointment slot, the booking will be changed to a VideoLink booking. Click "Ok" to confirm the change, or "Cancel" to keep the current appointment.',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }).then(() => {
            newBooking.BookingTypeID = 4
            newBooking.type = "Independent Medical Examination by Video"
            this.updateBooking(newBooking, specialist, oldSpecialist)
          }).catch(() => {
            this.cancelDrag()
          })
        } else if (newBooking.BookingTypeID === 4 && this.dragAvailability.item.Options.VideoOnly === 0 && this.dragAvailability.item.Options.VideoLinkAvailable === 0) {
          this.$msgbox(({
            title: 'Warning',
            message: `By rescheduling this booking to the selected appointment slot, the booking will be changed to an In Person booking at ${ decodeURIComponent(this.getFullLocation(this.dragAvailability.item.Location))}. Click "Ok" to confirm the change, or "Cancel" to keep the current appointment.`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          })).then(() => {
            newBooking.BookingTypeID = 2
            newBooking.type = "Independent Medical Examination"
            this.updateBooking(newBooking, specialist, oldSpecialist)
          }).catch(() => {
            this.cancelDrag()
          })
        } else if ((newBooking.BookingTypeID === 4 || newBooking.BookingTypeID === 2) && this.dragAvailability.item.Options.VideoLinkAvailable === 1) {
          this.currentItem = {
            newBooking,
            specialist,
            oldSpecialist
          }
          this.dialogVisible = true
        } else {
          await this.updateBooking(newBooking, specialist, oldSpecialist)
        }
      } else {
        this.cancelDrag()
      }
    },
    confirmChange(v) {
      const {newBooking, specialist, oldSpecialist} = this.currentItem
      this.dialogVisible = false
      this.updateBooking(newBooking, specialist, oldSpecialist)
      this.currentItem = null
    },
    async updateBooking(newBooking, specialist, oldSpecialist) {
      this.$store.dispatch("loading/setLoadComponent", true)
      var result = await this.putBooking(newBooking, specialist, oldSpecialist)
      if (result) {
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Booking updated !' +
              '          </div>',
        });
      }
      await this.loadAvailabilityBooking1(true)
      await this.loadAvailabilityBooking2()
      this.originalEvent = null
      this.dragAvailability = null
      this.dragEvent = null
      this.updateCalendarEvent()

      // End of updating tooltip
      utilities.disposeTooltip()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadAvailabilityBooking1(noReload) {
      var specialistId = (this.$route.query != undefined && this.$route.query.id != undefined) ? this.$route.query.id : ""
      if(specialistId != '' && this.calendarDate1 != '') {
        var specialist = await utilities.getSpecialistById(specialistId)
        if(specialist) {
          this.availabilityBooking1.specialist = _.cloneDeep(specialist)
          if (!noReload) this.availabilityBooking2.specialist = _.cloneDeep(specialist)
        }

        var params = {
          specialistId: specialistId,
          startDate: this.calendarDate1,
          endDate: this.calendarDate1,
        }
        var bookings = await utilities.getSpecialistBookings(params)
        var tempArray = []
        for (var i = 0; i < bookings.length; i++) {
          var booking =  bookings[i]
          if (booking.bookingDetails.typeId != 6 && booking.bookingDetails.typeId != 8) {
            if (booking.bookingDetails.status != 1 && booking.bookingDetails.status != 6 && booking.bookingDetails.status != 16) {
              tempArray.push(booking)
            }
          }
        }
        bookings = tempArray
        let availabilities = await utilities.getSpecialistAvailabilities(specialistId)
        const availability = availabilities.find(x => window.moment(x.date).isSame(window.moment(this.calendarDate1)))
        // Get Booking Events
        this.bookingEvents1 = []
        bookings.map(x => {
          this.bookingEvents1.push(utilities.loadBooking(x))
        })
        const arr = availability.Availabilities.map(item => {
          const year = window.moment(availability.availabilityDate).format('YYYY-MM-DD')
          return {
            name: item?.booking?.fullLocation || item?.Location?.LocationName,
            start: window.moment(year + ' ' + item.StartTime).format('YYYY-MM-DD HH:mm'),
            end: window.moment(year + ' ' + item.EndTime).format('YYYY-MM-DD HH:mm'),
            startTime: window.moment(item.StartTime, 'HH:mm:ss').format('hh:mm A'),
            endTime: window.moment(item.EndTime, 'HH:mm:ss').format('hh:mm A'),
            color: utilities.getAvailabilityColors(item),
            timed: true,
            booking: item.booking,
            item: item
          }
        })
        this.bookingEvents1.forEach(item => {
          const obj = {
            bookingId: item.id,
            className: 'booking-event',
            name: item?.fullLocation,
            start: window.moment(item.start).format('YYYY-MM-DD HH:mm'),
            end: window.moment(item.end).format('YYYY-MM-DD HH:mm'),
            startTime: window.moment(item.start).format('hh:mm A'),
            endTime: window.moment(item.end).format('hh:mm A'),
            color: '#0080ff',
            timed: false,
            bookingDetail: item
          }
          arr.push(obj)
        })
        this.availabilityBooking1.availabilities = _.cloneDeep(availabilities)
        this.availabilityBooking1.bookings = arr
        if(noReload) return
        let defaultDate = window.moment()
        this.disabledDates = availabilities.filter(item => {
          if(item.date && window.moment().startOf('day').isBefore(window.moment(item.date)) && item.status !== 'fully booked') {
            return true
          }
        }).map(item1 => {
            return item1.date
        })
        this.calendarDate2 = defaultDate.format("YYYY-MM-DD")
        this.availabilityBooking2.calendarDate = this.calendarDate2
        this.locationList1 = _.uniqBy(_.uniqBy(availabilities.filter(x => x.availabilityDate == this.calendarDate1), 'id'), 'locationId').map(x => x.fullLocation).join("; ")
        await this.loadAvailabilityBooking2()
        // Get availabilities
      }
    },

    async loadAvailabilityBooking2() {
      if(this.availabilityBooking2.specialist.id != '' && this.calendarDate2 != '') {
        var specialistId = this.availabilityBooking2.specialist.id
        let specialist = null
        let availabilities = null
        let params = {
          specialistId: specialistId,
          startDate: this.calendarDate2,
          endDate: this.calendarDate2,
        }
        let apiArr = [utilities.getSpecialistBookings(params)]
        if(specialistId !== this.availabilityBooking1.specialist.id) {
          apiArr.push(utilities.getSpecialistById(specialistId))
          apiArr.push(utilities.getSpecialistAvailabilities(specialistId))
        } else {
          specialist = this.availabilityBooking1.specialist
          availabilities = this.availabilityBooking1.availabilities
        }

        const r = await Promise.all(apiArr)

        if(!specialist) this.availabilityBooking2.specialist = _.cloneDeep(r[1])

        let bookings = r[0]
        let tempArray = []
        for (let i = 0; i < bookings.length; i++) {
          let booking =  bookings[i]
          if (booking.bookingDetails.typeId != 6 && booking.bookingDetails.typeId != 8) {
            if (booking.bookingDetails.status != 1 && booking.bookingDetails.status != 6 && booking.bookingDetails.status != 16) {
              tempArray.push(booking)
            }
          }
        }
        bookings = tempArray
        if(!availabilities) availabilities = r[2]
        const availability = availabilities.find(x => window.moment(x.date).isSame(window.moment(this.calendarDate2)))
        this.disabledDates = availabilities.filter(item => item.date && item.status !== 'fully booked' && window.moment().startOf('day').isBefore(window.moment(item.date))).map(item1 => {
          return item1.date
        })
        this.bookingEvents2= []
        bookings.map(x => {
          this.bookingEvents2.push(utilities.loadBooking(x))
        })
        if(availability && availability.Availabilities) {
          const arr = availability.Availabilities.map(item => {
            const year = window.moment(availability.availabilityDate).format('YYYY-MM-DD')
            return {
              name: item?.booking?.fullLocation || item?.Location?.LocationName,
              start: window.moment(year + ' ' + item.StartTime).format('YYYY-MM-DD HH:mm'),
              end: window.moment(year + ' ' + item.EndTime).format('YYYY-MM-DD HH:mm'),
              startTime: window.moment(item.StartTime, 'HH:mm:ss').format('hh:mm A'),
              endTime: window.moment(item.EndTime, 'HH:mm:ss').format('hh:mm A'),
              color: utilities.getAvailabilityColors(item),
              timed: true,
              booking: item.booking,
              item: item
            }
          })
          this.bookingEvents2.forEach(item => {
            const obj = {
              bookingId: item.id,
              className: 'booking-event',
              name: item?.fullLocation,
              start: window.moment(item.start).format('YYYY-MM-DD HH:mm'),
              end: window.moment(item.end).format('YYYY-MM-DD HH:mm'),
              startTime: window.moment(item.start).format('hh:mm A'),
              endTime: window.moment(item.end).format('hh:mm A'),
              color: '#0080ff',
              timed: false,
              bookingDetail: item
            }
            arr.push(obj)
          })
          this.availabilityBooking2.bookings = arr
          this.locationList2 = _.uniqBy(_.uniqBy(availabilities.filter(x => x.availabilityDate == this.calendarDate2), 'id'), 'locationId').map(x => x.fullLocation).join("; ")
          this.availabilityBooking2.availabilities = _.cloneDeep(availabilities)
        }
      }
    },
    async loadAvailabilityBooking() {
      if(this.clickCalendar == 'calendar-1') {
        await this.loadAvailabilityBooking1()
      } else {
        await this.loadAvailabilityBooking2()
      }
    },
    getLocationName(id) {
      if (id && this.locations && this.locations.length > 0) {
        return this.locations.find((x) => x.id == id).fullLocation.replace(', Australia', "")
      }
      return ''
    },
    async loadData() {
      this.specialists = await utilities.getSpecialists()
      this.originalSpecialists = utilities.getDropdownSpecialist(this.specialists)
      this.listSpecialists = _.cloneDeep(this.originalSpecialists)
      this.locations = await utilities.getLocations()
      // var specialists = await utilities.getSpecialists()
      // this.specialists = specialists.filter(x => x.id != this.availabilityBooking1.specialist.id)
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    this.availabilityBooking1.calendarDate = this.calendarDate1
    this.availabilityBooking2.calendarDate = this.calendarDate2
    await this.loadData()
    await this.loadAvailabilityBooking1()
    this.updateCalendarEvent()
    // Scroll down to the appointment
    $(".v-calendar-daily__scroll-area").animate({ scrollTop: ($(".v-calendar-daily__scroll-area").height() / 3 * 4) }, 'slow')
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {},
}
</script>
<style scoped>
.select-popper .el-select-dropdown__item.selected {
  color: #808080;
}

</style>

