/* eslint-disable */
<template>
  <div class="navigation specialist-navigation">
    <div class="navigation-content">
      <div class="navigation-section-1">
        <div class="navigation-icon">
          <router-link :to="{ name: 'specialist-home', params: {} }"><img src="@/assets/images/home_icon.svg" /></router-link>
        </div>
        <div class="navigation-icon navigation-red navigation-add dropdown">
          <img src="@/assets/images/add_large_icon.svg" class="navigation-add-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="navigation-add-icon">
            <router-link class="dropdown-item" to="/specialist-availability">Add Availability</router-link>
<!--            <router-link class="dropdown-item" to="/specialist-add-travel-receipt">Add Travel Receipt</router-link>-->
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-booking dropdown">
          <router-link to="/specialist-progress-bookings"><img src="@/assets/images/calendar_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <router-link class="router-link" to="/specialist-progress-bookings"><div class="dropdown-item">Bookings</div></router-link>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-case dropdown">
          <router-link to="/specialist-availability"><img src="@/assets/images/availability_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <router-link class="router-link" to="/specialist-availability"><div class="dropdown-item">Availability</div></router-link>
          </div>
        </div>
<!--        <div class="navigation-icon navigation-grey navigation-specialist dropdown">-->
<!--          <router-link to="/specialist-travel" style="height:100%;" class="d-flex align-items-center"><img src="@/assets/images/travel_icon.svg" /></router-link>-->
<!--          <div class="dropdown-menu">-->
<!--            <router-link class="router-link" to="/specialist-travel"><div class="dropdown-item">Travel & Accommodation</div></router-link>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="navigation-section-2">
        <div class="navigation-icon-no-place navigation-grey navigation-booking dropdown">
          <router-link to="/specialist-user-guides?index=0"><img style="height: 58px" src="@/assets/images/question.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/specialist-user-guides?index=0">"How To" guides</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-setting dropdown">
          <router-link to="/specialist-settings"><img src="@/assets/images/setting_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <router-link class="router-link" to="/specialist-settings"><div class="dropdown-item">Settings</div></router-link>
          </div>
        </div>
        <!-- <div class="navigation-icon navigation-grey navigation-user dropdown">
          <router-link to=""><img src="@/assets/images/user_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item">Users</div>
          </div>
        </div> -->
        <div class="navigation-icon navigation-red navigation-account dropdown">
          <img src="@/assets/images/account_icon.svg" class="navigation-account-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="navigation-account-icon">
            <router-link class="dropdown-item" to="/specialist-profile">Profile</router-link>
            <router-link :to="{ name: 'change-password', params: {} }" class="dropdown-item">Change Password</router-link>
            <div class="dropdown-item" @click="logout">Logout</div>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import auth from '@/assets/js/cognitoAuth'
import utilities from '@/assets/js/utilities'
import Chat from '@/components/Chat'

export default {
  name: 'SpecialistNavigation',
  components: {
    Chat
  },
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {
    logout() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var callback = () => {
        this.$store.dispatch("user/setAuthentication", utilities.initAuthentication())
        setTimeout(() => {
          this.$router.push({ name: "login" }).catch(err => {})
          this.$store.dispatch('loading/setLoadComponent', false)
        }, 1000)
      }
      auth.logout(callback)
    },
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".navigation-icon").eq(this.indexProps).addClass("navigation-selected")

    // Hover to show grey popup
    $(".navigation-grey img").hover(function() {
      $(".navigation-content").css("width", "285px")
    })
    // Hover outside to hide grey popup
    $(".navigation-grey .dropdown-menu").hover(function() {

    }, function() {
      $(".navigation-content").css("width", "58px")
    })
    // Click to open red popup
    $(".navigation-red img").click(function() {
      $(".navigation-content").css("width", "285px")
    })
    // Click outside red popup
    $(document).on("click", function(event){
      if(!$(event.target).closest(".navigation-red .dropdown-menu").length) {
        $(".navigation-content").css("width", "58px")
      }
    })
  },
}
</script>
