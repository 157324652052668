/* eslint-disable */
<template>
  <div class="user-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-users', params: {} }">Users</router-link></li>
      <li v-if="user.type == 6"><router-link :to="{ name: 'admin-manage-user', params: {} }">Add User</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'AdminUserMenu',
  props: ["indexProps"],
  data() {
    return {
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
