/* eslint-disable */
<template>
  <div class="specialist-bookings-calendar">
    <AdminNavigation ref="navigation" indexProps="3" />
    <div class="top-panel">
      <Header />
      <div v-if="bookings.specialist != undefined && bookings.specialist.id != ''" class="header-title-section">
        <div class="header-title"><h1>Bookings for {{ bookings.specialist.fullName }}</h1></div>
        <div class="header-sub-title">The following appointments are upcoming in your schedule.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistBookingsMenu indexProps="1" :specialistId="bookings.specialist != undefined ? bookings.specialist.id : ''" />
      <div class="content">
        <div v-if="bookings.specialist != undefined && bookings.specialist.id != ''">
          <div class="calendar-month">
            <div class="ml-4">{{ this.currentMoth }}</div>
            <v-row class="fill-height">
              <div class="is-icon move-icon" @click="previousMonth"><img src="@/assets/images/previous_icon.svg" /></div>
              <v-col>
                <v-sheet height="1600">
                  <v-calendar
                    id="calendar"
                    ref="calendar"
                    color="primary"
                    type="month"
                    v-model="calendarDate"
                    :short-months="false"
                    :short-weekdays="false"
                    :events="bookings.bookingsDetails.map((x) => x.calendarEvent)"
                    @click:date="openDate"
                    @change="getEvents"
                  >
                    <template v-slot:event="{ event, timed }">
                      <div class="fill-height calendar-event">
                        <div class="indicator-circle" :style="'background-color:' + event.color"></div>
                        <p class="long-text" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.name" :time="event.start">
                          <router-link :to="{ name: 'admin-manage-booking', query: { id: event.id } }">{{ event.name }}</router-link>
                        </p>
                      </div>
                    </template>
                  </v-calendar>
                </v-sheet>
              </v-col>
              <div class="is-icon move-icon" @click="nextMonth"><img src="@/assets/images/next_icon.svg" /></div>
            </v-row>
          </div>
        </div>
        <div v-else>This specialist could not be found.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSpecialistBookingsMenu from '@/components/AdminSpecialistBookingsMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'AdminSpecialistBookingsCalendar',
    components: {
      AdminNavigation,
      AdminSpecialistBookingsMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: '',

        calendarDate: utilities.formatDate(moment(), 'YYYY-MM-DD'),
        currentMoth: '',
        bookings: {
          specialist: utilities.initSpecialist(),
          bookingsDetails: [],
        },
        bookingTypes: [],
        bookingStatuses: utilities.getBookingStatuses(),
      }
    },
    computed: {},
    methods: {
      getMonth(date) {
        return utilities.formatDate(date, 'MMMM')
      },
      getEvents(event) {
        this.currentMoth = this.getMonth(event.start.date)
      },
      async previousMonth() {
        this.$store.dispatch('loading/setLoadComponent', true)

        this.calendarDate = moment(this.calendarDate)
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
        if (this.bookings.specialist != undefined && this.bookings.specialist.id != '') {
          var bookings = await utilities.getSpecialistBookings({
            specialistId: this.bookings.specialist.id,
            startDate: this.calendarDate,
          })
          bookings = bookings.filter(x => Number(x.bookingDetails.status) >= 2 && Number(x.bookingDetails.status) <= 15)
          this.bookings.bookingsDetails = bookings
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async nextMonth() {
        this.$store.dispatch('loading/setLoadComponent', true)

        this.calendarDate = moment(this.calendarDate)
          .add(1, 'month')
          .format('YYYY-MM-DD')
        if (this.bookings.specialist != undefined && this.bookings.specialist.id != '') {
          var bookings = await utilities.getSpecialistBookings({
            specialistId: this.bookings.specialist.id,
            startDate: this.calendarDate,
          })
          bookings = bookings.filter(x => Number(x.bookingDetails.status) >= 2 && Number(x.bookingDetails.status) <= 15)
          this.bookings.bookingsDetails = bookings
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      openDate(e) {
        this.$router.push({ name: 'admin-availability-bookings', query: { id: this.bookings.specialist.id, date: e.date } }).catch((err) => {})
      },

      async loadSpecialist() {
        this.bookings.specialist.id = this.$route.query.id != undefined ? this.$route.query.id : ''
        if (this.bookings.specialist.id != '') {
          this.bookings.specialist = await utilities.getSpecialistById(this.bookings.specialist.id)
        }
      },
      async loadData() {
        if (this.bookings.specialist != undefined && this.bookings.specialist.id != '') {
          var bookings = await utilities.getSpecialistBookings({
            specialistId: this.bookings.specialist.id,
            startDate: utilities.formatDate(moment().startOf('month')),
          })
          bookings = bookings.filter(x => Number(x.bookingDetails.status) >= 2 && Number(x.bookingDetails.status) <= 15 && Number(x.bookingDetails.status) !== 6)
          this.bookings.bookingsDetails = bookings
          if (this.bookings.bookingsDetails.length > 0) {
            //this.calendarDate = this.bookings.bookingsDetails[0].bookingDetails.dateTime
            this.calendarDate = moment(new Date()).startOf('month').format('YYYY-MM-DD hh:mm');
          }
        }
        this.bookingTypes = await utilities.getBookingTypes()
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadSpecialist()
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
