/* eslint-disable */
<template>
  <div class="booking-requests">
    <Navigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Service Requests</h1></div>
        <div class="header-sub-title">Booking Requests, including Supplementary Requests submitted by, or on behalf of, a Client, for action by Red Health.</div>
      </div>
    </div>
    <div class="panel">
      <ClientBookingMenu indexProps="4" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input clearable class="search-input w-15 filter-icon right-float-element mr-5 clear" placeholder="Assigned to" @input="search()" v-model="listParam.AssignedToName" />
            <div class="icon-group right-float-element">
            </div>
          </div>
        </div>

        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                <tr>
                  <th style="width: 20%" class="long-text">
                    <span data-placement="left" data-toggle="tooltip" title="Requesting Client">Booking Type</span>
                  </th>
                  <th :style="{width: user.type >= 2 ? '18%': '20%'}" class="long-text">Specialist / Specialty</th>
                  <th :style="{width: user.type >= 2 ? '14%': '17%'}"  class="long-text">Claimant Name</th>
                  <th :style="{width: user.type >= 2 ? '14%': '18%'}" class="long-text">
                    Submitted<img v-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)" />
                    <img v-else-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                    <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(1)"   />
                  </th>
                  <th v-if="user.type >= 2" style="width: 11%" class="long-text">
                    SubmittedBy
                  </th>
                  <th :style="{width: user.type >= 2 ? '8%': '10%'}" class="long-text">
                    Status
                  </th>
                  <th style="width: 12%" class="long-text">
                    Assigned To
                  </th>
                  <th style="width: 3%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                </tr>
                </thead>
              </table>
            </div>
            <div v-if="bookingRequests.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                <tr v-for="booking in bookingRequests" class="is-icon" :key="booking.id">
                  <td style="width: 20%" class="long-text" >
                    <el-tooltip effect="dark" :content="booking.bookingTypeName" placement="bottom">
                      <span>{{ booking.bookingTypeName}}</span>
                    </el-tooltip>
                  </td>
                  <td :style="{width: user.type >= 2 ? '18%': '20%'}" class="long-text" >
                    <el-tooltip effect="dark" :content="booking.qualificationList || booking.fullName" placement="bottom">
                      <span>{{ booking.qualificationList || booking.fullName }}</span>
                    </el-tooltip>
                  </td>
                  <td :style="{width: user.type >= 2 ? '14%': '17%'}" class="long-text">
                    <el-tooltip effect="dark" :content="booking.claimantFullName" placement="bottom">
                      <span>{{ booking.claimantFullName }}</span>
                    </el-tooltip>
                  </td>
                  <td :style="{width: user.type >= 2 ? '14%': '18%'}" class="long-text">
                    <el-tooltip effect="dark" :content="formatDate(booking.dateSubmitted, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss')" placement="bottom">
                      <span>{{ formatDate(booking.dateSubmitted, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss') }}</span>
                    </el-tooltip>
                  </td>
                  <td v-if="user.type >= 2" style="width: 9%" class="long-text">
                    {{booking.userName}}
                  </td>
                  <td :style="{width: user.type >= 2 ? '8%': '10%'}">
                    <span>{{getStatus(booking.status)}}</span>
                  </td>
                  <td style="width: 12%">
                    <el-tooltip effect="dark" :content="booking.assignedToName" placement="bottom">
                      <span>{{ booking.assignedToName }}</span>
                    </el-tooltip>
                  </td>
                  <td style="width: 3%" class="icon-col">
                    <div class="icon-group">
                      <span data-placement="right" data-toggle="tooltip" :title="booking.note"><img :src="booking.note == '' ? require('@/assets/images/note_light_icon.svg') : require('@/assets/images/note_dark_icon.svg')"/></span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import ClientBookingMenu from '@/components/ClientBookingMenu'
import Header from '@/components/Header'
import { debounce } from 'lodash'

export default {
  name: 'AdminBookingRequests',
  components: {
    Navigation,
    ClientBookingMenu,
    Header,
  },
  data() {
    return {
      fetchedData: false,
      successMessage: '',
      debounceGet: null,
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      bookingRequests: [],
      limit: 10,
      pagination: utilities.initPagination(),

      byClient: false,
      byRequirement: false,
      bySpecialty: false,
      bySpecialist: false,
      byLocation: false,
      byDate: false,
      bySubmitted: false,
      byStatus: false,
      byAssignedTo: false,

      searchAssignedTo: '',
      searchSpecialist: '',
      searchClient: '',

      users: [],
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 1,
        AssignedToName: "",
        SpecialistName: "",
        ClientName: ""
      },
      totalCount: 0,
      bookingRequestStatuses: [],
    }
  },
  computed: {},
  methods: {
    sortParam(v) {
      this.listParam.SortFlag = v
      this.selectPage(1)
    },
    async selectPage(index) {
      this.listParam.PageNum = index
      await this.loadBookingRequests()
    },
    search: debounce(function(){
      // 执行内容...
      this.listParam.PageNum = 1
      this.loadBookingRequests()
    },800),

    openBookingRequest(id) {
      return
    },
    getStatus(status) {
      const obj = this.bookingRequestStatuses.find(item => item.id === Number(status))
      return obj?.name || ''
    },
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    async changeBookingRequestStatus(index) {
      this.$store.dispatch('loading/setLoadComponent', true)
      if(this.bookingRequests[index].status != 1 && this.bookingRequests[index].assignedTo === '') {
        // Assigned to person who logged in
        this.bookingRequests[index].assignedTo = this.user.id
      }
      const result = await this.putBookingRequest(index)
      if (result) {
        this.successMessage = 'Booking request updated successfully.'
        utilities.showAlert('.alert-success')
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    async changeAssignedTo(index) {
      this.$store.dispatch('loading/setLoadComponent', true)
      // Change status to assigned
      this.bookingRequests[index].status = 2
      const result = await this.putBookingRequest(index)
      if (result) {
        this.successMessage = 'Booking request updated successfully.'
        utilities.showAlert('.alert-success')
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    putBookingRequest(index) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        BookingRequestID: this.bookingRequests[index].id,
        AssignedTo: Number(this.bookingRequests[index].assignedTo),
        BookingRequestStatus: Number(this.bookingRequests[index].status),
      }
      params.BookingRequestUserAssigned = params.AssignedTo == 0 ? 0 : 1
      var callback = (response) => {
        // Reload if change to Completed
        if(this.bookingRequests[index].status == 3) this.loadBookingRequests()
      }
      return utilities.putBookingRequest(params, callback)
    },

    async loadBookingRequests() {
      this.$store.dispatch('loading/setLoadComponent', true)
      let {bookingRequests, total} = await utilities.getBookingRequestsNew(this.listParam)
      this.totalCount = total
      // Show all not been completed yet
      bookingRequests = bookingRequests.filter(x => x.status !== 3)
      this.bookingRequests = _.cloneDeep(bookingRequests)
      this.bookingRequestStatuses = utilities.getBookingRequestStatuses()
      this.$store.dispatch('loading/setLoadComponent', false)
    }
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    await this.loadBookingRequests()
    this.fetchedData = true
  }
}
</script>
