<template>
  <div class="qr-code-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>QR Code</h1>
                  <p>Please scan this QR code with a third-party authenticator app (e.g. Google Authenticator)</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
              <div class="form-group">
                <div class="box">
                  <div class="box-body">
                    <!-- <div class="box-row">
                      <barcode :value="codeProps">
                        Can't generate the barcode.
                      </barcode>
                    </div> -->
                    <div class="box-row">
                      <vue-qrcode :value="codeProps" />
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">OTP from Authenticator Application</div>
                      <div class="box-col box-col-right">
                        <input type="password" :class="hasError && !validatePassword ? 'is-invalid' : ''" v-model="password" placeholder="Password" />
                        <div class="text-danger" v-if="hasError && !validatePassword">
                          Please enter OTP
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="alert alert-success hidden-element">
                    <a class="close" title="close">×</a>
                    <b>Success:</b> Your account is now confirmed! Please close this dialog box and login.
                  </div>
                </div>

                <div class="alert alert-danger alert-danger-code hidden-element">
                  <b>Error:</b> {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Close</v-btn>
                    <v-btn @click="clickSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import auth from '@/assets/js/cognitoAuth'

  export default {
    name: 'QRCodeModal',
    props: ['codeProps'],
    components: {},
    data() {
      return {
        loadModal: false,
        hasError: false,
        errorMessage: '',
        password: '',
        user: {},
      }
    },
    computed: {
      validatePassword() {
        return this.password !== ''
      },
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      clickSave() {
        this.hasError = false
        if (!this.validatePassword) {
          this.hasError = true
          return
        }
        // this.user.username = this.user.challengeParam.USER_ID_FOR_SRP
        //console.log('verifyOtp ' + this.password);
        auth.verifyOtp(this.user, this.password, true).then(async (result) => {
            if (result) {
              this.password = ""
              utilities.showAlert('.alert-success')
            } else {
              this.errorMessage = "Code mismatch and fail enable Software Token MFA"
              utilities.showAlert('.alert-danger-code')
            }

            // var userToSave = await utilities.getUserBySub(user.signInUserSession.accessToken.payload['sub'])
            // if (userToSave != undefined) {
            //   this.$store.dispatch('user/setUser', userToSave)
            //   console.log(userToSave)
            //   console.log('User:')
            //   console.log(this.$store.getters['user/getUser'])
            //   // Zoho Integration
            //   // fetch('https://vtjfl2u95a.execute-api.ap-southeast-2.amazonaws.com/RHOReferenceData/zoho-login').then((response) => console.log(response))
            //   // this.$parent.redirectToHome()
            // } else {
            //   var callback = () => {
            //     this.$store.dispatch('user/setAuthentication', utilities.initAuthentication())
            //     this.$router
            //       .push({
            //         name: 'login',
            //       })
            //       .catch((err) => {})
            //   }
            //   // auth.logout(callback)
            // }

          })
          .catch((e) => {
            utilities.showErrorMessage(e.message)
          })
      },
    },
    beforeMount() {},
    mounted() {},
    updated() {
    },
  }
</script>
