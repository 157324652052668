/* eslint-disable */
<template>
  <div class="horizontal-year-calendar">
    <v-btn-toggle v-model="year" mandatory class="pl-3 pt-3">
      <v-btn class="radio-toggle" :value="getYear()" @click="changeYear(getYear())">
        {{ getYear() }}
      </v-btn>
      <v-btn v-if="getShowNextYear()" class="radio-toggle" :value="getNextYear()" @click="changeYear(getNextYear())">
        {{ getNextYear() }}
      </v-btn>
    </v-btn-toggle>
    <v-sheet>
      <calendar
        :key="calendarKey"
        :year="year"
        :display-header="false"
        :sync="true"
        :data-source="dataSource"
        @mouse-on-day="mouseOnDay"
        render-style="background"
        :customDayRenderer="customDayRenderer"
        @render-end="hideMonths()"
      />
    </v-sheet>
    <div style="display: flex; justify-content: space-between; padding-top: 15px; padding-bottom: 15px;" class="">
      <div class="box-col">
        {{ messageProps }}
      </div>
      <div class="box-col calender-item">
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-video"></div>
          <div class="indicator-text pe-1">Video appointments Only</div>
          <img style="height: 30px" class="me-2" :src="require('@/assets/images/video_black_icon.svg')"  />
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-video-person"></div>
          <div class="indicator-text pe-1">Face to Face or Video Link Appointments</div>
          <img style="height: 30px" class="me-2" :src="require('@/assets/images/user_video_icon_black.svg')"  />
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-approved"></div>
          <div class="indicator-text">Face to Face</div>
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-fullbook"></div>
          <div class="indicator-text">Wait List</div>
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-request"></div>
          <div class="indicator-text">By Request Only</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import tippy from 'tippy.js'
  import 'tippy.js/dist/tippy.css'

  export default {
    name: 'ClientHorizontalCalendar',
    props: ['calendarDateProps', 'dataSourceProps', 'messageProps', 'hideMonthProps', 'displayOnly', 'mode', 'location', 'showAbuse', 'showWorkCover', 'showOnlyAvailable'],
    components: {},
    data() {
      return {
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        dataSource: this.dataSourceProps !== undefined ? this.dataSourceProps : [],
        filteredSource: [],
        calendarKey: 0,
        year: this.calendarDateProps,
        noFaceToFaceMsg: null
      }
    },
    watch: {
      dataSourceProps: {
        handler: function (val) {
          this.dataSource = val
          this.calendarKey += 1
        }
      },
      changeProps: {
        immediate: true,
        handler: function () {
          this.filteredSource = []
          if (this.mode === 'video') {
            this.dataSourceProps.forEach(item => {
              const filteredArr = item.Availabilities.filter(a => (a.Options.VideoOnly || a.Options.VideoLinkAvailable) )
              const obj = _.cloneDeep(item)
              obj.status = (obj.status !== 'request only' && obj.status !== 'fully booked') ? 'video only' : obj.status
              obj.Availabilities = filteredArr
              if(filteredArr.length > 0) this.filteredSource = this.filteredSource.concat(obj)
            })
            if(this.dataSourceProps.length > 0) {
              if(this.filteredSource.length === 0) {
                this.noFaceToFaceMsg = 'No Videolink appointments match your search, however we have recommended the following video link assessments'
                this.$emit('showNoFaceToFace', true,  this.noFaceToFaceMsg)
              } else {
                this.noFaceToFaceMsg = null
                this.$emit('showNoFaceToFace', false)
              }
            }
          } else if (this.mode === 'person') {
            this.dataSourceProps.forEach(item => {
              const filteredArr = item.Availabilities.filter(a => (a.Options.VideoLinkAvailable || (!a.Options.VideoLinkAvailable && !a.Options.VideoOnly ) ) && a.RegionID === Number(this.location.replace('r', '')))
              const obj = _.cloneDeep(item)
              obj.status = (obj.status !== 'request only' && obj.status !== 'fully booked') ? 'available' : obj.status
              obj.Availabilities = filteredArr
              if(filteredArr.length > 0)this.filteredSource = this.filteredSource.concat(obj)
            })
            if(this.dataSourceProps.length > 0) {
              if(this.filteredSource.length === 0) {
                this.noFaceToFaceMsg = 'No Face to Face appointments match your search, however we have recommended the following video link assessments'
                this.$emit('showNoFaceToFace', true,  this.noFaceToFaceMsg)
              } else {
                this.noFaceToFaceMsg = null
                this.$emit('showNoFaceToFace', false)
              }
            }
          }
          this.dataSource = []
          if(this.dataSourceProps.length > 0) {
            if(this.filteredSource.length === 0) {
              this.noFaceToFaceMsg = 'No Face to Face appointments match your search, however we have recommended the following video link assessments'
              this.$emit('showNoFaceToFace', true)
            } else {
              this.noFaceToFaceMsg = null
              this.$emit('showNoFaceToFace', false)
            }
          }
          this.$nextTick(() => {
            this.dataSource = this.filteredSource
          })
      }},
      mode: {
        handler: function(val) {
          this.filteredSource = []
          if (val === 'video') {
            this.dataSourceProps.forEach(item => {
              const filteredArr = item.Availabilities.filter(a => (a.Options.VideoOnly || a.Options.VideoLinkAvailable) )
              const obj = _.cloneDeep(item)
              obj.status = (obj.status !== 'request only' && obj.status !== 'fully booked') ? 'video only' : obj.status
              obj.Availabilities = filteredArr
              if(filteredArr.length > 0) this.filteredSource = this.filteredSource.concat(obj)
            })
            if(this.dataSourceProps.length > 0) {
              if(this.filteredSource.length === 0) {
                this.noFaceToFaceMsg = 'No Videolink appointments match your search, however we have recommended the following video link assessments'
                this.$emit('showNoFaceToFace', true,  this.noFaceToFaceMsg)
              } else {
                this.noFaceToFaceMsg = null
                this.$emit('showNoFaceToFace', false)
              }
            }
          } else if (val === 'person') {
            this.dataSourceProps.forEach(item => {
              const filteredArr = item.Availabilities.filter(a => (a.Options.VideoLinkAvailable || (!a.Options.VideoLinkAvailable && !a.Options.VideoOnly ) ) && a.RegionID === Number(this.location.replace('r', '')))
              const obj = _.cloneDeep(item)
              obj.status = (obj.status !== 'request only' && obj.status !== 'fully booked') ? 'available' : obj.status
              obj.Availabilities = filteredArr
              if(filteredArr.length > 0)this.filteredSource = this.filteredSource.concat(obj)
            })
            if(this.dataSourceProps.length > 0) {
              if(this.filteredSource.length === 0) {
                this.noFaceToFaceMsg = 'No Face to Face appointments match your search, however we have recommended the following video link assessments'
                this.$emit('showNoFaceToFace', true,  this.noFaceToFaceMsg)
              } else {
                this.noFaceToFaceMsg = null
                this.$emit('showNoFaceToFace', false)
              }
            }
          }
          this.$nextTick(() => {
            this.dataSource = this.filteredSource
            this.calendarKey += 1
          })
        }
      }
    },
    computed: {
      currentMonth() {
        var currentMonth = moment()
          .date(1)
          .month()
          return currentMonth
      },
      changeProps() {
        const {showAbuse, showWorkCover, showOnlyAvailable} = this
        return {showAbuse, showWorkCover, showOnlyAvailable}
      },
    },
    mounted() {
      window.goToBook = this.goToBook
      window.showClientModal = this.showClientModal
    },
    methods: {
      getShowNextYear() {
        if(this.hideMonthProps?.endDate && Number(window.moment(this.hideMonthProps.endDate).format('YYYY')) > Number(window.moment().format('YYYY'))) {
          return true
        } else {
          return false
        }
      },
      showClientModal(e) {
        let content = '<div style="padding: 10px"><b>Show only to these clients:</b>'
        e.forEach(item => {
          content += `<div>${decodeURIComponent(item.ClientName)}</div>`
        })
        content += '</div>'
        this.$notify({
          message: content,
          dangerouslyUseHTMLString: true,
          duration: 10000
        });
        // this.$alert(content, 'Show only to these clients', {
        //   dangerouslyUseHTMLString: true
        // });
        console.log(e)
      },
      goToBook(e, item) {
        this.$parent.tapAvailableSlot(e, item)
      },
      customDayRenderer(element, date) {
        let filter = this.dataSource.find(item => moment(item.date).isSame(moment(date), 'day'))
        if (filter && filter.status === 'request only') {
          element.parentElement.classList.add('calender-request')
        } else if (filter && filter.status === 'submitted') {
          element.parentElement.classList.add('calender-submited')
        } else if (filter && filter.status === 'fully booked') {
          element.parentElement.classList.add('calender-fullbook')
        } else if (filter && filter.status === 'video link') {
          element.parentElement.classList.add('calender-video-person')
        } else if (filter && filter.status === 'video only') {
          element.parentElement.classList.add('calender-video')
        } else if (filter && filter.status === 'available'){
          element.parentElement.classList.add('calender-approved')
        }
      },
      mouseOnDay: function (e) {
        if (e.events.length > 0) {
          var content = ''
          for (var i in e.events) {
            content +=
                `<div class="event-tooltip-content overflow-y-auto" style="max-height: 500px">`
            e.events[i].Availabilities.sort((a, b) => {
              return window.moment(a.StartTime, 'HH:mm').isAfter(window.moment(b.StartTime, 'HH:mm')) ? 1 : -1
            }).forEach(item => {
              let obj = {
                status: e.events[i].status,
                specialistId: e.events[i].specialistId,
                date: e.events[i].date,
              }

              content += `<div class="border-bottom cursor-pointer d-flex flex-column align-items-baseline pt-1 pb-1">
<div onclick='goToBook(${JSON.stringify(obj)}, ${JSON.stringify(item)})' class="d-flex align-items-center justify-content-between w-100">
<span>${e.events[i].fullName ? e.events[i].fullName : ''}</span>
${item.AvailabilityStatus === '1' ? '<span class="ml-2 blue--text">Unconfirmed</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'A' ? '<span class="ml-2 green--text">Unbooked</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'B' ? '<span class="ml-2 red--text">Booked</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'H' ? '<span class="ml-2 red--text">On hold</span>' : ''}
</div>
<div class="d-flex justify-content-between align-items-center w-100">
<span onclick='goToBook(${JSON.stringify(obj)}, ${JSON.stringify(item)})'>${utilities.formatDate(item.StartTime, 'HH:mm', 'HH:mm:ss')} ~ ${utilities.formatDate(item.EndTime, 'HH:mm', 'HH:mm:ss')}</span>
<div class="d-flex align-items-center ml-2">
${item.Options.VideoOnly ? '<div class="video-icon"></div>' : '' }
${item.Options.VideoLinkAvailable ? '<div class="user-video-icon"></div>' : '' }
${(!item.Options.VideoLinkAvailable && !item.Options.VideoOnly && !item.Options.ByRequestOnly) ? '<div class="face-icon"></div>' : '' }
${item.Options.ShowWorkCover && this.user.type >= 3 ? '<div class="show-worker-icon"></div>' : '' }
${item.Options.ShowSpecificClients && this.user.type >= 3 ? `<div onclick='showClientModal(${JSON.stringify(item.SpecificClients)})' class="specific-client-icon"></div>` : '' }
${item.Options.ByRequestOnly ? '<div class="request-icon"></div>' : '' }
</div>
</div>
<span onclick='goToBook(${JSON.stringify(obj)}, ${JSON.stringify(item)})'>${item.LocationName ? decodeURIComponent(item.LocationName) : ''}</span>
</div>`
            })
          }

          if (this.tooltip != null) {
            this.tooltip.destroy()
            this.tooltip = null
          }

          this.tooltip = tippy(e.element, {
            placement: 'right',
            content: content,
            trigger: 'click',
            interactive: true,
            interactiveBorder: 1000,
            interactiveDebounce: 10000,
            allowHTML: true,
            arrow: true,
            arrowType: 'round',
            delay: 1000,
            onShown(instance) {
              if (e.events.length > 3) {
                $(".tippy-box .tippy-content").css("height", "330px")
              } else {
                $(".tippy-box .tippy-content").css("height", "auto")
              }
            },
          })
          this.tooltip.show()
        } else {
          this.tooltip && this.tooltip.hide()
        }
      },
      mouseOutDay: function(e) {
        if(this.tooltip != undefined) this.tooltip.hide()
        if(this.tooltip != undefined) this.tooltip.hide()
        if(this.tooltip != undefined) this.tooltip.hide()
      },

      async updateAvailability(id, selfId, parentId = '') {
        this.dataSource
          .filter((x) => x.selfId == selfId && x.id == id)
          .map((x) => {
            x.availabilityStatus = 3
            x.color = utilities.getBookingStatusColor(x.availabilityStatus)
            if(x.availabilityStatus === 3 && x.hasUserVideo) {
              x.color = 'linear-gradient(-45deg, orange 50%, transparent 0),linear-gradient(-45deg, transparent 50%, #56C568 0);'
            }
          })
        this.calendarKey += 1

        if (parentId != '') {
          await this.$parent.approveAvailability(id, parentId)
          this.$parent.successMessage = 'Availability updated successfully.'
          utilities.showAlert('.year-calendar .alert-success')
        }
      },
      removeAvailability(id, selfId) {
        this.dataSource = this.dataSource.filter((x) => {
          return !(x.selfId == selfId && x.id == id)
        })
      },
      goToDay(e) {
        if (e.events.length > 0 && (e.events[0].isRequestOnly && this.$parent.user.type < 3)) {
          return
        }
        if(e.events.length == 1 && e.events[0] != undefined) {
          if((e.events[0].name == "Wait List" && !e.events[0].available) || e.events[0].isWaitlist) {
            this.$parent.tapWaitList(e.events[0])
          } else if (e.events[0].available) {
            this.$parent.tapAvailable(e.events[0])
          } else {
            this.$parent.tapAvailableSlot(e.events[0])
          }
        } else {
          var availabilty = e.events.find(x => !x.isWaitlist)
          if(availabilty != undefined) {
            this.$parent.tapAvailableSlot(availabilty)
          } else if(e.events[0] != undefined && e.events[0].isWaitlist) {
            this.$parent.tapWaitList(e.events[0])
          }
        }
      },
      // hideMonths() {
      //   var currentMonth = moment()
      //     .date(1)
      //     .month()
      //   for (var i = 0; i < currentMonth; i++) {
      //     $('.month-container[data-month-id="' + i + '"]').hide()
      //   }
      //
      //   if (this.hideMonthProps) {
      //     for (i = currentMonth + 3; i < 12; i++) {
      //         $('.month-container[data-month-id="' + i + '"]').hide()
      //     }
      //   }
      //   this.dataSource = this.dataSourceProps
      // },

      hideMonths() {
        this.dataSource = []
        this.dataSource = this.mode ? this.filteredSource: this.dataSourceProps
        utilities.hideMonthsClientCalendar(this.year, this.hideMonthProps?.startDate, this.hideMonthProps?.endDate)
      },
      getYear() {
        return this.hideMonthProps?.startDate ? moment(this.hideMonthProps.startDate).format('YYYY') : moment().format('YYYY')
      },
      getNextYear() {
        return moment()
          .add(1, 'y')
          .format('YYYY')
      },
      changeYear(year) {
        this.year = year
        this.dataKey += 1
      },

    },
    beforeMount() {},
    updated() {
      // utilities.showCurrentMonth()
      var currentMonth = moment()
        .date(1)
        .month()
      // for (var i = 0; i < currentMonth; i++) {
      //   $('.month-container[data-month-id="' + i + '"]').hide()
      // }
      // // Hide after current month + 3
      // currentMonth = moment()
      //   .date(1)
      //   .month()
      //
      for (var i = 0; i < 12; i++) {
        setTimeout(() => {
          $('.month-container[data-month-id="' + i + '"]').hide()
        }, 1000)
      }
    },
  }
</script>
