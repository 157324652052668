<template>
  <div class="search-claimant-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Claimant Search</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Claimant First Name</label>
                    <input type="text" class="textbox claimant-name" v-model="searchOption.claimantFirstname" />
                  </div>
                  <div class="col">
                    <label>Claimant Surname</label>
                    <input type="text" class="textbox claimant-name" v-model="searchOption.claimantSurname" />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input type="text" class="textbox hidden-element" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="search">Search</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AdminSearchClaimantModal",
  data() {
    return {
      loadModal: false,

      searchOption: this.initSearchOption(),
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
        setTimeout(() => {
          $('.search-claimant-modal form').on('keypress', (e) => {
            if (e.keyCode == 13) {
              this.search()
            }
          })
          $('.search-claimant-modal .claimant-name').focus()
        }, 500)
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
    initSearchOption() {
      return {
        claimantFirstname: "",
        claimantSurname: ""
      }
    },

    async search() {
      if(this.searchOption.claimantFirstname == "" && this.searchOption.claimantSurname == "") {
        return false
      }

      this.$store.dispatch("loading/setLoadComponent", true)
      if(this.$route.name == "admin-cases") {
        await this.$parent.$parent.$parent.searchCase(this.searchOption)
      } else {
        await utilities.delay(1000)
        this.$router.push({ name: 'admin-cases', params: { searchOptionProps: this.searchOption } }).catch((err) => {})
      }
      this.handleModal()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  beforeMount() {},
  mounted() {},
}
</script>
