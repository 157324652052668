<template>
  <div class="create-client-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>New Client</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form class="mb-10">
              <div class="box-body">
                <div class="box-row">
                  <div class="box-col box-col-left">Company Name <span class="text-danger">*</span></div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="clientRequest.companyName" class="pl-3 w-100" :class="hasError && !validateCompanyName ? 'is-invalid' : ''" placeholder="Company Name" />
                    <div v-if="hasError && !validateCompanyName" class="text-danger">{{ companyNameErrorMessage }}</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Branch Name</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="clientRequest.companyBranchName" class="pl-3 w-100" :class="hasError && !validateCompanyFullName ? 'is-invalid' : ''" placeholder="Branch Name" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Business Address</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="clientRequest.addressLine1" class="pl-3 w-100" placeholder="Address 1" />
                    <input type="text" v-model="clientRequest.addressLine2" class="pl-3 w-100" placeholder="Address 2" />
                    <div class="pl-3 w-60 d-flex">
                      <div class="w-25">City</div>
                      <div class="w-70">
                        <input type="text" v-model="clientRequest.city" class="pl-3 w-100" placeholder="City" />
                      </div>
                    </div>
                    <div class="pl-3 d-flex">
                      <div class="w-60 d-flex">
                        <div class="w-25">State <span class="text-danger">*</span></div>
                        <div class="w-70">
                          <select v-model="clientRequest.state" class="pl-3 textbox w-100" :class="hasError && !validateState ? 'is-invalid' : ''">
                            <option hidden value="">Please choose</option>
                            <option v-for="(state, index) in states" :value="state.value">{{ state.text }}</option>
                          </select>
                          <div v-if="hasError && !validateState" class="text-danger">State is required.</div>
                        </div>
                      </div>
                      <div class="w-40 d-flex">
                        <div class="w-35">Postcode <span class="text-danger">*</span></div>
                        <div class="w-60">
                          <input v-model="clientRequest.postcode" type="number" class="pl-3 w-100 postcode" :class="hasError && !validatePostcode ? 'is-invalid' : ''" placeholder="Postcode" />
                          <div v-if="hasError && !validatePostcode" class="text-danger">{{ postcodeErrorMessage }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="alert alert-success hidden-element">
              <a class="close" title="close">×</a>
              <b>Success:</b> {{ successMessage }}
            </div>
            <div class="alert alert-danger hidden-element">
              <a class="close" title="close">×</a>
              <b>Error:</b> {{ errorMessage }}
            </div>

            <div class="icon-group right-float-element">
              <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
              <v-btn class="black-button" dark @click="create()">Create</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminSearchSpecialistModal',
    data() {
      return {
        loadModal: false,
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        companyNameErrorMessage: '',
        postcodeErrorMessage: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        clientRequest: utilities.initClientRequest(),
        client: utilities.initClient(),
        states: [],
        clients: [],
      }
    },
    computed: {
      validateCompanyName() {
        if (this.clientRequest.companyName == '') {
          this.companyNameErrorMessage = "Company Name is required."
          return false
        } else if(!this.validateCompanyFullName) {
          this.companyNameErrorMessage = "The Company Name and Branch Name you have provided are already used. Please use a different name."
          return false
        }
        return true
      },
      validateCompanyFullName() {
        var companyFullName = this.clientRequest.companyBranchName != '' ? (this.clientRequest.companyName + ", " + this.clientRequest.companyBranchName) : this.clientRequest.companyName
        if(this.clients.map(x => x.fullName).includes(companyFullName)) {
          return false
        }
        return true
      },
      validateState() {
        if (this.clientRequest.state == '') {
          return false
        }
        return true
      },
      validatePostcode() {
        if (this.clientRequest.postcode == '') {
          this.postcodeErrorMessage = "Postcode is required."
          return false
        } else if (this.clientRequest.postcode.length != 4) {
          this.postcodeErrorMessage = "Postcode is invalid."
          return false
        }
        return true
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
          setTimeout(() => {
            $('.search-specialist-modal form').on('keypress', (e) => {
              if (e.keyCode == 13) {
                this.search()
              }
            })
          }, 500)
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      initModal() {
        this.hasError = false
      },
      async postClient() {
        var params = {
          ClientName: this.clientRequest.companyName,
          BranchName: this.clientRequest.companyBranchName,
          AddressLine1: this.clientRequest.addressLine1,
          AddressLine2: this.clientRequest.addressLine2,
          City: this.clientRequest.city,
          State: this.clientRequest.state,
          Postcode: this.clientRequest.postcode,
          Country: this.clientRequest.country,
          BillingCategoryID: 1,
          PrepaymentRequired: 0,
          PaymentDeferralOption: 0,
          RedHealthMayContactClaimants: 0,
          RedRescheduleEnabled: 0,

          UserID: this.user.id,
          Username: this.user.name,
        }
        // var callback = (response) => {
        //   this.client.id = response
        // }

        this.client.id = await utilities.postClient(params)
        return this.client.id
      },

      async create() {
        if(!this.validateCompanyName || !this.validateState || !this.validatePostcode) {
           this.hasError = true
           utilities.showAlert(".create-client-modal .alert-danger")
           return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        var result = await this.postClient()
        if(result != '') {
          this.successMessage = 'Client created successfully.'
          utilities.showAlert(".create-client-modal .alert-success")
          $(".icon-group .black-button").attr("disabled", true)
          this.$parent.client = _.cloneDeep(this.client)
          await this.$parent.loadData()
          setTimeout(() => {
            this.handleModal()
          }, 2000)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      loadData() {
        this.states = utilities.getStates()
        this.clients = this.$parent.clients
      },
    },
    beforeMount() {},
    mounted() {},
  }
</script>
