/* eslint-disable */
<template>
  <div class="forgot-password p-0">
    <div class="red-vertical"></div>
    <div class="login-container">
      <div class="login-left">
        <div class="logo">
          <router-link :to="{ path: '/' }"><img src="@/assets/images/red_health_logo.svg"/></router-link>
        </div>
        <v-form ref="form" class="login-form">
          <v-subheader>Email Address</v-subheader>
          <!-- <v-text-field
            v-model="email"
            :rules="[rules.validateEmail.required, rules.validateEmail.incorrect, rules.validateEmail.noExisted]"
            placeholder="Enter your Email Address"
            solo
            required
          ></v-text-field> -->
          <v-text-field
            v-model="email"
            placeholder="Enter your Email Address"
            solo
            required
          ></v-text-field>
          <br /><br />

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="icon-group right-float-element">
            <v-btn class="white-button" dark @click="$router.go(-1)">Cancel</v-btn>
            <v-btn class="red-button" dark @click="sendEmail">Send Email</v-btn>
          </div>
        </v-form>
        <ForgotPasswordModal ref="forgotPasswordModal" />
      </div>
      <div class="login-right">
        <div class="banner">
          <img src="@/assets/images/banner.png" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import auth from '@/assets/js/cognitoAuth'
  import utilities from '@/assets/js/utilities'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'
  import ForgotPasswordModal from '@/components/ForgotPasswordModal'

  export default {
    name: 'ForgotPassword',
    components: {
      Footer,
      Chat,
      ForgotPasswordModal,
    },
    data() {
      return {
        successMessage: 'Thank you. We have sent you an email containing a link allowing you to reset your password.',
        errorMessage: 'Please try after some time.',

        email: '',

        rules: {
          validateEmail: {
            required: (value) => !!value || 'Email is required.',
            incorrect: (value) => !!utilities.validateEmail(value) || 'Email is incorrect.',
            noExisted: (value) => !!this.users.map((x) => x.email).includes(value) || 'The email address you entered is not a registered user.',
          },
        },

        users: [],
      }
    },
    methods: {
      success() {
        this.$store.dispatch('loading/setLoadComponent', false)
        utilities.showAlert('.alert-success')
        setTimeout(() => {
          this.$router.push({ name: 'login' }).catch((err) => {})
        }, 2000)
      },
      async sendEmail() {
        // if (!this.$refs.form.validate()) {
        //   return false
        // }

        this.$store.dispatch('loading/setLoadComponent', true)
        var authenticationData = {
          Username: this.email,
        }
        try {
          const result = await utilities.checkEmailValidate(this.email)
          if(result) {
            try {
              auth.forgotPassword(authenticationData).then((res) => {
                if (res) {
                  this.$store.dispatch('loading/setLoadComponent', false)
                  this.$refs.forgotPasswordModal.handleModal()
                } else {
                  this.$store.dispatch('loading/setLoadComponent', false)
                  utilities.showAlert('.alert-danger')
                }
              })
            } catch (e) {
              this.$store.dispatch('loading/setLoadComponent', false)
              utilities.showAlert('.alert-danger')
            }
          } else {
            this.$store.dispatch('loading/setLoadComponent', false)
            this.$notify({
              dangerouslyUseHTMLString: true,
              message: '<div class="alert-warning">' +
                  '            <b>Warning:</b> Email address not exist' +
                  '          </div>',
            });
          }
        } catch (e) {
          this.$store.dispatch('loading/setLoadComponent', false)
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-warning">' +
                '            <b>Warning:</b> ' + e +
                '          </div>',
          });
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async loadData() {
        this.users = []
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadData()
      var result = ''
      var blob = await utilities.getBannerImage()
      await new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onloadend = function() {
          result = reader.result
          resolve(true)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(blob)
      })
      $('.banner img').attr('src', result)

      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $('.forgot-password form').on('keypress', (e) => {
        if (e.keyCode == 13) {
          this.sendEmail()
        }
      })
    },
  }
</script>
