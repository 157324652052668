/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import auth from '@/assets/js/cognitoAuth'
import utilities from '@/assets/js/utilities'

import Zoho from '@/components/Zoho'

import AdminHome from '@/components/AdminHome'
import AdminBookingFileLog from '@/components/system/AdminBookingFileLog'
import AdminViewSearchLog from '@/components/AdminViewSearchLog'
import AdminAvailabilityRequireTravel from '@/components/AdminAvailabilityRequireTravel'
import AdminBookings from '@/components/AdminBookings'
import AdminBookingsResult from '@/components/AdminBookingsResult'
import AdminBookingRequests from '@/components/AdminBookingRequests'
import AdminBookingRequestForm from '@/components/AdminBookingRequestForm'
import AdminManageBookingRequest from '@/components/AdminManageBookingRequest'
import AdminBookingsAwaitingDictation from '@/components/AdminBookingsAwaitingDictation'
import AdminBookingsRequiringAction from '@/components/AdminBookingsRequiringAction'
import AdminBookingClashes from '@/components/AdminBookingClashes'
import AdminBookingsAwaitingPaperwork from '@/components/AdminBookingsAwaitingPaperwork'
import AdminBookingsAwaitingConsent from '@/components/AdminBookingsAwaitingConsent'
import AdminBookingsUnconfirmed from '@/components/AdminBookingsUnconfirmed'
import AdminBookingsWaitlisted from '@/components/AdminBookingsWaitlisted'
import AdminBookingsTentative from '@/components/AdminBookingsTentative'
import AdminBookingsCancelled from '@/components/AdminBookingsCancelled'
import AdminManageBooking from '@/components/AdminManageBooking'
import AdminSpecialists from '@/components/AdminSpecialists'
import AdminManageSpecialist from '@/components/AdminManageSpecialist'
import AdminManageSpecialistFee from '@/components/AdminManageSpecialistFee'
import AdminAvailabilities from '@/components/AdminAvailabilities'
import AdminNextAvailabilitySpecialty from '@/components/AdminNextAvailabilitySpecialty'
import AdminNextAvailabilitySpecialist from '@/components/AdminNextAvailabilitySpecialist'
import AdminManageAvailability from '@/components/AdminManageAvailability'
import AdminAvailabilityBookings from '@/components/AdminAvailabilityBookings'
import AdminAvailabilityBookingsReschedule from '@/components/AdminAvailabilityBookingsReschedule'
import AdminPurchaseOrders from '@/components/AdminPurchaseOrders'
import AdminManagePurchaseOrder from '@/components/AdminManagePurchaseOrder'
import AdminTravels from '@/components/AdminTravels'
import AdminManageTravel from '@/components/AdminManageTravel'
import AdminClients from '@/components/AdminClients'
import AdminClientContacts from '@/components/AdminClientContacts'
import AdminEditClientContact from '@/components/AdminEditClientContact'
import AdminManageClient from '@/components/AdminManageClient'
import AdminClientRequests from '@/components/AdminClientRequests'
import AdminManageClientRequest from '@/components/AdminManageClientRequest'
import AdminSpecialistTravel from '@/components/AdminSpecialistTravel'
import AdminSpecialistTravelArchive from '@/components/AdminSpecialistTravelArchive'
import AdminSpecialistBookings from '@/components/AdminSpecialistBookings'
import AdminSpecialistBookingsArchive from '@/components/AdminSpecialistBookingsArchive'
import AdminSpecialistBookingsCalendar from '@/components/AdminSpecialistBookingsCalendar'
import AdminUpdatedCases from '@/components/AdminUpdatedCases'
import AdminCases from '@/components/AdminCases'
import AdminCasesResult from '@/components/AdminCasesResult'
import AdminManageCase from '@/components/AdminManageCase'
import AdminSettings from '@/components/AdminSettings'
import AdminRelatedSkills from '@/components/AdminRelatedSkills'
import AdminRelatedServices from '@/components/AdminRelatedServices'
import AdminLocationsAndRegions from '@/components/AdminLocationsAndRegions'
import AdminManageLocation from '@/components/AdminManageLocation'
import AdminRegions from '@/components/system/AdminRegions'
import AdminLocations from '@/components/AdminLocations'
import AdminReports from '@/components/AdminReports'
import AdminHoursBooked from '@/components/AdminHoursBooked'
import AdminHoursBookedByClient from '@/components/AdminHoursBookedByClient'
import AdminHoursBookedByClientPerMonth from '@/components/AdminHoursBookedByClientPerMonth'
import AdminHoursAvailable from '@/components/AdminHoursAvailable'
import AdminHoursBookedBySpecialist from '@/components/AdminHoursBookedBySpecialist'
import AdminUsers from '@/components/AdminUsers'
import AdminManageUser from '@/components/AdminManageUser'
import AdminHoursBookedByLocation from '@/components/AdminHoursBookedByLocation'
import AdminBillingCategories from '@/components/AdminBillingCategories'
import AdminTravelDetail from '@/components/AdminTravelDetail'
import AdminCreateNewBooking from '@/components/AdminCreateNewBooking'
import AdminManageCertifications from '@/components/AdminManageCertifications'
import AdminManagePersonConditions from '@/components/AdminManagePersonConditions'

import Home from '@/components/Home'
import Specialists from '@/components/Specialists'
import SearchSpecialist from '@/components/SearchSpecialist'
import ExportSpecialist from '@/components/ExportSpecialist'
import ManageBooking from '@/components/ManageBooking'
import SupplementaryServiceRequest from '@/components/SupplementaryServiceRequest'
import EditBooking from '@/components/EditBooking'
import BookingRequest from '@/components/BookingRequest'
import UpcomingBookings from '@/components/UpcomingBookings'
import BookingsInProgress from '@/components/BookingsInProgress'
import BookingsFinalised from '@/components/BookingsFinalised'
import Cases from '@/components/Cases'
import ManageCase from '@/components/ManageCase'
import SearchCasesList from '@/components/SearchCasesList'
import BookingSingleCalendar from '@/components/BookingSingleCalendar'
import Users from '@/components/Users'
import UserSetting from '@/components/UserSetting'
import SearchCases from '@/components/SearchCases'
import AdminReferralNetwork from '@/components/AdminReferralNetwork'
import ManageSpecialist from '@/components/ManageSpecialist'
import SpecialistSettings from '@/components/SpecialistSettings'
import SpecialistProfile from '@/components/SpecialistProfile'
import SpecialistAvailability from '@/components/SpecialistAvailability'
import SpecialistTravel from '@/components/SpecialistTravel'
import SpecialistTravelDetail from '@/components/SpecialistTravelDetail'
import SpecialistProgressBookings from '@/components/SpecialistProgressBooking'
import SpecialistArchive from '@/components/SpecialistArchive'
import SpecialistCourtCase from '@/components/SpecialistCourtCase'
import SpecialistAppointments from '@/components/SpecialistAppointments'
import SpecialistBookingsOnDay from '@/components/SpecialistBookingsOnDay'
import SpecialistBookingDetail from '@/components/SpecialistBookingDetail'
import SpecialistSearchBooking from '@/components/SpecialistSearchBooking'
import SpecialistAddTravelReceipt from '@/components/SpecialistAddTravelReceipt'

import ClaimantBookingAttendanceConfirmation from '@/components/ClaimantBookingAttendanceConfirmation'
import ClaimantConsentForm from '@/components/ClaimantConsentForm'

import Login from '@/components/Login'
import ChangePassword from '@/components/ChangePassword'
import ForgotPassword from '@/components/ForgotPassword'
import Registration from '@/components/Registration'
import Enquiry from '@/components/Enquiry'
import Auth from '@aws-amplify/auth'
import AdminCountries from "@/components/system/AdminCountries";
import AdminStates from "@/components/system/AdminStates";
import AdminCities from "@/components/system/AdminCities";
import ServiceRequest from "@/components/ServiceRequest";
import ClientBookings from "@/components/ClientBookings";
import EditProfile from "@/components/EditProfile";
import AdminManageEmail from "@/components/AdminManageEmail";
import UserGuides from "@/components/UserGuides";
import AdminOwnershipChange from "@/components/AdminOwnershipChange";
import SpecialistHome from "@/components/SpecialistHome";
import AdminManageAutoEmail from "@/components/AdminManageAutoEmail";
import SpecialistUserGuides from "@/components/SpecialistUserGuides";

Vue.use(Router)

// Admin section
async function requireAdminAuth(to, from, next) {
 try {
   var cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
 }catch(e) {
    next({
      path: "/login"
    })
  }
  // var cognitoUser = auth.getCognitoUser()


  await auth.getCurrentLoggedInSession(cognitoUser)
  if (cognitoUser != null && cognitoUser.signInUserSession != null) {
    // Check changing object
    var originalObject = store.getters["getOriginalObject"]
    var newObject = store.getters["getNewObject"]
    var result = _.isEqual(originalObject, newObject)
    if(!result) {
      var answer = await utilities.showWarningDialog(utilities.WARNING_LOST_DATA)
      if(!answer) return false
    }

    // Clear changing object
    utilities.clearChangingObject()

    var authentication = utilities.loadAuthentication(cognitoUser.signInUserSession)
    if(authentication.group == "RHOAdminGroup" || authentication.group == "RHOFinanceAdminGroup" || authentication.group == "RHOSuperAdminGroup") {
      next()
    } else if (authentication.group == "RHOClientGroup") {
      next({
        path: "/"
      })
    } else if (authentication.group == "RHOSpecialistsGroup") {
      next({
        path: "/specialist-home"
      })
    } else {
      authentication = utilities.initAuthentication()
      var callback = () => {
        store.dispatch('loading/setLoadComponent', false)
        next({
          path: "/login",
          query: { redirect: to.fullPath }
        })
      }
      auth.logout(callback)
    }
    store.dispatch("user/setAuthentication", authentication)
  } else {
    // Clear changing object
    utilities.clearChangingObject()
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    })
  }
}

async function checkAuth(to, from, next) {
  var callback = () => {
    store.dispatch("user/setAuthentication", utilities.initAuthentication())
  }
  auth.logout(callback)
  next()
}

// Client section
async function requireAuth(to, from, next) {
  try {
    var cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
  } catch(e) {
    next({
      path: "/login"
    })
  }

  // var cognitoUser = auth.getCognitoUser()
  await auth.getCurrentLoggedInSession(cognitoUser)
  if (cognitoUser != null && cognitoUser.signInUserSession != null) {
    // Check changing object
    var originalObject = store.getters["getOriginalObject"]
    var newObject = store.getters["getNewObject"]
    var result = _.isEqual(originalObject, newObject)
    if(!result) {
      if(from.name == 'manage-booking' && newObject.claimantFullName != '' && newObject.dateOfBirth != '' && newObject.claimTypeName != '') {
        var answer = await utilities.showDialogFromManageBooking("Are you sure you want to leave your booking unfinished?  Your changes will be lost.")
      } else {
        var answer = await utilities.showWarningDialog(utilities.WARNING_LOST_DATA)
        // Clear changing object
        utilities.clearChangingObject()
      }
      if (!answer) return false
    }

    var authentication = utilities.loadAuthentication(cognitoUser.signInUserSession)
    if(authentication.group == "RHOAdminGroup" || authentication.group == "RHOFinanceAdminGroup" || authentication.group == "RHOSuperAdminGroup") {
      next({
        path: "/management"
      })
    } else if (authentication.group == "RHOClientGroup") {
      next()
    } else if (authentication.group == "RHOSpecialistsGroup") {
      next({
        path: "/specialist-home"
      })
    } else {
      authentication = utilities.initAuthentication()
      var callback = () => {
        store.dispatch('loading/setLoadComponent', false)
        next({
          path: "/login",
          query: { redirect: to.fullPath }
        })
      }
      auth.logout(callback)
    }
    store.dispatch("user/setAuthentication", authentication)
  } else {
    // Clear changing object
    utilities.clearChangingObject()
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    })
  }
}

// Specialist section
async function requireSpecialistAuth(to, from, next) {
  try {
    var cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
  } catch(e) {
    next({
      path: "/login"
    })
  }
  // var cognitoUser = auth.getCognitoUser()
  await auth.getCurrentLoggedInSession(cognitoUser)
  if (cognitoUser != null && cognitoUser.signInUserSession != null) {
    // Check changing object
    var originalObject = store.getters["getOriginalObject"]
    var newObject = store.getters["getNewObject"]
    var result = _.isEqual(originalObject, newObject)
    if(!result) {
      var answer = await utilities.showWarningDialog(utilities.WARNING_LOST_DATA)
      if(!answer) return false
    }

    // Clear changing object
    utilities.clearChangingObject()

    var authentication = utilities.loadAuthentication(cognitoUser.signInUserSession)
    if(authentication.group == "RHOAdminGroup" || authentication.group == "RHOFinanceAdminGroup" || authentication.group == "RHOSuperAdminGroup") {
      next({
        path: "/management"
      })
    } else if (authentication.group == "RHOClientGroup") {
      next({
        path: "/"
      })
    } else if (authentication.group == "RHOSpecialistsGroup") {
      next()
    } else {
      authentication = utilities.initAuthentication()
      var callback = () => {
        store.dispatch('loading/setLoadComponent', false)
        next({
          path: "/login",
          query: { redirect: to.fullPath }
        })
      }
      auth.logout(callback)
    }
    store.dispatch("user/setAuthentication", authentication)
  } else {
    // Clear changing object
    utilities.clearChangingObject()
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    })
  }
}

export default new Router({
  mode: "history",
  base: "/",
  routes: [
    // Admin
    {
      path: "/management",
      name: "management",
      component: AdminHome,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-view-search-log",
      name: "admin-view-search-log",
      component: AdminViewSearchLog,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-availability-require-travel",
      name: "admin-availability-require-travel",
      component: AdminAvailabilityRequireTravel,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    //Both Client and Admin accesting same file with different path name
    {
      path: "/admin-booking-single-calendar",
      name: "admin-booking-single-calendar",
      component: BookingSingleCalendar,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-bookings",
      name: "admin-bookings",
      component: AdminBookings,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-bookings-result",
      name: "admin-bookings-result",
      component: AdminBookingsResult,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-booking-requests",
      name: "admin-booking-requests",
      component: AdminBookingRequests,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-booking-request-form",
      name: "admin-booking-request-form",
      component: AdminBookingRequestForm,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-booking-request",
      name: "admin-manage-booking-request",
      component: AdminManageBookingRequest,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-bookings-awaiting-dictation",
      name: "admin-bookings-awaiting-dictation",
      component: AdminBookingsAwaitingDictation,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-bookings-requiring-action",
      name: "admin-bookings-requiring-action",
      component: AdminBookingsRequiringAction,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-booking-clashes",
      name: "admin-booking-clashes",
      component: AdminBookingClashes,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-bookings-awaiting-paperwork",
      name: "admin-bookings-awaiting-paperwork",
      component: AdminBookingsAwaitingPaperwork,
      props: true,
      beforeEnter: requireAdminAuth,
    },


    {
      path: "/admin-bookings-awaiting-consent",
      name: "admin-bookings-awaiting-consent",
      component: AdminBookingsAwaitingConsent,
      props: true,
      beforeEnter: requireAdminAuth,
    },

    {
      path: "/admin-bookings-workflow",
      name: "admin-bookings-workflow",
      component: AdminBookingsUnconfirmed,
      props: true,
      beforeEnter: requireAdminAuth,
    },

    {
      path: "/admin-bookings-waitlisted",
      name: "admin-bookings-waitlisted",
      component: AdminBookingsWaitlisted,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/bookings-waitlisted",
      name: "bookings-waitlisted",
      component: AdminBookingsWaitlisted,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/admin-bookings-tentative",
      name: "admin-bookings-tentative",
      component: AdminBookingsTentative,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-bookings-cancelled",
      name: "admin-bookings-cancelled",
      component: AdminBookingsCancelled,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-booking",
      name: "admin-manage-booking",
      component: AdminManageBooking,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-supplementary-service-request",
      name: "admin-supplementary-service-request",
      component: SupplementaryServiceRequest,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-specialists",
      name: "admin-specialists",
      component: AdminSpecialists,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-specialist",
      name: "admin-manage-specialist",
      component: AdminManageSpecialist,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-rate-card",
      name: "admin-manage-rate-card",
      component: AdminManageSpecialistFee,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-specialist-bookings",
      name: "admin-specialist-bookings",
      component: AdminSpecialistBookings,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-specialist-bookings-archive",
      name: "admin-specialist-bookings-archive",
      component: AdminSpecialistBookingsArchive,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-specialist-bookings-calendar",
      name: "admin-specialist-bookings-calendar",
      component: AdminSpecialistBookingsCalendar,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-availabilities",
      name: "admin-availabilities",
      component: AdminAvailabilities,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-referral-network",
      name: "admin-referral-network",
      component: AdminReferralNetwork,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-next-availability-specialty",
      name: "admin-next-availability-specialty",
      component: AdminNextAvailabilitySpecialty,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-next-availability-specialist",
      name: "admin-next-availability-specialist",
      component: AdminNextAvailabilitySpecialist,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-availability",
      name: "admin-manage-availability",
      component: AdminManageAvailability,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-purchase-orders",
      name: "admin-purchase-orders",
      component: AdminPurchaseOrders,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-purchase-order",
      name: "admin-manage-purchase-order",
      component: AdminManagePurchaseOrder,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-travels",
      name: "admin-travels",
      component: AdminTravels,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-travel",
      name: "admin-manage-travel",
      component: AdminManageTravel,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-clients",
      name: "admin-clients",
      component: AdminClients,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-client-contacts",
      name: "admin-client-contacts",
      component: AdminClientContacts,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-edit-client-contact",
      name: "admin-edit-client-contact",
      component: AdminEditClientContact,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-client",
      name: "admin-manage-client",
      component: AdminManageClient,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-client-requests",
      name: "admin-client-requests",
      component: AdminClientRequests,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    // {
    //   path: "/admin-ownership-change-request",
    //   name: "admin-ownership-change-request",
    //   component: AdminOwnershipChange,
    //   props: true,
    //   beforeEnter: requireAdminAuth,
    // },
    {
      path: "/admin-manage-client-request",
      name: "admin-manage-client-request",
      component: AdminManageClientRequest,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    // {
    //   path: "/admin-specialist-travel",
    //   name: "admin-specialist-travel",
    //   component: AdminSpecialistTravel,
    //   props: true,
    //   beforeEnter: requireAdminAuth,
    // },
    {
      path: "/admin-specialist-travel-archive",
      name: "admin-specialist-travel-archive",
      component: AdminSpecialistTravelArchive,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-availability-bookings",
      name: "admin-availability-bookings",
      component: AdminAvailabilityBookings,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-availability-bookings-reschedule",
      name: "admin-availability-bookings-reschedule",
      component: AdminAvailabilityBookingsReschedule,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-updated-cases",
      name: "admin-updated-cases",
      component: AdminUpdatedCases,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-cases",
      name: "admin-cases",
      component: AdminCases,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-cases-result",
      name: "admin-cases-result",
      component: AdminCasesResult,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-case",
      name: "admin-manage-case",
      component: AdminManageCase,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-settings",
      name: "admin-settings",
      component: AdminSettings,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-related-skills",
      name: "admin-related-skills",
      component: AdminRelatedSkills,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-related-services",
      name: "admin-related-services",
      component: AdminRelatedServices,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-locations-and-regions",
      name: "admin-locations-and-regions",
      component: AdminLocationsAndRegions,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-countries",
      name: "admin-countries",
      component: AdminCountries,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-cities",
      name: "admin-cities",
      component: AdminCities,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-states",
      name: "admin-states",
      component: AdminStates,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-location",
      name: "admin-manage-location",
      component: AdminManageLocation,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-regions",
      name: "admin-regions",
      component: AdminRegions,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-locations",
      name: "admin-locations",
      component: AdminLocations,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-certifications",
      name: "admin-manage-certifications",
      component: AdminManageCertifications,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-personconditions",
      name: "admin-manage-personconditions",
      component: AdminManagePersonConditions,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-email",
      name: "admin-manage-email",
      component: AdminManageEmail,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-auto-email",
      name: "admin-manage-auto-email",
      component: AdminManageAutoEmail,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-booking-file-log",
      name: "admin-manage-booking-file-log",
      component: AdminBookingFileLog,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-reports",
      name: "admin-reports",
      component: AdminReports,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-hours-booked",
      name: "admin-hours-booked",
      component: AdminHoursBooked,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-hours-booked-by-client",
      name: "admin-hours-booked-by-client",
      component: AdminHoursBookedByClient,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-hours-booked-by-client-per-month",
      name: "admin-hours-booked-by-client-per-month",
      component: AdminHoursBookedByClientPerMonth,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-hours-available",
      name: "admin-hours-available",
      component: AdminHoursAvailable,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-hours-booked-by-specialist",
      name: "admin-hours-booked-by-specialist",
      component: AdminHoursBookedBySpecialist,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-users",
      name: "admin-users",
      component: AdminUsers,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-manage-user",
      name: "admin-manage-user",
      component: AdminManageUser,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-hours-booked-by-location",
      name: "admin-hours-booked-by-location",
      component: AdminHoursBookedByLocation,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-billing-categories",
      name: "admin-billing-categories",
      component: AdminBillingCategories,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-travel-detail",
      name: "admin-travel-detail",
      component: AdminTravelDetail,
      props:true,
      beforeEnter: requireAdminAuth,
    },
    //using same search sepclist screen for Admin
    {
      path: "/admin-search-specialist",
      name: "admin-search-specialist",
      component: SearchSpecialist,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-search-cases-details",
      name: "admin-search-cases-details",
      component: SearchCasesList,
      props: true,
      beforeEnter: requireAdminAuth,
    },
    {
      path: "/admin-create-new-booking",
      name: "admin-create-new-booking",
      component: AdminCreateNewBooking,
      props:true,
      beforeEnter: requireAdminAuth,
    },

    // Main
    {
      path: "/",
      name: "home",
      component: Home,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/specialists",
      name: "specialists",
      component: Specialists,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/edit-profile",
      name: "edit-profile",
      component: EditProfile,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/search-specialist",
      name: "search-specialist",
      component: SearchSpecialist,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/export-list",
      name: "export-list",
      component: ExportSpecialist,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/manage-booking",
      name: "manage-booking",
      component: ManageBooking,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/supplementary-service-request",
      name: "supplementary-service-request",
      component: SupplementaryServiceRequest,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/upcoming-bookings",
      name: "upcoming-bookings",
      component: UpcomingBookings,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/bookings-tentative",
      name: "bookings-tentative",
      component: AdminBookingsTentative,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/all-bookings",
      name: "all-bookings",
      component: ClientBookings,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/service-request",
      name: "service-request",
      component: ServiceRequest,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/bookings-in-progress",
      name: "bookings-in-progress",
      component: BookingsInProgress,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/bookings-finalised",
      name: "bookings-finalised",
      component: BookingsFinalised,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/edit-booking",
      name: "edit-booking",
      component: EditBooking,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/booking-request",
      name: "booking-request",
      component: BookingRequest,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/cases",
      name: "cases",
      component: Cases,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/manage-case",
      name: "manage-case",
      component: ManageCase,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/search-cases-details",
      name: "search-cases-details",
      component: SearchCasesList,
      props: true,
      beforeEnter: requireAuth,
    },
    //Both Client and Admin accesting same file with different path name
    {
      path: "/booking-single-calendar",
      name: "booking-single-calendar",
      component: BookingSingleCalendar,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/users",
      name: "users",
      component: Users,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/setting",
      name: "setting",
      component: UserSetting,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/search-cases",
      name: "search-cases",
      component: SearchCases,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/user-guides",
      name: "user-guides",
      component: UserGuides,
      props: true,
      beforeEnter: requireAuth,
    },
    {
      path: "/manage-specialist",
      name: "manage-specialist",
      component: ManageSpecialist,
      props: true,
      beforeEnter: requireAuth,
    },

    // Specialist
    {
      path: "/specialist-home",
      name: "specialist-home",
      component: SpecialistHome,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-user-guides",
      name: "specialist-user-guides",
      component: SpecialistUserGuides,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-progress-bookings",
      name: "specialist-progress-bookings",
      component: SpecialistProgressBookings,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-settings",
      name: "specialist-settings",
      component: SpecialistSettings,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-profile",
      name: "specialist-profile",
      component: SpecialistProfile,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-availability",
      name: "specialist-availability",
      component: SpecialistAvailability,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-travel",
      name: "specialist-travel",
      component: SpecialistTravel,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-travel-detail",
      name: "specialist-travel-detail",
      component: SpecialistTravelDetail,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-finished",
      name: "specialist-finished",
      component: SpecialistArchive,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-appointments",
      name: "specialist-appointments",
      component: SpecialistAppointments,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-court-cases",
      name: "specialist-court-cases",
      component: SpecialistCourtCase,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-bookings-day",
      name: "specialist-bookings-day",
      component: SpecialistBookingsOnDay,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-booking-detail",
      name: "specialist-booking-detail",
      component: SpecialistBookingDetail,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-search-booking",
      name: "specialist-search-booking",
      component: SpecialistSearchBooking,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },
    {
      path: "/specialist-add-travel-receipt",
      name: "specialist-add-travel-receipt",
      component: SpecialistAddTravelReceipt,
      props: true,
      beforeEnter: requireSpecialistAuth,
    },

    // Claimant
    {
      path: "/claimant-booking-attendance-confirmation",
      name: "claimant-booking-attendance-confirmation",
      component: ClaimantBookingAttendanceConfirmation,
      props: true
    },
    {
      path: "/claimant-consent-form",
      name: "claimant-consent-form",
      component: ClaimantConsentForm,
      props: true
    },

    // Common
    {
      path: "/login",
      name: "login",
      component: Login,
      props: true,
      beforeEnter: checkAuth,
    },
    {
      path: "/change-password",
      name: "change-password",
      component: ChangePassword,
      props: true,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      props: true,
    },
    {
      path: "/registration",
      name: "registration",
      component: Registration,
      props: true,
    },
    {
      path: "/enquiry",
      name: "enquiry",
      component: Enquiry,
      props: true,
    },
    {
      path: "/Zoho",
      name: "Zoho",
      component: Zoho,
      props: true,
    },
  ]
})
