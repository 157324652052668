<template>
  <div class="manage-education-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>Specialist {{ specialist.fullName }} 's Education</h1>
                </div>
                <div class="col">
                  <div class="right-float-element"><v-btn class="add-icon" dark @click="addEducation">Add New</v-btn></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div v-for="(education, index) in specialist.educations" class="box">
                  <div class="box-heading">
                    <div class="box-heading-title">{{ education.fullEducation }}</div>
                    <div class="right-element">
                      <img class="is-icon mr-5" src="@/assets/images/delete_icon.svg" @click="deleteEducation(index)"/>
                      <img v-if="!education.isOpen" src="@/assets/images/up_arrow_icon.svg" class="is-icon" @click="openEducation(index)" />
                      <img v-else src="@/assets/images/down_arrow_icon.svg" class="is-icon" @click="closeEducation(index)" />
                    </div>
                  </div>
                  <div class="box-body" :class="!education.isOpen ? 'hidden-element' : ''">
                    <div class="box-row">
                      <div class="box-col box-col-left">Qualification</div>
                      <div class="box-col box-col-right">
                        <input type="text" :class="hasError && !validateQualification(index) ? 'is-invalid' : ''" v-model="education.education" placeholder="Please enter the qualification" />
                        <div v-if="hasError && !validateQualification(index)" class="text-danger">Qualification is required.</div>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">
                        Year Achieved
                      </div>
                      <div class="box-col box-col-right">
                        <input type="number" class="w-100"  v-model="education.yearObtained" placeholder="Please enter the year achieved" />
                        <!-- <div v-if="hasError && !validateYearObtained(index)" class="text-danger">Year is required.</div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> Please correct the errors shown above and try again.
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="save">Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "ManageEducationModal",
  props: ["specialistProps"],
  components: {},
  data() {
    return {
      loadModal: false,
      hasError: false,

      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      specialist: this.specialistProps != undefined ? _.cloneDeep(this.specialistProps) : utilities.initSpecialist(),
    }
  },
  computed: {
    validateEducations() {
      var result = true
      for(var i = 0; i < this.specialist.educations.length; i++) {
        result = this.validateQualification(i) && this.validateYearObtained(i)
        if(!result) {
          break
        }
      }
      return result
    },
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    async deleteEducation(index) {
      var item = this.specialist.educations[index]
      this.$confirm({
        content: 'Are you sure you want to delete this Qualifications?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          if (item.id != "") {
            var params = {
              userID: this.user.id,
              userName: this.user.name,
              id: item.id,
            }
            var result = await utilities.deleteQualificationById(params)
            if (result) {
              this.specialist.educations.splice(index, 1)

              this.specialist.educationList = ""
              this.specialist.educations.map((x, xi) => {
                x.fullEducation = utilities.getFullEducation(x)
                this.specialist.educationList += x.fullEducation
                if(xi < this.specialist.educations.length - 1) this.specialist.educationList += ", "
              })

              this.$parent.specialist.educations = this.specialist.educations
              this.$parent.specialist.educationList = this.specialist.educationList
            } else {
              this.specialist.educations.splice(index, 1)
            }
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },
    validateQualification(index) {
      if (this.specialist.educations[index].education == '') {
        return false
      }
      return true
    },
    validateYearObtained(index) {
      // if (this.specialist.educations[index].yearObtained == '') {
      //   return false
      // }
     return true
    },
    openEducation(index) {
      this.specialist.educations.map((x, xi) => {
        x.isOpen = xi == index ? true : false
      })
    },
    closeEducation(index) {
      this.specialist.educations[index].isOpen = false
    },
    addEducation() {
      this.specialist.educations.push(utilities.initSpecialistEducation())
      this.openEducation(this.specialist.educations.length - 1)
    },


    async save() {
      if (!this.validateEducations) {
        this.hasError = true
        utilities.showAlert(".manage-education-modal .alert-danger")
        return false
      } else {
        this.hasError = false
      }

      this.specialist.educationList = ""
      this.specialist.educations.map((x, xi) => {
        x.fullEducation = utilities.getFullEducation(x)
        this.specialist.educationList += x.fullEducation
        if(xi < this.specialist.educations.length - 1) this.specialist.educationList += ", "
      })

      if(this.specialist.id != '') {
        this.$store.dispatch("loading/setLoadComponent", true)
        await this.putEducation()
        this.$store.dispatch("loading/setLoadComponent", false)
      }

      this.$parent.specialist.educations = this.specialist.educations
      this.$parent.specialist.educationList = this.specialist.educationList
      this.handleModal()
    },

    putEducation() {
      var educations = []
      var education = {}
      this.specialist.educations.map((x) => {
        if (x.education != '') {
          education = {
            SpecialistEducationID: x.id,
            Education: x.education,
            YearObtained: x.yearObtained,
          }
          educations.push(education)
        }
      })

      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.specialist.id,
        EducationObject: educations,
      }
      var callback = (response) => {
        this.$parent.getSpecialistInfo()
      }
      return utilities.putSpecialist(params, callback)
    },
  },
  beforeMount() {},
  mounted() {
    $('body>.tooltip').remove()
  },
}
</script>
