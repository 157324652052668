/* eslint-disable */
<template>
  <div v-if="!userVerfied" class="p-0">
    <div class="red-vertical"></div>
    <div class="booking-attendance-confirmation">
      <div class="login-left d-flex flex-column justify-content-start" v-if="!userVerfied">
        <div class="logo"><img src="@/assets/images/red_health_logo.svg" /></div>
        <v-form ref="form" class="login-form">
          <h4 class="py-5 center-title-height">To confirm your appointment we require you to verify your details</h4>
          <p class="mb-5">For your privacy &amp; security, please enter your details in the form below:</p>
          <v-subheader class="mb-4">Please enter the surname of the person attending the booking</v-subheader>
          <v-text-field v-model="claimantSurname" :rules="[rules.validateUsername.required]" placeholder="Enter surname" solo required></v-text-field>
          <v-subheader>Please enter the person's date of birth:</v-subheader>
          <!-- <v-text-field v-model="claimant.dateOfBirth" :rules="[rules.validateDate.required]" placeholder="Enter date of birth" solo required></v-text-field> -->
          <!-- <datepicker v-model="claimant.dateOfBirth" format="dd/MM/yyyy" :typeable="true" input-class="form-control" placeholder="dd/mm/yyyy"></datepicker> -->
          <!-- <v-date-picker v-model="claimant.dateOfBirth" :rules="[rules.validateDateOfBirth.required]" placeholder="Enter date of birth"></v-date-picker> -->
          <!-- <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"> -->
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateFormatted" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <datepicker
              class="w-100"
              format="dd/MM/yyyy"
              :typeable="true"
              placeholder="dd/mm/yyyy"
              input-class="textbox"
              v-model="dateOfBirth"
              >
            </datepicker>

            <!-- <v-date-picker ref="picker" v-model="claimant.dateOfBirth" class="reset" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01"></v-date-picker> -->
          <!-- </v-menu> -->
          <p class="pt-5 mt-5">If you cannot verify your details, or if you need to discuss the appointment, please don’t hesitate to call us on: 1300 100 733 during business hours.</p>
          <br />
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{errorMessage}}
          </div>

          <div class="login-button">
            <v-btn class="red-button" dark @click="verifyUser">Verify</v-btn>
          </div>
        </v-form>
        <div class="align-self-center text-center mt-auto">
          ©2008–2020 Red Medicine Pty Ltd,<br />
          trading as Red Health Independent Medical Assessments<br />
          <router-link to="#">Privacy</router-link> <router-link to="#">Terms of Use</router-link>
        </div>
      </div>
      <div class="login-right">
        <div class="banner">
          <img src="@/assets/images/banner.png" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
  <div v-else class="consent-form">
    <div class="red-vertical"></div>
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title">
          <h1 v-if="!status">Appointment Attendance Confirmed</h1>
          <h1 v-else>Consent Form/s</h1>
        </div>
        <div v-if="!status" class="header-sub-title">Thank you for confirming that you will attend the following appointment.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content" style="min-height: 100%">
        <div class="">
          <p class="my-5">
            Dear {{singleCase.claimantFullName}}, your appointment is with: {{ singleCase.fullName }}
          </p>
          <p>
            {{ bookingDateTime }}
          </p>
          <p>
            {{ singleCase.fullLocation }}
          </p>
          <p class="mt-5 pt-5">
            If you need to discuss the appointment, please don’t hesitate to call us on: 1300 100 733 during business hours.
          </p>
        </div>
        <div class="box">
          <div class="box-heading">
            <span>Your Details</span>
          </div>
          <div class="box-body" >
            <div class="box-row">
              <div class="box-col box-col-left">Claimant Contact Details</div>
              <div class="box-col box-col-right d-flex flex-row w-75 align-items-center">
                <div v-if="!isEditContact" @click="openManageContactDetails" class="hidden-opacity-element is-icon full-width-div"></div>
                <span  v-else :key="contactKey" @click="openManageContactDetails">
                  <span v-if="singleCase.claimantPhone1" class='text-bold'>P: </span> {{ singleCase.claimantPhone1 }}
                  <span v-if="singleCase.claimantMobile" class='text-bold'>M: </span> {{ singleCase.claimantMobile }}
                  <span v-if="singleCase.claimantPhone2" class='text-bold'>L: </span> {{ singleCase.claimantPhone2 }}
                  <span v-if="singleCase.claimantEmail" class='text-bold'>E: </span> {{ singleCase.claimantEmail }}
                </span>
                <div class="edit-icon-gray ml-2"></div>
                <div class="is-icon right-float-element" @click="openManageContactDetails">
                </div>
                <AddClientContactModal ref="addClientContactModal" :typrProps="'consentForm'"/>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Claimant Address</div>
              <div class="box-col box-col-right d-flex flex-row w-75 align-items-center">
                <div v-if="!isEditAddress" class="hidden-opacity-element is-icon full-width-div" @click="openManageAddress" :key="addressKey"></div>
                <span v-else @click="openManageAddress" :key="addressKey">{{singleCase.claimantFullAddress}}</span>
                <div  class="is-icon right-float-element ml-2" @click="openManageAddress">
                <div class="edit-icon-gray"></div>
                </div>
                <AddClientAddressModal ref="addClientAddressModal" :typrProps="'consentForm'"/>
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>

        <div class="right-element">
          <v-btn class="submit-request mr-3" @click="addToCalendar()" dark>Add To Calendar</v-btn>
          <!-- <v-btn class="submit-request" @click="tapUpdate()" dark>Update</v-btn> -->
        </div>
        <div class="box-row pt-10" style="display: flex; flex-direction: column;">
          <p v-if="!status">We require you to complete the following information before your appointment by clicking the individual links below.</p>
          <p v-else>If you have not yet completed the information forms, please do them now.</p>
          <ul>
            <li v-if="froms.specialistFrom" style="list-style-type: disc; margin-left: 20px; padding: 0px; text-decoration: revert;">
              <a :href="froms.specialistFrom" target="_blank">Consent Form : <u style="color: -webkit-link;">{{froms.specialistFrom}}</u></a>
            </li>
            <li v-if="froms.from1" style="list-style-type: disc; margin-left: 20px; padding: 0px; text-decoration: revert;">
              <a :href="froms.from1" target="_blank">Consent Form : <u style="color: -webkit-link;">{{froms.from1}}</u></a>
            </li>
            <li v-if="froms.from2" style="list-style-type: disc; margin-left: 20px; padding: 0px; text-decoration: revert;">
              <a :href="froms.from2" target="_blank">Consent Form : <u style="color: -webkit-link;">{{froms.from2}}</u></a>
            </li>
            <li v-if="froms.from3" style="list-style-type: disc; margin-left: 20px; padding: 0px; text-decoration: revert;">
              <a :href="froms.from3" target="_blank">Consent Form : <u style="color: -webkit-link;">{{froms.from3}}</u></a>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <div class="align-self-center text-center" style="padding-top: 10%">
      ©2011–{{new Date().getFullYear()}} Red Medicine Pty Ltd,<br />
      trading as Red Health Independent Medical Assessments<br />
      <router-link to="#">Privacy</router-link> <router-link to="#">Terms of Use</router-link>
    </div>
  </div>

</template>

<script>
  import utilities from '@/assets/js/utilities'
  import Header from '@/components/Header'
  import AddClientContactModal from '@/components/AddClientContactModal'
  import AddClientAddressModal from '@/components/AddClientAddressModal'

  export default {
    name: 'AdminRelatedSkills',
    components: {
      Header,
      AddClientContactModal,
      AddClientAddressModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',

        // caseId: this.$route.query.ch,
        bookingId: this.$route.query.bh,
        status: this.$route.query.status != undefined ? this.$route.query.status : false,
        userVerfied: false,
        // claimant: utilities.initClaimant(),
        singleCase: [],
        booking: [],
        froms: [],
        client: [],
        claimantSurname: '',
        dateOfBirth: '',
        bookingDateTime: '',
        location: '',
        endtime: '',
        isEditContact: false,
        isEditAddress: false,
        contactKey: 0,
        addressKey: 0,
        rules: {
          validateUsername: {
            required: (value) => !!value || 'Username is required.',
          },
          validateDate: {
            required: (value) => !!value || 'Username is required.',
          },
        },
      }
    },
    computed: {
      validateSurname() {
        return this.claimantSurname.trim != ''
      },
      validateDateOfBirth() {
        return this.dateOfBirth.trim != ''
      },
      dateFormatted() {
        return moment(this.dateOfBirth).format('DD/MM/YYYY')
      },
    },
    methods: {
      // async loadData() {
      //   if (this.booking) {
      //     this.booking = await utilities.getBookingById(this.bookingId)
      //     if (this.booking == undefined) {
      //       this.userVerfied = false
      //       this.errorMessage = "Invalid booking details."
      //       utilities.showAlert('.alert-danger')
      //       return
      //     }
      //     var location = await utilities.getLocationByLocationId(this.booking.locationId)
      //     this.bookingDateTime = moment(this.booking.bookingDateTime).format('hh:mm A, dddd Do MMMM, YYYY')
      //     this.location = location.fullLocation.replace(', Australia', "")
      //     this.endTime = moment(this.booking.bookingDateTime).format('YYYY-MM-DD') + moment(this.booking.bookingEndTime).format('HH:mm')
      //     this.froms = await utilities.getConsentForm(this.booking.id)
      //     this.client = await utilities.getClientById(this.booking.clientId)
      //   }
      //
      //   if (this.caseId) {
      //     this.singleCase = await utilities.getCaseAttendanceById(this.caseId)
      //     if (this.singleCase == undefined) {
      //       this.userVerfied = false
      //       this.errorMessage = "Invalid booking details."
      //       utilities.showAlert('.alert-danger')
      //       return
      //     }
      //     if (this.singleCase.claimantPhone1 != '' || this.singleCase.claimantMobile != '' || this.singleCase.claimantPhone2 != '' || this.singleCase.claimantEmail != '') {
      //       this.isEditContact = true
      //     } else {
      //       this.isEditContact = false
      //     }
      //
      //     if (this.singleCase.claimantAddressLine1 != '' || this.singleCase.claimantAddressLine2 != '' || this.singleCase.claimantCity != '') {
      //       this.isEditAddress = true
      //       this.singleCase.claimantCountry = ""
      //     } else {
      //       this.isEditAddress = false
      //     }
      //     this.contactKey ++
      //   }
      // },

      checkDetails() {
        if (this.singleCase.claimantPhone1 != '' || this.singleCase.claimantMobile != '' || this.singleCase.claimantPhone2 != '' || this.singleCase.claimantEmail != '') {
          this.isEditContact = true
        } else {
          this.isEditContact = false
        }

        if (this.singleCase.claimantAddressLine1 != '' || this.singleCase.claimantAddressLine2 != '' || this.singleCase.claimantCity != '') {
          this.isEditAddress = true
          this.singleCase.claimantCountry = ""
        } else {
          this.isEditAddress = false
        }
        this.endTime = moment(this.singleCase.bookingDateTime).format('YYYY-MM-DD') + ' ' + moment(this.singleCase.bookingEndTime, 'HH:mm:ss').format('HH:mm')
      },

      async tapUpdate() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          BookingID: Number(this.bookingId),
          ClaimantSurname: this.claimantSurname,
          DateofBirth: moment(this.dateOfBirth).format('YYYY-MM-DD'),
          ClaimantPhone1: utilities.validatePhonenumber(this.singleCase.claimantPhone1),
          ClaimantPhone2: utilities.validatePhonenumber(this.singleCase.claimantPhone2),
          ClaimantMobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.singleCase.claimantMobile)),
          ClaimantEmail: this.singleCase.claimantEmail,
          ClaimantAddressLine1: this.singleCase.claimantAddressLine1,
          ClaimantAddressLine2: this.singleCase.claimantAddressLine2,
          ClaimantCity: this.singleCase.claimantCity,
          ClaimantState: this.singleCase.claimantState,
          ClaimantPostcode: this.singleCase.claimantPostcode,
          ClaimantCountry: 'AU',
        }
        var callback = (response) => {
          if (response !== undefined) {
            this.successMessage = 'Thank you for updating your Contact Details.'
            utilities.showAlert('.alert-success')
          }
        }
        await utilities.putClaimantContactNoAuth(params, callback)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      openManageContactDetails() {
        this.$refs.addClientContactModal.singleCase = this.singleCase
        this.$refs.addClientContactModal.booking = this.booking
        this.$refs.addClientContactModal.handleModal()
        this.$refs.addClientContactModal.setCaseContactDetails()
      },

      openManageAddress() {
        this.$refs.addClientAddressModal.singleCase = this.singleCase
        this.$refs.addClientAddressModal.isClient = false
        this.$refs.addClientAddressModal.fromConsentForm()
        this.$refs.addClientAddressModal.handleModal()
        this.$refs.addClientAddressModal.setCaseAddressDetails()
      },


      async verifyUser() {
        if (!this.$refs.form.validate()) {
          return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          ClaimantSurname: this.claimantSurname,
          DateofBirth: window.moment(this.dateOfBirth).format('YYYY-MM-DD'),
          BookingID: Number(this.bookingId),
          Status: this.status,
        }

        if (this.client.redHealthMayContactClaimants && this.booking.claimantEmail.length > 0) {
          params.ClaimantEmailAddress = this.booking.claimantEmail
        } else {
          params.CaseOwner = this.booking.caseOwner
        }
        var res = await utilities.postClaimantVerification(params)
        if (res) {
          var params = {
            BookingID: Number(this.bookingId),
            ClaimantConfirmed: window.moment().format('YYYY-MM-DD'),
            ClaimantSurname: this.claimantSurname,
            DateofBirth: window.moment(this.dateOfBirth).format('YYYY-MM-DD'),
          }
          var result = await utilities.putClaimantconfirm(params)
          if (result) {
            this.froms = await utilities.getConsentForm(params)
            this.singleCase = res
            this.checkDetails()
            this.bookingDateTime = moment(this.singleCase.bookingDateTime, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A, dddd Do MMMM, YYYY')
            this.location = this.singleCase.fullLocation.replace(', Australia', "").replace(', AU', "")
            this.singleCase.fullLocation = this.location
            this.userVerfied = true
            if (!this.status) {
              setTimeout(() => {
                this.successMessage = "Your attendance has been confirmed."
                utilities.showAlert('.alert-success')
              }, 500)
            }
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }
        } else {
          this.errorMessage = "The details could not be verified. Please check your details and try again."
          utilities.showAlert('.alert-danger')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      addToCalendar() {
        this.$ics.addEvent('en-us', 'Appointment with ' + this.singleCase.fullName, '', this.singleCase.fullLocation, moment(this.bookingDateTime, 'hh:mm A, dddd Do MMMM, YYYY').format('YYYY-MM-DD HH:mm'), this.endTime)
        this.$ics.download('Appointment-Details-' + moment().unix())
      },
    },
    async beforeMount() {
    },
    mounted() {
    },
  }
</script>
