<template>
  <div class="add-carhire-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Add New Carhire</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Carhire Name</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="carhire.name" placeholder="Carhire Name" :class="hasError && !validateName ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validateName" class="text-danger">Name is required.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Address Line 1</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="carhire.address1" placeholder="Address Line 1" :class="hasError && !validateAddress1 ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validateAddress1" class="text-danger">Address is required.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Address Line 2</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="carhire.address2" placeholder="Address Line 2" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">City</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="carhire.city" placeholder="City" :class="hasError && !validateCity ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validateCity" class="text-danger">City is required.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">State</div>
                  <div class="box-col box-col-right">
                    <select v-model="carhire.state" :class="hasError && !validateState ? 'is-invalid' : ''">
                      <option value="" hidden>Select State</option>
                      <option v-for="state in states" :value="state.value">{{ state.text }}</option>
                    </select>
                    <div v-if="hasError && !validateState" class="text-danger">State is required.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Postcode</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="carhire.postcode" placeholder="Postcode" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Phone Number</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="carhire.phone" placeholder="Carhire Number" :class="hasError && !validatePhone ? 'is-invalid' : ''"  />
                    <div v-if="hasError && !validatePhone" class="text-danger">Phone is required.</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddCarhireModal',
    data() {
      return {
        loadModal: false,
        hasError: false,
        user: _.cloneDeep(this.$store.getters["user/getUser"]),
        carhire: {
          name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          postcode: '',
          phone: '',
        },
        index: -1,
        states: utilities.getStates(),
      }
    },
    computed: {
      validateName() {
        if(this.carhire.name == '') {
          return false
        }
        return true
      },
      validateAddress1() {
        if(this.carhire.address1 == '') {
          return false
        }
        return true
      },
      validateCity() {
        if(this.carhire.city == '') {
          return false
        }
        return true
      },
      validateState() {
        if(this.carhire.state == '') {
          return false
        }
        return true
      },
      validatePhone() {
        if(this.carhire.phone == '') {
          return false
        }
        return true
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      reset(index = -1) {
        this.carhire = {
          name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          postcode: '',
          phone: '',
        }
        this.hasError = false
        this.index = index
      },
      async callSave() {
        if (!this.validateName || !this.validateAddress1 || !this.validateCity || !this.validateState || !this.validatePhone) {
          this.hasError = true
          return
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          CarHireName:this.carhire.name,
          AddressLine1:this.carhire.address1,
          AddressLine2:this.carhire.address2,
          City:this.carhire.city,
          State:this.carhire.state,
          Postcode:this.carhire.postcode,
          Country:'AU',
          PhoneNumber:this.carhire.phone,
          UserID: this.user.id,
          Username: this.user.name
        }
        var carhireId = await utilities.postNewCarhire(params)
        if(carhireId > 0) {
          var carhires = await utilities.getCarHires()
          this.$parent.carhires = _.cloneDeep(carhires)
          this.$parent.originalListCarhires =  _.cloneDeep(utilities.getDropdownCarhires(carhires))
          this.$parent.listCarhires = _.cloneDeep(this.$parent.originalListCarhires)
          this.$parent.updateListCarhiresArray()
          this.$parent.carHireKey += 1
          if(this.index > -1) {
            this.$parent.travelCarHires[this.index].carhireId = carhireId
            this.$parent.updateLocation(carhireId, this.index)
            setTimeout(() => {
              this.$parent.setNewCarHire(carhireId, this.index)
            }, 500)
          }
          this.handleModal()
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    mounted() {},
  }
</script>
