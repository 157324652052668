/* eslint-disable */
<template>
  <div>
    <div class="red-vertical"></div>
    <div class="claimant-consent-form">
      <div class="row">
        <div class="col-2 offset-5">
          <div class="logo"><img src="@/assets/images/red_health_logo.svg" class="img-fluid" /></div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-7">
          <h1>Consent Form</h1>
          <p class="mt-5">
            I consent to the following in relation to my appointment: <br /><br />

            Appointment with: Dr Alexandra Cuthbertson<br />
            2:00 PM, Monday 20th September, 2020<br />
            Cairns Hospital, Main Street, Cairns.
          </p>
        </div>
        <div class="col-7">
          <div class="row">
            <div class="col-4">
              <label class="left-label">Your Phone Number</label>
              <input type="tel" v-model="claimant.phoneNumber" placeholder="Enter phone number" />
            </div>
            <div class="col-4">
              <label class="left-label">Mobile</label>
              <input type="tel" v-model="claimant.mobile" placeholder="Enter mobile number" />
            </div>
            <div class="col-4">
              <label class="left-label">Your Phone Number</label>
              <input type="tel" v-model="claimant.workNumber" placeholder="Enter work number" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="left-label">Address line 1</label>
              <input type="text" v-model="claimant.addressLine1" placeholder="Enter address line 1" />
            </div>
            <div class="col-4">
              <label class="left-label">Address line 2</label>
              <input type="text" v-model="claimant.phoneNumber1" placeholder="Enter address line 2" />
            </div>
            <div class="col-4">
              <label class="left-label">City</label>
              <div v-if="!cityFocus" @click="cityFocus = true">
                <input type="text" disabled placeholder="Click here to select city" />
              </div>
              <select v-else>
                <option value="" hidden>Select City...</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="left-label">State</label>
              <div v-if="!stateFocus" @click="stateFocus = true">
                <input type="text" disabled placeholder="Click here to select state" />
              </div>
              <select v-else>
                <option value="" hidden>Select State...</option>
              </select>
            </div>
            <div class="col-4">
              <label class="left-label">Postcode</label>
              <div v-if="!postcodeFocus" @click="postcodeFocus = true">
                <input type="text" disabled placeholder="Click here to select postcode" />
              </div>
              <select v-else>
                <option value="" hidden>Select Postcode...</option>
              </select>
            </div>
            <div class="col-4">
              <label class="left-label">Country</label>
              <div v-if="!countryFocus" @click="countryFocus = true">
                <input type="text" disabled placeholder="Click here to select country" />
              </div>
              <select v-else>
                <option value="" hidden>Select Country...</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-7 d-flex justify-content-center">
          <v-btn dark>I consent</v-btn>
        </div>
        <div class="col-7 d-flex flex-column align-items-center justify-content-center">
          <p class="my-5 py-5">
            If you need to discuss the appointment, please don’t hesitate to call us on: (07) 1234 1234 during business hours.
          </p>
          <div class="align-self-center text-center mt-5 pt-5">
            ©2008–2020 Red Medicine Pty Ltd,<br />
            trading as Red Health Independent Medical Assessments<br />
            <router-link to="#">Privacy</router-link> <router-link to="#">Terms of Use</router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminAvailabilityMenu from '@/components/AdminAvailabilityMenu'
  import Header from '@/components/Header'
  import Chat from '@/components/Chat'

  export default {
    name: 'AdminRelatedSkills',
    components: {
      AdminNavigation,
      AdminAvailabilityMenu,
      Header,
      Chat
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',

        userVerfied: false,
        claimant: utilities.initClaimant(),

        cityFocus: false,
        stateFocus: false,
        postcodeFocus: false,
        countryFocus: false,
      }
    },
    computed: {},
    methods: {
      async loadData() {},
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
