/* eslint-disable */
<template>
  <div class="specialist-appointments">
    <SpecialistNavigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Appointments</h1></div>
        <div class="header-sub-title">The following appointments are upcoming in your schedule.</div>
      </div>
    </div>
    <div class="panel d-flex calc-width">
      <SpecialistBookingMenu indexProps="2" />
      <div style="width: 220px; flex-shrink: 0;" />
      <div class="content" style="flex: 1; left: 0">
        <!-- <ThreeWeekCalendar :passedBookingsProps="bookings" /> -->
        <div class="calendar-month">
          <div>{{ getMonth(calendarDate) }}</div>
          <v-row class="fill-height">
            <div v-if="leftArrow" class="is-icon move-icon" @click="previousMonth"><img src="@/assets/images/previous_icon.svg" /></div>
            <v-col>
              <v-sheet height="1200">
                <v-calendar
                  id="calendar"
                  ref="calendar"
                  color="primary"
                  type="month"
                  v-model="calendarDate"
                  :short-months="false"
                  :short-weekdays="false"
                  :events="bookings.map((x) => x.calendarEvent)"
                  @click:date="openDate"
                >
                  <template v-slot:event="{ event }">
                    <div class="fill-height calendar-event">
                      <div class="indicator-circle" :style="'background-color:' + event.color" style="margin-top: 2px"></div>
                      <p class="long-text" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.name" :time="event.start">
                        <router-link  :to="{ name: 'specialist-booking-detail', query: {id:event.id} }">
                          <span>{{ decodeURIComponent(event.name) }}</span>
                          <span class="ml-2">{{ formatDateTime(event.start, 'hh:mm A', 'YYYY-MM-DD hh:mm') }}</span>
                        </router-link>
                      </p>
                    </div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
            <div  v-if="rightArrow"  class="is-icon move-icon" @click="nextMonth"><img src="@/assets/images/next_icon.svg" /></div>
          </v-row>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistBookingMenu from '@/components/SpecialistBookingMenu'
  import Header from '@/components/Header'
  import Chat from '@/components/Chat'

  export default {
    name: 'SpecialistAppointments',
    components: {
      SpecialistNavigation,
      SpecialistBookingMenu,
      Header,
      Chat,
    },
    data() {
      return {
        leftArrow: true,
        rightArrow: true,
        dateToGo: '',
        dateToBack: '',
        nextTwoWeek: '',
        today: window.moment().format('YYYY-MM-DD'),
        bookings: [],
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        calendarDate: window.moment().format('YYYY-MM-DD'),
      }
    },
    computed: {
      start() {
        return window.moment()
          .startOf('week')
          .format('YYYY-MM-DD')
      },
      end() {
        return window.moment()
          .endOf('week')
          .add(3, 'w')
          .format('YYYY-MM-DD')
      },
    },
    methods: {
      formatDateTime(date, format, currentFormat = "YYYY-MM-DD") {
        if (date != '') {
          if (format == 'MMM, YYYY hh:mm A' && this.booking.bookingStatus == 16) {
            format = 'MMM, YYYY'
            return utilities.formatDate(date, format, currentFormat)
          } else {
            return utilities.formatDate(date, format, currentFormat)
          }
        }
        return ''
      },
      getMonth(date) {
        return utilities.formatDate(date, 'MMMM', "YYYY-MM-DD")
      },
      async previousMonth() {
        this.$store.dispatch('loading/setLoadComponent', true)

        this.calendarDate = window.moment(this.calendarDate, "YYYY-MM-DD")
          .subtract(1, 'month').startOf('month')
          .format('YYYY-MM-DD')

        if (this.calendarDate == this.dateToBack) {
          this.leftArrow = false
        }
        this.rightArrow = true

        if (this.user.specialistId != undefined && this.user.specialistId != '') {
          const {bookings} = await utilities.getSpecialistUpcomingBookings({
            PageNum: 1,
            PageSize: 999,
            BookingStartDate: this.calendarDate,
            BookingEndDate: window.moment(this.calendarDate, "YYYY-MM-DD").endOf('month').format('YYYY-MM-DD'),
          }, true)
          this.bookings = bookings
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async nextMonth() {
        this.$store.dispatch('loading/setLoadComponent', true)

        this.calendarDate = window.moment(this.calendarDate, "YYYY-MM-DD")
          .add(1, 'month').startOf('month')
          .format('YYYY-MM-DD')
        if (this.calendarDate == this.dateToGo) {
          this.rightArrow = false
        }
        this.leftArrow = true
        if (this.user.specialistId != undefined && this.user.specialistId != '') {
          const {bookings} = await utilities.getSpecialistUpcomingBookings({
            BookingEndDate: window.moment(this.calendarDate, "YYYY-MM-DD").endOf('month').format('YYYY-MM-DD'),
            BookingStartDate: this.calendarDate,
            PageNum: 1,
            PageSize: 999,
          }, true)
          this.bookings = bookings
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async loadBookings() {
        this.dateToGo =  window.moment().add(3, 'M').format('YYYY-MM-DD')
        this.dateToBack =  window.moment().subtract(12, 'M').format('YYYY-MM-DD')
        this.nextTwoWeek =  window.moment().add(2, 'W').format('YYYY-MM-DD')

        const {bookings} = await utilities.getSpecialistUpcomingBookings({
          BookingStartDate: window.moment().startOf('month').format('YYYY-MM-DD'),
          BookingEndDate: window.moment().endOf('month').format('YYYY-MM-DD'),
          PageNum: 1,
          PageSize: 999,
        }, true)
        // var shownBookingStatuses = ["3","4","8"]
        this.bookings = bookings
        console.log(bookings)
      },
      checkStatus(e) {
        var date = window.moment(e.start, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD')
        if (date <= this.nextTwoWeek) {
          return true
        }
        return false
      },
      openDate(e) {
        this.$router.push({ name: "specialist-bookings-day", query: {date:e.date} }).catch((err) => {})
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadBookings()
      // Show current week and next 2 weeks in current month
      // var currentIndex = 0
      // $(".v-calendar-weekly__week").hide()
      // $(".v-calendar-weekly__week").each(function(index) {
      //   if($(this).find(".v-calendar-weekly__day-label .primary").length > 0) {
      //     currentIndex = index
      //     $(this).show()
      //   } else {
      //     if(index > currentIndex && index <= currentIndex + 2) $(this).show()
      //   }
      // })
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      window.scrollTo(0, 0)
    },
  }
</script>
