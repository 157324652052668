/* eslint-disable */
<template>
  <div class="admin-travel-detail">
    <AdminNavigation indexProps="4" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Travel &amp; Accommodation Details</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminFinanceMenu indexProps="1" />
      <div class="content">
        <div v-if="travelsToday.length > 0" class="timeline">
          <ul class="timeline">
            <li v-for="(travel, index) in travelsToday" :item="travel" :key="travel.id" class="timeline-item" :class="index == Object.keys(travelsToday).length - 1 ? 'mb-0' : ''">
              <!-- <div class="timeline-badge">{{ item.initials }}</div> -->
              <div class="timeline-badge">
                <img src="@/assets/images/travel_black_icon.svg" v-if="travel.flightNumber !== ''" />
                <img src="@/assets/images/carhire_icon.svg" v-if="travel.carhireId !== ''" />
                <img src="@/assets/images/accommodation_icon.svg" v-if="travel.accommodationId !== ''" />
              </div>
              <div class="timeline-panel" v-if="travel.flightNumber !== ''">
                <div class="timeline-body mt-0">
                  {{ travel.airlineName }} Flight {{ travel.flightNumber }} Seat {{ travel.seatNumber }} <br />
                  Departing {{ travel.departingAirportName }} at {{ formatTime(travel.travelStartDate, travel.travelStartTime) }} {{ formatDate(travel.travelStartDate) }} <br />
                  Arriving {{ travel.arrivingAirportName }} at {{ formatTime(travel.travelEndDate, travel.travelEndTime) }} {{ formatDate(travel.travelEndDate) }} <br />
                  Booking Reference: {{ travel.bookingReference }} <br />
                  Cost: ${{ travel.cost.toFixed(2) }} <br />
                  Contact: {{ travel.airlineContact }}
                  <!-- Contact here -->
                </div>
              </div>
              <div class="timeline-panel" v-if="travel.carhireId !== ''">
                <div class="timeline-body mt-0">
                  {{ travel.carHireName }} <br />
                  Collect from {{ formatTime(travel.travelStartDate, travel.travelStartTime) }} {{ formatDate(travel.travelStartDate) }} <br />
                  Return by {{ formatTime(travel.travelEndDate, travel.travelEndTime) }} {{ formatDate(travel.travelEndDate) }} <br />
                  Booking Reference: {{ travel.bookingReference }} <br />
                  Cost: ${{ travel.cost.toFixed(2) }} <br />
                  Contact: {{ travel.carHirePhoneNumber }}
                  <!-- Contact here -->
                </div>
              </div>
              <div class="timeline-panel" v-if="travel.accommodationId !== ''">
                <div class="timeline-body mt-0">
                  {{ travel.fullLocation }} <br />
                  Check-in: {{ formatTime(travel.travelStartDate, travel.travelStartTime) }} {{ formatDate(travel.travelStartDate) }} <br />
                  Check-out: {{ formatTime(travel.travelEndDate, travel.travelEndTime) }} {{ formatDate(travel.travelEndDate) }} <br />
                  Booking Reference: {{ travel.bookingReference }} <br />
                  Cost: ${{ travel.cost.toFixed(2) }} <br />
                  Contact: {{ travel.accommodationPhoneNumber }}
                  <!-- Contact here -->
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="box">
          <div class="box-heading">
            <span>Travel Receipts</span>
          </div>
          <div class="box-body my-3">
            <div>
              <div class="content-scroll-y file-list" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                  <tbody :key="fileKey">
                    <tr v-for="(file, index) in travelReceipts" :key="index" class="py-0">
                      <td style="width:100%" class="long-text py-0">
                        <span class="is-icon" @click="getFile(file.invoiceFilePath, file.invoiceFileName)">{{ file.invoiceFileName }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif" />
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
              </label>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> Files uploaded successfully.
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> There are the errors. Please try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminFinanceMenu from '@/components/AdminFinanceMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'SpecialistTravelDetail',
    components: {
      AdminNavigation,
      AdminFinanceMenu,
      Header,
    },
    data() {
      return {
        filelist: [],
        travelsToday: [],
        flattenedTravels: [],
        travelReceipts: [],
        availabilityId: '',
        travelItineraryId: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        fileKey: 0,
      }
    },
    methods: {
      async onChange() {
        this.$store.dispatch('loading/setLoadComponent', true)
        // var params = {
        //   SpecialistTravelItineraryID: this.travelItineraryId,
        //   FileObject: []
        // }
        var params = {}
        this.filelist = [...this.$refs.file.files]
        if(this.filelist.length > 0) {
          await Promise.all(this.filelist.map(async (x) => {
            if (this.travelItineraryId != '') {
              var fileName = moment().unix() + '_' + x.name.replace(/\s/g, '_')
              await new Promise((resolve, reject) => {
                var reader = new FileReader()
                reader.onloadend = async () => {
                  // params.FileObject.push({
                  //   SpecialistTravelItineraryID: this.travelItineraryId,
                  //   InvoiceFileName: fileName,
                  //   InvoiceFileBinary: reader.result,
                  //   DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                  //   UploadedBy: this.user.id,
                  //   MimeType: x.type,
                  //   AvailabilityID: this.availabilityId,
                  //   SpecialistID: this.user.specialistId,
                  // })
                  params = {
                    SpecialistTravelItineraryID: this.travelItineraryId,
                    InvoiceFileName: fileName,
                    InvoiceFileBinary: reader.result,
                    DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                    UploadedBy: this.user.id,
                    MimeType: x.type,
                    AvailabilityID: this.availabilityId,
                    SpecialistID: this.user.specialistId,
                    UserID: this.user.id,
                    Username: this.user.name
                  }
                  var callback = (response) => {}
                  await utilities.postTravelReceipt(params, callback)
                  resolve(true)
                }
                reader.onerror = (error) => {
                  reject(error)
                }
                reader.readAsBinaryString(x)
              })
            }
          }))
          // if(params.FileObject.length > 0) {
          //   var callback = (response) => {}
          //   var result = await utilities.postTravelReceipt(params, callback)
          //   if(result) {
          //     await this.loadData()
          //     this.fileKey += 1
          //   }
          // }
          await this.loadData()
          this.fileKey += 1
          utilities.showAlert(".alert-success")
          setTimeout(() => {
            $(".file-list").animate({ scrollTop: $(".file-list")[0].scrollHeight }, 'slow')
          }, 200)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      remove(i) {
        this.filelist.splice(i, 1)
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //   event.currentTarget.classList.remove('bg-gray-100')
        //   event.currentTarget.classList.add('bg-green-300')
        // }
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        // event.currentTarget.classList.add('bg-gray-100')
        // event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      getFile(filePath, fileName) {
        utilities.getFile(filePath, fileName)
      },
      formatTime(date, time) {
        return moment(date + ' ' + time).format('hh:mm A')
      },
      formatDate(date) {
        return moment(date).format('DD/MM/YYYY')
      },
      async loadData() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var id = this.$route.query.id != undefined ? this.$route.query.id : ''
        if(id != '') {
          this.travelReceipts = await utilities.getSpecialistTravelReceipts(id)
          this.travelItineraryId = id
        } else {
          this.availabilityId = this.$route.query.availabilityId != undefined ? this.$route.query.availabilityId : ''
          this.travelsToday = await utilities.getSpecialistTravelItineraryByAvailabilityId(this.availabilityId)
          this.travelsToday = this.travelsToday.sort((a, b) => moment(a.travelStartDate + ' ' + a.travelStartTime, "YYYY-MM-DD HH:mm:ss.SSSSSS") <  moment(b.travelStartDate+ ' ' + b.travelStartTime, "YYYY-MM-DD HH:mm:ss.SSSSSS") ? -1 : 1)
          this.travelReceipts = await utilities.getSpecialistTravelReceipts(this.travelsToday[0].specialistTravelItineraryId)
          this.travelItineraryId = this.travelsToday[0].specialistTravelItineraryId
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },

    async beforeMount() {
      await this.loadData()
    },

    mounted() {},
  }
</script>
