 /* eslint-disable */
<template>
  <div class="search_case_lists">
    <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="5" />
    <Navigation v-else indexProps="4" />
    <ProfileReadMoreModal ref="profileReadMoreModal" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>{{ setTitle() }}</h1></div>
        <div class="header-sub-title">
          <span v-if="showResult">Either choose an existing case, or create a new case by entering the claimant details.</span>
          <span v-else>Has the claimant attended an appointment before?  Please search below.</span>
        </div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu  v-if="user.type >= 3" indexProps="0" />
      <SpecialistMenu v-else indexProps="0" />
      <div class="content" v-if="showContent">
        <div class="top-detail">
          <div class="row m-0">
            <div class="col p-0 center-title">
              <div class="row center-title m-0 display-in-row" v-if="!bookingProps.isSupplementary">
                <div class="col-2 p-0">
                  <div class="border-image">
                    <div class="border-image-number">{{formatDateTime(bookingProps.bookingDate, 'DD')}}</div>
                    <div class="border-image-text">{{formatDateTime(bookingProps.bookingDate + ' ' + bookingProps.bookingTime, 'MMM, YYYY hh:mm A', 'YYYY-MM-DD hh:mm A')}}</div>
                  </div>
                </div>
                <div class="col center-title ml-4">
                {{decodeURIComponent(bookingProps.locationName)}}
                </div>
              </div>
            </div>
            <div class="col p-0">
              <div class="row m-0">
                <div class="col-2 p-0">
                  <div class="rounded-image medium-rounded-image m-t-0 m-t-b is-icon" v-if="specialist.profileThumbnail" @click="tapReadMore(specialist.id)">
                    <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                  </div>
                  <div class="box-col" v-else>
                    <div class="rounded-image medium-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content notice-background"><s class="text-initials">{{getInitials(specialist)}}</s></div>
                    </div>
                  </div>
                </div>
                <div class="col center-title">
                  <div>
                    <div class="name is-icon" @click="tapReadMore(specialist.id)">
                      {{specialist.fullName}}
                    </div>
                    <div class="">
                      {{getSpecialistInfo(specialist.practiceLocationId, specialist.locations)}}
                    </div>
                    <div class="is-icon">
                      <img v-if="booking.requiresTravel" class="icon-20 mr-2" src="@/assets/images/travel_black_circle_icon.svg" />
                      <img v-if="booking.videoLinkAvailable == 2" class="icon-20" src="@/assets/images/video_black_icon.svg" data-toggle="tooltip" data-placement="right" title="Appointment by Video Link" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-2" v-if="showResult">
            <span><b>Searching for: </b> <span v-if="user.type >=3">{{getName(searchOption.ClientID)}}</span></span>
            <span v-if="searchOption.FromDate" class="ml-2"><b>Date of Birth: </b> {{formatDateTime(this.searchOption.FromDate, 'DD/MM/YYYY')}}</span>&nbsp;
            <!-- <span class="ml-2"><b>case Reference: </b> {{searchOption.caseReference}}</span>&nbsp; -->
          </div>
          <div class="" v-if="showResult">
            <div class="table-responsive" style="max-height: 500px">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:12%" class="long-text">Reference No<img v-if="searchOption.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)" />
                      <img v-else-if="searchOption.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(1)"   /></th>
                    <th style="width:8%" class="long-text">Claimant<img v-if="searchOption.SortFlag === 7" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(8)" />
                      <img v-else-if="searchOption.SortFlag === 8" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(7)"   /></th>
                    <th style="width:8%" class="long-text">Case Owner<img v-if="searchOption.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)" />
                      <img v-else-if="searchOption.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(5)"   /></th>
                    <th style="width:10%"  class="long-text">Date of Birth<img v-if="searchOption.SortFlag === 11" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(12)" />
                      <img v-else-if="searchOption.SortFlag === 12" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(11)"   /></th>
                    <th style="width:12%" class="long-text">Type of Claim<img v-if="searchOption.SortFlag === 9" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(10)" />
                      <img v-else-if="searchOption.SortFlag === 10" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(9)"   /></th>
                    <th style="width:8%" class="long-text">Number of Bookings</th>
                    <th style="width:12%" class="long-text">Next Booking</th>
                    <th style="width:10%" class="long-text"></th>
                  </tr>
                </thead>
               </table>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr v-for="(item, index) in cases" :key="index" class="is-icon">
                    <td style="width:12%" class="long-text">{{item.caseReference}}</td>
                    <td style="width:8%" class="long-text">{{item.claimantFullName}}</td>
                    <td style="width:8%" class="long-text">{{item.caseOwnerName}}</td>
                    <td style="width:10%" class="long-text">{{formatDateTime(item.dateOfBirth, 'DD/MM/YYYY')}}</td>
                    <td style="width:12%" class="long-text">{{item.claimTypeName}}</td>
                    <td style="width:8%" class="long-text">{{item.numberBookings}}</td>
                    <td v-if="item.nextBookingId != ''" style="width:12%" class="long-text">
                      <router-link v-if="user.type >= 3" target="_blank" :to="{ name: 'admin-manage-booking', query: { id: item.nextBookingId } }">
                        {{ formatDateTime(item.nextBookingDate, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss') }}
                      </router-link>
                      <router-link v-else="" target="_blank" :to="{ name: 'edit-booking', query: { id: item.nextBookingId } }">
                        {{ formatDateTime(item.nextBookingDate, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss') }}
                      </router-link>
                    </td>
                    <td v-else style="width:12%" class="long-text">{{ formatDateTime(item.nextBookingDate, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss')  }}</td>
                    <td style="width:10%">
                      <v-btn class="submit-request" @click="selectCase(item)" dark>Select Case</v-btn>
                    </td>
                  </tr>
                  <tr v-if="cases.length == 0">
                    <p>No matching bookings / claimants found.</p>
                  </tr>
                </tbody>
              </table>
              <div v-if="totalCount > searchOption.PageSize" class="pagination">
                Page
                <div class="pagination-group">
                  <div class="border-icon pagination-current dropdown">
                    <div class="pagination-current-text">{{ searchOption.PageNum }}</div>
                    <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                    <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                      <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/searchOption.PageSize))" :class="searchOption.PageNum === item ? 'selected' : ''" :value="item" :key="item" @click="selectPage(item)">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/searchOption.PageSize) }}</div>
              </div>
            </div>
          </div>
          <div class="box-row item-right" v-if="showResult">
            </br>
            <div>
              <v-btn dark @click="tapAddNew()">Add New Claimant & Case</v-btn>
            </div>
          </div>

          <div class="box box-body search-details">
            <div class="box-heading d-flex justify-content-between align-items-center">
              <div>Find / Enter Claimant & Case Details</div>
              <v-btn v-if="!bookingProps.waitlisted && !bookingProps.isTentative" dark @click="switchToTentative">{{ !isTentative ? 'Tentative' : 'Book'}}</v-btn>
              <v-btn v-if="bookingProps.isTentative" dark @click="releaseBooking">Cancel</v-btn>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Claimant First Name</div>
              <div class="box-col box-col-right">
                <input :class="hasError && !validateClaimantFirstName ? 'is-invalid' : ''" type="text" v-model="searchOption.ClaimantFirstName" class="textbox referance"  placeholder="Click to enter part / all of the Claimant First Name" />
                <div class="text-danger" v-if="hasError && !validateClaimantFirstName"> Please enter the correct claimant's name (do not contain ' " or `)</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Claimant Surname</div>
              <div class="box-col box-col-right">
                <input  :class="hasError && !validateClaimantSurname ? 'is-invalid' : ''" type="text" v-model="searchOption.ClaimantSurName" class="textbox referance"  placeholder="Click to enter part / all of the Claimant Surname" />
                <div class="text-danger" v-if="hasError && !validateClaimantSurname"> Please enter the correct claimant's name (do not contain ' " or `)</div>
              </div>
            </div>
<!--            <div class="box-row">-->
<!--              <div class="box-col box-col-left">Reference Number</div>-->
<!--              <div class="box-col box-col-right">-->
<!--                <input type="text" v-model="searchOption.ReferenceNumber" class="textbox referance"  placeholder="Click to enter part / all of the Claimant Surname" />-->
<!--              </div>-->
<!--            </div>-->
            <div class="box-row">
              <div class="box-col box-col-left">Date of Birth</div>
              <div class="box-col box-col-right box-col-center">
                <el-date-picker
                    :class="hasError && !validateDateOfBirth ? 'is-invalid-datepicker' : ''"
                    v-model="dateValue"
                    type="date"
                    popper-class="date-range-picker-popper"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="changeDates"
                    placeholder="Birth Date"
                    clearable
                />
                <div class="text-danger" v-if="hasError && !validateDateOfBirth">Date of birth is required.</div>
              </div>
            </div>
            <!-- Ticket 5491 -->
            <!-- <div class="box-row">
              <div class="box-col box-col-left">case Reference</div>
              <div class="box-col box-col-right">
                <input type="text" v-model="searchOption.caseReference" class="textbox referance" placeholder="Click to enter part / all of the case Reference" />
              </div>
            </div> -->
            <div v-if="user.type >= 3" class="box-row">
              <div class="box-col box-col-left left-label">Client</div>
              <div class="box-col box-col-right align-items-center">
                <el-select :class="hasError && !validateClient ? 'is-invalid' : ''" class="referance" filterable clearable style="width: 260px" placeholder="Client Name" @change="changeClient" v-model="searchOption.ClientID">
                  <el-option v-for="item in listClients" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
                </el-select>
                <div class="text-danger" v-if="hasError && !validateClient">Client is required.</div>
              </div>
            </div>
            <div v-if="user.type >= 3 && searchOption.ClientID" class="box-row">
              <div class="box-col box-col-left left-label">Client Contact</div>
              <div class="box-col box-col-right">
                <el-select :class="hasError && !validateContact ? 'is-invalid' : ''"  filterable clearable style="width: 260px" placeholder="Client Contact" v-model="searchOption.contactId">
                  <el-option v-for="item in originalClientContacts" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
                </el-select>
                <button class="btn" data-toggle="tooltip" data-placement="right" title="Create New Contact" @click="addNewContact">
                  <img class="w-75" src="@/assets/images/add_black_icon.svg" />
                </button>
                <div class="text-danger" v-if="hasError && !validateContact">Client contact is required.</div>
              </div>
            </div>
            <div v-if="bookingProps.waitlisted" class="box-row">
              <div class="box-col box-col-left left-label">Reference Number</div>
              <div class="box-col box-col-right">
                <input type="text" v-model="searchOption.ReferenceNumber" class="textbox referance"  placeholder="Reference Number" />
                <div class="text-danger" v-if="hasError && !validateReferenceNumber">Reference number is required.</div>
              </div>
            </div>
          </div>
          <div class="box-row item-right">
            <div>
              <v-btn v-if="!bookingProps.waitlisted" class="submit-request" @click="tapSearch()" dark>{{isTentative ? 'PLACE HOLD' : 'Search'}}</v-btn>
              <v-btn v-else class="submit-request" @click="tapWaitList()" dark>WAITLIST</v-btn>
            </div>
          </div>

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

        </div>
      </div>
    </div>
    <Chat v-if="this.user.type < 3"/>
    <el-dialog title="Add New Contact"
               :visible.sync="showContactModal"
               @close="cancelDialog">
      <AdminEditClientContact :clientIdProps="searchOption.ClientID" @contactCreated="contactCreated" />
    </el-dialog>
  </div>
</template>
<script>

import utilities from '@/assets/js/utilities'
import AdminNavigation from "@/components/AdminNavigation";
import AdminBookingsMenu from "@/components/AdminBookingsMenu";
import Navigation from '@/components/Navigation'
import SpecialistMenu from '@/components/SpecialistMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import ProfileReadMoreModal from '@/components/ProfileReadMoreModal'
import AdminEditClientContact from "@/components/AdminEditClientContact";

export default {
  name: 'SearchCaseLists',
  components: {
    AdminEditClientContact,
    AdminNavigation,
    AdminBookingsMenu,
    Navigation,
    SpecialistMenu,
    Header,
    Chat,
    ProfileReadMoreModal,
  },
  data() {
    return {
      totalCount: 0,
      dateValue: "",
      setDisabled: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      claimantFirstName: '',
      claimantLastName: '',
      searchOption: {
        PageNum: 1,
        PageSize: 10,
        CaseStatus: 1,
        FromDate: null,
        ToDate: null,
        ReferenceNumber: '',
        ClientName: '',
        SortFlag: 0,
        ClaimantSurName: '',
        ClaimantFirstName: '',
        ClientID: '',
        contactId: '',
      },
      bookingProps: null,

      hasError: false,
      successMessage: '',
      errorMessage: '',
      searchData: '',

      showContent: true,
      isValidDateOfBirth: true,
      showResult: false,

      searchCase: [],
      specialist: [],
      originalListClients: [],
      listClients: [],
      cases: [],
      booking: [],
      showContactModal: false,
      isTentative: false,
      clients: [],
      originalClientContacts: [],
      listContact: [],
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  computed: {
    validateReferenceNumber() {
      if(this.bookingProps.waitlisted && !this.searchOption.ReferenceNumber) {
        return false
      }
      return true
    },
    validateContact() {
      if(this.user.type >= 3 && (this.bookingProps.waitlisted || this.isTentative) && !this.searchOption.contactId) {
        return false
      }
      return true
    },
    validateDateOfBirth() {
      if((this.isTentative || this.bookingProps.waitlisted) && (!this.searchOption.FromDate || !this.searchOption.ToDate)) {
        return false
      }
      return true
    },
    validateClient() {
      if(this.user.type >= 3 && !this.searchOption.ClientID) {
        return false
      }
      return true
    },
    validateClaimantFirstName() {
      if(this.searchOption.ClaimantFirstName.trim() !== '' && !utilities.validateNameText(this.searchOption.ClaimantFirstName)) {
        return false
      }
      if(!this.searchOption.ClaimantFirstName.trim() && (this.isTentative || this.bookingProps.waitlisted)) {
        return false
      }
      return true
    },
    validateClaimantSurname() {
      if(this.searchOption.ClaimantSurName.trim() !== '' && !utilities.validateNameText(this.searchOption.ClaimantSurName)) {
        return false
      }
      if(!this.searchOption.ClaimantSurName.trim() && (this.isTentative || this.bookingProps.waitlisted)) {
        return false
      }
      return true
    },
  },

  methods: {
    contactCreated() {
      this.cancelDialog();
      this.changeClient()
    },
    cancelDialog() {
      this.showContactModal = false
    },
    addNewContact() {
      this.showContactModal = true
    },
    releaseBooking() {
      if(!this.bookingProps.tentativeBookingID) return
      this.$confirm({
        content: 'Are you sure you want to release this Tentative Booking?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          const param = {
            tentativeBookingID: this.bookingProps.tentativeBookingID,
            Status: 2,
            UserID: this.user.id,
            Username: this.user.name
          }
          await utilities.putTentativeBooking(param)
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },
    switchToTentative() {
      this.isTentative = !this.isTentative
    },
    getName(id) {
      return this.clients.find(item => item.id === id).name
    },
    sortParam(v) {
      this.searchOption.SortFlag = v
      this.selectPage(1)
    },
    async selectPage(index) {
      this.searchOption.PageNum = index
      this.$store.dispatch('loading/setLoadComponent', true)
      const {clientCase, total} = await utilities.getCasesNew(this.searchOption)
      this.cases = clientCase
      this.totalCount = total
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    changeDates(v) {
      if(v) {
        this.searchOption.FromDate = v
        this.searchOption.ToDate = v
      } else {
        this.searchOption.FromDate = null
        this.searchOption.ToDate = null
      }
    },
    setTitle() {
      var title = "Create a Booking - case Details"
      if (this.bookingProps.waitlisted) {
        title = "Create a Waitlist Booking - case Details"
      }
      return title
    },

    getInitials(specialist) {
      var initials = ""
      if (specialist.firstName) {
        initials = specialist.firstName.substring(0,1)
      }

      if (specialist.lastName) {
        initials = initials + specialist.lastName.substring(0,1)
      }
      return initials
    },

    clientSelected(item) {
      if (item.id != undefined) {
        this.searchOption.clientId = item.id
        if (this.searchOption.clientId) {
          this.changeClient()
        }
      }
    },

    contactSelected(item) {
      if (item.id != undefined) {
        this.item = item
        this.searchOption.contactId = item.id
      }
    },

    filterContact(text) {
      this.listContact = this.originalClientContacts.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
    },

    async changeClient() {
      this.searchOption.contactId = null
      this.$store.dispatch('loading/setLoadComponent', true)
      if(this.searchOption.clientId != '') {
        this.contacts = await utilities.getClientContacts(this.searchOption.ClientID)
        this.originalClientContacts = _.cloneDeep(utilities.getClientsDropdownAutoCompleteTagWithName(this.contacts, false))
        this.listContact = this.originalClientContacts.map(item => item.name)
      }
      else {
        this.contacts = []
      }
      this.$store.dispatch('loading/setLoadComponent', false)
      setTimeout(() => {
        $(".dropdown-input").attr({
         autocomplete: "off",
        });
      }, 1000)
    },
    async createTentative() {
      const param = {
        BookingTypeID: this.bookingProps.typeId || 0,
        ClientID: this.user.type >= 3 ? this.searchOption.ClientID : this.user.clientId,
        SpecialistID: this.bookingProps.id,
        ClaimantFirstName: this.searchOption.ClaimantFirstName,
        ClaimantSurname: this.searchOption.ClaimantSurName,
        DateofBirth: this.searchOption.FromDate,
        CaseOwner: this.user.type >= 3 ? this.searchOption.contactId : this.user.id,
        LocationID: this.bookingProps.locationId,
        BookingDate: this.bookingProps.bookingDate,
        AvailabilityID: this.bookingProps.availabilityId,
        DateSubmitted: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        UserID: this.user.id,
        Username: this.user.name
      }
      this.$store.dispatch('loading/setLoadComponent', true)
      await utilities.postTentativeBooking(param)
      this.$store.dispatch('loading/setLoadComponent', false)
      this.$router.push('/admin-bookings-tentative')
    },

    async tapWaitList() {
      this.hasError = false
      if (!this.validateClient || !this.validateClaimantFirstName || !this.validateClaimantSurname || !this.validateContact || !this.validateReferenceNumber || !this.validateDateOfBirth) {
        this.hasError = true
        return
      }
      const param = {
        BookingTypeID: this.bookingProps.typeId || 0,
        ClientID: this.searchOption.ClientID || this.user.clientId,
        SpecialistID: this.bookingProps.id,
        ClaimantFirstName: this.searchOption.ClaimantFirstName,
        ClaimantSurname: this.searchOption.ClaimantSurName,
        DateofBirth: this.searchOption.FromDate,
        CaseOwner: this.searchOption.contactId || this.user.id,
        CaseReference: this.searchOption.ReferenceNumber,
        LocationID: this.bookingProps.locationId,
        BookingDate: this.bookingProps.bookingDate,
        RequestedDay: this.bookingProps.bookingDate,
        DateSubmitted: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        UserID: this.user.id,
        Username: this.user.name
      }
      this.$store.dispatch('loading/setLoadComponent', true)
      await utilities.postWaitlistBooking(param, () => {
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Waitlist booking created' +
              '          </div>',
        });
        this.$router.push({name: 'admin-bookings-waitlisted'})
      })
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async tapSearch() {
      this.hasError = false
      if (!this.validateClient || !this.validateClaimantFirstName || !this.validateClaimantSurname || !this.validateDateOfBirth || !this.validateContact ) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".alert-danger")
        return
      }
      if(this.isTentative) {
        this.createTentative()
        return
      }
      this.$store.dispatch('loading/setLoadComponent', true)

      if (this.user.type >= 3) {
        this.searchOption.ClientID = this.searchOption.ClientID
      } else {
        // params.Cc = 1
        this.searchOption.ClientID = this.user.clientId
      }

      this.cases = []
      const {clientCase, total} = await utilities.getCasesNew(this.searchOption)
      this.cases = clientCase
      this.totalCount = total
      //Changed the flow (issue #219). When used didn't get any matching result stay in the same screen and showing the message. User can search again or create a new case with exsisting details
      // if (this.cases.length == 0) {
      //   this.bookingProps.noResult = true
      //   //this.tapAddNew()
      // } else {
      //   this.showResult = true
      // }
      this.showResult = true
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    getLocationName(fullLocation = false) {
      if (this.booking.locationId && this.locations.length > 0) {
        if (fullLocation) {
          return this.locations.find((x) => x.id == this.booking.locationId).fullLocation.replace(', Australia', "")
        } else {
          return this.locations.find((x) => x.id == this.booking.locationId).shortLocation
        }

      }
      return ""
    },

    selectCase(item) {
      utilities.clearChangingObject()
      this.bookingProps.caseId = item.id
      this.bookingProps.caseOwnerName = item.caseOwnerName
      this.bookingProps.isNewCase = false
      this.$store.dispatch("booking/setBookingObject", this.bookingProps)
      if (this.user.type >= 3) {
        this.$router.push({ name: 'admin-create-new-booking', query: { clientId: item.id }, params: { bookingProps: this.bookingProps } }).catch((err) => {})
      } else {
        this.$router.push({ name: 'manage-booking', params: { bookingProps: this.bookingProps } }).catch((err) => {})
      }
    },

    tapAddNew() {
      console.log(this.searchOption)
      this.bookingProps.isNewCase = true
      this.bookingProps.caseId = ''
      this.bookingProps.dateOfBirth = window.moment(this.searchOption.FromDate, "YYYY-MM-DD").format('YYYY-MM-DD')
      this.bookingProps.claimantFirstName = this.searchOption.ClaimantFirstName
      this.bookingProps.claimantLastName = this.searchOption.ClaimantSurName
      this.bookingProps.caseReference = this.searchOption.ReferenceNumber

      if (this.user.type >= 3) {
        this.bookingProps.clientId = this.searchOption.ClientID
        this.bookingProps.caseOwner = this.searchOption.contactId
      }
      this.$store.dispatch("booking/setBookingObject", this.bookingProps)
      if (this.user.type >= 3) {
        this.$router.push({ name: 'admin-create-new-booking', params: { bookingProps: this.bookingProps } }).catch((err) => {})
      } else {
        this.$router.push({ name: 'manage-booking', params: { bookingProps: this.bookingProps } }).catch((err) => {})
      }
    },

    tapReadMore(index) {
      this.$refs.profileReadMoreModal.loadData(index)
      this.$refs.profileReadMoreModal.handleModal()
    },

    getSpecialistInfo(locationId, locations) {
      var text = ""
      if (this.specialist.qualificationList) {
        text = this.specialist.qualificationList
        return text
      }

      // if (locationId) {
      //   var value = locations.find((x) => x.id == locationId).shortLocation
      //   if (value) {
      //     if (text.length == 0) {
      //       return value
      //     } else {
      //       return text + ", " + value
      //     }
      //   }
      // }
      return ""
    },

    formatDateTime(date, format, currentFormat = "YYYY-MM-DD") {
      if (date != '') {
        if (format == 'MMM, YYYY hh:mm A' && this.booking.bookingStatus == 16) {
          format = 'MMM, YYYY'
          return utilities.formatDate(date, format, currentFormat)
        } else {
          return utilities.formatDate(date, format, currentFormat)
        }
      }
      return ''
    },

    async loadData() {
      if (this.user.type >=3) {
        this.clients = await utilities.getClients();
        this.originalListClients = _.cloneDeep(utilities.getDropdownAutoCompleteTag(this.clients))
        this.listClients = this.originalListClients
        if (this.bookingProps.clientId != undefined) {
          this.contacts = await utilities.getClientContacts(this.bookingProps.clientId)
          this.originalClientContacts = _.cloneDeep(utilities.getClientsDropdownAutoCompleteTagWithName(this.contacts, false))
          this.listContact = this.originalClientContacts.map(item => item.name)
        }
      }
    },
    setDefaultInput(bookingObj) {
      this.searchOption.ClaimantFirstName = bookingObj.claimantFirstName
      this.searchOption.ClaimantSurName = bookingObj.claimantSurname
      this.searchOption.ClientID = bookingObj.clientId
      this.searchOption.contactId = bookingObj.caseOwner
      this.searchOption.FromDate = bookingObj.DateofBirth
      this.searchOption.ReferenceNumber = bookingObj.caseReference
      this.dateValue = bookingObj.DateofBirth
    }
  },

  async beforeMount() {
    this.bookingProps = this.$store.getters["booking/getBookingObject"]
    // console.log("BookingProps")
    this.bookingProps.isNewCase = false
    this.bookingProps.noResult = false
    if (this.bookingProps == null) {
      if (this.user.type >= 3) {
        this.$router.push({ name: 'management'}).catch((err) => {})
      } else {
        this.$router.push({ name: 'home'}).catch((err) => {})
      }
      return
    }
    if(this.bookingProps.claimantFirstName) this.searchOption.ClaimantFirstName = this.bookingProps.claimantFirstName
    if(this.bookingProps.claimantSurname)this.searchOption.ClaimantSurName = this.bookingProps.claimantSurname
    if(this.bookingProps.isTentative || this.bookingProps.WaitListPreBookingID) {
      this.setDefaultInput(this.bookingProps)
    }
    this.$store.dispatch("booking/setBookingObject", this.bookingProps)
    this.$store.dispatch('loading/setLoadComponent', true)
    utilities.getSpecialistById(this.bookingProps.id).then(r => {
      this.specialist = r
      if(this.specialist.photo !== '') {
        // Load profile photo
        utilities.getProfileThumbnail(this.specialist.photo).then(res => {
          this.specialist.profileThumbnail = res
        })
      }
    })
    await this.loadData()
    this.$store.dispatch('loading/setLoadComponent', false)
  },
  mounted() {
    setTimeout((x) => {
      $(".dropdown-input").attr({
       autocomplete: "off",
      });
    }, 500)
  },
}
</script>
