/* eslint-disable */
<template>
  <div class="footer">
    <p>©2008–2020 Red Medicine Pty Ltd, </p>
    <p>trading as Red Health Independent Medical Assessments</p>
    <p>
      <a href="#">Privacy</a>&nbsp;&nbsp;&nbsp;
      <a href="#">Terms of Use</a>
    </p>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
