<template>
  <div class="admin-update-hero-image-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="min-height: 400px;">
          <div class="modal-header div-responsive flex-column position-relative">
            <div class="modal-title">
              <h1>Hero Image</h1>
            </div>
            <div>
              <v-btn-toggle v-model="tab" mandatory>
                <v-btn style="height: 30px" class="radio-toggle"> Upload Image</v-btn>
                <v-btn style="height: 30px" class="radio-toggle"> Url Setting</v-btn>
              </v-btn-toggle>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div v-if="tab === 0" class="modal-body div-responsive">
            <div class="pt-5">
              Dimensions 779 X 729 px
            </div>
            <div class="pl-2">
              {{ fileName }}
            </div>
            <form>
              <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave"
                   @drop="drop">
                <input
                    type="file"
                    name="fields[assetsFieldHandle][]"
                    id="assetsFieldHandle"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    @change="onChange"
                    ref="file"
                    accept=".jpg,.jpeg,.png"/>
                <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                  <div>Drop image in this area or <span style="text-decoration:underline"
                                                        class="is-icon">click here</span> to upload image.
                  </div>
                </label>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="upload">Upload</v-btn>
                  </div>
                </div>
              </div>
            </form>

            <div class="alert alert-success alert-success-banner hidden-element">
              <a class="close" title="close">×</a>
              <b>Success:</b> Updated. Please logout and refresh the home page to see the changes
            </div>

          </div>
          <div v-else class="modal-body d-flex div-responsive flex-column justify-content-between">
            <div>
              <span>Url: </span>
              <el-input v-model="src"/>
              <div class="text-danger" v-if="!validateUrl">
                url must contains https://
              </div>
            </div>
            <div class="icon-group d-flex justify-content-end">
              <v-btn dark @click="saveUrl">Save</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AdminUpdateHeroImageModal",
  data() {
    return {
      loadModal: false,
      filelist: [],
      src: '',
      fileName: '',
      tab: 'Upload Image'
    }
  },
  computed: {
    validateUrl() {
      let reg = /^https?:\/\/.*$/
      return reg.test(this.src)
    }
  },
  methods: {
    async getImage() {
      this.src = await utilities.getHeroImgSrc()
    },
    handleModal(e) {
      this.loadModal = !this.loadModal
      if (this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },

    dragover(event) {
      event.preventDefault()
      event.currentTarget.classList.add('dark-border-hover')
    },

    dragleave(event) {
      event.currentTarget.classList.remove('dark-border-hover')
    },

    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()

      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },

    async onChange() {
      this.filelist = [...this.$refs.file.files]
      this.fileName = this.filelist[0].name
    },

    async saveUrl() {
      if (!this.validateUrl) {
        return
      }
      this.$store.dispatch('loading/setLoadComponent', true)
      const result = await utilities.putHeroImgSrc(this.src)
      if (result) {
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Url updated !' +
              '          </div>',
        });
      }
      this.$store.dispatch('loading/setLoadComponent', false)
      setTimeout(() => {
        this.handleModal()
      }, 2200)
    },

    async upload() {
      if (this.filelist.length > 0) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          FileBinary: '',
          MimeType: '',
        }
        await Promise.all(this.filelist.map(async (x) => {
          await new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.onloadend = async () => {
              params.FileBinary = reader.result
              params.MimeType = x.type
              resolve(true)
            }
            reader.onerror = (error) => {
              reject(error)
            }
            reader.readAsBinaryString(x)
          })
        }))
        var result = await utilities.postUploadImage(params)
        if (result) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-success">' +
                '            <b>Success:</b> Updated. Please logout and refresh the home page to see the changes !' +
                '          </div>',
          });
        }
        this.$store.dispatch('loading/setLoadComponent', false)
        setTimeout(() => {
          this.handleModal()
        }, 2200)

      }
    },
  },
}
</script>
