/* eslint-disable */
<template>
  <div class="user-setting">
    <SpecialistNavigation indexProps="guides"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>{{ title }}</h1></div>
      </div>
    </div>
    <div class="panel">
      <SpecialistGuidesMenu :indexProps="index"/>
      <div class="content">
        <iframe v-show="frameSrc" :src="frameSrc" style="width: 100%; height: 100vh">
          <!--        <pdf-->
          <!--            v-for="i in numPages"-->
          <!--            :key="i"-->
          <!--            :src="src"-->
          <!--            :page="i"-->
          <!--            style="width: 70%"-->
          <!--        ></pdf>-->
        </iframe>
      </div>
    </div>
    <Chat/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import SpecialistGuidesMenu from "@/components/SpecialistGuidesMenu";
import SpecialistNavigation from "@/components/SpecialistNavigation";

export default {
  name: 'UserSetting',
  components: {
    SpecialistNavigation,
    SpecialistGuidesMenu,
    Navigation,
    Header,
    Chat
  },
  data() {
    return {
      loading: false,
      src: null,
      numPages: undefined,
      index: 1,
      title: '',
      frameSrc: ''
    }
  },
  methods: {
    async tapUpdate() {
      utilities.showAlert(".alert-warning", false)
    }
  },
  async mounted() {
    this.$store.dispatch('loading/setLoadComponent', true)
    this.index = this.$route.query.index
    if (this.index === '0') {
      this.src = "Specialist Module - Adding Extra Availability for bookings.html";
      this.title = "Specialist Module - Adding Extra Availability for bookings"
    } else if (this.index === '1') {
      this.src = "Specialist Module - Adding your dictation or report to a booking.html";
      this.title = 'Specialist Module - Adding your dictation or report to a booking'
    } else if (this.index === '2') {
      this.title = 'Specialist Module - Downloading your Schedules'
      this.src = "Specialist Module - Downloading your Schedules.html";
    } else if (this.index === '3') {
      this.title = 'Specialist Module - Navigating Bookings, Downloading Files, and Viewing your calendar'
      this.src = "Specialist Module - Navigating Bookings, Downloading Files, and Viewing your calendar.html";
    } else if (this.index === '4') {
      this.title = 'Specialist Module - Updating your Profile'
      this.src = "Specialist Module - Updating your Profile.html";
    }
    this.frameSrc = await utilities.getDownloadHowToUrlSpecialist({
      FileName: this.src
    })
    this.$store.dispatch('loading/setLoadComponent', false)
  },
}
</script>
