/* eslint-disable */
<template>
  <div class="admin-edit-client-contact">
    <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="2" />
    <Navigation v-else ref="navigation" indexProps="0" />
    <div class="top-panel">
      <Header />
    </div>
    <div class="panel">
      <AdminClientsMenu v-if="user.type >= 3" :indexProps="3" />
      <div class="content" style="left: 0">
        <div v-if="contact != undefined">
          <div class="mb-2">Some details can't be updated online. If you need to update these details, please call 1300 100 733.</div>
          <div class="box">
            <div class="box-heading col-space-between">
              <div>Contact details</div>
              <a v-if="zohoLink.length > 0 && zohoLink[0].zohoClientContactLink" :href="zohoLink[0].zohoClientContactLink" class="mr-1" target="blank"><img src="@/assets/images/zoho_circle.svg" class="mr-2 is-icon" /></a>
            </div>
            <div class="box-body">
              <div class="box-row disabled-element">
                <div class="box-col box-col-left">Client</div>
                <div class="box-col box-col-right disable-input">
                  <el-input disabled v-model="contact.clientName" />
                </div>
              </div>
              <div class="box-row disabled-element" v-if="!isNew">
                <div class="box-col box-col-left">Branch</div>
                <div class="box-col box-col-right disable-input">
                  <el-input disabled v-model="contact.branchName" />
                </div>
              </div>
              <div class="box-row disabled-element" v-if="!isNew && (user.type == 3 || user.type == 6)">
                <div class="box-col box-col-left">Active</div>
                <div class="box-col box-col-right">
                  <el-tooltip v-if="contact.isPrimaryContact" effect="dark" placement="bottom">
                    <div v-if="contact.isPrimaryContact" slot="content">Cannot deactivate as they are a primary user. Select a new primary user before deactivating this account</div>
                    <div style="width: fit-content">
                      <el-input  :key="activeKey" type="checkbox" :style="{cursor: contact.isPrimaryContact ? 'not-allowed' : 'default'}" disabled v-model="contact.active" />
                    </div>
                  </el-tooltip>
                  <div v-else style="width: fit-content">
                    <el-input :key="activeKey" disabled type="checkbox" :style="{cursor: contact.isPrimaryContact ? 'not-allowed' : 'default'}" v-model="contact.active" />
                  </div>
                </div>
              </div>
              <div class="box-row disabled-element">
                <div class="box-col box-col-left">First Name</div>
                <div class="box-col box-col-right disable-input">
                  <el-input disabled type="text" v-model="contact.firstName"  placeholder="Enter First Name" />
                </div>
              </div>
              <div class="box-row disabled-element">
                <div class="box-col box-col-left">Middle Name</div>
                <div class="box-col box-col-right disable-input">
                  <el-input autocomplete="new-password" disabled type="text" v-model="contact.middleName" placeholder="Enter Middle Name" />
                </div>
              </div>
              <div class="box-row disabled-element">
                <div class="box-col box-col-left">Surname</div>
                <div class="box-col box-col-right disable-input">
                  <el-input autocomplete="new-password" type="text" v-model="contact.surname" disabled placeholder="Enter Last Name" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Salutation</div>
                <div class="box-col box-col-right disable-input">
                  <el-select :popper-append-to-body="false" class="w-100" v-model="contact.Salutation" filterable clearable placeholder="Select Salutation">
                    <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Land Line</div>
                <div class="box-col box-col-right disable-input">
                  <el-input autocomplete="new-password" type="text" v-model="contact.phoneNumber1"  placeholder="Enter Land Line" />
                  <div class="text-danger" v-if="hasError && !validatePhone1">
                    Please enter a valid phone number.
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left required">Mobile</div>
                <div class="box-col box-col-right disable-input">
                  <el-input type="text" v-model="contact.mobile" placeholder="Enter Mobile" />
                  <div v-if="hasError && !validateMobile" class="text-danger">{{errorMobileMessage}}</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Work Phone</div>
                <div class="box-col box-col-right disable-input">
                  <el-input type="text" v-model="contact.phoneNumber2" placeholder="Enter Work Phone" />
                  <div v-if="hasError && !validatePhone2" class="text-danger">Work Phone is invalid.</div>
                </div>
              </div>
              <div class="box-row disabled-element">
                <div class="box-col box-col-left">Email Address</div>
                <div class="box-col box-col-right disable-input">
                  <el-input disabled type="text" v-model="contact.emailAddress"  placeholder="Enter Email Address" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Notification Email Address</div>
                <div class="box-col box-col-right disable-input">
                  <el-input autocomplete="new-password" type="text" v-model="contact.NotificationEmailAddress" placeholder="Enter Email Address" />
                  <div v-if="hasError && !validateNotificationEmail" class="text-danger">{{ errorEmailMessage }}</div>
                </div>
              </div>
              <div class="box-row disabled-element">
                <div class="box-col box-col-left">Role</div>
                <div class="box-col box-col-right disable-input">
                  <el-input v-model="contact.userRoleName" disabled />
                </div>
              </div>
              <div class="box-row" v-if="(user.type == 3 || user.type == 6)">
                <div class="box-col box-col-left">Primary Contact?</div>
                <div class="box-col box-col-right disable-input">
                  <el-input type="checkbox" :disabled="originalItem.isPrimaryContact" v-model="contact.isPrimaryContact" @change="changeIsPrimaryContact($event)" />
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-success alert-success-resend hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div v-if="showUpdate" class="box-row item-right button-action">
            <div class="ml-5"><v-btn :disabled="disableButton" dark  @click="tapUpdateContact">{{ 'Update' }}</v-btn></div>
          </div>

          <div class="warning-alert alert alert-warning hidden-element">
            <a class="close" title="close">×</a>
            <b>Warning:</b> {{ errorMessage }}
            <v-btn class="alert-button ml-4" @click="tapCreate()">Save</v-btn>
            <v-btn class="alert-button ml-4" @click="tapCancel()">Cancel</v-btn>
          </div>

          <div class="warning-alert-zoho alert alert-warning hidden-element">
            <a class="close" title="close">×</a>
            <b>Warning:</b> {{ errorZohoMessage }}
            <v-btn class="alert-button ml-4" @click="tapZohoCancel()">Ok</v-btn>
          </div>
        </div>
        <div v-else>Contact does not exist</div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import Navigation from '@/components/Navigation'
import Header from '@/components/Header'
import AdminClientsMenu from '@/components/AdminClientsMenu'

export default {
  name: 'AdminEditClientContact',
  props: ["searchOptionClientProps", "searchOptionContactProps"],
  components: {
    AdminNavigation,
    Header,
    AdminClientsMenu,
    Navigation
  },
  data() {
    return {
      salutationList: utilities.initSalutation(),
      loading: false,
      clientList: [],
      zohoLink: [],
      hasError: false,
      successMessage: '',
      errorMessage: 'Please correct the errors shown above and try again.',
      errorEmailMessage: '',
      errorMobileMessage: '',
      errorZohoMessage: 'Could not create the contact in Zoho.',

      clientId: '',
      contactId: '',
      contact: this.initContact(),
      originalItem: this.initContact(),
      allContacts: [],
      clients: [],
      cases: [],
      isNew: false,
      createContact: false,
      ignorName: false,
      showResend: false,
      disableButton: false,
      user: window._.cloneDeep(this.$store.getters["user/getUser"]),

      activeKey: 0,
    }
  },
  computed: {
    showUpdate() {
      return !window._.isEqual(this.originalItem, this.contact)
    },
    validateContactFirstName() {
      if (this.contact.firstName.trim() == '') {
        return false
      }
      return true
    },
    validateContactSurname() {
      if (this.contact.surname.trim() == '') {
        return false
      }
      return true
    },
    validateContactEmail() {
      if (this.contact.emailAddress.trim() == '') {
        this.errorEmailMessage = "Email is required."
        return false
      } else if(this.contact.emailAddress.trim() != '' && !utilities.validateEmail(this.contact.emailAddress)) {
        this.errorEmailMessage = "Email is invalid."
        return false
      }
      return true
    },
    validateNotificationEmail() {
      if (this.contact.NotificationEmailAddress.trim() === '') {
        return true
      } else if(this.contact.NotificationEmailAddress.trim() !== '' && !utilities.validateEmail(this.contact.NotificationEmailAddress)) {
        this.errorEmailMessage = "Email is invalid."
        return false
      }
      return true
    },
    validateMobile() {
      if(this.contact.canLogin == 1 && this.contact.mobile == '') {
        this.errorMobileMessage = "Mobile is required."
        return false
      } else if(this.contact.mobile != '' && !utilities.validatePhoneAU(this.contact.mobile)) {
        this.errorMobileMessage = "Please enter a valid mobile number in International format without spaces - i.e. +61400123456"
        return false
      }
      return true
    },
    validatePhone1() {
      if(this.contact.phoneNumber1 != '' && !utilities.validateLandPhone(this.contact.phoneNumber1.trim())) {
        return false
      }
      return true
    },
    validatePhone2() {
      if(this.contact.phoneNumber2 != '' && !utilities.validateLandPhone(this.contact.phoneNumber2)) {
        return false
      }
      return true
    },
  },
  methods: {
    tapChangeOwnership() {
      if(this.cases.length == 0) return
      this.$refs.adminChangeOwnershipCasesModal.handleModal()
      console.log(this.contact)
      this.$refs.adminChangeOwnershipCasesModal.fromId = this.contact.clientContactId
      this.$refs.adminChangeOwnershipCasesModal.contactName = this.contact.fullName
      this.$refs.adminChangeOwnershipCasesModal.setCaseOwners(this.client.contacts.filter((x) => x.userId != this.contact.userId))
    },
    tapCancel() {
      $('.warning-alert').hide()
      this.createContact = false
    },

    tapCreate() {
      this.createContact = true
      $('.warning-alert').hide()
      this.callCreateContact()
    },

    tapZohoCancel() {
      $('.warning-alert-zoho').hide()
      window.history.replaceState({}, document.title, location.pathname + location.search + "&contactId=" + this.contact.clientContactId)
      setTimeout(() => {
        location.reload()
      }, 300)
    },

    setSuccessMessage(status) {
      if (status == 200) {
        this.successMessage = "You have submitted a request to change case ownership!"
        utilities.showAlert(".alert-success-resend")
      } else if (status == 400) {
        this.errorMessage = "No cases for this client contact."
        utilities.showAlert(".alert-danger")
      }
    },
    initContact() {
      return {
        clientContactId: '',
        mobile: '',
        surname: '',
        canLogin: false,
        firstName: '',
        middleName: '',
        emailAddress: '',
        phoneNumber1: '',
        phoneNumber2: '',
        isPrimaryContact: false,
        userRoleId: 1,
        active: true,
        fullName: '',
        NotificationEmailAddress: '',
        Salutation: '',
      }
    },
    changeIsPrimaryContact(e) {
      if(this.contact.isPrimaryContact && !this.contact.active) {
        this.contact.active = true
        this.activeKey += 1
      }
    },

    validateClientEmail() {
      if (!this.isNew) {
        return true
      }
      if (this.allContacts.map(x=>{return x.emailAddress}).includes(this.contact.emailAddress)) {
        this.errorMessage = 'Email Address Already Exists!'
        utilities.showAlert('.alert-danger')
        this.createContact = false
        return false
      }
      return true
    },

    async validateContactName(ignorName) {
      if (!this.isNew) {
        return true
      }
      var isExists = false
      this.allContacts.map((x) => {
        if (x.firstName.toUpperCase() == this.contact.firstName.toUpperCase() && x.middleName.toUpperCase() == this.contact.middleName.toUpperCase() && x.surname.toUpperCase() == this.contact.surname.toUpperCase()) {
          this.errorMessage = 'The entered contact name already exists. Do you wish to continue?'
          utilities.showAlert('.warning-alert', false)
          isExists = true
          return
        }
      })
      if (isExists) {
        return false
      } else {
        return true
      }

    },

    async tapUpdateContact() {
      this.errorMessage = 'Please correct the errors shown above and try again.'
      if (!this.validateNotificationEmail || !this.validatePhone2 || !this.validatePhone1 || !this.validateMobile) {
        this.hasError = true
        utilities.showAlert('.alert-danger')
        return
      }
      await this.callCreateContact()
    },

    async callCreateContact() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.contact.fullName = this.contact.firstName + ' ' + (this.contact.middleName == '' ? '' : this.contact.middleName + ' ') + this.contact.surname
      var number = utilities.updateMobileCode(this.contact.mobile)
      var params = {
        Mobile: utilities.validatePhonenumber(number),
        Surname: this.contact.surname,
        Salutation: this.contact.Salutation,
        CanLogin: utilities.convertBoolToNum(this.contact.canLogin),
        FirstName: this.contact.firstName,
        MiddleName: this.contact.middleName,
        EmailAddress: this.contact.emailAddress,
        NotificationEmailAddress: this.contact.NotificationEmailAddress,
        PhoneNumber1: utilities.validatePhonenumber(this.contact.phoneNumber1),
        PhoneNumber2: utilities.validatePhonenumber(this.contact.phoneNumber2),
        IsPrimaryContact: utilities.convertBoolToNum(this.contact.isPrimaryContact),
        UserRoleID: this.contact.userRoleId,
        UserID: this.user.id,
        Username: this.user.name,
        Active: this.contact.active ? 1 : 0,
      }

      if (this.originalItem) {
        if (!this.contact.canLogin && this.originalItem.canLogin) { // Check User Login
          params.Deactivate = 1
        } else if (this.contact.canLogin && !this.originalItem.canLogin && this.originalItem.emailAddress != '') {  // Uncheck User Login for existing client contact
          params.Reactivate = 1
        } else {  // other cases - always send Reactivate 0 as default
          params.Reactivate = 0
        }

        if (this.contact.emailAddress != this.originalItem.emailAddress) {
          params.OldEmailAddress = this.originalItem.emailAddress
        }
      }
      if (this.contact.clientContactId != undefined && this.contact.clientContactId != '')
        params.ClientContactID = this.contact.clientContactId

      if (this.contact.contactUserId != undefined && this.contact.contactUserId != '')
        params.ContactUserID = this.contact.contactUserId

      params.ClientID = this.contact.clientId
      var result = ""
        if (this.contact.canLogin) {
          result = await utilities.putContactCanLogin(params)
        } else {
          result = await utilities.putContact(params)
        }
        if (result) {
          if(this.contact.canLogin) {
            let user = _.cloneDeep(this.$store.getters['user/getUser'])
            user.mobile = btoa(utilities.removeMobileCode(params.Mobile))
            this.$store.dispatch('user/setUser', user)
          }
          await this.callPutClientContactToZoho(number)
          if (this.contact.canLogin) {
            this.showResend = true
          } else {
            this.showResend = false
          }
          this.originalItem =  _.cloneDeep(this.contact)
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-success">' +
                '            <b>Success:</b> Contact Updated !' +
                '          </div>',
          });
          this.$router.push({ name: 'home'})
        }
      this.createContact = false
      this.$store.dispatch('loading/setLoadComponent', false)
    },


    async callPutClientContactToZoho(number) {
      var zohoLink = ''
      if (this.client.zohoLink) {
        zohoLink = this.client.zohoLink.split('/')[this.client.zohoLink.split('/').length - 1]
      }
      var params = {
        FirstName: this.contact.firstName,
        Surname: this.contact.surname,
        EmailAddress: this.contact.emailAddress,
        NotificationEmailAddress: this.contact.NotificationEmailAddress,
        Salutation: this.contact.Salutation,
        Mobile: number,
        PhoneNumber1: utilities.validatePhonenumber(this.contact.phoneNumber1),
        PhoneNumber2: utilities.validatePhonenumber(this.contact.phoneNumber2),
        UserID: this.user.id,
        Username: this.user.name,
        ClientZohoLink: zohoLink,
        ClientContactID: this.contactId,
        ClientID: this.user.clientId,
        Status: this.contact.active ? 1 : 0,
        ZohoCCID: this.contact.zohoClientContactId
      }
      await utilities.putClientContactToZoho(params)
    },

    async loadData() {
      if (this.contact) {
        // this.contact.active = true
        this.originalItem = _.cloneDeep(this.contact)
        let params = {
          clientId: this.clientId,
          userId: this.user.id
        }
        // this.clientId  = this.contact.clientId
        const apiArr = [utilities.getBranchNamesOfClient(this.contact.clientName), utilities.getCasesByClientId(params)]
        const r = await Promise.all(apiArr)
        this.clients = r[0]
        let cases = r[1]
        this.cases = cases.filter(x => x.caseOwner === this.contact.userId)
        if (this.contact.canLogin) {
          this.showResend = true
        } else {
          this.showResend = false
        }
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    $("body>.tooltip").remove()
    this.clientId = this.user.clientId

    if(this.clientId !== '') {
      this.zohoLink = this.allContacts.filter(item => item.clientContactId.toString() === this.contactId.toString())
      this.client = await utilities.getClientById(this.clientId)
      this.clientList.push(this.client)
      this.allContacts = this.client.contacts
          this.allContacts.map(x => {
            x.mobile = utilities.removeMobileCode(x.mobile)
          })
      this.contact = this.allContacts.find(item => item.userId === this.user.id)
      if(this.contact.mobile === utilities.DEFAULT_MOBILE) {
        this.contact.mobile = ''
      }
      this.contactId = this.contact.clientContactId
    }
    if(this.contactId !== '') { // Edit contact
      await this.loadData()
    }  else {
      this.contact = undefined
      this.showResend = false
    }
    this.$store.dispatch('loading/setLoadComponent', false)
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    const user = _.cloneDeep(this.$store.getters['user/getUser'])
    if(!user || !user.mobile) {
      next()
      return
    }
    const mobile = atob(user.mobile)
    if(mobile === utilities.DEFAULT_MOBILE) {
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: '<div class="alert-success">' +
            '            <b>Warning:</b> Please update your mobile number' +
            '          </div>',
      });
      return
    } else {
      next()
      return
    }
    next()
  },
}
</script>
