/* eslint-disable */
<template>
  <div class="client-requests">
    <AdminNavigation ref="navigation" indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Client Account Requests</h1></div>
        <div class="header-sub-title">Client account requests submitted by, or on behalf of, a Client, for action by Red Health.</div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu indexProps="2" />
      <div class="content">
        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 15%" class="long-text">First Name</th>
                    <th style="width: 15%" class="long-text">Surname</th>
                    <th style="width: 20%" class="long-text">Email Address</th>
                    <th style="width: 10%" class="long-text">Mobile Number</th>
                    <th style="width: 15%" class="long-text">Company Name</th>
                    <th style="width: 15%" class="long-text">Company Branch Location</th>
                    <th style="width: 10%" class="long-text">Date Received</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="allClientRequests.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                  <tr v-for="(clientRequest, index) in clientRequests" class="is-icon">
                    <td style="width: 15%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ clientRequest.firstName }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ clientRequest.surname }}
                    </td>
                    <td style="width: 20%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ clientRequest.email }}
                    </td>
                    <td style="width: 10%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ clientRequest.mobile }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ clientRequest.companyName }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ clientRequest.companyBranchName }}
                    </td>
                    <td style="width: 10%" class="long-text" @click="openClientRequest(clientRequest.id)">
                      {{ formatDate(clientRequest.dateReceived, "DD/MM/YYYY") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminClientRequests',
    components: {
      AdminNavigation,
      AdminClientsMenu,
      Header,
    },
    data() {
      return {
        fetchedData: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),

        clientRequests: [],
        allClientRequests: [],
        limit: 10,
        pagination: utilities.initPagination(),
      }
    },
    computed: {},
    methods: {
      selectPage(index) {
        utilities.disposeTooltip()
        this.pagination.current = index
        this.clientRequests = this.pagination.list[this.pagination.current]
        utilities.initTooltip()
      },
      openClientRequest(id) {
        this.$router.push({ name: 'admin-manage-client-request', query: { id: id } })
      },
      formatDate(dateToFormat, newFormat) {
        return utilities.formatDate(dateToFormat, newFormat)
      },
      paging(clientRequests) {
        utilities.disposeTooltip()
        if (clientRequests.length == 0 || clientRequests.length <= this.limit) {
          this.clientRequests = clientRequests
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(clientRequests, this.limit)
          this.clientRequests = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
        utilities.initTooltip()
        utilities.hoverTooltip()
      },

      async loadClientRequests() {
        var clientRequests = await utilities.getClientRequests()
        clientRequests = clientRequests.filter(x => !x.isActioned)
        clientRequests.sort((a, b) => {
          return moment(a.dateReceived).isAfter(moment(b.dateReceived)) ? 1 : -1
        })
        this.allClientRequests = _.cloneDeep(clientRequests)

        this.paging(clientRequests)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadClientRequests()
      this.fetchedData = true
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $(".pagination").hide()
    },
  }
</script>
