/* eslint-disable */
<template>
  <div class="hours-booked">
    <AdminNavigation ref="navigation" indexProps="6" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Number of Hours of Appointments Booked ({{ totalHours }})</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminReportsMenu indexProps="2" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <select class="bg-white d-inline-block" v-model="client" style="min-width:300px" @change="selectClient()">
                <option value="all">All Clients</option>
                <option v-for="client in clientList" :key="client.id" :value="client.id">{{ client.name }}</option>
              </select>
              <download-csv :data="getCalendarData()" :name="getFileName()" class="w-auto ml-1">
                <v-btn dark>CSV <img src="@/assets/images/download_light_icon.svg" /></v-btn>
              </download-csv>
            </div>
          </div>
        </div>

        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:20%" class="long-text">Client</th>
                    <th style="width:25%" class="long-text">Branch Name</th>
                    <th style="width:20%" class="long-text">Number of Bookings</th>
                    <th style="width:20%" class="long-text">Number of Hours</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="hoursBooked.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(hours, index) in hoursBooked" :key="index">
                    <td style="width:20%" class="long-text">{{ hours.clientName }}</td>
                    <td style="width:25%" class="long-text">{{ hours.branchName }}</td>
                    <td style="width:20%" class="long-text">{{ hours.numberOfBookings }}</td>
                    <td style="width:20%" class="long-text">{{ hours.bookedHours }}</td>
                  </tr>
                  <tr v-if="hoursBooked.length == 0">
                    <td colspan="4">No hours booked {{client=='all' ? '':'for this client'}}!</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminReportsMenu from '@/components/AdminReportsMenu'

  export default {
    name: 'AdminHoursBookedByClient',
    components: {
      AdminNavigation,
      Header,
      AdminReportsMenu,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        hoursBooked: [],
        limit: 10,
        pagination: utilities.initPagination(),
        allHoursBooked: [],
        clientList: [],
        client: 'all',
        totalHours: 0,
      }
    },
    methods: {
      async loadData(startDate = '', endDate = '', clientId = '') {
        this.hoursBooked = []
        this.allHoursBooked = []
        this.totalHours = 0

        var localHours = []
        //   var locations = await utilities.getLocations()
        // No client id or start/end (default!)
        if ((startDate == '' && clientId == '') || (startDate == endDate && clientId == '')) {
          localHours = await utilities.getReportHoursBookedByClient(
            moment().format('YYYY-MM-DD'),
            moment()
              .add(10, 'year')
              .format('YYYY-MM-DD')
          )
        }
        // No client id but has start/end
        else if (startDate != '' && startDate != endDate && clientId == '') {
          localHours = await utilities.getReportHoursBookedByClient(startDate, endDate)
        }
        // Has client ID but no start/end
        // else if (startDate == '' || startDate == endDate) {
        else {
          localHours = await utilities.getReportHoursBookedByClient(
            moment().format('YYYY-MM-DD'),
            moment()
              .add(10, 'year')
              .format('YYYY-MM-DD'),
            clientId
          )
        }
        // Has client ID and start/end
        // else {
        //   localHours = await utilities.getReportHoursBookedByClient(startDate, endDate, clientId)
        //   console.log("HERE4");
        // }

        localHours.map((x) => {
          this.totalHours += parseFloat(x.bookedHours)
        })
        localHours.map((x) => {
          if (this.allHoursBooked[x.clientName] !== undefined) {
            this.allHoursBooked[x.clientName].numberOfBookings += parseInt(x.numberOfBookings)
            this.allHoursBooked[x.clientName].bookedHours += parseFloat(x.bookedHours)
          } else {
            this.allHoursBooked[x.clientName] = {
              clientName: x.clientName,
              branchName: x.branchName,
              bookedHours: parseFloat(x.bookedHours),
              numberOfBookings: parseInt(x.numberOfBookings),
            }
          }
        })
        this.allHoursBooked = Object.values(this.allHoursBooked)
        this.paging(this.allHoursBooked)
      },
      paging(hoursBooked) {
        if (hoursBooked.length == 0 || hoursBooked.length <= this.limit) {
          this.hoursBooked = hoursBooked
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(hoursBooked, this.limit)
          this.hoursBooked = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
      },

      selectPage(index) {
        this.pagination.current = index
        this.hoursBooked = this.pagination.list[this.pagination.current]
      },

      getFileName() {
        return 'Hours_booked_by_client_' + moment().format('YYYYMMDDHHmm') + '.csv'
      },

      getCalendarData() {
        var calendarData = []
        this.hoursBooked.map((x) => {
          calendarData.push({
            Client: x.clientName,
            'Branch Name': x.branchName,
            'Number of Bookings': x.numberOfBookings,
            'Number of Hours': x.bookedHours,
          })
        })
        return calendarData
      },

      async selectClient() {
        if (this.client == 'all') {
          await this.loadData()
        } else {
          await this.loadData(
            moment().format('YYYY-MM-DD'),
            moment()
              .add(1, 'y')
              .format('YYYY-MM-DD'),
            this.client
          )
        }
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.clientList =  utilities.getClients()
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
