<template>
  <div class="otp-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>One Time Password</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">OTP from Authenticator Application</div>
                <div class="box-col box-col-right">
                  <input type="password" class="password" :class="hasError && !validatePassword ? 'is-invalid' : ''" v-model="password" placeholder="Password" />
                  <div class="text-danger" v-if="hasError && !validatePassword">
                    Please enter OTP
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="clickSave" dark>Submit</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import auth from '@/assets/js/cognitoAuth'

  export default {
    name: 'OTPModal',
    props: [],
    data() {
      return {
        loadModal: false,
        user: {},
        hasError: false,
        password: '',
      }
    },

    computed: {
      validatePassword() {
        return this.password !== ''
      },
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
          this.password = ""
          setTimeout(() => {
            $(".otp-modal .password").focus()
          }, 500)
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      async clickSave() {
        this.hasError = false
        if (!this.validatePassword) {
          this.hasError = true
          return
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        try {
          auth.verifyOtp(this.user, this.password).then(async (user) => {
            console.log(user)
            //Save Authentication details after login
            var authentication = utilities.loadAuthentication(user.signInUserSession)
            this.$store.dispatch('user/setAuthentication', authentication)

            let userToSave = await utilities.getUserBySub(user.signInUserSession.accessToken.payload['sub'])
            if (userToSave) {
              // Zoho Integration
              // fetch('https://vtjfl2u95a.execute-api.ap-southeast-2.amazonaws.com/RHOReferenceData/zoho-login').then((response) => console.log(response))
              if(userToSave.clientId) {
                const client = await utilities.getClientById(userToSave.clientId)
                const allContacts = client.contacts.map(x => {
                  return {
                    ...x,
                    mobile: utilities.removeMobileCode(x.mobile)
                  }
                })
                const contact = allContacts.find(item => item.userId === userToSave.id)
                if(contact && contact.mobile && contact.mobile !== '') {
                  userToSave.mobile = btoa(contact.mobile)
                }
              }
              this.$store.dispatch('user/setUser', userToSave)
              var result = await this.$parent.setLastLoginDate(userToSave)
              if(result) this.$parent.redirectToHome()
            } else {
              console.log(userToSave)
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }).catch(e => {
            utilities.showErrorMessage(e.message)
            this.$store.dispatch('loading/setLoadComponent', false)
          })
        } catch (e) {
          this.hasError = true
          utilities.showErrorMessage(e.message)
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },
    },

    beforeMount() {},
    mounted() {},
  }
</script>
