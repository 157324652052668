/* eslint-disable */
<template>
  <div class="admin_bookings_awaiting_consent">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Booking Awaiting Consent</h1></div>
        <div class="header-sub-title"></div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu :key="bookingMenuIndex" :indexProps="bookingMenuIndex" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Booking ID" v-model="listParam.BookingID"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Claimant Name" v-model="listParam.ClaimantName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactName"/>
            </div>
          </div>
        </div>

        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 10%" class="long-text">Booking ID<img v-if="listParam.SortFlag === 10" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(9)" />
                      <img v-else-if="listParam.SortFlag === 9" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(10)"   /></th>
                    <th style="width: 15%" class="long-text">Booking Date & Time<img v-if="listParam.SortFlag === 16" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(15)" />
                      <img v-else-if="listParam.SortFlag === 15" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(16)"   /></th>
                    <th style="width: 15%" class="long-text">Client Name<img v-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(1)" />
                      <img v-else-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(2)"   /></th>
                    <th style="width: 15%" class="long-text">Client Contact Name<img v-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(3)" />
                      <img v-else-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(4)"   /></th>
                    <th style="width: 13%" class="long-text">Client Phone/Email</th>
                    <th style="width: 15%" class="long-text">Claimant Name<img v-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(5)" />
                      <img v-else-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(6)"   /></th>
                    <th style="width: 12%" class="long-text">Claimant Phone/Email</th>
                    <th style="width: 5%" class="long-text">
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="bookings.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(booking, index) in awaitingConsents" :key="booking.id" class="is-icon">
                    <td style="width: 10%" class="long-text">
                      <div class="long-text" @click="openBooking(booking.id)">
                        <span @click="openBooking(booking.id)">{{ booking.id }}</span>
                      </div>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <el-tooltip effect="dark" :content="formatDate(booking.bookingDateTime, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.000000')" placement="bottom">
                          <span @click="openBooking(booking.id)">{{formatDate(booking.bookingDateTime, "DD/MM/YYYY hh:mm A", 'YYYY-MM-DD HH:mm:ss.000000')}}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <el-tooltip effect="dark" :content="booking.clientName" placement="bottom">
                          <span @click="openBooking(booking.id)">{{ booking.clientName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <el-tooltip effect="dark" :content="booking.clientContactFullName" placement="bottom">
                          <span @click="openBooking(booking.id)">{{ booking.clientContactFullName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 13%" class="long-text">
                      <el-tooltip effect="dark" :content="booking.clientContactMobile + (booking.clientContactMobile != '' && booking.clientContactEmail != '' ? ' / ' : '') + booking.clientContactEmail" placement="bottom">
                        <span @click="openBooking(booking.id)">{{ booking.clientContactMobile + (booking.clientContactMobile != '' && booking.clientContactEmail != '' ? ' / ' : '') + booking.clientContactEmail }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <el-tooltip effect="dark" :content="booking.claimantFullName" placement="bottom">
                        <span @click="openBooking(booking.id)">{{ booking.claimantFullName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 12%" class="long-text">
                      <el-tooltip effect="dark" :content="booking.claimantMobile + (booking.claimantMobile != '' && booking.claimantEmail != '' ? ' / ' : '') + booking.claimantEmail" placement="bottom">
                          <span @click="openBooking(booking.id)">{{ booking.claimantMobile + (booking.claimantMobile != '' && booking.claimantEmail != '' ? ' / ' : '') + booking.claimantEmail }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 5%">
                      <img
                        class="ml-2 is-icon"
                        src="@/assets/images/can_ring_claimant_icon.svg"
                        style="padding-left: 6px;"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="getCanRingClaimantTitle(booking)"
                        :data-original-title="getCanRingClaimantTitle(booking)"
                        :class="booking.redHealthMayContactClaimant ? '' : 'disabled-element'"/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminBookingsAwaitingConsent",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),
      bookingMenuIndex: 3,
      awaitingConsents: [],
      allAwaitingConsents: [],
      totalCount: 0,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 15,
        BookingID: null,
        ClientName: '',
        ClaimantName: '',
        ContactName: ''
      },
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    sortParam(v) {
      this.listParam.SortFlag = v
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },
    isDate(date) {
      return moment(date).isValid()
    },

    openBooking(id) {
      let routeData = this.$router.resolve({ path: '/admin-manage-booking', query: { id: id } })
      window.open(routeData.href, '_blank')
    },

    getCanRingClaimantTitle(booking) {
      var title = booking.redHealthMayContactClaimant ? "Can ring claimant" : "Can not ring claimant"
      return title
    },

    formatDate(dateToFormat, newFormat, currentFormat = "") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {bookings, total} = await utilities.getBookingAwaitingConsentNew(this.listParam)
      this.awaitingConsents = _.cloneDeep(bookings)
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  async beforeMount() {
    await this.loadData()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
