/* eslint-disable */
<template>
  <div class="specialist-availability">
    <SpecialistNavigation indexProps="3" />
    <div class="top-panel long-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Availability</h1></div>
        <div class="header-sub-title">Your availability for independent medico legal assessments is vital to the provision of services to Red Health Online clients. We will email you regularly to remind you to provide future availability, and to advise you when bookings have been confirmed during your available timeslots, and any travel & accommodation details arranged on your behalf. Thank you for submitting your availability.</div>
      </div>
    </div>
    <div class="panel long-sub-title">
      <SpecialistAvailabilityMenu indexProps="0" />
      <div class="content">
        <div class="box">
          <div class="box-heading col-space-between">
            <div>Current Availability</div>
            <div class="icon-group right-element">
              <div class="d-flex align-items-center">
<!--                <v-btn v-if="dataSource && dataSource.length > 0" class="right-float-element" dark @click="handleBulkUpdate">Bulk update</v-btn>-->
                <img data-toggle="tooltip" data-placement="right" title="Export Availability to PDF" src="@/assets/images/file_icon.svg" @click="exportPdf" />
              </div>
            </div>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1600"
              :filename="getFileName()"
              :pdf-quality="1"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="landscape"
              pdf-content-width="297mm"
              ref="html2Pdf"
              @hasDownloaded="hasDownloaded">
              <section slot="pdf-content">
                <SpecialistAvailabilityPdf ref="specialistAvailabilityPdf" :isSpecialist="true" />
              </section>
            </vue-html2pdf>
          </div>
          <div class="box-body">
            <HorizontalYearCalendar
              ref="horizontalYearCalendar"
              :key="calendarKey"
              :calendarDateProps="yearSelected"
              :dataSourceProps="dataSource"
              :isFromSpecialist="true"
              messageProps="Please contact Red Health if you need to change any confirmed availability."
              :pathProps="'/specialist-bookings-day'"
            />
          </div>
        </div>
        <AddRoutineAvailability
          ref="addRoutineAvailability"
          :locations="locations"
          :key="routineAvailabilityKey"
          :clients="clients"
          :specialistIdProps="specialist.id"
          :specialistNameProps="specialist.fullName"
          :availabilitiesProps="mapAvailabilities"
          :isFromSpecialistProps="true" />
        <EditRoutineAvailabilityModal :isFromSpecialistProps="true" ref="editRoutineAvailabilityModal" :clients="clients" :locations="locations" availabilityProp="editAvailability" :dataSourceProps="dataSource"/>
      </div>
    </div>
    <Chat />
    <el-dialog
        title="Bulk Update"
        :visible.sync="showBulkUpdate"
        @close="cancelDialog"
        width="70%">
      <div class="box-row">
        <div class="box-col box-col-left">Select Date time</div>
        <div class="box-col box-col-right d-flex">
          <div class="w-50">
            <el-date-picker
                v-model="bulkEditOption.dateDuration"
                type="daterange"
                popper-class="date-range-picker-popper"
                range-separator="~"
                start-placeholder="Start Date"
                end-placeholder="End Date">
            </el-date-picker>
          </div>
          <div class="w-50 d-flex justify-content-between align-items-center">
            <vue-timepicker ref="timePicker" v-model="bulkEditOption.startTime"
                            format="hh:mm A"
                            input-class="form-control"
                            :minute-interval="5"
                            placeholder="HH:MM">

            </vue-timepicker>
            <v-btn @click="searchAvailability" v-if="bulkEditOption.dateDuration" dark>Search</v-btn>
          </div>
        </div>
      </div>
      <div v-if="filteredAvailabilities.length > 0" style="line-height: 1">
        <el-table @selection-change="handleSelectionChange" height="400" class="w-100" size="mini" :data="filteredAvailabilities" tooltip-effect="dark" :header-row-style="{background: '#fde9eb'}" :header-cell-style="{fontWeight: 100}">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              width="80"
              show-overflow-tooltip
              label="id"
              prop="AvailabilityID"
          >
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="Date"
              prop="date"
          >
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="Start Time"
              prop="StartTime"
          >
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="End Time"
              prop="EndTime"
          >
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              label="Location"
          >
            <template slot-scope="scope">
              <span>{{decodeURIComponent(scope.row.Location.LocationName)}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="Status"
              prop="BookingStatus"
          >
            <template slot-scope="scope">
              <div class="calender-indicator" :class="getBookingStatus(scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              label="Options"
              width="180"
          >
            <template slot-scope="scope">
              <img v-if="scope.row.Options.VideoOnly" class="is-icon mr-2" :src="require('@/assets/images/video_black_icon.svg')" data-toggle="tooltip" data-placement="right" title="Only Allow Video" />
              <img v-if="scope.row.Options.VideoLinkAvailable" class="is-icon mr-2" style="height: 30px" :src="require('@/assets/images/user_video_icon_black.svg')" data-toggle="tooltip" data-placement="right" title="Video Link available" />
              <img v-if="scope.row.Options.ByRequestOnly" class="is-icon mr-2" :src="require('@/assets/images/phone_black_icon.svg')" data-toggle="tooltip" data-placement="right" title="By Request only" />
              <img v-if="scope.row.Options.ShowWorkCover" data-toggle="tooltip" data-placement="right" title="Show WorkCover"  class="is-icon mr-2" style="height: 26px" :src="require('./../assets/images/work_cover.svg')"/>
              <img v-if="scope.row.Options.ShowSpecificClients" class="is-icon mr-2" style="height: 26px"
                   :src="require('./../assets/images/specific_users.svg')"/>
            </template>
          </el-table-column>
        </el-table>
        <div class="d-flex justify-content-end mt-4">
          <div class="d-flex align-items-center">
            <div class="calender-indicator calender-submited"></div>
            <div class="indicator-text">Unconfirmed</div>
          </div>
          <div  class="d-flex align-items-center">
            <div class="calender-indicator calender-approved"></div>
            <div class="indicator-text">Available</div>
          </div>
          <div  class="d-flex align-items-center">
            <div class="calender-indicator calender-fullbook"></div>
            <div class="indicator-text">Booked</div>
          </div>
          <div  class="d-flex align-items-center">
            <div class="calender-indicator calender-onhold"></div>
            <div class="indicator-text">Oh hold</div>
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left d-flex align-items-center">
          Location
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showLocation"
              active-color="#13ce66"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col box-col-right d-flex">
          <div class="w-100">
            <el-select v-show="bulkEditOption.showLocation" popper-class="search-specialty-popup" clearable filterable class="w-100"
                       placeholder="Please choose" :class="hasError && !validateLocation ? 'is-invalid' : ''"
                       v-model="bulkEditOption.LocationID">
              <el-option v-for="(location, index) in locations" :value="location.id" :key="index"
                         :label="location.fullLocation">{{ location.fullLocation }}
              </el-option>
            </el-select>
            <div v-if="hasError && !validateLocation" class="text-danger">Location is required.</div>
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left">
          Select Type of Booking/Travel Required
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showOptions"
              active-color="#13ce66"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col box-col-right d-flex">
          <div v-if="bulkEditOption.showOptions" class="w-50 px-5">
            <img class="is-icon" :src="bulkEditOption.Options.VideoOnly ? require('@/assets/images/video_black_icon.svg') : require('@/assets/images/video_icon.svg')"
                 @click="changeOnlyAllowVideo" data-toggle="tooltip" data-placement="right" title="Only Allow Video" />
            <img class="is-icon pl-3" style="height: 30px" :src="bulkEditOption.Options.VideoLinkAvailable ? require('@/assets/images/user_video_icon_black.svg') : require('@/assets/images/user_video_icon.svg')"
                 @click="changeVideoLinkAvailable" data-toggle="tooltip" data-placement="right" title="Video Link available" />
            <img class="is-icon pl-3" :src="bulkEditOption.Options.ByRequestOnly ? require('@/assets/images/phone_black_icon.svg') : require('@/assets/images/phone_icon.svg')"
                 @click="changeByRequestOnly" data-toggle="tooltip" data-placement="right" title="By Request only" />
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col w-25">
          Start Time
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showStartTime"
              active-color="#13ce66"
              @change="toggleStartTime"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col w-25">
          <vue-timepicker v-if="bulkEditOption.showStartTime" v-model="bulkEditOption.updateStartTime"
                          format="hh:mm A"
                          class="w-100"
                          input-class="form-control"
                          :allowed-minutes="allowedStep"
                          placeholder="HH:MM"></vue-timepicker>
          <div v-if="hasError && !validateStartTime" class="text-danger">{{ startTimeMessage }}</div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left">
          Appointment Duration
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showDuration"
              active-color="#13ce66"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col box-col-right">
          <el-input-number v-show="bulkEditOption.showDuration" class="number-input" v-model="bulkEditOption.Duration" :step="5" :min="5"
                           :max="1000"></el-input-number>
          <span v-show="bulkEditOption.showDuration" class="ms-2">mins</span>
        </div>
      </div>
      <div class="box-row" v-if="user.type >= 5">
        <div class="box-col w-25">
          Visibility Configuration
        </div>
        <div class="box-col w-70">
          <div>
            <el-switch
                class="ml-2"
                active-text="Change WorkCover"
                inactive-text="Keep WorkCover Default"
                v-model="bulkEditOption.showWorkCover"
                active-color="#13ce66"
                inactive-color="#E0E0E0">
            </el-switch>
            <el-tooltip v-show="bulkEditOption.showWorkCover"
                        :content="bulkEditOption.Options.ShowWorkCover ? 'Clicking this button will mean that WorkCover Queensland can not book this appointment' : 'Clicking this button will mean that WorkCover Queensland can book this appointment'">
              <img @click="changeShowWorkCover" class="is-icon ml-3" style="height: 26px"
                   :src="bulkEditOption.Options.ShowWorkCover ? require('./../assets/images/work_cover.svg') : require('./../assets/images/work_cover_gray.svg')"/>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
         <v-btn dark @click="bulkUpdate" >Update</v-btn>
        <!--         <v-btn class="ml-3" dark @click="bulkDelete" >Delete</v-btn>-->
         <el-button @click="cancelDialog" class="ml-3" type="text">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistAvailabilityMenu from '@/components/SpecialistAvailabilityMenu'
  import AddRoutineAvailability from '@/components/AddRoutineAvailability'
  import Header from '@/components/Header'
  import Chat from '@/components/Chat'
  import EditRoutineAvailabilityModal from "@/components/EditRoutineAvailabilityModal";
  import 'tippy.js/dist/tippy.css'
  import HorizontalYearCalendar from '@/components/HorizontalYearCalendar'
  import SpecialistAvailabilityPdf from '@/components/SpecialistAvailabilityPdf'

  export default {
    name: 'SpecialistAvailability',
    components: {
      SpecialistNavigation,
      SpecialistAvailabilityMenu,
      AddRoutineAvailability,
      Header,
      Chat,
      EditRoutineAvailabilityModal,
      HorizontalYearCalendar,
      SpecialistAvailabilityPdf,
    },
    data() {
      return {
        showBulkUpdate: false,
        filteredAvailabilities: [],
        selectedAvailabilities: [],
        bulkEditOption: {
          showWorkCover: false,
          showClients: false,
          showLocation: false,
          showOptions: false,
          showDuration: false,
          showStartTime: false,
          dateDuration: '',
          startTime: '',
          LocationID: null,
          updateStartTime: '',
          Duration: 5,
          ClientIDs: [],
          Options: {
            ShowWorkCover: 0,
            ShowSpecificClients: 0,
            VideoLinkAvailable: 0,
            VideoOnly: 0,
            ByRequestOnly: 0,
          }
        },
        successMessage: '',
        hasError: false,
        errorMessage: '',
        locations: [],
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        specialist: utilities.initSpecialist(),
        availability: utilities.initAvailability(),
        availabilities: [],
        mapAvailabilities: [],
        dataSource: [],
        yearSelected: moment().format('YYYY'),
        clients: [],
        routineAvailabilityKey: 0,
        calendarKey: 0,
      }
    },
    methods: {
      toggleStartTime(v) {
        if(v) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-warning">' +
                '            <b>Warning:</b> Make sure that selected availabilities are not in the same date' +
                '          </div>',
          });
        }
      },
      allowedStep(m) {
        return m % 5 === 0
      },
      async bulkUpdate() {
        this.hasError = false
        if (!this.validateLocation || !this.validateStartTime || !this.validateDuration) {
          this.hasError = true
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-error">' +
                '            <b>Error:</b> Please correct the errors shown above and try again.' +
                '          </div>',
          });
          return
        }
        if(this.selectedAvailabilities.length === 0) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-error">' +
                '            <b>Error:</b> Please select at least one availability' +
                '          </div>',
          });
          return
        }
        if(this.selectedAvailabilities.filter(item => item.BookingStatus !== 'A').length > 0) {
          this.$confirm({
            content: 'Some of the Availabilities you selected have been Booked. We will only batch update the Availabilities that have not been Booked.',
            onConfirm: async () => {
              await this.executeBulkUpdate()
            }
          })
          return
        }
        await this.executeBulkUpdate()
      },
      async executeBulkUpdate() {
        let param = {
          AvailabilityIDs: this.selectedAvailabilities.map(item => item.AvailabilityID),
          Data: {
            UsernameLog: this.user.name,
            UserIDLog: this.user.id
          }
        }
        if (this.bulkEditOption.showOptions) param.Data.Options = {
          ByRequestOnly: this.bulkEditOption.Options.ByRequestOnly, // Integer 0-False, 1-True,
          VideoLinkAvailable: this.bulkEditOption.Options.VideoLinkAvailable, // Integer 0-False, 1-True,
          VideoOnly: this.bulkEditOption.Options.VideoOnly, // Integer 0-False, 1-True,
        }
        if (this.bulkEditOption.showWorkCover) {
          if (param.Data.Options) {
            param.Data.Options.ShowWorkCover = this.bulkEditOption.Options.ShowWorkCover
          } else {
            param.Data.Options = {
              ShowWorkCover: this.bulkEditOption.Options.ShowWorkCover
            }
          }
        }
        if (this.bulkEditOption.showClients) {
          param.Data.ClientIDs = this.bulkEditOption.ClientIDs
          if (param.Data.Options) {
            param.Data.Options.ShowSpecificClients = this.bulkEditOption.Options.ShowSpecificClients
          } else {
            param.Data.Options = {
              ShowSpecificClients: this.bulkEditOption.Options.ShowSpecificClients
            }
          }
        }
        if (this.bulkEditOption.showDuration) param.Data.Duration = this.bulkEditOption.Duration
        if (this.bulkEditOption.showLocation) param.Data.LocationID = this.bulkEditOption.LocationID
        if (this.bulkEditOption.showStartTime) param.Data.StartTime = utilities.formatDate(this.bulkEditOption.updateStartTime, "HH:mm:ss", "hh:mm A")
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkUpdateAvailability(param, () => {
          this.cancelDialog()
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-success">' +
                '            <b>Success:</b> Availabilities updated' +
                '          </div>',
          });
          this.reload()
        })
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      cancelDialog() {
        this.filteredAvailabilities = []
        this.selectedAvailabilities = []
        this.bulkEditOption = {
          dateDuration: '',
          startTime: '',
          LocationID: null,
          updateStartTime: '',
          Duration: 0,
          ClientIDs: [],
          Options: {
            ShowWorkCover: 0,
            ShowSpecificClients: 0,
            VideoLinkAvailable: 0,
            VideoOnly: 0,
            ByRequestOnly: 0,
          }
        }
        this.$refs.timePicker.clearTime()
        this.showBulkUpdate = false
      },
      changeByRequestOnly() {
        if(this.bulkEditOption.Options.ByRequestOnly) {
          this.bulkEditOption.Options.ByRequestOnly = 0
        } else {
          this.bulkEditOption.Options.ByRequestOnly = 1
          this.bulkEditOption.Options.VideoLinkAvailable = 0
          this.bulkEditOption.Options.VideoOnly = 0
        }
      },
      changeVideoLinkAvailable() {
        if(this.bulkEditOption.Options.VideoLinkAvailable) {
          this.bulkEditOption.Options.VideoLinkAvailable = 0
        } else {
          this.bulkEditOption.Options.VideoLinkAvailable = 1
          this.bulkEditOption.Options.VideoOnly = 0
          this.bulkEditOption.Options.ByRequestOnly = 0
        }
      },
      changeOnlyAllowVideo() {
        if(this.bulkEditOption.Options.VideoOnly === 0) {
          this.bulkEditOption.Options.VideoOnly = 1
          this.bulkEditOption.Options.VideoLinkAvailable = 0
          this.bulkEditOption.Options.ByRequestOnly = 0
        } else {
          this.bulkEditOption.Options.VideoOnly = 0
        }
      },
      changeShowWorkCover() {
        if (this.bulkEditOption.Options.ShowWorkCover) {
          this.bulkEditOption.Options.ShowWorkCover = 0
        } else {
          this.bulkEditOption.Options.ShowWorkCover = 1
        }
      },
      changeShowSpecificClients() {
        if (this.bulkEditOption.Options.ShowSpecificClients) {
          this.bulkEditOption.Options.ShowSpecificClients = 0
          this.bulkEditOption.ClientIDs = []
        } else {
          this.bulkEditOption.Options.ShowSpecificClients = 1
        }
      },
      getBookingStatus(row) {
        if(row.BookingStatus === 'A' && row.AvailabilityStatus === '2') {
          return 'calender-approved'
        } else if (row.BookingStatus === 'B') {
          return 'calender-fullbook'
        } else if (row.AvailabilityStatus === '1') {
          return 'calender-submited'
        }
        return 'calender-onhold'
      },
      handleSelectionChange(val) {
        this.selectedAvailabilities = val
      },
      searchAvailability() {
        const start = window.moment(this.bulkEditOption.dateDuration[0])
        const end = window.moment(this.bulkEditOption.dateDuration[1])
        let idsArr = []
        this.dataSource.forEach(item => {
          if(window.moment(item.date).add(1, 'hour').isBetween(window.moment(start), window.moment(end).endOf('day'))) {
            const filter = this.bulkEditOption.startTime ? item.Availabilities.filter(a => window.moment(a.StartTime, 'HH:mm:ss').format('HH:mm') === window.moment(this.bulkEditOption.startTime, 'hh:mm A').format('HH:mm')) : item.Availabilities
            const newArr = filter.map(b => {
              return {...b, date: window.moment(item.date).format('DD/MM/YYYY')}
            })
            const idArr = newArr
            idsArr = idsArr.concat(idArr)
          }
        })
        this.filteredAvailabilities = idsArr.sort((a, b) => {
          return window.moment(a.date, 'DD/MM/YYYY').isAfter(window.moment(b.date, 'DD/MM/YYYY')) ? -1 : 1
        })
        if(idsArr.length === 0) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-warning">' +
                '            <b>Error:</b> No data found' +
                '          </div>',
          });
        }
      },
      handleBulkUpdate() {
        this.showBulkUpdate = true
      },
      showEditAvailabilityModal(data) {
        $('.tippy-box').hide()
        this.$store.dispatch('loading/setLoadComponent', true)
        this.$refs.editRoutineAvailabilityModal.loadData()
        this.$refs.editRoutineAvailabilityModal.setAvailability(data)
        this.$refs.editRoutineAvailabilityModal.handleModal()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      getFileName() {
        return 'Availabilities (' + moment().format('DD.MM.YYYY') + ')'
      },
      async reload() {
        this.mapAvailabilities = await utilities.getSpecialistAvailabilities(this.user.specialistId)
        this.mapAvailabilities.forEach(item => {
          item.Availabilities.forEach(a => {
            item.specialist = this.specialist
          })
        })
        this.dataSource = this.mapAvailabilities
        this.routineAvailabilityKey += 1
        this.calendarKey += 1
      },
      updateCalendar(availabilities) {
        this.mapAvailabilities = availabilities
        this.dataSource = utilities.getAvailabilityRoutinesAsDataSource(availabilities)
        this.calendarKey += 1
      },
      removeSingleDraftAvailability(id, parentId) {
        this.$refs.addRoutineAvailability.removeSingleDraftAvailability(id, parentId)
      },
      changeRequiresTravel() {
        this.availability.requiresTravel = !this.availability.requiresTravel
      },
      callAvailabilityLog()  {
        var params = {
          UserID: this.user.id,
        	Username: this.user.name,
        	LogDate:  moment().format('YYYY-MM-DD HH:mm:ss'),
        	LogDetails: "Availabilities downloaded to PDF",
        	SpecialistID: this.user.specialistId,
        }
        utilities.postAvailabilityLog(params)
      },
      hasDownloaded() {
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async exportPdf() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.$refs.specialistAvailabilityPdf.specialist.id = this.user.specialistId
        await this.$refs.specialistAvailabilityPdf.loadData(true)
        if (this.$refs.specialistAvailabilityPdf.availabilities.length > 0) {
          setTimeout(() => {
            this.$refs.html2Pdf.generatePdf()
            this.$store.dispatch('loading/setLoadComponent', false)
          }, 1000)
          this.callAvailabilityLog()
        } else {
          this.errorMessage = 'No future availabilities added.'
          utilities.showAlert('.alert-danger')
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },
      async loadAvailability() {
        const apiArr = [utilities.getLocations(), utilities.getSpecialistById(this.user.specialistId), utilities.getSpecialistAvailabilities(this.user.specialistId)]
        const r = await Promise.all(apiArr)
        this.locations = r[0]
        this.specialist = r[1]
        this.mapAvailabilities = r[2]
        if(!this.user.specialistId) {
          this.clients = await utilities.getClients()
        }
        this.mapAvailabilities.forEach(item => {
          item.Availabilities.forEach(a => {
            item.specialist = this.specialist
          })
        })
        this.dataSource = this.mapAvailabilities
        this.routineAvailabilityKey += 1
        this.calendarKey += 1
      },
    },
    async reload() {
      this.mapAvailabilities = await utilities.getSpecialistAvailabilities(this.user.specialistId)
    },
    beforeRouteLeave(to, from, next) {
      if (this.availabilities.length != 0)
        if (!window.confirm('Your changes will not be saved if you move away from this page. Are you sure you want to continue?')) {
          return
        }
      next()
    },

    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadAvailability()
      utilities.initTooltip()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      window.scrollTo(0, 0)
    },
  }
</script>
