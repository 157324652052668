<template>
  <div class="admin-change-ownership-cases-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Change Ownership Of Cases from</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form style="font-size: 14px;">
              <div class="form-group">
                <select class="textbox" v-model="selectedUserId">
                  <option hidden value="">Please choose ...</option>
                  <option v-for="(contact, index) in clientContacts" :value="contact.clientContactId">{{ contact.clientContactId + " - " + contact.fullName + " - " + contact.emailAddress }}</option>
                </select>
              </div>
              <div class="modal-title">
                <h1>Change Ownership Of Cases to</h1>
              </div>
              <div class="form-group">
                <select class="textbox" v-model="selectedUserId1">
                  <option hidden value="">Please choose ...</option>
                  <option v-for="(contact, index) in clientContacts.filter(item => (item.active && item.canLogin))" :value="contact.clientContactId">{{ contact.clientContactId + " - " + contact.fullName + " - " + contact.emailAddress }}</option>
                </select>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" id="savebutton" dark>Submit</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminChangeOwnershipCasesModal',
    data() {
      return {
        loadModal: false,
        clientContacts: [],
        selectedUserId: '',
        selectedUserId1: '',
        fromId: '',
        contactName: '',
        user: window._.cloneDeep(this.$store.getters["user/getUser"]),
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      setCaseOwners(contacts) {
        this.clientContacts = contacts
      },

      async callSave() {
        if (this.selectedUserId) {
          var answer = await utilities.showWarningDialog("Are you sure you want to Change Ownership Of Cases?")
          if(!answer) return false

          this.$store.dispatch('loading/setLoadComponent', true)
          var params = {
            ClientID: this.$parent.client.id,
            OriginalClientContactID: this.selectedUserId,
            CurrentClientContactID: this.selectedUserId1,
            UserID: this.$parent.user.id,
            Username: this.$parent.user.name,
          }
          var callback = (response) => {
            if (response.status == 200) {
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: '<div class="alert-success">' +
                    '            <b>Success:</b> You have changed the ownership!' +
                    '          </div>',
              });
            }
            this.handleModal()
          }
          await utilities.putChangingCaseOwnership(params, this.user.type === 2, callback)
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },
    },
    beforeMount() {},
    mounted() {},
  }
</script>
