<template>
  <div class="admin-update-hero-image-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Referral Image</h1>
              <div class="pt-5">
                Dimensions A4 landscape (297 mm X 210 mm) OR (1123 px X 794 px) of ".jpg" format only
              </div>
            </div>

            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="pl-2">
              {{fileName}}
            </div>
            <form>
              <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input
                  type="file"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  accept=".jpg" />
                <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                  <div>Drop image in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload image.</div>
                </label>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="upload">Upload</v-btn>
                  </div>
                </div>
              </div>
            </form>

            <div class="alert alert-success alert-success-banner hidden-element">
              <a class="close" title="close">×</a>
              <b>Success:</b> Your new image has been uploaded. Please Download Referral Network to see the new image.
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AdminUpdateReferralPdfModal",
  data() {
    return {
      loadModal: false,
      filelist: [],
      fileName: '',
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    initFileUpload() {
      this.filelist = []
      this.fileName = ''
    },

    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },

    dragover(event) {
      event.preventDefault()
      event.currentTarget.classList.add('dark-border-hover')
    },

    dragleave(event) {
      event.currentTarget.classList.remove('dark-border-hover')
    },

    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()

      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },

    async onChange() {
      this.filelist = [...this.$refs.file.files]
      this.fileName = this.filelist[0].name
    },

    async upload() {
      if(this.filelist.length > 0) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var result = false

        await Promise.all(this.filelist.map(async (x) => {
          var params = {
            name: 'ReferralNetwork.jpg',
            fileType: x.type,
            uploadtype: 'rnpdf',
          }
          result = await utilities.postUploadImageURL(params)
          if(result != undefined && result.url != undefined && result.url != '' && result.fields != undefined) {
            var postData = new FormData()
            for(var key in result.fields) {
                postData.append(key, result.fields[key])
            }
            postData.append('file', x)
            await utilities.postImage(result.url, postData)
            result = await utilities.getSpecialistsForPdf()
          }
        }))

        if(result) {
          utilities.showAlert(".alert-success-banner")
        }
        this.$store.dispatch('loading/setLoadComponent', false)
        setTimeout(() => {
          this.handleModal()
        }, 2000)
      }
    },
  },
  beforeMount() {},
  mounted() {},
}
</script>
