/* eslint-disable */
<template>
  <div class="specialist-travel-detail">
    <SpecialistNavigation indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Travel &amp; Accommodation Details</h1></div>
        <div class="header-sub-title">Your itinerary for your travel is detailed below.</div>
      </div>
    </div>
    <div class="panel">
      <SpecialistTravelMenu indexProps="0" />
      <div class="content">
        <div class="box remove-min-height">
          <div class="box-heading d-flex align-items-center">
            <span>Travel Dates: {{ formatDate(itinerary.travelStartDate) + (itinerary.travelEndDate != '' ? " - " : "") + formatDate(itinerary.travelEndDate) }} <span class="invisible-element">{{ itinerary.specialistTravelItineraryId }}</span></span>
            <div class="right-element col col-center-content d-flex justify-content-end">
              <span class="rounded-white-bg" >
                <img :disable="itinerary.filePath != '' ? true : false"
                :class="itinerary.filePath == '' ? 'disabled-element' : 'is-icon'"
                data-toggle="tooltip" data-placement="right" title="Download Itinerary"
                src="@/assets/images/file_icon.svg" alt="" @click="getItineraryFile(itinerary.filePath, itinerary.fileName)" />
              </span>
            </div>
          </div>
        </div>
        <div class="timeline mt-0 pt-0">
          <div class="timeline-line"></div>
          <ul class="timeline">
            <li v-for="(travel, index) in travelItems" :item="travel" :key="travel.id" class="timeline-item" :class="index == Object.keys(travelItems).length - 1 ? 'mb-0' : ''">
              <div class="timeline-badge">
                <img src="@/assets/images/travel_black_icon.svg" v-if="travel.airlineId !== ''" />
                <img src="@/assets/images/carhire_icon.svg" v-if="travel.carhireId !== ''" />
                <img src="@/assets/images/accommodation_icon.svg" v-if="travel.accommodationId !== ''" />
              </div>
              <div class="timeline-panel" v-if="travel.airlineId !== ''">
                <div class="timeline-body mt-0">
                  {{ travel.airlineName }} Flight {{ travel.flightNumber }} Seat {{ travel.seatNumber }} <br />
                  Departing {{ travel.departingAirportName }} <span v-if="travel.travelStartDate != ''"> at {{ formatTime(travel.travelStartDate, travel.travelStartTime) }} {{ formatDate(travel.travelStartDate) }}</span> <br />
                  Arriving {{ travel.arrivingAirportName }} <br />
                  Booking Reference: {{ travel.bookingReference }} <br />
                  <!-- Cost: ${{ travel.cost.toFixed(2) }} <br /> -->
                  Contact: {{ travel.airlineContact }}
                </div>
              </div>
              <div class="timeline-panel" v-if="travel.carhireId !== ''">
                <div class="timeline-body mt-0">
                  {{ travel.carHireName }} <br />
                  Collect from {{ formatTime(travel.travelStartDate, travel.travelStartTime) }} {{ formatDate(travel.travelStartDate) }} <br />
                  Booking Reference: {{ travel.bookingReference }} <br />
                  <!-- Cost: ${{ travel.cost.toFixed(2) }} <br /> -->
                  Contact: {{ travel.carHirePhoneNumber }}
                </div>
              </div>
              <div class="timeline-panel" v-if="travel.accommodationId !== ''">
                <div class="timeline-body mt-0">
                  {{ travel.fullLocation }} <br />
                  Check-in: {{ formatTime(travel.travelStartDate, travel.travelStartTime) }} {{ formatDate(travel.travelStartDate) }} <br />
                  Check-out: {{ formatTime(travel.travelEndDate, travel.travelEndTime) }} {{ formatDate(travel.travelEndDate) }} <br />
                  Booking Reference: {{ travel.bookingReference }} <br />
                  <!-- Cost: ${{ travel.cost.toFixed(2) }} <br /> -->
                  Contact: {{ travel.accommodationPhoneNumber }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="box">
          <div class="box-heading">
            <span>Travel Receipts</span>
          </div>
          <div class="box-body my-3">
            <div>
              <div class="content-scroll-y file-list" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                  <tbody :key="fileKey">
                    <tr v-for="(file, index) in travelReceipts" class="py-0">
                      <td style="width:100%" class="long-text py-0">
                        <a class="is-icon" :href="file.fileUrl" target="_blank">{{ file.invoiceFileName }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif" />
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
              </label>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> Files uploaded successfully.
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> There are the errors. Please try again.
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistTravelMenu from '@/components/SpecialistTravelMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'

  export default {
    name: 'SpecialistTravelDetail',
    components: {
      SpecialistNavigation,
      SpecialistTravelMenu,
      Header,
      Chat,
    },
    data() {
      return {
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        availabilityId: '',
        itinerary: utilities.initTravelItinerary(),
        travelItems: [],

        filelist: [],
        travelReceipts: [],
        fileKey:0,
        itineraryId: ''
      }
    },
    methods: {
      async onChange() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {}
        this.filelist = [...this.$refs.file.files]
        if(this.filelist.length > 0) {
          await Promise.all(this.filelist.map(async (x) => {
            var fileName = moment().unix() + '_' + x.name.replace(/\s/g, '_')
            var fileParams = {
              name: fileName,
              fileType: x.type,
              uploadtype: 'tr',
              id: this.itineraryId,
            }

            var result = await utilities.postUploadImageURL(fileParams)
            var postData = new FormData();

            for(var key in result.fields){
                postData.append(key, result.fields[key]);
            }
            postData.append('file', x)
            await utilities.postImage(result.url, postData)

            params = {
              SpecialistTravelItineraryID: this.itinerary.specialistTravelItineraryId,
              InvoiceFileName: fileName,
              DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
              UploadedBy: this.user.id,
              MimeType: x.type,
              AvailabilityID: this.availabilityId,
              SpecialistID: this.user.specialistId,
              UserID: this.user.id,
              Username: this.user.name,
            }
            var callback = (response) => {}
            await utilities.postTravelReceipt(params, callback)


            /*if(this.itinerary.specialistTravelItineraryId != '') {
              var fileName = moment().unix() + '-' + x.name.replace(/\s/g, '-')
              await new Promise((resolve, reject) => {
                var reader = new FileReader()
                reader.onloadend = async () => {
                  // params.FileObject.push({
                  //   SpecialistTravelItineraryID: this.itinerary.specialistTravelItineraryId,
                  //   InvoiceFileName: fileName,
                  //   InvoiceFileBinary: reader.result,
                  //   DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                  //   UploadedBy: this.user.id,
                  //   MimeType: x.type,
                  //   AvailabilityID: this.availabilityId,
                  //   SpecialistID: this.user.specialistId,
                  // })
                  params = {
                    SpecialistTravelItineraryID: this.itinerary.specialistTravelItineraryId,
                    InvoiceFileName: fileName,
                    InvoiceFileBinary: reader.result,
                    DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                    UploadedBy: this.user.id,
                    MimeType: x.type,
                    AvailabilityID: this.availabilityId,
                    SpecialistID: this.user.specialistId,
                    UserID: this.user.id,
                    Username: this.user.name,
                  }
                  var callback = (response) => {}
                  await utilities.postTravelReceipt(params, callback)
                  resolve(true)
                }
                reader.onerror = (error) => {
                  reject(error)
                }
                reader.readAsBinaryString(x)
              })
            }*/
          }))
          await this.fetchReceipts()
          this.fileKey += 1
          utilities.showAlert(".alert-success")
          setTimeout(() => {
            $(".file-list").animate({ scrollTop: $(".file-list")[0].scrollHeight }, 'slow')
          }, 200)
          await this.logThis(false, true)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async getItineraryFile(filePath, fileName) {
        await utilities.getFile(filePath, fileName)
        await this.logThis(true, false)
      },
      getFile(filePath, fileName) {
        utilities.getFile(filePath, fileName)
      },
      remove(i) {
        this.filelist.splice(i, 1)
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //   event.currentTarget.classList.remove('bg-gray-100')
        //   event.currentTarget.classList.add('bg-green-300')
        // }
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        // event.currentTarget.classList.add('bg-gray-100')
        // event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      formatTime(date, time) {
        return time != '' ? moment(date + ' ' + time, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('hh:mm A') : ''
      },
      formatDate(date) {
        return date != '' ? moment(date).format('DD/MM/YYYY') : ''
      },
      async logThis(viewItinerary = false, addReceipt = false){
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistID: this.user.specialistId,
          LogDetails: this.user.name + " viewed travel itinerary " + this.itinerary.specialistTravelItineraryId,
          LogDate: moment().format("YYYY-MM-DD HH:mm:ss")
        }
        if(viewItinerary == true) {
          params.LogDetails = this.user.name + " downloaded travel itinerary " + this.itinerary.specialistTravelItineraryId
        }
        if(addReceipt == true) {
          params.LogDetails = this.user.name + " added a receipt to travel itinerary " + this.itinerary.specialistTravelItineraryId
        }
        await utilities.postRequestChangeLog(params)
      },

      async fetchReceipts(){
        if(this.itinerary.specialistTravelItineraryId != '') {
          var reqeceipts = await utilities.getSpecialistTravelReceipts(this.itinerary.specialistTravelItineraryId)
          if (reqeceipts.length > 0) {
            await Promise.all(reqeceipts.map(async(x) =>{
              if (x.invoiceFileName != '') {
                var params = {
                  uploadtype: "tr",
                  fn: x.invoiceFileName,
                  id: x.specialistTravelItineraryId
                }
                x.fileUrl = await utilities.getFileUrlNew(params)
              }
            }))
            this.travelReceipts = reqeceipts
            this.fileKey +=1
          }
        }

      },
      async loadTravel() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.itineraryId = this.$route.query.id != undefined ? this.$route.query.id : ''
        this.availabilityId = this.$route.query.availabilityId != undefined ? this.$route.query.availabilityId : ''
        this.travelItems = await utilities.getSpecialistTravelItineraryByAvailabilityId(this.availabilityId)
        if(this.travelItems.length > 0) {
          this.itinerary = await utilities.getTravelItinerary(this.availabilityId)

          // Get oldest travel start date
          var travelStartDates = this.travelItems.filter(x => x.travelStartDate != '').sort((a, b) => {
            return moment(a.travelStartDate).isAfter(moment(b.travelStartDate)) ? 1 : -1
          })
          if(travelStartDates[0] != undefined) this.itinerary.travelStartDate = travelStartDates[0].travelStartDate
          else this.itinerary.travelStartDate = ''

          // Get latest travel end date
          var travelEndDates = this.travelItems.filter(x => x.travelEndDate != '').sort((a, b) => {
            return moment(a.travelEndDate).isBefore(moment(b.travelEndDate)) ? 1 : -1
          })
          if(travelEndDates[0] != undefined) this.itinerary.travelEndDate = travelEndDates[0].travelEndDate
          else this.itinerary.travelEndDate = ''
          await this.fetchReceipts()
        }

        //Sort travel items
        for (var i = 0; i < this.travelItems.length; i++) {
          var item = this.travelItems[i]
          item.travelStartDateTime = moment(item.travelStartDate + ' ' + item.travelStartTime, "YYYY-MM-DD HH:mm:ss.SSSSSS").format('YYYY-MM-DD HH:mm:ss')
        }

        this.travelItems.sort((a,b) => {
          return moment(a.travelStartDateTime, "YYYY-MM-DD HH:mm:ss").isAfter(moment(b.travelStartDateTime, "YYYY-MM-DD HH:mm:ss")) ? 1:-1
        })

        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadTravel()
      utilities.initTooltip()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
