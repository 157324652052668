/* eslint-disable */
<template>
  <div class="specialist-availability-pdf">
    <div class="pdf-panel">
      <div class="pdf-content p-2">
        <div class="pdf-header">
          <div class="row">
            <div class="col-4">
              <strong>{{ specialist.fullName }}</strong> <br/>
              {{ specialist.locations[0].name }} <br/>
              {{ specialist.locations[0].address1 }} <br v-if="specialist.locations[0].address1 !== ''"/>
              {{ specialist.locations[0].address2 }} <br v-if="specialist.locations[0].address2 !== ''"/>
              {{ specialist.locations[0].cityName }}
              {{ specialist.locations[0].state }}
              {{ specialist.locations[0].postcode }}
            </div>
            <div class="col-4">
              <span class="increased-line-height"><strong>Red Health: </strong>Dictation@redhealth.com.au<br/></span>
              PO BOX 10362 Adelaide Street,<br/>
              Brisbane QLD 4000<br/>
              <span class="increased-line-height"><strong>Phone:</strong> 07 3221 5154<br/></span>
            </div>
            <div class="col-4">
              <img class="img-fluid" src="@/assets/images/red_health_logo.svg"/>
            </div>
          </div>
          <div v-for="(availability, index) in availabilities" :key="index" class="avoid-breaking-page">
            <div class="d-flex align-items-center" style="margin-top: 20px">
              <span class="text-bold" style="width: 30%">Date: {{ formatDate(availability.date) }}</span>
              <span class="text-bold">Location:   {{decodeURIComponent(availability.Availabilities[0].Location.LocationName)}}</span>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th width="16%">Time</th>
                <th width="16%">Allow Video Link</th>
                <th width="13%">Video Only</th>
                <th width="13%">Request Only</th>
                <th width="6%">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in availability.Availabilities" :key="item.AvailabilityID">
                <td width="16%">{{ item.StartTime }} ~ {{ item.EndTime }}</td>
                <td width="16%">
                  <input type="checkbox" :checked="item.Options.VideoLinkAvailable ? true : false"/>
                </td>
                <td width="13%">
                  <input type="checkbox" :checked="item.Options.VideoOnly ? true : false"/>
                </td>
                <td width="13%">
                  <input type="checkbox" :checked="item.Options.ByRequestOnly ? true : false"/>
                </td>
                <td width="6%">{{ getBookingStatus(item) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: 'SpecialistAvailabilityPdf',
  components: {},
  data() {
    return {
      availabilities: [],
      specialist: utilities.initSpecialist(),
      isSpecialist: false,
      fetchedEverything: false,
      completedLoading: false,
      dataKey: 0,
    }
  },
  computed: {},
  methods: {
    getBookingStatus(item) {
      if (item.AvailabilityStatus === '2') {
        if (item.BookingStatus === 'A') {
          return 'Available'
        } else if (item.BookingStatus === 'B') {
          return 'Booked'
        } else {
          return 'On hold'
        }
      } else {
        return 'Unconfirmed'
      }
    },
    formatDate(dateToFormat) {
      return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
    },
    formatTime(timeToFormat) {
      return window.moment(timeToFormat).format('hh:mm A')
    },
    getFillOrder(value) {
      return utilities.getFillOrder(value)
    },
    getAvailabilityStatus(id) {
      return utilities.getAvailabilityStatus(id)
    },
    async loadData(isSpec) {
      if(isSpec) this.isSpecialist = true
      if (this.specialist.id != '') {
        this.specialist = await utilities.getSpecialistById(this.specialist.id)
        const startDate = window.moment()
            .startOf('day')
            .format('YYYY-MM-DD')
        const endDate = window.moment().add(1, 'year').format('YYYY-MM-DD')
        let result = await utilities.getSpecialistAvailabilities(this.specialist.id, null, {
          StartDate: startDate,
          EndDate: endDate
        })
        let arr = result.sort((a, b) => {
          return Date.parse(a.date) - Date.parse(b.date)
        })
        let emptyArr = []
        let count = 0
        for(let i = 0; i < arr.length; i++) {
          count = count + arr[i].Availabilities.length
          emptyArr.push(arr[i])
          if(count >= 200) {
            break
          }
        }
        this.availabilities = emptyArr
        console.log(this.availabilities)
        this.dataKey += 1
      }
    },
  },
  beforeMount() {
  },
  mounted() {
  },
}
</script>
