/* eslint-disable */
<template>
  <div class="specialist-profile">
    <SpecialistNavigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Profile</h1></div>
        <div class="header-sub-title">Your Red Health profile will assist clients select and request you for appropriate independent medical assessments and other services by viewing your skills and qualifications and travelling locations. If you require any changes to your profile, please provide the details in the form below and request the change through Red Health.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <!-- <SpecialistMenu indexProps="0" /> -->
      <div class="content">
        <div class="top-detail">
          <div class="profile">
            <div class="box-col">
              <div class="rounded-image profile-image">
                <div v-if="specialist.profileThumbnail != ''" class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                <div v-else class="profile-image-content notice-background"><s class="text-initials">{{ getInitials(specialist) }}</s></div>
              </div>
            </div>
            <div class="box-col profile-info">
              <div class="name">
                {{ specialist.fullName }}
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-heading">
            <span>Current Profile</span>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Speciality</div>
              <div class="box-col box-col-right">
                <vue-tags-input
                  :disabled="true"
                  v-model="qualificationTag"
                  :tags="qualificationTags"
                  @focus="focusedEducation = true"
                  @blur="focusedEducation = false"
                  placeholder=""
                  :allow-edit-tags="false"
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Web Profile</div>
              <div class="box-col box-col-right">
                <a :disabled="true" :href="specialist.profileURL" target="_blank">{{ specialist.profileURL }}</a>
              </div>
            </div>
            <div class="box-row" v-for="(education, index) in specialist.educations" :key="index">
              <div class="box-col box-col-left">{{ index == 0 ? 'Qualifications' : '' }}</div>
              <div class="box-col box-col-right">
                <input type="text" :disabled="true" placeholder="" class="w-50" :value="education.education" />
                <input type="text" :disabled="true" placeholder="" class="w-50" :value="education.yearObtained" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Practice Location</div>
              <div class="box-col box-col-right"><input :disabled="true" type="text" placeholder="" v-model="specialist.locations[0].shortLocation" style="color: #333333;" /></div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Travels to</div>
              <div class="box-col box-col-right">
                <div style="line-height: 1; padding: 5px 0px; padding-left: 5px;" v-for="(travel, index) in specialist.travels">
                  {{ travel.locationName
                  }}<span data-toggle="tooltip" data-placement="right" :title="!travel.requiresTravel ? 'Travel & Accommodation Not Required' : 'Travel & Accommodation Required'"
                    ><img class="img-small" style="margin-left: 10px;" :style="!travel.requiresTravel ? 'opacity: 0.5' : ''" src="@/assets/images/travel_black_circle_icon.svg"
                  /></span>
                </div>
                <!-- <div style="line-height: 1; padding: 5px 0px;">
                  Box Hill Practice, Melbourne<img class="img-small" style="opacity: .5; margin-left: 10px;" src="@/assets/images/travel_black_circle_icon.svg" data-toggle="tooltip" data-placement="right" title="Travel & Accommodation Required"/>
                </div> -->
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left col-center-content ">Expert in</div>
              <div class="box-col box-col-right col-center-content">
                <vue-tags-input :disabled="true" v-model="expertTag" :tags="expertTags" :allow-edit-tags="false" placeholder="" @tags-changed="(newTags1) => (expertTags = newTags1)" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left col-center-content ">Special Interests</div>
              <div class="box-col box-col-right col-center-content">
                <vue-tags-input
                  :disabled="true"
                  v-model="specialInterestTag"
                  :tags="specialInterestTags"
                  @focus="focusedEducation = true"
                  @blur="focusedEducation = false"
                  placeholder=""
                  :allow-edit-tags="false"
                  @tags-changed="(newTags1) => (specialInterestTags = newTags1)"
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Accreditions / Certifications</div>
              <div class="box-col box-col-right col-center-content">
                <vue-tags-input
                  :disabled="true"
                  v-model="educationTag"
                  :tags="educationTags"
                  @focus="focusedEducation = true"
                  @blur="focusedEducation = false"
                  placeholder=""
                  :allow-edit-tags="false"
                  @tags-changed="(newTags1) => (expertTags = newTags1)"
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Profile</div>
              <div class="box-col box-col-right profile-body" style="line-height: 1.5;padding: 20px 0px;padding-left: 20px;" v-html="specialist.specialistProfile"></div>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="box-heading">
            <span>Request a Change</span>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Required Profile Details</div>
              <div class="box-col box-col-right"><textarea placeholder="Please describe the change you would like made to your profile." rows="12" v-model="changeRequest.body" /></div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left row-newline">
                Upload Replacement Image
                <span>(.jpg /.png max 10Mb size)</span>
              </div>
              <div class="box-col box-col-right">
                <div class="box-col-photo">
                  <div class="rounded-image profile-image request-change" style="margin-left: 0px;">
                    <div class="profile-image-content" :class="specialist.profileThumbnail != '' ? '' : 'notice-background'">
                      <img :src="specialist.profileThumbnail" />
                    </div>
                    <div class="edit-icon" @click="chooseProfileImage"></div>
                    <input type="file" name="profile-image" accept="image/png, image/jpeg" @change="changeProfileImage" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-row item-right">
              <div><v-btn class="submit-request" @click="tapRequestChange()" dark>Request Change</v-btn></div>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'

  export default {
    name: 'SpecialistProfile',
    components: {
      SpecialistNavigation,
      SpecialistMenu,
      Header,
      Chat,
    },
    data() {
      return {
        successMessage: '',
        errorMessage: '',
        expertTag: '',
        expertTags: [],
        specialInterestTag: '',
        specialInterestTags: [],
        educationTag: '',
        educationTags: [],
        changeRequest: { body: '', image: { image: '', imageType: '' }, specialist: { specialistId: '', specialistName: '' }, dateUploaded: '', IsProfilePicture: 0 },
        qualificationTag: '',
        qualificationTags: [],
        checked: false,
        specialist: utilities.initSpecialist(),
        placeholderText: '',
        location: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    methods: {
      getInitials(specialist) {
        return utilities.getInitials(specialist.firstName, specialist.lastName)
      },

      tapUpdate() {},

      setSpecialistInfo() {
        this.specialist.qualifications.forEach((item, i) => {
          if (item.id != "") {
            this.qualificationTags.push({ text: item.qualificationName })
          }
        })

        this.specialist.certifications.forEach((item, i) => {
          if (item.id != "") {
            this.educationTags.push({ text: item.certificationName })
          }
        })

        this.specialist.personConditions.forEach((item, i) => {
          if (item.id != "") {
            this.expertTags.push({ text: item.personConditionName })
          }
        })

        this.specialist.interests.forEach((item, i) => {
          if (item.id != "") {
            this.specialInterestTags.push({ text: item.personConditionName })
          }
        })

        this.location = this.specialist.locations.name + ', ' + this.specialist.locations.cityName
      },

      chooseProfileImage() {
        $("input[type='file'][name='profile-image']").click()
      },

      async changeProfileImage(e) {
        if (!e.target.files.length) {
          return false
        }

        this.specialist.photoURL = ""
        var object = await utilities.convertFileToBase64(e.target.files[0])
        this.specialist.photo = object
        setTimeout(() => {
          $(".request-change .profile-image-content img").attr("src", object.file.base64)
        }, 100)

        var reader = new FileReader()
        reader.onloadend = () => {
          this.changeRequest.image = {}
          this.changeRequest.IsProfilePicture = 1
          var res = reader.result
          var image = res.split(',')[1]
          var imageType = res.split(';')[0].split('/')[1]
          this.changeRequest.image.image = image
          this.changeRequest.image.imageType = imageType
        }
        reader.readAsDataURL(e.target.files[0])
      },

      async tapRequestChange() {
        var vm = this
        var params = {
          SpecialistID: this.changeRequest.specialist.specialistId,
          SpecialistName: this.changeRequest.specialist.specialistName,
          ImageType: this.changeRequest.image.imageType,
          DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
          ProfilePicture: this.changeRequest.image.image,
          ChangeRequestDetails: this.changeRequest.body,
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var callback = (response) => {
          this.specialist.id = response
        }
        if (await utilities.postRequestChange(params)) {
          var body = this.changeRequest.body
          if (this.changeRequest.image.image) {
            if (body.length > 0) {
              body = body + ' ;New image included.'
            } else {
              body = 'A new image included.'
            }
          } else {
            if (body.length > 0) {
              body = body + ' ;No image included'
            } else {
              body = 'No image included'
            }
          }
          params = {
            IsProfilePicture: this.changeRequest.IsProfilePicture,
            UserID: this.user.id,
            Username: this.user.name,
            SpecialistID: this.changeRequest.specialist.specialistId,
            ChangeRequestDetails: body,
            DateUploaded: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
          }

          if (await utilities.postRequestChangeLog(params)) {
            this.successMessage = 'We will review your updates and contact you if we need any further information.'
            utilities.showAlert('.alert-success')
          } else {
            this.errorMessage = 'The changes could not be requested. If you are trying to upload an image, please upload a JPG or a PNG file.'
            utilities.showAlert('.alert-danger')
          }

          this.changeRequest = {
            body: '',
            image: { image: '', imageType: '' },
            specialist: { specialistId: this.specialist.id, specialistName: this.specialist.fullName },
            dateUploaded: '',
            IsProfilePicture: 0
          }

        } else {
          this.errorMessage = 'The changes could not be requested. If you are trying to upload an image, please upload a JPG or a PNG file.'
          utilities.showAlert('.alert-danger')
        }

        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },

    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', false)
      var specialist = await utilities.getSpecialistById(this.user.specialistId)
      if(specialist != undefined) {
        this.specialist = specialist
        // Load profile photo
        if(this.specialist.photo != '') {
          this.specialist.profileThumbnail = await utilities.getProfileThumbnail(this.specialist.photo)
        }
        this.changeRequest.specialist.specialistId = this.specialist.id
        this.changeRequest.specialist.specialistName = this.specialist.fullName
        this.setSpecialistInfo()
      }
      utilities.initTooltip()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    computed: {},

    mounted() {},
  }
</script>

<style scoped>
  p {
    margin-top: 20px;
  }
</style>
