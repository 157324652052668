/* eslint-disable */
<template>
  <div class="availability-bookings">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Availability {{ availabilityBooking.specialist.id != '' ? ' - ' + availabilityBooking.specialist.fullName : '' }}</h1></div>
        <div class="header-sub-title">The following appointments have been arranged {{ availabilityBooking.specialist.id != '' ? ' for ' + availabilityBooking.specialist.fullName : '' }}.</div>
      </div>
    </div>
    <div class="panel">
      <AdminAvailabilityMenu indexProps="0" />
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <div class="w-100 mw-100 long-text">
              <span class="mr-1">{{ formatDate(calendarDate, "dddd Do MMMM, YYYY") }}</span>
              <span v-if="locationList.trim().length > 0">|</span>
              <span v-if="locationList.trim().length > 0" class="ml-1 location-list" data-placement="bottom" data-toggle="tooltip" :title="locationList"><strong>Location: </strong>{{ locationList }}</span>
            </div>
            <div class="icon-group right-element">
              <div><img src="@/assets/images/file_icon.svg" @click="exportPdf" data-toggle="tooltip" data-placement="right" title="Export appointments to PDF" /></div>
            </div>
            <vue-html2pdf
              v-show="availabilityBooking.specialist.id !== ''"
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1400"
              :filename="getName"
              :pdf-quality="2"
              :manual-pagination="true"
              :html-to-pdf-options="{...htmlToPdfOptions, filename: getName}"
              pdf-format="a4"
              pdf-orientation="landscape"
              pdf-content-width="297mm"
              ref="html2Pdf"
              @hasDownloaded="hasDownloaded">
              <section slot="pdf-content">
                <SpecialistAvailabilityBookingPdf ref="specialistAvailabilityBookingPdf" />
              </section>
            </vue-html2pdf>
          </div>
          <div class="box-body">
            <v-row class="fill-height">
                <v-col>
                  <v-sheet height="600">
                    <v-calendar
                      id="calendar"
                      ref="calendar"
                      color="primary"
                      type="day"
                      v-model="availabilityBooking.calendarDate"
                      :interval-height="anHourHeight"
                      :events="availabilityBooking.bookings"
                      :event-ripple="false"
                      :weekday-format="setWeekday"
                      :day-format="setDay"
                      event-overlap-mode="column"
                      @mouseleave.native="cancelDrag">
                      <template v-slot:event="{ event }">
                          <div v-if="!event.bookingId" @click="createBooking(event)" :id="event.item.AvailabilityID" @mouseover="onMouseEnter(event.item.AvailabilityID)" class="fill-height border calendar-event v-event-draggable" :data="event.id">
                              <div class="d-flex justify-content-between" >
                                <div class="d-flex align-items-center">
                                  <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                                  <div class="d-flex align-items-center ml-2">
                                    <el-tooltip  v-if="event.item.Options.VideoOnly" content="Only available for video appointment">
                                      <div class="video-icon"></div>
                                    </el-tooltip>
                                    <el-tooltip  v-if="event.item.Options.VideoLinkAvailable" content="Available for video and face to face appointment">
                                      <div  class="user-video-icon"></div>
                                    </el-tooltip>
                                    <el-tooltip  v-if="!event.item.Options.VideoLinkAvailable && !event.item.Options.VideoOnly" content="Only available for face to face appointment">
                                      <div class="face-icon"></div>
                                    </el-tooltip>
                                    <el-tooltip v-if="event.item.Options.ShowWorkCover" content="Show to everyone INCLUDING WorkCover QLD">
                                      <div class="show-worker-icon"></div>
                                    </el-tooltip>
                                    <el-tooltip v-if="event.item.Options.ShowSpecificClients">
                                      <template slot="content">
                                        <div>Show only to these clients:</div>
                                        <div
                                            v-for="(item, index) in event.item.SpecificClients"
                                            :key="index"
                                        >
                                          <div>{{decodeURIComponent(item.ClientName)}}</div>
                                        </div>
                                      </template>
                                      <div class="specific-client-icon"></div>
                                    </el-tooltip>
                                    <el-tooltip v-if="event.item.Options.ByRequestOnly" content="Request only">
                                      <div v-if="event.item.Options.ByRequestOnly" class="request-icon"></div>
                                    </el-tooltip>
                                  </div>
                                  <div class="text-white ml-2">{{decodeURIComponent(event.item.Location.LocationName)}}</div>
                                </div>
                                <div v-if="event.status === 'H'" class="text-white">On hold</div>
                              </div>
                          </div>
                        <div v-else class="calendar-event-booking-tooltip w-100 v-event-draggable" style="z-index: 100" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.bookingDetail.detail">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.name)}}</div>
                              <div class="event-icon right-element" @click="toBookingPage(event)"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                  <div class="box-col d-flex mt-5">
                    <div class="availability-item">
                      <div class="calender-indicator calender-available"></div>
                      <div class="indicator-text">Available</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-bookied"></div>
                      <div class="indicator-text">Booked</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-onhold"></div>
                      <div class="indicator-text">On hold</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-unconfirmed"></div>
                      <div class="indicator-text">Unconfirmed</div>
                    </div>
                  </div>

                  <div class="alert alert-success hidden-element">
                    <a class="close" title="close">×</a>
                    <b>Success:</b> {{ successMessage }}
                  </div>
                  <div class="alert alert-danger hidden-element">
                    <a class="close" title="close">×</a>
                    <b>Error:</b> {{ errorMessage }}
                  </div>
                  <br />

                  <div class="icon-group" v-if="!bookingObject || !bookingObject.waitlisted">
                    <div class="w-100"><v-btn class="reschedule right-float-element" dark>Reschedule</v-btn></div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <VerticalYearCalendar ref="verticalYearCalendar" :key="calendarKey"
                    :calendarDateProps="formatDate(availabilityBooking.calendarDate, 'YYYY')"
                    :dataSourceProps="availabilityBooking.dataSourceAvailabilities"
                    :pathProps="'admin-availability-bookings?id=' + availabilityBooking.specialist.id"
                    :specialist="availabilityBooking.specialist"
                    :isAdminProps = "true" />
                </v-col>
            </v-row>
          </div>
        </div>
        <br />

<!--        <div v-if="showTravel" :key="travelKey" class="box">-->
<!--          <div class="box-heading">-->
<!--            <span>Travel & Accommodation Detail</span>-->
<!--            <div class="right-element" v-if="this.availabilityId != ''">-->
<!--              <router-link :to="{ name: 'admin-manage-travel', query: { availabilityId: this.availabilityId } }" target="_blank">-->
<!--                <v-btn dark>Manage</v-btn>-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="availabilityBooking.travels.length > 0" class="box-body">-->
<!--            <div v-for="(travel, index) in availabilityBooking.travels" class="box-row" :key="index">-->
<!--              <div v-if="travel.airlineName.length > 0" class="box-col">-->
<!--                {{ 'Flight ' + travel.flightNumber + ', Departing ' + travel.departingAirportName + ', ' + formatDate(travel.travelStartTime, 'hh:mm A', 'HH:mm:ss') + ' ' + formatDate(travel.travelStartDate, 'DD/MM/YYYY') + ', Arriving ' + travel.arrivingAirportName + ', Seat ' + travel.seatNumber + ', Booking Ref ' + travel.bookingReference }}-->
<!--              </div>-->
<!--              <div v-if="travel.carHireName.length > 0" class="box-col">-->
<!--                {{ travel.carHireName + ', Booking Ref ' + travel.bookingReference + ', Pickup ' + formatDate(travel.travelStartTime, 'hh:mm A', 'HH:mm:ss') + ' ' + formatDate(travel.travelStartDate, 'DD/MM/YYYY') }}-->
<!--              </div>-->
<!--              <div v-if="travel.accommodationName.length > 0" class="box-col">-->
<!--                {{ travel.accommodationName + ', ' + travel.accommodationAddress + ', Phone ' + travel.accommodationPhoneNumber + ', Booking Ref ' + travel.bookingReference + ', Check-in ' + formatDate(travel.travelStartTime, 'hh:mm A', 'HH:mm:ss') }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <div class="box-row">-->
<!--              <div class="box-col">No travel items booked for {{ formatDate(calendarDate, "DD/MM/YYYY") }}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div v-if="this.availabilityBooking.availability.id != ''" class="general-information">
          <div class="box">
            <div class="box-heading">
              <span>General Information</span>
              <div class="right-element">
                <v-btn dark @click="updateAvailabilityGeneralInformation">Save</v-btn>
              </div>
            </div>
            <div class="box-body px-2 py-1">
              <textarea v-model="availabilityBooking.availability.generalInformation" maxlength="250" :class="hasError && !validateGeneralInformation ? 'is-invalid' : ''"></textarea>
              <div v-if="hasError && !validateGeneralInformation" class="text-danger">Maximum character is 250.</div>
            </div>
          </div>
          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu"
import VerticalYearCalendar from "@/components/VerticalYearCalendar"
import SpecialistAvailabilityBookingPdf from '@/components/SpecialistAvailabilityBookingPdf'
import Header from "@/components/Header"

export default {
  name: "AdminAvailabilityBookings",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    VerticalYearCalendar,
    SpecialistAvailabilityBookingPdf,
    Header,
  },
  data() {
    return {
      hasError: false,
      successMessage: "",
      errorMessage: "",

      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      calendarDate: (this.$route.query != undefined && this.$route.query.date != undefined) ? this.$route.query.date : "",
      availabilityBooking: {
        calendarDate: "",
        specialist: utilities.initSpecialist(),
        availability: utilities.initAvailability(),
        availabilities: [],
        dataSourceAvailabilities: [],
        bookings: [],
        travels: [],
      },
      bookingEvents: [],
      availabilityEvents: [],
      locationList: "",
      availabilityId: "",
      showTravel: true,
      bookingObject: {},
      selectedAvailability: {
        id: 0,
        locationId: 0,
      },
      originalBookings: [],

      originalEvent: null,  // event
      dragEvent: null,  // event
      dragOriginal: null, // event
      dragTime: null, // timestamp
      createEvent: null,
      createStart: null,
      extendOriginal: null, // time
      clickEvent: null,  // event
      specialistId: null,
      minimumAppointmentLength: utilities.APPOINTMENT_LENGTH,
      anHourHeight: utilities.AN_HOUR_HEIGHT,
      calendarKey: 0,
      travelKey: 0,
      dropEle: null,
      htmlToPdfOptions: {
        margin: 0,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'landscape'
        }
      },
    }
  },
  computed: {
    getName() {
      if(this.availabilityBooking?.specialist && this.availabilityBooking?.calendarDate) {
        return `Red Health Schedule of Assessments - ${this.availabilityBooking?.specialist?.fullName} - ${this.availabilityBooking?.calendarDate}`
      }
      return 'Red Health Schedule of Assessments'
    },
    validateGeneralInformation() {
      if(this.availabilityBooking.availability.generalInformation.trim().length > 250) {
        return false
      }
      return true
    }
  },
  methods: {
    showClient(e) {
      let content = '<div style="padding: 10px"><b>Show only to these clients:</b>'
      e.forEach(item => {
        content += `<div>${decodeURIComponent(item.ClientName)}</div>`
      })
      content += '</div>'
      this.$notify({
        message: content,
        dangerouslyUseHTMLString: true,
        duration: 10000
      });
    },
    onMouseEnter(e) {
      if(this.dragEvent) {
        this.dropEle = e
        console.log(e)
      }
    },
    createBooking(item) {
      if(item.booking || item.bookingId || item.status === 'H' || item?.item.AvailabilityStatus === '1') return
      this.openAvailabilityBookings(item.item)
    },
    toBookingPage(booking) {
      this.openBooking(booking.bookingId)
    },
    hasDownloaded() {
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    async exportPdf() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.$refs.specialistAvailabilityBookingPdf.specialist.id = this.availabilityBooking.specialist.id
      await this.$refs.specialistAvailabilityBookingPdf.loadData(this.calendarDate)
      console.log(this.availabilityBooking.specialist)
      if (this.$refs.specialistAvailabilityBookingPdf.bookings.length > 0) {
        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf()
          this.$store.dispatch('loading/setLoadComponent', false)
        }, 1000)
      } else {
        this.errorMessage = 'No bookings found for this day.'
        utilities.showAlert('.box-body .alert-danger')
        this.$store.dispatch('loading/setLoadComponent', false)
      }
    },
    setWeekday(d) {
      return this.formatDate(d.date, 'dddd')
    },
    setDay(d) {
      return this.formatDate(d.date, 'DD/MM/YYYY')
    },
    formatDate(dateToFormat, newFormat, currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    async openAvailabilityBookings(availability) {
      var bookingParams = {
        id: this.specialistId,
        endDate: this.availabilityBooking.calendarDate,
        locationId: availability.Location.LocationID,
        bookingDate: this.availabilityBooking.calendarDate,
        bookingTime: availability.StartTime,
        bookingEndTime: availability.EndTime,
        availabilityId: availability.AvailabilityID,
        locationName: availability.Location.LocationName,
        typeId: '',
        WaitListPreBookingID: this.bookingObject.WaitListPreBookingID,
        hasUserVideo: availability.Options.VideoLinkAvailable ? true : false,
        hasVideo: availability.Options.VideoOnly === 1 ? true : false
      }
      this.$store.dispatch("booking/setBookingObject", bookingParams)
      this.$router.push({ name: 'admin-search-cases-details' }).catch((err) => {})
    },
    startDrag(e) {
      if (e.event && e.event.bookingId) {
        this.originalBookings = _.cloneDeep(e.event)
        this.dragEvent = e.event
        this.dragOriginal = _.cloneDeep(this.dragEvent)
        this.originalEvent = _.cloneDeep(this.dragEvent)
        utilities.disposeTooltip()
      }
    },
    startTime(eventDate) {
      this.$nextTick(() => {
        var mouse = utilities.convertEventToTimestamp(eventDate)
        if (this.dragEvent && this.dragTime === null) { // Drag booking
          var start = utilities.convertDateToTimestamp(this.dragEvent.start)
          this.dragTime = mouse - start
        }
      })
    },
    extendBottom(event) {
      this.originalBookings = _.cloneDeep(this.availabilityBooking.bookings)
      if(moment(event.end, "YYYY-MM-DD HH:mm").isBefore(moment(event.start, "YYYY-MM-DD HH:mm").add(5, 'minutes'))) {
        return
      }

      this.createEvent = event
      this.originalEvent = _.cloneDeep(this.createEvent)
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    moveTime(eventDate) {
      var mouse = utilities.convertEventToTimestamp(eventDate)
      if (this.dragEvent && this.dragTime !== null) {
        var start = utilities.convertDateToTimestamp(this.dragEvent.start)
        var end = utilities.convertDateToTimestamp(this.dragEvent.end)
        var duration = end - start
        var newStartTime = mouse - this.dragTime
        var newStart = utilities.roundTime(newStartTime)
        var newEnd = newStart + duration
        this.dragEvent.start = utilities.convertTimestampToDate(newStart, "YYYY-MM-DD HH:mm")
        this.dragEvent.end = utilities.convertTimestampToDate(newEnd, "YYYY-MM-DD HH:mm")
      } else if (this.createEvent && this.createStart !== null) { // Extend time at the bottom
        var mouseRounded = utilities.roundTime(mouse, false)
        var min = Math.min(mouseRounded, utilities.convertDateToTimestamp(this.createStart))
        var max = Math.max(mouseRounded, utilities.convertDateToTimestamp(this.createStart))
        this.createEvent.start = utilities.convertTimestampToDate(min, "YYYY-MM-DD HH:mm")
        this.createEvent.end = utilities.convertTimestampToDate(max, "YYYY-MM-DD HH:mm")
      }
    },
    mouseMove(e) {
    },
    endDrag(e) {
      var updateEvent = null
      this.updateCalendarEvent()
      if(this.dragEvent) {
        if((this.dragEvent.start !== this.dragOriginal.start || this.dragEvent.end !== this.dragOriginal.end) && (this.dragEvent.bookingDetail.availabilityId !== this.dropEle)) {
          updateEvent = _.cloneDeep(this.dragEvent)
        } else {   // outside the available times
          this.cancelDrag()
          return
        }
      } else if(this.createEvent) {  // End extend time at the bottom
        if(this.createEvent.end !== this.extendOriginal
          && this.validateExtendEvent(this.formatDate(this.createEvent.start, "hh:mm A", "YYYY-MM-DD HH:mm"), this.formatDate(this.createEvent.end, "hh:mm A", "YYYY-MM-DD HH:mm"), this.createEvent.item.AvailabilityID)) {
          updateEvent = _.cloneDeep(this.createEvent)
        } else {
          this.cancelDrag()
          return
        }
      }
      this.dragEvent = null
      this.dragOriginal = null
      this.dragTime = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
      this.clickEvent = null
      if(updateEvent != null) this.updateAvailabilityBooking(updateEvent)
    },
    cancelDrag(e) {

      if(this.dragEvent) {
        this.dragEvent.start = this.dragOriginal.start
        this.dragEvent.end = this.dragOriginal.end
      } else if(this.createEvent) {
        this.createEvent.end = this.extendOriginal
      }

      this.dragEvent = null
      this.dragOriginal = null
      this.dragTime = null
      this.createEvent = null
      this.createStart = null
      this.clickEvent = null
    },
    hideAvailability() {
      if(this.availabilityEvents.length > 0 && this.availabilityBooking.bookings.length > this.bookingEvents.length) {
        this.availabilityEvents.map(x => {
          this.availabilityBooking.bookings.splice(-1, 1)
        })
      }
    },
    // showAvailability() {
    //   if(this.availabilityEvents.length > 0) {
    //     this.availabilityBooking.bookings = []
    //     this.availabilityEvents.map(x => {
    //       this.availabilityBooking.bookings.push(_.cloneDeep(x))
    //     })
    //   }
    // },
    updateCalendarEvent() {
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip({template: '<div class="tooltip calendar-event"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'})

        if(this.locationList.trim().length > 0) {
          var childWidth = Number($(".location-list").css("width").replace("px", ""))
          var parentWidth = Number($(".location-list").parent().css("width").replace("px", ""))
          if(childWidth < parentWidth / 2) $(".location-list[data-toggle='tooltip']").tooltip('dispose')
        }
        // Hover header
        $(".v-calendar-daily__head").unbind("hover")
        $(".v-calendar-daily__head").hover(() => {
          this.cancelDrag()
        })
      }, 500)
    },
    validateDragEvent(start, end) {
      var result = false
      this.availabilityBooking.availabilities.find(x => x.date === this.calendarDate).Availabilities.map(a => {
        const index = window.moment(this.calendarDate + " " + start).isBetween(window.moment(this.calendarDate + " " + a.StartTime), window.moment(this.calendarDate + " " + a.EndTime))
        const index2 = window.moment(this.calendarDate + " " + end).isBetween(window.moment(this.calendarDate + " " + a.StartTime), window.moment(this.calendarDate + " " + a.EndTime))
        if(!index && !index2) {
          this.selectedAvailability.id = a.AvailabilityID
          this.selectedAvailability.locationId = a.Location.LocationID
          result = true
        }
      })
      return result
    },
    validateExtendEvent(start, end, availabilityId) {
      let result = true
      const availability = this.availabilityBooking.availabilities.find(item => window.moment(item.date).isSame(window.moment(this.calendarDate)))
      availability.Availabilities.forEach(item => {
        if(window.moment(end, 'hh:mm A').isBetween(window.moment(item.StartTime, 'HH:mm:ss'), window.moment(item.EndTime, 'HH;mm:ss')) && availabilityId !== item.AvailabilityID) result = false
        if(window.moment(item.StartTime, 'HH:mm:ss').isBetween(window.moment(start, 'hh:mm A'), window.moment(end, 'hh:mm A')) && availabilityId !== item.AvailabilityID) result = false
      })
      return result
    },
    openBooking(id) {
      var routeData = this.$router.resolve({ name: 'admin-manage-booking', query: { id: id } })
      window.open(routeData.href, '_blank')
    },
    async putBooking(booking) {
      const obj = this.availabilityBooking.bookings.find(item => item.item.AvailabilityID === this.dropEle && !item.booking)
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingStatus: 3,
        BookingStatusName: 'Booked',
        BookingID: booking.bookingId,
        SpecialistID: this.availabilityBooking.specialist.id,
        AvailabilityID: this.dropEle,
        LocationID: obj.item.Location.LocationID,
        LocationName: obj.item.Location.LocationName,
        BookingTypeID: booking.bookingDetail.BookingTypeID,
        IsReschedule: true,
        BookingTypeName: booking.bookingDetail.type,
        CurrentDateTime: utilities.timeToBrisbane(window.moment().format('YYYY-MM-DD HH:mm:ss')),
        BookingDateTime: utilities.formatDate(obj.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"),
        BookingEndTime: utilities.formatDate(obj.end, "HH:mm:ss", "YYYY-MM-DD HH:mm"),
        ZohoLink: booking.bookingDetail.zohoLink.split('/')[booking.bookingDetail.zohoLink.split('/').length - 1],
        ZohoBookingDateTime: moment(obj.start, "YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm").format(),
      }

      const noteObj = {
        Note: `Booking Rescheduled from ${utilities.formatDate(this.originalEvent.start, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm")} to ${moment(params.BookingDateTime).format('DD/MM/YYYY HH:mm')}`,
        NoteDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: booking.bookingId,
        BookingDateTime: params.BookingDateTime,
        SpecialistName: booking.bookingDetail.specialistDetails.fullName,
        ClientName: booking.bookingDetail.clientName + ' - ' + booking.bookingDetail.branchName,
        BookingTypeName: params.BookingTypeName,
        InternalOnly: 1,
        IsMainEdit: 0,
        IsAdmin: true
      }

      var callback = async () => {
        await utilities.postBookingNote(noteObj)
        await this.rescheduleEmail(booking, obj)
        await this.loadAvailabilityBooking()
      }
      return await utilities.putBooking(params, callback)
    },
    rescheduleEmail(booking, obj) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: booking.bookingId,
        BookingDateTime: utilities.formatDate(obj.start, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"),
      	OldBookingDateTime: utilities.formatDate(this.originalEvent.start, "YYYY-MM-DD HH:mm:ss"),
      	SpecialistID: this.availabilityBooking.specialist.id,
      	OldSpecialistID: this.availabilityBooking.specialist.id,
        SpecialistName: this.availabilityBooking.specialist.fullName,
        OldSpecialistName: this.availabilityBooking.specialist.fullName,
      	LocationName: obj.item.Location.LocationName,
        OldLocationName: obj.item.Location.LocationName,
      	BookingTypeName: booking.bookingDetail.type,
        ClientName: booking.bookingDetail.clientName + ' - ' + booking.bookingDetail.branchName,
        BranchName: booking.branchName,
        IsAdmin: 1,
      }

      return utilities.postRescheduleEmail(params)
    },
    putAvailabilityGeneralInformation() {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        SpecialistAvailabilityID: this.availabilityBooking.availability.id,
        SpecialistID: this.availabilityBooking.specialist.id,
        GeneralInformation: this.availabilityBooking.availability.generalInformation,
      }
      var callback = (response) => {}

      return utilities.putAvailability(params, callback)
    },

    async updateAvailabilityBooking(booking) {
      if(booking && this.dropEle) {
        this.$store.dispatch("loading/setLoadComponent", true)
        await this.putBooking(booking)
        this.dropEle = null
        this.$store.dispatch("loading/setLoadComponent", false)
      }
    },
    async updateAvailabilityGeneralInformation() {
      if(this.availabilityBooking.availability.id == '') {
        return
      }
      if(!this.validateGeneralInformation) {
        this.hasError = true
        this.errorMessage = "There are the errors. Please try again."
        utilities.showAlert(".general-information .alert-danger")
        return
      }

      this.$store.dispatch("loading/setLoadComponent", true)
      var result = await this.putAvailabilityGeneralInformation()
      if(result) {
        this.successMessage = "General Information updated successfully."
        utilities.showAlert(".general-information .alert-success")
      } else {
        this.errorMessage = "There are the errors. Please try again."
        utilities.showAlert(".general-information .alert-danger")
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadTimeSlot() {
      var availability = this.availabilityBooking.availabilities.find(x => window.moment(x.AvailabilityDate).isSame(window.moment(this.calendarDate)))
      // Get all time slots from today to future only
      if(availability != undefined && moment(this.calendarDate, "YYYY-MM-DD").isSameOrAfter(moment())) {
        // Get all availabilities time slot
        availability.Availabilities.map(x => {
          this.selectedAvailability.id = x.AvailabilityID
          this.selectedAvailability.locationId = x.Location.LocationID
        })
      }
    },
    loadBookingObject() {
      this.bookingObject = this.$store.getters["booking/getBookingObject"]
    },
    async loadAvailabilityBooking() {
      var specialistId = (this.$route.query != undefined && this.$route.query.id != undefined) ? this.$route.query.id : ""
      this.specialistId = specialistId
      if(specialistId != '' && this.calendarDate != '') {

        let params = {
          specialistId: specialistId,
          startDate: this.calendarDate,
          endDate: this.calendarDate,
        }
        const params2 = {
          StartDate: moment().startOf('month').format("YYYY-MM-DD"),
          EndDate: moment().startOf('month').add(1, 'year').format("YYYY-MM-DD")
        }

        const apiArr = [utilities.getSpecialistBookings(params), utilities.getSpecialistAvailabilities(specialistId, '', params2), utilities.getSpecialistById(specialistId)]
        const r = await Promise.all(apiArr)
        this.availabilityBooking.specialist = r[2]
        let bookings = r[0]
        let tempArray = []
        for (let i = 0; i < bookings.length; i++) {
          let booking =  bookings[i]
          if (booking.bookingDetails.typeId != 6 && booking.bookingDetails.typeId != 8) {
            if (booking.bookingDetails.status != 1 && booking.bookingDetails.status != 6 && booking.bookingDetails.status != 16) {
              tempArray.push(booking)
            }
          }
        }
        bookings = _.cloneDeep(tempArray)
        //bookings = bookings.filter(x => Number(x.bookingDetails.status) >= 1 && Number(x.bookingDetails.status) <= 15)
        var availabilities = r[1]
        this.locationList = _.uniqBy(_.uniqBy(availabilities.filter(x => x.availabilityDate == this.calendarDate), 'id'), 'locationId').map(x => x.fullLocation).join("; ")

        // Get availabilities
        this.availabilityBooking.availabilities = _.cloneDeep(availabilities)
        this.availabilityBooking.dataSourceAvailabilities = _.cloneDeep(availabilities)
        this.bookingEvents = []
        bookings.map(x => {
          this.bookingEvents.push(utilities.loadBooking(x))
        })

        // Get Availability Events
        this.availabilityEvents = []
        // Show all availabilities from today to future only
        const availability = this.availabilityBooking.availabilities.find(x => window.moment(x.date).isSame(window.moment(this.calendarDate)))
        this.calendarKey += 1
        if(!availability || availability.Availabilities.length === 0) return
        this.bookingEvents.forEach(e => {
          const item = availability.Availabilities.find(item => item.AvailabilityID === e.availabilityId)
          if(item) item.booking = e
        })
        const arr = []
         availability.Availabilities.forEach(item => {
          const year = window.moment(availability.availabilityDate).format('YYYY-MM-DD')
           if(item.AvailabilityStatus !== '1') {
             let obj = {
               name: item?.booking?.fullLocation || item?.Location?.LocationName,
               start: window.moment(year + ' ' + item.StartTime).format('YYYY-MM-DD HH:mm'),
               end: window.moment(year + ' ' + item.EndTime).format('YYYY-MM-DD HH:mm'),
               startTime: window.moment(item.StartTime, 'HH:mm:ss').format('hh:mm A'),
               endTime: window.moment(item.EndTime, 'HH:mm:ss').format('hh:mm A'),
               color: utilities.getAvailabilityColors(item),
               timed: true,
               booking: item.booking,
               status: item.BookingStatus,
               item: item
             }
             arr.push(obj)
           }
        })
        this.bookingEvents.forEach(item => {
          const obj = {
            bookingId: item.id,
            className: 'booking-event',
            name: item?.fullLocation,
            start: window.moment(item.start).format('YYYY-MM-DD HH:mm'),
            end: window.moment(item.end).format('YYYY-MM-DD HH:mm'),
            startTime: window.moment(item.start).format('hh:mm A'),
            endTime: window.moment(item.end).format('hh:mm A'),
            color: '#0080ff',
            timed: true,
            bookingDetail: item
          }
          arr.push(obj)
        })
        this.availabilityEvents = arr
        this.availabilityBooking.bookings = arr
        this.travelKey += 1


        // setTimeout(() => {
        //   // Scroll down to the current month
        //   var month = moment(this.calendarDate, "YYYY-MM-DD").format("MM")
        //   var positionTo = 2
        //   if (month == 1) {
        //     positionTo = 0.5
        //   } else if (month == 12) {
        //     positionTo = 1
        //   }
        //   var index = Number(month) - positionTo
        //   // Check index is integer not decimal
        //   if(Number.isInteger(index)) $(".vertical-year-calendar .months-container").animate({ scrollTop: $(".vertical-year-calendar .months-container .month-container").eq(index).offset().top }, 'slow')
        // }, 300)
      }

      this.originalEvent = null
    },
  },

  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    this.availabilityBooking.calendarDate = this.calendarDate
    this.loadBookingObject()
    await this.loadAvailabilityBooking()
    this.updateCalendarEvent()
    // Scroll down to the appointment
    $(".v-calendar-daily__scroll-area").animate({ scrollTop: ($(".v-calendar-daily__scroll-area").height() / 3 * 4) }, 'slow')
    // Scroll down to the current month
    //$(".vertical-year-calendar .months-container").animate({ scrollTop: $(".vertical-year-calendar .months-container .month-container").eq(Number(moment(this.calendarDate).format("MM")) - 2).offset().top }, 'slow')

    $(".reschedule").click((e) => {
      if(moment(this.calendarDate).isBefore(moment(moment().format("YYYY-MM-DD")))) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      var routeData = this.$router.resolve({ path: 'admin-availability-bookings-reschedule', query: { id: this.availabilityBooking.specialist.id, date: this.calendarDate } })
      window.open(routeData.href)
    })
    if(moment(this.calendarDate).isBefore(moment(moment().format("YYYY-MM-DD")))) {
      $(".reschedule").addClass("disabled-element")
    }

    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {},
}
</script>
