<template>
  <div class="global-search">
    <AdminSearchClientModal ref="searchClientModal" />
    <AdminSearchClientContactModal ref="searchClientContactModal" />
    <AdminSearchClaimantModal ref="searchClaimantModal" />
    <AdminSearchSpecialistModal ref="searchSpecialistModal" />
    <AdminSearchBookingModal ref="searchBookingModal" />
    <AdminSelectClientModal ref="selectClientModal" />
    <AdminSearchCaseModal ref="searchCaseModal" />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminSelectClientModal from '@/components/AdminSelectClientModal'
import AdminSearchClientModal from '@/components/AdminSearchClientModal'
import AdminSearchClientContactModal from '@/components/AdminSearchClientContactModal'
import AdminSearchClaimantModal from '@/components/AdminSearchClaimantModal'
import AdminSearchSpecialistModal from '@/components/AdminSearchSpecialistModal'
import AdminSearchBookingModal from '@/components/AdminSearchBookingModal'
import AdminSearchCaseModal from '@/components/AdminSearchCaseModal'

export default {
  name: 'AdminSearch',
  components: {
    AdminSelectClientModal,
    AdminSearchClientModal,
    AdminSearchClientContactModal,
    AdminSearchClaimantModal,
    AdminSearchSpecialistModal,
    AdminSearchBookingModal,
    AdminSearchCaseModal,
  },
  data() {
    return {
      bulkRefData: {},
    }
  },
  methods: {
    async openSelectClient() {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.loadData()
      this.$refs.selectClientModal.handleModal()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    openSearchClient() {
      this.$refs.searchClientModal.searchOption = this.$refs.searchClientModal.initSearchOption()
      this.$refs.searchClientModal.handleModal()
    },
    openSearchClientContact() {
      this.$refs.searchClientContactModal.searchOption = this.$refs.searchClientContactModal.initSearchOption()
      this.$refs.searchClientContactModal.handleModal()
    },
    openSearchClaimant() {
      this.$refs.searchClaimantModal.searchOption = this.$refs.searchClaimantModal.initSearchOption()
      this.$refs.searchClaimantModal.handleModal()
    },
    async openSearchSpecialist() {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.loadData()
      this.$refs.searchSpecialistModal.searchOption = this.$refs.searchSpecialistModal.initSearchOption()
      this.$refs.searchSpecialistModal.handleModal()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async openSearchBooking(searchOption = null) {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.$refs.searchBookingModal.loadData()
      if(searchOption) this.$refs.searchBookingModal.searchOption = searchOption
      else this.$refs.searchBookingModal.searchOption = this.$refs.searchBookingModal.initSearchOption()
      this.$refs.searchBookingModal.handleModal()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async openSearchCase(searchOption = null) {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.loadData()
      if(searchOption) this.$refs.searchCaseModal.searchOption = searchOption
      else this.$refs.searchCaseModal.resetSearchOption()
      this.$refs.searchCaseModal.handleModal()
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    async loadData() {
      this.bulkRefData = await utilities.getBulkRefData()
      this.$refs.selectClientModal.clients = this.bulkRefData.clients
      this.$refs.searchCaseModal.clients = this.bulkRefData.clients

      this.$refs.searchSpecialistModal.qualifications = utilities.getAutoCompleteTags(this.bulkRefData.qualifications)
      this.$refs.searchSpecialistModal.personConditions = utilities.getAutoCompleteTags(this.bulkRefData.personConditions)
      this.$refs.searchSpecialistModal.regionLocations = utilities.getRegionLocations(this.bulkRefData.regions, this.bulkRefData.locations)
    }
  },
  beforeMount() {},
  mounted() {},
}
</script>
