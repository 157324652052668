/* eslint-disable */
<template>
  <div class="BookingRequest">
    <Navigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Booking Request</h1></div>
        <div class="header-sub-title">We look forward to helping you arrange the service you require for your claimant. Complete the form below, so we can assist you in finding the right specialist for your needs.</div>
      </div>
    </div>
    <div class="panel">
      <SpecialistMenu indexProps="0" />
      <div class="content">
        <div v-if="showCaseInfo" style="padding-left: 10px;">
          <span class="text-bold">Claimant:</span> {{ singleCase.claimantFullName }} <span class="text-bold"> D.O.B:</span> {{ formatDate(singleCase.dateOfBirth, "DD/MM/YYYY") }}
          <span class="text-bold"> Type of Claim:</span> {{getClaimType(singleCase.claimTypeId)}}
        </div>
        <div class="box booking-details">
          <div class="box-heading col-space-between">
            <div>Booking details</div>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Booking Type</div>
              <div class="box-col box-col-right">
                <el-select popper-class="search-specialty-popup" placeholder="Please choose" class="w-50" :class="hasError && !validateBookingType ? 'is-invalid' : ''" v-model="bookingRequest.bookingTypeId">
                  <el-option v-for="(bookingType, index) in bookingTypes" :key="bookingType.id" :value="bookingType.id" :label="bookingType.name">{{ bookingType.name }}</el-option>
                </el-select>
                <div v-if="hasError && !validateBookingType" class="text-danger">Booking Type is required.</div>
              </div>
            </div>
            <div class="box-row" v-if="!showCaseInfo">
              <div class="box-col box-col-left">Type of Claim</div>
              <div class="box-col box-col-right">
                <el-select popper-class="search-specialty-popup" placeholder="Please choose type of claim..."  class="w-50" v-model="bookingRequest.claimTypeId" :class="hasError && !validateClaimType ? 'is-invalid' : ''">
                  <el-option v-for="claimType in claimTypes" :key="claimType.id" :label="claimType.name" :value="claimType.id">{{ claimType.name }}</el-option>
                </el-select>
                <div v-if="hasError && !validateClaimType" class="text-danger">Claim Type is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left" :class="isHideSpecialty ? 'disabled-element' : ''">Required Specialty, if known</div>
              <div class="box-col box-col-right">
                <el-select filterable popper-class="search-specialty-popup" placeholder="Please choose" :disabled="isHideSpecialty" :class="isHideSpecialty ? 'disabled-element' : ''" class="w-50" v-model="specialtyId" @change="changeSpecialty">
                  <el-option v-for="(specialty, index) in qualifications" :key="specialty.id" :label="specialty.name" :value="specialty.id">{{ specialty.name }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left" :class="!isHideSpecialty ? 'disabled-element' : ''">OR Choose Specialist</div>
              <div class="box-col box-col-right">
                <el-select filterable popper-class="search-specialty-popup" class="w-50" v-model="bookingRequest.specialistId" placeholder="Please choose" @change="changeSpecialist">
                  <el-option v-for="(specialist, index) in specialists" :key="specialist.id" :value="specialist.id" :label="specialist.fullName">{{ specialist.fullName }}</el-option>
                </el-select>
                <!-- <div v-if="hasError && !validateBookingRequest" class="text-danger">speciality or Specialist is required.</div> -->
              </div>
            </div>
            <div class="box-row" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left">Claimant</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditName" @click="openAddNameModal" :key="nameKey" class="hidden-opacity-element is-icon full-width-div">
                </div>
                <span v-else class="padding-left-10" :key="nameKey" @click="openAddNameModal">
                  {{ this.bookingRequest.claimantFullName }}
                </span>
                <div class="text-danger" v-if="hasError && !validateClaimantName" style="position: absolute; z-index: 1; top: 30px;">
                  Please enter claimant's name
                </div>
              </div>
              <AddNameModal ref="addNameModal"/>
            </div>
            <div class="box-row" v-if="!showCaseInfo" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left">Date of Birth</div>
              <div class="box-col box-col-right box-col-center">
                <datepicker :disabled="disableCaseField" v-model="bookingRequest.dateOfBirth" format="dd/MM/yyyy" :typeable="true" class="w-50" placeholder="dd/mm/yyyy"></datepicker>
                <div v-if="hasError && !validateDateOfBirth" class="text-danger">Date of Birth is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Affected Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  v-model="bodyAreaTag"
                  :tags="bodyAreaTags"
                  placeholder="Add Affected Body Areas"
                  :autocomplete-items="filteredBodyAreaItems"
                  @tags-changed="(newTags) => (bodyAreaTags = newTags)"
                  @focus="openDigram()"
                />
              </div>
              <div @click="openBodyDiagram" class="center-item">
                <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3"/>
              </div>
              <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Other Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                    v-model="otherBodyAreaTag"
                    :tags="otherBodyAreaTags"
                    placeholder="Add Other Body Areas"
                    @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Conditions</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  ref="conditions"
                  v-model="personConditionTag"
                  :tags="personConditionTags"
                  placeholder="Add Conditions"
                  :add-only-from-autocomplete="true"
                  :autocomplete-items="filteredPersonConditionsItems"
                  @tags-changed="updateConditions"
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Choose Region / Location</div>
              <div class="box-col box-col-right">
                <treeselect ref="treeSelect"
                  class="w-50"
                  :multiple="false"
                  :searchable="false"
                  :closeOnSelect="false"
                  :options="regionLocations"
                  :limit="7"
                  :max-height="200"
                  :defaultExpandLevel="0"
                  placeholder="Please choose ..."
                  noOptionsText="No regions"
                  noChildrenText="No cities"
                  v-model="bookingRequest.regionId"
                  @select="changeRegion" />
                <div v-if="hasError && !validateLocation" class="text-danger">Region / Location is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Specify Date Range</div>
              <div class="box-col box-col-right">
                <v-md-date-range-picker :start-date="bookingRequest.dateFrom" :end-date="bookingRequest.dateTo" @change="changeDateRange"></v-md-date-range-picker>
                <div class="text-danger" v-if="hasError && !validateDateRange">
                  Please Specify Date Range
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Face to Face or Video Link Appointments</div>
              <div class="box-col box-col-right">
                <input type="checkbox" id="checkbox" :sync="true" v-model="bookingRequest.videoLinkAccepted" />
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-heading tab">
            <div class="tab-heading px-5  notes-heading tab-selected" @click="openNotes">Notes</div>
            <div class="tab-heading px-5 files-heading" @click="openFiles">Files</div>
            <div class="col-space-between is-icon right-float-element">
              <v-btn v-if="showAddNote" class="add-icon mr-3" dark @click="openAddNoteModal">Add New</v-btn>
              <v-btn v-else class="add-icon mr-3" dark @click="openFilesBox">Add New</v-btn>
              <AddNoteModal ref="addNoteModal" :bookingProps="booking" />
            </div>
          </div>
          <div class="box-body notes-content hidden-element">
            <div class="box-row">
              <div class="box-col box-col-left">{{ bookingRequest.note != '' ? user.name : '' }}</div>
              <div class="box-col box-col-left">{{ bookingRequest.note != '' ? getDate() : '' }}</div>
              <div @click="editNote()" style="max-width: 60%" class="box-col box-col-right box-col-long col-center-content">
                {{ bookingRequest.note }}
              </div>
            </div>
          </div>
          <div class="box-body files-content hidden-element">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width:20%" class="long-text">
                        File Type
                      </th>
                      <th style="width:40%" class="long-text">
                        File Name
                      </th>
                      <th style="width:20%" class="long-text">
                        Uploaded By
                      </th>
                      <th style="width:12%" class="long-text">
                        Status
                      </th>
                      <th style="width:8%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="content-scroll-y" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                  <tbody>
                    <tr v-for="(file, index) in fileParams" :key="index" class="py-0">
                      <td style="width:20%" class="long-text">
                        <select v-model="file.FileTypeID">
                          <option value="" hidden>Select File Type</option>
                          <option v-for="fileType in fileTypes" :value="fileType.id">{{ fileType.name }}</option>
                        </select>
                      </td>
                      <td style="width:40%" class="long-text">{{file.FileName}}</td>
                      <td style="width:20%" class="long-text">{{file.UploadedByName}}</td>
                      <td style="width:12%" class="long-text">
                        <span style="padding-left: 10px;">Draft</span>
                      </td>
                      <td style="width:8%" @click="deleteFile(index)" class="long-text"><img src="@/assets/images/delete_icon.svg" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV" />
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center flex-column align-items-center">
                <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
                <div>Files over 200MB in file size may cause errors. If you need to upload files of this size, please contact us on 1300 100 733</div>
              </label>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="box-row item-right box-col-next">
          <div><v-btn dark @click="tapSubmit()">Submit</v-btn></div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import Navigation from '@/components/Navigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import Header from '@/components/Header'
  import Chat from '@/components/Chat'
  import AddNoteModal from '@/components/AddNoteModal'
  import AddNameModal from '@/components/AddNameModal'
  import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'

  export default {
    name: 'BookingRequest',
    components: {
      Navigation,
      SpecialistMenu,
      Header,
      Chat,
      AddNoteModal,
      AddNameModal,
      AddBodyDiagramModal,
    },
    data() {
      return {
        otherBodyAreaTags: [],
        otherBodyAreaTag: "",
        hasError: false,
        isHideSpecialty: false,
        clickSpecialty: false,
        clickSpecialist: false,
        clickBookingType: false,
        clickClaimType: false,
        showCaseInfo: false,
        isEditName: false,
        showAddNote: true,
        disableCaseField: false,
        pagination: utilities.initPagination(),
        booking: utilities.initBooking(),
        bookingRequest: utilities.initBookingRequest(),
        singleCase: utilities.initCase(),
        specialtyId: '',
        specialistId: '',
        bodyAreas: [],
        bodyAreaTag: '',
        bodyAreaTags: [],
        personConditions: [],
        personConditionTag: '',
        personConditionTags: [],
        specialists: [],
        qualifications: [],
        bookingTypes: [],
        regions: [],
        locations: [],
        filelist: [],
        claimTypes: [],
        regionLocations: [],
        noteKey: 0,
        nameKey: 0,
        successMessage: '',
        placeholder: 'Add',
        fileTypes: [],
        SpecialistBookingTypes: utilities.getSearchSpecialistsBookingTypes(),
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        fileParams: [],
      }
    },
    computed: {
      filteredBodyAreaItems() {
        return this.bodyAreas.filter((i) => {
          return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1
        })
      },

      filteredPersonConditionsItems() {
        return this.personConditions.filter((i) => {
          return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
        })
      },

      validateBookingType() {
        if (this.bookingRequest.bookingTypeId == '') {
          return false
        }
        return true
      },

      validateBookingRequest() {
        if (this.specialtyId == '' && this.bookingRequest.specialistId == '') {
          return false
        }
        return true
      },

      validateClaimType() {
        if (this.singleCase.id == '' && this.bookingRequest.claimTypeId == '') {
          return false
        }
        return true
      },

      validateDateOfBirth() {
        if (this.singleCase.id == '' && this.bookingRequest.dateOfBirth == '') {
          return false
        }
        return true
      },

      validateClaimantName() {
        if (this.bookingRequest.claimantFirstName == '' || this.bookingRequest.claimantLastName == '') {
          return false
        }
        return true
      },

      validateLocation() {
        if (this.bookingRequest.regionId == '') {
          return false
        }
        return true
      },

      validateDateRange() {
        if (this.bookingRequest.dateFrom == '') {
          return false
        }
        return true
      },
    },

    methods: {
      updateConditions(newTags) {
        if(this.$refs.conditions.newTag.length == 0) newTags.pop()
        this.personConditionTags = newTags
      },

      openDigram() {
        this.openBodyDiagram()
      },

      openAddNameModal() {
        if (this.disableCaseField) {
          return
        }

        if (this.isEditName) {
          this.$refs.addNameModal.editFullName(this.bookingRequest.claimantFirstName, this.bookingRequest.claimantGivenNames, this.bookingRequest.claimantLastName)
        }
        this.$refs.addNameModal.handleModal();
      },

      openBodyDiagram() {
        this.$refs.addBodyDiagramModal.handleModal()
        this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
        this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
        this.$refs.addBodyDiagramModal.handleClick()
      },

      deleteBodyAreaItems() {},

      setBodyArea(addedBodyAreas) {
        this.bodyAreaTags = []
        this.bodyAreaTags = addedBodyAreas
      },

      setName(object) {
        this.bookingRequest.claimantFirstName = object.firstName
        this.bookingRequest.claimantGivenNames = object.middleName
        this.bookingRequest.claimantLastName = object.surname
        this.bookingRequest.claimantFullName = object.firstName + (object.middleName == '' ? '' : (' '+object.middleName)) + " " + object.surname
        if (this.bookingRequest.claimantFullName != "") {
          this.isEditName = true
        }
        this.nameKey += 1
      },

      changeSpecialty(event) {
        this.bookingRequest.specialistId = ""
        this.clickSpecialist = false
      },

      changeSpecialist(event) {
        this.specialtyId = ""
        this.clickSpecialty = false
      },

      async tapSubmit() {
        if (!this.validateBookingType || !this.validateDateOfBirth || !this.validateClaimantName || !this.validateClaimType) {
          this.hasError = true
          return
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        var result = await this.postBookingRequest()
        //upload file into the booking
        if(result) {
          var params = {
            BookingRequestID: this.bookingRequest.id,
            UserID: this.user.id,
            Username: this.user.name,
            SubmittedBy: this.user.id,
            FileObject: [],
          }

          if(this.fileParams.length > 0) {
            await Promise.all(this.fileParams.map(async (x) => {
              if(this.bookingRequest.id != '') {
                var fileName = moment().unix() + '_' + x.FileName.replace(/\s/g, '_')
                var fileParams = {
                  fn: fileName,
                  ft: x.MimeType,
                  id: this.bookingRequest.id,
                }
                var result = await utilities.postRequestPreSign(fileParams)
                var postData = new FormData();

                for(var key in result.fields){
                    postData.append(key, result.fields[key]);
                }
                postData.append('file', x)
                await utilities.postImage(result.url, postData)

                params.FileObject.push({
                  FileName: fileName,
                  FileTypeID: x.FileTypeID,
                  UploadedBy: this.user.id,
                  MimeType: x.MimeType,
                  DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                  FileStatus: 0,
                  UploadedByName: this.user.name,
                  BookingRequestID: this.bookingRequest.id,
                })
              }
            }))
          }
          if(params.FileObject.length > 0) {
            var callback = (response) => {
            }
            await utilities.putBookingRequest(params, callback)
          }
          this.$notify({
            dangerouslyUseHTMLString: true,
            duration: 10000,
            message: '<div class="alert-success">' +
                "            <b>Success:</b> Thank you, we've received your request, and we'll look into it. You can track your requests in the Bookings and then Service Requests" +
                '          </div>',
          })
          this.bookingRequest = []
          this.bookingRequest = utilities.initBookingRequest()
          this.specialtyId = ''
          this.bodyAreaTags = []
          this.personConditionTags = []
          this.filelist = []
          this.fileParams = []
          this.hasError = false
          this.isEditName = false
          this.bookingRequest.dateFrom = moment().format('YYYY-MM-DD')
          this.bookingRequest.dateTo = moment().format('YYYY-MM-DD')
          this.$router.push({name: 'search-specialist'})
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      postBookingRequest() {
        var bodyAreaObjects = []
        var bodyArea = {}
        if (this.bodyAreaTags.length > 0 && !this.disableCaseField) {
          this.bodyAreaTags.map((x) => {
            bodyArea = {
              BodyAreaID: x.value,
            }
            bodyAreaObjects.push(bodyArea)
          })
        }

        var conditionObject = []
        var condition = {}
        if (this.personConditionTags.length > 0 && !this.disableCaseField) {
          this.personConditionTags.map((x) => {
            condition = {
              PersonConditionID: x.value,
            }
            conditionObject.push(condition)
          })
        }

        var qualifications = []
        var qualification = {}
        if (this.specialtyId) {
          qualification = {
            QualificationID: this.specialtyId,
          }
          qualifications.push(qualification)
        }

        var params = {
          CaseID: this.singleCase.id,
          ClientID: this.user.clientId,
          SpecialistID: this.bookingRequest.specialistId,
          BookingTypeID: this.bookingRequest.bookingTypeId,
          ClaimTypeID: this.bookingRequest.claimTypeId,
          DateFrom: moment(this.bookingRequest.dateFrom).format('YYYY-MM-DD'),
          DateTo: moment(this.bookingRequest.dateTo).format('YYYY-MM-DD'),
          VideoLinkAccepted: utilities.convertBoolToNum(this.bookingRequest.videoLinkAccepted),
          BookingRequestStatus: 1,
          ClaimantFirstName: this.bookingRequest.claimantFirstName,
          ClaimantGivenNames: this.bookingRequest.claimantMiddleName,
          ClaimantSurname: this.bookingRequest.claimantLastName,
          DateofBirth: moment(this.bookingRequest.dateOfBirth).format('YYYY-MM-DD'),
          Notes: this.bookingRequest.note,
          BodyAreaObject: bodyAreaObjects,
          ConditionObject: conditionObject,
          QualificationObject: qualifications,
          // FileObject: this.fileParams,
          UserID: this.user.id,
          Username: this.user.name,
          DateSubmitted: moment().format('YYYY-MM-DD HH:mm:ss'),
          SubmittedBy: this.user.id,
        }

        if (this.singleCase.id) {
          params.DateofBirth = this.singleCase.dateOfBirth
          params.ClaimTypeID = this.singleCase.claimTypeId
        }

        let otherBodyArea = ""
        this.otherBodyAreaTags.map((x) => {
          if (otherBodyArea == "") {
            otherBodyArea = x.text
          } else {
            otherBodyArea = otherBodyArea + "," + x.text
          }
        })
        params.BodyAreaOther = otherBodyArea

        if (this.bookingRequest.regionId != undefined && this.bookingRequest.regionId != '') {
          if (this.bookingRequest.regionId.includes('c')) {
            params.CityID = this.bookingRequest.regionId.replace('c', '')
          } else {
            params.RegionID = this.bookingRequest.regionId.replace('r', '')
          }
        }

        var callback = (response) => {
          this.bookingRequest.id = response
        }
        return utilities.postBookingRequest(params, callback)
      },

      getDate() {
        return moment().format('DD/MM/YYYY HH:mm')
      },

      openNotes() {
        this.showAddNote = true
        $('.notes-content').show()
        $('.tab-selected').removeClass('tab-selected')
        $('.notes-heading').addClass('tab-selected')
        $('.files-content').hide()
      },

      openFiles() {
        this.showAddNote = false
        $('.notes-content').hide()
        $('.tab-selected').removeClass('tab-selected')
        $('.files-heading').addClass('tab-selected')
        $('.files-content').show()
      },

      addNoteToBooking(note) {
        this.bookingRequest.note = note
        this.openNotes()
      },

      editNote() {
        this.$refs.addNoteModal.editCurrentNote(this.bookingRequest.note, true)
        this.$refs.addNoteModal.handleModal()
      },

      handleClickSpecialty() {
        this.clickSpecialty = true
        this.isHideSpecialty = false
        // this.clickSpecialist = false
        // this.bookingRequest.specialistId = ''
      },

      handleClickSpecialist() {
        this.clickSpecialist = true
        this.isHideSpecialty = true
        // this.clickSpecialty = false
        // this.specialtyId = ''
      },

      handleClickBookingType() {
        this.clickBookingType = true
      },

      changeRegion(item, e) {
        if (item.id.includes('s')) {  // Disable select state
          this.$refs.treeSelect.clear()
          var index = this.regionLocations.findIndex(x => x.id == item.id)
          if(index > -1) {
            var mouseEvent = document.createEvent("MouseEvents")
            mouseEvent.initMouseEvent("mousedown", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
            $(".vue-treeselect__option[data-id*='s'] .vue-treeselect__option-arrow-container")[index].dispatchEvent(mouseEvent)
          }
        } else {
          this.$refs.treeSelect.closeMenu()
        }
      },

      changeDateRange(item) {
        this.bookingRequest.dateFrom = utilities.formatDate(item[0])
        this.bookingRequest.dateTo = utilities.formatDate(item[1])
      },

      formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
        return utilities.formatDate(date, format, currentFormat)
      },

      getClaimType(id) {
        var type = ''
        this.claimTypes.forEach((item) => {
          if (item.id == id) {
            type = item.name
          }
        })
        return type
      },

      openAddNoteModal(e) {
        this.$refs.addNoteModal.addNoteToBooking()
        this.$refs.addNoteModal.handleModal()
      },

      openFilesBox() {
        $('#assetsFieldHandle').trigger('click')
      },

      async onChange() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.filelist = [...this.$refs.file.files]
        if(this.filelist.length > 0) {
          await Promise.all(this.filelist.map(async (x) => {
            var params = {
              FileName: x.name.replace(/\s/g, '_'),
              FileTypeID: this.fileTypes.find((x) => x.name == 'Letter of Instruction').id,
              UploadedBy: this.user.id,
              UploadedByName: this.user.name,
              MimeType: x.type,
              FileStatus: 0,
              DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
            }
            this.fileParams.push(params)
          }))
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      remove(i) {
        this.filelist.splice(i, 1)
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //   event.currentTarget.classList.remove('bg-gray-100')
        //   event.currentTarget.classList.add('bg-green-300')
        // }
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        // event.currentTarget.classList.add('bg-gray-100')
        // event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },

      async deleteNote(id, index) {
        if (confirm('Are you sure you want to delete this note?')) {
          // var temp = _.cloneDeep(this.booking.bookingNotes)
          // this.booking.bookingNotes = temp.splice(0, index).concat(temp.splice(index + 1, temp.length - 1))
        }
      },

      async getLocationData() {
        var locations = await utilities.getLocations()
        var groupedLocations = []
        locations.map((x) => {
          if (groupedLocations[x.regionId] == undefined) {
            groupedLocations[x.regionId] = []
          }
          groupedLocations[x.regionId].push(x)
        })
        var tempLocations = []
        groupedLocations.map((x) => {
          var obj = {
            id: 'r' + x[0].regionId,
            label: x[0].regionName,
          }
          var children = []
          for (var i = 0; i < x.length; i++) {
            children.push({
              id: 'c' + x[i].cityId,
              label: x[i].cityName,
            })
          }
          children = _.uniqBy(children, 'label')
          obj.children = children
          tempLocations.push(obj)
        })
        this.locations = tempLocations
      },

      setLastSearchIngo(searchLogs) {
        if (searchLogs.dateStart != '' && this.bookingRequest.dateTo != '') {
          this.bookingRequest.dateFrom = moment(searchLogs.dateStart).format('YYYY-MM-DD')
          this.bookingRequest.dateTo = moment(searchLogs.dateEnd).format('YYYY-MM-DD')
        }
        this.bookingRequest.videoLinkAccepted = searchLogs.videoLinkAccepted == 1 ? true : false
        this.bookingRequest.specialistId

        if (searchLogs.qualifications.length > 0) {
          var qualification = this.qualifications.find((x)=> x.name == searchLogs.qualifications[0].qualificationName)
          this.specialtyId = qualification.id
          this.bookingRequest.specialistId = ""
          this.clickSpecialty = true
          this.clickSpecialist = false
        } else {
          this.clickSpecialist = true
          this.clickSpecialty = false
          this.bookingRequest.specialistId = searchLogs.specialistId
          this.specialtyId = ""
        }

        if (this.specialistId != '') {
          this.specialtyId = ""
          this.bookingRequest.specialistId = this.specialistId
          this.clickSpecialist = true
        }

        if (searchLogs.cities.length > 0) {
          var city = this.locations.find((x)=> x.cityName == searchLogs.cities[0].cityName)
          var region = this.regionLocations.find((x) => x.id == 'r'+city.regionId)
          if (region != undefined) {
            var selectedCity = region.children.find((x) => x.id == 'c'+city.cityId)
            if (selectedCity != undefined) {
              this.$refs.treeSelect.select(selectedCity)
            }
          }
        }

        var typeName = this.SpecialistBookingTypes.find((x) => x.id == searchLogs.specialistServiceName).name
        var bookingTypeId = this.bookingTypes.find((x) => x.name == typeName).id
        this.bookingRequest.bookingTypeId = bookingTypeId
        this.clickBookingType = true
      },

      showClientBookingTypes() {
        var temp = []
        this.bookingTypes.map((x) =>{
          if (x.bookingTypeStatus == '1' && (x.name !== "FCE Lite" && x.name !== "FCE Lite by Video" && x.name !== "Court case" && x.name !== "Phone Call" &&  x.name !== "Supplementary" &&  x.name !== "Supplementary by Video" &&  x.name !== "Independent Medical Examination - Abuse")) {
            temp.push(x)
          }
        })
        temp.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })

        this.bookingTypes = temp
      },

      setCaseInfo(){
        this.bookingRequest.claimantFirstName = this.singleCase.claimantFirstName
        this.bookingRequest.claimantGivenNames = this.singleCase.claimantGivenNames
        this.bookingRequest.claimantLastName = this.singleCase.claimantSurname
        this.bookingRequest.claimantFullName = this.singleCase.claimantFullName
        if (this.bookingRequest.claimantFullName != "") {
          this.isEditName = true
        }

        // if (this.singleCase.bodyAreas.length > 0) {
        //   this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(this.singleCase.bodyAreas)
        // }
        //
        // if (this.singleCase.personCondition.length > 0) {
        //   this.personConditionTags = utilities.getAutoCompleteTags(this.singleCase.personCondition)
        // }

        this.disableCaseField = true
      },

      async loadData(caseId) {
        this.$store.dispatch('loading/setLoadComponent', true)
        if (caseId != '') {
          this.singleCase = await utilities.getCaseByCaseId(caseId)
          this.showCaseInfo = true
          this.setCaseInfo()
        } else {
          this.showCaseInfo = false
        }
        const apiArr = [utilities.getBodyArea(), utilities.getPersonConditions(),utilities.getSpecialists(),utilities.getQualifications(),utilities.getRegions(),utilities.getLocations(),utilities.getBookingTypes(),utilities.getClaimTypes()]
        const res = await Promise.all(apiArr)
        let bodyAreas = res[0]
        this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)
        let personConditions = res[1]
        this.personConditions = utilities.getAutoCompleteTags(personConditions)
        this.specialists = res[2]
        this.qualifications = res[3]
        this.regions = res[4]
        this.locations = res[5]
        this.regionLocations = utilities.getRegionLocations(this.regions, this.locations)
        this.bookingTypes = res[6]
        this.showClientBookingTypes()
        this.claimTypes = res[7]

        var params = {
          PageNum: 1,
          PageSize: 1,
        }
        this.searchLogs = await utilities.getSearchLogsNew(params)
        let isEmpty = this.$route.query.isEmpty != undefined ? this.$route.query.isEmpty : false
        if (this.searchLogs[0] != undefined && !isEmpty) {
          this.setLastSearchIngo(this.searchLogs[0])
        }

        var types = await utilities.getFileTypes()
        types.map((x) => {
          if (x.name == 'Brief' || x.name == 'Letter of Instruction' || x.name == 'Other') {
            this.fileTypes.push(x)
          }
        })
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      deleteFile(index) {
        this.fileParams.splice(index, 1)
      }
    },

    async beforeMount() {
      this.bookingRequest.dateFrom = moment().format('YYYY-MM-DD')
      this.bookingRequest.dateTo = moment().format('YYYY-MM-DD')

      this.singleCase.id = this.$route.query.id != undefined ? this.$route.query.id : ''
      this.specialistId = this.$route.query.spId != undefined ? this.$route.query.spId : ''
      await this.loadData(this.singleCase.id)
      this.booking.bookingNotes.push(utilities.initBookingNote())
    },

    mounted() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      $('[data-toggle="tooltip"]').tooltip()
    },
  }
</script>
