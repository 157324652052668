/* eslint-disable */
<template>
  <div class="admin-locations">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Cities in  {{ regonName }}</h1></div>
        <div class="header-sub-title">Click to edit the name or move a city to a different region.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="3" />
      <div class="content">
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:20%" class="long-text">City Name</th>
                    <th style="width:65%" class="long-text"></th>
                    <th style="width:15%;vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-end">
                        <!-- <div class="icon-group">
                          <v-btn class="add-icon" @click="createLocation()" dark>Add New</v-btn>
                        </div> -->
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- <div :class="locations.length > 5 ? 'content-scroll-y' : ''"> -->
            <div>
              <table class="table" v-if="cities.length > 0">
                <tbody>
                  <tr v-for="(city, index) in cities">
                    <td style="width:20%" class="long-text">{{ city.name }}</td>
                    <td style="width:65%" class="long-text"></td>
                    <td style="width:15%; vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-end mr-2">
                        <img @click="openEditLocation(index)" class="ml-5" src="@/assets/images/edit_icon.svg" />
                      </div>
                    </td>
                  </tr>
                  <ManageCityModal ref="manageCityModal" />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSettingsMenu from '@/components/AdminSettingsMenu'
  import Header from '@/components/Header'
  import ManageCityModal from '@/components/ManageCityModal'

  export default {
    name: 'AdminLocations',
    components: {
      AdminNavigation,
      AdminSettingsMenu,
      Header,
      ManageCityModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        cities: [],
        limit: 10,
        pagination: utilities.initPagination(),

        selectedRegion: utilities.initRegion(),

        errorMessage: '',
        successMessage: '',
        regonName: '',
        regions: [],
      }
    },
    methods: {
      async loadData() {},
      createLocation() {
        this.$router.push({ name: 'admin-manage-location'}).catch((err) => {})
      },

      openEditLocation(index) {
        var item = this.cities[index]
        this.$refs.manageCityModal.index = index
        this.$refs.manageCityModal.setCity(item)
        this.$refs.manageCityModal.regions = this.regions
        this.$refs.manageCityModal.handleModal()
      },

      updateCity() {
        this.filterCities(this.cities)
        this.successMessage = "City Updated successfully."
        utilities.showAlert(".alert-success")
      },

      paging(cities) {
        if (cities.length == 0 || cities.length <= this.limit) {
          this.cities = cities
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(cities, this.limit)
          this.cities = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
      },
      selectPage(index) {
        this.pagination.current = index
        this.cities = this.pagination.list[this.pagination.current]
      },

      filterCities(list) {
        var filterCities =  list.filter((x) => x.regionId == this.regionId)
        this.paging(filterCities)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.regionId = this.$route.query.regionId != undefined ? this.$route.query.regionId : ''
      this.regions = await utilities.getRegions()
      var region = this.regions.find((x) => x.id == Number(this.regionId))
      if(region) {
        this.regonName = region.name
      }

      var cities = await utilities.getCities()
      this.filterCities(cities)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
