/* eslint-disable */
<template>
  <div class="home">
    <Navigation indexProps="0"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Upcoming Bookings & Bookings In Progress</h1></div>
        <div class="header-sub-title">To get started search for <a href="/search-specialist" class="text-underline">specialist
          availability</a> to book an appointment.
        </div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-between">
            <v-btn @click="getUrl" dark>Download Referral Network</v-btn>
            <div class="d-flex justify-content-end">
              <el-input style="max-width: 115px" class="mr-1" @input="search()" clearable v-model="searchBookingId"
                        placeholder="Booking ID"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable v-model="searchClaimantName"
                        placeholder="Claimant Name"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable v-model="searchSpecialist"
                        placeholder="Specialist Name"/>
            </div>
          </div>
        </div>
        <div :class="upcomingBookings.length == 0 ? 'min-height-table' : ''">
          <div class="row">
            <div class="col-2 pl-0">
              <div class="border-image ml-0">
                <div class="border-image-number">{{ totalUpcoming }}</div>
                <div class="border-image-text">Upcoming</div>
              </div>
            </div>
            <div class="col">&nbsp;</div>
            <div class="col">&nbsp;</div>
          </div>
          <div class="table-responsive">
            <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'booingDate', order: 'ascending'}" stripe @sort-change="sortParam" :data="upcomingBookings" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
              <el-table-column
                  width="100"
                  prop="id"
                  label="ID"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="120"
                  prop="claimantFullName"
                  label="Claimant"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="150"
                  prop="booingDate"
                  label="Appointment"
                  sortable="custom"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  show-overflow-tooltip
                  prop="specialistFullName"
                  label="Specialist"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="locationName"
                  label="Location"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="bookingTypeName"
                  label="Booking Type"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  v-if="user.type >= 2"
                  width="150"
                  show-overflow-tooltip
                  prop="contactFullName"
                  label="Client Contact"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  width="100"
                  show-overflow-tooltip
                  prop="bookingStatusName"
                  label="Status"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  width="150"
                  show-overflow-tooltip
                  prop="paperworkDue"
                  label="Paperwork Due"
                  sortable="custom"
              >
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.paperworkDue, 'DD/MM/YYYY') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  show-overflow-tooltip
                  prop="status"
                  label="Claimant Confirmed?"
              >
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.claimantConfirmed, 'DD/MM/YYYY') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="totalUpcoming > upcomingParam.PageSize" class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ upcomingParam.PageNum }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" v-for="item in (1, Math.ceil(totalUpcoming/upcomingParam.PageSize))"
                       :key="item" :class="upcomingParam.PageNum === item ? 'selected' : ''" :value="item"
                       @click="selectPage(item, 0)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ Math.ceil(totalUpcoming / upcomingParam.PageSize) }}</div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-2 pl-0">
              <div class="border-image ml-0">
                <div class="border-image-number">{{ totalProgress }}</div>
                <div class="border-image-text">In Progress</div>
              </div>
            </div>
            <div class="col">&nbsp;</div>
            <div class="col">&nbsp;</div>
          </div>
          <div class="table-responsive">
            <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'booingDate', order: 'ascending'}" stripe @sort-change="sortParam1" :data="bookingsProgress" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
              <el-table-column
                  width="100"
                  prop="id"
                  label="ID"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="120"
                  prop="claimantFullName"
                  label="Claimant"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="170"
                  prop="booingDate"
                  label="Appointment"
                  sortable="custom"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                </template>
              </el-table-column>

              <el-table-column
                  width="180"
                  show-overflow-tooltip
                  prop="specialistFullName"
                  label="Specialist"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="locationName"
                  label="Location"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="bookingTypeName"
                  label="Booking Type"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  v-if="user.type >= 2"
                  width="150"
                  show-overflow-tooltip
                  prop="contactFullName"
                  label="Client Contact"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  width="100"
                  show-overflow-tooltip
                  prop="bookingStatusName"
                  label="Status"
                  sortable="custom"
              >
              </el-table-column>
            </el-table>
          </div>
          <div v-if="totalProgress > inProgressParam.PageSize" class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ inProgressParam.PageNum }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" v-for="item in (1, Math.ceil(totalProgress/inProgressParam.PageSize))"
                       :key="item" :class="inProgressParam.PageNum === item ? 'selected' : ''" :value="item"
                       @click="selectPage(item, 1)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ Math.ceil(totalProgress / inProgressParam.PageSize) }}</div>
          </div>
        </div>


        <div>
          <div class="row">
            <div class="col-2 pl-0">
              <div class="border-image ml-0">
                <div class="border-image-number">{{ totalFinalised }}</div>
                <div class="border-image-text">Finalised</div>
              </div>
            </div>
            <div class="col">&nbsp;</div>
            <div class="col">&nbsp;</div>
          </div>
          <div class="table-responsive">
            <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'booingDate', order: 'ascending'}" stripe @sort-change="sortParam2" :data="finalisedbookings" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
              <el-table-column
                  width="100"
                  prop="id"
                  label="ID"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="120"
                  prop="claimantFullName"
                  label="Claimant"
                  sortable="custom"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="170"
                  prop="booingDate"
                  label="Appointment"
                  sortable="custom"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="180"
                  show-overflow-tooltip
                  prop="specialistFullName"
                  label="Specialist"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="locationName"
                  label="Location"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="bookingTypeName"
                  label="Booking Type"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  v-if="user.type >= 2"
                  width="150"
                  show-overflow-tooltip
                  prop="contactFullName"
                  label="Client Contact"
                  sortable="custom"
              >
              </el-table-column>
              <el-table-column
                  width="100"
                  show-overflow-tooltip
                  prop="bookingStatusName"
                  label="Status"
                  sortable="custom"
              >
              </el-table-column>
            </el-table>
          </div>
          <div v-if="totalFinalised > finalizeParam.PageSize" class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ finalizeParam.PageNum }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" v-for="item in (1, Math.ceil(totalFinalised/finalizeParam.PageSize))"
                       :key="item" :class="finalizeParam.PageNum === item ? 'selected' : ''" :value="item"
                       @click="selectPage(item, 2)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ Math.ceil(totalFinalised / finalizeParam.PageSize) }}</div>
          </div>
        </div>

      </div>
    </div>
    <Chat/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import {debounce} from 'lodash';

export default {
  name: 'Home',
  components: {
    Navigation,
    Header,
    Chat,
  },
  data() {
    return {
      limit: 12,
      totalUpcoming: 0,
      totalProgress: 0,
      totalFinalised: 0,
      allUpcomingBookings: [],
      upcomingBookings: [],
      allBookingsProgress: [],
      bookingsProgress: [],
      allFinalisedBookings: [],
      finalisedbookings: [],
      bookingTypes: [],
      upcomingParam: {
        PageSize: 10,
        PageNum: 1,
        FromDate: window.moment().format('YYYY-MM-DD'),
        SpecialistName: '',
        ClaimantName: '',
        BookingID: null,
        SortFlag: 15,
        Status: '3,4,7,9,10',
      },
      inProgressParam: {
        PageSize: 10,
        PageNum: 1,
        Status: '5,9,10',
        SpecialistName: '',
        ClaimantName: '',
        BookingID: null,
        SortFlag: 15,
      },
      finalizeParam: {
        PageSize: 10,
        PageNum: 1,
        Status: '1,11,12,13,14',
        SpecialistName: '',
        ClaimantName: '',
        BookingID: null,
        SortFlag: 15,
      },
      pagination: utilities.initPagination(),
      paginationUp: utilities.initPagination(),
      paginationFinalised: utilities.initPagination(),
      bookingStatuses: utilities.getBookingStatuses(),
      paperworkStatuses: utilities.getPaperworkStatuses(),
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      byDate: true,
      byPaperwork: false,
      byPaperworkDue: false,
      byReportDue: false,
      byStatus: false,
      byStatus1: false,
      byDateTime: true,
      maxDate: '',
      searchBookingId: '',
      searchClaimantName: '',
      searchSpecialist: '',
    }
  },

  methods: {
    sortParam( v ) {
      if(v.prop === 'booingDate' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 15
      } else if (v.prop === 'booingDate' && v.order === "descending") {
        this.upcomingParam.SortFlag = 16
      } else if(v.prop === 'clientName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 1
      } else if (v.prop === 'clientName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 2
      } else if(v.prop === 'contactFullName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 3
      } else if (v.prop === 'contactFullName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 4
      } else if(v.prop === 'specialistFullName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 9
      } else if (v.prop === 'specialistFullName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 10
      } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 5
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 6
      } else  if(v.prop === 'id' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 19
      } else if (v.prop === 'id' && v.order === "descending") {
        this.upcomingParam.SortFlag = 20
      }else if(v.prop === 'paperworkDue' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 31
      } else if (v.prop === 'paperworkDue' && v.order === "descending") {
        this.upcomingParam.SortFlag = 32
      } else if (v.prop === 'bookingTypeName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 29
      }else if (v.prop === 'bookingTypeName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 30
      } else if(v.prop === 'bookingStatusName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 17
      } else if (v.prop === 'bookingStatusName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 18
      } else if (v.prop === 'locationName' && v.order === "ascending") {
        this.upcomingParam.SortFlag = 33
      } else if (v.prop === 'locationName' && v.order === "descending") {
        this.upcomingParam.SortFlag = 34
      }
      this.upcomingParam.PageNum = 1
      this.sortUpcoming()
    },
    sortParam1(v) {
      if(v.prop === 'booingDate' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 15
      } else if (v.prop === 'booingDate' && v.order === "descending") {
        this.inProgressParam.SortFlag = 16
      } else if(v.prop === 'clientName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 1
      } else if (v.prop === 'clientName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 2
      } else if(v.prop === 'contactFullName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 3
      } else if (v.prop === 'contactFullName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 4
      } else if(v.prop === 'specialistFullName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 9
      } else if (v.prop === 'specialistFullName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 10
      } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 5
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 6
      } else  if(v.prop === 'id' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 19
      } else if (v.prop === 'id' && v.order === "descending") {
        this.inProgressParam.SortFlag = 20
      }else if (v.prop === 'bookingTypeName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 29
      }else if (v.prop === 'bookingTypeName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 30
      }else if(v.prop === 'bookingStatusName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 17
      } else if (v.prop === 'bookingStatusName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 18
      } else if (v.prop === 'locationName' && v.order === "ascending") {
        this.inProgressParam.SortFlag = 33
      } else if (v.prop === 'locationName' && v.order === "descending") {
        this.inProgressParam.SortFlag = 34
      }
      this.inProgressParam.PageNum = 1
      this.sortProgress()
    },
    sortParam2(v) {
      if(v.prop === 'booingDate' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 15
      } else if (v.prop === 'booingDate' && v.order === "descending") {
        this.finalizeParam.SortFlag = 16
      } else if(v.prop === 'clientName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 1
      } else if (v.prop === 'clientName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 2
      } else if(v.prop === 'contactFullName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 3
      } else if (v.prop === 'contactFullName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 4
      } else if(v.prop === 'specialistFullName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 9
      } else if (v.prop === 'specialistFullName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 10
      } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 5
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 6
      } else  if(v.prop === 'id' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 19
      } else if (v.prop === 'id' && v.order === "descending") {
        this.finalizeParam.SortFlag = 20
      }else if (v.prop === 'bookingTypeName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 29
      }else if (v.prop === 'bookingTypeName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 30
      }else if(v.prop === 'bookingStatusName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 17
      } else if (v.prop === 'bookingStatusName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 18
      } else if (v.prop === 'locationName' && v.order === "ascending") {
        this.finalizeParam.SortFlag = 33
      } else if (v.prop === 'locationName' && v.order === "descending") {
        this.finalizeParam.SortFlag = 34
      }
      this.finalizeParam.PageNum = 1
      this.sortFinalize()
    },
    RowClick(row) {
      this.editBooking(row?.id)
    },
    async getUrl() {
      this.$store.dispatch("loading/setLoadComponent", true)
      var blob = await utilities.getSpecialistProfiles()
      const code = blob.replace(/[\n\r]/g, '')
      const raw = window.atob(code)
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      const blobItem = new Blob([uInt8Array], {type: 'application/pdf'})
      const blobURL = window.URL.createObjectURL(blobItem)
      let a = document.createElement('a')
      a.setAttribute('href', blobURL)
      a.setAttribute('download', 'Red Health Referral Network.pdf')
      a.click()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    getBookingStatus(id) {
      return utilities.getBookingStatus(id)
    },
    search: debounce(function () {
      this.upcomingParam.BookingID = this.searchBookingId
      this.upcomingParam.SpecialistName = this.searchSpecialist
      this.upcomingParam.ClaimantName = this.searchClaimantName
      this.upcomingParam.PageNum = 1

      this.inProgressParam.BookingID = this.searchBookingId
      this.inProgressParam.SpecialistName = this.searchSpecialist
      this.inProgressParam.ClaimantName = this.searchClaimantName
      this.inProgressParam.PageNum = 1

      this.finalizeParam.BookingID = this.searchBookingId
      this.finalizeParam.SpecialistName = this.searchSpecialist
      this.finalizeParam.ClaimantName = this.searchClaimantName
      this.finalizeParam.PageNum = 1

      this.loadData()
    }, 800),

    setOriginalData() {
      this.bookingsProgress = _.cloneDeep(this.allBookingsProgress)
      this.upcomingBookings = _.cloneDeep(this.allUpcomingBookings)
      this.finalisedbookings = _.cloneDeep(this.allFinalisedBookings)
      this.setBookingInProgressPagination()
      this.setUpcomingPagination()
    },

    selectPage(number, index) {
      if (index === 0) {
        this.upcomingParam.PageNum = number
        this.sortUpcoming()
      } else if (index === 1) {
        this.inProgressParam.PageNum = number
        this.sortProgress()
      } else {
        this.finalizeParam.PageNum = number
        this.sortFinalize()
      }
    },

    selectFinalisedPage(index) {
      this.paginationFinalised.current = index
      this.finalisedbookings = this.paginationFinalised.list[this.paginationFinalised.current]
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date && date.length > 0 && date != '0000-00-00') {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ""
    },

    async sortUpcoming() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {bookings, total} = await utilities.getAllBookingsNew(this.upcomingParam)
      this.upcomingBookings = bookings;
      this.totalUpcoming = total
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async sortProgress() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {bookings, total} = await utilities.getAllBookingsNew(this.inProgressParam)
      this.bookingsProgress = bookings;
      this.totalProgress = total
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    async sortFinalize() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {bookings, total} = await utilities.getAllBookingsNew(this.finalizeParam)
      this.finalisedbookings = bookings;
      this.totalFinalised = total
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    sortByPaperworkDue(status) {
      var date1 = null
      var date2 = null
      this.allUpcomingBookings.sort((a, b) => {
        if (a.paperworkDue != '') {
          date1 = moment(a.paperworkDue)
        } else {
          date1 = moment(this.maxDate)
        }
        if (b.paperworkDue != '') {
          date2 = moment(b.paperworkDue)
        } else {
          date2 = moment(this.maxDate)
        }

        if (this.byPaperworkDue) {
          // ASC
          return date1.isAfter(date2) ? 1 : -1
        } else {
          // DESC
          return date1.isBefore(date2) ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings
      this.setUpcomingPagination()
      this.byPaperworkDue = !this.byPaperworkDue
    },

    sortByReportDue(date) {
      this.allBookingsProgress.sort((a, b) => {
        if (this.byReportDue) {  // ASC
          return a.dueDate > b.dueDate ? 1 : -1
        } else { // DESC
          return a.dueDate < b.dueDate ? 1 : -1
        }
      })
      this.bookingsProgress = this.allBookingsProgress
      this.setBookingInProgressPagination()
      this.byReportDue = !this.byReportDue
    },

    sortByAppoinmentDate(date) {
      this.bookingsProgress.sort((a, b) => {
        if (this.byDateTime) {  // ASC
          return a.dueDate > b.dueDate ? 1 : -1
        } else { // DESC
          return a.dueDate < b.dueDate ? 1 : -1
        }
      })
      this.byReportDue = !this.byReportDue
    },

    sortByStatusFirst(status) {
      this.allUpcomingBookings.sort((a, b) => {
        if (this.byStatus1) {  // ASC
          return a.status > b.status ? 1 : -1
        } else { // DESC
          return a.status < b.status ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings
      this.setUpcomingPagination()
      this.byStatus1 = !this.byStatus1
    },

    sortByStatus(status) {
      this.allBookingsProgress.sort((a, b) => {
        if (this.byStatus) {  // ASC
          return a.status > b.status ? 1 : -1
        } else { // DESC
          return a.status < b.status ? 1 : -1
        }
      })
      this.bookingsProgress = this.allBookingsProgress
      this.setBookingInProgressPagination()
      this.byStatus = !this.byStatus
    },

    editBooking(bookingId) {
      this.$router.push({path: '/edit-booking', query: {id: bookingId}}).catch((err) => {
      })
    },

    getBookingStatusById(id) {
      if (id) {
        var status = this.bookingStatuses.find((x) => x.id == id)
        if (status) {
          return status.name
        }
      }
      return ""
    },

    getPaperworkStatusById(id) {
      if (id) {
        var paperwork = this.paperworkStatuses.find((x) => x.id == id)
        if (paperwork) {
          return paperwork.name
        }

      }
      return ""
    },

    setUpcomingPagination() {
      if (this.upcomingBookings.length > this.limit) {
        this.paginationUp = utilities.loadPagination(this.upcomingBookings, this.limit)
        this.upcomingBookings = this.paginationUp.list[this.paginationUp.current]
        $(".paginationUpcoming").show()
      } else {
        $(".paginationUpcoming").hide()
      }
    },

    setBookingInProgressPagination() {
      if (this.bookingsProgress.length > this.limit) {
        this.pagination = utilities.loadPagination(this.bookingsProgress, this.limit)
        this.bookingsProgress = this.pagination.list[this.pagination.current]
        $(".paginationProgress").show()
      } else {
        $(".paginationProgress").hide()
      }
    },

    async loadData() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const apiArr = [utilities.getAllBookingsNew(this.upcomingParam),
        utilities.getAllBookingsNew(this.inProgressParam),
        utilities.getAllBookingsNew(this.finalizeParam)]
      const r = await Promise.all(apiArr)
      this.upcomingBookings = r[0].bookings
      this.totalUpcoming = r[0].total

      this.bookingsProgress = r[1].bookings
      this.totalProgress = r[1].total

      this.finalisedbookings = r[2].bookings
      this.totalFinalised = r[2].total
      this.$store.dispatch('loading/setLoadComponent', false)
    }
  },

  async beforeMount() {
    await this.loadData()
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
    $(".pagination").hide()
    $(".paginationProgress").hide()
    $(".paginationFinalised").hide()
    $(".clear").on("search", () => {
      this.setOriginalData()
    })
  },
}
</script>
