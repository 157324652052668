<template>
  <div class="forgot-password-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Verification Code</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col">
                  <p style="line-height:1.5">A verification code has been sent to your registered email address. Please provide the code and your new password below.</p>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  Verification Code
                </div>
                <div class="box-col box-col-right">
                  <input type="text" :class="hasError && (!validateCode || !correctCode) ? 'is-invalid' : ''" v-model="code" placeholder="Verification Code" />
                  <div class="text-danger" v-if="hasError && (!validateCode || !correctCode)">
                    Please enter the verification code
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  New password
                </div>
                <div class="box-col box-col-right">
                  <input type="password" :class="hasError && !validatePassword ? 'is-invalid' : ''" v-model="password" placeholder="New Password" />
                  <div class="text-danger" v-if="hasError && !validatePassword">
                    Your password must be at least 8 characters including a lowercase letter, an uppercase letter, a number, and a special character (@$!#_%*?&)
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="clickSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import auth from '@/assets/js/cognitoAuth'

  export default {
    name: 'ForgotPasswordModal',
    props: [],
    data() {
      return {
        loadModal: false,
        user: {},
        hasError: false,
        code: '',
        password: '',
        correctCode: true,
      }
    },

    computed: {
      validateCode() {
        return this.code !== ''
      },
      validatePassword() {
        var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$_!#%*?&])[A-Za-z\d@$!#_%*?&]{8,}$/
        if (!re.test(this.password)) return false
        return true
      },
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      async clickSave() {
        this.hasError = false
        if (!this.validatePassword) {
          this.hasError = true
          return
        }
        auth.confirmNewPassword(this.$parent.email, this.code, this.password).then((res) => {
            if(res) {
                this.$parent.successMessage = "Your password has been reset successfully!"
                this.$parent.success()
                this.handleModal()
            } else {
                this.correctCode = false
                this.hasError = true
            }
        })
      },
    },

    async beforeMount() {},

    mounted() {},
  }
</script>
