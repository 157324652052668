/* eslint-disable */
<template>
  <div class="admin-manage-certifications">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Add / Edit Accreditations/Certifications</h1></div>
        <div class="header-sub-title">The set of accreditations and certifications shown below are attached to particular specialists and will then appear in the specialist profile.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="9" />
      <div class="content">
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:42.5%" class="long-text">Id</th>
                    <th style="width:42.5%" class="long-text">Name</th>
                    <th style="width:15%; vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center"><v-btn class="add-icon" dark @click="addNewCertification">Add New</v-btn></div>
                      <AddCertificateModal ref="addCertificateModal" />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- <div :class="specialists.length > 5 ? 'content-scroll-y' : ''"> -->
            <div>
              <table class="table">
                <tbody>
                  <template v-for="(certification, index) in certifications">
                    <tr :key="index">
                    <td style="width:42.5%" class="long-text">
                      {{certification.id}}
                    </td>
                    <td style="width:42.5%" class="long-text">
                      {{certification.name}}
                    </td>
                    <td style="width:15%;vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center" style="height:100%">
                        <img @click="editCertification(index)" src="@/assets/images/edit_icon.svg" />
                      </div>
                    </td>
                    </tr>
                    <tr v-if="hasError && relatedSkill.hasError" class="text-danger" :key="indexOuter+500"><td colspan="3">This relationship already exists. Please ensure there are no duplicates and try again.</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSettingsMenu from '@/components/AdminSettingsMenu'
  import AddCertificateModal from '@/components/AddCertificateModal'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'AdminManageCertifications',
    components: {
      AdminNavigation,
      AdminSettingsMenu,
      AddCertificateModal,
      Header,
    },
    data() {
      return {
        hasError: false,
        certifications: [],

        successMessage: '',
      }
    },
    computed: {},
    methods: {
      async loadData() {
        this.certifications = await utilities.getCertifications()
      },

      addNewCertification() {
        this.$refs.addCertificateModal.isEdit = false
        this.$refs.addCertificateModal.handleModal()
      },

      editCertification(index) {
        var item = this.certifications[index]
        this.$refs.addCertificateModal.isEdit = true
        this.$refs.addCertificateModal.editItem(item)
        this.$refs.addCertificateModal.handleModal()

      }
    },
    async beforeMount() {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.loadData()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    mounted() {
      this.loadData()
    },
  }
</script>
