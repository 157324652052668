<template>
  <div class="search-case-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Case Search {{ client.id != '' ? ' - ' + client.name : '' }}</h1>
            </div>
            <button
              type="button"
              class="close-icon"
              @click="handleModal($event)"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="row">
                <div v-if="client.id == ''" class="col-12 col-md-6">
                  <label>Client</label>
                  <select class="textbox" v-model="searchOption.clientId" @change="changeClient">
                      <option value="">Please choose ...</option>
                      <option v-for="(client, index) in clients" :value="client.id">{{ client.name + " - " +  client.branchName }}</option>
                  </select>
                </div>
                <div class="col-12 col-md-6">
                  <label>Reference Number</label>
                  <input type="text" class="textbox" v-model="searchOption.referenceNumber" placeholder="Please enter search text" />
                </div>
                <div class="col-12 col-md-4">
                  <label>Claimant First Name</label>
                  <input type="text" class="textbox claimant-name" v-model="searchOption.claimantFirstname" placeholder="Please enter part of the Claimant first name"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <label>Claimant Surname</label>
                  <input type="text" class="textbox claimant-name" v-model="searchOption.claimantSurname" placeholder="Please enter part of the Claimant surname"
                  />
                </div>
                <div class="col-12 col-md-4">
                    <label>Case Owner</label>
                    <select :disabled="searchOption.clientId == '' ? true : false" class="textbox" :class="searchOption.clientId == '' ? 'disabled-element' : ''" v-model="searchOption.contactUserId">
                        <option value="">Please choose ...</option>
                        <option v-for="(contact, index) in searchOption.contacts" :key="index" :value="contact.fullName">{{ contact.fullName }}</option>
                    </select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn
                      class="white-button"
                      @click="handleModal($event)"
                      dark>Cancel</v-btn>
                    <v-btn dark @click="search">Search</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminSearchCaseModal',
    props: ['clientIdProps'],
    data() {
      return {
        loadModal: false,

        fromGlobalSearch: false,
        client: utilities.initClient(),
        searchOption: this.initSearchOption(),

        clients: [],
        contacts: [],
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
          setTimeout(() => {
            $('.search-case-modal form').on('keypress', (e) => {
              if (e.keyCode == 13) {
                this.search()
              }
            })
            $('.search-case-modal .claimant-name').focus()
          }, 500)
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      initSearchOption() {
        return {
          clientId: "",
          referenceNumber: "",
          claimantFirstName: "",
          claimantSurname: "",
          contactUserId: "",
          contacts: [],
        }
      },
      resetSearchOption() {
        this.searchOption = this.initSearchOption()
        this.client = utilities.initClient()
        this.contacts = []
      },
      async changeClient() {
        this.$store.dispatch("loading/setLoadComponent", true)
        if(this.searchOption.clientId != '') {
          this.searchOption.contacts = await utilities.getClientContacts(this.searchOption.clientId)
        } else {
          this.searchOption.contacts = []
        }
        this.searchOption.contactUserId = ""
        this.$store.dispatch("loading/setLoadComponent", false)
      },
      async search() {
        if(this.fromGlobalSearch) {
          if(this.searchOption.clientId == '' && this.searchOption.referenceNumber == '' && this.searchOption.claimantSurname == '' && this.searchOption.claimantFirstname == '' && this.searchOption.contactUserId == '') {
            return false
          }
        } else {
          if(this.searchOption.referenceNumber == '' && this.searchOption.claimantSurname == '' && this.searchOption.claimantFirstname == ''  && this.searchOption.contactUserId == '') {
            return false
          }
        }

        this.$store.dispatch("loading/setLoadComponent", true)
        if(this.fromGlobalSearch) {
          if(this.$route.name === 'admin-cases') {
            this.$parent.$parent.$parent.searchCase && await this.$parent.$parent.$parent.searchCase(this.searchOption)
          } else {
            await utilities.delay(1000)
            this.$router.push({ name: 'admin-cases', params: { searchOptionProps: this.searchOption } }).catch((err) => {})
          }
        } else {
          await this.$parent.searchCase(this.searchOption)
        }
        this.handleModal()
        this.$store.dispatch("loading/setLoadComponent", false)
      },

      async loadData() {
        if(this.clientIdProps) {
          this.searchOption.clientId = this.clientIdProps
          this.client = await utilities.getClientById(this.searchOption.clientId)
          this.searchOption.contacts = await utilities.getClientContacts(this.searchOption.clientId)
        } else {
          this.fromGlobalSearch = true
        }
      },
    },
    async beforeMount() {
      await this.loadData()
    },
    mounted() {},
  }
</script>
