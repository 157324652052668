<template>
  <div class="select-client-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Select a client to add new contact</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Client</label>
                    <select class="textbox" v-model="clientId">
                      <option hidden value="">Select ...</option>
                      <option v-for="(client, id) in clients" :value="client.id">{{ client.fullName }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="select">Select</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AdminSelectClientModal",
  data() {
    return {
      loadModal: false,

      clientId: "",
      clients: [],
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
    select() {
      if(this.clientId == '') {
        return false
      }

      if(this.$route.fullPath.includes("/admin-manage-client")) {
        this.handleModal()
        this.$parent.openManageContactModal()
      } else {
        this.$router.push({ name: "admin-manage-client", query: { id: this.clientId, type: "add-contact" } }).catch((err) => {})
      }
    },
  },
  beforeMount() {},
  mounted() {},
}
</script>
