/* eslint-disable */
<template>
  <div class="booking-requests">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Booking Requests</h1></div>
        <div class="header-sub-title">Booking Requests, including Supplementary Requests submitted by, or on behalf of, a Client, for action by Red Health.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu indexProps="1" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input clearable class="search-input w-15 filter-icon right-float-element mr-5 clear" placeholder="Assigned to" @input="search()" v-model="listParam.AssignedToName" />
            <el-input clearable class="search-input w-15 filter-icon right-float-element mr-5 clear" placeholder="Specialist" @input="search()" v-model="listParam.SpecialistName" />
            <el-input clearable class="search-input w-15 filter-icon right-float-element mr-5 clear" placeholder="Client" @input="search()" v-model="listParam.ClientName" />
            <div class="icon-group right-float-element">
              <router-link :to="{ name: 'admin-manage-booking-request', query: {} }">
                <v-btn class="add-icon" dark>Add New</v-btn>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'dateSubmitted', order: 'descending'}" stripe @sort-change="sortParam" :data="bookingRequests" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    width="180"
                    prop="id"
                    label="Requesting Client"
                    show-overflow-tooltip
                >
                  <template slot-scope="booking">
                    <span>{{ (booking.row.userName !== '' ? booking.row.userName + ', ' : '') + booking.row.clientName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    prop="requirements"
                    label="Requirements"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    prop="claimantFullName"
                    label="Claimant Name"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    width="120"
                    prop="qualificationList"
                    label="Specialty"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    width="140"
                    prop="fullName"
                    label="Specialist"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    width="160"
                    prop="fullLocation"
                    label="Location/Region"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    width="220"
                    prop="fullLocation"
                    label="Date Range"
                    show-overflow-tooltip
                >
                  <template slot-scope="booking">
                    <span>{{ formatDate(booking.row.dateFrom, 'DD/MM/YYYY') + (booking.row.dateTo != '' ? ' - ' + formatDate(booking.row.dateTo, 'DD/MM/YYYY') : '') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="180"
                    prop="dateSubmitted"
                    label="Submitted"
                    sortable="custom"
                    show-overflow-tooltip
                >
                  <template slot-scope="booking">
                    <span>{{ formatDate(booking.row.dateSubmitted, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    prop="status"
                    label="Status"
                    sortable="custom"
                    show-overflow-tooltip
                >
                  <template slot-scope="booking">
                    <select class="w-100 textbox" v-model="booking.row.status" @change="changeBookingRequestStatus(booking.$index)">
                      <option hidden value="">Select ...</option>
                      <option v-for="(bookingRequest, index) in bookingRequestStatuses" :key="bookingRequest.id" :value="bookingRequest.id">{{ bookingRequest.name }}</option>
                    </select>
                  </template>
                </el-table-column>
                <el-table-column
                    width="140"
                    prop="assignedTo"
                    label="Assigned To"
                    sortable="custom"
                    show-overflow-tooltip
                >
                  <template slot-scope="booking">
                    <el-select class="w-100" :loading="loading" filterable remote :remote-method="remoteMethod" placeholder="Select..." v-model="booking.row.assignedTo" @change="changeAssignedTo(booking.$index)">
                      <el-option v-for="(user, index) in users" :key="index" :value="user.id" :label="user.name">{{ user.name }}</el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    prop="requirements"
                    label=""
                    show-overflow-tooltip
                >
                  <template slot-scope="booking">
                    <div class="icon-group">
                      <span data-placement="right" data-toggle="tooltip" :title="booking.row.note"><img :src="booking.row.note == '' ? require('@/assets/images/note_light_icon.svg') : require('@/assets/images/note_dark_icon.svg')"/></span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminBookingsMenu from '@/components/AdminBookingsMenu'
  import Header from '@/components/Header'
  import { debounce } from 'lodash'

  export default {
    name: 'AdminBookingRequests',
    components: {
      AdminNavigation,
      AdminBookingsMenu,
      Header,
    },
    data() {
      return {
        fetchedData: false,
        successMessage: '',
        debounceGet: null,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        bookingRequests: [],
        limit: 10,
        pagination: utilities.initPagination(),
        userParam: {
          PageSize: 10,
          PageNum: 1,
          SortField: 'UserStatus',
          SortBy: 'DESC',
          UserName: null,
          RoleID: '3,6'
        },
        byClient: false,
        byRequirement: false,
        bySpecialty: false,
        bySpecialist: false,
        byLocation: false,
        byDate: false,
        bySubmitted: false,
        byStatus: false,
        byAssignedTo: false,

        searchAssignedTo: '',
        searchSpecialist: '',
        searchClient: '',
        loading: false,
        users: [],
        listParam: {
          PageSize: 10,
          PageNum: 1,
          SortFlag: 1,
          AssignedToName: "",
          SpecialistName: "",
          ClientName: ""
        },
        totalCount: 0,
        bookingRequestStatuses: [],
      }
    },
    computed: {},
    methods: {
      async remoteMethod(query) {
        this.userParam.UserName = query
        this.loading = true
        await this.loadData(true)
        this.loading = false
      },
      sortParam(v) {
        if(v.prop === 'dateSubmitted' && v.order === "ascending") {
          this.listParam.SortFlag = 2
        } else if (v.prop === 'dateSubmitted' && v.order === "descending") {
          this.listParam.SortFlag = 1
        } else if (v.prop === 'status' && v.order === "ascending") {
          this.listParam.SortFlag = 4
        } else if (v.prop === 'status' && v.order === "descending") {
          this.listParam.SortFlag = 3
        }  else if (v.prop === 'assignedTo' && v.order === "ascending") {
          this.listParam.SortFlag = 6
        } else if (v.prop === 'assignedTo' && v.order === "descending") {
          this.listParam.SortFlag = 5
        } else {
          this.listParam.SortFlag = 0
        }
        this.selectPage(1)
      },
      RowClick(row, col) {
        if(col.property === 'assignedTo' || col.property === 'status') return
        this.openBookingRequest(row?.id)
      },
      async selectPage(index) {
        this.listParam.PageNum = index
        await this.loadBookingRequests()
      },
      search: debounce(function(){
        // 执行内容...
        this.listParam.PageNum = 1
        this.loadBookingRequests()
      },800),

      openBookingRequest(id) {
        this.$router.push({ name: 'admin-booking-request-form', query: { id: id } })
      },
      formatDate(dateToFormat, newFormat, currentFormat) {
        return utilities.formatDate(dateToFormat, newFormat, currentFormat)
      },
      async changeBookingRequestStatus(index) {
        console.log(index)
        this.$store.dispatch('loading/setLoadComponent', true)
        if(this.bookingRequests[index].status != 1 && this.bookingRequests[index].assignedTo === '') {
          // Assigned to person who logged in
          this.bookingRequests[index].assignedTo = this.user.id
        }
        const result = await this.putBookingRequest(index)
        if (result) {
          this.successMessage = 'Booking request updated successfully.'
          utilities.showAlert('.alert-success')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async changeAssignedTo(index) {
        this.$store.dispatch('loading/setLoadComponent', true)
        // Change status to assigned
        this.bookingRequests[index].status = 2
        const result = await this.putBookingRequest(index)
        if (result) {
          this.successMessage = 'Booking request updated successfully.'
          utilities.showAlert('.alert-success')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      putBookingRequest(index) {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          BookingRequestID: this.bookingRequests[index].id,
          AssignedTo: Number(this.bookingRequests[index].assignedTo),
          BookingRequestStatus: Number(this.bookingRequests[index].status),
        }
        params.BookingRequestUserAssigned = params.AssignedTo == 0 ? 0 : 1
        var callback = (response) => {
          // Reload if change to Completed
          if(this.bookingRequests[index].status == 3) this.loadBookingRequests()
        }
        return utilities.putBookingRequest(params, callback)
      },

      async loadBookingRequests() {
        this.$store.dispatch('loading/setLoadComponent', true)
        let {bookingRequests, total} = await utilities.getBookingRequestsNew(this.listParam)
        this.totalCount = total
        // Show all not been completed yet
        bookingRequests = bookingRequests.filter(x => x.status !== 3)
        this.bookingRequests = _.cloneDeep(bookingRequests)
        this.bookingRequests.filter(item => item.assignedTo).forEach(item => {
          if(this.users.filter(user => user.id === item.assignedTo).length === 0) {
            this.users.push({id: item.assignedTo, name: decodeURIComponent(item.assignedToName)})
          }
        })
        this.bookingRequestStatuses = utilities.getBookingRequestStatuses()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async loadData() {
        var {users} = await utilities.getUsersNew(this.userParam)
        let arr = users.filter(item => item.status)
        for(let i = 0; i < arr.length; i++) {
          if(!this.users.find(item => item.id === arr[i].id)) {
            this.users.unshift(arr[i])
          } else {
            let index = this.users.findIndex(item => item.id === arr[i].id)
            this.users.splice(index, 1)
            this.users.unshift(arr[i])
          }
        }
      },
    },

    async beforeMount() {
      $('body>.tooltip').remove()
      await this.loadData()
      await this.loadBookingRequests()
      this.fetchedData = true
    }
  }
</script>
