/* eslint-disable */
<template>
  <div class="specialist-archive">
    <SpecialistNavigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Court Cases and Teleconferences</h1></div>
        <div class="header-sub-title">Search all court cases and teleconferences bookings.</div>
      </div>
    </div>
    <div class="panel">
      <SpecialistBookingMenu indexProps="1" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end align-items-center">
            <el-input style="max-width: 115px" class="mr-1" @input="search()" v-model="listParam.BookingID" clearable placeholder="Booking ID"/>
            <el-input style="max-width: 160px" class="mr-1" @input="search()" v-model="listParam.ClaimantName" clearable placeholder="Claimant Name"/>
            <el-input style="max-width: 160px" class="mr-1" @input="search()" v-model="listParam.ClientName" clearable placeholder="Client Name"/>
            <el-input style="max-width: 160px" class="mr-1" @input="search()" v-model="listParam.BranchName" clearable placeholder="Branch Name"/>
            <el-date-picker
                style="width: 290px"
                v-model="dateValue"
                type="daterange"
                start-placeholder="Booking Date Start"
                popper-class="date-range-picker-popper"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                @change="changeDates"
                end-placeholder="Booking Date End"
                clearable
            />
          </div>
        </div>
        <div class="table-responsive">
          <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'BookingDateTime', order: 'ascending'}"
                    stripe @sort-change="sortParam" :data="allBookings" tooltip-effect="dark"
                    :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
            <el-table-column
                width="100"
                prop="BookingID"
                label="ID"
                sortable="custom"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                width="200"
                prop="ClaimantName"
                label="Claimant"
                sortable="custom"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                width="150"
                prop="DateCreated"
                label="Date Created"
                sortable="custom"
                show-overflow-tooltip
            >
              <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.DateCreated, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="180"
                prop="BookingDateTime"
                label="Appointment"
                sortable="custom"
                show-overflow-tooltip
            >
              <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.BookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="ClientName"
                width="220"
                label="Client"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="BranchName"
                label="Branch"
                width="120"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="BookingTypeName"
                label="Booking Type"
                sortable="custom"
                width="140"
            >
            </el-table-column>
            <el-table-column
                width="150"
                show-overflow-tooltip
                prop="ClaimTypeName"
                label="Type of Claim"
                sortable="custom"
            >
            </el-table-column>
          </el-table>
        </div>
        <div v-if="totalCount > listParam.PageSize" class="pagination" style="margin-bottom: 0">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import SpecialistNavigation from '@/components/SpecialistNavigation'
import SpecialistBookingMenu from '@/components/SpecialistBookingMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import { debounce } from 'lodash';

export default {
  name: 'SpecialistArchive',
  components: {
    SpecialistNavigation,
    SpecialistBookingMenu,
    Header,
    Chat,
  },
  data() {
    return {
      totalCount: 0,
      listParam: {
        PageSize: 5,
        PageNum: 1,
        FromDate: null,
        toDate: null,
        SortField: 'BookingDateTime',
        SortBy: 'ASC',
        BookingID: null,
      },
      dateValue: [],
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      bookings: [],
      bookingStatuses: utilities.getBookingStatuses(),
      bookingTypes: [],
      allBookings: [],
      searchTerm: '',
    }
  },
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    sortParam(v) {
      this.listParam.SortField = v.prop
      if(v.order === "ascending") {
        this.listParam.SortBy = 'ASC'
      } else {
        this.listParam.SortBy = 'DESC'
      }
      this.selectPage(1)
    },
    RowClick(row) {
      this.$router.push({path: '/specialist-booking-detail', query: {id: row?.BookingID}}).catch((err) => {
      })
    },
    changeDates(v) {
      if(v) {
        this.listParam.BookingStartDate = v[0]
        this.listParam.BookingEndDate = v[1]
      } else {
        this.listParam.BookingStartDate = null
        this.listParam.BookingEndDate = null
      }
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },

    async loadBookings() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {bookings, total} = await utilities.getSpecialistCortCaseBookings(this.listParam)
      this.allBookings = bookings
      this.totalCount = total
      this.$store.dispatch('loading/setLoadComponent', false)
      console.log(this.allBookings)
    },
  },

  async beforeMount() {
    await this.loadBookings()
  },

  mounted() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      $("[data-toggle='tooltip']").tooltip()
    }, 700);
  },
}
</script>
