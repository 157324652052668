/* eslint-disable */
<template>
  <div class="bookings-awaiting-paperwork">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Bookings Awaiting Paperwork</h1></div>
        <div class="header-sub-title">The following bookings are awaiting paperwork, listed in due date order.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu :key="bookingMenuIndex" :indexProps="bookingMenuIndex" />
      <div class="content">
        <div class="row">
          <div class="col pt-0 pl-0">Total Bookings Awaiting Paperwork:  {{ total }}</div>
          <div class="col pt-0 d-flex align-items-center flex-column justify-content-start">
            <span>Bookings Awaiting Paperwork, due this week:  {{ numberDue }}</span>
          </div>
        </div>
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-radio-group v-model="radio" @input="filterWeek">
                <el-radio :label="1">Bookings this week</el-radio>
                <el-radio class="mb-0" :label="2">Show ALL bookings that are awaiting paperwork</el-radio>
              </el-radio-group>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Booking ID" v-model="listParam.BookingID"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Claimant Name" v-model="listParam.ClaimantName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactName"/>
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 8%" class="long-text">ID<img v-if="listParam.SortFlag === 10" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(9)" />
                      <img v-else-if="listParam.SortFlag === 9" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(10)"   /></th>
                    <th style="width: 15%" class="long-text">Client<img v-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(1)" />
                      <img v-else-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(2)"   /></th>
                    <th style="width: 10%" class="long-text">Contact<img v-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(3)" />
                      <img v-else-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(4)"   /></th>
                    <th style="width: 15%" class="long-text">Phone/Email</th>
                    <th style="width: 15%" class="long-text">Claimant<img v-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(5)" />
                      <img v-else-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(6)"   /></th>
                    <th style="width: 13%" class="long-text">Booking Type</th>
                    <th style="width: 10%" class="long-text">Date Due<img v-if="listParam.SortFlag === 12" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(11)" />
                      <img v-else-if="listParam.SortFlag === 11" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(12)"   /></th>
                    <th style="width: 14%" class="long-text">
                      <span data-placement="left" data-toggle="tooltip" title="Paperwork Status">Paperwork Status</span>
                      <img v-if="listParam.SortFlag !== 22 && listParam.SortFlag !== 24 && listParam.SortFlag !== 26 && listParam.SortFlag !== 28" id="22" @click="sortParam(22)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4.svg"
                      />
                      <img v-if="listParam.SortFlag === 22" id="24" @click="sortParam(24)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-1.svg"
                      />
                      <img v-if="listParam.SortFlag === 24" id="26" @click="sortParam(26)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-2.svg"
                      />
                      <img v-if="listParam.SortFlag === 26" id="28" @click="sortParam(28)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-3.svg"
                      />
                      <img v-if="listParam.SortFlag === 28" id="0" @click="sortParam(0)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-4.svg"
                      />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="bookings.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(booking, index) in bookings" :key="index" class="is-icon">
                    <td style="width: 8%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">{{ booking.id }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                    <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clientId } }">
                      <el-tooltip effect="dark" :content="booking.clientName" placement="bottom">
                        <span>{{ booking.clientName }}</span>
                      </el-tooltip>
                    </router-link>
                    </td>
                    <td style="width: 10%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clientId, type:'edit-contact' } }">
                        <el-tooltip effect="dark" :content="booking.clientContactFullName" placement="bottom">
                          <span>{{ booking.clientContactFullName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clientId, type:'edit-contact' } }">
                        <el-tooltip effect="dark" :content="booking.phone + (booking.phone != '' && booking.email != '' ? ' / ' : '') + booking.email" placement="bottom">
                          <span>{{ booking.phone + (booking.phone != '' && booking.email != '' ? ' / ' : '') + booking.email }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: booking.caseId } }">
                        <el-tooltip effect="dark" :content="booking.claimantFullName" placement="bottom">
                          <span>{{ booking.claimantFullName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 13%" class="long-text">
                      <el-tooltip effect="dark" :content="booking.BookingTypeName" placement="bottom">
                        <span>{{ booking.claimTypeName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 10%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">
                        <el-tooltip effect="dark" :content="formatDate(booking.paperworkDue, 'DD/MM/YYYY')" placement="bottom">
                          <span>{{ formatDate(booking.paperworkDue, 'DD/MM/YYYY') }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 14%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">
                        <div class="d-flex align-items-center">
                          <img
                            class="ml-4 is-icon"
                            src="@/assets/images/lol-received.svg"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="LOI Received"
                            :class="isDate(booking.lolReceived) ? '' : 'disabled-element'"
                          />
                          <img
                            class="ml-4 is-icon"
                            src="@/assets/images/brief-received.svg"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Brief Received"
                            :class="isDate(booking.briefReceived) ? '' : 'disabled-element'"
                          />
                          <img
                            class="ml-4 is-icon"
                            src="@/assets/images/additional-paperwork-received.svg"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Additional Paperwork Received"
                            :class="isDate(booking.additionalPaperworkReceived) ? '' : 'disabled-element'"
                          />

                          <img
                            class="ml-4 is-icon"
                            src="@/assets/images/paperwork-processed.svg"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Paperwork Processed"
                            :class="isDate(booking.paperworkProcessed) ? '' : 'disabled-element'"
                          />
                        </div>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminBookingsAwaitingPaperwork",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      totalCount: 0,
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),
      numberDue: 0,
      bookingMenuIndex: 2,
      total: 0,
      byClient: false,
      byContact: false,
      byClaimant: false,
      byTypeOfClaim: false,
      byDate: false,
      byStatus: false,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 11,
        BookingID: null,
        ClientName: '',
        ClaimantName: '',
        ContactName: '',
        PaperWorkDueFrom: moment().format('yyyy-MM-DD'),
        PaperWorkDueTo: moment().add('days', 7).format('yyyy-MM-DD')
      },
      filterList: [],
      radio: 1,
      paperworkStatuses: [],
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    isDate(date) {
      return moment(date).isValid()
    },

    filterWeek(e) {
      if(e === 2) {
        this.listParam = {
          PageSize: 10,
          PageNum: 1,
          SortFlag: 11,
          BookingID: null,
          ClientName: '',
          ClaimantName: '',
          ContactName: '',
        }
        this.selectPage(1)
      } else {
        this.listParam = {
          PageSize: 10,
          PageNum: 1,
          SortFlag: 11,
          BookingID: null,
          ClientName: '',
          ClaimantName: '',
          ContactName: '',
          PaperWorkDueFrom: moment().format('yyyy-MM-DD'),
          PaperWorkDueTo: moment().add('days', 7).format('yyyy-MM-DD')
        }
        this.selectPage(1)
      }
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },
    sortParam(v) {
      this.listParam.SortFlag = v
      this.selectPage(1)
    },
    formatDate(dateToFormat, newFormat, currentFormat = "") {
      return utilities.formatDate(dateToFormat, newFormat)
    },
    getPaperworkStatus(id) {
      return utilities.getPaperworkStatus(id)
    },
    async loadBookings() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {bookings, total }= await utilities.getBookingAwaitingPaperworkNew(this.listParam)
      this.bookings = _.cloneDeep(bookings)
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadData() {
      this.paperworkStatuses = utilities.getPaperworkStatuses()
    },
  },
  async beforeMount() {
    await this.loadBookings()
    this.numberDue = this.totalCount
    const {bookings, total}= await utilities.getBookingAwaitingPaperworkNew({PageNum:1, PageSize:10, SortFlag: 11})
    this.total = total
    this.filterList = bookings
    await this.loadData()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
