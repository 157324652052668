/* eslint-disable */
<template>
  <div class="users">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Users</h1></div>
        <div class="header-sub-title">A list of all the users of Red Health Online - clients, specialists and Red Health admin.</div>
      </div>
    </div>
    <div class="panel">
      <AdminUserMenu indexProps="0" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input style="max-width: 115px" class="mr-1" @input="search()" clearable placeholder="Phone" v-model="listParam.Mobile"/>
            <el-input style="max-width: 115px" class="mr-1" @input="search()" clearable placeholder="Email" v-model="listParam.EmailAddress"/>
            <el-input style="max-width: 115px" class="mr-1" @input="search()" clearable placeholder="UserName" v-model="listParam.UserName"/>
          </div>
        </div>
        <div class="table-responsive">
          <div>
            <div>
              <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'name', order: 'ascending'}" stripe @sort-change="sortParam" :data="allUsers" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    prop="id"
                    label="User ID"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="name"
                    sortable="custom"
                    label="User Name">
                </el-table-column>
                <el-table-column
                    prop="typeName"
                    label="Role"
                    sortable="custom"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="lastLoggedInDate"
                    label="Last Logged In"
                    sortable="custom"
                    width="180">
                  <template slot-scope="row">
                    {{ formatDate(row.row.lastLoggedInDate, "DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss") }}
                  </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="Can Login"
                    sortable="custom"
                    width="180">
                  <template slot-scope="row">
                    <input disabled type="checkbox" :checked="row.row.status" :value="row.row.status" />
                  </template>
                </el-table-column>
                <el-table-column
                    prop="email"
                    label="Email"
                    sortable="custom"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="Phone"
                    sortable="custom"
                    width="180">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))"
                     :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item"
                     @click="selectPage(item, 0)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminUserMenu from "@/components/AdminUserMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminUsers",
  components: {
    AdminNavigation,
    AdminUserMenu,
    Header,
  },
  data() {
    return {
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      users: [],
      allUsers: [],
      totalCount: 0,
      limit: 10,
      pagination: utilities.initPagination(),
      listParam: {
        PageNum: 1,
        PageSize: 10,
        SortField: 'UserName',
        SortBy: 'ASC',
        UserName: null,
      },
      byName: false,
      byRole: false,
      byLastLoggedIn: false,
      byStatus: false,
      // searchTerm: '',
      searchByUsername: '',
      searchByEmail: '',
      searchByMobile: '',
    }
  },
  computed: {},
  methods: {
    sortParam(v) {
      if(v.prop === 'name' && v.order === "ascending") {
        this.listParam.SortField = 'UserName'
        this.listParam.SortBy = 'ASC'
      } else if (v.prop === 'name' && v.order === "descending") {
        this.listParam.SortField = 'UserName'
        this.listParam.SortBy = 'DESC'
      } else if (v.prop === 'typeName' && v.order === "ascending") {
        this.listParam.SortField = 'RoleName'
        this.listParam.SortBy = 'ASC'
      } else if (v.prop === 'typeName' && v.order === "descending") {
        this.listParam.SortField = 'RoleName'
        this.listParam.SortBy = 'DESC'
      } else if (v.prop === 'lastLoggedInDate' && v.order === "ascending") {
        this.listParam.SortField = 'LastLoggedInDate'
        this.listParam.SortBy = 'ASC'
      } else if (v.prop === 'lastLoggedInDate' && v.order === "descending") {
        this.listParam.SortField = 'LastLoggedInDate'
        this.listParam.SortBy = 'DESC'
      } else if (v.prop === 'status' && v.order === "ascending") {
        this.listParam.SortField = 'UserStatus'
        this.listParam.SortBy = 'ASC'
      } else if (v.prop === 'status' && v.order === "descending") {
        this.listParam.SortField = 'UserStatus'
        this.listParam.SortBy = 'DESC'
      } else if (v.prop === 'email' && v.order === "ascending") {
        this.listParam.SortField = 'EmailAddress'
        this.listParam.SortBy = 'ASC'
      } else if (v.prop === 'email' && v.order === "descending") {
        this.listParam.SortField = 'EmailAddress'
        this.listParam.SortBy = 'DESC'
      } else if (v.prop === 'phone' && v.order === "ascending") {
        this.listParam.SortField = 'Mobile'
        this.listParam.SortBy = 'ASC'
      } else if (v.prop === 'phone' && v.order === "descending") {
        this.listParam.SortField = 'Mobile'
        this.listParam.SortBy = 'DESC'
      }  else {
        this.listParam.SortFlag = 0
      }
      this.selectPage(1)
    },

    search: debounce(function () {
      this.selectPage(1)
    }, 800),

    RowClick(row) {
      this.openUser(row?.id)
    },

    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },

    formatDate(dateToFormat, newFormat = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    openUser(id) {
      if(this.user.type == 6) this.$router.push({ name: 'admin-manage-user', query: { id: id } })
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {users, count} = await utilities.getUsersNew(this.listParam)
      this.allUsers = users
      this.totalCount = count
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  async beforeMount() {

    if (!utilities.isAdmin(this.user.type)) {
      this.$router.go(-1)
      return false
    }
    await this.loadData()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
