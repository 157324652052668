/* eslint-disable */
<template>
  <div class="cases">
    <AdminNavigation ref="navigation" indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Cases ({{totalCount}})</h1></div>
        <div class="header-sub-title">Click to view the case, or add a booking.</div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu :key="4" :indexProps="4" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div>
              <div class="d-flex align-items-center align-content-space-between w-100 flex-wrap" style="height: 90px">
                <img v-if="listParam.CaseID || listParam.ReferenceNumber || listParam.ClientName || listParam.ClaimantSurName || listParam.ClaimantFirstName || listParam.ClientID || listParam.CaseOwnerName || Number.isInteger(listParam.CaseStatus) || listParam.FromDate || listParam.ToDate" class="mr-2 cursor-pointer" src="@/assets/images/close_icon.svg" @click="cancelSearch" title="Cancel Search"/>
                <el-input style="max-width: 100px" class="mr-1" @input="search()" clearable placeholder="Case ID" v-model="listParam.CaseID"/>
                <el-input style="max-width: 170px" class="mr-1" @input="search()" clearable placeholder="Reference Number" v-model="listParam.ReferenceNumber"/>
                <el-input style="max-width: 100px" class="mr-1" @input="search()" clearable placeholder="ClientID" v-model="listParam.ClientID"/>
                <el-input style="max-width: 130px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
                <el-input style="max-width: 180px" class="mr-1" @input="search()" clearable placeholder="Claimant SurName" v-model="listParam.ClaimantSurName"/>
                <el-input style="max-width: 190px" class="mr-1" @input="search()" clearable placeholder="Claimant First Name" v-model="listParam.ClaimantFirstName"/>
                <el-input style="max-width: 190px" class="mr-1" @input="search()" clearable placeholder="Case Owner Name" v-model="listParam.CaseOwnerName"/>
                <el-select style="width: 100px" class="mr-1" v-model="listParam.CaseStatus" placeholder="Is Active" clearable @change="selectPage(1)">
                  <el-option :value="1" label="Active">Active</el-option>
                  <el-option :value="0" label="Inactive">Inactive</el-option>
                </el-select>
                <el-date-picker
                    v-model="dateValue"
                    type="daterange"
                    style="max-width: 320px"
                    start-placeholder="Birth Date Start"
                    popper-class="date-range-picker-popper"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="changeDates"
                    end-placeholder="Birth Date End"
                    clearable
                />
                <div class="d-flex justify-content-end flex-grow-1">
                  <v-btn class="add-icon" dark @click="addNewCase">Add New</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="fetchedData" class="content-scroll-x">
          <div>
              <el-table v-if="allCases.length > 0" @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'id', order: 'descending'}" stripe @sort-change="sortParam" :data="allCases" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    width="100"
                    prop="id"
                    label="ID"
                    sortable="custom"
                    show-overflow-tooltip
                    fixed="left"
                >
                </el-table-column>
                <el-table-column
                    width="180"
                    sortable="custom"
                    show-overflow-tooltip
                    label="Reference Number"
                    prop="caseReference"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="clientBranchName"
                    sortable="custom"
                    label="Client"
                >
                </el-table-column>
                <el-table-column
                    width="180"
                    show-overflow-tooltip
                    prop="claimantFullName"
                    sortable="custom"
                    label="Claimant Name"
                >
                </el-table-column>
                <el-table-column
                    width="180"
                    show-overflow-tooltip
                    prop="claimTypeName"
                    sortable="custom"
                    label="Type of Claim"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="dateOfBirth"
                    label="Date of Birth"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.dateOfBirth, "DD/MM/YYYY")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="nextBookingDate"
                    label="Next Booking Date"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.nextBookingDate, "DD/MM/YYYY")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="180"
                    show-overflow-tooltip
                    prop="numberBookings"
                    label="Number Of Booking"
                >
                </el-table-column>
                <el-table-column
                    width="160"
                    sortable="custom"
                    show-overflow-tooltip
                    prop="caseOwnerName"
                    label="Case Owner"
                >
                </el-table-column>
                <el-table-column
                    width="160"
                    sortable="custom"
                    show-overflow-tooltip
                    prop="caseStatus"
                    label="Status"
                >
                  <template slot-scope="scope">
                    <span>{{Number(scope.row.caseStatus) === 1 ? 'Active' : 'InActive'}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="160"
                    show-overflow-tooltip
                    prop="caseStatus"
                    label=" "
                >
                  <div class="icon-group" slot-scope="scope">
                    <router-link :to="{ name: 'admin-bookings', query: {caseId: scope.row.id} }"
                    ><img data-toggle="tooltip" data-placement="right" title="View Bookings" src="@/assets/images/calendar_dark_grey_icon.svg"
                    /></router-link>
                    <router-link v-if="Number(scope.row.caseStatus) === 1" :to="{ name: 'admin-search-specialist', query: { id: scope.row.id }}"
                    ><img data-toggle="tooltip" data-placement="right" title="Add Booking" src="@/assets/images/add_booking_dark_icon.svg"
                    /></router-link>
                    <a v-if="scope.row.zohoCaseLink" :href="scope.row.zohoCaseLink" target="_blank"><img data-toggle="tooltip" data-placement="right" title="View on Zoho" src="@/assets/images/zoho_icon.svg"/></a>
                  </div>
                </el-table-column>
              </el-table>
            <div v-else class="p-3">{{ noCaseMessage }}</div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" :key="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import { debounce } from 'lodash'
  export default {
    name: 'AdminCases',
    props: ['searchOptionProps'],
    components: {
      AdminNavigation,
      Header,
      AdminClientsMenu
    },
    data() {
      return {
        dateValue: "",
        fetchedData: false,
        hasError: false,
        successMessage: '',
        errorMessage: '',
        noCaseMessage: 'No cases found.',
        totalCount: 0,
        client: utilities.initClient(),
        cases: [],
        allCases: [],
        limit: 10,
        pagination: utilities.initPagination(),
        listParam: {
          ReferenceNumber: '',
          ClientName: '',
          ClientID: null,
          CaseOwnerName: '',
          ClaimantFirstName: '',
          ClaimantSurName: '',
          ClaimTypeID: null,
          CaseStatus: null,
          SortFlag: 0,
          PageNum: 1,
          PageSize: 10,
		      FromDate: null,
          CaseID: '',
          ToDate: null
        },
        byName: false,
        byType: false,
        byDate: false,
        byOwner: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    methods: {
      search: debounce(function () {
        this.selectPage(1)
      }, 800),
      changeDates(v) {
        if(v) {
          this.listParam.FromDate = v[0]
          this.listParam.ToDate = v[1]
        } else {
          this.listParam.FromDate = null
          this.listParam.ToDate = null
        }
        this.selectPage(1)
      },
      cancelSearch() {
        this.listParam.ClientName = ""
        this.listParam.ClientID = ""
        this.listParam.CaseOwnerName = ""
        this.listParam.ClaimantName = ""
        this.listParam.BranchName = ""
        this.listParam.CaseStatus = null
        this.listParam.FromDate = null
        this.listParam.ToDate = null
        this.listParam.CaseID = ""
        this.listParam.ClaimantSurName = ""
        this.listParam.ClaimantFirstName = ""
        this.dateValue= ""
        this.listParam.ReferenceNumber = ''
        this.client = utilities.initClient()
        window.history.replaceState({}, document.title, location.pathname)
        this.selectPage(1)
      },
      selectPage(index) {
        this.listParam.PageNum = index
        this.loadData()
      },
      sortParam( v ) {
        if(v.prop === 'id' && v.order === "ascending") {
          this.listParam.SortFlag = 16
        } else if (v.prop === 'id' && v.order === "descending") {
          this.listParam.SortFlag = 15
        } else if(v.prop === 'caseOwnerName' && v.order === "ascending") {
          this.listParam.SortFlag = 6
        } else if (v.prop === 'caseOwnerName' && v.order === "descending") {
          this.listParam.SortFlag = 5
        } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
          this.listParam.SortFlag = 8
        } else if (v.prop === 'claimantFullName' && v.order === "descending") {
          this.listParam.SortFlag = 7
        } else if(v.prop === 'clientBranchName' && v.order === "ascending") {
          this.listParam.SortFlag = 4
        } else if (v.prop === 'clientBranchName' && v.order === "descending") {
          this.listParam.SortFlag = 3
        } else if(v.prop === 'caseReference' && v.order === "ascending") {
          this.listParam.SortFlag = 2
        } else if (v.prop === 'caseReference' && v.order === "descending") {
          this.listParam.SortFlag = 1
        } else if(v.prop === 'claimTypeName' && v.order === "ascending") {
          this.listParam.SortFlag = 10
        } else if (v.prop === 'claimTypeName' && v.order === "descending") {
          this.listParam.SortFlag = 9
        }
        this.selectPage(1)
      },
      formatDate(dateToFormat) {
        return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
      },
      openSearchModal() {
        this.$refs.adminSearchCaseModal.handleModal()
      },
      openSearchClaimant() {
        this.$refs.navigation.$refs.globalSearch.openSearchClaimant()
      },

      RowClick(row) {
        this.openCase(row?.id)
      },
      openCase(id) {
        this.$router.push({ name: 'admin-manage-case', query: { id: id } })
      },
      addNewCase() {
        if(this.client.id != '') {
          this.$router.push({ path:'/admin-manage-case' , query: { clientId: this.client.id } })
        } else {
          this.$router.push({ path:'/admin-manage-case' , query: {} })
        }
      },
      async searchCase(searchOption) {
        this.listParam.ClientID = searchOption.clientId
        this.listParam.ClientName = searchOption.ClientName
        this.listParam.ReferenceNumber = searchOption.referenceNumber
        this.listParam.ClaimantName = searchOption.claimant ? searchOption.claimant : null
        this.listParam.ClaimantFirstName = searchOption.claimantFirstname
        this.listParam.ClaimantSurName = searchOption.claimantSurname
        this.listParam.CaseOwnerName = searchOption.contactUserId
        this.$store.dispatch('loading/setLoadComponent', true)
        var {clientCase, total} = await utilities.getCasesNew(this.listParam)
        this.$store.dispatch('loading/setLoadComponent', false)
        this.allCases = _.cloneDeep(clientCase)
        this.totalCount = total
        if (this.totalCount === 0) {
          this.noCaseMessage = 'No cases found.'
        } else {
          this.noCaseMessage = ''
        }
      },
      async loadClient() {
        var clientId = this.$route.query.id != undefined ? this.$route.query.id : ''
        if (clientId !== '') {
          this.$store.dispatch('loading/setLoadComponent', true)
          this.client = await utilities.getClientById(clientId)
          this.$store.dispatch('loading/setLoadComponent', false)
        }
        if (this.client.id !== '') {
          this.listParam.ClientID = this.client.id
        }
      },
      async loadData() {
        this.$store.dispatch('loading/setLoadComponent', true)
        const {clientCase, total} = await utilities.getCasesNew(this.listParam)
        this.allCases = _.cloneDeep(clientCase)
        this.totalCount = total
        if (clientCase.length === 0) {
          this.noCaseMessage = 'No cases found.'
        } else {
          this.noCaseMessage = ''
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      $('body>.tooltip').remove()
      if (this.searchOptionProps !== undefined) {
        await this.searchCase(this.searchOptionProps)
      } else {
        await this.loadClient()
        await this.loadData()
      }
      this.fetchedData = true
    },
    mounted() {
      $('.pagination').hide()
    },
  }
</script>
