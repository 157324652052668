/* eslint-disable */
<template>
  <div class="specialist-menu sub-menu">
    <ul>
      <li><router-link to="/search-specialist">Search Availability</router-link></li>
      <li class="is-icon" @click="getUrl">Download Referral Network</li>
    </ul>
    <ExportSpecialistModal ref="exportSpecialistsModal" />
  </div>
</template>

<script>


import utilities from '@/assets/js/utilities'
import ExportSpecialistModal from '@/components/ExportSpecialistModal'

export default {
  name: 'SpecialistMenu',
  components: {
    ExportSpecialistModal,
  },
  props: ["indexProps"],
  data() {
    return {
    }
  },
  methods: {
    async getUrl(){
      this.$store.dispatch("loading/setLoadComponent", true)
      var blob = await utilities.getSpecialistProfiles()
      const code = blob.replace(/[\n\r]/g, '')
      const raw = window.atob(code)
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      const blobItem = new Blob([uInt8Array], { type: 'application/pdf' })
      const blobURL = window.URL.createObjectURL(blobItem)
      let a = document.createElement('a')
      a.setAttribute('href', blobURL)
      a.setAttribute('download', 'Red Health Referral Network.pdf')
      a.click()
      this.$store.dispatch("loading/setLoadComponent", false)
    }
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
