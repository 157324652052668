/* eslint-disable */
<template>
  <div class="locations-and-regions">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Locations</h1></div>
        <div class="header-sub-title">Locations are available for Specialists to operate from. Ensure a region is attached to a location.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="2-3" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <input type="search" class="textbox filter-icon right-float-element w-50 mr-5" placeholder="Filter..." @keyup="search()" v-model="searchTerm" />
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:25%" class="long-text">Location Name</th>
                    <th style="width:30%" class="long-text">Address</th>
                    <th style="width:30%" class="long-text">Region</th>
                    <th style="width:15%;vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center">
                        <router-link to="/admin-manage-location">
                          <v-btn class="add-icon" dark>Add New</v-btn>
                        </router-link>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="locations.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(location, index) in locations" :key="index">
                    <td style="width:25%" class="long-text">{{ location.name }}</td>
                    <td style="width:30%" class="long-text">{{ getFullAddress(location) }}</td>
                    <td style="width:30%" class="long-text">{{ location.regionName }}</td>
                    <td style="width:15%" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center">
                        <router-link class="m-0" :to="{ path: '/admin-manage-location', query: { locationId: location.id } }">
                          <img data-toggle="tooltip" data-placement="right" title="Edit location" src="@/assets/images/edit_icon.svg" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSettingsMenu from '@/components/AdminSettingsMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminLocationsAndRegions',
    components: {
      AdminNavigation,
      AdminSettingsMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        locations: [],
        allLocations: [],
        limit: 10,
        pagination: utilities.initPagination(),

        errorMessage: '',
        successMessage: '',
        searchTerm: '',
      }
    },
    methods: {
      search() {
        if (this.searchTerm.trim() != '') {
          var locations = search(this.allLocations, ['name'], this.searchTerm)
          this.paging(locations)
        } else {
          this.paging(this.allLocations)
        }
      },

      async loadData() {
        this.locations = utilities.getLocations()
      },
      paging(locations) {
        if (locations.length == 0 || locations.length <= this.limit) {
          this.locations = locations
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(locations, this.limit)
          this.locations = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
      },

      getFullAddress(location) {
        return (
          location.address1 +
          (location.address1 != '' && location.address2 != '' ? ' ' : '') +
          location.address2 +
          (location.address1 != '' || location.address2 != '' ? ', ' : '') +
          location.cityName +
          (location.cityName != '' ? ', ' : '') +
          location.state +
          ' ' +
          location.postcode
        )
      },

      selectPage(index) {
        this.pagination.current = index
        this.locations = this.pagination.list[this.pagination.current]
      },
    },
    async beforeMount() {
      utilities.clearStore()
      this.$store.dispatch('loading/setLoadComponent', true)
      var locations = await utilities.getLocations()
      this.allLocations = _.cloneDeep(locations)
      this.paging(locations)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      setTimeout(() => {
        $("[data-toggle='tooltip']").tooltip()
      }, 900);
    },
  }
</script>
