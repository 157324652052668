/* eslint-disable */
<template>
  <div class="settings">
    <AdminNavigation ref="navigation" indexProps="9"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Email Templates</h1></div>
        <div class="header-sub-title"></div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="14"/>
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input style="max-width: 180px" class="mr-1" @input="search()" clearable placeholder="Template Name"
                      v-model="listParam.TemplateName"/>
          </div>
        </div>
        <div class="content-scroll-x">
          <el-table :data="records" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}"
                    :header-cell-style="{fontWeight: 100}">
            <el-table-column
                width="50"
                prop="EmailTemplateID"
                label="ID"
                show-overflow-tooltip
                fixed="left"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                width="180"
                label="Template Name"
                prop="EmailTemplateName"
            >
              <template slot-scope="item">
                <span>{{ decodeURIComponent(item.row.EmailTemplateName) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                show-overflow-tooltip
                label="Email Type"
                prop="AutoEmailType"
            >
              <template slot-scope="item">
                <span>{{ decodeURIComponent(item.row.AutoEmailType) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="140"
                show-overflow-tooltip
                label="Email Target"
                prop="EmailTarget"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                label="Subject"
                prop="Subject"
            >
            </el-table-column>
            <el-table-column
                width="180"
                label="Action"
            >
              <template slot-scope="item">
                <el-button size="small" link @click="handleClickModal(item.row)">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="totalCount > listParam.PageSize" class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ listParam.PageNum }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        top="5vh"
        :close-on-click-modal="false"
        :title="!curItem.EmailTemplateID ? 'Create Email Template' : 'Edit Email Template'"
        :visible.sync="dialogVisible"
        width="80%">
      <div class="box-row">
        <div class="box-col box-col-left left-label">Template Name</div>
        <div class="box-col-right">
          <span>{{curItem.EmailTemplateName}}</span>
        </div>
        <div class="box-col box-col-left left-label">Subject Name</div>
        <div class="box-col-right">
          <el-input v-model="curItem.Subject"/>
          <div v-if="hasError && !validateSubject" class="text-danger">
            Subject Name is required.
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left left-label">Email Target</div>
        <div class="box-col-right">
          <span>{{curItem.EmailTarget}}</span>
        </div>
<!--        <div class="box-col box-col-left left-label">Email cc</div>-->
<!--        <div class=" box-col-right">-->
<!--          <el-input v-model="curItem.EmailCc"/>-->
<!--        </div>-->
      </div>
      <div class="box-row">
        <div class="box-col box-col-left left-label">Fields</div>
        <div class="box-col-right">
          <el-select class="w-100" placeholder="Select to Fill" v-model="curValue" @change="onSelectOption" filterable>
            <el-option v-for="key in curItem.EmailDynamicFields" :value="key" :key="key" :label="key"/>
          </el-select>
        </div>
        <div class="box-col box-col-left left-label"></div>
        <div class="box-col box-col-right">
        </div>
      </div>
      <Editor apiKey="5wu9mqdus3m0ugez0lnjdl6dcxezik3j0rnecxh62g4xf31p" ref="editor" id="tinymceEle"  v-model="curItem.Body"
               :init="option"></Editor>
      <div v-if="hasError && !validateEmailBody" class="text-danger">
        Email Body can not be empty
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelModal">Cancel</el-button>
    <el-button type="primary" @click="onSave">Save</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminSettingsMenu from '@/components/AdminSettingsMenu'
import Header from '@/components/Header'
import {debounce} from 'lodash';
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import Editor from "@tinymce/tinymce-vue";

export default {
  name: 'AdminManageEmail',
  components: {
    AdminNavigation,
    AdminSettingsMenu,
    Header,
    Editor
  },
  data() {
    return {
      option: {
        plugins: 'link image code table',
        height: 400,
        branding: false,
        menu: false
      },
      curValue: null,
      content: '',
      hasError: false,
      records: [],
      dialogVisible: false,
      successMessage: '',
      mode: 'create',
      totalCount: 0,
      curItem: {
        TemplateName: '',
        Subject: '',
        Body: '',
        EmailTo: '',
        EmailCc: '',
        EmailBcc: '',
        Status: 1,
      },
      listParam: {
        PageNum: 1,
        PageSize: 10,
        EmailTemplateID: null,
        EmailTo: null,
        TemplateName: '',
        Status: null
      },
      emailToType: [{
        name: 'RH2',
        value: 0
      }, {
        name: 'Client Contact',
        value: 1
      }, {
        name: 'Specialist',
        value: 2
      }, {
        name: 'Claimant',
        value: 3
      }, {
        name: 'Custom',
        value: 4
      }],
      fieldList: utilities.getStaticTemplateParam(),
      editorOptions: {
        lineHeight: 1
      }
    }
  },
  computed: {
    validateTemplateName() {
      return this.curItem.EmailTemplateName.trim() !== ''
    },
    validateSubject() {
      return this.curItem.Subject.trim() !== ''
    },
    validateEmailBody() {
      return this.curItem.Body.trim() !== ''
    },
  },
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    handleDelete(item) {
      this.$confirm({
        content: 'Are you sure you want to delete this template?',
        onConfirm: async () => {
          this.curItem = item
          this.curItem.Deleted = 1
          this.$store.dispatch("loading/setLoadComponent", true)
          await utilities.putEmailTemplate(this.curItem)
          this.$store.dispatch("loading/setLoadComponent", false)
          this.selectPage(1)
        }
      })
    },
    async onSave() {
      if(!this.validateSubject || !this.validateEmailBody || !this.validateTemplateName) {
        this.hasError = true
        return
      } else {
        this.hasError = false
      }
      // this.curItem.Body = this.curItem.Body.replaceAll("'", '%27')
      this.$store.dispatch("loading/setLoadComponent", true)
      const param = {
        AutoEmailType: this.curItem.AutoEmailType,
        EmailTemplateName: this.curItem.EmailTemplateName,
        EmailTarget: this.curItem.EmailTarget,
        Data: {
          Subject: this.curItem.Subject,
          Body: this.curItem.Body.indexOf('<html>') < 0 ? `<html>${this.curItem.Body}</html>` : this.curItem.Body
        }
      }
      await utilities.putAutoEmails(param)
      this.$store.dispatch("loading/setLoadComponent", false)
      this.cancelModal()
      await this.loadData()
    },
    cancelModal() {
      this.dialogVisible = false
      this.curItem = {
        EmailTemplateName: '',
        Subject: '',
        Body: '',
        EmailTarget: ''
      }
    },
    handleClickModal(item) {
      let obj = JSON.parse(JSON.stringify(item))
      for (let key in obj) {
        if( typeof(obj[key]) === 'string' && key !== 'Body')
        obj[key] = decodeURIComponent(obj[key])
      }
      // obj.Body = obj.Body.replaceAll("%27", "'")
      this.curItem = obj
      this.hasError = false
      this.dialogVisible = true
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },
    onSelectOption(v) {
      this.$refs.editor.editor.execCommand('mceInsertContent', false, v);
      this.curValue = null
    },
    async loadData() {
      let obj = {...this.listParam}
      for (let key in obj) {
        if (obj[key] === null || obj[key] === '') {
          delete obj[key];
        }
      }
      this.$store.dispatch("loading/setLoadComponent", true)
      try {
        this.records = await utilities.getAutoEmails(obj)
        this.totalCount = this.records.length
        console.log(this.records)
        this.$store.dispatch("loading/setLoadComponent", false)
      } catch (e) {
        this.records =  []
        this.totalCount = 0
        this.$store.dispatch("loading/setLoadComponent", false)
      }

    },

    addNew() {
      this.curItem = {
        TemplateName: '',
            Subject: '',
            Body: '',
            EmailTo: '',
            EmailCc: '',
            EmailBcc: '',
            Status: 1,
      }
      this.hasError = false
      this.dialogVisible = true
    },
  },
  async beforeMount() {
    await this.loadData()
  },
  mounted() {
  },
}
</script>
