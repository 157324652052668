/* eslint-disable */
<template>
  <div class="upcoming-bookings">
    <SpecialistNavigation indexProps="2"/>
    <div class="top-panel" style="height: 60px">
      <Header/>
      <div class="header-title-section d-flex justify-content-between align-items-center"
           style="height: auto; padding-bottom: 0">
        <div class="header-title" style="margin: 0">
          <h1>Working in Progress</h1>
          <div class="header-sub-title">Search all Awaiting Dictation or Reports to Review</div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <el-checkbox class="mr-2 mb-0" @change="onChangeTickBox" v-model="isOverDue">Show Overdue</el-checkbox>
          <el-input style="max-width: 115px" class="mr-1" @input="search()" v-model="bookingId" clearable placeholder="Booking ID"/>
          <el-input style="max-width: 160px" class="mr-1" @input="search1()" v-model="claimantName" clearable placeholder="Claimant Name"/>
          <el-input style="max-width: 160px" class="mr-1" @input="search2()" v-model="clientName" clearable placeholder="Client Name"/>
          <el-input style="max-width: 160px" class="mr-1" @input="search3()" v-model="branchName" clearable placeholder="Branch Name"/>
          <el-date-picker
              style="width: 290px"
              v-model="dateValue"
              type="daterange"
              start-placeholder="Booking Date Start"
              popper-class="date-range-picker-popper"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              @change="changeDates"
              end-placeholder="Booking Date End"
              clearable
          />
        </div>
      </div>
    </div>
    <div class="panel d-flex calc-width">
      <SpecialistBookingMenu indexProps="0"/>
      <div style="width: 220px; flex-shrink: 0;" />
      <div class="content" style="flex: 1; left: 0; top: -40px">
        <div class="row">
          <div class="col-2 pl-0">
            <div class="border-image ml-0">
              <div class="border-image-number">{{ totalCount }}</div>
              <div class="border-image-text">Awaiting Dictation</div>
            </div>
          </div>
          <div class="col">&nbsp;</div>
          <div class="col">&nbsp;</div>
        </div>
        <div class="table-responsive">
          <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'BookingDateTime', order: 'ascending'}"
                    stripe @sort-change="sortParam" :data="upcomingBookings" tooltip-effect="dark"
                    :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
            <el-table-column
                width="100"
                prop="BookingID"
                label="ID"
                sortable="custom"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                width="150"
                prop="ClaimantName"
                label="Claimant"
                sortable="custom"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                width="150"
                prop="DateCreated"
                label="Date Created"
                sortable="custom"
                show-overflow-tooltip
            >
              <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.DateCreated, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                prop="BookingDateTime"
                label="Appointment"
                sortable="custom"
                show-overflow-tooltip
            >
              <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.BookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="ClientName"
                width="200"
                label="Client"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="BranchName"
                label="Branch"
                width="120"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                width="140"
                show-overflow-tooltip
                prop="BookingTypeName"
                label="Booking Type"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                width="150"
                show-overflow-tooltip
                prop="ClaimTypeName"
                label="Type of Claim"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                width="180"
                show-overflow-tooltip
                prop="DictationDueDate"
                label="Dictation Due Date"
                sortable="custom"
            >
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.DictationDueDate, 'DD/MM/YYYY') }}</span>
                <span v-if="checkIsOverDue(scope.row.DictationDueDate)" class="ml-2 text-red fs-12">Overdue</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="totalCount > listParam.PageSize" class="pagination" style="margin-bottom: 0">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
        <div v-else class="pagination"></div>
        <div class="row" style="margin-top: -40px; width: fit-content">
          <div class="col-2 pl-0">
            <div class="border-image ml-0">
              <div class="border-image-number">{{ totalCount2 }}</div>
              <div class="border-image-text">Reports to Review</div>
            </div>
          </div>
          <div class="col">&nbsp;</div>
          <div class="col">&nbsp;</div>
        </div>
        <div class="table-responsive">
          <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'BookingDateTime', order: 'ascending'}"
                    stripe @sort-change="sortParam2" :data="upcomingBookings2" tooltip-effect="dark"
                    :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
            <el-table-column
                width="100"
                prop="BookingID"
                label="ID"
                sortable="custom"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                width="150"
                prop="ClaimantName"
                label="Claimant"
                sortable="custom"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                width="150"
                prop="DateCreated"
                label="Date Created"
                sortable="custom"
                show-overflow-tooltip
            >
              <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.DateCreated, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                prop="BookingDateTime"
                label="Appointment"
                sortable="custom"
                show-overflow-tooltip
            >
              <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.BookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss.ssssss")
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="ClientName"
                width="200"
                label="Client"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="BranchName"
                label="Branch"
                width="120"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                width="140"
                show-overflow-tooltip
                prop="BookingTypeName"
                label="Booking Type"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                prop="ClaimTypeName"
                label="Type of Claim"
                sortable="custom"
                width="150"
            >
            </el-table-column>
            <el-table-column
                width="180"
                show-overflow-tooltip
                prop="ReportDueDate"
                label="Report Due Date"
                sortable="custom"
            >
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.ReportDueDate, 'DD/MM/YYYY') }}</span>
                <span v-if="checkIsOverDue(scope.row.ReportDueDate)" class="ml-2 text-red">Overdue</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="totalCount2 > listParam2.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam2.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount2/listParam2.PageSize))" :key="item"
                     :class="listParam2.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage2(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount2 / listParam2.PageSize) }}</div>
        </div>
        <div v-else class="pagination"></div>
      </div>
    </div>
    <Chat/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Header from '@/components/Header'
import {debounce} from 'lodash';
import Chat from '@/components/Chat'
import SpecialistNavigation from "@/components/SpecialistNavigation";
import SpecialistBookingMenu from "@/components/SpecialistBookingMenu";

export default {
  name: 'UpcomingBookings',
  components: {
    SpecialistNavigation,
    Header,
    Chat,
    SpecialistBookingMenu
  },
  data() {
    return {
      isOverDue: false,
      claimantName: '',
      clientName: '',
      branchName: '',
      bookingId: '',
      dateValue: [],
      limit: 12,
      pagination: utilities.initPagination(),
      byDate: false,
      byPaperwork: false,
      byPaperworkDue: false,
      maxDate: '',
      upcomingBookings: [],
      upcomingBookings2: [],
      allUpcomingBookings: [],
      totalCount: 0,
      totalCount2: 0,
      listParam: {
        PageSize: 5,
        PageNum: 1,
        FromDate: null,
        toDate: null,
        SortField: 'BookingDateTime',
        SortBy: 'ASC'
      },
      listParam2: {
        PageSize: 5,
        PageNum: 1,
        FromDate: null,
        toDate: null,
        SortField: 'BookingDateTime',
        SortBy: 'ASC'
      },
      paperworkStatuses: utilities.getPaperworkStatuses(),
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      searchBookingId: '',
      searchClaimantName: '',
      searchSpecialist: '',
    }
  },

  computed: {},

  methods: {
    checkIsOverDue(date) {
      const today = window.moment()
      const dataTime = window.moment(date, 'YYYY-MM-DD').endOf('day')
      if(today.isAfter(dataTime)) {
        return true
      } else {
        return false
      }
    },
    onChangeTickBox() {
      if(this.isOverDue) {
        this.listParam.DictationDueDateEnd = window.moment().subtract(1, 'day').format('YYYY-MM-DD')
        this.listParam2.ReportDueDateEnd = window.moment().subtract(1, 'day').format('YYYY-MM-DD')
        this.selectPage(1)
        this.selectPage2(1)
      } else {
        this.listParam.DictationDueDateEnd = null
        this.listParam2.ReportDueDateEnd = null
        this.selectPage(1)
        this.selectPage2(1)
      }
    },
    changeDates(v) {
      if(v && v.length > 0) {
        this.listParam.BookingStartDate = v[0]
        this.listParam.BookingEndDate = v[1]
        this.listParam2.BookingStartDate = v[0]
        this.listParam2.BookingEndDate = v[1]
      } else {
        this.listParam.BookingStartDate = null
        this.listParam.BookingEndDate = null
        this.listParam2.BookingStartDate = null
        this.listParam2.BookingEndDate = null
      }
      this.selectPage(1)
      this.selectPage2(1)
    },
    sortParam(v) {
      this.listParam.SortField = v.prop
      if(v.order === "ascending") {
        this.listParam.SortBy = 'ASC'
      } else {
        this.listParam.SortBy = 'DESC'
      }
      this.selectPage(1)
    },
    sortParam2(v) {
      this.listParam2.SortField = v.prop
      if(v.order === "ascending") {
        this.listParam2.SortBy = 'ASC'
      } else {
        this.listParam2.SortBy = 'DESC'
      }
      this.selectPage2(1)
    },
    RowClick(row) {
      this.editBooking(row?.BookingID)
    },
    getBookingStatus(id) {
      return utilities.getBookingStatus(id)
    },
    search: debounce(function () {
      this.listParam.BookingID = this.bookingId
      this.listParam2.BookingID = this.bookingId
      this.selectPage(1)
      this.selectPage2(1)
    }, 800),
    search1: debounce(function () {
      this.listParam.ClaimantName = this.claimantName
      this.listParam2.ClaimantName = this.claimantName
      this.selectPage(1)
      this.selectPage2(1)
    }, 800),
    search2: debounce(function () {
      this.listParam.ClientName = this.clientName
      this.listParam2.ClientName = this.clientName
      this.selectPage(1)
      this.selectPage2(1)
    }, 800),

    search3: debounce(function () {
      this.listParam.BranchName = this.branchName
      this.listParam2.BranchName = this.branchName
      this.selectPage(1)
      this.selectPage2(1)
    }, 800),

    setOriginalData() {
      this.upcomingBookings = _.cloneDeep(this.allUpcomingBookings)
      this.setUpcomingPagination()
    },

    selectPage(index) {
      this.listParam.PageNum = index
      this.awaitingDictation()
    },

    selectPage2(index) {
      this.listParam2.PageNum = index
      this.reportToView()
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date && date.length > 0 && date.indexOf('0000-00-00') < 0) {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ""
    },

    sortByDateTime(date) {
      // this.upcomingBookings.sort((a, b) => {
      //   if(this.byDate) {  // ASC
      //     return a.bookingDetails.dateTime > b.bookingDetails.dateTime ? 1 : -1
      //   } else { // DESC
      //     return a.bookingDetails.dateTime < b.bookingDetails.dateTime ? 1 : -1
      //   }
      // })
      // this.byDate = !this.byDate

      this.allUpcomingBookings.sort((a, b) => {
        if (this.byDate) {  // ASC
          return a.bookingDetails.dateTime > b.bookingDetails.dateTime ? 1 : -1
        } else { // DESC
          return a.bookingDetails.dateTime < b.bookingDetails.dateTime ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings;
      this.setUpcomingPagination()
      this.byDate = !this.byDate
    },

    sortByPaperwork(status) {
      this.allUpcomingBookings.sort((a, b) => {
        if (!this.byPaperwork) {  // ASC
          return Number(a.bookingDetails.paperworkStatus) > Number(b.bookingDetails.paperworkStatus) ? 1 : -1
        } else { // DESC
          return Number(a.paperworkStatus) < Number(b.paperworkStatus) ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings
      this.setUpcomingPagination()
      this.byPaperwork = !this.byPaperwork
    },

    sortByPaperworkDue(status) {
      // this.upcomingBookings.sort((a, b) => {
      //   if(this.byDate) {  // ASC
      //     return a.bookingDetails.paperworkStatus > b.bookingDetails.paperworkStatus ? 1 : -1
      //   } else { // DESC
      //     return a.paperworkStatus < b.paperworkStatus ? 1 : -1
      //   }
      // })
      // this.byDate = !this.byDate
      var date1 = null
      var date2 = null
      this.allUpcomingBookings.sort((a, b) => {
        if (a.bookingDetails.paperworkDue != '') {
          date1 = moment(a.bookingDetails.paperworkDue)
        } else {
          date1 = moment(this.maxDate)
        }
        if (b.bookingDetails.paperworkDue != '') {
          date2 = moment(b.bookingDetails.paperworkDue)
        } else {
          date2 = moment(this.maxDate)
        }

        if (this.byPaperworkDue) {
          // ASC
          return date1.isAfter(date2) ? 1 : -1
        } else {
          // DESC
          return date1.isBefore(date2) ? 1 : -1
        }
      })
      this.upcomingBookings = this.allUpcomingBookings
      this.setUpcomingPagination()
      this.byPaperworkDue = !this.byPaperworkDue
    },

    getPaperworkStatusById(id) {
      if (id) {
        return this.paperworkStatuses.find((x) => x.id == id).name
      }
      return ""
    },

    editBooking(bookingId) {
      this.$router.push({path: '/specialist-booking-detail', query: {id: bookingId}}).catch((err) => {
      })
    },

    setUpcomingPagination() {
      if (this.upcomingBookings.length > this.limit) {
        this.pagination = utilities.loadPagination(this.upcomingBookings, this.limit)
        this.upcomingBookings = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      } else {
        $(".pagination").hide()
      }
    },

    async awaitingDictation() {
      this.$store.dispatch("loading/setLoadComponent", true)
      let {bookings, total} = await utilities.getDictationBookingsNew(this.listParam);
      this.upcomingBookings = bookings
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    async reportToView() {
      this.$store.dispatch("loading/setLoadComponent", true)
      let {bookings, total} = await utilities.getReportBookingsNew(this.listParam2);
      this.upcomingBookings2 = bookings
      console.log(bookings)
      this.totalCount2 = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },

  async beforeMount() {
    if(this.$route.query.overdue) {
      this.isOverDue = this.$route.query.overdue
      if(this.isOverDue) {
        this.listParam.DictationDueDateEnd = window.moment().subtract(1, 'day').format('YYYY-MM-DD')
        this.listParam2.ReportDueDateEnd = window.moment().subtract(1, 'day').format('YYYY-MM-DD')
      } else {
        this.listParam.DictationDueDateEnd = null
        this.listParam2.ReportDueDateEnd = null
      }
    }
    await Promise.all([this.awaitingDictation(), this.reportToView()])
    this.$store.dispatch("loading/setLoadComponent", false)
  },

  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  },
}
</script>
