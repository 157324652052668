import Vue from 'vue'
import Vuex from 'vuex'
import utilities from '@/assets/js/utilities'

Vue.use(Vuex)

const user = {
  namespaced: true,
  state: {
    authentication: utilities.initAuthentication(),
    user: utilities.initUser(),
  },
  getters: {
    getAuthentication(state) {
      if (sessionStorage.getItem('authentication')) {
        state.authentication = JSON.parse(sessionStorage.getItem('authentication'))
      }
      return state.authentication
    },
    getUser(state) {
      if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'))
      }
      return state.user
    },
  },
  mutations: {
    setAuthentication(state, payload) {
      sessionStorage.setItem('authentication', JSON.stringify(payload))
      state.authentication = Object.assign([], payload)
    },
    setUser(state, payload) {
      localStorage.setItem('user', JSON.stringify(payload))
      state.user = Object.assign([], payload)
    },
    removeUser(state, payload) {
      localStorage.removeItem('user')
      state.user = {}
    }
  },
  actions: {
    setAuthentication(context, payload) {
      context.commit('setAuthentication', payload)
    },
    setUser(context, payload) {
      context.commit('setUser', payload)
    },
    removeUser(context) {
      context.commit('removeUser')
    }
  },
}

const loading = {
  namespaced: true,
  state: {
    loadComponent: false
  },
  getters: {
    getLoadComponent(state) {
      return state.loadComponent
    },
  },
  mutations: {
    setLoadComponent(state, payload) {
      state.loadComponent = payload
      // Fix can not scroll down after loading spinner
      setTimeout(() => {
        if(state.loadComponent) {
          $("html").addClass("modal-mask-open")
        } else {
          $("html").removeClass("modal-mask-open")
        }
      }, 200)
    },
  },
  actions: {
    setLoadComponent(context, payload) {
      context.commit('setLoadComponent', payload)
    },
  },
}

const specialList = {
  namespaced: true,
  state: {
    specialist: false
  },
  getters: {
    getSpecialist(state) {
      return state.specialist
    }
  },
  mutations: {
    setSpecialist(state, payload) {
      state.specialist = payload
    }
  },
  actions: {
    setSpecialist(context, payload) {
      context.commit('setSpecialist', payload)
    }
  },
}

const claimantConfirm = {
  namespaced: true,
  state: {
    claimantConfirm: false
  },
  getters: {
    getClaimantConfirm(state) {
      return state.claimantConfirm
    }
  },
  mutations: {
    setClaimantConfirm(state, payload) {
      state.claimantConfirm = payload
    }
  },
  actions: {
    setClaimantConfirm(context, payload) {
      context.commit('setClaimantConfirm', payload)
    }
  },
}


const booking = {
  namespaced: true,
  state: {
    bookingData: null
  },
  getters: {
    getBookingObject(state) {
      if (localStorage.getItem('bookingObject')) {
        state.bookingData = JSON.parse(localStorage.getItem('bookingObject'))
      }
      return state.bookingData
    }
  },
  mutations: {
    setBookingObject(state, payload) {
      localStorage.setItem('bookingObject', JSON.stringify(payload))
      state.bookingData = Object.assign([], payload)
    }
  },
  actions: {
    setBookingObject(context, payload) {
      context.commit('setBookingObject', payload)
    }
  },
}

const location = {
  namespaced: true,
  state: {
    locationData: null
  },
  getters: {
    getLocationData(state) {
      return state.locationData
    }
  },
  mutations: {
    setLocationData(state, payload) {
      state.locationData = payload
    }
  },
  actions: {
    setLocationData(context, payload) {
      context.commit('setLocationData', payload)
    }
  },
}

const regions = {
  namespaced: true,
  state: {
    regionsData: null
  },
  getters: {
    getRegions(state) {
      return state.regionsData
    }
  },
  mutations: {
    setRegions(state, payload) {
      state.regionsData = payload
    }
  },
  actions: {
    setRegions(context, payload) {
      context.commit('setRegions', payload)
    }
  },
}

const clientList = {
  namespaced: true,
  state: {
    clients: null
  },
  getters: {
    getClients(state) {
      return state.clients
    }
  },
  mutations: {
    setClients(state, payload) {
      state.clients = payload
    }
  },
  actions: {
    setClients(context, payload) {
      context.commit('setClients', payload)
    }
  },
}

const locations = {
  namespaced: true,
  state: {
    locationsData: null
  },
  getters: {
    getLocations(state) {
      return state.locationsData
    }
  },
  mutations: {
    setLocations(state, payload) {
      state.locationsData = payload
    }
  },
  actions: {
    setLocations(context, payload) {
      context.commit('setLocations', payload)
    }
  },
}

const qualification = {
  namespaced: true,
  state: {
    qualifications: null
  },
  getters: {
    getQualification(state) {
      return state.qualifications
    }
  },
  mutations: {
    setQualification(state, payload) {
      state.qualifications = payload
    }
  },
  actions: {
    setQualification(context, payload) {
      context.commit('setQualification', payload)
    }
  },
}

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    user: user,
    loading: loading,
    booking: booking,
    location: location,
    regions: regions,
    locations: locations,
    specialList: specialList,
    qualification: qualification,
    clientList: clientList,
    claimantConfirm: claimantConfirm
  },
  state: {
    // Admin
    currentLocation: null,
    originalObject: null,
    newObject: null,

    // Main
  },
  getters: {
    // Admin
    getCurrentLocation(state) {
      if (localStorage.getItem('currentLocation')) {
        state.currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
      }
      return state.currentLocation
    },
    getOriginalObject(state) {
      if (localStorage.getItem('originalObject')) {
        state.originalObject = JSON.parse(localStorage.getItem('originalObject'))
      }
      return state.originalObject
    },
    getNewObject(state) {
      if (localStorage.getItem('newObject')) {
        state.newObject = JSON.parse(localStorage.getItem('newObject'))
      }
      return state.newObject
    },

    // Main
  },
  mutations: {
    // Admin
    setCurrentLocation(state, payload) {
      localStorage.setItem('currentLocation', JSON.stringify(payload))
      state.currentLocation = Object.assign([], payload)
    },
    setOriginalObject(state, payload) {
      localStorage.setItem('originalObject', JSON.stringify(payload))
      state.originalObject = Object.assign([], payload)
    },
    setNewObject(state, payload) {
      localStorage.setItem('newObject', JSON.stringify(payload))
      state.newObject = Object.assign([], payload)
    },

    // Main
  },
  actions: {
    // Admin
    setCurrentLocation(context, payload) {
      context.commit('setCurrentLocation', payload)
    },
    setOriginalObject(context, payload) {
      context.commit('setOriginalObject', payload)
    },
    setNewObject(context, payload) {
      context.commit('setNewObject', payload)
    },

    // Main
  },
})

export default store
