/* eslint-disable */
<template>
  <div class="bookings-awaiting-dictation">
    <AdminNavigation ref="navigation" indexProps="0" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Bookings Awaiting Dictation</h1></div>
        <div class="header-sub-title">The following bookings are awaiting dictation.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactName"/>
              <el-select @change="selectPage(1)" style="max-width: 200px"  class="mr-1" v-model="listParam.Status" filterable  clearable>
                <el-option v-for="item in bookingStatuses" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 8%" class="long-text">Booking ID</th>
                    <th style="width: 18%" class="long-text">Client<img v-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(1)" />
                      <img v-else-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(2)"   /></th>
                    <th style="width: 15%" class="long-text">Contact<img v-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(3)" />
                      <img v-else-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(4)"   /></th>
                    <th style="width: 15%" class="long-text">Claimant<img v-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(5)" />
                      <img v-else-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(6)"   /></th>
                    <th style="width: 12%" class="long-text">Booking Date</th>
                    <th style="width: 12%" class="long-text">Booking Status</th>
                    <th style="width: 15%" class="long-text">Tracking Status</th>
                    <th style="width: 5%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="bookings.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(booking, index) in bookings" :key="booking.id" class="is-icon">
                    <td style="width: 8%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">{{ booking.id }}</router-link>
                    </td>
                    <td style="width: 18%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clientId } }">{{ booking.clientName }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clientId, type: 'edit-contact' } }">{{ booking.contactFullName }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: booking.caseId } }">{{ booking.claimantFullName }}</router-link>
                    </td>
                    <td style="width: 12%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">{{ formatDate(booking.bookingDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss") }}</router-link>
                    </td>
                    <td style="width: 12%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">{{ getBookingStatus(booking.bookingStatus) }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }">{{ booking.trackingStatus }}</router-link>
                    </td>
                    <td style="width: 5%">
                      <div class="is-icon">
                        <a :href="booking.zohoLink" target="_blank"><img src="@/assets/images/zoho_icon.svg" data-placement="right" data-toggle="tooltip" title="Link to Zoho" /></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminBookingsAwaitingDictation",
  components: {
    AdminNavigation,
    Header,
  },
  data() {
    return {
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),

      byClient: false,
      byContact: false,
      byClaimant: false,
      byDate: false,
      byBookingStatus: false,
      byTrackingStatus: false,
      totalCount: 0,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
        FromDate: null,
        toDate: null,
        ClientName: '',
        SpecialistName: '',
        ContactName: '',
        TrackStatus: utilities.AWAITING_DICTATION
      },
      bookingStatuses: [],
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },
    sortParam(v) {
      this.listParam.SortFlag = v
      this.selectPage(1)
    },
    formatDate(dateToFormat, newFormat, currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    getBookingStatus(id) {
      return utilities.getBookingStatus(id)
    },

    async loadBookings() {
      this.$store.dispatch("loading/setLoadComponent", true)
      var {bookings, total} = await utilities.getAllBookingsNew(this.listParam)
      this.bookings = _.cloneDeep(bookings)
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadData() {
      this.bookingStatuses = utilities.getBookingStatuses()
    },
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    await this.loadBookings()
    await this.loadData()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
