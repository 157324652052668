/* eslint-disable */
<template>
  <div class="specialist-bookings">
    <AdminNavigation ref="navigation" indexProps="3" />
    <div class="top-panel">
      <Header />
      <div v-if="bookings.specialist != undefined && bookings.specialist.id != ''" class="header-title-section">
        <div class="header-title"><h1>Bookings for {{ bookings.specialist.fullName }}</h1></div>
        <div class="header-sub-title">Completed Bookings.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistBookingsMenu indexProps="2" :specialistId="bookings.specialist.id" />
      <div class="content">
        <div v-if="bookings.specialist != undefined && bookings.specialist.id != ''">
          <div class="tool-bar">
            <div class="row">
              <div class="col"></div>
              <div class="col"></div>
              <div class="col"></div>
              <div class="col">
                <input type="search" id="search" class="textbox search-icon" placeholder="Search ..." />
              </div>
            </div>
          </div>
          <div class="box box-booking is-icon" @click="setViewStatus(index)" v-for="(booking, index) in bookings.bookingsDetails">
            <div class="box-heading travel-box-header">
              <div class="box-date-time w-80">
                Due: {{ booking.bookingDetails.dueDate != "" ? formatDate(booking.bookingDetails.dueDate, "DD/MM/YYYY") : 'Unknown' }}
              </div>
              <div>
                <span class="mr-5">{{ getBookingStatus(booking.bookingDetails.status) }}</span>
                <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.bookingDetails.id } }">
                  <img data-toggle="tooltip" data-placement="right" title="View Booking Details" class="img-small" src="@/assets/images/view_booking.svg" />
                </router-link>
              </div>
            </div>
            <div class="box-body hidden-element">
              <div class="box-col box-col-left-details">
                <span>Claimant: {{ booking.casesDetails.claimantFullName + ' ' + formatDate(booking.bookingDetails.dateCreated, "DD/MM/YYYY hh:mm A") }}</span>
                <span>Client: {{ booking.clientDetails.clientName }}</span>
                <span>Contact: {{ booking.clientContactDetails.fullName }}</span>
                <span>Ph: {{ booking.clientContactDetails.phoneNumber1 }}</span>
                <span>{{ getBookingType(booking.bookingDetails.typeId) + ' | ' + (booking.bookingDetails.fullBodyAreas.length > 0 ? (booking.bookingDetails.fullBodyAreas + ' | ') : '') + booking.casesDetails.claimTypeName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>This specialist could not be found.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSpecialistBookingsMenu from '@/components/AdminSpecialistBookingsMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'AdminSpecialistBookings',
    components: {
      AdminNavigation,
      AdminSpecialistBookingsMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: '',

        bookings: {
          specialist: utilities.initSpecialist(),
          bookingsDetails: [],
        },
        allBookingsDetails: [],

        bookingTypes: [],
        bookingStatuses: [],
      }
    },
    computed: {},
    methods: {
      setViewStatus(index) {
        utilities.setViewStatus(index)
      },
      formatDate(dateTime, newFormat) {
        return utilities.formatDate(dateTime, newFormat)
      },
      getBookingStatus(id) {
        return utilities.getBookingStatus(id)
      },
      getBookingType(id) {
        var bookingType = this.bookingTypes.find(x => x.id == id)
        return bookingType != undefined ? bookingType.name : ''
      },
      search(text) {
        if (text.trim() != '') {
          var bookingsDetails = search(this.allBookingsDetails, ["casesDetails.claimantFullName"], text)
          this.bookings.bookingsDetails = bookingsDetails
        } else {
          this.bookings.bookingsDetails = _.cloneDeep(this.allBookingsDetails)
        }
        setTimeout(() => {
          this.setViewStatus(0)
        }, 200)
      },

      async loadSpecialist() {
        this.bookings.specialist.id = this.$route.query.id != undefined ? this.$route.query.id : ''
        if (this.bookings.specialist.id != '') {
          this.bookings.specialist = await utilities.getSpecialistById(this.bookings.specialist.id)
        }
      },
      async loadData() {
        if (this.bookings.specialist != undefined && this.bookings.specialist.id != '') {
          var allBookingsDetails = await utilities.getSpecialistBookings({
            specialistId: this.bookings.specialist.id,
            // startDate: utilities.START_DATE
          })
          this.allBookingsDetails = allBookingsDetails.filter(x => Number(x.bookingDetails.status) == 15)
          this.bookings.bookingsDetails = _.cloneDeep(this.allBookingsDetails)
          this.bookingTypes = await utilities.getBookingTypes()
          this.bookingStatuses = utilities.getBookingStatuses()
        }
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadSpecialist()
      await this.loadData()
      this.setViewStatus(0)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      setTimeout(() => {
        $("#search").on('keypress', (e) => {
          if(e.keyCode == 13) {
            this.search(e.currentTarget.value)
          }
        })
      }, 500)
    },
  }
</script>
