/* eslint-disable */
<template>
  <div class="availability-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-availabilities', params: {} }">Upcoming Availability</router-link></li>
      <!-- <li><router-link :to="{ name: 'admin-manage-availability', params: {} }">Add Routine</router-link></li> -->
      <!-- <li><router-link :to="{ name: 'admin-search-availability', params: {} }">Search</router-link></li> -->
    </ul>
  </div>
</template>

<script>

export default {
  name: 'AdminAvailabilityMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
