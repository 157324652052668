/* eslint-disable */
<template>
  <div class="">
    <!-- <img src="@/assets/images/chat_icon.svg" /> -->
  </div>
</template>

<script>

export default {
  name: 'Chat',
  data() {
    return {

    }
  },
  methods: {

  },

  mounted() {
    var script = document.createElement('script')
    script.setAttribute('async', '')
    var data = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "3869199cd03d97ea1193d4f58ec9d80fc3393a740844b76966e3674047cbd12b", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com.au/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`
    script.append(data)
    $("head").append(script)
  }
}
</script>
