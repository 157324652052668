/* eslint-disable */
<template>
  <div class="navigation">
    <div class="navigation-content">
      <div class="navigation-section-1">
        <div class="navigation-icon">
          <router-link :to="{ name: 'management', params: {} }"><img src="@/assets/images/home_icon.svg" /></router-link>
        </div>
        <div class="navigation-icon navigation-red navigation-add dropdown">
          <img src="@/assets/images/add_large_icon.svg" class="navigation-add-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="control-from-home dropdown-menu" aria-labelledby="navigation-add-icon">
            <router-link class="dropdown-item" :to="{ name: 'admin-search-specialist', params: {} }">Add Booking</router-link>
            <!-- <router-link class="dropdown-item" :to="{ name: 'admin-manage-case', params: {} }">Add case</router-link> -->
            <router-link class="dropdown-item" :to="{ name: 'admin-manage-client', params: {} }">Add Client</router-link>
            <span class="dropdown-item" @click="openSelectClient">Add Client Contact</span>
            <router-link class="dropdown-item" :to="{ name: 'admin-manage-specialist', params: {} }">Add Specialist</router-link>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-client dropdown">
          <router-link :to="{ name: 'admin-clients', params: {} }"><img src="@/assets/images/client_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-clients', params: {} }">Clients</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-specialist dropdown">
          <router-link :to="{ name: 'admin-specialists', params: {} }"><img src="@/assets/images/specialist_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-specialists', params: {} }">Specialists</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-availability dropdown">
          <router-link :to="{ name: 'admin-availabilities', params: {} }"><img src="@/assets/images/availability_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-availabilities', params: {} }">Availability</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-booking dropdown">
          <router-link :to="{ name: 'admin-bookings', params: {} }"><img src="@/assets/images/calendar_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-bookings', params: {} }">Bookings</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-report dropdown">
          <router-link :to="{ name: 'admin-hours-booked', params: {} }"><img src="@/assets/images/report_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-hours-booked', params: {} }">Reports</router-link></div>
          </div>
        </div>
<!--        <div class="navigation-icon navigation-grey navigation-finance dropdown" v-if="user.type == 4 || user.type == 6">-->
<!--          <router-link :to="{ name: 'admin-purchase-orders', params: {} }"><img src="@/assets/images/finance_icon.svg" /></router-link>-->
<!--          <div class="dropdown-menu">-->
<!--            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-purchase-orders', params: {} }">Finance</router-link></div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="navigation-icon navigation-red navigation-search dropdown">
          <img src="@/assets/images/search_icon.svg" class="navigation-search-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="control-from-home dropdown-menu search-dropdown-submenu" aria-labelledby="navigation-search-icon">
            <div class="dropdown-item" @click="openSearchBooking">Bookings</div>
            <div class="dropdown-item" @click="openSearchCase">Cases</div>
            <div class="dropdown-item" @click="openSearchClaimant">Claimants</div>
            <div class="dropdown-item" @click="openSearchClient">Clients</div>
            <div class="dropdown-item" @click="openSearchClientContact">Client Contacts</div>
            <div class="dropdown-item" @click="openSearchSpecialist">Specialists</div>
            <div class="dropdown-item" @click="openSearchSpecialistAvailability">Specialist Availability</div>
          </div>
        </div>
      </div>
      <div class="navigation-section-2">
        <div v-if="user.type >= 6" class="navigation-icon navigation-grey navigation-setting dropdown">
          <router-link :to="{ name: 'admin-related-skills', params: {} }"><img src="@/assets/images/setting_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-related-skills', params: {} }">Settings</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-user dropdown" v-if="user.type == 3 || user.type == 4 || user.type == 6">
          <router-link :to="{ name: 'admin-users', params: {} }"><img src="@/assets/images/user_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" :to="{ name: 'admin-users', params: {} }">Users</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-red navigation-account dropdown">
          <img src="@/assets/images/account_icon.svg" class="navigation-account-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="navigation-account-icon">
            <router-link :to="{ name: 'change-password', params: {} }" class="dropdown-item">Change Password</router-link>
            <div class="dropdown-item" @click="logout">Logout</div>
          </div>
        </div>
      </div>
      <AdminGlobalSearch ref="globalSearch" />
    </div>
  </div>
</template>

<script>
import auth from '@/assets/js/cognitoAuth'
import utilities from '@/assets/js/utilities'
import AdminGlobalSearch from '@/components/AdminGlobalSearch'

export default {
  name: 'AdminNavigation',
  props: ["indexProps"],
  components: {
    AdminGlobalSearch,
  },
  data() {
    return {
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {
    openSearchClient() {
      this.$refs.globalSearch.openSearchClient()
    },
    openSearchClientContact() {
      this.$refs.globalSearch.openSearchClientContact()
    },
    openSearchClaimant() {
      this.$refs.globalSearch.openSearchClaimant()
    },
    openSearchSpecialist() {
      this.$refs.globalSearch.openSearchSpecialist()
    },
    openSearchSpecialistAvailability() {
      this.$router.push({ name: "admin-manage-availability" }).catch(err => {})
    },
    openSearchBooking() {
      this.$refs.globalSearch.openSearchBooking()
    },
    openSearchCase() {
      this.$refs.globalSearch.openSearchCase()
    },
    openSelectClient() {
      if(this.$route.fullPath.includes("/admin-manage-client")) {
        this.$parent.openManageContactModal()
      } else {
        this.$refs.globalSearch.openSelectClient()
      }
    },
    logout() {
      this.$store.dispatch("loading/setLoadComponent", true)
      utilities.clearStore()
      var callback = () => {
        this.$store.dispatch("user/setAuthentication", utilities.initAuthentication())
        setTimeout(() => {
          this.$router.push({ name: "login" }).catch(err => {})
          this.$store.dispatch("loading/setLoadComponent", false)
        }, 1000)
      }
      auth.logout(callback)
    },
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".navigation-icon").eq(this.indexProps).addClass("navigation-selected")
    $(".container > .global-search").remove()
    $(".global-search").closest(".container").append($(".global-search"))

    // Hover to show grey popup
    $(".navigation-grey img").hover(function() {
      $(".navigation-content").css("width", "285px")
    })
    // Hover outside to hide grey popup
    $(".navigation-grey .dropdown-menu").hover(function() {

    }, function() {
      $(".navigation-content").css("width", "58px")
    })
    // Click to open red popup
    $(".navigation-red img").click(function() {
      $(".navigation-content").css("width", "285px")
    })
    // Click outside red popup
    $(document).on("click", function(event){
      if(!$(event.target).closest(".navigation-red .dropdown-menu").length) {
        $(".navigation-content").css("width", "58px")
      }
    })
  },
}
</script>
