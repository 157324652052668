/* eslint-disable */
<template>
  <div class="specialist-travel">
    <SpecialistNavigation indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Travel</h1></div>
        <div class="header-sub-title">The following travel has been booked for your upcoming appointments.</div>
      </div>
    </div>
    <div class="panel">
      <SpecialistTravelMenu indexProps="0" />
      <div class="content">
        <div class="box" style="min-height: 0vh;" @click="setViewStatus(index)" v-for="(travel, index) in travels" :key="index">
          <div class="box-heading travel-box-header">
            <div class="is-icon">
              {{ dateFormat(travel.travelStartDate) + (travel.travelEndDate != '' ? " - " : "") + dateFormat(travel.travelEndDate) }}
              <span class="invisible-element">{{ travel.specialistTravelItineraryId }}</span>
            </div>
            <div>
              <img
                data-toggle="tooltip"
                data-placement="right"
                title="View Travel Details"
                class="img-small"
                src="@/assets/images/view_booking.svg"
                @click.stop="routeToDetails(travel.specialistTravelItineraryId, travel.specialistAvailabilityId)"
                style="cursor:pointer" />
            </div>
          </div>
          <div class="box-body hidden-element">
            <div class="box-col box-col-left-details" v-for="(item, index) in travel.specialistTravelItems" :key="index">
              <span v-if="item.airlineName.length > 0">
                Flight {{ item.flightNumber }} Departing {{ item.departingAirportName }} {{ timeFormat(item.travelStartTime) }} {{ dateFormat(item.travelStartDate) }}
                Arriving {{ item.arrivingAirportName }} Seat: {{ item.seatNumber }} Booking Ref: {{ item.bookingReference }}
              </span>
              <span v-if="item.carHireName.length > 0">{{ item.carHireName }} Booking Ref: {{ item.bookingReference }} Pickup: {{ timeFormat(item.travelStartTime) }}</span>
              <span v-if="item.accommodationName.length > 0">{{ item.accommodationName }}, {{ item.accommodationAddress }}, {{ item.accommodationPhoneNumber }} Booking Ref: {{ item.bookingReference }} Check-in {{ timeFormat(item.travelStartTime) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistTravelMenu from '@/components/SpecialistTravelMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'

  export default {
    name: 'SpecialistTravel',
    components: {
      SpecialistNavigation,
      SpecialistTravelMenu,
      Header,
      Chat,
    },
    data() {
      return {
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        travels: [],
      }
    },
    methods: {
      setViewStatus(index) {
        utilities.setViewStatus(index)
      },

      async loadTravels() {
        var today = new Date()
        var specialistId = 1
        var travels = await utilities.getSpecialistTravelItinerary({ specialistId: this.user.specialistId, date: moment().format('YYYY-MM-DD'), runType:3 })

        var dates = this.travels.filter(x => x.travelStartDate != '').map(x => { return new Date(x.travelStartDate) })
        var maxDate = moment(new Date(Math.max.apply(null, dates))).add(1, 'd').format("YYYY-MM-DD")
        travels = utilities.sortByDate(travels, 'travelStartDate', true, maxDate)
        travels = _.uniqBy(travels, 'specialistTravelItineraryId')
        travels.map((x) => {
          for (var i = 0; i < x.specialistTravelItems.length; i++) {
            var item = x.specialistTravelItems[i]
            item.travelStartDateTime = moment(item.travelStartDate + ' ' + item.travelStartTime, "YYYY-MM-DD HH:mm:ss.SSSSSS").format('YYYY-MM-DD HH:mm:ss')
          }

          x.specialistTravelItems.sort((a,b) => {
            return moment(a.travelStartDateTime, "YYYY-MM-DD HH:mm:ss").isAfter(moment(b.travelStartDateTime, "YYYY-MM-DD HH:mm:ss")) ? 1:-1
          })
        })
        this.travels = travels
      },

      dateFormat(date) {
        var date = utilities.formatDate(date, 'DD/MM/YYYY')
        return date
      },

      timeFormat(time) {
        var time = utilities.formatDate(time, 'HH:mm A', 'HH:mm:ss')
        return time
      },

      routeToDetails(id, availabilityId) {
        this.$router.push({ path: '/specialist-travel-detail', query: { id: id, availabilityId: availabilityId } }).catch((err) => {})
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadTravels()
      utilities.initTooltip()
      utilities.setViewStatus(0)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      window.scrollTo(0, 0)
    },
  }
</script>
