/* eslint-disable */
<template>
  <div class="enquiry p-0">
    <div class="red-vertical"></div>
    <div class="unlogin-container">
      <div class="content">
        <div class="d-flex">
          <div class="logo">
            <img src="@/assets/images/red_health_logo.svg" class="img-fluid" />
          </div>
          <div class="header-title-section">
            <div class="header-title"><h1>Client Account Request</h1></div>
            <div class="header-sub-title">To request access to the Red Health Online System, so that you can submit requests for independent medical examinations, please complete the form below.</div>
          </div>
        </div>
        <div class="box">
          <div class="box-heading col-space-between">
            <div>Client Request Details</div>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">First Name <span class="text-danger">*</span></div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.firstName" class="pl-3 w-100" :class="hasError && !validateFirstName ? 'is-invalid' : ''" placeholder="First Name" />
                <div v-if="hasError && !validateFirstName" class="text-danger">First Name is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Surname <span class="text-danger">*</span></div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.surname" class="pl-3 w-100" :class="hasError && !validateSurname ? 'is-invalid' : ''" placeholder="Surname" />
                <div v-if="hasError && !validateSurname" class="text-danger">Surname is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Business Email Address <span class="text-danger">*</span></div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.email" class="pl-3 w-100" :class="hasError && !validateEmail ? 'is-invalid' : ''" placeholder="Business Email Address" />
                <div v-if="hasError && !validateEmail" class="text-danger">{{ emailErrorMessage }}</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">
                Mobile Number <span class="text-danger">*</span>
                <div class="mt-1">(This is required for security reasons)</div>
              </div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.mobile" class="pl-3 w-100" :class="hasError && !validateMobile ? 'is-invalid' : ''" placeholder="Mobile Number" />
                <div v-if="hasError && !validateMobile" class="text-danger">{{ mobileErrorMessage }}</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Company Name <span class="text-danger">*</span></div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.companyName" class="pl-3 w-100" :class="hasError && !validateCompanyName ? 'is-invalid' : ''" placeholder="Company Name" />
                <div v-if="hasError && !validateCompanyName" class="text-danger">Company Name is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Company Branch Location</div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.companyBranchName" class="pl-3 w-100" placeholder="Company Branch Location" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Business Address</div>
              <div class="box-col box-col-right">
                <input type="text" v-model="clientRequest.addressLine1" class="pl-3 w-100" placeholder="Address 1" />
                <input type="text" v-model="clientRequest.addressLine2" class="pl-3 w-100" placeholder="Address 2" />
                <div class="pl-3 w-50 d-flex">
                  <div class="w-15">City</div>
                  <div class="w-75">
                    <input type="text" v-model="clientRequest.city" class="pl-3 w-100" placeholder="City" />
                  </div>
                </div>
                <div class="pl-3 d-flex">
                  <div class="w-50 d-flex">
                    <div class="w-15">State <span class="text-danger">*</span></div>
                    <div class="w-75">
                      <select v-model="clientRequest.state" class="pl-3 textbox w-100" :class="hasError && !validateState ? 'is-invalid' : ''">
                        <option hidden value="">Please choose</option>
                        <option v-for="(state, index) in states" :value="state.value">{{ state.text }}</option>
                      </select>
                      <div v-if="hasError && !validateState" class="text-danger">State is required.</div>
                    </div>
                  </div>
                  <div class="w-50 d-flex">
                    <div class="w-15">Postcode <span class="text-danger">*</span></div>
                    <div class="w-75">
                      <input v-model="clientRequest.postcode" type="number" class="pl-3 w-100 postcode" :class="hasError && !validatePostcode ? 'is-invalid' : ''" placeholder="Postcode" />
                      <div v-if="hasError && !validatePostcode" class="text-danger">{{ postcodeErrorMessage }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>

        <div class="icon-group right-float-element">
          <v-btn dark @click="submit()">Submit</v-btn>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import Footer from '@/components/Footer'

  export default {
    name: 'Enquiry',
    components: {
      Footer
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        emailErrorMessage: '',
        mobileErrorMessage: '',
        postcodeErrorMessage: '',

        clientRequest: utilities.initClientRequest(),
        states: [],
      }
    },
    computed: {
      validateFirstName() {
        if (this.clientRequest.firstName == '') {
          return false
        }
        return true
      },
      validateSurname() {
        if (this.clientRequest.surname == '') {
          return false
        }
        return true
      },
      validateEmail() {
        if (this.clientRequest.email == '') {
          this.emailErrorMessage = "Email is required."
          return false
        } else if(!utilities.validateEmail(this.clientRequest.email)) {
          this.emailErrorMessage = "Email is invalid."
          return false
        }
        return true
      },
      validateMobile() {
        if (this.clientRequest.mobile == '') {
          this.mobileErrorMessage = "Mobile is required."
          return false
        } else if(!utilities.validatePhoneAU(this.clientRequest.mobile)) {
          this.mobileErrorMessage = "Please enter a valid mobile number in International format without spaces - i.e. +61400123456"
          return false
        }
        return true
      },
      validateCompanyName() {
        if (this.clientRequest.companyName == '') {
          return false
        }
        return true
      },
      validateState() {
        if (this.clientRequest.state == '') {
          return false
        }
        return true
      },
      validatePostcode() {
        if (this.clientRequest.postcode == '') {
          this.postcodeErrorMessage = "Postcode is required."
          return false
        } else if (this.clientRequest.postcode.length != 4) {
          this.postcodeErrorMessage = "Postcode is invalid."
          return false
        }
        return true
      },
    },
    methods: {
      async submit() {
        if(!this.validateFirstName || !this.validateSurname || !this.validateEmail || !this.validateMobile
           || !this.validateCompanyName || !this.validateState || !this.validatePostcode) {
             this.hasError = true
             utilities.showAlert(".alert-danger")
             return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          FirstName: this.clientRequest.firstName,
          Surname: this.clientRequest.surname,
          BusinessEmailAddress: this.clientRequest.email,
          Mobile: this.clientRequest.mobile,
          CompanyName: this.clientRequest.companyName,
          CompanyBranchName: this.clientRequest.companyBranchName,
          AddressLine1: this.clientRequest.addressLine1,
          AddressLine2: this.clientRequest.addressLine2,
          City: this.clientRequest.city,
          State: this.clientRequest.state,
          Postcode: this.clientRequest.postcode,
          Country: this.clientRequest.country,
          DateReceived: moment().format("YYYY-MM-DD"),
          IsDeleted: 0,
        }
        var result = await utilities.postClientRequest(params)
        if(result) {
          // this.successMessage = "Client request created successfully."
          // utilities.showAlert(".alert-success")
          $(".icon-group button").attr("disabled", true)
          setTimeout(() => {
            this.$router.push({ name: 'login', params: { messageProps: 'Thank you for submitting your details.  We will be in touch shortly.' } })
          }, 200)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      loadData() {
        this.states = utilities.getStates()
      },
    },
    beforeMount() {
      this.loadData()
    },
    mounted() {
      $(".postcode").on('input', (e) => {
        if(this.clientRequest.postcode > 4) {
          this.clientRequest.postcode = this.clientRequest.postcode.slice(0, 4)
        }
      })
    },
  }
</script>
