/* eslint-disable */
<template>
  <div class="manage-specialist">
    <Navigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title">Create Specialist</div>
    </div>
    <div class="panel">
      <SpecialistMenu indexProps="0" />
      <div class="content">
        <div class="top-detail">
          <div class="row">
            Specialists can be engaged by Clients to provides services. Each Specialist has a profile to help promote them to Clients.
          </div>
        </div>

        <div class="box">
          <div class="box-heading">Specialist Details</div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col-1">Title</div>
              <div class="box-col-2">
                <select class="textbox">
                  <option hidden value="">Please choose ...</option>
                  <option v-for="(title, index) in titles" :value="title.id">{{ title.name }}</option>
                </select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col-1">First Name</div>
              <div class="box-col-2">
                <input type="text" placeholder="First name" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import SpecialistMenu from '@/components/SpecialistMenu'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Chat from '@/components/Chat'

export default {
  name: 'ManageSpecialist',
  components: {
    Navigation,
    SpecialistMenu,
    Header,
    Chat,
  },
  data() {
    return {
      specialist: {},
      titles: [
        {
          id: 1,
          name: "Mr",
        },
        {
          id: 2,
          name: "Ms",
        },
      ],
    }
  },
  methods: {

  },
  mounted() {

  },
}
</script>
