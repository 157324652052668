/* eslint-disable */
<template>
  <div class="clients">
    <AdminNavigation ref="navigation" indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Clients ({{ totalCount }})</h1></div>
        <div class="header-sub-title">Click to view the details of a client, view a list of cases, or add a booking to a client. You can also click through to the client in Zoho.</div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu :key="clientMenuIndex" :indexProps="clientMenuIndex" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <v-btn class="add-icon right-float-element" dark>
              <router-link :to="{ name: 'admin-manage-client', query: {} }">Add New</router-link>
            </v-btn>
            <div class="d-flex align-items-center justify-content-end">
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName" />
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Branch Name" v-model="listParam.BranchName" />
              <el-input style="max-width: 200px" class="mr-1"  @input="search()" clearable placeholder="Contact FullName" v-model="listParam.ContactFullName" />
            </div>
          </div>
        </div>

        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 5%" class="long-text">
                      Client ID
                    </th>
                    <th style="width: 15%" class="long-text">
                      Name
                      <img v-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortId(1)" />
                      <img v-else-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortId(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortId(2)" />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Branch Name
                      <img v-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortId(3)" />
                      <img v-else-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortId(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortId(4)" />
                    </th>
                    <th style="width: 20%" class="long-text">Address</th>
                    <th style="width: 15%" class="long-text">
                      Branch Admins
                    </th>
                    <th style="width: 15%" class="long-text">
                      Contacts
                    </th>
                    <th style="width: 10%" class="icon-col">&nbsp;</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="clients.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                  <tr v-for="(client, index) in clients" :key="client.id" class="is-icon">
                    <td style="width: 5%" class="long-text" @click="openClient(client.id)">{{ client.id }}</td>
                    <td style="width: 15%" class="long-text" @click="openClient(client.id)">
                      <div class="long-text" data-toggle="tooltip" data-placement="right" :title="client.name">
                        <span>{{ client.name }}</span>
                      </div>
                    </td>
                    <td style="width: 15%" class="long-text" @click="openClient(client.id)">
                      <div class="long-text" data-toggle="tooltip" data-placement="right" :title="client.branchName">
                        <span>{{ client.branchName }}</span>
                      </div>
                    </td>
                    <td style="width: 20%" class="long-text" @click="openClient(client.id)">
                      <div class="long-text" data-toggle="tooltip" data-placement="right" :title="client.fullAddress">
                        <span>{{ client.fullAddress }}</span>
                      </div>
                    </td>
                    <td style="width: 15%" class="long-text" @click="openClient(client.id)">
                      <div class="long-text" data-toggle="tooltip" data-placement="right" :title="client.branchAdminList">
                        <span>{{ client.branchAdminList }}</span>
                      </div>
                    </td>
                    <td style="width: 15%" class="long-text" @click="openClient(client.id)">
                      <div class="long-text" data-toggle="tooltip" data-placement="right" :title="client.contacts.map(x=>x.fullName).join(', ')">
                        <span>{{ client.contacts.map(x => x.fullName).join(", ") }}</span>
                      </div>
                    </td>
                    <td style="width: 10%" class="icon-col">
                      <div class="icon-group">
                        <!-- <router-link :to="{ name: 'admin-create-new-booking', query: { caseId: this.singleCase.id }}" -->
                        <router-link :to="{ name: 'admin-cases', query: { id: client.id } }"><img data-toggle="tooltip" data-placement="right" title="Cases" src="@/assets/images/case_dark_icon.svg"/></router-link>
                        <router-link :to="{ name: 'admin-search-specialist', query: { clientId: client.id } }"><img data-toggle="tooltip" data-placement="right" title="Add Booking" src="@/assets/images/add_booking_dark_icon.svg"/></router-link>
                        <a :href="client.zohoLink" target="_blank"><img data-toggle="tooltip" data-placement="right" title="View on Zoho" src="@/assets/images/zoho_icon.svg"/></a>
                        <EditZohoLinkDialog @updateSuccess="updateSuccess" :id="client.id" :index="index" type="client"/>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" :key="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import { debounce } from 'lodash'
  import EditZohoLinkDialog from "@/components/dialog/EditZohoLinkDialog";
  export default {
    name: 'AdminClients',
    props: ["searchOptionClientProps", "searchOptionContactProps"],
    components: {
      AdminNavigation,
      Header,
      AdminClientsMenu,
      EditZohoLinkDialog
    },
    data() {
      return {
        fetchedData: false,
        hasError: false,
        successMessage: '',
        errorMessage: '',
        clients: [],
        allClients: [],
        limit: 20,
        pagination: utilities.initPagination(),
        clientsNumber: 0,
        searchTerm: '',
        totalCount: 0,
        clientMenuIndex: 0,
        listParam: {
          PageSize: 10,
          PageNum: 1,
          SortFlag: 0,
          ContactFullName: null,
          ClientName: null,
          BranchName: null
        },
        byName: false,
        byBranch: false,
        byId: false,
      }
    },
    methods: {
      search: debounce(function(){
        this.selectPage(1)
      },800),
      selectPage(index) {
        this.listParam.PageNum = index
        this.loadClients()
      },
      sortId(v) {
        this.listParam.SortFlag = v
        this.listParam.PageNum = 1
        this.loadClients()
      },
      updateSuccess(index, newLink) {
        let obj = _.cloneDeep(this.clients[index])
        obj.zohoLink = newLink
        this.$set(this.clients, index, obj)
      },
      openSearchClient() {
        this.$refs.navigation.$refs.globalSearch.openSearchClient()
      },
      openSearchClientContact() {
        this.$refs.navigation.$refs.globalSearch.openSearchClientContact()
      },
      openClient(id) {
        this.$router.push({ name: 'admin-manage-client', query: { id: id } })
      },
      paging(clients) {
        utilities.disposeTooltip()
        if (clients.length == 0 || clients.length <= this.limit) {
          this.clients = clients
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(clients, this.limit)
          this.clients = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
        this.clientsNumber = clients.length
        utilities.initTooltip()
        utilities.hoverTooltip()
      },
      async searchClient(searchOption) {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.listParam = {
          ...this.listParam,
          ...searchOption
        }
        this.clients = []
        var {clients, total} = await utilities.getClientsNew(this.listParam)
        this.clients = _.cloneDeep(clients)
        this.totalCount = total
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async searchClientContact(searchOption) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          Name: searchOption.name,
        }
        this.clients = []
        var clients = await utilities.getSearchClientContacts(params)
        this.allClients = _.cloneDeep(clients)
        this.paging(clients)
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async loadClients() {
        this.$store.dispatch('loading/setLoadComponent', true)
        const {clients, total} = await utilities.getClientsNew(this.listParam)
        this.clients = _.cloneDeep(clients)
        this.totalCount = total
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      $('body>.tooltip').remove()
      if(this.searchOptionClientProps != undefined) {
        await this.searchClient(this.searchOptionClientProps)
      } else if(this.searchOptionContactProps != undefined) {
        await this.searchClientContact(this.searchOptionContactProps)
      } else {
        await this.loadClients()
      }
      this.fetchedData = true

    },
    mounted() {
      $(".pagination").hide()
    },
  }
</script>
