/* eslint-disable */
<template>
  <div class="next-availability-specialist">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Next Availability by Specialist</h1></div>
        <div class="header-sub-title">Choose a Specialist to view their next available dates & location, in date order.</div>
      </div>
    </div>
    <div class="panel">
      <AdminAvailabilityMenu indexProps="2" />
      <div class="content">
        <div class="box">
          <div class="box-heading"><span>Search</span></div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Choose Specialist</div>
              <div class="box-col box-col-right">
                <!-- <select class="textbox" v-model="searchOption.specialistId" @change="changeSpecialist">
                  <option hidden value="">Please choose ...</option>
                  <option v-for="(specialist, index) in specialists" :value="specialist.id">{{ specialist.fullName }}</option>
                </select> -->
                <Dropdown
                  ref="specialistDropdown"
                  class="select-dropdown w-50 pr-1"
                  :options="listSpecialists"
                  v-on:selected="selectedSpecialist"
                  v-on:filter="filterSpecialist"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000"
                  placeholder="Select a specialist">
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 20%" class="long-text">Date<img class="sort-icon" :src="!byDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDate" /></th>
                    <th style="width: 20%" class="long-text">Next Available<img class="sort-icon" :src="!byTime ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortTime" /></th>
                    <th style="width: 20%" class="long-text">No of Appointments<img class="sort-icon" :src="!byAppointment ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortAppointment" /></th>
                    <th style="width: 35%" class="long-text">Location<img class="sort-icon" :src="!byLocation ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortLocation" /></th>
                    <th style="width: 5%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="availabilities.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(availability, index) in availabilities" class="is-icon">
                    <td style="width: 20%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ formatDate(availability.availabilityDate) }}
                    </td>
                    <td style="width: 20%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ formatDate(availability.nextAvailableTime, "hh:mm A", "HH:mm:ss") }}
                    </td>
                    <td style="width: 20%" class="long-text" @click="openAvailability(availability.specialistId)">
                      <div class="w-50">
                        {{ availability.numberTimeSlots - availability.numberTimeSlotsBooked }}
                        <div v-if="availability.videoLinkAvailable == 2" class="is-icon right-float-element" data-placement="right" data-toggle="tooltip" title="Video Link Available">
                          <img src="@/assets/images/video_black_icon.svg" />
                        </div>
                      </div>
                    </td>
                    <td style="width: 35%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ availability.fullLocation }}
                    </td>
                    <td style="width: 5%" class="icon-col">
                      <div class="icon-group">
                        <router-link :to="{ name: 'admin-availability-bookings', query: { id: availability.specialistId, date: availability.availabilityDate } }">
                          <img src="@/assets/images/calendar_black_icon.svg" data-placement="right" data-toggle="tooltip" title="View bookings" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''"
                  v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu"
import Header from "@/components/Header"
import Dropdown from 'vue-simple-search-dropdown';

export default {
  name: "AdminNextAvailabilitySpecialist",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    Header,
    Dropdown
  },
  data() {
    return {
      searchOption: {
        specialistId: "",
      },

      availabilities: [],
      allAvailabilities: [],
      limit: 10,
      pagination: utilities.initPagination(),

      specialists: [],
      listSpecialists: [],
      originalListSpecialists: [],

      byDate: false,
      byTime: false,
      byAppointment: false,
      byLocation: false,
    }
  },
  computed: {},
  methods: {
    filterSpecialist(text) {
      this.listSpecialists = this.originalListSpecialists.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    formatDate(date, newFormat = "DD/MM/YYYY", currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(date, newFormat, currentFormat)
    },
    selectPage(index) {
      this.pagination.current = index
      this.availabilities = this.pagination.list[this.pagination.current]
    },
    sortDate() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byDate) {  // ASC
          return moment(a.availabilityDate) > moment(b.availabilityDate) ? 1 : -1
        } else { // DESC
          return moment(a.availabilityDate) < moment(b.availabilityDate) ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byDate = !this.byDate
    },
    sortTime() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byTime) {  // ASC
          return moment(a.startTime) > moment(b.startTime) ? 1 : -1
        } else { // DESC
          return moment(a.startTime) < moment(b.startTime) ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byTime = !this.byTime
    },
    sortAppointment() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byAppointment) {  // ASC
          return a.numberTimeSlotsBooked > b.numberTimeSlotsBooked ? 1 : -1
        } else { // DESC
          return a.numberTimeSlotsBooked < b.numberTimeSlotsBooked ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byAppointment = !this.byAppointment
    },
    sortLocation() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byLocation) {  // ASC
          return a.fullLocation > b.fullLocation ? 1 : -1
        } else { // DESC
          return a.fullLocation < b.fullLocation ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byLocation = !this.byLocation
    },
    openAvailability(id) {
      this.$router.push({ name: 'admin-manage-availability', query: { id: id } })
    },
    paging(availabilities) {
      utilities.disposeTooltip()
      if(availabilities.length == 0 || availabilities.length <= this.limit) {
        this.availabilities = availabilities
        $(".pagination").hide()
      } else {
        this.pagination = utilities.loadPagination(availabilities, this.limit)
        this.availabilities = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      }
      utilities.initTooltip()
    },
    selectedSpecialist(item) {
      if (item.id) {
        this.searchOption.specialistId = item.id
        this.changeSpecialist()
      }
    },

    async changeSpecialist() {
      this.$store.dispatch("loading/setLoadComponent", true)

      var params = {
        StartDate: moment().format("YYYY-MM-DD")
      }

      var availabilities = await utilities.getSpecialistAvailabilities(this.searchOption.specialistId, "", params)
      this.allAvailabilities = _.cloneDeep(availabilities)
      this.paging(availabilities)

      this.$store.dispatch("loading/setLoadComponent", false)
    },
    formatDate(date, newFormat = "DD/MM/YYYY", currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(date, newFormat, currentFormat)
    },

    async loadData() {
      this.specialists = await utilities.getSpecialists()
      this.originalListSpecialists = _.cloneDeep(utilities.getDropdownSpecialist(this.specialists))
      this.listSpecialists = this.originalListSpecialists

      setTimeout(() => {
        $(".dropdown-input").attr({
         autocomplete: "off",
        });
      }, 1000)

    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $('body>.tooltip').remove()
    await this.loadData()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
