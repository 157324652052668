/* eslint-disable */
<template>
  <div class="settings">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Settings</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <!-- Submenu here -->
      <div class="content content-settings d-flex flex-column align-items-center pt-5">
        <div class="row settings-box-row pt-5 mt-5">
          <router-link to="/admin-related-skills">
          <div class="settings-box">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/related_skills_black_icon.svg"/>
              <div class="pt-5 mt-5">Related Skills</div>
            </div>
          </div>
          </router-link>
          <router-link to="/admin-related-services">
          <div class="settings-box ml-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/related_services_black_icon.svg"/>
              <div class="pt-5 mt-5">Related Services</div>
            </div>
          </div>
          </router-link>
        </div>
        <div class="row settings-box-row">
          <router-link to="/admin-locations-and-regions">
          <div class="settings-box">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/locations_black_icon.svg"/>
              <div class="pt-5 mt-5">Locations</div>
            </div>
          </div>
          </router-link>
          <router-link to="#!">
          <div class="settings-box ml-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/finance_black_icon.svg"/>
              <div class="pt-5 mt-5">Billing Categories</div>
            </div>
          </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'AdminSettings',
    components: {
      AdminNavigation,
      SpecialistMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
      }
    },
    computed: {},
    methods: {},
    async beforeMount() {},
    mounted() {},
  }
</script>
