<template>
  <el-dialog
      class="edit-zoho-dialog"
      title="Booking Detail"
      :visible.sync="dialogVisible"
      @close="closeModal"
      width="50%">
    <el-descriptions v-if="booking" class="margin-top" title="" :column="2"  border>
      <el-descriptions-item>
        <template slot="label">
          Booking ID
        </template>
        {{ booking.bookingDetails.id }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Booking Type
        </template>
        {{ booking.bookingDetails.bookingTypeName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Booking Date
        </template>
        {{getDate()}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Booking Status
        </template>
        {{getBookingStatusById(booking.bookingDetails.status)}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Client Name
        </template>
        {{booking.clientDetails.clientName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Branch Name
        </template>
        {{ booking.clientDetails.branchName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Claimant Preferred Name
        </template>
        {{ booking.bookingDetails.PreferredGivenName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
        Body Areas
        </template>
        {{ booking.bookingDetails.fullBodyAreas }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Specialist Name
        </template>
        {{ booking.specialistDetails.fullName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Location
        </template>
        {{ booking.location.shortLocation }}
      </el-descriptions-item>
    </el-descriptions>
    <span slot="footer" class="dialog-footer">
       <v-btn dark class="me-2"  @click="toDetail(booking)">View Detail</v-btn>
       <v-btn class="white-button" dark @click="dialogVisible = false; closeModal()">Close</v-btn>
    </span>
  </el-dialog>
</template>

<script>
import utilities from "@/assets/js/utilities";

export default {
  name: "BookingModal.vue",
  props: ['booking'],
  watch: {
    booking: {
      handler(value) {
        if(value) {
          this.dialogVisible = true
        }
      },
      deep: true,
    },
  },
  methods: {
    toDetail(item) {
      this.dialogVisible = false;
      this.$router.push({path:'/admin-manage-booking', query:{id: item.bookingDetails.id}}).catch((err) => {})
    },
    closeModal() {
      this.$emit('closeModal')
    },
    getDate() {
     return moment(this.booking.bookingDetails.bookingDateTime).format('YYYY-MM-DD')
    },
    getBookingStatusById(id) {
      if (id) {
        const status = this.bookingStatuses.find((x) => x.id == id)
        if (status) {
          return status.name
        }
      }
      return ""
    },
  },
  data() {
    return {
      dialogVisible: false,
      bookingStatuses: utilities.getBookingStatuses(),
    }
  }
}
</script>

<style scoped>

</style>