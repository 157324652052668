<template>
  <div class="add-note-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive alert-modal">
            Your booking has been confirmed. You will receive an email confirmation and we will be in touch shortly to help co-ordinate the phone call. Add to my Calendar:
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AlertModal",
  data() {
    return {
      loadModal: false,

      note: "",
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
  },
  mounted() {

  },
  updated() {

  },
}
</script>
