/* eslint-disable */
<template>
  <div class="specialist-schedule-pdf">
    <div :key="dataKey" class="pdf-panel">
      <div class="pdf-content p-2" v-if="bookings.length > 0">
        <div class="p-1">
          <div class="pdf-header">
            <div class="row">
              <div class="col-4">
                <strong>{{ formatDate(bookings[0].bookingDetails.dateTime, 'dddd, DD MMMM YYYY') }}</strong> <br />
                <b>{{ specialist.fullName }}</b> <br />
              </div>
              <div class="col-4">
                <span class="increased-line-height"><strong>Red Health: </strong>Dictation@redhealth.com.au<br /></span>
                PO BOX 10362 Adelaide Street,<br />
                Brisbane QLD 4000<br />
                <span class="increased-line-height"><strong>Phone:</strong> 1300 100 733<br /></span>
              </div>
              <div class="col-4">
                <img class="img-fluid" src="@/assets/images/red_health_logo.svg" />
              </div>
            </div>
          </div>
        </div>
        <div v-for="(booking, index) in bookings" :key="index" class="my-3 break-page">
          <div class="row m-0 mb-2">
            {{ booking.location.name + ', ' }}
            {{ booking.location.address1 }} <span v-if="booking.location.address1 !== ''" >, </span>
            {{ booking.location.address2 }} <span v-if="booking.location.address2 !== ''" >, </span>
            {{ booking.location.cityName + ', ' }}
            {{ booking.location.state + ', '}}
            {{ booking.location.postcode }}
          </div>
          <div class="row m-0">
            <div class="col-4 border px-2 py-1">
              <div>
                <a v-if="pathProps != undefined && pathProps != ''" :href="pathProps + '?id=' + booking.bookingDetails.id" target="_blank">
                  {{ formatTime(booking.bookingDetails.dateTime) }} - #{{ booking.bookingDetails.id }} - {{ getBookingTypeName(booking.bookingDetails.typeId) }}
                </a>
                <span v-else>{{ formatTime(booking.bookingDetails.dateTime) }} - #{{ booking.bookingDetails.id }} - {{ getBookingTypeName(booking.bookingDetails.typeId) }}<span v-if="booking.bookingDetails.videoLinkUrl != ''"> - <a :href="booking.bookingDetails.videoLinkUrl" target="_blank">Join Video Link</a></span></span>
              </div>
              <div v-if="booking.bookingDetails.surrogateDetails != ''">
                <div><b style="background: #FFFF00">Surrogate details</b></div>
                <div style="word-wrap: break-word;">
                  <span style="background: #FFFF00">{{ booking.bookingDetails.surrogateDetails }}</span>
                </div>
              </div>
            </div>
            <div class="col-4 border px-2 p-1 text-break">
              <div>
                <div><b>Interpreter details</b></div>
                <div style="word-wrap: break-word">{{ booking.bookingDetails.interpreterDetails }}</div>
              </div>
            </div>
            <div class="col-4 border px-2 p-1 text-break">
              <div v-if="booking.bookingDetails.ClaimantLocation">
                <div><b style="background: #FFFF00">Claimant location</b></div>
                <div style="word-wrap: break-word"><span style="background: #FFFF00">{{ booking.bookingDetails.ClaimantLocation }}</span></div>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 border px-2 py-1"><b>Claimant</b></div>
            <div class="col-2 border px-2 py-1"><b>Claimant Preferred Name</b></div>
            <div class="col-2 border px-2 py-1"><b>Referring Client</b></div>
            <div class="col-2 border px-2 py-1"><b>Contact</b></div>
            <div class="col-4 border px-2 py-1"><b>Injury / Appointment details</b></div>
          </div>
          <div class="row m-0">
            <div class="col-2 border px-2 py-1">
              {{ booking.casesDetails.claimantFullName }}
              <div v-if="booking.casesDetails.claimantMobile">PH: {{ booking.casesDetails.claimantMobile }}</div>
              <div v-else-if="booking.casesDetails.claimantPhone1">PH: {{ booking.casesDetails.claimantPhone1 }}</div>
              <div v-else-if="booking.casesDetails.claimantPhone2">PH: {{ booking.casesDetails.claimantPhone2 }}</div>
            </div>
            <div class="col-2 border px-2 py-1">{{ booking.bookingDetails.PreferredGivenName || '' }}</div>
            <div class="col-2 border px-2 py-1">{{ booking.clientDetails.clientName }}</div>
            <div class="col-2 border px-2 py-1">
              {{ booking.clientContactDetails.fullName }}
              <div v-if="booking.clientContactDetails.mobile">PH: {{ booking.clientContactDetails.mobile }}</div>
              <div v-else-if="booking.clientContactDetails.phoneNumber1">PH: {{ booking.clientContactDetails.phoneNumber1 }}</div>
              <div v-else-if="booking.clientContactDetails.phoneNumber2">PH: {{ booking.clientContactDetails.phoneNumber2 }}</div>
            </div>
            <div class="col-4 border px-2 py-1 block-2">
              <div class="vertical-long-text">
                {{ booking.bookingDetails.fullBodyAreas + (booking.bookingDetails.fullBodyAreas != "" && booking.bookingDetails.bodyAreaOther != "" ? ", " : "") + booking.bookingDetails.bodyAreaOther }}
              </div>
              <div class="vertical-long-text">
                {{ booking.bookingDetails.fullPersonConditions }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="availability.generalInformation.trim() != ''" class="mt-1">
          <div class="mb-2">General Information for the day</div>
          <div class="general-information-content" v-html="availability.generalInformation"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'SpecialistAvailabilityBookingPdf',
    props: ['pathProps'],
    components: {},
    data() {
      return {
        specialist: utilities.initSpecialist(),
        availability: utilities.initAvailability(),
        bookings: [],
        bookingsByLocation: {},

        bookingTypes: [],
        dataKey: 0,
      }
    },
    computed: {},
    methods: {
      formatDate(dateToFormat, newFormat) {
        return utilities.formatDate(dateToFormat, newFormat)
      },
      formatTime(timeToFormat) {
        return moment(timeToFormat).format('hh:mm A')
      },
      getBookingTypeName(typeId) {
        return this.bookingTypes.find((x) => x.id === typeId).name
      },
      async loadData(date = "") {
        this.bookingsByLocation = []
        if(this.specialist.id != "") {
          this.specialist = await utilities.getSpecialistById(this.specialist.id)
          var startDate = ""
          var endDate = ""
          if(date == "") {
            startDate = moment().format('YYYY-MM-DD')
            endDate = moment().format('YYYY-MM-DD')
          } else {
            startDate = date
            endDate = date
          }

          var params = {
            specialistId: this.specialist.id,
            startDate: startDate,
            endDate: endDate,
          }
          this.bookings = await utilities.getSpecialistBookings(params)
          // Not showing Cancelled booking (id = 6)
          this.bookings = this.bookings.filter(x => Number(x.bookingDetails.status) >= 2 && Number(x.bookingDetails.status) <= 15 && Number(x.bookingDetails.status) != 6)
          this.bookings.sort((a, b) => {
              return moment(new Date(a.bookingDetails.dateTime)).isAfter(moment(new Date(b.bookingDetails.dateTime))) ? 1 : -1
          }).map(x => {
            if(x.bookingDetails.locationId != '') {
              if(this.bookingsByLocation[x.bookingDetails.locationId] == undefined) this.bookingsByLocation[x.bookingDetails.locationId] = []
              this.bookingsByLocation[x.bookingDetails.locationId].push(x)
            }
          })
          params = {
            StartDate: startDate,
            EndDate: endDate,
          }
          var availabilities = await utilities.getSpecialistAvailabilities(this.specialist.id, "", params)
          availabilities.sort((a, b) => {
            return moment(a.availabilityDate + " " + a.startTime).isAfter(moment(b.availabilityDate + " " + b.startTime)) ? 1 : -1
          })
          if(availabilities.length > 0) {
            var availabiltyHasGeneralInformation = availabilities.filter(x => x.generalInformation.trim() != 0)[0]
            if(availabiltyHasGeneralInformation) this.availability = availabiltyHasGeneralInformation
            else this.availability = availabilities[0]

            this.availability.generalInformation = this.availability.generalInformation.replace(/(?:\r\n|\r|\n)/g, '<br />')
          }

          this.dataKey += 1
        }
      },
    },
    async beforeMount() {
      this.bookingTypes = await utilities.getBookingTypes()
    },
    mounted() {},
  }
</script>
