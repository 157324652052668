/* eslint-disable */
<template>
  <div class="specialist-menu sub-menu">
    <ul>
      <li :class="{'sub-menu-selected': indexProps === '0'}"><router-link to="/user-guides?index=0">Schedule Booking</router-link></li>
      <li :class="{'sub-menu-selected': indexProps === '1'}"><router-link to="/user-guides?index=1">Reschedule Booking</router-link></li>
      <li :class="{'sub-menu-selected': indexProps === '2'}"><router-link to="/user-guides?index=2">Update Booking</router-link></li>
      <li :class="{'sub-menu-selected': indexProps === '3'}"><router-link to="/user-guides?index=3">Cancel Booking</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SpecialistMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  }
}
</script>
