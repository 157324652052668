/* eslint-disable */
<template>
  <div class="travels">
    <AdminNavigation ref="navigation" indexProps="7" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Travels</h1></div>
        <div class="header-sub-title">The required travel & accommodation have been booked for the following Specialist Travel Itineraries. Please review.</div>
      </div>
    </div>
    <div class="panel">
      <AdminFinanceMenu indexProps="1" />
      <div class="content">
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 30%" class="long-text">
                      Specialist<img class="sort-icon" :src="!bySpecialist ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortSpecialist" />
                    </th>
                    <th style="width: 25%" class="long-text">
                      Availability Date<img class="sort-icon" :src="!byDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDate" />
                    </th>
                    <th style="width: 30%" class="long-text">
                      Location<img class="sort-icon" :src="!byLocation ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortLocation" />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Total Cost<img class="sort-icon" :src="!byTotalCost ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortTotalCost" />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="travels.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(travel, index) in travels" class="is-icon">
                    <td style="width: 30%" class="long-text" @click="openTravel(travel.id)">
                      {{ travel.fullName }}
                    </td>
                    <td style="width: 25%" @click="openTravel(travel.id)">
                      {{ travel.dateRange != '' && travel.dateRange != 'Invalid date - Invalid date' ? travel.dateRange : '' }}
                    </td>
                    <td style="width: 30%" class="long-text" @click="openTravel(travel.id)">
                      {{ travel.locationName }}
                    </td>
                    <td style="width: 15%" @click="openTravel(travel.id)">${{ travel.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminFinanceMenu from '@/components/AdminFinanceMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminTravels',
    components: {
      AdminNavigation,
      AdminFinanceMenu,
      Header,
    },
    data() {
      return {
        travels: [],
        allTravels: [],
        limit: 10,
        pagination: utilities.initPagination(),

        bySpecialist: false,
        byDate: false,
        byLocation: false,
        byTotalCost: false,
      }
    },
    computed: {},
    methods: {
      selectPage(index) {
        this.pagination.current = index
        this.travels = this.pagination.list[this.pagination.current]
      },
      sortSpecialist() {
        this.allTravels.sort((a, b) => {
          if (this.bySpecialist) {
            // ASC
            return a.fullName > b.fullName ? 1 : -1
          } else {
            // DESC
            return a.fullName < b.fullName ? 1 : -1
          }
        })
        this.paging(this.allTravels)
        this.bySpecialist = !this.bySpecialist
      },
      sortDate() {
        this.allTravels.sort((a, b) => {
          if (this.byDate) {
            // ASC
            return moment(a.dateCompleted) > moment(b.dateCompleted) ? 1 : -1
          } else {
            // DESC
            return moment(a.dateCompleted) < moment(b.dateCompleted) ? 1 : -1
          }
        })
        this.paging(this.allTravels)
        this.byDate = !this.byDate
      },
      sortLocation() {
        this.allTravels.sort((a, b) => {
          if (this.byLocation) {
            // ASC
            return a.locationName > b.locationName ? 1 : -1
          } else {
            // DESC
            return a.locationName < b.locationName ? 1 : -1
          }
        })
        this.paging(this.allTravels)
        this.byLocation = !this.byLocation
      },
      sortTotalCost() {
        this.allTravels.sort((a, b) => {
          if (this.byTotalCost) {
            // ASC
            return a.total > b.total ? 1 : -1
          } else {
            // DESC
            return a.total < b.total ? 1 : -1
          }
        })
        this.paging(this.allTravels)
        this.byTotalCost = !this.byTotalCost
      },
      formatDate(dateToFormat, newFormat) {
        return utilities.formatDate(dateToFormat, newFormat)
      },
      openTravel(itineraryId) {
        this.$router.push({ name: 'admin-manage-travel', query: { id: itineraryId } })
      },
      paging(travels) {
        utilities.disposeTooltip()
        if (travels.length == 0 || travels.length <= this.limit) {
          this.travels = travels
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(travels, this.limit)
          this.travels = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
        utilities.initTooltip()
      },

      async loadTravels() {
        var travels = await utilities.getTravelsAwaitingApproval()
        travels = _.uniqBy(travels, 'id')
        for (var travel of travels) {
          const travelItems = await utilities.getSpecialistTravelItineraryByAvailabilityId(travel.specialistAvailabilityId)
          travel.travelItems = travelItems
        }
        travels.forEach((x, i) => {
          var dates = travels[i].travelItems.map((x) => {
            return { travelStartDate: x.travelStartDate, travelEndDate: x.travelEndDate }
          })
          var flattened = []
          dates.forEach((x) => {
            if (x.travelStartDate != '' && x.travelStartDate != '0000-00-00') flattened.push(x.travelStartDate)
            if (x.travelEndDate != '' && x.travelEndDate != '0000-00-00') flattened.push(x.travelEndDate)
          })
          flattened = flattened.sort((a,b) => {
            return moment(a).isBefore(moment(b), 'd') ? -1 : 1
          })
          // dates = _.flatMap(dates)

          travels[i].dateRange = moment(flattened[0]).format("DD/MM/YYYY") + " - " + moment(flattened[flattened.length - 1]).format("DD/MM/YYYY")
        })
        this.allTravels = _.cloneDeep(travels)
        this.paging(travels)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadTravels()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $('.pagination').hide()
    },
  }
</script>
