/* eslint-disable */
<template>
  <div class="bookings">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Bookings</h1></div>
        <div class="header-sub-title">Results of a search.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu :key="bookingMenuIndex" :indexProps="bookingMenuIndex" />
      <div class="content">
        <div v-if="fetchedData">
          <div>
            <div class="tool-bar row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn dark @click="openSearchBooking(true)">Modify Search</v-btn>
                </div>
              </div>
            </div>
            <div v-if="bookings.length > 0" :key="dataKey">
              <div class="booking-by-day" v-for="(bookingsByDateValue, bookingsByDateKey, bookingsByDateIndex) in bookingsByDate" :key="bookingsByDateIndex">
                <div class="booking-by-day-heading box-heading" @click="openBookingByDay(bookingsByDateIndex)">
                  <span><img src="@/assets/images/down_arrow_icon.svg" /></span>
                  <span class="ml-2">{{ formatDate(bookingsByDateKey, "dddd, MMMM DD YYYY") }}</span>
                </div>
                <div class="booking-by-specialist" v-for="(bookingsBySpecialistValue, bookingsBySpecialistKey, bookingsBySpecialistIndex) in bookingsByDateValue">
                  <div class="booking-by-specialist-heading box-heading" @click="openBookingBySpecialist(bookingsByDateIndex, bookingsBySpecialistIndex)">
                    <span><img src="@/assets/images/down_arrow_icon.svg" /></span>
                    <span class="ml-2">{{ bookingsBySpecialistValue[0].specialistFullName + (bookingsBySpecialistValue[0].locationName != '' ? ", " : "") + bookingsBySpecialistValue[0].locationName }}</span>
                  </div>
                  <div class="p-3">
                    <table class="table">
                      <tbody>
                        <tr class="is-icon" @click="openBooking(booking.id)" v-for="(booking, bookingIndex) in bookingsBySpecialistValue">
                          <td style="width: 25%">
                            <div class="d-flex">
                              {{ booking.id }}
                              <div class="d-flex align-items-center">
                                <img
                                  class="ml-4 is-icon"
                                  src="@/assets/images/lol-received.svg"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="LOI Received"
                                  :class="isDate(booking.lolReceived) ? '' : 'disabled-element'"
                                />
                                <img
                                  class="ml-4 is-icon"
                                  src="@/assets/images/brief-received.svg"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="Brief Received"
                                  :class="isDate(booking.briefReceived) ? '' : 'disabled-element'"
                                />
                                <img
                                  class="ml-4 is-icon"
                                  src="@/assets/images/additional-paperwork-received.svg"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="Additional Paperwork Received"
                                  :class="isDate(booking.additionalPaperworkReceived) ? '' : 'disabled-element'"
                                />

                                <img
                                  class="ml-4 is-icon"
                                  src="@/assets/images/paperwork-processed.svg"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="Paperwork Processed"
                                  :class="isDate(booking.paperworkProcessed) ? '' : 'disabled-element'"
                                />
                              </div>
                            </div>
                            <div>{{ booking.bookingTypeName }}</div>
                          </td>
                          <td style="width: 10%">{{ formatDate(booking.bookingDateTime, "hh:mm A" , 'YYYY-MM-DD HH:mm:ss.ssssss') }}</td>
                          <td style="width: 25%">
                            <div v-if="booking.Salutation && booking.PreferredGivenName">
                              <span class="me-1">{{booking.Salutation}}</span>
                              <span>{{booking.PreferredGivenName}}</span>
                            </div>
                            <div>{{ booking.claimantFullName }}</div>
                            <div>{{ booking.caseReference }}</div>
                          </td>
                          <td style="width: 25%">
                            <div>{{ booking.caseOwnerName }}</div>
                            <div>{{ booking.clientName + ", " + booking.branchName }}</div>
                          </td>
                          <td style="width: 15%">{{ booking.bookingStatusName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>{{ resultMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"

export default {
  name: "AdminBookings",
  props: ["searchOptionProps"],
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      fetchedData: false,
      resultMessage: "",

      bookings: [],
      bookingsByDate: null,
      bookingMenuIndex: 0,
      searchOption: null,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      dataKey: 0,
    };
  },
  computed: {},
  methods: {
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    openSearchBooking(modifySearch = false) {
      if(this.searchOption != undefined && modifySearch) this.$refs.navigation.$refs.globalSearch.openSearchBooking(this.searchOption)
      else this.$refs.navigation.$refs.globalSearch.openSearchBooking()
    },
    openBooking(id) {
      let routeData = this.$router.resolve({ path: '/admin-manage-booking', query: { id: id } })
      window.open(routeData.href, '_blank')
    },
    openBookingByDay(bookingsByDateIndex) {
      var bookingByDay = $(".booking-by-day").eq(bookingsByDateIndex)
      if(bookingByDay.length > 0) {
        var height = Number(bookingByDay.css("height").replace("px", ""))
        var autoHeight = 0
        if(height > 66) { // close
          bookingByDay.animate({ "height": 66 }, "slow")
          bookingByDay.find(".booking-by-day-heading > img").attr('src', require('@/assets/images/down_arrow_icon.svg'))
        } else {  // open
          bookingByDay.children().each(function() {
            autoHeight += $(this).outerHeight(true)
          })
          bookingByDay.animate({ "height": autoHeight }, "slow")
          bookingByDay.find(".booking-by-day-heading > img").attr('src', require('@/assets/images/up_arrow_icon.svg'))
        }
      }
    },
    openBookingBySpecialist(bookingsByDateIndex, bookingsBySpecialistIndex) {
      var bookingByDay = $(".booking-by-day").eq(bookingsByDateIndex)
      var bookingBySpecialist = $(".booking-by-day").eq(bookingsByDateIndex).find(".booking-by-specialist").eq(bookingsBySpecialistIndex)
      if(bookingByDay.length > 0 && bookingBySpecialist.length > 0) {
        var height = Number(bookingBySpecialist.css("height").replace("px", ""))
        var autoHeight = 0
        if(height > 66) { // close
          bookingBySpecialist.css("height", "66px")

          bookingByDay.children().each(function() {
            autoHeight += $(this).outerHeight(true)
          })
          bookingByDay.animate({ "height": autoHeight }, "slow")

          bookingBySpecialist.find(".booking-by-specialist-heading > img").attr('src', require('@/assets/images/down_arrow_icon.svg'))
        } else {  // open
          bookingBySpecialist.children().each(function() {
            autoHeight += $(this).outerHeight(true)
          })
          bookingBySpecialist.height(autoHeight)

          autoHeight = 0
          bookingByDay.children().each(function() {
            autoHeight += $(this).outerHeight(true)
          })
          bookingByDay.animate({ "height": autoHeight }, "slow")

          bookingBySpecialist.find(".booking-by-specialist-heading > img").attr('src', require('@/assets/images/up_arrow_icon.svg'))
        }
      }
    },

    isDate(date) {
      return moment(date, "YYYY-MM-DD").isValid()
    },

    async searchBooking(searchOption) {
      this.searchOption = _.cloneDeep(searchOption)
      this.$store.dispatch("loading/setLoadComponent", true)
      var params = {
        Firstname: searchOption.claimantFirstname.trim(),
        Surname: searchOption.claimantSurname.trim(),
        UserName: searchOption.userName,
        ClientID: searchOption.clientId,
        CaseRef: searchOption.bookingReference,
        BookingID: searchOption.bookingId,
        FromDate: searchOption.fromDate ? moment(searchOption.fromDate).format('YYYY-MM-DD') : '',
        ToDate: searchOption.toDate ? moment(searchOption.toDate).format('YYYY-MM-DD'): '',
        BType: searchOption.bookingType,
        BA: searchOption.bodyAreas,
        PC: searchOption.conditions,
        BStatus: searchOption.status,
        UserID: this.user.id,
        tmp: 1,
        SpecialistId: searchOption.specialistId,
        RegionID: searchOption.regionId,
        CityID: searchOption.cityId,
        LocationID: searchOption.locationId
      }
      var bookings = await utilities.getSearchBookings(params)
      if(searchOption.bookingId != '' && bookings[0]) {
        this.$router.push({ name: 'admin-manage-booking', query: { id: bookings[0].id } }).catch((err) => {})
        return
      }

      this.bookings = _.cloneDeep(bookings)
      this.bookingsByDate = {}
      var i = 0
      var limitBookingTypes = ['Supplementary', 'File Review', 'Medical Negligence Request']
      if(limitBookingTypes.includes(searchOption.bookingTypeName)) {  //  Supplementary, File Review, Medical Negligence Request
        this.bookings.sort((a, b) => {
          return moment(a.dateCreated, "YYYY-MM-DD HH:mm:ss.SSSSSS").isAfter(moment(b.dateCreated, "YYYY-MM-DD HH:mm:ss.SSSSSS")) ? 1 : -1
        })
        this.bookings.filter(x => limitBookingTypes.includes(x.bookingTypeName)).map(x => {
          // Empty booking date time for old data of these booking types
          if(x.bookingDateTime != '') {
            x.bookingDateTime = ''
          }
          if(x.dateCreated != '') {
            var dateCreated = x.dateCreated.slice(0, 10)
            if(this.bookingsByDate[dateCreated] == undefined) {
              this.bookingsByDate[dateCreated] = {}
            }
            var key = i + "-" + x.specialistId
            if(!key.includes(x.specialistId)) {
              i += 1
              key = i + "-" + x.specialistId
            }
            if(this.bookingsByDate[dateCreated][key] == undefined) {
              this.bookingsByDate[dateCreated][key] = []
            }
            this.bookingsByDate[dateCreated][key].push(x)
          }
        })
      } else {  // Others
        this.bookings.sort((a, b) => {
          return moment(a.bookingDateTime || a.dateCreated, "YYYY-MM-DD HH:mm:ss.SSSSSS").isAfter(moment(b.bookingDateTime || b.dateCreated, "YYYY-MM-DD HH:mm:ss.SSSSSS")) ? 1 : -1
        })
        this.bookings.map(x => {
          if(x.bookingDateTime != '') {
            var bookingDateTime = x.bookingDateTime.slice(0, 10)
            if(this.bookingsByDate[bookingDateTime] == undefined) {
              this.bookingsByDate[bookingDateTime] = {}
            }
            var key = i + "-" + x.specialistId
            if(!key.includes(x.specialistId)) {
              i += 1
              key = i + "-" + x.specialistId
            }
            if(this.bookingsByDate[bookingDateTime][key] == undefined) {
              this.bookingsByDate[bookingDateTime][key] = []
            }
            this.bookingsByDate[bookingDateTime][key].push(x)
          } else if(x.dateCreated != '') {
            var dateCreated = x.dateCreated.slice(0, 10)
            if(this.bookingsByDate[dateCreated] == undefined) {
              this.bookingsByDate[dateCreated] = {}
            }
            var key = i + "-" + x.specialistId
            if(!key.includes(x.specialistId)) {
              i += 1
              key = i + "-" + x.specialistId
            }
            if(this.bookingsByDate[dateCreated][key] == undefined) {
              this.bookingsByDate[dateCreated][key] = []
            }
            this.bookingsByDate[dateCreated][key].push(x)
          }
        })
      }
      this.dataKey += 1
      if(this.bookings.length == 0) {
        if(searchOption.bookingId != '') {
          this.resultMessage = "No Booking with the specified ID."
        } else {
          this.resultMessage = "No results found."
        }
      } else {
        setTimeout(() => {
          if(this.bookings.length > 0) {
            this.openBookingByDay(0)
            this.openBookingBySpecialist(0, 0)
          }
        }, 500)
      }
      utilities.initTooltip()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    if (this.searchOptionProps != undefined) {
      this.searchOption = _.cloneDeep(this.searchOptionProps)
      await this.searchBooking(this.searchOptionProps)
    } else {
      setTimeout(() => {
        if(this.$refs.navigation) this.$refs.navigation.$refs.globalSearch.openSearchBooking()
        utilities.initTooltip()
      }, 1000)
    }
    this.fetchedData = true
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {},
}
</script>
