<template>
  <div class="add-city-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>{{ city.id != undefined && city.id != '' ? 'Edit City' : 'Add City' }}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="row">
                <div class="col-3">City Name</div>
                <div class="col-9">
                  <input type="text" v-model="city.cityName" placeholder="Enter city name" :class="hasError && city.cityName == '' ? 'is-invalid' : ''" />
                  <div class="text-danger" v-if="hasError && city.cityName == ''">Please enter a city name</div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">Region</div>
                <div class="col-9">
                  <select v-model="city.regionId" :class="hasError && city.regionId == '' ? 'is-invalid' : ''">
                    <option value="" hidden>Select a region</option>
                    <option v-for="region in regions" :value="region.id">{{ region.name }}</option>
                  </select>
                  <div class="text-danger" v-if="hasError && city.regionId == ''"></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" id="savebutton" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddCityModal',
    props: ['cityProps', 'regions'],
    data() {
      return {
        loadModal: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        city: { cityName: '', regionId: '' },
        hasError: false,
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      async callSave() {
        if (this.cityName == '' || this.regionId == '') {
          this.hasError = true
          return
        }

        $('#savebutton').prop('disabled', true)
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          CityName: this.city.cityName,
          RegionID: this.city.regionId,
          UserID: this.user.id,
          Username: this.user.name,
        }
        var cityId = ''
        var callback = (response) => {
          cityId = response
        }
        await utilities.postCity(params, callback)
        this.$parent.cities = await utilities.getCities()
        this.$parent.cities.map((x) => {
          x.region = this.regions.find((y) => y.id == x.regionId)
        })
        this.$parent.cities.sort((a, b) => {
          return a.cityName > b.cityName
        })
        this.$parent.location.cityId = cityId
        this.$store.dispatch('loading/setLoadComponent', false)
        this.handleModal()
      },
    },
    mounted() {},
    updated() {
      if (!this.loaded) {
        if (this.cityProps != undefined) {
          this.city = _.cloneDeep(this.cityProps)
        }
        this.loaded = true
      }
    },
  }
</script>
