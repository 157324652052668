<template>
  <div class="profile-read-more-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="row">
              <div class="col-2 d-flex align-items-center">
                <div v-if="specialist.photo != ''" class="rounded-image profile-image m-0">
                  <div class="profile-image-content">
                    <img :src="specialist.profileThumbnail" />
                  </div>
                </div>
                <div v-else class="rounded-image medium-rounded-image m-t-0 m-t-b">
                  <div class="profile-image-content notice-background"><s class="text-initials">{{getInitials(specialist)}}</s></div>
                </div>
              </div>
              <div class="col-10 pl-4">
                <div class="name">
                  {{ specialist.fullName }}
                  <img v-if="specialist.requiresTravel" class="icon-20" src="@/assets/images/travel_black_circle_icon.svg" />
                  <img v-if="specialist.videoLinkAvailable == 2" class="icon-20 ml-2" src="@/assets/images/video_black_icon.svg" />
                </div>
                <div class="qualification">
                  {{ specialist.qualificationList }}
                </div>
                <div class="download-pdf">
                  <v-btn dark v-if="specialist.id != ''" class="is-icon" data-placement="right" data-toggle="tooltip" title="Download PDF" @click="downloadPdf()" >Download as PDF</v-btn>
                </div>
              </div>

              <!-- <div class="col-12 specialist-pdf-title">

              </div> -->
            </div>
            <div class="box-row" v-if="specialist.educationList">
              <div class="box-col box-col-left">QUALIFICATIONS</div>
              <div class="box-col box-col-right m-auto">
                <div class="profile_input">{{specialist.educationList}}</div>
              </div>
            </div>
            <div class="box-row" v-if="specialist.certificationList">
              <div class="box-col box-col-left">ACCREDITATIONS</div>
              <div class="box-col box-col-right m-auto">
                <div class="profile_input">{{specialist.certificationList}}</div>
              </div>
            </div>
            <div class="box-row" v-if="specialist.interestList">
              <div class="box-col box-col-left">SPECIAL INTERESTS</div>
              <div class="box-col box-col-right m-auto">
                <div class="profile_input">{{specialist.interestList}}</div>
              </div>
            </div>
            <div class="box-row" v-if="specialist.personConditionList">
              <div class="box-col box-col-left">ASSESSMENT AREAS</div>
              <div class="box-col box-col-right">
                  <div class="profile_input">{{specialist.personConditionList}}</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left mt-4">PROFILE</div>
              <div class="box-col box-col-right">
                <span v-html="specialist.specialistProfile" class="profile-details"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "ProfileReadMoreModal",
  data() {
    return {
      loadModal: false,
      specialist: utilities.initSpecialist(),
      note: "",
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("body>.tooltip").remove()
        utilities.initTooltip()
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    getInitials(specialist) {
      var initials = ""
      if (specialist.firstName) {
        initials = specialist.firstName.substring(0,1)
      }

      if (specialist.lastName) {
        initials = initials + specialist.lastName.substring(0,1)
      }
      return initials
    },

    async downloadPdf() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var pdf = await utilities.getSpecialistProfilesByIds(this.specialist.id)
      var specialistName = this.specialist.firstName.replace(/ /g, "") + this.specialist.lastName.replace(/ /g, "")
      var fileName = 'SpecialistProfile' + specialistName + ".pdf"
      var a = document.createElement('a')
      let blob = pdf
      a.setAttribute('href', window.URL.createObjectURL(blob))
      a.setAttribute('download', fileName)
      a.click()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async loadData(id) {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.specialist = await utilities.getSpecialistById(id)
      // Load profile photo
      if(this.specialist.photo != '') {
        this.specialist.profileThumbnail = await utilities.getProfileThumbnail(this.specialist.photo)
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },
  },
  mounted() {},
  updated() {},
}
</script>
