/* eslint-disable */
<template>
  <div class="specialist-search-booking">
    <SpecialistNavigation indexProps="5" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Search Bookings</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <SpecialistBookingMenu indexProps="3" />
      <div class="content">
        <div class="tool-bar">
          <div class="row" style="margin-top: 0px; margin-bottom: 0px">
            <div class="col-12 col-md-4">
              <input
                type="text"
                placeholder="Claimant Name"
                style="padding: 0 10px"
                v-model="searchParams.claimantName"
              />
            </div>
            <div class="col-12 col-md-4">
              <input
                type="text"
                placeholder="Client Name"
                style="padding: 0 10px"
                v-model="searchParams.clientName"
              />
            </div>
            <div class="col-12 col-md-4">
              <datepicker
                format="dd/MM/yyyy"
                :typeable="true"
                v-model="searchParams.aptDate"
                input-class="form-control"
                placeholder="Date of Appointment"
              >
              </datepicker>
            </div>
          </div>
          <div class="row" style="margin-top: 0px; padding-bottom: 12px">
            <div class="col-12 col-md-4 my-0 py-0">
              <vue-tags-input
                v-model="bodyArea"
                :tags="bodyAreas"
                :autocomplete-items="filteredBodyAreas"
                @tags-changed="(newTags) => (bodyAreas = newTags)"
                placeholder="Add Affected Body Areas"
                :validation="validate"
              />
            </div>
            <div class="col-12 col-md-4 my-0 py-0">
              <vue-tags-input
                v-model="condition"
                :tags="conditions"
                :autocomplete-items="filteredConditions"
                placeholder="Add Conditions"
                @tags-changed="(newTags) => (conditions = newTags)"
                :validation="validate"
              />
            </div>
            <div
              class="col-12 col-md-4 my-0 py-0 d-flex align-items-center justify-content-between"
            >
              <v-btn-toggle v-model="searchParams.timeline">
                <span v-for="value in values" :key="value.value">
                  <v-btn class="radio-toggle">
                    {{ value.label }}
                  </v-btn>
                </span>
              </v-btn-toggle>
              <div
                class="item-right search-icon search-icon-rounded ml-3 is-icon"
                @click="callSearch()"
              ></div>
            </div>
          </div>
        </div>

        <div
          class="box"
          style="min-height: 0vh"
          v-for="(result, index) in searchResults"
          :key="index"
          @click="setViewStatus(index)"
        >
          <div class="box-heading travel-box-header">
            <div class="box-date-time">
              <div class="booking-header-item">
                <div class="item-date">
                  <div>Due: {{ result.bookingDetails.dueDate != "" ? formatDate(result.bookingDetails.dueDate, "DD/MM/YYYY") : 'Unknown' }}</div>
                  <div>
                    <!-- Time will display here -->
                  </div>
                </div>
                <div class="">
                  Claimant: {{ result.casesDetails.claimantFullName }}
                </div>
              </div>
              <div class="booking-header-item box-date-time-end">
                <div class="padding-right-20">
                  {{
                    bookingStatuses.find(
                      (x) => x.id == result.bookingDetails.status
                    ).name
                  }}
                </div>
                <div class="">
                  <router-link
                    :to="{
                      path: '/specialist-booking-detail',
                      query: { id: result.bookingDetails.id },
                    }"
                  >
                    <img
                      data-toggle="tooltip"
                      data-placement="right"
                      title="View Booking Details"
                      class="img-small"
                      src="@/assets/images/view_booking.svg"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="box-body" :class="index != 0 ? 'hidden-element' : ''">
            <div class="box-col box-col-left-details">
              <span>Claimant: {{ result.casesDetails.claimantFullName }}</span>
              <span>Client: {{ result.clientDetails.clientName }}</span>
              <span>Contact: {{ result.clientContactDetails.fullName }}</span>
              <span>Phone: {{ result.clientContactDetails.phoneNumber1 }}</span>
              <span
                >{{
                  bookingTypes.find((x) => x.id == result.bookingDetails.typeId)
                    .name
                }}
                |
                {{
                  result.bookingDetails.bodyAreas
                    .map((x) => x.bodyArea)
                    .join(", ")
                }}
                | {{ result.casesDetails.claimTypeName }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="box"
          style="min-height: 0"
          v-if="searchResults.length == 0"
          :class="oopsString == '' ? 'border-0' : 'border'"
        >
          <div class="box-body">
            <div class="box-col box-col-left-details">
              {{ oopsString }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";
import SpecialistNavigation from "@/components/SpecialistNavigation";
import SpecialistBookingMenu from "@/components/SpecialistBookingMenu";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Chat from "@/components/Chat";

export default {
  name: "SpecialistSearchBooking",
  components: {
    SpecialistNavigation,
    SpecialistBookingMenu,
    Header,
    Chat,
  },
  data() {
    return {
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      oopsString: "",
      searchParams: {
        claimantName: "",
        clientName: "",
        aptDate: "",
        affectedBodyAreas: "",
        conditions: "",
        timeline: undefined,
      },
      bookingStatuses: utilities.getBookingStatuses(),
      bookingTypes: [],
      claimTypes: [],
      searchResults: [],
      bodyAreas: [],
      bodyArea: "",
      bodyAreasAutocomplete: [],
      conditions: [],
      condition: "",
      conditionsAutocomplete: [],
      values: [
        { label: "In Progress", value: "In Progress" },
        { label: "Past", value: "Past" },
        { label: "Upcoming", value: "Upcoming" },
      ],
      validate: [
        {
          classes: "test",
          rule: (tag) => tag.length < 2,
        },
      ],
    };
  },

  computed: {
    filteredBodyAreas() {
      return this.bodyAreasAutocomplete.filter((i) => {
        return i.text.toLowerCase().indexOf(this.bodyArea.toLowerCase()) !== -1;
      });
    },

    filteredConditions() {
      return this.conditionsAutocomplete.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.condition.toLowerCase()) !== -1
        );
      });
    },
  },

  methods: {
    formatDate(dateToFormat, newFormat) {
      return utilities.formatDate(dateToFormat, newFormat)
    },
    async callSearch() {
      var requestBody = {
        SpecialistID: this.user.specialistId,
      };
      this.oopsString = "";
      if (this.searchParams.aptDate !== "")
        requestBody.AptDate = moment(this.searchParams.aptDate).format(
          "YYYY-MM-DD"
        );
      if (this.searchParams.clientName !== "")
        requestBody.ClientName = this.searchParams.clientName;
      if (this.searchParams.claimantName !== "")
        requestBody.ClaimantName = this.searchParams.claimantName;
      if (this.bodyAreas.length > 0)
        requestBody.BodyAreas = this.bodyAreas.map((x) => x.id).join(",");
      if (this.conditions.length > 0)
        requestBody.Conditions = this.conditions.map((x) => x.id).join(",");
      this.$store.dispatch("loading/setLoadComponent", true);
      this.searchResults = await utilities.searchBookings(
        new URLSearchParams(requestBody).toString()
      );
      var shownBookingStatuses = [
        "2",
        "3",
        "5",
        "8",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
      ];
      this.searchResults = this.searchResults.filter((x) =>
        shownBookingStatuses.includes(x.bookingDetails.status)
      );
      if (this.searchParams.timeline != undefined) {
        if (this.searchParams.timeline == 1) {
          // Past
          this.searchResults = this.searchResults.filter((x) =>
            moment().isAfter(moment(x.bookingDetails.dateTime))
          );
        } else if (this.searchParams.timeline == 2) {
          // Future
          this.searchResults = this.searchResults.filter((x) =>
            moment().isBefore(moment(x.bookingDetails.dateTime))
          );
        }
      }
      if (this.searchResults.length == 0)
        this.oopsString = "Oops! That search did not return any results!";
      else {
        utilities.setViewStatus(0);
      }
      this.$store.dispatch("loading/setLoadComponent", false);
    },

    setViewStatus(i) {
      utilities.setViewStatus(i);
    },

    async loadData() {
      this.bookingTypes = await utilities.getBookingTypes();
      this.bodyAreasAutocomplete = await utilities.getBodyArea();
      this.bodyAreasAutocomplete = this.bodyAreasAutocomplete.map((x) => {
        return { id: x.id, text: x.bodyArea };
      });
      this.conditionsAutocomplete = await utilities.getPersonConditions();
      this.conditionsAutocomplete = this.conditionsAutocomplete.map((x) => {
        return { id: x.id, text: x.name };
      });
      this.claimTypes = await utilities.getClaimTypes();
    },
  },

  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    await this.loadData()
    utilities.initTooltip()
    this.$store.dispatch("loading/setLoadComponent", false)
  },

  mounted() {
    window.scrollTo(0, 0)
  },
};
</script>
