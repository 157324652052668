/* eslint-disable */
<template>
  <div class="specialists">
    <Navigation indexProps="4" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Specialists</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <SpecialistMenu indexProps="0" />
      <div class="content">
        <div class="top-detail">
          <div class="row">
            <div class="col">Search: Qualified to Practice As: Neurologist, Neurosurgeon</div>
            <div class="col">Location:  Cairns  (within 25km)</div>
          </div>
        </div>

        <div class="row">
          <div class="col-1 icon-group">
            <img src="@/assets/images/file_icon.svg" />
          </div>
          <div class="col">&nbsp;</div>
          <div class="col-2 icon-group">
            <img src="@/assets/images/search_black_icon.svg" />
            <router-link to="/manage-specialist"><img src="@/assets/images/add_black_icon.svg" /></router-link>
          </div>
        </div>

        <!-- <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th><input type="checkbox" class="form-checkbox-all" @change="changeCheckboxAll($event)" /></th>
                <th class="hidden-element">Id</th>
                <th style="width:15%" class="long-text">Name<img class="sort-icon" :src="!byName ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortName" /></th>
                <th style="width:13%" class="long-text">Client Alignment</th>
                <th style="width:15%" class="long-text">Certifications</th>
                <th style="width:15%" class="long-text">Specialities</th>
                <th style="width:13%" class="long-text" data-placement="right" data-toggle="tooltip" title="Next Availability">
                  Next Availability<img class="sort-icon" :src="!byNextAvailability ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortNextAvailability" />
                </th>
                <th style="width:13%" class="long-text" data-placement="right" data-toggle="tooltip" title="Last Availability">
                  Last Availability<img class="sort-icon" :src="!byLastAvailability ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortLastAvailability" />
                </th>
                <th style="width:16%" class="icon-table">&nbsp;</th>
              </tr>
            </thead>
          </table>
        </div> -->
        <div class="table-responsive" :class="specialists.length > 10 ? 'content-scroll-y': ''">
          <table class="table">
            <thead>
              <tr>
                <th><input type="checkbox" class="form-checkbox-all" @change="changeCheckboxAll($event)" /></th>
                <th class="hidden-element">Id</th>
                <th style="width:15%" class="long-text">Name<img class="sort-icon" :src="!byName ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortName" /></th>
                <th style="width:13%" class="long-text">Client Alignment</th>
                <th style="width:15%" class="long-text">Certifications</th>
                <th style="width:15%" class="long-text">Specialities</th>
                <th style="width:13%" class="long-text" data-placement="right" data-toggle="tooltip" title="Next Availability">
                  Next Availability<img class="sort-icon" :src="!byNextAvailability ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortNextAvailability" />
                </th>
                <th style="width:13%" class="long-text" data-placement="right" data-toggle="tooltip" title="Last Availability">
                  Last Availability<img class="sort-icon" :src="!byLastAvailability ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortLastAvailability" />
                </th>
                <th style="width:16%" class="icon-table">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(specialist, index) in specialists">
                <td><input type="checkbox" class="form-checkbox" :value="index" @change="changeCheckbox($event)" /></td>
                <td class="hidden-element">{{ specialist.id }}</td>
                <td style="width:15%" class="long-text" data-placement="right" data-toggle="tooltip" :title="specialist.fullName">{{ specialist.fullName }}</td>
                <td style="width:13%" class="long-text">{{ specialist.clientAlignment }}</td>
                <td style="width:15%" class="long-text" data-placement="right" data-toggle="tooltip" :title="specialist.certifications">{{ specialist.certifications }}</td>
                <td style="width:15%" class="long-text" data-placement="right" data-toggle="tooltip" :title="specialist.specialities">{{ specialist.specialities }}</td>
                <td style="width:13%" class="long-text">{{ specialist.nextAvailability }}</td>
                <td style="width:13%" class="long-text">{{ specialist.lastAvailability }}</td>
                <td style="width:16%" class="icon-table icon-group">
                  <img src="@/assets/images/finance_black_icon.svg" />
                  <img src="@/assets/images/travel_black_icon.svg" />
                  <img src="@/assets/images/view_booking_icon.svg" />
                  <img src="@/assets/images/add_booking_icon.svg" />
                  <img src="@/assets/images/zoho_icon.svg" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''"
                  v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">{{ item }}</div>
              </div>
            </div>

          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import SpecialistMenu from '@/components/SpecialistMenu'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Chat from '@/components/Chat'

export default {
  name: 'Specialists',
  components: {
    Navigation,
    SpecialistMenu,
    Header,
    Chat,
  },
  data() {
    return {
      specialists: [
        {
          id: "1",
          name: "Dr Ignatiius Wong",
          clientAlignment: "Plaintiff",
          certifications: "Lots of letters here Lots of letters here Lots of letters here",
          specialities: "Neurologist, Neurosurg",
          nextAvailability: "30/11/2020",
          lastAvailability: "30/11/2020",
        },
        {
          id: "2",
          name: "Dr Alexandra Cuthber",
          clientAlignment: "Both",
          certifications: "Lots of letters here Lots of letters here Lots of letters here",
          specialities: "Psychologist",
          nextAvailability: "30/11/2020",
          lastAvailability: "30/11/2020",
        },
      ],
      pagination: utilities.initPagination(),

      // ASC (true) with down icon (false), DESC (false) with up icon (true)
      byName: false,
      byNextAvailability: false,
      byLastAvailability: false,
    }
  },
  methods: {
    sortName() {
      this.specialists.sort((a, b) => {
        if(this.byName) {  // ASC
          return a.name > b.name ? 1 : -1
        } else { // DESC
          return a.name < b.name ? 1 : -1
        }
      })
      this.byName = !this.byName
    },
    sortNextAvailability() {
      this.specialists.sort((a, b) => {
        if(this.byNextAvailability) {  // ASC
          return moment(a.nextAvailability) > moment(b.nextAvailability) ? 1 : -1
        } else { // DESC
          return moment(a.nextAvailability) < moment(b.nextAvailability) ? 1 : -1
        }
      })
      this.byNextAvailability = !this.byNextAvailability
    },
    sortLastAvailability() {
      this.specialists.sort((a, b) => {
        if(this.byLastAvailability) {  // ASC
          return moment(a.lastAvailability) > moment(b.lastAvailability) ? 1 : -1
        } else { // DESC
          return moment(a.lastAvailability) < moment(b.lastAvailability) ? 1 : -1
        }
      })
      this.byLastAvailability = !this.byLastAvailability
    },
    changeCheckboxAll(e) {
      if(e.target.checked) {
        $(".form-checkbox").prop('checked', true)
      } else {
        $(".form-checkbox").prop('checked', false)
      }
    },
    changeCheckbox(e) {
      var checkedNumber = $(".form-checkbox:checked").length

      if(checkedNumber == 0) {
        $(".form-checkbox-all").prop('checked', false)
      }
      if(checkedNumber == $(".form-checkbox").length) {
        $(".form-checkbox-all").prop('checked', true)
      }
    },
  },
  beforeMount() {

  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
}
</script>
