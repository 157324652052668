/* eslint-disable */
<template>
  <div class="hours-booked">
    <AdminNavigation ref="navigation" indexProps="6" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Number of Hours of Appointments Booked ({{ totalHours }})</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminReportsMenu indexProps="3" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <v-md-date-range-picker @change="setDates" class="mr-3"></v-md-date-range-picker>
            <download-csv :data="getCalendarData()" :name="getFileName()">
              <v-btn dark>CSV <img src="@/assets/images/download_light_icon.svg" class="ml-3" /></v-btn>
            </download-csv>
            <!-- <v-btn @click="getCalendarData()">test</v-btn> -->
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 20%" class="long-text">Client Name</th>
                    <th style="width: 25%" class="long-text">Branch Name</th>
                    <th style="width: 20%" class="long-text">Month</th>
                    <th style="width: 20%" class="long-text">
                      Number of Bookings
                    </th>
                    <th style="width: 20%" class="long-text">
                      Number of Hours
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="hoursBooked.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <template v-for="(hours, key, index) in hoursBooked">
                    <tr :key="index" class="bg-light">
                      <td>{{ key }}</td>
                      <td>{{ hours.branchName }}</td>
                      <td>&nbsp;</td>
                      <td>{{ hours.totalBookings }}</td>
                      <td>{{ hours.totalHours }}</td>
                    </tr>
                    <tr v-for="hour in hours.details">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>{{ hour.month }}</td>
                      <td>{{ hour.numberOfBookings }}</td>
                      <td>{{ hour.numberOfHours }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";
import AdminNavigation from "@/components/AdminNavigation";
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu";
import Header from "@/components/Header";
import AdminReportsMenu from "@/components/AdminReportsMenu";

export default {
  name: "AdminHoursBookedByClientPerMonth",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    Header,
    AdminReportsMenu,
  },
  data() {
    return {
      hasError: false,
      successMessage: "",
      errorMessage: "Please correct the errors shown above and try again.",
      hoursBooked: {},
      limit: 20,
      allHoursBooked: {},
      errorMessage: "",
      successMessage: "",
      totalHours: 0,
    };
  },
  methods: {
    async loadData(startDate = "", endDate = "") {
      //   var locations = await utilities.getLocations()
      this.$store.dispatch("loading/setLoadComponent", true);
      this.totalHours = 0;
      this.allHoursBooked = {};
      if (startDate == "" || startDate == endDate)
        var localHours = await utilities.getReportHoursBookedByClient(
          moment().format("YYYY-MM-DD"),
          moment().add(1, "y").format("YYYY-MM-DD")
        );
      else
        var localHours = await utilities.getReportHoursBookedByClient(
          startDate,
          endDate
        );
      localHours.map((x) => {
        this.totalHours += parseFloat(x.bookedHours);
        if (
          this.allHoursBooked[x.clientName + ", " + x.branchName] !== undefined
        ) {
          this.allHoursBooked[
            x.clientName + ", " + x.branchName
          ].totalBookings += parseInt(x.numberOfBookings);
          this.allHoursBooked[x.clientName + ", " + x.branchName].totalHours = (
            parseFloat(
              this.allHoursBooked[x.clientName + ", " + x.branchName].totalHours
            ) + parseFloat(x.bookedHours)
          ).toFixed(2);
          this.allHoursBooked[x.clientName + ", " + x.branchName].details.push({
            month: x.groupingDate,
            numberOfBookings: parseInt(x.numberOfBookings),
            numberOfHours: parseFloat(x.bookedHours).toFixed(2),
          });
        } else {
          this.allHoursBooked[x.clientName + ", " + x.branchName] = {
            clientName: x.clientName,
            branchName: x.branchName,
            totalBookings: parseInt(x.numberOfBookings),
            totalHours: parseFloat(x.bookedHours).toFixed(2),
            details: [
              {
                month: x.groupingDate,
                numberOfBookings: parseInt(x.numberOfBookings),
                numberOfHours: parseFloat(x.bookedHours).toFixed(2),
              },
            ],
          };
        }
      });

      for(var key in this.allHoursBooked) {
        this.allHoursBooked[key].details = this.allHoursBooked[key].details.sort((a,b) => {
          return moment("01 "+a.month).isBefore(moment("01 "+b.month)) ? -1 : 1
        })
      }

      this.hoursBooked = this.allHoursBooked;
      this.$store.dispatch("loading/setLoadComponent", false);
    },

    getFileName() {
      return "Hours_booked_" + moment().format("YYYYMMDDHHmm") + ".csv";
    },

    getCalendarData() {
      var calendarData = [];
      for (var key in this.hoursBooked) {
        if (this.hoursBooked.hasOwnProperty(key)) {
          calendarData.push({
            "Client Name": key,
            "Branch Name": this.hoursBooked[key].branchName,
            Month: "",
            "Number of Bookings": this.hoursBooked[key].totalBookings,
            "Number of Hours": parseFloat(
              this.hoursBooked[key].totalHours
            ).toFixed(2),
          });
          for (var i = 0; i < this.hoursBooked[key].details.length; i++) {
            calendarData.push({
              "Client Name": "",
              "Branch Name": "",
              Month: this.hoursBooked[key].details[i].month,
              "Number of Bookings": this.hoursBooked[key].details[i]
                .numberOfBookings,
              "Number of Hours": parseFloat(
                this.hoursBooked[key].details[i].numberOfHours
              ).toFixed(2),
            });
          }
        }
      }
      return calendarData;
    },

    async setDates(values) {
      this.$store.dispatch("loading/setLoadComponent", true);
      var commencingDate = values[0];
      var endingDate = values[1];
      await this.loadData(
        commencingDate.format("YYYY-MM-DD"),
        endingDate.format("YYYY-MM-DD")
      );
      this.$store.dispatch("loading/setLoadComponent", false);
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true);
    await this.loadData();
    this.$store.dispatch("loading/setLoadComponent", false);
  },
  mounted() {},
};
</script>
