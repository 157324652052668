/* eslint-disable */
<template>
  <div class="finance-menu sub-menu">
    <ul>
      <li v-if="user.type == 4 || user.type == 6"><router-link :to="{ name: 'admin-purchase-orders', params: {} }">Purchase Orders</router-link></li>
      <li v-if="user.type == 4 || user.type == 6"><router-link :to="{ name: 'admin-travels', params: {} }">Travel</router-link></li>
      <li v-else>Travel</li>
      <!-- <li><router-link :to="{ name: 'admin-manage-rate-card', params: {} }">Billing Category</router-link></li> -->
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'AdminFinanceMenu',
    props: ['indexProps'],
    data() {
      return {
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    methods: {},
    beforeMount() {
      if (this.indexProps == undefined) {
        this.indexProps = 0
      }
    },
    mounted() {
      $('.sub-menu li')
        .eq(this.indexProps)
        .addClass('sub-menu-selected')
    },
  }
</script>
