/* eslint-disable */
<template>
  <div class="manage-travel">
    <AdminNavigation ref="navigation" indexProps="7" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title">
          <h1 v-if="travelItinerary.specialistTravelItineraryId == ''">Add New Travel Items {{ availabilityDate != '' ? " - " + formatDate(availabilityDate, "DD/MM/YYYY") : '' }}</h1>
          <h1 v-else>Travel - {{ specialist.fullName }} - {{ formatDate(availabilityDate, "DD/MM/YYYY") }}</h1>
        </div>
        <div class="header-sub-title">Travel items associated with the travel itinerary.</div>
      </div>
    </div>
    <div class="panel">
      <AdminFinanceMenu indexProps="1" />
      <div class="content">
        <div class="row">
          <img class="is-icon" src="@/assets/images/calendar_dark_grey_icon.svg" @click="goToFirstDate()" />
          <strong class="ml-4 mr-2">Location:</strong>{{ getLocationName() }}
          <strong class="mr-2 ml-6">Travelling From:</strong>{{ getSpecialistLocation }}
        </div>

        <div class="row">
          <div class="pl-0 col-12" :class="travelItems.length > 0 ? 'col-md-9' : ''">
            <div class="box border-0">
              <div class="box-heading">
                <span>Flight Details</span>
                <div v-if="!hideButton" class="right-element">
                  <v-btn dark class="add-icon" @click="addNewFlight()">Add new</v-btn>
                </div>
              </div>
              <div class="box-body">
                <div v-for="(travel, index) in travelFlights" :key="index">
                  <div class="row box-row reset-line-height mt-0">
                    <div class="col-12 col-md-4">
                      <label>Airline</label>
                      <!-- <select v-model="travel.airlineId" @change="checkNewAirline(travel)">
                        <option value="" hidden>Select airline</option>
                        <option v-for="airline in airlines" :value="airline.id">
                          {{ airline.name }}
                        </option>
                        <option value="newAirlinePopup">Add New Airline</option>
                      </select> -->
                      <div class="w-100 h-100 d-flex">
                        <Dropdown
                          :key="airlineKey"
                          :ref="'airlineDropdown' + index"
                          :class="'airline-dropdown-' + index"
                          class="select-dropdown w-95"
                          :options="listAirlinesArray[index]"
                          v-on:selected="selectedAirlines($event, index)"
                          v-on:filter="filterAirLines($event, index)"
                          :disabled="false"
                          :maxItem="10000"
                          placeholder="Select airline">
                        </Dropdown>
                        <button class="btn" @click="addNewAirline(index)">
                          <img class="w-75" src="@/assets/images/add_black_icon.svg" />
                        </button>
                      </div>

                    </div>
                    <div class="col-12 col-md-4">
                      <label>Departing</label>
                      <!-- <select v-model="travel.departingAirportId">
                        <option value="" hidden>
                          Select departure airport
                        </option>
                        <option v-for="airport in airports" :value="airport.id">
                          {{ airport.name + ' (' + airport.code + ')' }}
                        </option>
                      </select> -->
                      <Dropdown
                        :ref="'departureDropdown' + index"
                        :class="'departure-dropdown-' + index"
                        class="select-dropdown"
                        :options="listDepartuerAirportsArray[index]"
                        v-on:selected="selectedDeparting($event, index)"
                        v-on:filter="filterDepartuerAirport($event, index)"
                        :disabled="false"
                        :maxItem="10000"
                        placeholder="Select departure airport">
                      </Dropdown>
                    </div>
                    <div class="col-12 col-md-3">
                      <label>Arriving</label>
                      <!-- <select v-model="travel.arrivingAirportId">
                        <option value="" hidden>Select arrival airport</option>
                        <option v-for="airport in airports" :value="airport.id">
                          {{ airport.name + ' (' + airport.code + ')' }}
                        </option>
                      </select> -->
                      <Dropdown
                        :ref="'arrivingDropdown' + index"
                        :class="'arriving-dropdown-' + index"
                        class="select-dropdown"
                        :options="listArrivalAirportsArray[index]"
                        v-on:selected="selectedArrival($event, index)"
                        v-on:filter="filterArrivalAirport($event, index)"
                        :disabled="false"
                        :maxItem="10000"
                        placeholder="Select arrival airport">
                      </Dropdown>
                    </div>
                    <div class=" col-12 col-md-1 d-flex align-items-center justify-content-center">
                      <img src="@/assets/images/delete_icon.svg" class="is-icon" alt="" @click="deleteTravelItem(travel.specialistTravelItemId, 'airline')" v-if="travel.specialistTravelItemId != '' && !hideButton" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label>Flight Number</label>
                      <input type="text" v-model="travel.flightNumber" placeholder="Enter flight number" />
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Departure Time</label>
                      <vue-timepicker format="hh:mm A" v-model="travel.travelStartTime" input-class="form-control w-100 border-0" class="w-100" placeholder="Departure time" :minute-interval="5">
                      </vue-timepicker>
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Departure Date</label>
                      <datepicker format="dd/MM/yyyy" v-model="travel.travelStartDate" :typeable="true" class="w-100" input-class="form-control border-0" placeholder="Departure date"></datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label>Seat Number</label>
                      <input type="text" v-model="travel.seatNumber" placeholder="Enter seat number" />
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Cost</label>
                      <input type="text" v-model="travel.cost" class="cost" placeholder="Enter cost" />
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Booking Reference</label>
                      <input type="text" v-model="travel.bookingReference" placeholder="Enter booking reference" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddAirlineModal :travel="selectedTravel" ref="addAirlineModal" />

            <div class="box border-0">
              <div class="box-heading">
                <span>Accommodation Details</span>
                <div v-if="!hideButton" class="right-element">
                  <v-btn dark @click="addNewAccommodation()" class="add-icon">Add new</v-btn>
                </div>
              </div>
              <div class="box-body">
                <div v-for="(travel, index) in travelAccommodations" :key="index">
                  <div class="row box-row reset-line-height mt-0">
                    <div class="col-12 col-md-4">
                      <label>Accommodation Name</label>
                      <!-- <select v-model="travel.accommodationId" @change="checkNewAccommodation(travel, index)">
                        <option value="" hidden>Select accommodation</option>
                        <option v-for="accommodation in accommodations" :value="accommodation.id">
                          {{ accommodation.name }}
                        </option>
                        <option value="newAccommodationPopup">
                          Add New Accommodation
                        </option>
                      </select> -->
                      <div class="w-100 h-100 d-flex">
                        <Dropdown
                          :key="accommodationKey"
                          :ref="'accommodationDropdown' + index"
                          :class="'accommodation-dropdown-' + index"
                          class="select-dropdown w-95"
                          :options="listAccommodationsArray[index]"
                          v-on:selected="selectedAccommodation($event, index)"
                          v-on:filter="filterAccommodation($event, index)"
                          :disabled="false"
                          :maxItem="10000"
                          placeholder="Select accommodation">
                        </Dropdown>
                        <button class="btn" @click="addNewHouse(index)">
                          <img class="w-75" src="@/assets/images/add_black_icon.svg" />
                        </button>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Address</label>
                      <input type="text" v-model="travel.fullAddress" :id="'address_' + index" disabled />
                    </div>
                    <div class="col-12 col-md-3">
                      <label>Phone</label>
                      <input type="text" v-model="travel.accommodationPhoneNumber" :id="'phone_' + index" disabled />
                    </div>
                    <div
                      class="col-12 col-md-1
                        d-flex
                        align-items-center
                        justify-content-center">
                      <img src="@/assets/images/delete_icon.svg" class="is-icon" alt="" @click="deleteTravelItem(travel.specialistTravelItemId, 'accommodation')" v-if="travel.specialistTravelItemId != ''" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label>Booking reference</label>
                      <input type="text" v-model="travel.bookingReference" placeholder="Booking Reference" />
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Check-in Date &amp; Time</label>
                      <div class="row m-0 p-0">
                        <div class="col-6 m-0 p-0">
                          <vue-timepicker format="hh:mm A" v-model="travel.travelStartTime" input-class="form-control w-90 border-0" class="w-100" placeholder="Check-in time" :minute-interval="5" />
                        </div>
                        <div class="col-6 m-0 p-0">
                          <datepicker format="dd/MM/yyyy" v-model="travel.travelStartDate" :typeable="true" class="w-90" input-class="form-control border-0" placeholder="Check-in date"></datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Check-out Date &amp; Time</label>
                      <div class="row m-0 p-0">
                        <div class="col-6 m-0 p-0">
                          <vue-timepicker format="hh:mm A" v-model="travel.travelEndTime" input-class="form-control w-90 border-0" class="w-100" placeholder="Check-out time" :minute-interval="5" />
                        </div>
                        <div class="col-6 m-0 p-0">
                          <datepicker format="dd/MM/yyyy" v-model="travel.travelEndDate" :typeable="true" class="w-90" input-class="form-control border-0" placeholder="Check-out date"></datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label>Cost</label>
                      <input type="text" class="cost" v-model="travel.cost" placeholder="$0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddAccommodationModal :travel="selectedTravel" ref="addAccommodationModal" />

            <div class="box border-0">
              <div class="box-heading">
                <span>Car Hire Details</span>
                <div v-if="!hideButton" class="right-element">
                  <v-btn dark @click="addNewCarHire()" class="add-icon">Add new</v-btn>
                </div>
              </div>
              <div class="box-body">
                <div v-for="(travel, index) in travelCarHires" :key="index">
                  <div class="row box-row reset-line-height mt-0">
                    <div class="col-12 col-md-4">
                      <label>Car Hire Company</label>
                      <!-- <select v-model="travel.carhireId" @change="updateLocation(travel)">
                        <option value="" hidden>Select car hire</option>
                        <option v-for="carhire in carhires" :value="carhire.id"> {{ carhire.name }} {{ carhire != undefined ? carhire.city : '' }} </option>
                        <option value="newCarhirePopup">
                          Add New Car Rental
                        </option>
                      </select> -->
                      <div class="w-100 h-100 d-flex">
                        <Dropdown
                          :key="carHireKey"
                          :ref="'carHireDropdown' + index"
                          :class="'carHire-dropdown-' + index"
                          class="select-dropdown w-95"
                          :options="listCarhiresArray[index]"
                          v-on:selected="selectedCarHeir($event, index)"
                          v-on:filter="filterCarHair($event, index)"
                          :disabled="false"
                          :maxItem="10000"
                          placeholder="Select car hire">
                        </Dropdown>
                        <button class="btn" @click="addNewCar(index)">
                          <img class="w-75" src="@/assets/images/add_black_icon.svg" />
                        </button>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Pickup Time</label>
                      <vue-timepicker format="hh:mm A" v-model="travel.travelStartTime" input-class="form-control w-100 border-0" class="w-100" placeholder="Pickup time" :minute-interval="5" />
                    </div>
                    <div class="col-12 col-md-3">
                      <label>Date</label>
                      <datepicker format="dd/MM/yyyy" v-model="travel.travelStartDate" :typeable="true" class="w-100" input-class="form-control border-0" placeholder="Date"></datepicker>
                    </div>
                    <div
                      class="col-12 col-md-1
                        d-flex
                        align-items-center
                        justify-content-center">
                      <img
                        src="@/assets/images/delete_icon.svg"
                        class="is-icon"
                        alt=""
                        @click="deleteTravelItem(travel.specialistTravelItemId, 'carHire')"
                        v-if="travel.specialistTravelItemId != ''"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label>Pickup Location</label>
                      <input type="text" v-model="travel.accommodationAddress1" disabled />
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Booking Reference</label>
                      <input type="text" v-model="travel.bookingReference" placeholder="Booking Reference" />
                    </div>
                    <div class="col-12 col-md-4">
                      <label>Cost</label>
                      <input type="text" class="cost" v-model="travel.cost" placeholder="Cost" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddCarhireModal :travel="selectedTravel" ref="addCarhireModal" />

            <div v-if="travelItinerary.specialistTravelItineraryId != ''" class="box">
              <div class="box-heading tab">
                <div class="tab-heading" @click="openTab(0)">Notes</div>
                <div class="tab-heading" @click="openTab(1)">Itinerary File</div>
                <div class="right-element">
                  <v-btn v-if='selected == 0 && !hideButton' class="text-white" :class="(travelItinerary.specialistTravelItineraryId != '' && travelItinerary.notes != '') ? '' : 'add-icon'" @click="openAddNew">{{ (travelItinerary.specialistTravelItineraryId != '' && travelItinerary.notes != '') ? 'Edit Note' : 'Add New' }}</v-btn>
                  <div v-if="!completed">
                    <v-btn v-if='selected == 1' class="text-white" :class="(travelItinerary.specialistTravelItineraryId != '' && travelItinerary.filePath != '') ? '' : 'add-icon'" @click="openAddNew">{{ (travelItinerary.specialistTravelItineraryId != '' && travelItinerary.filePath != '') ? 'Replace' : 'Upload' }}</v-btn>
                  </div>
                  <AddNoteModal ref="addNoteModal" fromProps="travel" />
                </div>
              </div>
              <div class="box-body tab-content hidden-element">
                <div class="box-row">
                  <div
                    v-if="travelItinerary.specialistTravelItineraryId != '' && travelItinerary.notes != undefined"
                    style="width:100%; line-height:2"
                    class="box-col pt-4"
                    @click="openAddNoteModal">
                    {{ travelItinerary.notes }}
                  </div>
                  <div v-else style="width:100%; line-height:2" class="box-col pt-4" @click="openAddNoteModal">
                    {{ note }}
                  </div>
                  <!-- <div class="is-icon mr-1">
                    <img src="@/assets/images/delete_icon.svg" />
                  </div> -->
                </div>
              </div>

              <div class="box-body tab-content hidden-element">
                <div class="row my-0">
                  <div>
                    <div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="width: 100%" class="long-text">
                              File Name
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="content-scroll-y" style="height: auto; max-height: 460px">
                      <table class="table remove-min-height">
                        <tbody >
                          <tr>
                            <td style="width: 100%" v-if="travelItinerary.specialistTravelItineraryId != '' && travelItinerary.filePath != ''">
                              <a class="is-icon" @click="getFile(travelItinerary)" target="_blank">{{ travelItinerary.fileName }}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div v-if="!completed" class="file-upload-area border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                      <input
                        type="file"
                        ref="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        accept=".pdf,.docx" />
                      <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                        <div>Drop Itinerary file in this area or <span style="text-decoration: underline" class="is-icon">click here</span> to upload files.</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box">
              <div class="box-heading">
                <span>Travel Receipts</span>
              </div>
              <div class="box-body my-3">
                <div>
                  <div class="content-scroll-y" style="height:auto;max-height:460px;">
                    <table class="table remove-min-height">
                      <tbody key="fileKey">
                        <tr v-for="(file, index) in travelReceipts" :key="index" class="py-0">
                          <td style="width:100%" class="long-text py-0">
                            <a class="is-icon" @click="getFileTr(file)" target="_blank">{{ file.invoiceFileName }}</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="alert alert-success hidden-element">
              <a class="close" title="close">×</a>
              <b>Success:</b> {{ successMessage }}
            </div>
            <div class="alert alert-danger hidden-element">
              <a class="close" title="close">×</a>
              <b>Error:</b> {{ errorMessage }}
            </div>

            <div class="icon-group right-float-element">
              <v-btn class="btn-light mr-3" v-if="this.user.type == 4 && hideButton && !hideApproveButton" @click="changeStatus()">Revert to In Progress</v-btn>
              <!-- <v-btn class="btn-light" @click="changeStatus()" v-if="this.user.type == 4 && hideButton"></v-btn> -->
              <v-btn dark @click="approveItinerary()" v-if="this.user.type == 4 && !hideApproveButton">Approve</v-btn>
              <v-btn dark @click="finalize()" v-if="travelItinerary.specialistTravelItineraryId != '' && travelItinerary.dateCompleted == ''">Finalize</v-btn>
              <v-btn dark @click="update()" v-if="!completed">Update</v-btn>
            </div>
          </div>
          <div class="col-12 col-md-3" v-if="travelItems.length > 0">
            <div class="row">
              <div class="col-12">
                <strong>Availabilities associated with this itinerary: </strong>
              </div>
              <div class="col-12">
                <div class="d-flex align-items-center pt-2" v-for="(availability, index) in availabilities">
                  <div>
                    {{ getAvailabilityDateTime(availability) }}
                    <br>
                    {{ availability.fullLocation }}
                  </div>
                  <div v-if="index > 0 && !hideButton" class="right-element"><img @click="deleteAvailabilityItinerary(index)" src="@/assets/images/delete_icon.svg" class="is-icon pl-3" /></div>
                </div>
              </div>
              <div class="col-12" v-if="!hideButton">
                Add another availability to this itinerary:
              </div>
              <div class="col-12" v-if="!hideButton">
                <select v-model="availabilityToAdd" class="textbox">
                  <option value="">Please select ...</option>
                  <option v-for="(availability, index) in allAvailabilities" :value="availability.id">
                    {{ getAvailabilityDateTime(availability) + " - " + availability.fullLocation }}
                  </option>
                </select>
              </div>
              <div v-if="travelItinerary.specialistTravelItineraryId != '' && travelItinerary.approvedBy == '' && !hideButton" class="col-12">
                <v-btn dark @click="addToItinerary">Add</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminFinanceMenu from '@/components/AdminFinanceMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import AddNoteModal from '@/components/AddNoteModal'
  import AddAirlineModal from '@/components/AddAirlineModal'
  import AddAccommodationModal from '@/components/AddAccommodationModal'
  import AddCarhireModal from '@/components/AddCarhireModal'
  import Dropdown from 'vue-simple-search-dropdown';

  export default {
    name: 'AdminManageTravel',
    components: {
      Dropdown,
      AdminNavigation,
      AdminFinanceMenu,
      Header,
      AddNoteModal,
      AddAirlineModal,
      AddAccommodationModal,
      AddCarhireModal,
    },
    data() {
      return {
        hasError: false,
        hideButton: false,
        hideApproveButton: false,
        successMessage: '',
        errorMessage: '',

        travelItinerary: utilities.initTravelItinerary(),
        travelItems: [],
        travelReceipts: [],
        itineraryId: '',
        availabilityId: '',

        availability: utilities.initAvailability(),
        specialist: utilities.initSpecialist(),

        airports: [],
        listDepartuerAirports: [],
        listDepartuerAirportsArray: [],
        listArrivalAirports: [],
        listArrivalAirportsArray: [],
        originalListAirports: [],

        airlines: [],
        listAirlines: [],
        listAirlinesArray: [],
        originalListAirlines: [],

        accommodations: [],
        listAccommodations: [],
        listAccommodationsArray: [],
        originalListAccommodations: [],

        carhires: [],
        listCarhires:[],
        listCarhiresArray:[],
        originalListCarhires: [],

        travelAccommodations: [],
        travelFlights: [],
        travelCarHires: [],
        fileTypes: [],
        carhireLocation: '',
        selectedTravel: {},

        availabilities: [],
        allAvailabilities: [],
        availabilityToAdd: '',
        availabilityDate: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        note: '',
        filelist: [],

        completed: false,
        selected: 0,
        fileKey: 0,
        itineraryKey: 0,
        airlineKey: 0,
        accommodationKey: 0,
        carHireKey: 0,

        departingAirportId: '',
        arrivingAirportId: '',
        airlineId: '',
      }
    },
    computed: {
      getSpecialistLocation() {
        var location = ""
        if(this.specialist.id != '' && this.specialist.locations[0] != undefined) location = utilities.addComma(this.specialist.locations[0].cityName) + this.specialist.locations[0].state
        return location
      },
    },
    methods: {
      openTab(index) {
        utilities.openTab(index)
        this.selected = index
      },

      filterDepartuerAirport(text, index) {
        this.listDepartuerAirportsArray[index] = this.originalListAirports.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      },

      filterArrivalAirport(text, index) {
        this.listArrivalAirportsArray[index] = this.originalListAirports.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      },

      filterAirLines(text, index) {
        this.listAirlinesArray[index] = this.originalListAirlines.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      },

      filterAccommodation(text, index) {
        this.listAccommodationsArray[index] = this.originalListAccommodations.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      },

      filterCarHair(text, index) {
        this.listCarhiresArray[index] = this.originalListCarhires.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      },

      selectedDeparting(item, index) {
        if (item.id && this.travelFlights) {
          this.travelFlights[index].departingAirportId = item.id
        }
      },
      selectedArrival(item, index) {
        if (item.id && this.travelFlights) {
          this.travelFlights[index].arrivingAirportId = item.id
        }
      },

      selectedAirlines(item, index) {
        if (item.id && this.travelFlights) {
          this.travelFlights[index].airlineId = item.id
        }
      },

      selectedAccommodation(item, index) {
        if (item.id && this.travelAccommodations) {
          this.travelAccommodations[index].accommodationId = item.id
          this.updateAccommodation(item.id, index)
        }
      },

      selectedCarHeir(item, index) {
        if (item.id && this.travelCarHires) {
          this.travelCarHires[index].carhireId = item.id
          this.updateLocation(item.id, index)
        }
      },

      async deleteAvailabilityItinerary(index) {
        var item = this.availabilities[index]
        this.$confirm({
          content: 'Are you sure you want to remove this availability from the itinerary?',
          onConfirm: async () => {
            this.$store.dispatch('loading/setLoadComponent', true)
            var params = {
              id: item.id,
              userID: this.user.id,
              userName: this.user.name,
            }
            var result = await utilities.deleteAvailabilitiesItinerary(params)
            if (result) {
              this.availabilities.splice(index, 1)
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        })

      },

      getLocationName() {
        if (this.availabilities.length > 0) {
          return this.availabilities[0].fullLocation
        }
        if (this.availabilityId != '') {
          return this.availability.fullLocation
        }
        return ""
      },
      openAddNew() {
        if($(".tab-content").eq(0).is(":visible")) this.openAddNoteModal()
        else this.openAddFile()
      },
      openAddNoteModal() {
        if (this.travelItinerary.specialistTravelItineraryId != '') this.$refs.addNoteModal.note = this.travelItinerary.notes
        else this.$refs.addNoteModal.note = this.note
        this.$refs.addNoteModal.handleModal()
      },
      openAddFile() {
        $('#assetsFieldHandle').trigger('click')
      },
      async onChange() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.filelist = [...this.$refs.file.files]
        var file = this.filelist[0]
        var fileName = moment().unix() + '-' + file.name.replace(/\s/g, '-')
        var params = {
          name: fileName,
          fileType: file.type,
          uploadtype: 'if',
          id: this.itineraryId,
        }
        var result = await utilities.postUploadImageURL(params)
        var postData = new FormData();

        for(var key in result.fields){
            postData.append(key, result.fields[key]);
        }
        postData.append('file', file)
        await utilities.postImage(result.url, postData)

        var params = {
          SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
          FileName: fileName,
          DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
          FileTypeID: this.fileTypes.find((x) => x.name == 'Travel Itinerary').id,
          UploadedBy: this.user.id,
          Mimetype: file.type,
          FileStatus: 1,
          UserID: this.user.id,
          Username: this.user.name
        }
        // await utilities.postTravelItineraryFile(params)

        if(this.availabilityId != '') {
          var travelItinerary = await utilities.getTravelItinerary(this.availabilityId)
        } else if (this.itineraryId != '') {
          var travelItinerary = await utilities.getTravelItineraryByItineraryId(this.itineraryId)
        }

        if(travelItinerary != undefined) {
          if (travelItinerary.fileName != '') {
            this.travelItinerary = travelItinerary
            this.itineraryKey = +1
          }
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      dragover(event) {
        event.preventDefault()
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },

      formatTime(date, time) {
        return moment(date + ' ' + time, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A')
      },
      formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
        if (date != undefined && date.length > 0) {
          return utilities.formatDate(date, format, currentFormat)
        }
        return ''
      },

      addNewFlight() {
        this.travelFlights.push(utilities.initTravelDetail())
        this.listAirlinesArray.push(_.cloneDeep(this.listAirlines))
        this.listDepartuerAirportsArray.push(_.cloneDeep(this.listDepartuerAirports))
        this.listArrivalAirportsArray.push(_.cloneDeep(this.listArrivalAirports))
      },
      addNewAccommodation() {
        this.travelAccommodations.push(utilities.initTravelDetail())
        this.listAccommodationsArray.push(_.cloneDeep(this.listAccommodations))
      },
      addNewCarHire() {
        this.travelCarHires.push(utilities.initTravelDetail())
        this.listCarhiresArray.push(_.cloneDeep(this.listCarhires))
      },

      addNewAirline(index) {
        this.$refs.addAirlineModal.reset(index)
        this.$refs.addAirlineModal.handleModal()
      },
      addNewHouse(index) {
        this.$refs.addAccommodationModal.reset(index)
        this.$refs.addAccommodationModal.handleModal()
      },
      addNewCar(index) {
        this.$refs.addCarhireModal.reset(index)
        this.$refs.addCarhireModal.handleModal()
      },

      setNewAirline(airlineId, index) {
        for (var i = 0; i < this.travelFlights.length; i++) {
          var flight = i == index ? this.originalListAirlines.find((x) => x.id == airlineId) : this.originalListAirlines.find((x) => x.id == this.travelFlights[i].airlineId)
          if (flight != undefined) {
            $(".airline-dropdown-" + i + " input").val(flight.name)
            if(this.$refs['airlineDropdown' + i]) {
              this.$refs['airlineDropdown' + i].searchFilter = flight.name
            }
          }
        }
      },
      setNewAccommodation(accommodationId, index) {
        for (var i = 0; i < this.travelAccommodations.length; i++) {
          var accommodation = i == index ? this.originalListAccommodations.find((x) => x.id == accommodationId) : this.originalListAccommodations.find((x) => x.id == this.travelAccommodations[i].accommodationId)
          if (accommodation != undefined) {
            $(".accommodation-dropdown-" + i + " input").val(accommodation.name)
            if(this.$refs['accommodationDropdown' + i]) {
              this.$refs['accommodationDropdown' + i].searchFilter = accommodation.name
            }
          }
        }
      },
      setNewCarHire(carhireId, index) {
        for (var i = 0; i < this.travelCarHires.length; i++) {
          var carHire = i == index ? this.originalListCarhires.find((x) => x.id == carhireId) : this.originalListCarhires.find((x) => x.id == this.travelCarHires[i].carhireId)
          if (carHire != undefined) {
            $(".carHire-dropdown-" + i + " input").val(carHire.name)
            if(this.$refs['carHireDropdown' + i]) {
              this.$refs['carHireDropdown' + i].searchFilter = carHire.name
            }
          }
        }
      },

      setFlightDropdownDetails() {
        for (var i = 0; i < this.travelFlights.length; i++) {
          var flight = this.originalListAirlines.find((x) => x.id == this.travelFlights[i].airlineId)
          if (flight != undefined) {
            $(".airline-dropdown-" + i + " input").val(flight.name)
            if(this.$refs['airlineDropdown' + i]) {
              this.$refs['airlineDropdown' + i].searchFilter = flight.name
            }
          }

          var departure = this.originalListAirports.find((x) => x.id == this.travelFlights[i].departingAirportId)
          if (departure != undefined) {
            $(".departure-dropdown-" + i + " input").val(departure.name)
            if(this.$refs['departureDropdown' + i]) {
              this.$refs['departureDropdown' + i].searchFilter = departure.name
            }
          }

          var arrival = this.originalListAirports.find((x) => x.id == this.travelFlights[i].arrivingAirportId)
          if (arrival != undefined) {
            $(".arriving-dropdown-" + i + " input").val(arrival.name)
            if(this.$refs['arrivingDropdown' + i]) {
              this.$refs['arrivingDropdown' + i].searchFilter = arrival.name
            }
          }
        }
      },
      setAccommodationDropdownDetails() {
        for (var i = 0; i < this.travelAccommodations.length; i++) {
          var accommodation = this.originalListAccommodations.find((x) => x.id == this.travelAccommodations[i].accommodationId)
          if (accommodation != undefined) {
            $(".accommodation-dropdown-" + i + " input").val(accommodation.name)
            if(this.$refs['accommodationDropdown' + i]) {
              this.$refs['accommodationDropdown' + i].searchFilter = accommodation.name
            }
          }
        }
      },
      setCarHireDropdownDetails() {
        for (var i = 0; i < this.travelCarHires.length; i++) {
          var carHire = this.originalListCarhires.find((x) => x.id == this.travelCarHires[i].carhireId)
          if (carHire != undefined) {
            $(".carHire-dropdown-" + i + " input").val(carHire.name)
            if(this.$refs['carHireDropdown' + i]) {
              this.$refs['carHireDropdown' + i].searchFilter = carHire.name
            }
          }
        }
      },

      updateAccommodation(accommodationId, index) {
        var travel = this.accommodations.find((x) => x.id == accommodationId)
        if(travel) {
          this.travelAccommodations[index].fullAddress = (travel.addressLine1 != undefined ? (travel.addressLine1 != '' ? travel.addressLine1 + ' ' + travel.addressLine2 : '') : '') + ', ' + travel.city
          this.travelAccommodations[index].accommodationPhoneNumber = travel.phoneNumber
        }
      },
      updateLocation(carhireId, index) {
        var travel = this.carhires.find((x) => x.id == carhireId)
        if(travel) {
          this.travelCarHires[index].accommodationAddress1 = travel.addressLine1 != undefined ? travel.addressLine1 : ''
        }
      },

      updateListAirlinesArray() {
        this.listAirlinesArray = []
        this.travelFlights.map(x => {
          this.listAirlinesArray.push(_.cloneDeep(this.listAirlines))
        })
      },
      updateListDepartuerAirportsArray() {
        this.listDepartuerAirportsArray = []
        this.travelFlights.map(x => {
          this.listDepartuerAirportsArray.push(_.cloneDeep(this.listDepartuerAirports))
        })
      },
      updateListArrivalAirportsArray() {
        this.listArrivalAirportsArray = []
        this.travelFlights.map(x => {
          this.listArrivalAirportsArray.push(_.cloneDeep(this.listArrivalAirports))
        })
      },
      updateListAccommodationsArray() {
        this.listAccommodationsArray = []
        this.travelAccommodations.map(x => {
          this.listAccommodationsArray.push(_.cloneDeep(this.listAccommodations))
        })
      },
      updateListCarhiresArray() {
        this.listCarhiresArray = []
        this.travelCarHires.map(x => {
          this.listCarhiresArray.push(_.cloneDeep(this.listCarhires))
        })
      },

      async deleteTravelItem(id, travelItemType) {
        var res = await utilities.deleteTravelItem(id, this.user.id, this.user.name)
        if (res) {
          this.successMessage = 'Travel item successfully deleted!'
          if(travelItemType == 'airline') {
            this.travelFlights = this.travelFlights.filter((x) => x.specialistTravelItemId != id)
            this.updateListAirlinesArray()
            setTimeout(() => {
              this.setFlightDropdownDetails()
            }, 500)
          } else if(travelItemType == 'accommodation') {
            this.travelAccommodations = this.travelAccommodations.filter((x) => x.specialistTravelItemId != id)
            this.updateListAccommodationsArray()
            setTimeout(() => {
              this.setAccommodationDropdownDetails()
            }, 500)
          } else if(travelItemType == 'carHire') {
            this.travelCarHires = this.travelCarHires.filter((x) => x.specialistTravelItemId != id)
            this.updateListCarhiresArray()
            setTimeout(() => {
              this.setCarHireDropdownDetails()
            }, 500)
          }
          utilities.showAlert('.alert-success')
        } else {
          this.errorMessage = 'There was an error deleting this travel item.'
          utilities.showAlert('.alert-danger')
        }
      },
      getAvailabilityDateTime(a) {
        return (
          utilities.formatDate(a.availabilityDate + ' ' + a.startTime, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.SSSSSS') +
          ' - ' +
          utilities.formatDate(a.availabilityDate + ' ' + a.endTime, 'DD/MM/YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.SSSSSS')
        )
      },

      async addToItinerary() {
        if(this.availabilityToAdd != '') {
          this.$store.dispatch('loading/setLoadComponent', true)
          // 1. Get the availability ID
          var availability = this.availabilityToAdd

          // 2. Push to db
          var params = {
            SpecialistAvailabilityID: availability,
            SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var res = await utilities.postAvailabilityToItinerary(params)
          if (res) {
            // this.allAvailabilities.forEach((x, i) => {
            //   if (x.id == availability) {
            //     this.availabilities.push(this.allAvailabilities.splice(i, 1)[0])
            //     this.availabilityToAdd = ''
            //   }
            // })
            await this.loadAvailabilityByItinerary()
          } else {
            this.errorMessage = 'There was an error adding the specified availability to this travel itinerary. Please try again.'
            utilities.showAlert('.alert-danger')
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },

      goToFirstDate() {
        if (this.availabilities == undefined || this.availabilities.length == 0) {
          this.$router.push({
            name: 'admin-availability-bookings',
            query: {
              id: this.availability.specialistId,
              date: this.availability.availabilityDate,
            },
          })
        } else {
          var dates = this.availabilities
            .map((x) => x.availabilityDate)
            .sort((a, b) => {
              return moment(a, "YYYY-MM-DD").isBefore(moment(b, "YYYY-MM-DD"), 'd') ? -1 : 1
            })
          this.availabilityDate = dates[0]
          this.$router.push({
            name: 'admin-availability-bookings',
            query: {
              id: this.specialist.id,
              date: dates[0],
            },
          })
        }
      },
      getFileTr(file) {
        var params = {
          uploadtype: "tr",
          fn: file.invoiceFileName,
          id: this.itineraryId
        }
        utilities.getFileUrlNew(params).then(res => {
          let a = document.createElement('a');
          let fileName = file.invoiceFileName
          a.target = '_blank'
          a.download = fileName;
          a.href = res
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(res);
          document.body.removeChild(a);
        })
      },

      getFile(file) {
        const params = {
          uploadtype: "if",
          fn: file.fileName,
          id: this.booking.id
        }
        utilities.getFileUrlNew(params).then(res => {
          let a = document.createElement('a');
          let fileName = file.fileName
          a.target = '_blank'
          a.download = fileName;
          a.href = res
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(res);
          document.body.removeChild(a);
        })
      },

      async approveItinerary() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          ApprovedBy: this.user.id,
          SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
          SpecialistTravelItineraryStatus: 3,
          DateApproved: moment().format('YYYY-MM-DD'),
        }
        var callback = (response) => {
          if (response) {
            this.hideApproveButton = true
            this.successMessage = 'Travel itinerary has been approved.'
            utilities.showAlert('.alert-success')
          }
        }
        await utilities.putTravelItinerary(params, callback)
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async finalize() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
          DateCompleted: moment().format('YYYY-MM-DD'),
          SpecialistTravelItineraryStatus: 2,
        }
        var callback = (response) => {
          // Reload
          this.$router.go()
        }
        await utilities.putTravelItinerary(params, callback)
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async changeStatus() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
          SpecialistTravelItineraryStatus: 1,
          ApprovedBy: 'null',
          DateApproved: 'null',
          DateCompleted: 'null',
        }
        var callback = (response) => {
          if (response) {
            this.hideApproveButton = false
            this.hideButton = false
            this.completed = false
            this.successMessage = 'Travel itinerary has been Updated.'
            utilities.showAlert('.alert-success')
          }
        }
        await utilities.putTravelItinerary(params, callback)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      validateTravelItems() {
        if(this.travelFlights.length == 0 && this.travelAccommodations.length == 0 && this.travelCarHires.length == 0) {
          return false
        }
        return true
      },
      validateTravelFlights() {
        var result = true
        for(var i = 0; i < this.travelFlights.length; i ++) {
          if(this.travelFlights[i].travelStartDate == '') {
            result = false
            break
          }
        }
        return result
      },
      validateTravelAccommodations() {
        var result = true
        for(var i = 0; i < this.travelAccommodations.length; i ++) {
          if(this.travelAccommodations[i].travelStartDate != '' && this.travelAccommodations[i].travelStartTime != ''
            && this.travelAccommodations[i].travelEndDate != '' && this.travelAccommodations[i].travelEndTime != '') {
            var start = this.travelAccommodations[i].travelStartDate + ' ' + this.travelAccommodations[i].travelStartTime
            var end = this.travelAccommodations[i].travelEndDate + ' ' + this.travelAccommodations[i].travelEndTime
            if (moment(end, "YYYY-MM-DD hh:mm A").isBefore(moment(start, "YYYY-MM-DD hh:mm A"))) {
              result = false
              break
            }
          } else if(this.travelAccommodations[i].travelStartDate == '' || this.travelAccommodations[i].travelEndDate == '') {
            result = false
            break
          }
        }
        return result
      },
      validateTravelCarHires() {
        var result = true
        for(var i = 0; i < this.travelCarHires.length; i ++) {
          if(this.travelCarHires[i].travelStartDate == '') {
            result = false
            break
          }
        }
        return result
      },
      async update() {
        if(!this.validateTravelItems()) {
          this.errorMessage = 'Please add a travel item before saving this itinerary!'
          utilities.showAlert('.alert-danger')
          return
        }
        if(!this.validateTravelFlights() || !this.validateTravelCarHires()) {
          this.errorMessage = 'Please check all travel start dates. Travel start dates are required.'
          utilities.showAlert('.alert-danger')
          return
        }
        if(!this.validateTravelAccommodations()) {
          this.errorMessage = 'Please check all travel dates and times. Travel end date and time cannot be before travel start date and time.'
          utilities.showAlert('.alert-danger')
          return
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        if (this.travelItems.length > 0 || this.itineraryId) {  // Have Itinerary Id
          await Promise.all(this.travelFlights.map(async (x) => {
            var cost = x.cost.toString().split('$')
            cost = cost[cost.length - 1]
            var params = {
              SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
              TravelType: 1,
              FlightNumber: x.flightNumber,
              SeatNumber: x.seatNumber,
              BookingReference: x.bookingReference,
              AirlineID: x.airlineId,
              Cost: Number(cost),
              DepartingAirportID: x.departingAirportId,
              ArrivingAirportID: x.arrivingAirportId,
              UserID: this.user.id,
              Username: this.user.name,
            }
            if (x.travelStartDate != '' && utilities.validateDate(x.travelStartDate)) {
              params.TravelStartDate = utilities.formatDate(x.travelStartDate, "YYYY-MM-DD")
            }
            if (x.travelStartTime != '' && utilities.validateTime(x.travelStartTime)) {
              params.TravelStartTime = utilities.formatDate(x.travelStartTime, "HH:mm:ss", "hh:mm A")
            }
            if (x.specialistTravelItemId == undefined || x.specialistTravelItemId == '') {
              await utilities.postSpecialistTravelItem(params)
            } else {
              params.SpecialistTravelItemID = x.specialistTravelItemId
              await utilities.putSpecialistTravelItem(params)
            }
          }))
          await Promise.all(this.travelAccommodations.map(async (x) => {
            var cost = x.cost.toString().split('$')
            cost = cost[cost.length - 1]
            var params = {
              SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
              TravelType: 2,
              BookingReference: x.bookingReference,
              Cost: Number(cost),
              AccommodationID: x.accommodationId,
              UserID: this.user.id,
              Username: this.user.name,
            }
            if (x.travelStartDate != '' && utilities.validateDate(x.travelStartDate)) {
              params.TravelStartDate = utilities.formatDate(x.travelStartDate, "YYYY-MM-DD")
            }
            if (x.travelStartTime != '' && utilities.validateTime(x.travelStartTime)) {
              params.TravelStartTime = utilities.formatDate(x.travelStartTime, "HH:mm:ss", "hh:mm A")
            }
            if (x.travelEndDate != '' && utilities.validateDate(x.travelEndDate)) {
              params.TravelEndDate = utilities.formatDate(x.travelEndDate, "YYYY-MM-DD")
            }
            if (x.travelEndTime != '' && utilities.validateTime(x.travelEndTime)) {
              params.TravelEndTime = utilities.formatDate(x.travelEndTime, "HH:mm:ss", "hh:mm A")
            }
            if (x.specialistTravelItemId == undefined || x.specialistTravelItemId == '') {
              await utilities.postSpecialistTravelItem(params)
            } else {
              params.SpecialistTravelItemID = x.specialistTravelItemId
              await utilities.putSpecialistTravelItem(params)
            }
          }))
          await Promise.all(this.travelCarHires.map(async (x) => {
            var cost = x.cost.toString().split('$')
            cost = cost[cost.length - 1]
            var params = {
              SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
              TravelType: 2,
              Cost: Number(cost),
              CarhireID: x.carhireId,
              UserID: this.user.id,
              Username: this.user.name,
              BookingReference: x.bookingReference,
            }
            if (x.travelStartDate != '' && utilities.validateDate(x.travelStartDate)) {
              params.TravelStartDate = utilities.formatDate(x.travelStartDate, "YYYY-MM-DD")
            }
            if (x.travelStartTime != '' && utilities.validateTime(x.travelStartTime)) {
              params.TravelStartTime = utilities.formatDate(x.travelStartTime, "HH:mm:ss", "hh:mm A")
            }
            if (x.specialistTravelItemId == undefined || x.specialistTravelItemId == '') {
              await utilities.postSpecialistTravelItem(params)
            } else {
              params.SpecialistTravelItemID = x.specialistTravelItemId
              await utilities.putSpecialistTravelItem(params)
            }
          }))
          this.successMessage = 'All travel details updated.'
          utilities.showAlert('.alert-success')
          await this.loadTravelItems()
        } else if(this.availability) {  // Don't have Itinerary Id
          var params = {
            SpecialistAvailabilityID: this.availability.id,
            SpecialistID: this.availability.specialistId,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var callback = (response) => {
            this.travelItinerary.specialistTravelItineraryId = response
          }
          var result = await utilities.postTravelItinerary(params, callback)
          if(result) {
            this.openTab(0)
            window.history.replaceState({}, document.title, location.pathname + "?id=" + this.travelItinerary.specialistTravelItineraryId + "&availabilityId=" + this.availability.id)

            await Promise.all(this.travelFlights.map(async (x) => {
              var cost = x.cost.toString().split('$')
              cost = cost[cost.length - 1]
              var params = {
                SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
                TravelType: 1,
                FlightNumber: x.flightNumber,
                SeatNumber: x.seatNumber,
                BookingReference: x.bookingReference,
                AirlineID: x.airlineId,
                Cost: Number(cost),
                DepartingAirportID: x.departingAirportId,
                ArrivingAirportID: x.arrivingAirportId,
                UserID: this.user.id,
                Username: this.user.name,
              }
              if (x.travelStartDate != '' && utilities.validateDate(x.travelStartDate)) {
                params.TravelStartDate = utilities.formatDate(x.travelStartDate, "YYYY-MM-DD")
              }
              if (x.travelStartTime != '' && utilities.validateTime(x.travelStartTime)) {
                params.TravelStartTime = utilities.formatDate(x.travelStartTime, "HH:mm:ss", "hh:mm A")
              }
              await utilities.postSpecialistTravelItem(params)
            }))
            await Promise.all(this.travelAccommodations.map(async (x) => {
              var cost = x.cost.toString().split('$')
              cost = cost[cost.length - 1]
              var params = {
                SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
                SpecialistAvailabilityID: this.availability.id,
                TravelType: 2,
                BookingReference: x.bookingReference,
                Cost: Number(cost),
                AccommodationID: x.accommodationId,
                UserID: this.user.id,
                Username: this.user.name,
              }
              if (x.travelStartDate != '' && utilities.validateDate(x.travelStartDate)) {
                params.TravelStartDate = utilities.formatDate(x.travelStartDate, "YYYY-MM-DD")
              }
              if (x.travelStartTime != '' && utilities.validateTime(x.travelStartTime)) {
                params.TravelStartTime = utilities.formatDate(x.travelStartTime, "HH:mm:ss", "hh:mm A")
              }
              if (x.travelEndDate != '' && utilities.validateDate(x.travelEndDate)) {
                params.TravelEndDate = utilities.formatDate(x.travelEndDate, "YYYY-MM-DD")
              }
              if (x.travelEndTime != '' && utilities.validateTime(x.travelEndTime)) {
                params.TravelEndTime = utilities.formatDate(x.travelEndTime, "HH:mm:ss", "hh:mm A")
              }
              await utilities.postSpecialistTravelItem(params)
            }))
            await Promise.all(this.travelCarHires.map(async (x) => {
              var cost = x.cost.toString().split('$')
              cost = cost[cost.length - 1]
              var params = {
                SpecialistTravelItineraryID: this.travelItinerary.specialistTravelItineraryId,
                SpecialistAvailabilityID: this.availability.id,
                TravelType: 3,
                Cost: Number(cost),
                CarhireID: x.carhireId,
                UserID: this.user.id,
                Username: this.user.name,
                BookingReference: x.bookingReference,
              }
              if (x.travelStartDate != '' && utilities.validateDate(x.travelStartDate)) {
                params.TravelStartDate = utilities.formatDate(x.travelStartDate, "YYYY-MM-DD")
              }
              if (x.travelStartTime != '' && utilities.validateTime(x.travelStartTime)) {
                params.TravelStartTime = utilities.formatDate(x.travelStartTime, "HH:mm:ss", "hh:mm A")
              }
              await utilities.postSpecialistTravelItem(params)
            }))
            this.successMessage = 'All travel details updated.'
            utilities.showAlert('.alert-success')
            await this.loadTravelItems()
          }
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async loadTravelItems() {
        if (this.itineraryId) {
          this.travelItems = await utilities.getSpecialistTravelItineraryByItineraryId(this.itineraryId)
        } else if (this.availabilityId) {
          this.travelItems = await utilities.getSpecialistTravelItineraryByAvailabilityId(this.availabilityId)
        }
        if (this.travelItems.length > 0) {
          this.travelItems.map((x) => {
            x.travelStartTime = moment(x.travelStartDate + ' ' + x.travelStartTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A')
            x.travelEndTime = moment(x.travelEndDate + ' ' + x.travelEndTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A')
            x.fullAddress = (x.accommodationAddress1 != '' ? x.accommodationAddress1 + ' ' + x.accommodationAddress2 : '') + ', ' + x.accommodationCity
            if (x.cost != '') x.cost = '$' + parseFloat(x.cost).toFixed(2)
            this.travelItems.sort((a, b) => {
              moment(b.travelStartDate + " " + b.travelStartTime, "YYYY-MM-DD hh:mm A").isAfter(moment(a.travelStartDate + a.travelStartTime, "YYYY-MM-DD hh:mm A")) ? 1 : -1
            })
          })

          this.travelFlights = this.travelItems.filter((x) => x.airlineId != '')
          this.updateListAirlinesArray()
          this.updateListDepartuerAirportsArray()
          this.updateListArrivalAirportsArray()

          this.travelAccommodations = this.travelItems.filter((x) => x.accommodationId != '')
          this.updateListAccommodationsArray()

          this.travelCarHires = this.travelItems.filter((x) => x.carhireId != '')
          this.travelCarHires.map((x) => {
            var carHire = this.carhires.find((y) => y.id === x.carhireId)
            x.accommodationAddress1 = carHire != undefined && carHire.addressLine1 != undefined ? carHire.addressLine1 : ''
          })
          this.updateListCarhiresArray()

          setTimeout(() => {
            this.setFlightDropdownDetails()
            this.setAccommodationDropdownDetails()
            this.setCarHireDropdownDetails()
          }, 800)
        }
      },

      async loadAvailabilityByItinerary() {
        if (this.itineraryId != '') {
          this.availabilities = await utilities.getSpecialistAvailabilityByItineraryId(this.itineraryId)
          if (this.availabilities.length > 0) {
            this.availabilities.sort((a, b) => {
                return moment(a.availabilityDate, "YYYY-MM-DD").isBefore(moment(b.availabilityDate, "YYYY-MM-DD")) ? -1 : 1
            })

            var dates = this.availabilities
              .map((x) => x.availabilityDate)
              .sort((a, b) => {
                return moment(a, "YYYY-MM-DD").isBefore(moment(b, "YYYY-MM-DD"), 'd') ? -1 : 1
              })
            this.availabilityDate = dates[0]
          }
        }
      },

      async loadTravel() {
        this.itineraryId = this.$route.query.id != undefined ? this.$route.query.id : ''
        this.availabilityId = this.$route.query.availabilityId != undefined ? this.$route.query.availabilityId : ''

        if(this.availabilityId != '') {
          this.availability = await utilities.getSpecialistAvailabilities(this.availabilityId)
          this.specialist = await utilities.getSpecialistById(this.availability.specialistId)
          var travelItinerary = await utilities.getTravelItinerary(this.availabilityId)
        } else if (this.itineraryId != '') {
          var travelItinerary = await utilities.getTravelItineraryByItineraryId(this.itineraryId)
          this.specialist = await utilities.getSpecialistById(travelItinerary.specialistId)
        }

        await this.loadTravelItems()
        if (travelItinerary != undefined) {
          this.itineraryId = travelItinerary.specialistTravelItineraryId
          this.travelItinerary = travelItinerary
          if (this.travelItinerary.fileName != '') {
            var params = {
              uploadtype: "if",
              fn: this.travelItinerary.fileName,
              id: this.itineraryId
            }
            this.travelItinerary.fileUrl = await utilities.getFileUrlNew(params)
          }

          // this.availabilities = await utilities.getSpecialistAvailabilityByItineraryId(this.itineraryId)
          // if (this.availabilities.length > 0) {
          //   this.availabilities.sort((a, b) => {
          //       return moment(a.availabilityDate).isBefore(moment(b.availabilityDate)) ? -1 : 1
          //   })
          //
          //   var dates = this.availabilities
          //     .map((x) => x.availabilityDate)
          //     .sort((a, b) => {
          //       return moment(a).isBefore(moment(b), 'd') ? -1 : 1
          //     })
          //   this.availabilityDate = dates[0]
          // }

          await this.loadAvailabilityByItinerary()
          if (this.travelItinerary.itineraryStatus == '3' || this.travelItinerary.itineraryStatus == '2') {
            this.hideButton = true
          } else {
            this.hideButton = false
          }

          this.allAvailabilities = await utilities.getAvailabiltyRequiringTravelsNoItinerary(this.availability.specialistId)
          var receipts = await utilities.getSpecialistTravelReceipts(this.travelItinerary.specialistTravelItineraryId)
          this.travelReceipts = receipts
          this.fileKey +=1
        }
        if (this.travelItinerary.specialistTravelItineraryId != '' && this.travelItinerary.itineraryStatus != '1' && moment(this.travelItinerary.dateCompleted, "YYYY-MM-DD HH:mm:ss.ssssss").isBefore(moment())) {
          this.completed = true
          // $('input').attr('disabled', true)
          // $('select').attr('disabled', true)
        } else {
          this.completed = false
        }
      },
      async loadData() {
        var bulkRefData = await utilities.getBulkRefData()
        this.airports = bulkRefData.airports
        this.originalListAirports = _.cloneDeep(utilities.getDropdownAirport(this.airports))
        this.listDepartuerAirports = _.cloneDeep(this.originalListAirports)
        this.listArrivalAirports = _.cloneDeep(this.originalListAirports)

        this.airlines = bulkRefData.airlines
        this.originalListAirlines = _.cloneDeep(utilities.getDropdownAirline(this.airlines))
        this.listAirlines = _.cloneDeep(this.originalListAirlines)

        this.accommodations = bulkRefData.accommodations
        this.originalListAccommodations =  _.cloneDeep(utilities.getDropdownAccommodation(this.accommodations))
        this.listAccommodations = _.cloneDeep(this.originalListAccommodations)

        this.carhires = bulkRefData.carHires
        this.originalListCarhires =  _.cloneDeep(utilities.getDropdownCarhires(this.carhires))
        this.listCarhires = _.cloneDeep(this.originalListCarhires)

        this.fileTypes = await utilities.getFileTypes()
        // types.map((x) => {
        //   if (x.name != 'Travel Itinerary') {
        //     this.fileTypes.push(x)
        //   }
        // })
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadData()
      // await this.loadTravel()
      this.openTab(0)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
