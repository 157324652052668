/* eslint-disable */
<template>
  <div class="specialist-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-specialist-travel', query: { 'id': this.specialistId } }">Current / Upcoming</router-link></li>
      <li><router-link :to="{ name: 'admin-specialist-travel-archive', query: { 'id': this.specialistId } }">Archive</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'AdminSpecialistTravelMenu',
  props: ["indexProps", "specialistId"],
  data() {
    return {
    
    }
  },
  methods: {
    
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
