/* eslint-disable */
<template>
  <div class="search-availability">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Relationships between Specialist Services</h1></div>
        <div class="header-sub-title">When a client books an appointment for the specific specialist type and booking type, immediately offer another commonly booked service to be booked at the same time.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="1" />
      <div class="content">
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:25%" class="long-text">After Booking...</th>
                    <th style="width:25%" class="long-text">...Then Offer</th>
                    <th style="width:35%" class="long-text">Wording</th>
                    <th style="width:15%; vertical-align:middle; text-align:right" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center">
                        <v-btn class="add-icon" dark @click.stop.prevent="openRelatedServiceModal(blankService())">Add New</v-btn>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- <div :class="specialists.length > 5 ? 'content-scroll-y' : ''"> -->
            <div>
              <table class="table">
                <tbody>
                  <tr v-for="service in relatedServices">
                    <td style="width:25%;white-space:normal;line-height:normal" class="long-text">
                      <strong>Specialist Qualification:<br /></strong>{{ service.qualificationName }}<br /><br />
                      <strong>Booking Type:<br /></strong>{{ service.bookingTypeName }}<br />
                    </td>
                    <td style="width:25%;white-space:normal;line-height:normal" class="long-text">
                      <strong>Booking Type:<br /></strong>{{ service.offerBookingTypeName }}<br /><br />
                      <strong>Specialist Qualification:<br /></strong>{{ service.offerQualificationName }}<br />
                    </td>
                    <td style="width:35%;white-space:normal;line-height:normal" class="long-text">{{ service.wording }}</td>
                    <td style="width:15%;vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center" style="height:100%">
                        <img src="@/assets/images/edit_icon.svg" @click="openRelatedServiceModal(service)"/>
                        <img src="@/assets/images/delete_icon.svg" @click="deleteRelatedService(service.qualificationServiceRelationshipId)"/>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <AdminRelatedServicesModal ref="adminRelatedServicesModal" />
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSettingsMenu from '@/components/AdminSettingsMenu'
  import Header from '@/components/Header'
  import AdminRelatedServicesModal from '@/components/AdminRelatedServicesModal'

  export default {
    name: 'AdminRelatedSkills',
    components: {
      AdminNavigation,
      AdminSettingsMenu,
      Header,
      AdminRelatedServicesModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        // relatedServices: utilities.initRelatedServices(),
        relatedServices: '',
        specialties: [],
        relatedService: utilities.initRelatedServices()[0],
        errorMessage: '',
        successMessage: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    methods: {
      async loadData() {
        this.relatedServices = await utilities.relationshipSpecialistQualifications()
      },
      blankService() {
        return utilities.initRelatedServices()[0]
      },
      openRelatedServiceModal(service) {
        this.$refs.adminRelatedServicesModal.hasError = false
        this.$refs.adminRelatedServicesModal.relatedService = _.cloneDeep(service)
        this.$refs.adminRelatedServicesModal.handleModal()
      },
      async deleteRelatedService(id) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var res = await utilities.deleteRelatedServices({id:id,userId:this.user.id,userName:this.user.name})
        if(res) {
          this.successMessage = "Relationship deleted."
          utilities.showAlert(".alert-success")
          this.loadData()
        } else {
          this.errorMessage = "An error occurred. Please try again."
          utilities.showAlert('.alert-danger')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      }
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
