/* eslint-disable */
<template>
  <div class="change-password p-0">
    <div class="red-vertical"></div>
    <div class="login-container">
      <div class="login-left">
        <div class="logo">
          <router-link :to="{ path: '/' }"><img src="@/assets/images/red_health_logo.svg"/></router-link>
        </div>
        <v-form ref="form" class="login-form">
          <v-subheader>Current Password</v-subheader>
          <v-text-field
            v-model="account.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.validatePassword.required]"
            placeholder="Enter your Password"
            @click:append="() => (showPassword = !showPassword)"
            solo
            required
          ></v-text-field>

          <v-subheader>New Password</v-subheader>
          <v-text-field
            v-model="account.newPassword"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.validateNewPassword.required, rules.validateNewPassword.valid]"
            placeholder="Enter your new Password"
            @click:append="() => (showNewPassword = !showNewPassword)"
            solo
            required
          ></v-text-field>

          <v-subheader>Re-Password</v-subheader>
          <v-text-field
            v-model="account.rePassword"
            :type="showRePassword ? 'text' : 'password'"
            :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.validateRePassword.required, rules.validateRePassword.match]"
            placeholder="Re-Enter your new Password"
            @click:append="() => (showRePassword = !showRePassword)"
            solo
            required
          ></v-text-field>
          <br /><br />

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="icon-group right-float-element">
            <v-btn class="white-button" dark @click="$router.go(-1)">Cancel</v-btn>
            <v-btn class="red-button" dark @click="update">Update</v-btn>
          </div>
        </v-form>
      </div>
      <div class="login-right">
        <div class="banner">
          <img src="@/assets/images/banner.png" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import auth from '@/assets/js/cognitoAuth'
  import utilities from '@/assets/js/utilities'
  import Footer from '@/components/Footer'

  export default {
    name: 'ChangePassword',
    components: {
      Footer,
    },
    data() {
      return {
        successMessage: 'Password successfully changed.',
        errorMessage: 'Incorrect password.',

        showPassword: false,
        showNewPassword: false,
        showRePassword: false,

        account: {
          userName: '',
          password: '',
          newPassword: '',
          rePassword: '',
        },

        rules: {
          validatePassword: {
            required: (value) => !!value || 'Password is required.',
          },
          validateNewPassword: {
            required: (value) => !!value || 'New password is required.',
            valid: (value) => {
              if(value === this.account.password) {
                return 'New password is same as current password.'
              }
              var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$_!#%*?&])[A-Za-z\d@$!#_%*?&]{8,}$/
              if(!re.test(value)) return 'Your password must be at least 8 characters including a lowercase letter, an uppercase letter, a number, and a special character (@$!#_%*?&)'
              return true
            }
          },
          validateRePassword: {
            required: (value) => !!value || 'Re-password is required.',
            match: (value) => !(value != this.account.newPassword) || 'Re-password is incorrect.',
          },
        },
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('loading/setLoadComponent', true)
        utilities.clearStore()
        var callback = () => {
          this.$store.dispatch("user/setAuthentication", utilities.initAuthentication())
          this.$store.dispatch('user/removeUser')
          setTimeout(() => {
            this.$router.push({ name: "login" }).catch(err => {})
            this.$store.dispatch('loading/setLoadComponent', false)
          }, 1000)
        }
        auth.logout(callback)
      },
      async update() {

        if (!this.$refs.form.validate()) {
          return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        const pwdSHA = await utilities.logSha1(this.account.newPassword)
        const res = await utilities.passwordWeakCheck(pwdSHA.substring(0, 5))
        const formatRes = res.data.split('\r\n')
        const item = formatRes.find(item => item.indexOf(pwdSHA.substring(5)) > -1)
        if(item) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-warning">' +
                '            <b>Warning:</b> Your new password has been seen ' + item.split(':')[1] + ' times before, please change it' +
                '          </div>',
          });
          this.$store.dispatch('loading/setLoadComponent', false)
          return
        }
        var authenticationData = {
          Username: this.account.userName,
          Password: this.account.password,
          NewPassword: this.account.newPassword,
        }
        try {
          auth.changePassword(authenticationData).then(() => {
            this.$store.dispatch('loading/setLoadComponent', false)
            utilities.showAlert('.alert-success')
            setTimeout(() => {
              this.logout()
            }, 500)
          }).catch(e => {
            this.$store.dispatch('loading/setLoadComponent', false)
            utilities.showErrorMessage(e.message)
          })
        } catch (e) {
          this.$store.dispatch('loading/setLoadComponent', false)
          utilities.showErrorMessage(e.message)
        }
      },
    },
    async beforeMount() {
      var result = ''
      var blob = await utilities.getBannerImage()
      await new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onloadend = function() {
          result = reader.result
          resolve(true)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(blob)
      })
      $('.banner img').attr('src', result)
    },
    mounted() {
      $('.change-password form').on('keypress', (e) => {
        if (e.keyCode == 13) {
          this.update()
        }
      })
    },
  }
</script>
