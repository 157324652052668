/* eslint-disable */
<template>
  <div class="updated-cases">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Recently Updated Cases</h1></div>
        <div class="header-sub-title">Click to view cases that have been recently updated.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu v-if="user.type >= 3" indexProps="9"/>
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Reference Number" v-model="listParam.ReferenceNumber"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactName"/>
              <el-select @change="selectPage(1)" style="max-width: 200px" placeholder="Claim Type"  class="mr-1" v-model="listParam.ClaimTypeID" filterable  clearable>
                <el-option v-for="item in types" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
              </el-select>
              <el-date-picker
                  v-model="dateValue"
                  type="daterange"
                  start-placeholder="Start Date"
                  popper-class="date-range-picker-popper"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  @change="changeDates"
                  end-placeholder="End Date"
                  clearable
              />
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 15%" class="long-text">Reference Number<img v-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)" />
                      <img v-else-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(1)"  /></th>
                    <th style="width: 20%" class="long-text">Client<img v-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(4)" />
                      <img v-else-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(3)"   /></th>
                    <th style="width: 15%" class="long-text">Contact<img v-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)" />
                      <img v-else-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(6)"   /></th>
                    <th style="width: 15%" class="long-text">Claimant<img v-if="listParam.SortFlag === 7" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(8)" />
                      <img v-else-if="listParam.SortFlag === 8" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(7)"   /></th>
                    <th style="width: 15%" class="long-text">Type of Claim<img v-if="listParam.SortFlag === 9" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(10)" />
                      <img v-else-if="listParam.SortFlag === 10" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(9)"   /></th>
                    <th style="width: 10%" class="long-text">Date Modified<img v-if="listParam.SortFlag === 11" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(12)" />
                      <img v-else-if="listParam.SortFlag === 12" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(11)"   /></th>
                    <th style="width: 10%" class="long-text">Modified By<img v-if="listParam.SortFlag === 13" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(14)" />
                      <img v-else-if="listParam.SortFlag === 14" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(13)"   /></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="cases.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(singleCase, index) in cases" :key="index" class="is-icon">
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: singleCase.id } }">{{ singleCase.caseReference }}</router-link>
                    </td>
                    <td style="width: 20%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: singleCase.clientId } }">{{ singleCase.clientName }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: singleCase.clientId, type: 'edit-contact' } }">{{ singleCase.caseOwnerName }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: singleCase.id } }">{{ singleCase.claimantFullName }}</router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: singleCase.id } }">{{ singleCase.claimTypeName }}</router-link>
                    </td>
                    <td style="width: 10%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: singleCase.id } }">{{ formatDate(singleCase.lastUpdatedDate) }}</router-link>
                    </td>
                    <td style="width: 10%" class="long-text">
                      <router-link :to="{ name: 'admin-manage-case', query: { id: singleCase.id } }">{{ singleCase.lastUpdatedByName }}</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AdminBookingsMenu from "@/components/AdminBookingsMenu"
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import { debounce } from 'lodash';
  export default {
    name: 'AdminUpdatedCases',
    props: ["searchOptionProps"],
    components: {
      AdminNavigation,
      Header,
      AdminBookingsMenu
    },
    data() {
      return {
        types: [],
        dateValue: "",
        cases: [],
        allCases: [],
        limit: 10,
        pagination: utilities.initPagination(),
        totalCount: 0,
        user: _.cloneDeep(this.$store.getters["user/getUser"]),
        listParam: {
          PageSize: 10,
          PageNum: 1,
          SortFlag: 0,
          ReferenceNumber: null,
          ClaimTypeID: null,
          ClientName: '',
          ClaimantName: '',
          ContactName: '',
          FromDate: null,
          ToDate: null,
        },
      }
    },
    computed: {},
    methods: {
      search: debounce(function () {
        this.selectPage(1)
      }, 800),

      sortParam(v) {
        this.listParam.SortFlag = v
        this.selectPage(1)
      },
      changeDates(v) {
        if(v) {
          this.listParam.FromDate = v[0]
          this.listParam.ToDate = v[1]
        } else {
          this.listParam.FromDate = null
          this.listParam.ToDate = null
        }
        this.selectPage(1)
      },
      selectPage(index) {
        this.listParam.PageNum = index
        this.loadUpdatedCases()
      },
      formatDate(dateToFormat) {
        return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
      },

      async loadUpdatedCases() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var {data, total} = await utilities.getUpdatedCases(this.listParam)
        this.cases = _.cloneDeep(data)
        this.totalCount = total
        if(data.length == 0) {
          this.noCaseMessage = "No cases found."
        } else {
          this.noCaseMessage = ""
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      await this.loadUpdatedCases()
      this.types = await utilities.getClaimTypes()
    },
  }
</script>
