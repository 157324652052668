/* eslint-disable */
<template>
  <div class="reports-menu sub-menu">
    <ul>
      <li><router-link to="/admin-hours-booked">Hours Booked</router-link></li>
      <li><router-link to="/admin-hours-available">Hours Available</router-link></li>
      <li><router-link to="/admin-hours-booked-by-client">Hours Booked By Client</router-link></li>
      <li><router-link to="/admin-hours-booked-by-client-per-month">Hours Booked (Client/Month)</router-link></li>
      <li><router-link to="/admin-hours-booked-by-specialist">Hours Booked By Specialist</router-link></li>
      <li><router-link to="/admin-hours-booked-by-location">Hours Booked By Location</router-link></li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'AdminReportsMenu',
    props: ['indexProps'],
    data() {
      return {}
    },
    methods: {},
    beforeMount() {},
    mounted() {
      if (this.indexProps !== undefined)
        $('.sub-menu li')
          .eq(this.indexProps)
          .addClass('sub-menu-selected')
    },
  }
</script>
