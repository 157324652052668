/* eslint-disable */
<template>
  <div class="search-availability">
    <AdminNavigation ref="navigation" indexProps="8" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Relationships between Specialties / Qualifications</h1></div>
        <div class="header-sub-title">When searching for a speciality and no availability is found, offer a related speciality with availability who might be able to provide the assessment.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="0" />
      <div class="content">
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:42.5%" class="long-text">Speciality</th>
                    <th style="width:42.5%" class="long-text">Alternate</th>
                    <th style="width:15%; vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center"><v-btn class="add-icon" dark @click="addNewSpecialty">Add New</v-btn></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- <div :class="specialists.length > 5 ? 'content-scroll-y' : ''"> -->
            <div>
              <table class="table">
                <tbody>
                  <template v-for="(relatedSkill, indexOuter) in relatedSkills">
                    <tr :key="indexOuter">
                    <td style="width:42.5%" class="long-text">
                      <select v-model="relatedSkill.qualificationId" class="w-100" :class="hasError && relatedSkill.hasError ? 'is-invalid' : ''">
                        <option value="" hidden>Select Specialty</option>
                        <option v-for="(qualification, index) in qualifications" :key="index" :value="qualification.id">{{ qualification.name }}</option>
                      </select>
                    </td>
                    <td style="width:42.5%" class="long-text">
                      <select v-model="relatedSkill.offerQualificationId" class="w-100" :class="hasError && relatedSkill.hasError ? 'is-invalid' : ''">
                        <option value="" hidden>Select Alternate</option>
                        <option v-for="(qualification, index) in qualifications" :key="index+250" :value="qualification.id">{{ qualification.name }}</option>
                      </select>
                    </td>
                    <td style="width:15%;vertical-align:middle" class="icon-col">
                      <div class="icon-group justify-content-center align-items-center" style="height:100%">
                        <img src="@/assets/images/delete_icon.svg" v-if="relatedSkill.qualificationAlternateId != ''" @click="deleteRelationship(relatedSkill.qualificationAlternateId)" />
                      </div>
                    </td>
                    </tr>
                    <tr v-if="hasError && relatedSkill.hasError" class="text-danger" :key="indexOuter+500"><td colspan="3">This relationship already exists. Please ensure there are no duplicates and try again.</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <v-btn class="right-element" dark @click="submit">Update</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminAvailabilityMenu from '@/components/AdminAvailabilityMenu'
  import Header from '@/components/Header'
  import AdminSettingsMenu from '@/components/AdminSettingsMenu'

  export default {
    name: 'AdminRelatedSkills',
    components: {
      AdminNavigation,
      AdminAvailabilityMenu,
      Header,
      AdminSettingsMenu,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        relatedSkills: utilities.initRelatedSkills(),
        qualifications: [],
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        errorMessage: '',
        successMessage: '',
      }
    },
    methods: {
      addNewSpecialty() {
        this.relatedSkills = [utilities.initRelatedSkills()[0], ...this.relatedSkills]
      },
      deleteRelationship(specialty, alternate) {
        var relation = this.relatedSkills.find((x) => x.specialty == specialty && x.alternate == alternate)
        if (relation.local) this.relatedSkills = this.relatedSkills.filter((x) => !(x.specialty == specialty && x.alternate == alternate))
        else {
          // Delete API
        }
      },

      async loadData() {
        this.relatedSkills = await utilities.getAlternateQualifications()
        this.qualifications = await utilities.getQualifications()
        this.relatedSkills.map((x, i) => {
          this.relatedSkills[i].hasError = false
        })
      },

      async submit() {
        this.hasError = false
        this.$store.dispatch('loading/setLoadComponent', true)
        var all = []
        for (var i = 0; i < this.relatedSkills.length; i++) {
          var duplicateFlag = false
          for (var j = 0; j < this.relatedSkills.length; j++) {
            if (this.relatedSkills[i].offerQualificationId == this.relatedSkills[j].offerQualificationId && this.relatedSkills[i].qualificationId == this.relatedSkills[j].qualificationId && i != j) {
              duplicateFlag = true
            }
          }
          if (duplicateFlag) {
            this.relatedSkills[i].hasError = true
          } else {
            this.relatedSkills[i].hasError = false
          }
        }
        if (this.relatedSkills.find((x) => x.hasError) != undefined) {
          this.hasError = true
          this.$store.dispatch('loading/setLoadComponent', false)
          return
        }
        this.relatedSkills.map(async (x) => {
          var params = {
            QualificationID: x.qualificationId,
            OfferQualificationID: x.offerQualificationId,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var callback = (response) => {}
          if (x.qualificationAlternateId == '') {
            // post
            all.push(utilities.postAlternateQualification(params, callback))
          } else {
            //put
            params.QualificationAlternateID = x.qualificationAlternateId
            all.push(utilities.putAlternateQualification(params, callback))
          }
        })
        var flag = 0
        Promise.all(all)
          .then((response) => {
            response.map((x) => {
              if (x == true) flag += 1
            })
          })
          .then(async () => {
            // var factorial = 1
            // for (var i = 2; i <= all.length; i++) factorial = factorial + i
            if (flag == all.length) {
              this.successMessage = 'Related qualifications updated.'
              utilities.showAlert('.alert-success')
            }
            this.relatedSkills = await utilities.getAlternateQualifications()
          })
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async deleteRelationship(id) {
        var res = await utilities.deleteAlternateQualification(id, this.user.id, this.user.name)
        this.relatedSkills = this.relatedSkills.filter((x) => x.qualificationAlternateId != id)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
