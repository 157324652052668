/* eslint-disable */
<template>
  <div class="navigation client-navigation">
    <div class="navigation-content">
      <div class="navigation-section-1">
        <div class="navigation-icon">
          <router-link to="/"><img src="@/assets/images/home_icon.svg" /></router-link>
        </div>
        <div class="navigation-icon navigation-grey navigation-booking dropdown">
          <router-link to="/search-specialist"><img src="@/assets/images/add_large_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/search-specialist">Add Booking/Case</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-booking dropdown">
          <router-link to="/all-bookings"><img src="@/assets/images/calendar_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/all-bookings">Bookings</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-case dropdown">
          <router-link to="/cases"><img src="@/assets/images/case_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/cases">Cases</router-link></div>
          </div>
        </div>
        <div v-if="user.type >= 3" class="navigation-icon navigation-grey navigation-specialist dropdown">
          <router-link to="/search-specialist"><img src="@/assets/images/specialist_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/search-specialist">Specialists</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-red navigation-add dropdown">
          <img src="@/assets/images/search_icon.svg" class="navigation-add-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="control-from-home dropdown-menu" aria-labelledby="navigation-add-icon">
            <router-link class="dropdown-item" to="/cases">Search Cases</router-link>
            <router-link class="dropdown-item" to="/search-specialist">Specialist Availability</router-link>
          </div>
        </div>
      </div>
      <div class="navigation-section-2">
        <div class="navigation-icon-no-place navigation-grey navigation-booking dropdown">
          <router-link to="/user-guides?index=0"><img style="height: 58px" src="@/assets/images/question.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/user-guides?index=0">"How To" guides</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-setting dropdown" v-if="user.type == 2">
          <router-link to="/setting"><img src="@/assets/images/setting_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/setting">Settings</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-grey navigation-user dropdown" v-if="user.type == 2">
          <router-link to="/users"><img src="@/assets/images/user_icon.svg" /></router-link>
          <div class="dropdown-menu">
            <div class="dropdown-item"><router-link class="router-link" to="/users">Users</router-link></div>
          </div>
        </div>
        <div class="navigation-icon navigation-red navigation-account dropdown">
          <img src="@/assets/images/account_icon.svg" class="navigation-account-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
          <div class="dropdown-menu" aria-labelledby="navigation-account-icon">
            <router-link :to="{ name: 'change-password', params: {} }" class="dropdown-item">Change Password</router-link>
            <router-link :to="{ name: 'edit-profile', params: {id: 3679} }" class="dropdown-item">Edit Profile</router-link>
            <div class="dropdown-item" @click="logout">Logout</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/assets/js/cognitoAuth'
import utilities from '@/assets/js/utilities'
import store from "@/store";

export default {
  name: 'Navigation',
  props: ["indexProps"],
  data() {
    return {
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('loading/setLoadComponent', true)
      utilities.clearStore()
      var callback = () => {
        this.$store.dispatch("user/setAuthentication", utilities.initAuthentication())
        this.$store.dispatch('user/removeUser')
        setTimeout(() => {
          this.$router.push({ name: "login" }).catch(err => {})
          this.$store.dispatch('loading/setLoadComponent', false)
        }, 1000)
      }
      auth.logout(callback)
    },
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    console.log(this.indexProps)
    if(this.indexProps === 'guides') {
      $(".navigation-icon-no-place").addClass("navigation-selected")
    } else {
      $(".navigation-icon").eq(this.indexProps).addClass("navigation-selected")
    }

    // Hover to show grey popup
    $(".navigation-grey img").hover(function() {
      $(".navigation-content").css("width", "285px")
    })
    // Hover outside to hide grey popup
    $(".navigation-grey .dropdown-menu").hover(function() {

    }, function() {
      $(".navigation-content").css("width", "58px")
    })
    // Click to open red popup
    $(".navigation-red img").click(function() {
      $(".navigation-content").css("width", "285px")
    })
    // Click outside red popup
    $(document).on("click", function(event){
      if(!$(event.target).closest(".navigation-red .dropdown-menu").length) {
        $(".navigation-content").css("width", "58px")
      }
    })
  },
}
</script>
