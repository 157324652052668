/* eslint-disable */
<template>
  <div class="cases">
    <AdminNavigation ref="navigation" indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Cases</h1></div>
        <div class="header-sub-title">Click to view the case, or add a booking.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div class="tool-bar row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn dark @click="openSearchCase(true)">Modify Search</v-btn>
                </div>
              </div>
            </div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 15%" class="long-text">
                      Client & Branch
                    </th>
                    <th style="width: 15%" class="long-text">
                      Reference Number
                    </th>
                    <th style="width: 20%" class="long-text">
                      Claimant Name
                      <img class="sort-icon" :src="!byName ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortName" />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Type of Claim
                      <img class="sort-icon" :src="!byType ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortType" />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Next Booking Date
                      <img class="sort-icon" :src="!byDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDate" />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Case Owner
                      <img class="sort-icon" :src="!byOwner ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortOwner" />
                    </th>
                    <th style="width:5%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="allCases.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                  <tr v-for="(c, index) in cases" class="is-icon">
                    <td style="width: 15%" class="long-text" @click="openCase(c.id)">
                      {{ c.clientName }} {{ c.branchName }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openCase(c.id)">
                      {{ c.caseReference }}
                    </td>
                    <td style="width: 20%" class="long-text" @click="openCase(c.id)">
                      {{ c.claimantFullName }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openCase(c.id)">
                      {{ c.claimTypeName }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openCase(c.id)">
                      {{ formatDate(c.nextBookingDate) }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openCase(c.id)">
                      {{ c.caseOwnerName }}
                    </td>
                    <td style="width: 5%" class="icon-col">
                      <div class="icon-group">
                        <router-link :to="{ name: 'admin-bookings', query: {caseId: c.id} }" target="_blank">
                          <img data-toggle="tooltip" data-placement="right" title="View Bookings" src="@/assets/images/calendar_dark_grey_icon.svg" />
                        </router-link>
                        <router-link :to="{ name: 'admin-search-specialist', query: { id: c.id } }" target="_blank">
                          <img data-toggle="tooltip" data-placement="right" title="Add Booking" src="@/assets/images/add_booking_dark_icon.svg" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">{{ noCaseMessage }}</div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import AdminSearchCaseModal from '@/components/AdminSearchCaseModal'
  export default {
    name: 'AdminCasesResult',
    props: ['searchOptionProps'],
    components: {
      AdminNavigation,
      Header,
    },
    data() {
      return {
        fetchedData: false,
        hasError: false,
        successMessage: '',
        errorMessage: '',
        noCaseMessage: 'No cases found.',

        client: utilities.initClient(),
        cases: [],
        allCases: [],
        limit: 10,
        pagination: utilities.initPagination(),
        searchOption: null,
        user: _.cloneDeep(this.$store.getters['user/getUser']),

        byName: false,
        byType: false,
        byDate: false,
        byOwner: false,
      }
    },
    computed: {},
    methods: {
      selectPage(index) {
        this.pagination.current = index
        this.cases = this.pagination.list[this.pagination.current]
      },
      formatDate(dateToFormat) {
        return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
      },
      openSearchCase(modifySearch = false) {
        if(this.searchOption != undefined && modifySearch) this.$refs.navigation.$refs.globalSearch.openSearchCase(this.searchOption)
        else this.$refs.navigation.$refs.globalSearch.openSearchCase()
      },
      sortName() {
        this.allCases.sort((a, b) => {
          if (this.byName) {
            // ASC
            return a.claimantFullName > b.claimantFullName ? 1 : -1
          } else {
            // DESC
            return a.claimantFullName < b.claimantFullName ? 1 : -1
          }
        })
        this.paging(this.allCases)
        this.byName = !this.byName
      },
      sortType() {
        this.allCases.sort((a, b) => {
          if (this.byType) {
            // ASC
            return a.claimTypeName > b.claimTypeName ? 1 : -1
          } else {
            // DESC
            return a.claimTypeName < b.claimTypeName ? 1 : -1
          }
        })
        this.paging(this.allCases)
        this.byType = !this.byType
      },
      sortDate() {
        this.allCases.sort((a, b) => {
          if (this.byDate) {
            // ASC
            return moment(a.nextBookingDate) > moment(b.nextBookingDate) ? 1 : -1
          } else {
            // DESC
            return moment(a.nextBookingDate) < moment(a.nextBookingDate) ? -1 : 1
          }
        })
        this.paging(this.allCases)
        this.byDate = !this.byDate
      },
      sortOwner() {
        this.allCases.sort((a, b) => {
          if (this.byOwner) {
            // ASC
            return a.caseOwnerName > b.caseOwnerName ? 1 : -1
          } else {
            // DESC
            return a.caseOwnerName < b.caseOwnerName ? 1 : -1
          }
        })
        this.paging(this.allCases)
        this.byOwner = !this.byOwner
      },
      openCase(id) {
        let routeData = this.$router.resolve({name: 'admin-manage-case', query: {id: id}})
        window.open(routeData.href, '_blank')
      },
      paging(cases) {
        utilities.disposeTooltip()
        if (cases.length == 0 || cases.length <= this.limit) {
          this.cases = cases
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(cases, this.limit)
          this.cases = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
        utilities.initTooltip()
      },

      async searchCase(searchOption) {
        this.searchOption = _.cloneDeep(searchOption)
        var params = {
          ClientID: searchOption.clientId,
          CaseRef: searchOption.referenceNumber,
          ClaimantFirstName: searchOption.claimantFirstName.trim(),
          ClaimantSurname: searchOption.claimantSurname.trim(),
          ContactUserID: searchOption.contactUserId,
        }
        this.cases = []
        var cases = await utilities.getSearchCases(params)
        this.allCases = _.cloneDeep(cases)
        this.paging(cases)
        if (cases.length == 0) {
          this.noCaseMessage = 'No cases found.'
        } else {
          this.noCaseMessage = ''
        }
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      if (this.searchOptionProps != undefined) {
        this.searchOption = _.cloneDeep(this.searchOptionProps)
        await this.searchCase(this.searchOptionProps)
      } else {
        setTimeout(() => {
          if(this.$refs.navigation) this.$refs.navigation.$refs.globalSearch.openSearchCase()
        }, 1000)
      }
      this.fetchedData = true
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $('.pagination').hide()
    },
  }
</script>
