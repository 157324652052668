 /* eslint-disable */
<template>
  <div class="supplementary-service-request">
     <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="5" />
      <Navigation v-else indexProps="4" />
      <ProfileReadMoreModal ref="profileReadMoreModal" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Supplementary Service Request</h1></div>
        <div class="header-sub-title">We look forward to helping you arrange the service you require for your claimant. Please tell us about your requirements, so that we can find the right specialist for your needs.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu v-if="user.type >= 3" indexProps="0" />
      <ClientBookingMenu v-else indexProps="3" />
      <div class="content" v-if="showContent">
        <div class="top-detail">
          <div class="box box-body">
            <div class="box-heading">
              <div><b>Request Details for Claimant:</b>  {{booking.claimantFullName}}  <b class="pl-3">Date of Birth:</b>  {{formatDate(booking.dateOfBirth, 'DD/MM/YYYY')}}   <b class="pl-3">Booking ID:</b>  {{booking.id}} </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Booking Type</div>
              <div class="box-col box-col-right">
                <div v-if="!clickBookingType" @click="handleClickBookingType" class="parent">
                  <input class="child" type="text" disabled placeholder="Click to choose a booking type"  @click="handleClickBookingType"/>
                </div>
                <select v-else @change="onChangeBookingType($event)" class="textbox" :class="hasError && !validateBookingType ? 'is-invalid' : ''" v-model="supplementaryService.bookingTypeName">
                  <option hidden value="0">Please choose</option>
                  <option v-for="(bookingType, index) in bookingTypes" :value="bookingType.id">{{ bookingType.name }}</option>
                </select>
                <div v-if="hasError && !validateBookingType" class="text-danger">Booking Type is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Type of Claim</div>
              <div class="box-col box-col-right">
                <select class="textbox" v-model="supplementaryService.claimTypeId">
                  <option value="" hidden>Please choose type of claim...</option>
                  <option v-for="claimType in claimTypes" :value="claimType.id">{{ claimType.name }}</option>
                </select>
              </div>
            </div>
            <div class="box-row" v-if="showDateRange">
              <div class="box-col box-col-left">Date Range</div>
              <div class="box-col box-col-right">
                <v-md-date-range-picker :start-date="supplementaryService.startDate" :end-date="supplementaryService.endDate" class="w-50" @change="changeDateRange"></v-md-date-range-picker>
                <div v-if="invalidDate" class="text-danger">End Date cannot be before Today</div>
              </div>
            </div>
          </div>

        </br>
        <div class="box">
          <div class="box-heading tab">
            <div class="tab-heading px-5  notes-heading tab-selected" @click="openNotes">Notes</div>
            <div class="tab-heading px-5 files-heading" @click="openFiles">Files</div>
            <div class="col-space-between is-icon right-float-element">
              <v-btn v-if="showAddNote" class="add-icon mr-3" dark @click="openAddNoteModal">Add New</v-btn>
              <v-btn v-else class="add-icon mr-3" dark @click="openFilesBox">Add New</v-btn>
              <AddNoteModal ref="addNoteModal" fromProps="supplementaryService"/>
            </div>
          </div>
          <div class="box-body notes-content hidden-element">
            <div class="box-row">
              <div class="box-col box-col-left">{{ supplementaryService.note != '' ? user.name : '' }}</div>
              <div class="box-col box-col-left">{{ supplementaryService.note != '' ? getDate() : '' }}</div>
              <div @click="editNote()" style="max-width: 60%" class="box-col box-col-right box-col-long col-center-content">
                {{ supplementaryService.note }}
              </div>
            </div>
          </div>

          <div class="box-body files-content hidden-element">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width:20%" class="long-text">
                        File Type
                      </th>
                      <th style="width:40%" class="long-text">
                        File Name
                      </th>
                      <th style="width:20%" class="long-text">
                        Uploaded By
                      </th>
                      <th style="width:12%" class="long-text">
                        Status
                      </th>
                      <th style="width:4%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="content-scroll-y" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                    <tbody>
                      <tr v-for="(file, index) in requestFiles" :key="index" class="py-0">
                        <td style="width:20%" class="long-text">
                          <select v-model="file.FileTypeID">
                            <option value="" hidden>Select File Type</option>
                            <option v-for="fileType in fileTypes" :value="fileType.id">{{ fileType.name }}</option>
                          </select>
                        </td>
                        <td style="width:40%" class="long-text">{{file.FileName}}</td>
                        <td style="width:20%" class="long-text">{{file.UploadedByName}}</td>
                        <td style="width:12%" class="long-text">
                          <span style="padding-left: 10px;">Draft</span>
                        </td>
                        <td style="width:4%" @click="deleteFile(index)"><img src="@/assets/images/delete_icon.svg" /></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV" />
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
              </label>
            </div>
          </div>
        </div>
        <div class="box-row item-right">
          <div><v-btn class="submit-request" @click="tapSave()" dark>Submit</v-btn></div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div class="alert alert-warning hidden-element">
          <a class="close" title="close">×</a>
          <b>Warning:</b> {{ errorMessage }}
        </div>
        </div>
      </div>
    </div>
    <Chat/>
  </div>
</template>
<script>

import utilities from '@/assets/js/utilities'
import AdminNavigation from "@/components/AdminNavigation";
import AdminBookingsMenu from "@/components/AdminBookingsMenu";
import Navigation from '@/components/Navigation'
import ClientBookingMenu from '@/components/ClientBookingMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import ProfileReadMoreModal from '@/components/ProfileReadMoreModal'
import AddNoteModal from '@/components/AddNoteModal'

export default {
  name: 'SupplementaryServiceRequest',
  components: {
    Navigation,
    ClientBookingMenu,
    Header,
    Chat,
    AdminNavigation,
    AdminBookingsMenu,
    ProfileReadMoreModal,
    AddNoteModal
  },
  data() {
    return {
      hasError: false,
      successMessage: '',
      errorMessage: '',
      specialist: {},
      supplementaryService: {
        id: '',
        bookingTypeId: '',
        bookingTypeName: '',
        claimTypeId: '',
        note: '',
        startDate:  moment().format('YYYY-MM-DD'),
        endDate:  moment().format('YYYY-MM-DD'),
        bookingFiles: []
      },
      clickBookingType: false,
      clickClaimType: false,
      showContent: true,
      invalidDate: false,
      showAddNote: true,
      showDateRange: false,
      bookingTypes: [],
      claimTypes: [],
      notes: [],
      filelist: [],
      latestFileLists: [],
      requestFiles: [],
      booking: [],
      fileTypes: [],
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  computed: {
    validateBookingType() {
      if (this.supplementaryService.bookingTypeName == '') {
        return false
      }
      return true
    },
  },

  methods: {
    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date != '') {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ''
    },

    getDate() {
      return moment().format('DD/MM/YYYY HH:mm')
    },

    onChangeBookingType(event) {
      this.booking.bookingTypeId = event.target.value
      this.booking.bookingTypeName = this.bookingTypes.find((x) => x.id == this.booking.bookingTypeId).name
      if (this.booking.bookingTypeId == 5) {
        this.showDateRange = true
      } else {
        this.showDateRange = false
      }
    },

    handleClickBookingType() {
      this.clickBookingType = true
    },

    handleClickClaimType() {
      this.clickClaimType = true
    },

    changeDateRange(item) {
      var startDate = utilities.formatDate(item[0])
      var endDate = utilities.formatDate(item[1])
      var todayDate = moment().format('YYYY-MM-DD')
      this.invalidDate = false
      if (moment(endDate).isBefore(todayDate)) {
        this.invalidDate = true
      } else {
        this.supplementaryService.startDate = startDate
        this.supplementaryService.endDate = endDate
      }
    },

    openNotes() {
      this.showAddNote = true
      $('.notes-content').show()
      $('.tab-selected').removeClass('tab-selected')
      $('.notes-heading').addClass('tab-selected')
      $('.files-content').hide()
    },

    openFiles() {
      this.showAddNote = false
      $('.notes-content').hide()
      $('.tab-selected').removeClass('tab-selected')
      $('.files-heading').addClass('tab-selected')
      $('.files-content').show()
    },

    addNoteToBooking(note) {
      this.supplementaryService.note = note
      this.openNotes()
    },

    editNote() {
      this.$refs.addNoteModal.editCurrentNote(this.supplementaryService.note, true)
      this.$refs.addNoteModal.handleModal()
    },

    openAddNoteModal(e) {
      this.$refs.addNoteModal.addNoteToBooking()
      this.$refs.addNoteModal.isNewNote = false
      this.$refs.addNoteModal.handleModal()
    },


    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },


    dragover(event) {
      event.preventDefault()
      event.currentTarget.classList.add('dark-border-hover')
    },

    dragleave(event) {
      event.currentTarget.classList.remove('dark-border-hover')
    },

    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()

      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },

    async onChange() {
      this.filelist = [...this.$refs.file.files]
      var i = 0;
      if(this.filelist.length > 0) {
        this.filelist.map((x) => {
          if (x.index == undefined) {
            x.index = i
          }
            var params = {
              FileName: x.name.replace(/\s/g, '_'),
              FileTypeID: this.fileTypes.find((x) => x.name == 'Letter of Instruction').id,
              UploadedBy: this.user.id,
              UploadedByName: this.user.name,
              MimeType: x.type,
              FileStatus: 0,
              index:i,
              DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
            }
            this.requestFiles.push(params)
            this.latestFileLists.push(x)
            i = i + 1
        })
      };
    },

    async deleteFile(index) {
      this.$confirm({
        content: 'Are you sure you want to delete this file?',
        onConfirm: async () => {
          this.requestFiles.splice(index, 1)
          this.latestFileLists.splice(index, 1)
        }
      })
    },

    async tapSave() {
      if (!this.validateBookingType) {
        this.hasError = true
        return
      }

      this.$store.dispatch('loading/setLoadComponent', true)
      var result = await this.postSupplementaryRequest()
      //upload file into the booking
      if(result) {
        if(this.latestFileLists.length > 0) {
          let item = []
          await Promise.all(this.latestFileLists.map(async (x) => {
            if(this.supplementaryService.id != '') {
              var fileName = moment().unix() + '_' + x.name.replace(/\s/g, '_')
              var fileParams = {
                fn: fileName,
                ft: x.type,
                id: this.supplementaryService.id,
              }
              var result = await utilities.postRequestPreSign(fileParams)
              var postData = new FormData();

              for (var key in result.fields) {
                postData.append(key, result.fields[key]);
              }
              postData.append('file', x)
              const r = await utilities.postImage(result.url, postData)
              if(r) {
                item.push({
                  BookingRequestID: this.supplementaryService.id,
                  DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
                  FileName: fileName,
                  MimeType: x.type,
                  FileTypeID: 5,
                  UploadedBy: this.user.id,
                  UploadedByName: this.user.name
                })
              }
            }
          }))
          if(item.length > 0) {
            await utilities.postRequestFileRecord(item)
          }
        }
        this.successMessage = 'Thank you; we will be in touch shortly.'
        utilities.showAlert('.alert-success')
        this.supplementaryService.bookingTypeName = ''
        this.supplementaryService.id = ''
        this.supplementaryService.note = ''
        this.showDateRange = false
        this.openNotes()
        this.filelist = []
        this.requestFiles = []
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async postSupplementaryRequest() {
      var params = {
        CaseID: this.booking.caseId,
        ClientID: this.booking.clientId,
        SpecialistID: this.booking.specialistId,
        BookingTypeID: this.booking.bookingTypeId,
        BookingTypeName: this.booking.bookingTypeName,
        ClaimTypeID: this.supplementaryService.claimTypeId,
        ClaimTypeName: this.claimTypes.find((x) => x.id == this.supplementaryService.claimTypeId).name,
        VideoLinkAccepted: utilities.convertBoolToNum(this.booking.videoLink),
        BookingRequestStatus: 1,
        ClaimantFirstName: this.booking.claimantFirstName,
        ClaimantGivenNames: this.booking.claimantGivenNames,
        ClaimantSurname: this.booking.claimantSurname,
        DateofBirth: moment(this.booking.dateOfBirth).format('YYYY-MM-DD'),
        Notes: this.supplementaryService.note,
        UserID: this.user.id,
        Username: this.user.name,
        DateSubmitted: moment().format('YYYY-MM-DD HH:mm:ss'),
        SubmittedBy: this.user.id,
        OriginalBookingID: this.booking.id,
        DateofBirth: this.booking.dateOfBirth,
      }

      if (this.showDateRange) {
        params.DateFrom = moment(this.supplementaryService.startDate).format('YYYY-MM-DD')
        params.DateTo = moment(this.supplementaryService.endDate).format('YYYY-MM-DD')
      }

      var callback = (response) => {
        if (this.booking.id) {
          this.addNoteToTheBooking()
        }
        this.supplementaryService.id = response
      }
      return utilities.postBookingRequest(params, callback)
    },

    async addNoteToTheBooking() {
      var note = "Supplementary booking request for Booking ID: " + this.booking.id + "; Booking Type: " + this.booking.bookingTypeName + "; Claim Type: " + this.booking.claimTypeName
      if (this.showDateRange) {
        note = note +  " Court Evidence Matter Date Range: " + moment(this.supplementaryService.startDate).format('YYYY-MM-DD') + " to " + moment(this.supplementaryService.endDate).format('YYYY-MM-DD')
      }

      if (this.supplementaryService.note.length > 0) {
        note = note + ": " + this.supplementaryService.note
      }

      var params = {
        Note: note,
        NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        InternalOnly: this.internalOnly,
        IsMainEdit: 0,

      }
      if (params.IsMainEdit == 0) {
        params.BookingDateTime = moment(this.booking.bookingDateTime).format('YYYY-MM-DD HH:mm:ss'),
        params.SpecialistName = this.booking.specialistFullName
        params.CaseOwnerName = this.booking.clientContacts[0].clientContactFullName
        params.ClientName = this.booking.clientName + ' - ' + this.booking.branchName
        params.BookingTypeName = this.booking.bookingTypeName
        params.LocationName = this.booking.locationName
        params.IsAdmin = this.user.id >= 3 ? true : false
      }
      var callback = (response) => {
      }
      return utilities.postBookingNote(params, callback)
    },

    async loadData() {
      var bookingId = this.$route.query.id != undefined ? this.$route.query.id : ''
      this.booking = await utilities.getBookingById(bookingId)
      if (this.booking.id == '') {
        return
      }

      const apiArr = [utilities.getSpecialistById(this.booking.specialistId), utilities.getBookingTypes(), utilities.getClaimTypes(), utilities.getFileTypes()]
      const r = await Promise.all(apiArr)
      this.specialist = r[0]

      this.supplementaryService.claimTypeId = this.booking.claimTypeId

      var bookingTypes = r[1]
      bookingTypes.map((x) => {
        if (x.name == 'Phone Call' && this.specialist.servicePhoneCall) {
          this.bookingTypes.push(x)
        } else if (x.name == 'Court Case' && this.specialist.serviceCourtCase) {
          x.name = 'Court Evidence Matter'
          this.bookingTypes.push(x)
        }  else if (x.name == 'File Review' && this.specialist.serviceFileReview) {
          this.bookingTypes.push(x)
        }  else if (x.name == 'Medical Negligence Request' && this.specialist.serviceMedicalNegligenceRequest) {
          this.bookingTypes.push(x)
        }  else if (x.name == 'Supplementary') {
          this.bookingTypes.push(x)
        }  else if (this.booking.otfceRequired && x.name == 'FCE Lite' && (this.user.type == 3 || this.user.type == 4 || this.user.type == 6)) {
          this.bookingTypes.push(x)
        } else if (this.booking.otfceRequired && x.name == 'FCE Lite by Video' && (this.user.type == 3 || this.user.type == 4 || this.user.type == 6)) {
          this.bookingTypes.push(x)
        }
      })
      this.claimTypes = r[2]
      var types = r[3]
      types.map((x) => {
        if (x.name == 'Brief' || x.name == 'Letter of Instruction' || x.name == 'Other') {
          this.fileTypes.push(x)
        }
      })
    },
  },

  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    await this.loadData()
    this.$store.dispatch('loading/setLoadComponent', false)
  },
  mounted() {
  },
}
</script>
