/* eslint-disable */
<template>
  <div class="specialist-search">
    <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="5" />
    <Navigation v-else indexProps="1" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Search for Specialist Availability to book an Appointment</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminBookingsMenu v-if="user.type >= 3" :key="0" :indexProps="0" />
      <SpecialistMenu v-else-if="user.type !== 1" indexProps="0" />
      <div class="content" :style="{width: user.type === 1 ? '90%' : '80%', left: user.type === 1 ? '0' : '220px'}" v-if="showContent">
        <div v-if="showCaseInfo">
          <span class="text-bold">Claimant: </span> {{ singleCase.claimantFullName }} <span class="text-bold"> D.O.B: </span> {{ formatDate(singleCase.dateOfBirth) }}
          <span class="text-bold"> Type of Claim: </span> {{ getClaimantTypeName(singleCase.claimTypeId) }}
        </div>

        <div class="box box-search-result view-hide">
          <div class="box-heading">
            <div>Search Results</div>
            <ProfileReadMoreModal ref="profileReadMoreModal" />
          </div>
          <div class="box-body">
            <div class="box-row search-value">
              <span class="text-bold pr-1">Searching for: </span> {{ getSearchOption(searchResultOption.specialtyId, searchResultOption.specialistId) }}
              <span class="text-bold padding-left-10 pr-1" v-if="searchResultOption.startDate && this.searchResultOption.bookingTypeName != 'ServiceFileReview' && this.searchResultOption.bookingTypeName != 'ServiceSupplementary'"> Date Range: </span> {{ displayDateRange() }}
              <span class="text-bold padding-left-10 pr-1" v-if="searchOption.bookingTypeName != 'ServiceSupplementary' && searchOption.locationName"> Location: </span>
              {{ this.searchResultOption.bookingTypeName != 'ServiceSupplementary' ? searchResultOption.locationName : '' }}
            </div>

            <!-- Display search result when user search by Specialty -->
            <div class="search-with-specialty search-without-specialist-specialty" v-if="searchResultOption.specialtyId && showCalendar && !showNoSearchResult">
              <div v-if="!filterMode && !showWorkCover && !showOnlyAvailable && !showAbuse" class="ms-4  d-flex align-items-center"><h6>Displaying all related results, use the ‘Show Only’ filters to narrow down your search</h6><el-button v-if="selectedId" size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="filterMode === 'person' && !noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>Showing all Face to Face results by selected location</h6> <el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="filterMode === 'video' && !noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>Showing results for Videolink availability which is regionless</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="filterMode === 'person' && noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>No availability is found to match your criteria, either search again, select an alternate calendar or filter</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="!filterMode && (showAbuse || showOnlyAvailable || showWorkCover)" class="ms-4 d-flex align-items-center"><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div class="d-flex flex-wrap mt-2">
                <div class="search-result search-all" data="specialist.id" v-for="(item, index) in availabilities.specialists" :key="item.id" v-show="filteredList.findIndex(f => f.id === item.id) > -1" :class="{'selected' : selectedId === item.id}" >
                  <div class="box-col col-center-content h-100">
                    <div class="button center-title d-flex flex-column h-100 justify-content-around">
                      <v-btn v-if="availabilities.specialists.length !== 1" class="book small" dark @click="tapViewCalendar(item.id, item.fullName)">{{isBookingWithoutAvailability ? "Book" : "View Calendar"}}</v-btn>
                      <v-btn @click="tapReadMore(item.id)" class="book small white-button" data-placement="right" data-toggle="tooltip" title="View Profile" >View Profile</v-btn>
                    </div>
                  </div>
                  <div v-if="item.profileThumbnail">
                    <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content"><img :src="item.profileThumbnail"/></div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(item)}}</s></div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 w-100 pt-2 pb-2 d-flex flex-column justify-content-between overflow-hidden">
                    <div class="name small">
                      {{ item.fullName }}
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="Fly In Fly Out Specialist" v-if="item.requiresTravel" src="@/assets/images/travel_black_circle_icon.svg" />
                    </div>
                    <div class="sub-title small pt-1">
                      {{ item.qualificationList}}
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="Assessment can also be performed via video link" v-if="item.videoLinkAvailable == 2" src="@/assets/images/video_icon_with_circle.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <el-radio-group class="w-100 d-flex justify-content-around mt-2" v-model="filterMode">
                <el-checkbox v-model="showOnlyAvailable" @change="onChangeFullyBooked" v-if="user.type >= 3" class="default-checkbox">Hide Fully Booked</el-checkbox>
                <el-checkbox v-model="showAbuse" @change="onChangeAbuse"  v-if="user.type >= 3" class="default-checkbox">Show Abuse Specialist</el-checkbox>
                <el-checkbox v-model="showWorkCover" @change="onChangeWorkCover" v-if="user.type >= 3" class="default-checkbox">Show WorkCover</el-checkbox>
                <el-radio label="person">Show only Face to Face Assessments</el-radio>
                <el-radio label="video">Show only VideoLink Assessments</el-radio>
              </el-radio-group>
              <div class="box-body">
                <ClientHorizontalCalendar
                  :calendarDateProps="getYear()"
                  @showNoFaceToFace="showNoFaceToFace"
                  :dataSourceProps="dataSource"
                  :mode="filterMode"
                  :showAbuse="showAbuse"
                  :showOnlyAvailable="showOnlyAvailable"
                  :showWorkCover="showWorkCover"
                  :location="searchResultOption.regionId"
                  :displayOnly="isDisplayOnly"
                  :hideMonthProps="searchOption"
                  :key="calendarKey"
                  ref="clientHorizontalCalendar"
                  :sync="true"
                />
                <div>
                  <div v-if="searchResult.results.length > 0" class="p-2">
                    <b>Specialists: </b>
                    <span v-for="(result, index) in searchResult.results" :key="index">
                      {{ result.fullName }} ({{result.numberOfAvailable}})
                      <span v-if="index < searchResult.results.length - 1">, </span>
                    </span>
                  </div>
                  <div v-else class="p-2">
                    <p>No Specialist found.</p>
                  </div>
                </div>
              </div>

            </div>
            <!-- END -->

            <div class="" v-if="searchResultOption.specialistId && searchResultOption.specialtyId == '' && availabilities.specialists && availabilities.specialists.length > 0">
              <div v-if="noFaceToFaceMsg" class="ms-4"><h6>{{noFaceToFaceMsg}}</h6></div>
              <el-button v-if="filterMode && (!filterMode && (showAbuse || showOnlyAvailable || showWorkCover))" class="ms-4" type="info" size="small" @click="onClickShowAll">Show All</el-button>
              <div class="d-flex flex-wrap mt-2">
              <div class="search-result" v-for="(specialist, index) in availabilities.specialists" :key="specialist.id" v-show="filteredList.findIndex(f => f.id === specialist.id) > -1">
                <div class="box-col col-center-content">
                  <div class="button center-title d-flex flex-column h-100 justify-content-around">
                    <v-btn @click="tapReadMore(specialist.id)" class="book small white-button" data-placement="right" data-toggle="tooltip" title="View Profile" >View Profile</v-btn>
                  </div>
                </div>
                <div class="box-col" v-if="specialist.profileThumbnail">
                  <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                    <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                  </div>
                </div>
                <div class="box-col" v-else>
                  <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                    <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(specialist)}}</s></div>
                  </div>
                </div>
                <div class="pl-2 pr-2 w-100 pt-2 pb-2 d-flex flex-column justify-content-between overflow-hidden">
                  <div class="name small">
                    {{ specialist.fullName }}
                    <img class="long-text" data-placement="right" data-toggle="tooltip" title="Fly In Fly Out Specialist" v-if="specialist.requiresTravel" src="@/assets/images/travel_black_circle_icon.svg" />
                  </div>
                  <div class="sub-title small pt-1">
                    {{ specialist.qualificationList}}
                    <img class="long-text" data-placement="right" data-toggle="tooltip" title="Assessment can also be performed via video link" v-if="specialist.videoLinkAvailable == 2" src="@/assets/images/video_icon_with_circle.svg" />
                  </div>
                </div>
              </div>
              </div>
              <el-radio-group v-model="filterMode" class="w-100 d-flex justify-content-around mt-2">
                <el-checkbox v-model="showOnlyAvailable" @change="onChangeFullyBooked" v-if="user.type >= 3" class="default-checkbox">Hide Fully Booked</el-checkbox>
                <el-checkbox v-model="showAbuse" @change="onChangeAbuse" v-if="user.type >= 3" class="default-checkbox">Show Abuse Specialist</el-checkbox>
                <el-checkbox v-model="showWorkCover" @change="onChangeWorkCover" v-if="user.type >= 3" class="default-checkbox">Show WorkCover</el-checkbox>
                <el-radio label="person">Show only Face to Face Assessments</el-radio>
                <el-radio label="video">Show only VideoLink Assessments</el-radio>
              </el-radio-group>
              <ClientHorizontalCalendar
                  :calendarDateProps="getYear()"
                  @showNoFaceToFace="showNoFaceToFace"
                  :dataSourceProps="dataSource"
                  :showAbuse="showAbuse"
                  :hideMonthProps="searchOption"
                  :showOnlyAvailable="showOnlyAvailable"
                  :showWorkCover="showWorkCover"
                  :mode="filterMode"
                  :location="searchResultOption.regionId"
                  :key="calendarKey"
                  ref="clientHorizontalCalendar"
                  :sync="true"
              />
            </div>

            <div class="" v-if="additionalsearch">
              <div class="box-col w-100 pb-5">
                <div class="w-100 justify-content-between booking-header-item">
                  <h3 class="mt-0">
                    <p>No results were found to match your search. </p>
                    <p>Below are the closest available dates that meet your criteria.  </p>
                  </h3>
                </div>
              </div>
              <div v-if="!filterMode && !showWorkCover && !showOnlyAvailable && !showAbuse" class="ms-4  d-flex align-items-center"><h6>Displaying all related results, use the ‘Show Only’ filters to narrow down your search</h6><el-button v-if="selectedId" size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="filterMode === 'person' && !noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>Showing all Face to Face results by selected location</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="filterMode === 'video' && !noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>Showing results for Videolink availability which is regionless</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="filterMode === 'person' && noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>No availability is found to match your criteria, either search again, select an alternate calendar or filter</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div v-if="!filterMode && (showAbuse || showOnlyAvailable || showWorkCover)" class="ms-4 d-flex align-items-center"><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
              <div class="d-flex flex-wrap mt-2">
              <div class="search-result" v-for="(specialist, index) in availabilities.additionalsearch" :key="specialist.id" v-show="filteredList.findIndex(f => f.id === specialist.id) > -1" :class="selectedId === specialist.id ? 'selected' : ''" >
                <div class="box-col col-center-content w-25">
                  <div class="mb-2">{{getDuration(specialist)}}</div>
                  <div class="button">
                    <v-btn v-if="availabilities.additionalsearch.length !== 1" class="book d-block" dark @click="tapViewCalendar(specialist.id, specialist.fullName)">{{isBookingWithoutAvailability ? "Book" : "View Calendar"}}</v-btn>
                    <v-btn @click="tapReadMore(specialist.id)" class="book small white-button" data-placement="right" data-toggle="tooltip" title="View Profile" >View Profile</v-btn>
                  </div>
                </div>
                <div class="box-col" v-if="specialist.profileThumbnail">
                  <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                    <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                  </div>
                </div>
                <div class="box-col" v-else>
                  <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                    <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(specialist)}}</s></div>
                  </div>
                </div>
                <div class="box-col w-100">
                  <div class="name small">
                    {{ specialist.fullName }}
                    <img class="long-text" data-placement="right" data-toggle="tooltip" title="Fly In Fly Out Specialist" v-if="specialist.requiresTravel" src="@/assets/images/travel_black_circle_icon.svg" />
                  </div>
                  <div class="sub-title small pt-1">
                    {{ specialist.qualificationList}}
                    <img class="long-text" data-placement="right" data-toggle="tooltip" title="Assessment can also be performed via video link" v-if="specialist.videoLinkAvailable == 2" src="@/assets/images/video_icon_with_circle.svg" />
                  </div>
                </div>
              </div>
              </div>
              <el-radio-group v-model="filterMode" class="w-100 d-flex justify-content-around mt-2">
                <el-checkbox v-model="showOnlyAvailable" @change="onChangeFullyBooked" v-if="user.type >= 3" class="default-checkbox">Hide Fully Booked</el-checkbox>
                <el-checkbox v-model="showAbuse" @change="onChangeAbuse" v-if="user.type >= 3" class="default-checkbox">Show Abuse Specialist</el-checkbox>
                <el-checkbox v-model="showWorkCover" @change="onChangeWorkCover" v-if="user.type >= 3" class="default-checkbox">Show WorkCover</el-checkbox>
                <el-radio label="person">Show only Face to Face Assessments</el-radio>
                <el-radio label="video">Show only VideoLink Assessments</el-radio>
              </el-radio-group>
              <ClientHorizontalCalendar
                  :calendarDateProps="getYear()"
                  @showNoFaceToFace="showNoFaceToFace"
                  :dataSourceProps="dataSource"
                  :showAbuse="showAbuse"
                  :showOnlyAvailable="showOnlyAvailable"
                  :showWorkCover="showWorkCover"
                  :hideMonthProps="searchOption"
                  :mode="filterMode"
                  :location="searchResultOption.regionId"
                  :key="calendarKey"
                  ref="clientHorizontalCalendar"
                  :sync="true"
              />
              <div>
                <div v-if="searchResult.results.length > 0" class="p-2">
                  <b>Specialists: </b>
                  <span v-for="(result, index) in searchResult.results" :key="index">
                      {{ result.fullName }} ({{result.numberOfAvailable}})
                      <span v-if="index < searchResult.results.length - 1">, </span>
                    </span>
                </div>
                <div v-else class="p-2">
                  <p>No Specialist found.</p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="box-row" v-if="showNoSearchResult">
                <div class="box-col w-100 pt-5 pb-5">
                  <div class="w-100 justify-content-between booking-header-item">
                    <h3 class="mt-0">
                      <p>No results found to match your search. </p>
                      <p>Please either search again, or tell us what you need and we’ll do the hard work for you</p>
                    </h3>
                     <v-btn @click="tapSubmitRequest()" class="submit-request" dark>Submit Request</v-btn>
                  </div>
                </div>
              </div>
              <div v-if="showAlternative">
                <h3 class="mt-0">
                  <p>No availability found to match your search. The following specialists travel to that location, and can be requested.</p>
                </h3>
                <div class="d-flex flex-wrap mt-2">
                <div class="search-result" v-for="(specialist, index) in availabilities.alternate" v-show="filteredList.findIndex(f => f.id === specialist.id) > -1" :key="specialist.id">
                  <div class="box-col col-center-content h-100">
                    <div class="button center-title d-flex flex-column h-100 justify-content-around">
                      <v-btn class="book ps-1 pe-1 ms-2" dark @click="tapSubmitRequest(specialist.id)">Submit Request</v-btn>
                      <v-btn @click="tapReadMore(specialist.id)" class="book small white-button" data-placement="right" data-toggle="tooltip" title="View Profile" >View Profile</v-btn>
                    </div>
                  </div>
                  <div class="box-col" v-if="specialist.profileThumbnail">
                    <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                    </div>
                  </div>
                  <div class="box-col" v-else>
                    <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(specialist)}}</s></div>
                    </div>
                  </div>

                  <div class="pl-2 pr-2 w-100 pt-2 pb-2 d-flex flex-column justify-content-between overflow-hidden">
                    <div class="name small">
                      {{ specialist.fullName }}
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="Fly In Fly Out Specialist" v-if="specialist.requiresTravel" src="@/assets/images/travel_black_circle_icon.svg" />
                    </div>
                    <div class="sub-title small pt-1">
                      {{ specialist.qualificationList}}
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="Assessment can also be performed via video link" v-if="specialist.videoLinkAvailable == 2" src="@/assets/images/video_icon_with_circle.svg" />
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div v-if="showRelatedProf">
                <div class="w-100 justify-content-between booking-header-item">
                  <h3 class="mt-0 padding-left-20">
                    <p>No results found to match your search. Please either search again, or tell us what you need</p>
                  </h3>
                </div>
                <h2 class="padding-left-20 pt-5">Related Professionals - Have you considered using a related professional who may also be able to perform this assessment?<el-button v-if="selectedId" size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></h2>
                <div v-if="filterMode === 'person' && !noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>Showing all Face to Face results by selected location</h6> <el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
                <div v-if="filterMode === 'video' && !noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>Showing results for Videolink availability which is regionless</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
                <div v-if="filterMode === 'person' && noFaceToFaceMsg" class="ms-4 d-flex align-items-center"><h6>No availability is found to match your criteria, either search again, select an alternate calendar or filter</h6><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
                <div v-if="!filterMode && (showAbuse || showOnlyAvailable || showWorkCover)" class="ms-4 d-flex align-items-center"><el-button size="small" type="info" class="ms-4" @click="onClickShowAll">Show All</el-button></div>
                <div class="d-flex flex-wrap mt-2">
                <div class="search-result"  v-for="(specialist, index) in availabilities.related" :key="specialist.id" v-show="filteredList.findIndex(f => f.id === specialist.id) > -1" :class="selectedId === specialist.id ? 'selected' : ''" >
                  <div class="box-col col-center-content h-100">
                    <div class="button center-title d-flex flex-column h-100 justify-content-around">
                      <!-- <v-btn class="book" dark @click="tapBooking(index, 'related', true)">Book</v-btn> -->
                      <v-btn class="book" dark @click="tapViewCalendar(specialist.id, specialist.fullName)">{{isBookingWithoutAvailability ? "Book" : "View Calendar"}}</v-btn>
                      <v-btn @click="tapReadMore(specialist.id)" class="book small white-button" data-placement="right" data-toggle="tooltip" title="View Profile" >View Profile</v-btn>
                    </div>
                  </div>
                  <div class="box-col" v-if="specialist.profileThumbnail">
                    <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                    </div>
                  </div>
                  <div class="box-col" v-else>
                    <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(specialist)}}</s></div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 w-100 pt-2 pb-2 d-flex flex-column justify-content-between overflow-hidden">
                    <div class="name small">
                      {{ specialist.fullName }}
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="Fly In Fly Out Specialist" v-if="specialist.requiresTravel" src="@/assets/images/travel_black_circle_icon.svg" />
                    </div>
                    <div class="sub-title small pt-1">
                      {{ specialist.qualificationList}}
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="Assessment can also be performed via video link" v-if="specialist.videoLinkAvailable == 2" src="@/assets/images/video_icon_with_circle.svg" />
                    </div>
                  </div>
                </div>
                </div>
                <el-radio-group v-model="filterMode" class="w-100 d-flex justify-content-around mt-2">
                  <el-checkbox v-model="showOnlyAvailable" @change="onChangeFullyBooked" v-if="user.type >= 3" class="default-checkbox">Hide Fully Booked</el-checkbox>
                  <el-checkbox v-model="showAbuse" @change="onChangeAbuse" v-if="user.type >= 3" class="default-checkbox">Show Abuse Specialist</el-checkbox>
                  <el-checkbox v-model="showWorkCover" @change="onChangeWorkCover" v-if="user.type >= 3" class="default-checkbox">Show WorkCover</el-checkbox>
                  <el-radio label="person">Show only Face to Face Assessments</el-radio>
                  <el-radio label="video">Show only VideoLink Assessments</el-radio>
                </el-radio-group>
                <ClientHorizontalCalendar
                    :calendarDateProps="getYear()"
                    :dataSourceProps="dataSource"
                    @showNoFaceToFace="showNoFaceToFace"
                    :hideMonthProps="searchOption"
                    :showAbuse="showAbuse"
                    :showOnlyAvailable="showOnlyAvailable"
                    :showWorkCover="showWorkCover"
                    :mode="filterMode"
                    :location="searchResultOption.regionId"
                    :key="calendarKey"
                    ref="clientHorizontalCalendar"
                    :sync="true"
                />
                <div>
                  <div v-if="searchResult.results.length > 0" class="p-2">
                    <b>Specialists: </b>
                    <span v-for="(result, index) in searchResult.results" :key="index">
                      {{ result.fullName }} ({{result.numberOfAvailable}})
                      <span v-if="index < searchResult.results.length - 1">, </span>
                    </span>
                  </div>
                  <div v-else class="p-2">
                    <p>No Specialist found.</p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="showSpecialistAvailable">
              <div class="box-row" v-if="showNoSearchResultForSpecialist">
                <div class="box-col" style="width: 100%; padding-top: 15px; padding-bottom: 45px;">
                  <div class="w-100 justify-content-between booking-header-item">
                    <h3 class="mt-0" style="line-height: 1">
                      <p>No results were found to match your search.</p>
                      <p class="mt-2">Please either search again, or tell us what you need and we’ll do the hard work for you.
                        <v-btn class="book ps-1 pe-1 ms-2" dark @click="tapSubmitRequest(specialist.id)">Submit Request</v-btn>
                      </p>
                    </h3>
                    <!-- <v-btn @click="tapSubmitRequest()" class="submit-request" dark>Submit Request</v-btn> -->
                  </div>
                </div>
              </div>
              <h2 v-if="showNoSearchResultForSpecialist" class="padding-left-20">However we have displayed available dates for {{ specialist.fullName }} in other locations</h2>
              <h2 v-else class="padding-left-20">Available dates for {{ specialist.fullName }}</h2>
              <div class="search-result">
                <div class="box-col" v-if="specialist.profileThumbnail">
                  <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                    <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                  </div>
                </div>
                <div class="box-col" v-else>
                  <div class="rounded-image smaller-rounded-image m-t-0 m-t-b">
                    <div class="profile-image-content notice-background"><s class="text-initials small">{{getInitials(specialist)}}</s></div>
                  </div>
                </div>
                <div class="box-col w-100">
                  <div class="name small">
                    {{ specialist.fullName }}
                    <img class="long-text" data-placement="right" data-toggle="tooltip" title="Fly In Fly Out Specialist" v-if="specialist.requiresTravel" src="@/assets/images/travel_black_circle_icon.svg" />
                  </div>
                  <div class="sub-title small pt-1">
                    {{ specialist.qualificationList}}
                    <img class="long-text" data-placement="right" data-toggle="tooltip" title="Assessment can also be performed via video link" v-if="specialist.videoLinkAvailable == 2" src="@/assets/images/video_icon_with_circle.svg" />
                  </div>
                  <div class="">
                    <div class="is-icon right-float-element" @click="tapReadMore(specialist.id)">
                      <img class="long-text" data-placement="right" data-toggle="tooltip" title="View Profile" src="@/assets/images/view_booking.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <el-radio-group v-model="filterMode" class="w-100 d-flex justify-content-around mt-2">
                <el-checkbox v-model="showOnlyAvailable" @change="onChangeFullyBooked" v-if="user.type >= 3" class="default-checkbox">Hide Fully Booked</el-checkbox>
                <el-checkbox v-model="showAbuse" @change="onChangeAbuse" v-if="user.type >= 3" class="default-checkbox">Show Abuse Specialist</el-checkbox>
                <el-checkbox v-model="showWorkCover" @change="onChangeWorkCover" v-if="user.type >= 3" class="default-checkbox">Show WorkCover</el-checkbox>
                <el-radio label="person">Show only Face to Face Assessments</el-radio>
                <el-radio label="video">Show only VideoLink Assessments</el-radio>
              </el-radio-group>
              <ClientHorizontalCalendar
                :calendarDateProps="getYear()"
                :dataSourceProps="dataSource"
                @showNoFaceToFace="showNoFaceToFace"
                :hideMonthProps="searchOption"
                :showAbuse="showAbuse"
                :showOnlyAvailable="showOnlyAvailable"
                :showWorkCover="showWorkCover"
                :mode="filterMode"
                :location="searchResultOption.regionId"
                :key="calendarKey"
                 ref="clientHorizontalCalendar"
                 :sync="true"
               />
            </div>
          </div>
        </div>

        <div class="box search-details">
          <div class="box-heading">
            <div>Find a Specialist by completing the details below:</div>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left" :class="isHideSpecialty ? 'disabled-element' : ''">I'm looking for a</div>
              <div class="box-col box-col-right">
                <div class="d-flex align-items-center w-100">
                  <el-select placeholder="Select specialty" class="w-50" @change="onChangeSpecialty" filterable clearable :disabled="isHideSpecialty" :class="isHideSpecialty ? 'disabled-element' : ''" v-model="searchOption.specialtyId">
                    <el-option v-for="(specialty, index) in specialties" :key="specialty.id" :value="specialty.id" :label="specialty.name">{{ specialty.name }}</el-option>
                  </el-select>
                  <el-checkbox @change="onChangeCheck" class="ms-2 default-checkbox" v-model="isHideSpecialty">OR, click here if you know which specialist you want</el-checkbox>
                </div>
                <div v-if="hasError && !validateSpecialty" class="text-danger">Specialty is required.</div>
              </div>
            </div>
            <div class="box-row" :class="!isHideSpecialty ? 'disabled-element' : ''">
              <div class="box-col box-col-left">I want an appointment with</div>
              <div class="box-col box-col-right">
                <el-select placeholder="Select specialist" class="w-50" @change="onChangeSpecialist" filterable clearable :disabled="!isHideSpecialty" v-model="searchOption.specialistId">
                  <el-option v-for="(specialist, index) in listSpecialists" :key="specialist.id" :value="specialist.id" :label="specialist.name">{{ specialist.name }}</el-option>
                </el-select>
                <div v-if="hasError && !validateSpecialist" class="text-danger">Specialist is required.</div>
              </div>
            </div>
            <div v-if="this.searchOption.bookingTypeName !== 'ServiceMedicalNegligenceRequest' && this.searchOption.bookingTypeName !== 'ServiceSupplementary' && this.searchOption.bookingTypeName !== 'ServiceFileReview'" class="box-row">
              <div class="box-col box-col-left">Examinee Lives in</div>
              <div class="box-col box-col-right">
                <treeselect ref="treeSelect"
                  class="w-50"
                  :multiple="false"
                  :searchable="false"
                  :closeOnSelect="false"
                  :options="regionLocations"
                  :limit="7"
                  :max-height="200"
                  :defaultExpandLevel="0"
                  placeholder="Please choose ..."
                  noOptionsText="No regions"
                  noChildrenText="No cities"
                  v-model="searchOption.regionId"
                  @select="changeRegion" />
                <div v-if="hasError && !validateLocation" class="text-danger">Region / Location is required.</div>
              </div>
            </div>
            <div class="box-row" v-if="showDateRange && this.searchOption.bookingTypeName !== 'ServiceMedicalNegligenceRequest' && this.searchOption.bookingTypeName !== 'ServiceSupplementary' && this.searchOption.bookingTypeName !== 'ServiceFileReview'" >
              <div class="box-col box-col-left">I want an assessment between these dates</div>
              <div class="box-col box-col-right">
                <v-md-date-range-picker :start-date="searchOption.startDate" :end-date="searchOption.endDate" class="w-50" @change="changeDateRange"></v-md-date-range-picker>
                <div v-if="invalidDate" class="text-danger">End Date cannot be before Today</div>
              </div>
            </div>
            <div v-if="user.type >= 3" class="box-row" :disabled="this.booking.id != '' || this.disabledBookingType ? true: false" :class="this.booking.id != '' || this.disabledBookingType ? 'disabled-element' : ''">
              <div class="box-col box-col-left">Booking Type</div>
              <div class="box-col box-col-right">
                <el-select filterable clearable placeholder="Select booking type" :disabled="this.booking.id != '' || this.disabledBookingType ? true: false" @change="onChangeBookingType" class="w-50"  v-model="searchOption.bookingTypeName">
                  <el-option v-for="(bookingType, index) in filteredBookingTypes" :key="index" :value="bookingType.id" :label="bookingType.name">{{ bookingType.name }}</el-option>
                </el-select>
                <div v-if="hasError && !validateBookingType" class="text-danger">Booking Type is required.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-row item-right">
          <div><v-btn class="submit-request" @click="tapSearch()" dark>Search</v-btn></div>
        </div>
        <br>
        <div class="d-flex justify-content-center">
          <div style="height: fit-content" class="w-75 pt-3 pb-3 box-heading-center d-flex justify-content-between align-items-center pl-10 pr-10">
            <div class="display-sub-title">
              <div v-if="user.type < 3 "><b>If this booking is for an Abuse in Care Matter please use the submit request button to submit a request.</b></div>
              <div><b>Not sure what specialist to book or can’t find what your looking for?</b></div>
              <div><b>Submit a Request and we’ll do the hard work for you.</b></div>
            </div>
            <v-btn @click="tapSubmitRequest()" class="submit-request ps-1 pe-1" dark>Submit Request</v-btn>
          </div>
        </div>
        <br>
        <div class="box box-search-history" v-if="this.searchLogs.length > 0">
          <div class="box-heading col-space-between">
            <div>Previous Searches</div>
            <div class="">
              <v-btn class="submit-request" @click="showAllSearchLog" v-if="searchLogs.length <= 5" dark>View All</v-btn>
            </div>
          </div>
          <div class="box-body">
            <div class="box-row list-row" :class="searchLogs.length <= 5 ? 'searchlog-height' : ''">
              <div @click="setSearchItem(index)" class="list-row-item is-icon" v-for="(log, index) in searchLogs" :key="index">
                <strong class="mr-1">Searching for: </strong>{{ log.specialistName != '' ? log.specialistName : log.qualificationName }}
                <strong class="mr-1 ml-6">Date Range: </strong>{{ formatDate(log.dateStart) + ' - ' + formatDate(log.dateEnd) }}
                <strong v-if="log.fullLocation || log.regionName" class="mr-1 ml-6">Location: </strong>{{ log.fullLocation != '' ? log.fullLocation : log.regionName }}
                <span v-if="log.videoLinkAccepted == 2">
                  <strong class="mr-3 ml-6">Face to Face or Video Link Appointments: </strong><input disabled type="checkbox" class="d-table-cell" checked />
                </span>
                <span v-if="log.VideoOnly == 1">
                  <strong class="mr-3 ml-6">Video Only </strong><input type="checkbox" disabled class="d-table-cell" checked />
                </span>
                <span v-if="log.ShowAbuse == 1">
                  <strong class="mr-3 ml-6">Show Abuse Assessors </strong><input disabled type="checkbox" class="d-table-cell" checked />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Chat v-if="this.user.type < 3" />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import Navigation from '@/components/Navigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import AdminNavigation from "@/components/AdminNavigation";
  import AdminBookingsMenu from "@/components/AdminBookingsMenu";
  import Header from '@/components/Header'
  import Chat from '@/components/Chat'
  import ProfileReadMoreModal from '@/components/ProfileReadMoreModal'
  import ClientHorizontalCalendar from '@/components/ClientHorizontalCalendar'

  export default {
    name: 'SearchSpecialist',
    components: {
      Navigation,
      SpecialistMenu,
      AdminNavigation,
      AdminBookingsMenu,
      Header,
      Chat,
      ProfileReadMoreModal,
      ClientHorizontalCalendar,
    },
    data() {
      return {
        bookingRequest: null,
        showWorkCover: false,
        originalList: [],
        showAbuse: false,
        showOnlyAvailable: false,
        filteredList: [],
        filterMode: false,
        searchOption: {
          ShowAbuse: false,
          bookingTypeId: '',
          bookingTypeName: '',
          specialtyId: '',
          specialistId: '',
          locationId: '',
          locationName: '',
          regionId: null,
          radius: '',
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(6, 'w').format('YYYY-MM-DD'),
          videoLink: false,
          VideoOnly: false,
        },
        searchResultOption: {
          specialtyId: '',
          specialistId: '',
        },
        clickSpecialty: false,
        clickSpecialist: false,
        clickBookingType: false,
        isHideSpecialty: false,
        showAll: true,
        showDateRange: true,
        showLocation: true,
        hasError: false,
        noFaceToFaceMsg: null,
        checked: false,
        showCaseInfo: false,
        isReschedule: false,
        showNoSearchResult: false,
        showSearchResult: false,
        showAlternative: false,
        showRelatedProf: false,
        showSpecialistAvailable: false,
        showNoSearchResultForSpecialist: false,
        showSpecialist: false,
        isWaitlisted: false,
        showContent: true,
        isDateChange: false,
        invalidDate: false,
        showRadius: true,
        additionalsearch: false,
        isFromCalendar: false,
        isFromSpecialist: false,
        showCalendar: false,
        isBookingWithoutAvailability: false,
        disabledBookingType: false,
        minimumAppointmentLength: '',
        bookingType: '',
        bookingId: '',
        theme: '',
        specialty: '',
        searchRegionId: '',
        bookingTypeId: '',
        bookingRequestId: '',
        waitlistTime: '',
        clientId:'',
        claimTypes: [],
        specialists: [],
        specialist: [],
        specialties: [],
        listSpecialists: [],
        originalListSpecialists: [],
        regions: [],
        locations: [],
        regionLocations: [],
        searchLogs: [],
        availabilities: [],
        allBookingTypes: [],
        specialistTimes: [],
        allSearchLog: [],
        spLocations: [],
        timeCount: 0,
        booking: utilities.initBooking(),
        singleCase: utilities.initCase(),
        pagination: utilities.initPagination(),
        bookingTypes: utilities.getSearchSpecialistsBookingTypes(),
        filteredBookingTypes: [],
        selectedId: null,
        searchResult: this.initSearchResult(),
        specialistIds: [],
        isDisplayOnly: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        dataSource: [],
        calendarKey: 0,
      }
    },
    computed: {
      validateBookingType() {
        if (this.user.type >= 3 && this.searchOption.bookingTypeName == '') {
          return false
        }
        return true
      },
      validateDateRange() {
        if (this.searchOption.startDate == '') {
          return false
        }
        return true
      },
      validateSpecialist() {
        if (this.searchOption.specialistId == '' && this.isHideSpecialty) {
          return false
        }
        return true
      },
      validateSpecialty() {
        if (this.searchOption.specialtyId == '' && !this.isHideSpecialty) {
          return false
        }
        return true
      },
      validateLocation() {
        if (this.searchOption.bookingTypeName !== 'ServiceMedicalNegligenceRequest' && this.searchOption.bookingTypeName !== 'ServiceFileReview' && this.searchOption.bookingTypeName !== 'ServiceSupplementary' && this.searchOption.regionId == null) {
          return false
        }
        return true
      },
    },

    methods: {
      async onClickShowAll() {
        this.showWorkCover = false
        this.showAbuse = false
        this.showOnlyAvailable = false
        this.filterMode = null
        this.searchResult.results = []
        await this.onChangeAbuse(false)
      },
      showNoFaceToFace(b, msg) {
        if(b && this.filterMode) this.noFaceToFaceMsg = 'No Face to Face appointments match your search. There are VideoLink appointments available. Click "Show Only VideoLink Assessments" to pick one."'
        else if (b && !this.filterMode && msg) this.noFaceToFaceMsg = msg
        else this.noFaceToFaceMsg = null
      },
      getDuration(specialist) {
        return `${specialist.lastAvailability} ~ ${specialist.nextAvailability}`
      },
      onChangeCheck(e) {
        if(e) this.searchOption.specialtyId = ''
        else this.searchOption.specialistId = ""
      },
      async onChangeWorkCover(e) {
        this.filteredList = this.getFilterAvailabilities()
        if(this.showAbuse) this.filteredList = this.filteredList.filter(item => item.serviceIMEAbuse)
        this.filteredList = this.filteredList.map(item => {
          const arr = []
          item.specialistAvailabilities.forEach(a => {
            if (a.status !== 'fully booked') arr.push({...a})
          })
          return {
            ...item,
            specialistAvailabilities: arr
          }
        })
        this.filteredList = this.filteredList.filter(item => item.specialistAvailabilities.length > 0)
        if (e) {
          this.filteredList = this.filteredList.map(item => {
            const arr = []
            item.specialistAvailabilities.forEach(a => {
              const avaArr = []
              a.Availabilities.forEach(b => {
                if(b.Options.ShowWorkCover) {
                  avaArr.push(b)
                }
              })
              if(avaArr.length > 0) arr.push({...a, Availabilities: avaArr})
            })
            return {
              ...item,
              specialistAvailabilities: arr
            }
          })
          this.filteredList = this.filteredList.filter(item => item.specialistAvailabilities.length > 0)
          await this.setAvailability(this.filteredList)
        } else if (!e && (this.showAbuse || this.showOnlyAvailable)) {
          await this.setAvailability(this.filteredList)
        } else {
          await this.setAvailability()
        }
        this.selectedId = null
      },
      getFilterAvailabilities() {
        let arr = []
        if (this.availabilities.specialists.length > 0) {
          this.filteredList = _.cloneDeep(this.availabilities.specialists)
          arr = _.cloneDeep(this.availabilities.specialists)
        }
        if (this.availabilities.additionalsearch.length > 0) {
          this.filteredList = _.cloneDeep(this.availabilities.additionalsearch)
          arr = _.cloneDeep(this.availabilities.additionalsearch)
        }
        if (this.availabilities.related.length > 0) {
          this.filteredList = _.cloneDeep(this.availabilities.related)
          arr = _.cloneDeep(this.availabilities.related)
        }
        if (this.availabilities.alternate.length > 0) {
          this.filteredList = _.cloneDeep(this.availabilities.alternate)
          arr = _.cloneDeep(this.availabilities.alternate)
        }
        return arr
      },
      async onChangeFullyBooked(e) {
        let defaultArr = this.getFilterAvailabilities()
        if (this.showWorkCover) {
          this.filteredList = this.filteredList.map(item => {
            const arr = []
            item.specialistAvailabilities.forEach(a => {
              const avaArr = []
              a.Availabilities.forEach(b => {
                if (b.Options.ShowWorkCover) {
                  avaArr.push(b)
                }
              })
              if(avaArr.length > 0) arr.push({...a, Availabilities: avaArr})
            })
            return {
              ...item,
              specialistAvailabilities: arr
            }
          })
          this.filteredList = this.filteredList.filter(item => item.specialistAvailabilities.length > 0)
        }
        if (this.showAbuse) this.filteredList = this.filteredList.filter(item => item.serviceIMEAbuse)
        if (e) {
          this.filteredList = this.filteredList.map(item => {
            const arr = []
            item.specialistAvailabilities.forEach(a => {
              if (a.status !== 'fully booked') arr.push({...a})
            })
            return {
              ...item,
              specialistAvailabilities: arr
            }
          })
          this.filteredList = this.filteredList.filter(item => item.specialistAvailabilities.length > 0)
          await this.setAvailability(this.filteredList)
        } else if(!e && (this.showWorkCover || this.showAbuse)) {
          await this.setAvailability(this.filteredList)
        } else {
          await this.setAvailability()
        }
        this.selectedId = null
      },
      async onChangeAbuse(e) {
        let arr = this.getFilterAvailabilities()
        if(this.showOnlyAvailable) {
          this.filteredList = this.filteredList.map(item => {
            const arr = []
            item.specialistAvailabilities.forEach(a => {
              if (a.status !== 'fully booked') arr.push({...a})
            })
            return {
              ...item,
              specialistAvailabilities: arr
            }
          })
          this.filteredList = this.filteredList.filter(item => item.specialistAvailabilities.length > 0)
        }
        if(this.showWorkCover) {
          this.filteredList = this.filteredList.map(item => {
            const arr = []
            item.specialistAvailabilities.forEach(a => {
              const avaArr = []
              a.Availabilities.forEach(b => {
                if(b.Options.ShowWorkCover) {
                  avaArr.push(b)
                }
              })
              if(avaArr.length > 0) arr.push({...a, Availabilities: avaArr})
            })
            return {
              ...item,
              specialistAvailabilities: arr
            }
          })
          this.filteredList = this.filteredList.filter(item => item.specialistAvailabilities.length > 0)
        }
        if (e) {
          this.filteredList = this.filteredList.filter(item => item.serviceIMEAbuse)
          await this.setAvailability(this.filteredList)
        } else if(!e && (this.showWorkCover || this.showOnlyAvailable)) {
          await this.setAvailability(this.filteredList)
        } else {
          await this.setAvailability()
        }
        this.selectedId = null
      },
      changeVideoOnly(e) {
        if(!e.target.checked && !this.searchOption.videoLink && this.searchOption.bookingTypeName === 'ServiceIMEVideo') {
          this.$nextTick(() => {
            this.searchOption.VideoOnly = true
          })
        } else if (e.target.checked) {
          this.searchOption.videoLink = false
        }
      },
      changeVideoLink(e) {
        if(!e.target.checked && !this.searchOption.VideoOnly && this.searchOption.bookingTypeName === 'ServiceIMEVideo') {
          this.$nextTick(() => {
            this.searchOption.videoLink = true
          })
        } else if (e.target.checked) {
          this.searchOption.VideoOnly = false
        }
      },
      selectedSpecialist(item) {
        if (item.id) {
          this.searchOption.specialistId = item.id
          this.searchOption.specialtyId = ""
          this.clickSpecialty = false
        }
      },

      initSearchResult() {
        return {
          calendarDate: moment().format('YYYY-MM-DD'),
          availabilities: [],
          dataSourceAvailabilities: [],
          specialists: [],
          numberTimeSlotsBooked: 0,
          numberTimeSlots: 0,
          summary: '',
          results: [],
          searched: false,
        }
      },

      clearSearch() {
        this.searchOption.bookingTypeId = ''
        this.searchOption.bookingTypeName = ''
        this.searchOption.specialtyId = ''
        this.searchOption.specialistId = ''
        this.searchOption.locationId = ''
        this.searchOption.locationName = ''
        this.searchOption.regionId = null
        this.searchOption.radius = ''
        this.searchOption.videoLink = false
      },

      getInitials(specialist) {
        var initials = ""
        if (specialist.firstName) {
          initials = specialist.firstName.substring(0,1)
        }

        if (specialist.lastName) {
          initials = initials + specialist.lastName.substring(0,1)
        }
        return initials
      },

      tapViewCalendar(specialistId, specialistName) {
        if (this.isBookingWithoutAvailability) {
          this.gotoSearchCase(specialistId)
        } else {
          this.isDisplayOnly = false
          this.isFromSpecialist = true
          this.getSpecialistDetails(specialistId, specialistName)
        }
        this.selectedId = specialistId
      },

      displayDateRange() {
        if (this.searchOption.bookingTypeName != 'ServiceFileReview' && this.searchOption.bookingTypeName != 'ServiceSupplementary') {
          return utilities.formatDate(this.searchOption.startDate, 'DD/MM/YYYY') + ' - ' + utilities.formatDate(this.searchOption.endDate, 'DD/MM/YYYY')
        }
      },

      showAllSearchLog() {
        this.searchLogs = this.allSearchLog
        $('html, body').animate({
          scrollTop: $(".box-search-history").offset().top - 200
        }, 500);
      },

      tapBooking(index, type, isBook = false, e) {
        var bookingParams = this.getBookingData(index, type, e)
        if (this.isBookingWithoutAvailability) {
          this.gotoSearchCase(bookingParams.id)
        } else {
          var routeData = null
          if (this.user.type >= 3) {  // Admin
            if (bookingParams.caseId) {
              this.$router.push({ name: 'admin-create-new-booking'}).catch((err) => {})
            }
            if (this.bookingRequestId) {
              bookingParams.bookingRequestId = this.bookingRequestId
            }
            this.$store.dispatch("booking/setBookingObject", bookingParams)
            if(isBook || this.isWaitlisted) {
              if(this.bookingRequestId) {
                routeData = this.$router.resolve({ name: 'admin-create-new-booking'})
              } else {
                routeData = this.$router.resolve({ name: 'admin-search-cases-details'})
              }
            } else {
              routeData = this.$router.resolve({ name: 'admin-search-cases-details'})
            }
          } else {  // Client
            this.$store.dispatch("booking/setBookingObject", bookingParams)
            if (this.isWaitlisted || bookingParams.bookingId || bookingParams.caseId) {
              if (bookingParams.bookingId && bookingParams.caseId) {
                routeData = this.$router.resolve({ name: 'manage-booking'})
              } else {
                routeData = this.$router.resolve({ name: 'search-cases-details'})
              }
            } else {
              routeData = this.$router.resolve({ name: 'search-cases-details'})
            }
          }

          if(routeData != undefined && routeData.href != undefined) window.open(routeData.href, "_self")
        }
      },

      getBookingData(index, type, e) {
        var typeDefault = this.user.type >= 3 ? this.bookingTypes.find((x) => x.id == this.searchOption.bookingTypeName) : ''
        var typeName = this.user.type >= 3 ? (typeDefault.otherName || typeDefault.name) : ''
        var bookingType = this.allBookingTypes.find((x) => x.name.trim() === typeName.trim())
        var bookingTypeId = bookingType != undefined ? bookingType.id : ""
        var specialist = {}
        if (type == 'specialist') {
          specialist = this.availabilities.specialists[index]
        } else if (type == 'alternate') {
          specialist = this.availabilities.alternate[index]
        } else if (type == 'related') {
          specialist = this.availabilities.related[index]
        } else if (type == "") {
          if (this.specialist) {
           specialist = this.specialist
          }
        }
        var bookingParams = {
          id: specialist.id,
          typeId: bookingTypeId,
          typeName: typeName,
          waitlisted: this.isWaitlisted ? true : false,
          endDate: this.searchResultOption.endDate,
          fillOrder: specialist.fillOrder,
          locationId: e.LocationID,
          locationName: e.LocationName,
          bookingDate: specialist.bookingDate,
          bookingTime: specialist.bookingTime,
          bookingEndTime: e.EndTime,
          caseId: this.singleCase.id,
          bookingId: this.booking.id,
          availabilityId: specialist.specialistAvailabilityId,
          requiresTravel: specialist.requiresTravel,
          videoLinkAvailable: specialist.videoLinkAvailable,
          regionId: this.searchResultOption.regionId,
          qualificationId: this.searchResultOption.specialtyId,
          isSingleId: this.isFromCalendar ? false : true,
          isFromSpecialist: this.isFromSpecialist,
          isSupplementary: false
        }
        if(this.bookingRequest) {
          bookingParams.claimantFirstName = this.bookingRequest.claimantFirstName
          bookingParams.claimantSurname = this.bookingRequest.claimantSurname
        }

        if(e) {
          bookingParams.hasUserVideo = e.Options.VideoLinkAvailable && e.RegionID === Number(this.searchResultOption.regionId.replace('r', '')) ? true : false
          if(e.Options.VideoOnly) {
            bookingParams.hasVideo = true
          } else {
            bookingParams.hasVideo = e.Options.VideoLinkAvailable && e.RegionID !== Number(this.searchResultOption.regionId.replace('r', '')) ? true : false
          }
        }

        //Pass clientId into booking flow when admin try to add booking from Admin clients screen
        if (this.clientId != '') {
          bookingParams.clientId = this.clientId
        }

        if (bookingParams.waitlisted) {
          bookingParams.bookingTime = this.waitlistTime
        }
        if (specialist.locationId) {
          bookingParams.locationName = this.locations.find((x) => x.id == specialist.locationId).shortLocation
        }
        return bookingParams
      },

      setBookingTimeInfo(specialist) {
        var value = ''
        if (this.searchOption.bookingTypeName == 'ServiceFileReview' || this.searchOption.bookingTypeName == 'ServiceSupplementary') {
          return value
        } else {
          if (specialist.bookingTime) {
            value = moment(specialist.bookingTime, 'HH:mm').format('hh:mm A')
            value = value + ' ' + moment(specialist.bookingDate, 'YYYY-MM-DD').format('DD/MM')
          } else {
            value = 'Avail: ' + specialist.bookingDate
          }
          return value
        }
      },

      getBaseLocation(locationId) {
        if (locationId != undefined) {
          var value = this.locations.find((x) => x.id == locationId)
          if (value) {
            return ', ' + value.shortLocation
          }
        }
        return ''
      },

      setSearchItem(index) {
        $('.box-search-result').addClass('view-hide')
        this.clearSearch()
        this.showCalendar = false
        this.showNoSearchResult = false
        this.showSearchResult = false
        this.showAlternative = false
        this.showRelatedProf = false
        this.showSpecialist = false
        this.showSpecialistAvailable = false

        var item = this.searchLogs[index]
        this.isDateChange = true
        if (item.qualificationName) {
          var qualification = this.specialties.find((x) => x.name == item.qualificationName)
          this.searchOption.specialtyId = qualification.id
          this.searchOption.specialistId = ''
          this.clickSpecialty = true
          this.clickSpecialist = false
          this.isHideSpecialty = false
        } else {
          this.clickSpecialist = true
          this.clickSpecialty = false
          this.isHideSpecialty = true
          this.searchOption.specialistId = item.specialistId
          this.searchOption.specialtyId = ''
          this.setSpecialistData(this.searchOption.specialistId)
        }
        if (item.cities.length > 0) {
          var cityName = item.cities[0].cityName
          for (var i = 0; i < this.regionLocations.length; i++) {
            var item1 = this.regionLocations[i]
            for (var z = 0; z < item1.children.length; z++) {
              var itemInside = item1.children[z]
              if (itemInside.children != undefined) {
                region = itemInside.children.find((x) => x.label == cityName)
              }
              if (region) {
                break;
              }
            }
            if (region) {
              break;
            }
          }
          if (region != undefined) {
            this.searchOption.locationName = region.label
            this.searchOption.regionId = region.id
            this.$refs.treeSelect.select(region)
          }

        } else {
          this.showRadius = false
          for (var i = 0; i < this.regionLocations.length; i++) {
            var item1 = this.regionLocations[i]
            var region = item1.children.find((x) => x.label == item.regionName)
            if (region) {
              break;
            }
          }
          //var region = this.regionLocations.find((x) => x.label == item.regionName)
          this.searchOption.locationName = region?.label || null
          this.searchOption.regionId = region?.id || null
          region && this.$refs.treeSelect.select(region)
        }
        this.searchOption.bookingTypeName = item.specialistServiceName
        this.searchOption.startDate = this.validateDate(item.dateStart)
        this.searchOption.endDate = this.validateDate(item.dateEnd)
        this.clickBookingType = true
        $('html, body').animate({
          scrollTop: $(".search-details").offset().top - 200
        }, 500);
      },

      validateDate(selactedDate) {
        var date = moment().format("YYYY-MM-DD")
        if (moment(selactedDate).isSameOrAfter(date)) {
          return selactedDate
        } else {
          return date
        }
      },

      changeRegion(item, e) {
        if (item) {
          if (item.id.includes('s')) {  // Disable select state
            this.$refs.treeSelect.clear()
            var index = this.regionLocations.findIndex(x => x.id == item.id)
            if(index > -1) {
              var mouseEvent = document.createEvent("MouseEvents")
              mouseEvent.initMouseEvent("mousedown", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
              $(".vue-treeselect__option[data-id*='s'] .vue-treeselect__option-arrow-container")[index].dispatchEvent(mouseEvent)
            }
          } else {
            this.$refs.treeSelect.closeMenu()

            if (item.id.includes('r')) {
              this.searchReginId = item.id.replace('r', '')
              this.searchOption.radius = ""
              this.showRadius = false
            } else {
              var object = this.regionLocations.find((x) => x.children.label = item.label)
              this.searchReginId = object.id.replace('r', '');
              this.showRadius = true
            }
            this.searchOption.locationName = item.label
          }
        }
      },

      //
      gotoSearchCase(specialistId) {
        var typeName = this.bookingTypes.find((x) => x.id == this.searchOption.bookingTypeName).name
        var bookingType = this.allBookingTypes.find((x) => x.name.trim() == typeName.trim())
        var bookingTypeId = bookingType != undefined ? bookingType.id : ""
        this.specialist = this.specialists.find((x) => x.id == specialistId)
        var bookingParams = {
          id: this.specialist.id,
          typeId: bookingTypeId,
          typeName: typeName,
          waitlisted: this.isWaitlisted ? true : false,
          endDate: this.searchOption.endDate,
          fillOrder: this.specialist.fillOrder,
          locationId: this.specialist.locationId,
          bookingDate: "",
          bookingTime: "",
          caseId: "",
          bookingId: "",
          availabilityId: "",
          requiresTravel: this.specialist.requiresTravel,
          videoLinkAvailable: this.specialist.videoLinkAvailable,
          regionId: this.searchOption.regionId,
          qualificationId: this.searchOption.specialtyId,
          isSingleId: this.isFromCalendar ? false : true,
          isFromSpecialist: this.isFromSpecialist,
          isSupplementary: true
        }
        this.$store.dispatch("booking/setBookingObject", bookingParams)
        var routeData = this.$router.resolve({ name: 'admin-search-cases-details'})
        if(routeData != undefined && routeData.href != undefined) window.open(routeData.href, "_blank")
      },

      async tapSearch() {
        if (this.searchOption.bookingTypeName == '' || this.searchOption.bookingTypeName == 'ServiceIME' || this.searchOption.bookingTypeName == 'ServiceIMEOrVideo' || this.searchOption.bookingTypeName == 'ServiceIMEShortReport' ||
          this.searchOption.bookingTypeName == 'ServiceIMEVideo' || this.searchOption.bookingTypeName == 'ServiceSupplementaryByVideo' || this.searchOption.bookingTypeName == 'ServiceCourtCase' || this.searchOption.bookingTypeName == 'FCELite' || this.searchOption.bookingTypeName == 'FCELiteByVideo') {
          if (!this.validateBookingType  || !this.validateLocation) {
            this.hasError = true
            return
          }
        } else if (this.searchOption.bookingTypeName == 'ServicePhoneCall') {
          // Validate Phone Call
          if (!this.validateBookingType || !this.validateLocation) {
            this.hasError = true
            return
          }
        } else if ( this.searchOption.bookingTypeName == 'ServiceSupplementary') {
          // Validate Supplementary
          if (!this.validateBookingType || !this.validateLocation) {
            this.hasError = true
            return
          }
        } else if (this.searchOption.bookingTypeName == 'ServiceFileReview') {
          // Validate File Review
          if (!this.validateBookingType || !this.validateLocation) {
            this.hasError = true
            return
          }
        } else if ( this.searchOption.bookingTypeName == 'ServiceMedicalNegligenceRequest') {
          // Validate Medical Negligence Request
          if (!this.validateBookingType || !this.validateLocation) {
            this.hasError = true
            return
          }
        }
        if(!this.validateSpecialist || !this.validateSpecialty) {
          this.hasError = true
          return
        }
        if (this.searchOption.bookingTypeName == 'ServiceMedicalNegligenceRequest' || this.searchOption.bookingTypeName == 'ServiceSupplementary' || this.searchOption.bookingTypeName == 'ServiceFileReview') {
          //Without selecting a availability and the time, go to the search case screen

          if (this.searchOption.specialistId) {
            this.isBookingWithoutAvailability = false
            this.gotoSearchCase(this.searchOption.specialistId)
            return
          } else  {
            this.isBookingWithoutAvailability = true
          }
        }
        this.isBookingWithoutAvailability = false
        this.$store.dispatch('loading/setLoadComponent', true)
        this.searchResult.results = []
        if (this.searchOption.specialistId) {
          this.showCurrentMonth()
        }

        var params = {
          BookingTypeName: this.user.type >=3 ?this.searchOption.bookingTypeName : 'ServiceIMEOrVideo',
          QualificationID: this.searchOption.specialtyId,
          SpecialistID: this.searchOption.specialistId,
          RegionID: '',
          CityID: '',
          Radius: this.searchOption.radius,
          StartDate: this.searchOption.startDate,
          EndDate: this.searchOption.endDate,
          RhoUserID: this.user.id,
          ClientID: this.user.clientId,
          CaseID: this.singleCase.id != '' ? this.singleCase.id : 0
        }

        if (params.StartDate == params.EndDate && !this.isDateChange) {
          if (this.searchOption.bookingTypeName == 'ServiceFileReview' || this.searchOption.bookingTypeName == 'ServiceSupplementary') {
            params.StartDate = moment().format('YYYY-MM-DD')
            params.EndDate = moment()
              .add(1, 'month')
              .format('YYYY-MM-DD')
          } else {
            params.StartDate = moment().format('YYYY-MM-DD')
            params.EndDate = moment()
              .add(2, 'w')
              .format('YYYY-MM-DD')
          }
        }

        params.StartDate = this.validateDate(params.StartDate)
        params.EndDate = this.validateDate(params.EndDate)

        if (this.searchOption.bookingTypeName == 'ServiceSupplementary') {
          var specialist = this.specialists.find((x) => x.id == this.searchOption.specialistId)
          var city = specialist.locations.find((x) => x.id == specialist.practiceLocationId).cityName
          for (var i = 0; i < this.regionLocations.length; i++) {
            var item = this.regionLocations[i].children.find((x) => x.label.toUpperCase() == city.toUpperCase())
            if (item != null) {
              this.searchOption.regionId = item.id
              break
            }
          }
          if (this.searchOption.regionId == null || this.searchOption.regionId == "" ) {
            this.searchOption.regionId = "c1"
          }
        }

        this.searchOption.startDate = params.StartDate
        this.searchOption.endDate = params.EndDate

        if (this.searchOption.regionId != null) {
          if (this.searchOption.regionId.includes('r')) params.RegionID = this.searchOption.regionId.replace('r', '')
          if (this.searchOption.regionId.includes('c')) params.CityID = this.searchOption.regionId.replace('c', '')
        }
        this.$store.dispatch("booking/setBookingObject", '')
        this.availabilities = await utilities.getSearchSpecialistByAvailabilityNew(params)
        this.searchResultOption = _.cloneDeep(this.searchOption)
        this.filterMode = false
        this.showAbuse = false
        this.noFaceToFaceMsg = null
        this.selectedId = null
        this.additionalsearch = false
        if (this.availabilities.additionalsearch.length > 0) {
          await Promise.all(this.availabilities.additionalsearch.map(async (x) => {
            if(x.photo != '') {
              x.profileThumbnail = await utilities.getProfileThumbnail(x.photo)
            }
          }))
          this.filteredList = this.availabilities.additionalsearch
          this.additionalsearch = true
          this.showCalendar = false
          this.showNoSearchResult = false
          this.showSearchResult = false
          this.showAlternative = false
          this.showRelatedProf = false
          this.showSpecialist = false
          this.showSpecialistAvailable = false
          await this.setAvailability()
        } else {
          await Promise.all(this.availabilities.specialists.map(async (x) => {
            if(x.photo != '') {
              x.profileThumbnail = await utilities.getProfileThumbnail(x.photo)
            }
          }))
          await Promise.all(this.availabilities.related.map(async (x) => {
            if(x.photo != '') {
              x.profileThumbnail = await utilities.getProfileThumbnail(x.photo)
            }
          }))
          await Promise.all(this.availabilities.alternate.map(async (x) => {
            if(x.photo != '') {
              x.profileThumbnail = await utilities.getProfileThumbnail(x.photo)
            }
          }))
          await this.setAvailability()
        }

        this.showOnlyAvailable = true
        await this.onChangeFullyBooked(true)
        $('.box-search-result').removeClass('view-hide')
        $('.box-search-history').removeClass('view-hide')
        utilities.initTooltip();
        this.$store.dispatch('loading/setLoadComponent', false)
        this.getSearchLog()
      },

      showCurrentMonth() {
        var currentMonth = moment(this.searchOption.startDate).format('M') - 1
        for (var i = 0; i < currentMonth; i++) {
          setTimeout(() => {
            $('.month-container[data-month-id="' + i + '"]').hide()
          }, 1000)
        }
        for (i = currentMonth + 1; i < 12; i++) {
          setTimeout(() => {
            $('.month-container[data-month-id="' + i + '"]').hide()
          }, 1000)
        }
      },

      async setNextAvailableDateTime(specialists, isSpecialty = true) {
        //We are not checking Available time for following two booking types
        if (this.searchOption.bookingTypeName == 'ServiceFileReview' || this.searchOption.bookingTypeName == 'ServiceSupplementary') {
          return
        }

        if ((this.searchOption.specialtyId && isSpecialty) || (!this.searchOption.specialtyId && !this.searchOption.specialistId) || this.searchOption.specialistId) {
          var availabilities = []
          specialists.map((x) => {return x.specialistAvailabilities}).map(x => {
            availabilities = [...availabilities, ...x]
          })
          availabilities = availabilities.filter((x) => (x.availabilityStatus !== 1))
          if(availabilities == undefined) return false

          this.searchResult.availabilities = availabilities
          if(this.searchResult.availabilities[0] != undefined) this.searchResult.calendarDate = this.searchResult.availabilities[0].availabilityDate

          this.dataSource = availabilities
          this.isDisplayOnly = true
          const resultArr = specialists.length > 0 ? specialists.map(item => {
            let num = 0
            item.specialistAvailabilities.forEach(i => {
              const arr = i.Availabilities.filter(b => {
                return b.BookingStatus === 'A'
              })
              num = num + arr.length
            })
            return {
              specialistId: item.id,
              fullName: item.fullName,
              numberOfAvailable: num
            }
          }) : []
          this.searchResult.results = resultArr
          if(this.searchResult.results.length == 0 && this.searchOption.specialistId != '') {
            var specialist = this.specialists.find(x => x.id === this.searchOption.specialistId)
            if(specialist != undefined) {
              this.searchResult.results.push(specialist.fullName + ' (0)')
              this.searchResult.specialists.push({ specialistId: specialist.id, specialistName: specialist.fullName, numberTimeSlotsBooked: 0, numberTimeSlots: 0 })
            }
          }
          this.specialistIds = this.searchResult.specialists.map(x => x.specialistId)
          this.calendarKey += 1
        }
      },

      tapWaitList(e) {
        this.isWaitlisted = true
        this.isFromCalendar = true
        this.waitlistTime = e.startTime
        this.specialist.locationId = e.location
        this.specialist.bookingDate = moment(e.date).format('YYYY-MM-DD')
        this.specialist.specialistAvailabilityId = e.availabilityId
        if(this.specialist.id == undefined) this.specialist.id = e.specialistId
        this.tapBooking(0, "", false, e)
      },

      tapAvailable(e) {
        this.isWaitlisted = false
        this.isFromCalendar = true
        this.specialist.locationId = e.location,
        this.specialist.bookingDate = moment(e.date).format('YYYY-MM-DD')
        this.specialist.specialistAvailabilityId = e.availabilityId
        this.tapBooking(0, "", false, e)
      },

      tapAvailableSlot(e, item) {
        this.isWaitlisted = false
        if(e.status !== 'fully booked' && item.BookingStatus !== 'A') return
        if(e.status === 'fully booked') {
          this.isWaitlisted = true
          this.waitlistTime = item.StartTime
        }
        if(item.Options.ByRequestOnly) {
          this.tapSubmitRequest(e.specialistId)
        }
        this.specialist.id = e.specialistId
        this.specialist.bookingDate = moment(e.date).format('YYYY-MM-DD')
        this.specialist.bookingTime = item.StartTime
        this.specialist.specialistAvailabilityId = item.AvailabilityID
        this.tapBooking(0, "", false, item)
      },

      async setAvailability(list) {
        if(list && list.length > 0) {
          this.searchResult.results = []
          await this.setNextAvailableDateTime(list)
          return
        } else if (list && list.length === 0) {
          this.searchResult.results = []
          this.dataSource = []
          return
        }
        this.showNoSearchResult = false
        this.showSearchResult = false
        this.showAlternative = false
        this.showRelatedProf = false
        this.showSpecialist = false
        this.showSpecialistAvailable = false
        this.showNoSearchResultForSpecialist = false
        if (this.availabilities.specialists.length > 0) {
          this.filteredList = this.availabilities.specialists
          this.showCalendar = true
            if (this.availabilities.specialists.length > 0) {
              this.showSearchResult = true
              await this.setNextAvailableDateTime(this.availabilities.specialists, this.searchOption.specialtyId)
              // let arr = []
              // this.availabilities.specialists.forEach(item => {
              //   if(item.specialistAvailabilities && item.specialistAvailabilities.length > 0) {
              //     arr = arr.concat(item.specialistAvailabilities)
              //   }
              // })
              // this.showSearchResult = false
              // this.dataSource = arr
              if(this.searchOption.specialistId) {
                this.specialist = this.availabilities.specialists[0]
              }
            } else {
              if (this.searchOption.specialistId != '') {
                await this.getSpecialistDetails()
              } else {
                this.showNoSearchResult = true
              }

            }

        }  else if (this.availabilities.alternate.length > 0) {
          this.filteredList = this.availabilities.alternate
          this.showCalendar = false
          this.showNoSearchResult = true
          if (this.availabilities.alternate.length == 1 && this.availabilities.alternate[0].id == this.searchOption.specialistId) {
            await this.getSpecialistDetails()
          } else {
            await this.setNextAvailableDateTime(this.availabilities.alternate, this.searchOption.specialtyId !== '')
            //Comented sort funtion
            // this.availabilities.alternate.sort((a, b) => {
            //   return a.bookingDate > b.bookingDate ? 1 : -1
            // })
            if (this.availabilities.alternate.length > 0) {
              this.showAlternative = true
              this.showSearchResult = true
            }
          }
        } else if (this.availabilities.related.length > 0) {
          this.filteredList = this.availabilities.related
          this.showCalendar = false
          await this.setNextAvailableDateTime(this.availabilities.related, this.searchOption.specialtyId !== '')
          //if there is available time solt dissplay the result
          this.showRelatedProf = true
          this.showNoSearchResult = false
          this.showSearchResult = true
        } else if (this.availabilities.additionalsearch.length > 0) {
          this.filteredList = this.availabilities.additionalsearch
          this.showNoSearchResult = false
          this.showCalendar = false
          await this.setNextAvailableDateTime(this.availabilities.additionalsearch, this.searchOption.specialtyId !== '')
        } else {
          this.filteredList = []
          this.showSpecialistAvailable = false
          this.showNoSearchResult = true
          if (this.searchOption.specialistId && this.availabilities.specialists.length === 0) {
            await this.getSpecialistDetails()
          } else {
            this.showNoSearchResult = true
          }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },

      //get Year calender Data by SpecialistID. When user click View Calander
      async getSpecialistDetails(specialistId = -1, specialistName = "") {
        var params = {}
        if (specialistId != -1) {
          let viewSpecialist
          // if(this.availabilities.specialists.length > 0)  viewSpecialist = this.availabilities.specialists ? _.cloneDeep(this.availabilities.specialists.find(item => item.id === specialistId)) : null
          // if(this.availabilities.additionalsearch.length > 0)  viewSpecialist = this.availabilities.additionalsearch ? _.cloneDeep(this.availabilities.additionalsearch.find(item => item.id === specialistId)) : null
          // if(this.availabilities.related.length > 0)  viewSpecialist = this.availabilities.related ? _.cloneDeep(this.availabilities.related.find(item => item.id === specialistId)) : null
          // if(this.availabilities.alternate.length > 0)  viewSpecialist = this.availabilities.alternate ? _.cloneDeep(this.availabilities.alternate.find(item => item.id === specialistId)) : null
          viewSpecialist = _.cloneDeep(this.filteredList.find(item => item.id === specialistId))
          if(viewSpecialist && viewSpecialist.specialistAvailabilities && viewSpecialist.specialistAvailabilities.length > 0) {
            viewSpecialist.specialistAvailabilities = viewSpecialist.specialistAvailabilities.filter(item => (item.availabilityStatus !== 1))
            this.showSearchResult = false

            this.dataSource = viewSpecialist.specialistAvailabilities
            this.specialist = _.cloneDeep(viewSpecialist)
            this.calendarKey += 1
            return
          } else {
            this.$store.dispatch('loading/setLoadComponent', true)
            var items = []
            items = this.spLocations.filter((x) =>x.specialistId == specialistId)
            var locationIds = '0'
            if (items && !this.searchOption.videoLink) {
              items.map((i) => {
                if (locationIds == '0') {
                  locationIds = i.locationId.toString()
                } else {
                  if (!locationIds.includes(i.locationId)) {
                    locationIds = locationIds + ',' + locationIds
                  }
                }
              })
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        } else {
          this.$store.dispatch('loading/setLoadComponent', true)
          params = {
            SpecialistID: specialistId,
            StartDate: moment(this.searchOption.startDate).format('YYYY-MM-DD'),
            EndDate: moment(this.searchOption.startDate).add(12, 'M').format('YYYY-MM-DD'),
            LocationID: 0,
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }

        if (specialistId == -1) {
          if (this.searchOption.bookingTypeName == 'ServiceSupplementary') {
            this.showSpecialist = true
          }
        }

        this.calendarKey += 1
        if (specialistId != -1) {
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },

      getSearchOption(specialtyId, specialistId) {
        var value = ''
        if (this.specialties.length > 0) {
          if (specialtyId != undefined && specialtyId != '') {
            value = this.specialties.find((x) => x.id == specialtyId).name
          } else if (specialistId != undefined && specialistId != '') {
            value = this.specialists.find((x) => x.id == specialistId).fullName
          }
        }
        return value
      },

      formatDate(dateOfBirth) {
        return utilities.formatDate(dateOfBirth, 'DD/MM/YYYY')
      },

      getYear() {
        return moment().format('YYYY')
      },

      getClaimantTypeName(id) {
        var type = ''
        if (id && this.claimTypes.length > 0) {
          type = this.claimTypes.find((x) => x.id == id).name
        }
        return type
      },

      onChangeSpecialty() {
        this.searchOption.specialistId = ""
        this.searchOption.bookingTypeName = ""
        this.filteredBookingTypes = this.bookingTypes.filter(item => item.id !== 'ServiceFileReview' && item.id !== 'ServiceMedicalNegligenceRequest' && item.id !== 'ServiceSupplementary')
      },

      onChangeSpecialist(v) {
        this.searchOption.specialtyId = ""
        const obj = this.specialists.find(item => item.id === v)
        this.filteredBookingTypes = this.bookingTypes.filter(item => obj[item.field] || obj[item?.field2])
      },

      onChangeBookingType() {
        if (this.searchOption.bookingTypeName === 'ServiceMedicalNegligenceRequest' || this.searchOption.bookingTypeName === 'ServiceSupplementary' || this.searchOption.bookingTypeName === 'ServiceFileReview') {
          this.isHideSpecialty = true
        }
      },

      handleClickBookingType() {
        this.clickBookingType = true
      },

      handleClickSpecialty() {
        this.clickSpecialty = true
        // this.clickSpecialist = false
        // this.searchOption.specialistId = ''
      },

      handleClickSpecialist() {
        this.clickSpecialist = true
        // this.clickSpecialty = false
        // this.searchOption.specialtyId = ''
      },

      changeDateRange(item) {
        var startDate = utilities.formatDate(item[0])
        var endDate = utilities.formatDate(item[1])
        var todayDate = moment().format('YYYY-MM-DD')
        this.invalidDate = false
        if (moment(endDate).isBefore(todayDate)) {
          this.invalidDate = true
        } else {
          this.isDateChange = true
          this.searchOption.startDate = startDate
          this.searchOption.endDate = endDate
        }
      },

      async tapReadMore(index) {
        await this.$refs.profileReadMoreModal.loadData(index)
        this.$refs.profileReadMoreModal.handleModal()
      },

      tapSubmitRequest(specialistId = undefined) {
        if (this.user.type >= 3) {
          this.$router.push({ path: '/admin-manage-booking-request', query: { spId: specialistId} }).catch((err) => {})
        } else {
          if (this.showCaseInfo) {
            this.$router.push({ path: '/booking-request', query: { id: this.singleCase.id, spId: specialistId} }).catch((err) => {})
          } else {
            if (specialistId) {
              this.$router.push({ path: '/booking-request', query: { spId: specialistId} }).catch((err) => {})
            } else {
              this.$router.push({ path: '/booking-request', query: { isEmpty: true} }).catch((err) => {})
            }
          }
        }
      },

      setSpecialistData(id) {
        var selectedSpecialist = this.originalListSpecialists.find((x) => x.id == id)
        if (selectedSpecialist != undefined) {
          $('.select-dropdown input').val(selectedSpecialist.name)
          setTimeout(() =>{
            if(this.$refs.specialistDropdown) this.$refs.specialistDropdown.searchFilter = selectedSpecialist.name
          }, 500)
        }
      },

      setSearchInfo() {
        this.clickBookingType = true
        this.clickSpecialist = true
        var bookingTypeName = ''
        if (this.bookingTypeId != '') {
          bookingTypeName = this.allBookingTypes.find((x) => x.id == this.bookingTypeId).name
        } else {
          bookingTypeName = this.allBookingTypes.find((x) => x.id == this.booking.bookingTypeId).name
        }
        var bookingType = this.bookingTypes.find((x) => x.name == bookingTypeName)
        if (bookingType) {
          this.searchOption.bookingTypeName = bookingType.id
        }

        if (this.booking.id) {
          this.searchOption.specialistId = this.booking.specialistId
          this.setSpecialistData(this.searchOption.specialistId)
        }

        var location = this.locations.find((x) => x.id == this.booking.locationId)
        if (location != undefined) {
          var regionId = 'r' + location.regionId
          var cityId = 'c' + location.cityId
          var region = this.regionLocations.find((x) => x.id == regionId)
          if (region != undefined) {
            var city = region.children.find((x) => x.id == cityId)
            if (city != undefined) {
              this.$refs.treeSelect.select(city)
            } else {
              this.$refs.treeSelect.select(region)
            }
          }
        }

        // Set search of related service
        if (this.specialty != '') {
          this.searchOption.specialtyId = this.specialty
          this.searchOption.specialistId = ''
          this.clickSpecialty = true
          this.clickSpecialist = false
          this.booking.id = ''
        } else {
          // this.searchOption.startDate = utilities.formatDate(this.booking.bookingDateTime)
          // this.searchOption.endDate = utilities.formatDate(this.booking.bookingDateTime)
        }
      },

      async getSearchLog() {
        var params = {
          PageNum: 1,
          PageSize: 20,
        }
        this.allSearchLog = []
        this.searchLogs = []
        utilities.getSearchLogsNew(params).then(r => {
          this.allSearchLog = r
          let length = this.allSearchLog.length >= 5 ? 5 : this.allSearchLog.length
          for (let i = 0; i < length; i++) {
            this.searchLogs.push(this.allSearchLog[i])
          }
        })
      },

      async getMinimumAppointmentLength(specialtyId) {
        if ( this.availabilities.specialists.length > 0) {
          var minimumAppointment = this.availabilities.specialists.find((x) => x.id == specialtyId).specialistMinimumAppiontmentTime[0]
          var typeName = this.bookingTypes.find((x) => x.id == this.searchOption.bookingTypeName).name
          if (typeName == "Independent Medical Examination") {
            this.minimumAppointmentLength = minimumAppointment.independentMedicalAssessment
          } else if (typeName == "Independent Medical Examination - Abuse") {
            this.minimumAppointmentLength = minimumAppointment.independentMedicalAssessmentAbuse
          } else if (typeName == "Phone Call") {
            this.minimumAppointmentLength = minimumAppointment.phoneCall
          } else if (typeName == "Independent Medical Examination by Video") {
            this.minimumAppointmentLength = minimumAppointment.independentMedicalAssessmentVideo
          } else {
            this.minimumAppointmentLength = minimumAppointment.independentMedicalAssessment
          }
          return this.minimumAppointmentLength
        }
        return 60
      },

      async loadData() {
        if (this.singleCase.caseId) {
          this.singleCase = await utilities.getCaseByCaseId(this.singleCase.caseId)
        }
        const apiArr = [utilities.getClaimTypes(), utilities.getBookingTypes(), utilities.getSpecialists(), utilities.getQualifications(), utilities.getLocations()]
        const r = await Promise.all(apiArr)
        this.claimTypes = r[0]
        await this.getSearchLog()
        this.allBookingTypes = r[1]
        if (this.user.type < 3) {
          var temp = []
          this.bookingTypes.find((x) => {
            if (x.isClint) {
              temp.push(x)
            }
          })
          this.bookingTypes = temp
        }
        this.bookingTypes = this.bookingTypes.filter(item => item.id !== 'ServiceIMEAbuse' && item.isInCreateBooking)
        this.filteredBookingTypes = this.bookingTypes
        let specialists = r[2]
        if(this.user.type >= 3) {
          this.specialists = specialists
        } else {
          this.specialists = specialists.filter((x) => x.specialistStatus)
        }
        this.originalListSpecialists = _.cloneDeep(utilities.getDropdownSpecialist(this.specialists))
        this.listSpecialists = this.originalListSpecialists

        this.specialties = r[3]
        //Ticket #5425 - To improved the page load, saved regionLocations object into the local store.
        // this.regions = await utilities.getRegions()
        this.locations = r[4]
        // this.regionLocations = utilities.getRegionLocations(this.regions, this.locations, isclient)
        this.regionLocations = this.$store.getters["location/getLocationData"]
        if (!this.regionLocations) {
          let regions = await utilities.getRegions()
          let locations = await utilities.getLocations()
          this.regionLocations = utilities.getRegionLocations(regions, locations, true)
        }

        if (this.booking.id) {
          this.booking = await utilities.getBookingById(this.booking.id)
          if (this.booking == undefined) {
            this.showContent = false
            return
          }
          if (this.singleCase.caseId == '') {
            this.singleCase = await utilities.getCaseByCaseId(this.booking.caseId)
            this.showCaseInfo = true
          }
          this.setSearchInfo()
        } else if (this.searchOption.specialistId != '') {
          this.clickSpecialist = true
        }
      },

      async setBookingRequest() {
        var bookingRequest = await utilities.getBookingRequestById(this.bookingRequestId)
        this.bookingRequest = bookingRequest
        this.disabledBookingType = false
        if (bookingRequest.bookingTypeId != '') {
          //TICKET - #4915
          if (bookingRequest.bookingTypeName == "FCE Lite" || bookingRequest.bookingTypeName == "FCE Lite by Video") {
            var fceLite = {
              id: "FCELite",
              name: "FCE Lite",
              isClint: false
            }
            var fceLiteVideo = {
              id: "FCELiteByVideo",
              name: "FCE Lite by Video",
              isClint: false
            }
            this.bookingTypes.push(fceLite)
            this.bookingTypes.push(fceLiteVideo)
            if(this.user.type < 6)this.disabledBookingType = true
          }

          var bookingTypeName = this.allBookingTypes.find((x) => x.id == bookingRequest.bookingTypeId).name
          var bookingType = this.bookingTypes.find((x) => (x.name === bookingTypeName || x.otherName === bookingTypeName))

          if (bookingType) {
            this.searchOption.bookingTypeName = bookingType.id
          }
        }
        this.clickBookingType = true
        var regionId = 'r' + bookingRequest.regionId
        this.regionLocations.map((x) => {
          if (x.children) {
            var city = x.children.find((y) => y.id == regionId)
          }
          if (city != undefined) {
            this.$refs.treeSelect.select(city)
          }
        })

        if (bookingRequest.dateFrom) {
          this.searchOption.startDate = utilities.formatDate(bookingRequest.dateFrom)
        }

        if (bookingRequest.dateTo) {
          this.searchOption.endDate = utilities.formatDate(bookingRequest.dateTo)
        }
        // this.searchOption.startDate = utilities.formatDate(bookingRequest.dateFrom)
        // this.searchOption.endDate = utilities.formatDate(bookingRequest.dateTo)
        if (bookingRequest.specialistId == '') {
          this.isHideSpecialty = false
          var item = this.specialties.find((x) => x.name === bookingRequest.qualificationList)
          this.searchOption.specialtyId = item.id
        } else {
          var specialist = this.listSpecialists.find((x) => x.id === bookingRequest.specialistId)
          if (specialist) {
            this.isHideSpecialty = true
            this.searchOption.specialistId = bookingRequest.specialistId
          } else {
            this.isHideSpecialty = false
            this.searchOption.specialistId = ''
          }
        }

        if (this.searchOption.bookingTypeName == 'ServiceSupplementary') {
          this.isHideSpecialty = true
        }

        if (this.searchOption.bookingTypeName == 'ServiceFileReview' || this.searchOption.bookingTypeName == 'ServicePhoneCall') {
          this.showAll = false
          this.showDateRange = true
        } else {
          this.showAll = true
          this.showDateRange = true
        }

        if (this.searchOption.bookingTypeName == 'ServiceFileReview' || this.searchOption.bookingTypeName == 'ServiceSupplementary') {
          this.showAll = false
          this.showDateRange = false
        }

        if (this.searchOption.bookingTypeName == 'ServiceIME' || this.searchOption.bookingTypeName == 'ServiceIMEAbuse') {
          this.showRadius = true
        } else {
          this.showRadius = false
        }
      },
    },

    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.singleCase.caseId = this.$route.query.id != undefined ? this.$route.query.id : ''
      this.searchOption.specialistId = this.$route.query.specialistId != undefined ? this.$route.query.specialistId : ''
      this.clientId = this.$route.query.clientId != undefined ? this.$route.query.clientId : ''

      // Get query for search of related service
      this.booking.id = this.$route.query.bookingId != undefined ? this.$route.query.bookingId : ''
      this.specialty = this.$route.query.specialty != undefined ? this.$route.query.specialty : ''
      this.bookingTypeId = this.$route.query.bookingTypeId != undefined ? this.$route.query.bookingTypeId : ''

      if (this.singleCase.caseId != '') {
        this.showCaseInfo = true
      } else {
        this.showCaseInfo = false
      }
      await this.loadData()
      this.bookingRequestId = this.$route.query.requestId != undefined ? this.$route.query.requestId : ''
      if (this.bookingRequestId) {
        await this.setBookingRequest()
      }
      this.searchOption.specialtyId = this.specialty ? Number(this.specialty) : ''
      if(this.searchOption.specialistId) {
        this.isHideSpecialty = true
      }
      this.$store.dispatch('loading/setLoadComponent', false)
      this.showCurrentMonth()

      //hide radius drop down when client is login
      if (this.user.type < 3) {
        this.showRadius = false
      }
    },

    mounted() {
      this.$store.dispatch('booking/setBookingObject', null)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  }
</script>
