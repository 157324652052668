import Vue from 'vue'
import Vuex from "vuex"
import App from './App.vue'
import 'popper.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/style.css'
import 'jquery-confirm'
import 'jquery-confirm/dist/jquery-confirm.min.css'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'vuetify/dist/vuetify.min.js'
import '@mdi/font/css/materialdesignicons.css'
import { CircleSpinner } from 'vue-spinners'
import ToggleButton from 'vue-js-toggle-button'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueRadioToggleButtons from 'vue-radio-toggle-buttons'
import 'vue-radio-toggle-buttons/dist/vue-radio-toggle-buttons.css'
import VueTagsInput from '@johmun/vue-tags-input'
import Datepicker from './assets/js/vuejs-datepicker/dist/vuejs-datepicker.min.js'
import locale from 'element-ui/lib/locale/lang/en'
import moment from 'moment'
import VueSuggestion from 'vue-suggestion'
import Calendar from 'v-year-calendar'
import VMdDateRangePicker from 'v-md-date-range-picker'
import { search } from 'ss-search'
import JsonCSV from 'vue-json-csv'
import VueHtml2pdf from 'vue-html2pdf'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ICS from "vue-ics"
import Vue2Editor from "vue2-editor"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueQrcode from 'vue-qrcode'
import Message from '@/components/dialog/DialogUtil'


window.$ = require('jquery')
window._ = require('lodash')
window.autosize = require('autosize')
var Moment = require('moment')
Moment = require('moment-timezone')
var MomentRange = require('moment-range')
window.moment = MomentRange.extendMoment(Moment)
window.search = search
window.authenticator = require('authenticator')


Vue.use(Vuex)
Vue.use(Vuetify)
Vue.use(ToggleButton)
Vue.use(VueRadioToggleButtons)
Vue.use(VueTagsInput)
Vue.use(VueSuggestion)
Vue.use(VMdDateRangePicker)
Vue.use(VueHtml2pdf)
Vue.use(ICS)
Vue.use(ElementUI, { locale })
Vue.use(Vue2Editor)
Vue.prototype.$confirm = Message;
// Vue.use(Dropdown);

Vue.prototype.$_ = window._



Vue.component('circle-spin', CircleSpinner)
Vue.component('datepicker', Datepicker)
Vue.component('vue-timepicker', VueTimepicker)
Vue.component('calendar', Calendar)
Vue.component('downloadCsv', JsonCSV)
Vue.component('treeselect', Treeselect)
// Vue.component('barcode', VueBarcode)
Vue.component('vue-qrcode', VueQrcode)

// Set the locale to have dates in DD/MM/YYYY
moment.locale('en-AU')

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
  }),
}).$mount('#app')
