/* eslint-disable */
<template>
  <div class="cases">
    <AdminNavigation ref="navigation" indexProps="2"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Ownership Change Requests ({{ totalCount }})</h1></div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu :key="5" :indexProps="5"/>
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div>
              <div class="d-flex align-items-center justify-content-end w-100 flex-wrap">
                <el-input style="max-width: 130px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-table  ref="bookingTable"
                    :default-sort="{prop: 'id', order: 'descending'}" stripe @sort-change="sortParam" :data="allCases"
                    tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}"
                    :header-cell-style="{fontWeight: 100}">
            <el-table-column
                width="100"
                prop="CaseOwnerRequestID"
                label="ID"
                show-overflow-tooltip
                fixed="left"
            >
            </el-table-column>
            <el-table-column
                width="200"
                show-overflow-tooltip
                prop="Client"
                label="Client"
            >
              <template slot-scope="scope">
                    <span>{{decodeURIComponent(scope.row.Client.ClientName)}}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                show-overflow-tooltip
                prop="OriginalClientContact"
                label="Current Case Owner"
            >
              <template slot-scope="scope">
                    <span>{{getContactFullName(scope.row.OriginalClientContact)}}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                show-overflow-tooltip
                prop="nextBookingDate"
                label="New Case Owner"
            >
              <template slot-scope="scope">
                <span>{{getContactFullName(scope.row.CurrentClientContactID)}}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                show-overflow-tooltip
                prop="CreatedTime"
                label="Submit Time"
            >
              <template slot-scope="scope">
                <span>{{formatDate(scope.row.LastUpdatedDate, "hh:mm A DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss")}}</span>
              </template>
            </el-table-column>
            <el-table-column
                sortable="custom"
                show-overflow-tooltip
                prop="Status"
                label="Status"
            >
              <template slot-scope="scope">
                <span>{{ getStatusByName(scope.row.Status) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                show-overflow-tooltip
                prop="Status"
                label="Action"
            >
              <template slot-scope="scope">
                <el-button size="mini" type="primary" v-if="scope.row.Status === 1" @click="approve(scope.row)">Approve</el-button>
                <el-button size="mini" v-if="scope.row.Status === 1" type="text" @click="reject(scope.row)" dark>Reject</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" :key="item"
                     @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import Header from '@/components/Header'
import AdminClientsMenu from '@/components/AdminClientsMenu'
import {debounce} from 'lodash'
import moment from "moment";

export default {
  name: 'AdminCases',
  props: ['searchOptionProps'],
  components: {
    AdminNavigation,
    Header,
    AdminClientsMenu
  },
  data() {
    return {
      dateValue: "",
      fetchedData: false,
      hasError: false,
      successMessage: '',
      errorMessage: '',
      noCaseMessage: 'No data found.',
      totalCount: 0,
      client: utilities.initClient(),
      cases: [],
      allCases: [],
      limit: 10,
      pagination: utilities.initPagination(),
      listParam: {
        PageNum: 1,
        PageSize: 10,
        ClientName: '',
      },
      byName: false,
      byType: false,
      byDate: false,
      byOwner: false,
      user: _.cloneDeep(this.$store.getters['user/getUser']),
    }
  },
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),

    approve(item) {
      this.$confirm({
        content: 'Are you sure you want to approve this request?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          const param = {
            CaseOwnerRequestID: item.CaseOwnerRequestID,
            Status: 2,
            UserID: this.user.id,
            Username: this.user.name
          }
          try {
            await utilities.putOwnershipRequest(param)
            this.$notify({
              dangerouslyUseHTMLString: true,
              message: '<div class="alert-success">' +
                  '            <b>Success:</b> Request approved !' +
                  '          </div>',
            });
            await this.loadData()
          } catch (e) {
            console.log(e)
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },
    reject(item) {
      this.$confirm({
        content: 'Are you sure you want to reject this request?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          const param = {
            CaseOwnerRequestID: item.CaseOwnerRequestID,
            Status: 3,
            UserID: this.user.id,
            Username: this.user.name
          }
          try {
            await utilities.putOwnershipRequest(param)
            this.$notify({
              dangerouslyUseHTMLString: true,
              message: '<div class="alert-success">' +
                  '            <b>Success:</b> Request rejected !' +
                  '          </div>',
            });
            await this.loadData()
          } catch (e) {
            console.log(e)
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    getContactFullName(contact) {
      var fullName = decodeURIComponent(contact.FirstName) + (contact.MiddleName != '' ? ' ' + decodeURIComponent(contact.MiddleName) : '') + " " + decodeURIComponent(contact.Surname)
      return fullName.trim()
    },

    getStatusByName(status) {
      if(status === 1) {
        return 'Open'
      } else if (status === 2) {
        return 'Approved'
      } else {
        return 'Rejected'
      }
    },

    changeDates(v) {
      if (v) {
        this.listParam.FromDate = v[0]
        this.listParam.ToDate = v[1]
      } else {
        this.listParam.FromDate = null
        this.listParam.ToDate = null
      }
      this.selectPage(1)
    },
    cancelSearch() {
      this.listParam.ClientName = ""
      this.listParam.ClientID = ""
      this.listParam.CaseOwnerName = ""
      this.listParam.ClaimantName = ""
      this.listParam.BranchName = ""
      this.listParam.CaseStatus = null
      this.listParam.FromDate = null
      this.listParam.ToDate = null
      this.listParam.CaseID = ""
      this.listParam.ClaimantSurName = ""
      this.listParam.ClaimantFirstName = ""
      this.dateValue = ""
      this.listParam.ReferenceNumber = ''
      this.client = utilities.initClient()
      window.history.replaceState({}, document.title, location.pathname)
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },
    sortParam(v) {
      if (v.prop === 'id' && v.order === "ascending") {
        this.listParam.SortFlag = 16
      } else if (v.prop === 'id' && v.order === "descending") {
        this.listParam.SortFlag = 15
      } else if (v.prop === 'caseOwnerName' && v.order === "ascending") {
        this.listParam.SortFlag = 6
      } else if (v.prop === 'caseOwnerName' && v.order === "descending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === 'claimantFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 8
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.listParam.SortFlag = 7
      } else if (v.prop === 'clientBranchName' && v.order === "ascending") {
        this.listParam.SortFlag = 4
      } else if (v.prop === 'clientBranchName' && v.order === "descending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'caseReference' && v.order === "ascending") {
        this.listParam.SortFlag = 2
      } else if (v.prop === 'caseReference' && v.order === "descending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'claimTypeName' && v.order === "ascending") {
        this.listParam.SortFlag = 10
      } else if (v.prop === 'claimTypeName' && v.order === "descending") {
        this.listParam.SortFlag = 9
      }
      this.selectPage(1)
    },
    formatDate(date, newFormat = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      var newDate = ""
      if(date != "") {
        newDate = moment(date, currentFormat).format(newFormat)
      }
      return newDate
    },
    openSearchModal() {
      this.$refs.adminSearchCaseModal.handleModal()
    },
    openSearchClaimant() {
      this.$refs.navigation.$refs.globalSearch.openSearchClaimant()
    },

    openCase(id) {
      this.$router.push({name: 'admin-manage-case', query: {id: id}})
    },

    async loadData() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {data, total} = await utilities.getOwnershipRequest(this.listParam)
      this.allCases = data
      this.totalCount = total
      this.$store.dispatch('loading/setLoadComponent', false)
    },
  },
  async beforeMount() {
    await this.loadData()
  }
}
</script>
