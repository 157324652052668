<template>
  <div class="add-airline-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Add New Airline</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Airline Name</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="airline.name" placeholder="Airline Name" :class="hasError && !validateName ? 'is-invalid' : ''"/>
                    <div v-if="hasError && !validateName" class="text-danger">Name is required.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Contact Phone</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="airline.phone" placeholder="Contact Number" :class="hasError && !validatePhone ? 'is-invalid' : ''"/>
                    <div v-if="hasError && !validatePhone" class="text-danger">Phone is required.</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddAirlineModal',
    data() {
      return {
        loadModal: false,
        hasError: false,
        user: _.cloneDeep(this.$store.getters["user/getUser"]),
        airline: {
          name: '',
          phone: ''
        },
        index: -1,
      }
    },
    computed: {
      validateName() {
        if(this.airline.name == '') {
          return false
        }
        return true
      },
      validatePhone() {
        if(this.airline.phone == '') {
          return false
        }
        return true
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      reset(index = -1) {
        this.airline = {
          name: '',
          phone: ''
        }
        this.hasError = false
        this.index = index
      },
      async callSave() {
        if (!this.validateName || !this.validatePhone) {
          this.hasError = true
          return
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          AirlineName: this.airline.name,
          ContactPhone: this.airline.phone,
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name
        }
        var airlineId = await utilities.postNewAirline(params)
        if(airlineId > 0) {
          var airlines = await utilities.getAirlines()
          this.$parent.airlines = _.cloneDeep(airlines)
          this.$parent.originalListAirlines = _.cloneDeep(utilities.getDropdownAirline(airlines))
          this.$parent.listAirlines = _.cloneDeep(this.$parent.originalListAirlines)
          this.$parent.updateListAirlinesArray()
          this.$parent.airlineKey += 1
          if(this.index > -1) {
            this.$parent.travelFlights[this.index].airlineId = airlineId
            setTimeout(() => {
              this.$parent.setNewAirline(airlineId, this.index)
            }, 500)
          }
          this.handleModal()
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    mounted() {},
  }
</script>
