import Vue from "vue";
import Notification from "./ConfirmDialog.vue";

const NotificationConstructor = Vue.extend(Notification);

const instances = [];
let seed = 1;

const removeInstance = (instance) => {
    if (!instance) return;
    const len = instances.length;
    const index = instances.findIndex((ins) => instance.id === ins.id);

    instances.splice(index, 1);

    if (len <= 1) return;
    const removeHeight = instance.height;
    for (let i = index; i < len - 1; i++) {
        instances[i].verticalOffset =
            parseInt(instances[i].verticalOffset) - removeHeight - 16;
    }
};

const notify = (options = {}) => {
    if (Vue.prototype.$isServer) return;
    // 获取vue实例
    let instance = new NotificationConstructor({
        propsData: options, // 这里是传进来一组props
        data() {
            return {
                verticalOffset: 0,
                timer: null,
                visible: false,
                height: 0,
                loading: false
            };
        },
        methods: {
            cancel() {
                this.visible = false;
                document.body.removeChild(this.$el);
                removeInstance(this);
                this.$destroy(true);
            },
            async confirm() {
                this.loading = true
                options.onConfirm && await options.onConfirm()
                this.loading = false
                this.visible = false;
                document.body.removeChild(this.$el);
                removeInstance(this);
                this.$destroy(true);
            },
            handleAfterEnter() {
                this.height = this.$el.offsetHeight;
            },
        },
    });

    const id = `notification_${seed++}`; // generate id
    instance.id = id;
    instance = instance.$mount();
    document.body.appendChild(instance.$el);
    instance.visible = true;

    let verticalOffset = 0;

    instances.forEach((item) => {
        verticalOffset += item.$el.offsetHeight + 16;
    });

    verticalOffset += 16;
    instance.verticalOffset = verticalOffset;

    instances.push(instance);

    return instance;
};

export default notify;