/* eslint-disable */
<template>
  <div class="view-search-log">
    <AdminNavigation ref="navigation" indexProps="0"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>View Search Log</h1></div>
        <div class="header-sub-title">The following searches for searchLog, which did not result in an appointment, have
          been conducted recently.
        </div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <v-btn dark @click="exportToCsv">Export</v-btn>
              <el-date-picker
                  style="width: 260px"
                  v-model="dateValue"
                  type="daterange"
                  start-placeholder="Start Date"
                  popper-class="date-range-picker-popper"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  @change="changeDates"
                  end-placeholder="End Date"
                  clearable
              />
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <el-table ref="bookingTable" stripe  :data="allSearchLogs" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    width="150"
                    prop="userName"
                    label="Contact"
                    show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <router-link
                        :to="{ name: 'admin-manage-client', query: { id: scope.row.clientId, type: 'edit-contact' } }">
                      <div class="long-text" data-placement="right" data-toggle="tooltip" :title="scope.row.userName">
                        <span>{{ scope.row.userName }}</span>
                      </div>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    prop="actionedBy"
                    label="Client"
                    show-overflow-tooltip
                ><template slot-scope="scope">
                  <router-link
                      :to="{ name: 'admin-manage-client', query: { id: scope.row.clientId, type: 'edit-contact' } }">
                    <div class="long-text" data-placement="right" data-toggle="tooltip" :title="scope.row.userName">
                      <span>{{ scope.row.clientName }}</span>
                    </div>
                  </router-link>
                </template>
                </el-table-column>
              <el-table-column
                  width="100"
                  prop="caseId"
                  label="Case ID"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="120"
                  prop="numberofResults"
                  label="No. Results"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="180"
                  prop="dateSearched"
                  label="Search Date"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                      formatDate(scope.row.dateSearched, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss")
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="180"
                  prop="dateStart"
                  label="Booking Date Range"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                      formatDate(scope.row.dateStart, 'DD/MM/YYYY', "YYYY-MM-DD HH:mm:ss.ssssss") + ' - ' + formatDate(scope.row.dateEnd, 'DD/MM/YYYY', "YYYY-MM-DD HH:mm:ss.ssssss")
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="fullLocation"
                  label="Location"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="180"
                  prop="qualificationName"
                  label="Specialty"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="180"
                  prop="specialistName"
                  label="Specialist"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  width="150"
                  prop="actionedById"
                  label="Contacted By"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select :loading="loading" filterable remote :remote-method="remoteMethod" class="w-100" v-model="scope.row.actionedBy" placeholder="Select" @change="changeActionedBy(scope.$index)">
                    <el-option v-for="(user, index) in users" :key="index" :value="user.id" :label="user.name">{{ user.name }}</el-option>
                  </el-select>
                </template>
              </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import Header from "@/components/Header"

export default {
  name: "AdminViewSearchLog",
  components: {
    AdminNavigation,
    Header,
  },
  data() {
    return {
      totalCount: 0,
      successMessage: '',
      dateValue: [window.moment().subtract(30, 'days').format('YYYY-MM-DD'),  window.moment().format('YYYY-MM-DD')],
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      searchLogs: [],
      loading: false,
      allUsers: [],
      userParam: {
        PageSize: 10,
        PageNum: 1,
        SortField: 'UserStatus',
        SortBy: 'DESC',
        UserName: null,
        RoleID: '3,6'
      },
      allSearchLogs: [],
      limit: 10,
      pagination: utilities.initPagination(),
      listParam: {
        SearhClients: 'yes',
        PageNum: 1,
        PageSize: 10,
        Start: window.moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        End: window.moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      },
      byContact: false,
      byClient: false,
      byDate: false,
      byDateRange: false,
      byLocation: false,
      bySpecialty: false,
      bySpecialist: false,
      byContactedBy: false,

      users: [],
    }
  },
  computed: {},
  methods: {
    async remoteMethod(query) {
      this.userParam.UserName = query
      this.loading = true
      await this.loadData(true)
      this.loading = false
    },
    async exportToCsv() {
      this.$store.dispatch('loading/setLoadComponent', true)
      let params = _.cloneDeep(this.listParam)
      params.PageSize = 999
      let {searchLogs} = await utilities.getSearchLogList(params)
      let title = ['Contact', 'Client', 'Case ID', 'No. Results', 'Search Date', 'Booking Date Range', 'Location', 'Specialty', 'Specialist', 'Contacted By']
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += title.join(',') + "\n"
      searchLogs.forEach((row) => {
        let arr = [
          row.userName,
          row.clientName,
          row.caseId ? row.caseId : '',
          row.numberofResults,
          this.formatDate(row.dateSearched, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss"),
          this.formatDate(row.dateStart, 'DD/MM/YYYY') + ' - ' + this.formatDate(row.dateEnd, 'DD/MM/YYYY'),
          row.fullLocation,
          row.qualificationName,
          row.specialistName,
          row.actionedByName
        ]
        csvContent += arr.join(",") + "\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'Search Log ' + window.moment().format('DD/MM/YYYY'));
      document.body.appendChild(link);
      link.click();
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    changeDates(v) {
      if(v) {
        this.listParam.Start = v[0] + ' 00:00:00'
        this.listParam.End = v[1] + ' 23:59:59'
      } else {
        this.listParam.Start = null
        this.listParam.End = null
      }
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadSearchLogs()
    },
    sortContact() {
      this.allSearchLogs.sort((a, b) => {
        if (this.byContact) {  // ASC
          return a.userName > b.userName ? 1 : -1
        } else { // DESC
          return a.userName < b.userName ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.byContact = !this.byContact
    },
    sortClient() {
      this.allSearchLogs.sort((a, b) => {
        if (this.byClient) {  // ASC
          return a.clientName > b.clientName ? 1 : -1
        } else { // DESC
          return a.clientName < b.clientName ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.byClient = !this.byClient
    },
    sortDate() {
      this.allSearchLogs.sort((a, b) => {
        if (this.byDate) {  // ASC
          return moment(a.dateSearched) > moment(b.dateSearched) ? 1 : -1
        } else { // DESC
          return moment(a.dateSearched) < moment(b.dateSearched) ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.byDate = !this.byDate
    },
    sortDateRange() {
      this.allSearchLogs.sort((a, b) => {
        if (this.byDateRange) {  // ASC
          return moment(a.dateStart) > moment(b.dateStart) ? 1 : -1
        } else { // DESC
          return moment(a.dateStart) < moment(b.dateStart) ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.byDateRange = !this.byDateRange
    },
    sortLocation() {
      this.allSearchLogs.sort((a, b) => {
        if (this.byLocation) {  // ASC
          return a.fullLocation > b.fullLocation ? 1 : -1
        } else { // DESC
          return a.fullLocation < b.fullLocation ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.byLocation = !this.byLocation
    },
    sortSpecialty() {
      this.allSearchLogs.sort((a, b) => {
        if (this.bySpecialty) {  // ASC
          return a.qualificationList > b.qualificationList ? 1 : -1
        } else { // DESC
          return a.qualificationList < b.qualificationList ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.bySpecialty = !this.bySpecialty
    },
    sortSpecialist() {
      this.allSearchLogs.sort((a, b) => {
        if (this.bySpecialist) {  // ASC
          return a.specialistName > b.specialistName ? 1 : -1
        } else { // DESC
          return a.specialistName < b.specialistName ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.bySpecialist = !this.bySpecialist
    },
    sortContactedBy() {
      this.allSearchLogs.sort((a, b) => {
        if (this.byContactedBy) {  // ASC
          return a.actionedByName > b.actionedByName ? 1 : -1
        } else { // DESC
          return a.actionedByName < b.actionedByName ? 1 : -1
        }
      })
      this.paging(this.allSearchLogs)
      this.byContactedBy = !this.byContactedBy
    },
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    paging(searchLogs) {
      utilities.disposeTooltip()
      if (searchLogs.length == 0 || searchLogs.length <= this.limit) {
        this.searchLogs = searchLogs
        $(".pagination").hide()
      } else {
        this.pagination = utilities.loadPagination(searchLogs, this.limit)
        this.searchLogs = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      }
      utilities.initTooltip()
      utilities.hoverTooltip()
    },
    async changeActionedBy(index) {
      this.$store.dispatch('loading/setLoadComponent', true)
      var result = await this.putSearchLog(index)
      if (result) {
        this.successMessage = 'Search log updated successfully.'
        utilities.showAlert('.alert-success')
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    putSearchLog(index) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        SearchLogID: this.allSearchLogs[index].id,
        ActionedBy: Number(this.allSearchLogs[index].actionedBy),
      }
      var callback = (response) => {
      }
      return utilities.putSearchLog(params, callback)
    },
    async loadSearchLogs() {
      var {searchLogs, total} = await utilities.getSearchLogList(this.listParam)
      this.allSearchLogs = searchLogs
      this.totalCount = total
      this.allSearchLogs.filter(item => item.actionedBy).forEach(item => {
        if(this.users.filter(user => user.id === item.actionedBy).length === 0) {
          this.users.push({id: item.actionedBy, name: decodeURIComponent(item.actionedByName)})
        }
      })
      console.log(this.users)
    },
    async loadData() {
      var {users} = await utilities.getUsersNew(this.userParam)
      let arr = users.filter(item => item.status)
      for(let i = 0; i < arr.length; i++) {
        if(!this.users.find(item => item.id === arr[i].id)) {
          this.users.unshift(arr[i])
        } else {
          let index = this.users.findIndex(item => item.id === arr[i].id)
          this.users.splice(index, 1)
          this.users.unshift(arr[i])
        }
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $('body>.tooltip').remove()
    await this.loadData()
    await this.loadSearchLogs()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
