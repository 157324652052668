/* eslint-disable */
<template>
  <div class="hours-booked">
    <AdminNavigation ref="navigation" indexProps="6" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Number of Hours of Appointments Booked by Specialist ({{ totalHours }})</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminReportsMenu indexProps="4" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <v-md-date-range-picker @change="setDates" class="mr-3"></v-md-date-range-picker>
            <download-csv :data="getCalendarData()" :name="getFileName()">
              <v-btn dark>CSV <img src="@/assets/images/download_light_icon.svg" class="ml-3"/></v-btn>
            </download-csv>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:25%" class="long-text">Specialist</th>
                    <th style="width:30%" class="long-text">Number of Bookings</th>
                    <th style="width:30%" class="long-text">Number of Hours</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="hoursBooked.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(hours, index) in hoursBooked" :key="index">
                    <td style="width:25%" class="long-text">{{ hours.specialist }}</td>
                    <td style="width:30%" class="long-text">{{ hours.numberOfBookings }}</td>
                    <td style="width:30%" class="long-text">{{ hours.numberOfHours }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminAvailabilityMenu from '@/components/AdminAvailabilityMenu'
  import Header from '@/components/Header'
  import AdminReportsMenu from '@/components/AdminReportsMenu'

  export default {
    name: 'AdminHoursBookedBySpecialist',
    components: {
      AdminNavigation,
      AdminAvailabilityMenu,
      Header,
      AdminReportsMenu,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        hoursBooked: [],
        limit: 10,
        pagination: utilities.initPagination(),
        allHoursBooked: [],
        errorMessage: '',
        successMessage: '',
        totalHours: 0,
      }
    },
    methods: {
      async loadData(startDate = '', endDate = '') {
        this.allHoursBooked = []
        this.totalHours = 0
        //   var locations = await utilities.getLocations()
        if(startDate == '' || startDate == endDate)
        var localHours = await utilities.getReportHoursBookedBySpecialist(
          moment().format('YYYY-MM-DD'),
          moment()
            .add(1, 'y')
            .format('YYYY-MM-DD')
        )
        else
        var localHours = await utilities.getReportHoursBookedBySpecialist(startDate, endDate)
        localHours.map((x) => {
          this.totalHours += parseFloat(x.numberOfHours)
          if (this.allHoursBooked[x.specialist] === undefined) {
            this.allHoursBooked[x.specialist] = x
          } else {
            this.allHoursBooked[x.specialist].numberOfBookings += x.numberOfBookings
            this.allHoursBooked[x.specialist].numberOfHours = (parseFloat(this.allHoursBooked[x.specialist].numberOfHours) + parseFloat(x.numberOfHours)).toFixed(2)
          }
        })
        this.allHoursBooked = Object.values(this.allHoursBooked)
        this.paging(this.allHoursBooked)
      },
      paging(hoursBooked) {
        if (hoursBooked.length == 0 || hoursBooked.length <= this.limit) {
          this.hoursBooked = hoursBooked
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(hoursBooked, this.limit)
          this.hoursBooked = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
      },

      selectPage(index) {
        this.pagination.current = index
        this.locations = this.pagination.list[this.pagination.current]
      },

      getFileName() {
        return 'Hours_booked_' + moment().format('YYYYMMDDHHmm') + '.csv'
      },

      getCalendarData() {
        var calendarData = []
        this.allHoursBooked.map((x) => {
          calendarData.push({
            Specialist: x.specialist,
            'Number of Bookings': x.numberOfBookings,
            'Number of Hours': x.numberOfHours,
          })
        })
        return calendarData
      },
      async setDates(values) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var commencingDate = values[0]
        var endingDate = values[1]
        await this.loadData(commencingDate.format('YYYY-MM-DD'), endingDate.format('YYYY-MM-DD'))
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
