<template>
  <div class="add-user-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive display-sub-title">
            <div class="modal-title" style="padding-left: 20px;">
              <h1>Request Additional User</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
            <div class="padding-left-20" style="padding-top: 30px;">
              Please provide the details of the required new user:
            </div>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">First Name</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="newUser.firstName"  placeholder="First Name" />
                  <div class="text-danger" v-if="hasError && !validateFirstName">
                    Please enter a First Name.
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Surname</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="newUser.lastName"  placeholder="Surname" />
                  <div class="text-danger" v-if="hasError && !validateLastName">
                    Please enter a Surname.
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Email Address</div>
                <div class="box-col box-col-right">
                  <input type="text"  v-model="newUser.email"  placeholder="Email Address" />
                  <div class="text-danger" v-if="hasError && !validateUserEmail">
                    Please enter a valid email address.
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Phone Number</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="newUser.phoneNumber" placeholder="Phone Number" />
                  <div class="text-danger" v-if="hasError && !validatePhone">
                    Please enter a valid Phone Number.
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="alert alert-danger hidden-element">
                  <a class="close" title="close">×</a>
                  <b>Error:</b> Please correct the errors shown above and try again.
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn
                      class="white-button"
                      @click="handleModal($event)"
                      dark>Cancel</v-btn>
                    <v-btn
                      @click="clickSubmit"
                      dark>Submit</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import CitySuggestion from '@/components/CitySuggestion'

export default {
  name: "AddUserModal",
  components: {
    CitySuggestion,
  },
  data() {
    return {
      loadModal: false,
      hasError: false,
      clickState: false,
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        lastLogin: '',
      },
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },

  computed: {
    validateFirstName() {
      return this.newUser.firstName == "" ? false : true
    },
    validateLastName() {
      return this.newUser.lastName == "" ? false : true
    },
    validatePhone() {
      return this.newUser.phoneNumber == "" ? false : true
    },
    validateUserEmail() {
      if (this.newUser.email == "") {
        return false
      } else if (this.newUser.email.length > 0 && !utilities.validateEmail(this.newUser.email)) {
        return false
      } else {
        return true
      }
    },
  },

  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
        this.hasError = false
      }
    },

    async clickSubmit() {
      if (!this.validateFirstName || !this.validateLastName || !this.validateUserEmail || !this.validatePhone) {
        this.hasError = true
        utilities.showAlert(".alert-danger")
        return
      }

      this.$store.dispatch("loading/setLoadComponent", true)
      var user = {
        FirstName: this.newUser.firstName,
        Surname: this.newUser.lastName,
      	NewUserEmail: this.newUser.email,
      	NewUserPhoneNumber: this.newUser.phoneNumber,
      	ClientID: this.user.clientId,
      	LogDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        UserID: this.user.id,
    	  Username: this.user.name,
        ClientName: this.$parent.client.name,
        BranchName: this.$parent.client.branchName,
      }
      var callback = (response) => {
        this.handleModal()
        this.newUser.firstName = ""
        this.newUser.lastName = ""
        this.newUser.email = ""
        this.newUser.phoneNumber = ""
        this.$parent.displayMessage()
      }
      await utilities.postNewUser(user, callback)
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    handleState() {
      this.clickState = true
    },
  },

  beforeMount() {},

  mounted() {},

  updated() {},
}
</script>
