/* eslint-disable */
<template>
  <div class="hours-booked">
    <AdminNavigation ref="navigation" indexProps="6" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Number of Hours of Appointments Booked by Location ({{ totalHours }})</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminReportsMenu indexProps="5" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <v-md-date-range-picker @change="changeDateRange" class="mr-3"></v-md-date-range-picker>
            <download-csv :data="getCalendarData()" :name="getFileName()">
              <v-btn dark>CSV <img src="@/assets/images/download_light_icon.svg" class="ml-3" /></v-btn>
            </download-csv>
            <!-- <v-btn @click="getCalendarData()">test</v-btn> -->
          </div>
        </div>

        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 30%" class="long-text">Location</th>
                    <th style="width: 30%" class="long-text">Month</th>
                    <th style="width: 20%" class="long-text">
                      Number of Bookings
                    </th>
                    <th style="width: 20%" class="long-text">
                      Number of Hours
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              :key="hoursBooked.length"
              :class="hoursBooked.length > 5 ? 'content-scroll-y' : ''"
            >
              <table class="table">
                <tbody>
                  <template v-for="(hours, key) in hoursBooked">
                    <tr class="bg-light">
                      <td>{{ key }}</td>
                      <td>&nbsp;</td>
                      <td>{{ hours.totalBookings }}</td>
                      <td>{{ hours.totalHours }}</td>
                    </tr>
                    <tr v-for="hour in hours.hoursBooked">
                      <td class="padded-cell">{{ hour.city }}</td>
                      <td>{{ hour.groupingDate }}</td>
                      <td>{{ hour.numberOfBookings }}</td>
                      <td>{{ hour.numberOfHours }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";
import AdminNavigation from "@/components/AdminNavigation";
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu";
import Header from "@/components/Header";
import AdminReportsMenu from "@/components/AdminReportsMenu";

export default {
  name: "AdminHoursBookedByLocation",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    Header,
    AdminReportsMenu,
  },
  data() {
    return {
      hasError: false,
      successMessage: "",
      errorMessage: "Please correct the errors shown above and try again.",
      hoursBooked: {},
      limit: 20,
      allHoursBooked: {},
      allHoursUnfiltered: [],
      allHoursFiltered: [],
      errorMessage: "",
      successMessage: "",
      totalHours: 0,
    };
  },
  methods: {
    async loadData(startDate = "", endDate = "") {
      //   var locations = await utilities.getLocations()
      if (startDate == "" || startDate == endDate)
        this.allHoursUnfiltered = await utilities.getReportHoursBookedByLocation(
          moment().format("YYYY-MM-DD"),
          moment().add(1, "y").format("YYYY-MM-DD")
        );
      else
        this.allHoursUnfiltered = await utilities.getReportHoursBookedByLocation(
          startDate,
          endDate
        );
      this.allHoursFiltered = this.allHoursUnfiltered;
      var allHoursBooked = {};
      this.allHoursFiltered.map((x) => {
        this.totalHours += parseFloat(x.bookedHours);
        if (allHoursBooked[x.regionName] === undefined) {
          allHoursBooked[x.regionName] = {
            totalHours: parseFloat(x.bookedHours).toFixed(2),
            totalBookings: x.numberOfBookings,
            hoursBooked: [
              {
                city: x.cityName,
                numberOfBookings: x.numberOfBookings,
                numberOfHours: parseFloat(x.bookedHours).toFixed(2),
                groupingDate: x.groupingDate,
              },
            ],
          };
        } else {
          allHoursBooked[x.regionName].totalHours = (
            parseFloat(allHoursBooked[x.regionName].totalHours) +
            parseFloat(x.bookedHours)
          ).toFixed(2);
          allHoursBooked[x.regionName].totalBookings += x.numberOfBookings;
          if (
            allHoursBooked[x.regionName].hoursBooked.find(
              (y) => y.city == x.cityName && y.groupingDate == x.groupingDate
            ) != undefined
          ) {
            var locationTemp = allHoursBooked[x.regionName].hoursBooked.find(
              (y) => y.city == x.cityName && y.groupingDate == x.groupingDate
            );
            allHoursBooked[x.regionName].hoursBooked.find(
              (y) => y.city == x.cityName && y.groupingDate == x.groupingDate
            ).numberOfBookings =
              parseInt(locationTemp.numberOfBookings) +
              parseInt(x.numberOfBookings);
            allHoursBooked[x.regionName].hoursBooked.find(
              (y) => y.city == x.cityName && y.groupingDate == x.groupingDate
            ).numberOfHours = (
              parseFloat(locationTemp.numberOfHours) + parseFloat(x.bookedHours)
            ).toFixed(2);
          } else {
            allHoursBooked[x.regionName].hoursBooked.push({
              city: x.cityName,
              numberOfBookings: x.numberOfBookings,
              numberOfHours: x.bookedHours,
              groupingDate: x.groupingDate,
            });
          }
        }
      });
      this.allHoursBooked = allHoursBooked;
      for(var key in this.allHoursBooked) {
        this.allHoursBooked[key].hoursBooked = this.allHoursBooked[key].hoursBooked.sort((a,b) => {
          return moment("01 "+a.groupingDate).isBefore(moment("01 "+b.groupingDate)) ? -1 : 1
        })
      }
      this.hoursBooked = this.allHoursBooked;
    },

    getFileName() {
      return "Hours_booked_" + moment().format("YYYYMMDDHHmm") + ".csv";
    },

    getCalendarData() {
      var calendarData = [];
      for (var key in this.hoursBooked) {
        if (this.hoursBooked.hasOwnProperty(key)) {
          calendarData.push({
            Region: key,
            City: "",
            "Month/Date": "",
            "Number of Bookings": this.hoursBooked[key].totalBookings,
            "Number of Hours": this.hoursBooked[key].totalHours,
          });
          for (var i = 0; i < this.hoursBooked[key].hoursBooked.length; i++) {
            calendarData.push({
              Region: "",
              City: this.hoursBooked[key].hoursBooked[i].city,
              "Month/Date": this.hoursBooked[key].hoursBooked[i].groupingDate,
              "Number of Bookings": this.hoursBooked[key].hoursBooked[i]
                .numberOfBookings,
              "Number of Hours": this.hoursBooked[key].hoursBooked[i]
                .numberOfHours,
            });
          }
        }
      }
      return calendarData;
    },

    async changeDateRange(values) {
      this.$store.dispatch("loading/setLoadComponent", true);
      var commencingDate = values[0];
      var endingDate = values[1];
      await this.loadData(
        commencingDate.format("YYYY-MM-DD"),
        endingDate.format("YYYY-MM-DD")
      );
      this.$store.dispatch("loading/setLoadComponent", false);
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true);
    await this.loadData();
    this.$store.dispatch("loading/setLoadComponent", false);
  },
  mounted() {},
};
</script>
