/* eslint-disable */
<template>
  <div class="specialist-ava-menu sub-menu">
    <ul>
      <li><router-link to="/specialist-travel">Travel</router-link></li>
      <li><router-link to="/specialist-add-travel-receipt">Add Receipt</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SpecialistTravelMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
