<template>
  <div class="manage-city-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Edit City</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="box-row pt-2 pb-2">
                  <div class="box-col box-col-left">Name</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="city.name" class="textbox w-100" placeholder="Region Name" :class="hasError && !validateCityName ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validateCityName" class="text-danger">City name is required</div>
                  </div>
                </div>
                <div class="box-row pt-2 pb-2">
                  <div class="box-col box-col-left">Region</div>
                  <div class="box-col box-col-right">
                    <select v-model="city.regionId" class="textbox w-100">
                      <option v-for="region in regions" :value="region.id">{{region.name}}</option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'ManageCityModal',
    data() {
      return {
        loadModal: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        hasError: false,
        regionId: '',
        city: {},
        regions: [],
        index: -1,
      }
    },
    computed:{
      validateCityName(){
        return this.city.name === '' ? false:true
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      async callSave() {
        if(!this.validateCityName) {
            this.hasError = true
            return
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          CityID: this.city.id,
          CityName: this.city.name,
          RegionID: this.city.regionId,
          UserID: this.user.id,
          Username: this.user.name,
        }
        var callback = (response) => {
          var item = this.$parent.cities[this.index]
          item.name = this.city.name
          item.regionId = this.city.regionId
          this.$parent.updateCity()
          this.handleModal()
        }
        await utilities.putCity(params, callback)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      setCity(city) {
        this.city = _.cloneDeep(city)
      },
  },
  async mounted() {
    //this.regions = await utilities.getRegions()
  },
  updated(){}
  }
</script>
