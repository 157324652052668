/* eslint-disable */
<template>
  <div class="cases">
    <Navigation indexProps="3" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Cases</h1></div>
        <div class="header-sub-title">Manage cases and find all bookings, files and requests attached to your case.</div>
      </div>
    </div>
    <div class="panel">
      <CaseMenu indexProps="0" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <input type="search" class="search-input w-15 textbox filter-icon right-float-element mr-5 clear" placeholder="Claimant Name" @keyup="search()" v-model="searchClaimantName" />
            <input type="search" class="search-input w-15 textbox filter-icon right-float-element mr-5 clear" placeholder="Case Reference" @keyup="search()" v-model="searchCaseRef" />
            <input type="search" class="search-input w-15 textbox filter-icon right-float-element mr-5 clear" placeholder="Booking ID" @keyup="search()" v-model="searchBookingId" />
          </div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 15%" class="long-text">Booking Id</th>
                <th style="width:15%" class="long-text">Reference No.</th>
                <th style="width:15%" class="long-text">Case Owner</th>
                <th style="width:15%" class="long-text">Claimant</th>
                <th style="width:15%" class="long-text">Type of Claim</th>
                <th style="width:10%" class="long-text">Number of Bookings</th>
                <th style="width:15%" class="long-text">Next Booking</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr v-for="(item, index) in cases" :key="index" @click="gotoCase(item.id)" class="is-icon">
                <td style="width: 15%" class="long-text">
                  <el-tooltip  effect="dark" :content="item.bookingIds.join(', ')" placement="bottom">
                    <span>{{item.bookingIds.join(', ')}}</span>
                  </el-tooltip>
                </td>
                <td style="width:15%" class="long-text">{{item.caseReference}}</td>
                <td style="width:15%" class="long-text">{{item.caseOwnerName}}</td>
                <td style="width:15%" class="long-text">{{item.claimantFullName}}</td>
                <td style="width:15%" class="long-text">{{item.claimTypeName}}</td>
                <td style="width:10%" class="long-text">{{item.numberBookings}}</td>
                <td style="width:15%" class="long-text">{{formatDate(item.nextBookingDate, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss")}}</td>
              </tr>
              <tr v-if="cases.length == 0" class="dox-col">
                No Matching Cases
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''"
                  v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">{{ item }}</div>
              </div>
            </div>

          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import CaseMenu from '@/components/CaseMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'

export default {
  name: 'Cases',
  components: {
    Navigation,
    CaseMenu,
    Header,
    Chat,
  },
  data() {
    return {
      limit: 20,
      pagination: utilities.initPagination(),
      cases: [],
      allCases: [],
      searchTerm: '',
      // searchText: "",
      // searchReference: "",
      searchCaseRef: '',
      searchBookingId: '',
      searchClaimantName: '',
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {
    search() {
      // var cases = search(this.allCases, ['caseReference', 'claimantFullName', 'bookingIds'], this.searchTerm)
      // this.paging(cases)
      if (this.searchBookingId.trim() != '') {
        var cases = search(this.allCases, ['bookingIds'], this.searchBookingId)
        this.paging(cases)
      } else if (this.searchCaseRef.trim() != '') {
        var cases = this.allCases.filter((x)=> x.caseReference.toLowerCase().includes(this.searchCaseRef.toLowerCase()))
        this.paging(cases)
      } else if (this.searchClaimantName.trim() != '') {
        var cases = this.allCases.filter((x)=> x.claimantFullName.toLowerCase().includes(this.searchClaimantName.toLowerCase()))
        this.paging(cases)
      }  else {

        this.paging(this.allCases)
      }
    },

    setDateOfEvent(item) {
      if (item.overPeriodofTime) {
        return "Over a Period of Time"
      } else if (item.accidentDate) {
        return this.formatDate(item.accidentDate, 'DD/MM/YYYY')
      } else {
        return ""
      }
    },
    gotoCase(id) {
      this.$router.push({path:'/manage-case', query:{id:id}}).catch((err) => {})
    },

    selectPage(index) {
      this.pagination.current = index
      this.cases = this.pagination.list[this.pagination.current]
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date != '' && date != "0000-00-00") {
        return utilities.formatDate(date, format, currentFormat)
      }
      return "";
    },

    paging(cases) {
      if (cases.length == 0 || cases.length <= this.limit) {
        this.cases = cases
        $('.pagination').hide()
      } else {
        this.pagination = utilities.loadPagination(cases, this.limit)
        this.cases = this.pagination.list[this.pagination.current]
        $('.pagination').show()
      }
    },

    async loadData() {
      var params = {
        ClientId: this.user.clientId,
        PageSize: 100,
        PageNum: 1
      }
      let allCases = []
      const {clientCase, total}  = await utilities.getCasesNew(params)
      allCases = allCases.concat(clientCase)

      while(allCases.length < total) {
        params.PageNum ++
        const { clientCase }  = await utilities.getCasesNew(params)
        allCases = allCases.concat(clientCase)
      }

      allCases.sort((a, b) => {
        return Number(a.caseStatus) >  Number(b.caseStatus) ? 1 : -1
      })

      var tempActive = allCases.filter(x => Number(x.caseStatus) == 1)
      var tempInactive = allCases.filter(x => Number(x.caseStatus) == 0)
      tempActive.sort((a, b) => {
        return (a.nextBookingDate.length > b.nextBookingDate.length) ? 1 : -1
      }).sort((a, b) => {
        return (moment(a.nextBookingDate) > moment(b.nextBookingDate)) ? 1 : -1
      })

      var cases =[...tempActive, ...tempInactive]
      this.allCases =  _.cloneDeep(cases)
      this.paging(this.allCases)
    },

    // searchByName() {
    //   this.search(this.searchText.trim(), 'claimantFullName')
    // },
    //
    // searchByReference() {
    //   this.search(this.searchReference.trim(), 'caseReference')
    // },

    /*search(text, field) {
      if (text.trim() !== '') {
        var cases = search(this.cases, [field], text)
        if (cases.length > this.limit) {
          this.pagination = utilities.loadPagination(cases, this.limit)
          $('.pagination').show()
        } else {
          this.cases = cases
          $(".pagination").hide()
        }
      } else {
        $(".pagination").hide()
        this.cases = this.allCases
        this.pagination = utilities.loadPagination(this.cases, this.limit)
        this.cases = this.pagination.list[this.pagination.current]
      }
    },
    */
  },

  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    await this.loadData()
    this.$store.dispatch('loading/setLoadComponent', false)
  },

  mounted() {
    $(".clear").on("search", () => {
      this.paging(this.allCases)
    })
  },
}
</script>
