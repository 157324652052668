<template>
  <div class="search-booking-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Booking Search</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group mb-0">
                <div class="row">
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Claimant First Name</label>
                    <input type="text" :disabled="hideOthers" class="textbox claimant-name" v-model="searchOption.claimantFirstname" placeholder="Claimant Firstname" />
                    <div v-if="hasError && !validateClaimantFirstname" class="text-danger">special characters not allowed</div>
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Claimant Surname</label>
                    <input type="text" :disabled="hideOthers" class="textbox claimant-name" v-model="searchOption.claimantSurname" placeholder="Claimant Surname" />
                    <div v-if="hasError && !validateClaimantSurname" class="text-danger">special characters not allowed</div>
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Client Contact</label>
                    <input type="text" :disabled="hideOthers" class="textbox" v-model="searchOption.userName" placeholder="Client Contact" />
                    <div v-if="hasError && !validateUserName" class="text-danger">special characters not allowed</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>Booking ID</label>
                    <input type="text" @keyup="checkBookingId()" class="textbox" v-model="searchOption.bookingId" placeholder="Booking ID" />
                    <div v-if="hasError && !validateBookingID" class="text-danger">special characters not allowed</div>
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Client Name</label>
                    <Dropdown ref="clientDropdown"
                      class="client-selecter select-dropdown"
                      :options="listClients"
                      v-on:selected="clientSelected"
                      v-on:filter="filterClients"
                      :maxItem="10000"
                      placeholder="Select a Client">
                    </Dropdown>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex flex-column" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>From Date</label>
                    <datepicker
                      class="fromDate"
                      :disabled="hideOthers"
                      :clear-button="true"
                      v-model="searchOption.fromDate"
                      format="dd/MM/yyyy" :typeable="true"
                      placeholder="dd/mm/yyyy"></datepicker>
                  </div>
                  <div class="col d-flex flex-column" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>To Date</label>
                    <datepicker
                      class="toDate"
                      :disabled="hideOthers"
                      :clear-button="true"
                      v-model="searchOption.toDate"
                      format="dd/MM/yyyy" :typeable="true"
                      placeholder="dd/mm/yyyy"></datepicker>
                  </div>
                </div>
                <div class="row">
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Booking Reference</label>
                    <input type="text" :disabled="hideOthers" class="textbox" v-model="searchOption.bookingReference" placeholder="Booking Reference" />
                    <div v-if="hasError && !validateBookingReference" class="text-danger">special characters not allowed</div>
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Booking Type</label>
                    <select :disabled="hideOthers" class="textbox" v-model="searchOption.bookingType" @change="changeBookingType">
                      <option value="">Please choose ...</option>
                      <option v-for="(bookingType, index) in bookingTypes" :value="bookingType.id">{{ bookingType.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Affected Body Areas</label>
                    <div class="body-areas-input">
                      <vue-tags-input
                       :disabled="hideOthers"
                        v-model="bodyAreaTag"
                        :tags="bodyAreaTags"
                        placeholder="Add Affected Body Areas"
                        @tags-changed="updateBodyAreas"
                        @focus="openDigram()"
                        />
                    </div>
                    <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Conditions</label>
                    <div>
                      <vue-tags-input
                       :disabled="hideOthers"
                        ref="conditions"
                        v-model="personConditionTag"
                        :tags="personConditionTags"
                        :add-only-from-autocomplete="true"
                        placeholder="Add Conditions"
                        :autocomplete-items="filteredPersonConditionsItems"
                        @tags-changed="updateConditions"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Status</label>
                    <select :disabled="hideOthers" class="textbox" v-model="searchOption.status">
                      <option value="">Please choose ...</option>
                      <option :value="bookingStatus.id" v-for="bookingStatus in bookingStatuses">{{ bookingStatus.name }}</option>

                      <!-- <option value="Upcoming">Upcoming</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Past">Past</option> -->
                    </select>
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Specialist</label>
                    <div class="">
                      <el-select :popper-append-to-body="false" :disabled="hideOthers" class="w-100" clearable filterable v-model="searchOption.specialistId">
                        <el-option v-for="item in originalListSpecialists" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Region / Location</label>
                    <treeselect :disabled="hideOthers" ref="treeSelect"
                                :multiple="false"
                                :searchable="false"
                                :closeOnSelect="false"
                                :options="regionLocations"
                                :limit="7"
                                :max-height="200"
                                :defaultExpandLevel="0"
                                placeholder="Please choose ..."
                                noOptionsText="No regions"
                                noChildrenText="No cities"
                                @input="changeRegion" />
                  </div>
                  <div class="col" :class="this.hideOthers != '' ? 'disabled-element' : ''">
                    <label>Location Detail</label>
                    <el-select :popper-append-to-body="false" :disabled="hideOthers"  clearable class="w-100" v-model="searchOption.locationId">
                      <el-option v-for="item in locations" :key="item.id" :value="item.id" :label="item.fullLocation">{{ item.fullLocation }}</el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="search">Search</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'
import Dropdown from 'vue-simple-search-dropdown'

export default {
  name: "AdminSearchBookingModal",
  components: {
    AddBodyDiagramModal,
    Dropdown,
  },
  data() {
    return {
      hasError: false,
      loadModal: false,
      bookingTypes: [],
      bookingStatuses: [],
      regions: [],
      locations: [],
      regionLocations: [],
      bodyAreaTag: "",
      bodyAreaTags: [],
      personConditionTag: "",
      personConditionTags: [],
      hideOthers: false,
      searchOption: this.initSearchOption(),
      originalLocation: [],
      originalListClients: [],
      listClients: [],
      listSpecialists: [],
      originalListSpecialists: [],
    }
  },
  computed: {
    validateBookingReference() {
      if(this.searchOption.bookingReference.trim() === '') {
        return true
      }
      return utilities.validateInputText(this.searchOption.bookingReference)
    },
    validateBookingID() {
      if(this.searchOption.bookingId.trim() === '') {
        return true
      }
      return utilities.validateInputText(this.searchOption.bookingId)
    },
    validateClaimantSurname() {
      if(this.searchOption.claimantSurname.trim() === '') {
        return true
      }
      return utilities.validateInputText(this.searchOption.claimantSurname)
    },
    validateUserName() {
      if(this.searchOption.userName.trim() === '') {
        return true
      }
      return utilities.validateInputText(this.searchOption.userName)
    },
    validateClaimantFirstname() {
      if(this.searchOption.claimantFirstname.trim() === '') {
        return true
      }
      return utilities.validateInputText(this.searchOption.claimantFirstname)
    },
    filteredPersonConditionsItems() {
      if(this.personConditions) {
        return this.personConditions.filter((i) => {
          return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
        })
      } else {
        return []
      }
    },
  },
  methods: {
    changeRegion(item, e) {
      if (item && item.includes('s')) {  // Disable select state
        this.$refs.treeSelect.clear()
        var index = this.regionLocations.findIndex(x => x.id === item)
        if (index > -1) {
          var mouseEvent = document.createEvent("MouseEvents")
          mouseEvent.initMouseEvent("mousedown", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
          $(".vue-treeselect__option[data-id*='s'] .vue-treeselect__option-arrow-container")[index].dispatchEvent(mouseEvent)
        }
      } else if (item && item.includes('r')) {
        this.searchOption.regionId = Number(item.replace('r', ''))
        this.searchOption.cityId = null
        this.locations = this.originalLocation.filter(item => item.regionId === this.searchOption.regionId)
        this.searchOption.locationId = null
        this.$refs.treeSelect.closeMenu()
      } else if (item && item.includes('c')) {
        this.searchOption.regionId = null
        this.searchOption.cityId = Number(item.replace('c', ''))
        this.locations = this.originalLocation.filter(item => item.cityId === this.searchOption.cityId)
        this.searchOption.locationId = null
        this.$refs.treeSelect.closeMenu()
      } else {
        this.searchOption.regionId = null
        this.searchOption.cityId = null
        this.locations = this.originalLocation
        this.$refs.treeSelect.closeMenu()
      }
    },
    changeBookingType() {
      var bookingType = this.bookingTypes.find(x => x.id == this.searchOption.bookingType)
      this.searchOption.bookingTypeName = bookingType ? bookingType.name : ""
    },

    filterClients(text) {
      this.listClients = this.originalListClients.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
    },

    clientSelected(item) {
      if (item.id != undefined) {
        this.searchOption.clientId = item.id
        this.searchOption.clientName = item.name
      }
    },

    setSpecialistData(id) {
      var selectedSpecialist = this.originalListSpecialists.find((x) => x.id == id)
      if (selectedSpecialist != undefined) {
        $('.specialist-selecter input').val(selectedSpecialist.name)
        setTimeout(() =>{
          if(this.$refs.specialistDropdown) this.$refs.specialistDropdown.searchFilter = selectedSpecialist.name
        }, 500)
      }
    },

    selectedSpecialist(item) {
      if (item.id) {
        this.searchOption.specialistId = item.id
      }
    },

    filterSpecialist(text) {
      this.listSpecialists = this.originalListSpecialists.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    openDigram() {
      this.openBodyDiagram()
    },

    checkBookingId() {
      if (this.searchOption.bookingId.trim().length > 0) {
        this.hideOthers = true
        this.searchOption.claimantFirstname = ""
        this.searchOption.claimantSurname = ""
        this.searchOption.userName = "",
        this.searchOption.clientId = ""
        this.searchOption.clientName = ""
        this.searchOption.fromDate = ""
        this.searchOption.toDate = ""
        this.searchOption.locationId = ""
        this.searchOption.regionId = ""
        this.searchOption.bookingReference = ""
        this.searchOption.bookingType = ""
        this.searchOption.bodyAreas = ""
        this.searchOption.conditions = ""
        this.searchOption.status = ""
      } else {
        this.hideOthers = false
      }
    },

    openBodyDiagram() {
      this.$refs.addBodyDiagramModal.handleModal()
      this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
      this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
      this.$refs.addBodyDiagramModal.handleClick()
    },

    setBodyArea(addedBodyAreas) {
      this.bodyAreaTags = addedBodyAreas
    },

    deleteBodyAreaItems(items) {},

    updateBodyAreas(newTags) {
      this.bodyAreaTags = newTags

      if(this.bodyAreaTags.length >= 5) {
        $(".search-booking-modal .modal-body").css('height', '65vh')
      } else {
        $(".search-booking-modal .modal-body").css('height', 'auto')
      }
    },

    updateConditions(newTags) {
      this.personConditionTags = newTags

      if(this.personConditionTags.length >= 5) {
        $(".search-booking-modal .modal-body").css('height', '65vh')
      } else {
        $(".search-booking-modal .modal-body").css('height', 'auto')
      }
    },

    handleModal(e) {
      this.loadModal = !this.loadModal
      setTimeout(() => {
        if(this.loadModal) {
          $("html").addClass("modal-mask-open")
          $('.search-booking-modal form').on('keypress', (e) => {
            if (e.keyCode == 13) {
              this.search()
            }
          })

          $(".fromDate input").on('keyup', (e) => {
            if(e.keyCode == 46 || e.keyCode == 8) {
              if($(e.target).val().trim().length == 0) {
                $(".fromDate .vdp-datepicker__clear-button").click()
              }
            }
          })
          $(".toDate input").on('keyup', (e) => {
            if(e.keyCode == 46 || e.keyCode == 8) {
              if($(e.target).val().trim().length == 0) {
                $(".toDate .vdp-datepicker__clear-button").click()
              }
            }
          })

          $(".client-selecter input").after("<div class='dropdown-input-clear'></div>")
          $(".client-selecter .dropdown-input-clear").click((e) => {
            this.$refs.clientDropdown.searchFilter = ""
            this.searchOption.clientId = ""
            this.searchOption.clientName = ""
          })

          $(".specialist-selecter input").after("<div class='dropdown-input-clear'></div>")
          $(".specialist-selecter .dropdown-input-clear").click((e) => {
            this.$refs.specialistDropdown.searchFilter = ""
            this.searchOption.specialistId = ""
          })

          if(this.searchOption != undefined) {
            this.$refs.clientDropdown.searchFilter = this.searchOption.clientName
            this.setSpecialistData(this.searchOption.specialistId)
            this.setBodyArea(this.bodyAreas.filter(x => this.searchOption.bodyAreas.toString().split(",").includes(x.value.toString())))
            this.updateConditions(this.personConditions.filter(x => this.searchOption.conditions.toString().split(",").includes(x.value.toString())))
          }

          if(this.bodyAreaTags.length >= 5 || this.personConditionTags.length >= 5) {
            $(".search-booking-modal .modal-body").css('height', '65vh')
          } else {
            $(".search-booking-modal .modal-body").css('height', 'auto')
          }
        } else {
          $("html").removeClass("modal-mask-open")
        }
      }, 1000)
    },
    initSearchOption() {
      this.bodyAreaTags = []
      this.personConditionTags = []
      return {
        claimantFirstname: "",
        claimantSurname: "",
        userName: "",
        bookingId: "",
        clientId: "",
        clientName: "",
        fromDate: "",
        toDate: "",
        bookingReference: "",
        bookingType: "",
        bookingTypeName: "",
        bodyAreas: "",
        conditions: "",
        status: "", // booking status id
        specialistId: "",
        regionId: null,
        cityId: null,
        locationId: null
      }
    },

    async search() {
      this.searchOption.bodyAreas = ""
      this.bodyAreaTags.map((x, xi) => {
        this.searchOption.bodyAreas += x.value
        if(xi < this.bodyAreaTags.length - 1) this.searchOption.bodyAreas += ','
      })

      this.searchOption.conditions = ""
      this.personConditionTags.map((x, xi) => {
        this.searchOption.conditions += x.value
        if(xi < this.personConditionTags.length - 1) this.searchOption.conditions += ','
      })

      if(this.searchOption.claimantFirstname == "" && this.searchOption.claimantSurname == "" && this.searchOption.userName == "" && this.searchOption.bookingId == "" && this.searchOption.clientName == "" &&
        this.searchOption.fromDate == "" && this.searchOption.toDate == "" && this.searchOption.bookingReference == "" && this.searchOption.bookingType == "" &&
        this.searchOption.bodyAreas == "" && this.searchOption.conditions == "" && this.searchOption.status == "" && this.searchOption.specialistId == "" && !this.searchOption.cityId && !this.searchOption.regionId && !this.searchOption.locationId) {
        return false
      }

      if(!this.validateClaimantFirstname || !this.validateClaimantSurname || !this.validateBookingID || !this.validateBookingReference || !this.validateUserName) {
        this.hasError = true
        return
      }
      this.hasError = false
      if(this.$route.name == 'admin-bookings-result') {
        await this.$parent.$parent.$parent.searchBooking(this.searchOption)
      } else {
        this.$store.dispatch("loading/setLoadComponent", true)
        await utilities.delay(1000)
        this.$router.push({ name: 'admin-bookings-result', params: { searchOptionProps: this.searchOption } }).catch((err) => {})
      }

      this.handleModal()
      this.searchOption = this.initSearchOption()
      this.hideOthers = false
    },

    async loadData() {
      utilities.getClients().then(r => {
        const clients = r
        this.originalListClients = _.cloneDeep(utilities.getDropdownAutoCompleteTag(clients))
        this.listClients = _.cloneDeep(this.originalListClients)
      })
      utilities.getBookingTypes().then(r => {
        this.bookingTypes = r
      })
      utilities.getBookingTypes().then(r => {
        this.bookingTypes = r
      })
      utilities.getBodyArea().then(r => {
        const bodyAreas = r
        this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)
      })

      const personConditions = await utilities.getPersonConditions()
      this.personConditions = utilities.getAutoCompleteTags(personConditions)
      const apiArr = [utilities.getSpecialists(), utilities.getRegions(), utilities.getLocations()]
      const r = await Promise.all(apiArr)
      const specialists = r[0]
      this.originalListSpecialists = _.cloneDeep(utilities.getDropdownSpecialist(specialists))
      this.listSpecialists = this.originalListSpecialists
      this.regions = r[1]
      this.locations = r[2]
      this.originalLocation = r[2]
      this.regionLocations = utilities.getRegionLocations(this.regions, this.locations)
      console.log(this.regionLocations)

      // Get all booking status from API
      utilities.getAllBookingStatuses().then(r => {
        this.bookingStatuses = r.filter(item => item.id !== 8)
      })

      setTimeout(() => {
        $(".dropdown-input").attr({
         autocomplete: "off",
        })
      }, 1000)
    }
  },
  async beforeMount() {
    //await this.loadData()
  },
  mounted() {},
}
</script>
