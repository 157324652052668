/* eslint-disable */
<template>
  <div class="availabilities">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Upcoming Availability</h1></div>
        <div class="header-sub-title">The following is a summary of specialist availability, in order that it occurs.</div>
      </div>
    </div>
    <div class="panel">
      <AdminAvailabilityMenu indexProps="0" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-select style="max-width: 200px" @input="search()" class="mr-1" filterable clearable placeholder="Specialist" v-model="listParam.SpecialistID">
                <el-option v-for="item in specialists" :value="item.id" :key="item.id" :label="item.fullName">{{item.fullName}}</el-option>
              </el-select>
              <el-select style="max-width: 200px" class="mr-1" @input="search()" clearable filterable placeholder="Specialty" v-model="listParam.SpecialtyID">
                <el-option v-for="item in qualification" :value="item.id" :key="item.id" :label="item.name">{{item.name}}</el-option>
              </el-select>
              <el-select style="max-width: 240px" class="mr-1" @input="search()" clearable filterable placeholder="Location" v-model="listParam.LocationID">
                <el-option v-for="item in locations" :key="item.id" :value="item.id" :label="item.fullLocation">{{item.fullLocation}}</el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <el-table @row-click="RowClick" :data="allAvailabilities" ref="bookingTable" :default-sort="{prop: 'AvailabilityDate', order: 'ascending'}" stripe @sort-change="sortParam" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
            <el-table-column
                width="150"
                sortable="custom"
                show-overflow-tooltip
                label="Specialist"
                prop="SpecialistName"
            >
            </el-table-column>
            <el-table-column
                width="150"
                sortable="custom"
                show-overflow-tooltip
                label="Date"
                prop="AvailabilityDate"
            >
            </el-table-column>
            <el-table-column
                width="200"
                sortable="custom"
                show-overflow-tooltip
                label="Specialty"
                prop="Qualifications"
            >
            </el-table-column>
            <el-table-column
                width="200"
                sortable="custom"
                show-overflow-tooltip
                label="Number Of Bookings"
                prop="NumberOfBookings"
            >
              <template slot-scope="scope">
                <span>{{scope.row.NumberOfBookings}} / {{scope.row.TotalTimeslotsCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
                sortable="custom"
                show-overflow-tooltip
                label="Locations"
                prop="Locations"
            >
              <template slot-scope="scope">
                <span>{{decodeURIComponent(scope.row.Locations)}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminAvailabilities",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    Header,
  },
  data() {
    return {
      specialists: [],
      totalCount: 0,
      availabilities: [],
      allAvailabilities: [],
      limit: 10,
      pagination: utilities.initPagination(),
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      bySpecialist: false,
      qualification: [],
      locations: [],
      byDate: false,
      byBooking: false,
      byLocation: false,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SpecialistID: '',
        SpecialtyID: '',
        StartDate: window.moment().format('YYYY-MM-DD'),
        EndDate: '',
        LocationID: '',
        SortFlag: 4,
      }
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },
    RowClick(row) {
      this.$router.push('admin-availability-bookings?id=' + row.SpecialistID + '&date=' + row.AvailabilityDate)
    },
    sortParam(v) {
      if(v.prop === 'SpecialistName' && v.order === "ascending") {
        this.listParam.SortFlag = 2
      } else if (v.prop === 'SpecialistName' && v.order === "descending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'AvailabilityDate' && v.order === "ascending") {
        this.listParam.SortFlag = 4
      } else if (v.prop === 'AvailabilityDate' && v.order === "descending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'Qualifications' && v.order === "ascending") {
        this.listParam.SortFlag = 12
      } else if (v.prop === 'Qualifications' && v.order === "descending") {
        this.listParam.SortFlag = 11
      } else if (v.prop === 'NumberOfBookings' && v.order === "ascending") {
        this.listParam.SortFlag = 6
      } else if (v.prop === 'NumberOfBookings' && v.order === "descending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === 'Locations' && v.order === "ascending") {
        this.listParam.SortFlag = 10
      } else if (v.prop === 'Locations' && v.order === "descending") {
        this.listParam.SortFlag = 9
      } else {
        this.listParam.SortFlag = 0
      }
      this.selectPage(1)
    },
    sortSpecialist() {
      this.allAvailabilities.sort((a, b) => {
        if(this.bySpecialist) {  // ASC
          return a.fullName > b.fullName ? 1 : -1
        } else { // DESC
          return a.fullName < b.fullName ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.bySpecialist = !this.bySpecialist
    },
    sortDate() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byDate) {  // ASC
          return moment(a.availabilityDate) > moment(b.availabilityDate) ? 1 : -1
        } else { // DESC
          return moment(a.availabilityDate) < moment(b.availabilityDate) ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byDate = !this.byDate
    },
    sortBooking() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byBooking) {  // ASC
          return a.numberOfBookings > b.numberOfBookings ? 1 : -1
        } else { // DESC
          return a.numberOfBookings < b.numberOfBookings ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byBooking = !this.byBooking
    },
    sortLocation() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byLocation) {  // ASC
          return a.fullLocation > b.fullLocation ? 1 : -1
        } else { // DESC
          return a.fullLocation < b.fullLocation ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byLocation = !this.byLocation
    },
    formatDate(dateToFormat) {
      return utilities.formatDate(dateToFormat, "DD/MM/YYYY")
    },
    paging(availabilities) {
      utilities.disposeTooltip()
      if(availabilities.length == 0 || availabilities.length <= this.limit) {
        this.availabilities = availabilities
        $(".pagination").hide()
      } else {
        this.pagination = utilities.loadPagination(availabilities, this.limit)
        this.availabilities = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      }
      utilities.initTooltip()
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      var {specialistAvailabilities, total} = await utilities.getAvailabilitiesInMenus(this.listParam)
      this.totalCount = total
      this.allAvailabilities = _.cloneDeep(specialistAvailabilities)
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadDefaultData() {
      const apiArr = [utilities.getQualifications(), utilities.getLocations(), utilities.getSpecialists()]
      const r = await Promise.all(apiArr)
      this.qualification = r[0]
      this.locations = r[1]
      this.specialists = r[2]
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $('body>.tooltip').remove()
    await this.loadData()
    await this.loadDefaultData()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
