/* eslint-disable */
<template>
  <div class="horizontal-year-calendar">
    <v-btn-toggle v-model="year" mandatory class="pl-3 pt-3">
      <v-btn class="radio-toggle" :value="getYear()" @click="changeYear(getYear())">
        {{ getYear() }}
      </v-btn>
      <v-btn class="radio-toggle" :value="getNextYear()" @click="changeYear(getNextYear())">
        {{ getNextYear() }}
      </v-btn>
    </v-btn-toggle>
    <v-sheet>
      <calendar
          :key="calendarKey"
          :year="year"
          :display-header="false"
          :sync="true"
          :data-source="dataSource"
          @mouse-on-day="mouseOnDay"
          @click-day="goToDay"
          render-style="background"
          :customDayRenderer="customDayRenderer"
          @render-end="hideMonths()"
      />
    </v-sheet>
    <div style="display: flex; justify-content: space-between; padding-top: 15px; padding-bottom: 15px;" class="">
      <div class="box-col">
        {{ messageProps }}
      </div>
      <div class="box-col calender-item">
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-video"></div>
          <div class="indicator-text pe-1">Video appointments Only</div>
          <img style="height: 30px" class="me-2" :src="require('@/assets/images/video_black_icon.svg')"  />
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-video-person"></div>
          <div class="indicator-text pe-1">Face to Face or Video Link Appointments</div>
          <img style="height: 30px" class="me-2" :src="require('@/assets/images/user_video_icon_black.svg')"  />
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-approved"></div>
          <div class="indicator-text">Face to Face</div>
          <img style="height: 26px" class="me-2" :src="require('@/assets/images/face2face_black.svg')"  />
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-fullbook"></div>
          <div class="indicator-text">Wait List</div>
        </div>
        <div style="display: flex" class="align-items-center">
          <div class="calender-indicator calender-request"></div>
          <div class="indicator-text">By Request Only</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="calender-indicator calender-submited"></div>
          <div class="indicator-text">Unconfirmed</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default {
  name: 'HorizontalYearCalendar',
  props: ['calendarDateProps', 'dataSourceProps', 'messageProps', 'pathProps', 'isFromClient', 'buttonProps', 'isFromSpecialist'],
  components: {},
  data() {
    return {
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      dataSource: this.dataSourceProps != undefined ? this.dataSourceProps : [],
      year: this.calendarDateProps,
      showButtons: this.buttonProps != undefined ? this.buttonProps : false,
      filteredSource: [],
      calendarKey: 0,
    }
  },
  watch: {
    dataSourceProps: {
      handler: function (val) {
        this.dataSource = val
        this.calendarKey += 1
      }
    }
  },
  methods: {
    customDayRenderer(element, date) {
      let filter = this.dataSource.find(item => moment(item.date).isSame(moment(date), 'day'))
      if (filter && filter.status === 'request only') {
        element.parentElement.classList.add('calender-request')
      } else if (filter && filter.status === 'submitted') {
        element.parentElement.classList.add('calender-submited')
      } else if (filter && filter.status === 'fully booked') {
        element.parentElement.classList.add('calender-fullbook')
      } else if (filter && filter.status === 'video link') {
        element.parentElement.classList.add('calender-video-person')
      } else if (filter && filter.status === 'video only') {
        element.parentElement.classList.add('calender-video')
      } else if (filter && filter.status === 'available'){
        element.parentElement.classList.add('calender-approved')
      }
    },
    mouseOnDay: function (e) {
      if (e.events.length > 0) {
        var content = ''
        for (var i in e.events) {
          content +=
              `<div class="event-tooltip-content overflow-y-auto" style="max-height: 500px">`
          e.events[i].Availabilities.sort((a, b) => {
            return window.moment(a.StartTime, 'HH:mm').isAfter(window.moment(b.StartTime, 'HH:mm')) ? 1 : -1
          }).forEach(item => {
            content += `<div style="cursor: ${this.isFromSpecialist ? 'pointer' : 'default'}" class="border-bottom d-flex flex-column align-items-baseline pt-1 pb-1" onclick='goToBook(${JSON.stringify(item)})'>
<div class="d-flex align-items-center justify-content-between w-100">
<span>${e.events[i].specialist ? e.events[i].specialist.fullName : ''}</span>
${item.AvailabilityStatus === '1' ? '<span class="ml-2 blue--text">Unconfirmed</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'A' ? '<span class="ml-2 green--text">Unbooked</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'B' ? '<span class="ml-2 red--text">Booked</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'H' ? '<span class="ml-2 red--text">On hold</span>' : ''}
</div>
<div class="d-flex justify-content-between align-items-center w-100">
<span>${utilities.formatDate(item.StartTime, 'HH:mm', 'HH:mm:ss')} ~ ${utilities.formatDate(item.EndTime, 'HH:mm', 'HH:mm:ss')}</span>
</div>
<span>${item.Location ? decodeURIComponent(item.Location.LocationName) : ''}</span>
<div class="d-flex align-items-center mt-1">Options:
${item.Options.VideoOnly ? '<div class="video-icon"></div>' : '' }
${item.Options.VideoLinkAvailable ? '<div class="user-video-icon"></div>' : '' }
${(!item.Options.VideoLinkAvailable && !item.Options.VideoOnly && !item.Options.ByRequestOnly) ? '<div class="face-icon"></div>' : '' }
${item.Options.ShowWorkCover ? '<div class="show-worker-icon"></div>' : '' }
${item.Options.ShowSpecificClients ? `<div onclick='showClientModal(${JSON.stringify(item.SpecificClients)})' class="specific-client-icon cursor-pointer"></div>` : '' }
${item.Options.ByRequestOnly ? '<div class="request-icon"></div>' : '' }
</div>
<div class="d-flex align-items-center mt-1"> Action:
${item.AvailabilityStatus === '1' && !this.isFromSpecialist ? `<div class="tick-icon" onclick='approveSingle(${JSON.stringify(item)})'></div>` : '' }
${item.AvailabilityStatus === '1' && !this.isFromSpecialist ? `<div class="circle-close-icon" onclick='rejectSingle(${JSON.stringify(item)})'></div>` : '' }
${((item.AvailabilityStatus === '1' && this.isFromSpecialist) || !this.isFromSpecialist)  ? `<div class="edit-icon" onclick='editSingle(${JSON.stringify(item)}, ${JSON.stringify(e.events[i].date)})'></div>` : ''}
</div>
</div>
`
          })
        }

        if (this.tooltip != null) {
          this.tooltip.destroy()
          this.tooltip = null
        }

        this.tooltip = tippy(e.element, {
          placement: 'right',
          content: content,
          trigger: 'click',
          interactive: true,
          interactiveBorder: 1000,
          interactiveDebounce: 10000,
          allowHTML: true,
          arrow: true,
          arrowType: 'round',
          delay: 1000,
          onShown(instance) {
            if (e.events.length > 3) {
              $(".tippy-box .tippy-content").css("height", "330px")
            } else {
              $(".tippy-box .tippy-content").css("height", "auto")
            }
          },
        })
        this.tooltip.show()
      } else {
        this.tooltip && this.tooltip.hide()
      }
    },
    mouseOutDay: function (e) {
      if (this.tooltip != undefined) this.tooltip.hide()
    },
    approveAll() {
      this.$parent.approveAll()
    },
    approveSingle(e) {
      this.$parent.approveSingle(e)
    },
    editSingle(e, date) {
      let param = e
      param.date = date
      this.$parent.showEditAvailabilityModal(param)
    },
    rejectSingle(e) {
      this.$parent.rejectSingle(e)
    },
    goToBook(e) {
      if(this.isFromSpecialist && e.BookingID) {
        this.$router.push('/specialist-booking-detail?id=' + e.BookingID)
      }
    },

    putBooking(booking, specialist) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        BookingID: booking.bookingDetails.id,
        SpecialistID: specialist.id,
        AvailabilityID: booking.bookingDetails.specialistAvailabilityId,
        LocationID: booking.bookingDetails.locationId,
        LocationName: booking.location.fullLocation.replace(', Australia', ""),
        BookingTypeID: booking.bookingDetails.BookingTypeID,
        BookingTypeName: booking.bookingDetails.bookingTypeName,
        CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        BookingDateTime: moment(booking.bookingDetails.dateTime).format("YYYY-MM-DD HH:mm:ss"),
        BookingEndTime: utilities.formatDate(booking.bookingDetails.endTime, "HH:mm:ss", "HH:mm:ss.SSSSSS"),
        IsAdmin: 1,

        ZohoLink: booking.bookingDetails.zohoLink.split('/')[booking.bookingDetails.zohoLink.split('/').length - 1],
        ZohoBookingDateTime: moment(booking.bookingDetails.dateTime).format(),
        SpecialistZohoLink: specialist.zohoLink,
        RescheduleDueToAvailabilitySplit: 1,
      }

      var callback = (response) => {
      }

      return utilities.putBooking(params, callback)
    },

    async updateAvailability(id, selfId, parentId = '', availabilityId = 0) {
      if (id != '' && Number(selfId) > 0) {  // new availability without loading page
        this.dataSource
            .filter((x) => x.selfId == selfId && x.id == id)
            .map((x) => {
              // Approved
              x.status = 3
              // x.color = utilities.getBookingStatusColor(x.status)
            })
      } else {  // new availability after loading page
        this.dataSource
            .filter((x) => x.availabilityId == availabilityId)
            .map((x) => {
              // Approved
              x.status = 3
              // x.color = utilities.getBookingStatusColor(x.status)
            })
      }

      this.calendarKey += 1

      if (parentId != '') {
        await this.$parent.approveAvailability(id, parentId)
        this.$parent.successMessage = 'Availability updated successfully.'
        utilities.showAlert('.year-calendar .alert-success')
        setTimeout(() => {
          this.$parent.reload()
          if (this.$parent.selectedYear != '') {
            this.changeYear(this.$parent.selectedYear)
          }
        }, 1500)
      }
    },
    removeAvailability(id, selfId, availabilityId = 0) {
      if (id != '' && Number(selfId) > 0) {  // new availability without loading page
        this.dataSource = this.dataSource.filter((x) => {
          return !(x.selfId == selfId && x.id == id)
        })
      } else { // // new availability after loading page
        this.dataSource = this.dataSource.filter((x) => {
          return !(x.availabilityId == availabilityId)
        })
      }
    },
    goToDay(e) {
      if (this.pathProps != undefined) {
        var dateToGo = moment(e.date).format('YYYY-MM-DD')
        var query = {}
        if (e.events[0] != undefined && e.events[0].specialist.id != '' && !this.pathProps.includes('id')) {
          query.id = e.events[0].specialist.id
          query.date = moment(dateToGo).format('YYYY-MM-DD')

          var routeData = this.$router.resolve({path: this.pathProps, query: query})
          window.open(routeData.href, '_blank')
        }
      } else {
        if (e.events.length == 1 && e.events[0]) {
        } else {
        }
      }
    },
    hideMonths() {
      utilities.hideMonths(this.year)
    },
    getYear() {
      return moment().format('YYYY')
    },
    getNextYear() {
      return moment()
          .add(1, 'y')
          .format('YYYY')
    },
    changeYear(year) {
      this.$parent.selectedYear = year
      this.year = year
      this.dataKey += 1
    },
    showClientModal(e) {
      let content = '<div style="padding: 10px"><b>Show only to these clients:</b>'
      e.forEach(item => {
        content += `<div>${decodeURIComponent(item.ClientName)}</div>`
      })
      content += '</div>'
      this.$notify({
        message: content,
        dangerouslyUseHTMLString: true,
        duration: 10000
      });
    },
  },
  beforeMount() {
  },
  mounted() {
    window.approveSingle = this.approveSingle
    window.editSingle = this.editSingle
    window.rejectSingle = this.rejectSingle
    window.showClientModal = this.showClientModal
    window.goToBook = this.goToBook
    if (this.$parent.selectedYear != '') {
      this.changeYear(this.$parent.selectedYear)
    }
  },
}
</script>
