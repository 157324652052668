/* eslint-disable */
<template>
  <div class="AdminManageBookingRequest">
    <AdminNavigation ref="navigation" indexProps="1"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Booking Request</h1></div>
        <div class="header-sub-title">We look forward to helping you arrange the service you require for your claimant.
          Please tell us about your requirements, so that we can find the right specialist for your needs.
        </div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu indexProps="1"/>
      <div class="content">
        <!-- <div v-if="showCaseInfo" style="padding-left: 10px;">
          <span class="text-bold">Claimant:</span> {{ singleCase.claimantFullName }} <span class="text-bold"> D.O.B:</span> {{ formatDate(singleCase.dateOfBirth) }}
          <span class="text-bold"> Commencement Date: </span> {{ singleCase.commencementDate }} <span class="text-bold"> Type of Claim:</span> {{getClaimType(singleCase.claimTypeId)}}
        </div> -->
        <div class="box booking-details">
          <div class="box-heading col-space-between">
            <div>Booking Request details</div>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Booking Type</div>
              <div class="box-col box-col-right">
                <el-select @change="onChangeBookingType" filterable popper-class="search-specialty-popup" class="w-50" placeholder="Please choose" :class="hasError && !validateBookingType ? 'is-invalid' : ''"
                        v-model="bookingRequest.bookingTypeId">
                  <el-option v-for="bookingType in bookingTypes" :key="bookingType.id" :value="bookingType.id" :label="bookingType.name">
                    {{ bookingType.name }}
                  </el-option>
                </el-select>
                <div v-if="hasError && !validateBookingType" class="text-danger">Booking Type is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Type of Claim</div>
              <div class="box-col box-col-right">
                <el-select filterable placeholder="Please choose type of claim..." popper-class="search-specialty-popup" class="w-50" v-model="bookingRequest.claimTypeId">
                  <el-option v-for="claimType in claimTypes" :value="claimType.id" :key="claimType.id" :label="claimType.name">{{ claimType.name }}</el-option>
                </el-select>
                <div v-if="hasError && !validateClaimType" class="text-danger">Claim Type is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Client</div>
              <div class="box-col box-col-right">
                <el-select popper-class="search-specialty-popup" filterable clearable placeholder="Please choose client..." class="w-50" v-model="bookingRequest.clientId" @change="changeClient">
                  <el-option v-for="client in clients" :value="client.id" :key="client.id" :label="client.fullName">{{ client.fullName }}</el-option>
                </el-select>
                <div v-if="hasError && !validateClient" class="text-danger">Client is required.</div>
              </div>
            </div>
            <div v-if="isNewClaimant" class="box-row">
              <div class="box-col box-col-left">Contact</div>
              <div class="box-col box-col-right">
                <el-select popper-class="search-specialty-popup" filterable clearable  class="w-50" placeholder="Please choose contact..." v-model="caseOwner">
                  <el-option v-for="(contact, index) in clientContacts.contacts" :key="index"
                          :value="contact.contactUserId" :label="contact.fullName">{{ contact.fullName }}
                  </el-option>
                </el-select>
                <div class="text-danger" v-if="hasError && !validateContact">
                  Please select a case owner.
                </div>
              </div>
            </div>
<!--            <div class="box-row">-->
<!--              <div class="box-col box-col-left" :class="isHideSpecialty ? 'disabled-element' : ''">Required Specialty, if known</div>-->
<!--              <div class="box-col box-col-right">-->
<!--                <el-select filterable class="w-50" placeholder="Please choose" popper-class="search-specialty-popup"  :class="hasError && !validateBookingRequest ? 'is-invalid' : ''"-->
<!--                        v-model="specialtyId" @change="changeSpecialty">-->
<!--                  <el-option v-for="(specialty, index) in qualifications" :key="specialty.id" :label="specialty.name" :value="specialty.id">{{-->
<!--                      specialty.name-->
<!--                    }}-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--                <div v-if="hasError && !validateBookingRequest" class="text-danger">Speciality or Specialist is-->
<!--                  required.-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="box-row">
              <div class="box-col box-col-left" :class="!isHideSpecialty ? 'disabled-element' : ''">Choose Specialist</div>
              <div class="box-col box-col-right">
                <el-select class="w-50" popper-class="search-specialty-popup" filterable  placeholder="Please choose" :class="hasError && !validateBookingRequest ? 'is-invalid' : ''"
                        v-model="bookingRequest.specialistId" @change="changeSpecialist">
                  <el-option v-for="(specialist, index) in filteredSpecialists" :key="specialist.id" :label="specialist.fullName" :value="specialist.id">{{
                      specialist.fullName
                    }}
                  </el-option>
                </el-select>
                <div v-if="hasError && !validateBookingRequest" class="text-danger">Specialist is
                  required.
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Claimant</div>
              <div class="box-col box-col-right">
                <div class="is-icon">
                  <input @click="openAddNameModal" v-if="bookingRequest.claimantFullName.length == 0" readonly class="pl-3 w-50"
                         placeholder="Click to add Claimant"/>
                  <span @click="openAddNameModal" v-else class="padding-left-10">
                    {{ bookingRequest.claimantFullName }}
                  </span>
                </div>
                <div v-if="hasError && !validateClaimant" class="text-danger">Claimant is required.</div>
              </div>
              <AdminAddNameModal ref="adminAddNameModal"/>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Date of Birth</div>
              <div class="box-col box-col-right">
                <datepicker v-model="bookingRequest.dateOfBirth"
                            class="w-50"
                            :input-class="hasError && !validateDateOfBirth ? 'is-invalid' : ''"
                            format="dd/MM/yyyy"
                            :typeable="true"
                            placeholder="dd/mm/yyyy"></datepicker>
                <div v-if="hasError && !validateDateOfBirth" class="text-danger">Date of birth is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Affected Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                    v-model="bodyAreaTag"
                    :tags="bodyAreaTags"
                    placeholder="Add Affected Body Areas"
                    :autocomplete-items="filteredBodyAreaItems"
                    @tags-changed="(newTags) => (bodyAreaTags = newTags)"
                    @focus="openDigram()"
                />
                <div @click="openBodyDiagram">
                  <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3"
                       style="top:50%;margin-top:-18px;"/>
                </div>
                <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal"/>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Other Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                    v-model="otherBodyAreaTag"
                    :tags="otherBodyAreaTags"
                    placeholder="Add Other Body Areas"
                    @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Conditions</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                    v-model="personConditionTag"
                    :tags="personConditionTags"
                    class="pl-2"
                    placeholder="Add Conditions"
                    :autocomplete-items="filteredPersonConditionsItems"
                    @tags-changed="(newTags) => (personConditionTags = newTags)"
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Choose Region / Location</div>
              <div class="box-col box-col-right">
                <treeselect ref="treeSelect"
                            class="w-50"
                            :multiple="false"
                            :searchable="false"
                            :closeOnSelect="false"
                            :options="regionLocations"
                            :limit="7"
                            :max-height="200"
                            :defaultExpandLevel="0"
                            placeholder="Please choose ..."
                            noOptionsText="No regions"
                            noChildrenText="No cities"
                            v-model="bookingRequest.regionId"
                            @select="changeRegion"/>
                <div v-if="hasError && !validateLocation" class="text-danger">Region / Location is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Specify Date Range</div>
              <div class="box-col box-col-right">
                <v-md-date-range-picker @change="changeDateRange"></v-md-date-range-picker>
                <div class="text-danger" v-if="hasError && !validateDateRange">
                  Please Specify Date Range
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Face to Face or Video Link Appointments</div>
              <div class="box-col box-col-right">
                <input type="checkbox" id="checkbox" :sync="true" v-model="bookingRequest.videoLinkAccepted"/>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-heading tab">
            <div class="tab-heading" @click="openTab(0)">Notes</div>
            <div class="tab-heading" @click="openTab(1)">Files</div>
            <div class="right-element">
              <v-btn class="add-icon" dark @click="openAddNew">Add New</v-btn>
              <AdminAddNoteModal ref="adminAddNoteModal"/>
            </div>
          </div>
          <div class="box-body tab-content hidden-element">
            <div v-if="bookingRequest.note != ''" class="box-row is-icon" @click="openAddNoteModal">
              <div class="box-col">{{ user.name }}</div>
              <div class="box-col">{{ getCurrentDate() }}</div>
              <div class="box-col">
                {{ bookingRequest.note }}
              </div>
            </div>
          </div>
          <div class="box-body tab-content hidden-element">
            <div>
              <div class="content-scroll-y" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                  <tbody>
                  <tr v-for="(file, index) in filelist" class="py-0">
                    <th style="width:90%" class="long-text"><a :href="file.url" target="blank">{{ file.name }}</a></th>
                    <th style="width:10%" class="icon-col">
                      <div class="icon-group box-date-time-end">
                        <img src="@/assets/images/delete_icon.svg" @click="deleteFile(index)"/>
                      </div>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV"/>
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex flex-column align-items-center justify-content-center">
                <div>Drop files in this area or <span style="text-decoration:underline"
                                                      class="is-icon">click here</span> to upload files.
                </div>
                <div>Files over 200MB in file size may cause errors. If you need to upload files of this size, please contact us on 1300 100 733</div>
              </label>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>

        <div class="box-row item-right box-col-next">
          <div>
            <v-btn dark @click="submit()">Submit</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminBookingsMenu from '@/components/AdminBookingsMenu'
import Header from '@/components/Header'
import AdminAddNameModal from '@/components/AdminAddNameModal'
import AdminAddNoteModal from '@/components/AdminAddNoteModal'
import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'

export default {
  name: 'AdminManageBookingRequest',
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
    AdminAddNameModal,
    AdminAddNoteModal,
    AddBodyDiagramModal
  },
  data() {
    return {
      hasError: false,
      isNewClaimant: false,
      successMessage: '',
      errorMessage: '',
      caseOwner: '',
      isHideSpecialty: true,
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      bookingRequest: utilities.initBookingRequest(),
      specialtyId: '',
      singleCase: utilities.initCase(),
      otherBodyAreaTags: [],
      bookingTypes: [],
      claimTypes: [],
      clients: [],
      cases: [],
      qualifications: [],
      specialists: [],
      filteredSpecialists: [],
      regions: [],
      locations: [],
      regionLocations: [],
      fileTypes: [],
      otherBodyAreaTag: "",
      bodyAreas: [],
      bodyAreaTag: '',
      bodyAreaTags: [],
      personConditions: [],
      personConditionTag: '',
      personConditionTags: [],
      filelist: [],
      clientContacts: [],

    }
  },
  computed: {
    filteredBodyAreaItems() {
      return this.bodyAreas.filter((i) => {
        return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1
      })
    },
    filteredPersonConditionsItems() {
      return this.personConditions.filter((i) => {
        return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
      })
    },
    validateBookingType() {
      if (this.bookingRequest.bookingTypeId == '') {
        return false
      }
      return true
    },
    validateBookingRequest() {
      if (this.specialtyId == '' && this.bookingRequest.specialistId == '') {
        return false
      }
      return true
    },
    validateClaimType() {
      if (this.bookingRequest.claimTypeId == '') {
        return false
      }
      return true
    },
    validateClient() {
      if (this.bookingRequest.clientId == '') {
        return false
      }
      return true
    },
    validateClaimant() {
      if (this.bookingRequest.claimantFullName == '') {
        return false
      }
      return true
    },
    validateDateOfBirth() {
      if (this.bookingRequest.dateOfBirth == '') {
        return false
      }
      return true
    },
    validateLocation() {
      if (this.bookingRequest.regionId == null) {
        return false
      }
      return true
    },
    validateDateRange() {
      if (this.bookingRequest.dateFrom == '') {
        return false
      }
      return true
    },
    validateContact() {
      if (this.isNewClaimant && this.caseOwner == '') {
        return false
      }
      return true
    },
  },
  methods: {
    openDigram() {
      this.openBodyDiagram()
    },

    onChangeBookingType() {
      switch (this.bookingRequest.bookingTypeId) {
        case 2:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceIME === true)
          break;
        case 3:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceIMEAbuse === true)
          break;
        case 4:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceIMEVideo === true)
          break;
        case 5:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceCourtCase === true)
          break;
        case 6:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceFileReview === true)
          break;
        case 7:
          this.filteredSpecialists = this.specialists.filter(item => item.servicePhoneCall === true)
          break;
        case 8:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceSupplementary === true)
          break;
        case 10:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceIMEShortReport === true)
          break;
        case 12:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceSupplementaryByVideo === true)
          break;
        case 13:
          this.filteredSpecialists = this.specialists.filter(item => item.serviceMedicalNegligenceRequest === true)
          break;
        case 14:
          this.filteredSpecialists = this.specialists.filter(item => item.fceLite === true)
          break;
        case 15:
          this.filteredSpecialists = this.specialists.filter(item => item.fceLiteByVideo === true)
          break;
        default:
          this.filteredSpecialists = _.cloneDeep(this.specialists)
          break
      }
    },

    openBodyDiagram() {
      this.$refs.addBodyDiagramModal.handleModal()
      this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
      this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
      this.$refs.addBodyDiagramModal.handleClick()
    },

    setBodyArea(addedBodyAreas) {
      this.bodyAreaTags = []
      this.bodyAreaTags = addedBodyAreas
    },

    deleteBodyAreaItems() {
    },

    formatDate(dateOfBirth, newFormat) {
      return utilities.formatDate(dateOfBirth, newFormat)
    },
    getCurrentDate() {
      return moment().format('DD/MM/YYYY hh:mm A')
    },
    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },
    openTab(index) {
      utilities.openTab(index)
    },
    openAddNew() {
      if ($(".tab-content").eq(0).is(":visible")) this.openAddNoteModal()
      else this.openAddFile()
    },
    openAddNoteModal() {
      if (this.bookingRequest.note != '') this.$refs.adminAddNoteModal.loadNote(this.bookingRequest.note)
      this.$refs.adminAddNoteModal.handleModal()
    },
    openAddFile() {
      $('#assetsFieldHandle').trigger('click')
    },
    openAddNameModal() {
      this.$refs.adminAddNameModal.cases = this.cases
      console.log(this.bookingRequest)
      if (this.bookingRequest.claimantFullName != '') this.$refs.adminAddNameModal.loadName(this.bookingRequest)
      this.$refs.adminAddNameModal.handleModal()
    },
    changeRegion(item, e) {
      if (item.id.includes('s')) {  // Disable select state
        this.$refs.treeSelect.clear()
        var index = this.regionLocations.findIndex(x => x.id == item.id)
        if (index > -1) {
          var mouseEvent = document.createEvent("MouseEvents")
          mouseEvent.initMouseEvent("mousedown", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
          $(".vue-treeselect__option[data-id*='s'] .vue-treeselect__option-arrow-container")[index].dispatchEvent(mouseEvent)
        }
      } else {
        this.$refs.treeSelect.closeMenu()
      }
    },
    async changeClient() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.bookingRequest.caseId = ""
      this.cases = await utilities.getSearchCases({ClientID: this.bookingRequest.clientId})
      this.cases.sort((a, b) => {
        return a.claimantFullName > b.claimantFullName ? 1 : -1
      })

      this.clientContacts = await utilities.getClientById(this.bookingRequest.clientId)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    changeSpecialty() {
      this.bookingRequest.specialistId = ""
      this.isHideSpecialty = false
    },
    changeSpecialist() {
      this.specialtyId = ""
      this.isHideSpecialty = true
    },
    loadName(name) {
      if (name.caseId == '') {
        this.isNewClaimant = true
      } else {
        var primaryContact = this.clientContacts.contacts.find((x) => x.primaryContact)
        this.caseOwner = primaryContact != undefined ? primaryContact.contactUserId : this.user.id
      }
      this.bookingRequest.caseId = name.caseId
      this.bookingRequest.claimantFirstName = name.firstName
      this.bookingRequest.claimantGivenNames = name.middleName
      this.bookingRequest.claimantSurname = name.surname
      this.bookingRequest.claimantFullName = utilities.getClaimantFullName(this.bookingRequest)
      this.bookingRequest.dateOfBirth = name.dateOfBirth
    },
    loadNote(note) {
      this.bookingRequest.note = note
    },
    changeDateRange(item) {
      this.bookingRequest.dateFrom = utilities.formatDate(item[0])
      this.bookingRequest.dateTo = utilities.formatDate(item[1])
    },
    async onChange() {
      this.filelist = [...this.filelist, ...this.$refs.file.files]
    },
    deleteFile(index) {
      this.filelist.splice(index, 1)
    },
    dragover(event) {
      event.preventDefault()
      event.currentTarget.classList.add('dark-border-hover')
    },
    dragleave(event) {
      event.currentTarget.classList.remove('dark-border-hover')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },
    async getLocationData() {
      var locations = await utilities.getLocations()
      var groupedLocations = []
      locations.map((x) => {
        if (groupedLocations[x.regionId] == undefined) {
          groupedLocations[x.regionId] = []
        }
        groupedLocations[x.regionId].push(x)
      })
      var tempLocations = []
      groupedLocations.map((x) => {
        var obj = {
          id: 'r' + x[0].regionId,
          label: x[0].regionName,
        }
        var children = []
        for (var i = 0; i < x.length; i++) {
          children.push({
            id: 'c' + x[i].cityId,
            label: x[i].cityName,
          })
        }
        children = _.uniqBy(children, 'label')
        obj.children = children
        tempLocations.push(obj)
      })
      this.locations = tempLocations
    },
    async submit() {
      this.hasError = false
      if (!this.validateBookingType || !this.validateClient || !this.validateBookingRequest || !this.validateLocation || !this.validateDateRange || !this.validateContact || !this.validateClaimant || !this.validateDateOfBirth) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".alert-danger")
        return
      }

      this.$store.dispatch('loading/setLoadComponent', true)
      var result = await this.postBookingRequest()
      if (result) {
        var params = {
          BookingRequestID: this.bookingRequest.id,
          UserID: this.user.id,
          Username: this.user.name,
          SubmittedBy: this.user.id,
          FileObject: [],
        }
        if (this.filelist.length > 0) {
          let item = []
          await Promise.all(this.filelist.map(async (x) => {
            if (this.bookingRequest.id != '') {
              var fileName = window.moment().unix() + '_' + x.name.replace(/\s/g, '_')
              var fileParams = {
                fn: fileName,
                ft: x.type,
                id: this.bookingRequest.id,
              }
              var result = await utilities.postRequestPreSign(fileParams)
              var postData = new FormData();

              for (var key in result.fields) {
                postData.append(key, result.fields[key]);
              }
              postData.append('file', x)
              const r = await utilities.postImage(result.url, postData)
              if(r) {
                item.push({
                  BookingRequestID: this.bookingRequest.id,
                  DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
                  FileName: fileName,
                  MimeType: x.type,
                  FileTypeID: 5,
                  UploadedBy: this.user.id,
                  UploadedByName: this.user.name
                })
              }
            }
          }))
          if(item.length > 0) {
            await utilities.postRequestFileRecord(item)
          }
        }
        this.successMessage = 'Your Booking Request has been added.'
        utilities.showAlert('.alert-success')
        setTimeout(() => {
          this.$router.push({name: 'admin-booking-requests', params: {}})
        }, 2000)
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    postBookingRequest() {
      var bodyAreaObjects = []
      var bodyArea = {}
      if (this.bodyAreaTags.length > 0) {
        this.bodyAreaTags.map((x) => {
          bodyArea = {
            BodyAreaID: x.value,
          }
          bodyAreaObjects.push(bodyArea)
        })
      }

      var conditionObject = []
      var condition = {}
      if (this.personConditionTags.length > 0) {
        this.personConditionTags.map((x) => {
          condition = {
            PersonConditionID: x.value,
          }
          conditionObject.push(condition)
        })
      }

      var qualifications = []
      var qualification = {}
      if (this.specialtyId) {
        qualification = {
          QualificationID: this.specialtyId,
        }
        qualifications.push(qualification)
      }

      var params = {
        CaseID: this.bookingRequest.caseId,
        ClientID: this.bookingRequest.clientId,
        SpecialistID: this.bookingRequest.specialistId,
        BookingTypeID: this.bookingRequest.bookingTypeId,
        ClaimTypeID: this.bookingRequest.claimTypeId,
        DateFrom: this.bookingRequest.dateFrom,
        DateTo: this.bookingRequest.dateTo,
        VideoLinkAccepted: utilities.convertBoolToNum(this.bookingRequest.videoLinkAccepted),
        BookingRequestStatus: 1,
        ClaimantFirstName: this.bookingRequest.claimantFirstName,
        ClaimantGivenNames: this.bookingRequest.claimantGivenNames,
        ClaimantSurname: this.bookingRequest.claimantSurname,
        DateofBirth: this.formatDate(this.bookingRequest.dateOfBirth, 'YYYY-MM-DD'),
        Notes: this.bookingRequest.note,
        BodyAreaObject: bodyAreaObjects,
        ConditionObject: conditionObject,
        QualificationObject: qualifications,
        SubmittedBy: this.caseOwner != '' ? this.caseOwner : this.user.id,
        // FileObject: [],
        UserID: this.user.id,
        Username: this.user.name,
        DateSubmitted: moment().format('YYYY-MM-DD HH:mm:ss'),
      }
      if (this.bookingRequest.regionId.includes('c')) {
        params.CityID = this.bookingRequest.regionId.replace('c', '')
      } else {
        params.RegionID = this.bookingRequest.regionId.replace('r', '')
      }
      let otherBodyArea = ""
      this.otherBodyAreaTags.map((x) => {
        if (otherBodyArea == "") {
          otherBodyArea = x.text
        } else {
          otherBodyArea = otherBodyArea + "," + x.text
        }
      })
      params.BodyAreaOther = otherBodyArea
      var callback = (response) => {
        this.bookingRequest.id = response
      }
      return utilities.postBookingRequest(params, callback)
    },
    loadData() {
      this.bookingRequest.specialistId = this.$route.query.spId != undefined ? this.$route.query.spId : ''
      const promiseArr = [utilities.getBookingTypes(), utilities.getClaimTypes(), utilities.getClients(), utilities.getQualifications(), utilities.getSpecialists(), utilities.getBodyArea(), utilities.getPersonConditions(), utilities.getRegions(), utilities.getLocations(), utilities.getFileTypes()]
      Promise.all(promiseArr).then(r => {
        const bookingTypes = r[0]
        this.bookingTypes = bookingTypes.filter(x => x.id !== 1 && x.id !== 3 && x.id !== 9 && x.id !== 10 && x.id !== 11 && x.id !== 12) // Not including Additional Hours
        this.claimTypes = r[1]
        this.clients = r[2]
        this.qualifications = r[3]
        this.specialists = r[4]
        this.filteredSpecialists = r[4]
        const bodyAreas = r[5]
        this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)
        const personConditions = r[6]
        this.personConditions = utilities.getAutoCompleteTags(personConditions)
        this.regions = r[7]
        this.locations = r[8]
        this.regionLocations = utilities.getRegionLocations(this.regions, this.locations)
        const types = r[9]
        types.map((x) => {
          if (x.name != 'Travel Itinerary') {
            this.fileTypes.push(x)
          }
        })
        this.$store.dispatch('loading/setLoadComponent', false)
      })
    },
  },
  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    $('body>.tooltip').remove()
    this.loadData()
    this.openTab(0)
  },
  mounted() {
  },
}
</script>
