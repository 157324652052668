<template>
  <div class="related-services-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <!-- <h1>{{ relatedService.afterQualification == '' ? 'Add' : 'Update' }} Related Service</h1> -->
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close" style="right:52px"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="row">
                <div class="col-12">
                  <strong>After Booking...</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-3 left-label d-flex align-items-center">Specialist Qualification</div>
                <div class="col-9">
                  <select @change="isEdited"  v-model="relatedService.qualificationId" :class="hasError && !validateQualification ? 'is-invalid' : ''">
                    <option value="" hidden>Please choose ...</option>
                    <option v-for="qualification in qualifications" :value="qualification.id">{{ qualification.name }}</option>
                  </select>
                  <div v-if="hasError && !validateQualification" class="text-danger">is required.</div>
                </div>
              </div>
              <div class="row">
                <div class="col-3 left-label d-flex align-items-center">Booking Type</div>
                <div class="col-9">
                  <select @change="isEdited" v-model="relatedService.bookingTypeId" :class="hasError && !validateBookingType ? 'is-invalid' : ''">
                    <option value="" hidden>Please choose ...</option>
                    <option v-for="bookingType in bookingTypes" :value="bookingType.id">{{ bookingType.name }}</option>
                  </select>
                  <div v-if="hasError && !validateBookingType" class="text-danger">is required.</div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-12">
                  <strong>...Then offer</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-3 left-label d-flex align-items-center">Booking Type</div>
                <div class="col-9">
                  <select @change="isEdited" v-model="relatedService.offerBookingTypeId" :class="hasError && !validateOfferBookingType ? 'is-invalid' : ''">
                    <option value="" hidden>Please choose ...</option>
                    <option v-for="bookingType in bookingTypes" :value="bookingType.id">{{ bookingType.name }}</option>
                  </select>
                  <div v-if="hasError && !validateOfferBookingType" class="text-danger">is required.</div>
                </div>
              </div>
              <div class="row">
                <div class="col-3 left-label d-flex align-items-center">Specialist Qualification</div>
                <div class="col-9">
                  <select @change="isEdited" v-model="relatedService.offerQualificationId" :class="hasError && !validateOfferQualification ? 'is-invalid' : ''">
                    <option value="" hidden>Please choose ...</option>
                    <option v-for="qualification in qualifications" :value="qualification.id">{{ qualification.name }}</option>
                  </select>
                  <div v-if="hasError && !validateOfferQualification" class="text-danger">is required.</div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-3 left-label d-flex align-items-center">Wording</div>
                <div class="col-9">
                  <input
                    type="text"
                    v-model="relatedService.wording"
                    :class="hasError && !validateWording ? 'is-invalid' : ''"
                    placeholder="How would you like to describe this offer to the client?"
                  />
                  <div v-if="hasError && !validateWording" class="text-danger">is required.</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-danger" v-if="hasError && !validateUnique">
                  This relationship already exists. Please edit the existing relationship if you want to change the wording.
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave()" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminRelatedServicesModal',
    data() {
      return {
        loadModal: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        qualifications: [utilities.initQualification()],
        bookingTypes: [],
        hasError: false,
        isEdit: false
      }
    },
    computed: {
      validateQualification() {
        return this.relatedService.qualificationId != ''
      },
      validateBookingType() {
        return this.relatedService.bookingTypeId != ''
      },
      validateOfferBookingType() {
        return this.relatedService.offerBookingTypeId != ''
      },
      validateOfferQualification() {
        return this.relatedService.offerQualificationId != ''
      },
      validateWording() {
        return this.relatedService.wording != ''
      },

      validateUnique() {
          if (this.isEdit) {
            return this.$parent.relatedServices.find((x) => {
              return x.offerBookingTypeId == this.relatedService.offerBookingTypeId &&
                x.offerQualificationId == this.relatedService.offerQualificationId &&
                x.qualificationId == this.relatedService.qualificationId &&
                x.bookingTypeId == this.relatedService.bookingTypeId
            }) == undefined
          }
          return true
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      isEdited() {
        this.isEdit = true
      },

      async callSave() {
        this.hasError = false
        if (!this.validateQualification || !this.validateBookingType || !this.validateOfferBookingType || !this.validateOfferQualification || !this.validateWording || !this.validateUnique) {
          this.hasError = true
          return
        }
        if (!this.validateUnique) {
          return
        }
        var params = {
          QualificationID: this.relatedService.qualificationId,
          OfferQualificationID: this.relatedService.offerQualificationId,
          BookingTypeID: this.relatedService.bookingTypeId,
          OfferBookingTypeID: this.relatedService.offerBookingTypeId,
          Wording: this.relatedService.wording,
          UserID: this.user.id,
          Username: this.user.name,
        }
        if (this.relatedService.qualificationServiceRelationshipId == '') {
          // post
          var res = await utilities.postRelatedServices(params)
          if (res) {
            this.$store.dispatch('loading/setLoadComponent', true)
            this.$parent.relatedServices = await utilities.relationshipSpecialistQualifications()
            this.$store.dispatch('loading/setLoadComponent', false)
            this.$parent.successMessage = 'New qualification - service relationship saved successfully.'
            utilities.showAlert('.alert-success')
            this.handleModal()
          } else {
            this.$parent.errorMessage = 'An error occurred. Please try again.'
            utilities.showAlert('.alert-danger')
            this.handleModal()
          }
        } else {
          // put
          params.QualificationServiceRelationshipID = this.relatedService.qualificationServiceRelationshipId
          var res = await utilities.putRelatedServices(params)
          if (res) {
            this.$store.dispatch('loading/setLoadComponent', true)
            this.$parent.relatedServices = await utilities.relationshipSpecialistQualifications()
            this.$store.dispatch('loading/setLoadComponent', false)
            this.$parent.successMessage = 'New qualification - service relationship saved successfully.'
            utilities.showAlert('.alert-success')
            this.handleModal()
          } else {
            this.$parent.errorMessage = 'An error occurred. Please try again.'
            utilities.showAlert('.alert-danger')
            this.handleModal()
          }
        }
      },
    },
    async beforeMount() {
      var bookingTypes = await utilities.getBookingTypes()
      this.bookingTypes = bookingTypes.filter(x=>x.bookingTypeStatus == 1)
      this.qualifications = await utilities.getQualifications()
      this.hasError = false
    },
    mounted() {},
    updated() {},
  }
</script>
