/* eslint-disable */
<template>
  <div class="admin-edit-client-contact">
    <AdminNavigation v-if="!clientIdProps" ref="navigation" indexProps="2" />
    <div :class="{'top-panel' : !clientIdProps}" v-if="!clientIdProps">
      <Header />
      <div v-if="contact != undefined" class="header-title-section">
        <div class="header-title">
          <h1 v-if="!isNew">Edit Contact {{contact != undefined ? contact.fullName : ''}}</h1>
          <h1 v-else>Create New Contact</h1>
        </div>
        <div class="header-sub-title">View the details of a client contact.</div>
      </div>
    </div>
    <div :class="{'panel' : !clientIdProps}">
      <AdminClientsMenu v-if="!clientIdProps" :indexProps="3" />
      <div :class="{'content': !clientIdProps}">
        <div v-if="contact != undefined">
          <div class="box">
            <div v-if="!clientIdProps" class="box-heading col-space-between">
              <div>Contact details</div>
              <a v-if="zohoLink.length > 0 && zohoLink[0].zohoClientContactLink" :href="zohoLink[0].zohoClientContactLink" class="mr-1" target="blank"><img src="@/assets/images/zoho_circle.svg" class="mr-2 is-icon" /></a>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Client</div>
                <div class="box-col box-col-right ">
                  <el-select :disabled="clientId !== '' || user.type !== 6" class="w-50" @change="changeClients" v-model="contact.clientId" filterable remote  :loading="loading" :remote-method="remoteMethod">
                    <el-option v-for="item in clientList" :key="item.id" :value="item.id" :label="`${item.name} - ${item.branchName}`">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row" v-if="!isNew">
                <div class="box-col box-col-left">Branch</div>
                <div class="box-col box-col-right ">
                  <span>
                    {{contact.branchName}}
                  </span>
                </div>
              </div>
             <div class="box-row" v-if="!isNew && (user.type == 3 || user.type == 6)">
               <div class="box-col box-col-left">Active</div>
               <div class="box-col box-col-right">
                 <el-tooltip v-if="contact.isPrimaryContact" effect="dark" placement="bottom">
                   <div v-if="contact.isPrimaryContact" slot="content">Cannot deactivate as they are a primary user. Select a new primary user before deactivating this account</div>
                   <div style="width: fit-content">
                     <input :key="activeKey" type="checkbox" :style="{cursor: contact.isPrimaryContact ? 'not-allowed' : 'default'}" :disabled="contact.isPrimaryContact" v-model="contact.active" />
                   </div>
                 </el-tooltip>
                 <div v-else style="width: fit-content">
                   <input :key="activeKey" type="checkbox" :style="{cursor: contact.isPrimaryContact ? 'not-allowed' : 'default'}" v-model="contact.active" />
                 </div>
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">First Name</div>
               <div class="box-col box-col-right">
                 <input type="text" v-model="contact.firstName" :class="hasError && !validateContactFirstName ? 'is-invalid' : ''" placeholder="Enter First Name" />
                 <div autocomplete="new-password" v-if="hasError && !validateContactFirstName" class="text-danger">Please enter the correct contact name (do not contain ' " or `)</div>
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">Middle Name</div>
               <div class="box-col box-col-right">
                 <input autocomplete="new-password" type="text" v-model="contact.middleName" placeholder="Enter Middle Name" />
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">Surname</div>
               <div class="box-col box-col-right">
                 <input autocomplete="new-password" type="text" v-model="contact.surname" :class="hasError && !validateContactSurname ? 'is-invalid' : ''" placeholder="Enter Last Name" />
                 <div v-if="hasError && !validateContactSurname" class="text-danger">Please enter the correct contact name (do not contain ' " or `)</div>
               </div>
             </div>
              <div class="box-row">
                <div class="box-col box-col-left">Salutation</div>
                <div class="box-col box-col-right">
                 <el-select class="w-50" v-model="contact.Salutation" filterable clearable placeholder="Select Salutation">
                   <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                 </el-select>
                </div>
              </div>
             <div class="box-row">
               <div class="box-col box-col-left">Land Line</div>
               <div class="box-col box-col-right">
                 <input autocomplete="new-password" type="text" v-model="contact.phoneNumber1" :class="hasError && !validatePhone1 ? 'is-invalid' : ''" placeholder="Enter Land Line" />
                 <div v-if="hasError && !validatePhone1" class="text-danger">Land Line is invalid.</div>
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">Mobile</div>
               <div class="box-col box-col-right">
                 <input autocomplete="new-password" type="text" v-model="contact.mobile" :class="hasError && !validateMobile ? 'is-invalid' : ''" placeholder="Enter Mobile" />
                 <div v-if="hasError && !validateMobile" class="text-danger">{{ errorMobileMessage }}</div>
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">Work Phone</div>
               <div class="box-col box-col-right">
                 <input autocomplete="new-password" type="text" v-model="contact.phoneNumber2" :class="hasError && !validatePhone2 ? 'is-invalid' : ''" placeholder="Enter Work Phone" />
                 <div v-if="hasError && !validatePhone2" class="text-danger">Work Phone is invalid.</div>
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">Email Address</div>
               <div class="box-col box-col-right">
                 <input autocomplete="new-password" type="text" v-model="contact.emailAddress" :class="hasError && !validateContactEmail ? 'is-invalid' : ''" placeholder="Enter Email Address" />
                 <div v-if="hasError && !validateContactEmail" class="text-danger">{{ errorEmailMessage }}</div>
               </div>
             </div>
              <div class="box-row">
                <div class="box-col box-col-left">Notification Email Address</div>
                <div class="box-col box-col-right">
                  <input autocomplete="new-password" type="text" v-model="contact.NotificationEmailAddress" :class="hasError && !validateNotificationEmail ? 'is-invalid' : ''" placeholder="Enter Email Address" />
                  <div v-if="hasError && !validateNotificationEmail" class="text-danger">{{ errorEmailMessage }}</div>
                </div>
              </div>
             <div class="box-row">
               <div class="box-col box-col-left">Role</div>
               <div class="box-col box-col-right">
                 <select class="textbox" v-model="contact.userRoleId">
                   <option hidden value="">Please choose ...</option>
                   <option value="1">Client</option>
                   <option value="2">Branch Admin</option>
                 </select>
               </div>
             </div>
             <div class="box-row">
               <div class="box-col box-col-left">User Login?</div>
               <div class="box-col box-col-right">
                 <input type="checkbox" class="float-left row-item" v-model="contact.canLogin" />
                 <v-btn v-if="showResend" class="ml-10 col-center-content" style="background-color:#C4C4C4;color:#6686B6F" @click="reSendInvitation()"> Resend Invite</v-btn>
               </div>
             </div>
             <div class="box-row" v-if="(user.type == 3 || user.type == 6)">
               <div class="box-col box-col-left">Primary Contact?</div>
               <div class="box-col box-col-right">
                 <input type="checkbox" :disabled="originalItem.isPrimaryContact" v-model="contact.isPrimaryContact" @change="changeIsPrimaryContact($event)" />
               </div>
             </div>
           </div>
           <AdminChangeOwnershipCasesModal ref="adminChangeOwnershipCasesModal"/>
         </div>
         <div class="alert alert-success alert-success-resend hidden-element">
           <a class="close" title="close">×</a>
           <b>Success:</b> {{ successMessage }}
         </div>
         <div class="alert alert-danger hidden-element">
           <a class="close" title="close">×</a>
           <b>Error:</b> {{ errorMessage }}
         </div>

         <div class="box-row item-right button-action">
           <div v-if="contactId != ''"><v-btn dark :class="cases.length == 0 ? 'disabled-element' : ''" @click="tapChangeOwnership">Change Ownership of Case</v-btn></div>
           <div class="ml-5"><v-btn :disabled="disableButton" dark  @click="tapUpdateContact">{{ isNew ? 'Save' : 'Update' }}</v-btn></div>
         </div>

         <div class="warning-alert alert alert-warning hidden-element">
           <a class="close" title="close">×</a>
           <b>Warning:</b> {{ errorMessage }}
           <v-btn class="alert-button ml-4" @click="tapCreate()">Save</v-btn>
           <v-btn class="alert-button ml-4" @click="tapCancel()">Cancel</v-btn>
         </div>

         <div class="warning-alert-zoho alert alert-warning hidden-element">
           <a class="close" title="close">×</a>
           <b>Warning:</b> {{ errorZohoMessage }}
           <v-btn class="alert-button ml-4" @click="tapZohoCancel()">Ok</v-btn>
         </div>
       </div>
       <div v-else>Contact does not exist</div>
     </div>
   </div>
 </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import AdminChangeOwnershipCasesModal from '@/components/AdminChangeOwnershipCasesModal'
  export default {
    name: 'AdminEditClientContact',
    props: ["searchOptionClientProps", "searchOptionContactProps", "clientIdProps"],
    components: {
      AdminNavigation,
      Header,
      AdminClientsMenu,
      AdminChangeOwnershipCasesModal,
    },
    data() {
      return {
        salutationList: utilities.initSalutation(),
        loading: false,
        clientList: [],
        zohoLink: [],
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        errorEmailMessage: '',
        errorMobileMessage: '',
        errorZohoMessage: 'Could not create the contact in Zoho.',

        clientId: '',
        contactId: '',
        contact: this.initContact(),
        originalItem: this.initContact(),
        allContacts: [],
        clients: [],
        cases: [],
        isNew: false,
        createContact: false,
        ignorName: false,
        showResend: false,
        disableButton: false,
        user: _.cloneDeep(this.$store.getters["user/getUser"]),

        activeKey: 0,
      }
    },
    computed: {
      validateContactFirstName() {
        if (this.contact.firstName.trim() == '' || !utilities.validateNameText(this.contact.firstName)) {
          return false
        }
        return true
      },
      validateContactSurname() {
        if (this.contact.surname.trim() == '' || !utilities.validateNameText(this.contact.surname)) {
          return false
        }
        return true
      },
      validateContactEmail() {
        if (this.contact.emailAddress.trim() == '') {
          this.errorEmailMessage = "Email is required."
          return false
        } else if(this.contact.emailAddress.trim() != '' && !utilities.validateEmail(this.contact.emailAddress)) {
          this.errorEmailMessage = "Email is invalid."
          return false
        }
        return true
      },
      validateNotificationEmail() {
        if (this.contact.NotificationEmailAddress.trim() === '') {
          return true
        } else if(this.contact.NotificationEmailAddress.trim() !== '' && !utilities.validateEmail(this.contact.emailAddress)) {
          this.errorEmailMessage = "Email is invalid."
          return false
        }
        return true
      },
      validateMobile() {
        if(this.contact.canLogin == 1 && this.contact.mobile == '') {
          this.errorMobileMessage = "Mobile is required."
          return false
        } else if(this.contact.mobile != '' && !utilities.validatePhoneAU(this.contact.mobile)) {
          this.errorMobileMessage = "Please enter a valid mobile number in International format without spaces - i.e. +61400123456"
          return false
        }
        return true
      },
      validatePhone1() {
        if(this.contact.phoneNumber1 != '' && !utilities.validateLandPhone(this.contact.phoneNumber1.trim())) {
          return false
        }
        return true
      },
      validatePhone2() {
        if(this.contact.phoneNumber2 != '' && !utilities.validateLandPhone(this.contact.phoneNumber2)) {
          return false
        }
        return true
      },
    },
    methods: {
      changeClients(value) {
        this.client = this.clientList.find(item => item.id === value)
        this.contact.branchName = this.client.branchName
      },
      async remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          const param = {
            ClientName: query,
            PageSize: 50,
            PageNum: 1,
          }
          const r = await utilities.getClientsNew(param)
          this.clientList = r.clients
        } else {
          this.clientList = [this.client]
        }
        this.loading = false;
      },
      tapCancel() {
        $('.warning-alert').hide()
        this.createContact = false
      },

      tapCreate() {
        this.createContact = true
        $('.warning-alert').hide()
        this.callCreateContact()
      },

      tapZohoCancel() {
        $('.warning-alert-zoho').hide()
        window.history.replaceState({}, document.title, location.pathname + location.search + "&contactId=" + this.contact.clientContactId)
        setTimeout(() => {
          location.reload()
        }, 300)
      },

      setSuccessMessage(status) {
        if (status == 200) {
          this.successMessage = "Successfully moved case ownership!"
          utilities.showAlert(".alert-success-resend")
        } else if (status == 400) {
          this.errorMessage = "No cases for this client contact."
          utilities.showAlert(".alert-danger")
        }
      },
      initContact() {
        return {
          clientContactId: '',
          mobile: '',
          surname: '',
          canLogin: false,
          firstName: '',
          middleName: '',
          emailAddress: '',
          phoneNumber1: '',
          phoneNumber2: '',
          isPrimaryContact: false,
          userRoleId: 1,
          active: true,
          fullName: '',
          NotificationEmailAddress: '',
          Salutation: '',
        }
      },
      changeIsPrimaryContact(e) {
        if(this.contact.isPrimaryContact && !this.contact.active) {
          this.contact.active = true
          this.activeKey += 1
        }
      },
      tapChangeOwnership() {
        if(this.cases.length == 0) return
        this.$refs.adminChangeOwnershipCasesModal.handleModal()
        console.log(this.contact)
        this.$refs.adminChangeOwnershipCasesModal.fromId = this.contact.clientContactId
        this.$refs.adminChangeOwnershipCasesModal.contactName = this.contact.fullName
        this.$refs.adminChangeOwnershipCasesModal.setCaseOwners(this.client.contacts.filter((x) => x.userId != this.contact.userId))
      },

      validateClientEmail() {
        if (!this.isNew) {
          return true
        }
        if (this.allContacts.map(x=>{return x.emailAddress}).includes(this.contact.emailAddress)) {
          this.errorMessage = 'Email Address Already Exists!'
          utilities.showAlert('.alert-danger')
          this.createContact = false
          return false
        }
        return true
      },

      async validateContactName(ignorName) {
        if (!this.isNew) {
          return true
        }
        var isExists = false
        this.allContacts.map((x) => {
          if (x.firstName.toUpperCase() == this.contact.firstName.toUpperCase() && x.middleName.toUpperCase() == this.contact.middleName.toUpperCase() && x.surname.toUpperCase() == this.contact.surname.toUpperCase()) {
            this.errorMessage = 'The entered contact name already exists. Do you wish to continue?'
            utilities.showAlert('.warning-alert', false)
            isExists = true
            return
          }
        })
        if (isExists) {
          return false
        } else {
          return true
        }

      },

      async tapUpdateContact() {
        this.errorMessage = 'Please correct the errors shown above and try again.'
        if (!this.validateContactFirstName || !this.validateContactSurname || !this.validateContactEmail || !this.validateNotificationEmail || !this.validateMobile || !this.validatePhone1 || !this.validatePhone2) {
          this.hasError = true
          utilities.showAlert('.alert-danger')
          return
        }

        if (!this.validateClientEmail() || !await this.validateContactName()) {
          this.createContact = false
          return
        } else {
          this.createContact = true
        }
        //call API
        if (this.createContact) {
          this.callCreateContact()
        }
        this.createContact = false
      },

      async callCreateContact() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.contact.fullName = this.contact.firstName + ' ' + (this.contact.middleName == '' ? '' : this.contact.middleName + ' ') + this.contact.surname
        var number = utilities.updateMobileCode(this.contact.mobile)
        var params = {
          Mobile: utilities.validatePhonenumber(number),
          Surname: this.contact.surname,
          Salutation: this.contact.Salutation,
          CanLogin: utilities.convertBoolToNum(this.contact.canLogin),
          FirstName: this.contact.firstName,
          MiddleName: this.contact.middleName,
          EmailAddress: this.contact.emailAddress,
          NotificationEmailAddress: this.contact.NotificationEmailAddress,
          PhoneNumber1: utilities.validatePhonenumber(this.contact.phoneNumber1),
          PhoneNumber2: utilities.validatePhonenumber(this.contact.phoneNumber2),
          IsPrimaryContact: utilities.convertBoolToNum(this.contact.isPrimaryContact),
          UserRoleID: this.contact.userRoleId,
          UserID: this.user.id,
          Username: this.user.name,
          Active: this.contact.active ? 1 : 0,
        }

        if (this.originalItem) {
          if (!this.contact.canLogin && this.originalItem.canLogin) { // Check User Login
            params.Deactivate = 1
          } else if (this.contact.canLogin && !this.originalItem.canLogin && this.originalItem.emailAddress != '') {  // Uncheck User Login for existing client contact
            params.Reactivate = 1
          } else {  // other cases - always send Reactivate 0 as default
            params.Reactivate = 0
          }

          if (this.contact.emailAddress != this.originalItem.emailAddress) {
            params.OldEmailAddress = this.originalItem.emailAddress
          }
        }
        if (this.contact.clientContactId != undefined && this.contact.clientContactId != '')
          params.ClientContactID = this.contact.clientContactId

        if (this.contact.contactUserId != undefined && this.contact.contactUserId != '')
          params.ContactUserID = this.contact.contactUserId

        params.ClientID = this.contact.clientId

        var result = ""
        if (this.isNew) { // Add new contact
          if (this.contact.canLogin) {
            // Separate API
            result = await utilities.postContactCanLogin(params)
          } else {
            result = await utilities.postContact(params)
          }
          if (result) {
            let zohoResult = await this.callPostClientContactToZoho(number, result)
            this.successMessage = 'Client contact created successfully'
            utilities.showAlert('.alert-success-resend')
            if (zohoResult != true) {
              this.disableButton = true
              this.contact.clientContactId = result
              utilities.showAlert('.warning-alert-zoho', false)
            } else {
              if(this.clientIdProps) {
                this.$store.dispatch('loading/setLoadComponent', false)
                this.contact = this.initContact()
                this.$emit('contactCreated')
                return
              }
              window.history.replaceState({}, document.title, location.pathname + "?id=" + result)
              setTimeout(() => {
                location.reload()
              }, 1000)
            }
          }
        } else {  // Edit contact
        if (this.contact.canLogin) {
            result = await utilities.putContactCanLogin(params)
          } else {
            result = await utilities.putContact(params)
          }
          if (result) {
            //Ticket 5292 - display error message from API.
            // if(this.contact.zohoClientContactId != "") {
            //   await this.callPutClientContactToZoho(number)
            // } else {
            //   utilities.showWarningMessage("Could not update the client contact in Zoho.")
            // }
            await this.callPutClientContactToZoho(number)
            if (this.contact.canLogin) {
              this.showResend = true
            } else {
              this.showResend = false
            }
            this.originalItem =  _.cloneDeep(this.contact)
            this.successMessage = 'Contacts updated.'
            utilities.showAlert('.alert-success')
          }
        }
        this.createContact = false
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async reSendInvitation() {
        if (!utilities.validatePhoneAU(this.contact.mobile)) {
          this.hasError = true
          return
        }

        var mobile = utilities.updateMobileCode(utilities.validatePhonenumber(this.contact.mobile))
        if(mobile.charAt(0) == '0') {
          mobile = mobile.substring(1)
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var params = {
          Name: this.contact.fullName,
          EmailAddress: this.contact.emailAddress,
          Mobile: mobile,
          UserRoleID: this.contact.userRoleId,
        }
        var result = await utilities.postResendInvite(params)
        if (result) {
          this.successMessage = result
          utilities.showAlert(".alert-success-resend")
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async callPostClientContactToZoho(mobile, contactId) {
        var zohoLink = ''
        if (this.client.zohoLink) {
          zohoLink = this.client.zohoLink.split('/')[this.client.zohoLink.split('/').length - 1]
        }
        var params = {
          FirstName: this.contact.firstName,
          Surname: this.contact.surname,
          EmailAddress: this.contact.emailAddress,
          NotificationEmailAddress: this.contact.NotificationEmailAddress,
          Salutation: this.contact.Salutation,
          Mobile: mobile,
          PhoneNumber1: utilities.validatePhonenumber(this.contact.phoneNumber1),
          PhoneNumber2: utilities.validatePhonenumber(this.contact.phoneNumber2),
          UserID: this.user.id,
          Username: this.user.name,
          ClientZohoLink: zohoLink,
          ClientContactID: contactId,
          ClientID: this.contact.clientId,
          Status: 1
        }
        return await utilities.postClientContactToZoho(params)
      },

      async callPutClientContactToZoho(number) {
        var zohoLink = ''
        if (this.client.zohoLink) {
          zohoLink = this.client.zohoLink.split('/')[this.client.zohoLink.split('/').length - 1]
        }
        var params = {
          FirstName: this.contact.firstName,
          Surname: this.contact.surname,
          EmailAddress: this.contact.emailAddress,
          NotificationEmailAddress: this.contact.NotificationEmailAddress,
          Salutation: this.contact.Salutation,
          Mobile: number,
          PhoneNumber1: utilities.validatePhonenumber(this.contact.phoneNumber1),
          PhoneNumber2: utilities.validatePhonenumber(this.contact.phoneNumber2),
          UserID: this.user.id,
          Username: this.user.name,
          ClientZohoLink: zohoLink,
          ClientContactID: this.contactId,
          ClientID: this.contact.clientId,
          Status: this.contact.active ? 1 : 0,
          ZohoCCID: this.contact.zohoClientContactId
        }
        await utilities.putClientContactToZoho(params)
      },

      async loadData() {
        const {contacts} = await utilities.getClientContactsNew({PageNum: 1, PageSize: 10, ClientContactID: this.contactId})
        this.contact = contacts[0] || null
        if (this.contact) {
          // this.contact.active = true
          this.originalItem = _.cloneDeep(this.contact)
          let params = {
            clientId: this.contact.clientId,
            userId: this.user.id
          }
          // this.clientId = this.contact.clientId
          const apiArr = [utilities.getBranchNamesOfClient(this.contact.clientName), utilities.getCasesByClientId(params), utilities.getClientById(this.contact.clientId)]
          const r = await Promise.all(apiArr)
          this.clients = r[0]
          let cases = r[1]
          this.client = r[2]
              this.clientList.push(this.client)
          this.cases = cases.filter(x => x.caseOwner === this.contact.userId)
          if (this.contact.canLogin) {
            this.showResend = true
          } else {
            this.showResend = false
          }
        }
      },
      async createNewContact() {
        this.contact = this.initContact()
        this.contact.clientId = this.client.id
        this.contact.clientName = this.client.name
        this.contact.branchName = this.client.branchName
        this.isNew = true
        if (this.client.contacts.length == 0) {
          this.contact.isPrimaryContact = true
        }
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $("body>.tooltip").remove()
      this.clientId = this.$route.query.clientId !== undefined ? this.$route.query.clientId : ''
      this.contactId = this.$route.query.id !== undefined ? this.$route.query.id : ''

      if(this.clientIdProps) {
        this.clientId = this.clientIdProps
      }

      if(this.clientId !== '') {
        this.allContacts = await utilities.getClientContacts(this.clientId)
        this.allContacts.map(x => {
          x.mobile = utilities.removeMobileCode(x.mobile)
        })
        this.zohoLink = this.allContacts.filter(item => item.clientContactId.toString() === this.contactId.toString())
        this.client = await utilities.getClientById(this.clientId)
        this.clientList.push(this.client)
      }
      if(this.contactId !== '') { // Edit contact
        await this.loadData()
      } else if(this.clientId !== '' && this.contactId === '') {  // Add new contact
        await this.createNewContact()
        this.showResend = false
      } else {
        this.contact = undefined
        this.showResend = false
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
