/* eslint-disable */
<template>
  <div class="specialist-add-travel-receipt">
    <SpecialistNavigation indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Travel &amp; Accommodation Details</h1></div>
        <div class="header-sub-title">Please select a travel itinerary and upload any travel receipts related to the selected trip.</div>
      </div>
    </div>

    <div class="panel">
      <SpecialistTravelMenu indexProps="1" />
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <span>Add Travel Receipts</span>
          </div>
          <div class="box-body pb-5">
            <div class="box-row">
              <div class="box-col box-col-left">
                Itinerary:
              </div>
              <div class="box-col box-col-right">
                <div v-if="!caseFocus" @click="caseFocus = true" class="parent">
                  <input class="child" type="text" disabled placeholder="Click here to select itinerary" />
                </div>
                <select v-model="selectedItinerary" @change="fetchReceipts()" v-else>
                  <option hidden value="">Please choose ...</option>
                  <option v-for="itinerary in itineraries" :value="itinerary.id">{{ itinerary.name }}</option>
                </select>
              </div>
            </div>
            <div class="box-row mx-0 px-0">
              <div class="box-col mx-0 px-0">
                  <div>
                    <div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="width: 100%" class="long-text">
                              File Name
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="content-scroll-y file-list" style="height: auto; max-height: 460px">
                      <table class="table remove-min-height">
                        <tbody :key="fileKey">
                          <tr
                            v-for="(file, index) in receipts"
                            :key="index"
                            class="py-0">
                            <td style="width: 100%">
                              <a class="is-icon" :href="file.fileUrl" target="_blank">{{ file.invoiceFileName }}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="box-body pt-5">
            <div v-if="selectedItinerary != ''" class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif" />
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">
                <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
              </label>
            </div>
          </div>

          <br />
          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> Files uploaded successfully.
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> There are the errors. Please try again.
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistTravelMenu from '@/components/SpecialistTravelMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'

  export default {
    name: 'SpecialistAddTravelReceipt',
    components: {
      SpecialistNavigation,
      SpecialistTravelMenu,
      Header,
      Chat,
    },
    data() {
      return {
        filelist: [],
        caseFocus: false,
        itineraries: [],
        specialist: utilities.initSpecialist(),
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        selectedItinerary: "",
        receipts: [],
        fileKey: 0,
      }
    },
    methods: {
      async onChange() {
        this.$store.dispatch('loading/setLoadComponent', true)
        // var params = {
        //   SpecialistTravelItineraryID: this.selectedItinerary,
        //   FileObject: []
        // }
        var params = {}
        this.filelist = [...this.$refs.file.files]
        if(this.filelist.length > 0) {
          await Promise.all(this.filelist.map(async (x) => {
            if(this.selectedItinerary != '') {
              var fileName = moment().unix() + '_' + x.name.replace(/\s/g, '_')
              //await new Promise((resolve, reject) => {
                //var reader = new FileReader()
                //reader.onloadend = async () => {
                  // params.FileObject.push({
                  //   SpecialistTravelItineraryID: this.selectedItinerary,
                  //   InvoiceFileName: fileName,
                  //   DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                  //   UploadedBy: this.user.id,
                  //   SpecialistID: this.user.specialistId,
                  //   MimeType: x.type,
                  //   InvoiceFileBinary: reader.result,
                  // })

                  var params = {
                    name: fileName,
                    fileType: x.type,
                    uploadtype: 'tr',
                    id: this.selectedItinerary,
                  }
                  var result = await utilities.postUploadImageURL(params)
                  var postData = new FormData();

                  for(var key in result.fields){
                      postData.append(key, result.fields[key]);
                  }
                  postData.append('file', x)
                  await utilities.postImage(result.url, postData)

                  params = {
                    SpecialistTravelItineraryID: this.selectedItinerary,
                    InvoiceFileName: fileName,
                    DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                    UploadedBy: this.user.id,
                    SpecialistID: this.user.specialistId,
                    MimeType: x.type,
                    // InvoiceFileBinary: reader.result,
                    UserID: this.user.id,
                    Username: this.user.name,
                  }
                  var callback = (response) => {}
                  await utilities.postTravelReceipt(params, callback)
                //}
                // reader.onerror = (error) => {
                //   reject(error)
                // }
                // reader.readAsBinaryString(x)
              //})
            }
          }))
          // if(params.FileObject.length > 0) {
          //   var callback = (response) => {}
          //   var result = await utilities.postTravelReceipt(params, callback)
          //   if(result) {
          //     await this.fetchReceipts()
          //     this.fileKey += 1
          //   }
          // }
          await this.fetchReceipts()
          this.fileKey += 1
          utilities.showAlert(".alert-success")
          setTimeout(() => {
            $(".file-list").animate({ scrollTop: $(".file-list")[0].scrollHeight }, 'slow')
          }, 200)
          await this.logThis(false, true)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      getFile(filePath, fileName) {
        utilities.getFile(filePath, fileName)
      },
      remove(i) {
        this.filelist.splice(i, 1)
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //   event.currentTarget.classList.remove('bg-gray-100')
        //   event.currentTarget.classList.add('bg-green-300')
        // }
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        // event.currentTarget.classList.add('bg-gray-100')
        // event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      async logThis(viewItinerary = false, addReceipt = false){
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistID: this.user.specialistId,
          LogDetails: this.user.name + " viewed travel itinerary " + this.selectedItinerary,
          LogDate: moment().format("YYYY-MM-DD HH:mm:ss")
        }
        if(viewItinerary == true) {
          params.LogDetails = this.user.name + " downloaded travel itinerary " + this.selectedItinerary
        }
        if(addReceipt == true) {
          params.LogDetails = this.user.name + " added a receipt to travel itinerary " + this.selectedItinerary
        }
        await utilities.postRequestChangeLog(params)
      },
      async fetchReceipts() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var temp = await utilities.getSpecialistTravelReceipts(this.selectedItinerary)
        await Promise.all(temp.map(async x => {
          if (x.invoiceFileName != '') {
            var params = {
              uploadtype: "tr",
              filename: x.invoiceFileName,
              id: this.selectedItinerary
            }
            x.fileUrl = await utilities.getFileUrlNew(params)
          }
        }))
        this.receipts = temp
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async loadData() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var itineraries = await utilities.getAllTravelItineraries(this.user.specialistId)
        this.itineraries = itineraries.sort((a,b) => {
          return moment(a.travelStartDate).isBefore(b.travelStartDate) ? 1: -1
        })
        this.itineraries = _.uniqBy(itineraries, 'id')
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      await this.loadData()
    },
    mounted() {
      window.scrollTo(0, 0)
    },
  }
</script>
