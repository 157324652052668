<template>
  <div class="add-body-diagram-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Affected Body Areas</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="box-row reset-line-height">
            <!-- <div class="box-col box-col-left">Affected Body Areas</div> -->
            <div class="box-col box-col-right" style="height: 95px;width: 100%; overflow: auto;">
              <div class="tag-div">
                <vue-tags-input
                  class="px-3"
                  :disabled="true"
                  :add-only-from-autocomplete="true"
                  :avoid-adding-duplicates="true"
                  v-model="bodyArea"
                  :tags="bodyAreas"
                  :placeholder="msg"
                  @before-deleting-tag="deleteBodyArea"
                />
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body center center-item fullBody" :key="keyFullBody" v-if="showFullBody">
              <img src="@/assets/images/body-part.svg" usemap="#image-map">
              <map name="image-map">
                <area target="" alt="head" title="head" href="#" coords="137,52,28" shape="circle" id="body-area-head">

                <area target="" alt="right-shoulder" title="right-shoulder" href="#" coords="105,143,25" shape="circle" id="right-shoulder">
                <area target="" alt="right-elbow" title="right-elbow" href="#" coords="90,212,24" shape="circle" id="right-elbow">
                <area target="" alt="right-plam" title="right-plam" href="#" coords="69,285,19" shape="circle" id="right-plam">

                <area target="" alt="left-shoulder" title="left-shoulder" href="#" coords="170,143,25" shape="circle" id="left-shoulder">
                <area target="" alt="left-elbow" title="left-elbow" href="#" coords="184,211,25" shape="circle" id="left-elbow">
                <area target="" alt="left-palm" title="left-palm" href="#" coords="209,286,19" shape="circle" id="left-palm">

                <area target="" alt="right-hip" title="right-hip" href="#" coords="107,269,20" shape="circle" id="right-hip">
                <area target="" alt="left-hip" title="left-hip" href="#" coords="167,273,21" shape="circle" id="left-hip">
                <area target="" alt="right-pelvis" title="right-pelvis" href="#" coords="104,318,23" shape="circle" id="right-pelvis">
                <area target="" alt="left-pelvis" title="left-pelvis" href="#" coords="171,319,22" shape="circle" id="left-pelvis">

                <area target="" alt="right-knee" title="right-knee" href="#" coords="112,395,22" shape="circle" id="right-knee">
                <area target="" alt="left-knee" title="left-knee" href="#" coords="160,394,21" shape="circle" id="left-knee">


                <area target="" alt="" title="right-foot" href="#" coords="122,520,18" shape="circle" id="right-foot">
                <area target="" alt="left-foot" title="left-foot" href="#" coords="160,522,18" shape="circle" id="left-foot">


                <area target="" alt="back" title="back" href="#" coords="332,156,24" shape="circle" id="back">
                <area target="" alt="spine" title="spine" href="#" coords="333,213,23" shape="circle" id="spine">
                <area target="" alt="lower-back" title="lower-back" href="#" coords="334,274,25" shape="circle" id="lower-back">
            </map>
            </div>
            <div class="foot center-item" :key="keyFoot" v-if="showFoot">
              <img src="@/assets/images/feet.svg" usemap="#image-map">
                <map name="image-map">
                  <area target="" alt="right-foot-ankle" title="right-foot-ankle" href="#" coords="108,151,21" shape="circle" id="right-foot-ankle">
                  <area target="" alt="right-foot-whole" title="right-foot-whole" href="#" coords="103,193,19" shape="circle" id="right-foot-whole">
                  <area target="" alt="right-fifth-toe" title="right-fifth-toe" href="#" coords="46,227,10" shape="circle" id="right-fifth-toe">
                  <area target="" alt="right-fourth-toe" title="right-fourth-toe" href="#" coords="63,236,8" shape="circle" id="right-fourth-toe">
                  <area target="" alt="right-third-toe" title="right-third-toe" href="#" coords="82,242,10" shape="circle" id="right-third-toe">
                  <area target="" alt="right-second-toe" title="right-second-toe" href="#" coords="102,245,9" shape="circle" id="right-second-toe">
                  <area target="" alt="right-great-toe" title="right-great-toe" href="#" coords="128,245,9" shape="circle" id="right-great-toe">

                  <area target="" alt="left-foot-ankle" title="left-foot-ankle" href="#" coords="193,149,21" shape="circle" id="left-foot-ankle">
                  <area target="" alt="left-foot-whole" title="left-foot-whole" href="#" coords="198,194,18" shape="circle" id="left-foot-whole">
                  <area target="" alt="left-fifth-toe" title="left-fifth-toe" href="#" coords="254,225,10" shape="circle" id="left-fifth-toe">
                  <area target="" alt="left-fourth-toe" title="left-fourth-toe" href="#" coords="238,236,9" shape="circle" id="left-fourth-toe">
                  <area target="" alt="left-third-toe" title="left-third-toe" href="#" coords="219,241,8" shape="circle" id="left-third-toe">
                  <area target="" alt="left-second-toe" title="left-second-toe" href="#" coords="194,244,9" shape="circle" id="left-second-toe">
                  <area target="" alt="left-great-toe" title="left-great-toe" href="#" coords="170,244,12" shape="circle" id="left-great-toe">
                </map>
            </div>
            <div class="left-hand center-item" v-if="showLeftHand">
              <img src="@/assets/images/left-hand.svg" usemap="#image-map">
                <map name="image-map">
                  <area target="" alt="left-wrist" title="left-wrist" href="#" coords="119,88,26" shape="circle" id="left-wrist">
                  <area target="" alt="left-thumb" title="left-thumb" href="#" coords="202,169,16" shape="circle" id="left-thumb">
                  <area target="" alt="left-index" title="left-index" href="#" coords="195,226,10" shape="circle" id="left-index">
                  <area target="" alt="left-middle" title="left-middle" href="#" coords="180,246,9" shape="circle" id="left-middle">
                  <area target="" alt="left-ring" title="left-ring" href="#" coords="154,245,12" shape="circle" id="left-ring">
                  <area target="" alt="left-little" title="left-little" href="#" coords="129,224,11" shape="circle" id="left-little">

                </map>
            </div>
            <div class="right-hand center-item" v-if="showRightHand">
              <img src="@/assets/images/right-hand.svg" usemap="#image-map">
                <map name="image-map">
                  <area target="" alt="right-wrist" title="right-wrist" href="#" coords="177,87,22" shape="circle" id="right-wrist">
                  <area target="" alt="right-thumb" title="right-thumb" href="#" coords="96,172,15" shape="circle" id="right-thumb">
                  <area target="" alt="right-index" title="right-index" href="#" coords="103,227,13" shape="circle" id="right-index">
                  <area target="" alt="right-middle" title="right-middle" href="#" coords="117,250,11" shape="circle" id="right-middle">
                  <area target="" alt="right-ring" title="right-ring" href="#" coords="141,244,10" shape="circle" id="right-ring">
                  <area target="" alt="right-little" title="right-little" href="#" coords="168,234,10" shape="circle" id="right-little">

                </map>
            </div>

          </div>
          <div class="row">
            <div class="col">
              <div class="icon-group right-float-element">
                <v-btn v-if="showFullBody" class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                <v-btn v-else @click="backToFullBody()" dark>Back</v-btn>
                <v-btn @click="clickSave" dark>Save</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'



  export default {
    name: 'AddBodyDiagramModal',
    data() {
      return {
        loadModal: false,
        showFullBody: true,
        showFoot: false,
        showLeftHand: false,
        showRightHand: false,
        isFromAdmin: false,
        keyFullBody: 0,
        keyFoot: 0,
        bodyAreas: [],
        deleteItems: [],
        bodyArea: '',
        filteredBodyAreas: [],
        msg: 'Add Affected Body Areas By Image',
      }
    },

    computed: {
    },

    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }

        if(!this.loadModal && this.bodyAreas.length >= 5) {
          $(".search-booking-modal .modal-body").css('height', '65vh')
        } else {
          $(".search-booking-modal .modal-body").css('height', 'auto')
        }
      },
      deleteBodyArea(obj) {
        if(!this.deleteItems.map(x => { return x.tag.text }).includes(obj.tag.text)) {
          this.deleteItems.push(obj)
          this.bodyAreas.splice(obj.index, 1)
        }
      },

      backToFullBody() {
        this.keyFullBody += 1
        this.showFullBody = true
        this.showFoot = false
        this.showLeftHand = false
        this.showRightHand = false
        this.handleClick()
      },
      addToBodyArea(text) {
        if (text === "Right-foot" || text === "Left-foot") {
          this.showFullBody = false
          this.showFoot = true
          this.keyFoot++
          this.handleClick()
        } else if (text === "right-plam") {
          this.showFullBody = false
          this.showRightHand = true
          this.handleClick()
        } else if (text === "left-palm") {
          this.showFullBody = false
          this.showLeftHand = true
          this.handleClick()
        } else {
          this.msg = ''
          var area = this.filteredBodyAreas.find((x) => x.text == text)
          if (!this.bodyAreas.find((x) => x.text == area.text)) {
            this.bodyAreas.push(area)
            if(this.deleteItems.length > 0) {
              var index = this.deleteItems.findIndex(x => x.tag.text == text)
              if(index > -1) this.deleteItems.splice(index, 1)
            }
          }
        }
      },
      clickBody(body, id) {
        $(body).click((e) => {
          e.preventDefault()
          this.addToBodyArea(id)
        })
      },
      handleClick() {
        setTimeout(() => {
          this.clickBody("#body-area-head", 'Head')
          this.clickBody("#left-shoulder", 'Left Shoulder')
          this.clickBody("#right-shoulder", 'Right Shoulder')
          this.clickBody("#right-elbow", 'Right Elbow')
          this.clickBody("#right-plam", 'right-plam')
          this.clickBody("#left-elbow", 'Left Elbow')
          this.clickBody("#right-thumb", 'Right Hand - Thumb')
          this.clickBody("#right-index", 'Right Hand - Index Finger')
          this.clickBody("#right-middle", 'Right Hand - Middle Finger')
          this.clickBody("#right-ring", 'Right Hand - Ring Finger')
          this.clickBody("#right-little", 'Right Hand - Little Finger')
          this.clickBody("#right-wrist", 'Right Hand - Wrist')

          this.clickBody("#left-palm", 'left-palm')
          this.clickBody("#left-thumb", 'Left Hand - Thumb')
          this.clickBody("#left-index", 'Left Hand - Index Finger')
          this.clickBody("#left-middle", 'Left Hand - Middle Finger')
          this.clickBody("#left-ring", 'Left Hand - Ring Finger')
          this.clickBody("#left-little", 'Left Hand - Little Finger')
          this.clickBody("#left-wrist", 'Left Hand - Wrist')

          this.clickBody("#right-hip", 'Right Hip')
          this.clickBody("#right-pelvis", 'Right Pelvis')
          this.clickBody("#right-knee", 'Right Knee')
          this.clickBody("#left-hip", 'Left Hip')
          this.clickBody("#left-pelvis", 'Left Pelvis')
          this.clickBody("#left-knee", 'Left Knee')
          this.clickBody("#right-foot", 'Right-foot')
          this.clickBody("#right-foot-whole", 'Right Foot - Whole')
          this.clickBody("#right-great-toe", 'Right Foot - Great Toe')
          this.clickBody("#right-second-toe", 'Right Foot - Second Toe')
          this.clickBody("#right-third-toe", 'Right Foot - Third Toe')
          this.clickBody("#right-fourth-toe", 'Right Foot - Fourth Toe')
          this.clickBody("#right-fifth-toe", 'Right Foot - Fifth Toe')
          this.clickBody("#right-foot-ankle", 'Right Foot - Ankle')
          this.clickBody("#left-foot", 'Left-foot')
          this.clickBody("#left-foot-whole", 'Left Foot - Whole')
          this.clickBody("#left-great-toe", 'Left Foot - Great Toe')
          this.clickBody("#left-second-toe", 'Left Foot - Second Toe')
          this.clickBody("#left-third-toe", 'Left Foot - Third Toe')
          this.clickBody("#left-fourth-toe", 'Left Foot - Fourth Toe')
          this.clickBody("#left-fifth-toe", 'Left Foot - Fifth Toe')
          this.clickBody("#left-foot-ankle", 'Left Foot - Ankle')
          this.clickBody("#back", 'Spine - Cervical')
          this.clickBody("#spine", 'Spine - Thoracic')
          this.clickBody("#lower-back", 'Spine - Lumbar')
        }, 500)
      },
      async clickSave() {
        if (this.deleteItems.length > 0) {
          this.$parent.deleteBodyAreaItems(this.deleteItems)
        }
        this.bodyAreas.sort((a, b) => {
          return a.text > b.text ? 1 : -1
        })
        this.$parent.bodyAreaTags.sort((a, b) => {
          return a.text > b.text ? 1 : -1
        })
        if(!_.isEqual(this.bodyAreas.map(x => { return x.text }), this.$parent.bodyAreaTags.map(x => { return x.text }))) {
          await this.$parent.setBodyArea(this.bodyAreas)
        }
        this.handleModal()
      },
    },
    beforeMount() {},
    Updated() {},
    mounted() {},
  }
</script>
