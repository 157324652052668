/* eslint-disable */
<template>
  <div class="bookings-waitlisted">
    <AdminNavigation ref="navigation" indexProps="5"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Cancelled Bookings</h1></div>
        <div class="header-sub-title">The following bookings have been Cancelled.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu indexProps="8"/>
      <div class="content">
        <div class="tool-bar row">
          <div class="col" style="line-height: 40px">Total Cancelled Bookings: {{ totalCount }}</div>
          <div class="col d-flex align-items-center justify-content-end">
            <el-input style="width: 150px" class="mr-1" @input="search()" clearable placeholder="Booking ID"
                      v-model="listParam.BookingID"/>
            <el-input style="width: 150px" class="mr-1" @input="search()" clearable placeholder="Client Name"
                      v-model="listParam.ClientName"/>
            <el-input style="width: 150px" class="mr-1" @input="search()" clearable placeholder="Contact Name"
                      v-model="listParam.ContactName"/>
            <el-date-picker
                v-model="dateValue"
                type="daterange"
                start-placeholder="Cancel Date"
                popper-class="date-range-picker-popper"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="changeDates"
                end-placeholder="Cancel Date"
                clearable/>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <el-table :default-sort="{prop: 'CancellationDate', order: 'descending'}" stripe @sort-change="sortParam" :data="bookings"
                      tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}"
                      :header-cell-style="{fontWeight: 100}">
              <el-table-column
                  width="80"
                  label="ID"
                  prop="BookingID"
                  sortable="custom"
                  show-overflow-tooltip
                  fixed="left"
              >
                <template slot-scope="booking">
                  <router-link
                      :to="{ name: 'admin-manage-booking', query: { id: booking.row.BookingID, cancelled: true } }">
                    {{ booking.row.BookingID }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  width="130"
                  label="Claimant"
                  prop="Claimant"
                  sortable="custom"
                  show-overflow-tooltip
                  fixed="left"
              >
                <template slot-scope="booking">
                  <router-link :to="{ name: 'admin-manage-case', query: { id: booking.row.CaseID } }">{{ booking.row.CasesClaimantFirstName + ' ' + booking.row.CasesClaimantSurname }}</router-link>
                </template>
              </el-table-column>
              <el-table-column
                  width="140"
                  show-overflow-tooltip
                  prop="ClientName"
                  sortable="custom"
                  label="Client"
              >
                <template slot-scope="booking">
                  <router-link
                      :to="{ name: 'admin-manage-client', query: { id: booking.row.ClientID } }">
                    <span>{{ decodeURIComponent(booking.row.ClientName)}} - {{booking.row.BranchName}}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  width="140"
                  show-overflow-tooltip
                  sortable="custom"
                  prop="ContactName"
                  label="Contact"
              >
                <template slot-scope="booking">
                  <router-link
                      :to="{ name: 'admin-manage-contact', query: { id: booking.row.ClientContactID, type:'edit-contact' } }">
                    <span>{{ booking.row.ClientContactFirstName + ' ' + booking.row.ClientContactSurname }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  width="160"
                  show-overflow-tooltip
                  sortable="custom"
                  prop="SpecialistName"
                  label="Specialist"
              >
                <template slot-scope="booking">
                  <span>{{booking.row.Title + ' ' + booking.row.FirstName + ' ' + booking.row.Surname }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  show-overflow-tooltip
                  prop="BookingDateTime"
                  sortable="custom"
                  label="Booking Date"
              >
                <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.BookingDateTime, "DD/MM/YYYY")
                      }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  show-overflow-tooltip
                  prop="CancellationDate"
                  sortable="custom"
                  label="Cancel Date"
              >
                <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.CancellationDate, "DD/MM/YYYY")
                      }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="CancellationReason"
                  sortable="custom"
                  label="Cancel Reason"
              >
                <template slot-scope="scope">
                    <span>{{
                        decodeURIComponent(scope.row.CancellationReason)
                      }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="80"
                  label=""
                  show-overflow-tooltip
              >
                <template slot-scope="booking">
                  <div class="is-icon">
                    <el-tooltip effect="dark" content="Link to Zoho" placement="bottom">
                      <a :href="booking.row.ZohoLink" target="_blank"><img src="@/assets/images/zoho_icon.svg"/></a>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import {debounce} from 'lodash';

export default {
  name: "AdminBookingsCancelled",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),
      dateValue: [window.moment().subtract(7, 'd').format('YYYY-MM-DD'), window.moment().format('YYYY-MM-DD')],
      filterOption: {
        startDate: "",
        endDate: "",
      },
      totalCount: 0,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 14,
        Status: 6,
        StartDate: window.moment().subtract(7, 'd').format('YYYY-MM-DD'),
        EndDate: window.moment().format('YYYY-MM-DD'),
        ClientName: '',
        SpecialistName: '',
        ContactName: '',
        BookingID: ''
      },
      byBookingId: false,
      byClient: false,
      byContact: false,
      byClaimant: false,
      byBookingDateTime: false,
      totalBooking: 0,
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    changeDates(v) {
      console.log(this.dateValue)
      if (v) {
        this.listParam.StartDate = v[0]
        this.listParam.EndDate = v[1]
      } else {
        this.listParam.StartDate = null
        this.listParam.EndDate = null
      }
      this.selectPage(1)
    },
    sortParam(v) {
      if(v.prop === "BookingID" && v.order === "ascending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'BookingID' && v.order === "descending") {
        this.listParam.SortFlag = 2
      } else if(v.prop === "ClientName" && v.order === "ascending") {
        this.listParam.SortFlag = 7
      } else if (v.prop === "ClientName" && v.order === "descending") {
        this.listParam.SortFlag = 8
      } else if (v.prop === "ContactName" && v.order === "ascending") {
        this.listParam.SortFlag = 9
      } else if (v.prop === "ContactName" && v.order === "descending") {
        this.listParam.SortFlag = 10
      } else if (v.prop === "SpecialistName" && v.order === "ascending") {
        this.listParam.SortFlag = 11
      } else if (v.prop === "SpecialistName" && v.order === "descending") {
        this.listParam.SortFlag = 12
      } else if (v.prop === "BookingDateTime" && v.order === "ascending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === "BookingDateTime" && v.order === "descending") {
        this.listParam.SortFlag = 6
      } else if (v.prop === "CancellationDate" && v.order === "ascending") {
        this.listParam.SortFlag = 13
      } else if (v.prop === "CancellationDate" && v.order === "descending") {
        this.listParam.SortFlag = 14
      } else if (v.prop === "Claimant" && v.order === "ascending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === "Claimant" && v.order === "descending") {
        this.listParam.SortFlag = 4
      }
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },
    formatDate(dateToFormat, format, currentFormat) {
      return utilities.formatDate(dateToFormat, format, currentFormat)
    },
    async loadBookings() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {data, total} = await utilities.getBookingCancel(this.listParam)
      console.log(data)
      console.log(total)
      this.bookings = _.cloneDeep(data)
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    await this.loadBookings()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
