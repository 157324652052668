<template>
  <div class="add-certificate-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>{{ isEdit ? 'Edit Accreditations/Certifications' : 'Add Accreditations/Certifications' }}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form style="font-size: 14px;">
              <div class="form-group">
                <input type="text" v-model="certification" class="textbox" placeholder="Enter a Person Condition" />
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" id="savebutton" dark>Save</v-btn>
                  </div>
                </div>
              </div>

              <div class="alert alert-success hidden-element">
                <a class="close" title="close">×</a>
                <b>Success:</b> {{ successMessage }}
              </div>
              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> {{ errorMessage }}
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddPersonConditionModal',
    data() {
      return {
        loadModal: false,
        certification: '',
        successMessage: '',
        errorMessage: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        isEdit: false,
        selectedItem: ''
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      async callSave() {
        this.$store.dispatch("loading/setLoadComponent", true)
        if (this.certification.length > 0) {
          if (!this.isEdit) {
            var check = await utilities.checkDuplicate(this.certification, '3')
            if (check) {
              this.errorMessage = "Certification Already Exists!"
              utilities.showAlert(".alert-danger")
              this.$store.dispatch("loading/setLoadComponent", false)
              return
            }

            var params = {
              CertificationName: this.certification,
              UserID: this.user.id,
              Username: this.user.name,
            }
            var certificate = await utilities.postCertification(params)
            if (certificate) {
              this.$parent.loadData()
              this.successMessage = "Certification Added successfully."
              utilities.showAlert(".alert-success")
              setTimeout(() => {
                this.handleModal()
              }, 1000)
            }

          } else {
            var params = {
              CertificationName: this.certification,
              UserID: this.user.id,
              Username: this.user.name,
              Id: this.selectedItem.id
            }
            var certificate = await utilities.putCertification(params)
            if (certificate) {
              this.$parent.loadData()
              this.successMessage = "Certification Updated successfully."
              utilities.showAlert(".alert-success")
              setTimeout(() => {
                this.handleModal()
              }, 1000)
            }
          }
        }

        this.$store.dispatch("loading/setLoadComponent", false)
      },

      editItem(item) {
        this.selectedItem = item
        this.certification = item.name
      },

      // callPostBookingNoteAPI() {
      //   var params = {
      //     Note: this.note,
      //     NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      //     UserID: this.$parent.user.id,
      //     Username: this.$parent.user.name,
      //     BookingID: this.bookingProps.id,
      //     InternalOnly: this.internalOnly,
      //   }
      //   var callback = (response) => {
      //     try {
      //       var allNote = this.bookingProps.bookingNotes
      //       var newNote = {}
      //       newNote = {
      //         note: this.note,
      //         noteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      //         id: response,
      //         userName: this.user.name,
      //         userId: this.user.id,
      //         deleted: false,
      //         internalOnly: this.internalOnly,
      //       }
      //       allNote.push(newNote)
      //       this.$parent.booking.bookingNotes = allNote
      //       this.$parent.setBookingNoteAndAction()
      //       this.$parent.noteKey += 1
      //       this.handleModal()
      //     } catch (e) {}
      //   }
      //   $('#savebutton').prop('disabled', false)
      //   return utilities.postBookingNote(params, callback)
      // },

      // callPutClientNoteAPI() {
      //   var params = {
      //     ClientID: this.clientNote.clientId,
      //     ClientNoteID: this.clientNote.clientNoteId,
      //     Note: this.note,
      //     NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      //     UserID: this.$parent.user.id,
      //     Username: this.$parent.user.name,
      //     InternalOnly: this.internalOnly,
      //   }
      //   var callback = (response) => {
      //     this.$parent.getClientNotes()
      //     this.handleModal()
      //   }
      //   $('#savebutton').prop('disabled', false)
      //   return utilities.putClientNote(params, callback)
      // },
    },
    mounted() {},
  }
</script>
