/* eslint-disable */
<template>
  <div class="specialist-settings">
    <SpecialistNavigation indexProps="6" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Settings</h1></div>
        <div class="header-sub-title">Please send me the following regular, automated emails.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <div>Setting Details</div>
          </div>
          <div class="box-body">
<!--            <div class="box-row">-->
<!--              <div class="box-col box-col-left">New Booking Notification</div>-->
<!--              <div style="width:80%" class="box-col">-->
<!--                <toggle-button @change="onSettingsToggleChange(1)" :color="{ checked: '#56C568', unchecked: '#525252' }" :width="40" :sync="true" :value="setting.newBookingNotification" />-->
<!--                <span class="ml-5">Receive Notification of each booking made</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="box-row">-->
<!--              <div class="box-col box-col-left">Availability Reminder</div>-->
<!--              <div style="width:80%" class="box-col">-->
<!--                <toggle-button @change="onSettingsToggleChange(3)" :color="{ checked: '#56C568', unchecked: '#525252' }" :width="40" :sync="true" :value="setting.availabilityReminder" />-->
<!--                <span class="ml-5">Reminder sent when we have no more availability in our system</span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="box-row">
              <div class="box-col box-col-left">Weekly Schedule</div>
              <div style="width:80%" class="box-col">
                <toggle-button @change="onSettingsToggleChange(5)" :color="{ checked: '#56C568', unchecked: '#525252' }" :width="40" :sync="true" :value="setting.weeklySchedule" />
                <span class="ml-5">Your appointment schedule sent to you one week prior</span>
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-warning hidden-element">
          <a class="close" title="close" @click="tapButtonConfirmation(false)">×</a>
          <b>Warning:</b> Are you sure? Your changes will take effect immediately.
          <div class="icon-group center-element">
            <v-btn class="alert-button" @click="tapButtonConfirmation(true)">Yes</v-btn>
            <v-btn class="alert-button" @click="tapButtonConfirmation(false)">No</v-btn>
          </div>
        </div>

        <div class="right-element">
          <v-btn class="submit-request" @click="tapUpdate()" dark>Update</v-btn>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import Chat from '@/components/Chat'

  export default {
    name: 'SpecialistSettings',
    components: {
      SpecialistNavigation,
      SpecialistMenu,
      Header,
      Chat,
    },
    data() {
      return {
        setting: utilities.initSeeting(),
        successMessage: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    methods: {
      onSettingsToggleChange(index) {
        switch (index) {
          case 1:
            this.setting.newBookingNotification = !this.setting.newBookingNotification
            break
          case 2:
            this.setting.bookingChange = !this.setting.bookingChange
            break
          case 3:
            this.setting.availabilityReminder = !this.setting.availabilityReminder
            break
          case 4:
            this.setting.availabilityReturned = !this.setting.availabilityReturned
            break
          case 5:
            this.setting.weeklySchedule = !this.setting.weeklySchedule
            break
          case 6:
            this.setting.scheduleChange = !this.setting.scheduleChange
            break
          case 7:
            this.setting.reportsDue = !this.setting.reportsDue
            break
          case 8:
            this.setting.reportsOverdue = !this.setting.reportsOverdue
            break
          case 9:
            this.setting.bookingReminder = !this.setting.bookingReminder
            break
          default:
        }
      },

      setSettingInfo(setting) {},

      async putSpecialistSettings() {
        var params = {
          SpecialistID: this.setting.specialistId,
          NewBookingNotification: utilities.convertBoolToNum(this.setting.newBookingNotification),
          BookingChange: utilities.convertBoolToNum(this.setting.bookingChange),
          AvailabilityReminder: utilities.convertBoolToNum(this.setting.availabilityReminder),
          AvailabilityReturned: utilities.convertBoolToNum(this.setting.availabilityReturned),
          WeeklySchedule: utilities.convertBoolToNum(this.setting.weeklySchedule),
          ScheduleChange: utilities.convertBoolToNum(this.setting.scheduleChange),
          ReportsDue: utilities.convertBoolToNum(this.setting.reportsDue),
          ReportsOverdue: utilities.convertBoolToNum(this.setting.reportsOverdue),
          BookingReminder: utilities.convertBoolToNum(this.setting.bookingReminder),
          SpecialistSettingID: this.setting.specialistSettingId,
          UserID: this.user.id,
          Username: this.user.name,
        }

        var callback = (response) => {
        }
        return utilities.putSpecialistSettings(params, callback)
      },

      async tapButtonConfirmation(states) {
        utilities.hideAlert('.alert-warning')
        if (states) {
          this.$store.dispatch('loading/setLoadComponent', true)
          var result = await this.putSpecialistSettings()
          if (result) {
            this.successMessage = 'Your changes have been updated.'
            utilities.showAlert('.alert-success')
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },

      async tapUpdate() {
        utilities.showAlert('.alert-warning', false)
      },

    },

    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.setting = await utilities.getSpecialistSeetings(this.user.specialistId)
      if (_.isEmpty(this.setting)) this.setting = utilities.initSeeting()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    mounted() {
      $('[data-toggle="tooltip"]').tooltip()

    },
  }
</script>
