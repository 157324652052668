import Amplify, { Auth, Storage } from 'aws-amplify'
import moment from 'moment'

Amplify.configure({
    Auth: {
        //identityPoolId: 'ap-southeast-2:fecbb9df-41f7-457d-a316-e807bd549e14', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
        userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
        AWSS3: {
            bucket: 'rho.specialist.profile.images', //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
        },
        customPrefix: {
            public: '',
        },
    }
})

export default {
    uploadFile(file, contentType, path, fileName, level) {
        return new Promise((resolve, reject) => {
            Storage.put(path + '/' + fileName, file, {
              level: level,
              contentType: contentType
            })
            .then(res => {
                //console.log('result in test', JSON.stringify(res));
                resolve(res)
            })
            .catch(e => {
                //console.log(e)
                reject(e)
            })
        })
    },
    getFile(filepath, level, identityId = '') {
        if(identityId == '') {
            return new Promise((resolve, reject) => {
              Storage.get(filepath, {
                  level: level, // defaults to `public`
                  // identityId?: string, // id of another user, if `level: protected`
                  download: true, // defaults to false
                  expires: 30, // validity of the URL, in seconds. defaults to 900 (15 minutes)
                  // contentType: 'application/json' // set return content type, eg "text/html"
              }).then(res => {
                //console.log("result in test: ", JSON.stringify(res));
                resolve(res)
              })
              .catch(e => {
                //console.log(e)
                reject(e)
              })
            })
        } else {
            return new Promise((resolve, reject) => {
                Storage.get(filepath, {
                    level: level, // defaults to `public`
                    identityId: identityId,
                    download: true, // defaults to false
                    expires: 30, // validity of the URL, in seconds. defaults to 900 (15 minutes)
                    // contentType: 'application/json' // set return content type, eg "text/html"
                }).then(res => {
                  //console.log("result in test: ", JSON.stringify(res))
                  resolve(res)
                })
                .catch(e => {
                  //console.log(e)
                  reject(e)
                })
            })
        }
    }
}
