/* eslint-disable */
<template>
  <div class="specialist-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-specialists', params: {} }">Manage Specialists</router-link></li>
<!--      <li><router-link :to="{ name: 'admin-availability-require-travel', params: {} }">Requiring Travel</router-link></li>-->
      <li><router-link :to="{ name: 'admin-manage-availability', params: {} }">Add/Edit Specialist Routine</router-link></li>
      <li><router-link :to="{ name: 'admin-referral-network', params: {} }">Manage Referral Network</router-link></li>
      <!-- <li><router-link :to="{ name: 'admin-manage-specialist', params: {} }">Add Specialist</router-link></li> -->
      <!-- <li class="is-icon" @click="openSearch">Search</li> -->
    </ul>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: 'AdminSpecialistMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {
    openSearch() {
      this.$parent.$refs.navigation.openSearchSpecialist()
    },

    async getUrl(){
      this.$store.dispatch("loading/setLoadComponent", true)
      var blob = await utilities.getSpecialistProfiles()
      var a = document.createElement('a')
      a.setAttribute('href', window.URL.createObjectURL(blob))
      a.setAttribute('download', 'Red Health Referral Network.pdf')
      a.click()
    }

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
