/* eslint-disable */
<template>
  <div class="users">
    <Navigation indexProps="7" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Users</h1></div>
        <div class="header-sub-title">Manage user access to your Red Health Online account.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="top-tool">
          <div class="row">
            <div class="col">
              <div class="icon-group right-float-element">
                <v-btn @click="openAddUserModal()" class="submit-request" dark>Request New User</v-btn>
              </div>
              <AddUserModal ref="addUserModal" />
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:10%" class="long-text">Id</th>
                    <th style="width:30%" class="long-text">Name</th>
                    <th style="width:30%" class="long-text">Email</th>
<!--                    <th style="width:15%" class="long-text">Phone</th>-->
                    <th style="width:30%" class="icon-col">Last Login</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="users.length > 5 ? 'content-scroll-y': ''">
              <table class="table">
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <th style="width:10%" >{{user.id}}</th>
                    <th style="width:30%" >{{user.name}}</th>
                    <th style="width:30%" >{{user.email}}</th>
<!--                    <th style="width:15%" class="long-text">{{user.phone}}</th>-->
                    <th style="width:30%" class="icon-col">{{formatDate(user.lastLoggedInDate, "DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss")}}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''"
                  v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import Header from '@/components/Header'
import AddUserModal from '@/components/AddUserModal'
import Chat from '@/components/Chat'

export default {
  name: 'Users',
  components: {
    Navigation,
    AddUserModal,
    Header,
    Chat,
  },
  data() {
    return {
      limit: 10,
      pagination: utilities.initPagination(),
      successMessage: '',
      users: [],
      client: [],
      byName: true,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {
    selectPage(index) {
      this.pagination.current = index
      this.users = this.pagination.list[this.pagination.current]
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date) {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ""
    },

    openAddUserModal() {
      this.$refs.addUserModal.handleModal()
    },

    displayMessage() {
      this.successMessage = "Your request for a new user has been sent to Red Health.  It may take up to 24 hours for your request to be actioned."
      utilities.showAlert(".alert-success")
    },

    paging(users) {
      utilities.disposeTooltip()
      if(users.length == 0 || users.length <= this.limit) {
        this.users = users
        $(".pagination").hide()
      } else {
        this.pagination = utilities.loadPagination(users, this.limit)
        this.users = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      }
      utilities.initTooltip()
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      var allusers = await utilities.getClientUsers(this.user.clientId)
      this.client = await utilities.getClientById(this.user.clientId)
      allusers.sort((a, b) => {
        if(this.byName) {  // ASC
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        } else { // DESC
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
        }
      })

      this.users = allusers.filter(item => item.status)
      this.paging(this.users)
      this.byName = !this.byName
      this.$store.dispatch("loading/setLoadComponent", false)
    }
  },

  async beforeMount() {
    if (this.user.type != 2) {
      this.$router.push({path:'/'}).catch((err) => {})
    } else {
      await this.loadData()
    }
  },

  mounted() {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip()
      $(".pagination").hide()
    }, 500)
  },
}
</script>
