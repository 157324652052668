/* eslint-disable */
<template>
  <div class="booking_single_calendar">
    <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="5" />
    <Navigation v-else indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Availability: {{ availabilityBooking.specialist.id != '' ? availabilityBooking.specialist.fullName : '' }}</h1></div>
        <div class="header-sub-title">Click on an available timeslot to Book that time.  Navigate to another day using the month calendars.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu  v-if="user.type >= 3" indexProps="0" />
      <SpecialistMenu v-else indexProps="0" />
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <div class="w-100 mw-100 long-text">
              <span class="mr-1">{{ formatDate(calendarDate, "dddd Do MMMM, YYYY") }}</span>
              <span v-if="locationList.trim().length > 0">|</span>
              <span v-if="locationList.trim().length > 0" class="ml-1 location-list" data-placement="bottom" data-toggle="tooltip" :title="locationList"><strong>Location: </strong>{{ locationList }}</span>
            </div>
          </div>
          <div class="box-body">
            <v-row class="fill-height">
                <v-col>
                  <v-sheet height="600">
                    <v-calendar
                      id="calendar"
                      ref="calendar"
                      color="primary"
                      type="day"
                      v-model="availabilityBooking.calendarDate"
                      :interval-height="anHourHeight"
                      :events="availabilityBooking.bookings"
                      :event-ripple="false"
                      :weekday-format="setWeekday"
                      :day-format="setDay"
                      event-overlap-mode="column">
                      <template v-slot:event="{ event }">
                        <div v-if="!event.bookingId" :id="event.item.AvailabilityID" @click="toBooking(event.item)"  class="fill-height border calendar-event v-event-draggable" :data="event.id">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="d-flex align-items-center ml-2">
                                <div v-if="event.item.Options.VideoOnly" class="video-icon"></div>
                                <div v-if="event.item.Options.VideoLinkAvailable" class="user-video-icon"></div>
                                <div v-if="!event.item.Options.VideoLinkAvailable && !event.item.Options.VideoOnly" class="face-icon"></div>
                                <div v-if="event.item.Options.ShowWorkCover" class="show-worker-icon"></div>
                                <div v-if="event.item.Options.ShowSpecificClients" class="specific-client-icon"></div>
                                <div v-if="event.item.Options.ByRequestOnly" class="request-icon"></div>
                              </div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.name)}}</div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="calendar-event-booking-tooltip w-100 v-event-draggable" style="z-index: 100" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.bookingDetail.detail">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.name)}}</div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                  <div class="box-col d-flex mt-5">
                    <div class="availability-item">
                      <div class="calender-indicator calender-available"></div>
                      <div class="indicator-text">Available</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-bookied"></div>
                      <div class="indicator-text">Booked</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-onhold"></div>
                      <div class="indicator-text">On hold</div>
                    </div>
                  </div>
                  <br />

                  <div class="alert alert-success hidden-element">
                    <a class="close" title="close">×</a>
                    <b>Success:</b> {{ successMessage }}
                  </div>
                  <div class="alert alert-danger hidden-element">
                    <a class="close" title="close">×</a>
                    <b>Error:</b> {{ errorMessage }}
                  </div>
                  <div class="alert alert-warning hidden-element">
                    <a class="close" title="close" @click="hideAlert(false)">×</a>
                    <div class="reset-line-height">
                      <b>Warning:</b> {{ errorMessage }}
                    </div>
                  </div>
                  <br />
                </v-col>
                <v-col cols="4">
                  <VerticalYearCalendar ref="verticalYearCalendar" :key="calendarKey"
                    :calendarDateProps="formatDate(availabilityBooking.calendarDate, 'YYYY')"
                    :dataSourceProps="availabilityBooking.dataSourceAvailabilities"
                    :isAdminProps = "false" />
                </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation";
import AdminBookingsMenu from "@/components/AdminBookingsMenu";
import SpecialistMenu from '@/components/SpecialistMenu'
import Navigation from '@/components/Navigation'
import VerticalYearCalendar from "@/components/VerticalYearCalendar"
import Header from "@/components/Header"

export default {
  name: "BookingSingleCalendar",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    SpecialistMenu,
    Navigation,
    VerticalYearCalendar,
    Header,
  },
  data() {
    return {
      hasError: false,
      successMessage: "",
      errorMessage: "",

      bookingProps: '',
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      calendarDate: '',
      availabilityBooking: {
        specialist: utilities.initSpecialist(),
        calendarDate: "",
        availabilities: [],
        dataSourceAvailabilities: [],
        bookings: [],
        travels: [],
      },
      bookingEvents: [],
      availabilityEvents: [],
      locationList: "",
      availabilityId: "",
      showTravel: false,
      selectedAvailability: {
        id: 0,
        locationId: 0,
      },
      specialistTimes: [],
      minimumAppointmentLength: utilities.APPOINTMENT_LENGTH,
      anHourHeight: utilities.AN_HOUR_HEIGHT,
      calendarKey: 0,
      travelKey: 0,
    }
  },
  computed: {},
  methods: {
    toBooking(item) {
      if(item.BookingStatus === 'H' || item.BookingStatus === 'B' || item.AvailabilityStatus === '1') return
      this.openAvailabilityBookings(item)
    },
    hasDownloaded() {
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    hideAlert() {
      utilities.hideAlert('.alert-warning')
    },
    async exportPdf() {
      this.$store.dispatch('loading/setLoadComponent', true)
      this.$refs.specialistAvailabilityBookingPdf.specialist.id = this.availabilityBooking.specialist.id
      await this.$refs.specialistAvailabilityBookingPdf.loadData(this.calendarDate)
      if (this.$refs.specialistAvailabilityBookingPdf.bookings.length > 0) {
        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf()
        }, 1000)
      } else {
        this.errorMessage = 'No bookings found for this day.'
        utilities.showAlert('.alert-danger')
        this.$store.dispatch('loading/setLoadComponent', false)
      }
    },
    setWeekday(d) {
      return this.formatDate(d.date, 'dddd')
    },
    setDay(d) {
      return this.formatDate(d.date, 'DD/MM/YYYY')
    },
    formatDate(dateToFormat, newFormat, currentFormat = "") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },

    checkAvailabilityLength(availability) {
      var totalAvailableTime = 0
      var isAvailable = false
      this.availabilityBooking.availabilities.filter(x => x.availabilityDate == this.calendarDate).map(a => {
        for(var i = 0; i <= a.mergedTimes.length - 1; i ++) {
          if (a.mergedTimes[i].startTime == availability.selectedTime) {
            isAvailable = true
          }

          if (isAvailable && a.mergedTimes[i].status == 'A') {
            var duration = moment.duration(moment(this.calendarDate + " " + a.mergedTimes[i].endTime, "YYYY-MM-DD hh:mm A").diff(moment(this.calendarDate + " " + a.mergedTimes[i].startTime, "YYYY-MM-DD hh:mm A")))
            totalAvailableTime = duration.asMinutes()
          } else {
            isAvailable = false
          }

          if(isAvailable) {
            break
          }
        }
      })
      //ticket #5217
      // if (totalAvailableTime < this.minimumAppointmentLength) {
      //   this.errorMessage = "The available timeslot is less than the minimum appointment length for the selected specialist for a " + this.bookingProps.typeName + ". Please select another time."
      //   utilities.showAlert(".alert-warning", true)
      //   $("html, body").animate({ scrollTop: $(".alert-warning").position().top }, 1000)
      //   return false
      // } else {
      //   return true
      // }
      return true
    },

    updateCalendarEvent() {
      setTimeout(() => {
        // Init tooltip
        $('[data-toggle="tooltip"]').tooltip({template: '<div class="tooltip calendar-event"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'})

        if(this.locationList.trim().length > 0) {
          var childWidth = Number($(".location-list").css("width").replace("px", ""))
          var parentWidth = Number($(".location-list").parent().css("width").replace("px", ""))
          if(childWidth < parentWidth / 2) $(".location-list[data-toggle='tooltip']").tooltip('dispose')
        }

        // Click on availability time slot
        $(".available-times").children().unbind("click")
        $(".available-times").children().click((e) => {
          var id = Number($(e.currentTarget).parent().attr("data"))
          var availability = this.availabilityBooking.availabilities.find(x => x.id == id)
          if(availability != undefined) {
            availability.selectedTime = $(e.currentTarget).attr("data")
            //cheking selected timesolt is enough for the booking
            //Ticket #5217
            // var result = this.checkAvailabilityLength(availability)
            // if (!result) {
            //   return
            // }
            this.bookingProps.mergedTimes = availability.mergedTimes.find((x) => x.startTime == availability.selectedTime)
            this.bookingProps.bookingTime = availability.selectedTime
            this.bookingProps.locationName = this.locationList
            this.bookingProps.locationId = availability.locationId
            this.bookingProps.availabilityId = availability.id
            this.bookingProps.waitlisted = false
            this.$store.dispatch("booking/setBookingObject", this.bookingProps)

            if (this.user.type >= 3) {
              if (this.bookingProps.caseId || this.bookingProps.bookingRequestId) {
                this.$router.push({ name: 'admin-create-new-booking'}).catch((err) => {})
              } else {
                this.$router.push({ name: 'admin-search-cases-details' }).catch((err) => {})
              }
            } else {
              if (this.bookingProps.bookingId || this.bookingProps.caseId) {
                this.$router.push({ name: 'manage-booking'}).catch((err) => {})
              } else {
                this.$router.push({ name: 'search-cases-details'}).catch((err) => {})
              }
            }
            //this.openAvailabilityBookings(availability)
          }
        })
      }, 500)
    },

    async openAvailabilityBookings(availability) {
         this.bookingProps.endDate = this.availabilityBooking.calendarDate,
         this.bookingProps.locationId = availability.Location.LocationID,
         this.bookingProps.bookingDate = this.availabilityBooking.calendarDate,
         this.bookingProps.bookingTime = availability.StartTime,
         this.bookingProps.bookingEndTime = availability.EndTime,
         this.bookingProps.availabilityId = availability.AvailabilityID,
         this.bookingProps.locationName = availability.Location.LocationName,
         this.bookingProps.typeId = '',
         this.bookingProps.hasUserVideo = availability.Options.VideoLinkAvailable ? true : false,
         this.bookingProps.hasVideo = availability.Options.VideoOnly === 1 ? true : false
      this.$store.dispatch("booking/setBookingObject", this.bookingProps)
      this.$router.push({ name: 'search-cases-details' }).catch((err) => {})
    },

    loadTimeSlot() {
      this.specialistTimes.map(x => {
        var index = this.availabilityBooking.availabilities.findIndex(y => y.id == x.specialistAvailabilityId)
        if(index > -1) {
          this.minimumAppointmentLength = x.slotSize
          this.availabilityBooking.availabilities[index].availableTimes = []
          x.slots.map(y => {
            var item = {
              time: utilities.formatDate(y.time, "hh:mm A", "HH:mm"),
              status: y.status
            }
            this.availabilityBooking.availabilities[index].availableTimes.push(item)
          })
        }
      })
    },
    async loadAvailabilityBooking(bookingId) {
      if(bookingId != '') {
        let params = {
          specialistId: bookingId,
          startDate: this.bookingProps.bookingDate,
          endDate: this.bookingProps.bookingDate,
        }

        const params2 = {
          StartDate: moment().startOf('month').format("YYYY-MM-DD"),
          EndDate: moment().startOf('month').add(1, 'year').format("YYYY-MM-DD")
        }

        const apiArr = [utilities.getSpecialistById(bookingId), utilities.getSpecialistBookings(params), utilities.getSpecialistAvailabilities(bookingId, '', params2)]
        const r = await Promise.all(apiArr)
        this.availabilityBooking.specialist = r[0]
        let bookings = r[1]
        let tempArray = []
        for (let i = 0; i < bookings.length; i++) {
          let booking =  bookings[i]
          if (booking.bookingDetails.typeId != 6 && booking.bookingDetails.typeId != 8) {
            if (booking.bookingDetails.status != 1 && booking.bookingDetails.status != 6 && booking.bookingDetails.status != 16) {
              tempArray.push(booking)
            }
          }
        }
        bookings = tempArray
        let availabilities = r[2]
        this.locationList = _.uniqBy(_.uniqBy(availabilities.filter(x => x.availabilityDate == this.calendarDate), 'id'), 'locationId').map(x => x.fullLocation).join("; ")

        this.availabilityBooking.availabilities = _.cloneDeep(availabilities)
        this.availabilityBooking.dataSourceAvailabilities = _.cloneDeep(availabilities)
        this.bookingEvents = []
        bookings.map(x => {
          this.bookingEvents.push(utilities.loadBooking(x))
        })

        // Get Availability Events
        this.availabilityEvents = []
        // Show all availabilities from today to future only
        const availability = this.availabilityBooking.availabilities.find(x => window.moment(x.date).isSame(window.moment(this.calendarDate)))
        if(availability.Availabilities.length === 0) return
        this.bookingEvents.forEach(e => {
          const item = availability.Availabilities.find(item => item.AvailabilityID === e.availabilityId)
          item.booking = e
        })
        let arr = []
        availability.Availabilities.forEach(item => {
          const year = window.moment(availability.availabilityDate).format('YYYY-MM-DD')
          arr.push({
            name: item?.booking?.fullLocation || item?.Location?.LocationName,
            start: window.moment(year + ' ' + item.StartTime).format('YYYY-MM-DD HH:mm'),
            end: window.moment(year + ' ' + item.EndTime).format('YYYY-MM-DD HH:mm'),
            startTime: window.moment(item.StartTime, 'HH:mm:ss').format('hh:mm A'),
            endTime: window.moment(item.EndTime, 'HH:mm:ss').format('hh:mm A'),
            color: utilities.getClientAvailabilityColors(item),
            timed: true,
            status: item.BookingStatus,
            booking: item.booking,
            item: item
          })
        })
        this.bookingEvents.forEach(item => {
          const obj = {
            bookingId: item.id,
            className: 'booking-event',
            name: item?.fullLocation,
            start: window.moment(item.start).format('YYYY-MM-DD HH:mm'),
            end: window.moment(item.end).format('YYYY-MM-DD HH:mm'),
            startTime: window.moment(item.start).format('hh:mm A'),
            endTime: window.moment(item.end).format('hh:mm A'),
            color: '#0080ff',
            timed: false,
            bookingDetail: item
          }
          arr.push(obj)
        })
        this.availabilityEvents = arr
        this.availabilityBooking.bookings = arr
        this.calendarKey += 1
        this.travelKey += 1


        // Update time slot on front end
        setTimeout(() => {
          // Clear all available times on calendar
          $(".available-times").remove()
          this.availabilityBooking.availabilities.filter(x => x.availabilityDate == this.calendarDate).map(a => {
            // Add available times to calendar
            $(".v-event-timed-container").parent().append("<div class='available-times' data='" + a.id + "'></div>")
            $(".available-times[data='" + a.id + "']").css("top", $(".calendar-event-availability[data='" + a.id + "']").parent().css("top"))
            // Add slot to available times
            a.mergedTimes.map((x, xi) => {
              var duration = moment.duration(moment(this.calendarDate + " " + x.endTime, "YYYY-MM-DD hh:mm A").diff(moment(this.calendarDate + " " + x.startTime, "YYYY-MM-DD hh:mm A")))
              var length = duration.asMinutes()
              var start = moment(x.startTime, 'hh:mm A')
              var end = moment(x.endTime, 'hh:mm A')
              var height = utilities.convertTimeToHeight(start, end, 'hh:mm A')
              // Ticket 5217
              // var element = "<div class='v-event-timed-slot" + (length < this.minimumAppointmentLength ? ' not-available-slot' : '') + "' style='height:" + height + "px' data='" + x.startTime + "'></div>"
              var element = "<div class='v-event-timed-slot" + "' style='height:" + height + "px' data='" + x.startTime + "'></div>"
              $(".available-times[data='" + a.id + "']").append(element)
            })
          })
          // Hide availability
          // this.hideAvailability()
        }, 300)

        this.calendarKey += 1
        this.travelKey += 1

        var availabilityToday = availabilities.filter(x => moment(x.availabilityDate, "YYYY-MM-DD").isSame(moment(this.availabilityBooking.calendarDate, "YYYY-MM-DD"),'d'))[0]
        if (availabilityToday != undefined) {
          // Show the Manage button
          this.availabilityId = availabilityToday.id
        }

        setTimeout(() => {
          // Scroll down to the current month
          var month = moment(this.calendarDate, "YYYY-MM-DD").format("MM")
          var positionTo = 2
          if (month == 1) {
            positionTo = 0.5
          } else if (month == 12) {
            positionTo = 1
          }
          var index = Number(month) - positionTo
          // Check index is integer not decimal
          if(Number.isInteger(index)) $(".vertical-year-calendar .months-container").animate({ scrollTop: $(".vertical-year-calendar .months-container .month-container").eq(index).offset().top }, 'slow')
        }, 300)
      }

      this.originalEvent = null
    },

    async reloadCalendar() {
      this.bookingProps = this.$store.getters["booking/getBookingObject"]
      if (this.bookingProps == null) {
        if (this.user.type >= 3) {
          this.$router.push({ name: 'management'}).catch((err) => {})
        } else {
          this.$router.push({ name: 'home'}).catch((err) => {})
        }
      } else {
        if(this.bookingProps != undefined && moment(this.bookingProps.bookingDate, "YYYY-MM-DD").isValid()) {
          this.$store.dispatch("loading/setLoadComponent", true)
          this.calendarDate = this.bookingProps.bookingDate
          this.availabilityBooking.calendarDate = this.calendarDate
          await this.loadAvailabilityBooking(this.bookingProps.id)
          this.updateCalendarEvent()
          this.$store.dispatch("loading/setLoadComponent", false)
        }
      }
    },
  },

  async beforeMount() {
    $('body>.tooltip').remove()
    await this.reloadCalendar()
    // Scroll down to the appointment
    $(".v-calendar-daily__scroll-area").animate({ scrollTop: ($(".v-calendar-daily__scroll-area").height() / 3 * 4) }, 'slow')
  },
  mounted() {},
}
</script>
