/* eslint-disable */
<template>
  <div class="add-routine-availability">
    <div class="box">
      <div class="box-heading"><span>Add Routine Availability</span></div>
      <div class="box-body">
        <div v-if="!isFromSpecialistProps" class="box-row">
          <div class="box-col box-col-left">Choose Specialist</div>
          <div class="box-col box-col-right">
            <el-select placeholder="Please choose ..." @change="selectedSpecialist" class="select-dropdown w-50" :class="hasError && !validateSpecialist ? 'is-invalid' : ''" v-model="availability.SpecialistID" filterable>
              <el-option v-for="item in originalSpecialists" :key="item.id" :value="item.id" :label="item.name">{{item.name}}</el-option>
            </el-select>
            <div v-if="hasError && !validateSpecialist" class="text-danger">Specialist is required.</div>
          </div>
        </div>
        <div class="box-row">
          <div class="box-col box-col-left">Location</div>
          <div class="box-col box-col-right d-flex">
            <div class="w-100">
              <el-select popper-class="search-specialty-popup" clearable filterable class="w-100" placeholder="Please choose" :class="hasError && !validateLocation ? 'is-invalid' : ''" v-model="availability.LocationID" @change="changeLocation">
                <el-option v-for="(location, index) in locations" :value="location.id" :key="index" :label="location.fullLocation">{{ location.fullLocation }}</el-option>
              </el-select>
              <div v-if="hasError && !validateLocation" class="text-danger">Location is required.</div>
            </div>
          </div>
        </div>
        <div class="box-row">
          <div class="box-col box-col-left">Select Type of Booking/Travel Required</div>
          <div class="box-col box-col-right d-flex">
            <div class="w-50 px-5">
              <img class="is-icon" :src="availability.Options.VideoOnly ? require('@/assets/images/video_black_icon.svg') : require('@/assets/images/video_icon.svg')"
                   @click="changeOnlyAllowVideo" data-toggle="tooltip" data-placement="right" title="Only Allow Video" />
              <img class="is-icon pl-3" style="height: 30px" :src="availability.Options.VideoLinkAvailable ? require('@/assets/images/user_video_icon_black.svg') : require('@/assets/images/user_video_icon.svg')"
                   @click="changeVideoLinkAvailable" data-toggle="tooltip" data-placement="right" title="Video Link available" />
              <img class="is-icon pl-3" :src="availability.Options.ByRequestOnly ? require('@/assets/images/phone_black_icon.svg') : require('@/assets/images/phone_icon.svg')"
                   @click="changeByRequestOnly" data-toggle="tooltip" data-placement="right" title="By Request only" />
            </div>
          </div>
        </div>
        <div class="box-row">
          <div class="box-col box-col-left">Frequency</div>
          <div class="box-col box-col-right">
            <select class="textbox" :class="hasError && !validateFrequency ? 'is-invalid' : ''" v-model="availability.frequency" @change="changeFrequency">
              <option hidden value="">Please choose ...</option>
              <option v-for="(frequency, index) in frequencies" :value="frequency.value">{{ frequency.text }}</option>
            </select>
            <div v-if="hasError && !validateFrequency" class="text-danger">Frequency is required.</div>
          </div>
        </div>
        <div v-if="availability.frequency == 1" class="box-row">
          <div class="box-col box-col-left">Date</div>
          <div class="box-col box-col-right box-col-center">
            <datepicker v-model="date"
              format="dd/MM/yyyy"
              :typeable="false"
              class="w-50"
              :input-class="hasError && !validateDate ? 'textbox is-invalid' : 'textbox'"
              :disabled-dates="disabledDates"
              placeholder="dd/mm/yyyy"></datepicker>
            <div v-if="hasError && !validateDate" class="text-danger">Date is required.</div>
          </div>
        </div>
        <div v-if="availability.frequency > 1" class="box-row">
          <div class="box-col box-col-left">Occurrence</div>
          <div class="box-col box-col-right">
            <select class="textbox" :class="hasError && !validateOccurrence ? 'is-invalid' : ''" v-model="availability.occurrence">
              <option hidden value="">Please choose ...</option>
              <option v-for="(occurrence, index) in occurrences" :value="occurrence.value">{{ occurrence.text }}</option>
            </select>
            <div v-if="hasError && !validateOccurrence" class="text-danger">Occurrence is required.</div>
          </div>
        </div>
        <div v-if="availability.frequency == 3 || availability.frequency == 4" class="box-row">
          <div class="box-col box-col-left">Day/s</div>
          <div class="box-col box-col-right col-center-content">
            <vue-tags-input
              v-model="weekDayTag"
              :tags="weekDayTags"
              :add-only-from-autocomplete="true"
              :autocomplete-items="weekDays"
              placeholder="Add Days"
              @tags-changed="(newTags) => (weekDayTags = newTags)" />
            <div v-if="hasError && !validateDaysOfWeek" class="text-danger">Day/s of the week is required.</div>
          </div>
        </div>
        <div class="box-row">
          <div class="box-col w-25">Start Time</div>
          <div class="box-col w-25">
            <vue-timepicker v-model="availability.StartTime"
              format="hh:mm A"
              class="w-100"
              :input-class="hasError && !validateStartTime ? 'is-invalid' : ''"
              input-class="form-control"
              :minute-interval="15"
              placeholder="HH:MM"></vue-timepicker>
            <div v-if="hasError && !validateStartTime" class="text-danger">{{ startTimeMessage }}</div>
          </div>
          <div class="box-col w-25">
            <span class="ml-10">Number of Appointments </span>
          </div>
          <div class="box-col w-25">
            <el-input-number class="number-input" v-model="availability.Number" :min="1" :max="100" ></el-input-number>
          </div>
        </div>
        <div v-if="availability.frequency > 1" class="box-row">
          <div class="box-col box-col-left">Dates</div>
          <div class="box-col box-col-right box-col-center">
            <v-md-date-range-picker ref="dateRangePicker"
              class="w-50"
              @change="changeDates"></v-md-date-range-picker>
            <div v-if="hasError && ((!validateStartDate && !validateEndDate) || !validateDateRange)" class="text-danger">{{ dateRangeMessage }}</div>
          </div>
        </div>
        <div class="box-row">
          <div class="box-col box-col-left">Appointment Duration</div>
          <div class="box-col box-col-right">
            <el-input-number class="number-input" v-model="availability.Duration" :step="5" :min="5" :max="1000" ></el-input-number>
            <span class="ms-2">mins</span>
            <div v-if="hasError && !validateStatus" class="text-danger">Duration is required.</div>
          </div>
        </div>
        <div class="box-row">
          <div class="box-col w-25">Visibility Configuration</div>
          <div class="box-col w-25" >
            <el-tooltip v-if="isFromSpecialistProps" :content="availability.Options.ShowWorkCover ? 'Clicking this button will mean that WorkCover Queensland can not book this appointment' : 'Clicking this button will mean that WorkCover Queensland can book this appointment'">
              <img @click="changeShowWorkCover" class="is-icon" style="height: 26px" :src="availability.Options.ShowWorkCover ? require('./../assets/images/work_cover.svg') : require('./../assets/images/work_cover_gray.svg')" />
            </el-tooltip>
            <el-tooltip v-else :content="availability.Options.ShowWorkCover ? 'Show to everyone INCLUDING WorkCover QLD' : 'Show to everyone EXCEPT WorkCover QLD'">
              <img @click="changeShowWorkCover" class="is-icon" style="height: 26px" :src="availability.Options.ShowWorkCover ? require('./../assets/images/work_cover.svg') : require('./../assets/images/work_cover_gray.svg')" />
            </el-tooltip>
            <el-tooltip v-if="user.type >= 6" :content="availability.Options.ShowSpecificClients ? 'Show only to specific clients' : 'Show for all clients'">
              <img @click="changeShowSpecificClients" class="is-icon ml-3" style="height: 26px" :src="availability.Options.ShowSpecificClients ? require('./../assets/images/specific_users.svg') : require('./../assets/images/specific_users_gray.svg')" />
            </el-tooltip>
          </div>
          <div class="box-col w-50">
            <el-select clearable class="w-100" v-if="availability.Options.ShowSpecificClients" v-model="availability.ClientIDs" multiple filterable placeholder="Hide from everyone EXCEPT these specific clients">
              <el-option v-for="item in clients" :key="item.id" :value="item.id" :label="item.fullName"></el-option>
            </el-select>
            <div v-if="hasError && !validateClients" class="text-danger">Please select clients.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-success hidden-element">
      <a class="close" title="close">×</a>
      <b>Success:</b> {{ successMessage }}
    </div>
    <div class="alert alert-danger hidden-element">
      <a class="close" title="close">×</a>
      <b>Error:</b> {{ errorMessage }}
    </div>

    <div class="icon-group">
      <div class="w-100">
<!--        <v-btn v-if="availabilities && availabilities.length > 0 && !isFromSpecialistProps" class="right-float-element" dark @click="approveAll">Approve All</v-btn>-->
<!--        <v-btn v-if="availabilities && availabilities.length > 0 && !isFromSpecialistProps" class="right-float-element" dark @click="rejectAll">Reject All</v-btn>-->
<!--        <v-btn v-else class="right-float-element" dark @click="submitAll">Submit All</v-btn>-->
        <v-btn class="right-float-element" dark @click="add">Add</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"

export default {
  name: "AddRoutineAvailability",
  props: ["specialistIdsProps", "clients", "specialistIdProps", "specialistNameProps", "availabilitiesProps", "isFromSpecialistProps", 'locations', "specialistRequestProps"],
  data() {
    return {
      hasError: false,
      isOverlapping: false,
      successMessage: "",
      errorMessage: "Please correct the errors shown above and try again.",
      startTimeMessage: "",
      endTimeMessage: "",
      dateRangeMessage: "",
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      availability: utilities.initAvailability(),
      availabilities: [],
      draftAvailabilities: [],
      draftAvailabilitiesKey: 0,
      date: "",
      fillOrder: null,
      specialists: [],
      originalSpecialists: [],
      listSpecialists: [],
      frequencies: [],
      occurrences: [],
      fillOrders: [],
      availabilityStatuses: [],

      weekDayTag: "",
      weekDayTags: [],
      weekDays: [],
      days: [],
      disableType: false,
      currentDate: moment().format("YYYY-MM-DD"),
      disabledDates: {},
    }
  },
  watch: {
    'specialistRequestProps': {
      handler (newVal) {
        if(newVal) {
          this.disableType = true
          this.availability.byRequestOnly = true
          this.availability.requiresTravel = false
          this.availability.VideoOnly = 0
          this.availability.videoLinkAvailable = 1
        } else {
          this.disableType = false
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    validateSpecialist() {
      if(this.availability.SpecialistID == "") {
        return false
      }
      return true
    },
    validateLocation() {
      if(this.availability.LocationID == "") {
        return false
      }
      return true
    },
    validateFrequency() {
      if(this.availability.frequency == "") {
        return false
      }
      return true
    },
    validateOccurrence() {
      if(this.availability.occurrence == "") {
        return false
      }
      return true
    },
    validateDaysOfWeek() {
      if((this.availability.frequency == 3 || this.availability.frequency == 4) && this.weekDayTags.length == 0) {
        return false
      }
      return true
    },
    validateDate() {
      if(this.date == "") {
        return false
      }
      return true
    },
    validateStartTime() {
      if(this.availability.StartTime == "") {
        this.startTimeMessage = "Start Time is required."
        return false
      } else if(!moment(moment().format("YYYY-MM-DD") + " " + this.availability.StartTime, "YYYY-MM-DD hh:mm A").isValid()) {
        this.startTimeMessage = "Start Time is invalid."
        return false
      }
      return true
    },
    validateEndTime() {
      if(this.availability.endTime == "") {
        this.endTimeMessage = "End Time is required."
        return false
      } else if(!moment(moment().format("YYYY-MM-DD") + " " + this.availability.endTime, "YYYY-MM-DD hh:mm A").isValid()) {
        this.endTimeMessage = "End Time is invalid."
        return false
      } else {
        var start = moment(this.availability.StartTime, "hh:ss A")
        var end = moment(this.availability.endTime, "hh:ss A")
        if((end-start) / 1000 < 30 || start.isAfter(end)) {
          this.endTimeMessage = "End Time must be at least 30 minutes."
          return false
        }
      }
      return true
    },
    validateStartDate() {
      if(this.availability.startDate == "") {
        this.dateRangeMessage = "Please select a date range."
        return false
      }
      return true
    },
    validateEndDate() {
      if(this.availability.endDate == "") {
        this.dateRangeMessage = "Please select a date range."
        return false
      }
      return true
    },
    validateDateRange() {
      if(this.days.length == 0) {
        this.dateRangeMessage = "Please select a valid date range."
        return false
      }
      return true
    },
    validateStatus() {
      if(this.availability.availabilityStatus == "") {
        return false
      }
      return true
    },
    validateClients() {
      if(this.availability.Options.ShowSpecificClients === 1 && (!this.availability.ClientIDs || this.availability.ClientIDs.length === 0)) {
        return false
      }
      return true
    }
  },
  methods: {
    changeShowWorkCover() {
      if(this.availability.Options.ShowWorkCover) {
        this.availability.Options.ShowWorkCover = 0
      } else {
        this.availability.Options.ShowWorkCover = 1
      }
    },
    changeShowSpecificClients() {
      if(this.availability.Options.ShowSpecificClients) {
        this.availability.Options.ShowSpecificClients = 0
        this.availability.ClientIDs = []
      } else {
        this.availability.Options.ShowSpecificClients = 1
      }
    },
    loadDraft(item) {
      this.availability = _.cloneDeep(item)
      this.weekDayTags = this.availability.weekDayTags
      this.fillOrder = this.availability.fillOrder
      if(this.$refs.dateRangePicker != undefined) {
        this.$refs.dateRangePicker.start_ = moment(this.availability.startDate, "YYYY-MM-DD")
        this.$refs.dateRangePicker.end_ = moment(this.availability.endDate, "YYYY-MM-DD")
        this.$refs.dateRangePicker.startText = moment(this.availability.startDate, "YYYY-MM-DD").format('DD/MM/YYYY')
        this.$refs.dateRangePicker.endText = moment(this.availability.endDate, "YYYY-MM-DD").format('DD/MM/YYYY')
      }
    },
    changeDates(values) {
      this.hasError = false
      if(!values[0].isSame(moment(this.availability.startDate, "YYYY-MM-DD")) || !values[1].isSame(moment(this.availability.endDate, "YYYY-MM-DD"))) {
        if(values[0].isBefore(moment())) {
          this.$refs.dateRangePicker.start_ = moment()
          this.$refs.dateRangePicker.startText = moment().format('DD/MM/YYYY')
        }
        if(values[1].isBefore(moment())) {
          this.$refs.dateRangePicker.end_ = moment()
          this.$refs.dateRangePicker.endText = moment().format('DD/MM/YYYY')
        }
        this.availability.startDate = this.$refs.dateRangePicker.start_.format('YYYY-MM-DD')
        this.availability.endDate = this.$refs.dateRangePicker.end_.format('YYYY-MM-DD')
      }
    },
    selectedSpecialist(item) {
      if(item) {
        if (this.$route.fullPath.includes('/admin-manage-availability')) {
          this.$parent.loadAvailability(this.availability.SpecialistID)
        }
      }
    },
    filterSpecialist(text) {
      this.listSpecialists = this.originalSpecialists.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
    },
    // changeSpecialist() {
    //   if(this.$route.fullPath.includes('/admin-manage-availability')) {
    //     this.$store.dispatch("loading/setLoadComponent", true)
    //     this.$parent.loadAvailability(this.availability.specialistId)
    //   }
    // },
    changeFrequency() {
      if(this.availability.frequency == 4) {
        this.occurrences = utilities.getOccurrences()
      } else {
        this.occurrences = utilities.getOccurrences().filter(x => x.value != 2)
        if(this.availability.occurrence == 2) this.availability.occurrence = ""
      }
    },
    changeLocation(e) {
      var location = this.locations.find(x => x.id === Number(e))
      this.availability.locationName = location.name
      this.availability.cityName = location.cityName
      console.log(this.availability)
    },
    changeRequiresTravel() {
      this.availability.requiresTravel = !this.availability.requiresTravel
    },
    changeByRequestOnly() {
      if(this.availability.Options.ByRequestOnly) {
        this.availability.Options.ByRequestOnly = 0
      } else {
        this.availability.Options.ByRequestOnly = 1
        this.availability.Options.VideoLinkAvailable = 0
        this.availability.Options.VideoOnly = 0
      }
    },
    changeVideoLinkAvailable() {
      if(this.availability.Options.VideoLinkAvailable) {
        this.availability.Options.VideoLinkAvailable = 0
      } else {
        this.availability.Options.VideoLinkAvailable = 1
        this.availability.Options.VideoOnly = 0
        this.availability.Options.ByRequestOnly = 0
      }
    },
    changeOnlyAllowVideo() {
      if(this.availability.Options.VideoOnly === 0) {
        this.availability.Options.VideoOnly = 1
        this.availability.Options.VideoLinkAvailable = 0
        this.availability.Options.ByRequestOnly = 0
      } else {
        this.availability.Options.VideoOnly = 0
      }
    },
    changeFillOrder() {
      var fillOrder = this.fillOrders[this.fillOrder]
      if(fillOrder != undefined) this.availability.fillOrder = fillOrder.value
    },
    add() {
      if(!this.validateSpecialist || !this.validateLocation || !this.validateClients || !this.validateFrequency || (this.availability.frequency == 1 && !this.validateDate)
        || (this.availability.frequency > 1 && (!this.validateOccurrence || !this.validateDaysOfWeek || (!this.validateStartDate && !this.validateEndDate)))
        || !this.validateStartTime) {
          this.hasError = true
          utilities.showAlert(".add-routine-availability .alert-danger")
          return false
        }
        this.addAvailability()
    },
    approveAll() {
      this.approveAllAvailability()
    },
    async rejectAll() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const param = {
        UserIDLog: this.user.id,
        UsernameLog: this.user.name,
        SpecialistID: this.availability.SpecialistID
      }
      await utilities.bulkReject(param)
      this.successMessage = "Routine Availability rejected successfully."
      utilities.showAlert(".add-routine-availability .alert-success")
      await this.$parent.reload()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    postAvailability(availability) {
      var params = {
        SpecialistID: availability.SpecialistID,
        LocationID: availability.LocationID,
        AvailabilityDate: utilities.formatDate(availability.availabilityDate),
        StartTime: utilities.formatDate(availability.StartTime, "HH:mm:ss", "hh:mm A"),
        EndTime: utilities.formatDate(availability.endTime, "HH:mm:ss", "hh:mm A"),
        RequiresTravel: utilities.convertBoolToNum(availability.requiresTravel),
        VideoLinkAvailable: availability.videoLinkAvailable,
        VideoOnly: availability.VideoOnly,
        ByRequestOnly: utilities.convertBoolToNum(availability.byRequestOnly),
        FillOrder: availability.fillOrder,
        AvailabilityStatus: availability.availabilityStatus,
        UserID: this.user.id,
        Username:this.user.name
      }

      var callback = (response) => {
        this.successMessage = "Routine Availability added successfully."
        utilities.showAlert(".add-routine-availability .alert-success")

      }
      return utilities.postAvailability(params, callback)
    },

    async addAvailability() {
      this.days = []
      var frequency = this.frequencies.find(x => x.value == this.availability.frequency).text

      this.availability.availabilityDate = moment().format("YYYY-MM-DD") + '-' + this.draftAvailabilities.length
      this.availability.id = this.availability.availabilityDate
      if(this.availability.startDate == '') this.availability.startDate = this.availability.availabilityDate
      if(this.availability.endDate == '') this.availability.endDate = this.availability.availabilityDate
      this.availability.hasVideo = this.availability.VideoOnly === 1 ? true : false
      this.availability.hasUserVideo = this.availability.videoLinkAvailable === 2 ? true : false
      this.availability.isInFuture = this.availability.availabilityDate > moment() ? true : false
      this.availability.fullName = this.specialistNameProps != undefined ? this.specialistNameProps : this.specialists.find(x => x.id == this.availability.specialistId).fullName
      this.availability.availabilities = []

      if(this.availability.frequency == 1) {  // Once Off
        this.days.push(utilities.formatDate(this.date))

        this.availability.label = this.availability.locationName + ', ' + this.availability.cityName + ', ' + frequency + ', ' + this.availability.StartTime + ' - ' + this.availability.endTime + ', '
        + utilities.formatDate(this.date, "DD/MM/YYYY")
      } else {  // Daily, Weekly, Monthly
        var label = ""
        var occurrence = this.occurrences.find(x => x.value == this.availability.occurrence).text.toLowerCase()
        var weekDays = this.weekDayTags.map(x => x.text).join(", ")

        var days = []
        // Get all dates in a range of dates
        for (var i = moment(this.availability.startDate, "YYYY-MM-DD"); i.diff(moment(this.availability.endDate, "YYYY-MM-DD"), 'days') <= 0; i.add(1, 'days')) {
          this.days.push(i.format('YYYY-MM-DD'))
        }

        if(this.availability.frequency == 2) {  // Daily
          if(this.availability.occurrence == 1) { // Every
            label = "everyday"
          } else if(this.availability.occurrence == 3) { // 2nd
            label = "every 2nd day"
            this.days = this.days.filter((x, xi) => (xi + 1)%2 == 0)
          } else if(this.availability.occurrence == 4) {  // 3rd
            label = "every 3rd day"
            this.days = this.days.filter((x, xi) => (xi + 1)%3 == 0)
          } else if(this.availability.occurrence == 5) {  // 4th
            label = "every 4th day"
            this.days = this.days.filter((x, xi) => (xi + 1)%4 == 0)
          }
        } else if(this.availability.frequency == 3) { // Weekly
          if(this.availability.occurrence == 1) { // Every
            label = weekDays + " every week"
            this.weekDayTags.map(y => {
              this.days.map(x => {
                if(moment(x, "YYYY-MM-DD").day() == y.value) {
                  days.push(x)
                }
              })
            })
            this.days = days
          } else {  // 2nd, 3rd, 4th
            this.weekDayTags.map(y => {
              y.days = []
              this.days.map(x => {
                if(moment(x, "YYYY-MM-DD").day() == y.value) {
                  y.days.push(x)
                }
              })
            })

            if(this.availability.occurrence == 3) {  // 2nd
              label = weekDays + " every " + occurrence + " week"
              this.weekDayTags.map(y => {
                y.days = y.days.filter((x, xi) => (xi + 1)%2 == 0)
                days = days.concat(y.days)
              })
              this.days = days
            } else if(this.availability.occurrence == 4) {  // 3rd
              label = weekDays + " every " + occurrence + " week"
              this.weekDayTags.map(y => {
                y.days = y.days.filter((x, xi) => (xi + 1)%3 == 0)
                days = days.concat(y.days)
              })
              this.days = days
            } else if(this.availability.occurrence == 5) {  // 4th
              label = weekDays + " every " + occurrence + " week"
              this.weekDayTags.map(y => {
                y.days = y.days.filter((x, xi) => (xi + 1)%4 == 0)
                days = days.concat(y.days)
              })
              this.days = days
            }
          }
        } else if(this.availability.frequency == 4) { // Monthly
          if(this.availability.occurrence == 1) { // Every
            label = occurrence + " " + weekDays + " of every month"
            this.weekDayTags.map(y => {
              this.days.map(x => {
                if(moment(x, "YYYY-MM-DD").day() == y.value) {
                  days.push(x)
                }
              })
            })
            this.days = days
          } else { // 1st, 2nd, 3rd, 4th
            // Get all months in a range of dates
            var months = []
            var dateStart = moment(this.availability.startDate, "YYYY-MM-DD")
            var dateEnd = moment(this.availability.endDate, "YYYY-MM-DD")
            var month = {
              date: "",
            }
            while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
              month.date = dateStart.format('YYYY-MM')
              months.push(_.cloneDeep(month))
              dateStart.add(1, 'month')
            }
            // Get all weekdays in each month
            months.map(x => {
              this.weekDayTags.map(y => {
                x[y.value] = {
                  days: []
                }
                this.days.map(z => {
                  if(Number(x.date.split('-')[1]) - 1 == moment(z, "YYYY-MM-DD").month() && moment(z, "YYYY-MM-DD").day() == y.value) {
                    x[y.value].days.push(z)
                  }
                })
              })
            })

            days = []
            var date = ""
            if(this.availability.occurrence == 2) {  // 1st
              label = occurrence + " " + weekDays + " of every month"
              months.map(x => {
                this.weekDayTags.map(y => {
                  // Get the 1st weekday in a month
                  if(x[y.value].days[0] != undefined) days.push(x[y.value].days[0])
                })
              })
              this.days = days
            } else if(this.availability.occurrence == 3) {  // 2nd
              label = occurrence + " " + weekDays + " of every month"
              months.map(x => {
                this.weekDayTags.map(y => {
                  // Get the 2nd weekday in a month
                  if(x[y.value].days[1] != undefined) days.push(x[y.value].days[1])
                })
              })
              this.days = days
            } else if(this.availability.occurrence == 4) {  // 3rd
              label = occurrence + " " + weekDays + " of every month"
              months.map(x => {
                this.weekDayTags.map(y => {
                  // Get the 3rd weekday in a month
                  if(x[y.value].days[2] != undefined) days.push(x[y.value].days[2])
                })
              })
              this.days = days
            } else if(this.availability.occurrence == 5) {  // 4th
              label = occurrence + " " + weekDays + " of every month"
              months.map(x => {
                this.weekDayTags.map(y => {
                  // Get the 4th weekday in a month
                  if(x[y.value].days[3] != undefined) days.push(x[y.value].days[3])
                })
              })
              this.days = days
            }
          }
        }

        this.availability.label = this.availability.locationName + ', ' + this.availability.cityName + ', ' + frequency + ', ' + label + ', ' + this.availability.StartTime + ' - ' + this.availability.endTime + ', '
        + utilities.formatDate(this.availability.startDate, "DD/MM/YYYY") + ' - ' + utilities.formatDate(this.availability.endDate, "DD/MM/YYYY")
      }

      if(!this.validateDateRange) {
        this.hasError = true
        utilities.showAlert(".add-routine-availability .alert-danger")
        return false
      } else {
        this.hasError = false
      }

      this.availability.fillOrder = this.fillOrder
      this.availability.weekDayTags = this.weekDayTags
      this.availability.isOverlapping = this.isOverlapping
      this.draftAvailabilities.push(_.cloneDeep(this.availability))
      this.isOverlapping = false

      this.availability.AvailabilityDates = this.days

      this.availability.UsernameLog = this.user.name
      this.availability.UserIDLog = this.user.id
      await this.saveDraftAvailability()
    },
    checkOverlapping(date) {
      var result = false
      var range1 = null
      var range2 = null
      for(var i = 0; i < this.availabilities.length; i++) {
        if(this.availabilities[i].availabilityDate == date) {
          range1 = moment.range(utilities.formatDate(date + " " + this.availability.StartTime, "YYYY-MM-DD HH:mm", "YYYY-MM-DD hh:mm A"), utilities.formatDate(date + " " + this.availability.endTime, "YYYY-MM-DD HH:mm", "YYYY-MM-DD hh:mm A"))
          range2 = moment.range(utilities.formatDate(this.availabilities[i].availabilityDate + " " + this.availabilities[i].startTime, "YYYY-MM-DD HH:mm", "YYYY-MM-DD hh:mm A"), utilities.formatDate(this.availabilities[i].availabilityDate + " " + this.availabilities[i].endTime, "YYYY-MM-DD HH:mm", "YYYY-MM-DD hh:mm A"))
          if(range1.overlaps(range2)) {
            result = true
            this.isOverlapping = true
            break
          }
        }
      }
      return result
    },
    removeRoutineList(index) {
      var draftAvailabily = this.draftAvailabilities[index]
      if(draftAvailabily != undefined) {
        var draftAvailabilities = [...this.draftAvailabilities.slice(0, index), ...this.draftAvailabilities.slice(index + 1, this.draftAvailabilities.length)]
        this.draftAvailabilities = _.cloneDeep(draftAvailabilities)
      }
      if(this.draftAvailabilities.length == 0) this.isOverlapping = false
    },

    removeSingleDraftAvailability(id, parentId) {
      var draftAvailabily = this.draftAvailabilities.find(x => x.id == parentId)
      if(draftAvailabily != undefined) {
        var availabilities = this.availabilities.filter(x => !(x.id == id && x.parentId == parentId))
        this.availabilities = _.cloneDeep(availabilities)
        this.$parent.updateCalendar(this.availabilities)

        var index = this.draftAvailabilities.indexOf(draftAvailabily)
        availabilities = this.draftAvailabilities[index].availabilities.filter(x => !(x.id == id))
        if(availabilities.length > 0) {
          this.draftAvailabilities[index].availabilities = _.cloneDeep(availabilities)
        } else {
          this.draftAvailabilities = this.draftAvailabilities.splice(0, index).concat(this.draftAvailabilities.splice(index + 1, this.draftAvailabilities.length - 1))
        }
      }
    },

    async saveDraftAvailability() {
      console.log(this.availability.StartTime)
      const param = {
        SpecialistID: this.availability.SpecialistID, // Integer
        LocationID: this.availability.LocationID, // Integer
        AvailabilityDates: this.availability.AvailabilityDates, // array
        StartTime: utilities.formatDate(this.availability.StartTime, "HH:mm:ss", "hh:mm A"),
        Duration: this.availability.Duration, // Integer
        Number: this.availability.Number, // Integer
        UsernameLog: this.availability.UsernameLog, // String,
        UserIDLog: this.availability.UserIDLog, // Integer,
        Options:{
          ByRequestOnly: this.availability.Options.ByRequestOnly, // Integer 0-False, 1-True,
          VideoLinkAvailable: this.availability.Options.VideoLinkAvailable, // Integer 0-False, 1-True,
          VideoOnly: this.availability.Options.VideoOnly, // Integer 0-False, 1-True,
          ShowWorkCover: this.availability.Options.ShowWorkCover, // Integer 0-False, 1-True,
          ShowSpecificClients: this.availability.ClientIDs.length > 0 ? this.availability.Options.ShowSpecificClients : 0, // Integer 0-False, 1-True,
        },
        ClientIDs: this.availability.ClientIDs // Integer Array, Optional
      }
      var callback = async () => {
        this.successMessage = "Routine Availability added successfully."
        utilities.showAlert(".add-routine-availability .alert-success")
        await this.$parent.reload()
        this.$store.dispatch('loading/setLoadComponent', false)
      }
       this.$store.dispatch('loading/setLoadComponent', true)
       await utilities.postAvailability(param, callback)
    },

    async approveAllAvailability() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const param = {
        UserIDLog: this.user.id,
        UsernameLog: this.user.name,
        SpecialistID: this.availability.SpecialistID
      }
      await utilities.bulkApprove(param)
      this.successMessage = "Routine Availability approved successfully."
      utilities.showAlert(".add-routine-availability .alert-success")
      await this.$parent.reload()
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    callSpecialistAvailabilityEmail(draftList) {
      //When specialist create an availability. send email to the specialist and Admin
      if (this.isFromSpecialistProps) {
        var temp = []
        if(this.draftAvailabilities.length > 0) {
          this.draftAvailabilities[0].availabilities.map((x)=> {
            temp.push(x.availabilityDate)
          })
        } else {
          draftList.map((x)=> {
            temp.push(x.availabilityDate)
          })
        }

        var params = {
          SpecialistID: this.$parent.specialist.id,
          SpecialistFullName: this.$parent.specialist.fullName,
          SpecialistEmailAddress: this.$parent.specialist.email,
        }
        if (temp.length == 1) {
          params.StartDate = temp[0]
        } else {
          params.StartDate = temp[0]
          params.EndDate = temp[temp.length - 1]
        }
        utilities.postSpecialistAvailabilityEmail(params)
      }
    },


    async approveAvailability(id, parentId) {
      this.$store.dispatch("loading/setLoadComponent", true)
      var draftAvailabily = this.draftAvailabilities.find(x => x.id == parentId)
      if(draftAvailabily != undefined) {
        var availability = this.availabilities.find(x => x.parentId == parentId && x.id == id)
        if(availability != undefined) {
          var index = this.availabilities.indexOf(availability)
          // Approved
          this.availabilities[index].availabilityStatus = 3
        }

        availability = draftAvailabily.availabilities.find(x => x.id == id)
        if(availability != undefined) {
          // Approved
          availability.availabilityStatus = 3
          await this.postAvailability(availability)

          var parentIndex = this.draftAvailabilities.indexOf(draftAvailabily)
          index = this.draftAvailabilities[parentIndex].availabilities.indexOf(availability)
          if(draftAvailabily.availabilities.length == 1) {
            this.draftAvailabilities = this.draftAvailabilities.slice(0, parentIndex).concat(this.draftAvailabilities.slice(parentIndex + 1, this.draftAvailabilities.length))
          } else {
            this.draftAvailabilities[parentIndex].availabilities = this.draftAvailabilities[parentIndex].availabilities.slice(0, index).concat(this.draftAvailabilities[parentIndex].availabilities.slice(index + 1, this.draftAvailabilities[parentIndex].availabilities.length))
          }
        }
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadData() {
      var specialists = []
      if (this.user.type != 5) {
        const arr = [utilities.getSpecialists()]
        const r = await Promise.all(arr)
        specialists = r[0]
      }

      if(this.specialistIdsProps != undefined && this.specialistIdsProps.length > 0) {
        this.specialists = specialists.filter(x => this.specialistIdsProps.includes(x.id))
      } else {
        this.specialists = specialists
      }
      this.originalSpecialists = utilities.getDropdownSpecialist(this.specialists)
      this.listSpecialists = _.cloneDeep(this.originalSpecialists)

      // var locations = await utilities.getLocations()
      // if(!this.isFromSpecialistProps) { // Admin
      //   this.locations = locations
      // } else {  // Specialist
      //   if(this.$parent.specialist != undefined && this.$parent.specialist.travels.length > 0) {
      //     var travels = this.$parent.specialist.travels.map((y) => y.locationId)
      //     var specialistLocations = this.$parent.specialist.locations.map(x => x.id)
      //     this.locations = locations.filter((x) => travels.includes(x.id) || specialistLocations.includes(x.id))
      //   }
      // }

      this.weekDays = utilities.getAutoCompleteTags(utilities.getWeekDays().slice(0, 6), "text")
      this.frequencies = utilities.getFrequencies()
      this.occurrences = utilities.getOccurrences()
      this.fillOrders = utilities.getFillOrders()
      this.availabilityStatuses = utilities.getAvailabilityStatuses().filter(x => x.id !== 3 && x.id !== 4 && x.id !== 5)

      this.disabledDates = {
        ranges: [
          {
            from: moment(utilities.START_DATE, "YYYY-MM-DD"),
            to: moment().subtract(1, 'd'),
          },
          {
            from: moment(),
          }
        ],
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    if(this.specialistIdProps != undefined && this.specialistIdProps != '') this.availability.SpecialistID = this.specialistIdProps
    if(this.availabilitiesProps != undefined) this.availabilities = this.availabilitiesProps
    this.availability.label = ""
    this.availability.availabilities = []
    // set it as a draft
    this.availability.availabilityStatus = 1
    await this.loadData()
    utilities.initTooltip()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {},
}
</script>
