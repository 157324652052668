<template>
  <div class="manage-availability-note-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Add Note</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="">
                <input type="text" v-model="availabilityNote.note" class="textbox" placeholder="Enter a note" />
              </div>
              <br />
              <div class="alert alert-success hidden-element">
                <a class="close" title="close">×</a>
                <b>Success:</b> {{ successMessage }}
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="save" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'ManageAvailabilityNoteModal',
    props: ["specialistIdProps"],
    data() {
      return {
        loadModal: false,
        successMessage: "",

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        availabilityNote: utilities.initAvailabilityNote(),
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      async save() {
        if(this.availabilityNote.note == "") {
          return false
        }

        if(this.availabilityNote.id != '') {
          this.editAvailabilityNote()
        } else {
          this.addAvailabilityNote()
        }
      },
      postAvailabilityNote() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          SpecialistAvailabilityNoteID: "",
          SpecialistID: this.specialistIdProps,
          Note: this.availabilityNote.note,
          AddedBy: this.user.id,
          DateAdded: moment().format("YYYY-MM-DD"),
        }

        var callback = (response) => {
          this.availabilityNote.id = response
          this.availabilityNote.addedByName = params.Username
          this.availabilityNote.dateAdded = params.DateAdded
        }

        return utilities.postAvailabilityNote(params, callback)
      },
      putAvailabilityNote() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          SpecialistAvailabilityNoteID: this.availabilityNote.id,
          SpecialistID: this.specialistIdProps,
          Note: this.availabilityNote.note,
        }
        var callback = (response) => {}

        return utilities.putAvailabilityNote(params, callback)
      },

      async addAvailabilityNote() {
        this.$store.dispatch("loading/setLoadComponent", true)

        var result = await this.postAvailabilityNote()
        if (result) {
          this.successMessage = "Note successfully added."
          utilities.showAlert(".manage-availability-note-modal .alert-success")
          setTimeout(() => {
            this.$parent.specialistAvailability.specialist.availabilityNote = _.cloneDeep(this.availabilityNote)
            this.handleModal()
          }, 2000)
        }

        this.$store.dispatch("loading/setLoadComponent", false)
      },
      async editAvailabilityNote() {
        this.$store.dispatch("loading/setLoadComponent", true)

        var result = await this.putAvailabilityNote()
        if (result) {
          this.successMessage = "Note updated successfully."
          utilities.showAlert(".manage-availability-note-modal .alert-success")
          setTimeout(() => {
            this.$parent.specialistAvailability.specialist.availabilityNote = _.cloneDeep(this.availabilityNote)
            this.handleModal()
          }, 2000)
        }

        this.$store.dispatch("loading/setLoadComponent", false)
      },
    },
    beforeMount() {},
    mounted() {},
  }
</script>
