/* eslint-disable */
<template>
  <div class="manage-specialist">
    <AdminNavigation ref="navigation" indexProps="3" />
    <div class="top-panel">
      <Header />
      <div v-if="specialist != undefined" class="header-title-section">
        <div class="header-title"><h1>{{ specialist.id != "" ? "Edit Specialist" : "Create New Specialist" }}</h1></div>
        <div class="header-sub-title">{{ specialist.id != "" ? "Enter specialist details and complete their profile to help promote them to the clients." : "Enter new specialist details and complete their profile to help promote them to the clients." }}</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistMenu indexProps="0" />
      <div class="content">
        <div v-if="specialist != undefined">
          <div class="box">
            <div class="box-heading d-flex align-items-center justify-content-between">
              <span>Specialist Details</span>
              <div class="d-flex">
                <img v-if="specialist.id != ''" class="is-icon"
                  src="@/assets/images/file_icon_circle.svg"
                  data-placement="right"
                  data-toggle="tooltip"
                  title="Download PDF"
                  @click="downloadPdf()"
                />
                <img v-if="user.type == 4 || user.type == 6" src="@/assets/images/finance_black_icon.svg" class="is-icon specialist-cercle ml-5" @click="goToRateCard(specialist.id)" alt="">
              </div>
            </div>
            <div class="box-body">
              <div v-if="specialist.id != ''" class="box-row">
                <div class="box-col box-col-left">Active</div>
                <div class="box-col box-col-right">
                  <toggle-button
                    :value="specialist.specialistStatus"
                    :sync="true"
                    @change="changeStatus"
                    :color="{ checked: '#56C568', unchecked: '#525252' }"
                    :width="40"
                  />
                </div>
              </div>

              <div class="box-row">
                <div class="box-col box-col-left">Name</div>
                <div class="box-col box-col-right">
                  <div
                    class="parent w-100 h-100"
                    @click="openManageSpecialistNameModal">
                    <span v-if="specialist.fullName != ''">{{
                      specialist.fullName
                    }}</span>
                    <input
                      v-else
                      type="text"
                      disabled
                      class="child w-100"
                      :class="hasError && !validateFullName ? 'is-invalid' : ''"
                      placeholder="Click here to add new name" />
                    <div
                      v-if="hasError && !validateFullName"
                      class="text-danger">
                      Full name is required.
                    </div>
                  </div>
                  <AdminManageSpecialistNameModal :key="modalKey" ref="manageSpecialistNameModal" />
                </div>
              </div>

              <div class="box-row">
                <div class="box-col box-col-left">Contact Details</div>
                <div class="box-col box-col-right">
                  <div class="parent w-100 h-100" @click="openManageSingleContactModal">
                    <span v-if="specialist.contactDetails != ''">
                      <span v-if="specialist.phone1 !== ''" class="mr-2"><b>P: </b>{{ specialist.phone1 }}</span>
                      <span v-if="specialist.mobile !== ''" class="mr-2"><b>M: </b>{{ specialist.mobile }}</span>
                      <span v-if="specialist.phone2 !== ''" class="mr-2"><b>W: </b>{{ specialist.phone2 }}</span>
                      <span v-if="specialist.email !== ''" class="mr-2"><b>E: </b>{{ specialist.email }}</span>
                      <span v-if="specialist.NotificationEmailAddress !== ''" class="mr-2"><b>N: </b>{{ specialist.NotificationEmailAddress }}</span>
                    </span>
                    <input v-else type="text" disabled class="child w-100" placeholder="Click here to add new contact" />
                    <div v-if="hasError && !validatePhone" class="text-danger">Mobile is required.</div>
                    <div v-if="hasError && !validateEmail" class="text-danger">Email is required.</div>
                  </div>
                  <AdminManageSingleContactModal :key="modalKey" ref="manageSingleContactModal" />
                </div>
              </div>

              <div class="box-row">
                <div class="box-col box-col-left">User login access</div>
                <div class="box-col box-col-right row-item">
                  <input type="checkbox" v-model="specialist.isUser" />
                  <v-btn v-if="specialist.isUser && oldSpecialist.isUser && specialist.id != ''" class="ml-10 col-center-content" style="background-color:#C4C4C4;color:#6686B6F" @click="reSendInvitation()"> Resend Invite</v-btn>
                </div>
              </div>

              <div class="box-row">
                <div class="box-col box-col-left">
                  Appointment By Request Only
                </div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.byRequestOnly" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  Vendor generated invoices
                </div>
                <div class="box-col box-col-right">
                  <input
                    type="checkbox"
                    v-model="specialist.vendorGeneratedInvoices"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Xero Code</div>
                <div class="box-col box-col-right">
                  <input
                    type="text"
                    :disabled="user.type == 3 ? true : false"
                    v-model="specialist.xeroCode"
                    placeholder="Xero code"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Client Alignment</div>
                <div class="box-col box-col-right select-client-alignment">
                  <div
                    class="parent"
                    v-if="!clickClientAlignment"
                    @click="handleClientAlignment">
                    <input
                      class="child"
                      type="text"
                      disabled
                      v-model="specialist.clientAlignment"
                      placeholder="Click to choose the Client alignment"
                    />
                  </div>
                  <select v-else v-model="specialist.clientAlignment">
                    <option hidden value="">Please choose ...</option>
                    <option
                      v-for="(clientAlignment, index) in clientAlignments"
                      :value="clientAlignment.value"
                    >
                      {{ clientAlignment.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Practice Location</div>
                <div class="box-col box-col-right d-flex flex-column justify-content-center">
                  <div class="d-flex justify-content-between align-items-center">
                    <el-select :popper-append-to-body="false" placeholder="Please select a primary practice location" filterable v-model="specialist.practiceLocationId" class="w-50" :class="hasError && !validatePracticeLocation ? 'is-invalid' : ''" @change="changePracticeLocation">
                      <el-option v-for="location in locations" :key="location.id" :value="location.id" :label="location.fullLocation">{{ location.fullLocation }}</el-option>
                    </el-select>
                    <router-link :to="{ name: 'admin-manage-location' }"><v-btn class="add-icon" dark>Add New</v-btn></router-link>
                  </div>
                  <div v-if="hasError && !validatePracticeLocation" class="text-danger">Practice Location is required.</div>
                </div>
              </div>
              <!-- <div class="box-row border-top-0" v-if="Number(specialist.practiceLocationId) > 0">
                <div class="box-col box-col-left"></div>
                <div class="box-col box-col-right">
                  <div v-if="specialist.locations[1] != undefined && specialist.locations[1].fullLocation != ''" class="w-100 is-icon" v-for="(location, index) in specialist.locations" @click="openManageLocationModal(index)">
                    <span :class="index == 0 ? 'hidden-element' : ''">{{ location.fullLocation }}</span>
                  </div>
                  <div v-else @click="openManageLocationModal(0)">
                    <input type="text" disabled class="w-100" placeholder="Click here to add or edit location" />
                  </div>
                </div>
                <AdminManageLocationModal ref="manageLocationModal" />
              </div> -->
            </div>
          </div>

          <!-- Add new practice location -->
          <div class="practice-location pb-10" v-if="specialist.practiceLocationId == 'new'">
            <div class="box">
              <div class="box-heading"><span>Add new practice location</span></div>
              <div class="box-body">
                <div class="box-row">
                  <div class="box-col box-col-left">Location Name</div>
                  <div class="box-col box-col-right">
                    <input type="text" class="pl-1" :class="hasError && !validateLocationName ? 'is-invalid' : ''" v-model="location.name" placeholder="Location name" />
                    <div v-if="hasError && !validateLocationName" class="text-danger">Location name is required</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Phone Number</div>
                  <div class="box-col box-col-right">
                    <input type="text" class="pl-1" v-model="location.phoneNumber" placeholder="Phone Number" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Address Line 1</div>
                  <div class="box-col box-col-right">
                    <input type="text" class="pl-1" v-model="location.address1" placeholder="Address Line 1" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Address Line 2</div>
                  <div class="box-col box-col-right">
                    <input type="text" class="pl-1" v-model="location.address2" placeholder="Address Line 2" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">City</div>
                  <div class="box-col box-col-right">
                    <select v-model="location.cityId" class="textbox w-100" :class="hasError && !validateCity ? 'is-invalid' : ''" @change="changeCity()">
                      <option value="" hidden>Please select ...</option>
                      <option value="new">Add New</option>
                      <option v-for="city in cities" :key="city.id" :value="city.id">{{ setCityName(city) }}</option>
                    </select>
                    <div v-if="hasError && !validateCity" class="text-danger">City is required</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">State</div>
                  <div class="box-col box-col-right">
                    <select v-model="location.state" class="textbox w-100">
                      <option value="" hidden>Please select ...</option>
                      <option :value="state.value" v-for="state in states">{{ state.text }}</option>
                    </select>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Postcode</div>
                  <div class="box-col box-col-right">
                    <input type="text" class="pl-1" placeholder="Enter postcode" v-model="location.postcode" :class="hasError && !validatePostcode ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validatePostcode" class="text-danger">Please enter a valid postcode.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Country</div>
                  <div class="box-col box-col-right">
                    <span class="pl-1">Australia</span>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left">Geolocation</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="location.latitude" placeholder="Latitude" :class="hasError && !validateLatitude ? 'is-invalid' : ''" />
                    <input type="text" v-model="location.longitude" placeholder="Longitude" :class="hasError && !validateLongitude ? 'is-invalid' : ''" />
                    <div v-if="hasError && (!validateLatitude || !validateLongitude)" class="text-danger">Please enter valid coordinates.</div>
                  </div>
                </div>
              </div>
              <AddCityModal ref="addCityModal" :regions="regions" />
            </div>
<!--
            <div class="alert alert-success hidden-element">
              <a class="close" title="close">×</a>
              <b>Success:</b> New practice location created successfully.
            </div>
            <div class="alert alert-danger hidden-element">
              <a class="close" title="close">×</a>
              <b>Error:</b> {{ errorMessage }}
            </div> -->

            <!-- <div class="right-element">
              <v-btn dark class="mr-5" @click="savePracticeLocation" >Save</v-btn>
              <v-btn dark @click="cancelPracticeLocation">Cancel</v-btn>
            </div> -->
          </div>

          <!-- <div v-if="specialist.id != '' && (user.type == 4 || user.type == 6)" class="icon-group">
            <div class="w-100">
              <router-link
                :to="{
                  name: 'admin-manage-rate-card',
                  query: { id: this.specialist.id },
                }"
              >
                <v-btn class="right-float-element" dark>Manage Rate Card</v-btn>
              </router-link>
            </div>
          </div> -->

          <div class="alert alert-success alert-success-resend hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>

          <br />
          <div class="box">
            <div class="box-heading">
              <span>Travels To</span>
              <div
                class="is-icon right-element"
                @click="openManageTravelModal(0)"
              >
                <img
                  v-if="specialist.travels.length > 1"
                  src="@/assets/images/locations_black_icon.svg"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="View Travels to"
                />
              </div>
              <AdminManageTravelModal
                :key="modalKey"
                ref="manageTravelModal"
                :specialistProps="specialist"
              />
            </div>
            <div :key="travelToKey" class="box-body travel-to" :class="specialist.travels.length > 2 ? 'content-scroll-y' : ''">
              <div v-for="(travel, index) in specialist.travels">
                <div class="box-row">
                  <div class="box-col box-col-left">
                    {{ index == 0 ? "Location" : "" }}
                  </div>
                  <div class="box-col box-col-right">
                    <div
                      class="parent w-100 h-100"
                      @click="openManageTravelModal(index)">
                      <span v-if="travel.locationName != ''">{{
                        travel.locationName
                      }}</span>
                      <input
                        v-else
                        type="text"
                        disabled
                        class="child w-100"
                        placeholder="Click here to add new travel to"
                      />
                      <span
                        v-if="travel.id != '' || travel.fullTravel != ''"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="!travel.requiresTravel ? 'Travel & Accommodation Not Required' : 'Travel & Accommodation required'"
                        ><img
                          class="img-small"
                          style="margin-left: 10px"
                          :style="!travel.requiresTravel ? 'opacity: 0.5' : ''"
                          src="@/assets/images/travel_black_circle_icon.svg"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="box">
            <div class="box-heading"><span>Qualifications</span></div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Speciality:</div>
                <div class="box-col box-col-right">
                  <vue-tags-input
                    ref="qualificationTag"
                    v-model="qualificationTag"
                    :tags="qualificationTags"
                    :add-only-from-autocomplete="true"
                    :autocomplete-items="filteredQualifications"
                    placeholder="Add Qualifications"
                    @tags-changed="updateQualificationTags"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">
                  Expert in (Conditions / Assessment Areas):
                </div>
                <div class="box-col box-col-right">
                  <vue-tags-input
                    ref="personConditionTag"
                    v-model="personConditionTag"
                    :tags="personConditionTags"
                    :add-only-from-autocomplete="true"
                    :autocomplete-items="filteredPersonConditions"
                    placeholder="Add Conditions"
                    @tags-changed="updatePersonConditionTags"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Special Interests:</div>
                <div class="box-col box-col-right">
                  <vue-tags-input
                    ref="interestTag"
                    v-model="interestTag"
                    :tags="interestTags"
                    :autocomplete-items="filteredInterests"
                    placeholder="Add Interests"
                    :add-only-from-autocomplete="true"
                    @tags-changed="updateInterestTags"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="box">
            <div class="box-heading"><span>Education</span></div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">
                  Accreditations / Certifications:
                </div>
                <div class="box-col box-col-right">
                  <vue-tags-input
                    ref="certificationTag"
                    v-model="certificationTag"
                    :tags="certificationTags"
                    :autocomplete-items="filterCertifications"
                    placeholder="Add Certifications"
                    :add-only-from-autocomplete="true"
                    @tags-changed="updateCertificationTags"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Qualifications</div>
                <div class="box-col box-col-right">
                  <div class="parent w-100 h-100" @click="openManageEducationModal(0)">
                    <span v-if="specialist.educationList != ''">
                      {{ specialist.educationList }}
                      <div class="is-icon right-float-element">
                        <img
                          src="@/assets/images/education_black_icon.svg"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="View Educations"
                        />
                      </div>
                    </span>
                    <input
                      v-else
                      type="text"
                      disabled
                      class="child w-100"
                      placeholder="Click here to add new education"
                    />
                  </div>
                  <AdminManageEducationModal
                    :key="modalKey"
                    ref="manageEducationModal"
                    :specialistProps="specialist"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="box">
            <div class="box-heading"><span>Services Peformed</span></div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Independent Medical Examination</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceIME" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Independent Medical Examination Abuse</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceIMEAbuse" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Independent Medical Examination by Video</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceIMEVideo" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">File Review</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceFileReview" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Supplementary</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceSupplementary" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Phone Call</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.servicePhoneCall" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Court Case</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceCourtCase" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Supplementary by Video</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceSupplementaryByVideo" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Medical Negligence Request</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.serviceMedicalNegligenceRequest" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">FCE Lite</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.fceLite" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">FCE Lite by Video</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" v-model="specialist.fceLiteByVideo" />
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="box">
            <div class="box-heading">
              <div>Notification Settings</div>
            </div>
            <div class="box-body">
<!--              <div class="box-row">-->
<!--                <div class="box-col box-col-left">New Booking Notification</div>-->
<!--                <div style="width:80%" class="box-col">-->
<!--                  <toggle-button @change="onSettingsToggleChange(1)" :color="{ checked: '#56C568', unchecked: '#525252' }" :width="40" :sync="true" :value="setting.newBookingNotification" />-->
<!--                  <span class="ml-5">Receive Notification of each booking made</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="box-row">-->
<!--                <div class="box-col box-col-left">Availability Reminder</div>-->
<!--                <div style="width:80%" class="box-col">-->
<!--                  <toggle-button @change="onSettingsToggleChange(3)" :color="{ checked: '#56C568', unchecked: '#525252' }" :width="40" :sync="true" :value="setting.availabilityReminder" />-->
<!--                  <span class="ml-5">Reminder sent when we have no more availability in our system</span>-->
<!--                </div>-->
<!--              </div>-->
              <div class="box-row">
                <div class="box-col box-col-left">Weekly Schedule</div>
                <div style="width:80%" class="box-col">
                  <toggle-button @change="onSettingsToggleChange(5)" :color="{ checked: '#56C568', unchecked: '#525252' }" :width="40" :sync="true" :value="setting.weeklySchedule" />
                  <span class="ml-5">Your appointment schedule sent to you one week prior</span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="box">
            <div class="box-heading"><span>Consent Form</span></div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Consent Form 1</div>
                <div class="box-col box-col-right">
                  <input
                    type="text"
                    v-model="specialist.consentForm1"
                    placeholder="Consent Form 1"
                    maxlength="255"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Consent Form 2</div>
                <div class="box-col box-col-right">
                  <input
                    type="text"
                    v-model="specialist.consentForm2"
                    placeholder="Consent Form 2"
                    maxlength="255"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Consent Form 3</div>
                <div class="box-col box-col-right">
                  <input
                    type="text"
                    v-model="specialist.consentForm3"
                    placeholder="Consent Form 3"
                    maxlength="255"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="box">
            <div class="box-heading"><span>Profile</span></div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Photo</div>
                <div class="box-col box-col-right">
                  <div class="box-col-photo">
                    <div class="rounded-image profile-image">
                      <div class="profile-image-content" :class="specialist.profileThumbnail != '' ? '' : 'notice-background'">
                        <img :src="specialist.profileThumbnail" />
                      </div>
                      <!-- <div class="profile-image-name long-text"></div> -->
                      <div
                        class="edit-icon"
                        data-placement="right"
                        data-toggle="tooltip"
                        title="Change Photo"
                        @click="chooseProfileImage"></div>
                      <input
                        type="file"
                        name="profile-image"
                        accept="image/png, image/jpeg"
                        @change="changeProfileImage" />
                    </div>
                    <!-- <div class="rounded-image profile-image">
                      <div class="profile-image-content">
                        <img id="input" src="" />
                      </div>
                    </div>
                    <div class="rounded-image profile-image">
                      <div class="profile-image-content">
                        <img id="output" src="" />
                      </div>
                    </div> -->
                  </div>
                  <input
                    type="text"
                    v-model="specialist.photoURL"
                    placeholder="Please paste the AWS URL for a submitted profile image here"
                    @change="changePhotoURL"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Web Profile</div>
                <div class="box-col box-col-right">
                  <input
                    type="text"
                    v-model="specialist.profileURL"
                    placeholder="e.g. LinkedIn URL or Website Team Profile"
                  />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Profile</div>
                <div class="box-col box-col-right">
                  <vue-editor
                    v-model="specialist.specialistProfile"
                    :editor-toolbar="customToolbar"
                  />
                  <!-- <textarea rows="3" maxlength="1000" v-model="specialist.specialistProfile" /> -->
                </div>
              </div>
            </div>
          </div>

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="right-element submit-specialist">
            <v-btn dark @click="submit">
              {{ specialist.id == "" ? "Create" : "Update" }}
            </v-btn>
          </div>
        </div>
        <div v-else>This specialist not found.</div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminSpecialistMenu from "@/components/AdminSpecialistMenu"
import Header from "@/components/Header"
import AdminManageSpecialistNameModal from "@/components/AdminManageSpecialistNameModal"
import AdminManageSingleContactModal from "@/components/AdminManageSingleContactModal"
// import AdminManageLocationModal from "@/components/AdminManageLocationModal"
import AdminManageTravelModal from "@/components/AdminManageTravelModal"
import AdminManageEducationModal from "@/components/AdminManageEducationModal"
import AddCityModal from '@/components/AddCityModal'

export default {
  name: "AdminManageSpecialist",
  components: {
    AdminNavigation,
    AdminSpecialistMenu,
    Header,
    AdminManageSpecialistNameModal,
    AdminManageSingleContactModal,
    // AdminManageLocationModal,
    AdminManageTravelModal,
    AdminManageEducationModal,
    AddCityModal,
  },
  data() {
    return {
      hasError: false,
      successMessage: "",
      errorMessage: "Please correct the errors shown above and try again.",
      loaded: false,

      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      specialist: utilities.initSpecialist(),
      oldSpecialist: [],
      location: utilities.initLocation(),
      newLocation: utilities.initLocation(),
      setting: utilities.initSeeting(),

      clickClientAlignment: false,
      settingsChanged: false,

      qualificationTag: "",
      qualificationTags: [],
      qualifications: [],

      certificationTag: "",
      certificationTags: [],
      certifications: [],

      personConditionTag: "",
      personConditionTags: [],
      personConditions: [],

      interestTag: "",
      interestTags: [],
      interests: [],

      titles: [],
      clientAlignments: [],
      appointmentLengths: [],
      shortAppointmentLengths: [],
      cities: [],
      regions: [],
      states: [],

      locations: [],

      modalKey: 0,
      travelToKey: 0,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    }
  },
  watch: {
    specialist: {
      handler(value) {
        if (this.loaded) this.$store.dispatch("setNewObject", value)
      },
      deep: true,
    },
  },
  computed: {
    validateFullName() {
      if (this.specialist.fullName == '') {
        return false
      }
      return true
    },
    validateEmail() {
      if(this.specialist.isUser && this.specialist.email == '') {
        return false
      }
      return true
    },
    validatePhone() {
      if(this.specialist.isUser && this.specialist.mobile == '') {
        return false
      }
      return true
    },
    validatePracticeLocation() {
      if(this.specialist.practiceLocationId == '' || typeof this.specialist.practiceLocationId == 'string') {
        return false
      }
      return true
    },
    validateLocationName() {
      if(this.location.name == '') {
        return false
      }
      return true
    },
    validateCity() {
      if(this.location.cityId == '') {
        return false
      }
      return true
    },
    validatePostcode() {
      var regExp = new RegExp('^[0-9]{4}$')
      if(this.location.postcode !== '' && !regExp.test(this.location.postcode)) {
        return false
      }
      return true
    },
    validateLatitude() {
      var regExp = new RegExp('^-?[0-9]+\.[0-9]+$')
      if (this.location.latitude !== '' && !regExp.test(this.location.latitude)) {
        return false
      }
      return true
    },
    validateLongitude() {
      var regExp = new RegExp('^-?[0-9]+\.[0-9]+$')
      if (this.location.longitude !== '' && !regExp.test(this.location.longitude)) {
        return false
      }
      return true
    },
    filteredQualifications() {
      return this.qualifications.filter((i) => {
        return i.text.toLowerCase().indexOf(this.qualificationTag.toLowerCase()) !== -1
      })
    },
    filteredPersonConditions() {
      return this.personConditions.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
        )
      })
    },
    filteredInterests() {
      return this.personConditions.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.interestTag.toLowerCase()) !== -1
        )
      })
    },
    filterCertifications() {
      return this.certifications.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.certificationTag.toLowerCase()) !== -1
        )
      })
    },
  },
  methods: {
    updatePersonConditionTags(newTags) {
      // if(this.$refs.personConditionTag.newTag.length == 0) newTags.pop()
      this.personConditionTags = newTags
    },
    updateQualificationTags(newTags) {
      // Commit id 17666983 - When I type in a random item in a listbox, I can hit enter and that is being added i.e. not a real entry. Please prevent that from happening
      // Set "add-only-from-autocomplete = true" to prevent hitting enter free text so don't need the code below
      // if(this.$refs.qualificationTag.newTag.length == 0) newTags.pop()
      this.qualificationTags = newTags
    },
    updateInterestTags(newTags) {
      // if(this.$refs.interestTag.newTag.length == 0) newTags.pop()
      this.interestTags = newTags
    },
    updateCertificationTags(newTags) {
      // if(this.$refs.certificationTag.newTag.length == 0) newTags.pop()
      this.certificationTags = newTags
    },

    changeStatus() {
      this.specialist.specialistStatus = !this.specialist.specialistStatus
    },
    changeAppointment() {
      this.specialist.byRequestOnly = !this.specialist.byRequestOnly
    },
    changeVendor() {
      this.specialist.vendorGeneratedInvoices = !this.specialist
        .vendorGeneratedInvoices
    },

    async reSendInvitation() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var mobile = utilities.updateMobileCode(utilities.validatePhonenumber(this.specialist.mobile))
      if(mobile.charAt(0) == '0') {
        mobile = mobile.substring(1)
      }
      var params = {
        Name: this.specialist.userName,
        EmailAddress: this.specialist.email,
        Mobile: mobile,
        UserRoleID: 5,
      }
      var result = await utilities.postResendInvite(params)
      if (result) {
        this.successMessage = result
        utilities.showAlert(".alert-success-resend")
      }
      this.$store.dispatch('loading/setLoadComponent', false)

    },

    async downloadPdf() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var pdf = await utilities.getSpecialistProfilesByIds(this.specialist.id)
      var specialistName = this.specialist.firstName.replace(/ /g, "") + this.specialist.lastName.replace(/ /g, "")
      var fileName = 'SpecialistProfile' + specialistName + ".pdf"
      var a = document.createElement('a')
      let blob = pdf
      a.setAttribute('href', window.URL.createObjectURL(blob))
      a.setAttribute('download', fileName)
      a.click()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    chooseProfileImage() {
      $("input[type='file'][name='profile-image']").click()
    },
    setCityName(city) {
      var name = city.name
      if (city.region) {
        name = name + ', ' + city.region.name
      }
      return name
    },
    async changeProfileImage(e) {
      if (!e.target.files.length) {
        return false
      }

      this.specialist.photoURL = ""
      var object = await utilities.convertFileToBase64(e.target.files[0])
      this.specialist.photo = object
      this.specialist.profileThumbnail = object.file.base64
    },
    changePhotoURL() {
      this.specialist.photo = ""
    },
    handleClientAlignment() {
      this.clickClientAlignment = true
    },
    openManageSpecialistNameModal() {
      this.$refs.manageSpecialistNameModal.specialist = this.specialist
      this.$refs.manageSpecialistNameModal.handleModal()
    },
    openManageSingleContactModal() {
      if (!this.specialist.isUser && this.oldSpecialist.isUser) {
        utilities.showWarningMessage("Please update your changes first.")
        return;
      }

      this.$refs.manageSingleContactModal.specialist = _.cloneDeep(this.specialist)
      this.$refs.manageSingleContactModal.handleModal()
    },
    async openManageLocationModal(index) {
      this.$store.dispatch("loading/setLoadComponent", true)
      this.$refs.manageLocationModal.specialist = _.cloneDeep(this.specialist)
      await this.$refs.manageLocationModal.loadLocations()
      this.$refs.manageLocationModal.handleModal()
      this.$refs.manageLocationModal.openLocation(index)
      this.$refs.manageLocationModal.opened()
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async openManageTravelModal(index) {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.$refs.manageTravelModal.loadTravels()
      await this.$refs.manageTravelModal.loadData()
      this.$refs.manageTravelModal.handleModal()
      this.$refs.manageTravelModal.openTravel(index)
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    openManageEducationModal(index) {
      this.$store.dispatch("loading/setLoadComponent", true)
      this.$refs.manageEducationModal.handleModal()
      this.$refs.manageEducationModal.specialist = this.specialist
      this.$refs.manageEducationModal.openEducation(index)
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    changePracticeLocation() {
      if (this.specialist.practiceLocationId == "new") {
        this.location = utilities.initLocation()
        if(this.specialist.id != '') this.location.specialistId = this.specialist.id
        setTimeout(() => {
          $("html").animate({ scrollTop: $(".practice-location").offset().top - 100 }, 'slow')
        }, 200)
      }
    },
    goToRateCard(specialistId) {
      this.$router.push({ name:'admin-manage-rate-card', query: { id: specialistId } })
    },
    changeCity() {
      if (this.location.cityId == 'new') {
        //show popup
        this.$refs.addCityModal.handleModal()
      } else {
        var city = this.cities.find((x) => { return x.id == this.location.cityId })
        if(city != undefined) {
          this.location.cityName = city.name
          this.location.regionId = city.regionId
        }
      }
    },
    onSettingsToggleChange(index) {
      this.settingsChanged = true
      switch (index) {
        case 1:
          this.setting.newBookingNotification = !this.setting.newBookingNotification
          break
        case 2:
          this.setting.bookingChange = !this.setting.bookingChange
          break
        case 3:
          this.setting.availabilityReminder = !this.setting.availabilityReminder
          break
        case 4:
          this.setting.availabilityReturned = !this.setting.availabilityReturned
          break
        case 5:
          this.setting.weeklySchedule = !this.setting.weeklySchedule
          break
        case 6:
          this.setting.scheduleChange = !this.setting.scheduleChange
          break
        case 7:
          this.setting.reportsDue = !this.setting.reportsDue
          break
        case 8:
          this.setting.reportsOverdue = !this.setting.reportsOverdue
          break
        case 9:
          this.setting.bookingReminder = !this.setting.bookingReminder
          break
        default:
      }
    },
    async savePracticeLocation() {
      if (!this.validateLocationName || !this.validateCity || !this.validatePostcode || !this.validateLatitude || !this.validateLongitude) {
        this.hasError = true
        utilities.showAlert(".alert-danger")
        return
      } else {
        this.hasError = false
      }

      this.$store.dispatch("loading/setLoadComponent", true)
      var result = await this.postLocation()
      if (result) {
        this.specialist.practiceLocationId = result
        //utilities.showAlert('.practice-location .alert-success')
        this.locations = await utilities.getLocations()
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    cancelPracticeLocation() {
      this.specialist.practiceLocationId = ""
    },
    async submit(e) {
      e.preventDefault()
      e.stopPropagation()

      if (this.specialist.practiceLocationId == 'new') {
        await this.savePracticeLocation()
      }
      if (!this.validateFullName || !this.validateEmail || !this.validatePhone || !this.validatePracticeLocation) {
        this.hasError = true
        utilities.showAlert(".alert-danger")
        return false
      } else {
        this.hasError = false
      }

      var temp = null
      var qualification = {}
      var certification = {}
      var personCondition = {}
      var interest = {}

      // Qualifications

      if (
        this.specialist.qualifications[0].id == "" &&
        this.specialist.qualifications[0].qualificationId == ""
      ) {
        this.qualificationTags.map((x) => {
          qualification = {
            id: x.id,
            qualificationId: x.value,
            qualificationName: x.text,
          }
          this.specialist.qualifications.push(qualification)
        })
      } else {
        this.specialist.qualifications.map((x) => {
          temp = this.qualificationTags.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            x.qualificationId = ""
          }
        })

        var qualifications = _.cloneDeep(this.specialist.qualifications)
        this.qualificationTags.map((x) => {
          temp = qualifications.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            qualification = {
              id: x.id,
              qualificationId: x.value,
              qualificationName: x.text,
            }
            this.specialist.qualifications.push(qualification)
          }
        })
      }

      // Certifications
      if (
        this.specialist.certifications[0].id == "" &&
        this.specialist.certifications[0].certificationId == ""
      ) {
        this.certificationTags.map((x) => {
          certification = {
            id: x.id,
            certificationId: x.value,
            certificationName: x.text,
          }
          this.specialist.certifications.push(certification)
        })
      } else {
        this.specialist.certifications.map((x) => {
          temp = this.certificationTags.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            x.certificationId = ""
          }
        })

        var certifications = _.cloneDeep(this.specialist.certifications)
        this.certificationTags.map((x) => {
          temp = certifications.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            certification = {
              id: x.id,
              certificationId: x.value,
              certificationName: x.text,
            }
            this.specialist.certifications.push(certification)
          }
        })
      }

      // PersonConditions
      if (
        this.specialist.personConditions[0].id == "" &&
        this.specialist.personConditions[0].personConditionId == ""
      ) {
        this.personConditionTags.map((x) => {
          personCondition = {
            id: x.id,
            personConditionId: x.value,
            personConditionName: x.text,
          }
          this.specialist.personConditions.push(personCondition)
        })
      } else {
        this.specialist.personConditions.map((x) => {
          temp = this.personConditionTags.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            x.personConditionId = ""
          }
        })

        var personConditions = _.cloneDeep(this.specialist.personConditions)
        this.personConditionTags.map((x) => {
          temp = personConditions.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            personCondition = {
              id: x.id,
              personConditionId: x.value,
              personConditionName: x.text,
            }
            this.specialist.personConditions.push(personCondition)
          }
        })
      }

      // Interests
      if (
        this.specialist.interests[0].id == "" &&
        this.specialist.interests[0].personConditionId == ""
      ) {
        this.interestTags.map((x) => {
          interest = {
            id: x.id,
            personConditionId: x.value,
            personConditionName: x.text,
          }
          this.specialist.interests.push(interest)
        })
      } else {
        this.specialist.interests.map((x) => {
          temp = this.interestTags.filter((y) => y.id == x.id)[0]
          if (temp == undefined) {
            x.personConditionId = ""
          }
        })

        var interests = _.cloneDeep(this.specialist.interests)
        this.interestTags.map((x) => {
          temp = interests.find((y) => y.id == x.id)
          if (temp == undefined) {
            interest = {
              id: x.id,
              personConditionId: x.value,
              personConditionName: x.text,
            }
            this.specialist.interests.push(interest)
          }
        })
      }

      this.specialist.specialistProfile = this.specialist.specialistProfile.replace(/(<a href=\"[a-zA-Z0-9\:\/\/\.-]*\">)/g, "")
      this.specialist.specialistProfile = this.specialist.specialistProfile.replace(/(<\/a>)/g, "")

      if((this.specialist.isUser && (this.oldSpecialist.email != this.specialist.email)) || (this.specialist.isUser && this.specialist.id == '')) {
        var check = await utilities.checkDuplicate(this.specialist.email, '1')
        if (check == 1) {
          this.errorMessage = "Email Address Already Exists!"
          utilities.showAlert(".alert-danger")
          return
        }
      }

      if (this.specialist.id == "") {
        this.addSpecialist()
      } else {
        this.editSpecialist()
      }
    },
    async postLocation() {
      var params = {
        LocationName: this.location.name,
        AddressLine1: this.location.address1,
        AddressLine2: this.location.address2,
        CityID: this.location.cityId,
        State: this.location.state,
        Postcode: this.location.postcode,
        PhoneNumber: this.location.phoneNumber,
        UserID: this.user.id,
        Username: this.user.name,
      }

      if (this.location.latitude == '' || this.location.longitude == '') {
        var coordinates = await utilities.getGeoLocation(this.location.name + ', ' + this.location.address1 + ',' + this.location.address2 + ',' + this.location.cityName + ',' + this.location.state)
        params.Latitude = coordinates.lat
        params.Longitude = coordinates.lng
        this.location.latitude = coordinates.lat
        this.location.longitude = coordinates.lng
      } else {
        params.Latitude = this.location.latitude
        params.Longitude = this.location.longitude
      }
      var callback = (response) => {
        setTimeout(() => {
          this.specialist.practiceLocationId = response
          this.location.id = response
          this.newLocation = _.cloneDeep(this.location)
        }, 1000)
      }
      if(this.location.specialistId != '') params.SpecialistID = this.location.specialistId
      return utilities.postLocation(params, callback)
    },
    async putLocation() {
      var params = {
        LocationID: this.newLocation.id,
        LocationName: this.newLocation.name,
        CityID: this.location.cityId,
        SpecialistID: this.specialist.id,
        UserID: this.user.id,
        Username: this.user.name,
      }
      var callback = (response) => {}
      return utilities.putLocation(params, callback)
    },
    async postSpecialist() {
      var locations = []
      var location = {}
      this.specialist.locations.map((x) => {
        if (x.cityId != "") {
          location = {
            LocationID: "",
            LocationName: x.name,
            AddressLine1: x.address1,
            AddressLine2: x.address2,
            CityID: x.cityId,
            State: x.state,
            Postcode: x.postcode,
            Country: x.country,
          }
          locations.push(location)
        }
      })

      var travels = []
      var travel = {}
      this.specialist.travels.map((x) => {
        if (x.locationId != "") {
          travel = {
            SpecialistTravelsToID: "",
            LocationID: x.locationId,
            RequiresTravel: utilities.convertBoolToNum(x.requiresTravel),
          }
          travels.push(travel)
        }
      })

      var qualifications = []
      var qualificationNames = []
      var qualification = {}
      this.specialist.qualifications.map((x) => {
        if (x.id != "" || x.qualificationId != "") {
          qualification = {
            SpecialistQualificationID: "",
            QualificationID: x.qualificationId,
            QualificationName: x.qualificationName,
          }
          qualifications.push(qualification)
          if (x.qualificationId) {
              qualificationNames.push(x.qualificationName)
          }
        }
      })

      var certifications = []
      var certification = {}
      this.specialist.certifications.map((x) => {
        if (x.id != "" || x.certificationId != "") {
          certification = {
            SpecialistCertificationID: "",
            CertificationID: x.certificationId,
            CertificationName: x.certificationName,
          }
          certifications.push(certification)
        }
      })

      var personConditions = []
      var personCondition = {}
      this.specialist.personConditions.map((x) => {
        if (x.id != "" || x.personConditionId != "") {
          personCondition = {
            SpecialistPersonConditionID: "",
            PersonConditionID: x.personConditionId,
            PersonConditionName: x.personConditionName,
          }
          personConditions.push(personCondition)
        }
      })

      var interests = []
      var interest = {}
      this.specialist.interests.map((x) => {
        if (x.id != "" || x.personConditionId != "") {
          interest = {
            SpecialistInterestID: x.id,
            PersonConditionID: x.personConditionId,
            PersonConditionName: x.personConditionName,
          }
          interests.push(interest)
        }
      })

      var educations = []
      var education = {}
      this.specialist.educations.map((x) => {
        if (x.education != "" && x.yearObtained != "") {
          education = {
            SpecialistEducationID: "",
            Education: x.education,
            YearObtained: x.yearObtained,
          }
          educations.push(education)
        }
      })

      console.log(qualifications)
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        Title: this.specialist.title,
        FirstName: this.specialist.firstName,
        Surname: this.specialist.lastName,
        PhoneNumber1: utilities.validatePhonenumber(this.specialist.phone1),
        PhoneNumber2: utilities.validatePhonenumber(this.specialist.phone2),
        Mobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.specialist.mobile)),
        EmailAddress: this.specialist.email,
        NotificationEmailAddress: this.specialist.NotificationEmailAddress,
        ClientAlignment: this.specialist.clientAlignment,
        IsUser: utilities.convertBoolToNum(this.specialist.isUser),
        ByRequestOnly: utilities.convertBoolToNum(
          this.specialist.byRequestOnly
        ),
        VendorGeneratedInvoices: utilities.convertBoolToNum(
          this.specialist.vendorGeneratedInvoices
        ),
        ServiceIME: utilities.convertBoolToNum(this.specialist.serviceIME),
        ServiceIMEAbuse: utilities.convertBoolToNum(this.specialist.serviceIMEAbuse),
        ServiceIMEVideo: utilities.convertBoolToNum(this.specialist.serviceIMEVideo),
        ServiceSupplementary: utilities.convertBoolToNum(this.specialist.serviceSupplementary),
        ServiceFileReview: utilities.convertBoolToNum(this.specialist.serviceFileReview),
        ServicePhoneCall: utilities.convertBoolToNum(this.specialist.servicePhoneCall),
        ServiceMedicalNegligenceRequest: utilities.convertBoolToNum(this.specialist.serviceMedicalNegligenceRequest),
        ServiceSupplementaryByVideo: utilities.convertBoolToNum(this.specialist.serviceSupplementaryByVideo),
        ServiceCourtCase: utilities.convertBoolToNum(this.specialist.serviceCourtCase),
        FCELite: utilities.convertBoolToNum(this.specialist.fceLite),
        ECELiteByVideo: utilities.convertBoolToNum(this.specialist.fceLiteByVideo),
        ConsentForm1: this.specialist.consentForm1,
        ConsentForm2: this.specialist.consentForm2,
        ConsentForm3: this.specialist.consentForm3,
        ProfileURL: this.specialist.profileURL,
        SpecialistProfile: this.specialist.specialistProfile,
        SpecialistStatus: utilities.convertBoolToNum(
          this.specialist.specialistStatus
        ),
        XeroCode: this.specialist.xeroCode,
        PracticeLocationID: this.specialist.practiceLocationId,
        LocationObject: locations,
        TravelsToObject: travels,
        QualificationObject: qualifications,
        QualificationNames: qualificationNames,
        CertificationObject: certifications,
        PersonConditionObject: personConditions,
        InterestObject: interests,
        EducationObject: educations,
      }

      var callback = (response) => {
        this.specialist.id = response
      }
      return await utilities.postSpecialist(params, callback)
    },

    async postSpecialistSettings(id) {
      var params = {
        SpecialistID: this.specialist.id,
        NewBookingNotification: utilities.convertBoolToNum(this.setting.newBookingNotification),
        BookingChange: utilities.convertBoolToNum(this.setting.bookingChange),
        AvailabilityReminder: utilities.convertBoolToNum(this.setting.availabilityReminder),
        AvailabilityReturned: utilities.convertBoolToNum(this.setting.availabilityReturned),
        WeeklySchedule: utilities.convertBoolToNum(this.setting.weeklySchedule),
        ScheduleChange: utilities.convertBoolToNum(this.setting.scheduleChange),
        ReportsDue: utilities.convertBoolToNum(this.setting.reportsDue),
        ReportsOverdue: utilities.convertBoolToNum(this.setting.reportsOverdue),
        BookingReminder: utilities.convertBoolToNum(this.setting.bookingReminder),
        SpecialistSettingID: this.setting.specialistSettingId,
        UserID: this.user.id,
        Username: this.user.name,
      }

      var callback = (response) => {}

      return utilities.postSpecialistSettings(params, callback)
    },

    async putSpecialistSettings() {
      var params = {
        SpecialistID: this.setting.specialistId,
        NewBookingNotification: utilities.convertBoolToNum(this.setting.newBookingNotification),
        BookingChange: utilities.convertBoolToNum(this.setting.bookingChange),
        AvailabilityReminder: utilities.convertBoolToNum(this.setting.availabilityReminder),
        AvailabilityReturned: utilities.convertBoolToNum(this.setting.availabilityReturned),
        WeeklySchedule: utilities.convertBoolToNum(this.setting.weeklySchedule),
        ScheduleChange: utilities.convertBoolToNum(this.setting.scheduleChange),
        ReportsDue: utilities.convertBoolToNum(this.setting.reportsDue),
        ReportsOverdue: utilities.convertBoolToNum(this.setting.reportsOverdue),
        BookingReminder: utilities.convertBoolToNum(this.setting.bookingReminder),
        SpecialistSettingID: this.setting.specialistSettingId,
        UserID: this.user.id,
        Username: this.user.name,
      }
      var callback = (response) => {
      }
      return utilities.putSpecialistSettings(params, callback)
    },

    async putProfilePicture() {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.specialist.id,
        FirstName: this.specialist.firstName,
        Surname: this.specialist.lastName,
        ProfileUpdateOnly: 1
      }

      if (typeof this.specialist.photo != "string") {
        var object = this.specialist.photo
        params.ProfilePicture = object.base64
        params.SpecialistName = utilities.removeSpace(this.specialist.firstName + this.specialist.lastName)
        params.DateUploaded = moment().format("YYYY-MM-DD HH:mm:ss").replace(" ", "_")
        params.ImageType = object.type

        var resizeImage = await utilities.resizeImage(object.file)
        params.ProfileThumbnail = resizeImage
      } else if (this.specialist.photoURL != "") {
        params.Photo = this.specialist.photoURL
      }

      var callback = (response) => {}
      return utilities.putSpecialist(params, callback)
    },
    async putSpecialist() {
      var locations = []
      var travels = []
      var educations = []

      var qualifications = []
      var qualificationNames = []
      var qualification = {}
      this.specialist.qualifications.map((x) => {
        if (x.id != "" || x.qualificationId != "") {
          qualification = {
            SpecialistQualificationID: x.id,
            QualificationID: x.qualificationId,
            QualificationName: x.qualificationName,
          }
          qualifications.push(qualification)
          if (x.qualificationId) {
              qualificationNames.push(x.qualificationName)
          }
        }
      })
      var certifications = []
      var certification = {}
      this.specialist.certifications.map((x) => {
        if (x.id != "" || x.certificationId != "") {
          certification = {
            SpecialistCertificationID: x.id,
            CertificationID: x.certificationId,
            CertificationName: x.certificationName,
          }
          certifications.push(certification)
        }
      })

      var personConditions = []
      var personCondition = {}
      this.specialist.personConditions.map((x) => {
        if (x.id != "" || x.personConditionId != "") {
          personCondition = {
            SpecialistPersonConditionID: x.id,
            PersonConditionID: x.personConditionId,
            PersonConditionName: x.personConditionName,
          }
          personConditions.push(personCondition)
        }
      })

      var interests = []
      var interest = {}
      this.specialist.interests.map((x) => {
        if (x.id != "" || x.personConditionId != "") {
          interest = {
            SpecialistInterestID: x.id,
            PersonConditionID: x.personConditionId,
            PersonConditionName: x.personConditionName,
          }
          interests.push(interest)
        }
      })

      var zohoId = this.specialist.zohoLink.split("/")
      zohoId = zohoId[zohoId.length - 1]
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        SpecialistID: this.specialist.id,
        Title: this.specialist.title,
        FirstName: this.specialist.firstName,
        Surname: this.specialist.lastName,
        PhoneNumber1: utilities.validatePhonenumber(this.specialist.phone1),
        PhoneNumber2: utilities.validatePhonenumber(this.specialist.phone2),
        Mobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.specialist.mobile)),
        EmailAddress: this.specialist.email,
        NotificationEmailAddress: this.specialist.NotificationEmailAddress,
        ClientAlignment: this.specialist.clientAlignment,
        IsUser: utilities.convertBoolToNum(this.specialist.isUser),
        ByRequestOnly: utilities.convertBoolToNum(
          this.specialist.byRequestOnly
        ),
        VendorGeneratedInvoices: utilities.convertBoolToNum(
          this.specialist.vendorGeneratedInvoices
        ),
        ServiceIME: utilities.convertBoolToNum(this.specialist.serviceIME),
        ServiceIMEAbuse: utilities.convertBoolToNum(this.specialist.serviceIMEAbuse),
        ServiceIMEVideo: utilities.convertBoolToNum(this.specialist.serviceIMEVideo),
        ServiceSupplementary: utilities.convertBoolToNum(this.specialist.serviceSupplementary),
        ServiceFileReview: utilities.convertBoolToNum(this.specialist.serviceFileReview),
        ServicePhoneCall: utilities.convertBoolToNum(this.specialist.servicePhoneCall),
        ServiceMedicalNegligenceRequest: utilities.convertBoolToNum(this.specialist.serviceMedicalNegligenceRequest),
        ServiceSupplementaryByVideo: utilities.convertBoolToNum(this.specialist.serviceSupplementaryByVideo),
        ServiceCourtCase: utilities.convertBoolToNum(this.specialist.serviceCourtCase),
        FCELite: utilities.convertBoolToNum(this.specialist.fceLite),
        FCELiteByVideo: utilities.convertBoolToNum(this.specialist.fceLiteByVideo),
        ConsentForm1: this.specialist.consentForm1,
        ConsentForm2: this.specialist.consentForm2,
        ConsentForm3: this.specialist.consentForm3,
        ProfileURL: this.specialist.profileURL,
        SpecialistProfile: this.specialist.specialistProfile,
        SpecialistStatus: utilities.convertBoolToNum(this.specialist.specialistStatus),
        XeroCode: this.specialist.xeroCode,
        PracticeLocationID: this.specialist.practiceLocationId,
        LocationObject: locations,
        TravelsToObject: travels,
        QualificationObject: qualifications,
        QualificationNames: qualificationNames,
        CertificationObject: certifications,
        PersonConditionObject: personConditions,
        InterestObject: interests,
        EducationObject: educations,
        ZohoLink: zohoId,
        ProfileUpdateOnly: 0,
      }

      if (typeof this.specialist.photo != "string") {
        var object = this.specialist.photo
        params.ProfilePicture = object.base64
        params.SpecialistName = utilities.removeSpace(this.specialist.firstName + this.specialist.lastName)
        params.DateUploaded = moment().format("YYYY-MM-DD HH:mm:ss").replace(" ", "_")
        params.ImageType = object.type

        var resizeImage = await utilities.resizeImage(object.file)
        params.ProfileThumbnail = resizeImage
      } else if (this.specialist.photoURL != "") {
        params.Photo = this.specialist.photoURL
      }

      var callback = (response) => {}
      return utilities.putSpecialist(params, callback)
    },

    postCognitoUser(type) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        Name: this.specialist.userName,
        UserRoleID: 5,  // Specialist
        UserStatus: 1,
        SpecialistID: this.specialist.id,
        ClientID: "",
        EmailAddress: this.specialist.email,
        NotificationEmailAddress: this.specialist.NotificationEmailAddress,
        Mobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.specialist.mobile)),
        FullName: this.specialist.fullName,
        CanLogin: utilities.convertBoolToNum(this.specialist.isUser),
        Method: type,
        SpecialistUserID: this.specialist.specialistUserId,
      }

      if (JSON.stringify(this.specialist) !== JSON.stringify(this.oldSpecialist)) {
        if (this.specialist.isUser && !this.oldSpecialist.isUser) {
          params.Reactivate = 1
        } else if (this.specialist.isUser && this.oldSpecialist.isUser) {
          params.Reactivate = 0
        }

        if (this.specialist.email != this.oldSpecialist.email) {
          params.OldEmailAddress = this.oldSpecialist.email
        }
      }

      return utilities.createCognitoUser(params)
    },

    async addSpecialist() {
      this.$store.dispatch("loading/setLoadComponent", true)
      try {
        var result = await this.postSpecialist()
        if (result) {
          $(".submit-specialist button").attr('disabled', true)
          await this.postSpecialistSettings(this.specialist.id)
          if (typeof this.specialist.photo != "string" && this.specialist.photo != undefined) {
            await this.putProfilePicture()
          }

          if(this.specialist.practiceLocationId == this.newLocation.id) {
            await this.putLocation()
          }

          if(this.specialist.isUser) {
            await this.postCognitoUser('post')
          }

          // Clear changing object
          utilities.clearChangingObject()
          if (this.specialist.isUser) {
            this.successMessage = "Specialist created and invited to Red Health Online successfully."
          } else {
            this.successMessage = "Specialist created successfully."
          }
          utilities.showAlert(".alert-success")
          utilities.clearStore()
          setTimeout(async () => {
            this.$router.push({ name:'admin-manage-specialist', query: { id: this.specialist.id } })
          }, 2000)
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      } catch (e) {
        this.$store.dispatch("loading/setLoadComponent", false)
      }

    },
    async editSpecialist() {
      this.$store.dispatch("loading/setLoadComponent", true)
      if((this.oldSpecialist.isUser && !this.specialist.isUser) || (!this.oldSpecialist.isUser && this.specialist.isUser)) {
        var result = await this.postCognitoUser('put')
        if (result) {
          var result2 = await this.putSpecialist()
          if (result2) {

            if (this.settingsChanged) {
              await this.putSpecialistSettings()
            }
            // Clear changing object
            utilities.clearChangingObject()
            this.loaded = false
            this.successMessage = "Specialist updated successfully."
            utilities.showAlert(".alert-success")
            await this.loadSpecialist()
          }
        }
      } else {
        var result2 = await this.putSpecialist()
        if (result2) {

          if (this.settingsChanged) {
            await this.putSpecialistSettings()
          }
          // Clear changing object
          utilities.clearChangingObject()
          this.loaded = false
          this.successMessage = "Specialist updated successfully."
          utilities.showAlert(".alert-success")
          await this.loadSpecialist()
        }
      }
      this.$store.dispatch("loading/setLoadComponent", false)
      utilities.clearStore()
    },

    async getSpecialistInfo() {
      var specialistId = this.$route.query != undefined && this.$route.query.id != undefined ? this.$route.query.id : ""
      if (specialistId != "") {
        this.specialist = await utilities.getSpecialistById(specialistId)
        if (this.specialist.specialistMinimumAppiontmentTime.length > 0) {
          this.specialist.minimumAppointment = this.specialist.specialistMinimumAppiontmentTime[0]
        }
      }
    },

    async loadData() {
      if (this.specialist != undefined) {
        // Ticket #5425 - to improve the page speed, call getBulkRefData API to get comman objects.
        let bulkRefData = await utilities.getBulkRefData()

        this.locations = bulkRefData.locations //await utilities.getLocations()

        var qualifications = bulkRefData.qualifications //await utilities.getQualifications()
        this.qualifications = utilities.getAutoCompleteTags(qualifications)

        var certifications = bulkRefData.certifications //await utilities.getCertifications()
        this.certifications = utilities.getAutoCompleteTags(certifications)

        var personConditions = bulkRefData.personConditions //await utilities.getPersonConditions()
        this.personConditions = utilities.getAutoCompleteTags(personConditions)

        this.interests = utilities.getAutoCompleteTags(personConditions)
        this.titles = utilities.getTitles()
        this.clientAlignments = utilities.getClientAlignments()
        this.shortAppointmentLengths = utilities.getShortAppointmentLengths()
        this.appointmentLengths = [...this.shortAppointmentLengths.slice(0, this.shortAppointmentLengths.length - 1) , ...utilities.getAppointmentLengths(), ...utilities.getLongAppointmentLengths()]

        this.regions = bulkRefData.regions //await utilities.getRegions()
        var cities = bulkRefData.citys //await utilities.getCities()
        cities.map((x) => {
          x.region = this.regions.find((y) => y.id == x.regionId)
        }).sort((a, b) => {
          return a.cityName > b.cityName ? 1 : -1
        })
        this.cities = cities
        this.states = utilities.getStates()
      }
    },

    async loadSpecialist() {
      var specialistId = this.$route.query != undefined && this.$route.query.id != undefined ? this.$route.query.id : ""
      if (specialistId != "") {
        this.specialist = await utilities.getSpecialistById(specialistId)
        this.oldSpecialist = _.cloneDeep(this.specialist)
        if (this.specialist == undefined) return false

        this.$store.dispatch("setOriginalObject", this.specialist)
        this.$store.dispatch("setNewObject", this.specialist)
        //Ticket #5425 - to improve the page speed, get following two api data inside getSpecialistById()
        //this.setting = await utilities.getSpecialistSeetings(this.specialist.id)
        if (this.specialist.specialistMinimumAppiontmentTime.length > 0) {
          this.specialist.minimumAppointment = this.specialist.specialistMinimumAppiontmentTime[0]
        }

        if (this.specialist.specialistSetting) {
          this.setting = this.specialist.specialistSetting
        }

        // Load profile photo
        if(this.specialist.photo != '') {
          this.specialist.profileThumbnail = await utilities.getProfileThumbnail(this.specialist.photo)
        }

        var qualification = {}
        this.qualificationTags = []
        this.specialist.qualifications.map((x) => {
          if (x.id != "") {
            qualification = {
              id: x.id,
              value: x.qualificationId,
              text: x.qualificationName,
            }
            this.qualificationTags.push(qualification)
          }
        })

        var certification = {}
        this.certificationTags = []
        this.specialist.certifications.map((x) => {
          if (x.id != "") {
            certification = {
              id: x.id,
              value: x.certificationId,
              text: x.certificationName,
            }
            this.certificationTags.push(certification)
          }
        })

        var personCondition = {}
        this.personConditionTags = []
        this.specialist.personConditions.map((x) => {
          if (x.id != "") {
            personCondition = {
              id: x.id,
              value: x.personConditionId,
              text: x.personConditionName,
            }
            this.personConditionTags.push(personCondition)
          }
        })

        var interest = {}
        this.interestTags = []
        this.specialist.interests.map((x) => {
          if (x.id != "") {
            interest = {
              id: x.id,
              value: x.personConditionId,
              text: x.personConditionName,
            }
            this.interestTags.push(interest)
          }
        })
      }

      if (this.specialist != undefined && this.specialist.minimumAppointment.independentMedicalAssessment == "") this.specialist.minimumAppointment.independentMedicalAssessment = 60
      if (this.specialist != undefined && this.specialist.minimumAppointment.independentMedicalAssessmentVideo == "") this.specialist.minimumAppointment.independentMedicalAssessmentVideo = 60
      if (this.specialist != undefined && this.specialist.minimumAppointment.independentMedicalAssessmentAbuse == "") this.specialist.minimumAppointment.independentMedicalAssessmentAbuse = 60
      if (this.specialist != undefined && this.specialist.minimumAppointment.phoneCall == "") this.specialist.minimumAppointment.phoneCall = 30

      this.modalKey += 1
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $("body>.tooltip").remove()
    await this.loadSpecialist()
    await this.loadData()
    this.loaded = true
    autosize($("textarea"))
    utilities.initTooltip()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {
      let scripts = document.querySelectorAll('script');
      var added = false
      for (var i = 0; i < scripts.length; i++) {
        if (scripts[i].src == 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ4ZI98UvV5tH4FnGFmlO-jmS46pmLDRU') {
          added = true
          break
        }
      }
      if (!added) {
        let externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ4ZI98UvV5tH4FnGFmlO-jmS46pmLDRU')
          document.head.appendChild(externalScript)
      }
  },
}
</script>
