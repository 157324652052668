import { Auth } from 'aws-amplify'
// import awsconfig from '@/assets/js/aws-config'
var QRCode = require('qrcode')
import * as AWS from 'aws-sdk'

export default {
  async getCognitoUser() {
    return Auth.currentUserInfo()
  },

  async getCognitoIdentityCredentials() { },

  async getCurrentLoggedInSession() { },

  async login(authenticationData) {
    try {
      var user = Auth.signIn(authenticationData.Username, authenticationData.Password)
      return user
    } catch (error) {
      //console.log(error)
      if (user.code == 'UserNotConfirmedException') {

      }
      return false
    }
  },

  async changePassword(authenticationData) {
    var user = await Auth.currentAuthenticatedUser({ bypassCache: false })
    return Auth.changePassword(user, authenticationData.Password, authenticationData.NewPassword)
  },

  async forgotPassword(authenticationData) {
    return Auth.forgotPassword(authenticationData.Username)
      .then((res) => { return res })
      .catch(e => { return false })
  },

  async confirmNewPassword(username, code, password) {
    return Auth.forgotPasswordSubmit(username, code, password)
      .then(() => { return true })
      .catch(e => { return false })
  },

  async signUp({ username, email, password, phoneNumber }) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          phone_number: phoneNumber
        }
      });
    } catch (error) {

    }
  },

  async addToAuthenticator(user) {
    return Auth.setupTOTP(user)
  },

  setNewPassword(user, newPassword) {
    const { requiredAttributes } = user.challengeParam;
    return Auth.completeNewPassword(user, newPassword)
  },

  async verifyOtp(user, otp, firstTime = false) {
    if (firstTime) {
      var userRespon = await Auth.verifyTotpToken(user, otp).then(async (res) => {
        Auth.setPreferredMFA(user, 'TOTP');
        //Can't call this function using same OTP code. Because it already used in above
        // var userRespon = await Auth.confirmSignIn(
        //   user,
        //   otp,
        //   'SOFTWARE_TOKEN_MFA'
        // )
        return res
      })
      .catch((error) => {
        //console.log(error)
      })
      return userRespon
    } else {
      var userRespon = await Auth.confirmSignIn(
        user,
        otp,
        'SOFTWARE_TOKEN_MFA'
      )
      return userRespon
    }
  },

  async logout(callback) {
    var cognitoUser = this.getCognitoUser()
    if (cognitoUser != null) {
      Auth.signOut()
      callback()
    }
  },

  async getCurrentAuthenticatedUser() {
    try { return await Auth.currentAuthenticatedUser({ bypassCache: false }) } catch (error) { return false }
  },
}
