<template>
  <div class="manage-travel-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>Specialist {{ specialist.fullName }}'s Travel To</h1>
                </div>
                <div class="col">
                  <div class="right-float-element"><v-btn class="add-icon" dark @click="addTravel">Add New</v-btn></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div v-for="(travel, index) in specialist.travels" class="box">
                  <div class="box-heading">
                    <div class="box-heading-title w-95" style="overflow: hidden">{{ travel.fullTravel }}</div>
                    <div class="right-element">
                      <img v-if="!travel.isOpen" src="@/assets/images/up_arrow_icon.svg" class="is-icon mr-3" @click="openTravel(index)" />
                      <img v-else src="@/assets/images/down_arrow_icon.svg" class="is-icon mr-3" @click="closeTravel(index)" />
                      <img v-if="travel.id != ''" src="@/assets/images/delete_icon.svg" @click="deleteTravelTo(travel, index)"/>
                    </div>
                  </div>
                  <div class="box-body" :class="!travel.isOpen ? 'hidden-element' : ''">
                    <div class="box-row">
                      <div class="box-col box-col-left">Location</div>
                      <div class="box-col box-col-right">
                        <div v-if="!clickLocation" @click="handleLocation" class="parent">
                          <input class="child" type="text" disabled v-model="travel.fullTravel" placeholder="Click to choose the title" />
                        </div>
                        <el-select :popper-append-to-body="false" filterable placeholder="Please choose ..." v-else class="w-100" v-model="travel.locationId" @change="changeLocation(index)">
                          <el-option v-for="(location, index) in locations" :value="location.id" :key="location.id" :label="location.fullLocation">{{ location.fullLocation }}</el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">
                        Flights & Accommodation Required
                      </div>
                      <div class="box-col box-col-right">
                        <input type="checkbox" v-model="travel.requiresTravel" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> Please correct the errors shown above and try again.
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="save">Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "ManageTravelModal",
  props: ["specialistProps"],
  components: {},
  data() {
    return {
      loadModal: false,
      hasError: false,
      clickLocation: false,

      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      specialist: this.specialistProps != undefined ? _.cloneDeep(this.specialistProps) : utilities.initSpecialist(),

      locations: [],
      location: utilities.initLocation(),

      index: -1,
    }
  },
  computed: {
    validateTravels() {
      var result = true
      for(var i = 0; i < this.specialist.travels.length; i++) {
        result = this.validateLocation(i)
        if(!result) {
          break
        }
      }
      return result
    },
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
    handleLocation() {
      this.clickLocation = true
    },
    async deleteTravelTo(travel, index) {
      this.$confirm({
        content: 'Are you sure you want to delete this travel location?',
        onConfirm: async () => {
          var params = {
            userID: this.user.id,
            userName: this.user.name,
            id: travel.id,
          }

          var result = await utilities.deleteTravelLocation(params)
          if (result) {
            this.specialist.travels.splice(index, 1)
            if (this.specialist.travels.length == 0) {
              this.specialist.travels.push(utilities.initTravel())
            }
            this.$parent.specialist.travels = _.cloneDeep(this.specialist.travels)
          }
        }
      })
    },
    validateLocation(index) {
      if (this.specialist.travels[index].locationId == '') {
        return false
      }
      return true
    },
    changeLocation(index) {
      this.location = this.locations.find(x => x.id == this.specialist.travels[index].locationId)
      this.location.fullTravel = this.location.fullLocation
      this.index = index
    },
    openTravel(index) {
      this.specialist.travels.map((x, xi) => {
        x.isOpen = xi == index ? true : false
      })
    },
    closeTravel(index) {
      this.specialist.travels[index].isOpen = false
    },
    addTravel() {
      this.specialist.travels.push(utilities.initTravel())
      this.openTravel(this.specialist.travels.length - 1)
    },
    async save() {
      if (this.index != -1) {
        this.specialist.travels[this.index].locationName = this.location.name
        this.specialist.travels[this.index].cityName = this.location.cityName
        this.specialist.travels[this.index].state = this.location.state
        this.specialist.travels[this.index].country = this.location.country
        this.specialist.travels[this.index].fullTravel = this.location.fullTravel
      }
      if (!this.validateTravels) {
        this.hasError = true
        utilities.showAlert(".manage-travel-modal .alert-danger")
        return false
      } else {
        this.hasError = false
      }

      if(this.specialist.id != '') {
        this.$store.dispatch("loading/setLoadComponent", true)
        await Promise.all(this.specialist.travels.map(async (x, xi) => {
          if(x.id != '') {
            await this.putTravel(x, xi)
          } else {
            await this.postTravel(x, xi)
          }
        }))
        this.$store.dispatch("loading/setLoadComponent", false)
      } else {
        this.specialist.travels.map((x, xi) => {
          x.fullTravel = utilities.getFullTravel(x)
        })
      }

      this.$parent.specialist.travels = _.cloneDeep(this.specialist.travels)
      this.$parent.travelToKey += 1
      setTimeout(() => {
        $('.travel-to [data-toggle="tooltip"]').tooltip()
      }, 500)
      this.handleModal()
    },

    postTravel(travel, index) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.specialist.id,
        SpecialistTravelsToID: "",
        LocationID: travel.locationId,
        RequiresTravel: utilities.convertBoolToNum(travel.requiresTravel),
      }
      var callback = (response) => {
        this.specialist.travels[index].id = response
      }
      return utilities.postTravel(params, callback)
    },
    putTravel(travel, index) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.specialist.id,
        SpecialistTravelsToID: travel.id,
        LocationID: travel.locationId,
        RequiresTravel: utilities.convertBoolToNum(travel.requiresTravel),
      }
      var callback = (response) => {}
      return utilities.putTravel(params, callback)
    },
    async loadTravels() {
      if(this.specialist.id != '') {
        var travels = await utilities.getTravels(this.specialist.id)
        if(travels.length == 0) {
          travels.push(utilities.initTravel())
        }
        this.specialist.travels = travels
      }
      this.location = utilities.initLocation()
      this.index = -1
    },
    async loadData() {
      this.locations = await utilities.getLocations()
    },
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    //await this.loadTravels()
    //await this.loadData()
  },
  mounted() {},
}
</script>
