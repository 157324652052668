/* eslint-disable */
<template>
  <div class="specialists">
    <AdminNavigation ref="navigation" indexProps="3"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Specialists</h1></div>
        <div class="header-sub-title"><span class="search-title" v-html="searchTitle"></span></div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistMenu :key="specialistMenuIndex" :indexProps="specialistMenuIndex"/>
      <div class="content">
        <div class="tool-bar row">
          <div class="col-1">
            <div class="w-50">
              <img
                  class="is-icon div-image"
                  src="@/assets/images/file_icon.svg"
                  data-placement="right"
                  data-toggle="tooltip"
                  title="Download PDF"
                  :disabled="selectedSpecialists.length == 0"
                  :class="selectedSpecialists.length == 0 ? 'disabled-element' : ''"
                  @click="selectedSpecialists.length == 0 ? '' : downloadPdf()"
              />
            </div>
          </div>
          <div class="col">
            <div class="icon-group right-float-element">
              <div class="d-inherit">
                <img v-if="!useSearch" src="@/assets/images/search_black_icon.svg" title="Search Specialist"
                     @click="openSearchSpecialist"/>
                <img v-else src="@/assets/images/close_icon.svg" @click="cancelSearch" title="Cancel Search"/>
                <el-input @input="search()" clearable placeholder="Specialist Name" v-model="listParam.SpecialistName"/>
              </div>
              <router-link :to="{ name: 'admin-manage-specialist', query: {} }">
                <v-btn class="add-icon" dark>Add New</v-btn>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                <tr>
                  <th style="width:5%" class="icon-col"><input type="checkbox" class="form-checkbox-all"
                                                               @change="changeCheckboxAll($event)"/></th>
                  <th class="hidden-element">Id</th>
                  <th style="width:14%" class="long-text">
                    Name<img v-if="listParam.SortFlag === 2" class="sort-icon"
                             :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortName(1)"/>
                    <img v-else-if="listParam.SortFlag === 1" class="sort-icon"
                         :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortName(0)"/>
                    <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                         @click="sortName(2)"/>
                  </th>
                  <th style="width:5%" class="long-text">&nbsp;</th>
                  <th style="width:12%" class="long-text">Client Alignment</th>
                  <th style="width:13%" class="long-text">Certifications</th>
                  <th style="width:13%" class="long-text">Specialities</th>
                  <th style="width:12%" class="long-text">
                    Next Availability
                  </th>
                  <th style="width:12%" class="long-text">
                    Last Availability
                  </th>
                  <th style="width:15%" class="icon-col">
                    <div class="icon-group">&nbsp;</div>
                  </th>
                </tr>
                </thead>
              </table>
            </div>
            <div v-if="specialists.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                <tr v-for="(specialist, index) in specialists" :key="specialist.id" class="is-icon">
                  <td style="width:5%" class="icon-col"><input type="checkbox" class="form-checkbox" :value="index"
                                                               @change="changeCheckbox($event)"/></td>
                  <td class="hidden-element">{{ specialist.id }}</td>
                  <td style="width:14%" class="long-text" @click="openSpecialist(specialist.id)">
                    <div class="long-text" data-placement="right" data-toggle="tooltip" :title="specialist.fullName">
                      <span>{{ specialist.fullName }}</span>
                    </div>
                  </td>
                  <td style="width:5%" class="long-text" @click="openSpecialist(specialist.id)">
                    <img
                        :src="specialist.byRequestOnly ? require('@/assets/images/by_request_only_icon.svg') : require('@/assets/images/by_request_only_dark_icon.svg')"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="specialist.byRequestOnly ? 'By Request Only' : 'Not By Request Only'"
                    />
                  </td>
                  <td style="width:12%" class="long-text" @click="openSpecialist(specialist.id)">
                    <div class="long-text" data-placement="right" data-toggle="tooltip"
                         :title="specialist.clientAlignment">
                      <span>{{ specialist.clientAlignment }}</span>
                    </div>
                  </td>
                  <td style="width:13%" class="long-text" @click="openSpecialist(specialist.id)">
                    <div class="long-text" data-placement="right" data-toggle="tooltip"
                         :title="specialist.certificationList">
                      <span>{{ specialist.certificationList }}</span>
                    </div>
                  </td>
                  <td style="width:13%" class="long-text" @click="openSpecialist(specialist.id)">
                    <div class="long-text" data-placement="right" data-toggle="tooltip"
                         :title="specialist.qualificationList">
                      <span>{{ specialist.qualificationList }}</span>
                    </div>
                  </td>
                  <td style="width:12%" class="long-text" @click="openSpecialist(specialist.id)">
                    {{ formatDate(specialist.nextAvailabilityDate, 'DD/MM/YYYY') }}
                  </td>
                  <td style="width:12%" class="long-text" @click="openSpecialist(specialist.id)">
                    {{ formatDate(specialist.lastAvailabilityDate, 'DD/MM/YYYY') }}
                  </td>
                  <td style="width:15%" class="icon-col">
                    <div class="icon-group">
                      <router-link v-if="user.type == 4 || user.type == 6"
                                   :to="{ name: 'admin-manage-rate-card', query: { id: specialist.id } }"
                      ><img src="@/assets/images/finance_black_icon.svg" data-placement="right" data-toggle="tooltip"
                            title="Access Rate Card"
                      /></router-link>
                      <router-link :to="{ name: 'admin-specialist-travel', query: { id: specialist.id } }"
                      ><img src="@/assets/images/travel_black_icon.svg" data-placement="right" data-toggle="tooltip"
                            title="View Current & Past Travel"
                      /></router-link>
                      <router-link :to="{ name: 'admin-specialist-bookings', query: { id: specialist.id } }"
                      ><img src="@/assets/images/calendar_dark_grey_icon.svg" data-placement="right"
                            data-toggle="tooltip" title="View Bookings"
                      /></router-link>
                      <router-link :to="{ name: 'admin-search-specialist', query: { specialistId: specialist.id } }"
                      ><img src="@/assets/images/add_booking_dark_icon.svg" data-placement="right" data-toggle="tooltip"
                            title="Add Booking"
                      /></router-link>
                      <!-- DO NOT CHANGE VARIABLE NAME FROM specialistId TO id BECAUSE THE OTHER PAGE HAS 2 POSSIBLE IDs. -->
                      <a :href="specialist.zohoLink" target="_blank"><img src="@/assets/images/zoho_icon.svg"
                                                                          data-placement="right" data-toggle="tooltip"
                                                                          title="Link to Zoho"/></a>
                      <EditZohoLinkDialog @updateSuccess="updateSuccess" :id="specialist.id" :index="index" type="specialist"/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>


        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminSpecialistMenu from '@/components/AdminSpecialistMenu'
import Header from '@/components/Header'
import {debounce} from 'lodash'
import EditZohoLinkDialog from "@/components/dialog/EditZohoLinkDialog"

export default {
  name: 'AdminSpecialists',
  props: ['searchOptionProps'],
  components: {
    AdminNavigation,
    AdminSpecialistMenu,
    Header,
    EditZohoLinkDialog
  },
  data() {
    return {
      dialogVisible: false,
      fetchedData: false,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      specialists: [],
      allSpecialists: [],
      selectedSpecialists: [],
      limit: 20,
      pagination: utilities.initPagination(),
      specialistMenuIndex: 0,
      searchTitle: "",
      nextMaxDate: "",
      lastMaxDate: "",
      searchParam: {},
      // ASC (true) with down icon (false), DESC (false) with up icon (true)
      byName: false,
      byNextAvailability: false,
      byLastAvailability: false,
      fromSearch: false,
      useSearch: false,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
      },
      totalCount: 0,
    }
  },
  methods: {
    search: debounce(function () {
      this.searchParam.name = this.listParam.SpecialistName
      this.searchSpecialist(this.searchParam)
    }, 800),
    cancelSearch() {
      this.useSearch = false
      this.listParam = {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 1
      }
      this.searchParam = {}
      this.showSearchTitle('')
      this.loadSpecialists()
    },
    updateSuccess(index, newLink) {
      let obj = _.cloneDeep(this.specialists[index])
      obj.zohoLink = newLink
      this.$set(this.specialists, index, obj)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadSpecialists()
    },
    sortName(v) {
      this.listParam.SortFlag = v
      this.listParam.PageNum = 1
      this.loadSpecialists()
    },
    formatDate(dateToFormat, newFormat) {
      if(!dateToFormat) return ''
      return window.moment(dateToFormat).format(newFormat)
    },
    changeCheckboxAll(e) {
      if (e.target.checked) {
        $('.form-checkbox').prop('checked', true)
        this.selectedSpecialists = this.specialists.map((x) => x.id)
      } else {
        $('.form-checkbox').prop('checked', false)
        this.selectedSpecialists = []
      }
    },
    changeCheckbox(e) {
      if (e.target.checked) {
        // Add to list
        this.selectedSpecialists.push(this.specialists[e.target.value].id)
      } else {
        // Remove from list
        this.selectedSpecialists = this.selectedSpecialists.filter((x) => x != this.specialists[e.target.value].id)
      }
      var checkedNumber = $('.form-checkbox:checked').length

      if (checkedNumber == 0) {
        $('.form-checkbox-all').prop('checked', false)
      }
      if (checkedNumber >= $('.form-checkbox').length - 1) {
        $('.form-checkbox-all').prop('checked', true)
      }
    },
    openSearchSpecialist() {
      this.$refs.navigation.$refs.globalSearch.openSearchSpecialist()
    },
    openSpecialist(id) {
      if (this.fromSearch) {
        let routeData = this.$router.resolve({name: 'admin-manage-specialist', query: {id: id}});
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({name: 'admin-manage-specialist', query: {id: id}})
      }
    },
    paging() {
      utilities.disposeTooltip()
      utilities.initTooltip()
      utilities.hoverTooltip()
    },
    showSearchTitle(text) {
      this.searchTitle = text
    },
    async downloadPdf() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var specialistIds = this.selectedSpecialists.join(',')
      var pdf = await utilities.getSpecialistProfilesByIds(specialistIds)
      var a = document.createElement('a')
      let blob = pdf
      a.setAttribute('href', window.URL.createObjectURL(blob))
      a.setAttribute('download', 'SpecialistsProfile.pdf')
      a.click()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async searchSpecialist(searchOption) {
      this.fromSearch = true
      var params = {
        QualificationID: searchOption.qualificationTags ? searchOption.qualificationTags.map((x) => x.value).join(', ') : null,
        PersonConditionID: searchOption.personConditionTags ? searchOption.personConditionTags.map((x) => x.value).join(', ') : null,
        SpecialistName: searchOption.name,
      }

      if (searchOption.regionId != null) {
        if (searchOption.regionId.includes('r')) {
          params.RegionID = searchOption.regionId.replace('r', '')
        } else {
          params.CityID = searchOption.regionId.replace('c', '')
          if (searchOption.radius > 0) params.Radius = searchOption.radius
        }
      }
      params.PageNum = 1
      params.PageSize = 10
      this.listParam = params
      this.$store.dispatch('loading/setLoadComponent', true)
      var {specialists, total} = await utilities.getSearchSpecialists(this.listParam)
      this.$store.dispatch('loading/setLoadComponent', false)
      this.useSearch = true
      this.specialists = _.cloneDeep(specialists)
      this.totalCount = total

      var text = '<b>Search:</b>'
      if (searchOption.qualificationTags && searchOption.qualificationTags.length > 0) {
        text += '&nbsp;&nbsp;&nbsp;<b>Qualified to Practice As:</b> ' + searchOption.qualificationTags.map((x) => x.text).join(', ')
        text += '.'
      }
      if (searchOption.personConditionTags && searchOption.personConditionTags.length > 0) {
        text += '&nbsp;&nbsp;&nbsp;<b>Expert In:</b> ' + searchOption.personConditionTags.map((x) => x.text).join(', ')
        text += '.'
      }
      if (searchOption.name !== '') {
        text += '&nbsp;&nbsp;&nbsp;<b>Name:</b> ' + searchOption.name
        text += '.'
      }
      if (searchOption.regionId != null && searchOption.regionId != '') {
        text += '&nbsp;&nbsp;&nbsp;<b>Location:</b> ' + searchOption.regionName
        if (searchOption.regionId.includes('c') && searchOption.radius > 0) text += ' (within ' + searchOption.radius + ' km)'
        text += '.'
      }
      this.showSearchTitle(text)
      this.searchParam = searchOption
    },
    async loadSpecialists() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {specialists, total} = await utilities.getSpecialistsNew(this.listParam)
      this.specialists = _.cloneDeep(specialists)
      this.totalCount = total
      this.paging()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    if (this.searchOptionProps !== undefined) {
      await this.searchSpecialist(this.searchOptionProps)
    } else {
      await this.loadSpecialists()
    }
    this.fetchedData = true
  },
  mounted() {
    $('.pagination').hide()
  },
}
</script>
