import { render, staticRenderFns } from "./AddClientAddressModal.vue?vue&type=template&id=4e709d63&"
import script from "./AddClientAddressModal.vue?vue&type=script&lang=js&"
export * from "./AddClientAddressModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports