/* eslint-disable */
<template>
  <div class="hours-booked">
    <AdminNavigation ref="navigation" indexProps="6" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Number of Available Hours ({{ totalHours }})</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminReportsMenu indexProps="1" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <download-csv :data="getCalendarData()" :name="getFileName()">
              <v-btn dark>CSV <img src="@/assets/images/download_light_icon.svg" class="ml-3"/></v-btn>
            </download-csv>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width:50%" class="long-text">Month / Year</th>
                    <th style="width:50%" class="long-text">Number of Hours</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="hoursAvailable.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(hours, index) in hoursAvailable" :key="index">
                    <td style="width:50%" class="long-text">{{ hours.groupingDate }}</td>
                    <td style="width:50%" class="long-text">{{ hours.availableHours }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminAvailabilityMenu from '@/components/AdminAvailabilityMenu'
  import Header from '@/components/Header'
  import AdminReportsMenu from '@/components/AdminReportsMenu'

  export default {
    name: 'AdminHoursAvailable',
    components: {
      AdminNavigation,
      AdminAvailabilityMenu,
      Header,
      AdminReportsMenu,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        hoursAvailable: [],
        limit: 10,
        pagination: utilities.initPagination(),
        allHoursAvailable: [],
        errorMessage: '',
        successMessage: '',
        totalHours:0,
      }
    },
    methods: {
      async loadData() {},
      paging(hoursAvailable) {
        if (hoursAvailable.length == 0 || hoursAvailable.length <= this.limit) {
          this.hoursAvailable = hoursAvailable
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(hoursAvailable, this.limit)
          this.hoursAvailable = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
      },

      selectPage(index) {
        this.pagination.current = index
        this.locations = this.pagination.list[this.pagination.current]
      },

      getFileName() {
          return "Hours_available_"+moment().format("YYYYMMDDHHmm")+".csv"
      },

      getCalendarData() {
          var calendarData = []
          this.allHoursAvailable.map(x=>{
              calendarData.push({
                  "Month / Year": x.groupingDate,
                  "Number of Hours": x.availableHours
              })
          })
          return calendarData
      }
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      //   var locations = await utilities.getLocations()
      this.allHoursAvailable = await utilities.getReportHoursAvailable()
      this.allHoursAvailable.map(x=>{
        this.totalHours += parseFloat(x.availableHours)
      })
      this.totalHours.toFixed(2)
      this.paging(this.allHoursAvailable)
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
