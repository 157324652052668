/* eslint-disable */
<template>
  <div class="create-client" :key="updateKey">
    <AdminNavigation ref="navigation" indexProps="2" />
    <div class="top-panel">
      <Header />
      <div v-if="client != undefined" class="header-title-section">
        <div class="header-title"><h1>{{ pageMode == 'edit' ? 'Edit Client' : 'Create Client' }}</h1></div>
        <div class="header-sub-title">Clients can have numerous branches, and contacts at each branch.</div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu :indexProps="pageMode == 'edit' ? 0: 1" />
      <div class="content">
        <div class="row">
          <div class="col-9 pt-0">
            <div class="box">
              <div class="box-heading">
                <span>Client Details</span>
                <div class="icon-group right-element d-flex align-items-center">
                  <router-link v-if="pageMode == 'edit'" :to="{ name: 'admin-cases', query: { id: client.id } }"><img data-toggle="tooltip" data-placement="right" title="View Cases" src="@/assets/images/list_case_icon.svg"/></router-link>
                  <router-link :to="{ name: 'admin-bookings', query: { clientId: client.id } }"><img data-toggle="tooltip" data-placement="right" title="View Booking" src="@/assets/images/list_booking_icon.svg"/></router-link>
                  <a :href="client.zohoLink" target="blank"><img src="@/assets/images/zoho_circle.svg" class="mr-2 is-icon" /></a>
                </div>
              </div>
              <div class="box-body">
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Name</div>
                  <div class="box-col box-col-right">
                    <div v-if="this.pageMode === 'create'">
                      <el-select filterable clearable placeholder="Please choose ..." v-model="client.id" @change="handleClientChange" class="w-50" :class="hasError && !validateClientName ? 'is-invalid' : ''">
                        <el-option value="new">New</el-option>
                        <el-option v-for="c in clients" :value="c.id" :key="c.id" :label="c.name + ', ' + c.branchName">
                          {{ c.name+ ", " + c.branchName }}
                        </el-option>
                      </el-select>
                      <div v-if="hasError && !validateClientName" class="text-danger pl-2">
                        Client Name is required (Please do not start with '=' or  '-'  or  '+' or  '@' or  '%' and do not contains '--' or '||' or '@@')
                      </div>
                    </div>
                    <div v-else>
                      <input type="text" v-model="client.name" maxlength="100" class="px-3" :class="hasError && !validateClientName ? 'is-invalid' : ''" />
                      <div v-if="hasError && !validateClientName" class="text-danger pl-2">
                        Client Name is required (Please do not start with '=' or  '-'  or  '+' or  '@' or  '%' and do not contains '--' or '||' or '@@')
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-row" v-if="client.id == 'new'">
                  <div class="box-col box-col-left left-label">Enter Name</div>
                  <div class="box-col box-col-right">
                    <input type="text" v-model="client.name" maxlength="100" class="px-3" :class="hasError && !validateClientName ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validateClientName" class="text-danger pl-2">
                      Client Name is required (Please do not start with '=' or  '-'  or  '+' or  '@' or  '%' and do not contains '--' or '||' or '@@')
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Branch</div>
                  <div class="box-col box-col-right">
                    <input type="text" maxlength="100" v-model="client.branchName" placeholder="Enter branch name" class="px-3 branch" :class="hasError && !validateBranchName ? 'is-invalid' : ''" />
                    <div v-if="hasError && !validateBranchName" class="text-danger pl-2">
                      {{ branchError }}
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Category</div>
                  <div class="box-col box-col-right">
                    <div class="parent" v-if="!categoryFocus && this.pageMode == 'create'" @click="handleCategoryClick()">
                      <input type="text" class="child px-3" disabled value="Click to choose category" />
                    </div>
                    <select v-else class="px-3 category" v-model="client.clientCategory">
                      <option hidden value="">Please choose ...</option>
                      <option v-for="category in categories" :key="category.value" :value="category.text">
                        {{ category.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Client Alignment</div>
                  <div class="box-col box-col-right">
                    <div class="parent" v-if="!alignmentFocus && this.pageMode == 'create'" @click="handleAlignmentClick()">
                      <input type="child text" class="px-3" disabled value="Click to choose client alignment" />
                    </div>
                    <select v-else class="px-3 alignment" v-model="client.clientAlignment">
                      <option hidden value="">Please choose ...</option>
                      <option v-for="clientAlignment in clientAlignments" :key="clientAlignment.value" :value="clientAlignment.value">
                        {{ clientAlignment.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Billing Category</div>
                  <div class="box-col box-col-right">
                    <div class="parent" v-if="!billingCategoryFocus && pageMode == 'create'" @click="handleBillingCategoryClick()">
                      <input type="text" class="child px-3" disabled value="Click to choose client billing category" />
                    </div>
                    <select v-else class="px-3 billing" v-model="client.billingCategoryId" :class="hasError && !validateBillingCategory ? 'is-invalid' : ''">
                      <option hidden value="">Please choose ...</option>
                      <option v-for="billingCategory in billingCategories" :key="billingCategory.id" :value="billingCategory.id">{{ billingCategory.name }}</option>
                    </select>
                    <div v-if="hasError && !validateBillingCategory" class="text-danger pl-2">
                      Billing Category is required
                    </div>
                  </div>
                </div>

                <div class="box-row">
                  <div class="box-col box-col-left left-label">Xero Client Code</div>
                  <div class="box-col box-col-right">
                    <input type="text" class="px-3 xero" :disabled="user.type == 3 ? true : false" value="Enter Xero Client Code" v-model="client.xeroClientCode" maxlength="40" />
                  </div>
                </div>

                <div class="box-row">
                  <div class="box-col box-col-left left-label">Address</div>
                  <div class="box-col box-col-right px-3 pl-8" @click="openManageAddressModal">{{ clientAddress }}</div>
                </div>

                <div class="box-row">
                  <div class="box-col box-col-left left-label">
                    Primary Contact
                  </div>
                    <!-- v-if="client.contacts.find((x) => x.primaryContact == true && x.emailAddress != '') !== undefined || client.contacts.length > 0" -->
                  <div
                    class="box-col box-col-right d-flex justify-content-between px-3"
                    v-if="client.contacts.length > 0 && client.contacts[0].emailAddress != ''">
                    <span @click="openManageContactModal" class="pl-5 is-icon">
                      {{ primaryContactDetails }}
                    </span>
                    <div class="" v-if="client.id != '' && pageMode == 'edit'">
                      <button class="btn" data-toggle="tooltip" data-placement="right" title="Create New Contact" @click="addNewContact">
                        <img class="w-75" src="@/assets/images/add_black_icon.svg" />
                      </button>
                      <button class="btn" data-toggle="tooltip" data-placement="right" title="Open Contacts" @click="openManageContactModal">
                        <img src="@/assets/images/users_black_icon.svg" />
                      </button>
                    </div>
                  </div>
                  <div class="box-col box-col-right d-flex justify-content-between align-items-center flex-row" style="flex-wrap: wrap;" v-else>
                    <span @click="openManageContactModal" class="parent w-100 px-0">
                      <input type="text" class="child w-100 px-3" disabled placeholder="Click here to add new users"/>
                    </span>
                    <!-- <button class="btn" data-toggle="tooltip" data-placement="right" title="Tooltip on left" @click="openManageContactModal">
                      <img src="@/assets/images/users_black_icon.svg" />
                    </button> -->
                    <div v-if="hasError && !validatePrimaryContact" class="text-danger pl-2">
                      Primary Contact is required
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">
                    Allow Red Health to contact Claimants
                  </div>
                  <div class="box-col box-col-right px-3">
                    <input type="checkbox" style="margin-left:20px;" v-model="client.redHealthMayContactClaimants" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Require Pre-payment</div>
                  <div class="box-col box-col-right px-3">
                    <input type="checkbox" style="margin-left:20px;" v-model="client.prepaymentRequired" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Allow Payment Deferral</div>
                  <div class="box-col box-col-right px-3">
                    <input type="checkbox" style="margin-left:20px;" v-model="client.paymentDeferralOption" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Enable Red Reschedule</div>
                  <div class="box-col box-col-right px-3">
                    <input type="checkbox" style="margin-left:20px;" v-model="client.redRescheduleEnabled" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 action-log d-inline-block m-0 p-0" style="vertical-align:top; height: 830px;">
            <Timeline :timeline="timelineData" />
          </div>
        </div>
        <div class="box" v-if="pageMode == 'edit'">
          <div class="box-heading tab">
            <div class="tab-heading notes-heading tab-selected px-5" @click="openNotes">
              Notes
            </div>
            <div class="right-element">
              <v-btn dark class="add-icon" @click="openAddNoteModal">Add New</v-btn>
              <AddNoteModal ref="addNoteModal"/>
            </div>
          </div>
          <div class="box-body notes-content hidden-element">
            <div class="box-row" v-for="(note, index) in clientNotes">
              <div class="box-col box-col-left col-1">{{note.userName}}</div>
              <div class="box-col box-col-left col-2">{{formatDateTime(note.noteDateTime)}}</div>
              <div style="max-width: 80%" class="box-col box-col-right box-col-long col-center-content text-break" @click="editNote(index)">
                {{note.note}}
              </div>
              <div v-if="note.userId == user.id" style="width: 2%" class="is-icon" @click="deleteNote(note.clientNoteId, index)">
                <img src="@/assets/images/delete_icon.svg" />
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>

        <div class="right-element"><v-btn dark @click="submit">{{ this.pageMode == 'create' ? 'Create' : 'Update' }}</v-btn></div>
      </div>
    </div>
    <AdminManageContactModal v-if="client.clientId !== undefined || client.id !== undefined" :passedContactsProps="this.client.contacts" ref="adminManageContactModal" />
    <AddClientAddressModal v-if="client.clientId !== undefined || client.id !== undefined" :clientProps="this.client" ref="adminManageAddressModal" typrProps="fromclient" />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import AdminManageContactModal from '@/components/AdminManageContactModal'
  import AddClientAddressModal from '@/components/AddClientAddressModal'
  import Timeline from '@/components/Timeline'
  import AddNoteModal from '@/components/AddNoteModal'

  export default {
    name: 'AdminManageClient',
    components: {
      AdminNavigation,
      Header,
      AdminClientsMenu,
      AdminManageContactModal,
      AddClientAddressModal,
      Timeline,
      AddNoteModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: '',
        pageMode: '',
        branchError: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),

        nameFocus: false,
        branchFocus: false,
        categoryFocus: false,
        alignmentFocus: false,
        billingCategoryFocus: false,
        xeroClientCodeFocus: false,
        stateFocus: false,
        roleFocus: false,
        numberOfContacts: 1,

        clientAlignments: [],
        clients: [],
        branches: [],
        states: [],
        categories: [],
        billingCategories: [],
        clientNotes: [],

        roles: [{ value: '1', text: 'Role 1' }],
        client: utilities.initClient(),

        pagination: utilities.initPagination(),

        timelineData: utilities.initTimeline(),

        updateKey: true,

        initialDataConfiguration: null,
        loaded: false,
      }
    },
    watch: {
      client: {
        handler(value) {
          if (this.loaded) this.$store.dispatch('setNewObject', value)
        },
        deep: true,
      },
    },
    computed: {
      validateClientName() {
        if (this.client.name.trim() === '') {
          return false
        } else {
          if (utilities.validateInput(this.client.name)) {
            return true
          } else {
            return false
          }
        }
        return true
      },
      validateBranchName() {
        if (this.client.branchName.trim() === '') {
          this.branchError = 'Branch Name is required'
          return false
        } else if(this.branches.includes(this.client.name.trim() + " " + this.client.branchName.trim())) {
          this.branchError = 'This Branch Name already exists'
          return false
        } else {
          if (utilities.validateInput(this.client.branchName)) {
            return true
          } else {
            this.branchError = 'Please do not start with \'=\' or  \'-\'  or  \'+\' or  \'@\' or  \'%\' and do not contains \'--\' or \'||\' or \'@@\''
            return false
          }
        }
        return true
      },
      validateBillingCategory() {
        if (this.client.billingCategoryId == '') {
          return false
        }
        return true
      },
      validatePrimaryContact() {
        if (this.client.contacts.length == 0) {
            return false
        } else if (this.client.contacts.length > 0 && this.client.contacts[0].emailAddress == '') {
          return false
        }
        return true
      },

      primaryContactDetails() {
        // var primaryContact = this.client.contacts.find(
        //   (x) => x.primaryContact == true
        // )
        return utilities.getContactFormattedString(this.client.primaryContact == undefined ? this.client.contacts[0] : this.client.primaryContact)
      },
      clientAddress() {
        var address = ''
        if (this.client.addressLine1 != '') address += this.client.addressLine1 + ', '
        if (this.client.addressLine2 != '') address += this.client.addressLine2 + ', '
        if (this.client.city != '') address += this.client.city + ', '
        if (this.client.state != '') address += this.client.state + ' '
        if (this.client.postcode != '') address += this.client.postcode
        return address
      },
    },
    methods: {
      formatDateTime(date) {
        if (date) {
          return moment(date).format('DD/MM/YYYY hh:mm A')
        }
        return ''
      },

      editNote(index) {
        var note = this.clientNotes[index]
        this.$refs.addNoteModal.isEditClientNote = true
        this.$refs.addNoteModal.editClientNote(note, true)
        this.$refs.addNoteModal.handleModal()
      },

      async deleteNote(noteId, index) {
        var note = this.clientNotes[index]
        if (note.userId != this.user.id) {
          return
        }
        this.$confirm({
          content: 'Are you sure you want to delete this note?',
          onConfirm: async () => {
            this.$store.dispatch('loading/setLoadComponent', true)
            var params = {
              userID: this.user.id,
              userName: this.user.name,
              id: noteId,
            }
            var result = await utilities.deleteClientNoteById(params)
            if (result) {
              this.clientNotes.splice(index, 1)
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        })
      },

      async getClientNotes() {
        this.clientNotes = await utilities.getClientNotes(this.client.id)
      },

      openNotes() {
        $('.notes-content').show()
        $('.tab-selected').removeClass('tab-selected')
        $('.notes-heading').addClass('tab-selected')
        $('.files-content').hide()
      },
      handleNameClick() {
        this.nameFocus = true
      },
      handleBranchClick() {
        this.branchFocus = true
      },
      handleCategoryClick() {
        this.categoryFocus = true
        this.openCategorySelector()
      },
      handleAlignmentClick() {
        this.alignmentFocus = true
        this.openAlignmentSelector()
      },
      handleBillingCategoryClick() {
        this.billingCategoryFocus = true
        this.openAlignmentSelector()
      },
      handleStateClick() {
        this.stateFocus = true
      },
      handleXeroClientCodeClick() {
        this.xeroClientCodeFocus = true
      },
      handleRoleClick() {
        this.roleFocus = true
      },

      handleClientChange(v) {
        if (v && this.client.id !== 'new') {
          this.client.name = this.clients.find((x) => x.id == this.client.id).name
          this.branches = this.clients.filter(x => x.name == this.client.name && x.branchName != this.client.branchName).map(x => { return x.name.trim() + " " + x.branchName.trim() })
        } else if (!v) {
          this.client.id = null
          this.client.name = ''
          this.branches = []
        }
      },

      openManageContactModal() {
        if (this.pageMode == 'edit' && this.client.contacts.length !== 0) {
          this.$router.push({ name: 'admin-client-contacts', query: { clientId: this.client.id }}).catch((err) => {})
        } else if (this.pageMode == 'edit' && this.client.contacts.length === 0) {
          this.addNewContact()
        } else {
          this.client.contacts.find((x) => x.primaryContact == true)
          this.$refs.adminManageContactModal.setContacts(this.client.contacts)
          this.$refs.adminManageContactModal.handleModal()
        }
      },

      addNewContact() {
        this.$router.push({ name: 'admin-edit-client-contact', query: { clientId: this.client.id, } })
      },

      openAddNoteModal(e) {
        this.$refs.addNoteModal.addNoteToBooking()
        this.$refs.addNoteModal.handleModal()
      },

      async addNoteToBooking(note) {
        var newNote = {
          ClientID: this.client.id,
          Note: note,
          NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          UserID: this.user.id,
          Username: this.user.name,
          InternalOnly: 0,

        }
        var callback = (response) => {

        }
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.postClientNote(newNote, callback)
        this.clientNotes = await utilities.getClientNotes(this.client.id)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async openManageAddressModal(e) {
        e.preventDefault()
        e.stopPropagation()
        this.$refs.adminManageAddressModal.isClient = true
        this.$refs.adminManageAddressModal.handleModal()
      },

      pushContactToClient() {
        this.client.contacts.push({
          clientContactId: '',
          mobile: '',
          surname: '',
          canLogin: false,
          firstName: '',
          middleName: '',
          emailAddress: '',
          phoneNumber1: '',
          phoneNumber2: '',
          primaryContact: false,
          NotificationEmailAddress: '',
          Salutation: '',
          userRoleId: 1,
        })
        if (this.client.contacts.length == 1) {
          this.client.contacts[0].primaryContact = true
          this.client.contacts[0].canLogin = true
        }
      },

      validateContactFirstName(index) {
        // First name and email blank, don't validate this entry because we won't be sending it anyway
        if (this.client.contacts[index].firstName.trim() == '' && this.client.contacts[index].emailAddress.trim() == '') return true
        if (this.client.contacts[index].firstName.trim() == '') {
          return false
        }
        return true
      },
      validateContactEmail(index) {
        // First name and email blank, don't validate this entry because we won't be sending it anyway
        if (this.client.contacts[index].firstName.trim() == '' && this.client.contacts[index].emailAddress.trim() == '') return true
        if (this.client.contacts[index].emailAddress.trim() == '') {
          return false
        }
        return true
      },
      async submit(e) {
        e.preventDefault()
        e.stopPropagation()

        if (!this.validateClientName || !this.validateBranchName || !this.validateBillingCategory || !this.validateContactFirstName || !this.validatePrimaryContact) {
          this.hasError = true
          this.errorMessage = 'Please correct the errors shown above and try again.'
          utilities.showAlert('.alert-danger')
          return false
        } else {
          this.hasError = false
        }

        if (this.pageMode == 'create') {
          var clientToPost = {
            UserID: this.user.id,
            Username: this.user.name,
            ClientName: this.client.name,
            BranchName: this.client.branchName,
            PrepaymentRequired: utilities.convertBoolToNum(this.client.prepaymentRequired),
            PaymentDeferralOption: utilities.convertBoolToNum(this.client.paymentDeferralOption),
            RedHealthMayContactClaimants: utilities.convertBoolToNum(this.client.redHealthMayContactClaimants),
            BillingCategoryID: this.client.billingCategoryId,
            ClientCategory: this.client.clientCategory,
            AddressLine1: this.client.addressLine1,
            AddressLine2: this.client.addressLine2,
            City: this.client.city,
            State: this.client.state,
            Postcode: this.client.postcode,
            Country: this.client.country,
            ClientAlignment: this.client.clientAlignment,
            XeroClientCode: this.client.xeroClientCode,
            RedRescheduleEnabled: utilities.convertBoolToNum(this.client.redRescheduleEnabled),
          }
          if (this.client.contacts != undefined && this.client.contacts.length > 0 && this.pageMode != 'edit') {
            var localContacts = []
            this.client.contacts.map((x) => {
              var number = utilities.updateMobileCode(x.mobile)
              localContacts.push({
                Mobile: utilities.validatePhonenumber(number),
                Surname: x.surname,
                CanLogin: utilities.convertBoolToNum(x.canLogin),
                FirstName: x.firstName,
                MiddleName: x.middleName,
                EmailAddress: x.emailAddress,
                NotificationEmailAddress: x.NotificationEmailAddress,
                Salutation: x.Salutation,
                PhoneNumber1: utilities.validatePhonenumber(x.phoneNumber1),
                PhoneNumber2: utilities.validatePhonenumber(x.phoneNumber2),
                IsPrimaryContact: utilities.convertBoolToNum(x.primaryContact),
                UserRoleID: x.userRoleId,
              })
            })

            var canLogin = 0
            var conatc = localContacts.find((x) => x.CanLogin == 1)
            if (conatc) {
              canLogin = conatc.CanLogin
            }
            clientToPost.ContactObject = localContacts
          }
          console.log(this.client.contacts)
          this.postClient(clientToPost, canLogin)
        } else {
          var clientToPut = {
            UserID: this.user.id,
            Username: this.user.name,
            ClientID: this.client.id,
            ClientName: this.client.name,
            BranchName: this.client.branchName,
            PrepaymentRequired: utilities.convertBoolToNum(this.client.prepaymentRequired),
            PaymentDeferralOption: utilities.convertBoolToNum(this.client.paymentDeferralOption),
            RedHealthMayContactClaimants: utilities.convertBoolToNum(this.client.redHealthMayContactClaimants),
            BillingCategoryID: this.client.billingCategoryId,
            ClientCategory: this.client.clientCategory,
            AddressLine1: this.client.addressLine1,
            AddressLine2: this.client.addressLine2,
            City: this.client.city,
            State: this.client.state,
            Postcode: this.client.postcode,
            Country: this.client.country,
            ClientAlignment: this.client.clientAlignment,
            XeroClientCode: this.client.xeroClientCode,
            RedRescheduleEnabled: utilities.convertBoolToNum(this.client.redRescheduleEnabled),
          }
          if(this.client.zohoLink != '') {
            var zohoId = this.client.zohoLink.split("/")
            zohoId = zohoId[zohoId.length - 1]
            clientToPut.ZohoLink = zohoId
          }
          // if (
          //   this.client.contacts != undefined &&
          //   this.client.contacts.length > 0
          // ) {
          //   var localContacts = []
          //   this.client.contacts.map((x) => {
          //     var localContact = {
          //       Mobile: x.mobile,
          //       Surname: x.surname,
          //       CanLogin: utilities.convertBoolToNum(x.canLogin),
          //       FirstName: x.firstName,
          //       MiddleName: x.middleName,
          //       EmailAddress: x.emailAddress,
          //       PhoneNumber1: x.phoneNumber1,
          //       PhoneNumber2: x.phoneNumber2,
          //       PrimaryContact: utilities.convertBoolToNum(x.primaryContact),
          //       UserRoleID: x.userRoleId,
          //     }
          //     if (x.clientContactId !== undefined && x.clientContactId !== '')
          //       localContact.ClientContactID = x.clientContactId
          //     localContacts.push(localContact)
          //   })
          //   clientToPut.ContactObject = localContacts
          // }
          this.putClient(clientToPut)
        }
        this.$store.dispatch('setOriginalObject', this.client)
        this.$store.dispatch('setNewObject', this.client)
      },

      async postClient(client, canLogin) {
        this.$store.dispatch('loading/setLoadComponent', true)
        // var callback = (response) => {
        //   this.client.id = response
        //   // this.rateCard.specialist.id = response
        // }
        var result = await utilities.postClient(client)
        if (result) {
          this.client.id = result
          await utilities.delay(1000)
          console.log(this.client.contacts)
          await this.callPostClientContactToZoho()
          if (canLogin) {
            this.successMessage = 'Client created, and the new contacts have been invited to Red Health Online.'
          } else {
             this.successMessage = 'Client created successfully'
          }

          utilities.showAlert('.alert-success')
          setTimeout(() => {
            this.$router.push({name:'admin-manage-client', query:{id:this.client.id}})
          },2000)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async putClient(client) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var callback = (response) => {
          // this.rateCard.specialist.id = response
          if (response) {
            this.successMessage = 'Client updated, and any new contacts have been invited to Red Health Online.'
            utilities.showAlert('.alert-success')
          }
        }
        await utilities.putClient(client, callback)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async callPostClientContactToZoho() {
        this.client = await utilities.getClientById(this.client.id)
        //var contacts = await utilities.getClientContacts(this.client.id)
        var zohoLink = ''
        if (this.client.zohoLink) {
          zohoLink = this.client.zohoLink.split('/')[this.client.zohoLink.split('/').length - 1]
        }
        console.log(this.client.contacts)
        for (var i = 0; i < this.client.contacts.length; i++) {
          var contact = this.client.contacts[i]
          var params = {
            FirstName: contact.firstName,
            Surname: contact.surname,
            EmailAddress: contact.emailAddress,
            Mobile: contact.mobile,
            PhoneNumber1: contact.phoneNumber1,
            PhoneNumber2: contact.phoneNumber2,
            NotificationEmailAddress: contact.NotificationEmailAddress,
            Salutation: contact.Salutation,
            UserID: this.user.id,
            Username: this.user.name,
            ClientZohoLink: zohoLink,
            ClientContactID: contact.clientContactId,
            ClientID: this.client.id,
            Status: 1
          }
          await utilities.postClientContactToZoho(params)
        }
      },

      async loadClient() {
        this.client = await utilities.getClientById(this.client.id)
        console.log(this.client)
        this.getClientNotes()
        if (this.client.id != '') var clientLogs = await utilities.getClientLogsById(this.client.id)

        var timeline = {}
        this.timelineData = []
        clientLogs.map((x) => {
          timeline = {
            id: x.clientLogId,
            userName: x.userName,
            datetime: utilities.formatDate(x.logDate, 'DD/MM/YYYY hh:ss a'),
            body: x.logDetails,
          }
          this.timelineData.push(timeline)
        })
      },
      async loadData() {
        const apiArr = [utilities.getClients(), utilities.getBillingCategories()]
        return new Promise(resolve => {
          Promise.all(apiArr).then(r => {
            this.clients = r[0]
            this.clients.sort((a, b) => {
              return a.name > b.name ? 1 : -1
            })
            this.branches = this.clients.filter(x => x.name == this.client.name && x.branchName != this.client.branchName).map(x => { return x.name.trim() + " " + x.branchName.trim() })
            this.clientAlignments = utilities.getClientAlignments()
            this.categories = utilities.getClientCategories()
            this.states = utilities.getStates()
            this.billingCategories = r[1]
            resolve()
          })
        })
      },

      openCategorySelector() {
        setTimeout(() => {
          $('.category').keydown((e) => {
              var code = e.keyCode || e.which;
              if (code === 9) {
                this.alignmentFocus = true
                this.openAlignmentSelector()
              }
          });
        }, 1000)
      },
      openAlignmentSelector() {
        setTimeout(() => {
          $('.alignment').keydown((e) => {
              var code = e.keyCode || e.which;
              if (code === 9) {
                this.billingCategoryFocus = true
              }
          });
        }, 1000)
      }

    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      this.pageMode = this.$route.query.id != undefined ? 'edit' : 'create'
      this.client.id = this.$route.query.id != undefined ? this.$route.query.id : ''

      if(this.client.id != '') {
        var type = this.$route.query != undefined && this.$route.query.type != undefined ? this.$route.query.type : ''
        if (type == 'add-contact') {
          this.addNewContact()
        } else if (type == 'edit-contact') {
          this.openManageContactModal()
        }
      }

      if (this.pageMode == 'edit') await this.loadClient()
      await this.loadData()
      this.$store.dispatch('setOriginalObject', this.client)
      this.$store.dispatch('setNewObject', this.client)
      this.loaded = true
      utilities.initTooltip
      this.$store.dispatch('loading/setLoadComponent', false)

      $('.branch').keydown((e) => {
          var code = e.keyCode || e.which;
          if (code === 9) {
            this.handleCategoryClick()
            this.openCategorySelector()
          }
      });

      setTimeout(() => {
        $('.category').keydown((e) => {
            var code = e.keyCode || e.which;
            if (code === 9) {
              this.alignmentFocus = true
            }
        });
      }, 500)

      $('.alignment').keydown((e) => {
          var code = e.keyCode || e.which;
          if (code === 9) {
            this.handleBillingCategoryClick()
            this.openAlignmentSelector()
          }
      });
      $('.xero').keydown((e) => {
          var code = e.keyCode || e.which;
          if (code === 9) {
            this.openManageAddressModal(e)
          }
      });
    },
    mounted() {
      this.openNotes()
      // document.querySelector('.action-log').style.height = document.querySelector('.content .row .col-9 .box').offsetHeight+'px'
      // document.querySelector('.action-log').style.overflowY = 'auto'
    },
  }
</script>
