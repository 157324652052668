/* eslint-disable */
<template>
  <div class="next-availability-specialty">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Next Availability by Specialty</h1></div>
        <div class="header-sub-title">Choose a Specialty and / or Location to view the next available dates & location, in date order.</div>
      </div>
    </div>
    <div class="panel">
      <AdminAvailabilityMenu indexProps="1" />
      <div class="content">
        <div class="box">
          <div class="box-heading"><span>Search</span></div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Choose Specialty</div>
              <div class="box-col box-col-right">
                <select class="textbox" v-model="searchOption.specialtyId" @change="changeSpecialty">
                  <option hidden value="">Please choose ...</option>
                  <option v-for="(specialty, index) in specialties" :value="specialty.id">{{ specialty.name }}</option>
                </select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Choose Region / Location</div>
              <div class="box-col box-col-right">
                <treeselect ref="treeSelect"
                  class="w-50"
                  :class="searchOption.specialtyId == '' ? 'disabled-element' : ''"
                  :disabled="searchOption.specialtyId == '' ? true : false"
                  :multiple="false"
                  :searchable="false"
                  :closeOnSelect="false"
                  :options="regionLocations"
                  :limit="7"
                  :max-height="200"
                  :defaultExpandLevel="0"
                  placeholder="Please choose ..."
                  noOptionsText="No regions"
                  noChildrenText="No cities"
                  v-model="searchOption.regionId"
                  @select="changeRegion" />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 25%" class="long-text">Specialist<img class="sort-icon" :src="!bySpecialist ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortSpecialist" /></th>
                    <th style="width: 15%" class="long-text">Date<img class="sort-icon" :src="!byDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDate" /></th>
                    <th style="width: 10%" class="long-text">Next Available<img class="sort-icon" :src="!byTime ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortTime" /></th>
                    <th style="width: 15%" class="long-text">No of Appointments<img class="sort-icon" :src="!byAppointment ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortAppointment" /></th>
                    <th style="width: 30%" class="long-text">Location<img class="sort-icon" :src="!byLocation ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortLocation" /></th>
                    <th style="width: 5%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="availabilities.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(availability, index) in availabilities" class="is-icon">
                    <td style="width: 25%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ availability.fullName }}
                    </td>
                    <td style="width: 15%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ formatDate(availability.availabilityDate) }}
                    </td>
                    <td style="width: 10%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ formatDate(availability.nextAvailableTime, "hh:mm A", "HH:mm:ss") }}
                    </td>
                    <td style="width: 15%" class="long-text">
                      <div class="w-50">
                        {{ availability.numberTimeSlots - availability.numberTimeSlotsBooked }}
                        <div v-if="availability.videoLinkAvailable == 2" class="is-icon right-float-element" data-placement="right" data-toggle="tooltip" title="Video Link Available">
                          <img src="@/assets/images/video_black_icon.svg" />
                        </div>
                      </div>
                    </td>
                    <td style="width: 30%" class="long-text" @click="openAvailability(availability.specialistId)">
                      {{ availability.fullLocation }}
                    </td>
                    <td style="width: 5%" class="icon-col">
                      <div class="icon-group">
                        <router-link :to="{ name: 'admin-availability-bookings', query: { id: availability.specialistId, date: availability.availabilityDate } }">
                          <img src="@/assets/images/calendar_black_icon.svg" data-placement="right" data-toggle="tooltip" title="View bookings" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''"
                  v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminAvailabilityMenu from "@/components/AdminAvailabilityMenu"
import Header from "@/components/Header"

export default {
  name: "AdminNextAvailabilitySpecialty",
  components: {
    AdminNavigation,
    AdminAvailabilityMenu,
    Header,
  },
  data() {
    return {
      searchOption: {
        specialtyId: "",
        regionId: null,
      },

      availabilities: [],
      allAvailabilities: [],
      limit: 10,
      pagination: utilities.initPagination(),

      specialties: [],
      regions: [],
      locations: [],
      regionLocations: [],

      bySpecialist: false,
      byDate: false,
      byTime: false,
      byAppointment: false,
      byLocation: false,
    }
  },
  computed: {},
  methods: {
    formatDate(date, newFormat = "DD/MM/YYYY", currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(date, newFormat, currentFormat)
    },
    selectPage(index) {
      this.pagination.current = index
      this.availabilities = this.pagination.list[this.pagination.current]
    },
    sortSpecialist() {
      this.allAvailabilities.sort((a, b) => {
        if(this.bySpecialist) {  // ASC
          return a.fullName > b.fullName ? 1 : -1
        } else { // DESC
          return a.fullName < b.fullName ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.bySpecialist = !this.bySpecialist
    },
    sortDate() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byDate) {  // ASC
          return moment(a.availabilityDate) > moment(b.availabilityDate) ? 1 : -1
        } else { // DESC
          return moment(a.availabilityDate) < moment(b.availabilityDate) ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byDate = !this.byDate
    },
    sortTime() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byTime) {  // ASC
          return moment(a.startTime) > moment(b.startTime) ? 1 : -1
        } else { // DESC
          return moment(a.startTime) < moment(b.startTime) ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byTime = !this.byTime
    },
    sortAppointment() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byAppointment) {  // ASC
          return a.numberTimeSlotsBooked > b.numberTimeSlotsBooked ? 1 : -1
        } else { // DESC
          return a.numberTimeSlotsBooked < b.numberTimeSlotsBooked ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byAppointment = !this.byAppointment
    },
    sortLocation() {
      this.allAvailabilities.sort((a, b) => {
        if(this.byLocation) {  // ASC
          return a.fullLocation > b.fullLocation ? 1 : -1
        } else { // DESC
          return a.fullLocation < b.fullLocation ? 1 : -1
        }
      })
      this.paging(this.allAvailabilities)
      this.byLocation = !this.byLocation
    },
    openAvailability(id) {
      this.$router.push({ name: 'admin-manage-availability', query: { id: id } })
    },
    paging(availabilities) {
      utilities.disposeTooltip()
      if(availabilities.length == 0 || availabilities.length <= this.limit) {
        this.availabilities = availabilities
        $(".pagination").hide()
      } else {
        this.pagination = utilities.loadPagination(availabilities, this.limit)
        this.availabilities = this.pagination.list[this.pagination.current]
        $(".pagination").show()
      }
      utilities.initTooltip()
    },
    async changeSpecialty() {
      this.$store.dispatch("loading/setLoadComponent", true)

      var params = {
        StartDate: moment().format("YYYY-MM-DD")
      }

      if(this.searchOption.regionId != null) {
        if(this.searchOption.regionId.includes("r")) params.RegionID = this.searchOption.regionId.replace("r", "")
        if(this.searchOption.regionId.includes("c")) params.CityID = this.searchOption.regionId.replace("c", "")
      }

      var availabilities = await utilities.getSpecialistAvailabilities("", this.searchOption.specialtyId, params)
      this.allAvailabilities = _.cloneDeep(availabilities)
      this.paging(availabilities)

      this.$store.dispatch("loading/setLoadComponent", false)
    },
    changeRegion(item, e) {
      if (item.id.includes('s')) {  // Disable select state
        this.$refs.treeSelect.clear()
        var index = this.regionLocations.findIndex(x => x.id == item.id)
        if(index > -1) {
          var mouseEvent = document.createEvent("MouseEvents")
          mouseEvent.initMouseEvent("mousedown", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
          $(".vue-treeselect__option[data-id*='s'] .vue-treeselect__option-arrow-container")[index].dispatchEvent(mouseEvent)
        }
      } else {
        this.$refs.treeSelect.closeMenu()

        this.$store.dispatch("loading/setLoadComponent", true)

        setTimeout(async () => {
          var params = {
            StartDate: moment().format("YYYY-MM-DD")
          }

          if(this.searchOption.regionId != null) {
            if(this.searchOption.regionId.includes("r")) params.RegionID = this.searchOption.regionId.replace("r", "")
            else if(this.searchOption.regionId.includes("c")) params.CityID = this.searchOption.regionId.replace("c", "")
          }

          var availabilities = await utilities.getSpecialistAvailabilities("", this.searchOption.specialtyId, params)
          this.allAvailabilities = _.cloneDeep(availabilities)
          this.paging(availabilities)

          this.$store.dispatch("loading/setLoadComponent", false)
        }, 300)
      }
    },

    async loadData() {
      this.specialties = await utilities.getQualifications()
      this.regions = await utilities.getRegions()
      this.locations = await utilities.getLocations()
      this.regionLocations = await utilities.getRegionLocations(this.regions, this.locations)
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $('body>.tooltip').remove()
    await this.loadData()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
