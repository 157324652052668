<template>
  <div class="purchase-order-item-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>{{ itemProps.id == '' ? 'Create' : 'Edit' }} Purchase Order Item</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="box-body">
                  <div class="box-row">
                    <div class="box-col box-col-left">Date</div>
                    <div class="box-col box-col-right">
                      <datepicker v-model="itemProps.dateAdded" format="dd/MM/yyyy" :typeable="true" class="w-50" style="left: -10px;" placeholder="dd/mm/yyyy"></datepicker>
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">Item Code</div>
                    <div class="box-col box-col-right">
                      <input type="text" v-model="itemProps.itemCode" class="w-50" placeholder="Enter purchase order item code" />
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">Description</div>
                    <div class="box-col box-col-right">
                      <input type="text" v-model="itemProps.description" class="w-50" placeholder="Enter purchase order item description" />
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">Amount</div>
                    <div class="box-col box-col-right">
                      <input type="text" v-model="itemProps.amount" class="w-50" placeholder="Enter amount" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> Please correct the errors shown above and try again.
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="save">Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminPurchaseOrderItemModal',
    props: ['itemProps'],
    data() {
      return {
        loadModal: false,
        hasError: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        item: utilities.initPurchaseOrderItem(),
        loaded: false,
      }
    },
    computed: {},
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      async save() {
        this.$store.dispatch("loading/setLoadComponent", true)
        // VALIDATION
        // Post
        var params = {
          ItemCode: this.itemProps.itemCode,
          BookingPurchaseOrderID: this.itemProps.bookingPurchaseOrderId,
          DateAdded: moment(this.itemProps.dateAdded).format('YYYY-MM-DD'),
          Amount: Number(this.itemProps.amount),
          PurchaseOrderItemDescription: this.itemProps.description,
          UserID: this.user.id,
          Username: this.user.name,
        }
        if (this.itemProps.id == '') {
          var res = await utilities.postPurchaseOrderItem(params)
          if (res) {
            this.$parent.getUpdatedPurchaseOrder()
            this.handleModal()
          }
        }
        // Put
        else {
          params.PurchaseOrderItemID = this.itemProps.id
          var res = await utilities.putPurchaseOrderItem(params)
          if (res) {
            this.$parent.getUpdatedPurchaseOrder()
            this.handleModal()
          }
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      },
    },
    async beforeMount() {},
    mounted() {},
    updated() {
      if (!this.loaded) {
        this.item = this.itemProps
        this.loaded = true
      }
    },
  }
</script>
