/* eslint-disable */
<template>
  <div class="availability-bookings">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Add/Edit Specialist Routine Availability {{ specialistAvailability.specialist.id != '' ? ' - ' + specialistAvailability.specialist.fullName : '' }}</h1></div>
        <div class="header-sub-title">Add or Edit specialists routines and confirm{{ specialistAvailability.specialist.id != '' ? ' for ' + specialistAvailability.specialist.fullName : '' }}.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistMenu indexProps="1" />
      <div class="content">
        <div class="year-calendar">
          <div class="box">
            <div class="box-heading">
              <span>Current Availability ({{ specialistAvailability.numberTimeSlots - specialistAvailability.numberTimeSlotsBooked + '/' + specialistAvailability.numberTimeSlots }} appointment remaining)</span>
              <div class="icon-group right-element">
                <v-btn v-if="specialistAvailability && specialistAvailability.dataSourceAvailabilities.length > 0" class="right-float-element" dark @click="handleBulkUpdate">Bulk update</v-btn>
                <div><img :class="specialistAvailability.specialist.id == '' ? 'disabled-element' : ''" src="@/assets/images/file_icon.svg" @click="exportPdf" data-toggle="tooltip" data-placement="right" title="Export appointments to PDF" /></div>
              </div>
              <vue-html2pdf
                v-if="specialistAvailability.specialist.id != ''"
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="SpecialistSchedule"
                :pdf-quality="1"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="297mm"
                ref="html2Pdf"
                @hasDownloaded="hasDownloaded">
                <section slot="pdf-content">
                  <SpecialistSchedulePdf ref="specialistSchedulePdf" />
                </section>
              </vue-html2pdf>
            </div>
            <div class="box-body">
              <HorizontalYearCalendar
                ref="horizontalYearCalendar"
                :key="calendarKey"
                :calendarDateProps="formatDate(specialistAvailability.calendarDate, 'YYYY')"
                :dataSourceProps="specialistAvailability.dataSourceAvailabilities"
                :buttonProps="showButtons"
                pathProps="admin-availability-bookings"
              />

              <EditRoutineAvailabilityModal :specialistId="specialistAvailability.specialist.id" ref="editRoutineAvailabilityModal" :clients="clients" :locations="locations" availabilityProp="editAvailability" :dataSourceProps="specialistAvailability.dataSourceAvailabilities"/>
            </div>
          </div>

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>
        </div>

        <AddRoutineAvailability
          ref="addRoutineAvailability"
          :key="routineAvailabilityKey"
          :locations="locations"
          :clients="clients"
          :specialistIdProps="specialistAvailability.specialist.id"
          :specialistRequestProps="specialistAvailability.specialist.byRequestOnly"
          :specialistNameProps="specialistAvailability.specialist.fullName"
          :availabilitiesProps="specialistAvailability.availabilities" />
        <br />

        <div class="row">
          <div class="col">
            <div class="box">
              <div class="box-heading">
                <span>Notes</span>
                <div v-if="loaded && this.specialistAvailability.specialist.id != ''" class="right-element is-icon" @click="openAvailabilityNoteModal">
                  <v-btn v-if="specialistAvailability.specialist.availabilityNote.id == ''" class="add-icon" dark>Add New</v-btn>
                  <img v-else src="@/assets/images/booking_action_black_icon.svg" />
                </div>
                <AdminManageAvailabilityNoteModal ref="manageAvailabilityNoteModal" :specialistIdProps="specialistAvailability.specialist.id" />
              </div>
              <div class="box-body">
                <div class="box-row">
                  <!-- <div class="box-col">{{ specialistAvailability.specialist.availabilityNote.addedByName }}</div>
                  <div class="box-col">{{ formatDate(specialistAvailability.specialist.availabilityNote.dateAdded, "DD/MM/YYYY hh:mm A") }}</div> -->
                  <div class="box-col">{{ specialistAvailability.specialist.availabilityNote.note }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 action-log d-inline-block m-0 p-0" style="vertical-align:top; height: 620px;">
            <div style="display: flex;flex-direction: row;">
              <span style="line-height: 3; padding-left: 20%">Booking Log</span>
              <select @change="updateBookingLog($event)" class="ml-3 small-selector">
                <option value="0">Recent</option>
                <option value="1">View All</option>
              </select>
            </div>
            <Timeline :timeline="specialistAvailability.timelineData" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="Bulk Update"
        :visible.sync="showBulkUpdate"
        @close="cancelDialog"
        width="80%">
      <div class="box-row">
        <div class="box-col box-col-left">Select Date time</div>
        <div class="box-col box-col-right d-flex">
          <div class="w-50">
            <el-date-picker
                v-model="bulkEditOption.dateDuration"
                type="daterange"
                popper-class="date-range-picker-popper"
                range-separator="~"
                start-placeholder="Start Date"
                end-placeholder="End Date">
            </el-date-picker>
          </div>
          <div class="w-50 d-flex justify-content-between align-items-center">
            <vue-timepicker ref="timePicker" v-model="bulkEditOption.startTime"
                            format="hh:mm A"
                            input-class="form-control"
                            :minute-interval="5"
                            placeholder="HH:MM">

            </vue-timepicker>
            <v-btn @click="searchAvailability" v-if="bulkEditOption.dateDuration" dark>Search</v-btn>
          </div>
        </div>
      </div>
      <div v-if="filteredAvailabilities.length > 0" style="line-height: 1">
        <el-table @selection-change="handleSelectionChange" height="400" class="w-100" size="mini" :data="filteredAvailabilities" tooltip-effect="dark" :header-row-style="{background: '#fde9eb'}" :header-cell-style="{fontWeight: 100}">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              width="80"
              show-overflow-tooltip
              label="id"
              prop="AvailabilityID"
          >
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="Date"
              prop="date"
          >
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="Start Time"
              prop="StartTime"
          >
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="End Time"
              prop="EndTime"
          >
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              label="Location"
          >
            <template slot-scope="scope">
              <span>{{decodeURIComponent(scope.row.Location.LocationName)}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              show-overflow-tooltip
              label="Specialist"
          >
            <template>
              <span>{{specialistAvailability.specialist.fullName}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              show-overflow-tooltip
              label="Status"
              prop="BookingStatus"
          >
            <template slot-scope="scope">
              <div class="calender-indicator" :class="getBookingStatus(scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              label="Options"
              width="180"
          >
            <template slot-scope="scope">
              <img v-if="scope.row.Options.VideoOnly" class="is-icon mr-2" :src="require('@/assets/images/video_black_icon.svg')" data-toggle="tooltip" data-placement="right" title="Only Allow Video" />
              <img v-if="scope.row.Options.VideoLinkAvailable" class="is-icon mr-2" style="height: 30px" :src="require('@/assets/images/user_video_icon_black.svg')" data-toggle="tooltip" data-placement="right" title="Video Link available" />
              <img v-if="scope.row.Options.ByRequestOnly" class="is-icon mr-2" :src="require('@/assets/images/phone_black_icon.svg')" data-toggle="tooltip" data-placement="right" title="By Request only" />
              <img v-if="scope.row.Options.ShowWorkCover" data-toggle="tooltip" data-placement="right" title="Show WorkCover"  class="is-icon mr-2" style="height: 26px" :src="require('./../assets/images/work_cover.svg')"/>
              <img v-if="scope.row.Options.ShowSpecificClients" class="is-icon mr-2" style="height: 26px"
                   :src="require('./../assets/images/specific_users.svg')"/>
            </template>
          </el-table-column>
        </el-table>
        <div class="d-flex justify-content-end mt-4">
          <div class="d-flex align-items-center">
            <div class="calender-indicator calender-submited"></div>
            <div class="indicator-text">Unconfirmed</div>
          </div>
          <div  class="d-flex align-items-center">
            <div class="calender-indicator calender-approved"></div>
            <div class="indicator-text">Available</div>
          </div>
          <div  class="d-flex align-items-center">
            <div class="calender-indicator calender-fullbook"></div>
            <div class="indicator-text">Booked</div>
          </div>
          <div  class="d-flex align-items-center">
            <div class="calender-indicator calender-onhold"></div>
            <div class="indicator-text">Oh hold</div>
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left d-flex align-items-center">
          Location
        <el-switch
            class="ml-2"
            v-model="bulkEditOption.showLocation"
            active-color="#13ce66"
            inactive-color="#E0E0E0">
        </el-switch>
      </div>
        <div class="box-col box-col-right d-flex">
          <div class="w-100">
            <el-select v-show="bulkEditOption.showLocation" :popper-append-to-body="false" popper-class="search-specialty-popup" clearable filterable class="w-100"
                       placeholder="Please choose" :class="hasError && !validateLocation ? 'is-invalid' : ''"
                       v-model="bulkEditOption.LocationID">
              <el-option v-for="(location, index) in locations" :value="location.id" :key="index"
                         :label="location.fullLocation">{{ location.fullLocation }}
              </el-option>
            </el-select>
            <div v-if="hasError && !validateLocation" class="text-danger">Location is required.</div>
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left">
          Select Type of Booking/Travel Required
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showOptions"
              active-color="#13ce66"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col box-col-right d-flex">
          <div v-if="bulkEditOption.showOptions" class="w-50 px-5">
            <img class="is-icon" :src="bulkEditOption.Options.VideoOnly ? require('@/assets/images/video_black_icon.svg') : require('@/assets/images/video_icon.svg')"
                 @click="changeOnlyAllowVideo" data-toggle="tooltip" data-placement="right" title="Only Allow Video" />
            <img class="is-icon pl-3" style="height: 30px" :src="bulkEditOption.Options.VideoLinkAvailable ? require('@/assets/images/user_video_icon_black.svg') : require('@/assets/images/user_video_icon.svg')"
                 @click="changeVideoLinkAvailable" data-toggle="tooltip" data-placement="right" title="Video Link available" />
            <img class="is-icon pl-3" :src="bulkEditOption.Options.ByRequestOnly ? require('@/assets/images/phone_black_icon.svg') : require('@/assets/images/phone_icon.svg')"
                 @click="changeByRequestOnly" data-toggle="tooltip" data-placement="right" title="By Request only" />
          </div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col w-25">
          Start Time
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showStartTime"
              active-color="#13ce66"
              @change="toggleStartTime"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col w-25">
          <vue-timepicker v-if="bulkEditOption.showStartTime" v-model="bulkEditOption.updateStartTime"
                          format="hh:mm A"
                          class="w-100"
                          input-class="form-control"
                          :allowed-minutes="allowedStep"
                          placeholder="HH:MM"></vue-timepicker>
          <div v-if="hasError && !validateStartTime" class="text-danger">{{ startTimeMessage }}</div>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col box-col-left">
          Appointment Duration
          <el-switch
              class="ml-2"
              v-model="bulkEditOption.showDuration"
              active-color="#13ce66"
              inactive-color="#E0E0E0">
          </el-switch>
        </div>
        <div class="box-col box-col-right">
          <el-input-number v-show="bulkEditOption.showDuration" class="number-input" v-model="bulkEditOption.Duration" :step="5" :min="5"
                           :max="1000"></el-input-number>
          <span v-show="bulkEditOption.showDuration" class="ms-2">mins</span>
        </div>
      </div>
      <div class="box-row">
        <div class="box-col w-25">
          Visibility Configuration
        </div>
        <div class="box-col w-70">
          <div>
            <el-switch
                class="ml-2"
                active-text="Change WorkCover"
                inactive-text="Keep WorkCover Default"
                v-model="bulkEditOption.showWorkCover"
                active-color="#13ce66"
                inactive-color="#E0E0E0">
            </el-switch>
            <el-tooltip v-show="bulkEditOption.showWorkCover"
                        :content="bulkEditOption.Options.ShowWorkCover ? 'Show to everyone INCLUDING WorkCover QLD' : 'Show to everyone EXCEPT WorkCover QLD'">
              <img @click="changeShowWorkCover" class="is-icon ml-3" style="height: 26px"
                   :src="bulkEditOption.Options.ShowWorkCover ? require('./../assets/images/work_cover.svg') : require('./../assets/images/work_cover_gray.svg')"/>
            </el-tooltip>
          </div>
          <div>
            <el-switch
                class="ml-2"
                active-text="Change Clients"
                inactive-text="Keep Clients Default"
                v-model="bulkEditOption.showClients"
                active-color="#13ce66"
                inactive-color="#E0E0E0">
            </el-switch>
            <el-tooltip
                v-if="bulkEditOption.showClients"
                :content="bulkEditOption.Options.ShowSpecificClients ? 'Show only to specific clients' : 'Show for all clients'">
              <img @click="changeShowSpecificClients" class="is-icon ml-3" style="height: 26px"
                   :src="bulkEditOption.Options.ShowSpecificClients ? require('./../assets/images/specific_users.svg') : require('./../assets/images/specific_users_gray.svg')"/>
            </el-tooltip>
            <el-select clearable class="w-100" v-if="bulkEditOption.Options.ShowSpecificClients && bulkEditOption.showClients"
                       v-model="bulkEditOption.ClientIDs" multiple filterable
                       placeholder="Hide from everyone EXCEPT these specific clients">
              <el-option v-for="item in clients" :key="item.id" :value="item.id"
                         :label="item.fullName"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer d-flex justify-content-between">
        <div>
          <v-btn dark @click="bulkApprove" >Approve</v-btn>
          <v-btn dark class="ml-3" @click="bulkReject" >Reject</v-btn>
        </div>
        <div>
          <v-btn dark @click="bulkUpdate" >Update</v-btn>
         <v-btn class="ml-3" dark @click="bulkDelete" >Delete</v-btn>
         <el-button @click="cancelDialog" class="ml-3" type="text">Cancel</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSpecialistMenu from '@/components/AdminSpecialistMenu'
  import AddRoutineAvailability from '@/components/AddRoutineAvailability'
  import HorizontalYearCalendar from '@/components/HorizontalYearCalendar'
  import SpecialistSchedulePdf from '@/components/SpecialistSchedulePdf'
  import AdminManageAvailabilityNoteModal from '@/components/AdminManageAvailabilityNoteModal'
  import Timeline from '@/components/Timeline'
  import Header from '@/components/Header'
  import EditRoutineAvailabilityModal from '@/components/EditRoutineAvailabilityModal'

  export default {
    name: 'AdminManageAvailability',
    components: {
      AdminNavigation,
      AdminSpecialistMenu,
      AddRoutineAvailability,
      HorizontalYearCalendar,
      SpecialistSchedulePdf,
      AdminManageAvailabilityNoteModal,
      EditRoutineAvailabilityModal,
      Timeline,
      Header,
    },
    data() {
      return {
        startTimeMessage: '',
        bulkEditOption: {
          showWorkCover: false,
          showClients: false,
          showLocation: false,
          showOptions: false,
          showDuration: false,
          showStartTime: false,
          dateDuration: '',
          startTime: '',
          LocationID: null,
          updateStartTime: '',
          Duration: 5,
          ClientIDs: [],
          Options: {
            ShowWorkCover: 0,
            ShowSpecificClients: 0,
            VideoLinkAvailable: 0,
            VideoOnly: 0,
            ByRequestOnly: 0,
          }
        },
        clients: [],
        value1: '',
        showBulkUpdate: false,
        hasError: false,
        successMessage: '',
        errorMessage: '',
        showButtons: false,
        locations: [],
        filteredAvailabilities: [],
        selectedAvailabilities: [],
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        specialistAvailability: {
          specialist: utilities.initSpecialist(),
          calendarDate: moment().format('YYYY-MM-DD'),
          availabilities: [],
          dataSourceAvailabilities: [],
          numberTimeSlotsBooked: 0,
          numberTimeSlots: 0,
          timelineData: [],
        },
        availability: utilities.initAvailability(),
        editAvailability: [],
        appointmentLengths: [],
        shortAppointmentLengths: [],
        draftAvailabilities: [],

        routineAvailabilityKey: 0,
        calendarKey: 0,
        allLogs: [],
        loaded: false,
        selectedYear: '',
      }
    },
    computed: {
      validateDuration() {
        if(!this.bulkEditOption.Duration && this.bulkEditOption.showDuration) {
          return false
        }
        return true
      },
      validateLocation() {
        if(this.bulkEditOption.LocationID == "" && this.bulkEditOption.showLocation) {
          return false
        }
        return true
      },
      validateStartTime() {
        if(this.bulkEditOption.updateStartTime == "" && this.bulkEditOption.showStartTime) {
          this.startTimeMessage = "Start Time is required."
          return false
        } else if(!moment(moment().format("YYYY-MM-DD") + " " + this.availability.StartTime, "YYYY-MM-DD hh:mm A").isValid() && this.bulkEditOption.showStartTime) {
          this.startTimeMessage = "Start Time is invalid."
          return false
        }
        return true
      },
    },
    methods: {
      refreshFilterArray() {
        this.selectedAvailabilities = []
        this.searchAvailability()
      },
      async bulkApprove() {
        if(!this.selectedAvailabilities || this.selectedAvailabilities.length === 0) return
        const param = {
          UserIDLog: this.user.id,
          UsernameLog: this.user.name,
          SpecialistID: this.availability.SpecialistID,
          AvailabilityIDs: this.selectedAvailabilities.map(item => item.AvailabilityID)
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkApprove(param)
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Availability Approved' +
              '          </div>',
        });
        await this.reload()
        this.refreshFilterArray()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async bulkReject() {
        if(!this.selectedAvailabilities || this.selectedAvailabilities.length === 0) return
        const param = {
          UserIDLog: this.user.id,
          UsernameLog: this.user.name,
          SpecialistID: this.availability.SpecialistID,
          AvailabilityIDs: this.selectedAvailabilities.map(item => item.AvailabilityID)
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkReject(param)
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Availability Rejected' +
              '          </div>',
        });
        await this.reload()
        this.refreshFilterArray()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      toggleStartTime(v) {
        if(v) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-warning">' +
                '            <b>Warning:</b> Make sure that selected availabilities are not in the same day' +
                '          </div>',
          });
        }
      },
      getBookingStatus(row) {
        if(row.BookingStatus === 'A' && row.AvailabilityStatus === '2') {
          return 'calender-approved'
        } else if (row.BookingStatus === 'B') {
          return 'calender-fullbook'
        } else if (row.AvailabilityStatus === '1') {
          return 'calender-submited'
        }
        return 'calender-onhold'
      },
      async bulkDelete() {
        this.$confirm({
          content: 'We will only delete the Availabilities that have not been Booked.',
          onConfirm: async () => {
            this.$store.dispatch('loading/setLoadComponent', true)
            var result = await utilities.batchDeleteAvailability({AvailabilityIDs: this.selectedAvailabilities.map(item => item.AvailabilityID), UserIDLog: this.user.id, UsernameLog: this.user.name, SpecialistID: this.specialistAvailability.specialist.id})
            if (result) {
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: '<div class="alert-success">' +
                    '            <b>Success:</b> Availabilities deleted' +
                    '          </div>',
              });
              this.cancelDialog()
              await this.reload()
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        })
      },
      allowedStep(m) {
        return m % 5 === 0
      },
      async bulkUpdate() {
        this.hasError = false
        if (!this.validateLocation || !this.validateStartTime || !this.validateDuration) {
          this.hasError = true
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-error">' +
                '            <b>Error:</b> Please correct the errors shown above and try again.' +
                '          </div>',
          });
          return
        }
        if(this.selectedAvailabilities.length === 0) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-error">' +
                '            <b>Error:</b> Please select at least one availability' +
                '          </div>',
          });
          return
        }
        if(this.selectedAvailabilities.filter(item => item.BookingStatus !== 'A').length > 0) {
          this.$confirm({
            content: 'Some of the Availabilities you selected have been Booked. We will only batch update the Availabilities that have not been Booked.',
            onConfirm: async () => {
              await this.executeBulkUpdate()
            }
          })
          return
        }
        await this.executeBulkUpdate()
      },
      async executeBulkUpdate() {
        let param = {
          AvailabilityIDs: this.selectedAvailabilities.map(item => item.AvailabilityID),
          Data: {
            UsernameLog: this.user.name,
            UserIDLog: this.user.id
          }
        }
        if (this.bulkEditOption.showOptions) param.Data.Options = {
          ByRequestOnly: this.bulkEditOption.Options.ByRequestOnly, // Integer 0-False, 1-True,
          VideoLinkAvailable: this.bulkEditOption.Options.VideoLinkAvailable, // Integer 0-False, 1-True,
          VideoOnly: this.bulkEditOption.Options.VideoOnly, // Integer 0-False, 1-True,
        }
        if (this.bulkEditOption.showWorkCover) {
          if (param.Data.Options) {
            param.Data.Options.ShowWorkCover = this.bulkEditOption.Options.ShowWorkCover
          } else {
            param.Data.Options = {
              ShowWorkCover: this.bulkEditOption.Options.ShowWorkCover
            }
          }
        }
        if (this.bulkEditOption.showClients) {
          param.Data.ClientIDs = this.bulkEditOption.ClientIDs
          if (param.Data.Options) {
            param.Data.Options.ShowSpecificClients = this.bulkEditOption.Options.ShowSpecificClients
          } else {
            param.Data.Options = {
              ShowSpecificClients: this.bulkEditOption.Options.ShowSpecificClients
            }
          }
        }
        if (this.bulkEditOption.showDuration) param.Data.Duration = this.bulkEditOption.Duration
        if (this.bulkEditOption.showLocation) param.Data.LocationID = this.bulkEditOption.LocationID
        if (this.bulkEditOption.showStartTime) param.Data.StartTime = utilities.formatDate(this.bulkEditOption.updateStartTime, "HH:mm:ss", "hh:mm A")
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkUpdateAvailability(param, () => {
          this.cancelDialog()
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-success">' +
                '            <b>Success:</b> Availabilities updated' +
                '          </div>',
          });
          this.reload()
        })
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      cancelDialog() {
        this.filteredAvailabilities = []
        this.selectedAvailabilities = []
        this.bulkEditOption = {
          dateDuration: '',
          startTime: '',
          LocationID: null,
          updateStartTime: '',
          Duration: 0,
          ClientIDs: [],
          Options: {
            ShowWorkCover: 0,
            ShowSpecificClients: 0,
            VideoLinkAvailable: 0,
            VideoOnly: 0,
            ByRequestOnly: 0,
          }
        }
        this.$refs.timePicker.clearTime()
        this.showBulkUpdate = false
      },
      changeByRequestOnly() {
        if(this.bulkEditOption.Options.ByRequestOnly) {
          this.bulkEditOption.Options.ByRequestOnly = 0
        } else {
          this.bulkEditOption.Options.ByRequestOnly = 1
          this.bulkEditOption.Options.VideoLinkAvailable = 0
          this.bulkEditOption.Options.VideoOnly = 0
        }
      },
      changeVideoLinkAvailable() {
        if(this.bulkEditOption.Options.VideoLinkAvailable) {
          this.bulkEditOption.Options.VideoLinkAvailable = 0
        } else {
          this.bulkEditOption.Options.VideoLinkAvailable = 1
          this.bulkEditOption.Options.VideoOnly = 0
          this.bulkEditOption.Options.ByRequestOnly = 0
        }
      },
      changeOnlyAllowVideo() {
        if(this.bulkEditOption.Options.VideoOnly === 0) {
          this.bulkEditOption.Options.VideoOnly = 1
          this.bulkEditOption.Options.VideoLinkAvailable = 0
          this.bulkEditOption.Options.ByRequestOnly = 0
        } else {
          this.bulkEditOption.Options.VideoOnly = 0
        }
      },
      changeShowWorkCover() {
        if (this.bulkEditOption.Options.ShowWorkCover) {
          this.bulkEditOption.Options.ShowWorkCover = 0
        } else {
          this.bulkEditOption.Options.ShowWorkCover = 1
        }
      },
      changeShowSpecificClients() {
        if (this.bulkEditOption.Options.ShowSpecificClients) {
          this.bulkEditOption.Options.ShowSpecificClients = 0
          this.bulkEditOption.ClientIDs = []
        } else {
          this.bulkEditOption.Options.ShowSpecificClients = 1
        }
      },
      handleSelectionChange(val) {
        this.selectedAvailabilities = val
      },
      searchAvailability() {
        const start = window.moment(this.bulkEditOption.dateDuration[0])
        const end = window.moment(this.bulkEditOption.dateDuration[1])
        let idsArr = []
        this.specialistAvailability.dataSourceAvailabilities.forEach(item => {
          if(window.moment(item.date).add(1, 'hour').isBetween(window.moment(start), window.moment(end).endOf('day'))) {
            const filter = this.bulkEditOption.startTime ? item.Availabilities.filter(a => window.moment(a.StartTime, 'HH:mm:ss').format('HH:mm') === window.moment(this.bulkEditOption.startTime, 'hh:mm A').format('HH:mm')) : item.Availabilities
            const newArr = filter.map(b => {
              return {...b, date: window.moment(item.date).format('DD/MM/YYYY')}
            })
            const idArr = newArr
            idsArr = idsArr.concat(idArr)
          }
        })
        this.filteredAvailabilities = idsArr.sort((a, b) => {
          return window.moment(a.date, 'DD/MM/YYYY').isAfter(window.moment(b.date, 'DD/MM/YYYY')) ? -1 : 1
        })
        console.log(this.filteredAvailabilities)
        if(idsArr.length === 0) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-warning">' +
                '            <b>Error:</b> No data found' +
                '          </div>',
          });
        }
      },
      handleBulkUpdate() {
        this.showBulkUpdate = true
      },
      hasDownloaded() {
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async showEditAvailabilityModal(data) {
        this.$store.dispatch('loading/setLoadComponent', true)
        $('.tippy-box').hide()
        this.$refs.editRoutineAvailabilityModal.loadData()
        this.$refs.editRoutineAvailabilityModal.setAvailability(data)
        this.$refs.editRoutineAvailabilityModal.handleModal()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async approveAll() {
        const param = {
          SpecialistID: this.specialistAvailability.specialist.id,
          UserIDLog: this.user.id,
          UsernameLog: this.user.name,
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkApprove(param)
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Bulk approved !' +
              '          </div>',
        });
        await this.reload()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async approveSingle(obj) {
        const param = {
          UserIDLog: this.user.id,
          UsernameLog: this.user.name,
          AvailabilityIDs: [obj.AvailabilityID]
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkApprove(param)
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Availability Approved !' +
              '          </div>',
        });
        await this.reload()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async rejectSingle(e) {
        const param = {
          UserIDLog: this.user.id,
          UsernameLog: this.user.name,
          SpecialistID: this.availability.SpecialistID,
          AvailabilityIDs: [e.AvailabilityID]
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        await utilities.bulkReject(param)
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Availability Rejected' +
              '          </div>',
        });
        this.$store.dispatch('loading/setLoadComponent', false)
        await this.reload()

      },
      async exportPdf() {
        if(this.specialistAvailability.specialist.id == '') {
          return
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        this.$refs.specialistSchedulePdf.specialist.id = this.specialistAvailability.specialist.id
        await this.$refs.specialistSchedulePdf.loadData()
        if (this.$refs.specialistSchedulePdf.bookings.length > 0) {
          setTimeout(() => {
            this.$refs.html2Pdf.generatePdf()
          }, 1000)
        } else {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-error">' +
                '            <b>Error:</b> No bookings found for the next 3 weeks.' +
                '          </div>',
          });
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },
      formatDate(dateToFormat, newFormat) {
        return utilities.formatDate(dateToFormat, newFormat)
      },
      updateCalendar(availabilities) {
        this.specialistAvailability.availabilities = availabilities
        this.specialistAvailability.dataSourceAvailabilities = utilities.getAvailabilityRoutinesAsDataSource(availabilities)
        this.calendarKey += 1
      },
      removeSingleDraftAvailability(id, parentId) {
        this.$refs.addRoutineAvailability.removeSingleDraftAvailability(id, parentId)
      },
      async approveAvailability(id, parentId) {
        await this.$refs.addRoutineAvailability.approveAvailability(id, parentId)
      },
      async reload() {
        await this.loadAvailability(this.specialistAvailability.specialist.id)
      },
      openAvailabilityNoteModal() {
        this.$refs.manageAvailabilityNoteModal.availabilityNote = _.cloneDeep(this.specialistAvailability.specialist.availabilityNote)
        this.$refs.manageAvailabilityNoteModal.handleModal()
      },

      async loadAvailability(specialistId = '') {
        if (specialistId != '') {
          this.$store.dispatch('loading/setLoadComponent', true)
          var specialistAvailability = _.cloneDeep(this.specialistAvailability)
          var params = {
            StartDate: moment().startOf('month').format("YYYY-MM-DD"),
            EndDate: moment().startOf('month').add(2, 'year').format("YYYY-MM-DD")
          }
          const apiArr = [utilities.getSpecialistById(specialistId), utilities.getSpecialistAvailabilities(specialistId, "", params),utilities.getAvailabilityNoteBySpecialistId(specialistId), utilities.getSpecialistAvailabilityLog(specialistId)]
          try {
            const r = await Promise.all(apiArr)
            specialistAvailability.specialist = r[0]
            var availabilities = await r[1]
            specialistAvailability.numberTimeSlotsBooked = 0
            specialistAvailability.numberTimeSlots = 0
            availabilities.forEach(item => {
              item.Availabilities.forEach(a => {
                item.specialist = specialistAvailability.specialist
                if(a.AvailabilityStatus === '2') {
                  specialistAvailability.numberTimeSlots ++
                  if(a.BookingStatus === 'B') {
                    specialistAvailability.numberTimeSlotsBooked++
                  }
                }
              })
            })

            this.showButtons = false
            specialistAvailability.availabilities = _.cloneDeep(availabilities)
            specialistAvailability.dataSourceAvailabilities = specialistAvailability.availabilities

            this.specialistAvailability = specialistAvailability
            this.routineAvailabilityKey += 1
            this.calendarKey += 1


            this.allLogs = r[3].map((x) => {
              return { id: x.specialistAvailabilityLogId, userName: x.rhoUserName, datetime: window.moment(x.logDate).format('DD/MM/YYYY'), body: x.logDetails }
            })
            var availabilityNote = r[2]
            //
            // avLog = avLog.filter((x) => x.specialistId == specialistId).sort((a, b) => {
            //   return moment(a.logDate).isBefore(moment(b.logDate)) ? 1 : -1
            // }).map((x) => {
            //   return { id: x.specialistAvailabilityLogId, userName: x.rhoUserName, datetime: moment(x.logDate).format('DD/MM/YYYY'), body: x.logDetails }
            // })
            //
            // this.allLogs = avLog
            // Default is recent
            this.specialistAvailability.timelineData = this.allLogs.slice(0,20)
            if (availabilityNote != undefined) this.specialistAvailability.specialist.availabilityNote = availabilityNote

            this.$refs.addRoutineAvailability.draftAvailabilities = this.draftAvailabilities
            setTimeout(() => {
              var specialist = this.$refs.addRoutineAvailability.listSpecialists.find((x) => x.id == specialistId)
              if(specialist && this.$refs.addRoutineAvailability.$refs.specialistDropdown) {
                this.$refs.addRoutineAvailability.$refs.specialistDropdown.selected = specialist
                this.$refs.addRoutineAvailability.$refs.specialistDropdown.searchFilter = specialist.name
                this.$store.dispatch("loading/setLoadComponent", false)
              }
            }, 1000)
            this.$store.dispatch('loading/setLoadComponent', false)
          } catch (e) {
            this.$store.dispatch('loading/setLoadComponent', false)
          }

        }
      },
      async loadData() {
        const apiArr = [utilities.getClients(), utilities.getLocations()]
        const res = await Promise.all(apiArr)
        this.clients = res[0]
        this.locations = res[1]
        this.shortAppointmentLengths = utilities.getShortAppointmentLengths()
        this.appointmentLengths = [...this.shortAppointmentLengths.slice(0, this.shortAppointmentLengths.length - 1), ...utilities.getAppointmentLengths(), ...utilities.getLongAppointmentLengths()]
      },
      updateBookingLog(e) {
        if(e.target.value == 0) {
          this.specialistAvailability.timelineData = this.allLogs.slice(0,20)
        } else {
          this.specialistAvailability.timelineData = this.allLogs
        }
      }
    },

    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      this.specialistAvailability.specialist.id = this.$route.query != undefined && this.$route.query.id != undefined ? this.$route.query.id : ''
      await this.loadAvailability(this.specialistAvailability.specialist.id)
      await this.loadData()
      this.loaded = true
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
