import axios from 'axios'
import store from '@/store'
import moment from 'moment'
import crypto from "crypto"
import fileUtils from './fileUtils'
import Auth from '@aws-amplify/auth'
import {
  URL_API,
  URL_API_1,
  URL_API_2,
  URL_API_3,
  URL_API_4,
  URL_API_5,
  URL_API_6,
  URL_API_8,
  URL_API_9,
  GET_FILE_URL,
  GET_FILE_URL_NEW, S3_URL, URL_API_7
} from "@/assets/js/baseUrlConfig";

/*
// Production urls
const URL_API = 'https://j04nej1qha.execute-api.ap-southeast-2.amazonaws.com/RhoPSpl/'
const URL_API_1 = 'https://kt2hthtrsk.execute-api.ap-southeast-2.amazonaws.com/RhoPBooking/'
const URL_API_2 = 'https://w16o0v5r30.execute-api.ap-southeast-2.amazonaws.com/RhoPRef/'
const URL_API_3 = 'https://2y2ysc4pw9.execute-api.ap-southeast-2.amazonaws.com/RhoPClient/'
const URL_API_4 = 'https://g2rudsy8xh.execute-api.ap-southeast-2.amazonaws.com/RhoPCase/'
const URL_API_5 = 'https://62gts3y7ka.execute-api.ap-southeast-2.amazonaws.com/RhoPBill/'
const URL_API_6 = 'https://6qgo16yk8k.execute-api.ap-southeast-2.amazonaws.com/RhoPFile/'
const URL_API_8 = 'https://d3w821d7ai.execute-api.ap-southeast-2.amazonaws.com/RhoPCog/'
const URL_API_9 = 'https://0nsre1kvb9.execute-api.ap-southeast-2.amazonaws.com/'
const URL_API_10 = 'https://uqiu0xungl.execute-api.ap-southeast-2.amazonaws.com/RhoPStep/callStep'
//We are not using below 4 URLs. Backend automatically concatenate zoho urls
const SP_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/CustomModule4/'
const BK_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/CustomModule3/'
const CLIENT_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/Accounts/'
const CASE_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/Cases/'
*/

// UAT urls
// const URL_API = 'https://0nm5xknrvl.execute-api.ap-southeast-2.amazonaws.com/RHOTSpecialist/'
// const URL_API_1 = 'https://7lduo2onp7.execute-api.ap-southeast-2.amazonaws.com/RHOTBooking/'
// const URL_API_2 = 'https://vtjfl2u95a.execute-api.ap-southeast-2.amazonaws.com/RHOTRefData/'
// const URL_API_3 = 'https://inrvwyjjca.execute-api.ap-southeast-2.amazonaws.com/RHOTClient/'
// const URL_API_4 = 'https://c2j2jhem9d.execute-api.ap-southeast-2.amazonaws.com/RHOTCase/'
// const URL_API_5 = 'https://l65egosoti.execute-api.ap-southeast-2.amazonaws.com/RHOTBilling/'
// const URL_API_6 = 'https://t7glmp4yh2.execute-api.ap-southeast-2.amazonaws.com/RHOTFiles/'
// const URL_API_8 = 'https://77rgj94z4i.execute-api.ap-southeast-2.amazonaws.com/RHOTBackend/'
// const URL_API_9 ='https://ecb8xw1nc9.execute-api.ap-southeast-2.amazonaws.com/'
// const URL_API_10 = 'https://ptkaqa1xwb.execute-api.ap-southeast-2.amazonaws.com/MockStage/callbookingstep'
// const SP_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/CustomModule4/'
// const BK_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/CustomModule3/'
// const CLIENT_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7001081515/tab/Accounts/'
// const CASE_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7001081515/tab/Cases/'

//PenTest
// const URL_API = 'https://0nm5xknrvl.execute-api.ap-southeast-2.amazonaws.com/RHOPTSpecialist/'
// const URL_API_1 = 'https://7lduo2onp7.execute-api.ap-southeast-2.amazonaws.com/RHOPTBooking/'
// const URL_API_2 = 'https://vtjfl2u95a.execute-api.ap-southeast-2.amazonaws.com/RHOPTRefData/'
// const URL_API_3 = 'https://inrvwyjjca.execute-api.ap-southeast-2.amazonaws.com/RHOPTClient/'
// const URL_API_4 = 'https://c2j2jhem9d.execute-api.ap-southeast-2.amazonaws.com/RHOPTCase/'
// const URL_API_5 = 'https://l65egosoti.execute-api.ap-southeast-2.amazonaws.com/RHOPTBilling/'
// const URL_API_6 = 'https://t7glmp4yh2.execute-api.ap-southeast-2.amazonaws.com/RHOPTFiles/'
// const URL_API_8 = ' https://77rgj94z4i.execute-api.ap-southeast-2.amazonaws.com/RHOPTBackend/'

// Dev urls
// const URL_API = 'https://0nm5xknrvl.execute-api.ap-southeast-2.amazonaws.com/BetaSpecialist/'
// const URL_API_1 = 'https://7lduo2onp7.execute-api.ap-southeast-2.amazonaws.com/BetaBooking/'
// const URL_API_2 = 'https://vtjfl2u95a.execute-api.ap-southeast-2.amazonaws.com/RHOReferenceData/'
// const URL_API_3 = 'https://inrvwyjjca.execute-api.ap-southeast-2.amazonaws.com/RHOClient/'
// const URL_API_4 = 'https://c2j2jhem9d.execute-api.ap-southeast-2.amazonaws.com/RHOCase/'
// const URL_API_5 = 'https://l65egosoti.execute-api.ap-southeast-2.amazonaws.com/RHOBilling/'
// const URL_API_6 = 'https://t7glmp4yh2.execute-api.ap-southeast-2.amazonaws.com/RHOFiles/'
// const URL_API_7 = 'https://ptkaqa1xwb.execute-api.ap-southeast-2.amazonaws.com/MockStage/'
// const URL_API_8 = 'https://77rgj94z4i.execute-api.ap-southeast-2.amazonaws.com/RHOBackend/'
// const URL_API_9 ='https://ecb8xw1nc9.execute-api.ap-southeast-2.amazonaws.com/'
// const URL_API_10 = 'https://ptkaqa1xwb.execute-api.ap-southeast-2.amazonaws.com/MockStage/callStep'
// const SP_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/CustomModule4/'
// const BK_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7000408710/tab/CustomModule3/'
// const CLIENT_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7001081515/tab/Accounts/'
// const CASE_ZOHO_URL = 'https://crm.zoho.com.au/crm/org7001081515/tab/Cases/'

const CONSENT_URL = S3_URL

const STATUS_200 = {
  data: {
    status: 200
  }
}

axios.defaults.timeout = 600000
const DEFAULT_COUNTRY = "AU"
const PHONE_REGEX = /^(\+?[0-9]){6,11}$/
const AU_PHONE_REGEX = /^\+614\d{8}$/
const AU_PHONE_REGEX_2 = /^04\d{10}$/
const LAND_PHONE_REGEX = /^(\+?[0-9])(\d{5}|\d{9})$/
const LAND_PHONE_REGEX_WITHOUT_PLUS = /^(\d{11})$/
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const COMMON_INPUT = /^(?![=+%\-@])(?:[0-9A-Za-z\s+$&^;:,.'"?<>/\\=*#!{}[\]()]+|([-@|])(?!\1))+$/
const COMMON_TEXT = /^[^%!@#$&*(){}\\\/<>]*$/
const COMMON_NO_SPECIAL_TEXT = /^[^'"`]*$/
const WEBSITE_REGEX = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
const START_DATE = "1970-01-01"
const AWAITING_DICTATION = "01. Awaiting Dictation"
const SLOT_SIZE = 15
const APPOINTMENT_LENGTH = 60
const AN_HOUR_HEIGHT = 80

const WARNING_LOST_DATA = "Are you sure you want to leave this screen before saving? Your changes will be lost."
const ERROR_ALERT = "Unable to handle request. Please refresh the page."
const SQL_INJECTION_ALERT = ""
const APIKEY = "hwcgX5JNKN7qIp1SyNtKK25zpdYIjH9A1eZRM9eU"

export default {
  DEFAULT_COUNTRY: DEFAULT_COUNTRY,
  START_DATE: START_DATE,
  AWAITING_DICTATION: AWAITING_DICTATION,
  WARNING_LOST_DATA: WARNING_LOST_DATA,
  SLOT_SIZE: SLOT_SIZE,
  APPOINTMENT_LENGTH: APPOINTMENT_LENGTH,
  AN_HOUR_HEIGHT: AN_HOUR_HEIGHT,
  DEFAULT_MOBILE: "0433877190",

  // Initial
  initAuthentication() {
    return {
      idToken: "",
      group: "",
      logined: false,
    }
  },
  initUser() {
    return {
      id: "",
      name: "",
      type: 0,  // 1: client, 2: branch admin, 3: RH admin, 4: RH financial admin, 5: specialist, 6: super admin
      typeName: "",
      status: false,
      clientId: 0,
      specialistId: 0,
      lastLoggedInDate: "",
      email: "",
      phone: "",
    }
  },
  isAdmin(type) {
    if(type == 3 || type == 4 || type == 6) {
      return true
    }
    return false
  },
  initPagination() {
    return {
      current: 0,
      list: [],
    }
  },
  initSpecialist() {
    var specialist = {
      id: "",
      title: "",
      firstName: "",
      lastName: "",
      fullName: "",
      userName: "",
      phone1: "",
      phone2: "",
      mobile: "",
      email: "",
      NotificationEmailAddress: '',
      contactDetails: "",
      practiceLocationId: "",
      isUser: false,
      userId: "",
      byRequestOnly: false,
      vendorGeneratedInvoices: false,
      clientAlignment: "",
      serviceIME: false,
      serviceIMEAbuse: false,
      serviceIMEVideo: false,
      serviceSupplementary: false,
      serviceFileReview: false,
      servicePhoneCall: false,
      serviceMedicalNegligenceRequest: false,
      serviceSupplementaryByVideo: false,
      serviceCourtCase: false,
      fceLite: false,
      fceLiteByVideo: false,
      minimumAppointment: this.initMinimumAppointment(),
      availabilityNote: this.initAvailabilityNote(),
      consentForm1: "",
      consentForm2: "",
      consentForm3: "",
      photo: "",
      profileThumbnail: "",
      photoURL: "",
      profileURL: "",
      type: 5,
      specialistProfile: "",
      specialistStatus: true,
      zohoLink: "",
      xeroCode: "",
      locations: [],
      travels: [],
      qualifications: [],
      qualificationList: "",
      certifications: [],
      certificationList: "",
      personConditions: [],
      personConditionList: "",
      interests: [],
      interestList: "",
      educations: [],
      educationList: "",
      lastAvailability: "",
      nextAvailability: "",
    }

    specialist.locations.push(this.initLocation())
    specialist.travels.push(this.initTravel())
    specialist.qualifications.push(this.initQualification())
    specialist.certifications.push(this.initCertification())
    specialist.personConditions.push(this.initPersonCondition())
    specialist.interests.push(this.initPersonCondition())
    specialist.educations.push(this.initSpecialistEducation())

    return specialist
  },
  initMinimumAppointment() {
    return {
      id: "",
      specialistId: "",
      independentMedicalAssessment: 0,
      independentMedicalAssessmentVideo: 0,
      independentMedicalAssessmentAbuse: 0,
      phoneCall: 0,
    }
  },
  initAvailabilityNote() {
    return {
      id: "",
      specialistId: "",
      note: "",
      addedBy: "",
      dateAdded: "",
    }
  },

  getAvailabilityColors(options) {
    if (options.AvailabilityStatus === '1') {
      return '#ff0000'
    } else {
      if(options.BookingStatus === 'H') {
        return '#808080'
      }
      return '#40aa00dd'
    }
  },
  getClientAvailabilityColors(options) {
    if (options.AvailabilityStatus === '1') {
      return '#ff0000'
    } else {
      if(options.BookingStatus === 'H') {
        return '#808080'
      } else if (options.BookingStatus === 'B') {
        return '#0080FF'
      }
      return '#40aa00dd'
    }
  },
  initLocation() {
    return {
      id: "",
      name: "",
      specialistId: "",
      phone: "",
      address1: "",
      address2: "",
      cityId: "",
      cityName: "",
      regionId: "",
      regionName: "",
      state: "",
      stateId: "",
      countryId: "",
      postcode: "",
      country: "",
      geoLocation: "",
      latitude: "",
      longitude: "",
      cities: [],
      fullLocation: "",
      shortLocation: "",
      isOpen: true,
      newCity: false,
      incorrectCity: false,
    }
  },
  initCity() {
    return {
      id: "",
      name: "",
      regionId: "",
    }
  },
  initRegion() {
    return {
      id: "",
      name: "",
    }
  },
  initTravel() {
    return {
      id: "",
      locationId: "",
      locationName: "",
      requiresTravel: false,
      address1: "",
      address2: "",
      cityId: "",
      cityName: "",
      regionId: "",
      regionName: "",
      state: "",
      country: DEFAULT_COUNTRY,
      fullTravel: "",
      isOpen: true,
    }
  },
  initTravelItinerary() {
    return {
      specialistTravelItineraryId: "",
      specialistId: "",
      approvedBy: "",
      assignedTo: "",
      dateApproved: "",
      dateCompleted: "",
      dateUploaded: "",
      fileName: "",
      filePath: "",
      fileStatus: "",
      fileTypeId: "",
      itinerary: "",
      mime: "",
      notes: "",
    }
  },
  initTravelDetail() {
    return {
      accommodationAddress1: '',
      accommodationAddress2: '',
      accommodationCity: '',
      accommodationCountry: '',
      accommodationId: '',
      accommodationName: '',
      accommodationPhoneNumber: '',
      accommodationPostcode: '',
      accommodationState: '',
      airline: '',
      airlineContact: '',
      airlineId: '',
      arrivingAirportId: '',
      arrivingAirportName: '',
      bookingReference: '',
      carHireName: '',
      carHirePhoneNumber: '',
      carhireId: '',
      cost: '',
      departingAirportId: '',
      departingAirportName: '',
      flightNumber: '',
      fullAddress: '',
      fullLocation: '',
      seatNumber: '',
      specialistTravelItemId: '',
      specialistTravelItineraryId: '',
      travelEndDate: '',
      travelEndTime: '',
      travelStartDate: '',
      travelStartTime: '',
      travelType: '',
    }
  },
  initQualification() {
    return {
      id: "",
      qualificationId: "",
      qualificationName: "",
    }
  },
  initPersonCondition() {
    return {
      id: "",
      personConditionId: "",
      personConditionName: "",
    }
  },
  initCertification() {
    return {
      id: "",
      certificationId: "",
      certificationName: "",
    }
  },
  initBookingHour() {
    return {
      id: "",
      status: "",
      service: "",
      numberOfHours: "",
      isNew: true,
    }
  },
  initBookingTest() {
    return {
      id: "",
      testName: "",
      dateOrdered: "",
      orderedFrom: "",
      dateReceived: "",
      isNew: true,
    }
  },
  initBookingNote() {
    return {
      id: "",
      Note: "",
      NoteDateTime: "",
      userName: "",
    }
  },
  initContactDetails() {
    return {
      landline: "",
      mobile: "",
      workNumber: "",
      email: ""
    }
  },
  initAddress() {
    return {
      address1: "",
      address2: "",
      city: "",
      state: "",
      postcode: "",
      country: "Australia"
    }
  },
  initSeeting() {
    return {
      specialistId: "",
      specialistSettingId: "",
      newBookingNotification: false,
      bookingChange: false,
      availabilityReminder: true,
      availabilityReturned: false,
      weeklySchedule: true,
      scheduleChange: false,
      reportsDue: false,
      reportsOverdue: false,
      bookingReminder: false,
    }
  },
  initAvailability() {
    return {
      id: "",
      Options: {
        ByRequestOnly: 0, // Integer 0-False, 1-True,
        VideoLinkAvailable: 0, // Integer 0-False, 1-True,
        VideoOnly: 0, // Integer 0-False, 1-True,
        ShowWorkCover: 0, // Integer 0-False, 1-True,
        ShowSpecificClients: 0, // Integer 0-False, 1-True,
      },
      Number: 1,
      ClientIDs: [],
      Duration: 0,
      showAll: false,
      SpecialistID: "",
      specialistName: "",
      title: "",
      firstName: "",
      lastName: "",
      practiceLocationId: "",
      locationId: "",
      locationName: "",
      cityId: "",
      cityName: "",
      photo: "",
      availabilityDate: "",
      availabilityStatus: "",
      StartTime: "",
      endTime: "",
      fillOrder: "",
      numberTimeSlotsBooked: 0,
      numberTimeSlots: 0,
      availableTimes: [],
      byRequestOnly: false,
      requiresTravel: false,
      videoLinkAvailable: 1,  // 1: No, 2: Yes
      VideoOnly: 0, // 0: No, 1: Yes
      startDate: "",
      endDate: "",
      frequency: "",
      occurrence: "",
      date: "",
      days: [],
      generalInformation: "",
    }
  },
  initBooking() {
    var booking = {
      id : '',
      assignedTo: '',
      specialistAvailabilityId: '',
      videoLink: '',
      videoLinkUrl: '',
      claimantStateDuringBooking: '',
      claimantBookingDateTime: '',
      billTo : '',
      reportTo : '',
      private: false,
      clientName : '',
      bookingTypeId : '',
      bookingTypeName : '',
      bookingDateTime : '',
      caseId : '',
      caseReference : '',
      caseOwnerName : '',
      claimTypeName : '',
      claimantFirstName : '',
      claimantGivenNames : '',
      claimantSurname : '',
      claimantEmail : '',
      dateOfBirth : '',
      claimantMobile : '',
      claimantPhone1 : '',
      claimantPhone2 : '',
      claimantAddressLine1 : '',
      claimantAddressLine2 : '',
      claimantState : '',
      claimantCity : '',
      claimantPostcode : '',
      claimantCountry : '',
      PreferredGivenName : '',
      Salutation : '',
      note : '',
      ClaimantLocation: '',
      commencementDate : '',
      accidentDate : '',
      overPeriodofTime : false,
      specialistId : '',
      claimantConfirmed : false,
      otfceRequired : false,
      redHealthMayContactClaimant : false,
      claimantConsentReceived : false,
      interpreterRequired : false,
      callClientAfterAppointment : false,
      videoLinkTestStatus : false,
      hasVideoLink : false,
      feeDeferralRequired: false,
      paymentDeferralOption : false,
      prepaymentRequired : false,
      specialistConfirmedHours : false,
      bookingStatus : '',
      interpreterDetails : '',
      surrogateRequired : '',
      surrogateDetails : '',
      willAcceptEarlier : '',
      specialistTitle : '',
      specialistFirstName : '',
      specialistSurname : '',
      locationId : '',
      paperworkDue : '',
      onBehalfOf : '',
      reportToEmailAddress: '',
      clientId : '',
      clientContacts: [],
      bodyAreas: [],
      personConditions: [],
      bookingFiles: [],
      bookingTests: [],
      bookingHours: [],
      bookingNotes: [],
      billingNotes: [],
      bookingStatusName: '',
      paperworkStatusName: '',
      bookingTime: '',
      OrganizeRequired: false,
    }
    booking.bookingHours.push(this.initBookingHour())
    booking.bookingTests.push(this.initBookingTest())

    return booking
  },
  initBookingRequest() {
    var bookingRequest = {
      id: "",
      status: "",
      dateFrom: "",
      dateTo: "",
      bookingId: "",
      bookingTypeId: "",
      bookingTypeName: "",
      dateOfBirth: "",
      claimTypeId: "",
      note: "",
      mimeType: "",
      assignedTo: "",
      branchName: "",
      bookingRequestFileId: "",
      fileTypeId: "",
      uploadedBy: "",
      submittedBy: "",
      dateSubmitted: "",
      videoLinkAccepted: false,
      specialistId: "",
      firstName: "",
      lastName: "",
      title: "",
      clientId: "",
      clientName: "",
      caseId: "",
      claimantFirstName: "",
      claimantLastName: "",
      claimantGivenNames: "",
      claimantFullName: "",
      regionId: null,
      regionName: "",
      cityId: "",
      cityName: "",
      bodyAreas: [],
      qualifications: [],
      personCondition: [],
      filePaths: [],
    }

    return bookingRequest
  },
  initBodyArea() {
    return {
      id: "",
      bodyArea: "",
    }
  },
  initClient() {
    return {
      id: "",
      name: "",
      branchName: "",
      category: "",
      clientAlignment: "",
      billingCategoryId: "",
      xeroClientCode: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postcode: "",
      country: DEFAULT_COUNTRY,
      contacts: [],
    }
  },
  initClientRequest() {
    return {
      id: "",
      firstName: "",
      surname: "",
      email: "",
      mobile: "",
      companyName: "",
      companyBranchName: "",
      companyFullName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postcode: "",
      country: DEFAULT_COUNTRY,
      dateReceived: "",
      isActioned : false,
      isDeleted : false,
    }
  },
  initTimeline() {
    return {
      id: '',
      userName: '',
      datetime: '',
      body: '',
    }
  },
  initCase() {
    return {
      id: '',
      clientId: '',
      caseOwner: '',
      claimTypeId: '',
      caseStatus: '',
      onBehalfOf: '',
      dateOfBirth: '',
      accidentDate: '',
      caseOwnerName: '',
      caseReference: '',
      claimTypeName: '',
      numberBookings: '',
      claimantSurname: '',
      nextBookingDate: '',
      commencementDate: '',
      overPeriodofTime: false,
      claimantFirstName: '',
      claimantGivenNames: '',
      claimantPhone1: '',
      claimantMobile: '',
      claimantPhone2: '',
      claimantEmail: '',
      claimantAddressLine1: '',
      claimantAddressLine2: '',
      claimantCity: '',
      claimantState: '',
      claimantPostcode: '',
      claimantCountry: '',
      claimantFullName: '',
      bodyAreaOther: '',
      PreferredGivenName: '',
      Salutation: ''
    }
  },
  initCalendarData() {
    return {
      'Subject': '',
      'Start Date': '',
      'Start Time': '',
      'End Date': '',
      'End Time': '',
      'All Day Event': '',
      'Description': ''
    }
  },
  initCalendarEvent() {
    return {
      id: "",
      name: "",
      start: "", // format YYYY-MM-DD hh:mm
      end: "", // format YYYY-MM-DD hh:mm
      timed: true,
      displayTime: "", // format YYYY-MM-DD hh:mm - YYYY-MM-DD hh:mm
      color: "", // color code
      shortDetail: '',  // one line text
      detail: ``, // html content
    }
  },
  initEmail() {
    return {
      files:[],
      smsBody: '',
      sendTo: '',
      subject: '',
      body: '',
      clientEmail: [],
      EmailTo: ''
    }
  },
  initPhone() {
    return {
      sendTo:'',
      body: '',
    }
  },
  initRelatedSkills() {
    return [
      {
        qualificationId: '',
        qualificationName:'',
        offerQualificationId: '',
        offerQualificationName: '',
        qualificationAlternateId: ''
      }
    ]
  },
  initRelatedServices() {
    return [
      {
        wording:'',
        bookingTypeId:'',
        bookingTypeName:'',
        offerBookingTypeId:'',
        offerBookingTypeName:'',
        offerQualificationId:'',
        offerQualificationName:'',
        qualificationId:'',
        qualificationName:'',
        qualificationServiceRelationshipId:'',
      }
    ]
  },
  initClaimant() {
    return [
      {
        dateOfBirth: '',
        surname: '',
        phoneNumber: '',
        mobile: '',
        workNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
      }
    ]
  },
  initBookingTypes() {
    return [{
      id:'',
      bookingTypeName:'',
    }]
  },
  initPurchaseOrderItem() {
    return {
      amount:'',
      bookingPurchaseOrderId:'',
      dateAdded:'',
      description:'',
      id:'',
    }
  },
  initSpecialistEducation() {
    return {
      id: '',
      education: '',
      yearObtained: '',
      fullEducation: '',
      isOpen: false,
    }
  },
  initSpecialistFee(){
    return {
      billingCategoryId:'',
      billingCategoryName:'',
      billingCategorySpecialistFeeId:'',
      bookingTypeId:'',
      bookingTypeName:'',
      clientId:'',
      fee:'',
      specialistFeecode:'',
      specialistId:'',
    }
  },

  loadAuthentication(cognitoUser) {
    var group = cognitoUser.accessToken.payload['cognito:groups']
    return {
      idToken: cognitoUser.getIdToken().getJwtToken(),
      group: group != undefined ? group[0] : "",
      logined: true,
      idTokenExpire: cognitoUser.getIdToken().getExpiration()
    }
  },
  loadPagination(data, limit) {
    var pagination = this.initPagination()
    var temp = []
    var count = 0
    data.map((x, xi) => {
      temp.push(x)
      count += 1
      if (count == limit || data.length - (xi + 1) == 0) {
        pagination.list.push(temp)
        temp = []
        count = 0
      }
    })

    return pagination
  },
  //Display calander data
  loadBooking(x) {
    var booking = {
      class: 'booking',
      specialistDetails: x.specialistDetails,
      BookingTypeID: x.bookingDetails.typeId,
      status: Number(x.bookingDetails.status),
      id: x.bookingDetails.id,
      availabilityId: x.bookingDetails.AvailabilityID,
      type: x.bookingDetails.bookingTypeName,
      start: moment(x.bookingDetails.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm'),
      end: moment(x.bookingDetails.dateTime.slice(0, 10) + ' ' + x.bookingDetails.endTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm'),
      timed: true,
      color: this.getColorHexForBookingType(x.bookingDetails.typeId),
      displayTime: moment(x.bookingDetails.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A') + ' - ' + moment(x.bookingDetails.dateTime.slice(0, 10) + ' ' + x.bookingDetails.endTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A'),
      detail: '<p><strong>Claimant: </strong>' +
          x.casesDetails.claimantFullName +
          ' <strong>Ph: </strong>' +
          x.casesDetails.claimantPhone1 +
          '</p><p><strong>Client: </strong>' +
          x.clientDetails.clientName +
          ' <strong>Contact: </strong>' +
          x.clientContactDetails.fullName +
          ' <strong>Ph: </strong>' +
          x.clientContactDetails.phoneNumber1 +
          '</p><p>' +
          x.bookingDetails.bookingTypeName +
          '</p><p class="tooltip-long-text">' +
          this.getBookingStatus(x.bookingDetails.status) +
          '</p><p class="tooltip-long-text"><strong>Attendance: </strong>' + (window.moment(x.bookingDetails.claimantConfirmed, "YYYY-MM-DD").isValid() ? 'Confirmed' : 'Unconfirmed') +
          '</p><p class="tooltip-long-text">' +
          x.bookingDetails.fullBodyAreas.trim() +
          '</p><p class="tooltip-long-text">' +
          x.bookingDetails.fullPersonConditions.trim() +
          '</p><p>' +
          x.casesDetails.claimTypeName +
          '</p>',
      shortDetail: 'Claimant: ' +
          x.casesDetails.claimantFullName +
          '&nbsp;Ph: ' +
          x.casesDetails.claimantPhone1 +
          '&nbsp;&nbsp;Client: ' +
          x.clientDetails.clientName +
          '&nbsp;Contact: ' +
          x.clientContactDetails.fullName +
          '&nbsp;Ph: ' +
          x.clientContactDetails.phoneNumber1 +
          ' ' +
          x.bookingDetails.bookingTypeName +
          ' ' +
          this.getBookingStatus(x.bookingDetails.status) +
          ' ' +
          x.bookingDetails.fullBodyAreas.trim() +
          (x.bookingDetails.fullBodyAreas.trim().length > 0 ? ', ' : '') +
          x.bookingDetails.fullPersonConditions.trim() +
          (x.bookingDetails.fullPersonConditions.trim().length > 0 ? ', ' : '') +
          x.casesDetails.claimTypeName,
      locationId: x.location.id,
      fullLocation: x.location.fullLocation,
      clientName: x.clientDetails.clientName,
      branchName: x.clientDetails.branchName,
      zohoLink: x.bookingDetails.zohoLink,
    }
    booking.detail = "<p>" + booking.displayTime + "</p>" + booking.detail
    booking.height = this.convertTimeToHeight(booking.start, booking.end)

    return booking
  },
  //Display calander data in Single Booking Calander
  loadSingleBooking(x) {
    var booking = {
      status: Number(x.bookingDetails.status),
      class: 'booking',
      id: x.bookingDetails.id,
      availabilityId: x.bookingDetails.specialistAvailabilityId,
      type: x.bookingDetails.bookingTypeName,
      start: moment(x.bookingDetails.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm'),
      end: moment(x.bookingDetails.dateTime.slice(0, 10) + ' ' + x.bookingDetails.endTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm'),
      timed: true,
      color: this.getColorHexForBookingType(0),
      displayTime: moment(x.bookingDetails.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A') + ' - ' + moment(x.bookingDetails.dateTime.slice(0, 10) + ' ' + x.bookingDetails.endTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A'),
      detail: "<p>Claimant: " + x.casesDetails.claimantFullName + "&nbsp;  " + x.bookingDetails.bookingTypeName + "</p>",
      shortDetail: 'Claimant: ' +
          x.casesDetails.claimantFullName +
          '&nbsp;  ' +
          x.bookingDetails.bookingTypeName,
      locationId: x.location.id,
      fullLocation: x.location.fullLocation,
      clientName: x.clientDetails.clientName,
      branchName: x.clientDetails.branchName,
      zohoLink: x.bookingDetails.zohoLink,
    }
    var duration = moment.duration(moment(booking.end).diff(moment(booking.start)))
    var length = duration.asMinutes()
    if(length <= 45) {
      booking.detail = "<p>" + booking.displayTime + "</p>" + booking.detail
    } else {
      booking.detail = ""
    }
    booking.height = this.convertTimeToHeight(booking.start, booking.end)

    return booking
  },
  loadAvailability(x) {
    return {
      id: x.id,
      type: "availability",
      start: x.availabilityDate + ' ' + this.formatDate(x.startTime, 'HH:mm', 'HH:mm:ss'),
      end: x.availabilityDate + ' ' + this.formatDate(x.endTime, 'HH:mm', 'HH:mm:ss'),
      timed: false,
      color: "#FAFAFA",
      displayTime: '',
      detail: '',
      locationId: x.locationId
    }
  },

  async checkTokenStatus() {
    var idTokenExpire = this.getIdTokenExpireTime()
    var currentTimeSeconds = Math.round(+new Date() / 1000);
    if (idTokenExpire - 20 < currentTimeSeconds) {
      //automatically updating new idToken
      await Auth.currentSession()
      .then(async (session) => {
        var authentication = this.loadAuthentication(session)
        store.dispatch('user/setAuthentication', authentication)
        return true
      })
      .catch((err) => {
        console.log(err);
        store.dispatch('loading/setLoadComponent', false)
        return false
      })
    }
    return true
  },

  // Invoke API
  // Google API
  async getGeoLocation(address) {
    return new Promise((resolve, reject) => {
      // var location = {
      //   lat: 0,
      //   lng: 0,
      // }
      var location = null
      var geocoder = new google.maps.Geocoder()
      geocoder.geocode({address: address}, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          // location.lat = results[0].geometry.location.lat()
          // location.lng = results[0].geometry.location.lng()
          location = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }
        }
        resolve(location)
      })
    })
  },
  getCurrentLocation() {
    if(store.getters.getCurrentLocation == null) {
      navigator.geolocation.getCurrentPosition((position) => {
        var location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        store.dispatch("setCurrentLocation", location)
        console.log(store.getters.getCurrentLocation)
      })
    }
  },

  // Admin --------------------------------------------------------------------
  // GET
  async getDashboardData() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'adminDashboard', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = {
        searchLog: response.data.SearchLog != undefined ? response.data.SearchLog[0] : [],
        adminDashboard: response.data.AdminDashboard != undefined ? response.data.AdminDashboard[0][0] : []
      }
      var dashboardData = this.formatDashboardData(data)
      return dashboardData
    })
    .catch(error => {
      console.log(JSON.stringify(error.response))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getSpecialistDashboardData() {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spDashboard', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(JSON.stringify(error.response))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBulkRefData() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'bulkRefData?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data
      var bulkRefData = {
        clients: [],
        regions: [],
        locations: [],
        qualifications: [],
        personConditions: [],
        airports: [],
        airlines: [],
        accommodations: [],
        carHires: [],
        citys: [],
        certifications: [],
      }

      // Global search
      if(data.Clients != undefined) bulkRefData.clients = this.formatClient(data.Clients[0])
      if(data.Regions != undefined) bulkRefData.regions = this.formatRegion(data.Regions[0])
      if(data.City != undefined) bulkRefData.citys = this.formatCity(data.City[0])
      if(data.Locations != undefined) bulkRefData.locations = this.formatLocation(data.Locations[0])
      if(data.Qualifications != undefined) bulkRefData.qualifications = this.formatQualification(data.Qualifications[0])
      if(data.PersonConditions != undefined) bulkRefData.personConditions = this.formatPersonCondition(data.PersonConditions[0])
      if(data.Certification != undefined) bulkRefData.certifications = this.formatCertification(data.Certification[0])

      // Travel
      if(data.Airports != undefined) bulkRefData.airports = this.formatAirports(data.Airports[0])
      if(data.Airlines != undefined) bulkRefData.airlines = this.formatAirlines(data.Airlines[0])
      if(data.Accommodation != undefined) bulkRefData.accommodations = this.formatAccommodations(data.Accommodation[0])
      if(data.Carhire != undefined) bulkRefData.carHires = this.formatCarHires(data.Carhire[0])

      return bulkRefData
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialists() {
    let specialListData = store.getters["specialList/getSpecialist"]
    if (!specialListData) {
      await this.checkTokenStatus()
      return await axios.get(URL_API + 'specialist?UserID=' + this.getUserId(), {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            let data = response.data.Specialists != undefined ? response.data.Specialists[0] : []
            let specialists = this.formatSpecialist(data)
            store.dispatch('specialList/setSpecialist', specialists)
            return specialists
          })
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
          })
    }
    return specialListData
  },
  async getSpecialistsNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'specialist-page', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          const data = response.data.Specialists != undefined ? response.data.Specialists[0] : []
          const specialists = this.formatSpecialist(data, true)
          const total = response.data.total || 0
          return {specialists, total}
          })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getSearchSpecialists(params = null) {
    await this.checkTokenStatus()
    let endpoint = URL_API + 'specialist-page?UserID=' + this.getUserId()
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params
    })
    .then(response => {
      var data = response.data.Specialists != undefined ? response.data.Specialists[0] : []
      var specialists = this.formatSpecialist(data)
      const total = response.data.total || 0
      return {specialists, total}
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistById(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'specialist?SpecialistID=' + id + '&UserID=' + this.getUserId(), {
    headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Specialist != undefined ? response.data.Specialist[0] : []
      var specialist = this.formatSpecialist(data)[0]
      return specialist
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getUsersNew(params) {
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + 'rhouser-page'
    return await axios.get(endpoint, {
      params,
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          var data = response.data.Data != undefined ? response.data.Data : []

          var users = this.formatUser(data)
          let count = response.data.Counts || 0
          return {users, count}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getAutoEmails(params) {
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + 'auto-email-templates'
    return await axios.get(endpoint, {
      params,
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          var data = response.data.results != undefined ? response.data.results : []
          return data
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async putAutoEmails(params) {
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + 'auto-email-templates'
    return await axios.put(endpoint, params,{
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          var data = response.data.results != undefined ? response.data.results : []
          return data
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getUserById(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'rhouser-page?UserID=' + id + '&PageNum=1&PageSize=10', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Data != undefined ? response.data.Data : []
      var user = this.formatUser(data)[0]
      return user
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getUserBySub(sub) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'rhouser-page?Sub=' + sub, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data != undefined ? response.data : []
      var user = this.formatUser(data)[0]
      return user
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getUserRoles() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'userRoles?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.UserRoles != undefined ? response.data.UserRoles[0] : []
      var roles = this.formatRole(data)
      return roles
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientUsers(clentId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'rhouser-page?ClientID=' + clentId, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data != undefined ? response.data.Data : []
      var users = this.formatUser(data)
      return users
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClients() {
    let clientsData = store.getters["clientList/getClients"]
    if(!clientsData) {
      await this.checkTokenStatus()
      return await axios.get(URL_API + 'clients?UserID=' + this.getUserId(), {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            var data = response.data.Clients != undefined ? response.data.Clients[0] : []
            var clients = this.formatClient(data)
            store.dispatch('clientList/setClients', clients)
            return clients
          })
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
          })
    }
    return clientsData
  },
  async getClientsNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + 'clients-page', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          const data = response.data.Clients != undefined ? response.data.Clients[0] : []
          const clients = this.formatClient(data, true)
          const total = response.data.total || 0
          return {clients, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getClientRequests() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + "accReq?UserID=" + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClientAccountRequests != undefined ? response.data.ClientAccountRequests[0] : []
      var clientRequests = this.formatClientRequest(data)
      return clientRequests
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientRequestById(id){
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + "accReq?CarID=" + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClientAccountRequests != undefined ? response.data.ClientAccountRequests[0] : []
      var clientRequest = this.formatClientRequest(data)[0]
      return clientRequest
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSearchClients(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_3 + 'client?UserID=' + this.getUserId()
    if(params != undefined) {
      endpoint += params.Name != undefined && params.Name != "" ? '&Name=' + params.Name : ''
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Clients != undefined ? response.data.Clients[0] : []
      var clients = this.formatClient(data)
      return clients
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSearchClientContacts(params = null) {
    await this.checkTokenStatus()
    let endpoint = URL_API_3 + 'clientContact?UserID=' + this.getUserId()
    if(params != undefined) {
      endpoint += (params.Name != undefined && params.Name != "" ? '&CName=' + params.Name : '')
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClientContact != undefined ? response.data.ClientContact[0] : []
      var contacts = this.formatClientContact(data)
      return contacts
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getRatecardForSpecialistClient(specialistId, clientId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spRatecard?SpecialistID=' + specialistId + '&' + 'ClientID=' + clientId, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistRate != undefined ? response.data.SpecialistRate[0] : []
      var ratecard = this.formatRatecard(data)
      return ratecard
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistBookings(params) {
    await this.checkTokenStatus()
    var specialistId = params.specialistId
    var url = URL_API_1 + "bookings?UserID=" + this.getUserId() + "&SpecialistID=" + specialistId
    if (params.startDate != undefined) url += '&StartDate=' + params.startDate
    if (params.endDate != undefined) url += '&EndDate=' + params.endDate
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatSpecialistBooking(data)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientBookings(params) {
    await this.checkTokenStatus()
    var url = URL_API_1 + "bookings?UserID=" + this.getUserId() + "&ClientID=" + params.clientId
    if (params.startDate != undefined) {
      url += '&StartDate=' + params.startDate
      url += '&Search=0'
    }
    if (params.endDate != undefined) {
      url += '&StartDate=' + params.startDate + '&EndDate=' + params.endDate
    }
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatSpecialistBooking(data, false)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistTravels(params) {
    await this.checkTokenStatus()
    var specialistId = params.specialistId
    var url = URL_API + 'spTravelArrangement?SpecialistID=' + specialistId
    if(typeof params.startDate !== 'undefined')
      url += '&StartDate=' + params.startDate
      if(typeof params.endDate !== 'undefined')
      url += '&EndDate=' + params.endDate
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelArrangement != undefined ? response.data.SpecialistTravelArrangement[0] : []
      var travels = this.formatTravelItinerary(data)
      return travels
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getStandardForms() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'listStdInfoFiles', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data != undefined ? response.data : []
      return data
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientById(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + 'client?ClientID=' + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Client != undefined ? response.data.Client[0] : []
      var client = this.formatClient(data)[0]
      return client
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientNotes(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + 'clientNote?ClientID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClientNote != undefined ? response.data.ClientNote[0] : []
      var notes = this.formatClientNotes(data)
      return notes
    })
  },
  async getBillingCategories() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_5 + 'bcategory?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BillingCategories != undefined ? response.data.BillingCategories[0] : []
      var billingCategories = this.formatBillingCategories(data)
      return billingCategories
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getCasesByFullname(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'case?ClaimantFullName=' + params.fullname + '&ClaimantDoB=' + params.dateOfBirth + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.CasesbyClaimantFullNameDirect != undefined ? response.data.CasesbyClaimantFullNameDirect[0] : []
      var cases = this.formatCase(data)
      return cases
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getCaseByCaseId(caseId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'case?CaseID=' + caseId + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Case != undefined ? response.data.Case[0] : []
      var clientCase = this.formatCase(data)[0]
      return clientCase
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.data.errno != 1644) {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },
  async getCaseAttendanceById(caseId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'caseAttendance?CaseID=' + caseId + '&Claimant=0', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Case != undefined ? response.data.Case[0] : []
      var clientCase = this.formatCase(data)[0]
      return clientCase
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getVerifiedClaimant(param) {
    // await this.checkTokenStatus()
    return await axios.post(URL_API_7 + 'claimant/details', param, {
      headers: {},
    })
    .then(response => {
      var data = response.data.DetailsForClaimantAttendance != undefined ? response.data.DetailsForClaimantAttendance[0] : []
      var verifiedClaimant = this.formatVerifiedClaimant(data)
      return verifiedClaimant
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },


  async getUpdatedCases(param) {
    await this.checkTokenStatus()
    var endpoint = URL_API_4 + 'updatedCases-page'
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param,
        UserID: this.getUserId()
      }
    })
    .then(response => {
      const res = response.data.CasesByLastUpdateDate != undefined ? response.data.CasesByLastUpdateDate[0] : []
      const data = this.formatUpdatedCase(res)
      const total = response.data.total || 0
      return {data, total}
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSearchCases(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_4 + 'searchCases'
    if(params != undefined) {
      endpoint += '?'
      + (params.ClientID != undefined && params.ClientID != "" ? 'ClientID=' + params.ClientID : '')
      + (params.CaseRef != undefined && params.CaseRef != "" ? '&CaseRef=' + params.CaseRef : '')
      + (params.ClaimantFirstName != undefined && params.ClaimantFirstName != "" ? '&ClaimantFirstName=' + params.ClaimantFirstName : '')
      + (params.ClaimantSurname != undefined && params.ClaimantSurname != "" ? '&ClaimantSurname=' + params.ClaimantSurname : '')
      + (params.Salutation != undefined && params.Salutation != "" ? '&Salutation=' + params.Salutation : '')
      + (params.PreferredGivenName != undefined && params.PreferredGivenName != "" ? '&PreferredGivenName=' + params.PreferredGivenName : '')
      + (params.ContactUserID != undefined && params.ContactUserID != "" ? '&ContactUserID=' + params.ContactUserID : '')
      + (params.DateofBirth != undefined && params.DateofBirth != "" ? '&DateofBirth=' + params.DateofBirth : '')
      + (params.Cc != undefined && params.Cc != "" ? '&Cc=' + params.Cc : '')
    }
    return await axios.get(endpoint + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Cases != undefined ? response.data.Cases[0] : []
      var clientCases = this.formatCase(data)
      return clientCases
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSearchClaimants(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_4 + 'case'
    if(params != undefined) {
      endpoint += '?ClaimantName=1'
      + (params.ClaimantFirstName != undefined && params.ClaimantFirstName != "" ? '&ClaimantFirstName=' + params.ClaimantFirstName : '')
      + (params.ClaimantSurname != undefined && params.ClaimantSurname != "" ? '&ClaimantSurname=' + params.ClaimantSurname : '')
    }
    return await axios.get(endpoint + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Cases != undefined ? response.data.Cases[0] : []
      var clientCases = this.formatCase(data)
      return clientCases
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getCasesByClientId(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'case?ClientID=' + params.clientId +'&UserID=' + params.userId, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.CasesbyClientID != undefined ? response.data.CasesbyClientID[0] : []
      var clientCase = this.formatCase(data)
      return clientCase
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getCasesNew(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'cases-page', {
      params: {
        ...params,
        UserID: this.getUserId()
      },
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          const data = response.data.Cases != undefined ? response.data.Cases[0] : []
          const clientCase = this.formatCase(data)
          const total = response.data.total || 0
          return {clientCase, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getOwnershipRequest(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'CaseOwnerRequest', {
      params: {
        ...params,
        UserID: this.getUserId()
      },
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          const data = response.data.results != undefined ? response.data.results : []
          const total = response.data.total || 0
          return {data, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async putOwnershipRequest(params) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.put(URL_API_4 + 'CaseOwnerRequest', params, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {resolve(response)})
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    })
  },

  async getReportHoursAvailable() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'reportNHAvailable?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.NumberHoursAvailable != undefined ? response.data.NumberHoursAvailable[0] : []
      var report = this.formatHoursAvailable(data)
      return report
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getReportHoursBooked() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'reportNHBooked?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.NumberHoursBooked != undefined ? response.data.NumberHoursBooked[0] : []
      var report = this.formatHoursBooked(data)
      return report
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getReportHoursBookedByClient(startDate, endDate, clientId) {
    await this.checkTokenStatus()
    var url = URL_API_1 + 'reportNHClient?StartDate='+startDate+'&EndDate='+endDate + '&UserID=' + this.getUserId()
    if(clientId != undefined && clientId != null && clientId != '')
    url += '&ClientID='+clientId
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.NumberHoursBookedbyAllClients != undefined ? response.data.NumberHoursBookedbyAllClients[0] : response.data.NumberHoursBookedbyClient != undefined ? response.data.NumberHoursBookedbyClient[0]:[]
      var report = this.formatHoursBookedByClient(data)
      return report
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getReportHoursBookedBySpecialist(startDate, endDate) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'reportNHSpecialist?StartDate='+startDate+'&EndDate='+endDate + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.NumberHoursBookedbySpecialists != undefined ? response.data.NumberHoursBookedbySpecialists[0] : []
      var report = this.formatHoursBookedBySpecialist(data)
      return report
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getReportHoursBookedByLocation(startDate, endDate) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'reportNHLocation?StartDate='+startDate+'&EndDate='+endDate + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.NumberHoursBookedbyLocation != undefined ? response.data.NumberHoursBookedbyLocation[0] : []
      var report = this.formatHoursBookedByLocation(data)
      return report
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getBookingAwaitingPaperworkNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'bkAwaitingPaperwork-page', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          const data = response.data?.BookingsAwaitingPaperwork != undefined ? response.data?.BookingsAwaitingPaperwork[0] : []
          const total = response?.data.total || 0
          const bookings = this.formatBookingAwaitingPaperwork(data)
          return {bookings, total}
        })
        .catch(error => {
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getBookingAwaitingConsentNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'bkAwaitingConsent-page', {
      headers: {
        'Authorization': this.getIdToken(),
      },
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          var data = response.data?.Booking != undefined ? response.data?.Booking[0] : []
          var bookings = this.formatBookingAwaitingConsent(data)
          const total = response.data?.total || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getBookingUnconfirmedNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'bkUnconfirmed-page', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param,
        UserID: this.getUserId()
      }
    })
        .then(response => {
          var data = response.data.Booking != undefined ? response.data.Booking[0] : []
          var bookings = this.formatBookingAwaitingConsent(data)
          const total = response.data.total || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },



  async getAccommodations() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'accomodation', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Accommodation != undefined ? response.data.Accommodation[0] : []
      var accommodations = this.formatAccommodations(data)
      return accommodations
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAirlines() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'airline', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Airlines != undefined ? response.data.Airlines[0] : []
      var airlines = this.formatAirlines(data)
      return airlines
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAirports() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'airport', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Airports != undefined ? response.data.Airports[0] : []
      var airports = this.formatAirports(data)
      return airports
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getCarHires() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'carhire', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.CarHires != undefined ? response.data.CarHires[0] : []
      var carHires = this.formatCarHires(data)
      return carHires
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getPurchaseOrder(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_5 + 'bkPurchaseOrder?BookingID=' + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BookingPurchaseOrder != undefined ? response.data.BookingPurchaseOrder[0][0] : []
      var purchaseOrder = this.formatPurchaseOrder(data)
      return purchaseOrder
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getPurchaseOrderItems(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_5 + 'purchaseOrderItem?BookingPurchaseOrderID=' + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.PurchaseOrderItems != undefined ? response.data.PurchaseOrderItems[0] : []
      var purchaseOrderItems = this.formatPurchaseOrderItems(data)
      return purchaseOrderItems
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistFee(specialistId, billingCategoryId=-1, clientId=-1){
    await this.checkTokenStatus()
    var url = URL_API_5 + 'bcSpecialistFee?SpecialistID=' + specialistId + '&UserID=' + this.getUserId()
    if(clientId != -1) {
      url += '&ClientID=' + clientId
    }
    if(billingCategoryId != -1) {
      url += '&BCID=' + billingCategoryId
    }
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BillingCategorySpecialistFees != undefined ? response.data.BillingCategorySpecialistFees[0] : []
      var specialistFees = this.formatSpecialistFees(data)
      return specialistFees
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistCancellationFees(specialistId,  billingCategoryId=-1, clientId=-1) {
    await this.checkTokenStatus()
    var url = URL_API_5 + 'bcSpCancellationFee?SpecialistID=' + specialistId + '&UserID=' + this.getUserId()
    if(clientId != -1) {
      url += '&ClientID=' + clientId
    }
    if(billingCategoryId != -1) {
      url += '&BCID=' + billingCategoryId
    }
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BillingCategorySpecialistCancellationFee != undefined ? response.data.BillingCategorySpecialistCancellationFee[0] : []
      var cancellations = this.formatCancellations(data)
      return cancellations.length > 0 ? cancellations[0] : cancellations
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistAvailabilityLog(specialistId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'availability-logs?UserID=' + this.getUserId() + '&SpecialistID=' + specialistId + '&PageSize=999 ', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.results != undefined ? response.data.results : []
      var avLog = this.formatSpecialistAvailabilityLog(data)
      return avLog
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getBannerImage(userId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_6 + 'banner', {
      responseType: 'blob',
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      console.log(JSON.parse(JSON.stringify(error.response)))
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBranchNamesOfClient(name) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + 'client?SBN=1&Name=' + name + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Client != undefined ? response.data.Client[0] : []
      var clients = this.formatClient(data)
      return clients
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      this.showErrorMessage(error.response.data?.message || error.response.data)
      store.dispatch('loading/setLoadComponent', false)
    })
  },

  // async getFileTypesInaactive() {
  //   return [
  //     {
  //       id:1,
  //       name:'Brief'
  //     },
  //     {
  //       id:2,
  //       name:'Letter of Intent'
  //     },
  //     {
  //       id:9,
  //       name:'Internal Document'
  //     },
  //     {
  //       id:7,
  //       name:'Report'
  //     },
  //     {
  //       id:8,
  //       name:'Other'
  //     },
  //   ]
  // },

getClientFileTypes() {
    return [
      {
        id:1,
        name:'Brief'
      },
      {
        id:2,
        name:'Letter of Instruction'
      },
      {
        id:8,
        name:'Other'
      },
    ]
  },

getFileStatus() {
    return [
      {
        id:0,
        name:'Draft'
      },
      {
        id:1,
        name:'Final'
      },
    ]
  },

  // POST
  async postSpecialist(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'specialist', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistID != undefined ? Number(response.data.SpecialistID) : 0
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      if (id == 0) {
        this.showErrorMessage(SQL_INJECTION_ALERT)
        return
      }
      if (id > 0) {
        callback(id)
        result = true
      }
      store.dispatch("specialList/setSpecialist", null)
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      this.showErrorMessage(error.response.data?.message || error.response.data)
      store.dispatch('loading/setLoadComponent', false)
    })
  },
  async postUser(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'rhouser-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = false
      var id = response.data.UserID != undefined ? Number(response.data.UserID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      if (error.response.status == 400 && error.response.data == "Email Address Already Exists!") {
        this.showErrorMessage(error.response.data, '')
      } else if (error.response.status == 400 || error.response.status == 440) {
        this.showErrorMessage(error.response.data.message, '')
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
      store.dispatch('loading/setLoadComponent', false)
    })
  },

  async postNewUser(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'reqNewUser', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data
      if (id == "") {
        callback(0)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postLocation(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'location', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }
      store.dispatch("locations/setLocations", null)
      store.dispatch("location/setLocationData", null)
      var result = false
      var id = response.data.LocationID!= undefined ? Number(response.data.LocationID) : 0
      if (id > 0 && callback != undefined) {
        callback(id)
      }
      result = id
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postTravel(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spTravelsTo', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistTravelsToID != undefined ? Number(response.data.SpecialistTravelsToID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postRatecard(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spRatecard', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistClientRateID != undefined ? Number(response.data.SpecialistClientRateID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postClient(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'client', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      if (response.data.ZohoResponse != null && response.data.ZohoResponse != 'null') {
        this.showZohoError(response.data.ZohoResponse, 'Could not create the client in Zoho.')
      }

      var result = ''
      var id = response.data.ClientID != undefined ? Number(response.data.ClientID) : 0
      if (id > 0) {
        //callback(id)
        result = id
      }
      store.dispatch('clientList/setClients', null)
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      if (error.response.status == 440 && error.response.data.message == "An account with the given email already exists.") {
        this.showErrorMessage(error.response.data.message, '')
      } else {
        this.showErrorMessage(error.response.data?.message || error.response.data)
      }
      store.dispatch('loading/setLoadComponent', false)
    })
  },
  async postClientNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'clientNote', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientID != undefined ? Number(response.data.ClientID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postClientRequest(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'accReq', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = false
      var id = response.data.ClientAccountRequestID != undefined ? Number(response.data.ClientAccountRequestID) : 0
      if (id > 0) {
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postBooking(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'booking-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.BookingID != undefined ? Number(response.data.BookingID) : 0
      if (id > 0) {
        callback(id)
        result = true
      } else {
        callback(false)
        result = false
      }
      if (response.data.ZohoResponse != null && response.data.ZohoResponse != 'null') {
        this.showZohoError(response.data.ZohoResponse, 'Could not create the booking in Zoho.')
      }
      return result
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.data.sqlMessage == "Data too long for column 'FilePath' at row 1") {
        this.showErrorMessage("The filename is too long.  Please reduce the length of the name of the file and try again.", error.response.data.message)
      } else {
        this.showErrorMessage(error.response.data?.message || error.response.data)
      }
      console.log(JSON.parse(JSON.stringify(error.response)))
    })
  },

  async postBookingFile(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'moveBookingRequestFile-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      if (response.data.Message != undefined) {
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postMoveBookingFiles(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'moveBookingFile-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = false
      if (response.data) {
        result = true
      }
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
      return false
    })
  },

  async postMoveBookingFilesDB(params, callback) {
    await this.checkTokenStatus()
    var url = 'moveBookingFilesDB'
    if(params.UserID) url += "?UserID=" + params.UserID
    if(params.Username) url += "&Username=" + params.Username
    if(params.FileIDs) url += "&FileIDs=" + params.FileIDs
    if(params.ToBookingID) url += "&ToBookingID=" + params.ToBookingID
    return await axios.post(URL_API_1 + url, params,{
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = false
      if (response.data.Message != undefined) {
        result = true
      }
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postBookingRequest(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingRequest', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = false
      var id = response.data.BookingRequestID != undefined ? Number(response.data.BookingRequestID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postResendBookingEmail(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'resendbkEmail', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = response.data
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putBookingRequestFile(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingRequestFile ', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.BookingRequestID != undefined ? Number(response.data.BookingRequestID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postContact(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'clientContact', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = ''
      var id = response.data.ClientContactID
      if (id != undefined && id > 0) {
        // callback(id)
        result = id
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 400 && error.response.data == "Email Address Already Exists!") {
        this.showErrorMessage(error.response.data, '')
      } else {
        this.showErrorMessage(error.response.data?.message || error.response.data)
      }
    })
  },
  async postContactCanLogin(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'contactCanLogin', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = ''
      var id = response.data.ClientContactID
      if (id != undefined && id > 0) {
        // callback(id)
        result = id
      }
      return result
    })
    .catch((error) => {
      if (error.response.status == 400 && error.response.data == "Email Address Already Exists!") {
        this.showErrorMessage(error.response.data, '')
      } else {
        this.showErrorMessage(error.response.data?.message || error.response.data)
      }
    })
  },
  async postCase(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_4 + 'case', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.CaseID != undefined ? Number(response.data.CaseID) : 0
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      if (id == 0) {
        this.showErrorMessage(SQL_INJECTION_ALERT)
        return
      }

      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postClaimantVerification(params) {
    return await axios.post(URL_API_7 + 'claimant/details', params, {
      headers: {},
    })
    .then((response) => {
      var verifiedClaimant = this.formatVerifiedClaimant(response.data)
      return verifiedClaimant
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postPurchaseOrderItem(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_5 + 'purchaseOrderItem', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.status == 200
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postNewAirline(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'airline', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.data.AirlineID != undefined ? Number(response.data.AirlineID) : 0
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postNewAccommodation(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'accomodation', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.data.AccommodationID != undefined ? Number(response.data.AccommodationID) : 0
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postNewCarhire(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'carhire', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.data.CarhireID != undefined ? Number(response.data.CarhireID) : 0
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postBillingCategory(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_5 + 'bcategory', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.data.BillingCategoryID
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postReplicatingBillingCategory(params){
    await this.checkTokenStatus()
    return await axios.post(URL_API_5 + 'replicatebc', params,  {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postSpecialistCancellationFee(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_5 + 'bcSpCancellationFee', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postAvailabilityToItinerary(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spAvailabilityTravelItinerary', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  //Sending Email after Booking Status changed to Failed To Attend
  async postSendEmailAfterFailedToAttend(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingFAEmail', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postTravelItineraryFile(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'travelItineraryFile', params, {
      headers: {
        'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async createCognitoUser(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_8 + 'createUser', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 440) {
        this.showErrorMessage(error.response.data.message, '')
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },
  async postUserToGroup(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_8 + 'addtogroup', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postTentativeBooking(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'tentative-booking', params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then((response) => {
          return response.status == 200
        })
        .catch((error) => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async postReportUploadEmail(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'fileUploadEmail', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postAvailabilityLog(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spAvailabilityLog', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postPOToXero(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_5 + 'toXero?UserID=' + this.getUserId(), params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.data.InvoiceNumber
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 440) {
        var content = `<b>Warning:</b> ` + error.response.data.message
        this.dispayZohoError(content)
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }

    })
  },

  async postPreSign(params) {
    await this.checkTokenStatus()
    let randomStr = this.randomGenerateMath()
    return await axios.post(URL_API_9 + '/bookingFile', params,{
      headers: {
        'Authorization': this.getIdToken(),
        'X-Secret-Key': randomStr
      },
    }).then(response => {
          return JSON.parse(this.decrypt(response?.data, randomStr))
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async postRequestPreSign(params) {
    await this.checkTokenStatus()
    let randomStr = this.randomGenerateMath()
    return await axios.post(URL_API_9 + '/bookingRequestFile', params,{
      headers: {
        'Authorization': this.getIdToken(),
        'X-Secret-Key': randomStr
      },
    }).then(response => {
      return JSON.parse(this.decrypt(response?.data, randomStr))
    })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async postFileRecord(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingFile-page', params,{
      headers: {
        'Authorization': this.getIdToken(),
      },
    }).then(response => {
      return response?.data
    })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async postRequestFileRecord(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingrequestFile-page', params,{
      headers: {
        'Authorization': this.getIdToken(),
      },
    }).then(response => {
      return response?.data
    })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async postUploadImageURL(params) {
    //Uplod types | if - travelItineraryFile | tr - TravelReceipt |  bf - BookingFile | brf - bookingRequestFile
    await this.checkTokenStatus()
    var enpoint = URL_API_9
    enpoint += '?fn=' + params.name + '&ft=' + params.fileType + '&uploadtype=' + params.uploadtype
    if(params.id != undefined) enpoint += '&id=' + params.id
    return await axios.get(enpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(ERROR_ALERT, error.response.data.message)
    })
  },

  async postImage(url, params) {
    //URL -  s3 pre signed url
    await this.checkTokenStatus()
    return await axios.post(url, params, {

    })
    .then(response => {
      return true
    })
    .catch(error => {
      store.dispatch("loading/setLoadComponent", false)
      this.showErrorMessage(ERROR_ALERT, error.response?.data?.message)
      return false
    })
  },


  async postUploadImage(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'uploadBanner', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postPersonCondition(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'personCondition', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postCertification(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'certification', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postResendInvite(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'resetPwd', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      if (response.status == 200) {
        return response.data
      }
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 440 && error.response.data.message == "User does not exist.") {
        this.showErrorMessage(error.response.data.message, '')
      } else if (error.response.status == 440 && error.response.data.message.includes('Resend not possible')) {
        this.showErrorMessage('Resend not possible since User is already confirmed', '')
      } else {
        this.showErrorMessage( error.response?.data?.message || error.message)
      }
      console.log(JSON.parse(JSON.stringify(error.response)))
    })
  },

  async postWaitlistBooking(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'waitlist-booking', params,{
      headers: {'Authorization': this.getIdToken()},
    }).then(response => {
      if (response.status === 200) {
         callback(response.data)
      }
    }).catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putWaitlistBooking(id, params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'waitlist-booking?tableid=' + id, params,{
      headers: {'Authorization': this.getIdToken()},
    }).then(response => {
      return true
    }).catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  // PUT
  async putSpecialist(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'specialist?tableid=' + params.SpecialistID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistID != undefined ? Number(response.data.SpecialistID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      if (response.data.ZohoResponse != null && response.data.ZohoResponse != 'null') {
        this.showZohoError(response.data.ZohoResponse, 'Could not update the specialist in Zoho.')
      }
      store.dispatch("specialList/setSpecialist", null)
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putUser(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'rhouser-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.UserID != undefined ? Number(response.data.UserID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 400 && error.response.data == "Email Address Already Exists!") {
        this.showErrorMessage(error.response.data, '')
      } else if (error.response.status == 400 && error.response.data.message == "User does not exist.") {
        this.showErrorMessage(error.response.data.message, '')
      } else if (error.response.status == 440) {
        this.showErrorMessage(error.response.data.message, '')
      }else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },

  async putLoginTime(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'rhouser-logintime?tableid=' + params.SingleUserID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then((response) => {
          var result = false
          var id = response.data.UserID != undefined ? Number(response.data.UserID) : 0
          if (id > 0) {
            callback(id)
            result = true
          }

          return result
        })
        .catch((error) => {
          store.dispatch('loading/setLoadComponent', false)
          if (error.response.status == 400 && error.response.data == "Email Address Already Exists!") {
            this.showErrorMessage(error.response.data, '')
          } else if (error.response.status == 400 && error.response.data.message == "User does not exist.") {
            this.showErrorMessage(error.response.data.message, '')
          } else if (error.response.status == 440) {
            this.showErrorMessage(error.response.data.message, '')
          }else {
            this.showErrorMessage(ERROR_ALERT, error.response.data.message)
          }
        })
  },
  async putLocation(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'location?tableid=' + params.LocationID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      store.dispatch("locations/setLocations", null)
      store.dispatch("location/setLocationData", null)
      var result = false
      var id = response.data.LocationID != undefined ? Number(response.data.LocationID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putCity(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'city?tableid=' + params.CityID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.CityID != undefined ? Number(response.data.CityID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putTravel(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spTravelsTo?tableid=' + params.SpecialistTravelsToID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.TravelsID != undefined ? Number(response.data.TravelsID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putTravelArrangement(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spTravelArrangement?tableid=' + params.SpecialistTravelItineraryID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistTravelItineraryID != undefined ? Number(response.data.SpecialistTravelItineraryID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putRatecard(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spRatecard?tableid=' + params.SpecialistClientRateId, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistClientRateID != undefined ? Number(response.data.SpecialistClientRateID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putClient(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'client?tableid=' + params.ClientID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientID != undefined ? Number(response.data.ClientID) : 0
      if(id > 0) {
        callback(id)
        result = true
      }
      store.dispatch('clientList/setClients', null)
      return result
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putClientRequest(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'accReq?tableid=' + params.ClientRequestID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientAccountRequestID != undefined ? Number(response.data.ClientAccountRequestID) : 0
      if(id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async finalizeBooking(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spFinaliseBooking', params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then((response) => {
          callback(response.data)
        })
        .catch((error) => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async putBooking(params, callback, isTable) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'booking?tableid=' + params.BookingID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = false
      if (response.data != undefined && response.data !== '') {
        var id = response.data.BookingID
        var zohoLink = ''
        var payload = response.data.ZohoResponse != undefined && response.data.ZohoResponse != undefined ? JSON.parse(response.data.ZohoResponse) : null
        if(payload && payload.body && !isTable) {
          this.showZohoError(response.data.ZohoResponse, 'Could not update the booking in Zoho.')
          zohoLink = payload.body && payload.body.indexOf('{') > - 1 ? JSON.parse(payload.body).ZohoLink : ''
        }
        callback && callback(id, zohoLink)
        result = response.data.BookingID
      }
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.data.sqlMessage == "Data too long for column 'FilePath' at row 1") {
        this.showErrorMessage("The filename is too long.  Please reduce the length of the name of the file and try again.", error.response.data.message)
      } else {
        this.showErrorMessage(error.response.data?.message || error.response.data)
      }
    })
  },
  async putBookingUnConfirmed(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bkUnconfirmed-page?tableid=' + params.BookingID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          var result = false
          if (response.data != undefined && response.data !== '') {
            var id = response.data.BookingID
            var zohoLink = ''
            var payload = response.data.ZohoResponse != undefined && response.data.ZohoResponse != undefined ? JSON.parse(response.data.ZohoResponse) : null
            if(payload && payload.body) {
              this.showZohoError(response.data.ZohoResponse, 'Could not update the booking in Zoho.')
              zohoLink = JSON.parse(payload.body).ZohoLink
            }
            callback(id, zohoLink)
            result = response.data.BookingID
          }
          return result
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          if (error.response.data.sqlMessage == "Data too long for column 'FilePath' at row 1") {
            this.showErrorMessage("The filename is too long.  Please reduce the length of the name of the file and try again.", error.response.data.message)
          } else {
            this.showErrorMessage(ERROR_ALERT, error.response.data.message)
          }
        })
  },
  async putBookingRequest(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingRequest?tableid=' + params.BookingRequestID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.BookingRequestID != undefined ? Number(response.data.BookingRequestID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBookingClash(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingClash?tableid=' + params.BookingClashID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.BookingClashID != undefined ? Number(response.data.BookingClashID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putContact(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'clientContact', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientContactID
      if (id != undefined && id > 0) {
        // callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 440 || (error.response.status == 400 && error.response.data == "Email Address Already Exists!")) {
        this.showErrorMessage(error.response.data, '')
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },
  async putContactCanLogin(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'contactCanLogin', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientContactID
      if (id != undefined && id > 0) {
        // callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 400 && error.response.data == "Email Address Already Exists!") {
        this.showErrorMessage(error.response.data, '')
      } else if (error.response.status == 440) {
        this.showErrorMessage(error.response.data.message, '')
      }else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },
  async putCase(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_4 + 'case?tableid=' + params.CaseID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.CaseID != undefined ? Number(response.data.CaseID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      if (response.data.ZohoResponse != null && response.data.ZohoResponse != 'null') {
        this.showZohoError(response.data.ZohoResponse, 'Could not update the case in Zoho.')
      }
      return result
    })
    .catch((error) => {
      callback(null)
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putTravelReceipt(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'travelReceipt?tableid=' + params.SpecialistTravelItineraryID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.TravelReceiptID != undefined ? Number(response.data.TravelReceiptID) : 0
      if (id > 0) {
        result = true
        callback(id)
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBookingNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingNote-page?tableid=' + params.BookingNoteID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = false
      var id = response.data.BookingNoteID != undefined ? Number(response.data.BookingNoteID) : 0
      if (id > 0) {
        result = true
        callback(id)
      }
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putPurchaseOrderItem(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_5 + 'purchaseOrderItem?tableid=' + params.PurchaseOrderItemID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBillingCategory(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_5 + 'bcategory?tableid=' + params.BillingCategoryID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putSpecialistCancellationFee(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_5 + 'bcSpCancellationFee?tableid=' + params.BillingCategorySpecialistCancellationFeeID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putClientNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'clientNote?tableid=' + params.ClientNoteID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientNoteID != undefined ? Number(response.data.ClientNoteID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putBillingNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'billingNote?tableid=' + params.BookingNoteID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          var result = false
          var id = response.data.BillingNoteID != undefined ? Number(response.data.BillingNoteID) : 0
          if (id > 0) {
            result = true
            callback(id)
          }
          return result
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async putAdditionHoursStatus(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingHour', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.BookingHoursID != undefined ? Number(response.data.BookingHoursID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putClaimantconfirm(params) {
    // await this.checkTokenStatus()
    return await axios.put(URL_API_7 + 'claimant/attendance-confirmation', params, {
      headers: {},
    })
    .then(response => {
      return true
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putClaimantContactNoAuth(params,  callback) {
    return await axios.put(URL_API_7 + 'claimant/case', params, {
      headers: {},
    })
        .then(response => {
          callback(response)
          return true
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async putPurchaseOrder(params,  callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_5 + 'bkPurchaseOrder?tableid=' + params.BookingPurchaseOrderID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      callback(response)
      return true
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putPersonCondition(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'personCondition?tableid=' + params.Id, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putCertification(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'certification?tableid=' + params.Id, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      this.showErrorMessage(ERROR_ALERT, error.response.data.message)
    })
  },

  async changeCaseOwnerByCaseID(params) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.post(URL_API_4 + 'changeCaseOwnerByCaseID', params, {
        headers: {'Authorization': this.getIdToken()}
      }).then(res => {
        resolve(res.data)
      }).catch(error => {
        this.showErrorMessage(error.response.data?.message || error.response.data)
        store.dispatch('loading/setLoadComponent', false)
        reject(error)
      })
    })
  },
  async putChangingCaseOwnership(params, isClient, callback) {
    await this.checkTokenStatus()
    let url = 'changeCaseOwner'
    return await axios.post(URL_API_4 + url, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      callback && callback(response)
      return true
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      console.log(JSON.parse(JSON.stringify(error.response)))
      if (error.response.status == 400 && error.response.data.sqlMessage == "No records to Update") {
        this.showErrorMessage('No cases for this client contact', '')
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },


  // DELETE
  async deleteSpecialist(id) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'specialist?specialist_id=' + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = response.data
      store.dispatch("specialList/setSpecialist", null)
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      this.showErrorMessage(ERROR_ALERT, error.response.data.message)
    })
  },
  async deleteRatecardById(id) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'spRatecard?tableid=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = response.data
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteBillingNoteById(params) {
    await this.checkTokenStatus()
    var url = URL_API_1 + 'billingNote?tableid=' + params.id
    return await axios.delete(url, {
      headers: {'Authorization': this.getIdToken()},
      data: params,
    })
        .then(response => {
          var result = response.data
          return result
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async deleteBookingNoteById(params) {
    await this.checkTokenStatus()
    var url = URL_API_1 + 'bookingNote-page?tableid=' + params.id
    return await axios.delete(url, {
      data: params,
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = response.data
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async deleteRequestNoteById(params) {
    await this.checkTokenStatus()
    var url = URL_API_1 + 'bkSpecialistRequestNote'
    return await axios.delete(url, {
      data: params,
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          var result = response.data
          return result
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  // Delete body areas or person conditions from case
  async deleteFromCase(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_4 + 'case?tableid=' + params.CaseID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = Number(response.data.CaseID)
      return id
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async deleteAlternateQualification(id,userId,userName) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_2 + 'qualificationAlt?tableid=' + id + '&UserID='+ userId + '&Username=' + userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteCancellationFees(id,userId,userName) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_5 + 'bcSpCancellationFee?tableid=' + id + '&UserID=' + userId + '&Username=' + userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteClientRequest(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_3 + 'accReq?tableid=' + params.ClientRequestID +'&UserID=' + params.UserID + '&Username=' + params.Username, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      console.log(response)
      var result = false
      if(response.data) result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteClientNoteById(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_3 + 'clientNote?tableid=' + params.id +'&UserID=' + params.userID + '&Username=' + params.userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = response.data
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteTravelItem(itemId, userId, username) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'spTravelItem?tableid=' + itemId +'&UserID=' + userId + '&Username=' + username, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteQualificationById(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'spEducation?tableid=' + params.id +'&UserID=' + params.userID + '&Username=' + params.userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = response.data
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deletePurchaseOrderItem(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_5 + 'purchaseOrderItem?tableid=' + params.PurchaseOrderItemID +'&UserID=' + params.UserID + '&Username=' + params.UserName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async deleteBooking(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_1 + 'booking?tableid=' + params.bookingId +'&UserID=' + params.userID + '&Username=' + params.userName + '&CaseID=' + params.caseId, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async deleteTravelLocation(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'spTravelsTo?tableid=' + params.id +'&UserID=' + params.userID + '&Username=' + params.userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async deleteAvailabilitiesItinerary(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'spAvailabilityTravelItinerary?tableid=' + params.id +'&UserID=' + params.userID + '&Username=' + params.userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },


  // Main --------------------------------------------------------------------
  // GET
  async getPhoto(name) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_6 + 'profile/' + name, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data
      return data
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistSeetings(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spSetting?SpecialistID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistSetting != undefined ? response.data.SpecialistSetting[0] : []
      var settings = this.formatSpecialistSettings(data)
      return settings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  // async getBookingFile(param) {
  //   await this.checkTokenStatus()
  //   return new Promise((resolve, reject) => {
  //     axios.get(URL_API_1 + 'bookingFile-page', {
  //       headers: {'Authorization': this.getIdToken()},
  //       params: {
  //         UserID: this.getUserId(),
  //         ...param
  //       }
  //     })
  //         .then(res => {
  //           const result = res.data.results || []
  //           const total = res.data.total || 0
  //           resolve({result, total})
  //         })
  //         .catch(error => {
  //           this.showErrorMessage(error.response.data?.message || error.response.data)
  //           reject(error)
  //         })
  //   })
  // },
  async getEditBookingById(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "bookingForEdit?UserID=" + this.getUserId() + '&BookingID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      if(!response.data) {
        this.showErrorMessage('Booking not found')
      }
      var booking = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bodyAres = response.data.BodyAreas != undefined  ? response.data.BodyAreas[0] : []
      var bookingAction = response.data.BookingActionRequest != undefined && response.data.BookingActionRequest[0][0].BookingActionRequestID != undefined ? response.data.BookingActionRequest[0] : []
      var bookingLog = response.data.BookingLog != undefined ? response.data.BookingLog[0] : []
      var bookingTypes = response.data.BookingTypes != undefined ? response.data.BookingTypes[0] : []
      var selectedCase = response.data.Case != undefined  ? response.data.Case[0] : []
      var claimTypes = response.data.ClaimTypes != undefined ? response.data.ClaimTypes[0] : []
      var client = response.data.Client != undefined ? response.data.Client[0] : []
      var fileTypes = response.data.FileTypes != undefined ? response.data.FileTypes[0] : []
      var locations = response.data.Locations != undefined  ? response.data.Locations[0] : []
      var personConditions = response.data.PersonConditions != undefined ? response.data.PersonConditions[0] : []
      var availableTimeSlots = response.data.SpecialistAvailableTimeSlots != undefined ? response.data.SpecialistAvailableTimeSlots[0] : []
      var minimumAppointment = response.data.SpecialistMinimumAppointment != undefined ? response.data.SpecialistMinimumAppointment[0] : []
      var specialists = response.data.Specialists != undefined  ? response.data.Specialists[0] : []
      var bookingResult = {}

      bookingResult.booking = this.formatBooking(booking)[0]
      bookingResult.bodyAres = this.formatBodyArea(bodyAres[0].length == 0 || bodyAres[0].Records == 0 ? [] : bodyAres)
      bookingResult.bookingAction = this.formatBookingAction(bookingAction.length == 0 || bookingAction.length == undefined ? [] : bookingAction)
      bookingResult.bookingLog = this.formatBookingLog(bookingLog.length == 0 || bookingLog[0].Records == 0 ? [] : bookingLog)
      bookingResult.bookingTypes = this.formatBookingType(bookingTypes.length == 0 || bookingTypes[0].Records == 0 ? [] : bookingTypes)
      bookingResult.case = this.formatCase(selectedCase.length == 0 || selectedCase[0].Records == 0 ? [] : selectedCase)
      bookingResult.claimTypes = this.formatClaimType(claimTypes.length == 0 || claimTypes[0].Records == 0 ? [] : claimTypes)
      bookingResult.client = this.formatClient(client)[0]
      bookingResult.fileTypes = this.formatFileTypes(fileTypes.length == 0 || fileTypes[0].Records == 0 ? [] : fileTypes)
      bookingResult.locations = this.formatLocation(locations.length == 0 || locations[0].Records == 0 ? [] : locations)
      bookingResult.personConditions = this.formatPersonCondition(personConditions.length == 0 || personConditions[0].Records == 0 ? [] : personConditions)
      bookingResult.availableTimeSlots = this.formatSpecialistAvailableTimeSlots(availableTimeSlots.length == 0 || availableTimeSlots[0].Records == 0 ? [] : availableTimeSlots)
      bookingResult.minimumAppointment = this.formatMinimumAppointment(minimumAppointment.length == 0 || minimumAppointment[0].Records == 0 ? [] : minimumAppointment)
      bookingResult.specialists = this.formatSpecialist(specialists.length == 0 || specialists[0].Records == 0 ? [] : specialists)
      return bookingResult
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.data.sqlMessage == 'Not Allowed Here') {
        this.showErrorMessage(error.response.data.sqlMessage)
      } if (error.response.data.sqlMessage != 'Not Allowed Here') {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },

  async getBookingById(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "booking?UserID=" + this.getUserId() + '&BookingID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var booking = this.formatBooking(data)[0]
      return booking
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.data.sqlMessage == 'Not Allowed Here') {
        this.showErrorMessage(error.response.data.sqlMessage)
      } if (error.response.data.sqlMessage != 'Not Allowed Here') {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },
  async getBookingsByCaseId(id, clientId = '0') {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + "booking?UserID=" + this.getUserId() + "&CaseID=" + id
    if (clientId == '0') {
      endpoint += '&ClientID=' + this.getClientId()
    } else {
      endpoint += '&ClientID=' + clientId
    }

    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatSpecialistBooking(data, false)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAvailabilitiesInMenus(params) {
    await this.checkTokenStatus()
    const endpoint = URL_API + 'availability-group-page'
    return await axios.get(endpoint, {
      params: {
        ...params,
        UserID: this.getUserId()
      },
      headers: {'Authorization': this.getIdToken()},
    }).then(response => {
      var specialistAvailabilities = response.data && response.data.AvailabilityGroups[0] ? response.data.AvailabilityGroups[0] : []
      const total = response.data?.total || 0
      return {specialistAvailabilities, total}
    })
  },
  async getSpecialistAvailabilities(specialistId = "", specialtyId = "", params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API + 'availability-page?UserID=' + this.getUserId()
    if(specialistId != '') {
      endpoint += '&SpecialistID=' + specialistId
    } else if(specialtyId != '') {
      endpoint += '&SpecialtyID=' + specialtyId
    }

    if(params != null) {
      endpoint += (params.RegionID != undefined && params.RegionID != '' ? '&RegionID=' + params.RegionID : '')
      endpoint += (params.CityID != undefined && params.CityID != '' ? '&CityID=' + params.CityID : '')
      endpoint += (params.StartDate != undefined && params.StartDate != '' ? '&StartDate=' + params.StartDate : '')
      endpoint += (params.EndDate != undefined && params.EndDate != '' ? '&EndDate=' + params.EndDate : '')
      endpoint += (params.Vla != undefined ? '&Vla=' + params.Vla : '')
      endpoint += (params.VideoOnly != undefined ? '&VideoOnly=' + params.VideoOnly : '')
      endpoint += (params.AvailabilityID != undefined ? '&AvailabilityID=' + params.AvailabilityID : '')
    }

    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data != undefined && response.data[0] ? response.data[0].Availabilities : []
      var specialistAvailabilities = this.formatAvailability(data)
      return specialistAvailabilities
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      return []
      if (error.response.status == 401) {
        return ""
      } else {
        this.showErrorMessage(error.response.data?.message || error.response.data)
      }
    })
  },

  async getAvailabiltyRequiringTravels() {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spAvRequiringTravel?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistAvailabiltyRequiringTravel != undefined ? response.data.SpecialistAvailabiltyRequiringTravel[0] : []
      var availabilities = this.formatAvailabilityRequiringTravel(data)
      return availabilities
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAvailabiltyRequiringTravelsNoItinerary(specialistId = "") {
    await this.checkTokenStatus()
    var endpoint = URL_API + 'spAvReqTravelNotItinerary?UserID=' + this.getUserId()
    if(specialistId != '') endpoint += '&SpecialistID=' + specialistId
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.AvailabilityRequiringTravelNoItinerary != undefined ? response.data.AvailabilityRequiringTravelNoItinerary[0] : []
      var availabilities = this.formatAvailabilityRequiringTravelNoItinerary(data)
      return availabilities
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getAvailabilityNoteBySpecialistId(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spAvailabilityNote?SpecialistID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistAvailabilityNote != undefined ? response.data.SpecialistAvailabilityNote[0] : []
      var availabilityNote = this.formatAvailabilityNote(data)[0]
      return availabilityNote
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getTravelItinerary(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spTravelItinerary?SpecialistAvailabilityID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelItinerary != undefined ? response.data.SpecialistTravelItinerary[0] : []
      var travelItineraries = this.formatTravelItineraryWithoutItems(data)
      var travelItinerary = travelItineraries[travelItineraries.length - 1]
      return travelItinerary
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingFileLogs(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'bookingFileLog', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...params
      }
    }).then(result => {
      let data = result.data.results !== undefined ? result.data.results : []
      let count = result.data.count
      return {data, count}
    }).catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getQualifications() {
    let qualificationData = store.getters["qualification/getQualification"]
    if(!qualificationData) {
      await this.checkTokenStatus()
      return await axios.get(URL_API + 'qualification', {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            let data = response.data.Qualifications != undefined ? response.data.Qualifications[0] : []
            let qualifications = this.formatQualification(data)
            store.dispatch("qualification/setQualification", qualifications)
            return qualifications
          })
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
          })
    }
    return qualificationData
  },
  async getCertifications() {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'certification', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Certifications != undefined ? response.data.Certifications[0] : []
      var certifications = this.formatCertification(data)
      return certifications
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getPersonConditions() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'personCondition?UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.PersonConditions != undefined ? response.data.PersonConditions[0] : []
      var personConditions = this.formatPersonCondition(data)
      return personConditions
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getFileTypes() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'fileType', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.FileTypes != undefined ? response.data.FileTypes[0] : []
      var personConditions = this.formatFileTypes(data)
      return personConditions
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getCities() {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'city', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Cities != undefined ? response.data.Cities[0] : []
      var cities = this.formatCity(data)
      return cities
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getLocations(id = "", params = "") {
    if(id === "" && params === "") {
      let locations = store.getters["locations/getLocations"]
      if(locations) {
        return locations
      }
    }
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + 'location'
    if(id != '') {
      endpoint += "?SpecialistID=" + id
    }

    if (params.regionId) {
      endpoint += "?RegionID=" + params.regionId
    } else if (params.cityId) {
      endpoint += "?CityID=" + params.cityId
    }

    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Locations != undefined ? response.data.Locations[0] : []
      var locations = this.formatLocation(data)
      if(id === "" && params === "") {
        store.dispatch("locations/setLocations", locations)
      }
      return locations
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getLocationById(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'location?SpecialistID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Locations[0]
      var location = this.formatLocation(data)
      return location
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getHeroImgSrc() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'heroImageLink').then(response => {
      return response.data ? response.data.Data : ''
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async putHeroImgSrc(src) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'heroImageLink', {HeroImageLink: src}, {
      headers: {'Authorization': this.getIdToken()},
    }).then(response => {
      return response.data
    })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getLocationByLocationId(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'location?LocationID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Locations != undefined ? response.data.Locations[0] : []
      var locations = this.formatLocation(data)
      return locations[0]
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getRegions() {
    let regionsData = store.getters["regions/getRegions"]
    if (!regionsData) {
      await this.checkTokenStatus()
      return await axios.get(URL_API_2 + 'regions-page?PageNum=1&PageSize=999', {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            var data = response.data.results != undefined ? response.data.results : []
            var regions = this.formatRegion(data)
            store.dispatch("regions/setRegions", regions)
            return regions
          })
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
          })
    } else {
      return regionsData
    }
  },
  async getCountries(param) {
      await this.checkTokenStatus()
      return await axios.get(URL_API_2 + 'countries-page', {
        headers: {'Authorization': this.getIdToken()},
        params: {
          ...param
        }
      })
          .then(response => {
            let data = response.data.results !== undefined ? response.data.results : []
            let count = response.data.count || 0
            return {data, count}
          })
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
          })
  },

  async getStatesNew(param) {
      await this.checkTokenStatus()
      return await axios.get(URL_API_2 + 'states-page', {
        headers: {'Authorization': this.getIdToken()},
        params: {
          ...param
        }
      })
          .then(response => {
            let data = response.data.results !== undefined ? response.data.results : []
            let count = response.data.count || 0
            return {data, count}
          })
          .catch(error => {
            console.log(JSON.parse(JSON.stringify(error.response)))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
          })
  },

  async getRegionsNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'regions-page', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          let data = response.data.results !== undefined ? response.data.results : []
          let count = response.data.count || 0
          return {data, count}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getCitiesNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'cities-page', {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          let data = response.data.results !== undefined ? response.data.results : []
          let count = response.data.count || 0
          return {data, count}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getTravels(id = "") {
    await this.checkTokenStatus()
    var endpoint = URL_API + 'spTravelsTo'
    if(id != '') {
      endpoint += "?SpecialistID=" + id
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelsTo != undefined ? response.data.SpecialistTravelsTo[0] : []
      var travels = this.formatTravel(data)
      return travels
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistTravelItinerary(params) {
    await this.checkTokenStatus()
    var url = URL_API + 'spTravelItem?SpecialistID=' + params.specialistId + '&CurrentDate=' + params.date
    if(params.runType != undefined)
      url += "&Runtype=" + params.runType
    else
      url += "&Runtype=0"
    return await axios.get(url, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      if(response.status == 400) return []
      var data = response.data.SpecialistTravelItineraryItems != undefined ? response.data.SpecialistTravelItineraryItems[0] : []
      var travels = this.formatTravelItinerary(data)
      return travels
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      if(error.response.status == 400) return []
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAllTravelItineraries(specialistId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spTravelItinerary?SpecialistID=' + specialistId, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelItineraries != undefined ? response.data.SpecialistTravelItineraries[0] : []
      var itineraries = this.formatAllTravelItineraries(data)
      return itineraries
    })
    .catch(error => {
      console.log(JSON.stringify(error.response))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getTravelItineraryByItineraryId(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spTravelItinerary?ItineraryID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelItinerary != undefined ? response.data.SpecialistTravelItinerary[0] : []
      var travelItineraries = this.formatTravelItineraryWithoutItems(data)
      var travelItinerary = travelItineraries[travelItineraries.length - 1]
      return travelItinerary
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBodyArea() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'bodyArea', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BodyAreas != undefined ? response.data.BodyAreas[0] : []
      var bodyAreas = this.formatBodyArea(data)
      return bodyAreas
    })
      .catch(error => {
        console.log(JSON.parse(JSON.stringify(error.response)))
        store.dispatch('loading/setLoadComponent', false)
        this.showErrorMessage(error.response.data?.message || error.response.data)
      })
    },
  async getSpecialistTravelItineraryByAvailabilityId(specialistAvailabilityID) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spTravelItem?spAvailabilityID=' + specialistAvailabilityID + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelItineraryItems != undefined ? response.data.SpecialistTravelItineraryItems[0] : []
      var travels = this.formatTravelItems(data)
      return travels
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistTravelItineraryByItineraryId(itineraryID) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'spTravelItem?ItineraryID=' + itineraryID + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistTravelItineraryItems != undefined ? response.data.SpecialistTravelItineraryItems[0] : []
      var travels = this.formatTravelItems(data)
      return travels
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistTravelReceipts(specialistTravelItineraryId) {
    await this.checkTokenStatus()
    return await axios.get(URL_API + 'travelReceipt?SpecialistTravelItineraryID=' + specialistTravelItineraryId, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.TravelReceipts != undefined ? response.data.TravelReceipts[0] : []
      var receipts = this.formatReceipts(data)
      return receipts
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async searchBookings(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'searchBookings?' + params + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatSpecialistBooking(data)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async searchCasesByClient(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_4 + 'searchCases?ClientID=' + params.clientId + '&ClaimantFirstName=' + params.ClaimantFirstName + '&ClaimantSurname=' + params.ClaimantSurname + '&CaseRef=' + params.caseRef + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Cases != undefined ? response.data.Cases[0] : [] //SearchCases
      var cases = this.formatCase(data)
      return cases
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingsByStatus(status) {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + "booking?UserID=" + this.getUserId() + '&Status=' + status
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatBooking(data)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAllBookings(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + "booking?UserID=" + this.getUserId()
    if(params != undefined) {
      endpoint += '?'
      endpoint += params.ClientID != undefined ? 'ClientID=' + params.ClientID : ''
      endpoint += params.CaseID != undefined ? 'CaseID=' + params.CaseID : ''
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatBooking(data)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getTentativeBooking(params) {
    await this.checkTokenStatus()
    const endpoint = URL_API_1 + "tentative-booking"
    return new Promise((resolve, reject) => {
      axios.get(endpoint, {
        headers: {'Authorization': this.getIdToken()},
        params: {
          UserID: this.getUserId(),
          ...params
        }
      }).then(response => {
        const data = response.data?.TentativePreBookings ? response.data.TentativePreBookings[0] : []
        const total = response.data?.total
        resolve({data, total})
      }).catch(error => {
        store.dispatch('loading/setLoadComponent', false)
        this.showErrorMessage(error.response.data?.message || error.response.data)
        reject(error)
      })
    })
  },

  async getCommonLogs(id) {
    await this.checkTokenStatus()
    const endpoint = URL_API_1 + "BookingCommsLogs"
    return new Promise((resolve, reject) => {
      axios.get(endpoint, {
        headers: {'Authorization': this.getIdToken()},
        params: {
          UserID: this.getUserId(),
          ...id
        }
      }).then(response => {
        const data = response.data?.results ? response.data.results : []
        const total = response.data?.count ? response.data.count : 0
        resolve({data, total})
      }).catch(error => {
        this.showErrorMessage(error.response.data?.message || error.response.data)
        reject(error)
      })
    })
  },

  async putTentativeBooking(params) {
    await this.checkTokenStatus()
    const endpoint = URL_API_1 + "tentative-booking?tableid=" + params.tentativeBookingID
    return new Promise((resolve, reject) => {
      axios.put(endpoint, params, {
        headers: {'Authorization': this.getIdToken()},
      }).then(response => {
        const data = response.data?.TentativePreBookings ? response.data.TentativePreBookings[0] : []
        const total = response.data?.total
        resolve({data, total})
      }).catch(error => {
        store.dispatch('loading/setLoadComponent', false)
        this.showErrorMessage(error.response.data?.message || error.response.data)
        reject(error)
      })
    })
  },

  async getAllBookingsNew(param, isCalendar) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + "bookings-page"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          var data = response.data.Booking != undefined ? response.data.Booking[0] : []
          var bookings = []
          if(isCalendar) {
            bookings = this.formatSpecialistBooking(data, true)
          } else {
            bookings = this.formatBooking(data)
          }
          const total = response.data.total || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(ERROR_ALERT, error.response.data.message)
        })
  },

  async getSpecialistCortCaseBookings(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API + "spCourtCasesTeleconferencesBookings"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          var bookings = response.data.Data != undefined ? response.data.Data : []
          const total = response.data.Counts || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(ERROR_ALERT, error.response.data.message)
        })
  },

  async getSpecialistUpcomingBookings(param, isCalendar) {
    await this.checkTokenStatus()
    let endpoint = URL_API + "spUpcomingBookings"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          var data = response.data.Data != undefined ? response.data.Data : []
          var bookings = []
          if(isCalendar) {
            bookings = this.formatSpecialistBooking(data, true)
          } else {
            bookings = this.formatBooking(data)
          }
          const total = response.data.total || 0
          return {bookings, total}
        })
        .catch(error => {
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(ERROR_ALERT, error.response.data.message)
        })
  },

  async getDictationBookingsNew(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API + "spAwaitingDictationBookings"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          var data = response.data.Data != undefined ? response.data.Data : []
          var bookings = []
          bookings = data
          const total = response.data.Counts || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(ERROR_ALERT, error.response.data.message)
        })
  },

  async getSpecialistFinishedBookingsNew(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API + "spFinishedBookings"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          var data = response.data.Data != undefined ? response.data.Data : []
          var bookings = []
          bookings = data
          const total = response.data.Counts || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(ERROR_ALERT, error.response.data.message)
        })
  },

  async getReportBookingsNew(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API + "spReportToViewBookings"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param
      }
    })
        .then(response => {
          var data = response.data.Data != undefined ? response.data.Data : []
          let bookings = data
          const total = response.data.Counts || 0
          return {bookings, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(ERROR_ALERT, error.response.data.message)
        })
  },

  async getBookingCancel(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + "cancelled-page"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          var data = response.data?.Booking != undefined ? response.data.Booking[0] : []
          const total = response.data?.total || 0
          return {data, total}
        })
        .catch(error => {
          store.dispatch("loading/setLoadComponent", false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getBookingFileNew(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + 'bookingFile-page'
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
         console.log(response)
          return response.data.results ? response.data.results : []
        })
        .catch(error => {
          store.dispatch("loading/setLoadComponent", false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getBookingFileVersion(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + 'bookingFileVersion-page'
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          console.log(response)
          return response.data.results ? response.data.results : []
        })
        .catch(error => {
          store.dispatch("loading/setLoadComponent", false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async deleteBookingFileVersion(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + 'bookingFileVersion-page'
    return await axios.delete(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      data: param
    })
        .then(response => {
          console.log(response)
          return response.data.results ? response.data.results : []
        })
        .catch(error => {
          store.dispatch("loading/setLoadComponent", false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async deleteBookingFileAllVersion(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + 'removeAllBookingFileVersion'
    return await axios.delete(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      data: param
    })
        .then(response => {
          console.log(response)
          return response.data.results ? response.data.results : []
        })
        .catch(error => {
          store.dispatch("loading/setLoadComponent", false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async postUploadFileNew(params) {
    await this.checkTokenStatus()
     return await axios.post(URL_API_1 + 'bookingFileUpload-page', params, {
       headers: {'Authorization': this.getIdToken()},
     }).then(response => {
       console.log(response)
     }).catch(error => {
       this.showErrorMessage(error.response.data?.message || error.response.data || error)
       store.dispatch("loading/setLoadComponent", false)
     })
  },

  async getBookingWaitlist(param) {
    await this.checkTokenStatus()
    let endpoint = URL_API_1 + "waitlist-booking"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          var data = response.data.WaitListPreBookings != undefined ? response.data.WaitListPreBookings[0] : []
          const total = response.data.total || 0
          return {data, total}
        })
        .catch(error => {
          store.dispatch("loading/setLoadComponent", false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getSearchBookings(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + "bookings"
    if(params != undefined) {
      endpoint += '?Search=1'
      endpoint += params.UserName != undefined ? '&UserName=' + params.UserName : ''
      endpoint += params.Firstname != undefined ? '&Firstname=' + params.Firstname : ''
      endpoint += params.Surname != undefined ? '&Surname=' + params.Surname : ''
      endpoint += params.ClientID != undefined ? '&ClientID=' + params.ClientID : ''
      endpoint += params.CaseRef != undefined ? '&CaseRef=' + params.CaseRef : ''
      endpoint += params.BookingID != undefined ? '&BookingID=' + params.BookingID : ''
      endpoint += params.FromDate != undefined ? '&FromDate=' + params.FromDate : ''
      endpoint += params.ToDate != undefined ? '&ToDate=' + params.ToDate : ''
      endpoint += params.BType != undefined ? '&BType=' + params.BType : ''
      endpoint += params.BA != undefined ? '&BA=' + params.BA : ''
      endpoint += params.PC != undefined ? '&PC=' + params.PC : ''
      endpoint += params.BStatus != undefined ? '&BStatus=' + params.BStatus : ''
      endpoint += params.tmp != undefined ? '&tmp=' + params.tmp : ''
      endpoint += params.SpecialistId != undefined ? '&SpecialistID=' + params.SpecialistId : ''
      endpoint += params.RegionID != undefined ? '&RegionID=' + params.RegionID : ''
      endpoint += params.CityID != undefined ? '&CityID=' + params.CityID : ''
      endpoint += params.LocationID != undefined ? '&LocationID=' + params.LocationID : ''
    }
    return await axios.get(endpoint + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatBooking(data)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingTypes(){
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "bookingType", {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BookingTypes != undefined ? response.data.BookingTypes[0] : []
      var bookingTypes = this.formatBookingType(data)
      return bookingTypes
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAllBookingStatuses(){
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "bookingStatus", {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BookingStatuses != undefined ? response.data.BookingStatuses[0] : []
      var bookingStatuses = this.formatBookingStatus(data)
      return bookingStatuses
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingHours(params) {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + "bookingHour" + "?UserID=" + this.getUserId()
    if(params != undefined) {
      endpoint += params.BookingID != undefined ? "&BookingID=" + params.BookingID : ''
      endpoint += params.Service != undefined ? "&Service=" + params.Service : ''
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BookingHour != undefined ? response.data.BookingHour[0] : []
      var bookingHours = this.formatBookingHour(data)
      return bookingHours
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClaimTypes(){
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + "claimType", {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClaimTypes != undefined ? response.data.ClaimTypes[0] : []
      var claimTypeTypes = this.formatClaimType(data)
      return claimTypeTypes
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientContacts(id){
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + "clientContact?ClientID=" + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClientContact != undefined ? response.data.ClientContact[0] : []
      var contacts = this.formatClientContact(data)
      return contacts
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientContactsNew(param){
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + "clientContact-page", {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...param,
        UserID: this.getUserId()
      }
    })
        .then(response => {
          const data = response.data.ClientContact != undefined ? response.data.ClientContact[0] : []
          const contacts = this.formatClientContact(data)
          const total = response.data.total || 0
          return {contacts, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getBookingActions(id = "") {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + 'bookingAction' + '?UserID=' + this.getUserId()
    if(id != '') {
      endpoint += "&BookingID=" + id
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = ""
      if (id == "") {
        data = response.data.BookingActionRequests != undefined ? response.data.BookingActionRequests[0] : []
      } else {
        data = response.data.BookingActionRequest != undefined ? response.data.BookingActionRequest[0] : []
      }

      var actions = this.formatBookingAction(data)
      return actions
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getBookingActionsNew(param) {
    await this.checkTokenStatus()
    var endpoint = URL_API_1 + 'bookingAction-page'
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          const res = response.data.BookingActionRequests != undefined ? response.data.BookingActionRequests[0] : []
          const data = this.formatBookingAction(res)
          const total = response.data.total || 0
          return {data, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getBookingRequestsNew(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "bookingRequest-page", {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
        .then(response => {
          const data = response.data.BookingRequests != undefined ? response.data.BookingRequests[0] : []
          const bookingRequests = this.formatBookingRequest(data)
          const total = response.data.total || 0
          return {bookingRequests, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async getBookingRequestById(id){
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "bookingRequest?BookingRequestID=" + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BookingRequest != undefined ? response.data.BookingRequest[0] : []
      var bookingRequest = this.formatBookingRequest(data)[0]
      return bookingRequest
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingClashes(param) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + "bookingClash-page", {
      headers: {'Authorization': this.getIdToken()},
      params: {
        UserID: this.getUserId(),
        ...param
      }
    })
    .then(response => {
      const res = response.data.BookingClashes != undefined ? response.data.BookingClashes[0] : []
      const data = this.formatBookingClash(res)
      const total = response.data.total || 0
      return {data, total}
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingsAwaitingApproval() {
    await this.checkTokenStatus()
    return await axios.get(URL_API_5 + "bkFinancialApproval?UserID=" + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.Booking != undefined ? response.data.Booking[0] : []
      var bookings = this.formatBookingAwaitingApproval(data)
      return bookings
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getTravelsAwaitingApproval() {
    await this.checkTokenStatus()
    return await axios.get(URL_API + "travelAwaitingApproval", {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.TravelAwaitingApproval != undefined ? response.data.TravelAwaitingApproval[0] : []
      var travels = this.formatTravelAwaitingApproval(data)
      return travels
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSearchLogs(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + "searchLog"
    if(params != undefined) {
      endpoint += '?Search=1'
      + (params.NOR != undefined && params.NOR != "" ? '&NOR=' + params.NOR : '')
      + (params.ClientID != undefined && params.ClientID != "" ? '&ClientID=' + params.ClientID : '')
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SearchLog != undefined ? response.data.SearchLog[0] : []
      var searchLogs = this.formatSearchLog(data)
      return searchLogs
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getSearchLogsNew(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + "searchLog-page"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...params
      }
    })
        .then(response => {
          var data = response.data.results != undefined ? response.data.results : []
          var searchLogs = this.formatSearchLog(data)
          return searchLogs
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getSearchLogList(params = null) {
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + "searchLog-page"
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
      params: {
        ...params
      }
    })
        .then(response => {
          var data = response.data.results != undefined ? response.data.results : []
          var searchLogs = this.formatSearchLog(data)
          let total = response.data.count
          return {searchLogs, total}
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async relationshipSpecialistQualifications(params = null){
    await this.checkTokenStatus()
    var endpoint = URL_API_2 + "qualServiceRelation"
    if (params != undefined) {
      endpoint += '?QualificationID=' + params.qualificationId + '&BookingTypeID=' + params.bookingTypeID
    }
    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.QualificationServiceRelationship != undefined ? response.data.QualificationServiceRelationship[0] : []
      return this.formatQualificationServiceRelationship(data)
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getAlternateQualifications(){
    await this.checkTokenStatus()
    return await axios.get(URL_API_2 + 'qualificationAlt', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.AlternateQualifications != undefined ? response.data.AlternateQualifications[0] : []
      var alternateQualifications = this.formatAlternateQualifications(data)
      return alternateQualifications
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getBookingLogsById(id){
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'bookingLog?BookingID=' + id + '&UserID=' + this.getUserId(), {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.BookingLog != undefined ? response.data.BookingLog[0] : []
      var bookingLog = this.formatBookingLog(data)
      return bookingLog
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getClientLogsById(id){
    await this.checkTokenStatus()
    return await axios.get(URL_API_3 + 'clientLog?ClientID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.ClientLog != undefined ? response.data.ClientLog[0] : []
      var clientLog = this.formatClientLog(data)
      return clientLog
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistsForPdf(){
    await this.checkTokenStatus()
    return await axios.get(URL_API+ 'spForPdf', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data
      var result = false
      if(data.length > 0) result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
      return false
    })
  },

  async getSearchSpecialistByAvailabilityNew(params) {
    await this.checkTokenStatus()
    var endpoint = URL_API + "spSearch-page"
    endpoint += '?QualificationID=' + params.QualificationID + '&SpecialistID=' + params.SpecialistID + '&BookingTypeName=' + params.BookingTypeName
    endpoint += '&RegionID=' + params.RegionID + '&CityID=' + params.CityID + '&Radius=' + params.Radius
    endpoint += '&StartDate=' + params.StartDate + '&EndDate=' + params.EndDate
    endpoint += '&RhoUserID=' + params.RhoUserID + '&ClientID=' + params.ClientID + '&CaseID=' + params.CaseID

    return await axios.get(endpoint, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then(response => {
          if(!response.data)  {
            let specialistResult = {}
            specialistResult.specialists = []
            specialistResult.alternate = []
            specialistResult.related = []
            specialistResult.additionalsearch = []
            return specialistResult
          }
          var data1 = response.data.Specialists != undefined ? response.data.Specialists : []
          var data2 = response.data["Specialists - Alternate Recommendations"] != undefined  ? response.data["Specialists - Alternate Recommendations"] : []
          var data3 = response.data["Specialists - Related Professions"] != undefined ? response.data["Specialists - Related Professions"] : []
          var data4 = response.data['Specialists - Extend Availability Date'] != undefined ? response.data['Specialists - Extend Availability Date'] : []
          var specialistResult = {}

          specialistResult.specialists = this.formatSpecialist(data1.length == 0 || data1[0]?.Records == 0 ? [] : data1)
          specialistResult.alternate = this.formatSpecialist(data2.length == 0 || data2[0]?.Records == 0 ? [] : data2) //data2.Records != undefined ? [] : data2
          specialistResult.related = this.formatSpecialist(data3.length == 0 || data3[0]?.Records == 0 ? [] : data3)
          specialistResult.additionalsearch = this.formatSpecialist(data4.length == 0 || data4[0]?.Records === 0 ? [] : data4)

          return specialistResult
        })
        .catch(error => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },

  async getSpecialistProfiles() {
    await this.checkTokenStatus()
    return await axios.get(URL_API+ 'spReferralNetwork', {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  postSpecialistProfiles() {
    return URL_API+ 'spReferralNetwork'
  },

  async updateZohoLink(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.put(URL_API_2+ 'sccZoholink', param, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async postCountry(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.post(URL_API_2+ 'countries-page', {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async deleteCountry(id) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.delete(URL_API_2+ `country-page?countryID=${id}`, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },
  async deleteState(id) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.delete(URL_API_2+ `state-page?stateID=${id}`, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async deleteRegionNew(id) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.delete(URL_API_2+ `region-page?regionID=${id}`, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async deleteCitiesNew(id) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.delete(URL_API_2+ `city-page?cityID=${id}`, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async postState(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.post(URL_API_2+ 'states-page', {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            const err = JSON.parse(JSON.stringify(error))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async postRegionNew(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.post(URL_API_2+ 'regions-page', {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async postCitiesNew(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.post(URL_API_2+ 'cities-page', {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async putCountry(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.put(URL_API_2+ `country-page?countryID=${param.CountryID}`, {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            const err = JSON.parse(JSON.stringify(error))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async putState(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.put(URL_API_2+ `state-page?stateID=${param.StateID}`, {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            const err = JSON.parse(JSON.stringify(error))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async putRegionNew(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.put(URL_API_2+ `region-page?regionID=${param.RegionID}`, {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            const err = JSON.parse(JSON.stringify(error))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async putCityNew(param) {
    await this.checkTokenStatus()
    return new Promise(((resolve, reject) => {
      axios.put(URL_API_2+ `city-page?cityID=${param.CityID}`, {
        ...param,
        UserID: this.getUserId(),
        Username: this.getUserName()
      }, {
        headers: {'Authorization': this.getIdToken()},
      })
          .then(response => {
            return resolve(response.data)
          })
          .catch(error => {
            const err = JSON.parse(JSON.stringify(error))
            store.dispatch('loading/setLoadComponent', false)
            this.showErrorMessage(error.response.data?.message || error.response.data)
            reject(error)
          })
    }))
  },

  async getFileFromPathByToken(path, idToken = '') {
    await this.checkTokenStatus()
    return await axios.get(URL_API_6 + path, {
      responseType: 'blob',
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error)))
      this.showErrorMessage(error.response?.data?.message || error.response.data)
      store.dispatch('loading/setLoadComponent', false)
    })
  },

  async getFileUrl(params) {
    await this.checkTokenStatus()
    return await axios.get(GET_FILE_URL + '?uploadtype=' + params.uploadtype + '&fn=' + params.filename + '&id=' + params.id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async getFileUrlNew(params) {
    await this.checkTokenStatus()
    const randomStr = this.randomGenerateMath()
    return new Promise((resolve, reject) => {
      axios.post(GET_FILE_URL_NEW, params, {
        headers: {
          'Authorization': this.getIdToken(),
          'X-Secret-Key': randomStr
        },
      })
          .then(response => {
            resolve(this.decrypt(response?.data, randomStr))
          })
          .catch((error) => {
            console.log(JSON.parse(JSON.stringify(error)))
            store.dispatch('loading/setLoadComponent', false)
            reject(error)
            this.showErrorMessage(ERROR_ALERT, error.response?.data?.message || error?.message)
          })
    })
  },

  async getDownloadFilesUrlNew(params) {
    await this.checkTokenStatus()
    const randomStr = this.randomGenerateMath()
    return new Promise((resolve, reject) => {
      axios.post(GET_FILE_URL_NEW + '/bookingFiles', params, {
        headers: {
          'Authorization': this.getIdToken(),
          'X-Secret-Key': randomStr
        },
      })
          .then(response => {
            let result = this.decrypt(response?.data, randomStr)
            result = result.replaceAll('"', '')
            resolve(result)
          })
          .catch((error) => {
            console.log(JSON.parse(JSON.stringify(error)))
            reject(error)
            this.showErrorMessage(ERROR_ALERT, error.response?.data?.message || error.response?.message || error?.message)
          })
    })
  },
  async getDownloadFileUrlNew(params) {
    await this.checkTokenStatus()
    const randomStr = this.randomGenerateMath()
    return new Promise((resolve, reject) => {
      axios.post(GET_FILE_URL_NEW + '/bookingFile', params, {
        headers: {
          'Authorization': this.getIdToken(),
          'X-Secret-Key': randomStr
        },
      })
          .then(response => {
            let result = this.decrypt(response?.data, randomStr)
            result = result.replaceAll('"', '')
            resolve(result)
          })
          .catch((error) => {
            console.log(JSON.parse(JSON.stringify(error)))
            reject(error)
            this.showErrorMessage(ERROR_ALERT, error.response?.data?.message || error.response?.message || error?.message)
          })
    })
  },

  async getDownloadHowToUrlNew(params) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.post(GET_FILE_URL_NEW + '/clientHowToFiles', params, {
        headers: {
          'Authorization': this.getIdToken(),
        },
      })
          .then(response => {
            resolve(response?.data)
          })
          .catch((error) => {
            console.log(JSON.parse(JSON.stringify(error)))
            reject(error)
            this.showErrorMessage(ERROR_ALERT, error.response?.data?.message || error?.message)
          })
    })
  },
  async getDownloadHowToUrlSpecialist(params) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.post(GET_FILE_URL_NEW + '/specialistHowToFiles', params, {
        headers: {
          'Authorization': this.getIdToken(),
        },
      })
          .then(response => {
            resolve(response?.data)
          })
          .catch((error) => {
            console.log(JSON.parse(JSON.stringify(error)))
            reject(error)
            this.showErrorMessage(ERROR_ALERT, error.response?.data?.message || error?.message)
          })
    })
  },



  decrypt(text, key) {
  const [ivString, encryptedString] = text.split(':');
  const iv = Buffer.from(ivString, 'hex');
  const encryptedText = Buffer.from(encryptedString, 'hex');
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
},


  randomGenerateMath() {
    let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2022112';
    let maxPos = $chars.length;
    let num = '';
    for (let i = 0; i < 32; i++) {
      num += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return num;
  },

  async getSpecialistProfilesByIds(ids) {
    await this.checkTokenStatus()
    return await axios.get(URL_API+ 'spProfile?SpecialistID=' + ids, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return new Blob([Uint8Array.from(
        atob(response.data)
          .split('')
          .map(char => char.charCodeAt(0))
      )], { type: 'application/pdf' })
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getSpecialistAvailabilityByItineraryId(id) {
    await this.checkTokenStatus()
    return await axios.get(URL_API+ 'spAvItinerary?ItineraryID=' + id, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data.SpecialistAvailabilityByItinerary != undefined ? response.data.SpecialistAvailabilityByItinerary[0] : []
      var availabilities = this.formattedAvailabilities(data)
      return availabilities
    })
    .catch(error => {
      console.log(error)
      store.dispatch('loading/setLoadComponent', false)
    })
  },

  async getConsentForm(param) {
    // await this.checkTokenStatus()
    return await axios.post(URL_API_7 + 'claimant/consent-form', param, {
      headers: {},
    })
    .then(response => {
      var data = response.data
      var froms = this.formatConsentForms(data)
      return froms
    })
    .catch(error => {
      console.log(error)
      store.dispatch('loading/setLoadComponent', false)
      // this.showErrorMessage(ERROR_ALERT, error.response.data.message)
    })
  },

  async checkDuplicate(item, type) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_2+ 'chkDup?itemname='+ item + '&itemtype=' + type, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var data = response.data
      if (data.Exists == 1) {
        return true
      } else {
        return false
      }

    })
    .catch(error => {
      console.log(error)
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },


  // POST
  async getZohoToken(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_7 + "case?ClientID=" + params.clientId + "&UserID=" + params.userId, {
      headers: {'Authorization': params.idToken,},
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async getZohoAccess(params) {
    await this.checkTokenStatus()
    axios({
      method: "post",
      url: "https://zoho.com.au/oauth/v2/token",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': this.getIdToken()
       },
    })
      .then(function (response) {
        return response
      })
      .catch(function (response) {
        console.log(response);
        store.dispatch('loading/setLoadComponent', false)
        this.showErrorMessage(error.response.data?.message || error.response.data)
      });
  },
  async postAvailability(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'availability-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.AvailabilityID != undefined ? response.data.AvailabilityID : []
      if (id.length > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postAvailabilityNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spAvailabilityNote?SpecialistID=' + params.SpecialistID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = false
      var id = response.data.SpecialistAvailabilityNoteID != undefined ? Number(response.data.SpecialistAvailabilityNoteID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postTravelItinerary(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spTravelItinerary', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistTravelItineraryID != undefined ? Number(response.data.SpecialistTravelItineraryID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postRequestChange(params){
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spReqChange', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      if(response.status == 200) {
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postRequestChangeLog(params){
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spLog', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      if(response.status == 200) {
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postTravelReceipt(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'travelReceipt?SpecialistTravelItineraryID=' + params.SpecialistTravelItineraryID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.TravelReceiptID != undefined ? Number(response.data.TravelReceiptID) : 0
      if (id > 0) {
        result = true
        callback(id)
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postBillingNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'billingNote', params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then((response) => {
          let result = false
          const id = response.data.BillingNoteID
          if (id != undefined && id > 0) {
            callback(id)
            result = true
          }
          return result
        })
        .catch((error) => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async postBookingNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingNote-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = false
      var id = response.data.BookingNoteID
      if (id != undefined && id > 0) {
        callback && callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postSpecialistNote(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bkSpecialistRequestNote ', params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then((response) => {
          var result = false
          var id = response.data.SpecialistRequestNoteID
          if (id != undefined && id > 0) {
            result = true
          }
          return result
        })
        .catch((error) => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },


  async getSpecialistNote(params) {
    await this.checkTokenStatus()
    return await axios.get(URL_API_1 + 'bkSpecialistRequestNote', {
      headers: {'Authorization': this.getIdToken()},
      params
    })
        .then((response) => {
          return response.data.Data
        })
        .catch((error) => {
          console.log(JSON.parse(JSON.stringify(error.response)))
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async postBookingHour(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingHour', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = false
      var id = response.data.BookingHoursID != undefined ? Number(response.data.BookingHoursID) : 0
      if (id != undefined && id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async postEmailTemplate(param) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.post(URL_API_2 + 'emailTemplate', {...param, UserID: this.getUserId(),
        Username: this.getUserName()}, {
        headers: {'Authorization': this.getIdToken()},
      }).then(r => {
        resolve(r)
      }).catch(error => {
        this.showErrorMessage( error.response?.data?.message || error.message)
        reject()
        store.dispatch('loading/setLoadComponent', false)
      })
    })
  },

  async putEmailTemplate(param) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.put(URL_API_2 + 'emailTemplate', {...param, UserID: this.getUserId(),
        Username: this.getUserName()}, {
        headers: {'Authorization': this.getIdToken()},
      }).then(r => {
        resolve(r)
      }).catch(error => {
        this.showErrorMessage( error.response?.data?.message || error.message)
        reject()
        store.dispatch('loading/setLoadComponent', false)
      })
    })
  },

  async getEmailTemplate(param) {
    await this.checkTokenStatus()
    return new Promise((resolve, reject) => {
      axios.get(URL_API_2 + 'emailTemplate', {
        headers: {'Authorization': this.getIdToken()},
        params: {
          ...param,
          UserID: this.getUserId()
        }
      }).then(r => {
        resolve(r.data)
      }).catch(error => {
        this.showErrorMessage( error.response?.data?.message || error.message)
        reject()
        store.dispatch('loading/setLoadComponent', false)
      })
    })
  },

  getStaticTemplateParam() {
    return [{name: 'Client Name', value: '${ClientName}', key:'clientName' }, {
      name: 'Booking Id',
      value: '${BookingId}', key: 'id'
    }, {name: 'Booking Type', value: '${BookingType}', key: 'bookingTypeName'}, {
      name: 'Booking Status',
      value: '${BookingStatus}',
      key: 'bookingStatusName'
    }, {name: 'Booking Time', value: '${BookingTime}', key: 'bookingDateTime'}, {
      name: 'Specialist',
      value: '${Specialist}',
      key: 'sFullName'
    }, {name: 'Specialty', value: '${Specialty}', key: 'qualification'}, {
      name: 'Claimant Name',
      value: '${ClaimantName}',
      key: 'claimantFullName'
    },{
      name: 'Claimant Booking Date Time',
      value: '${ClaimantBookingDateTime}',
      key: 'ClaimantBookingDateTime'
    }, {
      name: 'Claimant Date Of Birth',
      value: '${ClaimantDateOfBirth}',
      key: 'ClaimantDateOfBirth'
    }, {
      name: 'Client Contact email address',
      value: '${ClientContactEmailAddress}',
      key: 'ClientContactEmailAddress'
    },{
      name: 'Claimant Address',
      value: '${ClaimantAddress}',
      key: 'ClaimantAddress'
    }, {
      name: 'Client Address',
      value: '${ClientAddress}',
      key: 'ClientAddress'
    },{name: 'Location', value: '${Location}', key: 'locationName'}, {
      name: 'Case Reference Number',
      value: '${CaseReferenceNumber}',
      key: 'caseReference'
    }, {name: 'Client Contact Name', value: '${ClientContactName}', key: 'clientContactFullName'}, {
      name: 'Claimant State for VideoLink',
      value: '${ClaimantStateForVideoLink}',
      key: 'claimantStateDuringBooking'
    }, {name: 'Claimant Location', value: '${ClaimantLocation}', key: 'ClaimantLocation'}, {
      name: 'Examinee Location for VideoLink',
      value: '${ExamineeLocationForVideoLink}',
      key: 'ClaimantLocation'
    }, {name: 'VideoLink URL', value: '${VideoLinkURL}', key: 'videoLinkUrl'}, {name: 'Paperwork Due', value: '${PaperworkDue}', key: 'paperworkDue'}, {
      name: 'Additional Time',
      value: '${AdditionalTime}',
      key: 'bookingHours'
    }, {name: 'Date of Accident (or Over a Period of Time)', value: '${DateOfAccident}', key: 'accidentDate'}, {
      name: 'Pre-approved Hours',
      value: '${Pre-approvedHours}',
      key: 'bookingHours'
    }]
  },

  async postBookingAction(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingAction', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      var result = false
      var id = response.data.BookingActionRequestID != undefined ? Number(response.data.BookingActionRequestID) : 0
      if (id != undefined && id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postAlternateQualification(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'qualificationAlt', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postRelatedServices(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'qualServiceRelation', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postRegion(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_2 + 'regions-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      store.dispatch("regions/setRegions", null)
      store.dispatch("location/setLocationData", null)
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postCity(params, callback) {
    await this.checkTokenStatus()
    if(params.Latitude == undefined || params.Longitude == undefined) {
      var location = await this.getGeoLocation(params.CityName)
      params.Latitude = location.lat
      params.Longitude = location.lng
    }
    return await axios.post(URL_API + 'city', params,{
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.CityID != undefined ? Number(response.data.CityID) : 0
      if (id != undefined && id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async sendEmailFromBooking(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'bookingEmail-new', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postSpecialistTravelItem(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spTravelItem', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      // Validation for SQL injection. API gateway returns 200 status for sql injection error
      // if (this.validateSQlInjection(response)) {
      //   this.showErrorMessage(SQL_INJECTION_ALERT)
      //   return
      // }

      // return response.status == 200
      if(response.data.SpecialistTravelItineraryID != undefined) {
        return response.data.SpecialistTravelItineraryID
      }
      if(response.data.SpecialistTravelItemID != undefined) {
        return response.data.SpecialistTravelItemID
      }
      return status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postSpecialistFee(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_5 + 'bcSpecialistFee', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postSpecialistSettings(params, callback) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'spSetting', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postSpecialistAvailabilityEmail(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API + 'newSpAvEmail', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postRescheduleEmail(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_1 + 'rescheduleEmail', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async postClientContactToZoho(params) {
    await this.checkTokenStatus()
    return await axios.post(URL_API_3 + 'zohocc', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 400) {
        return false
        //this.showErrorMessage(error.response.data.Message, '')
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },

  // PUT
  async putAvailability(id, params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'availability-page?AvailabilityID=' + id, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.AvailabilityID
      if (id != undefined && Number(id) > 0) {
        callback(id)
        result = true
      } else {
        store.dispatch('loading/setLoadComponent', false)
      }
      return result
    })
    .catch((error) => {
      callback(false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
      store.dispatch('loading/setLoadComponent', false)
    })
  },

  async bulkUpdateAvailability(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'availabilities-page', params, {
      headers: {'Authorization': this.getIdToken()},
    })
        .then((response) => {
          if(response.status === 200) {
            callback(response.status === 200)
          }
          return response.status == 200
        })
        .catch((error) => {
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage( error.response?.data?.message || error.message)
        })
  },
  async bulkApprove(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'availabilities-approvement', params,{
      headers: {'Authorization': this.getIdToken()},
    }).then(response => {
      if (response.status == 200) {
        return response.data
      }
    }).catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage( error.response?.data?.message || error.message)
    })
  },
  async bulkReject(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'availabilities-rejection', params,{
      headers: {'Authorization': this.getIdToken()},
    }).then(response => {
      if (response.status == 200) {
        return response.data
      }
    }).catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage( error.response?.data?.message || error.message)
    })
  },

  async putAvailabilityNote(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spAvailabilityNote?tableid=' + params.SpecialistAvailabilityNoteID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistAvailabilityNoteID
      if (id != undefined && Number(id) > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putTravelItinerary(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spTravelItinerary?tableid=' + params.SpecialistTravelItineraryID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SpecialistTravelItineraryID != undefined ? Number(response.data.SpecialistTravelItineraryID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putSearchLog(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'searchLog-page?tableid=' + params.SearchLogID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.SearchLogID
      if (id != undefined && Number(id) > 0) {
        callback(id)
        result = true
      }

      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putSpecialistSettings(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spSetting?tableid=' + params.SpecialistSettingID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = false
      var id = Number(response.data.SpecialistSettingID)
      if (id != undefined && id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBookingFile(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'booking?tableid=' + params.BookingID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = true
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBookingFileDetails(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingFile-page?tableid=' + params.BookingFileID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBookingAction(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingAction?tableid=' + params.BookingActionRequestID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.BookingActionRequestID != undefined ? Number(response.data.BookingActionRequestID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putAlternateQualification(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'qualificationAlt?tableid='+params.QualificationAlternateID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putRelatedServices(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'qualServiceRelation?tableid='+params.QualificationServiceRelationshipID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putRegion(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_2 + 'region-page?tableid=' + params.RegionID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      store.dispatch("regions/setRegions", null)
      store.dispatch("location/setLocationData", null)
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putClientSetting(params, callback) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'clientSetting?tableid='+params.ClientID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      var result = false
      var id = response.data.ClientID != undefined ? Number(response.data.ClientID) : 0
      if (id > 0) {
        callback(id)
        result = true
      }
      return result
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putSpecialistTravelItem(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API + 'spTravelItem?tableid=' + params.SpecialistTravelItemID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putSpecialistFee(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_5 + 'bcSpecialistFee?tableid=' + params.BillingCategorySpecialistFeeID, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async putBookingBodyAreaPersonCondition(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_1 + 'bookingBAPC?tableid=' + params.BookingId, params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      return response.status == 200
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async putClientContactToZoho(params) {
    await this.checkTokenStatus()
    return await axios.put(URL_API_3 + 'zohocc', params, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then(response => {
      var result = true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      if (error.response.status == 400 && error.response.data.Message == "Error") {
        var content = `<b>Warning:</b> ` + "Could not update the client contact in Zoho."
        this.dispayZohoError(content)
      } else if (error.response.status == 400) {
        this.showErrorMessage(error.response.data.Message, '')
      } else {
        this.showErrorMessage(ERROR_ALERT, error.response.data.message)
      }
    })
  },

  // DELETE
  async deleteAvailability(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'availability-page', {
      headers: {'Authorization': this.getIdToken()},
      params
    })
    .then(response => {
      var result = false
      var id = response.data.AvailabilityID != undefined ? Number(response.data.AvailabilityID) : 0
      if (id > 0) {
        result = true
      }
      return result
    })
    .catch(error => {
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async batchDeleteAvailability(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API + 'availabilities-page', {
      headers: {'Authorization': this.getIdToken()},
      data:params
    })
        .then(response => {
          var result = response.data.AvailabilityIDs
          return result
        })
        .catch(error => {
          store.dispatch('loading/setLoadComponent', false)
          this.showErrorMessage(error.response.data?.message || error.response.data)
        })
  },
  async deleteBookingFileById(params, params2 = null) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_1 + 'bookingFile-page?tableid=' + params.id, {
      headers: {'Authorization': this.getIdToken()},
      data:params2
    })
    .then(response => {
      var result = response.data
      if(result != undefined) return true
      return result
    })
    .catch(error => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  async deleteRelatedServices(params) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_2 + 'qualServiceRelation?tableid='+params.id + '&UserID=' + params.userId + '&Username=' + params.userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },
  async deleteRatecardForClient(id, userId, userName) {
    await this.checkTokenStatus()
    return await axios.delete(URL_API_5 + 'bcSpecialistFee?tableid='+id + '&UserID=' + userId + '&Username=' + userName, {
      headers: {'Authorization': this.getIdToken()},
    })
    .then((response) => {
      return response.status == 200
    })
    .catch((error) => {
      console.log(JSON.parse(JSON.stringify(error.response)))
      store.dispatch('loading/setLoadComponent', false)
      this.showErrorMessage(error.response.data?.message || error.response.data)
    })
  },

  // Format JSON data
  // Admin
  formatDashboardData(data) {
    var searchLog = []
    var adminDashboard = {}
    adminDashboard = {
      countAwaitingFinancialApprovals: Number(this.convertNullToEmpty(data.adminDashboard.CountAwaitingFinancialApprovals)),
      countBookingActionRequests: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingActionRequests)),
      countBookingClashes: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingClashes)),
      countBookingRequests: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingRequests)),
      countBookingsAwaitingDictation: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingsAwaitingDictation)),
      countBookingsAwaitingPaperwork: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingsAwaitingPaperwork)),
      countBookingsCancelled: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingsCancelled)),
      countBookingsTentative: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingsTentative)),
      countBookingsWaitlisted: Number(this.convertNullToEmpty(data.adminDashboard.CountBookingsWaitlisted)),
      countClientRequests: Number(this.convertNullToEmpty(data.adminDashboard.CountClientAccountRequest)),
      countSpecialistAvailabiltyRequiringTravel: Number(this.convertNullToEmpty(data.adminDashboard.CountSpecialistAvailabiltyRequiringTravel)),
      countTravelAwaitingApprovals: Number(this.convertNullToEmpty(data.adminDashboard.CountTravelAwaitingApprovals)),
    }
    data.searchLog.forEach(x=>{
      var y = {
        actionedById: this.convertNullToEmpty(x.ActionedByID),
        actionedByName: this.convertNullToEmpty(x.ActionedByName),
        caseId: this.convertNullToEmpty(x.CaseID),
        caseReference: this.convertNullToEmpty(x.CaseReference),
        city: this.convertNullToEmpty(x.City),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        dateEnd: this.convertNullToEmpty(x.DateEnd),
        dateSearched: this.convertNullToEmpty(x.DateSearched),
        dateStart: this.convertNullToEmpty(x.DateStart),
        numberofResults: this.convertNullToEmpty(x.NumberofResults),
        qualification: this.convertNullToEmpty(x.Qualification),
        radius: this.convertNullToEmpty(x.Radius),
        regionName: this.convertNullToEmpty(x.RegionName),
        searchLogId: this.convertNullToEmpty(x.SearchLogID),
        specialist: this.convertNullToEmpty(x.Specialist),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        specialistServiceName: this.convertNullToEmpty(x.SpecialistServiceName),
        userName: this.convertNullToEmpty(x.UserName),
        videoLinkAccepted: this.convertNullToEmpty(x.VideoLinkAccepted),
        qualificationList: this.convertNullToEmpty(x.Qualification != undefined ? x.Qualification.map(y=>y.QualificationName).join(', ') : ''),
      }
      y.fullLocation = y.city + ", " + y.regionName
      y.cityList = y.city != undefined  && y.city != '' ? y.city.map(z=>z.CityName).join(', ') : ''
      searchLog.push(y)
    })
    return {searchLog: searchLog, adminDashboard: adminDashboard}
  },
  formatUser(data) {
    var user = {}
    var users = []
    data.map(x => {
      user = {
        id: x.UserID,
        name: this.convertNullToEmpty(x.UserName),
        type: Number(this.convertNullToEmpty(x.UserRoleID)),
        typeName: this.convertNullToEmpty(x.RoleName),
        status: this.convertNumToBool(x.UserStatus),
        clientId: Number(this.convertNullToEmpty(x.ClientID)),
        specialistId: Number(this.convertNullToEmpty(x.SpecialistID)),
        lastLoggedInDate: this.convertNullToEmpty(x.LastLoggedInDate),
        email: this.convertNullToEmpty(x.EmailAddress),
        phone: this.convertNullToEmpty(x.Mobile),
      }
      users.push(user)
    })
    return users
  },
  formatRole(data) {
    var role = {}
    var roles = []
    data.map(x => {
      role = {
        id: Number(this.convertNullToEmpty(x.UserRoleID)),
        name: this.convertNullToEmpty(x.RoleName),
      }
      roles.push(role)
    })

    return roles
  },

  formatSpecialistAvailableTimeSlots(data) {
    var availabilities = []
    var availabilitie = {}
    data.map(x => {
      availabilitie = {
        date: this.convertNullToEmpty(x.Date),
        fillOrder: this.convertNullToEmpty(x.FillOrder),
        regionId: this.convertNullToEmpty(x.RegionID),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
        cityName: this.convertNullToEmpty(x.CityName),
        specialistAvailabilityId: this.convertNullToEmpty(x.SpecialistAvailabilityID),
        slots:[],
        slotSize: this.convertNullToEmpty(x.SlotSizeMins),
        byRequestOnly: this.convertNumToBool(x.ByRequestOnly),
        videoLinkAvailable: this.convertNullToEmpty(x.VideoLinkAvailable),
        VideoOnly: this.convertNullToEmpty(x.VideoOnly),
        contiguousSlotCount: this.convertNullToEmpty(x.ContiguousSlotCount),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        totalAvailabileMinites: '',
        startTime: this.convertNullToEmpty(x.StartTime),
        endTime: this.convertNullToEmpty(x.EndTime),
      }
      availabilitie.totalAvailabileMinites = availabilitie.contiguousSlotCount * 15
      if (x.Slots != undefined) {
        var timeSlot = {}
        x.Slots.map(y => {
          timeSlot = {
            time: moment(y.Time, "HH:mm:ss").format("HH:mm"),
            status: this.convertNullToEmpty(y.Status),
          }
          availabilitie.slots.push(timeSlot)
        })
      }
      availabilities.push(availabilitie)
    })
    return availabilities
  },
  formatSpecialist(data, isNew) {
    const specialists = []
    let specialist = {}
    data.map(x => {
      specialist = {
        id: this.convertNullToEmpty(x.SpecialistID),
        title: this.convertNullToEmpty(x.Title),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        phone1: this.convertNullToEmpty(x.PhoneNumber1),
        phone2: this.convertNullToEmpty(x.PhoneNumber2),
        mobile: this.convertNullToEmpty(x.Mobile),
        email: this.convertNullToEmpty(x.EmailAddress),
        NotificationEmailAddress: this.convertNullToEmpty(x.NotificationEmailAddress),
        isUser: this.convertNumToBool(this.convertNullToEmpty(x.IsUser)),
        userId: "",
        byRequestOnly: this.convertNumToBool(this.convertNullToEmpty(x.ByRequestOnly)),
        vendorGeneratedInvoices: this.convertNumToBool(this.convertNullToEmpty(x.VendorGeneratedInvoices)),
        clientAlignment: this.convertNullToEmpty(x.ClientAlignment),
        serviceIME: this.convertNumToBool(this.convertNullToEmpty(x.ServiceIME)),
        serviceIMEAbuse: this.convertNumToBool(this.convertNullToEmpty(x.ServiceIMEAbuse)),
        serviceIMEVideo: this.convertNumToBool(this.convertNullToEmpty(x.ServiceIMEVideo)),
        serviceIMEShortReport: this.convertNumToBool(this.convertNullToEmpty(x.ServiceIMEShortReport)),
        serviceSupplementary: this.convertNumToBool(this.convertNullToEmpty(x.ServiceSupplementary)),
        serviceFileReview: this.convertNumToBool(this.convertNullToEmpty(x.ServiceFileReview)),
        servicePhoneCall: this.convertNumToBool(this.convertNullToEmpty(x.ServicePhoneCall)),
        serviceMedicalNegligenceRequest: this.convertNumToBool(this.convertNullToEmpty(x.ServiceMedicalNegligenceRequest)),
        serviceSupplementaryByVideo: this.convertNumToBool(this.convertNullToEmpty(x.ServiceSupplementaryByVideo)),
        serviceCourtCase: this.convertNumToBool(this.convertNullToEmpty(x.ServiceCourtCase)),
        fceLite: this.convertNumToBool(this.convertNullToEmpty(x.FCELite)),
        fceLiteByVideo: this.convertNumToBool(this.convertNullToEmpty(x.FCELiteByVideo)),
        minimumAppointment: this.initMinimumAppointment(),
        availabilityNote: this.initAvailabilityNote(),
        consentForm1: this.convertNullToEmpty(x.ConsentForm1),
        consentForm2: this.convertNullToEmpty(x.ConsentForm2),
        consentForm3: this.convertNullToEmpty(x.ConsentForm3),
        photo: this.convertNullToEmpty(x.Photo),
        profileThumbnail: this.convertNullToEmpty(x.ProfileThumbnail),
        profileURL: this.convertNullToEmpty(x.ProfileURL),
        photoURL: "",
        specialistProfile: this.convertNullToEmpty(x.SpecialistProfile),
        specialistStatus: this.convertNumToBool(this.convertNullToEmpty(x.SpecialistStatus)),
        xeroCode: this.convertNullToEmpty(x.XeroCode),
        practiceLocationId: this.convertNullToEmpty(x.PracticeLocationID),
        locations: [],
        travels: [],
        qualifications: [],
        qualificationList: "",
        certifications: [],
        certificationList: "",
        personConditions: [],
        personConditionList: "",
        interests: [],
        interestList: "",
        educations: [],
        educationList: "",
        videoLinkAvailable: this.convertNullToEmpty(x.VideoLinkAvailable),
        VideoOnly: this.convertNullToEmpty(x.VideoOnly),
        requiresTravel: this.convertNumToBool(x.RequiresTravel),
        nextAvailabilityDate: this.convertNullToEmpty(x.NextAvailabilityDate),
        lastAvailabilityDate: this.convertNullToEmpty(x.LastAvailabilityDate),
        zohoLink: this.convertNullToEmpty(x.ZohoLink),
        specialistUserId: this.convertNullToEmpty(x.UserID),
        specialistAvailabilities: [],
        specialistMinimumAppiontmentTime: [],
        specialistSetting: [],
      }

      specialist.specialistProfile = specialist.specialistProfile.replace(/((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g, '<a href=\"$1\">$1</a>')

      specialist.fullName = this.getSpecialistFullName(specialist)
      specialist.userName = this.getSpecialistUserName(specialist)
      specialist.contactDetails = this.getSpecialistContact(specialist)

      if (x.Specialists_Availability != undefined) {
        specialist.specialistAvailabilities = this.formatAvailability(x.Specialists_Availability, specialist.fullName)
        let last = moment(specialist.specialistAvailabilities[0].date)
        let next = moment(specialist.specialistAvailabilities[0].date)
        specialist.specialistAvailabilities.forEach(item => {
          if(moment(item.date).isAfter(next)) {
            next = moment(item.date)
          }
          if(moment(item.date).isBefore(last)) {
            next = moment(item.date)
          }
        })
        specialist.nextAvailability = moment(next).format("DD/MM/YYYY")
        specialist.lastAvailability = moment(last).format("DD/MM/YYYY")
      }

      if (x.SpecialistMinimumAppiontmentTime != undefined) {
        specialist.specialistMinimumAppiontmentTime = this.formatMinimumAppointment(x.SpecialistMinimumAppiontmentTime)
      }

      if (x.SpecialistMinimumAppointment != undefined) {
        specialist.specialistMinimumAppiontmentTime = this.formatMinimumAppointment(x.SpecialistMinimumAppointment)
      }

      if (x.SpecialistSettings != undefined) {
        specialist.specialistSetting = this.formatSpecialistSettings(x.SpecialistSettings)
      }

      var location = {}
      if (x.PracticeLocation != undefined) {
        x.PracticeLocation.map(y => {
          location = {
            id: this.convertNullToEmpty(y.LocationID),
            name: this.convertNullToEmpty(y.Name),
            phone: this.convertNullToEmpty(y.PhoneNumber),
            address1: this.convertNullToEmpty(y.AddressLine1),
            address2: this.convertNullToEmpty(y.AddressLine2),
            cityId: this.convertNullToEmpty(y.CityID),
            cityName: this.convertNullToEmpty(y.City),
            state: this.convertNullToEmpty(y.State),
            postcode: this.convertNullToEmpty(y.Postcode),
            country: this.convertCountryCodeToName(this.convertNullToEmpty(y.Country)),
            geoLocation: this.convertNullToEmpty(y.GEOLocation),
            latitude: "",
            longitude: "",
            cities: [],
            isOpen: true,
          }
          location.shortLocation = this.getShortLocation(location)
          location.fullLocation = this.getFullLocation(location)
          specialist.locations.push(location)
        })
      }

      if (x.OtherLocations != undefined) {
        x.OtherLocations.map(y => {
          location = {
            id: this.convertNullToEmpty(y.LocationID),
            name: this.convertNullToEmpty(y.Name),
            phone: this.convertNullToEmpty(y.PhoneNumber),
            address1: this.convertNullToEmpty(y.AddressLine1),
            address2: this.convertNullToEmpty(y.AddressLine2),
            cityId: this.convertNullToEmpty(y.CityID),
            cityName: this.convertNullToEmpty(y.City),
            state: this.convertNullToEmpty(y.State),
            postcode: this.convertNullToEmpty(y.Postcode),
            country: this.convertCountryCodeToName(this.convertNullToEmpty(y.Country)),
            geoLocation: this.convertNullToEmpty(y.GEOLocation),
            latitude: "",
            longitude: "",
            cities: [],
            isOpen: false,
          }
          location.shortLocation = this.getShortLocation(location)
          location.fullLocation = this.getFullLocation(location)
          specialist.locations.push(location)
        })
      }

      if (specialist.locations.length == 0) {
        specialist.locations.push(this.initLocation())
      }

      if (x.SpecialistTravelsTo != undefined) {
        specialist.travels = this.formatTravel(x.SpecialistTravelsTo)
      } else {
        specialist.travels.push(this.initTravel())
      }

      var qualification = {}
      if (x.Qualification != undefined) {
        x.Qualification.map((y, yi) => {
          qualification = {
            id: this.convertNullToEmpty(y.SpecialistQualificationID),
            qualificationId: this.convertNullToEmpty(y.ID),
            qualificationName: this.convertNullToEmpty(y.QualificationName),
          }
          specialist.qualifications.push(qualification)

          specialist.qualificationList += qualification.qualificationName
          if(yi < x.Qualification.length - 1) specialist.qualificationList += ", "
        })
      } else {
        specialist.qualifications.push(this.initQualification())
      }

      var certification = {}
      if (x.Certifications != undefined) {
        x.Certifications.map((y, yi) => {
          certification = {
            id: this.convertNullToEmpty(y.SpecialistCertificationID),
            certificationId: this.convertNullToEmpty(y.ID),
            certificationName: this.convertNullToEmpty(y.CertificationName),
          }
          specialist.certifications.push(certification)

          specialist.certificationList += certification.certificationName
            if(yi < x.Certifications.length - 1) specialist.certificationList += ", "
        })
      } else {
        specialist.certifications.push(this.initCertification())
      }

      var personCondition = {}
      if (x.PersonCondition != undefined) {
        x.PersonCondition.map((y, yi) => {
          personCondition = {
            id: this.convertNullToEmpty(y.SpecialistPersonConditionID),
            personConditionId: this.convertNullToEmpty(y.PersonConditionID),
            personConditionName: this.convertNullToEmpty(y.PersonConditionName),
          }
          specialist.personConditions.push(personCondition)

          specialist.personConditionList += personCondition.personConditionName
          if(yi < x.PersonCondition.length - 1) specialist.personConditionList += ", "
        })
      } else {
        specialist.personConditions.push(this.initPersonCondition())
      }

      var interest = {}
      if (x.Interest != undefined) {
        x.Interest.map((y, yi) => {
          interest = {
            id: this.convertNullToEmpty(y.SpecialistInterestID),
            personConditionId: this.convertNullToEmpty(y.PersonConditionID),
            personConditionName: this.convertNullToEmpty(y.ConditionName),
          }
          specialist.interests.push(interest)

          specialist.interestList += interest.personConditionName
          if(yi < x.Interest.length - 1) specialist.interestList += ", "
        })
      } else {
        specialist.interests.push(this.initPersonCondition())
      }

      var education = {}
      if (x.Education != undefined) {
        x.Education.sort((a, b) => {
          return a.YearObtained < b.YearObtained ? 1 : -1
        })
        x.Education.map((y, yi) => {
          education = {
            id: this.convertNullToEmpty(y.SpecialistEducationID),
            education: this.convertNullToEmpty(y.Education),
            yearObtained: this.convertNullToEmpty(y.YearObtained),
            isOpen: false,
          }
          education.fullEducation = this.getFullEducation(education)
          specialist.educations.push(education)

          specialist.educationList += education.fullEducation
          if(yi < x.Education.length - 1) specialist.educationList += ", "
        })
      } else {
        specialist.educations.push(this.initSpecialistEducation())
      }

      specialists.push(specialist)
    })
     !isNew && specialists.sort((a, b) => {
      return a.fullName > b.fullName ? 1 : -1
    })
    return specialists
  },
  formatSpecialistForPdf(data) {
    var specialists = []
    var specialist = {}
    data.map(x => {
      specialist = {
        id: this.convertNullToEmpty(x.SpecialistID),
        title: this.convertNullToEmpty(x.Title),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        photo: this.convertNullToEmpty(x.Photo),
        profileURL: this.convertNullToEmpty(x.ProfileURL),
        specialistProfile: x.SpecialistProfile,
        qualifications: [],
        personConditions: [],
        certifications: [],
        interests: [],
        educations: [],
      }

      specialist.fullName = this.getSpecialistFullName(specialist)
      specialist.photo = specialist.photo != '' ? URL_API_6 + '/profile/' + specialist.photo : ''

      if (x.Qualifications != undefined) {
        x.Qualifications.map(y => {
          specialist.qualifications.push({
            qualificationName: this.convertNullToEmpty(y.QualificationName),
          })
        })
      }

      if (x.PersonCondition != undefined) {
        x.PersonCondition.map(y => {
          specialist.personConditions.push({
            personConditionName: this.convertNullToEmpty(y.PersonConditionName),
          })
        })
      }

      if (x.Certifications != undefined) {
        x.Certifications.map(y => {
          specialist.certifications.push({
            certificationName: this.convertNullToEmpty(y.CertificationName),
          })
        })
      }

      if (x.Education != undefined) {
        x.Education.map(y => {
          specialist.educations.push({
            education: this.convertNullToEmpty(y.Education),
            yearObtained: this.convertNullToEmpty(y.YearObtained),
          })
        })
      }

      if (x.Interest != undefined) {
        x.Interest.map(y => {
          specialist.interests.push({
            conditionName: this.convertNullToEmpty(y.ConditionName),
          })
        })
      }
      specialists.push(specialist)
    })

    specialists.sort((a, b) => {
      return a.fullName > b.fullName ? 1 : -1
    })
    return specialists
  },
  formatMinimumAppointment(data) {
    var minimumAppointments = []
    var minimumAppointment = {}
    data.map(x => {
      minimumAppointment = {
        id: this.convertNullToEmpty(x.SpecialistMinimumAppointmentID),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        independentMedicalAssessment: this.convertNullToEmpty(x.IndependentMedicalAssessment),
        independentMedicalAssessmentVideo: this.convertNullToEmpty(x.IndependentMedicalAssessmentVideo),
        independentMedicalAssessmentAbuse: this.convertNullToEmpty(x.IndependentMedicalAssessmentAbuse),
        phoneCall: this.convertNullToEmpty(x.PhoneCall),
      }
      minimumAppointments.push(minimumAppointment)
    })
    return minimumAppointments
  },
  formatAvailabilityNote(data) {
    var availabilityNotes = []
    var availabilityNote = {}
    data.map(x => {
      availabilityNote = {
        id: this.convertNullToEmpty(x.SpecialistAvailabilityNoteID),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        note: this.convertNullToEmpty(x.Note),
        addedBy: this.convertNullToEmpty(x.AddedBy),
        addedByName: this.convertNullToEmpty(x.UserName),
        dateAdded: this.convertNullToEmpty(x.DateAdded),
      }
      availabilityNotes.push(availabilityNote)
    })
    return availabilityNotes
  },
  formatClient(data, isNew) {
    var clients = []
    var client = {}
    var contact = {}
    data.map(x => {
      client = {
        id: this.convertNullToEmpty(x.ClientID),
        name: this.convertNullToEmpty(x.ClientName),
        city: this.convertNullToEmpty(x.City),
        state: this.convertNullToEmpty(x.State),
        postcode: this.convertNullToEmpty(x.Postcode),
        country: this.convertCountryCodeToName(this.convertNullToEmpty(x.Country)),
        branchName: this.convertNullToEmpty(x.BranchName),
        geoLocation: this.convertNullToEmpty(x.GeoLocation),
        phoneNumber: this.convertNullToEmpty(x.PhoneNumber),
        addressLine1: this.convertNullToEmpty(x.AddressLine1),
        addressLine2: this.convertNullToEmpty(x.AddressLine2),
        xeroClientCode: this.convertNullToEmpty(x.XeroClientCode),
        clientCategory: this.convertNullToEmpty(x.ClientCategory),
        clientAlignment: this.convertNullToEmpty(x.ClientAlignment),
        billingCategoryId: this.convertNullToEmpty(x.BillingCategoryID),
        prepaymentRequired: this.convertNumToBool(this.convertNullToEmpty(x.PrepaymentRequired)),
        paymentDeferralOption: this.convertNumToBool(this.convertNullToEmpty(x.PaymentDeferralOption)),
        redHealthMayContactClaimants: this.convertNumToBool(this.convertNullToEmpty(x.RedHealthMayContactClaimants)),
        redRescheduleEnabled: this.convertNumToBool(this.convertNullToEmpty(x.RedRescheduleEnabled)),
        zohoLink: this.convertNullToEmpty(x.ClientZohoLink),
        contacts: [],
        branchAdmins: [],
        branchAdminList: "",
      }
      client.fullAddress = this.getFullAddress(client)
      client.fullName = client.branchName != '' ? (client.name + ", " + client.branchName) : client.name
      if(x.Contacts != undefined) {
        x.Contacts.map(y => {
          contact = {
            mobile: this.convertNullToEmpty(y.Mobile),
            contactUserId: this.convertNullToEmpty(y.UserID),
            clientName: this.convertNullToEmpty(y.ClientName),
            surname: this.convertNullToEmpty(y.Surname),
            canLogin: this.convertNumToBool(y.CanLogin),
            firstName: this.convertNullToEmpty(y.FirstName),
            middleName: this.convertNullToEmpty(y.MiddleName),
            emailAddress: this.convertNullToEmpty(y.EmailAddress),
            phoneNumber1: this.convertNullToEmpty(y.PhoneNumber1),
            phoneNumber2: this.convertNullToEmpty(y.PhoneNumber2),
            primaryContact: this.convertNumToBool(y.PrimaryContact),
            clientContactId: this.convertNullToEmpty(y.ClientContactID),
            NotificationEmailAddress: this.convertNullToEmpty(y.NotificationEmailAddress),
            Salutation: this.convertNullToEmpty(y.Salutation),
            active: true,
            branchName: this.convertNullToEmpty(y.BranchName),
            userRoleName: this.convertNullToEmpty(y.UserRoleName),
            userId: this.convertNullToEmpty(y.UserID),
            userRoleId: this.convertNullToEmpty(y.UserRoleID),
            zohoClientContactId: this.convertNullToEmpty(y.ZohoClientContactID),
          }
          contact.fullName = this.getContactFullName(contact)
          if(contact.primaryContact) client.primaryContact = contact
          client.contacts.push(contact)
        })
      }

      if(x.BranchAdmins != undefined) {
        x.BranchAdmins.map((y, yi) => {
          client.branchAdmins.push({userName: this.convertNullToEmpty(y.UserName)})
          client.branchAdminList += this.convertNullToEmpty(y.UserName)
          if(yi < x.BranchAdmins.length - 1) client.branchAdminList += ", "
        })
      }
      clients.push(client)
    })
    !isNew && clients.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return clients
  },
  formatClientRequest(data) {
    var clientRequests = []
    var clientRequest = {}
    data.map(x => {
      clientRequest = {
        id: this.convertNullToEmpty(x.ClientAccountRequestID),
        firstName: this.convertNullToEmpty(x.FirstName),
        surname: this.convertNullToEmpty(x.Surname),
        email: this.convertNullToEmpty(x.BusinessEmailAddress),
        mobile: this.convertNullToEmpty(x.Mobile),
        companyName: this.convertNullToEmpty(x.CompanyName),
        companyBranchName: this.convertNullToEmpty(x.CompanyBranchName),
        addressLine1: this.convertNullToEmpty(x.AddressLine1),
        addressLine2: this.convertNullToEmpty(x.AddressLine2),
        city: this.convertNullToEmpty(x.City),
        state: this.convertNullToEmpty(x.State),
        postcode: this.convertNullToEmpty(x.PostCode),
        dateReceived: this.convertNullToEmpty(x.DateReceived),
        isActioned : this.convertNumToBool(this.convertNullToEmpty(x.IsActioned)),
        isDeleted : this.convertNumToBool(this.convertNullToEmpty(x.IsDeleted)),
      }
      clientRequest.companyFullName = clientRequest.companyBranchName != '' ? (clientRequest.companyName + ", " + clientRequest.companyBranchName) : clientRequest.companyName
      clientRequests.push(clientRequest)
    })
    clientRequests.sort((a, b) => {
      return a.firstName > b.firstName ? 1 : -1
    })
    return clientRequests
  },
  formatClientNotes(data) {
    var notes = []
    data.map(x => {
      notes.push({
        note: this.convertNullToEmpty(x.Note),
        userId: this.convertNullToEmpty(x.UserID),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientNoteId: this.convertNullToEmpty(x.ClientNoteID),
        internalOnly: this.convertNumToBool(this.convertNullToEmpty(x.InternalOnly)),
        noteDateTime: this.convertNullToEmpty(x.NoteDateTime),
        userName: this.convertNullToEmpty(x.UserName)
      })
    })
    return notes
  },
  formatRatecard(data) {
    var ratecard = []
    var rate = {}
    data.map(x => {
      rate = {
        cost: this.convertNullToEmpty(x.Cost),
        clientId: this.convertNullToEmpty(x.ClientID),
        branchName: this.convertNullToEmpty(x.BranchName),
        clientName: this.convertNullToEmpty(x.ClientName),
        bookingTypeId: this.convertNullToEmpty(x.BookingTypeID),
        bookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        specialistClientRateCode: this.convertNullToEmpty(x.SpecialistClientRateCode),
        specialistClientRateId: this.convertNullToEmpty(x.SpecialistClientRateID)
      }
      ratecard.push(rate)
    })
    return ratecard
  },

  // Main
  // Format specialist settings
  formatSpecialistSettings(data) {
    var setting = {}
    data.map(x => {
      setting = {
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        specialistSettingId: this.convertNullToEmpty(x.SpecialistSettingID),
        newBookingNotification: this.convertNumToBool(x.NewBookingNotification),
        bookingChange: this.convertNumToBool(x.BookingChange),
        availabilityReminder: this.convertNumToBool(x.AvailabilityReminder),
        availabilityReturned: this.convertNumToBool(x.AvailabilityReturned),
        weeklySchedule: this.convertNumToBool(x.WeeklySchedule),
        scheduleChange: this.convertNumToBool(x.ScheduleChange),
        reportsDue: this.convertNumToBool(x.ReportsDue),
        reportsOverdue: this.convertNumToBool(x.ReportsOverdue),
        bookingReminder: this.convertNumToBool(x.BookingReminder),
      }
    })
    return setting
  },
  formatQualification(data) {
    var qualifications = []
    var qualification = {}
    data.map(x => {
      qualification = {
        id: x.QualificationID,
        name: this.convertNullToEmpty(x.QualificationName),
      }
      qualifications.push(qualification)
    })
    qualifications.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return qualifications
  },
  formatPersonCondition(data) {
    var personConditions = []
    var personCondition = {}
    data.map(x => {
      personCondition = {
        id: x.PersonConditionID,
        name: this.convertNullToEmpty(x.ConditionName),
      }
      personConditions.push(personCondition)
    })
    personConditions.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return personConditions
  },
  formatFileTypes(data) {
    var fileTypes = []
    var fileType = {}
    data.map(x => {
      fileType = {
        id: x.FileTypeID,
        name: this.convertNullToEmpty(x.FileTypeName),
      }
      fileTypes.push(fileType)
    })
    return fileTypes
  },
  formatCertification(data) {
    var certifications = []
    var certification = {}
    data.map(x => {
      certification = {
        id: x.CertificationID,
        name: this.convertNullToEmpty(x.CertificationName),
      }
      certifications.push(certification)
    })
    certifications.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return certifications
  },
  formatCity(data) {
    var cities = []
    var city = {}
    data.map(x => {
      city = {
        id: this.convertNullToEmpty(x.CityID),
        name: this.convertNullToEmpty(x.CityName),
        regionId: this.convertNullToEmpty(x.RegionID),
        regionName: this.convertNullToEmpty(x.RegionName),
      }
      cities.push(city)
    })
    cities.sort((a, b) => {
      return a.regionName > b.regionName ? 1 : -1
    }).sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return cities
  },
  formatLocation(data) {
    var locations = []
    var location = {}
    data.map(x => {
      location = {
        id: this.convertNullToEmpty(x.LocationID),
        name: this.convertNullToEmpty(x.LocationName),
        address1: this.convertNullToEmpty(x.AddressLine1),
        address2: this.convertNullToEmpty(x.AddressLine2),
        cityId: this.convertNullToEmpty(x.CityID),
        cityName: this.convertNullToEmpty(x.CityName),
        state: this.convertNullToEmpty(x.State),
        postcode: this.convertNullToEmpty(x.Postcode),
        country: this.convertNullToEmpty(x.Country),
        regionId: this.convertNullToEmpty(x["RegionID "]),
        regionName: this.convertNullToEmpty(x.RegionName),
        geoLocation: this.convertNullToEmpty(x.GEOLocation),
        phoneNumber: this.convertNullToEmpty(x.PhoneNumber),
        specialistId: this.convertZeroToEmpty(this.convertNullToEmpty(x.SpecialistID)),
        latitude: this.convertNullToEmpty(x.Latitude),
        longitude: this.convertNullToEmpty(x.Longitude),
        cities: [],
        isOpen: false,
        newCity: false,
        incorrectCity: false,
      }
      location.shortLocation = this.getShortLocation(location)
      location.fullLocation = this.getFullLocation(location)
      locations.push(location)
    })
    locations.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return locations
  },
  formatRegion(data) {
    var regions = []
    var region = {}
    data.map(x => {
      region = {
        id: this.convertNullToEmpty(x.RegionID),
        name: this.convertNullToEmpty(x.RegionName),
        description: this.convertNullToEmpty(x.RegionDescription),
        state: this.convertNullToEmpty(x.State),
      }
      regions.push(region)
    })
    regions.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return regions
  },
  formatTravel(data) {
    var travels = []
    var travel = {}
    data.map(x => {
      travel = {
        id: this.convertNullToEmpty(x.SpecialistTravelsToID),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
        requiresTravel: this.convertNumToBool(this.convertNullToEmpty(x.RequiresTravel)),
        address1: this.convertNullToEmpty(x.AddressLine1),
        address2: this.convertNullToEmpty(x.AddressLine2),
        cityId: this.convertNullToEmpty(x.CityID),
        cityName: this.convertNullToEmpty(x.CityName),
        regionId: this.convertNullToEmpty(x.RegionID),
        regionName: this.convertNullToEmpty(x.RegionName),
        state: this.convertNullToEmpty(x.State),
        postcode: this.convertNullToEmpty(x.Postcode),
        country: DEFAULT_COUNTRY,
        isOpen: false,
      }
      travel.fullTravel = this.getFullTravel(travel)
      travels.push(travel)
    })
    travels.sort((a, b) => {
      return a.locationName > b.locationName ? 1 : -1
    })
    return travels
  },
  formatTravelAwaitingApproval(data) {
    var travels = []
    var travel = {}
    data.map(x => {
      travel = {
        id: this.convertNullToEmpty(x.SpecialistTravelItinerary),
        total: Number(this.convertNullToEmpty(x.TotalCost)).toFixed(2),
        dateCompleted: this.convertNullToEmpty(x.DateCompleted),
        specialistAvailabilityId: this.convertNullToEmpty(x.SpecialistAvailabilityID),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        title: this.convertNullToEmpty(x.Title),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
      }
      travel.fullName = this.getSpecialistFullName(travel)
      travels.push(travel)
    })
    return travels
  },
  formatSearchLog(data) {
    var searchLogs = []
    var searchLog = {}
    data.map(x => {
      searchLog = {
        id: this.convertNullToEmpty(x.SearchLogID),
        userId: this.convertNullToEmpty(x.UserID),
        userName: this.convertNullToEmpty(x.UserName),
        actionedByID: this.convertNullToEmpty(x.ActionedByID),
        actionedBy: this.convertNullToEmpty(x.ActionedBy ? x.ActionedBy : null),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        specialistName: this.convertNullToEmpty(x.Specialist),
        qualificationName: this.convertNullToEmpty(x.QualificationName),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        caseId: this.convertNullToEmpty(x.CaseID),
        caseReference: this.convertNullToEmpty(x.CaseReference),
        bookingType: this.convertNullToEmpty(x.BookingType),
        dateSearched: this.convertNullToEmpty(x.DateSearched),
        regionID: this.convertNullToEmpty(x.RegionID),
        regionName: this.convertNullToEmpty(x.RegionName),
        radius: this.convertNullToEmpty(x.Radius),
        dateStart: this.convertNullToEmpty(x.DateStart),
        dateEnd: this.convertNullToEmpty(x.DateEnd),
        numberofResults: this.convertNullToEmpty(x.NumberofResults),
        videoLinkAccepted: this.convertNullToEmpty(x.VideoLinkAccepted),  // videoLinkAvailable // 1: No, 2: Yes
        VideoOnly: this.convertNullToEmpty(x.VideoOnly),
        ShowAbuse: this.convertNullToEmpty(x.ShowAbuse),
        actionedById: this.convertNullToEmpty(x.ActionedByID),
        actionedByName: this.convertNullToEmpty(x.ActionedByUserName),
        specialistServiceName: this.convertNullToEmpty(x.SpecialistServiceName),
        cities: [],
        fullLocation: "",
        qualifications: [],
        qualificationList: "",
      }

      var city = {}
      if(x.City != undefined && x.City.length > 0) {
        x.City.map(y => {
          city = {
            cityName: this.convertNullToEmpty(y.CityName)
          }
          searchLog.cities.push(city)
        })
        searchLog.fullLocation = x.City.map(z => z.CityName + (searchLog.radius > 0 ? " (within " + searchLog.radius + "km)" : "")).join(', ')
      } else {
        searchLog.fullLocation = searchLog.regionName
      }

      var qualification = {}
      if(x.Qualification != undefined) {
        x.Qualification.map(y => {
          qualification = {
            qualificationName: this.convertNullToEmpty(y.QualificationName)
          }
          searchLog.qualifications.push(qualification)
        })
        searchLog.qualificationList = x.Qualification.map(z => z.QualificationName).join(", ")
      }

      searchLogs.push(searchLog)
    })
    return searchLogs
  },
  formatBookingType(data) {
    var bookingTypes = []
    var bookingType = {}
    data.map(x => {
      bookingType = {
        id: this.convertNullToEmpty(x.BookingTypeID),
        name: this.convertNullToEmpty(x.BookingTypeName),
        description: this.convertNullToEmpty(x['BookingType.BookingTypeDescription']),
        bookingTypeStatus: this.convertNullToEmpty(x.BookingTypeStatus),
      }
      bookingTypes.push(bookingType)
    })
    bookingTypes.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return bookingTypes
  },
  formatBookingStatus(data) {
    var bookingStatuses = []
    var bookingStatus = {}
    data.map(x => {
      bookingStatus = {
        id: this.convertNullToEmpty(x.BookingStatusID),
        name: this.convertNullToEmpty(x.StatusDescription),
      }
      bookingStatuses.push(bookingStatus)
    })
    // bookingStatuses.sort((a, b) => {
    //   return a.name > b.name ? 1 : -1
    // })
    return bookingStatuses
  },


  async logSha1(str) {
    const buffer = new TextEncoder('utf-8').encode(str);
    const digest = await window.crypto.subtle.digest('SHA-1', buffer);

    // Convert digest to hex string
    const result = Array.from(new Uint8Array(digest)).map(x => x.toString(16).padStart(2, '0')).join('').toUpperCase();
    return result
  },
    async passwordWeakCheck(password) {
    return await axios.get('https://api.pwnedpasswords.com/range/' + password)
  },
formatBookingHour(data) {
    var bookingHours = []
    var bookingHour = {}
    data.map(x => {
      bookingHour = {
        id: this.convertNullToEmpty(x.BookingHoursID),
        service: this.convertNullToEmpty(x.Service),
        bookingId: this.convertNullToEmpty(x.BookingID),
        numberHours: this.convertNullToEmpty(x.NumberHours),
        dateRequested: this.convertNullToEmpty(x.DateRequested),
        bookingHoursStatus: this.convertNullToEmpty(x.BookingHoursStatus),
        requestedBy: this.convertNullToEmpty(x.RequestedBy),
        requestedByUsername: this.convertNullToEmpty(x.RequestedByUsername),
      }
      bookingHours.push(bookingHour)
    })
    return bookingHours
  },
  formatClaimType(data) {
    var claimTypes = []
    var claimType = {}
    data.map(x => {
      claimType = {
        id: this.convertNullToEmpty(x.ClaimTypeID),
        name: this.convertNullToEmpty(x.ClaimTypeName),
      }
      claimTypes.push(claimType)
    })
    claimTypes.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return claimTypes
  },
  formatClientContact(data) {
    var contacts = []
    var contact = {}
    data.map(x => {
      contact = {
        mobile: this.convertNullToEmpty(x.Mobile),
        contactUserId: this.convertNullToEmpty(x.UserID),
        surname: this.convertNullToEmpty(x.Surname),
        canLogin: this.convertNumToBool(x.CanLogin),
        clientId: this.convertNullToEmpty(x.ClientID),
        firstName: this.convertNullToEmpty(x.FirstName),
        Salutation: this.convertNullToEmpty(x.Salutation),
        middleName: this.convertNullToEmpty(x.MiddleName),
        emailAddress: this.convertNullToEmpty(x.EmailAddress),
        NotificationEmailAddress: this.convertNullToEmpty(x.NotificationEmailAddress),
        phoneNumber1: this.convertNullToEmpty(x.PhoneNumber1),
        phoneNumber2: this.convertNullToEmpty(x.PhoneNumber2),
        primaryContact: this.convertNumToBool(x.PrimaryContact),
        isPrimaryContact: this.convertNumToBool(x.IsPrimaryContact),
        clientContactId: this.convertNullToEmpty(x.ClientContactID),
        branchName: this.convertNullToEmpty(x.BranchName),
        zohoClientContactLink: this.convertNullToEmpty(x.ZohoClientContactLink),
        clientName: this.convertNullToEmpty(x.ClientName),
        userRoleName: this.convertNullToEmpty(x.UserRoleName),
        userId: this.convertNullToEmpty(x.UserID),
        active: this.convertNullToEmpty(x.Active),
        userRoleId: this.convertNullToEmpty(x.UserRoleID),
        zohoClientContactId: this.convertNullToEmpty(x.ZohoClientContactID)
      }
      contact.fullName = contact.firstName + " " + (contact.middleName != '' ? contact.middleName + " " : "") + contact.surname
      contacts.push(contact)
    })
    return contacts
  },
  formatBookingAction(data) {
    var bookingActions = []
    var bookingAction = {}
    data.map(x => {
      bookingAction = {
        ZohoAdminOwner: this.convertNullToEmpty(x.ZohoAdminOwner),
        id: this.convertNullToEmpty(x.BookingActionRequestID),
        bookingId: this.convertNullToEmpty(x.BookingID),
        status: this.convertNullToEmpty(x.BookingActionRequestStatus),
        firstName: this.convertNullToEmpty(x.FirstName),
        surname: this.convertNullToEmpty(x.Surname),
        requestedAction: this.convertNullToEmpty(x.RequestedAction),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        dateRequested: this.convertNullToEmpty(x.DateRequested),
        requestedBy: this.convertNullToEmpty(x.RequestedBy),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        clientBranchName: this.convertNullToEmpty(x.ClientName + ' - ' + x.BranchName),
        caseId: this.convertNullToEmpty(x.CaseID),
        branchName: this.convertNullToEmpty(x.BranchName),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
        assignedToName: this.convertNullToEmpty(x.AssignedToName),
        zohoLink: this.convertNullToEmpty(x.ZohoLink),
        userName: this.convertNullToEmpty(x.RHOUserName),
      }
      bookingAction.clientContactFullName = bookingAction.firstName + " " + bookingAction.surname
      bookingAction.claimantFullName = this.getClaimantFullName(bookingAction)
      bookingActions.push(bookingAction)
    })
    return bookingActions
  },
  formatBookingRequest(data) {
    var bookingRequests = []
    var bookingRequest = {}
    data.map(x => {
      bookingRequest = {
        id: this.convertNullToEmpty(x.BookingRequestID),
        status: this.convertNullToEmpty(x.BookingRequestStatus),
        dateFrom: this.convertNullToEmpty(x.DateFrom),
        dateTo: this.convertNullToEmpty(x.DateTo),
        bookingId: this.convertNullToEmpty(x.BookingID),
        bookingTypeId: this.convertNullToEmpty(x.BookingTypeID),
        bookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        note: this.convertNullToEmpty(x.Notes),
        filePath: this.convertNullToEmpty(x.FilePath),
        mimeType: this.convertNullToEmpty(x.MimeType),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
        branchName: this.convertNullToEmpty(x.BranchName),
        fileTypeId: this.convertNullToEmpty(x.FileTypeID),
        uploadedBy: this.convertNullToEmpty(x.UploadedBy),
        submittedBy: this.convertNullToEmpty(x.SubmittedBy),
        userName: x.RHOUserName != undefined ? this.convertNullToEmpty(x.RHOUserName) : this.convertNullToEmpty(x.UserName),
        dateSubmitted: this.convertNullToEmpty(x.DateSubmitted),
        videoLinkAccepted: this.convertNumToBool(this.convertNullToEmpty(x.VideoLinkAccepted)),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        title: this.convertNullToEmpty(x.Title),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        caseId: this.convertNullToEmpty(x.CaseID),
        regionId: this.convertNullToEmpty(x.RegionID),
        regionName: this.convertNullToEmpty(x.RegionName),
        cityId: this.convertNullToEmpty(x.CityID),
        cityName: this.convertNullToEmpty(x.CityName),
        claimTypeId: this.convertNullToEmpty(x.ClaimTypeID),
        claimTypeName: this.convertNullToEmpty(x.ClaimTypeName),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        dateOfBirth: this.convertNullToEmpty(x.DateofBirth),
        bookingRequestFileId: this.convertNullToEmpty(x.BookingRequestFileID),
        originalBookingId: this.convertNullToEmpty(x.OriginalBookingID),
        bodyAreaOther: this.convertNullToEmpty(x.BodyAreaOther),
        bookingRequestFile: [],
        assignedToName: this.convertNullToEmpty(x.AssignedToName),
        bodyAreas: [],
        bodyAreaList: "",
        qualifications: [],
        qualificationList: "",
        personConditions: [],
        personConditionList: "",
      }
      bookingRequest.fullName = this.getSpecialistFullName(bookingRequest)
      bookingRequest.fullLocation = (bookingRequest.cityName != "" ? bookingRequest.cityName + ", " : "") + bookingRequest.regionName
      bookingRequest.claimantFullName = this.getClaimantFullName(bookingRequest)
      bookingRequest.name = bookingRequest.claimantFullName + " " + this.formatDate(bookingRequest.dateOfBirth, "DD/MM/YYYY") + " (" + bookingRequest.id + ")"

      if(x.BookingRequestFile != undefined) {
        x.BookingRequestFile.map(y => {
          bookingRequest.bookingRequestFile.push({
            filePath: this.convertNullToEmpty(y.FilePath),
            fileName: this.convertNullToEmpty(y.FileName),
            mimeType: this.convertNullToEmpty(y.MimeType),
            fileTypeId: this.convertNullToEmpty(y.FileTypeID),
            uploadedBy: this.convertNullToEmpty(y.UploadedBy),
            uploadedByName: this.convertNullToEmpty(y.RHOUserName),
            bookingRequestFileId: this.convertNullToEmpty(y.BookingRequestFileID),
          })
        })
      }

      if(x.BodyAreas != undefined) {
        x.BodyAreas.map((y, yi) => {
          bookingRequest.bodyAreas.push({
            bodyAreaId: this.convertNullToEmpty(y.BodyAreaID),
            bodyArea: this.convertNullToEmpty(y.BodyArea),
          })
          bookingRequest.bodyAreaList += y.BodyArea
          if(yi < x.BodyAreas.length - 1) bookingRequest.bodyAreaList += ", "
        })
      }

      if (x.Qualifications != undefined) {
        x.Qualifications.map((y, yi) => {
          bookingRequest.qualifications.push({
            qualificationId: this.convertNullToEmpty(y.QualificationID),
            qualificationName: this.convertNullToEmpty(y.QualificationName),
          })
          bookingRequest.qualificationList += y.QualificationName
          if(yi < x.Qualifications.length - 1) bookingRequest.qualificationList += ", "
        })
      }

      if (x.PersonCondition != undefined) {
        x.PersonCondition.map((y, yi) => {
          bookingRequest.personConditions.push({
            personConditionId: this.convertNullToEmpty(y.PersonConditionID),
            personConditionName: this.convertNullToEmpty(y.PersonCondition),
          })
          bookingRequest.personConditionList += decodeURI(y.PersonCondition)
          if(yi < x.PersonCondition.length - 1) bookingRequest.personConditionList += ", "
        })
      }

      bookingRequest.requirements = this.addComma(bookingRequest.bodyAreaList) + bookingRequest.personConditionList
      bookingRequests.push(bookingRequest)
    })
    return bookingRequests
  },
  formatBookingClash(data) {
    var bookingClashes = []
    var bookingClash = {}
    data.map(x => {
      bookingClash = {
        id: this.convertNullToEmpty(x.BookingClashID),
        bookingId: this.convertNullToEmpty(x.BookingID),
        bookingStatus: this.convertNullToEmpty(x.BookingStatus),
        status: this.convertNullToEmpty(x.Status),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        title: this.convertNullToEmpty(x.Title),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        clientBranchName: this.convertNullToEmpty(x.ClientName + ' - ' + x.BranchName),
        branchName: this.convertNullToEmpty(x.BranchName),
        clashingBookingId: this.convertNullToEmpty(x.ClashingBookingID),
        clashBookingStatus: this.convertNullToEmpty(x.ClashingBookingStatus),
        clashingClientId: this.convertNullToEmpty(x.ClashingClientID),
        clashingClientName: this.convertNullToEmpty(x.ClashingClientName),
      }
      bookingClash.fullName = this.getSpecialistFullName(bookingClash)
      bookingClash.claimantFullName = this.getClaimantFullName(bookingClash)
      bookingClash.statusName = this.getBookingClashStatus(bookingClash.status)
      bookingClashes.push(bookingClash)
    })
    return bookingClashes
  },
  formatBookingAwaitingApproval(data) {
    var bookings = []
    var booking = {}
    data.map(x => {
      booking = {
        id: this.convertNullToEmpty(x.BookingID),
        total: Number(this.convertNullToEmpty(x.TotalOfPurchaseOrderItems)),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        firstName: this.convertNullToEmpty(x.SpecialistFirstName),
        lastName: this.convertNullToEmpty(x.SpecialistSurname),
        title: this.convertNullToEmpty(x.SpecialistTitle),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        branchName: this.convertNullToEmpty(x.ClientBranchName),
        dateReportCompleted: this.convertNullToEmpty(x.DateReportCompleted),
      }
      booking.fullName = this.getSpecialistFullName(booking)
      booking.claimantFullName = this.getClaimantFullName(booking)
      bookings.push(booking)
    })
    return bookings
  },
  formatBooking(data) {
    var bookings = []
    var booking = {}
    var clientContact = {}
    data.map(x => {
      booking = {
        UpdatedTime: this.convertNullToEmpty(x.UpdatedTime),
        ZohoAdminOwner: this.convertNullToEmpty(x.ZohoAdminOwner),
        id: this.convertNullToEmpty(x.BookingID),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
        assignedToName: this.convertNullToEmpty(x.AssignedToName),
        specialistAvailabilityId: this.convertNullToEmpty(x.AvailabilityID),
        videoLink: this.convertNullToEmpty(x.VideoLink),
        videoLinkUrl: this.convertNullToEmpty(x.VideoLinkURL),
        claimantStateDuringBooking: this.convertNullToEmpty(x.ClaimantStateDuringBooking),
        claimantBookingDateTime: this.convertNullToEmpty(x.ClaimantBookingDateTime),
        billTo: this.convertNullToEmpty(x.BillTo),
        reportTo: this.convertNullToEmpty(x.ReportTo),
        clientName: this.convertNullToEmpty(x.ClientName),
        bookingTypeId: this.convertNullToEmpty(x.BookingTypeID),
        bookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        bookingDateTime: this.convertNullToEmpty(x.BookingDateTime),
        bookingEndTime: this.convertNullToEmpty(x.BookingEndTime),
        OrganizeRequired: this.convertNumToBool(x.OrganizeRequired),
        caseId: this.convertNullToEmpty(x.CaseID),
        caseReference: this.convertNullToEmpty(x.CaseReference),
        callClientAfterAppointment: this.convertNumToBool(this.convertNullToEmpty(x.CallClientAfterAppointment)),
        claimTypeId: this.convertNullToEmpty(x.ClaimTypeID),
        claimTypeName: this.convertNullToEmpty(x.ClaimTypeName),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        clientContactPhoneNumber1:  this.convertNullToEmpty(x.ClientContactPhoneNumber1),
        clientContactEmailAddress:  this.convertNullToEmpty(x.ClientContactEmailAddress),
        contactFullName:  this.convertNullToEmpty(x.ClientContactFullName),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantEmail: this.convertNullToEmpty(x.ClaimantEmail),
        dateOfBirth: this.convertNullToEmpty(x.DateofBirth),
        claimantMobile: this.convertNullToEmpty(x.ClaimantMobile),
        claimantPhone1: this.convertNullToEmpty(x.ClaimantPhone1),
        claimantPhone2: this.convertNullToEmpty(x.ClaimantPhone2),
        claimantAddressLine1: this.convertNullToEmpty(x.ClaimantAddressLine1),
        claimantAddressLine2: this.convertNullToEmpty(x.ClaimantAddressLine2),
        claimantState: this.convertNullToEmpty(x.ClaimantState),
        claimantCity: this.convertNullToEmpty(x.ClaimantCity),
        claimantPostcode: this.convertNullToEmpty(x.ClaimantPostcode),
        claimantCountry: this.convertNullToEmpty(x.ClaimantCountry),
        PreferredGivenName: this.convertNullToEmpty(x.PreferredGivenName),
        Salutation: this.convertNullToEmpty(x.Salutation),
        note: this.convertNullToEmpty(x.Note),
        commencementDate: this.convertNullToEmpty(x.CommencementDate),
        claimantConfirmed: this.convertNullToEmpty(x.ClaimantConfirmed),
        claimantConsentReceived: this.convertNullToEmpty(x.ClaimantConsentReceived),
        accidentDate: this.convertNullToEmpty(x.AccidentDate),
        overPeriodofTime: this.convertNumToBool(x.OverPeriodofTime),
        videoLinkTestStatus: this.convertNullToEmpty(x.VideoLinkTestStatus),
        hasVideoLink: this.convertNumToBool(x.VideoLink),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        private: this.convertNumToBool(this.convertNullToEmpty(x.Private)),
        otfceRequired: this.convertNumToBool(this.convertNullToEmpty(x.OTFCERequired)),
        bookingStatus: Number(this.convertNullToEmpty(x.BookingStatus)),
        trackingStatus : this.convertNullToEmpty(x.BookingTrackingStatus),
        interpreterRequired: this.convertNumToBool(this.convertNumToBool(x.InterpreterRequired)),
        interpreterDetails: this.convertNullToEmpty(x.InterpreterDetails),
        surrogateRequired: this.convertNumToBool(x.SurrogateRequired),
        surrogateDetails: this.convertNullToEmpty(x.SurrogateDetails),
        willAcceptEarlier: this.convertNumToBool(this.convertNullToEmpty(x.WillAcceptEarlier)),
        redHealthMayContactClaimant: this.convertNumToBool(this.convertNullToEmpty(x.RedHealthMayContactClaimant)),
        specialistTitle: x.SpecialistTitle ? this.convertNullToEmpty(x.SpecialistTitle) : this.convertNullToEmpty(x.Title),
        specialistFirstName: x.SpecialistFirstName ? this.convertNullToEmpty(x.SpecialistFirstName) : this.convertNullToEmpty(x.FirstName),
        specialistSurname: x.SpecialistSurname ? this.convertNullToEmpty(x.SpecialistSurname) : this.convertNullToEmpty(x.Surname),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
        dueDate: this.convertNullToEmpty(x.DueDate),
        paperworkDue: this.convertNullToEmpty(x.PaperworkDue),
        onBehalfOf: this.convertNullToEmpty(x.OnBehalfOf),
        feeDeferralRequired: this.convertNumToBool(this.convertNullToEmpty(x.FeeDeferralRequired)),
        paymentDeferralOption: this.convertNumToBool(x.PaymentDeferralOption),
        prepaymentRequired: this.convertNumToBool(this.convertNullToEmpty(x.PrePaymentRequired)),
        specialistConfirmedHours: this.convertNumToBool(x.SpecialistConfirmedHours),
        clientId: this.convertNullToEmpty(x.ClientID),
        paperworkStatus: this.convertNullToEmpty(x.PaperworkStatus),
        dateCreated: this.convertNullToEmpty(x.DateCreated),
        createdBy: this.convertNullToEmpty(x.createdBy),
        zohoLink: this.convertNullToEmpty(x.ZohoLink),
        caseOwner: this.convertNullToEmpty(x.CaseOwner),
        caseOwnerName: this.convertNullToEmpty(x.CaseOwnerName),
        caseOwnerEmailAddress: this.convertNullToEmpty(x.CaseOwnerEmailAddress),
        caseOwnerMobile: this.convertNullToEmpty(x.CaseOwnerMobile),
        bookingPurchaseOrderId: this.convertNullToEmpty(x.BookingPurchaseOrderID),
        timesRescheduled: this.convertNullToEmpty(x.TimesRescheduled),
        paid: this.convertNumToBool(x.Paid),
        reportToEmailAddress: this.convertNullToEmpty(x.ReportToEmailAddress),
        requiresTravel: this.convertNumToBool(this.convertNullToEmpty(x.RequiresTravel)),
        videoLinkAvailable: this.convertNullToEmpty(x.VideoLinkAvailable),
        VideoOnly: this.convertNullToEmpty(x.VideoOnly),
        branchName: this.convertNullToEmpty(x.BranchName),
        xeroClientCode: this.convertNullToEmpty(x.XeroClientCode),
        xeroCode: this.convertNullToEmpty(x.XeroLocationCode),
        specialistFullName: this.convertNullToEmpty(x.SpecialistName),
        bodyAreaOther: this.convertNullToEmpty(x.BodyAreaOther),
        originaleBookingId: this.convertNullToEmpty(x.OriginaleBookingID),
        lolReceived: this.convertNullToEmpty(x.LOIReceived),
        briefReceived: this.convertNullToEmpty(x.BriefReceived),
        additionalPaperworkReceived: this.convertNullToEmpty(x.AdditionalPaperworkReceived),
        paperworkProcessed: this.convertNullToEmpty(x.PaperworkProcessed),
        ClaimantLocation: this.convertNullToEmpty(x.ClaimantLocation),
        ClaimantBookingDateTime: this.convertNullToEmpty(x.ClaimantBookingDateTime),
        specialistFinaliseBookingDate: x.SpecialistFinaliseBookingDate && x.SpecialistFinaliseBookingDate.indexOf('0000-00') < 0 ? true : false,
        bodyAreas: [],
        clientContacts: [],
        personConditions: [],
        bookingTests: [],
        bookingHours: [],
        qualification: [],
        bookingNotes: [],
        billingNotes: [],
        bookingFiles: [],
      }
      if(x.Qualification && x.Qualification.length > 0) {
        booking.qualification = x.Qualification.map(item => {
          return item?.QualificationName
        })
      }
      booking.specialist = {
        title: booking.specialistTitle,
        firstName: booking.specialistFirstName,
        lastName: booking.specialistSurname
      }
      booking.sFullName = this.getSpecialistFullName(booking.specialist)
      booking.name = "Booking ID " + booking.id
      booking.claimantFullName = this.getClaimantFullName(booking)
      // booking.clientContactFullName = booking.clientFirstName + " " + booking.clientSurname
      booking.specialistFullName =  booking.specialistTitle + " " + booking.specialistFirstName + " " +  booking.specialistSurname
      booking.fullBodyAreas = this.getFullBodyAreas(booking)

      if(booking.claimantConfirmed == '') booking.claimantConfirmed = false
      if(booking.claimantConsentReceived == '') booking.claimantConsentReceived = false

      booking.bookingStatusName = this.getBookingStatus(booking.bookingStatus)
      booking.paperworkStatusName = this.getPaperworkStatus(booking.paperworkStatus)

      if(x.ClientContact != undefined) {
        x.ClientContact.map(y => {
          clientContact = {
            clientContactFirstName: this.convertNullToEmpty(y.ClientContactFirstName),
            clientContactSurname: this.convertNullToEmpty(y.ClientContactSurname),
            clientContactMiddleName: this.convertNullToEmpty(y.ClientContactMiddleName),
            email: y.ClientContactEmailAddress != undefined ? this.convertNullToEmpty(y.ClientContactEmailAddress) : this.convertNullToEmpty(y.EmailAddress),
            phone: y.ClientContactPhoneNumber1 != undefined ? this.convertNullToEmpty(y.ClientContactPhoneNumber1) : this.convertNullToEmpty(y.PhoneNumber1),
            phone2: y.ClientContactPhoneNumber2 != undefined ? this.convertNullToEmpty(y.ClientContactPhoneNumber2) : this.convertNullToEmpty(y.PhoneNumber2),
            mobile: y.ClientContactMobile != undefined ? this.convertNullToEmpty(y.ClientContactMobile) : this.convertNullToEmpty(y.Mobile),
            userId: this.convertNullToEmpty(y.UserID),
            NotificationEmailAddress: this.convertNullToEmpty(y.NotificationEmailAddress),
          }
          clientContact.clientContactFullName = clientContact.clientContactFirstName + ' ' + clientContact.clientContactSurname
          booking.clientContacts.push(clientContact)
          if (y.UserID === booking.caseOwner) {
            booking.contact = clientContact.clientContactFullName
            booking.phoneEmail = this.getFullPhoneEmail(clientContact.phone, clientContact.email)
          }
        })
      }

      //booking.contact = booking.clientContacts.length > 0 ? booking.clientContacts.find((x) => x.userId == booking.caseOwner).clientContactFullName : ""
      //booking.phoneEmail = booking.clientContacts.length > 0 ? this.getFullPhoneEmail(booking.clientContacts[0].phone, booking.clientContacts[0].email) : this.getFullPhoneEmail(booking.caseOwnerMobile,booking.caseOwnerEmailAddress)

      if (x.BookingHours != undefined) {
        x.BookingHours.map(y => {
          booking.bookingHours.push({
            id: this.convertNullToEmpty(y.BookingHoursID),
            status: this.convertNullToEmpty(y.Status),
            service: this.convertNullToEmpty(y.Service),
            numberOfHours: this.convertNullToEmpty(y.NumberOfHours),
            isNew: false,
            role:this.convertNullToEmpty(y.RoleName),
            reason:this.convertNullToEmpty(y.Reason),
          })
        })
      }
      if (x.BookingTests != undefined) {
        x.BookingTests.map(y => {
          booking.bookingTests.push({
            id: this.convertNullToEmpty(y.BookingTestID),
            testName: this.convertNullToEmpty(y.TestName),
            dateOrdered: (y.DateOrdered != "0000-00-00" && y.DateOrdered != null ? moment(this.convertNullToEmpty(y.DateOrdered), "YYYY-MM-DD").format("DD/MM/YYYY") : ''),
            orderedFrom: this.convertNullToEmpty(y.OrderedFrom),
            dateReceived: (y.DateReceived != "0000-00-00" && y.DateReceived != null ? moment(this.convertNullToEmpty(y.DateReceived), "YYYY-MM-DD").format("DD/MM/YYYY") : ''),
            isNew: false,
          })
        })
      }
      if (x.BookingNote != undefined) {
        x.BookingNote.map(y => {
          booking.bookingNotes.push({
            id: this.convertNullToEmpty(y.BookingNoteID),
            userId: this.convertNullToEmpty(y.UserID),
            note: this.convertNullToEmpty(y.Note),
            noteDateTime: this.convertNullToEmpty(y.NoteDateTime),
            userName: this.convertNullToEmpty(y.UserName),
            isNew: false,
            deleted: this.convertNumToBool(y.Deleted),
            internalOnly: this.convertNullToEmpty(y.InternalOnly)
          })
        })
      }
      if (x.BillingNote) {
        x.BillingNote.map(y => {
          booking.billingNotes.push({
            id: this.convertNullToEmpty(y.BillingNoteID),
            userId: this.convertNullToEmpty(y.UserID),
            note: this.convertNullToEmpty(y.Note),
            noteDateTime: this.convertNullToEmpty(y.NoteDateTime),
            userName: this.convertNullToEmpty(y.UserName),
            isNew: false,
            deleted: this.convertNumToBool(y.Deleted),
          })
        })
      }

      if (x.BodyAreas != undefined) {
        x.BodyAreas.map(y => {
          booking.bodyAreas.push({
            bodyArea: this.convertNullToEmpty(y.BodyArea),
            bodyAreaId : this.convertNullToEmpty(y.BodyAreaID),
            bookingBodyAreaId: this.convertNullToEmpty(y.BookingBodyAreaID),
          })
        })
      }
      if (x.PersonConditions != undefined) {
        x.PersonConditions.map(y => {
          booking.personConditions.push({
            name: this.convertNullToEmpty(y.ConditionName),
            id : this.convertNullToEmpty(y.PersonConditionID),
            bookingPersonConditionId: this.convertNullToEmpty(y.BookingPersonConditionID),
          })
        })
      }

      // if (x.BodyAreas != undefined) {
      //   x.BodyAreas.map(y => {
      //     booking.bodyAreas.push({
      //       bodyArea: this.convertNullToEmpty(y.BodyArea),
      //     })
      //   })
      // }
      // if (x.PersonConditions != undefined) {
      //   x.PersonConditions.map(y => {
      //     booking.personConditions.push({
      //       conditionName: this.convertNullToEmpty(y.ConditionName),
      //     })
      //   })
      // }

      bookings.push(booking)
    })
    return bookings
  },
  formatBookingAwaitingPaperwork(data) {
    var bookings = []
    var booking = {}
    data.map(x => {
      booking = {
        id: this.convertNullToEmpty(x.BookingID),
        caseId: this.convertNullToEmpty(x.CaseID),
        dueDate: this.convertNullToEmpty(x.DueDate),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        claimTypeID: this.convertNullToEmpty(x.ClaimTypeID),
        claimTypeName: this.convertNullToEmpty(x.ClaimTypeName),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        clientContactId: this.convertNullToEmpty(x.ClientContactID),
        clientContactFirstName: this.convertNullToEmpty(x.ClientContactFirstName),
        clientContactSurname: this.convertNullToEmpty(x.ClientContactSurName),
        clientContactMiddleName: this.convertNullToEmpty(x.ClientContactMiddleName),
        clientContactEmail: this.convertNullToEmpty(x.ClientContactEmailAddress),
        clientContactMobile: this.convertNullToEmpty(x.ClientContactMobile),
        email: this.convertNullToEmpty(x.EmailAddress),
        phone: this.convertNullToEmpty(x.Mobile),
        phoneNumberPrimary: this.convertNullToEmpty(x.PhoneNumberPrimary),
        phoneNumberSecondary: this.convertNullToEmpty(x.PhoneNumberSecondary),
        paperworkDue: this.convertNullToEmpty(x.PaperworkDue),
        paperworkStatus: this.convertNullToEmpty(x.PaperworkStatus),
        lolReceived: this.convertNullToEmpty(x.LOIReceived),
        briefReceived: this.convertNullToEmpty(x.BriefReceived),
        additionalPaperworkReceived: this.convertNullToEmpty(x.AdditionalPaperworkReceived),
        paperworkProcessed: this.convertNullToEmpty(x.PaperworkProcessed),
        bookingDateTime: this.convertNullToEmpty(x.BookingDateTime),
        BookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        BookingTypeID: this.convertNullToEmpty(x.BookingTypeID),
        redHealthMayContactClaimant: this.convertNullToEmpty(x.RedHealthMayContactClaimant),
      }
      booking.name = "Booking ID " + booking.id
      booking.claimantFullName = this.getClaimantFullName(booking)
      booking.clientContactFullName = this.getClientContactFullName(booking)
      booking.paperworkStatusName = this.getPaperworkStatus(booking.paperworkStatus)

      bookings.push(booking)
    })
    return bookings
  },

  formatBookingAwaitingConsent(data) {
    var bookings = []
    var booking = {}
    data.map(x => {
      booking = {
        id: this.convertNullToEmpty(x.BookingID),
        ZohoAdminOwner: this.convertNullToEmpty(x.ZohoAdminOwner),
        specialistFullName: this.convertNullToEmpty(x.Title + ' ' + x.FirstName + ' ' + x.Surname),
        assignedToName: this.convertNullToEmpty(x.AssignedToName),
        specialistAvailabilityId: this.convertNullToEmpty(x.AvailabilityID),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
        bookingDateTime: this.convertNullToEmpty(x.BookingDateTime),
        bookingEndTime: this.convertNullToEmpty(x.BookingEndTime),
        redHealthMayContactClaimant: this.convertNullToEmpty(x.BookingRedHealthMayContactClaimant),
        branchName: this.convertNullToEmpty(x.BranchName),
        bookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        bookingTypeId: this.convertNullToEmpty(x.BookingTypeID),
        claimantEmail: this.convertNullToEmpty(x.CasesClaimantEmail),
        claimantFirstName: this.convertNullToEmpty(x.CasesClaimantFirstName),
        claimantSurname: this.convertNullToEmpty(x.CasesClaimantSurname),
        claimantGivenNames: this.convertNullToEmpty(x.CasesClaimantGivenNames),
        claimantMobile: this.convertNullToEmpty(x.CasesClaimantMobile),
        claimantPhone1: this.convertNullToEmpty(x.CasesClaimantPhone1),
        claimantPhone2: this.convertNullToEmpty(x.CasesClaimantPhone2),
        clientContactEmail: this.convertNullToEmpty(x.ClientContactEmailAddress),
        clientContactFirstName: this.convertNullToEmpty(x.ClientContactFirstName),
        clientContactMiddleName: this.convertNullToEmpty(x.ClientContactMiddleName),
        clientContactMobile: this.convertNullToEmpty(x.ClientContactMobile),
        clientContactPhoneNumber1: this.convertNullToEmpty(x.ClientContactPhoneNumber1),
        clientContactPhoneNumber2: this.convertNullToEmpty(x.ClientContactPhoneNumber2),
        clientContactSurname: this.convertNullToEmpty(x.ClientContactSurname),
        clientName: this.convertNullToEmpty(x.ClientName),
        hasVideoLink: this.convertNumToBool(x.VideoLink),
        videoLinkTestStatus: this.convertNullToEmpty(x.VideoLinkTestStatus),
        lolReceived: this.convertNullToEmpty(x.LOIReceived),
        briefReceived: this.convertNullToEmpty(x.BriefReceived),
        additionalPaperworkReceived: this.convertNullToEmpty(x.AdditionalPaperworkReceived),
        paperworkProcessed: this.convertNullToEmpty(x.PaperworkProcessed),
      }
      booking.claimantFullName = this.getClaimantFullName(booking)
      booking.clientContactFullName = this.getClientContactFullName(booking)
      bookings.push(booking)
    })
    return bookings
  },

  formatSpecialistBooking(data, isCalendar = true) {
    var bookings = []
    var bookingDetails = {}
    var casesDetails = {}
    var clientDetails = {}
    var clientContactDetails = {}
    var specialistDetails = {}
    var calendarEvent = {}
    var location = {}
    var booking = {}
    data.map(x => {
      bookingDetails = {
        id: this.convertNullToEmpty(x.BookingID),
        specialistAvailabilityId: this.convertNullToEmpty(x.AvailabilityID),
        AvailabilityID: this.convertNullToEmpty(x.AvailabilityID),
        caseId: this.convertNullToEmpty(x.CaseID),
        dueDate: this.convertNullToEmpty(x.DueDate),
        private: this.convertNullToEmpty(x.Private),
        zohoLink: this.convertNullToEmpty(x.ZohoLink),
        createdBy: this.convertNullToEmpty(x.CreatedBy),
        videoLink: this.convertNullToEmpty(x.VideoLink),
        videoLinkUrl: this.convertNullToEmpty(x.VideoLinkURL),
        claimantStateDuringBooking: this.convertNullToEmpty(x.ClaimantStateDuringBooking),
        claimantBookingDateTime: this.convertNullToEmpty(x.ClaimantBookingDateTime),
        locationId: this.convertNullToEmpty(x.LocationID),
        dateCreated: this.convertNullToEmpty(x.DateCreated),
        paperworkDue: this.convertNullToEmpty(x.PaperworkDue),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        status: this.convertNullToEmpty(x.BookingStatus),
        typeId: this.convertNullToEmpty(x.BookingTypeID),
        bookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        dateTime: this.convertNullToEmpty(x.BookingDateTime),
        startTime: this.convertNullToEmpty(x.BookingDateTime),
        endTime: this.convertNullToEmpty(x.BookingEndTime),
        paperworkStatus: this.convertNullToEmpty(x.PaperworkStatus),
        claimantConfirmed: this.convertNullToEmpty(x.ClaimantConfirmed),
        ClaimantLocation: this.convertNullToEmpty(x.ClaimantLocation),
        trackingStatus: this.convertNullToEmpty(x.BookingTrackingStatus),
        claimantConsentReceived: this.convertNullToEmpty(x.ClaimantConsentReceived),
        interpreterDetails: this.convertNullToEmpty(x.InterpreterDetails),
        surrogateDetails: this.convertNullToEmpty(x.SurrogateDetails),
        redHealthMayContactClaimant: this.convertNullToEmpty(x.RedHealthMayContactClaimant),
        prepaymentRequired: this.convertNumToBool(x.PrepaymentRequired),
        paid: this.convertNumToBool(x.Paid),
        bodyAreaOther : this.convertNullToEmpty(x.BodyAreaOther),
        PreferredGivenName: this.convertNullToEmpty(x.PreferredGivenName),
        bookingFiles: [],
        bookingTests: [],
        bodyAreas: [],
        fullBodyAreas: "",
        personConditions: [],
        fullPersonConditions: "",
      }
      if(bookingDetails.startTime.length > 0) {
        bookingDetails.startTime = bookingDetails.startTime.slice(11, bookingDetails.startTime.length - 1)
      }

      if (x.BodyAreas != undefined) {
        var bodyAreas = []
        x.BodyAreas.map(y => {
          bodyAreas.push({
            bodyArea: this.convertNullToEmpty(y.BodyArea)
          })
        })
        bookingDetails.bodyAreas = bodyAreas
        bookingDetails.fullBodyAreas = this.getFullBodyAreas(bookingDetails)
      }
      if (x.PersonConditions != undefined) {
        var personConditions = []
        x.PersonConditions.map(y => {
          personConditions.push({
            name: this.convertNullToEmpty(y.ConditionName),
          })
        })
        bookingDetails.personConditions = personConditions
        bookingDetails.fullPersonConditions = this.getFullPersonConditions(bookingDetails)
      }

      if (x.BookingTests != undefined) {
        x.BookingTests.map(y => {
          bookingDetails.bookingTests.push({
            id: this.convertNullToEmpty(y.BookingTestID),
            testName: this.convertNullToEmpty(y.TestName),
            dateOrdered: (y.DateOrdered != "0000-00-00" && y.DateOrdered != null ? moment(this.convertNullToEmpty(y.DateOrdered), "YYYY-MM-DD").format("DD/MM/YYYY") : ''),
            orderedFrom: this.convertNullToEmpty(y.OrderedFrom),
            dateReceived: (y.DateReceived != "0000-00-00" && y.DateReceived != null ? moment(this.convertNullToEmpty(y.DateReceived), "YYYY-MM-DD").format("DD/MM/YYYY") : ''),
            isNew: false,
          })
        })
      }
      casesDetails = {
        clientId : this.convertNullToEmpty(x.ClientID),
        caseOwner : this.convertNullToEmpty(x.CaseOwner),
        claimTypeId : this.convertNullToEmpty(x.ClaimTypeID),
        claimTypeName : this.convertNullToEmpty(x.ClaimTypeName),
        claimantMobile : this.convertNullToEmpty(x.ClaimantMobile),
        claimantPhone1 : this.convertNullToEmpty(x.ClaimantPhone1),
        claimantPhone2 : this.convertNullToEmpty(x.ClaimantPhone2),
        claimantSurname : this.convertNullToEmpty(x.ClaimantSurname),
        claimantFirstName : this.convertNullToEmpty(x.ClaimantFirstName),
        claimantGivenNames : this.convertNullToEmpty(x.ClaimantGivenNames),
        claimantFullName: this.convertNullToEmpty(x.ClaimantFirstName) + " " + this.convertNullToEmpty(x.ClaimantSurname)
      }
      clientDetails = {
        branchName : this.convertNullToEmpty(x.BranchName),
        clientName : this.convertNullToEmpty(x.ClientName),
      }
      clientContactDetails = {
        surname : this.convertNullToEmpty(x.ClientContactSurname),
        firstName : this.convertNullToEmpty(x.ClientContactFirstName),
        emailAddress : this.convertNullToEmpty(x.EmailAddress),
        phoneNumber1 : this.convertNullToEmpty(x.PhoneNumber1),
        phoneNumber2 : this.convertNullToEmpty(x.PhoneNumber2),
        mobile : this.convertNullToEmpty(x.Mobile),
        fullName: this.convertNullToEmpty(x.ClientContactFirstName) + " " + this.convertNullToEmpty(x.ClientContactSurname)
      }
      if (clientContactDetails.fullName == ' ' && (x.ClientContact != undefined && x.ClientContact.length > 0)) { // Get all bookings on Admin section
        x.ClientContact.map(c => {
          if (c.UserID == x.CaseOwner) {
            clientContactDetails = {
              surname : this.convertNullToEmpty(c.ClientContactSurname),
              firstName : this.convertNullToEmpty(c.ClientContactFirstName),
              emailAddress : this.convertNullToEmpty(c.EmailAddress),
              phoneNumber1 : this.convertNullToEmpty(c.PhoneNumber1),
              phoneNumber2 : this.convertNullToEmpty(c.PhoneNumber2),
              mobile : this.convertNullToEmpty(c.Mobile),
              fullName: this.convertNullToEmpty(c.ClientContactFirstName) + " " + this.convertNullToEmpty(c.ClientContactSurname)
            }
          }
        })
      } else if (clientContactDetails.fullName == ' ' && (x.ClientContacts != undefined && x.ClientContacts.length > 0)) {  // Search booking on Specialist section
        x.ClientContacts.map(c => {
          if(this.convertNumToBool(c.IsPrimaryContact)) {
            clientContactDetails = {
              surname : this.convertNullToEmpty(c.Surname),
              firstName : this.convertNullToEmpty(c.FirstName),
              emailAddress : this.convertNullToEmpty(c.EmailAddress),
              phoneNumber1 : this.convertNullToEmpty(c.PhoneNumber1),
              phoneNumber2 : this.convertNullToEmpty(c.PhoneNumber2),
              mobile : this.convertNullToEmpty(c.Mobile),
              fullName: this.convertNullToEmpty(c.FirstName) + " " + this.convertNullToEmpty(c.Surname)
            }
          }
        })
      }
      specialistDetails = {
        id: this.convertNullToEmpty(x.SpecialistID),
        title : this.convertNullToEmpty(x.Title),
        surname : this.convertNullToEmpty(x.Surname),
        firstName : this.convertNullToEmpty(x.FirstName),
        fullName: this.convertNullToEmpty(x.Title) + " " + this.convertNullToEmpty(x.FirstName) + " " + this.convertNullToEmpty(x.Surname)
      }
      location = {
        id: this.convertNullToEmpty(x.LocationID),
        name: this.convertNullToEmpty(x.LocationName),
        address1: this.convertNullToEmpty(x.AddressLine1),
        address2: this.convertNullToEmpty(x.AddressLine2),
        cityId: this.convertNullToEmpty(x.CityID),
        cityName: this.convertNullToEmpty(x.CityName),
        state: this.convertNullToEmpty(x.State),
        postcode: this.convertNullToEmpty(x.Postcode),
        country: this.convertNullToEmpty(x.Country),
      }
      location.shortLocation = this.getShortLocation(location)
      location.fullLocation = this.getFullLocation(location)

      if(x.BookingFile != undefined) {
        x.BookingFile.map(y => {
          bookingDetails.bookingFiles.push({
            bookingFileId: this.convertNullToEmpty(y.BookingFileID),
            fileName: this.convertNullToEmpty(y.FileName),
            filePath: this.convertNullToEmpty(y.FilePath),
            fileStatus: this.convertNullToEmpty(y.FileStatus),
            fileTypeId: this.convertNullToEmpty(y.FileTypeID),
            fileTypeName: this.convertNullToEmpty(y.FileTypeName),
            uploadedBy: this.convertNullToEmpty(y.UploadedBy),
            uploadedByName: this.convertNullToEmpty(y.UploadedByName),
          })
        })
      }
      if(isCalendar) {
        calendarEvent = {
          id: bookingDetails.id,
          name: x.ClaimantName,
          start: this.formatDate(bookingDetails.dateTime, "YYYY-MM-DD hh:mm", "YYYY-MM-DD HH:mm:ss.ssssss"),
          end: this.formatDate(bookingDetails.dateTime.slice(0, 10) + " " + bookingDetails.endTime, "YYYY-MM-DD hh:mm"),
          timed: true,
          displayTime: "",
          color: this.getColorHexForBookingType(bookingDetails.typeId),
          shortDetail: '',
          detail: ``,
          bookingType: bookingDetails.bookingTypeName,
        }
      }
      booking = {
        bookingDetails: bookingDetails,
        casesDetails: casesDetails,
        clientDetails: clientDetails,
        clientContactDetails: clientContactDetails,
        specialistDetails: specialistDetails,
        calendarEvent: calendarEvent,
        location: location,
      }
      bookings.push(booking)
    })
    return bookings
  },
  formatTravelItinerary(data) {
    var travels = []
    var travel = {}
    data.map(x => {
      travel = {
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        travelEndDate: this.convertNullToEmpty(x.TravelEndDate),
        travelStartDate: this.convertNullToEmpty(x.TravelStartDate),
        specialistAvailabilityId: this.convertNullToEmpty(x.AvailabilityID),
        specialistTravelItems: [],
        numberOfBookings: this.convertNullToEmpty(x.NumberofBookings),
        numberOfReceipts: this.convertNullToEmpty(x.NumberofReceipts),
        specialistTravelItineraryStatus: Number(this.convertNullToEmpty(x.SpecialistTravelItineraryStatus)),
        specialistTravelItineraryId: this.convertNullToEmpty(x.SpecialistTravelItineraryID),
        dateCompleted: this.convertNullToEmpty(x.DateCompleted),
        totalCost: this.convertNullToEmpty(x.TotalCost),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
      }
      travel.status = travel.dateCompleted != '' ? true : false
      if (x.SpecialistTravelItems != undefined) {
        x.SpecialistTravelItems.map(y => {
          var item = {
            travelType: this.convertNullToEmpty(y.TravelType),
            accommodationId:this.convertNullToEmpty(y.AccommodationID),
            bookingReference:this.convertNullToEmpty(y.BookingReference),
            accommodationCity:this.convertNullToEmpty(y.AccommodationCity),
            accommodationName:this.convertNullToEmpty(y.AccommodationName),
            accommodationState:this.convertNullToEmpty(y.AccommodationState),
            accommodationCountry:this.convertNullToEmpty(y.AccommodationCountry),
            arrivingAirportName:this.convertNullToEmpty(y.ArrivingAirportName),
            departingAirportName:this.convertNullToEmpty(y.DepartingAirportName),
            accommodationPostcode:this.convertNullToEmpty(y.AccommodationPostcode),
            accommodationPhoneNumber:this.convertNullToEmpty(y.AccommodationPhoneNumber),
            accommodationAddressLine1:this.convertNullToEmpty(y.AccommodationAddressLine1),
            accommodationAddressLine2:this.convertNullToEmpty(y.AccommodationAddressLine2),
            seatNumber: this.convertNullToEmpty(y.SeatNumber),
            flightNumber:this.convertNullToEmpty(y.FlightNumber),
            airlineName: this.convertNullToEmpty(y.AirlineName),
            airlinePhone: this.convertNullToEmpty(y.AirlinePhone),
            carHireCity: this.convertNullToEmpty(y.CarHireCity),
            carHireName: this.convertNullToEmpty(y.CarHireName),
            carHireAddressLine1: this.convertNullToEmpty(y.CarHireAddressLine1),
            carHireAddressLine2: this.convertNullToEmpty(y.CarHireAddressLine2),
            carHireState: this.convertNullToEmpty(y.CarHireState),
            carHirePostcode: this.convertNullToEmpty(y.CarHirePostcode),
            travelStartDate: this.convertNullToEmpty(y.TravelStartDate),
            travelStartTime: this.convertNullToEmpty(y.TravelStartTime),
            travelEndDate: this.convertNullToEmpty(y.TravelEndDate),
            travelEndTime: this.convertNullToEmpty(y.TravelEndTime),
            seatNumber: this.convertNullToEmpty(y.SeatNumber),
          }
          //Pullman Cairns International, 17 Abbott St, Cairns City QLD 4870 (07) 4031 1300 Booking Ref: BB20060574 Check-in 02:00 PM
          item.accommodationAddress = item.accommodationAddressLine1 + " " +item.accommodationAddressLine2 + " " + item.accommodationCity + " " + item.accommodationState + " " + item.accommodationPostcode
          travel.specialistTravelItems.push(item)
        })
      } else {
        //travel.specialistTravelItems.push(this.initPersonCondition())
      }

      // Get oldest travel start date
      var travelStartDates = travel.specialistTravelItems.filter(x => x.travelStartDate != '').sort((a, b) => {
        return moment(a.travelStartDate, "YYYY-MM-DD").isAfter(moment(b.travelStartDate, "YYYY-MM-DD")) ? 1 : -1
      })
      if(travelStartDates[0] != undefined) travel.travelStartDate = travelStartDates[0].travelStartDate

      // Get latest travel end date
      var travelEndDates = travel.specialistTravelItems.filter(x => x.travelEndDate != '').sort((a, b) => {
        return moment(a.travelEndDate, "YYYY-MM-DD").isBefore(moment(b.travelEndDate, "YYYY-MM-DD")) ? 1 : -1
      })
      if(travelEndDates[0] != undefined) travel.travelEndDate = travelEndDates[0].travelEndDate

      // Check start date and end date should not be the same
      if(moment(travel.travelStartDate, "YYYY-MM-DD").isSame(moment(travel.travelEndDate, "YYYY-MM-DD"))) travel.travelEndDate = ''

      travels.push(travel)
    })
    return travels
  },
  formatTravelItems(data) {
    var travel = []
    try {
    data.map(y => {
      var item = {
        accommodationId: this.convertNullToEmpty(y.AccommodationID),
        accommodationName: this.convertNullToEmpty(y.AccommodationName),
        accommodationAddress1: this.convertNullToEmpty(y.AddressLine1),
        accommodationAddress2: this.convertNullToEmpty(y.AddressLine2),
        accommodationCity: this.convertNullToEmpty(y.City),
        accommodationState: this.convertNullToEmpty(y.State),
        accommodationPostcode: this.convertNullToEmpty(y.Postcode),
        accommodationCountry: this.convertNullToEmpty(y.Country),
        carHirePhoneNumber: this.convertNullToEmpty(y.CarhirePhoneNmber),
        accommodationPhoneNumber: this.convertNullToEmpty(y.Phonenumber),
        airlineContact: this.convertNullToEmpty(y.ContactPhone),
        airline: this.convertNullToEmpty(y.Airline),
        airlineId: this.convertNullToEmpty(y.AirlineID),
        arrivingAirportId: this.convertNullToEmpty(y.ArrivingAirportID),
        arrivingAirportName: this.convertNullToEmpty(y.ArrivingAirportName),
        bookingReference: this.convertNullToEmpty(y.BookingReference),
        carHireName: this.convertNullToEmpty(y.CarHireName),
        carhireId: this.convertNullToEmpty(y.CarhireID),
        cost: this.convertNullToEmpty(y.Cost),
        departingAirportId: this.convertNullToEmpty(y.DepartingAirportID),
        departingAirportName: this.convertNullToEmpty(y.DepartingAirportName),
        flightNumber: this.convertNullToEmpty(y.FlightNumber),
        seatNumber: this.convertNullToEmpty(y.SeatNumber),
        specialistTravelItemId: this.convertNullToEmpty(y.SpecialistTravelItemID),
        specialistTravelItineraryId: this.convertNullToEmpty(y.SpecialistTravelItineraryID),
        travelEndDate: this.convertNullToEmpty(y.TravelEndDate),
        travelEndTime: this.convertNullToEmpty(y.TravelEndTime),
        travelStartDate: this.convertNullToEmpty(y.TravelStartDate),
        travelStartTime: this.convertNullToEmpty(y.TravelStartTime),
        travelType: this.convertNullToEmpty(y.TravelType),
      }
      item.fullLocation = this.getAccommodationFullLocation(item)
      //Pullman Cairns International, 17 Abbott St, Cairns City QLD 4870 (07) 4031 1300 Booking Ref: BB20060574 Check-in 02:00 PM
      // item.accommodationAddress = item.accommodationAddressLine1 + " " +item.accommodationAddressLine2 + " " + item.accommodationCity + " " + item.accommodationState + " " + item.accommodationPostcode
      if(item.specialistTravelItemId != '') travel.push(item)
    })
  } catch(e) {console.log(e)}
    return travel
  },
  formatCase(data) {
    var clientCases = []
    var clientCase = {}
    data.map(x => {
      clientCase = {
        id: this.convertNullToEmpty(x.CaseID),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        clientBranchName: this.convertNullToEmpty(x.ClientName + ' - ' + x.BranchName),
        branchName: this.convertNullToEmpty(x.BranchName),
        caseOwner: this.convertNullToEmpty(x.CaseOwner),
        caseOwnerName: this.convertNullToEmpty(x.CaseOwnerName),
        zohoCaseId: this.convertNullToEmpty(x.ZohoCaseID),
        caseStatus: this.convertNullToEmpty(x.CaseStatus),
        // onBehalfOf: this.convertNullToEmpty(x.OnBehalfOf),
        claimTypeId: this.convertNullToEmpty(x.ClaimTypeID),
        dateOfBirth: this.convertNullToEmpty(x.DateofBirth),
        // accidentDate: this.convertNullToEmpty(x.AccidentDate),
        // bodyAreaOther: this.convertNullToEmpty(x.BodyAreaOther),
        caseReference: this.convertNullToEmpty(x.CaseReference),
        claimTypeName: this.convertNullToEmpty(x.ClaimTypeName),
        claimantEmail: this.convertNullToEmpty(x.ClaimantEmail),
        claimantMobile: this.convertNullToEmpty(x.ClaimantMobile),
        claimantPhone1: this.convertNullToEmpty(x.ClaimantPhone1),
        claimantPhone2: this.convertNullToEmpty(x.ClaimantPhone2),
        commencementDate: this.convertNullToEmpty(x.CommencementDate),
        // overPeriodofTime: this.convertNumToBool(this.convertNullToEmpty(x.OverPeriodofTime)),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantAddressLine1: this.convertNullToEmpty(x.ClaimantAddressLine1),
        claimantAddressLine2: this.convertNullToEmpty(x.ClaimantAddressLine2),
        claimantCity: this.convertNullToEmpty(x.ClaimantCity),
        claimantState: this.convertNullToEmpty(x.ClaimantState),
        claimantPostcode: this.convertNullToEmpty(x.ClaimantPostcode),
        claimantCountry: this.convertCountryCodeToName(this.convertNullToEmpty(x.ClaimantCountry)),
        numberBookings: this.convertNullToEmpty(x.NumberBookings),
        nextBookingDate: this.convertNullToEmpty(x.NextBookingDate),
        nextBookingId: this.convertNullToEmpty(x.NextBookingID),
        lastUpdatedDate: this.convertNullToEmpty(x.LastUpdatedDate),
        redHealthMayContactClaimant: this.convertNumToBool(this.convertNullToEmpty(x.RedHealthMayContactClaimant)),
        // bodyAreaOther: this.convertNullToEmpty(x.BodyAreaOther),
        // bodyAreas: [],
        // personCondition: [],
        zohoClaimantId: this.convertNullToEmpty(x.ZohoClaimantID),
        zohoCaseLink: this.convertNullToEmpty(x.ZohoCaseLink),
        PreferredGivenName: this.convertNullToEmpty(x.PreferredGivenName),
        Salutation: this.convertNullToEmpty(x.Salutation),
        zohoCaseID: this.convertNullToEmpty(x.ZohoCaseID),
        bookingIds: [],
        nextBookingID: this.convertNullToEmpty(x.NextBookingID)
      }
      clientCase.claimantFullName = this.getClaimantFullName(clientCase)
      clientCase.claimantFullAddress = clientCase.claimantAddressLine1 + " " + clientCase.claimantAddressLine2 + ", " + clientCase.claimantCity + " " + clientCase.claimantState  + " " + clientCase.claimantPostcode + " " + clientCase.claimantCountry
      // if (x.CaseBodyArea != undefined) {
      //   x.CaseBodyArea.map(y => {
      //     clientCase.bodyAreas.push({
      //       bodyArea: this.convertNullToEmpty(y.BodyArea),
      //       caseBodyAreaId : this.convertNullToEmpty(y.CaseBodyAreaID),
      //     })
      //   })
      // }

      // if (x.CasePersonCondition != undefined) {
      //   x.CasePersonCondition.map(y => {
      //     clientCase.personCondition.push({
      //       name: this.convertNullToEmpty(y.ConditionName),
      //       id : this.convertNullToEmpty(y.CasePersonConditionID),
      //     })
      //   })
      // }

      if (x.BookingIDs != undefined) {
        x.BookingIDs.map(y => {
          clientCase.bookingIds.push(this.convertNullToEmpty(y.BookingID).toString())
        })
      }

      clientCases.push(clientCase)
    })
    return clientCases
  },
  formatUpdatedCase(data) {
    var clientCases = []
    var clientCase = {}
    data.map(x => {
      clientCase = {
        id: this.convertNullToEmpty(x.CaseID),
        caseReference: this.convertNullToEmpty(x.CaseReference),
        caseOwner: this.convertNullToEmpty(x.CaseOwner),
        caseOwnerName: this.convertNullToEmpty(x.CaseOwnerName),
        clientId: this.convertNullToEmpty(x.ClientID),
        clientName: this.convertNullToEmpty(x.ClientName),
        claimTypeId: this.convertNullToEmpty(x.ClaimTypeID),
        claimTypeName: this.convertNullToEmpty(x.ClaimTypeName),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        clientContactFirstName: this.convertNullToEmpty(x.ClientContactFirstName),
        clientContactMiddleName: this.convertNullToEmpty(x.ClientContactMiddleName),
        clientContactSurname: this.convertNullToEmpty(x.ClientContactSurname),
        accidentDate: this.convertNullToEmpty(x.AccidentDate),
        nextBookingDate: this.convertNullToEmpty(x.NextBookingDate),
        numberBookings: this.convertNullToEmpty(x.NumberBookings),
        overPeriodofTime: this.convertNumToBool(this.convertNullToEmpty(x.OverPeriodofTime)),
        lastUpdateBy: this.convertNullToEmpty(x.LastUpdateBy),
        lastUpdatedByName: this.convertNullToEmpty(x.LastUpdatedByName),
        lastUpdatedDate: this.convertNullToEmpty(x.LastUpdatedDate),
      }
      clientCase.claimantFullName = this.getClaimantFullName(clientCase)
      clientCase.clientContactFullName = this.getClientContactFullName(clientCase)
      clientCases.push(clientCase)
    })
    return clientCases
  },
  formatBillingCategories(data) {
    var billingCategories = []
    data.map( x=> {
      billingCategories.push({
        id: this.convertNullToEmpty(x.BillingCategoryID),
        name: this.convertNullToEmpty(x.BillingCategoryName)
      })
    })
    return billingCategories
  },
  formatAvailability(data, fullName = '') {
    var availabilities = []
    var availability = {}
    data.map(x => {
      availability = {
        id: moment(x.AvailabilityDate).format('YYYY-MM-DD'),
        specialistAvailabilityId: this.convertNullToEmpty(x.AvailabilityID),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        title: this.convertNullToEmpty(x.Title),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        practiceLocationId: this.convertNullToEmpty(x.PracticeLocationID),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
        cityId: this.convertNullToEmpty(x.CityID),
        cityName: this.convertNullToEmpty(x.CityName),
        photo: this.convertNullToEmpty(x.Photo),
        availabilityDate: this.convertNullToEmpty(x.AvailabilityDate),
        date: moment(x.AvailabilityDate).format('YYYY-MM-DD'),
        startDate: new Date(x.AvailabilityDate),
        endDate: new Date(x.AvailabilityDate),
        regionId: this.convertNullToEmpty(x.RegionID),
        byRequestOnly: this.convertNumToBool(this.convertNullToEmpty(x.ByRequestOnly)),
        requiresTravel: this.convertNumToBool(this.convertNullToEmpty(x.RequiresTravel)),
        videoLinkAvailable: this.convertNullToEmpty(x.VideoLinkAvailable),
        VideoOnly: this.convertNullToEmpty(x.VideoOnly),
        specialistTravelItineraryId: this.convertNullToEmpty(x.SpecialistTravelItineraryID),
        specialistTravelItineraryStatus: this.convertNullToEmpty(x.SpecialistTravelItineraryStatus),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
        generalInformation : this.convertNullToEmpty(x.GeneralInformation),
        contiguousSlotCount: this.convertNullToEmpty(x.ContiguousSlotCount),
        totalAvailabileMinites: '',
        Availabilities: x.Availabilities ?  x.Availabilities : x.Specialists_AvailabilityTimeSlots,
        status: ''
      }
      if(availability.Availabilities && availability.Availabilities.length > 0) {
        let allBooked = false
        let status = availability.Availabilities.filter(item => item.AvailabilityStatus === '2' && item.BookingStatus === 'A')
        if(status.length > 0) {
          availability.status = 'available'
        }
        status = availability.Availabilities.filter(item => item.AvailabilityStatus === '2' && item.BookingStatus !== 'A')
        if(status.length === availability.Availabilities.length) {
          allBooked = true
          availability.status = 'fully booked'
        }
        status = availability.Availabilities.filter(item => item.AvailabilityStatus === '2' && item.Options.VideoOnly === 1)
        if(status.length === availability.Availabilities.length && !allBooked) {
          availability.status = 'video only'
        }
        status = availability.Availabilities.filter(item => item.AvailabilityStatus === '2' && item.Options.VideoLinkAvailable === 1)
        if(status.length > 0 && !allBooked) {
          availability.status = 'video link'
        }
        status = availability.Availabilities.filter(item => item.AvailabilityStatus === '2' && item.Options.ByRequestOnly === 1)
        if(status.length === availability.Availabilities.length) {
          availability.status = 'request only'
        }
        status = availability.Availabilities.filter(item => item.AvailabilityStatus === '1')
        if(status.length > 0) {
          availability.status = 'submitted'
        }
      }

      if (fullName != '') {
        availability.fullName = fullName
        availability.specialistName = fullName
      } else {
        availability.fullName = this.getSpecialistFullName(availability)
        availability.specialistName =  availability.fullName
      }
      availability.fullLocation = this.addComma(availability.locationName) + availability.cityName
      availabilities.push(availability)
    })
    availabilities.sort((a, b) => {
      return moment(a.availabilityDate, "YYYY-MM-DD") > moment(b.availabilityDate, "YYYY-MM-DD") ? 1 : -1
    })
    return availabilities
  },
  formatAvailabilityRequiringTravel(data) {
    var availabilities = []
    var availability = {}
    data.map(x => {
      availability = {
        id: this.convertNullToEmpty(x.AvailabilityID),
        availabilityDate: this.convertNullToEmpty(x.AvailabilityDate),
        specialistTravelItineraryId: this.convertNullToEmpty(x.SpecialistTravelItineraryID),
        specialistTravelItineraryStatus: this.convertNullToEmpty(x.SpecialistTravelItineraryStatus),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        title: this.convertNullToEmpty(x.Title),
        firstName: this.convertNullToEmpty(x.FirstName),
        lastName: this.convertNullToEmpty(x.Surname),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
        cityId: this.convertNullToEmpty(x.CityID),
        cityName: this.convertNullToEmpty(x.CityName),
        regionName: this.convertNullToEmpty(x.RegionName),
        state: this.convertNullToEmpty(x.State),
        assignedTo: this.convertNullToEmpty(x.AssignedTo),
        assignedToName: this.convertNullToEmpty(x.AssignedToName),
        numberofBookings: this.convertNullToEmpty(x.NumberofBookings),
        startDate: this.convertNullToEmpty(x.StartDate),
        endDate: this.convertNullToEmpty(x.EndDate),
      }
      availability.fullName = this.getSpecialistFullName(availability)
      availability.fullLocation = availability.locationName + (availability.cityName != '' ? ", " : "") + availability.cityName + (availability.state != '' ? ", " : "") + availability.state

      availabilities.push(availability)
    })
    availabilities.sort((a, b) => {
      return a.startDate.length > b.startDate.length ? 1 : -1
    }).sort((a, b) => {
      return moment(a.startDate, "YYYY-MM-DD") < moment(b.startDate, "YYYY-MM-DD") ? 1 : -1
    })
    return availabilities
  },
  formatAvailabilityRequiringTravelNoItinerary(data) {
    var availabilities = []
    var availability = {}
    data.map(x => {
      availability = {
        id: this.convertNullToEmpty(x.AvailabilityID),
        availabilityDate: this.convertNullToEmpty(x.AvailabilityDate),
        startTime: this.convertNullToEmpty(x.SpecialistAvailabilityStartTime),
        endTime: this.convertNullToEmpty(x.SpecialistAvailabilityEndTime),
        title: this.convertNullToEmpty(x.SpecialistTitle),
        firstName: this.convertNullToEmpty(x.SpecialistFirstName),
        lastName: this.convertNullToEmpty(x.SpecialistSurname),
        numberofBookings: this.convertNullToEmpty(x.NumberofBookings),
        locationName: this.convertNullToEmpty(x.LocationName),
        cityName: this.convertNullToEmpty(x.CityName),
        regionName: this.convertNullToEmpty(x.RegionName),
        state: this.convertNullToEmpty(x.State),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
      }
      availability.fullName = this.getSpecialistFullName(availability)
      availability.fullLocation = availability.locationName + (availability.cityName != '' ? ", " : "") + availability.cityName + (availability.state != '' ? ", " : "") + availability.state

      availabilities.push(availability)
    })
    availabilities.sort((a, b) => {
      return moment(a.availabilityDate, "YYYY-MM-DD") < moment(b.availabilityDate, "YYYY-MM-DD") ? 1 : -1
    })
    return availabilities
  },
  formatBodyArea(data) {
    var bodyAreas = []
    var bodyArea = {}
    data.map(x => {
      bodyArea = {
        id: this.convertNullToEmpty(x.BodyAreaID),
        bodyArea: this.convertNullToEmpty(x.BodyArea),
      }
      bodyAreas.push(bodyArea)
    })
    return bodyAreas
  },
  formatHoursAvailable(data) {
    var report = []
    data.map(x => {
      console.log(this.convertNullToEmpty(x.AvailableHours).toFixed(2))
      report.push({
        groupingDate: this.convertNullToEmpty(x.GroupingDate),
        availableHours: this.convertNullToEmpty(x.AvailableHours).toFixed(2)
      })
    })
    return report
  },
  formatHoursBooked(data) {
    var report = []
    data.map(x => {
      report.push({
        groupingDate: this.convertNullToEmpty(x.GroupingDate),
        bookedHours: this.convertNullToEmpty(x.BookedHours).toFixed(2),
        numberOfBookings: this.convertNullToEmpty(x.NumberOfBookings)
      })
    })
    report.sort((a,b) => {
      return moment('01 ' + a.groupingDate, "YYYY-MM-DD").isBefore(moment('01 ' + b.groupingDate, "YYYY-MM-DD")) ? -1 : 1
    })
    return report
  },

  formatHoursBookedByClient(data) {
    var report = []
    data.map(x => {
      report.push({
        branchName:this.convertNullToEmpty(x.BranchName),
        clientName:this.convertNullToEmpty(x.ClientName),
        bookedHours:this.convertNullToEmpty(x.BookedHours).toFixed(2),
        groupingDate:this.convertNullToEmpty(x.GroupingDate),
        numberOfBookings:this.convertNullToEmpty(x.NumberOfBookings),
      })
    })
    return report
  },
  formatHoursBookedBySpecialist(data) {
    var report = []
    data.map(x => {
      var specialistName = this.convertNullToEmpty(x.Title) + ' ' + this.convertNullToEmpty(x.FirstName) + ' ' + this.convertNullToEmpty(x.Surname)
      specialistName.replace(/[\s]+/,' ')
      report.push({
        specialist: specialistName,
        numberOfBookings:this.convertNullToEmpty(x.NumberOfBookings),
        numberOfHours:this.convertNullToEmpty(x.BookedHours).toFixed(2)
      })
    })
    return report
  },
  formatHoursBookedByLocation(data) {
    var report = []
    data.map(x => {
      report.push({
        cityId:this.convertNullToEmpty(x.CityID),
        cityName:this.convertNullToEmpty(x.CityName),
        locationId:this.convertNullToEmpty(x.LocationID),
        regionId:this.convertNullToEmpty(x.RegionID),
        regionName:this.convertNullToEmpty(x.RegionName),
        bookedHours:this.convertNullToEmpty(x.BookedHours).toFixed(2),
        groupingDate:this.convertNullToEmpty(x.GroupingDate),
        numberOfBookings:this.convertNullToEmpty(x.NumberOfBookings),
      })
    })
    return report
  },
  formatVerifiedClaimant(x) {
    let booking = {
        claimantEmail: this.convertNullToEmpty(x.ClaimantEmail),
        claimantMobile: this.convertNullToEmpty(x.ClaimantMobile),
        claimantPhone1: this.convertNullToEmpty(x.ClaimantPhone1),
        claimantPhone2: this.convertNullToEmpty(x.ClaimantPhone2),
        claimantAddressLine1: this.convertNullToEmpty(x.ClaimantAddressLine1),
        claimantAddressLine2: this.convertNullToEmpty(x.ClaimantAddressLine2),
        claimantCity: this.convertNullToEmpty(x.ClaimantCity),
        claimantState: this.convertNullToEmpty(x.ClaimantState),
        claimantPostcode: this.convertNullToEmpty(x.ClaimantPostcode),
        claimantCountry: this.convertCountryCodeToName(this.convertNullToEmpty(x.ClaimantCountry)),
        claimantFirstName: this.convertNullToEmpty(x.ClaimantFirstName),
        claimantSurname: this.convertNullToEmpty(x.ClaimantSurname),
        claimantGivenNames: this.convertNullToEmpty(x.ClaimantGivenNames),
        title : this.convertNullToEmpty(x.Title),
        surname : this.convertNullToEmpty(x.SpecialistSurname),
        firstName : this.convertNullToEmpty(x.SpecialistFirstName),
        name: this.convertNullToEmpty(x.LocationName),
        address1: this.convertNullToEmpty(x.LocationAddressLine1),
        address2: this.convertNullToEmpty(x.LocationAddressLine2),
        cityName: this.convertNullToEmpty(x.CityName),
        state: this.convertNullToEmpty(x.LocationState),
        postcode: this.convertNullToEmpty(x.LocationPostcode),
        country: this.convertNullToEmpty(x.LocationCountry),
        bookingDateTime: this.convertNullToEmpty(x.BookingDateTime),
        bookingEndTime: this.convertNullToEmpty(x.BookingEndTime),
        fullName:'',
        shortLocation: '',
        fullLocation: '',
        fullAddress: '',
      }
       booking.claimantFullName = this.getClaimantFullName(booking)
       booking.fullName = booking.title.trim() + (booking.title.trim() != '' ? " " : "") + booking.firstName.trim() + " " + booking.surname.trim()
       booking.claimantFullAddress = this.addComma(booking.claimantAddressLine1) + this.addComma(booking.claimantAddressLine2) + this.addComma(booking.claimantCity) + this.addComma(booking.claimantState) + booking.claimantPostcode
       booking.shortLocation = this.addComma(booking.name) + this.addComma(booking.cityName) + this.addComma(booking.state)
       booking.fullLocation = this.addComma(booking.name) + this.addComma(booking.address1) + this.addComma(booking.address2) + this.addComma(booking.cityName) + this.addComma(booking.state) + (booking.country != '' ? this.addComma(booking.postcode) : '') + booking.country
    return booking
  },
  getBookingStatusColor(status) {
    var color = ""
    switch(status) {
      case 1: color = "pink"
              break
      case 2: color = "#3FA2F7"
              break
      case 3: color = "#56C568"
              break
      case 4: color = "#EB5757"
              break
      case 5: color = "#8576ED"
              break
      default: break
    }

    return color
  },
  getAvailabilityRoutinesAsDataSource(data) {
    var groupByDate = {}
    var availabilityDates = {}
    var key = ""
    var availabilities = []
    data.map(x => {
      if(x.startTime.includes("AM") || x.startTime.includes("PM")) {
        x.startTime = moment(x.availabilityDate + ' ' + x.startTime, "YYYY-MM-DD hh:mm A").format("HH:mm:ss.ssssss")
      }
      if(x.endTime.includes("AM") || x.endTime.includes("PM")) {
        x.endTime = moment(x.availabilityDate + ' ' + x.endTime, "YYYY-MM-DD hh:mm A").format("HH:mm:ss.ssssss")
      }
    })
    data.sort((a, b) => {
      return moment(a.availabilityDate + " " + a.startTime, "YYYY-MM-DD HH:mm:ss.ssssss").isAfter(moment(b.availabilityDate + " " + b.startTime, "YYYY-MM-DD HH:mm:ss.ssssss")) ? 1 : -1
    })

    data.map(x => {
      if(groupByDate[x.availabilityDate] == undefined) {
        groupByDate[x.availabilityDate] = {
          numberWaitlist: 0,
          numberByRequestOnly: 0,
        }
      }

      key = x.availabilityDate + " " + x.startTime.slice(0, 8) + " " + x.specialistId
      if(availabilityDates[key] == undefined) {
        availabilityDates[key] = {}
      }
      availabilityDates[key].availabilityId = this.convertNullToEmpty(x.id)
      availabilityDates[key].availabilityDate = this.convertNullToEmpty(x.availabilityDate)
      availabilityDates[key].generalInformation = this.convertNullToEmpty(x.generalInformation)
      availabilityDates[key].location = Number(this.convertNullToEmpty(x.locationId))
      availabilityDates[key].locationName = this.convertNullToEmpty(x.locationName)
      availabilityDates[key].cityName = this.convertNullToEmpty(x.cityName)
      availabilityDates[key].startTime = moment(x.availabilityDate + ' ' + x.startTime, "YYYY-MM-DD HH:mm:ss.ssssss").format("hh:mm A")
      availabilityDates[key].endTime = moment(x.availabilityDate + ' ' + x.endTime, "YYYY-MM-DD HH:mm:ss.ssssss").format("hh:mm A")
      availabilityDates[key].length = moment.duration(moment(x.availabilityDate + ' ' + x.endTime, "YYYY-MM-DD HH:mm:ss.ssssss").diff(moment(x.availabilityDate + ' ' + x.startTime, "YYYY-MM-DD HH:mm:ss.ssssss"))).asMinutes()
      availabilityDates[key].slotSizeMin = this.convertNullToEmpty(x.slotSizeMin)
      availabilityDates[key].id = this.convertNullToEmpty(x.availabilityDate)
      availabilityDates[key].date = moment(x.availabilityDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      availabilityDates[key].startDate = moment(x.availabilityDate, "YYYY-MM-DD").toDate()
      availabilityDates[key].endDate = moment(x.availabilityDate, "YYYY-MM-DD").toDate()
      availabilityDates[key].selfId = this.convertNullToEmpty(x.selfId)
      availabilityDates[key].parentId = this.convertNullToEmpty(x.parentId)
      availabilityDates[key].regionId = this.convertNullToEmpty(x.regionId)
      availabilityDates[key].specialistId = this.convertNullToEmpty(x.specialistId)
      availabilityDates[key].fillOrder = this.convertNullToEmpty(x.fillOrder)
      availabilityDates[key].requiresTravel = this.convertNullToEmpty(x.requiresTravel)
      availabilityDates[key].specialistName = x.fullName != undefined ? x.fullName : ""

      if(x.VideoOnly === 1) {
        availabilityDates[key].hasVideo = true
      } else {
        availabilityDates[key].hasVideo = false
      }
      if(x.videoLinkAvailable === 2) {
        availabilityDates[key].hasUserVideo = true
      } else {
        availabilityDates[key].hasUserVideo = false
      }
      if(moment(x.availabilityDate, "YYYY-MM-DD").isAfter(moment())) {
        availabilityDates[key].isInFuture = true
      }

      availabilityDates[key].name = x.numberTimeSlotsBooked + ' bookings, ' + (x.numberTimeSlots - x.numberTimeSlotsBooked) + ' remaining.'
      availabilityDates[key].status = x.availabilityStatus
      availabilityDates[key].numberTimeSlots = x.numberTimeSlots
      availabilityDates[key].numberTimeSlotsBooked = x.numberTimeSlotsBooked
      availabilityDates[key].statusName = this.getAvailabilityStatus(x.availabilityStatus)

      availabilityDates[key].isWaitlist = false
      availabilityDates[key].isRequestOnly = false
      // if(x.numberTimeSlots - x.numberTimeSlotsBooked == 0 && x.numberTimeSlots != 0) {  // red
      //   availabilityDates[key].color = "#EB5757"
      //   groupByDate[x.availabilityDate].numberWaitlist += 1
      //   availabilityDates[key].name = "Wait List"
      //   availabilityDates[key].isWaitlist = true
      // } else if(x.byRequestOnly == 1 && x.availabilityStatus == 3) {  // purple
      //   availabilityDates[key].isRequestOnly = true
      //   availabilityDates[key].color = "#8576ED"
      //   groupByDate[x.availabilityDate].numberByRequestOnly += 1
      // } else {  // other color
      //   availabilityDates[key].color = this.getBookingStatusColor(Number(x.availabilityStatus))
      // }
    })

    availabilities = Object.values(availabilityDates)
    Object.keys(groupByDate).map(x => {
      var slots = availabilities.filter(y => y.availabilityDate == x)
      if(slots.length == groupByDate[x].numberWaitlist) {  // red
        slots[slots.length - 1].color = "#EB5757"
      } else if(slots.length == groupByDate[x].numberByRequestOnly) {  // purple
        slots[slots.length - 1].color = "#8576ED"
      } else {  // other color
        var slot = slots.find(y => !y.isWaitlist)
        if(slot != undefined) slots[slots.length - 1].color = this.getBookingStatusColor(Number(slot.status))
      }
    })

    return availabilities
  },
  getAvailabilityRoutinesAsClientDataSource(data) {
    data.sort((a, b) => {
      var date1 = a.date + (a.slots[0] && a.slots[0].time ? (' ' + a.slots[0].time) : '')
      var date2 = b.date + (b.slots[0] && b.slots[0].time ? (' ' + b.slots[0].time) : '')
      return moment(date1, "YYYY-MM-DD").isAfter(moment(date2, "YYYY-MM-DD")) ? 1 : -1
    })

    var groupByDate = {}
    var res = []
    var i = 0
    data.map(x => {
      if(groupByDate[x.date] == undefined) {
        groupByDate[x.date] = {
          numberWaitlist: 0,
          numberByRequestOnly: 0,
        }
      }

      var statusFlag = false
      var notAvailable = true
      var times = []
      var waitlistTimes = []
      var hasVideo = false
      var hasUserVideo = false
      if(x.videoLinkAvailable === 2) {
        hasUserVideo = true
      } else {
        hasUserVideo = false
      }
      if(x.VideoOnly === 1) {
        hasVideo = true
      } else {
        hasVideo = false
      }
      res[i] = {
        id: i,
        date: moment(x.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
        availabilityId: this.convertNullToEmpty(x.specialistAvailabilityId),
        specialistId: this.convertNullToEmpty(x.specialistId),
        specialistName: this.convertNullToEmpty(x.specialistName),
        startDate:moment(x.date, "YYYY-MM-DD").toDate(),
        endDate:moment(x.date, "YYYY-MM-DD").toDate(),
        location: x.locationId,
        regionId: x.regionId,
        locationName: this.convertNullToEmpty(x.locationName),
        cityName: this.convertNullToEmpty(x.cityName),
        hasVideo: hasVideo,
        hasUserVideo: hasUserVideo,
        status: this.convertNullToEmpty(x.availabilityStatus),
      }
      x.slots.map(y => {
        notAvailable = false
        if(y.status == 'A' && !x.byRequestOnly) {
          statusFlag = true
          // ADD BY REQUEST ONLY CHECK IN IF, MAKE STATUS FLAG ELSE IF
          times.push(moment(moment().format("YYYY-MM-DD") + ' ' + y.startTime, "YYYY-MM-DD HH:mm:ss.ssssss").format("hh:mm A"))
        } else if(y.status == 'B') {
          waitlistTimes.push(moment(moment().format("YYYY-MM-DD") + ' ' + y.startTime, "YYYY-MM-DD HH:mm:ss.ssssss").format("hh:mm A"))
        }
      })
      res[i].time = times.join('<br />')
      res[i].isWaitlist = false
      res[i].isRequestOnly = false

      if(!statusFlag && !x.byRequestOnly) { // red
        res[i].color = "#EB5757"
        groupByDate[x.date].numberWaitlist += 1
      } else if (x.byRequestOnly) { // purple
        res[i].color = "#8576ED"
        groupByDate[x.date].numberByRequestOnly += 1
      } else {  // green
         res[i].color = "#56C568"
      }

      if(!statusFlag && !x.byRequestOnly) {
        res[i].name = "Wait List"
        res[i].available = false
        res[i].isWaitlist = true
        res[i].startTime = waitlistTimes.join('<br />')
      } else if (x.byRequestOnly) {
        res[i].name = "By Request Only"
        res[i].isRequestOnly = true
      } else if (statusFlag) {
        res[i].available = true
        res[i].name = times.join('<br />')
      } else {
        res[i].available = false
      }

      i++
    })

    Object.keys(groupByDate).map(x => {
      var slots = res.filter(y => y.date == x)
      if(slots.length == groupByDate[x].numberWaitlist) {  // red
        slots[slots.length - 1].color = "#EB5757"
      } else if(slots.length == groupByDate[x].numberByRequestOnly) {  // purple
        slots[slots.length - 1].color = "#8576ED"
      } else {  // green
        slots[slots.length - 1].color = "#56C568"
      }
    })

    return res
  },
  formatEventsToCalendarEvents(events){
    var calendarData = []
    events.map(x => {
      var description = x.detail.replaceAll("<p>", "").replaceAll("<strong>", "").replaceAll("</strong>","").replace("Contact:", "\nContact:").replaceAll("Ph:","\nPh:").split("</p>")
      description.pop()
      description = description.join("\n")
      calendarData.push({
        "Subject": "Red Health Booking",
        "Start Date": moment(x.start, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD"),
        "Start Time": moment(x.start, "YYYY-MM-DD HH:mm").format("hh:mm A"),
        "End Date": moment(x.end, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD"),
        "End Time": moment(x.end, "YYYY-MM-DD HH:mm").format("hh:mm A"),
        "All Day Event": false,
        "Description": description,
        "Location": x.location == undefined ? '' : x.location.fullLocation
      })
    })
    return calendarData
  },
  formatReceipts(data) {
    var receipts = []
    var receipt = {}
    data.map(x => {
      receipt = {
        amount:this.convertNullToEmpty(x.Amount),
        dateUploaded:this.convertNullToEmpty(x.DateUploaded),
        expenseDate:this.convertNullToEmpty(x.ExpenseDate),
        invoiceFileName:this.convertNullToEmpty(x.InvoiceFileName),
        invoiceFilePath:this.convertNullToEmpty(x.InvoiceFilePath),
        specialistId:this.convertNullToEmpty(x.SpecialistID),
        specialistTravelItineraryId:this.convertNullToEmpty(x.SpecialistTravelItineraryID),
        travelReceiptId:this.convertNullToEmpty(x.TravelReceiptID),
        uploadedBy:this.convertNullToEmpty(x.UploadedBythis),
      }
      receipts.push(receipt)
    })
    return receipts
  },
  formatAlternateQualifications(data) {
    var alternateQualifications = []
    data.map(x=>{
      alternateQualifications.push({
        qualificationId: this.convertNullToEmpty(x.QualificationID),
        qualificationName: this.convertNullToEmpty(x.QualificationName),
        offerQualificationId: this.convertNullToEmpty(x.OfferQualificationID),
        offerQualificationName: this.convertNullToEmpty(x.OfferQualificationName),
        qualificationAlternateId: this.convertNullToEmpty(x.QualificationAlternateID),
      })
    })
    alternateQualifications.sort((a, b) => {
      return a.qualificationName > b.qualificationName ? 1 : -1
    })
    return alternateQualifications
  },
  formatQualificationServiceRelationship(data) {
    var qualificationServiceRelationships = []
    data.map(x => {
      qualificationServiceRelationships.push({
        bookingTypeId:this.convertNullToEmpty(x.BookingTypeID),
        bookingTypeName:this.convertNullToEmpty(x.BookingTypeName),
        offerBookingTypeId:this.convertNullToEmpty(x.OfferBookingTypeID),
        offerBookingTypeName:this.convertNullToEmpty(x.OfferBookingTypeName),
        offerQualificationId:this.convertNullToEmpty(x.OfferQualificationID),
        offerQualificationName:this.convertNullToEmpty(x.OfferQualificationName),
        qualificationId:this.convertNullToEmpty(x.QualificationID),
        qualificationName:this.convertNullToEmpty(x.QualificationName),
        qualificationServiceRelationshipId:this.convertNullToEmpty(x.QualificationServiceRelationshipID),
        wording: this.convertNullToEmpty(x.Wording)
      })
    })
    return qualificationServiceRelationships
  },
  formatAccommodations(data) {
    var accommodations = []
    var accommodation = {}
    data.map(x => {
      accommodation = {
        city: this.convertNullToEmpty(x.City),
        state: this.convertNullToEmpty(x.State),
        postcode: this.convertNullToEmpty(x.Postcode),
        country: this.convertNullToEmpty(x.Country),
        phoneNumber: this.convertNullToEmpty(x.PhoneNumber),
        addressLine1: this.convertNullToEmpty(x.AddressLine1),
        addressLine2: this.convertNullToEmpty(x.AddressLine2),
        id: this.convertNullToEmpty(x.AccommodationID),
        name: this.convertNullToEmpty(x.AccommodationName),
      }
      accommodation.fullTravel = (accommodation.addressLine1 != undefined ? (accommodation.addressLine1 != '' ? accommodation.addressLine1 + ' ' + accommodation.addressLine2 : '') : '') + ', ' + accommodation.city
      accommodations.push(accommodation)
    })
    return accommodations
  },
  formatBookingLog(data) {
    var bookingLogs = []
    data.map(x => {
      bookingLogs.push({
        userId: this.convertNullToEmpty(x.UserID),
        logDate: this.convertNullToEmpty(x.LogDate),
        userName: this.convertNullToEmpty(x.Username),
        bookingId: this.convertNullToEmpty(x.BookingID),
        logDetails: this.convertNullToEmpty(x.LogDetails),
        bookingLogId: this.convertNullToEmpty(x.BookingLogID),
      })
    })
    bookingLogs.sort((a, b) => {
      return moment(a.logDate) < moment(b.logDate) ? 1 : -1
    })
    return bookingLogs
  },
  formatClientLog(data) {
    var clientLogs = []
    data.map(x => {
      clientLogs.push({
        userId: this.convertNullToEmpty(x.UserID),
        logDate: this.convertNullToEmpty(x.LogDate),
        userName: this.convertNullToEmpty(x.Username),
        clientId: this.convertNullToEmpty(x.ClientID),
        logDetails: this.convertNullToEmpty(x.LogDetails),
        clientLogId: this.convertNullToEmpty(x.ClientLogID),
      })
    })
    clientLogs.sort((a, b) => {
      return moment(a.logDate, "YYYY-MM-DD") < moment(b.logDate, "YYYY-MM-DD") ? 1 : -1
    })
    return clientLogs
  },
  formatAirlines(data) {
    var airlines = []
    data.map(x => {
      airlines.push({
        id: this.convertNullToEmpty(x.AirlineID),
        name: this.convertNullToEmpty(x.AirlineName),
        contactPhone: this.convertNullToEmpty(x.ContactPhone)
      })
    })
    return airlines
  },
  formatAirports(data) {
    var airports = []
    data.map(x => {
      airports.push({
        id: this.convertNullToEmpty(x.AirportID),
        name: this.convertNullToEmpty(x.AirportName),
        code: this.convertNullToEmpty(x.AirportCode),
      })
    })
    return airports
  },
  formatCarHires(data){
    var carHires = []
    data.map(x => {
      carHires.push({
        city: (x.City),
        name: decodeURI(x.CarHireName),
        id: (x.CarHireID),
        state: (x.State),
        postcode: (x.Postcode),
        phoneNumber: (x.PhoneNumber),
        addressLine1: decodeURI(x.AddressLine1),
        addressLine2: decodeURI(x.AddressLine2),
      })
    })
    return carHires
  },
  formatPurchaseOrder(data) {
    return {
      paid: this.convertNumToBool(data.Paid),
      bookingId: this.convertNullToEmpty(data.BookingID),
      approvedBy: this.convertNullToEmpty(data.ApprovedBy),
      approvedDate: this.convertNullToEmpty(data.ApprovedDate),
      approvedTotal: this.convertNullToEmpty(data.ApprovedTotal),
      invoiceNumber: this.convertNullToEmpty(data.InvoiceNumber),
      id: this.convertNullToEmpty(data.BookingPurchaseOrderID),
      status: this.convertNullToEmpty(data.BookingPurchaseOrderStatus),
    }
  },
  formatPurchaseOrderItems(data) {
    var items = []
    data.map(x=>{
      items.push({
        amount:this.convertNullToEmpty(x.Amount),
        dateAdded:this.convertNullToEmpty(x.DateAdded),
        id:this.convertNullToEmpty(x.PurchaseOrderItemID),
        bookingPurchaseOrderId:this.convertNullToEmpty(x.BookingPurchaseOrderID),
        description:this.convertNullToEmpty(x.PurchaseOrderItemDescription),
        itemCode: this.convertNullToEmpty(x.ItemCode)
      })
    })
    return items
  },
  formatTravelItineraryWithoutItems(data) {
    var items = []
    data.map(x=>{
      items.push({
        notes:this.convertNullToEmpty(x.Notes),
        itinerary:this.convertNullToEmpty(x.Itinerary),
        approvedBy:this.convertNullToEmpty(x.ApprovedBy),
        assignedTo:this.convertNullToEmpty(x.AssignedTo),
        dateApproved:this.convertNullToEmpty(x.DateApproved),
        specialistId:this.convertNullToEmpty(x.SpecialistID),
        dateCompleted:this.convertNullToEmpty(x.DateCompleted),
        specialistTravelItineraryId:this.convertNullToEmpty(x.SpecialistTravelItineraryID),
        dateUploaded:this.convertNullToEmpty(x.DateUploaded),
        fileName:this.convertNullToEmpty(x.FileName),
        filePath:this.convertNullToEmpty(x.FilePath),
        fileStatus:this.convertNullToEmpty(x.FileStatus),
        fileTypeId:this.convertNullToEmpty(x.FileTypeID),
        mime:this.convertNullToEmpty(x.Mimetype),
        uploadedBy:this.convertNullToEmpty(x.UploadedBy),
        itineraryStatus: this.convertNullToEmpty(x.SpecialistTravelItineraryStatus),
      })
    })
    return items
  },
  formatAllTravelItineraries(data) {
    var travelItineraries = []
    var travelItinerary = {}
    var date = ""
    data.map(x => {
      travelItinerary = {
        itinerary: this.convertNullToEmpty(x.Itinerary),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        id: this.convertNullToEmpty(x.SpecialistTravelItineraryID),
        status: this.convertNullToEmpty(x.SpecialistTravelItineraryStatus),
        travelEndDate: this.convertNullToEmpty(x.TravelEndDate),
        travelStartDate: this.convertNullToEmpty(x.TravelStartDate),
        availabilityDate: this.convertNullToEmpty(x.AvailabilityDate),
        locationID:this.convertNullToEmpty(x.LocationID),
        locationName:this.convertNullToEmpty(x.LocationName)
      }
      travelItinerary.name = travelItinerary.itinerary + (travelItinerary.itinerary != '' ? ", " : "") + travelItinerary.locationName
      date = this.formatDate(travelItinerary.travelStartDate, "DD/MM/YYYY") + (travelItinerary.travelStartDate != '' && travelItinerary.travelEndDate != '' ? " - " : "") + this.formatDate(travelItinerary.travelEndDate, "DD/MM/YYYY")
      if(date == '') date = this.formatDate(travelItinerary.availabilityDate, "DD/MM/YYYY")
      if(date != '') travelItinerary.name += " (" + date + ")"
      travelItineraries.push(travelItinerary)
    })
    return travelItineraries
  },
  formatSpecialistFees(data) {
    var fees = []
    data.map(x => {
      fees.push({
        fee: this.convertNullToEmpty(x.Fee),
        clientId: this.convertNullToEmpty(x.ClientID),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        bookingTypeId: this.convertNullToEmpty(x.BookingTypeID),
        bookingTypeName: this.convertNullToEmpty(x.BookingTypeName),
        billingCategoryId: this.convertNullToEmpty(x.BillingCategoryID),
        specialistFeecode: this.convertNullToEmpty(x.SpecialistFeecode),
        billingCategoryName: this.convertNullToEmpty(x.BillingCategoryName),
        billingCategorySpecialistFeeId: this.convertNullToEmpty(x.BillingCategorySpecialistFeeID),
      })
    })
    return fees
  },
  formatCancellations(data) {
    var fees = []
    data.map(x => {
      fees.push({
        clientId: this.convertNullToEmpty(x.ClientID),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        cancellationFee: this.convertNullToEmpty(x.CancellationFee),
        billingCategoryId: this.convertNullToEmpty(x.BillingCategoryID),
        billingCategorySpecialistCancellationFeeId: this.convertNullToEmpty(x.BillingCategorySpecialistCancellationFeeID),
      })
    })
    return fees
  },
  formattedAvailabilities(data) {
    var availabilities = []
    var availability = {}
    data.map(x => {
      availability = {
        id: this.convertNullToEmpty(x.SpecialistAvailabilityTravelID),
        state: this.convertNullToEmpty(x.LocationState),
        endtime: this.convertNullToEmpty(x.Endtime),
        cityName: this.convertNullToEmpty(x.CityName),
        startTime: this.convertNullToEmpty(x.StartTime),
        locationId: this.convertNullToEmpty(x.LocationID),
        locationName: this.convertNullToEmpty(x.LocationName),
        availabilityDate: this.convertNullToEmpty(x.AvailabilityDate),
        availabilityStatus: this.convertNullToEmpty(x.AvailabilityStatus),
        availabilityId: this.convertNullToEmpty(x.AvailabilityID),
        numberoftimeslotsbooked: this.convertNullToEmpty(x.Numberoftimeslotsbooked),
      }
      availability.fullLocation = this.addComma(availability.locationName) + availability.cityName
      availabilities.push(availability)
    })
    return availabilities
  },
  formatSpecialistAvailabilityLog(data) {
    var items = []
    data.forEach(x=> {
      items.push({
        logDate: this.convertNullToEmpty(x.LogDate),
        rhoUser: this.convertNullToEmpty(x.RHOUser),
        logDetails: this.convertNullToEmpty(x.LogDetails),
        rhoUserName: this.convertNullToEmpty(x.Username),
        specialistId: this.convertNullToEmpty(x.SpecialistID),
        specialistTitle: this.convertNullToEmpty(x.SpecialistTitle),
        specialistSurnName: this.convertNullToEmpty(x.SpecialistSurnName),
        specialistFirstName: this.convertNullToEmpty(x.SpecialistFirstName),
        specialistAvailabilityLogId: this.convertNullToEmpty(x.SpecialistAvailabilityLogID),
      })
    })
    return items
  },
  formatConsentForms(data) {
    var forms = []
    forms.from1 = this.convertNullToEmpty(data.ConsentForm1)
    forms.from2 = this.convertNullToEmpty(data.ConsentForm2)
    forms.from3 = this.convertNullToEmpty(data.ConsentForm3)
    forms.specialistFrom = this.convertNullToEmpty(data.SpecialConsentForm)
    return forms
  },

  // Common function
  delay(ms) {
    return new Promise(res => setTimeout(res, ms))
  },
  showAlert(alert, close = true) {
    $(alert).fadeIn(1000)
    if(close) {
      setTimeout(() => {
        this.closeAlert(alert)
      }, 2000)
    }
  },
  hideAlert(alert) {
    $(alert).fadeOut()
  },
  closeAlert(alert = "") {
    var intervalAlert = setInterval(() => {
      if (alert != "") {
        $(alert).fadeOut()
      } else {
        $(".alert").fadeOut()
      }

      clearInterval(intervalAlert)
    }, 2000)
  },
  hideMonths(year = "") {
    var currentMonth = Number(moment().format("M"))
    if(year == moment().format("YYYY")) {
      for (var i = 0; i < currentMonth - 1; i++) {
        $('.month-container[data-month-id="' + i + '"]').hide()
      }
    } else {
      for (var i = currentMonth; i < 12; i++) {
        $('.month-container[data-month-id="' + i + '"]').hide()
      }
    }
  },
  hideMonthsClientCalendar(year = "", start, end) {
    console.log(start)
    var currentMonth = start ? Number(moment(start).format("M")) : Number(moment().format("M"))
    if(year == moment().format("YYYY")) {
      for (var i = 0; i < currentMonth - 1; i++) {
        $('.month-container[data-month-id="' + i + '"]').hide()
      }
      if(end && moment(end).format("YYYY") == year) {
        for (var i = 11; i > Numberne(moment(end).format("M")) - 1; i--) {
          $('.month-container[data-month-id="' + i + '"]').hide()
        }
      }
    } else {
      if(end) {
        for (var i = 11; i > Number(moment(end).format("M")) - 1; i--) {
          $('.month-container[data-month-id="' + i + '"]').hide()
        }
      } else {
        var upto = 12 - currentMonth
        for (var i = 12 - upto - 1; i < 12; i++) {
          $('.month-container[data-month-id="' + i + '"]').hide()
        }
      }
    }
  },
  hideNoDataMonth(year, dataSource) {
    if(!dataSource || dataSource.length === 0) return
    let arr = dataSource.sort((a, b) =>  moment(a.date).isAfter(moment(b.date)) ? 1 : -1)
    console.log(arr)
  },
  clearChangingObject() {
    // Clear changing object
    store.dispatch("setOriginalObject", null)
    store.dispatch("setNewObject", null)
  },
  initTooltip(ms = 500) {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="tooltip"]').attr('title', '')
    }, ms)
  },
  disposeTooltip() {
    $('[data-toggle="tooltip"]').tooltip('dispose')
  },
  hoverTooltip() {
    setTimeout(() => {
      $("table.table tbody td div[data-toggle='tooltip']").hover(function(e) {
        var element = Number($(this).css('width').replace("px", ""))
        var children = Number($(this).children().css('width').replace("px", ""))
        if(children <= element) {
          $(this).tooltip("hide")
        }
      })
    }, 500)
  },
  getInitials(firstName, lastName) {
    var initials = ""
    if (firstName) {
      initials = firstName.substring(0,1)
    }
    if (lastName) {
      initials = initials + lastName.substring(0,1)
    }
    return initials
  },
  getMergedTimes(availableTimes, minimumAppointmentLength, onlyAvailable = false) {
    var timeB = 0
    var startTimeB = ''
    var endTimeB = ''
    var timeA = 0
    var startTimeA = ''
    var endTimeA = ''
    var timeObject = {
      startTime: "",
      endTime: "",
      status: "",
    }
    var mergedTimes = []
    availableTimes.map((x, xi) => {
      if(onlyAvailable) { // only status A
        if(timeA == 0) {
          startTimeA = x.time
        }
        timeA += SLOT_SIZE
        if(timeA == minimumAppointmentLength) {
          endTimeA = moment(startTimeA, 'hh:mm A').add(timeA, 'minutes').format('hh:mm A')
          timeObject = {
            startTime: startTimeA,
            endTime: endTimeA,
            status: 'A',
          }
          mergedTimes.push(timeObject)
          timeA = 0
        }
      } else {  // status A and B
        // Merge Booked slot
        if(x.status == 'B') {
          if(timeB == 0) {
            startTimeB = x.time
          }
          timeB += SLOT_SIZE
          if((availableTimes[xi + 1] && availableTimes[xi + 1].status == 'A') || !availableTimes[xi + 1]) {
            endTimeB = moment(startTimeB, 'hh:mm A').add(timeB, 'minutes').format('hh:mm A')
            timeObject = {
              startTime: startTimeB,
              endTime: endTimeB,
              status: 'B',
            }
            mergedTimes.push(timeObject)
            timeB = 0
          }
        }

        // Merge Available slot
        if(x.status == 'A') {
          if(timeA == 0) {
            startTimeA = x.time
          }
          timeA += SLOT_SIZE
          if((availableTimes[xi + 1] && availableTimes[xi + 1].status == 'B') || !availableTimes[xi + 1] || timeA == minimumAppointmentLength) {
            endTimeA = moment(startTimeA, 'hh:mm A').add(timeA, 'minutes').format('hh:mm A')
            timeObject = {
              startTime: startTimeA,
              endTime: endTimeA,
              status: 'A',
            }
            mergedTimes.push(timeObject)
            timeA = 0
          }
        }
      }
    })

    return mergedTimes
  },

  getUserId() {
    return store.getters['user/getUser'].id
  },

  getUserName() {
    return store.getters['user/getUser'].name
  },

  getClientId() {
    return store.getters['user/getUser'].clientId
  },

  getSpecialistId() {
    return store.getters['user/getUser'].specialistId
  },

  getIdToken() {
    var authentication = _.cloneDeep(store.getters["user/getAuthentication"])
    return authentication.idToken
  },

  getIdTokenExpireTime() {
    var authentication = _.cloneDeep(store.getters["user/getAuthentication"])
    return authentication.idTokenExpire
  },

  sortByDate(list, key, type, maxDate) {
    var date1 = null
    var date2 = null
    list.sort((a, b) => {
      if(a[key] != '') {
        date1 = moment(a[key], "YYYY-MM-DD")
      } else {
        date1 = moment(maxDate, "YYYY-MM-DD")
      }
      if(b[key] != '') {
        date2 = moment(b[key], "YYYY-MM-DD")
      } else {
        date2 = moment(maxDate, "YYYY-MM-DD")
      }

      if (type) {
        // ASC
        return date1.isAfter(date2) ? 1 : -1
      } else {
        // DESC
        return date1.isBefore(date2) ? 1 : -1
      }
    })
    return list
  },

  async getFile(filePath, fileName) {
    if(filePath.trim() != '') {
      store.dispatch('loading/setLoadComponent', true)
      var blob = await this.getFileUrl() //this.getFileFromPathByToken(filePath.replace("BookingFiles", "files"))
      if(blob != undefined) {
        var a = document.createElement('a')
        a.setAttribute('href', window.URL.createObjectURL(blob))
        // a.setAttribute('target', '_blank')
        a.setAttribute('download', fileName)
        a.click()
      }
      store.dispatch('loading/setLoadComponent', false)
    }
  },

  async checkEmailValidate(email) {
    return new Promise((resolve, reject) => {
      axios.post(URL_API_3 + 'checkExist', {EmailAddress: email}, {
        headers: {'Authorization': this.getIdToken()},
      }).then(r => {
        if(r.data.result) {
          resolve(r.data.result)
        } else {
          reject('Email does not exist')
        }
      }).catch(e => {
        store.dispatch('loading/setLoadComponent', false)
        this.showErrorMessage(e.response.data?.message || e.response.data)
        reject(e)
      })
    })
  },

  async getProfileThumbnail(photoPath) {
    var profileThumbnail = ""
    var blob = await this.getFileFromPathByToken('profile/' + photoPath)
    await new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.onloadend = function() {
        if (reader.result) {
          profileThumbnail = reader.result
          resolve(true)
        }
      }
      reader.onerror = (error) => {
        reject(error)
      }
      if (true) {
        reader.readAsDataURL(blob)
      }
    })
    return profileThumbnail
  },
  getAutoCompleteTags(data = [], key = "", className = "") {
    var tags = []
    var tag = {}
    data.map(x => {
      tag = {
        id: "",
        value: key != '' ? x.value : x.id,
        text: key != '' ? x[key] : x.name,
      }
      if(className != "") tag.classes = className
      tags.push(tag)
    })

    return tags
  },
  getAutoCompleteBodyAreaTags(data = [], key = "", className = "") {
    var tags = []
    var tag = {}
    data.map(x => {
      tag = {
        id: "",
        value: x.id,
        text: x.bodyArea,
      }
      if(className != "") tag.classes = className
      tags.push(tag)
    })

    return tags
  },
  getAutoCompleteOtherBodyAreaTags(data = [], key = "", className = "") {
    var tags = []
    var tag = {}
    data.map(x => {
      tag = {
        id: "",
        text: x,
      }
      if(className != "") tag.classes = className
      tags.push(tag)
    })

    return tags
  },

  getDropdownAutoCompleteTag(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      var name = x.name.replace('(', '-').replace(')', '')
      item = {
        id: x.id,
        name: name + '-' + x.branchName,
        fullName: x.fullName
      }
      items.push(item)
    })
    return items
  },

  getClientsDropdownAutoCompleteTag(data = [], addOther = true) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: addOther ? x.clientContactId : x.contactUserId , // Booking searchCases API need contactUserId
        name: x.fullName + '-'+ x.emailAddress,
        value: x.emailAddress
      }
      x.active && items.push(item)
    })

    if (addOther) {
      //add other option into the Array
      item = {
        id: -1,
        name: 'Other',
        value: 'Other'
      }
      items.push(item)
    }
    return items
  },

  getClientsDropdownAutoCompleteTagWithName(data = [], addOther = true) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: addOther ? x.clientContactId : x.contactUserId , // Booking searchCases API need contactUserId
        name: x.fullName + '-'+ x.emailAddress
      }
      items.push(item)
    })

    if (addOther) {
      //add other option into the Array
      item = {
        id: -1,
        name: 'Other'
      }
      items.push(item)
    }
    return items
  },

  getClientsContactDropdownAutoCompleteTag(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.userId,
        name: x.fullName + '-'+ x.emailAddress,
        value: x.emailAddress
      }
      x.active && items.push(item)
    })
    return items
  },

  getDropdownCases(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.claimantFullName + ' - ' + x.caseReference
      }
      items.push(item)
    })
    return items
  },

  getDropdownSpecialist(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.fullName
      }
      items.push(item)
    })
    return items
  },

  getDropdownLocation(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.fullLocation
      }
      items.push(item)
    })
    return items
  },

  getDropdownAirport(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.name + ' ' + x.code
      }
      items.push(item)
    })
    return items
  },

  getDropdownAirline(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.name
      }
      items.push(item)
    })
    return items
  },

  getDropdownAccommodation(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.name
      }
      items.push(item)
    })
    return items
  },

  getDropdownCarhires(data = []) {
    var items = []
    var item = {}
    data.map(x => {
      item = {
        id: x.id,
        name: x.name
      }
      items.push(item)
    })
    return items
  },

  getRegionLocations(regions, locations, isClient = false) {
    var regionLocations = []
    var region = {}
    regions.map(x => {
      region = {
        id: 'r' + x.id,
        label: this.convertNullToEmpty(x.name),
        children: [],
        state: x.state,
      }
      regionLocations.push(region)
    })

    var location = {}
    locations = _.uniqBy(locations, 'cityId')
    locations.map(x => {
      location = {
        id: 'c' + x.cityId,
        label: this.convertNullToEmpty(x.cityName),
      }
      region = regionLocations.find(y => Number(y.id.replace("r", "")) == x.regionId)
      if(region != undefined) {
        region.children.push(location)
      }
    })

    // Hide region without any cities
    // regionLocations = regionLocations.filter(x => x.children.length > 0)
    // Group by State
    var temp = _.uniqBy(_.cloneDeep(regions), "state")
    temp.sort((a, b) => {
      return a.state > b.state ? 1 : -1
    })

    var states = []
    var state = {}
    temp.map((x, xi) => {
      state = {
        id: 's' + (xi + 1),
        label: this.convertNullToEmpty(x.state),
        children: [],
      }
      states.push(state)
    })
    states.push(states.shift())

    states.map(x => {
      x.children = regionLocations.filter(y => Number(y.state == x.label))
      x.children.sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })
    })

    // Hide state without any regions
    // states = states.filter(x => x.children.length > 0)
    //Client can only select to the Region Level. deleting city from region.
    if (isClient) {
      states.map((x, i) => {
        x.children.map((y) => {
          delete y.children
        })
      })
    }
    return states
  },
  getTitles() {
    return [
      {
        id: 1,
        name: "Dr",
      },
      {
        id: 2,
        name: "A/Prof",
      },
      {
        id: 3,
        name: "Prof",
      },
      {
        id: 4,
        name: "Ms",
      },
      {
        id: 5,
        name: "Mr",
      },
    ]
  },
  getClientAlignments() {
    var clientAlignment = [
      {
        value: "Defendant",
        text: "Defendant",
      },
      {
        value: "Plaintiff",
        text: "Plaintiff",
      },
      {
        value: "Both",
        text: "Both",
      },
    ]
    return clientAlignment
  },
  getStates() {
    var states = [
      {
        value: "ACT",
        text: "Australian Capital Territory",
      },
      {
        value: "NSW",
        text: "New South Wales",
      },
      {
        value: "NT",
        text: "Northern Territory",
      },
      {
        value: "QLD",
        text: "Queensland",
      },
      {
        value: "SA",
        text: "South Australia",
      },
      {
        value: "TAS",
        text: "Tasmania",
      },
      {
        value: "VIC",
        // value: "Victoria",
        text: "Victoria",
      },
      {
        value: "WA",
        text: "Western Australia",
      },
    ]
    return states
  },
  getSearchSpecialistsBookingTypes() {
    return [
      {
        id: "ServiceIMEOrVideo",
        name: "Independent Medical Examination (Video Link or Face to Face)",
        otherName: 'Independent Medical Examination',
        isClint: true,
        field: 'serviceIME',
        field2: 'serviceIMEVideo',
        isInCreateBooking: true
      },
      {
        id: "ServicePhoneCall",
        name: "Phone Call",
        otherName: '',
        isClint: true,
        field: 'servicePhoneCall',
        isInCreateBooking: true
      },
      {
        id: "ServiceCourtCase",
        name: "Court case",
        otherName: '',
        isClint: false,
        field: 'serviceCourtCase',
        isInCreateBooking: true
      },
      {
        id: "ServiceFileReview",
        name: "File Review",
        otherName: '',
        isClint: false,
        field: 'serviceFileReview',
        isInCreateBooking: true
      },
      {
        id: "ServiceMedicalNegligenceRequest",
        name: "Medical Negligence Request",
        otherName: '',
        isClint: false,
        field: 'serviceMedicalNegligenceRequest',
        isInCreateBooking: true
      },
      {
        id: "ServiceSupplementary",
        name: "Supplementary",
        otherName: '',
        isClint: false,
        field: 'serviceSupplementary',
        isInCreateBooking: true
      },
    ]
  },
  getCreateSpecialistsBookingTypes() {
    return [
      {
        id: "ServiceIME",
        name: "Independent Medical Examination",
        otherName: 'Independent Medical Examination',
        isClint: true,
        field: 'serviceIME',
        isInCreateBooking: true,
        clientSideName: 'Face to Face Assessment'
      },
      {
        id: "ServiceIMEAbuse",
        name: "Independent Medical Examination - Abuse",
        otherName: '',
        isClint: false,
        field: 'ServiceIMEAbuse',
        isInCreateBooking: false
      },
      {
        id: "ServiceIMEVideo",
        name: "Independent Medical Examination by Video",
        isClint: true,
        otherName: '',
        field: 'serviceIMEVideo',
        isInCreateBooking: true,
        clientSideName: 'VideoLink Assessment'
      },
      {
        id: "ServicePhoneCall",
        name: "Phone Call",
        otherName: '',
        isClint: false,
        field: 'servicePhoneCall',
        isInCreateBooking: true
      },
      {
        id: "ServiceCourtCase",
        name: "Court case",
        otherName: '',
        isClint: false,
        field: 'serviceCourtCase',
        isInCreateBooking: true
      },
      {
        id: "ServiceFileReview",
        name: "File Review",
        otherName: '',
        isClint: false,
        field: 'serviceFileReview',
        isInCreateBooking: true
      },
      {
        id: "ServiceMedicalNegligenceRequest",
        name: "Medical Negligence Request",
        otherName: '',
        isClint: false,
        field: 'serviceMedicalNegligenceRequest',
        isInCreateBooking: true
      },
      {
        id: "ServiceSupplementary",
        name: "Supplementary",
        otherName: '',
        isClint: false,
        field: 'serviceSupplementary',
        isInCreateBooking: true
      },
      {
        id: "fceLite",
        name: "FCE Lite",
        isClint: false,
        otherName: '',
        field: 'fceLite',
        isInCreateBooking: true
      },
      {
        id: "fceLiteByVideo",
        name: "FCE Lite by Video",
        isClint: false,
        otherName: '',
        field: 'fceLiteByVideo',
        isInCreateBooking: true
      },
    ]
  },



  getBookingTypeAbbreviations(){
    return [
      {
        id: 1,
        name: "IMA"
      },
      {
        id: 2,
        name: "IMA - Abuse"
      },
      {
        id: 3,
        name: "Supplementary"
      },
      {
        id: 4,
        name: "File Review"
      },
      {
        id: 5,
        name: "Phone"
      },
      {
        id: 6,
        name: "Additional Services"
      },
      {
        id: 7,
        name: "OTFCE"
      },
    ]
  },
  getBookingStatuses() {
    return [
      {
        id: 3,
        name: "Booked",
      },
      {
        id: 4,
        name: "Awaiting Appointment",
      },
      {
        id: 5,
        name: "Attended",
      },
      {
        id: 6,
        name: "Cancelled",
      },
      {
        id: 7,
        name: "Failed to Attend",
      },
      {
        id: 9,
        name: "With QA",
      },
      {
        id: 10,
        name: "Report Completed",
      },
      // {
      //   id: 11,
      //   name: "Finalised",
      // },
      // {
      //   id: 12,
      //   name: "Finalised Late Documents",
      // },
      // {
      //   id: 13,
      //   name: "Invoiced No Documents",
      // },
      {
        id: 14,
        name: "Invoiced",
      },
      {
        id: 15,
        name: "Archived",
      }
    ]
  },
  getBookingStatus(id) {
    var bookingStatus = this.getBookingStatuses().find(x => x.id == id)
    return bookingStatus != undefined ? bookingStatus.name : ''
  },
  getPaperworkStatuses() {
    return [
      {
        id: 1,
        name: "LOI Received",
      },
      {
        id: 2,
        name: "Brief Received",
      },
      {
        id: 3,
        name: "Paperwork Processed",
      },
      {
        id: 4,
        name: "Video Test Completed",
      },
      {
        id: 5,
        name: "Pre-Appointment Forms Completed",
      },
    ]
  },
  getPaperworkStatus(id) {
    var paperworkStatus = this.getPaperworkStatuses().find(x => x.id == id)
    return paperworkStatus != undefined ? paperworkStatus.name : ''
  },
  getBookingRequestStatuses() {
    return [
      {
        id: 1,
        name: "New",
      },
      {
        id: 2,
        name: "Assigned",
      },
      {
        id: 3,
        name: "Completed",
      },
    ]
  },
  getBookingRequestStatus(id) {
    var bookingRequestStatus = this.getBookingRequestStatuses().find(x => x.id == id)
    return bookingRequestStatus != undefined ? bookingRequestStatus.name : ''
  },
  getBookingClashStatuses() {
    return [
      {
        id: 1,
        name: "New",
      },
      {
        id: 2,
        name: "In Progress",
      },
      {
        id: 3,
        name: "Resolved",
      },
    ]
  },
  getBookingClashStatus(id) {
    var bookingClashStatus = this.getBookingClashStatuses().find(x => x.id == id)
    return bookingClashStatus != undefined ? bookingClashStatus.name : ''
  },
  getAvailabilityStatuses() {
    return [
      {
        id: 1,
        name: "Draft",
      },
      {
        id: 2,
        name: "Submitted",
      },
      {
        id: 3,
        name: "Face to Face",
      },
      {
        id: 4,
        name: "Fully Booked",
      },
      {
        id: 5,
        name: "By Request Only",
      },
    ]
  },
  getAvailabilityStatus(id) {
    var availabilityStatus = this.getAvailabilityStatuses().find(x => x.id == id)
    return availabilityStatus != undefined ? availabilityStatus.name : ''
  },
  getTravelItineraryStatuses() {
    // Start from 0
    return [
      {
        id: 0,
        name: 'New',
        value: false,
      },
      {
        id: 1,
        name: 'In Progress',
        value: false,
      },
      {
        id: 2,
        name: 'Actioned',
        value: true,
      },
      {
        id: 3,
        name: 'Approved',
        value: true,
      }
    ]
  },
  getAdditionalHoursStatuses() {
    return [
      {
        id: 0,
        name: 'Pending',
      },
      {
        id: 1,
        name: 'Approved',
      },
      {
        id: 2,
        name: 'Rejected',
      },
    ]
  },

  getClientCategories() {
    return [
      {
        value: 'Workcover',
        text: 'Workcover'
      },
      {
        value: 'Self Insurers',
        text: 'Self Insurers'
      },
      {
        value: 'Government',
        text: 'Government'
      },
      {
        value: 'Insurers',
        text: 'Insurers'
      },
      {
        value: 'Legal Plaintiff',
        text: 'Legal Plaintiff'
      },
      {
        value: 'Legal Defendant',
        text: 'Legal Defendant'
      },
      {
        value: 'Human Resources',
        text: 'Human Resources'
      },
    ]
  },
  getFrequencies() {
    return [
      {
        value: 1,
        text: 'Once Off'
      },
      {
        value: 2,
        text: 'Daily'
      },
      {
        value: 3,
        text: 'Weekly'
      },
      {
        value: 4,
        text: 'Monthly'
      },
    ]
  },
  getOccurrences() {
    return [
      {
        value: 1,
        text: 'Every'
      },
      {
        value: 2,
        text: '1st'
      },
      {
        value: 3,
        text: '2nd'
      },
      {
        value: 4,
        text: '3rd'
      },
      {
        value: 5,
        text: '4th'
      },
    ]
  },
  getWeekDays() {
    return [
      {
        value: 1,
        text: 'Monday',
      },
      {
        value: 2,
        text: 'Tuesday',
      },
      {
        value: 3,
        text: 'Wednesday',
      },
      {
        value: 4,
        text: 'Thursday',
      },
      {
        value: 5,
        text: 'Friday',
      },
      {
        value: 6,
        text: 'Saturday',
      },
      {
        value: 7,
        text: 'Sunday',
      },
    ]
  },
  getFillOrders() {
    return [
      {
        value: 'FtL',
        text: 'First to Last',
      },
      {
        value: 'LtF',
        text: 'Last to First',
      },
    ]
  },
  getFillOrder(value) {
    var fillOrder = this.getFillOrders().find(x => x.value.toLowerCase() == value.toLowerCase())
    return fillOrder != undefined ? fillOrder.text : ''
  },
  getNewAppointmentLengths(minimum) {
    var bookingLengths = []
    for(var i = 0; i < 5; i++) {
      bookingLengths.push({
        value: minimum,
        text: this.convertMinuteToHourText(minimum),
      })
      minimum += 15
    }
    return bookingLengths
  },
  getShortAppointmentLengths() {
    return [
      {
        value: 15,
        text: '15 mins',
      },
      {
        value: 30,
        text: '30 mins',
      },
      {
        value: 45,
        text: '45 mins',
      },
      {
        value: 60,
        text: '1 hour',
      },
    ]
  },
  getAppointmentLengths() {
    return [
      {
        value: 60,
        text: '1 hour',
      },
      {
        value: 75,
        text: '1 hour 15 mins',
      },
      {
        value: 90,
        text: '1 hour 30 mins',
      },
      {
        value: 105,
        text: '1 hour 45 mins',
      },
      {
        value: 120,
        text: '2 hours',
      },
    ]
  },
  getLongAppointmentLengths() {
    return [
      {
        value: 180,
        text: '3 hours',
      },
      {
        value: 240,
        text: '4 hours',
      },
      {
        value: 300,
        text: '5 hours',
      },
    ]
  },
  getCancellationReasons() {
    return [
      {
        value: 'Availability',
        text: 'Availability',
      },
      {
        value: 'Location',
        text: 'Location',
      },
      {
        value: 'Specialty',
        text: 'Specialty',
      },
      {
        value: 'Matter Settled',
        text: 'Matter Settled',
      },
      {
        value: 'Out of Control',
        text: 'Out of Control',
      },
      {
        value: 'None Provided',
        text: 'None Provided',
      },
      {
        value: 'Other',
        text: 'Other',
      },
    ]
  },
  getStatus() {
    return [
      {
        value: '0',
        text: 'Inactive',
      },
      {
        value: '1',
        text: 'Active',
      },
    ]
  },
  getBookingHourServices() {
    return [
      {
        value: 1,
        text: 'Preparation Time',
      },
      {
        value: 2,
        text: 'Reading Time',
      },
    ]
  },
  getColorHexForBookingType(typeId){
    switch(typeId) {
      case 0: return '#3D83F926'
      case 2: return '#3D83F926'
      case 3: return '#3D83F926'
      case 4: return '#3D83F926'
      case 5: return '#3D83F926'
      case 7: return '#3D83F926'
      default: return '#3D83F926'
    }
  },
  setViewStatus(index) {
    if(!$(".box .box-body").eq(index).is(":visible")) {
      $(".box .box-body").fadeOut()
      $(".box .box-body").eq(index).fadeIn()
    }
  },
  openTab(index) {
    if(!$(".tab-content").eq(index).is(":visible")) {
      $(".tab-content").hide()
      $(".tab-content").eq(index).fadeIn()

      $(".tab-heading").removeClass("tab-selected")
      $(".tab-heading").eq(index).addClass("tab-selected")
    }
  },
  showZohoError(zoho, message = 'Could not create the booking in Zoho.') {
    var body = JSON.parse(zoho)
    if (body.statusCode != undefined) {
      var meg = body?.body && body.body.indexOf('{') > -1 ? JSON.parse(body.body) : ''
      if (body?.statusCode == 400 && meg?.code == 'INVALID_URL_PATTERN') {
        var content = `<b>WARNING:</b> ` + 'The Zoho id given seems to be invalid. Could not update in Zoho.'
        this.dispayZohoError(content)
      } else if (body?.statusCode == 400) {
        var content = `<b>Error:</b> ` + message
        this.dispayZohoError(content)
      }
    }
  },
  dispayZohoError(message) {
    $.dialog({
      title: "",
      content: message,
      onOpenBefore: function() {
        // Change to error color
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("background-color", "#fff3cd")
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("border", "2px solid #FF8000")
      },
    })
  },
  showSuccessMessage(message = "") {
    var content = `<b>SUCCESS:</b> ` + message
    $.dialog({
      title: "",
      content: content,
      onOpenBefore: function() {
        // Change to success color
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("background-color", "#D4EDDA")
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("border", "2px solid #4C9748")
      },
    })
  },
  showErrorMessage(message = "", originalMsg = "") {
    if (originalMsg == "The incoming token has expired") {
      location.reload()
      return;
    }
    if(message && message.indexOf('Error') > -1) {
      message = message.replace('Error:',  '')
    }
    var content = `<b>ERROR:</b> ` + message
    $.dialog({
      title: "",
      content: content,
      onOpenBefore: function() {
        // Change to error color
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("background-color", "#FDE9EA")
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("border", "2px solid #DA202D")
      },
    })
    store.dispatch('loading/setLoadComponent', false)
  },
  showWarningMessage(message = "") {
    var content = `<b>WARNING:</b> ` + message
    $.dialog({
      title: "",
      content: content,
      onOpenBefore: function() {
        // Change to success color
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("background-color", "#FDF3E9")
        $(".jconfirm .jconfirm-box-container .jconfirm-box").css("border", "2px solid #FF8000")
      },
    })
  },
  showWarningDialog(message = "", keepDoing = false) {
     var def = $.Deferred()
     var content = `<b>WARNING:</b> ` + message

     $.confirm({
       title: "",
       content: content,
       onOpenBefore: function() {
         // Change to warning color
         $(".jconfirm .jconfirm-box-container .jconfirm-box").css("background-color", "#FDF3E9")
         $(".jconfirm .jconfirm-box-container .jconfirm-box").css("border", "2px solid #FF8000")
       },
       closeIcon: true,
       buttons: {
         Yes: () => {
           def.resolve(true)
         },
         No: () => {
           if(keepDoing) def.resolve(false)
           else def.reject(false)
         },
       }
     })

     return def.promise()
  },
  showDialogInfo(message = "") {
     var def = $.Deferred()
     var content = message

     $.confirm({
       title: "",
       content: content,
       closeIcon: true,
       buttons: {
         Ok: () => {
           def.resolve(true)
         },
       }
     })

     return def.promise()
  },
  showDialogFromManageBooking(message = "") {
     var def = $.Deferred()
     var content = `<b>WARNING:</b> ` + message

     $.confirm({
       title: "",
       content: content,
       closeIcon: true,
       buttons: {
         Yes: () => {
           def.resolve(true)
         },
         No: () => {
           def.reject(false)
         },
       }
     })

     return def.promise()
  },

  async callBookingAPI() {
    var booking = store.getters["getNewObject"]
    var bodyAreaTags = JSON.parse(localStorage.getItem('storedBodyArea'))
    var singleCase = JSON.parse(localStorage.getItem('storedCase'))
    var client = JSON.parse(localStorage.getItem('storedClient'))
    var otherBodyAreaTags = JSON.parse(localStorage.getItem('storedOtherBodyArea'))
    var addedBodyArea = JSON.parse(localStorage.getItem('storedAddedBodyArea'))
    var personConditionTags = JSON.parse(localStorage.getItem('storedPersonCondition'))
    var addedConditions = JSON.parse(localStorage.getItem('storedAddedConditions'))
    var specialist = JSON.parse(localStorage.getItem('storedSpecialist'))
    var user = JSON.parse(localStorage.getItem('storedUser'))

    var bodyAreaObjects = []
    var bodyArea = {}
    if (bodyAreaTags != null && bodyAreaTags.length > 0) {
      bodyAreaTags.map((x) => {
        var temp = addedBodyArea.filter((y) => y.bodyArea == x.text)[0]
        if (temp == undefined) {
            bodyArea = {
              BodyAreaID: x.value
            }
            bodyAreaObjects.push(bodyArea)
        }
      })
    }

    var conditionObject = []
    var condition = {}
    if (personConditionTags != null && personConditionTags.length > 0) {
      personConditionTags.map((x) => {
        var temp = addedConditions.filter((y) => y.name == x.text)
        if (temp == undefined || temp.length == 0) {
            condition = {
              PersonConditionID: x.value
            }
            conditionObject.push(condition)
        }
      })
    }

    var params = {
      CaseID: singleCase.id,
      ZohoCaseID: singleCase.zohoCaseId,
      CaseReference: booking.caseReference,
      SpecialistID: booking.specialistId,
      SpecialistZohoLink: specialist.zohoLink,
      ClientID: user.clientId,
      DateCreated: moment().format('YYYY-MM-DD HH:mm:ss'),
      CurrentDateTime: moment().format('YYYY-MM-DD'),
      ZohoDateCreated: moment().format(),
      CreatedBy: user.id,
      BookingStatus: 1,
      BookingTypeID: booking.bookingTypeId,
      BookingTypeName: booking.bookingTypeName,
      onBehalfOf: booking.onBehalfOf,
      Private: booking.private,
      OTFCERequired: booking.otfceRequired,
      WillAcceptEarlier: booking.willAcceptEarlier,
      RedHealthMayContactClaimant: booking.redHealthMayContactClaimant,
      BillTo: booking.billTo,
      ReportTo: booking.reportTo,
      ReportToEmailAddress: booking.reportToEmailAddress,
      ClaimTypeID: booking.claimTypeId,
      DateFrom: booking.dateFrom,
      DateTo: booking.dateTo,
      BookingDateTime: booking.bookingDateTime,
      ZohoBookingDateTime: moment(booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format(),
      BookingEndTime : booking.bookingEndTime,
      ClaimantPhone1: booking.claimantPhone1,
      ClaimantPhone2: booking.claimantPhone2,
      ClaimantMobile: booking.claimantMobile,
      ClaimantEmail: booking.claimantEmail,
      ClaimantAddressLine1: booking.claimantAddressLine1,
      ClaimantAddressLine2: booking.claimantAddressLine2,
      ClaimantCity: booking.claimantCity,
      ClaimantState: booking.claimantState,
      ClaimantPostcode: booking.claimantPostcode,
      ClaimantCountry: booking.claimantCountry,
      LocationID: booking.locationId,
      VideoLinkTestStatus: this.convertBoolToNum(booking.videoLinkTestStatus),
      ClaimantFirstName: booking.claimantFirstName,
      ClaimantGivenNames: booking.claimantGivenNames,
      ClaimantSurname: booking.claimantSurname,
      DateofBirth: moment(booking.dateOfBirth, "YYYY-MM-DD").format('YYYY-MM-DD'),
      InterpreterRequired: this.convertBoolToNum(booking.interpreterRequired),
      FeeDeferralRequired: this.convertBoolToNum(booking.feeDeferralRequired),
      InterpreterDetails: booking.interpreterDetails,
      BookingNoteObject: booking.bookingNotes,
      BillingNoteObject: booking.billingNotes,
      SpecialistAvailabilityID: 0,
      OverPeriodofTime: this.convertNumToBool(booking.overPeriodofTime),
      AccidentDate: booking.accidentDate ? moment(booking.accidentDate, "YYYY-MM-DD").format('YYYY-MM-DD HH:mm:ss') : "",
      BodyAreaObject: bodyAreaObjects,
      PersonConditionObject: conditionObject,
      UserID: user.id,
      Username: user.name,
      CaseOwner: user.id
    }

    if (singleCase.id == undefined) {
      params.ClientZohoLink = client.zohoLink
      params.ClaimTypeName = booking.claimTypeName

      if (otherBodyAreaTags) {
        var otherBodyArea = ""
        otherBodyAreaTags.map((x) => {
          if (otherBodyArea == "") {
            otherBodyArea = x.text
          } else {
            otherBodyArea = otherBodyArea + "," + x.text
          }
        })
        params.BodyAreaOther = otherBodyArea
      }
    }
    var bookingId = 0
    var callback = (response) => {
      bookingId = response
    }
    var result = await this.postBooking(params, callback)

    if (result) {
      var params = {
        BookingID: bookingId,
        UserID: user.id,
        Username: user.name,
        BookingFileObject: [],
      }
      if(booking.bookingFiles.length > 0) {
        await Promise.all(booking.bookingFiles.map(async (x) => {
          if(bookingId != '') {
            var fileName = moment().unix() + '-' + x.FileName.replace(/\s/g, '-')
            var fileParams = {
              name: fileName,
              fileType: x.type,
              uploadtype: 'bf',
              id: bookingId,
            }
            var result = await this.postUploadImageURL(fileParams)
            var postData = new FormData();

            for(var key in result.fields){
                postData.append(key, result.fields[key]);
            }
            postData.append('file', x)
            await this.postImage(result.url, postData)

            params.BookingFileObject.push({
              BookingID: bookingId,
              FileName: fileName,
              FileTypeID: 2,
              UploadedBy: user.id,
              MimeType: x.MimeType,
              FileStatus: 0,
              UploadedByName: user.name,
              DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
            })
          }
        }))
      }

      if(booking.bookingFiles.length > 0) {
        var result = await this.putBookingFile(params)
      }


      /*await Promise.all(booking.bookingFiles.map(async (x) => {
        if(bookingId != 0) {
          var fileName = moment().unix() + '-' + x.FileName.replace(/\s/g, '-')
          var key = await fileUtils.uploadFile(x, x.MimeType, 'Booking/Booking_' + bookingId, fileName, 'private')
          params.BookingFileObject.push({
            BookingID: bookingId,
            FileName: fileName,
            FileTypeID: 2,
            FilePath: key.key,
            UploadedBy: user.id,
            MimeType: x.MimeType,
            FileStatus: 0,
            UploadedByName: user.name,
            DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
          })
        }
      }))
      console.log(params);
      await this.putBookingFile(params)*/

      if (booking.interpreterRequired) {
        var parem = {
          BookingID: response,
          DateRequested: moment().format('YYYY-MM-DD HH:mm:ss'),
          RequestedBy: user.id,
          BookingActionRequestStatus: 1,
          RequestedAction: "Interpreter Required",
          UserID: user.id,
          Username: user.name,
        }
        var callback = (response) => {}
        this.postBookingAction(parem, callback)
      }
    }
    // Clear create booking object
    localStorage.removeItem('storedBodyArea')
    localStorage.removeItem('storedCase')
    localStorage.removeItem('storedClient')
    localStorage.removeItem('storedOtherBodyArea')
    localStorage.removeItem('storedPersonCondition')
    localStorage.removeItem('storedSpecialist')
    localStorage.removeItem('storedUser')
    localStorage.removeItem('storedAddedBodyArea')
    localStorage.removeItem('storedAddedConditions')

    this.clearChangingObject()
    store.dispatch("booking/setBookingObject", null)
  },
  getContactFullName(contact) {
    var fullName = contact.firstName + (contact.middleName != '' ? ' ' + contact.middleName : '') + " " + contact.surname
    return fullName.trim()
  },
  getClientContactFullName(contact) {
    var fullName = contact.clientContactFirstName + (contact.clientContactMiddleName != '' ? ' ' + contact.clientContactMiddleName : '') + " " + contact.clientContactSurname
    return fullName.trim()
  },
  getContactFormattedString(contact) {
    return contact.fullName +
      ' M: ' +
      contact.mobile +
      ' E: ' +
      contact.emailAddress
  },
  getSpecialistFullName(specialist) {
    var fullName = specialist.title.trim() + (specialist.title.trim() != '' ? " " : "") + specialist.firstName.trim() + " " + specialist.lastName.trim()
    return fullName.trim()
  },
  getSpecialistUserName(specialist) {
    var userName = specialist.firstName.trim().toLowerCase() + specialist.lastName.trim().toLowerCase()
    userName = this.removeSpace(userName)
    return userName
  },
  getSpecialistContact(specialist) {
    return (specialist.phone1 != '' ? 'P: ' : '') + specialist.phone1 + (specialist.mobile != '' ? ', M: ' : '') + specialist.mobile + (specialist.phone2 != '' ? ', P: ' : '') + specialist.phone2 + (specialist.email != '' ? ', E: ' : '') + specialist.email
  },
  getClaimantFullName(claimant) {
    var fullName = claimant.claimantFirstName + (claimant.claimantGivenNames != '' ? ' ' + claimant.claimantGivenNames : '') + " " + claimant.claimantSurname
    return fullName.trim()
  },
  getShortLocation(location) {
    var shortLocation = this.addComma(location.name) + this.addComma(location.cityName) + location.state
    return shortLocation.trim()
  },
  getFullLocation(location) {
    var fullLocation = ""
    if(location.name != '') {
      var country = this.convertCountryCodeToName(location.country)
      fullLocation = this.addComma(location.name) + this.addComma(location.address1) + this.addComma(location.address2) + this.addComma(location.cityName) + this.addComma(location.state) + (country != '' ? this.addComma(location.postcode) : '') + country
    }
    return decodeURIComponent(fullLocation).trim()
  },
  getAccommodationFullLocation(accommodation) {
    var country = this.convertCountryCodeToName(accommodation.accommodationCountry)
    var fullLocation = this.addComma(accommodation.accommodationName) + this.addComma(accommodation.accommodationAddress1) + this.addComma(accommodation.accommodationAddress2) + this.addComma(accommodation.accommodationCity) + this.addComma(accommodation.accommodationState) + (country != '' ? this.addComma(accommodation.accommodationPostcode) : '') + country
    return fullLocation.trim()
  },
  getFullTravel(travel) {
    var country = this.convertCountryCodeToName(travel.country)
    var fullTravel = this.addComma(travel.locationName) + this.addComma(travel.address1) + this.addComma(travel.address2) + this.addComma(travel.cityName) + this.addComma(travel.state) + (country != '' ? this.addComma(travel.postcode) : '') + country
    return fullTravel.trim()
  },
  getFullEducation(education) {
    var fullEducation = education.education + (education.yearObtained != '' ? " in " : '') + education.yearObtained
    return fullEducation.trim()
  },
  getFullBodyAreas(booking) {
    var fullBodyAreas = ""
    booking.bodyAreas.map((x, xi) => {
      fullBodyAreas += x.bodyArea
      if(xi != booking.bodyAreas.length - 1) fullBodyAreas += ", "
    })
    return fullBodyAreas.trim()
  },
  getFullPersonConditions(booking) {
    var fullPersonConditions = ""
    booking.personConditions.map((x, xi) => {
      fullPersonConditions += x.name
      if(xi != booking.personConditions.length - 1) fullPersonConditions += ", "
    })
    return fullPersonConditions.trim()
  },
  getFullAddress(client) {
    var fullAddress = this.addComma(client.addressLine1) + this.addComma(client.addressLine2) + this.addComma(client.city) + (client.postcode != '' ? this.addComma(client.state) : client.state) + client.postcode
    return fullAddress.trim()
  },
  getFullPhoneEmail(phone, email) {
    var fullPhoneEmail = phone + (phone != '' && email != '' ? " / " : "") + email
    return fullPhoneEmail
  },
  capitalize(str) {
    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())
  },
  removeSpace(str) {
    return str.replace(/ /g, "")
  },
  addComma(text) {
    if(text !== '' && text !== undefined) {
      text = text.trim()
      text += ', '
      return text
    }
    return ''
  },
  validatePhone(phone) {
    if (phone != ''){
      return PHONE_REGEX.test(phone)
    }
    return true
  },

  validatePhoneAU(phone) {
    if (phone != '' ){
      return (AU_PHONE_REGEX.test(phone) || AU_PHONE_REGEX_2.test(phone))
    }
    return true
  },

  validateLandPhone(phone) {
    if (phone != '') {
      if (phone.length == 6 && phone.startsWith('13')) {
        return LAND_PHONE_REGEX.test(phone)
      } else if (phone.length == 12 && phone.startsWith('+')) {
        return LAND_PHONE_REGEX_WITHOUT_PLUS.test(phone.slice(1, phone.length))
      } else if (phone.length > 9) {
        return LAND_PHONE_REGEX.test(phone)
      }
      return false
    }
    return true
  },
  validateEmail(email) {
    return EMAIL_REGEX.test(email)
  },
  validateInput(str) {
    return COMMON_INPUT.test(str)
  },
  validateInputText(str) {
    return COMMON_TEXT.test(str)
  },
  validateNameText(str) {
    return COMMON_NO_SPECIAL_TEXT.test(str)
  },
  validateWebsite(website) {
    return WEBSITE_REGEX.test(website)
  },
  validateDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
    return moment(moment(date, currentFormat).format(format), format).isValid()
  },
  validateTime(time, currentFormat = "HH:mm:ss.ssssss") {
    return moment(moment().format("YYYY-MM-DD") + " " + time, "YYYY-MM-DD " + currentFormat).isValid()
  },
  formatDate(date, newFormat = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
    var newDate = ""
    if(date && date != "" && moment(date, currentFormat).isValid()) {
      newDate = moment(date, currentFormat).format(newFormat)
    }
    return newDate
  },
  convertDateToTimestamp(date, currentFormat = "YYYY-MM-DD HH:mm") {
    var timestamp = moment(date, currentFormat).format("x")
    return timestamp
  },
  convertTimestampToDate(timestamp, format = "YYYY-MM-DD HH:mm:ss") {
    var date = moment(timestamp, "x").format(format)
    return date
  },
  convertEventToTimestamp(eventDate) {
    var timestamp = new Date(eventDate.year, eventDate.month - 1, eventDate.day, eventDate.hour, eventDate.minute).getTime()
    return timestamp
  },
  convertTimeToHeight(start, end, currentFormat = "") {
    var startTime = currentFormat != "" ? moment(start, currentFormat) : moment(start)
    var endTime = currentFormat != "" ? moment(end, currentFormat) : moment(end)
    var duration = moment.duration(endTime.diff(startTime))
    var height = parseInt(duration.asMinutes()) * AN_HOUR_HEIGHT / 60

    return height
  },
  convertNullToEmpty(value) {
    var newValue = ""
    if((typeof value != 'string' && value != undefined)
      || (typeof value == 'string' && (value.toLowerCase() != 'null' && !value.includes("0000-")))) {
      newValue = value
    }
    if(typeof value == 'string') newValue = unescape(newValue)
    return newValue
  },
  convertZeroToEmpty(value) {
    return value == 0 ? "" : value
  },
  convertNumToBool(value) {
    return Number(value) === 1 ? true : false
  },
  convertBoolToNum(value) {
    return value === true ? 1 : 0
  },
  convertMinuteToHourText(value) {
    var hours = Math.floor(value / 60)
    var minutes = value % 60
    var text = ""
    if(hours > 0) {
      text = hours + (hours > 1 ? " hours " : " hour ") + (minutes > 0 ? minutes + " mins" : '')
    } else {
      text = minutes + " mins"
    }
    return text.trim()
  },
  convertCountryCodeToName(code) {
    var name = ""
    if(code.length == 2) {
      code = code.toUpperCase()
      if(code == "AU") name = "Australia"
    } else {
      name = this.capitalize(code)
    }
    return name.trim()
  },
  async convertFileToBase64(file) {
    var object = {
      base64: "",
      type: "",
    }
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.onload = (e) => {
        var result = e.currentTarget.result
        object.base64 = result.split(',')[1]
        object.type = result.split(';')[0].split('/')[1]
        object.file = {
          base64: result,
          type: file.type
        }
        resolve(object)
      }
      reader.onerror = (e) => {
        resolve(object)
      }
      reader.readAsDataURL(file)
    })
  },
  async resizeImage(object) {
    // var img = document.createElement("img")
    var img = new Image()
    img.src = object.base64
    return new Promise((resolve, reject) => {
      img.onload = () => {
        var canvas = document.createElement("canvas")
        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var MAX_WIDTH = 240
        var MAX_HEIGHT = 240
        var width = img.width
        var height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width > 0 ? width : MAX_WIDTH
        canvas.height = height > 0 ? height : MAX_HEIGHT
        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, width, height)

        var dataUrl = canvas.toDataURL(object.type)
        // document.getElementById('input').src = object.base64
        // document.getElementById('output').src = dataUrl
        resolve(dataUrl)
      }
    })
  },
  roundTime(time, down = true) {
    var roundTo = 15 // minutes
    var roundDownTime = roundTo * 60 * 1000
    return down
      ? time - time % roundDownTime
      : time + (roundDownTime - (time % roundDownTime))
  },
  roundNumber(value, number) {
    return Math.ceil(value / number) * number
  },
  getFileFromPath(path) {
    return URL_API_6 + path
  },
  getConsentFormUrl(path) {
    return CONSENT_URL + path
  },

  validatePhonenumber(number) {
    if (number != undefined && number.length > 0) {
      var value = number.replace(/-|_|\(|\)|\s|\./g,'');
      return value
    }
    return ""
  },

  updateMobileCode(mobile) {
    if (mobile != undefined && mobile.length > 0) {
      if (mobile.startsWith('02') || mobile.startsWith('09') || mobile.startsWith('9') || mobile.startsWith('2')) {
        if (mobile.startsWith('02')) {
          return '+64' + mobile.substring(1, mobile.length)
        } else {
          return '+64' + mobile
        }
      } else if (mobile.startsWith('+64')) {
        return mobile
      } else if (mobile.startsWith('04') || mobile.startsWith('4'))  {
        if (mobile.startsWith('04')) {
          return '+61' + mobile.substring(1, mobile.length)
        } else {
          return '+61' + mobile
        }
      } else if (mobile.startsWith('+61')) {
        return mobile
      }
    }
    return mobile
  },

  initSalutation() {
    return [{
      value: 'Mr'
    },{
      value: 'Mrs'
    }, {
      value: 'Ms'
    }, {
      value: 'Miss'
    }, {
      value: 'Dr'
    }, {
      value: 'Prof'
    }]
  },

  removeMobileCode(mobile) {
    if (mobile != undefined && mobile.length > 0) {
      if (mobile.startsWith('+61')) {
        return mobile.replace('+61', '0')
      } else if (mobile.startsWith('+64')) {
        return mobile.replace('+64', '0')
      }
    }
    return mobile
  },

  timeToBrisbane(date) {
    var date = moment(date, "YYYY-MM-DD HH:mm:ss").tz("Australia/Brisbane")
    return date.format("YYYY-MM-DD HH:mm:ss")
  },

  checkFileNamelength(name){
    var object = name.split('.')
    return object[0].substring(0, 240) + '.'+ object[object.length - 1]
  },

  validateSQlInjection(response) {
    if (Object.keys(response.data).length == 0 || response.data == "Error") {
      return true
    }
    return false
  },
    async getLocalIP() {
        return await new Promise(function (resolve, reject) {
            // NOTE: window.RTCPeerConnection is "not a constructor" in FF22/23
            var RTCPeerConnection = /*window.RTCPeerConnection ||*/ window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

            if (!RTCPeerConnection) {
                reject('Your browser does not support this API');
            }

            var rtc = new RTCPeerConnection({iceServers: []});
            var addrs = {};
            addrs["0.0.0.0"] = false;

            function grepSDP(sdp) {
                var hosts = [];
                var finalIP = '';
                sdp.split('\r\n').forEach(function (line) { // c.f. http://tools.ietf.org/html/rfc4566#page-39
                    if (~line.indexOf("a=candidate")) {     // http://tools.ietf.org/html/rfc4566#section-5.13
                        var parts = line.split(' '),        // http://tools.ietf.org/html/rfc5245#section-15.1
                            addr = parts[4],
                            type = parts[7];
                        if (type === 'host') {
                            finalIP = addr;
                        }
                    } else if (~line.indexOf("c=")) {       // http://tools.ietf.org/html/rfc4566#section-5.7
                        var parts = line.split(' '),
                            addr = parts[2];
                        finalIP = addr;
                    }
                });
                return finalIP;
            }

            if (1 || window.mozRTCPeerConnection) {      // FF [and now Chrome!] needs a channel/stream to proceed
                rtc.createDataChannel('', {reliable: false});
            }

            rtc.onicecandidate = function (evt) {
                // convert the candidate to SDP so we can run it through our general parser
                // see https://twitter.com/lancestout/status/525796175425720320 for details
                if (evt.candidate) {
                    var addr = grepSDP("a=" + evt.candidate.candidate);
                    resolve(addr);
                }
            };
            rtc.createOffer(function (offerDesc) {
                rtc.setLocalDescription(offerDesc);
            }, function (e) {
                console.warn("offer failed", e);
            });
        });
    },

  clearStore() {
    store.dispatch("location/setLocationData", null)
    store.dispatch("regions/setRegions", null)
    store.dispatch("locations/setLocations", null)
    store.dispatch("specialList/setSpecialist", null)
    store.dispatch("qualification/setQualification", null)
    store.dispatch("clientList/setClients", null)
  }
}
