/* eslint-disable */
<template>
  <div v-if="loadComponent" class="modal-mask">
    <div class="loading">
      <circle-spin></circle-spin>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'Loading',
  data() {
    return {

    }
  },
  computed: {
    ...mapState('loading', ['loadComponent'])
  },
  methods: {
    ...mapGetters('loading', ['getLoadComponent']),
    ...mapMutations('loading', ['setLoadComponent']),
    ...mapActions('loading', ['setLoadComponent']),
    showLoading() {
      this.setLoadComponent(true)
    },
    hideLoading() {
      this.setLoadComponent(false)
    }
  }
}
</script>
