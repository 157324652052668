<template>
  <div class="add-note-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>{{ note != '' ? 'Edit Note' : 'Add Note' }}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <input type="text" v-model="note" @keypress.enter.stop="save" class="textbox" placeholder="Enter a note" />
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn id="savebutton" dark @click="save">Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: 'AdminAddNoteModal',
  data() {
    return {
      loadModal: false,

      user: _.cloneDeep(this.$store.getters['user/getUser']),
      note: '',
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if (this.loadModal) {
        $('html').addClass('modal-mask-open')
      } else {
        $('html').removeClass('modal-mask-open')
      }
    },
    loadNote(note) {
      this.note = note
    },
    save() {
      this.$parent.loadNote(this.note)
      this.handleModal()
    },
  },
  mounted() {},
}
</script>
