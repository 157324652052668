<template>
  <div class="manage-specialist-name-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>Specialist Name</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="box">
                  <div class="box-body">
                    <div class="box-row">
                      <div class="box-col box-col-left">Title</div>
                      <div class="box-col box-col-right select-title">
                        <select class="w-100 textbox" v-model="specialist.title">
                          <option value="">Please choose ...</option>
                          <option v-for="(title, index) in titles" :value="title.name">{{ title.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">First Name</div>
                      <div class="box-col box-col-right">
                        <input type="text" :class="hasError && !validateFirstName ? 'is-invalid' : ''" v-model="specialist.firstName" placeholder="First name" />
                        <div v-if="hasError && !validateFirstName" class="text-danger">First Name is required.</div>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">Surname</div>
                      <div class="box-col box-col-right">
                        <input type="text" :class="hasError && !validateLastName ? 'is-invalid' : ''" v-model="specialist.lastName" placeholder="Surname" />
                        <div v-if="hasError && !validateLastName" class="text-danger">Surname is required.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert alert-success hidden-element">
                <a class="close" title="close">×</a>
                <b>Success:</b> {{ successMessage }}
              </div>
              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> {{ errorMessage }}
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="save">Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "ManageSpecialistNameModal",
  components: {},
  data() {
    return {
      loadModal: false,
      hasError: false,
      successMessage: "Name upatded successfully.",
      errorMessage: "Please correct the errors shown above and try again.",

      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      specialist: utilities.initSpecialist(),

      titles: utilities.getTitles(),
    }
  },
  computed: {
    validateFirstName() {
      if(this.specialist.firstName == "") {
        return false
      }
      return true
    },
    validateLastName() {
      if(this.specialist.lastName == "") {
        return false
      }
      return true
    },
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
    update() {
      this.$parent.specialist.title = this.specialist.title
      this.$parent.specialist.firstName = this.specialist.firstName
      this.$parent.specialist.lastName = this.specialist.lastName
      this.$parent.specialist.fullName = utilities.getSpecialistFullName(this.specialist)
      this.$parent.specialist.userName = utilities.getSpecialistUserName(this.specialist)
    },
    async save() {
      if (!this.validateFirstName || !this.validateLastName) {
        this.hasError = true
        utilities.showAlert(".manage-specialist-name-modal .alert-danger")
        return false
      } else {
        this.hasError = false
      }

      if(this.specialist.id != '') {
        this.$store.dispatch("loading/setLoadComponent", true)
        var result = await this.putName()
        if(result) {
          this.update()
          utilities.showAlert(".manage-specialist-name-modal .alert-success")
          setTimeout(() => {
            this.handleModal()
          }, 2000)
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      } else {
        this.update()
        this.handleModal()
      }
    },

    putName() {
      var zohoId = this.$parent.specialist.zohoLink.split("/");
      zohoId = zohoId[zohoId.length - 1];
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.specialist.id,
        Title: this.specialist.title,
        FirstName: this.specialist.firstName,
        Surname: this.specialist.lastName,
        ZohoLink: zohoId,
        ProfileUpdateOnly: 0
      }
      var callback = () => {}
      return utilities.putSpecialist(params, callback)
    },
  },
  beforeMount() {},
  mounted() {},
}
</script>
