/* eslint-disable */
<template>
  <div class="settings">
    <AdminNavigation ref="navigation" indexProps="6" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Reports</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
        <AdminReportsMenu />
      <div class="content content-settings d-flex flex-column align-items-center pt-5">
        <div class="row settings-box-row pt-5 mt-5">
          <router-link to="/admin-hours-booked">
          <div class="settings-box">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/related_skills_black_icon.svg"/>
              <div class="pt-5 mt-5">HOURS BOOKED</div>
            </div>
          </div>
          </router-link>
          <router-link to="/admin-hours-available">
          <div class="settings-box ml-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/related_services_black_icon.svg"/>
              <div class="pt-5 mt-5">HOURS AVAILABLE</div>
            </div>
          </div>
          </router-link>
          <router-link to="/admin-hours-booked-by-client">
          <div class="settings-box ml-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/related_services_black_icon.svg"/>
              <div class="pt-5 mt-5">HOURS BOOKED BY CLIENT</div>
            </div>
          </div>
          </router-link>
        </div>
        <div class="row settings-box-row">
          <router-link to="/admin-hours-booked-by-client-per-month">
          <div class="settings-box">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/locations_black_icon.svg"/>
              <div class="pt-5 mt-5">HOURS BOOKED BY CLIENT BY MONTH</div>
            </div>
          </div>
          </router-link>
          <router-link to="/admin-hours-booked-by-specialist">
          <div class="settings-box ml-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/finance_black_icon.svg"/>
              <div class="pt-5 mt-5">HOURS BOOKED BY SPECIALIST</div>
            </div>
          </div>
          </router-link>
          <router-link to="/admin-hours-booked-by-location">
          <div class="settings-box ml-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="@/assets/images/finance_black_icon.svg"/>
              <div class="pt-5 mt-5">HOURS BOOKED BY LOCATION</div>
            </div>
          </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import SpecialistMenu from '@/components/SpecialistMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import AdminReportsMenu from '@/components/AdminReportsMenu'

  export default {
    name: 'AdminReports',
    components: {
      AdminNavigation,
      SpecialistMenu,
      Header,
      AdminReportsMenu
    },
    data() {
      return {
        hasError: false,
      }
    },
    computed: {},
    methods: {},
    async beforeMount() {},
    mounted() {},
  }
</script>
