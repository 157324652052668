/* eslint-disable */
<template>
  <div class="admin-client-contacts">
    <AdminNavigation ref="navigation" indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Client Contacts ({{ totalCount }})</h1></div>
        <div class="header-sub-title">Click to view, edit the details of a client contact.</div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu :indexProps="3" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <img v-if="listParam.ClientName || listParam.BranchName || listParam.ContactFullName || listParam.ClientID || listParam.ClientContactID || Number.isInteger(listParam.IsActive)" class="mr-2" src="@/assets/images/close_icon.svg" @click="cancelSearch" title="Cancel Search"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Branch Name" v-model="listParam.BranchName"/>
              <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactFullName"/>
              <el-select v-model="listParam.IsActive" placeholder="Is Active" clearable @change="selectPage(1)">
                <el-option :value="1" label="Active">Active</el-option>
                <el-option :value="0" label="Inactive">Inactive</el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 6%" class="long-text">ID</th>
                    <th style="width: 12%" class="long-text">Full Name
                      <img v-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(1)" />
                      <img v-else-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(2)"   />
                    </th>
                    <th style="width: 17%" class="long-text">Client & Branch
                      <img v-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(3)" />
                      <img v-else-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(4)"   /></th>
                    <th style="width: 14%" class="long-text">Email</th>
                    <th style="width: 10%" class="long-text">Mobile</th>
                    <th style="width: 9%" class="long-text text-center">User Login?</th>
                    <th style="width: 10%" class="long-text text-center">Primary User?</th>
                    <th style="width: 7%" class="long-text">Role</th>
                    <th style="width: 7%" class="long-text">isActive</th>
                    <th style="width: 8%" class="long-text"></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="allContacts.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                  <tr v-for="(contact, index) in allContacts" :key="contact.clientContactId" class="is-icon">
                    <td style="width: 7%" class="long-text" @click="openContact(contact.clientId, contact.clientContactId)">{{ contact.clientContactId }}</td>
                    <td style="width: 12%" class="long-text" @click="openContact(contact.clientId, contact.clientContactId)">
                      <el-tooltip  effect="dark" :content="contact.fullName" placement="bottom">
                        <span>{{ contact.fullName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 17%" class="long-text" @click="openContact(contact.clientId,contact.clientContactId)">
                      <el-tooltip  effect="dark" :content="contact.clientName + ' ' + contact.branchName" placement="bottom">
                        <span>{{ contact.clientName + ' ' + contact.branchName}}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 14%" class="long-text" @click="openContact(contact.clientId,contact.clientContactId)">
                      <el-tooltip  effect="dark" :content="contact.emailAddress" placement="bottom">
                        <span>{{ contact.emailAddress }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 10%" class="long-text" @click="openContact(contact.clientId,contact.clientContactId)">
                      <el-tooltip  effect="dark" :content="contact.mobile" placement="bottom">
                        <span>{{ contact.mobile }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 9%" @click="openContact(contact.clientId,contact.clientContactId)">
                      <input disabled type="checkbox" :checked="contact.canLogin" :value="contact.canLogin" />
                    </td>
                    <td style="width: 10%" @click="openContact(contact.clientId,contact.clientContactId)">
                      <input disabled type="checkbox" :checked="contact.isPrimaryContact" :value="contact.isPrimaryContact" />
                    </td>
                    <td style="width: 7%" class="long-text" @click="openContact(contact.clientId,contact.clientContactId)">
                      {{ contact.userRoleName }}
                    </td>
                    <td style="width: 7%" class="long-text">
                      <input disabled type="checkbox" :checked="contact.active" :value="contact.active" />
                    </td>
                    <td style="width: 8%" class="long-text" >
                      <div class="icon-group">
                        <el-tooltip v-if="contact.zohoClientContactLink" effect="dark" content="Link to Zoho" placement="bottom">
                          <a v-if="contact.zohoClientContactLink" :href="contact.zohoClientContactLink" target="_blank">
                        <img class="me-2" src="@/assets/images/zoho_icon.svg"/></a>
                      </el-tooltip>
                      <EditZohoLinkDialog @updateSuccess="updateSuccess" :id="contact.clientContactId" :index="index" type="contact" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import { debounce } from 'lodash'
  import EditZohoLinkDialog from "@/components/dialog/EditZohoLinkDialog";
  export default {
    name: 'AdminClientContacts',
    props: ["searchOptionClientProps", "searchOptionContactProps"],
    components: {
      AdminNavigation,
      Header,
      AdminClientsMenu,
      EditZohoLinkDialog
    },
    data() {
      return {
        fetchedData: false,
        totalCount: 0,
        contacts: [],
        allContacts: [],
        limit: 20,
        pagination: utilities.initPagination(),
        contactsNumber: 0,
        searchTerm: '',
        clientId: '',
        listParam: {
          PageNum: 1,
          PageSize: 10,
          SortFlag: 0,
          ClientID: '',
          ClientContactID: '',
          IsActive: null,
          ClientName: '',
          BranchName: '',
          ContactFullName: ''
        },
        clientMenuIndex: 0,
      }
    },
    methods: {
      search: debounce(function () {
        this.selectPage(1)
      }, 800),
      cancelSearch() {
        this.useSearch = false
        this.listParam.ClientContactID = ""
        this.listParam.ClientID = ""
        this.listParam.ContactFullName = ""
        this.listParam.ClientName = ""
        this.listParam.BranchName = ""
        this.listParam.IsActive = null
        window.history.replaceState({}, document.title, location.pathname)
        this.selectPage(1)
      },
      updateSuccess(index, newLink) {
        let obj = _.cloneDeep(this.allContacts[index])
        obj.zohoClientContactLink = newLink
        this.$set(this.allContacts, index, obj)
      },
      sortParam(v) {
        this.listParam.SortFlag = v
        this.selectPage(1)
      },
      selectPage(index) {
        this.listParam.PageNum = index
        this.loadData()
      },
      openContact(id, contactId) {
        // let routeData = this.$router.resolve({ name: 'admin-edit-client-contact', query: { clientId: id, contactId: contactId } })
        this.$router.push({ name: 'admin-edit-client-contact', query: { id: contactId } })
        // window.open(routeData.href, '_blank')
      },
      async searchClientContact(searchOption) {
        window.history.replaceState({}, document.title, location.pathname)
        this.listParam.ContactFullName = searchOption.name
        this.listParam.ClientName = searchOption.ClientName
        this.listParam.BranchName = searchOption.BranchName
        this.listParam.IsActive = searchOption.IsActive
        this.selectPage(1)
      },

      async loadData() {
        this.$store.dispatch('loading/setLoadComponent', true)
        let param = {}
        for (let i in this.listParam) {
          if (this.listParam[i] !== undefined && this.listParam[i] !== null && this.listParam[i] !== "") param[i] = this.listParam[i]
        }
        const {contacts, total} = await utilities.getClientContactsNew(param)
        this.allContacts = contacts
        this.totalCount = total
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    async beforeMount() {
      if(this.searchOptionContactProps) {
        this.listParam.BranchName = this.searchOptionContactProps.BranchName
        this.listParam.ClientName = this.searchOptionContactProps.ClientName
        this.listParam.ContactFullName = this.searchOptionContactProps.name
        this.listParam.IsActive = this.searchOptionContactProps.IsActive
        await this.loadData()
      } else {
        this.listParam.ClientContactID = this.$route.query.id !== undefined ? this.$route.query.id : ''
        this.listParam.ClientID = this.$route.query.clientId !== undefined ? this.$route.query.clientId : ''
        await this.loadData()
      }
      this.fetchedData = true
    }
  }
</script>
