<template>
  <div class="additional-booking-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <button
              type="button"
              class="close-icon"
              @click="handleModal($event)"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body div-responsive red-border mt-2 mb-5" style="overflow:hidden">
            <div class="row">
              <div class="col-12">
                <p>{{relatedServices[0].wording}}</p>
                <p class="mt-3">
                  <p>Would you also like to book a {{relatedServices[0].offerQualificationName}}  for a {{relatedServices[0].offerBookingTypeName}}?  </p>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn  @click="backToSeach()" dark>Yes</v-btn>
                   <v-btn @click="handleModal($event);$router.push({path:'/'})" dark>No</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdditionalBookingModal',
    props: ['clientProps'],
    data() {
      return {
        loadModal: false,
        relatedServices: this.$parent.relatedServices,
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      backToSeach() {
        if (this.relatedServices.length > 0) {
          this.handleModal()
          this.$router.push({path: '/search-specialist', query:{specialty:this.relatedServices[0].offerQualificationId, bookingTypeId: this.relatedServices[0].offerBookingTypeId, bookingId: this.$parent.newBookingId}})
          //this.$router.push({path:'/search-specialist', query:{specialty:this.relatedServices[0].offerQualificationId, bookingTypeId: this.relatedServices[0].offerBookingTypeId, bookingId: this.$parent.newBookingId}}).catch((err) => {})
        } else {
          this.handleModal()
        }
      },


    },
    beforeMount() {},
    mounted() {},
    updated() {},
  }
</script>
