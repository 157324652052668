/* eslint-disable */
<template>
  <div class="manage-booking">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Purchase Order - Booking {{ booking.id }}</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminBookingsMenu ref="navigation" indexProps="0" />
      <div class="content">
        <div class="box case-container remove-min-height">
          <div
            class="box-heading tab d-flex align-items-center is-icon"
            @click="toggleBoxDisplay()"
          >
            <img
              src="@/assets/images/case_dark_icon.svg"
              class="ml-2"
              height="50px"
            />
            <span>Claimant: {{ booking.claimantFullName }}</span>
            <span class="ml-4">Client: {{ booking.clientName }}</span>
            <span class="ml-4"
              >{{ getCaseOwnerName() }}&nbsp&nbspP:
              {{ getCaseOwnerPhone() }}&nbsp&nbspE:
              {{ getCaseOwnerEmail() }}</span
            >
            <img
              src="@/assets/images/view_booking.svg"
              class="right-element"
              @click.stop="goToCase(booking.caseId)"
            />
          </div>
          <div class="box-body tab-content hidden-element">
            <div class="box-row">
              <div class="box-col box-col-left">Status</div>
              <div class="box-col box-col-right">
                <span class="ml-3">{{
                  singleCase.caseStatus == 0 ? "Inactive" : "Active"
                }}</span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Case Details</div>
              <div class="box-col box-col-right ml-3">
                D.O.B: {{ formatDate(booking.dateOfBirth)
                }}<span class="ml-3">Type: {{ booking.claimTypeName }}</span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Claimant Details
              </div>
              <div class="box-col box-col-right ml-3">
                Ph: {{ booking.claimantPhone1 }}
                <span class="ml-3">M: <u class="is-icon" @click="openEmailModal('claimant', 1)">{{ booking.claimantMobile }}</u></span>
                <span class="ml-3">Ph: {{ booking.claimantPhone2 }}</span>
                <span class="ml-3">E: <u class="is-icon" @click="openEmailModal('claimant', 0)">{{ booking.claimantEmail }}</u></span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Claimant Address
              </div>
              <div class="box-col box-col-right ml-3">{{ formattedAddress }}</div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Date of Accident
              </div>
              <div class="box-col box-col-right ml-3">
                {{
                  booking.overPeriodofTime == false
                    ? formatDate(booking.accidentDate)
                    : "Over a period of time"
                }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Affected Body Areas
              </div>
              <div class="box-col box-col-right">
                <div class="tag-div">
                  <vue-tags-input
                    :disabled="true"
                    v-model="bodyAreasTag"
                    :tags="bodyAreasTags"
                    :allow-edit-tags="false"
                    placeholder=""
                    :autocomplete-items="bodyAreasAutocomplete"
                    @tags-changed="(newTags) => (bodyAreasTags = newTags)"
                  />
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Other Body Areas
              </div>
              <div class="box-col box-col-right">
                <div class="tag-div">
                  <vue-tags-input
                    :disabled="true"
                    v-model="otherBodyAreaTag"
                    :tags="otherBodyAreaTags"
                    placeholder=""
                    @tags-changed="(newTags) => (otherBodyAreaTags = newTags)"
                  />
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Conditions</div>
              <div class="box-col box-col-right">
                <div class="tag-div" :class="{ focused: focused }">
                  <vue-tags-input
                    :disabled="true"
                    v-model="conditionTag"
                    :tags="conditionTags"
                    :allow-edit-tags="false"
                    placeholder=""
                    :autocomplete-items="conditionsAutocomplete"
                    @tags-changed="(newTags) => (conditionTags = newTags)"
                  />
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Client</div>
              <div class="box-col box-col-right">
                <span class="ml-2">Client: {{ booking.clientName }}</span>
                <span class="ml-4">{{ getCaseOwnerName() }}</span>
                <span class="ml-4">P: <u class="is-icon" @click="openEmailModal('client', 1)">{{ getCaseOwnerPhone() }}</u></span>
                <span class="ml-4">E: <u class="is-icon" @click="openEmailModal('client', 0)">{{ getCaseOwnerEmail() }}</u></span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">On Behalf Of</div>
              <div class="box-col box-col-right">
                <input :disabled="true"
                  type="text"
                  class="px-3"
                  v-model="booking.onBehalfOf"
                  placeholder="Enter on behalf of"
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Send Report To</div>
              <div class="box-col box-col-right">
                <span class="ml-3">{{booking.reportTo}}</span>
                <!-- <select :disabled="true" class="w-75" v-model="booking.reportTo">
                  <option value="" hidden>Please choose ...</option>
                  <option
                    :value="contact.clientContactId"
                    v-for="contact in clientContacts"
                  >
                    {{ contact.fullName }}
                  </option>
                  <option value="-1">Other</option>
                </select> -->
              </div>
            </div>
            <div class="box-row" v-if="booking.reportTo == -1">
              <div  class="box-col box-col-left">Email Address</div>
              <div class="box-col box-col-right">
                <span class="ml-3">{{booking.reportToEmailAddress}}</span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Bill To</div>
              <div class="box-col box-col-right">
                <span class="ml-3">{{booking.billTo}}</span>
                <!-- <select :disabled="true" class="w-75 px-3" v-model="booking.billTo">
                  <option value="" hidden>Please choose ...</option>
                  <option
                    :value="contact.clientContactId"
                    v-for="contact in clientContacts"
                  >
                    {{ contact.fullName }}
                  </option>
                </select> -->
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-heading d-flex align-items-center">
            Booking Details
            <div class="right-element">
              <a :href="booking.zohoLink" target="blank"><img src="@/assets/images/zoho_circle.svg" class="mr-2 is-icon" /></a>
              <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.id } }"> <img src="@/assets/images/view_booking.svg" /></router-link>
            </div>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left left-label">Booking Type</div>
              <div class="box-col box-col-right">
                {{ bookingTypeName }}
                <span class="right-element">{{
                  booking.length > 0
                    ? bookingStatuses.find((x) => x.id == booking.bookingStatus)
                        .name
                    : ""
                }}</span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Specialist</div>
              <div class="box-col box-col-right">
                {{ getSpecialistFullName }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Date &amp Time</div>
              <div class="box-col box-col-right">
                {{ formatDateTime(booking.bookingDateTime) }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Location</div>
              <div class="box-col box-col-right">
                {{ fullLocation }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Booking Status</div>
              <div class="box-col box-col-right">
                {{ getBookingStatus }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Type of Claim</div>
              <div class="box-col box-col-right">
                {{ booking.claimTypeName }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Claimant Attendance Status
              </div>
              <div class="box-col box-col-right">
                {{
                  booking.claimantConfirmed == ""
                    ? "Not confirmed"
                    : "Confirmed"
                }}
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">OTFCE Required?</div>
              <div class="box-col box-col-right d-flex align-items-center">
                <input
                  type="checkbox"
                  disabled
                  v-model="booking.otfceRequired"
                />
                <!-- <img
                      class="ml-4"
                      src="@/assets/images/otfce_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="OTFCE required"
                      @click="toggleOTFCE()"
                      :class="booking.otfceRequired == true ? '' : 'disabled-element'"
                    /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>

        <div class="box remove-min-height">
          <div class="box-heading d-flex align-items-center">
            Tests Ordered
            <!-- <div class="right-element">
              <v-btn class="add-icon" dark @click="openTestsModal">Add New</v-btn>
              <AddSpecialistBookingTestModal ref="addSpecialistBookingTestModal" :bookingProps="booking" :testsProps="test" />
            </div> -->
          </div>

          <div
            v-if="booking.bookingTests.length > 0"
            v-for="(bookingTest, index) in booking.bookingTests"
          >
            <div class="box-body">
              <div class="box-row">
                <div class="box-col col">{{ bookingTest.testName }}</div>
                <div class="box-col col">{{ bookingTest.orderedFrom }}</div>
                <div class="box-col col">
                  <div class="left-label" v-if="bookingTest.dateOrdered != ''">
                    Ordered: {{ bookingTest.dateOrdered }}
                  </div>
                </div>
                <div class="box-col col">
                  <div class="left-label" v-if="bookingTest.dateReceived != ''">
                    Received: {{ bookingTest.dateReceived }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="box-row"></div>
        </div>

        <div class="box">
          <div class="box-heading tab">
            <div class="tab-heading tab-selected px-5" id="heading-notes" @click="openNotes">Notes</div>
            <!-- <div class="tab-heading px-5" id="heading-files" @click="openFiles">Files</div> -->
            <!-- <div class="right-element">
              <v-btn class="add-icon" dark @click="openAddNoteModal">Add New</v-btn>
              <AddNoteModal ref="addNoteModal" :bookingProps="booking" />
            </div> -->
          </div>
          <div class="box-body notes-content">
            <div class="box-row overflow-hidden" v-for="note in booking.bookingNotes">
              <div class="box-col box-col-left">{{ note.userName }}</div>
              <div class="box-col box-col-left">
                {{ formatDateTime(note.noteDateTime, "YYYY-MM-DD HH:mm:ss") }}
              </div>
              <div class="box-col box-col-right" style="max-width: 60%">
                {{ note.note }}
              </div>
              <!-- <div class="is-icon" style="width:5%">
                <img src="@/assets/images/delete_icon.svg" @click="deleteNote(note.id)" />
              </div> -->
            </div>
            <div class="box-row" v-for="note in bookingAction">
              <!-- <div class="box-col box-col-left">{{ note.userName }}</div> -->
              <div class="box-col box-col-left">&nbsp</div>
              <div class="box-col box-col-left">
                {{ formatDateTime(note.dateRequested) }}
              </div>
              <div class="box-col box-col-right" style="max-width: 60%">
                <strong>Requested Action: </strong>{{ note.requestedAction }}
              </div>
              <!-- <div class="is-icon" style="width:5%">
                <img src="@/assets/images/delete_icon.svg" @click="deleteNote(note.id)" />
              </div> -->
            </div>
          </div>

          <!-- <div class="box-body files-content hidden-element"></div> -->

          <div class="alert alert-warning confirmation hidden-element">
            <div class="icon-group center-element">
              <v-btn class="alert-button" @click="tapButtonConfirmation(true)"
                >Yes</v-btn
              >
              <v-btn class="alert-button" @click="tapButtonConfirmation(false)"
                >No</v-btn
              >
            </div>
            <a class="close" title="close" @click="tapButtonConfirmation(false)"
              >×</a
            >
            <b>Warning:</b> Are you sure? Your changes will take effect
            immediately.
          </div>
        </div>

        <div class="alert alert-success alert-success-down hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>

        <div class="box-body">
          <div class="box-heading d-flex align-items-center">
            Purchase Order Lines
          </div>
          <div class="content-scroll-x">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 15%" class="long-text">Date</th>
                      <th style="width: 10%" class="long-text">Item Code</th>
                      <th style="width: 50%" class="long-text">Description</th>
                      <th style="width: 10%" class="long-text text-right">
                        Fee
                      </th>
                      <th style="width: 15%" class="text-right">
                        <v-btn  v-if="!hideButton"
                          class="add-icon"
                          dark
                          @click="openPurchaseOrderItemModal()">Add New</v-btn>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <!-- <div :class="specialists.length > 5 ? 'content-scroll-y' : ''"> -->
              <div>
                <table class="table">
                  <tbody>
                    <tr v-for="(purchaseOrderItem, index) in purchaseOrderItems">
                      <td style="width: 15%" class="long-text">
                        {{ formatDate(purchaseOrderItem.dateAdded) }}
                      </td>
                      <td style="width: 10%" class="long-text">
                        {{ purchaseOrderItem.itemCode }}
                      </td>
                      <td
                        style="width: 50%"
                        class="long-text">
                        {{ purchaseOrderItem.description }}
                      </td>
                      <td style="width: 10%" class="long-text text-right">
                        ${{ formatPrice(purchaseOrderItem.amount) }}
                      </td>
                      <td style="width: 15% vertical-align: middle" class="icon-col">
                        <div class="icon-group d-flex justify-content-end">
                          <div><img v-if="!hideButton" src="@/assets/images/delete_icon.svg" @click="deletePurchaseOrderItem(purchaseOrderItem.id, index)"/></div>
                          <div><img v-if="!hideButton" class="ml-3" src="@/assets/images/edit_icon.svg" @click="openPurchaseOrderItemModal(purchaseOrderItem)"/></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp</td>
                      <td><span class="left-label">Total</span></td>
                      <td class="text-right">
                        <span class="left-label"></span>
                      </td>
                      <td class="text-right">
                        <span class="left-label">${{ purchaseOrderTotal }}</span>
                      </td>
                    </tr>
                    <AdminPurchaseOrderItemModal
                      ref="purchaseOrderItemModal"
                      :itemProps="selectedItem" />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="right-element mt-5">
          <v-btn v-if="!hideButton" class="mr-5" dark @click="updatePurchaseOrder()">Update</v-btn>
          <v-btn class="mr-5" v-if="!hideButton" @click="sendToXero()" dark>Send To Xero</v-btn>
          <v-btn v-if="hideButton" :class="purchaseOrder.paid ? 'disabled-element' : ''" @click="tapPaid()" dark>Paid</v-btn>
        </div>
      </div>
    </div>
    <AdminManageContactDetailsModal ref="adminManageContactModal" />
    <AdminSendEmailModal :sendToProps="sendToProps" :emailProps="emails" :nameProps="names" :phoneProps="phones" :emailSmsProps="emailOrSms" ref="adminSendEmailModal" />
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
// import BookingMenu from "@/components/BookingMenu"
import AdminBookingsMenu from '@/components/AdminBookingsMenu'
import Header from "@/components/Header"
import AdminManageContactDetailsModal from "@/components/AdminManageContactDetailsModal"
import AdminSendEmailModal from "@/components/AdminSendEmailModal"
import AdminPurchaseOrderItemModal from "@/components/AdminPurchaseOrderItemModal"

export default {
  name: "AdminManagePurchaseOrder",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
    AdminManageContactDetailsModal,
    AdminSendEmailModal,
    AdminPurchaseOrderItemModal,
  },
  data() {
    return {
      bodyAreasTag: "",
      conditionTag: "",

      focused: "",
      interpreterRequired: false,
      surrogateRequired: false,
      hideButton: false,

      otherBodyAreaTags: [],
      otherBodyAreaTag: "",

      test: -1,
      names: [],
      emails: {},
      phones: {},
      emailOrSms: 0,


      preApprovedHours: [
        {
          hours: "",
          hoursFor: "",
        },
      ],
      requestedExtraHours: [
        {
          hours: 1,
          hoursFor: 1,
        },
      ],

      specialist: [],
      bodyAreasTags: [],
      conditionTags: [],
      filelist: [],
      locations: [],
      bodyAreasAutocomplete: [],
      conditionsAutocomplete: [],
      clientContacts: [],
      standardFiles: [],

      user: _.cloneDeep(this.$store.getters["user/getUser"]),

      bookingStatuses: utilities.getBookingStatuses(),
      bookingTypes: utilities.initBookingTypes(),
      claimTypes: [],
      booking: utilities.initBooking(),

      deleteId: "",
      deleteIndex: "",
      roleProps: "admin",

      sendToProps: "",

      successMessage: "",
      errorMessage: "",
      email: {},
      bookingAction: [],
      purchaseOrder: {},
      purchaseOrderItems: [],
      selectedItem: {},
      singleCase: {},
    }
  },
  computed: {
    filteredBodyAreas() {
      return this.bodyAreasAutocomplete.filter((i) => {
        return i.text.toLowerCase().indexOf(this.bodyArea.toLowerCase()) !== -1
      })
    },
    filteredConditions() {
      return this.conditionsAutocomplete.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.condition.toLowerCase()) !== -1
        )
      })
    },
    formattedAddress() {
      var address = ''
      if (this.booking.claimantAddressLine1 != '') address += this.booking.claimantAddressLine1 + ', '
      if (this.booking.claimantAddressLine2 != '') address += this.booking.claimantAddressLine2 + ', '
      if (this.booking.claimantCity != '') address += this.booking.claimantCity + ', '
      if (this.booking.claimantState != '') address += this.booking.claimantState + ' '
      if (this.booking.claimantPostcode != '') address += this.booking.claimantPostcode
      return address
    },
    preapprovedHours() {
      return this.booking.bookingHours.filter((x) => x.role === "RHAdmin")
    },
    additionalHours() {
      return this.booking.bookingHours.filter(
        (x) => x.role === "Specialist" || x.role === "Client"
      )
    },
    interpreterDetails: {
      get: function () {
        return this.booking.interpreterDetails
      },
      set: function (newValue) {
        if (newValue.trim() != "") this.booking.interpreterRequired = true
        else this.booking.interpreterRequired = false
        this.booking.interpreterDetails = newValue
      },
    },
    surrogateDetails: {
      get: function () {
        return this.booking.surrogateDetails
      },
      set: function (newValue) {
        if (newValue.trim() != "") this.booking.surrogateRequired = true
        else this.booking.surrogateRequired = false
        this.booking.surrogateDetails = newValue
      },
    },
    fullLocation() {
      var location = this.locations.find(
        (x) => x.id === this.booking.locationId
      )
      return location == undefined ? "" : location.fullLocation
    },

    getBookingStatus() {
      var status = this.bookingStatuses.find((x) => x.id === this.booking.bookingStatus)
      return status != undefined ? status.name : ""
    },
    bookingTypeName() {
      var type = this.bookingTypes.find(
        (x) => x.id === this.booking.bookingTypeId
      )
      return type == undefined ? "" : type.name
    },
    purchaseOrderTotal() {
      var total = 0
      this.purchaseOrderItems.map((x) => (total += x.amount))
      return Number(total).toFixed(2)
    },
    getSpecialistFullName() {
      return this.specialist.fullName
    },
  },
  methods: {
    // List push
    pushToPreApprovedHours() {
      this.preApprovedHours.push({ hours: "", hoursFor: "" })
    },

    async tapPaid() {
      if (this.purchaseOrder.paid) {
        return
      }
      this.$store.dispatch("loading/setLoadComponent", true)
      var params = {
        ApprovedBy: this.purchaseOrder.approvedBy,
        ApprovedDate: this.purchaseOrder.approvedDate,
        ApprovedTotal: this.purchaseOrder.approvedTotal,
        BookingID: this.purchaseOrder.bookingId,
        BookingPurchaseOrderID: this.purchaseOrder.id,
        BookingPurchaseOrderStatus: this.purchaseOrder.status,
        InvoiceNumber: this.purchaseOrder.invoiceNumber,
        Paid: 1,
        UserID: this.user.id,
        Username: this.user.name,
      }

      var callback = (response) => {
        this.postBookingPOLog(this.purchaseOrder.id)
        this.purchaseOrder.paid = true
        this.successMessage = "The Purchase Order has been paid successfully."
        utilities.showAlert(".alert-success-down")
      }
      await utilities.putPurchaseOrder(params, callback)
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    updatePurchaseOrder() {

    },

    setDescription(items) {
      items.find((x) => {
        var newDescription = ""
        if (x.description.includes("Report Finalised Fee")) {
          var newDescription = this.booking.bookingTypeName + " for " + this.booking.claimantFullName + " by "
          if (this.specialist.qualifications.length > 0) {
            newDescription +=  this.specialist.qualifications[0].qualificationName + " "
          }
           newDescription += this.specialist.fullName + " on the " + moment(this.booking.bookingDateTime).format("DD/MM/YYYY")
           x.description = newDescription
        }
      })


    },

    async sendToXero() {
      this.$store.dispatch("loading/setLoadComponent", true)
      if (this.purchaseOrderItems.length > 0) {
        var items = []
        var description = ""
        if (this.booking.billTo) {
          //var client = this.clientContacts.find((x) => x.clientContactId == this.booking.billTo) //Now we are passing bill to with client name.
          description = "BillTo: " + this.booking.billTo   //client.fullName + " - " + client.emailAddress+";"
        }

        if (this.booking.onBehalfOf.length > 0) {
          description = description + " On Behalf Of " + this.booking.onBehalfOf+";"
        }

        if (description.length > 0) {
          description = description + " CaseReference: " + this.booking.caseReference
        } else {
          description = "CaseReference: " + this.booking.caseReference
        }

        var firstItem = {'Description': description}
        items.push(firstItem)
        var bookingPurchaseOrderId = 0
        var trackings = []
        var tracking1 = {
          Name: 'Job',
          Option: this.specialist.xeroCode,
        }
        trackings.push(tracking1)
        var tracking2 = {
          Name: 'Location',
          Option: this.booking.xeroCode,
        }
        trackings.push(tracking2)

        this.purchaseOrderItems.map((x) => {
          bookingPurchaseOrderId = x.bookingPurchaseOrderId
          var item = {
            Description: x.description,
            UnitAmount: x.amount,
            ClaimantName: this.singleCase.claimantFullName,
            Specialty: this.specialist.qualifications.length > 0 ? this.specialist.qualifications[0].qualificationName : '',
            SpecialistName: this.booking.specialistFullName,
            ItemDate: x.dateAdded,
            ItemCode: x.itemCode == 'Failed' ? 'WC100805' : x.itemCode,
            Tracking: trackings
          }
          items.push(item)
        })

        var params = {
          BookingID: this.booking.id,
          ClientID: this.booking.clientId,
          BookingPOID: bookingPurchaseOrderId,
          UserID: this.user.id,
          Username: this.user.name,
          LineItems: items,
          Contact: {ContactID: this.booking.xeroClientCode},
          CurrentDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        }

        var status =  await utilities.postPOToXero(params)
        if (status) {
          this.postBookingPOLog(bookingPurchaseOrderId, true, status)
          this.successMessage = "Pushed into Xero Successfully!"
          utilities.showAlert(".alert-success-down")
          this.hideButton = true
        }
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    async postBookingPOLog(poId, isZero = false, invoiceNumber='') {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        LogDate: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
      }

      if (isZero) {
        params.LogDetails = 'Invoice ' + invoiceNumber +' has been sent to Xero.'
      } else {
        params.LogDetails = 'Booking Purchase Order ID - ' + poId + ' has been marked as Paid'
      }
      // await utilities.postBookingLog(params)
    },

    toggleBoxDisplay() {
      if ($(".case-container>.box-body").is(":visible")) {
        $(".case-container>.box-body").fadeOut()
      } else {
        $(".case-container>.box-body").fadeIn()
      }
    },
    openNotes() {
      $(".notes-content").show()
      $(".files-content").hide()
      $(".tab-selected").removeClass("tab-selected")
      $("#heading-notes").addClass("tab-selected")
    },
    // openFiles() {
    //   $('.notes-content').hide()
    //   $('.files-content').show()
    //   $('.tab-selected').removeClass('tab-selected')
    //   $('#heading-files').addClass('tab-selected')
    // },
    openAddNoteModal() {
      this.$refs.addNoteModal.handleModal()
    },

    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains('bg-green-300')) {
      //   event.currentTarget.classList.remove('bg-gray-100')
      //   event.currentTarget.classList.add('bg-green-300')
      // }
      event.currentTarget.classList.add("dark-border-hover")
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100')
      // event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove("dark-border-hover")
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100")
      event.currentTarget.classList.remove("bg-green-300")
      event.currentTarget.classList.remove("dark-border-hover")
    },
    openManageContactModal(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs.adminManageContactModal.singleCase = this.singleCase
      this.$refs.adminManageContactModal.booking = this.booking
      this.$refs.adminManageContactModal.handleModal()
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY")
      }
      return ""
    },
    formatDateTime(dateTime, format = "") {
      return moment(dateTime, format).format("hh:mm A DD/MM/YYYY")
    },
    formatPrice(amount) {
      return Number(amount).toFixed(2)
    },
    toggleCallAfterAppointment() {
      this.booking.callClientAfterAppointment =
        !this.booking.callClientAfterAppointment
    },
    toggleCanRingClaimant() {
      this.booking.redHealthMayContactClaimant =
        !this.booking.redHealthMayContactClaimant
    },
    toggleAttendance() {
      this.booking.claimantConfirmed = !this.booking.claimantConfirmed
    },
    toggleConsentReceived() {
      this.booking.claimantConsentReceived =
        !this.booking.claimantConsentReceived
    },
    toggleOTFCE() {
      this.booking.otfceRequired = !this.booking.otfceRequired
    },
    toggleVideoLinkTestArranged() {
      this.booking.videoLinkTestStatus = !this.booking.videoLinkTestStatus
    },
    toggleHasVideo() {
      this.booking.hasVideoLink = !this.booking.hasVideoLink
    },
    togglePaymentDeferralOption() {
      this.booking.paymentDeferralOption = !this.booking.paymentDeferralOption
    },
    togglePrepaymentRequired() {
      this.booking.prepaymentRequired = !this.booking.prepaymentRequired
    },
    toggleSpecialistConfirmedHours() {
      this.booking.specialistConfirmedHours =
        !this.booking.specialistConfirmedHours
    },
    openServiceTimeModalPreApproved() {
      this.$refs.addSpecialistServiceTimeModal.handleModal()
    },
    openServiceTimeModal() {
      this.roleProps = "adminAsSpecialist"
      this.$refs.addSpecialistServiceTimeModal.handleModal()
    },
    async deleteNote(id, index) {
      utilities.showAlert(".confirmation", false)
      this.deleteId = id
      this.deleteIndex = index
    },
    async tapButtonConfirmation(states) {
      utilities.hideAlert(".confirmation")
      if (states) {
        this.$store.dispatch("loading/setLoadComponent", true)
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          id: this.deleteId,
          IsMainEdit: 0
        }
        if (params.IsMainEdit === 0) {
          params.BookingDateTime = this.booking.bookingDateTime
          params.SpecialistName = this.booking.specialistFullName
          params.CaseOwnerName = this.getCaseOwnerName()
          params.ClientName = this.booking.clientName + ' - ' + this.booking.branchName
          params.BookingTypeName = this.booking.bookingTypeName
          params.LocationName = this.getLocationName(this.booking.locationId)
          params.IsAdmin = true
          params.BookingID = this.booking.id
          params.NoteDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
        }
        var result = await utilities.deleteBookingNoteById(params)
        if (result) {
          var temp = _.cloneDeep(this.booking.bookingNotes)
          this.booking.bookingNotes = temp
            .splice(0, this.deleteIndex)
            .concat(temp.splice(this.deleteIndex + 1, temp.length - 1))
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      }
    },

    getLocationName(id) {
      if (id) {
        var value = this.locations.find((x) => x.id == id)
        if (value) {
          return value.shortLocation
        }
      }
    },

    openTestsModal(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs.addSpecialistBookingTestModal.handleModal()
    },

    setTestAndOpenModal(e, test) {
      e.preventDefault()
      e.stopPropagation()
      this.test = test
      this.$refs.addSpecialistBookingTestModal.handleModal()
    },

    checkInterpreterRequired() {
      if (this.booking.interpreterDetails.trim() != "")
        this.booking.interpreterRequired = true
      else this.booking.interpreterRequired = false
    },
    checkSurrogateRequired() {
      if (this.booking.surrogateDetails.trim() != "")
        this.booking.surrogateRequired = true
      else this.booking.surrogateRequired = false
    },

    async updateBooking() {
      // Validate
      // Create request body
      var params = {
        BillTo: this.booking.billTo,
        CaseID: this.booking.caseId,
        Private: this.booking.private,
        ClientID: this.booking.clientId,
        ReportTo: this.booking.reportTo,
        BookingID: this.booking.id,
        LocationID: this.booking.locationId,
        OnBehalfOf: this.booking.onBehalfOf,
        AvailabilityID: this.booking.specialistAvailabilityId,
        ClaimTypeID: this.booking.claimTypeId,
        DateofBirth: moment(this.booking.dateOfBirth).format("YYYY-MM-DD"),
        PaperworkDue: moment(this.booking.paperworkDue).format("YYYY-MM-DD"),
        SpecialistID: this.booking.specialistId,
        BookingStatus: this.booking.bookingStatus,
        BookingTypeID: this.booking.bookingTypeId,
        BookingTypeName: this.booking.bookingTypeName,
        CurrentDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        OTFCERequired: this.booking.otfceRequired,
        BookingDateTime: moment(this.booking.bookingDateTime).format("YYYY-MM-DD HH:mm:ss"),
        PaperworkStatus: this.booking.paperworkStatus,
        SurrogateDetails: this.booking.surrogateDetails,
        ClaimantConfirmed: this.booking.claimantConfirmed,
        SurrogateRequired: this.booking.surrogateRequired,
        WillAcceptEarlier: this.booking.willAcceptEarlier,
        InterpreterDetails: this.booking.interpreterDetails,
        PrePaymentRequired: this.booking.prepaymentRequired,
        InterpreterRequired: this.booking.interpreterRequired,
        VideoLinkTestStatus: this.booking.videoLinkTestStatus,
        BookingTrackingStatus: this.booking.bookingTrackingStatus,
        PaymentDeferralOption: this.booking.paymentDeferralOption,
        ClaimantConsentReceived: this.booking.claimantConsentReceived,
        SpecialistConfirmedHours: this.booking.specialistConfirmedHours,
        CallClientAfterAppointment: this.booking.callClientAfterAppointment,
        RedHealthMayContactClaimant: this.booking.redHealthMayContactClaimant,
        UserID: this.user.id,
        Username: this.user.name,
      }
      if(this.booking.bookingStatus == 8) {
        params.BookingStatusName = 'Booked'
      }
      // Pass it to API
      var result = await utilities.putBooking(params)
      if (result !== false) {
        this.successMessage = "Booking details updated."
        utilities.showAlert(".alert-success")
      } else {
        this.errorMessage = "An error occurred."
        utilities.showAlert(".alert-danger")
      }
      // Process response
    },
    goToCase(caseId) {
      this.$router
        .push({ path: "/admin-manage-case", query: { id: caseId } })
        .catch((err) => {})
    },

    async openEmailModal(mode, emailOrSmsLocal = 0) {
      if(mode == 'claimant' && (!this.booking.redHealthMayContactClaimant || !this.client.redHealthMayContactClaimants)) {
        return false
      }

      this.$store.dispatch('loading/setLoadComponent', true)
      if(!this.booking.redHealthMayContactClaimant || !this.client.redHealthMayContactClaimants) {
        this.$refs.adminSendEmailModal.redHealthMayContactClaimant = false
      }

      this.sendToProps = mode
      this.emailOrSms = emailOrSmsLocal

      this.$refs.adminSendEmailModal.email = utilities.initEmail()
      this.$refs.adminSendEmailModal.sendTo = mode
      this.$refs.adminSendEmailModal.emailOrSms = this.emailOrSms
      this.$refs.adminSendEmailModal.standardFiles = await utilities.getStandardForms()
      this.$refs.adminSendEmailModal.standardFiles.sort((a, b) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1
      })

      this.$refs.adminSendEmailModal.handleModal()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    requestAction() {
      this.$refs.requestActionModal.handleModal()
    },

    async actionConfirmation(isNew = true) {
      if (isNew) {
        this.successMessage = "Your update has been saved."
        utilities.showAlert(".alert-success")
      }
      this.bookingAction = await utilities.getBookingActions(this.booking.id)
    },

    async updateNotify(mode) {
      await this.updateBooking()
      if (mode == "client") {
        var params = {
          EmailTo: this.email.client,
          Content:
            "Your booking with id " + this.booking.id + " has been updated.",
          Subject: "RedHealth Booking Updated",
        }
        var res = await utilities.sendEmailFromBooking(params)
        if (res) {
          this.successMessage = "Email sent to " + this.sendToProps + "."
          utilities.showAlert(".alert-success")
          this.handleModal()
        }
      } else {
        var params = {
          EmailTo: this.email.specialist,
          Content:
            "Your booking with id " + this.booking.id + " has been updated.",
          Subject: "RedHealth Booking Updated",
        }
        var res = await utilities.sendEmailFromBooking(params)
        if (res) {
          this.successMessage = "Email sent to " + this.sendToProps + "."
          utilities.showAlert(".alert-success")
          this.handleModal()
        }
      }
    },
    openPurchaseOrderItemModal(item = "") {
      if (item != "") this.selectedItem = item
      else {
        this.selectedItem = utilities.initPurchaseOrderItem()
        this.selectedItem.bookingPurchaseOrderId = this.purchaseOrder.id
      }
      this.$refs.purchaseOrderItemModal.handleModal()
    },

    async deletePurchaseOrderItem(id, index) {
      this.$confirm({
        content: 'Are you sure you want to delete this Purchase Order item?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          var params = {
            UserID: this.user.id,
            UserName: this.user.name,
            PurchaseOrderItemID: id,
          }
          var result = await utilities.deletePurchaseOrderItem(params)
          if (result) {
            this.purchaseOrderItems.splice(index, 1)
            this.successMessage = 'Purchase order item deleted successfully.'
            utilities.showAlert('.alert-success-down')
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    isDate(date) {
      var d = new Date(date)
      return !isNaN(d.getTime())
    },
    getCaseOwnerName() {
      if (this.clientcontacts == undefined || this.clientcontacts.length == 0)
        return ""
      var caseOwner = this.clientContacts.find(
        (x) => x.clientContactId == this.booking.caseOwner
      )
      if (caseOwner != undefined) {
        return caseOwner.fullName
      } else {
        caseOwner = this.clientContacts[0]
        if (caseOwner != undefined) {
          return caseOwner.fullName
        } else {
          return ""
        }
      }
    },
    getCaseOwnerPhone() {
      if (this.clientContacts == undefined || this.clientContacts.length == 0)
        return ""
      var caseOwner = this.clientContacts.find(
        (x) => x.clientContactId == this.booking.caseOwner
      )
      if (caseOwner != undefined) {
        return caseOwner.phoneNumber1 == ""
          ? caseOwner.mobile == ""
            ? caseOwner.phoneNumber2 == ""
              ? ""
              : caseOwner.phoneNumber2
            : caseOwner.mobile
          : caseOwner.phoneNumber1x
      } else {
        caseOwner = this.clientContacts[0]
        if (caseOwner != undefined) {
          return caseOwner.phoneNumber1 == ""
            ? caseOwner.mobile == ""
              ? caseOwner.phoneNumber2 == ""
                ? ""
                : caseOwner.phoneNumber2
              : caseOwner.mobile
            : caseOwner.phoneNumber1
        } else {
          return ""
        }
      }
    },
    getCaseOwnerEmail() {
      if (this.clientContacts == undefined || this.clientContacts.length == 0)
        return ""
      var caseOwner = this.clientContacts.find(
        (x) => x.clientContactId == this.booking.caseOwner
      )
      if (caseOwner != undefined) {
        return caseOwner.emailAddress
      } else {
        caseOwner = this.clientContacts[0]
        if (caseOwner != undefined) {
          return caseOwner.emailAddress
        } else {
          return ""
        }
      }
    },

    async getUpdatedPurchaseOrder() {
      this.successMessage = 'Purchase order item updated.'
      utilities.showAlert('.alert-success-down')
      this.purchaseOrderItems = await utilities.getPurchaseOrderItems(this.purchaseOrder.id)
      this.setDescription(this.purchaseOrderItems)
    },

    async loadPurchaseOrder() {
      const apiArr = [utilities.getSpecialistById(this.booking.specialistId), utilities.getClientById(this.booking.clientId), utilities.getClientContacts(this.booking.clientId), utilities.getCaseByCaseId(this.booking.caseId), utilities.getBookingActions(this.booking.id), utilities.getPurchaseOrder(this.booking.id)]
      const r = await Promise.all(apiArr)

      this.specialist = r[0]
      this.client = r[1]
      this.clientContacts = r[2]
      if (this.booking.reportToEmailAddress && this.booking.reportTo == '') {
        this.booking.reportTo = -1
        this.showOther = true
      }

      this.singleCase = r[3]

      //set case info
      if (this.booking.bodyAreaOther.length > 0) {
        var items = this.booking.bodyAreaOther.split(",")
        this.otherBodyAreaTags =
          utilities.getAutoCompleteOtherBodyAreaTags(items)
      }

      this.booking.bodyAreas.map((x) => {
        this.bodyAreasTags.push({
          id: x.caseBodyAreaId,
          text: x.bodyArea,
          caseId: this.$route.query.id,
        })
      })

      this.booking.personConditions.map((x) => {
        this.conditionTags.push({
          id: x.id,
          text: x.name,
          caseId: this.$route.query.id,
        })
      })

      this.bookingAction = r[4]
      this.purchaseOrder = r[5]
      if (this.purchaseOrder.invoiceNumber) {
        this.hideButton = true
      }
      if (this.purchaseOrder.id !== "") {
        this.purchaseOrderItems = await utilities.getPurchaseOrderItems(this.purchaseOrder.id)
        this.setDescription(this.purchaseOrderItems)
      }

      this.email = {
        specialist: this.specialist.email,
        client: this.booking.clientEmail,
        claimant: this.booking.claimantEmail,
      }

      this.emails = {
        specialist: this.specialists.find((x) => x.id == this.booking.specialistId).email,
        client: this.booking.clientContacts != undefined && this.booking.clientContacts[0] != undefined ? this.booking.clientContacts[0].email : "",
        claimant: this.booking.claimantEmail,
      }
      this.phones = {
        specialist: this.specialists.find(x=>x.id == this.booking.specialistId).mobile,
        client: this.booking.clientContacts != undefined && this.booking.clientContacts[0] != undefined ? this.booking.clientContacts[0].phone : "",
        claimant: this.booking.claimantMobile
      }
      this.names = {
        specialist: this.specialists.find((x) => x.id == this.booking.specialistId).fullName,
        client: this.booking.clientName,
        claimant: this.booking.claimantFullName,
      }
    },
    async loadData() {
      this.booking.id = this.$route.query.id != undefined ? this.$route.query.id : ""
      const apiArr = [utilities.getBodyArea(), utilities.getPersonConditions(), utilities.getBookingTypes(), utilities.getLocations(), utilities.getClaimTypes(), utilities.getSpecialists(), utilities.getBookingById(this.booking.id)]
      const r = await Promise.all(apiArr)
      this.booking = r[6]
      this.bodyAreasAutocomplete = r[0]
      this.bodyAreasAutocomplete = this.bodyAreasAutocomplete.map((x) => {
        return { id: x.id, text: x.bodyArea }
      })
      this.conditionsAutocomplete = r[1]
      this.conditionsAutocomplete = this.conditionsAutocomplete.map((x) => {
        return { id: x.id, text: x.name }
      })
      this.bookingTypes = r[2]
      this.locations = r[3]
      this.claimTypes = r[4]
      this.specialists = r[5]
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $("body>.tooltip").remove()
    if (this.user.type != 4 && this.user.type != 6) {
      this.$router.go(-1)
    } else {
      await this.loadData()
      await this.loadPurchaseOrder()
    }
    utilities.initTooltip()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
}
</script>
