<template>
  <div class="add-specialist-booking-test-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <h1>Tests Ordered</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-center box-col-left">Tests Ordered</div>
                <div class="box-col box-col-right">
                  <input type="text" class="ml-2" v-model="bookingTest.testName" placeholder="Tests Ordered" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">From</div>
                <div class="box-col box-col-right">
                  <input type="text" class="ml-2" v-model="bookingTest.orderedFrom" placeholder="From" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Date Ordered</div>
                <div class="box-col box-col-right box-col-center">
                  <datepicker
                    v-model="bookingTest.dateOrdered"
                    format="dd/MM/yyyy"
                    :typeable="true"
                    placeholder="dd/mm/yyyy"
                    input-class="hasError && !isValidDateOrdered ? 'is-invalid' : ''"
                    class="w-50 date-ordered"
                    @input="changeDateOrdered">
                  </datepicker>
                  <div class="text-danger" v-if="hasError && !isValidDateOrdered">
                    Date Ordered date is invalid
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Date Received</div>
                <div class="box-col box-col-right box-col-center">
                  <datepicker
                    v-model="bookingTest.dateReceived"
                    format="dd/MM/yyyy"
                    :typeable="true"
                    :input-class="hasError && !isValidDateReceived ? 'is-invalid' : ''"
                    placeholder="dd/mm/yyyy"
                    class="w-50 date-recevied"
                    @input="changeDateRecevied"
                    >
                  </datepicker>
                  <div class="text-danger" v-if="hasError && !isValidDateReceived">
                    Date Received date is invalid
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                  <v-btn @click="clickSave" dark>Save</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AddSpecialistBookingTestModal',
    props: ['bookingProps', 'testsProps', 'fromProps'],
    data() {
      return {
        loadModal: false,
        hasError: false,
        note: '',
        bookingTest: utilities.initBookingTest(),
        test: this.testsProps,
        validateHours: false,
        validateService: false,
        isValidDateReceived: true,
        isValidDateOrdered:true,
        selectedIndex: -1,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    watch: {
      test: {
        handler(value) {
          if (value != -1) {
            this.assignToBookingTest(value)
          } else {
            this.bookingTest = utilities.initBookingTest()
          }
        },
        deep: true,
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          this.clearInputData()
          this.$parent.test = -1
          $('html').removeClass('modal-mask-open')
        }
      },

      setBookingTest(val) {
        this.bookingTest = {
          id: val.id,
          testName: val.testName,
          dateOrdered: moment(val.dateOrdered, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          orderedFrom: val.orderedFrom,
          dateReceived: moment(val.dateReceived, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          isNew: false,
        }
      },

      clearInputData() {
        this.bookingTest.testName = ''
        this.bookingTest.orderedFrom = ''
        this.bookingTest.dateOrdered = ''
        this.bookingTest.dateReceived = ''
      },

      changeDateRecevied() {
        setTimeout(() => {
          this.isValidDateReceived = this.validateDateRecevied()
        }, 200)
      },
      validateDateRecevied() {
        if ($(".date-recevied input"). length > 0 && $(".date-recevied input").val().length > 0) {
          if(this.bookingTest.dateReceived == '' || !moment($(".date-recevied input").val(), "DD/MM/YYYY").isValid()) {
            return false
          }
        }
        return true
      },

      changeDateOrdered() {
        setTimeout(() => {
          this.isValidDateOrdered = this.validateDateOrdered()
        }, 200)
      },
      validateDateOrdered() {
        if ($(".date-ordered input").length > 0 && $(".date-ordered input").val().length > 0) {
          if(this.bookingTest.dateOrdered == '' || !moment($(".date-ordered input").val(), "DD/MM/YYYY").isValid()) {
            return false
          }
        }
        return true
      },

      assignToBookingTest: function(val) {
        this.bookingTest = {
          id: val.id,
          testName: val.testName,
          dateOrdered: moment(val.dateOrdered, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          orderedFrom: val.orderedFrom,
          dateReceived: moment(val.dateReceived, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          isNew: false,
        }
      },

      async clickSave() {
        if (!this.validateDateRecevied()) {
          this.hasError = true
          return
        }

        if (this.fromProps == undefined || this.fromProps == '') {
          this.$store.dispatch('loading/setLoadComponent', true)
          var result = await this.callputBooking()
          if (result) {
            this.$parent.getOriginalBooking()
            var booking = await utilities.getBookingById(this.bookingProps.id)
            this.$parent.booking.bookingTests = booking.bookingTests
            // this.booking = await utilities.getBookingById(this.booking.id)
            this.handleModal()
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        } else {
          if (this.selectedIndex != -1) {
            var test = this.$parent.booking.bookingTests[this.selectedIndex]
            test.testName = this.bookingTest.testName
            test.orderedFrom = this.bookingTest.orderedFrom
            test.dateOrdered = this.checkDate(this.bookingTest.dateOrdered, 'DD/MM/YYYY')
            test.dateReceived = this.checkDate(this.bookingTest.dateReceived, 'DD/MM/YYYY')
          } else {
            this.$parent.booking.bookingTests.push({
              testName: this.bookingTest.testName,
              orderedFrom: this.bookingTest.orderedFrom,
              dateOrdered: this.checkDate(this.bookingTest.dateOrdered, 'DD/MM/YYYY'),
              dateReceived: this.checkDate(this.bookingTest.dateReceived, 'DD/MM/YYYY'),
            })
          }

          this.handleModal()
        }
      },

      checkDate(date, format) {
        if (date != '' && date != 'Invalid date') {
          return moment(date).format(format)
        }
        return ''
      },

      async callputBooking() {
        var tests = []
        var test = {}
        if (this.bookingTest.id === '') {
          test = {
            TestName: this.bookingTest.testName,
            OrderedFrom: this.bookingTest.orderedFrom,
            DateOrdered: this.checkDate(this.bookingTest.dateOrdered, 'YYYY-MM-DD'),
            DateReceived: this.checkDate(this.bookingTest.dateReceived, 'YYYY-MM-DD'),
          }
        } else {
          test = {
            BookingTestID: this.bookingTest.id,
            TestName: this.bookingTest.testName,
            OrderedFrom: this.bookingTest.orderedFrom,
            DateOrdered: this.checkDate(this.bookingTest.dateOrdered, 'YYYY-MM-DD'),
            DateReceived: this.checkDate(this.bookingTest.dateReceived, 'YYYY-MM-DD'),
          }
        }
        // return
        tests.push(test)

        var params = {
          BookingID: this.bookingProps.id,
          SpecialistID: this.bookingProps.specialistId,
          BookingTypeID: this.bookingProps.bookingTypeId,
          BookingTypeName: this.bookingProps.bookingTypeName,
          CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
          Private: this.bookingProps.private,
          OTFCERequired: this.bookingProps.otfcERequired,
          BookingStatus: this.bookingProps.bookingStatus,
          WillAcceptEarlier: this.bookingProps.willAcceptEarlier,
          RedHealthMayContactClaimant: this.bookingProps.redHealthMayContactClaimant,
          TestObject: tests,
          UserID: this.user.id,
          Username: this.user.name,
        }

        var callback = (response) => {
          this.bookingProps.id = response
        }
        return utilities.putBooking(params, callback)
      },
    },

    beforeMount() {
      this.isValidDateReceived = this.validateDateRecevied()
      this.isValidDateOrdered = this.validateDateOrdered()
    },
    mounted() {},
    updated() {},
  }
</script>
