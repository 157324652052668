<template>
  <el-dialog
      class="edit-zoho-dialog"
      title="Duplicated Cases"
      :visible.sync="dialogVisible"
      @close="closeModal"
      width="70%">
    <div>Following cases may duplicated with yours, you can select to use an existing case, or modify your case details:</div>
    <el-table
        :data="result"
        style="width: 100%">
      <el-table-column
          prop="id"
          label="ID"
          width="180">
      </el-table-column>
      <el-table-column
          prop="caseReference"
          label="Reference Number"
          width="180">
      </el-table-column>
      <el-table-column
          prop="claimantFullName"
          label="Claimant Name">
      </el-table-column>
      <el-table-column
          prop="numberBookings"
          label="Number Of Booking">
      </el-table-column>
      <el-table-column
          prop="caseOwnerName"
          label="Case Owner">
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              class="v-btn border-0"
              style="min-width: 0; min-height: 0;"
              @click="handleEdit(scope.$index, scope.row)">Add to this case</el-button>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <v-btn v-if="!hasToChooseCase" dark @click="handleEdit()">Make a new Case</v-btn>
       <v-btn class="white-button" dark @click="closeModal()">Cancel</v-btn>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DuplicateCaseModal.ve",
  props: ['dialogVisible', 'result', 'hasToChooseCase'],
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    handleEdit(index, item) {
      this.$emit('selectCase', item)
    }
  }
}
</script>

<style scoped>

</style>