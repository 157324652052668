/* eslint-disable */
<template>
  <div class="clients-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-clients', query: {stamp:getStamp()} }">Clients</router-link></li>
      <li><router-link :to="{ name: 'admin-manage-client', params: {} }">Create Client</router-link></li>
      <li><router-link :to="{ name: 'admin-client-requests', params: {} }">Client Account Requests</router-link></li>
      <li><router-link :to="{ name: 'admin-client-contacts', params: {} }">Client Contacts</router-link></li>
      <li><router-link :to="{ name: 'admin-cases', params: {} }">Client Cases</router-link></li>
<!--      <li><router-link :to="{ name: 'admin-ownership-change-request', params: {} }">Ownership Change Requests</router-link></li>-->
      <!-- <li class="is-icon" @click="openSearch">Search</li> -->
<!--      <li class="is-icon" @click="openSearchContact">Search Client Contacts</li>-->
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'AdminClientsMenu',
    props: ['indexProps'],
    data() {
      return {}
    },
    methods: {
      openSearch() {
        this.$parent.$refs.navigation.openSearchClient()
      },
      openSearchContact() {
        this.$parent.$refs.navigation.openSearchClientContact()
      },
      getStamp() {
        return moment().unix()
      }
    },
    beforeMount() {
      if (this.indexProps == undefined) {
        this.indexProps = 0
      }
    },
    mounted() {
      $('.sub-menu li').eq(this.indexProps).addClass('sub-menu-selected')
    },
  }
</script>
