<template>
  <div class="add-name-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Claimant Name</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Claimant</div>
                <div class="box-col box-col-right">
                  <select class="textbox w-100" v-model="name.caseId" @change="changeCase">
                    <option value="">Please choose</option>
                    <option v-for="(singleCase, index) in cases" :value="singleCase.id">{{ singleCase.claimantFullName + " (" + singleCase.caseReference + ")" }}</option>
                  </select>
                  <div class="text-danger" v-if="cases.length == 0">No Claimants.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col">OR</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">First Name</div>
                <div class="box-col box-col-right">
                  <input type="text" :class="hasError && !validateFirstName ? 'is-invalid' : ''" v-model="name.firstName" placeholder="First Name" @input="changeText" />
                  <div class="text-danger" v-if="hasError && !validateFirstName">First Name is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Middle Name/s</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="name.middleName" placeholder="Middle Name/s" @input="changeText" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Surname</div>
                <div class="box-col box-col-right">
                  <input type="text" :class="hasError && !validateSurname ? 'is-invalid' : ''" v-model="name.surname" placeholder="Surname" @input="changeText" />
                  <div class="text-danger" v-if="hasError && !validateSurname">Surname is required.</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                  <v-btn @click="save" dark>Save</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: 'AdminAddNameModal',
  data() {
    return {
      loadModal: false,
      hasError: false,

      name: {
        caseId: "",
        firstName: "",
        middleName: "",
        surname: "",
        fullName: "",
        dateOfBirth: "",
      },

      cases: [],
    }
  },
  computed: {
    validateFirstName() {
      if (this.name.firstName == '') {
        return false
      }
      return true
    },
    validateSurname() {
      if (this.name.surname == '') {
        return false
      }
      return true
    },
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if (this.loadModal) {
        $('html').addClass('modal-mask-open')
      } else {
        $('html').removeClass('modal-mask-open')
      }
    },
    changeCase() {
      var singleCase = this.cases.find(x => x.id == this.name.caseId)
      this.name.firstName = singleCase != undefined ? singleCase.claimantFirstName : ""
      this.name.middleName = singleCase != undefined ? singleCase.claimantGivenNames : ""
      this.name.surname = singleCase != undefined ? singleCase.claimantSurname : ""
      this.name.fullName = singleCase != undefined ? singleCase.claimantFullName : ""
      this.name.dateOfBirth = singleCase != undefined ? singleCase.dateOfBirth : ""
    },
    changeText() {
      this.name.caseId = ""
    },
    loadName(claimant) {
      this.name.firstName =  claimant.claimantFirstName
      this.name.middleName =  claimant.claimantGivenNames
      this.name.surname =  claimant.claimantSurname
      this.name.fullName =  claimant.claimantFullName
    },
    save() {
      if(!this.validateFirstName || !this.validateSurname) {
        this.hasError = true
        return false
      } else {
        this.hasError = false
      }

      this.$parent.loadName(this.name)
      this.handleModal()
    },
  },
  beforeMount() {},
  mounted() {},
}
</script>
