/* eslint-disable */
<template>
  <div class="manage-case">
    <Navigation indexProps="3"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title">
          <h1 v-if="showContent">{{ isEdit ? 'case ' + singleCase.caseReference : 'Create case' }}</h1>
          <h5 v-else>Case does not exist</h5>
        </div>
        <div class="header-sub-title">A case allows you to keep track of all the assessments required for a particular
          matter.
        </div>
      </div>
    </div>
    <div class="panel">
      <CaseMenu :key="isEditId" :indexProps="isEdit ? '0' : '1'"/>
      <div class="content" v-if="showContent">
        <div class="top-detail">
          <div class="box">
            <div class="box-heading">
              <div>{{ isEdit ? 'case ' : 'Create a New case' }}</div>
            </div>
            <div class="box-body">
              <div class="box-row" v-if="isEdit">
                <div class="box-col box-col-left">Status</div>
                <div class="box-col box-col-right">
                  <select v-model="singleCase.caseStatus" class="textbox w-50">
                    <option v-for="status in statusList" :value="status.value">{{ status.text }}</option>
                  </select>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Reference Number</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="singleCase.caseReference" class="textbox referance"
                         :class="hasError && !validateCaseReference ? 'is-invalid' : ''"
                         placeholder="Your reference number"/>
                  <div class="text-danger" v-if="hasError && !validateCaseReference">
                    Please enter case reference (do not contain ' " or `)
                  </div>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <!-- <div class="box-row" v-if="isEdit">
                <div class="box-col box-col-left">On behalf of</div>
                <div class="box-col box-col-right">
                  <input type="text" class="textbox behalf" v-model="singleCase.onBehalfOf" placeholder="On behalf of" />
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div> -->
              <div class="box-row" v-if="isEdit && user.type == 2">
                <div class="box-col box-col-left">Case Owner</div>
                <div class="box-col box-col-right">
                  <select class="textbox w-50" v-model="singleCase.caseOwner">
                    <option value="">Please choose</option>
                    <option v-for="(contact, index) in clientContacts" :value="contact.userId">{{
                        contact.fullName
                      }}
                    </option>
                  </select>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Claimant</div>
                <div class="box-col box-col-right">
                  <div v-if="!isEditName" @click="openAddNameModal" class="parent is-icon full-width-div">
                    <input class="child placeholder-opacity px-3" type="text" disabled
                           placeholder="Please click here to enter the Claimant details"
                           :class="hasError && !validateClaimantName ? 'is-invalid' : ''"/>
                    <div class="text-danger" v-if="hasError && !validateClaimantName">
                      Please enter claimant's name
                    </div>
                  </div>
                  <span v-else :key="nameKey" @click="openAddNameModal" class="pl-2">
                    {{ this.singleCase.claimantFullName }}
                  </span>
                  <!-- <div class="text-danger" v-if="hasError && !validateClaimantName" style="position: absolute; z-index: 1; top: 30px;">
                    Please enter claimant's name
                  </div> -->
                </div>
                <AddNameModal ref="addNameModal"/>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Claimant Salutation</div>
                <div class="box-col box-col-right" >
                  <el-select v-model="singleCase.Salutation" filterable clearable placeholder="Claimant Salutation">
                    <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Claimant Preferred Name</div>
                <div class="box-col box-col-right" >
                  <input class="px-3 w-50" v-model="singleCase.PreferredGivenName" maxlength="100" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Date of Birth</div>
                <div class="box-col box-col-right box-col-center">
                  <datepicker
                      format="dd/MM/yyyy"
                      :typeable="true"
                      class="date-of-birth w-50"
                      placeholder="dd/mm/yyyy"
                      v-model="singleCase.dateOfBirth"
                      :input-class="hasError && !isValidDateOfBirth ? 'is-invalid' : ''"
                      @input="changeDateOfBirth"></datepicker>
                  <div class="text-danger" v-if="hasError && !isValidDateOfBirth">
                    Claimant's date of birth is invalid
                  </div>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Claimant Contact Details</div>
                <div class="box-col box-col-right">
                  <div v-if="!isEditContact" @click="openManageContactDetails" class="parent is-icon full-width-div">
                    <input class="child placeholder-opacity" type="text" disabled
                           placeholder="Please click here to enter contact details"/>
                  </div>
                  <span v-else :key="contactKey" @click="openManageContactDetails">
                    <span v-if="this.singleCase.claimantMobile"
                          class='text-bold'>M: </span> {{ this.singleCase.claimantMobile }}
                    <span v-if="this.singleCase.claimantEmail"
                          class='text-bold'>E: </span> {{ this.singleCase.claimantEmail }}
                    <span v-if="this.singleCase.claimantPhone1"
                          class='text-bold'>P: </span> {{ this.singleCase.claimantPhone1 }}
                    <span v-if="this.singleCase.claimantPhone2"
                          class='text-bold'>L: </span> {{ this.singleCase.claimantPhone2 }}
                  </span>
                  <div class="is-icon right-float-element" @click="openManageContactDetails">
                  </div>
                  <AddClientContactModal ref="addClientContactModal" :typrProps="'fromcases'"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Claimant Address</div>
                <div class="box-col box-col-right">
                  <div v-if="!isEditAddress" class="parent is-icon full-width-div" @click="openManageAddress"
                       :key="addressKey">
                    <input class="child placeholder-opacity w-100" disabled
                           placeholder="Please click here to update claimant's address"/>
                  </div>
                  <span v-else @click="openManageAddress" :key="addressKey">{{ singleCase.claimantFullAddress }}</span>
                  <div class="is-icon right-float-element" @click="openManageAddress">
                  </div>
                  <AddClientAddressModal ref="addClientAddressModal"/>
                </div>
              </div>
              <!-- <div class="box-row">
                <div class="box-col box-col-left">Date of Accident/Event</div>
                <div class="box-col box-col-right box-col-center">
                  <div class="w-75">
                    <div class="right-float-element" style="line-height: 40px;">
                      <toggle-button @change="changeOverTime()" class="togglePeriodOfTime" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="singleCase.overPeriodofTime"/>  Over a period of time
                    </div>
                    <datepicker
                      :disable="singleCase.overPeriodofTime"
                      format="dd/MM/yyyy"
                      :typeable="true"
                      class="w-50 accient-date"
                      placeholder="dd/mm/yyyy"
                      :disabled="togglePeriodOfTime"
                      :input-class="hasError && !isValidAccidentDate ? 'is-invalid' : ''"
                      v-model="singleCase.accidentDate"
                      @selected="selectAccientDate"
                      @input="changeAccientDate"></datepicker>
                    <div class="text-danger" v-if="hasError && !isValidAccidentDate">
                      Date of Accident/Event is invalid
                    </div>
                  </div>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div> -->
              <!-- <div class="box-row">
                <div class="box-col box-col-left">Commencement Date</div>
                <div class="box-col box-col-right box-col-center">
                  <datepicker
                    class="w-50"
                    format="dd/MM/yyyy"
                    :typeable="true"
                    placeholder="dd/mm/yyyy"
                    v-model="singleCase.commencementDate"
                    :input-class="hasError && !validateCommencementDate ? 'is-invalid' : ''"
                    ></datepicker>
                  <div class="text-danger" v-if="hasError && !validateCommencementDate">
                    Please enter commencement date
                  </div>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div> -->
              <div class="box-row">
                <div class="box-col box-col-left">Type of Claim</div>
                <div class="box-col box-col-right">
                  <div v-if="!claimFocus && (singleCase.claimTypeId == '' || singleCase.claimTypeId == undefined)"
                       @click="claimFocus = true" class="parent">
                    <input class="child w-50" type="text" disabled placeholder="Click to choose the Type of Claim"
                           :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''"/>
                  </div>
                  <select v-model="singleCase.claimTypeId" v-else
                          :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" class="w-50 textbox">
                    <option value="" hidden>Please choose type of claim...</option>
                    <option v-for="claimType in claimTypes" :value="claimType.id">{{ claimType.name }}</option>
                  </select>
                  <div class="text-danger" v-if="hasError && !validateTypeOfClaim">
                    Please choose type of claim
                  </div>
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <!-- <div class="box-row" v-if="isEdit">
                <div class="box-col box-col-left">Affected Body Areas</div>
                <div class="box-col box-col-right box-col-center">
                  <vue-tags-input
                    v-model="bodyAreaTag"
                    :tags="bodyAreaTags"
                    placeholder="Please choose"
                    :autocomplete-items="filteredBodyAreaItems"
                    @before-deleting-tag="deleteBodyArea"
                    @focus="openDigram()"
                    />
                </div>
                <div @click="openBodyDiagram" class="center-item">
                  <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3"/>
                </div>
                <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
              </div>
              <div class="box-row" v-if="isEdit">
                <div class="box-col box-col-left">Other Body Areas</div>
                <div class="box-col box-col-right box-col-center">
                  <vue-tags-input
                    v-model="otherBodyAreaTag"
                    :tags="otherBodyAreaTags"
                    placeholder="Add Other Body Areas"
                    @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div>
              <div class="box-row" v-if="isEdit">
                <div class="box-col box-col-left">Conditions</div>
                <div class="box-col box-col-right box-col-center">
                  <vue-tags-input
                    ref="conditions"
                    v-model="personConditionTag"
                    :tags="personConditionTags"
                    placeholder="Add Conditions"
                    :add-only-from-autocomplete="true"
                    @before-deleting-tag="deletePersonCondition"
                    :autocomplete-items="filteredPersonConditionsItems"
                    @tags-changed="updateConditions" />
                </div>
                <div class="box-col box-col-right w-auto">
                  &nbsp;
                </div>
              </div> -->
            </div>
          </div>

          <div class="box-row item-right">
            <div>
              <v-btn dark @click="tapSave">{{ isEdit ? 'Update' : 'Save' }}</v-btn>
            </div>
          </div>

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>
          <div class="alert alert-warning hidden-element">
            <a class="close" title="close">×</a>
            <b>Warning:</b> {{ errorMessage }}
          </div>

          <div class="header-title ml-0" style="padding-left=0px" v-if="isEdit">File Summary</div>
          <div class="" v-if="isEdit">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="hidden-element">Id</th>
                  <th style="width:20%" class="long-text">File Type</th>
                  <th style="width:30%" class="long-text">File Name</th>
                  <th style="width:25%" class="long-text">Uploaded by</th>
                  <th style="width:15%" class="long-text">Status</th>
                  <th style="width:5%" class="long-text"></th>
                  <th style="width:5%" class="long-text"></th>
                </tr>
                </thead>
              </table>
            </div>
            <div class="table-responsive" style="max-height: 200px">
              <table class="table">
                <tbody>
                <tr v-for="(file, index) in allBookingFiles" v-if="allBookingFiles.length > 0">
                  <td style="width:20%" class="long-text">{{ file.fileTypeName }}</td>
                  <td style="width:30%" class="long-text">{{ file.fileName }}</td>
                  <td style="width:25%" class="long-text">{{ file.uploadedByName }}</td>
                  <td style="width:15%" class="long-text">
                    {{ file.fileStatus == 2 ? 'Complete' : getFileStatusNameById(file.fileStatus) }}
                  </td>
                  <td style="width:5%" :class="file.uploadedBy != user.id ? 'img-disable' : ''"
                      @click="deleteFile(file.bookingFileId, index, file.uploadedBy)" class="long-text"><img
                      v-if="file.fileStatus != 1" src="@/assets/images/delete_icon.svg"/></td>
                  <td style="width:5%" class="long-text"><div @click="getFile(file)" class="cursor-pointer"><img
                      src="@/assets/images/eye-icon.svg"/></div></td>
                </tr>
                <tr v-if="allBookingFiles.length == 0">
                  <td>No Files</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="box" v-if="isEdit" style="margin-top: 30px: max-height: 200px">
            <div class="box-heading col-space-between">
              <div>Bookings</div>
              <!-- <div>
                <router-link :to="{path:'/search-specialist',query:{id:singleCase.id}}" class="router-link"><v-btn class="add-icon submit-request" dark>Add Booking</v-btn></router-link>
              </div> -->
            </div>
            <div class="box-body" style="max-height: 250px;overflow: hidden;overflow-y: auto;">
              <div v-if="bookings.length > 0" class="box-row" v-for="(booking, index) in bookings" :key="index">
                <div @click="editBooking(booking.bookingDetails.id)" class="list-detail-row is-icon">
                  <div class="box-col" style="width: 90%;">
                    ID: {{ booking.bookingDetails.id }} {{ booking.bookingDetails.bookingTypeName + "," }}
                    {{ formatDate(booking.bookingDetails.dateTime, "hh:mm A DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss") }}
                    {{ booking.specialistDetails.fullName }} {{ getLocationById(booking.bookingDetails.locationId) }}
                  </div>
                  <div class="box-col" style="width: 15%;text-align: end;">
                    {{ getBookingStatusById(booking.bookingDetails.status) }}
                  </div>
                </div>
              </div>
              <div v-if="bookings.length == 0" class="box-row" style="padding: 10px;">
                No bookings
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Chat/>
    <AdminCaseCreatedModal ref="adminCaseCreatedModal" :clientProps="true"/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import CaseMenu from '@/components/CaseMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import AddNameModal from '@/components/AddNameModal'
import AdminCaseCreatedModal from '@/components/AdminCaseCreatedModal'
import AddClientContactModal from '@/components/AddClientContactModal'
import AddClientAddressModal from '@/components/AddClientAddressModal'

export default {
  name: 'ManageCase',
  components: {
    Navigation,
    CaseMenu,
    Header,
    Chat,
    AddNameModal,
    AdminCaseCreatedModal,
    AddClientContactModal,
    AddClientAddressModal,
  },
  data() {
    return {
      salutationList: utilities.initSalutation(),
      hasError: false,
      successMessage: '',
      errorMessage: '',

      // Validate Dates
      isValidDateOfBirth: true,
      isValidAccidentDate: true,

      isEdit: true,
      claimFocus: false,
      isEditContact: false,
      isEditAddress: false,
      isEditName: false,
      togglePeriodOfTime: false,
      loaded: false,
      showContent: true,
      bodyAreaTag: "",
      otherBodyAreaTag: "",
      personConditionTag: "",
      placeholder: "Add",
      claimTypes: [],
      // bodyAreas: [],
      // bodyAreaTags: [],
      // otherBodyAreaTags: [],
      // deletedBodyTags: [],
      // personConditions: [],
      // personConditionTags: [],
      // deletedConditionTags: [],
      clientContacts: [],
      client: [],
      bookings: [],
      allBookingFiles: [],
      addedBodyArea: [],
      addedConditions: [],
      cases: [],
      locations: [],
      fileStatus: utilities.getFileStatus(),
      statusList: utilities.getStatus(),
      bookingStatuses: utilities.getBookingStatuses(),
      pagination: utilities.initPagination(),
      singleCase: utilities.initCase(),
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      contactKey: 0,
      addressKey: 0,
      nameKey: 0,
      isEditId: 0,
    }
  },
  watch: {
    singleCase: {
      handler(value) {
        if (this.loaded) this.$store.dispatch("setNewObject", value)
      },
      deep: true,
    },
  },
  computed: {
    validateCaseReference() {
      if (this.singleCase.caseReference == '' || !utilities.validateNameText(this.singleCase.caseReference)) {
        return false
      }
      return true
    },

    validateClaimantName() {
      if (this.singleCase.claimantFirstName == '' || this.singleCase.claimantSurname == '') {
        return false
      }
      return true
    },

    // validateCommencementDate() {
    //   return this.singleCase.commencementDate == '' ? false : true
    // },

    validateTypeOfClaim() {
      return this.singleCase.claimTypeId == '' ? false : true
    },

    filteredBodyAreaItems() {
      return this.bodyAreas.filter(i => {
        return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1;
      });
    },

    filteredPersonConditionsItems() {
      return this.personConditions.filter(i => {
        return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1;
      });
    },
  },

  methods: {
    // updateConditions(newTags) {
    //   if(this.$refs.conditions.newTag.length == 0) newTags.pop()
    //   this.personConditionTags = newTags
    // },

    validateDateOfBirth() {
      if (this.singleCase.dateOfBirth == '' || !moment($(".date-of-birth input").val(), "DD/MM/YYYY").isValid()) {
        return false
      }
      return true
    },
    // validateAccidentDate() {
    //   if((this.singleCase.accidentDate == '' || !moment($(".accient-date input").val(), "DD/MM/YYYY").isValid()) && !this.singleCase.overPeriodofTime) {
    //     return false
    //   }
    //   return true
    // },
    validateAllDates() {
      if (!this.validateDateOfBirth()) return false
      else return true
    },
    changeDateOfBirth() {
      setTimeout(() => {
        this.isValidDateOfBirth = this.validateDateOfBirth()
      }, 200)
    },
    // changeAccientDate() {
    //   setTimeout(() => {
    //     this.isValidAccidentDate = this.validateAccidentDate()
    //   }, 200)
    // },

    // selectAccientDate() {
    //   this.singleCase.overPeriodofTime = false
    // },

    getFileStatusNameById(id) {
      if(id == 2) {
        return 'Complete'
      } else if (id == 3) {
        return 'For Review'
      }
      if (id == 0 || id == 1) {
        return this.fileStatus.find((x) => x.id == id).name
      }
      return ''
    },

    getClaimantTypeName(id) {
      var type = ''
      if (id) {
        type = this.claimTypes.find((x) => x.id == id).name
      }
      return type
    },

    enableEditCase(status) {
      this.isEditId += 1
      this.isEdit = status;
    },

    getBookingStatusById(id) {
      if (id) {
        var status = this.bookingStatuses.find((x) => x.id == id)
        if (status) {
          return status.name
        }
      }
      return ""
    },

    changeOverTime() {
      this.singleCase.overPeriodofTime = !this.singleCase.overPeriodofTime
      if (this.singleCase.overPeriodofTime) {
        this.singleCase.accidentDate = ""
      }
      //this.isValidAccidentDate = this.validateAccidentDate()
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date) {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ""
    },

    // deleteBodyArea(obj) {
    //   this.deletedBodyTags.push(obj.tag)
    //   this.bodyAreaTags.splice(obj.index, 1);
    // },

    // deletePersonCondition(obj) {
    //   this.deletedConditionTags.push(obj.tag)
    //   this.personConditionTags.splice(obj.index, 1);
    // },

    getPath(filepath) {
      return utilities.getFileFromPath(filepath.replace("/BookingFiles", "/files"))
    },

    editBooking(bookingId) {
      this.$router.push({path: '/edit-booking', query: {id: bookingId}}).catch((err) => {
      })
    },

    // openBodyDiagram() {
    //   this.$refs.addBodyDiagramModal.handleModal()
    //   this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
    //   this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
    //   this.$refs.addBodyDiagramModal.handleClick()
    // },

    // setBodyArea(addedBodyAreas) {
    //   this.bodyAreaTags = []
    //   this.bodyAreaTags = addedBodyAreas
    // },

    setName(object) {
      this.singleCase.claimantFirstName = object.firstName
      this.singleCase.claimantGivenNames = object.middleName
      this.singleCase.claimantSurname = object.surname
      this.singleCase.claimantFullName = utilities.getClaimantFullName(this.singleCase)
      if (this.singleCase.claimantFullName != "") {
        this.isEditName = true
      } else {
        this.isEditName = false
      }
      this.nameKey += 1
      if (this.isEdit) {
        this.tapSave()
      }
    },

    // deleteBodyAreaItems(items) {
    //   items.map((x) => {
    //     this.deletedBodyTags.push(x.tag)
    //   })
    // },

    async tapSave() {
      if (!this.validateCaseReference || !this.validateClaimantName
          || !this.validateAllDates() || !this.validateTypeOfClaim) {
        this.hasError = true
        return
      } else {
        this.hasError = false
      }

      /*
      //Remove unique constraint for case Reference number, so that a client can give the same reference number to more than one case.
      if(this.cases.length > 0 && this.singleCase.id == '' && this.cases.map(x => x.caseReference.toLowerCase()).includes(this.singleCase.caseReference.toLowerCase())) {
        utilities.showAlert('.alert-danger')
        this.errorMessage = "The Reference Number you entered already exists."
        return false
      }
      */

      //Move to the Booking screen
      // var otherBodyArea = ""
      // this.otherBodyAreaTags.map((x) => {
      //   if (otherBodyArea == "") {
      //     otherBodyArea = x.text
      //   } else {
      //     otherBodyArea = otherBodyArea + "," + x.text
      //   }
      // })
      //
      // var bodyAreaObjects = []
      // var bodyArea = {}
      // if (this.bodyAreaTags.length > 0) {
      //   this.bodyAreaTags.map((x) => {
      //     var temp = this.addedBodyArea.filter((y) => y.bodyArea == x.text)[0]
      //     if (temp == undefined) {
      //         bodyArea = {
      //           BodyAreaID: x.value
      //         }
      //         bodyAreaObjects.push(bodyArea)
      //     }
      //   })
      // }
      // //Pass deleted bodyArea object
      // if (this.deletedBodyTags.length > 0) {
      //   this.deletedBodyTags.forEach((item, i) => {
      //     this.addedBodyArea.forEach((added, i) => {
      //       if (item.text == added.bodyArea) {
      //         var bodyArea = this.bodyAreas.filter((y) => y.text == item.text)[0]
      //         bodyArea = {
      //           BodyAreaID: bodyArea.value,
      //           BookingBodyAreaID: added.caseBodyAreaId
      //         }
      //         bodyAreaObjects.push(bodyArea)
      //       }
      //     });
      //   });
      // }
      //
      // var conditionObject = []
      // var condition = {}
      // if (this.personConditionTags.length > 0) {
      //   this.personConditionTags.map((x) => {
      //     var temp = this.addedConditions.filter((y) => y.name == x.text)
      //     if (temp == undefined || temp.length == 0) {
      //         condition = {
      //           PersonConditionID: x.value
      //         }
      //         conditionObject.push(condition)
      //     }
      //   })
      // }
      //
      // //Pass deleted PersonCondition object
      // if (this.deletedConditionTags.length > 0) {
      //   this.deletedConditionTags.forEach((item, i) => {
      //     this.addedConditions.forEach((added, i) => {
      //       if (item.text == added.name) {
      //         var condition = this.personConditions.filter((y) => y.text == item.text)[0]
      //         condition = {
      //           PersonConditionID: condition.value,
      //           BookingPersonConditionID: added.id
      //         }
      //         conditionObject.push(condition)
      //       }
      //     });
      //   });
      // }
      var params = {
        ClientID: this.user.clientId,
        ClaimTypeID: this.singleCase.claimTypeId,
        ClaimTypeName: this.getClaimantTypeName(this.singleCase.claimTypeId),
        CaseOwner: this.singleCase.caseOwner,
        ClaimantFirstName: this.singleCase.claimantFirstName,
        ClaimantGivenNames: this.singleCase.claimantGivenNames,
        ClaimantSurname: this.singleCase.claimantSurname,
        // CommencementDate: moment(this.singleCase.commencementDate).format('YYYY-MM-DD'),
        DateofBirth: moment(this.singleCase.dateOfBirth).format('YYYY-MM-DD'),
        CaseReference: this.singleCase.caseReference,
        PreferredGivenName: this.singleCase.PreferredGivenName,
        Salutation: this.singleCase.Salutation,
        ClaimantPhone1: utilities.validatePhonenumber(this.singleCase.claimantPhone1),
        ClaimantPhone2: utilities.validatePhonenumber(this.singleCase.claimantPhone2),
        ClaimantMobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.singleCase.claimantMobile)),
        ClaimantEmail: this.singleCase.claimantEmail,
        ClaimantAddressLine1: this.singleCase.claimantAddressLine1,
        ClaimantAddressLine2: this.singleCase.claimantAddressLine2,
        ClaimantCity: this.singleCase.claimantCity,
        ClaimantState: this.singleCase.claimantState,
        ClaimantPostcode: this.singleCase.claimantPostcode,
        ClaimantCountry: 'AU',
        CaseStatus: this.singleCase.caseStatus,
        ClientZohoLink: this.client.zohoLink,
        ZohoClaimantID: this.singleCase.zohoClaimantId,
        // OnBehalfOf: this.singleCase.onBehalfOf,
        // AccidentDate: this.singleCase.accidentDate ? moment(this.singleCase.accidentDate).format('YYYY-MM-DD') : "",
        // OverPeriodofTime: utilities.convertNumToBool(this.singleCase.overPeriodofTime),
        // BodyAreaObject: bodyAreaObjects,
        // BodyAreaOther: otherBodyArea,
        // ConditionObject: conditionObject,
        UserID: this.user.id,
        Username: this.user.name,
      }

      if (this.client.zohoLink != '') {
        var zohoId = this.client.zohoLink.split("/")
        zohoId = zohoId[zohoId.length - 1]
        params.ClientZohoLink = zohoId
      }

      //pass clientContact details into the Zoho
      var contact = this.clientContacts.find((x) => x.userId == this.singleCase.caseOwner)
      if (contact != undefined) {
        params.CCFirstName = contact.firstName
        params.CCSurname = contact.surname
        params.CCPhone = contact.phoneNumber1
        params.CCEmail = contact.NotificationEmailAddress || contact.emailAddress
        params.CCZohoID = contact.zohoClientContactId
        params.BranchName = contact.branchName
      }

      if (this.singleCase.id == '' || this.singleCase.id == undefined) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var callback = (response) => {
          this.singleCase.id = response
          if (response !== undefined) {
            this.$refs.adminCaseCreatedModal.setCaseRefe(this.singleCase.caseReference)
            this.$refs.adminCaseCreatedModal.handleModal()
          }
        }
        params.CaseOwner = this.user.id
        await utilities.postCase(params, callback)
        utilities.clearChangingObject()
        this.$store.dispatch('loading/setLoadComponent', false)
      } else {
        params.CaseID = this.singleCase.id
        params.ZohoCaseID = this.singleCase.zohoCaseId
        this.$store.dispatch('loading/setLoadComponent', true)
        var callback = (response) => {
          this.singleCase.id = response
          if (response) {
            this.successMessage = 'case Updated Successfully.'
            utilities.showAlert('.alert-success')
          }
        }
        await utilities.putCase(params, callback)
        utilities.clearChangingObject()
        this.$store.dispatch('loading/setLoadComponent', false)
      }
    },

    setDetails() {
      if (this.singleCase.caseStatus == '') {
        this.singleCase.caseStatus = 1
      }
      if (this.singleCase.claimantPhone1 != '' || this.singleCase.claimantMobile != '' || this.singleCase.claimantPhone2 != '' || this.singleCase.claimantEmail != '') {
        this.isEditContact = true
      } else {
        this.isEditContact = false
      }

      if (this.singleCase.claimantAddressLine1 != '' || this.singleCase.claimantAddressLine2 != '' || this.singleCase.claimantCity != '') {
        this.isEditAddress = true
        this.singleCase.claimantCountry = ""
      } else {
        this.isEditAddress = false
      }

      if (this.singleCase.claimantFullName != "") {
        this.isEditName = true
      } else {
        this.isEditName = false
      }
    },

    openAddNameModal() {
      if (this.isEditName) {
        this.$refs.addNameModal.editFullName(this.singleCase.claimantFirstName, this.singleCase.claimantGivenNames, this.singleCase.claimantSurname)
      }
      this.$refs.addNameModal.handleModal();
    },

    openManageContactDetails() {
      this.$refs.addClientContactModal.singleCase = this.singleCase
      this.$refs.addClientContactModal.booking = this.booking
      this.$refs.addClientContactModal.setCaseContactDetails()
      this.$refs.addClientContactModal.handleModal()
    },

    openManageAddress() {
      this.$refs.addClientAddressModal.singleCase = this.singleCase
      this.$refs.addClientAddressModal.isClient = false
      this.$refs.addClientAddressModal.handleModal()
      this.$refs.addClientAddressModal.setCaseAddressDetails()
    },

    async deleteFile(fileId, index, uploadedBy) {
      var file = this.allBookingFiles[index]
      if (uploadedBy != this.user.id) {
        return
      }
      this.$confirm({
        content: 'Are you sure you want to delete this file?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          var booking = this.bookings.find((x) => x.bookingDetails.id == file.bookingId)
          var params = {
            userId: this.user.id,
            userName: this.user.name,
            id: fileId,
          }
          var params2 = {
            BookingID: file.bookingId,
            FileName: file.fileName,
            BookingDateTime: moment(booking.bookingDetails.dateTime).format('YYYY-MM-DD HH:mm:ss'),
            SpecialistName: booking.specialistDetails.fullName,
            CaseOwnerName: booking.clientContactDetails.fullName,
            ClientName: booking.clientDetails.clientName + ' - ' + booking.clientDetails.branchName,
            BookingTypeName: booking.bookingDetails.bookingTypeName,
            LocationName: booking.location.fullLocation,
            CurrentDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            UserID: this.user.id,
            Username: this.user.name,
          }
          var result = await utilities.deleteBookingFileById(params, params2)
          if (result) {
            this.allBookingFiles.splice(index, 1)
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    getLocationById(id) {
      var value = ''
      if (id) {
        value = this.locations.find((x) => x.id == id).fullLocation
      }
      return value
    },
    getFile(file) {
      const params = {
        uploadtype: "bf",
        fn: file.fileName,
        id: file.bookingId
      }
      utilities.getFileUrlNew(params).then(res => {
        let a = document.createElement('a');
        let fileName = file.fileName
        a.target = '_blank'
        a.download = fileName;
        a.href = res
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(res);
        document.body.removeChild(a);
      })
    },

    async loadData(caseId) {
      const apiArr = [utilities.getClientContacts(this.user.clientId), utilities.getCaseByCaseId(caseId), utilities.getLocations(), utilities.getBookingsByCaseId(caseId)]
      const r = await Promise.all(apiArr)
      this.clientContacts = r[0]
      this.singleCase = r[1]
      this.$store.dispatch("setOriginalObject", this.singleCase)
      this.$store.dispatch("setNewObject", this.singleCase)

      // if (this.singleCase.bodyAreas.length > 0) {
      //   this.addedBodyArea = _.cloneDeep(this.singleCase.bodyAreas)
      //   this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(this.singleCase.bodyAreas)
      // }
      //
      // if (this.singleCase.bodyAreaOther.length > 0) {
      //   var items = this.singleCase.bodyAreaOther.split(',');
      //   this.otherBodyAreaTags = utilities.getAutoCompleteOtherBodyAreaTags(items)
      // }
      //
      // if (this.singleCase.personCondition.length > 0) {
      //   this.addedConditions = _.cloneDeep(this.singleCase.personCondition)
      //   this.personConditionTags = utilities.getAutoCompleteTags(this.singleCase.personCondition)
      // }

      this.locations = r[2]
      this.bookings = r[3]
      this.bookings.forEach((booking, i) => {
        if (booking.bookingDetails.bookingFiles.length > 0) {
          booking.bookingDetails.bookingFiles.forEach((file, i) => {
            file.bookingId = booking.bookingDetails.id
            if (file.fileTypeId != 9 && file.fileTypeId != 8 && file.fileTypeId != 7) {
              this.allBookingFiles.push(file)
            } else if (file.fileTypeId == 8 && file.uploadedBy == this.user.id) {
              this.allBookingFiles.push(file)
            } else if (file.fileTypeId == 8 && file.fileStatus == 1 && file.uploadedBy != this.user.id) {
              this.allBookingFiles.push(file)
            } else if (file.fileTypeId == 7 && file.fileStatus == 2) {
              if (!booking.bookingDetails.prepaymentRequired) {
                this.allBookingFiles.push(file)
              } else if (booking.bookingDetails.prepaymentRequired && booking.bookingDetails.paid) {
                this.allBookingFiles.push(file)
              }
            }
          });
        }
      });
      // var bodyAreas = await utilities.getBodyArea()
      // this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)
      //
      // var personConditions = await utilities.getPersonConditions()
      // this.personConditions = utilities.getAutoCompleteTags(personConditions)
      this.setDetails()
    },
  },

  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    $('body>.tooltip').remove()
    // var params = {
    //   clientId: this.user.clientId,
    //   userId: this.user.id
    // }
    this.singleCase.id = this.$route.query.id !== undefined ? this.$route.query.id : ''
    if (this.singleCase.id !== '') {
      await this.loadData(this.singleCase.id)
      this.enableEditCase(true)
    } else {
      this.enableEditCase(false)
    }
    const apiArr = [utilities.getClientById(this.user.clientId), utilities.getClaimTypes()]
    const r = await Promise.all(apiArr)
    this.client = r[0]
    this.claimTypes = r[1]
    this.$store.dispatch('loading/setLoadComponent', false)
    this.loaded = true
    this.isValidDateOfBirth = this.validateDateOfBirth()
    // this.isValidAccidentDate = this.validateAccidentDate()

    $('.referance').keydown((e) => {
      var code = e.keyCode || e.which;
      if (code === 9) {
        this.openAddNameModal()
      }
    });

    $('.behalf').keydown((e) => {
      var code = e.keyCode || e.which;
      if (code === 9) {
        this.openAddNameModal()
      }
    });

    $('.date-of-birth').keydown((e) => {
      var code = e.keyCode || e.which;
      if (code === 9) {
        this.openManageContactDetails()
      }
    });

  },

  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
}
</script>
