<template>
  <div class="case-created-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Case Created</h1>
            </div>
            <button
              type="button"
              class="close-icon"
              @click="handleModal($event)"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body div-responsive" style="overflow:hidden">
            <div class="row">
              <div class="col-12" v-if="!clientProps">
                <p>
                  Case Ref: {{ caseReference }} has been created for
                  {{ clientName }}.
                </p>
                <p>You can now add a booking.</p>
              </div>
              <div class="col-12" v-else>
                <p >
                  Your case has been created for Ref: {{ caseReference }}.
                </p>
                </br>
                <p>You can now book an appointment for an independent medical assessment or other service.</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn class="white-button" @click="backToCases()" dark>Return To Cases</v-btn>
                   <v-btn @click="createBooking()" dark>Add Booking</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminCaseCreatedModal',
    props: ['clientProps'],
    data() {
      return {
        loadModal: false,
        caseReference: this.$parent.singleCase.caseReference,
        clientName: this.$parent.client != undefined ? this.$parent.client.name : '',
        caseId: this.$parent.singleCase.caseId,
        clientId: this.$parent.singleCase.clientId
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      setCaseRefe(reference) {
        this.caseReference = reference
      },

      backToCases() {
        this.handleModal()
        if (!this.clientProps) {
          this.$router.push({path:'/admin-cases', query:{id:this.clientId}}).catch((err) => {})
        } else {
          this.$router.push({path:'/cases'}).catch((err) => {})
        }
      },

      createBooking() {
        this.handleModal()
        if (!this.clientProps) {
          this.$router.push({path:'/admin-search-specialist', query:{id:this.$parent.singleCase.id}}).catch((err) => {})
        } else {
          this.$router.push({path:'/search-specialist', query:{id:this.$parent.singleCase.id}}).catch((err) => {})
        }
      },
    },
    beforeMount() {},
    mounted() {},
    updated() {},
  }
</script>
