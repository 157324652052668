/* eslint-disable */
<template>
  <div class="specialist-bookings-onday">
    <SpecialistNavigation indexProps="2" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Bookings</h1></div>
        <div class="header-sub-title">The following appointments have been arranged for you.</div>
      </div>
    </div>
    <div class="panel">
      <SpecialistBookingMenu indexProps="1" />
      <div class="content">
        <div class="download-section">
            <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>
        </div>
        <div class="box m-0">
          <div class="box-heading">
            <div class="row d-flex justify-content-between align-items-center m-0">
              <!-- Monday 22nd September, 2020 -->
              <span>{{ formattedHeadingDate }}</span>
              <div class="icon-group right-element">
                <div class="mr-2"><img data-toggle="tooltip" data-placement="right" title="Export appointments to PDF" src="@/assets/images/file_icon.svg" @click="exportPdf" /></div>
                <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  filename="getName"
                  :pdf-quality="2"
                  :manual-pagination="true"
                  :html-to-pdf-options="{...htmlToPdfOptions, filename: getName}"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="297mm"
                  ref="html2Pdf">
                  <section slot="pdf-content">
                    <SpecialistAvailabilityBookingPdf ref="specialistAvailabilityBookingPdf" pathProps="/specialist-booking-detail" />
                  </section>
                </vue-html2pdf>
                <div><img src="@/assets/images/calendar_black_icon.svg" @click="exportCSV()" data-toggle="tooltip" data-placement="right" title="Download Appointments as Calendar CSV" /></div>
                <download-csv
                  :data="calendarData"
                  :name="getFileNameCalendar()">
                  <div class="download-csv invisible-element"></div>
                </download-csv>
              </div>
            </div>
          </div>
          <div class="box-body">
            <div>
              <v-row class="fill-height">
                <v-col>
                  <v-sheet height="600">
                    <v-calendar
                      id="calendar"
                      ref="calendar"
                      color="primary"
                      type="day"
                      class="calendar-no-drag"
                      v-model="availabilityBooking.date"
                      :interval-height="anHourHeight"
                      :events="availabilityBooking.events"
                      :event-ripple="false">
                      <template v-slot:event="{ event }">
                        <div v-if="!event.bookingId" :id="event.item.AvailabilityID" class="fill-height border calendar-event v-event-draggable" :data="event.id">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="d-flex align-items-center ml-2">
                                <div v-if="event.item.Options.VideoOnly" class="video-icon"></div>
                                <div v-if="event.item.Options.VideoLinkAvailable" class="user-video-icon"></div>
                                <div v-if="!event.item.Options.VideoLinkAvailable && !event.item.Options.VideoOnly" class="face-icon"></div>
                                <div v-if="event.item.Options.ShowWorkCover" class="show-worker-icon"></div>
                                <div v-if="event.item.Options.ShowSpecificClients" class="specific-client-icon"></div>
                                <div v-if="event.item.Options.ByRequestOnly" class="request-icon"></div>
                              </div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.item.Location.LocationName)}}</div>
                            </div>
                            <div v-if="event.status === 'H'" class="text-white">On hold</div>
                          </div>
                        </div>
                        <div v-else class="calendar-event-booking-tooltip w-100 v-event-draggable" style="z-index: 100" data-placement="top" data-toggle="tooltip" data-html="true" :title="event.bookingDetail.detail">
                          <div class="d-flex justify-content-between" >
                            <div class="d-flex align-items-center">
                              <div class="text-white">{{event.startTime}} ~ {{event.endTime}}</div>
                              <div class="text-white ml-2">{{decodeURIComponent(event.name)}}</div>
                              <div class="event-icon right-element" @click="toBookingPage(event)"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                  <div class="box-col d-flex mt-5">
                    <div class="availability-item">
                      <div class="calender-indicator calender-available"></div>
                      <div class="indicator-text">Available</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-bookied"></div>
                      <div class="indicator-text">Booked</div>
                    </div>
                    <div class="availability-item">
                      <div class="calender-indicator calender-onhold"></div>
                      <div class="indicator-text">On hold</div>
                    </div>
                  </div>
                  <br />
                </v-col>
                <v-col cols="4">
                  <VerticalYearCalendar
                    :calendarDateProps="getYear(availabilityBooking.date)"
                    :dataSourceProps="dataSource"
                    :pathProps="'/specialist-bookings-day'"
                    :isAdminProps = "true"
                    :key="calendarKey"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
<!--        <div v-if="flattenedTravels.length > 0">-->
<!--          <div class="box">-->
<!--            <div class="box-heading">-->
<!--              <div class="box-row w-100 d-flex justify-content-between">-->
<!--                <span>Travel & Accommodation Details</span>-->
<!--                <div class="pull-right">-->
<!--                  <router-link-->
<!--                    :to="{-->
<!--                      path: '/specialist-travel-detail',-->
<!--                      query: {-->
<!--                        availabilityId:-->
<!--                          travelsToday[0].specialistAvailabilityId,-->
<!--                      },-->
<!--                    }"-->
<!--                  >-->
<!--                    <img-->
<!--                      class="img-small"-->
<!--                      src="@/assets/images/view_booking.svg"-->
<!--                      style="cursor: pointer"-->
<!--                    />-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="box-body">-->
<!--              <div class="box-row" v-for="(item, index) in flattenedTravels">-->
<!--                <div class="box-col">-->
<!--                  <div class="d-block" v-if="item.airlineName.length > 0">-->
<!--                    Flight {{ item.flightNumber }} Departing-->
<!--                    {{ item.departingAirportName }}-->
<!--                    {{-->
<!--                      formatTime(item.travelStartDate, item.travelStartTime)-->
<!--                    }}-->
<!--                    {{ formatDate(item.travelStartDate) }} Arriving-->
<!--                    {{ item.arrivingAirportName }} Seat:-->
<!--                    {{ item.seatNumber }} Booking Ref:-->
<!--                    {{ item.bookingReference }}-->
<!--                  </div>-->
<!--                  <div class="d-block" v-if="item.carHireName.length > 0">-->
<!--                    {{ item.carHireName }} Booking Ref:-->
<!--                    {{ item.bookingReference }} Pickup:-->
<!--                    {{ formatTime(item.travelStartDate, item.travelStartTime) }}-->
<!--                  </div>-->
<!--                  <div class="d-block" v-if="item.accommodationName.length > 0">-->
<!--                    {{ item.accommodationName }},-->
<!--                    {{ item.accommodationAddress }},-->
<!--                    {{ item.accommodationPhoneNumber }} Booking Ref:-->
<!--                    {{ item.bookingReference }} Check-in-->
<!--                    {{ formatTime(item.travelStartDate, item.travelStartTime) }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="box">-->
<!--            <div class="box-heading">-->
<!--              <span>Travel Receipts</span>-->
<!--            </div>-->
<!--            <div class="box-body my-3">-->
<!--              <div>-->
<!--                <div class="content-scroll-y file-list" style="height: auto; max-height: 460px">-->
<!--                  <table class="table remove-min-height">-->
<!--                    <tbody :key="fileKey">-->
<!--                      <tr-->
<!--                        v-for="(file, index) in travelReceipts"-->
<!--                        :key="index"-->
<!--                        class="py-0"-->
<!--                      >-->
<!--                        <td style="width: 100%" class="long-text py-0">-->
<!--                          <span class="is-icon" @click="getFile(file.invoiceFilePath, file.invoiceFileName)">{{ file.invoiceFileName }}</span>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                    </tbody>-->
<!--                  </table>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div-->
<!--                class="file-upload-area border-gray-300"-->
<!--                @dragover="dragover"-->
<!--                @dragleave="dragleave"-->
<!--                @drop="drop">-->
<!--                <input-->
<!--                  type="file"-->
<!--                  multiple-->
<!--                  name="fields[assetsFieldHandle][]"-->
<!--                  id="assetsFieldHandle"-->
<!--                  class="w-px h-px opacity-0 overflow-hidden absolute"-->
<!--                  @change="onChange"-->
<!--                  ref="file"-->
<!--                  accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV" />-->
<!--                <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center">-->
<!--                  <div>Drop files in this area or <span style="text-decoration: underline" class="is-icon">click here</span> to upload files.</div>-->
<!--                </label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="alert alert-success hidden-element">-->
<!--            <a class="close" title="close">×</a>-->
<!--            <b>Success:</b> Files uploaded successfully.-->
<!--          </div>-->
<!--          <div class="alert alert-danger hidden-element">-->
<!--            <a class="close" title="close">×</a>-->
<!--            <b>Error:</b> There are the errors. Please try again.-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <div class="box">-->
<!--            <div class="box-heading">-->
<!--              <span> Travel & Accommodation Details </span>-->
<!--            </div>-->
<!--            <div class="box-body">-->
<!--              <div class="box-col mt-5">-->
<!--                No travel items booked for {{ formatDate(this.displayDate) }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="box">
          <div class="box-heading">
            <span>General Information</span>
          </div>
          <div class="box-body px-2 py-1">
            <textarea v-model="availability.generalInformation" :disabled="true"></textarea>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import SpecialistNavigation from '@/components/SpecialistNavigation'
  import SpecialistBookingMenu from '@/components/SpecialistBookingMenu'
  import Header from '@/components/Header'
  import Chat from '@/components/Chat'
  import 'tippy.js/dist/tippy.css'
  import VerticalYearCalendar from '@/components/VerticalYearCalendar'
  import SpecialistAvailabilityBookingPdf from '@/components/SpecialistAvailabilityBookingPdf'

  export default {
    name: 'SpecialistBookingsOnDay',
    components: {
      SpecialistNavigation,
      SpecialistBookingMenu,
      Header,
      Chat,
      VerticalYearCalendar,
      SpecialistAvailabilityBookingPdf,
    },
    data() {
      return {
        errorMessage: '',
        bookingEvents: [],
        displayDate: '',
        availability: utilities.initAvailability(),
        availabilities: [],
        availabilityBooking: {
          date: '',
          events: [],
        },
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        bookingTypes: [],
        dataSource: [],
        calendarData: [utilities.initCalendarData()],
        deleteIcon: require('@/assets/images/delete_icon.svg'),

        dragEvent: null, // event
        dragTime: null, // timestamp
        createEvent: null,
        createStart: null,
        extendOriginal: null,

        travelsToday: [],
        flattenedTravels: [],
        travelReceipts: [],

        locations: [],
        anHourHeight: utilities.AN_HOUR_HEIGHT,
        fileKey: 0,
        calendarKey: 0,

        tab: 'notes',

        htmlToPdfOptions: {
          margin: 0,
          image: {
            type: 'jpeg',
            quality: 0.98
          },
          enableLinks: true,
          html2canvas: {
            scale: 2,
            useCORS: true
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'landscape'
          }
        },
      }
    },
    computed: {
      getName() {
        if(this.user.name && this.availabilityBooking?.date) {
          return `Red Health Schedule of Assessments - ${this.user.name} - ${this.availabilityBooking?.date}`
        }
        return 'Red Health Schedule of Assessments'
      },
      formattedHeadingDate() {
        return moment(this.displayDate, "YYYY-MM-DD").format('dddd Do MMMM, YYYY')
      },
      getLocation() {
        return this.availabilities.find(x=>x.availabilityDate==this.displayDate) != undefined ? this.availabilities.find(x=>x.availabilityDate==this.displayDate).fullLocation : ""
      }
    },
    methods: {
      toBookingPage(item) {
        this.$router.push({ name: "specialist-booking-detail", query: {id: item.bookingId} }).catch((err) => {})
      },
      async exportPdf() {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.$refs.specialistAvailabilityBookingPdf.specialist.id = this.user.specialistId
        await this.$refs.specialistAvailabilityBookingPdf.loadData(this.displayDate)
        if (this.$refs.specialistAvailabilityBookingPdf.bookings.length > 0) {
          setTimeout(async () => {
            this.$refs.html2Pdf.generatePdf()
            await this.logThis("Specialist downloaded pdf of " + this.availabilityBooking.date)
            this.$store.dispatch('loading/setLoadComponent', false)
          }, 1000)
        } else {
          this.errorMessage = 'No bookings found for this day.'
          utilities.showAlert('.download-section .alert-danger')
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },
      async exportCSV() {
        this.$store.dispatch('loading/setLoadComponent', true)
        if(this.calendarData.length > 0) {
          $(".download-csv").click()
          await this.logThis("Specialist downloaded schedule of " + this.availabilityBooking.date)
        } else {
          this.errorMessage = 'No bookings found for this day.'
          utilities.showAlert('.download-section .alert-danger')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      routeToDetails(availabilityId) {
        this.$router.push({ path: '/specialist-travel-detail', query: { availabilityId: availabilityId } }).catch((err) => {})
      },
      setViewStatus(index) {
        $('.box .box-body').fadeOut(400, function() {
          if ($('.box .box-body').is(':visible')) {
            $('.box .box-body')
              .eq(index)
              .fadeOut()
            $('.box')
              .eq(index)
              .css({ 'min-height': '0vh' })
          } else {
            $('.box .box-body')
              .eq(index)
              .fadeIn()
          }
        })
      },
      getFileNameCalendar() {
        return 'Red Health Bookings - ' + moment(this.displayDate, "YYYY-MM-DD").format('DD-MM-YYYY') + '.csv'
      },
      getFile(filePath, fileName) {
        utilities.getFile(filePath, fileName)
      },
      async onChange() {
        this.$store.dispatch('loading/setLoadComponent', true)
        // var params = {
        //   SpecialistTravelItineraryID: this.travelsToday[0].specialistTravelItineraryId,
        //   FileObject: []
        // }
        var params = {}
        this.filelist = [...this.$refs.file.files]
        if(this.filelist.length > 0) {
          await Promise.all(this.filelist.map(async (x) => {
            if(this.travelsToday[0] != undefined && this.travelsToday[0].specialistTravelItineraryId != '') {
              var fileName = moment().unix() + '_' + x.name.replace(/\s/g, '_')
              await new Promise((resolve, reject) => {
                var reader = new FileReader()
                reader.onloadend = async () => {
                  // params.FileObject.push({
                  //   SpecialistTravelItineraryID: this.travelsToday[0].specialistTravelItineraryId,
                  //   InvoiceFileName: fileName,
                  //   InvoiceFileBinary: reader.result,
                  //   DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                  //   UploadedBy: this.user.id,
                  //   MimeType: x.type,
                  //   AvailabilityID: this.travelsToday[0].specialistAvailabilityId,
                  //   SpecialistID: this.user.specialistId,
                  // })
                  params = {
                    SpecialistTravelItineraryID: this.travelsToday[0].specialistTravelItineraryId,
                    InvoiceFileName: fileName,
                    InvoiceFileBinary: reader.result,
                    DateUploaded: moment().format('YYYY-MM-DD HH:mm:ss'),
                    UploadedBy: this.user.id,
                    MimeType: x.type,
                    AvailabilityID: this.travelsToday[0].specialistAvailabilityId,
                    SpecialistID: this.user.specialistId,
                    UserID: this.user.id,
                    Username: this.user.name,
                  }
                  var callback = (response) => {}
                  await utilities.postTravelReceipt(params, callback)
                  resolve(true)
                }
                reader.onerror = (error) => {
                  reject(error)
                }
                reader.readAsBinaryString(x)
              })
            }
          }))
          // if(params.FileObject.length > 0) {
          //   var callback = (response) => {}
          //   var result = await utilities.postTravelReceipt(params, callback)
          //   if(result) {
          //     await this.loadData()
          //     this.fileKey += 1
          //   }
          // }
          await this.loadData()
          this.fileKey += 1
          utilities.showAlert(".alert-success")
          setTimeout(() => {
            $(".file-list").animate({ scrollTop: $(".file-list")[0].scrollHeight }, 'slow')
          }, 200)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      remove(i) {
        this.filelist.splice(i, 1)
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //   event.currentTarget.classList.remove('bg-gray-100')
        //   event.currentTarget.classList.add('bg-green-300')
        // }
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        // event.currentTarget.classList.add('bg-gray-100')
        // event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },

      formatDate(date) {
        return moment(date, "YYYY-MM-DD").format('DD/MM/YYYY')
      },
      formatTime(date, time) {
        return moment(date + ' ' + time, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A')
      },
      getYear(date) {
        return moment(date, "YYYY-MM-DD").format('YYYY')
      },
      getFlattenedTravels() {
        var travels = []
        if (this.travelsToday.length > 0) {
          this.travelsToday.map((x) => {
            travels.push(x.specialistTravelItems)
          })
        }
        travels = travels.flat()
        travels = _.uniqWith(travels,_.isEqual)
        return travels
      },
      async loadData() {
        this.displayDate = this.$route.query.date != undefined ? window.moment(this.$route.query.date, "YYYY-MM-DD").format('YYYY-MM-DD') : window.moment().format('YYYY-MM-DD')
        this.availabilityBooking.date = this.displayDate
        this.bookingEvents = []
        const apiArr = [utilities.getBookingTypes(), utilities.getSpecialistBookings({ specialistId: this.user.specialistId, startDate: this.displayDate, endDate: this.displayDate }), utilities.getLocations(), utilities.getSpecialistAvailabilities(this.user.specialistId)]
        const r = await Promise.all(apiArr)
        this.bookingTypes = r[0]
        this.$store.dispatch('loading/setLoadComponent', true)
        var bookings = r[1]
        this.locations = r[2]
        this.availabilities = r[3]
        let tempArray = []
        for (let i = 0; i < bookings.length; i++) {
          let booking =  bookings[i]
          if (booking.bookingDetails.typeId != 6 && booking.bookingDetails.typeId != 8) {
            if (booking.bookingDetails.status != 1 && booking.bookingDetails.status != 6 && booking.bookingDetails.status != 16) {
              tempArray.push(booking)
            }
          }
        }
        bookings = _.cloneDeep(tempArray)
        bookings.map(x => {
          this.bookingEvents.push(utilities.loadBooking(x))
        })
        const availability = this.availabilities.find(x => window.moment(x.date).isSame(window.moment(this.displayDate)))
        if(!availability) {
          this.dataSource = this.availabilities
          this.calendarKey += 1
          this.$store.dispatch('loading/setLoadComponent', false)
          return
        }
        this.bookingEvents.forEach(e => {
          const item = availability.Availabilities.find(item => item.AvailabilityID === e.availabilityId)
          item.booking = e
        })
        const arr = []
          availability.Availabilities.forEach(item => {
          const year = window.moment(availability.availabilityDate).format('YYYY-MM-DD')
            if(item.AvailabilityStatus !== '1') {
              let obj = {
                name: item?.booking?.fullLocation || item?.Location?.LocationName,
                start: window.moment(year + ' ' + item.StartTime).format('YYYY-MM-DD HH:mm'),
                end: window.moment(year + ' ' + item.EndTime).format('YYYY-MM-DD HH:mm'),
                startTime: window.moment(item.StartTime, 'HH:mm:ss').format('hh:mm A'),
                endTime: window.moment(item.EndTime, 'HH:mm:ss').format('hh:mm A'),
                color: utilities.getAvailabilityColors(item),
                timed: true,
                booking: item.booking,
                status: item.BookingStatus,
                item: item
              }
              arr.push(obj)
            }
          console.log(item)
        })
        this.bookingEvents.forEach(item => {
          const obj = {
            bookingId: item.id,
            className: 'booking-event',
            name: item?.fullLocation,
            start: window.moment(item.start).format('YYYY-MM-DD HH:mm'),
            end: window.moment(item.end).format('YYYY-MM-DD HH:mm'),
            startTime: window.moment(item.start).format('hh:mm A'),
            endTime: window.moment(item.end).format('hh:mm A'),
            color: '#0080ff',
            timed: true,
            bookingDetail: item
          }
          arr.push(obj)
        })
        this.availabilityBooking.events = arr
        var calendarDateAvailabilities = this.availabilities.filter(x => x.availabilityDate == this.displayDate).sort((a, b) => {
          return moment(a.availabilityDate + " " + a.startTime, "YYYY-MM-DD HH:mm:ss.ssssss").isAfter(moment(b.availabilityDate + " " + b.startTime, "YYYY-MM-DD HH:mm:ss.ssssss")) ? 1 : -1
        })
        if(calendarDateAvailabilities.length > 0) {
          this.availability = calendarDateAvailabilities[0]
        }
        this.travelsToday = await utilities.getSpecialistTravelItinerary({ specialistId: this.user.specialistId, date: moment(this.displayDate, "YYYY-MM-DD").format('YYYY-MM-DD') })
        this.travelsToday = _.uniqBy(this.travelsToday, 'specialistTravelItineraryId')
        var toRemove = []
        this.travelsToday.forEach((x,i)=>{
          var flag = false
          x.specialistTravelItems.forEach(y=>{
            if(moment(y.travelStartDate, "YYYY-MM-DD").isSame(this.displayDate,'d') || moment(y.travelEndDate, "YYYY-MM-DD").isSame(this.displayDate,'d'))
            flag = true
          })
          if(!flag) toRemove.push(i)
        })
        toRemove = toRemove.sort((a,b) => {return b-a})
        toRemove.forEach(x=>{
          this.travelsToday.splice(x,1)
        })
        this.dataSource = this.availabilities
        this.calendarKey += 1
        this.flattenedTravels = this.getFlattenedTravels()
        let arrFilter = this.availabilityBooking.events.filter(item => item.booking).map(x => {
          return {
            ...x.booking,
            location: x.booking.fullLocation,
            detail: x.booking.detail += '<p><strong>Location:</strong> ' + x.booking.fullLocation + '</p>',
            shortDetail: x.booking.shortDetail += ' Location: ' + x.booking.fullLocation
          }
        })
        this.calendarData = utilities.formatEventsToCalendarEvents(arrFilter)

        for (var i = 0; i < this.flattenedTravels.length; i++) {
          var item = this.flattenedTravels[i]
          item.travelStartDateTime = moment(item.travelStartDate + ' ' + item.travelStartTime, "YYYY-MM-DD HH:mm:ss.SSSSSS").format('YYYY-MM-DD HH:mm:ss')
        }

        this.flattenedTravels.sort((a,b) => {
          return moment(a.travelStartDateTime, "YYYY-MM-DD HH:mm:ss").isAfter(moment(b.travelStartDateTime, "YYYY-MM-DD HH:mm:ss")) ? 1:-1
        })


        if (this.flattenedTravels.length > 0) {
          this.travelReceipts = await utilities.getSpecialistTravelReceipts(this.travelsToday[0].specialistTravelItineraryId)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      displayCalendarIcon(event) {
        if (event.type == "FCE Lite" || event.type == "FCE Lite by Video") {
          return false
        }
        return moment(event.start, "YYYY-MM-DD HH:mm").isBefore(moment().add(2,'w'), 'day')
      },
      async logThis(logDetails) {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistID: this.user.specialistId,
          LogDetails: logDetails,
          LogDate: moment().format("YYYY-MM-DD HH:mm:ss")
        }
        await utilities.postRequestChangeLog(params)
      }
    },

    async beforeMount() {
      await this.loadData()
      $('[data-toggle="tooltip"]').tooltip({template: '<div class="tooltip calendar-event"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'})
      // Scroll down to the appointment
      $(".v-calendar-daily__scroll-area").animate({ scrollTop: ($(".v-calendar-daily__scroll-area").height() / 3 * 2) }, 'slow')
      // Scroll down to the current month
      $(".vertical-year-calendar .months-container").animate({ scrollTop: $(".vertical-year-calendar .months-container .month-container").eq(Number(moment().format("MM")) - 1).offset().top }, 'slow')
    },
    mounted() {},
    updated() {},
  }
</script>
