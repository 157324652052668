/* eslint-disable */
<template>
  <div class="home admin-home">
    <AdminNavigation ref="navigation" indexProps="0" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Welcome to Red Health Online Admin</h1></div>
        <div class="header-sub-title">Your dashboard for all booking related activities.</div>
      </div>
    </div>
    <div class="panel">
      <AdminHomeMenu indexProps="0" :dataProps="adminDashboard" :key="updatedKey"/>
      <div class="content">
        <div class="tool-bar cards">
          <div class="cards-item">
            <router-link :to="{ name: 'admin-booking-requests', params: {} }">
              <div class="cards-item-number">{{ bookingRequestNumber }}</div>
              <div class="cards-item-title">
                <div class="cards-item-title-text">Booking Requests</div>
                <div class="small-rounded-image"><img src="@/assets/images/booking_request_black_icon.svg" /></div>
              </div>
            </router-link>
          </div>
          <div class="cards-item">
            <router-link :to="{ name: 'admin-availability-require-travel', params: {} }">
              <div class="cards-item-number">{{ requireTravelNumber }}</div>
              <div class="cards-item-title">
                <div class="cards-item-title-text">Require Travel</div>
                <div class="small-rounded-image"><img src="@/assets/images/travel_black_icon.svg" /></div>
              </div>
            </router-link>
          </div>
          <div class="cards-item">
            <router-link :to="{ name: 'admin-bookings-requiring-action', params: {} }">
              <div class="cards-item-number">{{ requireActionNumber }}</div>
              <div class="cards-item-title">
                <div class="cards-item-title-text">Action Required</div>
                <div class="small-rounded-image right-float-element"><img src="@/assets/images/booking_action_black_icon.svg" /></div>
              </div>
            </router-link>
          </div>
          <div class="cards-item">
            <router-link :to="{ name: 'admin-booking-clashes', params: {} }">
              <div class="cards-item-number">{{ bookingClashNumber }}</div>
              <div class="cards-item-title">
                <div class="cards-item-title-text">Booking Clash</div>
                <div class="small-rounded-image right-float-element"><img src="@/assets/images/booking_clash_black_icon.svg" /></div>
              </div>
            </router-link>
          </div>
          <div class="cards-item">
            <router-link :to="{ name: 'admin-bookings-cancelled', params: {} }">
              <div class="cards-item-number">{{ bookingCancelNumber }}</div>
              <div class="cards-item-title">
                <div class="cards-item-title-text">Booking Cancellations </div>
                <div class="small-rounded-image right-float-element"><img style="width: 24px" src="@/assets/images/booking_cancel_icon.svg" /></div>
              </div>
            </router-link>
          </div>
        </div>
        <br />
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 20%" class="long-text">Recent Searches for Specialists</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div>
              <table class="table">
                <tbody>
                  <tr v-for="(searchLog, index) in searchLogs" class="is-icon" @click="viewAll">
                    <td style="width: 20%" class="long-text">
                      {{ formatDate(searchLog.dateSearched, "DD/MM/YYYY hh:mm A", 'YYYY-MM-DD HH:mm:ss.ssssss') }}
                    </td>
                    <td class="long-text">
                      {{ addComma(searchLog.actionedByName) + addComma(searchLog.clientName) + addComma(searchLog.specialist) + addComma(searchLog.qualificationList) + addComma(searchLog.cityList) + addComma(searchLog.regionName) + formatDate(searchLog.dateStart, "DD/MM/YYYY", "") + ' - ' + formatDate(searchLog.dateEnd, "DD/MM/YYYY", "") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />

        <div class="icon-group">
          <div class="w-100">
            <v-btn class="right-float-element" dark @click="viewAll">View All</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminHomeMenu from '@/components/AdminHomeMenu'
import Header from '@/components/Header'

export default {
  name: 'AdminHome',
  components: {
    AdminNavigation,
    AdminHomeMenu,
    Header,
  },
  data() {
    return {
      bookingRequestNumber: 0,
      requireTravelNumber: 0,
      requireActionNumber: 0,
      bookingClashNumber: 0,
      bookingCancelNumber: 0,
      searchLogs: [],
      adminDashboard: {},
      updatedKey: 0,
    }
  },
  methods: {
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    addComma(text) {
      return utilities.addComma(text)
    },
    viewAll() {
      this.$router.push({ name: 'admin-view-search-log', params: {} })
    },

    async loadData() {
      var dashboardData = await utilities.getDashboardData()
      this.adminDashboard = dashboardData.adminDashboard
      this.bookingRequestNumber = this.adminDashboard.countBookingRequests
      this.requireTravelNumber = this.adminDashboard.countSpecialistAvailabiltyRequiringTravel
      this.requireActionNumber = this.adminDashboard.countBookingActionRequests
      this.bookingClashNumber = this.adminDashboard.countBookingClashes
      this.bookingCancelNumber = this.adminDashboard.countBookingsCancelled
      this.searchLogs = _.cloneDeep(dashboardData.searchLog.filter(x => x.clientId != ''))
      this.updatedKey += 1
    },

  },
  async beforeMount() {
    $('.zsiq_floatmain').hide()
    this.$store.dispatch("loading/setLoadComponent", true)
    await this.loadData()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {},
}
</script>
