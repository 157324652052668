/* eslint-disable */
<template>
  <div class="booking-clashes">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Booking Clashes</h1></div>
        <div class="header-sub-title">The following bookings are for the same claimant and specialist potentially for both sides of the matter.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu indexProps="5" />
      <div class="content">
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input style="max-width: 200px" class="mr-1" clearable placeholder="Client" @input="search()" v-model="listParam.ClientName" />
            <el-input style="max-width: 200px" clearable class="mr-1" placeholder="Claimant Name" @input="search()" v-model="listParam.ClaimantName" />
            <el-input style="max-width: 200px" clearable class="mr-1" placeholder="Specialist Name" @input="search()" v-model="listParam.SpecialistName" />
            <el-select style="max-width: 200px" class="mr-1" placeholder="Status" @change="search()" clearable v-model="listParam.Status" >
              <el-option :value="1" label="New"></el-option>
              <el-option :value="2" label="In Progress"></el-option>
            </el-select>
          </div>
        </div>
        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 10%" class="long-text">
                      Claimant<img v-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)" />
                      <img v-else-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(1)"   />
                    </th>
                    <th style="width: 13%" class="long-text border-right">
                      Specialist<img v-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(4)" />
                      <img v-else-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(3)" />
                    </th>
                    <th style="width: 12%" class="long-text">Client 1</th>
                    <th style="width: 9%" class="long-text">Booking ID</th>
                    <th style="width: 12%" class="long-text border-right">Booking Status</th>
                    <th style="width: 12%" class="long-text">Client 2</th>
                    <th style="width: 9%" class="long-text">Booking ID</th>
                    <th style="width: 12%" class="long-text border-right">Booking Status</th>
                    <th style="width: 11%" class="long-text">
                      Status<img v-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)" />
                      <img v-else-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(5)" />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="allBookings.length > 0">
              <table class="table">
                <tbody>
                  <tr v-for="(booking, index) in bookings" :key="booking.id" class="is-icon">
                    <td style="width: 10%" class="long-text">
                      <el-tooltip  effect="dark" :content="booking.claimantFullName" placement="bottom">
                      <span>{{ booking.claimantFullName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 13%" class="long-text border-right">
                      <router-link :to="{ name: 'admin-manage-specialist', query: { id: booking.specialistId } }">
                        <el-tooltip  effect="dark" :content="booking.fullName" placement="bottom">
                        <span>{{ booking.fullName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 12%" @click="openBooking(booking.bookingId)" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clientId } }">
                        <el-tooltip  effect="dark" :content="booking.clientBranchName" placement="bottom">
                        <span>{{ booking.clientBranchName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 9%" @click="openBooking(booking.bookingId)" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.bookingId } }">
                        <span>{{ booking.bookingId }}</span>
                      </router-link>
                    </td>
                    <td style="width: 12%" @click="openBooking(booking.bookingId)" class="long-text border-right">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.bookingId } }">
                        <span>{{ getBookingStatus(booking.bookingStatus) }}</span>
                      </router-link>
                    </td>
                    <td style="width: 12%" @click="openBooking(booking.clashingBookingId)" class="long-text">
                      <router-link :to="{ name: 'admin-manage-client', query: { id: booking.clashingClientId } }">
                        <el-tooltip  effect="dark" :content="booking.clashingClientName" placement="bottom">
                        <span>{{ booking.clashingClientName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 9%" @click="openBooking(booking.clashingBookingId)" class="long-text">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.clashingBookingId } }">
                        <span>{{ booking.clashingBookingId }}</span>
                      </router-link>
                    </td>
                    <td style="width: 12%" @click="openBooking(booking.bookingId)" class="long-text border-right">
                      <router-link :to="{ name: 'admin-manage-booking', query: { id: booking.bookingId } }">
                        <span>{{ getBookingStatus(booking.clashBookingStatus)}}</span>
                      </router-link>
                    </td>
                    <td style="width: 11%" class="long-text">
                      <select class="w-100 textbox" v-model="booking.status" @change="changeBookingClashStatus(index)">
                        <option hidden value="">Please choose ...</option>
                        <option v-for="(bookingClash, index) in bookingClashStatuses" :key="index" :value="bookingClash.id">{{ bookingClash.name }}</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminBookingsMenu from '@/components/AdminBookingsMenu'
  import Header from '@/components/Header'
  import { debounce } from 'lodash';

  export default {
    name: 'AdminBookingClashes',
    components: {
      AdminNavigation,
      AdminBookingsMenu,
      Header,
    },
    data() {
      return {
        fetchedData: false,
        successMessage: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        bookings: [],
        allBookings: [],
        limit: 10,
        pagination: utilities.initPagination(),

        byClaimant: false,
        bySpecialist: false,
        byStatus: false,

        bookingClashStatuses: [],
        totalCount: 0,
        listParam: {
          PageSize: 10,
          PageNum: 1,
          SortFlag: 0,
          SpecialistName: '',
          ClaimantName: '',
          ClientName: '',
          Status: null
        },
      }
    },
    computed: {},
    methods: {
      getBookingStatus(status) {
        return utilities.getBookingStatus(status)
      },
      selectPage(index) {
        this.listParam.PageNum = index
        this.loadBookingClashes()
      },
      sortParam(v) {
        this.listParam.SortFlag = v
        this.selectPage(1)
      },
      search: debounce(function () {
        this.selectPage(1)
      }, 800),

      openBooking(id) {
        this.$router.push({ name: 'admin-manage-booking', query: { id: id } })
      },
      async changeBookingClashStatus(index) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var result = await this.putBookingClash(index)
        if (result) {
          this.successMessage = 'Booking clash updated successfully.'
          utilities.showAlert('.alert-success')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      putBookingClash(index) {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          BookingClashID: this.bookings[index].id,
          Status: Number(this.bookings[index].status),
        }
        var callback = (response) => {}
        return utilities.putBookingClash(params, callback)
      },

      async loadBookingClashes() {
        this.$store.dispatch('loading/setLoadComponent', true)
        var {data, total} = await utilities.getBookingClashes(this.listParam)
        this.allBookings = _.cloneDeep(data)
        this.bookings = _.cloneDeep(data)
        this.totalCount = total
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async loadData() {
        this.bookingClashStatuses = utilities.getBookingClashStatuses()
      },
    },
    async beforeMount() {
      $('body>.tooltip').remove()
      await this.loadBookingClashes()
      await this.loadData()
      this.fetchedData = true
    },
    mounted() {
      $(".pagination").hide()
    },
  }
</script>
