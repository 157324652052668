/* eslint-disable */
<template>
  <div class="purchase-orders">
    <AdminNavigation ref="navigation" indexProps="7" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Awaiting Financial Approval</h1></div>
        <div class="header-sub-title">The report for the following bookings have been completed, but an invoice has not yet been generated.</div>
      </div>
    </div>
    <div class="panel">
      <AdminFinanceMenu indexProps="0" />
      <div class="content">
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 10%" class="long-text">
                      Booking ID<img class="sort-icon" :src="!byId ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortId" />
                    </th>
                    <th style="width: 20%" class="long-text">
                      Specialist<img class="sort-icon" :src="!bySpecialist ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortSpecialist" />
                    </th>
                    <th style="width: 25%" class="long-text">
                      Client<img class="sort-icon" :src="!byClient ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortClient" />
                    </th>
                    <th style="width: 20%" class="long-text">
                      Claimant<img class="sort-icon" :src="!byClaimant ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortClaimant" />
                    </th>
                    <th style="width: 10%" class="long-text">
                      Draft Total<img class="sort-icon" :src="!byDraftTotal ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDraftTotal" />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Date Completed<img class="sort-icon" :src="!byDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDate" />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div :class="bookings.length > 5 ? 'content-scroll-y' : ''">
              <table class="table">
                <tbody>
                  <tr v-for="(booking, index) in bookings" class="is-icon">
                    <td style="width: 10%" class="long-text" @click="openPurchaseOrder(booking.id)">
                      {{ booking.id }}
                    </td>
                    <td style="width: 20%" class="long-text" @click="openPurchaseOrder(booking.id)">
                      {{ booking.fullName }}
                    </td>
                    <td style="width: 25%" class="long-text" @click="openPurchaseOrder(booking.id)">
                      {{ booking.clientName }}
                    </td>
                    <td style="width: 20%" class="long-text" @click="openPurchaseOrder(booking.id)">
                      {{ booking.claimantFullName }}
                    </td>
                    <td style="width: 10%" @click="openPurchaseOrder(booking.id)">${{ booking.total.toFixed(2) }}</td>
                    <td style="width: 15%" @click="openPurchaseOrder(booking.id)">
                      {{ booking.dateReportCompleted != '' ? formatDate(booking.dateReportCompleted) : '' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminFinanceMenu from '@/components/AdminFinanceMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminPurchaseOrders',
    components: {
      AdminNavigation,
      AdminFinanceMenu,
      Header,
    },
    data() {
      return {
        bookings: [],
        allBookings: [],
        limit: 10,
        pagination: utilities.initPagination(),

        bySpecialist: false,
        byClient: false,
        byClaimant: false,
        byDraftTotal: false,
        byDate: false,
        byId: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
      }
    },
    computed: {},
    methods: {
      selectPage(index) {
        this.pagination.current = index
        this.bookings = this.pagination.list[this.pagination.current]
      },

      sortId() {
        this.allBookings.sort((a, b) => {
          if (this.byId) {
            // ASC
            return a.id - b.id
          } else {
            // DESC
            return -1 * (a.id - b.id)
          }
        })
        this.paging(this.allBookings)
        this.byId = !this.byId
      },
      sortSpecialist() {
        this.allBookings.sort((a, b) => {
          if (this.bySpecialist) {
            // ASC
            return a.fullName > b.fullName ? 1 : -1
          } else {
            // DESC
            return a.fullName < b.fullName ? 1 : -1
          }
        })
        this.paging(this.allBookings)
        this.bySpecialist = !this.bySpecialist
      },
      sortClient() {
        this.allBookings.sort((a, b) => {
          if (this.byClient) {
            // ASC
            return a.clientName > b.clientName ? 1 : -1
          } else {
            // DESC
            return a.clientName < b.clientName ? 1 : -1
          }
        })
        this.paging(this.allBookings)
        this.byClient = !this.byClient
      },
      sortClaimant() {
        this.allBookings.sort((a, b) => {
          if (this.byClient) {
            // ASC
            return a.claimantFullName > b.claimantFullName ? 1 : -1
          } else {
            // DESC
            return a.claimantFullName < b.claimantFullName ? 1 : -1
          }
        })
        this.paging(this.allBookings)
        this.byClient = !this.byClient
      },
      sortDraftTotal() {
        this.allBookings.sort((a, b) => {
          if (this.byDraftTotal) {
            // ASC
            return a.total > b.total ? 1 : -1
          } else {
            // DESC
            return a.total < b.total ? 1 : -1
          }
        })
        this.paging(this.allBookings)
        this.byDraftTotal = !this.byDraftTotal
      },
      sortDate() {
        this.allBookings.sort((a, b) => {
          if (this.byDate) {
            // ASC
            return moment(a.dateReportCompleted) > moment(b.dateReportCompleted) ? 1 : -1
          } else {
            // DESC
            return moment(a.dateReportCompleted) < moment(b.dateReportCompleted) ? 1 : -1
          }
        })
        this.paging(this.allBookings)
        this.byDate = !this.byDate
      },
      formatDate(dateToFormat) {
        return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
      },
      openPurchaseOrder(id) {
        this.$router.push({ name: 'admin-manage-purchase-order', query: { id: id } })
      },
      paging(bookings) {
        utilities.disposeTooltip()
        if (bookings.length == 0 || bookings.length <= this.limit) {
          this.bookings = bookings
          $('.pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(bookings, this.limit)
          this.bookings = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
        utilities.initTooltip()
      },

      async loadBookings() {
        var bookings = await utilities.getBookingsAwaitingApproval()
        this.allBookings = _.cloneDeep(bookings)
        this.paging(bookings)
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      if (this.user.type != 4 && this.user.type != 6) {
        this.$router.go(-1)
      } else {
        await this.loadBookings()
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $('.pagination').hide()
    },
  }
</script>
