/* eslint-disable */
<template>
<div>
    {{zohoContent}}
</div>
</template>

<script>
import auth from '@/assets/js/cognitoAuth'
import utilities from '@/assets/js/utilities'
import Footer from '@/components/Footer'
import Chat from '@/components/Chat'

export default {
  name: 'Zoho',
  components: {
    Footer,
    Chat,
  },
  data() {
    return {
      zohoContent:'',

    }
  },
  methods: {

  },
  async beforeMount() {
      var bodyFormData = new FormData();
    bodyFormData.append('grant_type', 'authorization_code');
    bodyFormData.append('client_id', '1000.QFEC0QM291LZWNOWMXW92EW0O54J1V');
    bodyFormData.append('client_secret', '965e7b95cafada91243754802c1a3cb75d0113ab92');
    bodyFormData.append('code', '1000.cab733e39f0f2aaf267b8ea4da4dfab5.28180634cd6a3254f7fddca39b2638e0');
      
      this.zohoContent=await utilities.getZohoAccess(bodyFormData)
  },
}
</script>
