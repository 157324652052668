<template>
  <div class="manage-billing-category-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>{{ isEdit ? 'Edit Billing Category' : 'Add Billing Category' }}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                Billing Category Name
                <input
                  type="text"
                  v-model="billingCategory.name"
                  class="textbox border-0"
                  placeholder="Enter unique billing category name"
                  style="padding-left:10px;"
                  :class="hasError && !validName ? 'is-invalid' : ''"
                />
                <div class="text-danger" v-if="hasError && !validName">Please enter a category name</div>
              </div>
              <div class="form-group" v-if="!isEdit">
                Set Specialist Fees as per this Billing Category
                <select v-model="billingCategory.id">
                  <option value="" hidden>Select ...</option>
                  <option v-for="(category, index) in allBillingCategory" :value="category.id">{{ category.name }}</option>
                </select>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                    <v-btn @click="callSave" dark>Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'

  export default {
    name: 'AdminManageBillingCategoryModal',
    props: ['billingCategoryProps'],
    data() {
      return {
        loadModal: false,
        isEdit: false,
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        allBillingCategory: [],
        billingCategory: {
          id: '',
          name: ''
        },
        selectedCategory: {},
        loaded: false,
        hasError: false,
      }
    },
    computed: {
      validName() {
        return this.billingCategory.name != ''
      },
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },

      editBillingCategory(item) {
        if (item.id == '') {
          this.isEdit = false
        } else {
          this.isEdit = true
        }
        this.billingCategory = item
      },

      async callSave() {
        if (!this.validName) {
          this.hasError = true
          return
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        if (!this.isEdit) {
          var params = {
            BillingCategoryName: this.billingCategory.name,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var postSuccess = await utilities.postBillingCategory(params)
          if (postSuccess) {
            if (this.billingCategory.id) {
              var newParams = {
                NewBCID: postSuccess,
                OldBCID: this.billingCategory.id,
                UserID: this.user.id,
                Username: this.user.name,
              }
              await utilities.postReplicatingBillingCategory(newParams)
            }
            this.$parent.loadData()
            this.$parent.successMessage = 'New category successfully added.'
            utilities.showAlert('.alert-success')
            this.handleModal()
          }
        } else {
          var params = {
            BillingCategoryName: this.billingCategory.name,
            BillingCategoryID: this.billingCategory.id,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var putSuccess = await utilities.putBillingCategory(params)
          if (putSuccess) {
            this.$parent.loadData()
            this.$parent.successMessage = 'Category updated successfully.'
            utilities.showAlert('.alert-success')
            this.handleModal()
          }
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
    },
    mounted() {},
    updated() {
      if (!this.loaded) {
        this.allBillingCategory = _.cloneDeep(this.billingCategoryProps)
        this.loaded = true
      }
    },
  }
</script>
