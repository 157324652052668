/* eslint-disable */
<template>
  <div class="cearch-cases">
    <Navigation indexProps="3" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Search Cases</h1></div>
        <div class="header-sub-title">Select a Case to book your required Independent Medico Legal Assessment, or create a new case.</div>
      </div>
    </div>
    <div class="panel">
      <CaseMenu indexProps="2" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end top-search-bar">
            <input type="search" class="search-input w-15 textbox filter-icon right-float-element mr-5 clear pr-0" placeholder="Search by Claimant First Name" v-model="searchByFirstname" />
            <input type="search" class="search-input w-15 textbox filter-icon right-float-element mr-5 clear pr-0" placeholder="Search by Claimant Surname" v-model="searchBySurname" />
            <input type="search" class="search-input w-15 textbox filter-icon right-float-element mr-5 clear pr-0" placeholder="Search by Reference No" v-model="searchByReference" />
            <v-btn
              @click="searchCase"
              dark>Search</v-btn>
          </div>
        </div>

        <div v-if="cases.length > 0" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="hidden-element">Id</th>
                <th style="width:20%" class="long-text">Reference No.</th>
                <!-- <th style="width:20%" class="long-text">Date of Accident/Event</th> -->
                <th style="width:30%" class="long-text">Claimant</th>
                <th style="width:20%" class="long-text">Type of Claim</th>
                <th style="width:15%" class="long-text">Number of Bookings</th>
                <th style="width:15%" class="long-text">Next Booking</th>
              </tr>
            </thead>
          </table>
        </div>
        <div v-if="showMessage"  class="box-row">
          <div class="box-col">
            No results found to match your search. Please either search again, or tell us what you need:
          </div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr v-for="(c, index) in cases" :key="index" @click="editCase(c.id)" class="is-icon">
                <td class="hidden-element"></td>
                <td style="width:20%" class="long-text">{{c.caseReference}}</td>
                <!-- <td style="width:20%" class="long-text">{{formatDate(c.accidentDate, 'DD/MM/YYYY')}}</td> -->
                <td style="width:30%" class="long-text">{{c.claimantFullName}}</td>
                <td style="width:20%" class="long-text">{{c.claimTypeName}}</td>
                <td style="width:15%" class="long-text">{{c.numberBookings}}</td>
                <td style="width:15%" class="long-text">{{formatDate(c.nextBookingDate, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss")}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''"
                  v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">{{ item }}</div>
              </div>
            </div>

          </div>
          <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
        </div>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import CaseMenu from '@/components/CaseMenu'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Chat from '@/components/Chat'

export default {
  name: 'SearchCases',
  components: {
    Navigation,
    CaseMenu,
    Header,
    Chat,
  },
  data() {
    return {
      limit: 20,
      pagination: utilities.initPagination(),
      cases: [],
      searchByFirstname: "",
      searchBySurname: '',
      searchByReference: "",
      showMessage: false,
      user: _.cloneDeep(this.$store.getters['user/getUser']),
    }
  },

  methods: {
    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date != '' && date != "0000-00-00") {
        return utilities.formatDate(date, format, currentFormat)
      }
      return "";
    },

    selectPage(index) {
      this.pagination.current = index
      this.cases = this.pagination.list[this.pagination.current]
    },

    editCase(id) {
      this.$router.push({path:'/manage-case', query:{id:id}}).catch((err) => {})
    },

    async searchCase() {
      this.showMessage = false
      if (this.searchByName != "" || this.searchByReference != "") {
        this.$store.dispatch('loading/setLoadComponent', true)
        var searchCase = {
          clientId:  this.user.clientId,
          ClaimantFirstName: this.searchByFirstname.trim(),
          ClaimantSurname: this.searchBySurname.trim(),
          caseRef: this.searchByReference,
        }

        this.cases = await utilities.searchCasesByClient(searchCase)
        if (this.cases.length == 0) {
          this.showMessage = true
        }
        if(this.cases.length > this.limit) {
          this.pagination = utilities.loadPagination(this.cases, this.limit)
          this.cases = this.pagination.list[this.pagination.current]
          $(".pagination").show()
        } else {
          this.specialists = this.cases
          $(".pagination").hide()
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      }
    }

  },

  beforeMount() {
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    $(".pagination").hide()
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip()

      $("#search-name").on('keypress', (e) => {
        if(e.keyCode == 13) {
          //this.searchByName()
          this.searchByReference()
        }
      })

      $("#search-reference").on('keypress', (e) => {
        if(e.keyCode == 13) {
          //this.searchByName()
          this.searchByReference()
        }
      })

    }, 500)

    $('.top-search-bar').on('keypress', (e) => {
      if (e.keyCode == 13) {
        this.searchCase()
      }
    })

  },
}
</script>
