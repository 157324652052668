/* eslint-disable */
<template>
  <div class="specialist-menu sub-menu">
    <ul>
      <li><router-link to="/all-bookings">All</router-link></li>
      <li><router-link to="/upcoming-bookings">Upcoming Bookings</router-link></li>
      <li><router-link to="/bookings-in-progress">Bookings in Progress</router-link></li>
      <li><router-link to="/bookings-finalised">Finalised Bookings</router-link></li>
      <li><router-link to="/service-request">Service Requests</router-link></li>
      <li><router-link to="/bookings-tentative">Tentative Booking</router-link></li>
      <li><router-link :to="{ name: 'bookings-waitlisted', params: {} }">Waitlisted</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SpecialistMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {

  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
