/* eslint-disable */
<template>
  <div class="manage-user">
    <AdminNavigation ref="navigation" indexProps="10" />
    <div class="top-panel">
      <Header />
      <div v-if="singleUser != undefined" class="header-title-section">
        <div class="header-title"><h1>{{ singleUser.id != '' ? 'Edit User' + (singleUser.name != '' ? ' - ' + singleUser.name : '') : 'Create User' }}</h1></div>
        <div class="header-sub-title">Users are required to keep track of data ownership, whether the user can login or not. Only add 'user login' where the person will authenticate into the application.</div>
      </div>
    </div>
    <div class="panel">
      <AdminUserMenu :indexProps="$route.query.id != undefined ? 0 : 1" />
      <div class="content">
        <div v-if="singleUser != undefined">
          <div class="box">
            <div class="box-heading"><span>User Details</span></div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">User Name</div>
                <div class="box-col box-col-right">
                  <input type="text" :class="hasError && !validateUserName ? 'is-invalid' : ''" v-model="singleUser.name" placeholder="Please enter a user friendly name e.g. Firstname Surname - this will be used to identify the user throughout the system" />
                  <div v-if="hasError && !validateUserName" class="text-danger">User name is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Role</div>
                <div class="box-col box-col-right">
                  <select class="textbox" :class="hasError && !validateRole ? 'is-invalid' : ''" v-model="singleUser.type">
                    <option hidden value="0">Select ...</option>
                    <option v-for="(role, index) in roles" :value="role.id">{{ role.name }}</option>
                  </select>
                  <div v-if="hasError && !validateRole" class="text-danger">Role is required.</div>
                </div>
              </div>
              <div class="box-row" v-if="singleUser.id != ''" :class="singleUser.id != '' ? 'disabled-element' : ''">
                <div class="box-col box-col-left">Specialist</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="singleUser.specialistId" :disabled="singleUser.id != ''">
                    <option  v-if="singleUser.id == ''" value="0">Select ...</option>
                    <option  v-else></option>
                    <option v-for="(specialist, index) in specialists" :value="specialist.id">{{ specialist.fullName }}</option>
                  </select>
                </div>
              </div>
              <div class="box-row"  v-if="singleUser.id != ''" :class="singleUser.id != '' ? 'disabled-element' : ''">
                <div class="box-col box-col-left">Client</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="singleUser.clientId" :disabled="singleUser.id != ''">
                    <option  v-if="singleUser.id == ''" value="0">Select ...</option>
                    <option  v-else></option>
                    <option v-for="(client, index) in clients" :value="client.id">{{ client.name }}</option>
                  </select>
                </div>
              </div>
              <div class="box-row" :class="!isEditable ? 'disabled-element' : ''">
                <div class="box-col box-col-left">User Login?</div>
                <div class="box-col box-col-right row-item">
                   <input type="checkbox" :checked="singleUser.status" :disabled="!isEditable" v-model="singleUser.status" />
                    <v-btn v-if="showResend" class="ml-10 col-center-content" style="background-color:#C4C4C4;color:#6686B6F" @click="reSendInvitation()"> Resend Invite</v-btn>
                </div>
              </div>
              <div class="box-row" :class="!isEditable ? 'disabled-element' : ''">
                <div class="box-col box-col-left">Email</div>
                <div class="box-col box-col-right">
                  <input type="text" :class="hasError && !validateEmail ? 'is-invalid' : ''" v-model="singleUser.email" :disabled="!isEditable" placeholder="Please enter the user’s email address - this will be used to login" />
                  <div v-if="hasError && !validateEmail" class="text-danger">{{ emailMessage }}</div>
                </div>
              </div>
              <div class="box-row" :class="!isEditable ? 'disabled-element' : ''">
                <div class="box-col box-col-left">Phone</div>
                <div class="box-col box-col-right">
                  <input type="text" class="w-50" :class="hasError && !validatePhone ? 'is-invalid' : ''" v-model="singleUser.phone" :disabled="!isEditable" placeholder="Please enter the user’s phone number" />
                  <div class="text-danger" v-if="(hasError && !validatePhone)">{{ phoneMessage }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="icon-group right-float-element">
            <v-btn dark @click="save">Save</v-btn>
          </div>
        </div>
        <div v-else>This user not found.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminUserMenu from '@/components/AdminUserMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminManageUser',
    components: {
      AdminNavigation,
      AdminUserMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
        successMessage: "",
        errorMessage: "Please correct the errors shown above and try again.",
        emailMessage: "",
        phoneMessage: "",
        loaded: false,
        showResend: false,
        isEditable: true,

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        singleUser: utilities.initUser(),

        roles: [],
        specialists: [],
        clients: [],
        users: [],
        userParam: {
          PageNum: 1,
          PageSize: 10,
          EmailAddress: null
        },
        oldUser: [],
      }
    },
    watch: {
      singleUser: {
        handler(value) {
          if (this.loaded) this.$store.dispatch('setNewObject', value)
        },
        deep: true,
      },
    },
    computed: {
      validateUserName() {
        if(this.singleUser.name == '') {
          return false
        }
        return true
      },
      validateRole() {
        if(this.singleUser.type == '') {
          return false
        }
        return true
      },
      validatePhone() {
        if(this.singleUser.phone == '') {
          this.phoneMessage = "Phone is required."
          return false
        } else if(!utilities.validatePhonenumber(this.singleUser.phone)) {
          this.phoneMessage = "Phone is invalid."
          return false
        }
        return true
      },
      validateEmail() {
        if(this.singleUser.email == '') {
          this.emailMessage = "Email is required."
          return false
        } else if(!utilities.validateEmail(this.singleUser.email)) {
          this.emailMessage = "Email is incorrect."
          return false
        }
        return true
      },
    },
    methods: {
      async save() {
        if (!this.validateUserName || !this.validateRole || !this.validateEmail || !this.validatePhone) {
          this.hasError = true
          utilities.showAlert(".alert-danger")
          return false
        } else {
          this.hasError = false
        }
        this.userParam.EmailAddress = this.singleUser.email
        const {users} = await utilities.getUsersNew(this.userParam)
        let list = users
        if (this.singleUser.id == '' && list.map(x => x.email).includes(this.singleUser.email)) {
          this.errorMessage = "Email is already existed."
          utilities.showAlert(".alert-danger")
          return false
        }

        if (this.singleUser.id != '') {
          await this.editUser()
        } else {
          await this.addUser()
        }
      },
      postUser() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          Name: this.singleUser.name,
          UserRoleID: this.singleUser.type,
          UserStatus: utilities.convertBoolToNum(this.singleUser.status),
          // SpecialistID: this.singleUser.specialistId,
          // ClientID: this.singleUser.clientId != 0 ? this.singleUser.clientId : "",
          EmailAddress: this.singleUser.email,
          Mobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.singleUser.phone)),
        }

        if (this.singleUser.status) {
          params.CanLogin = 1
        } else {
          params.CanLogin = 0
        }

        var callback = (response) => {
          this.singleUser.id = response
          this.oldUser = _.cloneDeep(this.singleUser)
        }
        return utilities.postUser(params, callback)
      },

      async reSendInvitation() {
        if(!this.validatePhone) {
          this.hasError = true
          utilities.showAlert(".alert-danger")
          return false
        } else {
          this.hasError = false
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        var mobile = utilities.updateMobileCode(utilities.validatePhonenumber(this.singleUser.phone))
        if(mobile.charAt(0) == '0') {
          mobile = mobile.substring(1)
        }
        var params = {
          Name: this.singleUser.name,
          EmailAddress: this.singleUser.email,
          Mobile: mobile,
          UserRoleID: this.singleUser.type,
        }
        var result = await utilities.postResendInvite(params)
        if (result) {
          this.successMessage = result
          utilities.showAlert(".alert-success")
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      putUser() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          SingleUserID: this.singleUser.id,
          Name: this.singleUser.name,
          UserRoleID: this.singleUser.type,
          UserStatus: utilities.convertBoolToNum(this.singleUser.status),
          SpecialistID: this.singleUser.specialistId,
          ClientID: this.singleUser.clientId != 0 ? this.singleUser.clientId : "",
          EmailAddress: this.singleUser.email,
          Mobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.singleUser.phone)),
          CanLogin: 0
        }

        if (this.singleUser.status) {
          params.CanLogin = 1
        } else {
          params.CanLogin = 0
        }


        if (this.singleUser.email != undefined && this.oldUser.email != undefined && this.oldUser.email.length > 0) {
          if (this.singleUser.email != this.oldUser.email) {
            params.OldEmailAddress = this.oldUser.email
          }
        }

        if (this.singleUser.name != this.oldUser.name ) {
          params.OldName = 1
        }

        if (this.singleUser.phone != this.oldUser.phone ) {
          params.OldPhone = 1
        }

        if (this.singleUser.type != this.oldUser.type ) {
          params.OldRole = this.oldUser.type
        }

        if (this.singleUser.status && !this.oldUser.status) {
          params.Reactivate = 1
        } else {
          params.Reactivate = 0
        }

        var callback = (response) => {
          this.oldUser = _.cloneDeep(this.singleUser)
        }

        return utilities.putUser(params, callback)
      },

      async addUser() {
        this.$store.dispatch("loading/setLoadComponent", true)

        var result = await this.postUser()
        if (result) {
          if (this.singleUser.status) {
            this.showResend = true
          } else {
            this.showResend = false
          }
          this.successMessage = "User successfully created and invited to Red Health Online."
          utilities.showAlert(".alert-success")

          // var params = {
          //   UserName: this.singleUser.email,
          // 	Name: this.singleUser.name
          // }
          // var callback = (response) => {}
          // await utilities.postNewUserEmail(params, callback)
          setTimeout(() => {
            window.history.replaceState({}, document.title, location.pathname + '?id=' + this.singleUser.id)
            this.loadUser()
          }, 2000)
        }

        this.$store.dispatch("loading/setLoadComponent", false)
      },
      async editUser() {
        this.$store.dispatch("loading/setLoadComponent", true)

        var result = await this.putUser()
        if (result) {
          if (this.singleUser.status) {
            this.showResend = true
          } else {
            this.showResend = false
          }
          utilities.clearChangingObject()
          this.successMessage = "User updated successfully."
          utilities.showAlert(".alert-success")
          setTimeout(() => {
            this.loadUser()
          }, 2000)
        }

        this.$store.dispatch("loading/setLoadComponent", false)
      },
      async loadUser() {
        var id = (this.$route.query != undefined && this.$route.query.id != undefined) ? this.$route.query.id : ""
        if(id != '') {
          this.singleUser = await utilities.getUserById(id)
          if (this.singleUser.status) {
            this.showResend = true
          } else {
            this.showResend = false
          }
          this.oldUser = _.cloneDeep(this.singleUser)
          this.$store.dispatch('setOriginalObject', this.singleUser)
          this.$store.dispatch('setNewObject', this.singleUser)
        }
      },
      async loadData() {
        var role = await utilities.getUserRoles()

        if (this.singleUser.id == '' || this.singleUser.typeName == "RHFinancialAdmin" || this.singleUser.typeName == "SuperAdmin" || this.singleUser.typeName == "RHAdmin" ) {
          this.isEditable = true
          role.map((x) => {
            if (x.name == "RHFinancialAdmin" || x.name == "SuperAdmin" ||x.name == "RHAdmin") {
              this.roles.push(x)
            }
          })
        } else if (this.singleUser.typeName == 'Client' || this.singleUser.typeName == 'BranchAdmin') {
          this.isEditable = false
          role.map((x) => {
            if (x.name == "BranchAdmin" || x.name == "Client") {
              this.roles.push(x)
            }
          })
          this.clients = await utilities.getClients()
        } else if (this.singleUser.typeName == 'Specialist') {
          this.isEditable = false
          role.map((x) => {
            if (x.name == "Specialist") {
              this.roles.push(x)
            }
          })
          this.specialists = await utilities.getSpecialists()
        }

      }
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      if (this.user.type != 6) {
        this.$router.go(-1)
        return false
      }
      await this.loadUser()
      await this.loadData()
      this.loaded = true
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
