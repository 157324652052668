/* eslint-disable */
<template>
  <div class="vertical-year-calendar">
    <v-sheet height="600">
      <calendar
        :key="calendarKey"
        :year="calendarDateProps"
        :data-source="dataSource"
        :sync="true"
        @mouse-on-day="mouseOnDay"
        render-style="background"
        @click-day="goToDay"
        :customDayRenderer="customDayRenderer"
        :display-header="false" />
    </v-sheet>
    <div class="box-col calender-item-vertical">
      <div class="availability-item">
        <div style="width: 20px; flex: none" class="calender-indicator calender-video"></div>
        <div class="indicator-text">Video appointments Only</div>
      </div>
      <div class="availability-item">
        <div style="width: 20px; flex: none" class="calender-indicator calender-video-person"></div>
        <div class="indicator-text">Face to Face or Video Link Appointments</div>
      </div>
      <div class="availability-item" v-if="isAdminProps">
        <div class="calender-indicator calender-submited"></div>
        <div class="indicator-text">Submitted availability and unconfirmed by Red Health</div>
      </div>
      <div class="availability-item">
        <div class="calender-indicator calender-approved"></div>
        <div class="indicator-text">{{isAdminProps ? 'Face to Face' : 'Availability'}}</div>
      </div>
      <div class="availability-item">
        <div class="calender-indicator calender-fullbook"></div>
        <div class="indicator-text">Fully Booked</div>
      </div>
      <div class="availability-item">
        <div class="calender-indicator calender-request"></div>
        <div class="indicator-text">By Request Only</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import tippy from 'tippy.js'
  import 'tippy.js/dist/tippy.css'

  export default {
    name: 'VerticalYearCalendar',
    props: ['calendarDateProps', 'dataSourceProps', 'pathProps', 'isAdminProps', 'specialist'],
    components: {},
    data() {
      return {
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        dataSource: this.dataSourceProps,
        calendarKey: 0,
      }
    },
    computed: {},
    methods: {
      customDayRenderer(element, date) {
        let filter = this.dataSource.find(item => window.moment(item.date).isSame(window.moment(date), 'day'))
        if (filter && filter.status === 'request only') {
          element.parentElement.classList.add('calender-request')
        } else if (filter && filter.status === 'submitted') {
          element.parentElement.classList.add('calender-submited')
        } else if (filter && filter.status === 'fully booked') {
          element.parentElement.classList.add('calender-fullbook')
        } else if (filter && filter.status === 'video link') {
          element.parentElement.classList.add('calender-video-person')
        } else if (filter && filter.status === 'video only') {
          element.parentElement.classList.add('calender-video')
        } else if (filter && filter.status === 'available'){
          element.parentElement.classList.add('calender-approved')
        }
      },
      mouseOnDay: function (e) {
        if (e.events.length > 0) {
          var content = ''
          for (var i in e.events) {
            content +=
                `<div class="event-tooltip-content overflow-y-auto" style="max-height: 500px">`
            e.events[i].Availabilities.sort((a, b) => {
              return window.moment(a.StartTime, 'HH:mm').isAfter(window.moment(b.StartTime, 'HH:mm')) ? 1 : -1
            }).forEach(item => {
              content += `<div class="border-bottom d-flex flex-column align-items-baseline pt-1 pb-1">
<div class="d-flex align-items-center justify-content-between w-100">
<span>${this.specialist ? this.specialist.fullName : ''}</span>
${item.AvailabilityStatus === '1' ? '<span class="ml-2 blue--text">Unconfirmed</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'A' ? '<span class="ml-2 green--text">Unbooked</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'B' ? '<span class="ml-2 red--text">Booked</span>' : ''}
${item.AvailabilityStatus === '2' && item.BookingStatus === 'H' ? '<span class="ml-2 red--text">On hold</span>' : ''}
</div>
<div class="d-flex justify-content-between align-items-center w-100">
<span>${utilities.formatDate(item.StartTime, 'HH:mm', 'HH:mm:ss')} ~ ${utilities.formatDate(item.EndTime, 'HH:mm', 'HH:mm:ss')}</span>
<div class="d-flex align-items-center ml-2">
${item.Options.VideoOnly ? '<div class="video-icon"></div>' : '' }
${item.Options.VideoLinkAvailable ? '<div class="user-video-icon"></div>' : '' }
${(!item.Options.VideoLinkAvailable && !item.Options.VideoOnly) ? '<div class="face-icon"></div>' : '' }
${item.Options.ShowWorkCover ? '<div class="show-worker-icon"></div>' : '' }
${item.Options.ShowSpecificClients ? '<div class="specific-client-icon"></div>' : '' }
${item.Options.ByRequestOnly ? '<div class="request-icon"></div>' : '' }
</div>
</div>
<span>${item.Location ? decodeURIComponent(item.Location.LocationName) : ''}</span>
</div>`
            })
          }

          if (this.tooltip != null) {
            this.tooltip.destroy()
            this.tooltip = null
          }

          this.tooltip = tippy(e.element, {
            placement: 'right',
            content: content,
            trigger: 'click',
            interactive: true,
            interactiveBorder: 1000,
            interactiveDebounce: 10000,
            allowHTML: true,
            arrow: true,
            arrowType: 'round',
            delay: 1000,
            onShown(instance) {
              if (e.events.length > 3) {
                $(".tippy-box .tippy-content").css("height", "330px")
              } else {
                $(".tippy-box .tippy-content").css("height", "auto")
              }
            },
          })
          this.tooltip.show()
        } else {
          this.tooltip && this.tooltip.hide()
        }
      },
      mouseOutDay: function(e) {
        if(this.tooltip != undefined) this.tooltip.hide()
      },
      updateAvailability(id, selfId) {
        this.dataSource.filter(x => x.selfId == selfId && x.id == id).map(x => {
          // Approved
          x.status = 3
          x.color = utilities.getBookingStatusColor(x.status)
         })
        this.calendarKey += 1
      },
      removeAvailability(id, selfId) {
        this.dataSource = this.dataSource.filter((x) => {
          return !(x.selfId == selfId && x.id == id)
        })
      },

      goToDay(e) {
        if (e.events.length === 0 ||(e.events[0]?.isRequestOnly && this.user.type < 3)) {
          return
        }
        if(this.pathProps != undefined) {
          var dateToGo = moment(e.date).format('YYYY-MM-DD')
          var query = {}
          if(e.events[0] != undefined && e.events[0].specialistId != '' && !this.pathProps.includes("id")) {
            query.id = e.events[0].specialistId
          }
          query.date = moment(dateToGo).format('YYYY-MM-DD')
          this.$router.push({ path: this.pathProps, query: query }).catch((err) => {})
        } else if(this.pathProps == undefined && this.$route.path.includes("booking-single-calendar")) {
          this.reloadCalendar(e.events[0])
          // if(e.events.length == 1 && e.events[0]) {
          //   console.log('1 availability')
          //   this.reloadCalendar(e.events[0])
          // } else {
          //   console.log('Many availabilities')
          // }
        }
      },
      reloadCalendar(event) {
        if((event.name == "Wait List" && !event.available) || event.isWaitlist) {
          //Pass waitlist booking details
          var booking = _.cloneDeep(this.$store.getters["booking/getBookingObject"])
          booking.bookingDate = event.date
          booking.bookingTime = event.startTime
          booking.waitlisted = true
          booking.locationId = event.location
          booking.locationName = event.locationName
          this.$store.dispatch("booking/setBookingObject", booking)
          if(this.user.type >= 3) {
            this.$router.push({ name: 'admin-search-cases-details' }).catch((err) => {})
          } else {
            this.$router.push({ name: 'search-cases-details' }).catch((err) => {})
          }
        } else {
          var booking = _.cloneDeep(this.$store.getters["booking/getBookingObject"])
          booking.bookingDate = event.date
          booking.availabilityId = event.availabilityId
          this.$store.dispatch("booking/setBookingObject", booking)
          this.$parent.reloadCalendar()
        }
      },
      hideMonths() {
        utilities.hideMonths(this.calendarDateProps)
      },
    },
    beforeMount() {},
    mounted() {},
  }
</script>
