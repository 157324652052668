/* eslint-disable */
<template>
  <div class="login p-0">
    <div class="red-vertical"></div>
    <div class="login-container">
      <div class="login-left">
        <div class="logo"><img src="@/assets/images/red_health_logo.svg" /></div>
        <v-form ref="form" class="login-form">
          <v-subheader>Business Email Address</v-subheader>
          <v-text-field v-model="account.userName" :rules="[rules.validateUsername.required]" placeholder="Enter your Email Address" solo required></v-text-field>

          <v-subheader>Password</v-subheader>
          <v-text-field
            v-model="account.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.validatePassword.required]"
            placeholder="Enter your Password"
            @click:append="() => (showPassword = !showPassword)"
            solo
            required
          ></v-text-field>

          <!-- <v-subheader>One Time Code</v-subheader>
          <v-text-field v-model="account.oneTime" placeholder="Enter Code from authenticator application " solo></v-text-field> -->

          <br />
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="login-button">
            <v-btn class="red-button" dark @click="login">Login</v-btn>
          </div>
          <QRCodeModal ref="qrcodeModal" :codeProps="secretCode" />
        </v-form>
        <div class="login-option">
          <div class="login-forgot">
            <router-link :to="{ name: 'forgot-password' }" class="text-decoration">Forgot Password?</router-link>
          </div>
          <a target="_blank" href="https://redhealth.com.au/red-health-online/red-health-online-how-to-create-a-new-account-and-set-up-2fa/" download="How to create new account.pdf" class="mt-4 text-decoration" style="display: block; color: #808080; font-size: 12px">Guide to logging in for the first time</a>
          <div class="login-enquire">Don’t have an account with Red Health Online? <a href="/enquiry" class="text-decoration">Enquire Now</a></div>
        </div>
      </div>
      <SetNewPasswordModal ref="setNewPasswordModal" />
      <OTPModal ref="otpModal" />
      <div class="login-right">
        <div class="banner">
          <a class="img" target="_blank" :href="src">
            <img src="@/assets/images/banner.png" />
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import auth from '@/assets/js/cognitoAuth'
  import utilities from '@/assets/js/utilities'
  import Footer from '@/components/Footer'
  import QRCodeModal from '@/components/QRCodeModal'
  import SetNewPasswordModal from '@/components/SetNewPasswordModal'
  import OTPModal from '@/components/OTPModal'

  export default {
    name: 'Login',
    props: ['messageProps'],
    components: {
      Footer,
      QRCodeModal,
      SetNewPasswordModal,
      OTPModal,
    },
    data() {
      return {
        showPassword: false,
        errorMessage: '',
        src: '',
        user: _.cloneDeep(this.$store.getters['user/getUser']),
        account: {
          userName: '',
          password: '',
          oneTime: '',
        },
        secretCode: '',

        rules: {
          validateUsername: {
            required: (value) => !!value || 'Username is required.',
          },
          validatePassword: {
            required: (value) => !!value || 'Password is required.',
          },
        },

        verifyOtp: false,
      }
    },
    methods: {
      // Used on OTPModal
      setLastLoginDate(user) {
        var params = {
          UserID: user.id,
          Username: user.name,
          LastLoggedInDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          SingleUserID: user.id,
        }
        var callback = (response) => {}
        return utilities.putLoginTime(params, callback)
      },

      async login() {
        if (!this.$refs.form.validate()) {
          return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        var authenticationData = {
          Username: this.account.userName.trim(),
          Password: this.account.password.trim(),
        }
        auth.login(authenticationData).then(async (user) => {
            this.$store.dispatch('loading/setLoadComponent', false)
            if (user != false && user != null && user !== undefined) {
              if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
                // User requires new password
                this.$refs.setNewPasswordModal.user = user
                this.$refs.setNewPasswordModal.handleModal()
              } else if (user.challengeName == 'MFA_SETUP') {
                await this.mfaSetup(user)
              } else if (user.challengeName == 'SOFTWARE_TOKEN_MFA') {
                this.promptForTotp(user)
              } else {
                var authentication = utilities.loadAuthentication(user.signInUserSession)
                this.$store.dispatch('user/setAuthentication', authentication)

                let userToSave = await utilities.getUserBySub(user.signInUserSession.accessToken.payload['sub'])
                if (userToSave) {
                  // Zoho Integration
                  // fetch('https://vtjfl2u95a.execute-api.ap-southeast-2.amazonaws.com/RHOReferenceData/zoho-login').then((response) => console.log(response))
                  if(userToSave.clientId) {
                    const client = await utilities.getClientById(userToSave.clientId)
                    const allContacts = client.contacts.map(x => {
                      return {
                        ...x,
                        mobile: utilities.removeMobileCode(x.mobile)
                      }
                    })
                    const contact = allContacts.find(item => item.userId === userToSave.id)
                    if(contact && contact.mobile && contact.mobile !== '') {
                      userToSave.mobile = btoa(contact.mobile)
                    }
                  }
                  this.$store.dispatch('user/setUser', userToSave)
                  var result = await this.setLastLoginDate(userToSave)
                  if(result) this.redirectToHome()
              }
            }
            }
          })
          .catch((error) => {
            this.$store.dispatch('loading/setLoadComponent', false)
            this.errorMessage = error.message
            utilities.showAlert('.alert-danger')
          })
      },
      async mfaSetup(user) {
        this.account.password = ""
        var code = await auth.addToAuthenticator(user)
        // Show email user name in authenticator app
        this.secretCode = 'otpauth://totp/RedHealthOnline:' + this.account.userName + '?secret=' + code + '&issuer=RedHealthOnline'
        this.$refs.qrcodeModal.user = user
        this.$refs.qrcodeModal.handleModal()

        setTimeout(() => {
          $(".qr-code-modal input[type='password']").focus()
          $(".qr-code-modal input[type='password']").on('keypress', (e) => {
            if (e.keyCode == 13) {
              this.$refs.qrcodeModal.clickSave()
            }
          })
        }, 500)
      },

      // Used on OTPModal
      redirectToHome() {
        const user = _.cloneDeep(this.$store.getters['user/getUser'])
        const mobile = user.mobile ? atob(user.mobile) : ''
        if(mobile === utilities.DEFAULT_MOBILE) {
          this.$router.push({ name: 'edit-profile' }).catch((err) => {})
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-success">' +
                '            <b>Warning:</b> Please update your mobile number' +
                '          </div>',
          });
        } else {
          if(this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push({ name: 'home' }).catch((err) => {})
          }
        }
      },

      promptForTotp(user) {
        this.$refs.otpModal.user = user
        this.$refs.otpModal.handleModal()

        setTimeout(() => {
          $(".otp-modal input[type='password']").focus()
          $(".otp-modal input[type='password']").on('keypress', (e) => {
            if (e.keyCode == 13) {
              this.$refs.otpModal.clickSave()
            }
          })
        }, 500)
      },
    },
    async beforeMount() {
      // await auth.signOut()
      this.$store.dispatch('user/setAuthentication', utilities.initAuthentication())
      utilities.getCurrentLocation()
      var result = ''
      var blob = await utilities.getBannerImage()
      await new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onloadend = function() {
          result = reader.result
          resolve(true)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(blob)
      })
      $('.banner img').attr('src', result)
      if(this.messageProps != undefined) {
        utilities.showSuccessMessage(this.messageProps)
      }
    },
    async mounted() {
      // Clear all sessions
      $('.login form').on('keypress', (e) => {
        if (e.keyCode == 13) {
          this.login()
        }
      })
      this.src = await utilities.getHeroImgSrc()
    },
  }
</script>
