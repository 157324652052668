/* eslint-disable */
<template>
  <div class="admin-referral-network">
    <AdminNavigation ref="navigation" indexProps="4" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Download Referral Network</h1></div>
        <div class="header-sub-title">Download and create a full PDF of all Active specialists in the system</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistMenu indexProps="2" />
      <div class="content h-100">
        <el-button type="primary" @click="getUrl">Download</el-button>
        <div v-if="user.type === 6" class="mt-16 m-auto d-flex flex-column align-items-center w-auto">
          <el-upload
              class="referral-upload"
              drag
              ref="uploadPDF"
              accept="pdf"
              :show-file-list="false"
              size="lg"
              :action="url"
              :headers="{'Authorization': getIdToken()}"
              :limit="1"
              :before-upload="beforeUpload"
              :on-success="onSuccess"
              :on-progress="onProgress"
              :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Please drag the file，or <em>click to upload</em>
              <div style="font-size: 12px">Only accept PDF file</div>
            </div>
          </el-upload>
          <el-progress v-if="uploading" style="width: 360px" class="mt-4" :percentage="percent"></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminSpecialistMenu from '@/components/AdminSpecialistMenu'
import Header from '@/components/Header'

export default {
  name: "AdminReferralNetwork",
  props: ["indexProps"],
  components: {
    AdminNavigation,
    AdminSpecialistMenu,
    Header
  },
  data() {
    return {
      percent: 0,
      uploading: false,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      fileList: [],
      url: utilities.postSpecialistProfiles()
    }
  },
  methods: {
    onProgress(event) {
      this.percent = Number(event.percent.toFixed(0))
    },
    onSuccess() {
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: '<div class="alert-success">' +
            '            <b>Success:</b> File Uploaded !' +
            '          </div>',
      });
      this.uploading = false
      this.$refs.uploadPDF.clearFiles()
      this.percent = 0
    },
    async beforeUpload(file) {
      if(!file.type.includes('pdf')) {
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-error">' +
              '            <b>Error:</b> Please select a pdf file !' +
              '          </div>',
        });
        return Promise.reject(false)
      } else {
        const result = await utilities.checkTokenStatus()
        if(result) {
          this.percent = 0
          this.uploading = true
          return Promise.resolve(true)
        }
      }
      return Promise.reject(false)
    },
    getIdToken() {
      return utilities.getIdToken()
    },
    async getUrl(){
      this.$store.dispatch("loading/setLoadComponent", true)
      var blob = await utilities.getSpecialistProfiles()
      const code = blob.replace(/[\n\r]/g, '')
      const raw = window.atob(code)
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      const blobItem = new Blob([uInt8Array], { type: 'application/pdf' })
      const blobURL = window.URL.createObjectURL(blobItem)
      let a = document.createElement('a')
      a.setAttribute('href', blobURL)
      a.setAttribute('download', 'Red Health Referral Network.pdf')
      a.click()
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    postFile() {

    }
  }
}
</script>
