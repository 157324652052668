/* eslint-disable */
<template>
  <div class="billing-categories">
    <AdminNavigation ref="navigation" indexProps="9" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Billing Categories</h1></div>
        <div class="header-sub-title">A billing category is assigned to each client, and used, in combination with the selected specialist, to determine the amount to be charged on a particular booking.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="8" />
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <span>Billing Category Name</span>
            <div class="right-element"><v-btn dark class="add-icon" @click="openManageModal(-1)">Add New</v-btn></div>
          </div>
          <div class="box-body">
            <div class="box-row" v-for="(billingCategory, index) in billingCategories">
              <div class="box-col w-100">
                <span>{{ billingCategory.name }}</span>
                <div class="right-element" style="top:0">
                  <img src="@/assets/images/edit_icon.svg" alt="" @click="openManageModal(index)" />
                </div>
              </div>
            </div>
            <AdminManageBillingCategoryModal ref="adminManageBillingCategoryModal" :billingCategoryProps="billingCategories" />
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSettingsMenu from '@/components/AdminSettingsMenu'
  import Header from '@/components/Header'
  import AdminManageBillingCategoryModal from '@/components/AdminManageBillingCategoryModal'

  export default {
    name: 'AdminBillingCategories',
    components: {
      AdminNavigation,
      AdminSettingsMenu,
      Header,
      AdminManageBillingCategoryModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: 'Please correct the errors shown above and try again.',
        errorMessage: '',
        successMessage: '',
        billingCategories: [],
        selectedCategory: null,
      }
    },
    methods: {
      async loadData() {
        this.billingCategories = await utilities.getBillingCategories()
      },

      newBillingCategory() {
        return { id: '', name: '' }
      },

      openManageModal(index = '') {
        if (index != -1) {
          this.$refs.adminManageBillingCategoryModal.editBillingCategory(this.billingCategories[index])
        } else {
          this.$refs.adminManageBillingCategoryModal.editBillingCategory(this.newBillingCategory())
        }
        this.$refs.adminManageBillingCategoryModal.handleModal()
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
