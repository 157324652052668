/* eslint-disable */
<template>
  <div class="settings-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-related-skills', params: {} }">Related Specialties</router-link></li>
      <li><router-link :to="{ name: 'admin-related-services', params: {} }">Related Services</router-link></li>
      <el-menu
          :collapse-transition="false"
          :default-active="indexProps"
          :unique-opened="true"
          class="el-menu-vertical">
        <el-submenu index="2" :show-timeout="0" :hide-timeout="0">
          <template slot="title">
            <span>Location Manage</span>
          </template>
          <el-menu-item index="2-1"><router-link class="w-100"  :to="{ name: 'admin-countries', params: {} }">Countries</router-link></el-menu-item>
          <el-menu-item index="2-2"><router-link class="w-100"  :to="{ name: 'admin-states', params: {} }">States</router-link></el-menu-item>
          <el-menu-item index="2-4"><router-link class="w-100"  :to="{ name: 'admin-regions', params: {} }">Regions</router-link></el-menu-item>
          <el-menu-item index="2-5"><router-link class="w-100"  :to="{ name: 'admin-cities', params: {} }">Cities</router-link></el-menu-item>
          <el-menu-item index="2-3"><router-link class="w-100"  :to="{ name: 'admin-locations-and-regions', params: {} }">Locations</router-link></el-menu-item>
        </el-submenu>
      </el-menu>
      <li v-if="user.type === 4 || user.type === 6"><router-link class="w-100" :to="{ name: 'admin-billing-categories', params: {} }">Billing categories</router-link></li>
      <li v-if="user.type === 6"> <router-link class="w-100"  :to="{ name: 'admin-manage-certifications', params: {} }">Accreditations/Certifications</router-link></li>
      <li v-if="user.type === 6"> <router-link class="w-100"  :to="{ name: 'admin-manage-personconditions', params: {} }">Person Conditions</router-link></li>
      <li v-if="user.type === 6" class="is-icon" @click="openUploadHeroImage">Hero Image</li>
      <AdminUpdateHeroImageModal ref="uploadHeroImage" />
      <li v-if="user.type === 6" class="is-icon" @click="openUploadReferralPdf">Upload Referral Image</li>
      <AdminUpdateReferralPdfModal ref="uploadReferralPdf" />
      <li v-if="user.type === 6"> <router-link class="w-100"  :to="{ name: 'admin-manage-email', params: {} }">Email Templates</router-link></li>
      <li v-if="user.type === 6"> <router-link class="w-100"  :to="{ name: 'admin-manage-auto-email', params: {} }">Auto Comms</router-link></li>
      <el-menu
          v-if="user.type === 6"
          :collapse-transition="false"
          :default-active="indexProps"
          :unique-opened="true"
          class="el-menu-vertical">
        <el-submenu index="1" :show-timeout="0" :hide-timeout="0">
          <template slot="title">
            <span>System Log</span>
          </template>
          <el-menu-item index="1-1"><router-link class="w-100"  :to="{ name: 'admin-manage-booking-file-log', params: {} }">Booking File Log</router-link></el-menu-item>
        </el-submenu>
      </el-menu>
    </ul>
  </div>
</template>

<script>
import AdminUpdateHeroImageModal from '@/components/AdminUpdateHeroImageModal'
import AdminUpdateReferralPdfModal from '@/components/AdminUpdateReferralPdfModal'
import utilities from "@/assets/js/utilities";

export default {
  name: 'AdminSettingsMenu',
  props: ["indexProps"],
  components: {
    AdminUpdateHeroImageModal,
    AdminUpdateReferralPdfModal
  },
  data() {
    return {
      user: _.cloneDeep(this.$store.getters['user/getUser']),
    }
  },
  methods: {
    async openUploadHeroImage() {
      this.$store.dispatch('loading/setLoadComponent', true)
      await this.$refs.uploadHeroImage.getImage()
      this.$refs.uploadHeroImage.handleModal()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    openUploadReferralPdf() {
      this.$refs.uploadReferralPdf.initFileUpload()
      this.$refs.uploadReferralPdf.handleModal()
    },
  },
  beforeMount() {
    if(this.indexProps === undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
