/* eslint-disable */
<template>
  <div class="manage-client-request">
    <AdminNavigation ref="navigation" indexProps="1" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Client Account Request</h1></div>
        <div class="header-sub-title">Client account request submitted by the Client, for action by Red Health.</div>
      </div>
    </div>
    <div class="panel">
      <AdminClientsMenu indexProps="2" />
      <div class="content">
        <div v-if="clientRequest != undefined">
          <div class="box">
            <div class="box-heading">
              <span>Client Account Request Details</span>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">First Name</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.firstName" class="pl-3 w-100" :class="hasError && !validateFirstName ? 'is-invalid' : ''" placeholder="First Name" />
                  <div v-if="hasError && !validateFirstName" class="text-danger">First Name is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Surname</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.surname" class="pl-3 w-100" :class="hasError && !validateSurname ? 'is-invalid' : ''" placeholder="Surname" />
                  <div v-if="hasError && !validateSurname" class="text-danger">Surname is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Business Email Address</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.email" class="pl-3 w-100" :class="hasError && !validateEmail ? 'is-invalid' : ''" placeholder="Business Email Address" />
                  <div v-if="hasError && !validateEmail" class="text-danger">{{ emailErrorMessage }}</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Mobile Number</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.mobile" class="pl-3 w-100" :class="hasError && !validateMobile ? 'is-invalid' : ''" placeholder="Mobile Number" />
                  <div v-if="hasError && !validateMobile" class="text-danger">{{ mobileErrorMessage }}</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Company Name</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.companyName" class="pl-3 w-100" :class="hasError && !validateCompanyName ? 'is-invalid' : ''" placeholder="Company Name" />
                  <div v-if="hasError && !validateCompanyName" class="text-danger">Company Name is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Company Branch Location</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.companyBranchName" disabled class="pl-3 w-100" placeholder="Company Branch Location" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Business Address</div>
                <div class="box-col box-col-right">
                  <input type="text" v-model="clientRequest.addressLine1" class="pl-3 w-100" placeholder="Address 1" />
                  <input type="text" v-model="clientRequest.addressLine2" class="pl-3 w-100" placeholder="Address 2" />
                  <div class="pl-3 w-50 d-flex">
                    <div class="w-15">City:</div>
                    <div class="w-75">
                      <input type="text" v-model="clientRequest.city" class="pl-3 w-100" placeholder="City" />
                    </div>
                  </div>
                  <div class="pl-3 d-flex">
                    <div class="w-50 d-flex">
                      <div class="w-15">State:</div>
                      <div class="w-75">
                        <select v-model="clientRequest.state" class="pl-3 w-100 disabled-select">
                          <option hidden value="">Please choose</option>
                          <option v-for="(state, index) in states" :value="state.value">{{ state.text }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="w-50 d-flex">
                      <div class="w-15">Postcode:</div>
                      <div class="w-75">
                        <input v-model="clientRequest.postcode" type="number" class="pl-3 w-100 postcode" placeholder="Postcode" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Date Received</div>
                <div class="box-col box-col-right"><span class="pl-3">{{ formatDate(clientRequest.dateReceived, "DD/MM/YYYY") }}</span></div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">User Login</div>
                <div class="box-col box-col-right">
                  <input type="checkbox" class="float-left row-item" v-model="canLogin" />
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Client Name</div>
                <div class="box-col box-col-right">
                  <el-select filterable class="w-75" :class="hasError && !validateClient ? 'is-invalid' : ''" placeholder="Please choose" v-model="client.id" @change="changeClient">
                    <el-option v-for="(client, index) in clients" :key="client.id" :value="client.id" :label="client.name + ', ' + client.branchName">{{ client.name + ", " + client.branchName }}</el-option>
                  </el-select>
                  <div class="d-inline-block ml-3 is-icon" @click="openCreateClient">
                    <img src="@/assets/images/add_black_icon.svg" alt="Add new client" />
                  </div>
                  <AdminCreateClientModal ref="adminCreateClientModal" />
                  <div v-if="hasError && !validateClient" class="text-danger">Client is required.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="icon-group right-float-element">
            <v-btn dark @click="deleteRequest">Delete Request</v-btn>
            <v-btn dark @click="createContact">Create Contact</v-btn>
          </div>
        </div>
        <div v-else>This client request not found.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminClientsMenu from '@/components/AdminClientsMenu'
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import AdminCreateClientModal from '@/components/AdminCreateClientModal'

  export default {
    name: 'AdminManageClientRequest',
    components: {
      AdminNavigation,
      AdminClientsMenu,
      Header,
      AdminCreateClientModal,
    },
    data() {
      return {
        hasError: false,
        successMessage: "",
        errorMessage: "Please correct the errors shown above and try again.",
        emailErrorMessage: '',
        mobileErrorMessage: '',
        postcodeErrorMessage: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        clientRequest: utilities.initClientRequest(),
        client: utilities.initClient(),
        canLogin: true,
        states: [],
        clients: [],
      }
    },
    computed: {
      validateFirstName() {
        if (this.clientRequest.firstName == '') {
          return false
        }
        return true
      },
      validateSurname() {
        if (this.clientRequest.surname == '') {
          return false
        }
        return true
      },
      validateEmail() {
        if (this.clientRequest.email == '') {
          this.emailErrorMessage = "Email is required."
          return false
        } else if(!utilities.validateEmail(this.clientRequest.email)) {
          this.emailErrorMessage = "Email is invalid."
          return false
        }
        return true
      },
      validateMobile() {
        if (this.clientRequest.mobile == '') {
          this.mobileErrorMessage = "Mobile is required."
          return false
        } else if(!utilities.validatePhoneAU(this.clientRequest.mobile)) {
          this.mobileErrorMessage = "Please enter a valid mobile number in International format without spaces - i.e. +61400123456"
          return false
        }
        return true
      },
      validateCompanyName() {
        if (this.clientRequest.companyName == '') {
          return false
        }
        return true
      },
      validateClient() {
        if (this.client.id == '') {
          return false
        }
        return true
      },
    },
    methods: {
      formatDate(dateToFormat, newFormat) {
        return utilities.formatDate(dateToFormat, newFormat)
      },
      changeClient(index) {
        this.client = this.clients.find(x => x.id == this.client.id)
      },
      openCreateClient() {
        this.$refs.adminCreateClientModal.initModal()
        this.$refs.adminCreateClientModal.loadData()
        this.$refs.adminCreateClientModal.clientRequest = _.cloneDeep(this.clientRequest)
        this.$refs.adminCreateClientModal.handleModal()
      },
      putClientRequest() {
        var params = {
          ClientRequestID: this.clientRequest.id,
          FirstName: this.clientRequest.firstName,
          Surname: this.clientRequest.surname,
          BusinessEmailAddress: this.clientRequest.email,
          Mobile: this.clientRequest.mobile,
          CompanyName: this.clientRequest.companyName,
          CompanyBranchName: this.clientRequest.companyBranchName,
          AddressLine1: this.clientRequest.addressLine1,
          AddressLine2: this.clientRequest.addressLine2,
          City: this.clientRequest.city,
          State: this.clientRequest.state,
          Postcode: this.clientRequest.postcode,
          Country: this.clientRequest.country,
          DateReceived: this.clientRequest.dateReceived,

          // Completed
          IsActioned: 1,
          IsDeleted: 0,

          UserID: this.user.id,
          Username: this.user.name,
        }
        var callback = (response) => {}
        return utilities.putClientRequest(params, callback)
      },
      deleteClientRequest() {
        var params = {
          ClientRequestID: this.clientRequest.id,

          UserID: this.user.id,
          Username: this.user.name,
        }
        var callback = (response) => {}
        return utilities.deleteClientRequest(params, callback)
      },
      async callPostClientContactToZoho(id) {
        this.client = await utilities.getClientById(this.client.id)
        //var contacts = await utilities.getClientContacts(this.client.id)
        var zohoLink = ''
        if (this.client.zohoLink) {
          zohoLink = this.client.zohoLink.split('/')[this.client.zohoLink.split('/').length - 1]
        }
        const contact = this.client.contacts.find(item => item.clientContactId === id)
        console.log('id = ' + id)

        if(contact) {
          let params = {
            FirstName: contact.firstName,
            Surname: contact.surname,
            EmailAddress: contact.emailAddress,
            Mobile: contact.mobile,
            PhoneNumber1: contact.phoneNumber1,
            PhoneNumber2: contact.phoneNumber2,
            UserID: this.user.id,
            Username: this.user.name,
            ClientZohoLink: zohoLink,
            ClientContactID: contact.clientContactId,
            ClientID: this.client.id,
            Status: 1
          }
          await utilities.postClientContactToZoho(params)
        }
      },

      async createContact() {
        if(!this.validateFirstName || !this.validateSurname || !this.validateEmail || !this.validateMobile || !this.validateClient) {
             this.hasError = true
             utilities.showAlert(".alert-danger")
             return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        let phone = this.clientRequest.mobile
        if(this.clientRequest.mobile.charAt(0) == '0' && this.clientRequest.mobile.charAt(1) == '4') phone = "+61"+this.clientRequest.mobile.substring(1)
        const params = {
          FirstName: this.clientRequest.firstName,
          MiddleName: "",
          Surname: this.clientRequest.surname,
          EmailAddress: this.clientRequest.email,
          Mobile: phone,
          PhoneNumber1: "",
          PhoneNumber2: "",
          PrimaryContact: 0,
          CanLogin: utilities.convertBoolToNum(this.canLogin),
          UserRoleID: 1, // Client
          ClientID: this.client.id,

          UserID: this.user.id,
          Username: this.user.name,
        }

        let result
        if (this.canLogin) {
          // Separate API
          result = await utilities.postContactCanLogin(params)
        } else {
          result = await utilities.postContact(params)
        }
        let id = result;
        if(result) result = await this.putClientRequest()
        if(result) {
          await this.callPostClientContactToZoho(id)
          this.successMessage = 'Client contact created successfully.'
          utilities.showAlert(".alert-success")
          $(".icon-group button").attr("disabled", true)
          setTimeout(() => {
            this.$router.push({ name: 'admin-client-contacts', query: { id: id }}).catch((err) => {})
          }, 2000)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async deleteRequest() {
        var answer = await utilities.showWarningDialog("Are you sure you want to delete this client request?")
        if(!answer) return false

        this.$store.dispatch('loading/setLoadComponent', true)
        var result = await this.deleteClientRequest()
        if(result) {
          this.successMessage = 'Client deleted successfully.'
          utilities.showAlert(".alert-success")
          $(".icon-group button").attr("disabled", true)
          setTimeout(() => {
            this.$router.push({ name: 'admin-client-requests', params: {} })
          }, 2000)
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async loadClientRequest() {
        var id = (this.$route.query != undefined && this.$route.query.id != undefined) ? this.$route.query.id : ""
        if(id != '') {
          var clientRequest = await utilities.getClientRequestById(id)
          if(clientRequest != undefined && !clientRequest.isActioned) {
            this.clientRequest = clientRequest
          } else {
            this.clientRequest = undefined
          }
        }
      },
      async loadData(){
        if(this.clientRequest != undefined) {
          this.states = utilities.getStates()
          this.clients = await utilities.getClients()
        }
      }
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadClientRequest()
      await this.loadData()
      utilities.initTooltip()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
