<template>
  <div class="manage-address-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>{{ passedCasesProps.claimantFullName }}'s Address</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <form>
              <div class="form-group">
                <div class="box-body">
                  <div class="box-row">
                    <div class="box-col box-col-left">Address 1</div>
                    <div class="box-col box-col-right">
                      <input id="address-line1" name="address-line1" autocomplete="address-line1" type="text" v-model="claimant.addressLine1" placeholder="Address 1" />
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">Address 2</div>
                    <div class="box-col box-col-right">
                      <input id="address-line2" name="address-line2" autocomplete="address-line2" type="text" v-model="claimant.addressLine2" placeholder="Address 2" />
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">City</div>
                    <div class="box-col box-col-right">
                      <!-- <vue-suggestion
                        :items="filteredCities"
                        :ref="'citySuggestion'"
                        inputClasses="city-suggestion"
                        placeholder="Enter a location"
                        :itemTemplate="citySuggestion"
                        @changed="changeCity($event)"
                        @selected="selectCity($event)"
                        :setLabel="setLabel"
                      >
                      </vue-suggestion> -->
                      <input id="address-level2" autocomplete="address-level2"  name="address-level2" type="text" v-model="claimant.city" placeholder="City" />
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">State</div>
                    <div class="box-col box-col-right select-state">
                      <div v-if="!clickState" @click="handleState">
                        <input id="address-level1" autocomplete="address-level1"  name="address-level1" type="text" readonly v-model="claimant.state" placeholder="Click to choose the State" />
                      </div>
                      <select v-else class="textbox" v-model="claimant.state">
                        <option hidden value="">Please choose ...</option>
                        <option v-for="(state, index) in states" :value="state.value" :key="index">{{ state.text }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="box-row">
                    <div class="box-col box-col-left">Postcode</div>
                    <div class="box-col box-col-right">
                      <input id="postal-code" name="postal-code" autocomplete="postal-code"  type="number" v-model="claimant.postcode" placeholder="Postcode" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="handleModal($event)">Cancel</v-btn>
                    <button style="min-width: 120px;
    min-height: 39px;
    border-radius: 5px;
    text-transform: capitalize;
    background-color: #525252; color: white" dark @click="save">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import CitySuggestion from '@/components/CitySuggestion'

  export default {
    name: 'AdminManageClaimantAddressModal',
    props: ['passedCasesProps'],
    components: {
      CitySuggestion,
    },
    data() {
      return {
        loadModal: false,
        clickState: false,
        clickLocation: false,

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        claimant: utilities.initClaimant(),

        cities: [],
        listCities: [],
        filteredCities: [],
        citySuggestion: CitySuggestion,
        states: utilities.getStates(),

        loaded: false,
      }
    },
    methods: {
      handleModal(e) {
        this.loadModal = !this.loadModal
        if (this.loadModal) {
          $('html').addClass('modal-mask-open')
        } else {
          $('html').removeClass('modal-mask-open')
        }
      },
      handleState() {
        this.clickState = true
      },
      handleLocation() {
        this.clickLocation = true
      },

      async loadData() {
        this.listCities = await utilities.getCities()
        this.filteredCities = this.listCities
      },
      async save() {
        if (this.$parent.singleCase != undefined && this.$parent.singleCase.id != '') {

          this.$parent.singleCase.claimantAddressLine1 = this.claimant.addressLine1
          this.$parent.singleCase.claimantAddressLine2 = this.claimant.addressLine2
          this.$parent.singleCase.claimantCity = this.claimant.city
          this.$parent.singleCase.claimantState = this.claimant.state
          this.$parent.singleCase.claimantPostcode = this.claimant.postcode
          this.$parent.singleCase.claimantCountry = this.claimant.country
          this.$parent.contactKey += 1
          this.handleModal()
        } else {
          this.$parent.singleCase.claimantAddressLine1 = this.claimant.addressLine1
          this.$parent.singleCase.claimantAddressLine2 = this.claimant.addressLine2
          this.$parent.singleCase.claimantCity = this.claimant.city
          this.$parent.singleCase.claimantState = this.claimant.state
          this.$parent.singleCase.claimantPostcode = this.claimant.postcode
          this.$parent.singleCase.claimantCountry = 'Australia'
          this.$parent.contactKey += 1
          this.handleModal()
        }
        // If cancel, don't save
      },
      changeCity($event) {
        this.filteredCities = this.listCities.filter((x) => x.name.toLowerCase().indexOf($event.toLowerCase()) !== -1)
      },
      setLabel(item) {
        return item.name
      },
      selectCity(index, $event) {},
    },
    async beforeMount() {
      await this.loadData()
    },
    mounted() {
      setTimeout(() => {
        $('.vue-suggestion input.city-suggestion').each((index, element) => {
          $(element).focusout(() => {
            this.$refs['citySuggestion' + index][0].searchText = ''
          })
        })
      }, 500)
    },
    updated() {
      if (!this.loaded) {
        this.claimant = {
          addressLine1: this.passedCasesProps.claimantAddressLine1,
          addressLine2: this.passedCasesProps.claimantAddressLine2,
          city: this.passedCasesProps.claimantCity,
          state: this.passedCasesProps.claimantState,
          postcode: this.passedCasesProps.claimantPostcode,
          country: this.passedCasesProps.claimantCountry,
        }
        this.loaded = true
      }
    },
  }
</script>
