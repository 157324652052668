/* eslint-disable */
<template>
  <div class="user-setting">
    <Navigation indexProps="6" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Settings</h1></div>
        <div class="header-sub-title">The settings in this screen apply to all users within your branch / account.  If allowed, this setting can be disallowed for individual bookings.</div>
      </div>
    </div>
    <div class="panel no-sub-menu">
      <div class="content">
        <div class="box">
          <div class="box-heading">
            <span>Details</span>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Allow Red Health to contact Claimants</div>
              <div style="width:80%" class="box-col col-center-content">
                <input type="checkbox" id="checkbox" :sync="true" v-model="client.redHealthMayContactClaimants">
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-warning hidden-element">
          <a class="close" title="close" @click="tapButtonConfirmation(false)">×</a>
          <b>Warning:</b> Are you sure? Your changes will take effect immediately.
          <div class="icon-group center-element">
            <v-btn class="alert-button" @click="tapButtonConfirmation(true)">Yes</v-btn>
            <v-btn class="alert-button" @click="tapButtonConfirmation(false)">No</v-btn>
          </div>
        </div>
        <div class="box-row item-right button-action">
          <div>
            <v-btn dark @click="tapChangeOwnership">Change Ownership of Case</v-btn>
            <v-btn class="submit-request ms-4" @click="tapUpdate()" dark>Update</v-btn>
          </div>
        </div>
        <BranchAdminChangeOwnershipCasesModal ref="adminChangeOwnershipCasesModal"/>
      </div>
    </div>
    <Chat />
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import BranchAdminChangeOwnershipCasesModal from '@/components/BranchAdminChangeOwnershipCasesModal'

export default {
  name: 'UserSetting',
  components: {
    Navigation,
    Header,
    Chat,
    BranchAdminChangeOwnershipCasesModal
  },
  data() {
    return {
      contactClaimants: false,
      successMessage: "",
      client: utilities.initClient(),
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
    }
  },
  methods: {
    tapChangeOwnership() {
      this.$refs.adminChangeOwnershipCasesModal.handleModal()
      console.log(this.client)
      this.$refs.adminChangeOwnershipCasesModal.setCaseOwners(this.client.contacts)
    },
    async tapButtonConfirmation(states) {
      utilities.hideAlert(".alert-warning")
      var setting = {
        ClientID: this.user.clientId,
        ClientName: this.client.name,
        CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        UserID: this.user.id,
    	  Username: this.user.name,
        RedHealthMayContactClaimants: utilities.convertBoolToNum(this.client.redHealthMayContactClaimants),
	      BranchName: this.client.branchName,

      }
      var callback = (response) => {
        this.successMessage = "Your changes have been updated."
        utilities.showAlert(".alert-success")
      }
      await utilities.putClientSetting(setting, callback);

    },

    async tapUpdate() {
      utilities.showAlert(".alert-warning", false)
    }
  },

  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    this.client = await utilities.getClientById(this.user.clientId)
    const {contacts} = await utilities.getClientContactsNew({
      PageNum: 1,
      PageSize: 100,
      SortFlag: 0,
      ClientID: this.user.clientId,
    })
    this.client.contacts = contacts
    this.$store.dispatch('loading/setLoadComponent', false)
  },

  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
}
</script>
