<template>
  <div class="export-specialists-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            Please wait, this file is being generated for you. This may take a few seconds.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "ExportSpecialistsModal",
  data() {
    return {
      loadModal: false,

      note: "",
    }
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
  },
  mounted() {

  },
  updated() {

  },
}
</script>
