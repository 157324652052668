/* eslint-disable */
<template>
  <div class="header">
    <div class="logo">
      <router-link :to="{ path: '/', params: {} }"><img src="@/assets/images/red_health_logo.svg" /></router-link>
    </div>
    <div class="menu-icon" @click="openNav"><img src="@/assets/images/menu_icon.svg" /></div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      isOpenNav: false,
    }
  },
  methods: {
    openNav() {
      this.isOpenNav = !this.isOpenNav
      if(this.isOpenNav) {
        // $(".header").closest(".container").addClass("modal-mask")
        $(".navigation").fadeIn()
        $(".sub-menu").fadeIn()
      } else {
        // $(".header").closest(".container").removeClass("modal-mask")
        $(".navigation").fadeOut()
        $(".sub-menu").fadeOut()
      }
    },
  },
}
</script>
