<template>
  <div class="manage-single-contact-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>Specialist {{ specialist.fullName }} 's Contact</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
            <form autocomplete="off">
              <div class="form-group">
                <div class="box">
                  <div class="box-body">
                    <div class="box-row">
                      <div class="box-col box-col-left">Primary Phone</div>
                      <div class="box-col box-col-right">
                        <input autocomplete="new-password" type="tel" v-model="specialist.phone1"  :class="hasError && !validatePhone1 ? 'is-invalid' : ''" placeholder="Land line" />
                        <div v-if="hasError && !validatePhone1" class="text-danger">Primary Phone is invalid.</div>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">Mobile</div>
                      <div class="box-col box-col-right">
                        <input autocomplete="new-password" type="tel" :class="hasError && !validatePhone ? 'is-invalid' : ''" v-model="specialist.mobile" placeholder="Mobile number" />
                        <div v-if="hasError && !validatePhone" class="text-danger">Mobile is required.</div>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">Work Number</div>
                      <div class="box-col box-col-right">
                        <input autocomplete="new-password" type="tel" v-model="specialist.phone2" :class="hasError && !validateWorkPhone1 ? 'is-invalid' : ''" placeholder="Work number" />
                        <div v-if="hasError && !validateWorkPhone1" class="text-danger">Work Number is invalid.</div>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">Email Address</div>
                      <div class="box-col box-col-right">
                        <input autocomplete="new-password" type="text" :class="hasError && !validateEmail ? 'is-invalid' : ''" v-model="specialist.email" placeholder="Email address" />
                        <div v-if="hasError && !validateEmail" class="text-danger">{{ emailMessage }}</div>
                      </div>
                    </div>
                    <div class="box-row">
                      <div class="box-col box-col-left">Notification Email Address</div>
                      <div class="box-col box-col-right">
                        <input autocomplete="new-password" type="text" :class="hasError && !validateNotificationEmail ? 'is-invalid' : ''" v-model="specialist.NotificationEmailAddress" placeholder="Email address" />
                        <div v-if="hasError && !validateNotificationEmail" class="text-danger">{{ notificationMessage }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert alert-success hidden-element">
                <a class="close" title="close">×</a>
                <b>Success:</b> {{ successMessage }}
              </div>
              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> {{ errorMessage }}
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn class="white-button" dark @click="resetModel(); handleModal($event)">Cancel</v-btn>
                    <v-btn dark @click="save">Save</v-btn>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: "AdminManageSingleContactModal",
  components: {},
  data() {
    return {
      userParam: {
        PageNum: 1,
        PageSize: 10,
        EmailAddress: null
      },
      loadModal: false,
      hasError: false,
      notificationMessage: '',
      successMessage: "Contact updated successfully.",
      errorMessage: "Please correct the errors shown above and try again.",
      emailMessage: "",

      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      specialist: utilities.initSpecialist(),
      oldSpecialist: [],
      users: [],
    }
  },
  computed: {
    validateEmail() {
      if(this.specialist.isUser && this.specialist.email == '') {
        this.emailMessage = "Email is required."
        return false
      } else if(!utilities.validateEmail(this.specialist.email)) {
        this.emailMessage = "Email is incorrect."
        return false
      }
      this.emailMessage = ""
      return true
    },
    validateNotificationEmail() {
      if(this.specialist.NotificationEmailAddress.trim() !== '' && !utilities.validateEmail(this.specialist.NotificationEmailAddress)) {
        this.notificationMessage = "Email is incorrect."
        return false
      }
      return true
    },
    validatePhone() {
      if(this.specialist.isUser && this.specialist.mobile == '') {
        return false
      }
      return true
    },
    validatePhone1() {
      if(this.specialist.phone1 != '' && !utilities.validateLandPhone(this.specialist.phone1)) {
        return false
      }
      return true
    },
    validateWorkPhone1() {
      if(this.specialist.phone2 != '' && !utilities.validateLandPhone(this.specialist.phone2)) {
        return false
      }
      return true
    },
  },
  methods: {
    resetModel() {
      this.specialist = _.cloneDeep(this.oldSpecialist)
    },
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },
    async update() {
      this.$parent.specialist.phone1 = utilities.validatePhonenumber(this.specialist.phone1)
      this.$parent.specialist.phone2 = utilities.validatePhonenumber(this.specialist.phone2)
      this.$parent.specialist.mobile = utilities.updateMobileCode(utilities.validatePhonenumber(this.specialist.mobile))
      this.$parent.specialist.email = this.specialist.email
      this.$parent.specialist.NotificationEmailAddress = this.specialist.NotificationEmailAddress
      this.$parent.specialist.contactDetails = utilities.getSpecialistContact(this.specialist)
      if(this.$parent.specialist.isUser && this.$parent.specialist.id != '' && (this.oldSpecialist.email != this.specialist.email)) {
        //this.$parent.specialist.createUserType = 'put'
        this.oldSpecialist = _.cloneDeep(this.$parent.specialist)
        return this.$parent.postCognitoUser('put')
      } else {
        this.oldSpecialist = _.cloneDeep(this.$parent.specialist)
        return true
      }
    },
    async save() {
      if(!this.validateEmail || !this.validatePhone || !this.validatePhone1 || !this.validateWorkPhone1 || !this.validateNotificationEmail) {
        this.hasError = true
        return false
      } else {
        this.hasError = false
      }
      this.$store.dispatch("loading/setLoadComponent", true)
      if(this.specialist.isUser && (this.oldSpecialist.email != this.specialist.email)) {
        var check = await utilities.checkDuplicate(this.specialist.email, '1')
        if (check == 1) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-error">' +
                '            <b>Error:</b> Email Address Already Exists!' +
                '          </div>',
          });
          this.$store.dispatch("loading/setLoadComponent", false)
          return
        }
      }
      if(this.specialist.id != '') {
        var result = await this.update()
        if(result) {
          await this.putContact()
          utilities.showAlert(".manage-single-contact-modal .alert-success")
          if(this.$parent.oldSpecialist) this.$parent.oldSpecialist = _.cloneDeep(this.specialist)
          setTimeout(() => {
            this.handleModal()
          }, 2000)
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      } else {
        this.$store.dispatch("loading/setLoadComponent", false)
        await this.update()
        this.handleModal()
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    async putContact() {
      if(!this.$parent.specialist.isUser && (this.oldSpecialist.email != this.specialist.email)) {
        var check = await utilities.checkDuplicate(this.specialist.email, '1')
        if (check == 1) {
          this.errorMessage = "Email Address Already Exists!"
          utilities.showAlert(".alert-danger")
          return
        }
      }
      var zohoId = this.$parent.specialist.zohoLink.split("/");
      zohoId = zohoId[zohoId.length - 1];

      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.specialist.id,
        Title: this.$parent.specialist.title,
        FirstName: this.$parent.specialist.firstName,
        Surname: this.$parent.specialist.lastName,
        PhoneNumber1: utilities.validatePhonenumber(this.specialist.phone1),
        PhoneNumber2: utilities.validatePhonenumber(this.specialist.phone2),
        Mobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.specialist.mobile)),
        EmailAddress: this.specialist.email,
        NotificationEmailAddress: this.specialist.NotificationEmailAddress,
        ZohoLink: zohoId,
        ProfileUpdateOnly: 0
      }
      var callback = () => {

      }
      return utilities.putSpecialist(params, callback)
    },

  },
  async beforeMount() {
    this.oldSpecialist = _.cloneDeep(this.$parent.specialist)
  },
  mounted() {},
}
</script>
