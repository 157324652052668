/* eslint-disable */
<template>
  <div class="manage-location">
    <AdminNavigation ref="navigation" indexProps="9"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Locations</h1></div>
        <div class="header-sub-title">Locations are available for Specialists to operate from. Locations belong to a
          region. Regions support specialist searches.
        </div>
      </div>
    </div>
    <div class="panel">
      <admin-settings-menu indexProps="2-3"/>
      <div class="content">
        <div v-if="location != undefined">
          <div class="box">
            <div class="box-heading">
              <span>Location Details</span>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Specialist</div>
                <div class="box-col box-col-right">
                  <el-select placeholder="Click here to select a specialist" class="w-50" clearable filterable v-model="location.specialistId">
                    <el-option v-for="specialist in specialists" :value="specialist.id" :key="specialist.id" :label="specialist.fullName">{{ specialist.fullName }}</el-option>
                  </el-select>
                  <div class="pl-2">If this location is only used by a particular specialist, please select the specialist.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Location Name</div>
                <div class="box-col box-col-right">
                  <input type="text" class="pl-1" :class="hasError && !validateLocationName ? 'is-invalid' : ''"
                         v-model="location.name" placeholder="Location name"/>
                  <div v-if="hasError && !validateLocationName" class="text-danger">Location name is required</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Phone Number</div>
                <div class="box-col box-col-right">
                  <input type="text" class="pl-1" v-model="location.phoneNumber" placeholder="Phone Number"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Country</div>
                <div class="box-col box-col-right">
                  <el-select @change="onChangeCountry" class="w-50" v-model="location.country" filterable clearable
                             placeholder="Select Country">
                    <el-option v-for="item in countries" :key="item.CountryID" :value="item.CountryCode"
                               :label="decodeURI(item.CountryName)"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">State</div>
                <div class="box-col box-col-right">
                  <el-select @change="onChangeState" class="w-50" v-model="location.state" filterable clearable placeholder="Select State">
                    <el-option v-for="item in states" :key="item.StateID" :value="item.StateCode"
                               :label="decodeURI(item.StateName)"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Region</div>
                <div class="box-col box-col-right">
                  <el-select @change="onChangeRegion" class="w-50" v-model="location.regionId" filterable clearable placeholder="Select Region">
                    <el-option v-for="item in regions" :key="item.RegionID" :value="item.RegionID"
                               :label="decodeURI(item.RegionName)"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">City</div>
                <div class="box-col box-col-right">
                  <el-select @change="onChangeCity" class="w-50" v-model="location.cityId" filterable clearable placeholder="Select City">
                    <el-option v-for="item in cities" :key="item.CityID" :value="item.CityID"
                               :label="decodeURI(item.CityName)"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Address Line 1</div>
                <div class="box-col box-col-right">
                  <input type="text" class="pl-1" v-model="location.address1" placeholder="Address Line 1"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Address Line 2</div>
                <div class="box-col box-col-right">
                  <input type="text" class="pl-1" v-model="location.address2" placeholder="Address Line 2"/>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Postcode</div>
                <div class="box-col box-col-right">
                  <input type="text" class="pl-1" placeholder="Enter postcode" v-model="location.postcode"
                         :class="hasError && !validatePostcode ? 'is-invalid' : ''"/>
                  <div v-if="hasError && !validatePostcode" class="text-danger">Please enter a valid postcode.</div>
                </div>
              </div>
<!--              <div class="box-row">-->
<!--                <div class="box-col box-col-left">Geolocation</div>-->
<!--                <div class="box-col box-col-right">-->
<!--                  <input type="text" v-model="location.latitude" placeholder="Latitude"-->
<!--                         :class="hasError && !validateLatitude ? 'is-invalid' : ''"/>-->
<!--                  <input type="text" v-model="location.longitude" placeholder="Longitude"-->
<!--                         :class="hasError && !validateLongitude ? 'is-invalid' : ''"/>-->
<!--                  <div v-if="hasError && (!validateLatitude || !validateLongitude)" class="text-danger">Please enter-->
<!--                    valid-->
<!--                    coordinates.-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <AddCityModal ref="addCityModal" :regions="regions"/>
          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="right-element">
            <v-btn dark @click="submit">
              {{ location.id == '' ? 'Create' : 'Update' }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminSettingsMenu from '@/components/AdminSettingsMenu'
import Header from '@/components/Header'
import AddCityModal from '@/components/AddCityModal'

export default {
  name: 'AdminLocationsAndRegions',
  components: {
    AdminNavigation,
    AdminSettingsMenu,
    Header,
    AddCityModal,
  },
  data() {
    return {
      hasError: false,
      successMessage: '',
      errorMessage: 'Please correct the errors shown above and try again.',
      location: utilities.initLocation(),
      specialistFocus: false,
      cityFocus: false,
      stateFocus: false,
      postcodeFocus: false,
      countryFocus: false,
      regionFocus: false,
      specialists: [],
      originRegions: [],
      regions: [],
      originCities: [],
      cities: [],
      originStates: [],
      states: [],
      countries: [],
      loaded: false,
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      oldLocation: [],
      listParam: {
        PageSize: 999,
        PageNum: 1
      }
    }
  },
  watch: {
    location: {
      handler(value) {
        if (this.loaded) this.$store.dispatch('setNewObject', value)
      },
      deep: true,
    },
  },
  computed: {
    validateLocationName() {
      return this.location.name === '' ? false : true
    },
    validateCity() {
      return this.location.cityId === '' ? false : true
    },
    validatePostcode() {
      var regExp = new RegExp('^[0-9]{4}$')
      if (this.location.postcode !== '' && !regExp.test(this.location.postcode)) return false
      return true
    },
    validateLatitude() {
      var regExp = new RegExp('^-?[0-9]+\.[0-9]+$')
      if (this.location.latitude !== '' && !regExp.test(this.location.latitude)) return false
      return true
    },
    validateLongitude() {
      var regExp = new RegExp('^-?[0-9]+\.[0-9]+$')
      if (this.location.longitude !== '' && !regExp.test(this.location.longitude)) return false
      return true
    },
  },
  methods: {
    onChangeCountry(v) {
      if(v) {
        this.states = this.originStates.filter(item => item.CountryCode === v)
      } else {
        this.states = this.originStates
      }
      this.location.state = null
      this.location.regionId = null
      this.location.cityId = null
    },
    onChangeState(v) {
      if(v) {
        this.regions = this.originRegions.filter(item => item.StateCode === v)
      } else {
        this.regions = this.originRegions
      }
      this.location.regionId = null
      this.location.cityId = null
      this.location.country = this.originStates.find(item => item.StateCode === this.location.state)?.CountryCode
    },
    onChangeRegion(v) {
      if(v) {
        this.cities = this.originCities.filter(item => item.RegionID === v)
      } else {
        this.cities = this.originCities
      }
      this.location.cityId = null
      this.location.state = this.originRegions.find(item => item.RegionID === v)?.StateCode
      this.location.country = this.originStates.find(item => item.StateCode === this.location.state)?.CountryCode
    },
    onChangeCity(v) {
      this.location.cityName = this.originCities.find(item => item.CityID === v)?.CityName
      this.location.regionId = this.originCities.find(item => item.CityID === v)?.RegionID
      this.location.state = this.originRegions.find(item => item.RegionID === this.location.regionId)?.StateCode
      this.location.country = this.originStates.find(item => item.StateCode === this.location.state)?.CountryCode
    },
    async submit() {
      this.hasError = false
      if (!this.validateLocationName || !this.validateCity || !this.validatePostcode) {
        this.hasError = true
        return
      }

      this.$store.dispatch('loading/setLoadComponent', true)
      var params = {
        LocationName: this.location.name,
        AddressLine1: this.location.address1,
        AddressLine2: this.location.address2,
        CityID: this.location.cityId,
        State: this.location.state,
        RegionID: this.location.regionId,
        Country: this.location.country,
        Postcode: this.location.postcode,
        PhoneNumber: this.location.phoneNumber,
        SpecialtyID: this.location.specialistId,
        UserID: this.user.id,
        Username: this.user.name,
      }

      // if (this.location.latitude == '' || this.location.longitude == '') {
      //   var coordinates = await utilities.getGeoLocation(this.location.name + ', ' + this.location.address1 + ',' + this.location.address2 + ',' + this.location.cityName + ',' + this.location.state + ',' + this.location.country)
      //   params.Latitude = coordinates.lat
      //   params.Longitude = coordinates.lng
      //   this.location.latitude = coordinates.lat
      //   this.location.longitude = coordinates.lng
      // } else {
      //   params.Latitude = this.location.latitude
      //   params.Longitude = this.location.longitude
      // }
      var callback = (response) => {
      }
      if (this.location.id != '') {
        params.LocationID = this.location.id
        params.SpecialistID = this.location.specialistId
        var res = await utilities.putLocation(params, callback)
        if (res) {
          if (this.location.specialistId != this.oldLocation.specialistId) {
            this.putTravelTo()
          } else if (this.location.specialistId && this.oldLocation.specialistId == "") {
            this.postTravel(this.location.id)
          }
          utilities.clearChangingObject()
          this.successMessage = 'Location updated successfully.'
          utilities.showAlert('.alert-success')
        } else {
          this.errorMessage = 'An error occurred.'
          utilities.showAlert('.alert-danger')
        }
      } else {
        if (this.location.specialistId != '') params.SpecialistID = this.location.specialistId
        var res = await utilities.postLocation(params, callback)
        if (res) {
          if (this.location.specialistId) {
            this.postTravel(res)
          }
          utilities.clearChangingObject()
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: '<div class="alert-success">' +
                'Location created successfully !' +
                '</div>',
          });
          utilities.showAlert('.alert-success')
          setTimeout(() => {
            this.$router.back()
          }, 500)
        } else {
          this.errorMessage = 'An error occurred.'
          utilities.showAlert('.alert-danger')
        }
      }
      this.oldLocation = _.cloneDeep(this.location)
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async putTravelTo() {
      var travels = await utilities.getTravels(this.oldLocation.specialistId)
      var travaltoId = travels.find((x) => x.locationId == this.location.id)
      if (travaltoId) {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistID: this.location.specialistId,
          SpecialistTravelsToID: travaltoId.id,
          LocationID: this.location.id,
          RequiresTravel: true,
        }
        var callback = (response) => {

        }
        return utilities.putTravel(params, callback)
      }
    },

    async postTravel(locationId) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        SpecialistID: this.location.specialistId,
        SpecialistTravelsToID: "",
        LocationID: locationId,
        RequiresTravel: true,
      }
      var callback = (response) => {
        this.specialist.travels[index].id = response
      }
      return utilities.postTravel(params, callback)
    },


    updateRegion() {
      if (this.location.cityId == 'new') {
        //show popup
        this.$refs.addCityModal.handleModal()
      } else {
        this.location.cityName = this.cities.find((x) => {
          return x.id == this.location.cityId
        }).name
        this.location.regionId = this.cities.find((x) => {
          return x.id == this.location.cityId
        }).regionId
      }
    },

    async loadRegion() {
      if (this.$route.query != undefined && this.$route.query.locationId !== undefined) {
        this.location.id = this.$route.query.locationId
        if (this.location.id) {
          this.location = await utilities.getLocationByLocationId(this.location.id)
          console.log(this.location)
          this.oldLocation = _.cloneDeep(this.location)
        }

        if (this.location == undefined) return
      }

      if (this.$route.query != undefined && this.$route.query.specialistId !== undefined) this.location.specialistId = this.$route.query.specialistId

      this.$store.dispatch('setOriginalObject', this.location)
      this.$store.dispatch('setNewObject', this.location)
    },
    async loadData() {
      const apiArr = [utilities.getSpecialists(), utilities.getCountries(this.listParam), utilities.getStatesNew(this.listParam), utilities.getRegionsNew(this.listParam), utilities.getCitiesNew(this.listParam)]
      const r = await Promise.all(apiArr)
      this.specialists = r[0]
      this.countries = r[1].data
      this.originStates = r[2].data
      this.states = r[2].data
      this.originRegions = r[3].data
      this.regions = r[3].data
      this.originCities = r[4].data
      this.cities = r[4].data

    },
  },
  async beforeMount() {
    this.$store.dispatch('loading/setLoadComponent', true)
    $('body>.tooltip').remove()
    await this.loadData()
    await this.loadRegion()
    this.loaded = true

    this.$store.dispatch('loading/setLoadComponent', false)
  },
  mounted() {
    let scripts = document.querySelectorAll('script');
    var added = false
    for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].src == 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ4ZI98UvV5tH4FnGFmlO-jmS46pmLDRU') {
        added = true
        break
      }
    }
    if (!added) {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ4ZI98UvV5tH4FnGFmlO-jmS46pmLDRU')
      document.head.appendChild(externalScript)
    }
  },
}
</script>
