/* eslint-disable */
<template>
  <div class="bookings-waitlisted">
    <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="5" />
    <Navigation v-else ref="navigation" indexProps="5"/>
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Waitlisted Bookings</h1></div>
        <div class="header-sub-title">The following bookings are Waitlisted.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu v-if="user.type >= 3" indexProps="7" />
      <ClientBookingMenu v-else indexProps="6"/>
      <div class="content">
        <div class="row">
          <div class="col pt-0 pl-0">Total Waitlisted Bookings:  {{ totalCount}}</div>
          <el-input style="max-width: 120px" class="mr-1" @input="search()" clearable placeholder="ID" v-model="listParam.WaitListPreBookingID"/>
          <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Specialist Name" v-model="listParam.SpecialistName"/>
          <el-input v-if="user.type >= 3" style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
          <el-input v-if="user.type >= 3"  style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactName"/>
          <el-date-picker
              style="width: 280px"
              v-model="dateValue"
              type="daterange"
              start-placeholder="Booking Date Start"
              popper-class="date-range-picker-popper"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              @change="changeDates"
              end-placeholder="Booking Date End"
              clearable
          />
        </div>

        <div class="content-scroll-x">
          <div>
            <el-table ref="bookingTable" :default-sort="{prop: 'WaitListPreBookingID', order: 'descending'}" stripe @sort-change="sortParam" :data="bookings" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
              <el-table-column
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                  label="ID"
                  prop="WaitListPreBookingID"
              >
              </el-table-column>
              <el-table-column
                  width="200"
                  sortable="custom"
                  show-overflow-tooltip
                  label="Specialist"
                  prop="SpecialistName"
              >
              </el-table-column>
              <el-table-column
                  v-if="user.type >= 3"
                  width="200"
                  sortable="custom"
                  show-overflow-tooltip
                  label="Client"
                  prop="ClientName"
              >
                <template slot-scope="scope">
                  <span>{{decodeURIComponent(scope.row.ClientName)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  sortable="custom"
                  show-overflow-tooltip
                  label="Contact Name"
                  prop="ContactName"
              >
                <template slot-scope="scope">
                  <span>{{decodeURIComponent(scope.row.ContactName)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  v-if="user.type >= 3"
                  width="150"
                  show-overflow-tooltip
                  label="Phone/Email"
              >
                <template slot-scope="booking">
                  <router-link :to="{ name: 'admin-manage-client', query: { id: booking.row.clientId, type:'edit-contact' } }">
                    <span>{{ getFullPhoneEmail(booking.row.ContactPhone, booking.row.ContactEmail) }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  show-overflow-tooltip
                  label="Claimant Name"
                  prop="ClaimantName"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.ClaimantFirstName + ' ' + scope.row.ClaimantSurname}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  show-overflow-tooltip
                  label="Reference Number"
                  prop="CaseReference"
              >
              </el-table-column>
              <el-table-column
                  width="200"
                  show-overflow-tooltip
                  label="Requested Date"
                  prop="RequestedDay"
              >
                <template slot-scope="scope">
                  <span>{{getTime(scope.row.RequestedDay)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  width="200"
                  show-overflow-tooltip
                  fixed="right"
                  label="Actions"
              >
                <template slot-scope="scope">
                  <el-button @click="toBooking(scope.row)" type="primary" size="mini">Book</el-button>
                  <el-popconfirm
                      title="Are you sure you wish to remove it？"
                      @confirm="removeBooking(scope.row)"
                  >
                  <el-button class="ml-2"  size="mini" slot="reference">Remove</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import Navigation from "@/components/Navigation";
import ClientBookingMenu from "@/components/ClientBookingMenu";
import { debounce } from 'lodash';

export default {
  name: "AdminBookingsWaitlisted",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
    Navigation,
    ClientBookingMenu
  },
  data() {
    return {
      dateValue: '',
      bookings: [],
      allBookings: [],
      limit: 10,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      pagination: utilities.initPagination(),
      totalCount: 0,
      listParam: {
        WaitListPreBookingID: null,
        BookingTypeID: null,
        ClientName: '',
        SpecialistName: '',
        ClaimantName: '',
        ContactName: '',
        StartDate: '',
        EndDate: '',
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
      },
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    toBooking(item) {
      let bookingParams = {
        id: item.SpecialistID,
        WaitListPreBookingID: item.WaitListPreBookingID,
        typeId: item.BookingTypeID,
        typeName: item.BookingTypeName,
        waitlisted: true,
        clientID: item.ClientID,
        caseOwner: item.CaseOwner,
        claimantFirstName: item.ClaimantFirstName,
        caseReference: item.CaseReference,
        claimantSurname: item.ClaimantSurname,
        dateofBirth: window.moment(item.DateofBirth).format('yyyy-MM-DD'),
        regionId: item.RegionID,
        endDate: item.RequestedDay,
        locationId: item.LocationID,
        bookingDate: item.RequestedDay,
        isSingleId: true,
        isFromSpecialist: true,
      }
      this.$store.dispatch("booking/setBookingObject", bookingParams)
      if(this.user.type >= 3) {
        this.$router.push({ name: 'admin-availability-bookings', query: { id: item.SpecialistID, date: item.RequestedDay } })
      } else {
        this.$router.push({ name: 'booking-single-calendar', query: { id: item.SpecialistID, date: item.RequestedDay } })
      }
    },
    async removeBooking(item) {
      this.$store.dispatch("loading/setLoadComponent", true)
      let obj = {
        Status: 2,
        UserID: this.user.id,
        Username: this.user.name
      }
      await utilities.putWaitlistBooking(item.WaitListPreBookingID, obj)
      this.$store.dispatch("loading/setLoadComponent", false)
      this.selectPage(1)
    },
    changeDates(v) {
      if(v) {
        this.listParam.StartDate = v[0]
        this.listParam.EndDate = v[1]
      } else {
        this.listParam.StartDate = null
        this.listParam.EndDate = null
      }
      this.selectPage(1)
    },
    getTime(item) {
      return utilities.formatDate(item, 'DD/MM/YYYY')
    },
    sortParam(v) {
      if(v.prop === 'WaitListPreBookingID' && v.order === "descending") {
        this.listParam.SortFlag = 0
      } else if (v.prop === 'ClientName' && v.order === "descending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'ClientName' && v.order === "ascending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'SpecialistName' && v.order === "ascending") {
        this.listParam.SortFlag = 4
      } else if (v.prop === 'SpecialistName' && v.order === "descending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'ContactName' && v.order === "descending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === 'ContactName' && v.order === "descending") {
        this.listParam.SortFlag = 6
      }
      this.selectPage(1)
    },
    getFullPhoneEmail(phone, email) {
      let fullPhoneEmail = phone + (phone !== '' && email !== '' ? " / " : "") + email
      return fullPhoneEmail
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },
    formatDate(dateToFormat, newFormat, currentFormat = "") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },

    async loadBookings() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {data, total} = await utilities.getBookingWaitlist(this.listParam)
      this.bookings = _.cloneDeep(data)
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    await this.loadBookings()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
