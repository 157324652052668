/* eslint-disable */
<template>
  <div class="bookings-menu sub-menu">
    <ul>
      <li><router-link :to="{ name: 'admin-bookings', params: {} }">All</router-link></li>
      <li><router-link :to="{ name: 'admin-booking-requests', params: {} }">Booking Requests</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-awaiting-paperwork', params: {} }">Awaiting Paperwork</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-awaiting-consent', params: {} }">Awaiting Consent</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-requiring-action', params: {} }">Requiring Action</router-link></li>
      <li><router-link :to="{ name: 'admin-booking-clashes', params: {} }">Booking Clashes</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-tentative', params: {} }">Tentative</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-waitlisted', params: {} }">Waitlisted</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-cancelled', params: {} }">Cancelled</router-link></li>
      <li><router-link :to="{ name: 'admin-updated-cases', params: {} }">Recently Updated Cases</router-link></li>
      <li><router-link :to="{ name: 'admin-bookings-workflow', params: {} }">Admin Workflow</router-link></li>
      <!-- <li><router-link :to="{ name: 'admin-search-specialist', params: {} }">Add Booking</router-link></li>
      <li class="is-icon" @click="openSearch">Search Bookings</li> -->
    </ul>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
export default {
  name: 'AdminBookingsMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {
    openSearch() {
      this.$parent.$refs.navigation.openSearchBooking()
    },
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
