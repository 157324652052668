/* eslint-disable */
<template>
  <div class="specialist-ava-menu sub-menu">
    <ul>
      <li><router-link to="/specialist-availability">Availability</router-link></li>
      <li @click="exportPdf()" class="is-icon">Download</li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SpecialistAvailabilityMenu',
  props: ["indexProps"],
  data() {
    return {

    }
  },
  methods: {
    exportPdf() {
      window.scrollTo(0, 0)
      this.$parent.exportPdf()
    }
  },
  beforeMount() {
    if(this.indexProps == undefined) {
      this.indexProps = 0
    }
  },
  mounted() {
    $(".sub-menu li").eq(this.indexProps).addClass("sub-menu-selected")
  },
}
</script>
