<template>
  <div class="locations-and-cities">
    <AdminNavigation ref="navigation" indexProps="9"/>
    <div class="top-panel">
      <Header/>
      <div class="header-title-section">
        <div class="header-title"><h1>Cities</h1></div>
      </div>
    </div>
    <div class="panel">
      <AdminSettingsMenu indexProps="2-5"/>
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-select @change="selectPage(1)" style="max-width: 200px" placeholder="Select Region"  class="mr-1" v-model="listParam.regionID" filterable  clearable>
                <el-option v-for="item in regionList" :key="item.RegionID" :value="item.RegionID"
                           :label="decodeURI(item.RegionName)"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                <tr>
                  <th style="width:20%" class="long-text">City Name</th>
                  <th style="width:65%" class="long-text">Region</th>
                  <th style="width:15%;vertical-align:middle" class="icon-col">
                    <div class="icon-group justify-content-center align-items-center">
                      <v-btn class="add-icon" @click="dialogVisible = true" dark>Add New</v-btn>
                    </div>
                  </th>
                </tr>
                </thead>
              </table>
            </div>
            <!-- <div :class="locations.length > 5 ? 'content-scroll-y' : ''"> -->
            <div>
              <table class="table">
                <tbody>
                <tr v-for="city in cities" :key="city.CityID">
                  <td style="width:20%" class="long-text">{{ decodeURI(city.CityName) }}</td>
                  <td style="width:65%" class="long-text">{{ decodeURI(city.RegionName) }}</td>
                  <td style="width:15%; vertical-align:middle" class="icon-col">
                    <div class="icon-group justify-content-center align-items-center">
                      <img @click="currentObj=$_.cloneDeep(city); dialogVisible=true" data-toggle="tooltip" data-placement="right"
                           title="Edit City" src="@/assets/images/edit_icon.svg"/>
                      <img data-toggle="tooltip" data-placement="right"
                           title="Delete City" @click="showDelete(city)" src="@/assets/images/delete_icon.svg"/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
    <el-dialog
        class="edit-zoho-dialog"
        :title="currentObj.CityID ? 'Edit City' : 'Add City'"
        :visible.sync="dialogVisible"
        @close="closeModal"
        width="50%">
      <div>
        <el-form ref="ruleForm" :model="currentObj" label-width="110px" :rules="rules">
          <el-form-item label="City Name" prop="CityName">
            <el-input placeholder="City Name" v-model="currentObj.CityName"></el-input>
          </el-form-item>
          <el-form-item label="Region" prop="RegionID">
            <el-select class="w-100" v-model="currentObj.RegionID" filterable clearable placeholder="Select Region"
                       :loading="loadingSelect">
              <el-option v-for="item in regionList" :key="item.RegionID" :value="item.RegionID"
                         :label="decodeURI(item.RegionName)"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <v-btn class="white-button me-2" dark @click="dialogVisible = false">Cancel</v-btn>
    <v-btn dark :loading="loading" @click="submitForm('ruleForm')">Save</v-btn>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import AdminNavigation from '@/components/AdminNavigation'
import AdminSettingsMenu from '@/components/AdminSettingsMenu'
import Header from '@/components/Header'

export default {
  name: 'AdminLocationsAndRegions',
  components: {
    AdminNavigation,
    AdminSettingsMenu,
    Header,
  },
  data() {
    return {
      currentObj: {
        CityName: '',
        RegionID: null,
      },
      loadingSelect: false,
      regionList: [],
      rules: {
        CityName: [
          {required: true, message: 'Please input city name', trigger: 'blur'},
        ],
        RegionID: [
          {required: true, message: 'Please select region', trigger: 'change'},
        ]
      },
      loading: false,
      cities: [],
      dialogVisible: false,
      totalCount: 0,
      listParam: {
        PageNum: 1,
        PageSize: 10,
        regionID: null
      }
    }
  },
  methods: {
    closeModal() {
      this.$refs.ruleForm.resetFields()
      this.currentObj = {
        CityName: '',
        RegionID: null,
      }
    },
    showDelete(c) {
      this.$confirm({
        content: `Are you sure you wish to delete the city: ${c.CityName}?`,
        onConfirm: () => {
          utilities.deleteCitiesNew(c.CityID).then(() => {
            this.$notify({
              dangerouslyUseHTMLString: true,
              message: '<div class="alert-success">' +
                  '            <b>Success:</b> City Deleted !' +
                  '          </div>',
            });
            this.selectPage(1)
          })
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.currentObj.CityID) {
            utilities.putCityNew(this.currentObj).then(() => {
              this.loading = false
              this.dialogVisible = false
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: '<div class="alert-success">' +
                    '            <b>Success:</b> City Updated !' +
                    '          </div>',
              });
              this.selectPage(1)
            }).catch(() => {
              this.loading = false
            })
          } else {
            utilities.postCitiesNew(this.currentObj).then(() => {
              this.loading = false
              this.dialogVisible = false
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: '<div class="alert-success">' +
                    '            <b>Success:</b> City Created !' +
                    '          </div>',
              });
              this.selectPage(1)
            }).catch(() => {
              this.loading = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.getData()
    },
    async getData() {
      this.$store.dispatch('loading/setLoadComponent', true)
      const {data, count} = await utilities.getCitiesNew(this.listParam)
      this.cities = data
      this.totalCount = count
      this.$store.dispatch('loading/setLoadComponent', false)
    },
  },
  async beforeMount() {
    await this.getData()
    const {data} = await utilities.getRegionsNew({PageNum: 1, PageSize: 999})
    this.regionList = data
  },
  mounted() {
  },
}
</script>
