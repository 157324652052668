/* eslint-disable */
<template>
  <div>
    <div class="bookings-tentative">
      <AdminNavigation v-if="user.type >= 3" ref="navigation" indexProps="5"/>
      <Navigation v-else ref="navigation" indexProps="2"/>
      <div class="top-panel">
        <Header/>
        <div class="header-title-section">
          <div class="header-title"><h1>Tentative Bookings</h1></div>
          <div class="header-sub-title">The following bookings have a Tentative status.</div>
        </div>
      </div>
      <div class="panel">
        <AdminBookingsMenu v-if="user.type >= 3" indexProps="6"/>
        <ClientBookingMenu v-else indexProps="5"/>
        <div class="content">
          <div class="row">
            <div class="col pt-0 pl-0">Total Tentative Bookings: {{totalCount }}</div>
            <el-input style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Specialist Name" v-model="listParam.SpecialistName"/>
            <el-input v-if="user.type >= 3" style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
            <el-input v-if="user.type >= 3" style="max-width: 200px" class="mr-1" @input="search()" clearable placeholder="Contact Name" v-model="listParam.ContactName"/>
            <el-date-picker
                style="width: 260px"
                v-model="dateValue"
                type="daterange"
                start-placeholder="Start Date"
                popper-class="date-range-picker-popper"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                @change="changeDates"
                end-placeholder="End Date"
                clearable
            />
          </div>

          <div class="content-scroll-x">
            <div>
              <el-table :data="bookings" stripe @sort-change="sortParam" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    width="80"
                    show-overflow-tooltip
                    label="ID"
                    prop="TentativePreBookingID"
                >
                </el-table-column>
                <el-table-column
                    v-if="user.type >= 3"
                    width="150"
                    show-overflow-tooltip
                    label="Client"
                    prop="ClientName"
                >
                  <template slot-scope="booking">
                      <span>{{ decodeURIComponent(booking.row.ClientName)}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    v-if="user.type < 3"
                    width="150"
                    show-overflow-tooltip
                    label="Branch Name"
                    prop="BranchName"
                >
                  <template slot-scope="booking">
                    <span>{{ decodeURIComponent(booking.row.BranchName)}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Contact"
                    sortable="custom"
                    prop="ContactName"
                >
                  <template slot-scope="booking">
                    <router-link v-if="user.type >= 3"
                        :to="{ name: 'admin-manage-client', query: { id: booking.row.clientId, type:'edit-contact' } }">
                      <span>{{ decodeURIComponent(booking.row.ContactName)}}</span>
                    </router-link>
                    <span v-else>{{decodeURIComponent(booking.row.ContactName)}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Phone/Email"
                    prop="claimantFullName"
                >
                  <template slot-scope="booking">
                    <span>{{booking.row.ContactPhone}} / {{booking.row.ContactEmail}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Claimant"
                >
                  <template slot-scope="booking">
                    <span>{{decodeURIComponent(booking.row.ClaimantFirstName + ' ' + booking.row.ClaimantSurname)}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="180"
                    show-overflow-tooltip
                    label="RHAdmin Username"
                    prop="CreatedByName"
                >
                  <template slot-scope="booking">
                    <span>{{decodeURIComponent(booking.row.CreatedByName)}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Date Submitted"
                    prop="DateSubmitted"
                >
                  <template slot-scope="booking">
                      <span>{{
                          formatDate(booking.row.DateSubmitted, 'DD/MM/YYYY hh:ss A', 'YYYY-MM-DD HH:mm:ss.ssssss')
                        }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Specialist"
                    prop="SpecialistName"
                    sortable="custom"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Location"
                    prop="Location"
                >
                  <template slot-scope="booking">
                    <span>{{ decodeURIComponent(booking.row.LocationName) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Booking Date"
                    prop="BookingDate"
                >
                  <template slot-scope="booking">
                    <span>{{
                        formatDate(booking.row.BookingDate, 'DD/MM/YYYY', 'YYYY-MM-DD HH:mm:ss.ssssss')
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="180"
                    show-overflow-tooltip
                    label="Expired Time"
                    prop="BookingDate"
                >
                  <template slot-scope="booking">
                    <span>{{
                        formatDate(booking.row.ExpiredTime, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss.ssssss')
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="Booking Status"
                    prop="Status"
                >
                  <template slot-scope="booking">
                    <span>{{
                        getStatus(booking.row.Status)
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="250"
                    fixed="right"
                    show-overflow-tooltip
                    label="Actions"
                >
                  <template slot-scope="booking">
                    <div class="d-flex">
                      <el-button @click="toBook(booking.row)" type="primary" size="mini">Book</el-button>
                      <el-button v-if="user.type >= 3" @click="extendBooking(booking.row)" size="mini" type="info">Extend</el-button>
                      <el-button @click="releaseBooking(booking.row)" size="mini">Release</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div v-if="totalCount > listParam.PageSize" class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ listParam.PageNum }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                       :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';
import Navigation from "@/components/Navigation";
import ClientBookingMenu from "@/components/ClientBookingMenu";
import store from "@/store";

export default {
  name: "AdminBookingsTentative",
  components: {
    ClientBookingMenu,
    Navigation,
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      dateValue: '',
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),
      totalCount: 0,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
        ClientName: '',
        SpecialistName: '',
        ContactName: '',
        StartDate: null,
        EndDate: null
      },
    }
  },
  computed: {},
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    releaseBooking(item) {
      this.$confirm({
        content: 'Are you sure you want to release this Tentative Booking?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          const param = {
            tentativeBookingID: item.TentativePreBookingID,
            Status: 2,
            UserID: this.user.id,
            Username: this.user.name
          }
          await utilities.putTentativeBooking(param)
          this.$store.dispatch('loading/setLoadComponent', false)
          this.selectPage(1)
        }
      })
    },
    extendBooking(item) {
      this.$confirm({
        content: 'Are you sure you want to extend this Tentative Booking?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          const param = {
            tentativeBookingID: item.TentativePreBookingID,
            Status: 4,
            UserID: this.user.id,
            Username: this.user.name
          }
          await utilities.putTentativeBooking(param)
          this.$store.dispatch('loading/setLoadComponent', false)
          this.selectPage(1)
        }
      })
    },
    toBook(item) {
      const bookingParams = {
        id: item.SpecialistID,
        tentativeBookingID: item.TentativePreBookingID,
        typeId: '',
        typeName: '',
        waitlisted: false,
        claimantFirstName: decodeURIComponent(item.ClaimantFirstName),
        claimantSurname: decodeURIComponent(item.ClaimantSurname),
        clientId: item.ClientID,
        caseOwner: item.CaseOwner,
        DateofBirth: window.moment(item.DateofBirth).format('yyyy-MM-DD'),
        endDate: item.BookingDate,
        locationId: item.LocationID,
        locationName: item.LocationName,
        bookingDate: item.BookingDate,
        bookingTime: window.moment(item.StartTime, 'HH:mm:ss').format('HH:mm:ss'),
        bookingEndTime: window.moment(item.EndTime, 'HH:mm:ss').format('HH:mm:ss'),
        caseId: "",
        bookingId: "",
        isTentative: true,
        availabilityId: item.AvailabilityID,
        videoLinkAvailable: item.Options.VideoLinkAvailable,
        regionId: item.RegionID,
        isSingleId: true,
        isFromSpecialist: this.isFromSpecialist,
        isSupplementary: false,
        hasUserVideo: item.Options.VideoLinkAvailable ? true : false,
        hasVideo: item.Options.VideoOnly ? true : false
      }
      this.$store.dispatch("booking/setBookingObject", bookingParams)
      if(this.user.type >= 3) {
        this.$router.push({ name: 'admin-search-cases-details'})
      } else {
        this.$router.push({ name: 'search-cases-details'})
      }
    },
    changeDates(v) {
      if(v) {
        this.listParam.StartDate = v[0]
        this.listParam.EndDate = v[1]
      } else {
        this.listParam.StartDate = null
        this.listParam.EndDate = null
      }
      this.selectPage(1)
    },
    sortParam(v) {
      if(v.prop === 'ClientName' && v.order === "ascending") {
        this.listParam.SortFlag = 2
      } else if (v.prop === 'ClientName' && v.order === "descending") {
        this.listParam.SortFlag = 1
      } else if(v.prop === 'SpecialistName' && v.order === "ascending") {
        this.listParam.SortFlag = 4
      } else if (v.prop === 'SpecialistName' && v.order === "descending") {
        this.listParam.SortFlag = 3
      } else if(v.prop === 'ContactName' && v.order === "ascending") {
        this.listParam.SortFlag = 6
      } else if (v.prop === 'ContactName' && v.order === "descending") {
        this.listParam.SortFlag = 5
      }
      this.selectPage(1)
    },
    getStatus(status) {
      if(status === 0) {
        return 'On Hold'
      } else if (status === 1) {
        return 'Booked'
      } else if (status === 2) {
        return 'Released'
      } else if (status === 3) {
        return 'Expired'
      } else if (status === 4) {
        return 'Hold Extended'
      }
    },
    getFullPhoneEmail(phone, email) {
      let fullPhoneEmail = phone + (phone !== '' && email !== '' ? " / " : "") + email
      return fullPhoneEmail
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookings()
    },
    formatDate(dateToFormat, newFormat, currentFormat = "") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },

    async loadBookings() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {data, total} = await utilities.getTentativeBooking(this.listParam)
      this.bookings = _.cloneDeep(data)
      this.totalCount = total
      this.$store.dispatch("loading/setLoadComponent", false)
    },
  },
  async beforeMount() {
    $('body>.tooltip').remove()
    await this.loadBookings()
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
