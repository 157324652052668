/* eslint-disable */
<template>
  <div class="specialist-travel">
    <AdminNavigation ref="navigation" indexProps="3" />
    <div class="top-panel">
      <Header />
      <div v-if="travel.specialist != undefined" class="header-title-section">
        <div class="header-title"><h1>Specialist Travel Arrangements for {{ travel.specialist.fullName }}</h1></div>
        <div class="header-sub-title">View Travel Arrangements and Receipts for the selected Specialist.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistTravelMenu indexProps="0" :specialistId="travel.specialist != undefined ? travel.specialist.id : ''" />
      <div class="content">
        <div v-if="travel.specialist != undefined">
          <div class="content-scroll-x">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 20%" class="long-text">
                        Date<img class="sort-icon" :src="!byDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortDate" />
                      </th>
                      <th style="width: 15%" class="long-text">
                        Travel Receipts<img class="sort-icon" :src="!byReceipt ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortReceipt" />
                      </th>
                      <th style="width: 20%" class="long-text">Status</th>
                      <th style="width: 20%" class="long-text">Assigned To</th>
                      <th style="width: 15%" class="long-text">
                        No of Bookings<img class="sort-icon" :src="!byBooking ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortBooking" />
                      </th>
                      <th style="width: 10%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div :class="travel.travelDetails.length > 5 ? 'content-scroll-y' : ''">
                <table class="table">
                  <tbody>
                    <tr v-for="(travel, index) in travel.travelDetails" class="is-icon">
                      <td style="width: 20%" class="long-text" @click="openTravel(travel.specialistAvailabilityId)">{{ formatDate(travel.travelStartDate) }}</td>
                      <td style="width: 15%" class="long-text">
                        <router-link :to="{ name: 'admin-travel-detail', query: { id: travel.specialistTravelItineraryId } }">{{ travel.numberOfReceipts }}</router-link>
                      </td>
                      <td style="width: 20%" class="long-text" @click="openTravel(travel.specialistAvailabilityId)">{{ getTravelStatus(travel.specialistTravelItineraryStatus) }}</td>
                      <td style="width: 20%" class="long-text">
                        <select class="textbox w-75" v-model="travel.assignedTo" @change="changeAssignTo(index)">
                          <option hidden value="">Please choose ...</option>
                          <option v-for="(user, index) in users" :value="user.id">{{ user.name }}</option>
                        </select>
                      </td>
                      <td style="width: 15%" class="long-text">
                        <router-link :to="{ name: 'admin-availability-bookings', query: { id: travel.specialistId, date: travel.travelStartDate } }">{{ travel.numberOfBookings }}</router-link>
                      </td>
                      <td style="width: 10%" class="icon-col">
                        <div class="icon-group">
                          <router-link :to="{ name: 'admin-availability-bookings', query: { id: travel.specialistId, date: travel.travelStartDate } }">
                            <img src="@/assets/images/calendar_black_icon.svg" data-placement="right" data-toggle="tooltip" title="View Bookings" />
                          </router-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
          </div>
        </div>
        <div v-else>This specialist could not be found.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import Header from '@/components/Header'
  import AdminSpecialistTravelMenu from '@/components/AdminSpecialistTravelMenu'

  export default {
    name: 'AdminSpecialistTravel',
    components: {
      AdminNavigation,
      Header,
      AdminSpecialistTravelMenu,
    },
    data() {
      return {
        hasError: false,
        successMessage: '',
        errorMessage: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        travel: {
          specialist: utilities.initSpecialist(),
          travelDetails: [],
          allTravelDetails: [],
        },
        limit: 10,
        pagination: utilities.initPagination(),

        users: [],
        travelStatuses: [],

        byDate: false,
        byReceipt: false,
        byBooking: false,
      }
    },
    computed: {},
    methods: {
      sortDate() {
        this.travel.allTravelDetails.sort((a, b) => {
          if (this.byDate) {
            // ASC
            return moment(a.travelStartDate) > moment(b.travelStartDate) ? 1 : -1
          } else {
            // DESC
            return moment(a.travelStartDate) < moment(b.travelStartDate) ? 1 : -1
          }
        })
        this.paging(this.travel.allTravelDetails)
        this.byDate = !this.byDate
      },
      sortReceipt() {
        this.travel.allTravelDetails.sort((a, b) => {
          if (this.byReceipt) {
            // ASC
            return a.numberOfReceipts > b.numberOfReceipts ? 1 : -1
          } else {
            // DESC
            return a.numberOfReceipts < b.numberOfReceipts ? 1 : -1
          }
        })
        this.paging(this.travel.allTravelDetails)
        this.byReceipt = !this.byReceipt
      },
      sortBooking() {
        this.travel.allTravelDetails.sort((a, b) => {
          if (this.byBooking) {
            // ASC
            return a.numberOfBookings > b.numberOfBookings ? 1 : -1
          } else {
            // DESC
            return a.numberOfBookings < b.numberOfBookings ? 1 : -1
          }
        })
        this.paging(this.travel.allTravelDetails)
        this.byBooking = !this.byBooking
      },
      formatDate(dateToFormat) {
        return utilities.formatDate(dateToFormat, 'DD/MM/YYYY')
      },
      getTravelStatus(id) {
        var travelStatus = this.travelStatuses.find(x => x.id == id)
        return travelStatus != undefined ? travelStatus.name : ''
      },
      openTravel(availabilityId) {
        if(availabilityId != '') this.$router.push({ name: 'admin-manage-travel', query: { availabilityId: availabilityId } })
      },
      paging(travelDetails) {
        utilities.disposeTooltip()
        if(travelDetails.length == 0 || travelDetails.length <= this.limit) {
          this.travel.travelDetails = travelDetails
          $('.pagination').hide()
        }
        else {
          this.pagination = utilities.loadPagination(travelDetails, this.limit)
          this.travel.travelDetails = this.pagination.list[this.pagination.current]
          $('.pagination').show()
        }
        utilities.initTooltip()
      },
      async changeAssignTo(index) {
        this.$store.dispatch('loading/setLoadComponent', true)
        await this.putTravelArrangement(index)
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      putTravelArrangement(index) {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          SpecialistTravelItineraryID: this.travel.travelDetails[index].specialistTravelItineraryId,
          AssignedTo: this.travel.travelDetails[index].assignedTo,
        }
        var callback = (response) => {}
        return utilities.putTravelArrangement(params, callback)
      },

      async loadData() {
        this.travel.specialist.id = this.$route.query.id != undefined ? this.$route.query.id : ''
        if (this.travel.specialist.id !== '') {
          const apiArr = [utilities.getSpecialistById(this.travel.specialist.id), utilities.getSpecialistTravels({
            specialistId: this.travel.specialist.id,
            startDate: moment().format('YYYY-MM-DD')
          }), utilities.getUsersNew()]
          const r = await Promise.all(apiArr)
          this.travel.specialist = r[0]
          var travelDetails = r[1]
          travelDetails.sort((a, b) => {
            // ASC
            return moment(a.travelStartDate) > moment(b.travelStartDate) ? 1 : -1
          })
          this.travel.allTravelDetails = _.cloneDeep(travelDetails)
          this.paging(travelDetails)

          var {users} = r[2]
          this.users = users.filter(x => x.status && (x.type == 3 || x.type == 4 || x.type == 6))
          this.travelStatuses = utilities.getTravelItineraryStatuses().slice(0, 3)
        }
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $(".pagination").hide()
    },
  }
</script>
