<template>
  <div class="request_action-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive display-sub-title">
            <div class="modal-title">
              <h1>Request Action</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-right" style="width: 100%;">
                  <textarea rows="4" maxlength="1000" placeholder="Enter details of any special requests you have for this booking." v-model="action"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="icon-group right-float-element">
                  <v-btn
                    class="white-button"
                    @click="handleModal($event)"
                    dark>Cancel</v-btn>
                  <v-btn
                    @click="clickSave"
                    dark>{{isEdit ? 'Update' : 'Save'}}</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import CitySuggestion from '@/components/CitySuggestion'

export default {
  name: "RequestActionModal",
  data() {
    return {
      loadModal: false,
      hasError: false,
      clickState: false,
      action: "",
      editItem: {},
      isEdit: false,
      isEditClient: false,
      cancelationReasons: utilities.getCancellationReasons(),
    }
  },

  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
      }
    },

    async clickSave() {
      this.$store.dispatch('loading/setLoadComponent', true)
      if (this.isEdit) {
        var parem = {
          BookingID: this.editItem.bookingId,
          DateRequested: moment().format('YYYY-MM-DD HH:mm:ss'),
          RequestedBy: this.$parent.user.id,
          BookingActionRequestStatus: 1,
          RequestedAction: this.action,
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name,
          BookingActionRequestID: this.editItem.id,
        }
        var callback = (response) => {
          this.$parent.actionConfirmation(false)
          this.action = ""
          this.handleModal()
        }
        await utilities.putBookingAction(parem, callback)
      } else {
        var parem = {
          BookingID: this.$parent.booking.id,
          DateRequested: moment().format('YYYY-MM-DD HH:mm:ss'),
          RequestedBy: this.$parent.user.id,
          BookingActionRequestStatus: 1,
          RequestedAction: this.action,
          UserID: this.$parent.user.id,
          Username: this.$parent.user.name,
        }

        var callback = (response) => {
          this.$parent.actionConfirmation()
          this.action = ""
          this.handleModal()
        }
        await utilities.postBookingAction(parem, callback)
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },


    editAction(item) {
      this.isEdit = true
      this.action = item.requestedAction
      this.editItem = item
    },

    handleState() {
      this.clickState = true
    },
  },

  async beforeMount() {
  },

  mounted() {
  },

  updated() {
  },
}
</script>
