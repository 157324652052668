<template>
  <div id="app">
    <v-app id="inspire">
      <v-container>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
      <Loading ref="loading" />
    </v-app>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'App',
  components: {
    Loading,
  },
  methods: {

  },
  mounted() {
    $("form").on('submit', (e) => {
      e.preventDefault()
      e.stopPropagation()
    })

    $(window).on('beforeunload', () => {
      // Clear changing object
      // utilities not working here
      this.$store.dispatch("setOriginalObject", null)
      this.$store.dispatch("setNewObject", null)
    });
    $(window).scrollTop(0);
  },
}
</script>
