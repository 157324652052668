<template>
  <div class="file-type-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal" aria-label="Close"></button>
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <div class="row">
                <div class="col">
                  <h1>File Status</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body div-responsive">
              <div class="form-group">
                <div class="box">
                  <div class="box-body">
                    <el-select :popper-append-to-body="false" v-model="fileTypeId" class="w-100" placeholder="Select File Status" :class="hasError && !validateFileType ? 'is-invalid' : ''">
                      <el-option :value="0" label="Draft">Draft</el-option>
                      <el-option :value="1" label="Final">Final</el-option>
                    </el-select>
                    <div v-if="hasError && !validateFileType" class="text-danger mt-2">Please select file status.</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn @click="save" dark>Save</v-btn>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FileTypeModal",
  data() {
    return {
      loadModal: false,
      hasError: false,
      fileTypeId: 0,
    }
  },
  computed: {
    validateFileType() {
      if (this.fileTypeId === '') {
        return false
      }
      return true
    },
  },
  methods: {
    handleModal(e) {
      this.loadModal = !this.loadModal
      if(this.loadModal) {
        $("html").addClass("modal-mask-open")
      } else {
        $("html").removeClass("modal-mask-open")
        this.$parent.clearFile()
      }
    },
    reset() {
      this.hasError = false
      this.fileTypeId = 0
    },
    save() {
      if(!this.validateFileType) {
        this.hasError = true
        return false
      }

      this.$parent.fileStatusName = this.fileTypeId
      this.$parent.curStatus = this.fileTypeId
      this.$parent.uploadFile()
      this.handleModal()
    },
  },
  beforeMount() {},
  mounted() {},
}
</script>
