/* eslint-disable */
<template>
  <div class="availability-require-travel">
    <AdminNavigation ref="navigation" indexProps="3" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Availability Requiring Travel</h1></div>
        <div class="header-sub-title">The following specialist availability contain bookings, but travel / accommodation has not yet been arranged.</div>
      </div>
    </div>
    <div class="panel">
      <AdminSpecialistMenu indexProps="1" />
      <div class="content">
        <div class="require-travel-itinerary pb-5">
          <div>The itineraries for travel & accommodation are underway.</div>
          <br />
          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="content-scroll-x">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 8%" class="long-text">Itinerary ID</th>
                      <th style="width: 15%" class="long-text">
                        Specialist<img class="sort-icon" :src="!bySpecialist ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortSpecialist" />
                      </th>
                      <th style="width: 10%" class="long-text">
                        Start Date<img class="sort-icon" :src="!byStartDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortStartDate" />
                      </th>
                      <th style="width: 10%" class="long-text">
                        End Date<img class="sort-icon" :src="!byEndDate ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortEndDate" />
                      </th>
                      <th style="width: 12%" class="long-text">
                        Number of Bookings<img class="sort-icon" :src="!byBooking ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortBooking" />
                      </th>
                      <th style="width: 20%" class="long-text">
                        Location<img class="sort-icon" :src="!byLocation ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortLocation" />
                      </th>
                      <th style="width: 10%" class="long-text">
                        Status<img class="sort-icon" :src="!byStatus ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortStatus" />
                      </th>
                      <th style="width: 15%" class="long-text">
                        Assigned To<img class="sort-icon" :src="!byAssignedTo ? require('@/assets/images/down_arrow_icon.svg') : require('@/assets/images/up_arrow_icon.svg')" @click="sortAssignedTo" />
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div :class="availabilities.length > 5 ? 'content-scroll-y' : ''">
                <table class="table">
                  <tbody>
                    <tr v-for="(availability, index) in availabilities" class="is-icon">
                      <td style="width: 8%" class="long-text">
                        <router-link :to="{ name: 'admin-manage-travel', query: { id: availability.specialistTravelItineraryId} }">{{ availability.specialistTravelItineraryId }}</router-link>
                      </td>
                      <td style="width: 15%" class="long-text">
                        <router-link :to="{ name: 'admin-manage-specialist', query: { id: availability.specialistId } }">
                          <div class="long-text" data-placement="right" data-toggle="tooltip" :title="availability.fullName">
                            <span>{{ availability.fullName }}</span>
                          </div>
                        </router-link>
                      </td>
                      <td style="width: 10%" class="long-text">
                        <div class="long-text">
                          <span>{{ formatDate(availability.startDate, 'DD/MM/YYYY') }}</span>
                        </div>
                      </td>
                      <td style="width: 10%" class="long-text">
                        <div class="long-text">
                          <span>{{ formatDate(availability.endDate, 'DD/MM/YYYY') }}</span>
                        </div>
                      </td>
                      <td style="width: 12%" class="long-text">
                        {{ availability.numberofBookings }}
                      </td>
                      <td style="width: 20%" class="long-text">
                        <div class="long-text" data-placement="right" data-toggle="tooltip" :title="availability.fullLocation">
                          <span>{{ availability.fullLocation }}</span>
                        </div>
                      </td>
                      <td style="width: 10%">
                        <select class="w-100 textbox" v-model="availability.specialistTravelItineraryStatus" @change="changeTravelItineraryStatus(index)">
                          <option hidden value="">Select ...</option>
                          <option v-for="(travelItinerary, index) in travelItineraryStatuses" :value="travelItinerary.id">{{ travelItinerary.name }}</option>
                        </select>
                      </td>
                      <td style="width: 15%">
                        <select class="w-100 textbox" v-model="availability.assignedTo" @change="changeAssignedTo(index)">
                          <option hidden value="">Select ...</option>
                          <option v-for="(user, index) in users" :value="user.id">{{ user.name }}</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ this.pagination.current + 1 }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" :class="pagination.current == item - 1 ? 'selected' : ''" v-for="item in (1, pagination.list.length)" :value="item - 1" @click="selectPage(item - 1)">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ this.pagination.list.length }}</div>
          </div>
        </div>

        <div class="require-travel-no-itinerary pt-5">
          <div>The following availability is not yet associated with a travel itinerary.</div>
          <br />
          <div class="content-scroll-x">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 15%" class="long-text">Availability ID</th>
                      <th style="width: 25%" class="long-text">Specialist</th>
                      <th style="width: 15%" class="long-text">Date</th>
                      <th style="width: 15%" class="long-text">Number of Bookings</th>
                      <th style="width: 30%" class="long-text">Location</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div :class="availabilitiesNoItinerary.length > 5 ? 'content-scroll-y' : ''">
                <table class="table">
                  <tbody>
                    <tr v-for="(availability, index) in availabilitiesNoItinerary" class="is-icon">
                      <td style="width: 15%" class="long-text">
                        <router-link :to="{ name: 'admin-manage-travel', query: { availabilityId: availability.id } }">{{ availability.id }}</router-link>
                      </td>
                      <td style="width: 25%" class="long-text">
                        <router-link :to="{ name: 'admin-manage-specialist', query: { id: availability.specialistId } }">
                          <div class="long-text" data-placement="right" data-toggle="tooltip" :title="availability.fullName">
                            <span>{{ availability.fullName }}</span>
                          </div>
                        </router-link>
                      </td>
                      <td style="width: 15%" class="long-text">
                        <div class="long-text">
                          <span>{{ formatDate(availability.availabilityDate, 'DD/MM/YYYY') }}</span>
                        </div>
                      </td>
                      <td style="width: 15%" class="long-text">
                        {{ availability.numberofBookings }}
                      </td>
                      <td style="width: 30%" class="long-text">
                        <div>
                          <span>{{ availability.fullLocation }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="pagination">
            Page
            <div class="pagination-group">
              <div class="border-icon pagination-current dropdown">
                <div class="pagination-current-text">{{ this.paginationNoItinerary.current + 1 }}</div>
                <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                  <div class="dropdown-item" :class="paginationNoItinerary.current == item - 1 ? 'selected' : ''"
                    v-for="item in (1, paginationNoItinerary.list.length)" :value="item - 1" @click="selectPage(item - 1, true)">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="pagination-total">of &nbsp; {{ this.paginationNoItinerary.list.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminSpecialistMenu from '@/components/AdminSpecialistMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminAvailabilityRequireTravel',
    components: {
      AdminNavigation,
      AdminSpecialistMenu,
      Header,
    },
    data() {
      return {
        successMessage: '',

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        availabilities: [],
        allAvailabilities: [],
        availabilitiesNoItinerary: [],
        allAvailabilitiesNoItinerary: [],
        limit: 5,
        pagination: utilities.initPagination(),
        paginationNoItinerary: utilities.initPagination(),
        startMaxDate: "",
        endMaxDate: "",

        bySpecialist: false,
        byStartDate: false,
        byEndDate: false,
        byBooking: false,
        byLocation: false,
        byStatus: false,
        byAssignedTo: false,

        users: [],
        travelItineraryStatuses: [],
      }
    },
    computed: {},
    methods: {
      selectPage(index ,isItinerary = false) {
        if (isItinerary) {
          this.paginationNoItinerary.current = index
          this.availabilitiesNoItinerary = this.paginationNoItinerary.list[this.paginationNoItinerary.current]
        } else {
          this.pagination.current = index
          this.availabilities = this.pagination.list[this.pagination.current]
        }

      },
      sortSpecialist() {
        this.allAvailabilities.sort((a, b) => {
          if (this.bySpecialist) {
            // ASC
            return a.fullName > b.fullName ? 1 : -1
          } else {
            // DESC
            return a.fullName < b.fullName ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.bySpecialist = !this.bySpecialist
      },
      sortStartDate() {
        var date1 = null
        var date2 = null
        this.allAvailabilities.sort((a, b) => {
          if(a.startDate != '') {
            date1 = moment(a.startDate)
          } else {
            date1 = moment(this.startMaxDate)
          }
          if(b.startDate != '') {
            date2 = moment(b.startDate)
          } else {
            date2 = moment(this.startMaxDate)
          }

          if (this.byStartDate) {
            // ASC
            return date1.isAfter(date2) ? 1 : -1
          } else {
            // DESC
            return date1.isBefore(date2) ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.byStartDate = !this.byStartDate
      },
      sortEndDate() {
        var date1 = null
        var date2 = null
        this.allAvailabilities.sort((a, b) => {
          if(a.endDate != '') {
            date1 = moment(a.endDate)
          } else {
            date1 = moment(this.endMaxDate)
          }
          if(b.endDate != '') {
            date2 = moment(b.endDate)
          } else {
            date2 = moment(this.endMaxDate)
          }

          if (this.byEndDate) {
            // ASC
            return date1.isAfter(date2) ? 1 : -1
          } else {
            // DESC
            return date1.isBefore(date2) ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.byEndDate = !this.byEndDate
      },
      sortBooking() {
        this.allAvailabilities.sort((a, b) => {
          if (this.byBooking) {
            // ASC
            return a.numberofBookings > b.numberofBookings ? 1 : -1
          } else {
            // DESC
            return a.numberofBookings < b.numberofBookings ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.byBooking = !this.byBooking
      },
      sortLocation() {
        this.allAvailabilities.sort((a, b) => {
          if (this.byLocation) {
            // ASC
            return a.fullLocation > b.fullLocation ? 1 : -1
          } else {
            // DESC
            return a.fullLocation < b.fullLocation ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.byLocation = !this.byLocation
      },
      sortStatus() {
        this.allAvailabilities.sort((a, b) => {
          if (this.byLocation) {
            // ASC
            return a.fullLocation > b.fullLocation ? 1 : -1
          } else {
            // DESC
            return a.fullLocation < b.fullLocation ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.byStatus = !this.byStatus
      },
      sortAssignedTo() {
        this.allAvailabilities.sort((a, b) => {
          if (this.byAssignedTo) {
            // ASC
            return a.assignedTo > b.assignedTo ? 1 : -1
          } else {
            // DESC
            return a.assignedTo < b.assignedTo ? 1 : -1
          }
        })
        this.paging(this.allAvailabilities)
        this.byAssignedTo = !this.byAssignedTo
      },
      formatDate(dateToFormat, newFormat, currentFormat = '') {
        return utilities.formatDate(dateToFormat, newFormat)
      },
      paging(availabilities) {
        utilities.disposeTooltip()
        if (availabilities.length == 0 || availabilities.length <= this.limit) {
          this.availabilities = availabilities
          $('.require-travel-itinerary .pagination').hide()
        } else {
          this.pagination = utilities.loadPagination(availabilities, this.limit)
          this.availabilities = this.pagination.list[this.pagination.current]
          $('.require-travel-itinerary .pagination').show()
        }
        utilities.initTooltip()
        utilities.hoverTooltip()
      },
      pagingNoItinerary(availabilities) {
        utilities.disposeTooltip()
        if (availabilities.length == 0 || availabilities.length <= this.limit) {
          this.availabilitiesNoItinerary = availabilities
          $('.require-travel-no-itinerary .pagination').hide()
        } else {
          this.paginationNoItinerary = utilities.loadPagination(availabilities, this.limit)
          this.availabilitiesNoItinerary = this.paginationNoItinerary.list[this.paginationNoItinerary.current]
          $('.require-travel-no-itinerary .pagination').show()
        }
        utilities.initTooltip()
        utilities.hoverTooltip()
      },
      async changeTravelItineraryStatus(index) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var result = await this.putTravelItinerary(index)
        if (result) {
          this.successMessage = 'Availability updated successfully.'
          utilities.showAlert('.alert-success')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async changeAssignedTo(index) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var result = await this.putTravelItinerary(index)
        if (result) {
          this.successMessage = 'Availability updated successfully.'
          utilities.showAlert('.alert-success')
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      putTravelItinerary(index) {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          SpecialistTravelItineraryID: this.availabilities[index].specialistTravelItineraryId,
          SpecialistTravelItineraryStatus: Number(this.availabilities[index].specialistTravelItineraryStatus),
          AssignedTo: Number(this.availabilities[index].assignedTo),
        }
        params.RequireTravelUserAssigned = params.AssignedTo == 0 ? 0 : 1
        if (params.SpecialistTravelItineraryStatus == 3) {
          params.ApprovedBy = this.user.id
        }

        var callback = (response) => {
          // Reload if change to Approved
          if (this.availabilities[index].specialistTravelItineraryStatus == 3) this.loadAvailabilities()
        }
        return utilities.putTravelItinerary(params, callback)
      },

      async loadAvailabilities() {
        // Show all not been Approved yet
        var availabilities = await utilities.getAvailabiltyRequiringTravels()
        availabilities = _.uniqBy(availabilities, 'specialistTravelItineraryId')
          availabilities = availabilities.filter((x) => {
            return x.numberofBookings > 0
          })

        await Promise.all(availabilities.map(async (x, i) => {
          await utilities.getSpecialistAvailabilities(x.specialistId, null, { StartDate: x.availabilityDate, EndDate: x.availabilityDate }).then((temp) => {
            availabilities[i].id = temp[0].id
            availabilities.forEach((x) => {
              if (x.endDate == '') x.endDate = x.startDate
            })
            this.allAvailabilities = _.cloneDeep(availabilities)

            // Get max date to sort date with empty value
            var dates = this.allAvailabilities.filter(x => x.startDate != '').map(x => { return new Date(x.startDate) })
            this.startMaxDate = moment(new Date(Math.max.apply(null, dates))).add(1, 'd').format("YYYY-MM-DD")
            dates = this.allAvailabilities.filter(x => x.endDate != '').map(x => { return new Date(x.endDate) })
            this.endMaxDate = moment(new Date(Math.max.apply(null, dates))).add(1, 'd').format("YYYY-MM-DD")

            // Order by oldest start date
            var date1 = null
            var date2 = null
            this.allAvailabilities.sort((a, b) => {
              if(a.startDate != '') {
                date1 = moment(a.startDate)
              } else {
                date1 = moment(this.startMaxDate)
              }
              if(b.startDate != '') {
                date2 = moment(b.startDate)
              } else {
                date2 = moment(this.startMaxDate)
              }

              // ASC
              return date1.isAfter(date2) ? 1 : -1
            })
            this.paging(this.allAvailabilities)
          })
        }))
      },
      async loadAvailabilitiesNoItinerary() {
        // Show all not been Approved yet
        var availabilities = await utilities.getAvailabiltyRequiringTravelsNoItinerary()
        availabilities.sort((a, b) => {
          // ASC
          return moment(a.availabilityDate).isAfter(moment(b.availabilityDate)) ? 1 : -1
        })
        this.allAvailabilitiesNoItinerary = _.cloneDeep(availabilities)
        this.pagingNoItinerary(availabilities)
      },
      async loadData() {
        var {users} = await utilities.getUsersNew()
        this.users = users.filter((x) => x.status && (x.type == 3 || x.type == 4 || x.type == 6))
        var travelItineraryStatuses = utilities.getTravelItineraryStatuses()
        if (this.user.type == 4 || this.user.type == 6) {
          // RH finance user
          this.travelItineraryStatuses = travelItineraryStatuses // //  New, In progress, Actioned, Approved
        } else {
          //  RH admin user
          this.travelItineraryStatuses = travelItineraryStatuses.filter((x) => x.id != 3) //  New, In progress, Actioned
        }
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      this.loadAvailabilities()
      this.loadAvailabilitiesNoItinerary()
      await this.loadData()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {
      $('.pagination').hide()
    },
  }
</script>
