<template>
  <div class="edit-routine-availability-modal">
    <div v-if="loadModal" class="modal modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header div-responsive">
            <div class="modal-title">
              <h1>Edit Availability {{ formatDate(date) }}</h1>
            </div>
            <button type="button" class="close-icon" @click="handleModal($event)" data-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body div-responsive">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Location</div>
                <div class="box-col box-col-right d-flex">
                  <div class="w-100">
                    <el-select :popper-append-to-body="false" popper-class="search-specialty-popup" clearable filterable class="w-100"
                               placeholder="Please choose" :class="hasError && !validateLocation ? 'is-invalid' : ''"
                               v-model="availability.Location.LocationID" @change="changeLocation">
                      <el-option v-for="(location, index) in locations" :value="location.id" :key="index"
                                 :label="location.fullLocation">{{ location.fullLocation }}
                      </el-option>
                    </el-select>
                    <div v-if="hasError && !validateLocation" class="text-danger">Location is required.</div>
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Select Type of Booking/Travel Required</div>
                <div class="box-col box-col-right d-flex">
                  <div class="w-50 px-5">
                    <img class="is-icon" :src="availability.Options.VideoOnly ? require('@/assets/images/video_black_icon.svg') : require('@/assets/images/video_icon.svg')"
                         @click="changeOnlyAllowVideo" data-toggle="tooltip" data-placement="right" title="Only Allow Video" />
                    <img class="is-icon pl-3" style="height: 30px" :src="availability.Options.VideoLinkAvailable ? require('@/assets/images/user_video_icon_black.svg') : require('@/assets/images/user_video_icon.svg')"
                         @click="changeVideoLinkAvailable" data-toggle="tooltip" data-placement="right" title="Video Link available" />
                    <img class="is-icon pl-3" :src="availability.Options.ByRequestOnly ? require('@/assets/images/phone_black_icon.svg') : require('@/assets/images/phone_icon.svg')"
                         @click="changeByRequestOnly" data-toggle="tooltip" data-placement="right" title="By Request only" />
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col w-25">Start Time</div>
                <div class="box-col w-25">
                  <vue-timepicker v-model="startTime"
                                  format="hh:mm A"
                                  class="w-100"
                                  :input-class="hasError && !validateStartTime ? 'is-invalid' : ''"
                                  input-class="form-control"
                                  :minute-interval="15"
                                  placeholder="HH:MM"></vue-timepicker>
                  <div v-if="hasError && !validateStartTime" class="text-danger">{{ startTimeMessage }}</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Appointment Duration</div>
                <div class="box-col box-col-right">
                  <el-input-number @change="changeVal" class="number-input" v-model="availability.Duration" :step="5" :min="5" :max="1000" ></el-input-number>
                  <span class="ms-2">mins</span>
                  <div v-if="hasError && !validateStatus" class="text-danger">Duration is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col w-25">Visibility Configuration</div>
                <div class="box-col w-25">
                  <el-tooltip
                      v-if="!isFromSpecialistProps"
                      popper-class="body-tooltip"
                      :content="availability.Options.ShowWorkCover ? 'Show to everyone INCLUDING WorkCover QLD' : 'Show to everyone EXCEPT WorkCover QLD'">
                    <img @click="changeShowWorkCover" class="is-icon" style="height: 26px"
                         :src="availability.Options.ShowWorkCover ? require('./../assets/images/work_cover.svg') : require('./../assets/images/work_cover_gray.svg')"/>
                  </el-tooltip>
                  <el-tooltip
                      v-else
                      popper-class="body-tooltip"
                      :content="availability.Options.ShowWorkCover ? 'Clicking this button will mean that WorkCover Queensland can not book this appointment' : 'Clicking this button will mean that WorkCover Queensland can book this appointment'">
                    <img @click="changeShowWorkCover" class="is-icon" style="height: 26px"
                         :src="availability.Options.ShowWorkCover ? require('./../assets/images/work_cover.svg') : require('./../assets/images/work_cover_gray.svg')"/>
                  </el-tooltip>
                  <el-tooltip
                      popper-class="body-tooltip"
                      v-if="!isFromSpecialistProps"
                      :content="availability.Options.ShowSpecificClients ? 'Show only to specific clients' : 'Show for all clients'">
                    <img @click="changeShowSpecificClients" class="is-icon ml-3" style="height: 26px"
                         :src="availability.Options.ShowSpecificClients ? require('./../assets/images/specific_users.svg') : require('./../assets/images/specific_users_gray.svg')"/>
                  </el-tooltip>
                </div>
                <div class="box-col w-50">
                  <el-select :popper-append-to-body="false" clearable class="w-100" v-show="availability.Options.ShowSpecificClients"
                             v-model="availability.ClientIDs" multiple filterable @change="selectClients"
                             placeholder="Hide from everyone EXCEPT these specific clients">
                    <el-option v-for="item in clients" :key="item.id" :value="item.id"
                               :label="item.fullName"></el-option>
                  </el-select>
                  <div v-if="hasError && !validateClient" class="text-danger">Please select clients.</div>
                </div>
              </div>

              <div class="alert alert-danger hidden-element">
                <a class="close" title="close">×</a>
                <b>Error:</b> Please correct the errors shown above and try again.
              </div>

              <div class="row">
                <div class="col">
                  <div class="icon-group right-float-element">
                    <v-btn @click="clickSave" dark>Save</v-btn>
                    <v-btn @click="deleteAvailability" dark>Delete</v-btn>
                    <v-btn class="white-button" @click="handleModal($event)" dark>Cancel</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-danger hidden-element edit-routine-alert">
      <a class="close" title="close">×</a>
      <b>Error:</b> {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'

export default {
  name: 'EditRoutineAvailabilityModal',
  props: ['availabilityProp', 'dataSourceProps', 'clients', 'locations', 'isFromSpecialistProps', 'specialistId'],
  components: {},
  data() {
    return {
      errorMessage: '',
      dateRange: '',
      isBatchUpdate: false,
      loadModal: false,
      hasError: false,
      hasEndtimeError: false,
      hideButton: false,
      hideStartTime: false,
      hideEndTime: false,
      fillOrder: null,
      date: '',
      validateClient: true,
      startTime: '',
      startTimeMessage: '',
      endTimeMessage: '',
      fillOrders: [],
      availability: {},
      availabilities: [],
      specialistTimes: [],
      bookings: [],
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      disabledDates: {
        ranges: [
          {
            from: window.moment(utilities.START_DATE, "YYYY-MM-DD"),
            to: window.moment().subtract(1, 'd'),
          },
          {
            from: window.moment(),
          }
        ],
      }
    }
  },
  computed: {
    validDateRange() {
      if(this.isBatchUpdate && !this.dateRange) {
        return false
      }
      return true
    },
    validateLocation() {
      if(this.availability.LocationID == "") {
        return false
      }
      return true
    },
    validateDate() {
      if (this.date == "") {
        return false
      }
      return true
    },
    validateStatus() {
      if (this.availability.availabilityStatus == "") {
        return false
      }
      return true
    },
    validateStartTime() {
      if (this.availability.StartTime == "") {
        this.startTimeMessage = "Start time is required."
        return false
      }
      return true
    },
    validateEndTime() {
      if (this.availability.endTime == "") {
        this.endTimeMessage = "End time is required."
        return false
      } else if (moment(this.date + " " + this.availability.endTime, 'YYYY-MM-DD hh:mm A').isSameOrBefore(moment(this.date + " " + this.availability.startTime, 'YYYY-MM-DD hh:mm A'))) {
        this.endTimeMessage = 'End time cannot be before the start time.'
        return false
      }
      return true
    },
    validateOverlapTime() {
      if (this.checkOverlapTime(this.date, this.availability.startTime, this.availability.endTime)) {
        return false
      }
      return true
    },
    validateExistingBookingTime() {
      if (this.checkExistingBookingTime(this.date, this.availability)) {
        return false
      }
      return true
    },
  },
  methods: {
    selectClients(v) {
      this.$forceUpdate()
      if(v && v.length > 0) this.validateClient = true
    },
    changeVal() {
      this.$forceUpdate()
    },
    changeShowWorkCover() {
      if (this.availability.Options.ShowWorkCover) {
        this.availability.Options.ShowWorkCover = 0
      } else {
        this.availability.Options.ShowWorkCover = 1
      }
    },
    changeShowSpecificClients() {
      if (this.availability.Options.ShowSpecificClients) {
        this.availability.Options.ShowSpecificClients = 0
        this.availability.ClientIDs = []
      } else {
        this.availability.Options.ShowSpecificClients = 1
      }
    },
    handleModal(e) {
      this.loadModal = !this.loadModal
      if (this.loadModal) {
        $('html').addClass('modal-mask-open')
      } else {
        $('html').removeClass('modal-mask-open')
      }
    },
    formatDate(date) {
      return utilities.formatDate(date, "DD/MM/YYYY")
    },
    checkOverlapTime(date, startTime, endTime) {
      var result = false
      var range1 = null
      var range2 = null
      for (var i = 0; i < this.availabilities.length; i++) {
        range1 = moment.range(utilities.formatDate(date + " " + startTime, "YYYY-MM-DD HH:mm"), utilities.formatDate(date + " " + endTime, "YYYY-MM-DD HH:mm"))
        range2 = moment.range(utilities.formatDate(date + " " + this.availabilities[i].startTime, "YYYY-MM-DD HH:mm"), utilities.formatDate(date + " " + this.availabilities[i].endTime, "YYYY-MM-DD HH:mm"))
        if (range1.overlaps(range2)) {
          result = true
          break
        }
      }
      return result
    },
    checkExistingBookingTime(date, availabilty) {
      var result = false
      // Get last booking on the list
      var booking = this.bookings.filter(x => x.bookingDetails.specialistAvailabilityId == availabilty.id).pop()
      if (booking) {
        var bookingStartTime = utilities.formatDate(booking.bookingDetails.dateTime, "YYYY-MM-DD HH:mm")
        var bookingEndTime = utilities.formatDate(utilities.formatDate(booking.bookingDetails.dateTime, "YYYY-MM-DD") + " " + booking.bookingDetails.endTime, "YYYY-MM-DD HH:mm")
        var startTime = utilities.formatDate(date + " " + availabilty.startTime, "YYYY-MM-DD HH:mm")
        var endTime = utilities.formatDate(date + " " + availabilty.endTime, "YYYY-MM-DD HH:mm")
        if (availabilty.startTime != '' && availabilty.endTime != '' && (moment(startTime, "YYYY-MM-DD HH:mm").isAfter(bookingStartTime, "YYYY-MM-DD HH:mm") || moment(endTime, "YYYY-MM-DD HH:mm").isBefore(bookingEndTime))) {
          result = true
        }
      }
      return result
    },
    changeStartTime() {
      // if (this.availability.numberTimeSlotsBooked > 0) {
      //
      // }
    },
    changeEndTime() {
      // this.hasEndtimeError = false
      // if (moment(this.availability.startTime, 'hh:mm A').isSameOrAfter(moment(this.availability.endTime, 'hh:mm A'))) {
      //   this.hasEndtimeError = true
      //   this.endTimeMessage = 'End time cannot be before the start time!'
      // }
    },
    setAvailability(item) {
      this.availability = _.cloneDeep(item)
      let time = window.moment(this.availability.StartTime, 'HH:mm').format('hh:mm A')
      this.startTime = time
      this.availability.Duration = window.moment(this.availability.EndTime, 'HH:mm').diff(window.moment(this.availability.StartTime, 'HH:mm'), 'minute')
      this.availability.ClientIDs = this.availability.SpecificClients ? this.availability.SpecificClients.map(item => item.ClientID) : []
    },

    async clickSave() {
      this.$forceUpdate()
      if (!this.validateStartTime || !this.validateEndTime || !this.validateOverlapTime || !this.validateExistingBookingTime || !this.validDateRange) {
        this.hasError = true
        utilities.showAlert(".edit-routine-availability-modal .alert-danger")
        return
      } else {
        if(this.availability.Options.ShowSpecificClients && (!this.availability.ClientIDs || this.availability.ClientIDs.length ===0)) {
          this.validateClient = false
          this.hasError = true
          return
        }
      }
      this.hasError = false
      this.validateClient = true
      const param = {
        UsernameLog: this.user.name,
        UserIDLog: this.user.id,
        LocationID: this.availability.Location.LocationID, // Integer
        StartTime: utilities.formatDate(this.startTime, "HH:mm:ss", "hh:mm A"), // Time String
        Duration: this.availability.Duration, // Integer
        Options:{
          ByRequestOnly: this.availability.Options.ByRequestOnly, // Integer 0-False, 1-True,
          VideoLinkAvailable: this.availability.Options.VideoLinkAvailable, // Integer 0-False, 1-True,
          VideoOnly: this.availability.Options.VideoOnly, // Integer 0-False, 1-True,
          ShowWorkCover: this.availability.Options.ShowWorkCover, // Integer 0-False, 1-True,
          ShowSpecificClients: this.availability.Options.ShowSpecificClients, // Integer 0-False, 1-True,
        },
        ClientIDs: this.availability.ClientIDs
      }
      if(this.isBatchUpdate) {
        const start = window.moment(this.dateRange[0])
        const end = window.moment(this.dateRange[1])
        let idsArr = []
        this.dataSourceProps.forEach(item => {
          if(window.moment(item.date).add(1, 'hour').isBetween(window.moment(start), window.moment(end).endOf('day'))) {
            const idArr = item.Availabilities.map(a => a.AvailabilityID)
            idsArr.concat(idArr)
          }
        })
      }
      this.$store.dispatch('loading/setLoadComponent', true)
      if(this.availability.BookingStatus === 'B') {
        const obj = {
          specialistId: this.specialistId,
          startDate: this.availability.date,
          endDate: this.availability.date
        }
        const bookings  = await utilities.getSpecialistBookings(obj)
        const booking = bookings.find(item => item.bookingDetails.AvailabilityID === this.availability.AvailabilityID)
        if (booking) {
          let result = true
          if(this.availability.Options.VideoOnly && booking.bookingDetails.typeId === 2) {
            result = false
          } else if (this.availability.Options.ByRequestOnly) {
            result = false
          } else if (!this.availability.Options.VideoLinkAvailable && !this.availability.Options.VideoOnly && booking.bookingDetails.typeId === 4) {
            result = false
          }
          if(!result) {
            this.errorMessage = 'Please update booking type before you edit availability type.'
            utilities.showAlert('.edit-routine-alert')
            this.$store.dispatch('loading/setLoadComponent', false)
            return
          }
        }
      }
      await utilities.putAvailability(this.availability.AvailabilityID, param,(id) => {
        if(id) {
          this.$parent.successMessage = 'Availability updated successfully.'
          utilities.showAlert('.year-calendar .alert-success')
          this.$parent.reload()
          this.$store.dispatch('loading/setLoadComponent', false)
          this.handleModal()
        }
      })
    },

    async deleteAvailability() {
      this.$store.dispatch('loading/setLoadComponent', true)
      var result = await utilities.deleteAvailability({AvailabilityID: this.availability.AvailabilityID, UserID: this.user.id, Username: this.user.name})
      if (result) {
        this.$parent.successMessage = 'Availability successfully deleted.'
        utilities.showAlert('.year-calendar .alert-success')
        this.handleModal()
        setTimeout(() => {
          this.$parent.reload()
        }, 1000)
      }
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    changeLocation(e) {
      var location = this.locations.find(x => x.id == Number($(e.currentTarget).val()))
      this.availability.locationName = location.name
      this.availability.cityName = location.cityName
    },
    changeRequiresTravel() {
      if (this.availability.specialistTravelItineraryId == "") {
        this.availability.Options.requiresTravel = !this.availability.Options.requiresTravel
        this.availability.Options.byRequestOnly = false
      }
    },
    changeByRequestOnly() {
      if(this.availability.Options.ByRequestOnly) {
        this.availability.Options.ByRequestOnly = 0
      } else {
        this.availability.Options.ByRequestOnly = 1
        this.availability.Options.VideoLinkAvailable = 0
        this.availability.Options.VideoOnly = 0
      }
    },
    changeVideoLinkAvailable() {
      if(this.availability.Options.VideoLinkAvailable) {
        this.availability.Options.VideoLinkAvailable = 0
      } else {
        this.availability.Options.VideoLinkAvailable = 1
        this.availability.Options.VideoOnly = 0
        this.availability.Options.ByRequestOnly = 0
      }
    },
    changeOnlyAllowVideo() {
      if(this.availability.Options.VideoOnly === 0) {
        this.availability.Options.VideoOnly = 1
        this.availability.Options.VideoLinkAvailable = 0
        this.availability.Options.ByRequestOnly = 0
      } else {
        this.availability.Options.VideoOnly = 0
      }
    },
    changeFillOrder() {
      var fillOrder = this.fillOrders[this.fillOrder]
      if (fillOrder != undefined) this.availability.fillOrder = fillOrder.value
    },

    async loadData() {
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  },
}
</script>
